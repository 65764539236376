import React, { memo } from 'react'

import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useReceiver } from '../../contexts/ReceiverContext'
function MenuItem({ icon, title, subtitle, link = '' }) {
  const { pathName } = useLocation()
  const navigate = useNavigate()
  const { receiver, saveReceiver } = useReceiver()
  return (
    <div
      className={'flex px-5 py-1 cursor-pointer'}
      onClick={() => {
        localStorage.setItem('vbc_receiver', null)
        saveReceiver((prev) => ({
          ...prev,
          edit_mode: '',
          edit_vbcid: '',
        }))
        return navigate(link, { state: { parent: pathName } })
      }}
      // to={link}
      // state={{ parent: pathName }}
    >
      {icon && (
        <div className='icon w-8 flex-shrink-0'>
          {icon && <img src={icon} alt='' className='w-11 h-auto' />}
        </div>
      )}
      <div className='pl-5'>
        {title && (
          <h3 className='text-gray-800 font-base text-[18px] font-semibold'>
            {title}
          </h3>
        )}
        {subtitle && (
          <p className='text-gray-500 font-base text-xs font-light'>
            {subtitle}
          </p>
        )}
      </div>
    </div>
  )
}

export default memo(MenuItem)
