import { useState } from 'react'
import { set_storage } from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import uiStyles from '../../ui/uiStyles'

const DeleteProvider = ({ market_name, state, setState, setShowModal }) => {
  const { axiomFetch } = useAxiomFetch()
  const [loading, setLoading] = useState(false)

  return (
    <div>
      <p>
        Are you sure you want to delete {state?.market_edit?.name} from{' '}
        {market_name}?
      </p>
      <div className='flex justify-end gap-[5px]'>
        <button
          className={`${uiStyles.white_btn}`}
          onClick={() => {
            setShowModal(false)
          }}
        >
          Cancel
        </button>
        <button
          className={`${uiStyles.red_btn}`}
          onClick={async () => {
            const result = await axiomFetch(
              '/products/vbc/services',
              'DELETE',
              state?.market_edit || {}
            )
            setLoading(true)
            setState((prev) => {
              const the_state = {
                ...prev,
                providers: [
                  ...prev?.providers?.filter(
                    (item) =>
                      item.default_uuid !== state?.market_edit?.default_uuid
                  ),
                ],
              }
              set_storage('vbcs', the_state)
              return the_state
            })
            setLoading(false)
            setShowModal(false)
          }}
        >
          Confirm
        </button>
      </div>
    </div>
  )
}

export default DeleteProvider
