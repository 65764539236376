import React, { useCallback, useEffect, useRef, useState } from 'react'

import ListItem from '../../ui/ListItem'
import SwipeConfig from '../../ui/SwipeConfig'
// import Wrapper from '../layout/Wrapper';
import { useProfile } from '../../../contexts/UserContext'
import UpdateRequest from '../../UpdateRequest'
import Modal from '../../Modal'
import SmallLoading from '../../ui/SmallLoading'
import axiom_1 from '../../../assets/images/AXIOM_1.png'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { MarketingMaterialSubmit } from './MarketingMaterialSubmit'
import { getDateString } from '../../../helpers'

export default function MarketingMaterialCompleted({
  selection = false,
  preview = true,
  state,
  setState,
  type = 'completed',
}) {
  const [selected, setSelected] = useState(1)
  const [toggle, setToggle] = useState(0)
  let prevToggle = 0
  let lastToggle = 0
  const { axiomFetch } = useAxiomFetch()
  const { profile } = useProfile()
  // const [, setNeedsUpdate] = useState(0)
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [currentOrder, setCurrentOrder] = useState({})

  console.log(state)
  const save_address = () => {
    let address = {}
    if (state?.create_order?.current_selection?.del_add1_p) {
      return
    }
    if (profile?.BR_DEL_ADD_LINE1) {
      address = {
        // eslint-disable-next-line
        ['del_add1_p']: profile?.BR_DEL_ADD_LINE1 || '',
        // eslint-disable-next-line
        ['del_add2_p']: profile?.BR_DEL_ADD_LINE2 || '',
        // eslint-disable-next-line
        ['del_city_p']: profile?.BR_DEL_ADD_CITY || '',
        // eslint-disable-next-line
        ['del_st_p']: profile?.BR_DEL_ADD_ST || '',
        // eslint-disable-next-line
        ['del_zip_p']: profile?.BR_DEL_ADD_POSTAL || '',
      }
    } else {
      address = {
        // eslint-disable-next-line
        ['del_add1_p']: profile?.BR_ADD_LINE1 || '',
        // eslint-disable-next-line
        ['del_add2_p']: profile?.BR_ADD_LINE2 || '',
        // eslint-disable-next-line
        ['del_city_p']: profile?.BR_ADD_CITY || '',
        // eslint-disable-next-line
        ['del_st_p']: profile?.BR_ADD_ST || '',
        // eslint-disable-next-line
        ['del_zip_p']: profile?.BR_ADD_POSTAL || '',
      }
    }

    setState((prev) => ({
      ...prev,
      create_order: {
        ...prev?.create_order,
        current_selection: {
          ...prev?.create_order?.current_selection,
          ...address,
        },
      },
    }))
  }

  const save_selection = (key, value) => {
    setState((prev) => ({
      ...prev,
      create_order: {
        ...prev?.create_order,
        current_selection: {
          ...prev?.create_order?.current_selection,
          [key]: value,
        },
      },
    }))
  }

  useEffect(() => {
    const controller = new AbortController()

    const get_pending_materials = async () => {
      setIsLoading(true)
      const items = await axiomFetch(
        `/marketing/materials/status/${type}`,
        'GET',
        {},
        {},
        false,
        controller.signal
      )
      // const items_11 = await axiomFetch(
      //   '/marketing/materials/status/11',
      //   'GET',
      //   {},
      //   {},
      //   false,
      //   controller.signal
      // )
      console.log(items)
      let final_items = items?.length ? items : []
      // ?.filter(
      //   (item) => !(item.AXIOM_FLYER_SESS_ID > 0)
      // ) || []
      // console.log(final_items)
      // if (final_items?.length) {
      //   final_items = final_items?.concat(items_11)
      // }
      setData(final_items)
      if (!controller.signal.aborted) {
        setIsLoading(false)
      }
    }
    get_pending_materials()
    return () => {
      controller?.abort()
    }
  }, [type])

  const rightConfig = (item) => () =>
    (
      <button
        className='text-md'
        onClick={() => {
          //change it back to 5
          let new_item = {}
          if (item?.NEW_PDF_URL) {
            item.PDF_URL = item.NEW_PDF_URL
            item.IMAGE_LINK = item.NEW_PDF_URL
          }
          for (const [key, value] of Object.entries(item)) {
            new_item[key?.toLowerCase()] = value
          }

          save_address()
          setState((prev) => ({
            ...prev,
            create_order: {
              ...prev?.create_order,
              current_selection: {
                status_p: 65,
                ...new_item,
              },
            },
          }))
          setShowModal(true)
        }}
      >
        {type === 'catalog' ? 'Order' : 'Re-order'}
      </button>
    )

  const htmlListing =
    data && !data?.length ? (
      <div className='p-5'>
        {type === 'completed'
          ? 'No requests are completed yet.'
          : 'No requests are cataloged yet.'}
      </div>
    ) : (
      data?.map((item, index) => {
        // if (index < 10) {
        return (
          <SwipeConfig
            key={index}
            index={index}
            selectedIndex={selected}
            setSelectedIndex={setSelected}
            RightComponents={rightConfig(item)}
            // LeftComponents={leftConfig(item)}
          >
            <ListItem
              key={index}
              toggle={toggle}
              index={index + 1}
              setToggle={setToggle}
              image={item.IMAGE_LINK || item?.NEW_PDF_URL || axiom_1}
              // {DUMMYTHUMBS[Math.floor(Math.random() * 3)]}
              title={item?.PRODUCT_REQ || 'Instant Design'}
              //content={item.REQ_DESC}
              orderDate={getDateString(item.REQ_DT)}
              quantity={item.REQ_QUANTITY}
              job_id={item.REQ_ID}
              status_text={item?.REQ_STATUS_TEXT || 'Complete'}
              link={false}
              preview={preview}
            />
          </SwipeConfig>
        )
        //}
      })
    )
  if (isLoading) {
    return <SmallLoading />
  }
  return (
    // <Wrapper>
    <div
      onClick={() => {
        // when clicking on wrapper need to have it actually close on first try. problem is when the picture is clicked wrapper gets clicked
        lastToggle = toggle
        // eslint-disable-next-line
        if ((lastToggle !== toggle || prevToggle == toggle) && toggle > 0) {
          setToggle(0)
          prevToggle = toggle
        }

        prevToggle = toggle
      }}
    >
      {/* <div className="pt-2 pb-4 px-4">
        <PageSubMenu selected={INNERMENU.ApproveMM} />
      </div> */}
      <div>
        <div className='flex flex-col gap-[2px] mt-[10px]'>{htmlListing}</div>
      </div>
      {/* </Wrapper> */}
      {showModal && (
        <Modal
          modalName={`marketing_material_pending`}
          height='h-[550px]'
          showModal={showModal}
          setShowModal={setShowModal}
        >
          <MarketingMaterialSubmit
            state={state}
            setState={setState}
            reorder={true}
          />
        </Modal>
      )}
    </div>
  )
}
