import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useListingTours } from '../../../contexts/ListingToursContext'
import { get_storage, set_storage } from '../../../helpers'
import uiStyles from '../../ui/uiStyles'
import Navigation from './Navigation'

const FinishTour = ({ state, setState }) => {
  const { get_event_tour_type_id } = useListingTours()
  const navigate = useNavigate()
  console.log(get_event_tour_type_id(state?.tour_state?.event_id))
  console.log(get_storage('uuid'))
  return (
    <div>
      <div className='px-3'>
        <p className='text-[18px] font-bold mb-[10px]'>
          Looks like our tour has come to end!
        </p>
        <p>
          Thanks for hopping on for the ride with us!! We look forward to
          meeting with you again.
        </p>
      </div>
      <div className='flex justify-center gap-[10px] mt-[10px]'>
        <button
          className={uiStyles.white_btn}
          onClick={() => {
            const new_tour_state = {
              event_id: state?.tour_state?.event_id,
              completed: state?.tour_state?.completed,
            }
            setState((prev) => {
              set_storage('listing_tours_state', new_tour_state)
              return {
                ...prev,
                tour_state: new_tour_state,
              }
            })
          }}
        >
          Restart Tour
        </button>
        {get_event_tour_type_id(state?.tour_state?.event_id) === 22 ? (
          <button
            className={uiStyles.hover_btn}
            onClick={() => {
              return navigate(
                state?.tour_state?.from_card || get_storage('uuid')
                  ? '/card'
                  : '/user'
              )
            }}
          >
            Finish
          </button>
        ) : (
          <button
            className={uiStyles.hover_btn}
            onClick={() => {
              navigate('/user')
            }}
          >
            Explore AXIOM
          </button>
        )}
      </div>
      <Navigation state={state} setState={setState} />
    </div>
  )
}

export default FinishTour
