import { useState } from 'react'
import {
  BsArrowBarLeft,
  BsArrowLeft,
  BsArrowLeftCircle,
  BsArrowRightCircle,
} from 'react-icons/bs'
import images_homes from '../../assets/images/home'
import { useSales } from '../../contexts/SalesContext'
import { validate_number } from '../../helpers'
import uiStyles from '../ui/uiStyles'

const AtBat = ({ setShowModal, setModalType }) => {
  const { sales, saveSales } = useSales()
  const [currQuest, setCurrQuest] = useState(0)
  const get_cli_first_name = () => {
    return (
      sales?.curr_client?.data?.cont_name?.split(' ')?.[0] ||
      sales?.curr_client?.data?.cont_name ||
      ''
    )
  }

  const get_rep_first_name = () => {
    return (
      sales?.curr_client?.data?.rep_name?.split(' ')?.[0] ||
      sales?.curr_client?.data?.rep_name ||
      ''
    )
  }

  const get_questions = () => {
    return sales?.powerful_questions
  }
  // potentially at bat before stmt
  return (
    <div>
      <div className='px-3 mt-[10px] flex flex-col gap-[5px]'>
        <p>
          {get_rep_first_name()}: Hi,{' '}
          <span className='font-bold'>{get_cli_first_name()}</span> it's{' '}
          {get_rep_first_name()} {'(wait for a response)'}
        </p>
        <p>
          {get_cli_first_name()}: Hi {get_rep_first_name()}
        </p>
        <div className={`${uiStyles.line}`}></div>

        <p>{get_rep_first_name()}: Can I tell you why I called?</p>
        <p>{get_cli_first_name()}: Sure</p>
        <div className={`${uiStyles.line_3px}`}></div>
        <div className='font-bold mt-[10px]'>
          <div className='flex gap-[5px] justify-between'>
            <button
              onClick={() => {
                saveSales((prev) => {
                  let curr_quest = validate_number(
                    prev?.curr_client?.curr_quest
                  )
                  if (curr_quest === 0) {
                    curr_quest = get_questions()?.length - 1
                  } else {
                    curr_quest = curr_quest - 1
                  }
                  const sales = {
                    ...prev,
                    curr_client: { ...prev?.curr_client, curr_quest },
                  }
                  return sales
                })
              }}
            >
              <BsArrowLeftCircle size={20} />
            </button>
            <div className='max-w-[250px]'>
              <p className='text-black overflow-scroll whitespace-nowrap'>
                {
                  get_questions()?.[
                    validate_number(sales?.curr_client?.curr_quest)
                  ]?.qst_name
                }
              </p>
              <div className={uiStyles.line}></div>
              <p>
                {get_questions()?.[
                  validate_number(sales?.curr_client?.curr_quest)
                ]?.position_text?.replaceAll('¿', "'")}
              </p>
            </div>
            <button
              onClick={() => {
                saveSales((prev) => {
                  let curr_quest = validate_number(
                    prev?.curr_client?.curr_quest
                  )
                  if (curr_quest === get_questions()?.length - 1) {
                    curr_quest = 0
                  } else {
                    curr_quest = curr_quest + 1
                  }
                  const sales = {
                    ...prev,
                    curr_client: { ...prev?.curr_client, curr_quest },
                  }
                  return sales
                })
              }}
            >
              <BsArrowRightCircle size={20} />
            </button>
          </div>
          {
            <div className='flex justify-end'>
              <img
                src={images_homes.filter}
                className='w-[20px] h-[20px] cursor-pointer'
                onClick={() => {
                  setModalType('quest_picker')
                  setShowModal(true)
                }}
              />
            </div>
          }
        </div>
        <div className={`${uiStyles.line_3px}`}></div>

        <p>{get_rep_first_name()}: Can I ask you a question?</p>
        <p>{get_cli_first_name()}: Sure</p>
        <div className={`${uiStyles.line}`}></div>
        <p>
          {get_rep_first_name()}: That probably isn't a problem for you is it?
        </p>
        <p>{get_cli_first_name()}: It is.</p>
        <div className={`${uiStyles.line}`}></div>
        <p>{get_rep_first_name()}: Really?...</p>
        <p>{get_cli_first_name()}: Yes</p>
        <div className={`${uiStyles.line}`}></div>
        <p>{get_rep_first_name()}: Would you like to fix it?...</p>
        <p>{get_cli_first_name()}: Yes</p>
        <div className={`${uiStyles.line}`}></div>
      </div>
      <div className='flex flex-col justify-end gap-[10px] mt-[20px] px-3'>
        <p className='font-bold'>Did you get an appointment?</p>
        <div className='flex w-full'>
          <button
            className={`${uiStyles.white_btn} flex-grow rounded-none`}
            onClick={() => {
              saveSales((prev) => ({
                ...prev,
                curr_client: {
                  ...prev?.curr_client,
                  appointment: { appointment_set: false },
                },
              }))
              setModalType('appointment_set')
              setShowModal(true)
            }}
          >
            No
          </button>
          <button
            className={`${uiStyles.hover_btn} flex-grow rounded-none`}
            onClick={() => {
              saveSales((prev) => ({
                ...prev,
                curr_client: {
                  ...prev?.curr_client,
                  appointment: { appointment_set: true },
                },
              }))
              setModalType('appointment_set')
              setShowModal(true)
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  )
}

export default AtBat
