import { memo, useEffect, useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { array_nums, validate_number } from '../../helpers'
import { useCalc } from '../../contexts/CalcContext'
import LabelSlider from '../form/LabelSlider'
const CalcBuyLineChart = memo(
  ({
    labels = array_nums(360),
    show_line_slider = false,
    show_graph = true,
    line_point = 0,
    slide_change = () => {},
    datasets = [
      {
        fill: false,
        data: labels.map((item) => item * 20),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        fill: false,
        data: labels.map((item) => item * 30),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  }) => {
    ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Legend,
      {
        id: 'customLine',
        afterDraw: (chart, _) => {
          const { ctx, scales, chartArea, datalabels } = chart

          if (
            chart.options.plugins.customLine.idx !== null &&
            chart.options.plugins.customLine.idx !== null
          ) {
            const xValue =
              chart?.$datalabels?._datasets?.[0]?.[
                chart.options.plugins.customLine.idx
              ]?._el?.x
            if (xValue) {
              ctx.save()
              ctx.beginPath()
              ctx.lineWidth = 2
              ctx.strokeStyle = 'rgba(0, 0, 0, 0.8)'
              ctx.moveTo(xValue, chartArea.top)
              ctx.lineTo(xValue, chartArea.bottom)
              ctx.stroke()
              ctx.restore()
            }
          }
        },
      }
    )
    const { calc, saveCalc, swiper_slide } = useCalc()

    const options = {
      responsive: false,
      animation: false,
      plugins: {
        legend: {
          display: false,
          position: 'top',
        },
        customLine: {
          idx: line_point,
        },
        title: {
          display: false,
          text: 'Chart.js Line Chart',
        },
        datalabels: {
          display: false,
        },
        tooltip: {
          display: false,
          intersect: false,
          mode: 'index',
        },
      },
      pointRadius: 0,
      chart: {
        type: 'line',
        // height: 350,
        // width: 500,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: true,
        },
      },
      scales: {
        x: {
          display: false,
          ticks: {
            display: false,
          },
        },
        y: {
          display: true,
          beginAtZero: true,
          // ticks: {
          //   display: false,
          // },
        },
      },
    }

    const data = {
      labels,
      datasets,
    }

    return (
      <div className='flex flex-col items-center'>
        {show_graph ? <Line options={options} data={data} /> : ''}
        {show_line_slider ? (
          <div className={`${show_graph ? 'ml-[62px]' : ''} w-[251px]`}>
            <LabelSlider
              onMouseDown={(e) => {
                swiper_slide(false)
              }}
              onTouchStart={(e) => {
                swiper_slide(false)
              }}
              onTouchEnd={(e) => {
                swiper_slide(true)
              }}
              onMouseUp={(e) => {
                swiper_slide(true)
              }}
              type='range'
              min='0'
              step={1}
              max={labels.length - 2}
              show_count={true}
              value={
                line_point <= 0
                  ? 1
                  : line_point >= labels?.length - 1
                  ? labels?.length - 1
                  : line_point + 1
              }
              onChange={(e) => slide_change(e)}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }
)

export default CalcBuyLineChart
