import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { HiPlus } from 'react-icons/hi2'
import SubNav from '../../components/SubNav'
import TitleIcon from '../../components/ui/TitleIcon'
import { INNERMENU } from '../../data/data'
import PageSubMenu from '../../components/PageSubMenu'
import SubTitle from '../../components/ui/SubTitle'
import PrimaryButton from '../../components/ui/PrimaryButton'
import Pagination from '../ui/Pagination'
import PromotionList from '../PromotionsList'
import Input from '../hookform/Input'
import PrimaryFormButton from '../ui/PrimaryFormButton'
import Textarea from '../hookform/Textarea'
import DateTimePicker from '../ui/DateTimePicker'
import { useAuth0 } from '@auth0/auth0-react'

import { useQuery } from '@tanstack/react-query'
import Loading from '../Loading'
import { useProfile } from '../../contexts/UserContext'
import Wrapper from '../layout/Wrapper'
import { ServicePromotions } from '../../services/ServiceMarketing'

export default function Promotions() {
  const [promFormVisibility, setPromFormVisibility] = useState(false)
  const methods = useForm()
  const { getAccessTokenSilently } = useAuth0()
  const { profile } = useProfile()

  const fetchController = useRef(null)
  const params = useRef({
    skip: 0,
    limit: 5,
  })

  const getPromotions = useCallback(async () => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUDIENCE,
    }).catch((err) => null)

    const serviceClient = new ServicePromotions(token)
    if (!fetchController.current) {
      fetchController.current = serviceClient.getController().controller
    }
    return serviceClient.getAll(params.current)
  }, [params, getAccessTokenSilently])

  const { isLoading, isError, data, refetch, isFetching } = useQuery({
    queryKey: ['promotions', params.current],
    queryFn: getPromotions,
    refetchOnWindowFocus: false,
    enabled: !!profile,
  })

  const showNext = () => {
    if (!isLoading && !isFetching) {
      params.current = {
        ...params.current,
        skip: params.current.skip + params.current.limit,
      }
      refetch()
    }
  }
  const showPrev = () => {
    if (!isLoading && !isFetching) {
      params.current = {
        ...params.current,
        skip: params.current.skip - params.current.limit,
      }
      refetch()
    }
  }

  const onSubmit = (data) => console.log(data)

  const dateHandler = (date) => {
    methods.setValue('selecetddate', date)
  }

  useEffect(() => {
    return () => fetchController.current && fetchController.current.abort()
  }, [])
  if (!isError && isLoading && isFetching) {
    return <Loading />
  }

  return (
    <Wrapper>
      <SubNav />
      <TitleIcon className='mb-5'>My RSS</TitleIcon>
      <div className='pt-2 pb-4 px-4'>
        <PageSubMenu selected={INNERMENU.Promotions} />
        <SubTitle className='mt-4'>My Promotions</SubTitle>
        <p className='text-gray-500 text-sm mb-3'>
          Creating a custom promotion can be a good way to drive sales and
          engage customers. By designing a promotion specifically for your VBC,
          you have the opportunity to create a unique and relevant offer that is
          tailored to your current clients or your target audience.
        </p>
        <div className='border border-gray-200 p-4 '>
          <SubTitle>My Promotions</SubTitle>
          <p className='mb-4'>Manage your Promotions here.</p>
          <PrimaryButton
            clickHandler={() => setPromFormVisibility(!promFormVisibility)}
            isSelected={promFormVisibility}
          >
            <HiPlus className='inline text-xl  align-middle mr-1' /> Create a
            Promotion
          </PrimaryButton>
        </div>
        {promFormVisibility && (
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onSubmit)}
              className='pt-5 pb-5'
            >
              <input type='hidden' {...methods.register(`selecetddate`)} />
              <Input
                type='text'
                name='promotiontitle'
                placeholder='New Promotion Name'
                label='Add a promotion headline'
                errorMessage='Promotion Title is necessary'
                suggestion='Best practice: keep your headline short and catchy.'
                className='mb-7'
                rule={{ required: true }}
              />
              <Textarea
                name='promotioncontent'
                label='Promotion Content'
                errorMessage='Promotion Content is necessary'
                suggestion='You can customize the content now to suit your needs.'
                className='mb-4'
                rule={{ required: true }}
              />
              <Input
                type='text'
                name='promotionlink'
                placeholder=''
                label='Link'
                errorMessage='Enter a url.'
                suggestion=''
                className='mb-6'
                rule={{
                  pattern:
                    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\\+.~#?&\\/=]*)$/,
                  required: true,
                }}
              />
              <hr className='border-gray-200 mb-4' />
              <p className='text-xs font-semibold text-center text-primary'>
                When would you like this to be posted?
              </p>
              <DateTimePicker dateChangeHandler={dateHandler} />
              <div className='text-right'>
                <PrimaryFormButton type='submit'>Schedule</PrimaryFormButton>
              </div>
            </form>
          </FormProvider>
        )}
        <div className='my-4'>
          <p className='text-sm font-semibold text-center text-primary'>
            Promotions
          </p>
        </div>
        <div>
          {data && <PromotionList sources={data} />}
          <Pagination
            showPrev={data && params.current.skip > 0}
            showNext={data && data.length >= params.current.limit}
            clickNextHandler={showNext}
            clickPrevHandler={showPrev}
            pageNum={
              (params.current.skip + params.current.limit) /
              params.current.limit
            }
          />
        </div>
      </div>
    </Wrapper>
  )
}
