import React, { memo } from 'react';

function Loading() {
  return (
    <div className="flex items-center justify-center space-x-2 absolute left-0 top-0 bg-white w-full h-full right-0 bottom-0">
      <span className="flex h-16 w-16 relative">
        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-gradient-to-b from-accent-150 to-accent-400 opacity-75"></span>
        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-gradient-to-b from-accent-150 to-accent-400 opacity-75"></span>
      </span>
    </div>
  );
}

export default memo(Loading);
