import React, { useEffect, useState } from 'react';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import SubNav from '../SubNav';
import TitleIcon from '../ui/TitleIcon';
import PageSubMenu from '../PageSubMenu';
import { INNERMENU } from '../../data/data';

import SwipeConfig from '../ui/SwipeConfig';

import LeadItem from '../ui/LeadItem';
import useRecorder from '../../hooks/useRecorder';
import AxiomPlayIcon from '../ui/AxiomPlayIcon';
import AxiomPauseIcon from '../ui/AxiomPauseIcon';

import AxiomRecordIcon from '../ui/AxiomRecordIcon';
import AxiomStopRecordIcon from '../ui/AxiomStopRecordIcon';
import { FormProvider, useForm } from 'react-hook-form';
import Input from '../hookform/Input';
import PrimaryFormButton from '../ui/PrimaryFormButton';

import { HiOutlinePlay } from 'react-icons/hi2';

import Wrapper from '../layout/Wrapper';
const slydials = [
  {
    title: 'NOD Slydial',
    description: 'Message to Notice of Default',
    created: '01/03/23',
  },
  {
    title: 'FSBO Slydial',
    description: 'Message for Sale by owner',
    created: '01/03/23',
  },
  {
    title: 'NOD Slydial',
    description: 'Message to Notice of Default',
    created: '01/03/23',
  },
  {
    title: 'FSBO Slydial',
    description: 'Message for Sale by owner',
    created: '01/03/23',
  },
];
export default function MySlyDials() {
  const [selected, setSelected] = useState(1);
  let [audioURL, isRecording, startRecording, stopRecording] = useRecorder();
  const methods = useForm();

  const rightConfig = (item) => () =>
    (
      <span className="text-md" onClick={() => {}}>
        <HiOutlinePlay className="text-4xl" />
      </span>
    );
  const leftConfig = (item) => () =>
    (
      <span className="text-md" onClick={() => {}}>
        Send
      </span>
    );
  const htmlListing = slydials?.map((item, index) => (
    <SwipeConfig
      key={index}
      index={index}
      selectedIndex={selected}
      setSelectedIndex={setSelected}
      RightComponents={rightConfig(item)}
      LeftComponents={leftConfig(item)}
    >
      <LeadItem
        key={index}
        title={item.title}
        description={item.description}
        index={index}
        number={item.created}
      />
    </SwipeConfig>
  ));
  const createNewSlyDial = async (data) => {
    if (audioURL) {
      let formdata = new FormData();
      let blob = await fetch(audioURL).then((r) => r.blob());
      formdata.append('wavfile', blob, `${data.newslydial}.wav`);
    }
  };
  useEffect(() => {
    methods.setValue('blogfile', audioURL);
  }, [methods, audioURL]);
  return (
    <Wrapper>
      <SubNav />
      <TitleIcon className="mb-5">Marketing</TitleIcon>
      <div className="pt-2 pb-4 px-4">
        <PageSubMenu selected={INNERMENU.MySlyDials} />
      </div>
      <div className="header-lead">
        <div className={`flex items-center px-4 py-3`}>
          <div className="info flex-1 text-center">SlyDial Name</div>
          <div className="w-[90px] text-center">Date Created</div>
        </div>
      </div>
      {htmlListing}
      <div className="p-4">
        <p className="text-center text-primary">Record a New SlyDIal</p>

        <AudioPlayer
          onError={() => {}}
          onPlayError={() => {}}
          autoPlay
          src={audioURL}
          onPlay={(e) => {}}
          showFilledVolume={false}
          customAdditionalControls={[]}
          customVolumeControls={[]}
          showJumpControls={false}
          customControlsSection={[
            RHAP_UI.MAIN_CONTROLS,
            isRecording ? (
              <AxiomStopRecordIcon clickHandler={stopRecording} />
            ) : (
              <AxiomRecordIcon clickHandler={startRecording} />
            ),
          ]}
          customIcons={{
            play: <AxiomPlayIcon />,
            pause: <AxiomPauseIcon />,
          }}
        />

        <hr className="border-gray-200 my-4" />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(createNewSlyDial)}>
            <Input
              type="text"
              rule={{ required: true }}
              label="Name Your SlyDIal"
              name="newslydial"
              errorMessage="Name is necessary."
            />
            <input
              type="hidden"
              {...methods.register('blogfile', { required: true })}
            />
            {methods.formState.errors['blogfile'] && (
              <p className="text-xs text-red-500">Record an audio file.</p>
            )}
            <div className="text-right mt-4">
              <PrimaryFormButton>Save</PrimaryFormButton>
            </div>
          </form>
        </FormProvider>
      </div>
    </Wrapper>
  );
}
