import { React, useState, forwardRef } from 'react'
import uiStyles from '../../ui/uiStyles'
import { AiFillDelete } from 'react-icons/ai'
import { GrFormNextLink } from 'react-icons/gr'
import { FcNext } from 'react-icons/fc'
import rightArrow from '../../../assets/images/arrow_1.svg'
import { MdOutlineNavigateNext } from 'react-icons/md'
import { formatPhoneNumber } from '../../../helpers'
import { Link, useNavigate } from 'react-router-dom'
import { useDistribute } from '../../../contexts/DistributeContext'
import SwipeConfig from '../../ui/SwipeConfig'
import {
  HiEnvelopeOpen,
  HiOutlineChatBubbleLeft,
  HiOutlineEnvelope,
  HiOutlinePhoneArrowUpRight,
  HiOutlinePencil,
} from 'react-icons/hi2'
import { TfiPencilAlt } from 'react-icons/tfi'

const ConfigIcon = ({ icon, text, clickHandler }) => {
  return (
    <span
      onClick={clickHandler}
      className='flex min-w-[38px] font-light flex-col mx-1 text-[6px] text-center items-center justify-center leading-3 hover:text-accent-100'
    >
      {text}
      {icon}
    </span>
  )
}

const MySingleList = forwardRef(
  (
    {
      setModalListName,
      setModalListId,
      setShowModal,
      setModalType,
      list,
      handle_save,
      selected,
      setSelected,
      filterStartDate,
      filterEndDate,
    },
    ref
  ) => {
    const [lis, setLis] = useState(list)
    const { saveDistribute } = useDistribute()
    const navigate = useNavigate()
    return (
      <SwipeConfig
        bg={list?.bg}
        key={list.index}
        index={list.index}
        selectedIndex={selected}
        setSelectedIndex={setSelected}
        widthConfigRight={44}
        widthConfigLeft={44}
        RightComponents={() => (
          <>
            {!list?.LIST_ID ? (
              <button onClick={handle_save}>Save</button>
            ) : (
              <>
                <ConfigIcon
                  text='Email List'
                  icon={<HiOutlineEnvelope className='text-2xl' />}
                  clickHandler={() => {
                    saveDistribute((prev) => ({
                      ...prev,
                      type: 'email',
                      list_name: list?.LIST_NAME,
                      list_id: list?.LIST_ID,
                    }))
                    navigate('/user/axiom-ai')
                  }}
                />
                <ConfigIcon
                  text='Text List'
                  icon={<HiOutlineChatBubbleLeft className='text-2xl' />}
                  clickHandler={() => {
                    saveDistribute((prev) => ({
                      ...prev,
                      type: 'text',
                      list_name: list?.LIST_NAME,
                      list_id: list?.LIST_ID,
                    }))
                    navigate('/user/axiom-ai')
                  }}
                />
                {/* <ConfigIcon
                    text="SkyDial List"
                    icon={<HiOutlinePhoneArrowUpRight className="text-2xl" />}
                    clickHandler={() => {
                      setModalListId(list?.LIST_ID)
                      setModalListName(list?.LIST_NAME)
                      setShowModal(true);
                      setModalType('slyDial');
                    }}
                  /> */}
                {/* <ConfigIcon
                    text="Send to Myself"
                    icon={<HiEnvelopeOpen className="text-2xl" />}
                    clickHandler={() => {}}
                  /> */}
              </>
            )}
          </>
        )}
        LeftComponents={() => (
          <>
            {!list?.LIST_ID ? (
              <button onClick={handle_save}>Save</button>
            ) : (
              <>
                {/* <div className='mr-5'>
             <label className="transition-all delay-100 block capitalize relative">
                  <input
                    className="absolute left-0 right-0 top-0 h-full opacity-0 transition-all duration-100"
                    type="checkbox"
                    name={lis?.LIST_NAME}
                    value={lis.STATUS}
                    checked={lis?.STATUS === 'A' ? false : true}
                    onChange={(e) => {
                    // if (list.updated) {
                    //     list.updated = false
                    // } else {
                        list.updated = true
                    //}
                    if (e.target.checked) {
                        list.STATUS = 'X'
                    setLis({...lis, STATUS: 'X'})
                    } else {
                        list.STATUS = 'A'
                        setLis({...lis, STATUS: 'A'})
                    }
                    }}
                  />
                  <div className={`transition-all duration-100 toggle inline-flex w-[36px] h-[20px] rounded-2xl bg-gradient-to-b ${lis.STATUS === 'A' ? 'from-accent-150 to-accent-250' : 'from-gray-200 to-gray-200'} p-[2px] mr-2 align-middle`}>
                    <span className={` bg-white transition-all duration-100 w-[16px] h-[16px] rounded-full inline-block ${lis.STATUS === 'A' && 'transform translate-x-4'}`}></span>
                  </div>
                  <span className='block'>{lis?.STATUS === 'A' ? 'Active' : lis?.STATUS === 'X' ? 'Inactive' : 'Deleted'}</span>
                </label>
                </div> */}
                <button
                  className={`cursor-pointer mr-5`}
                  onClick={() => {
                    console.log('hello')
                    // if (list.updated) {
                    //     list.updated = false
                    // } else {
                    list.updated = true
                    // }
                    if (list?.STATUS === 'D') {
                      list.STATUS = 'A'
                      setLis({ ...lis, STATUS: 'A' })
                    } else {
                      list.STATUS = 'D'
                      setLis({ ...lis, STATUS: 'D' })
                    }
                  }}
                >
                  {lis.STATUS === 'D' ? (
                    <AiFillDelete size={25} color={'red'} />
                  ) : (
                    <AiFillDelete color={'white'} size={25} />
                  )}
                </button>
                <Link
                  className={`cursor-pointer`}
                  to={
                    filterStartDate && filterEndDate
                      ? `${lis?.LIST_ID}?startdate=${filterStartDate}&enddate=${filterEndDate}`
                      : `${lis?.LIST_ID}`
                  }
                  state={{
                    list_name: lis.LIST_NAME,
                    select: 'MY LISTS',
                    parent: '/user/my-leads-lobby',
                  }}
                >
                  {<TfiPencilAlt size={25} />}
                </Link>
              </>
            )}
          </>
        )}
      >
        <div
          className={`swipeable flex items-center ${list.bg} w-[339px] h-[75px] py-3`}
        >
          <div
            ref={ref}
            className='swipeable flex justify-between w-full mr-[50px]'
          >
            <label className='block capitalize relative px-3'>
              {/* List Name */}
              <input type='hidden' value={lis?.LIST_ID} />
              <input
                placeholder='name'
                className={`${list.bg} max-w-[200px] border pl-2 py-1 text-[14px] text-[#101828] capitalize`}
                value={lis?.LIST_NAME || ''}
                onChange={(e) => {
                  // if (list.updated) {
                  //     list.updated = false
                  // } else {
                  list.updated = true
                  //}
                  list.LIST_NAME = e.target.value
                  setLis({ ...lis, LIST_NAME: e.target.value })
                }}
              />
            </label>
            <div className='swipeable text-[#101828]'>{list.LIST_COUNT}</div>
          </div>
        </div>
      </SwipeConfig>
    )
  }
)

export default MySingleList
