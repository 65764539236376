import { useEffect, useState, useRef } from 'react'
import { useCalc } from '../../contexts/CalcContext'
import {
  calculate_amortization_totals,
  convert_percent,
  format_currency,
  format_input,
  get_storage,
  get_uuid,
  shade_color,
  validate_number,
} from '../../helpers'
import LabelInput from '../form/LabelInput'
import { Schedule } from '../form/Schedule'
import Select from '../form/Select'
import uiStyles from '../ui/uiStyles'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { CircleCheckToggle } from '../form/CircleCheckToggle'
import Modal from '../Modal'
import CalcLoanBalance from './CalcLoanBalance'
import { FaCalculator } from 'react-icons/fa'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import CalcShare from './CalcShare'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ChartDataLabels,
  Title,
  Tooltip,
  Legend
)
const CalcSellHold = ({ type }) => {
  const { calc, saveCalc, get_loan_type, get_calc_type_id } = useCalc()
  const { axiomFetch } = useAxiomFetch()
  const [error, setError] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const timeoutRef = useRef()

  const modals = {
    loan_balance: {
      component: (
        <CalcLoanBalance showModal={showModal} setShowModal={setShowModal} />
      ),
      width: 'w-full',
      height: 'h-[550px]',
    },
  }

  const update_input = (options) => {
    const calculator_type = 'holding_costs'
    const { name, value } = format_input(options)
    let type =
      options?.type || calc?.[calculator_type]?.types?.[`${name}`] || ''
    if (name === 'sale_price' || name === 'loan_balance') {
      const calculator_type = 'sell'
      return saveCalc((prev) => {
        const result = {
          ...prev,
          [calculator_type]: {
            ...prev?.[calculator_type],
            values: { ...prev?.[calculator_type]?.values, [name]: value },
            types: { ...prev?.[calculator_type]?.types, [name]: type },
          },
        }
        return result
      })
    }
    saveCalc((prev) => {
      const result = {
        ...prev,
        [calculator_type]: {
          ...prev?.[calculator_type],
          values: { ...prev?.[calculator_type]?.values, [name]: value },
          types: { ...prev?.[calculator_type]?.types, [name]: type },
        },
      }
      return result
    })
  }
  const get_inputs = (calculator_type = 'buy') => {
    const types = calc?.[calculator_type]?.types
    const names = calc?.[calculator_type]?.names
    const placeholders = calc?.[calculator_type]?.placeholders
    const values = calc?.[calculator_type]?.values
    return Object.entries(values || {})
      ?.filter(([key, value]) => ![].includes(key))
      ?.map(([key, value]) => {
        const type = types[key]
        const name = names[key]
        const placeholder = placeholders[key] || ''
        const label_width = 'w-[80%]'
        const initial_data = {
          left_label: name,
          left_label_width: label_width,
          name: key,
          value,
          placeholder,
        }

        if (
          type === '%' ||
          type === '$' ||
          type === '%$' ||
          type === 'm' ||
          type === 'a'
        ) {
          initial_data.show_delete_all = true
          initial_data.inputMode = 'decimal'
        }
        if (type === '%') {
          initial_data.show_percent = true
          initial_data.percent_value = '%'
          initial_data.handleChange = (e, type) => {
            update_input({ e, type, decimal: 3, original: value })
          }
        }
        if (type === '$') {
          initial_data.show_dollar = true
          initial_data.percent_value = ''
          initial_data.value = initial_data?.value
            ? `$${initial_data.value}`
            : initial_data?.value || ''
        }
        if (
          key === 'taxes' ||
          key === 'insurance_rate' ||
          key === 'hoa' ||
          key === 'other' ||
          key === 'utilities'
        ) {
          initial_data.show_percent = true
          initial_data.show_dollar = true
          initial_data.percent_value =
            calc?.[calculator_type]?.types?.[key] === '%' ? '%' : ''
          initial_data.dollar_percent = calc?.[calculator_type]?.types?.[key]
          if (key === 'hoa' || key === 'other' || key === 'utilities') {
            initial_data.toggle_values = ['a', 'm']
            initial_data.dollar_percent = 'm'
          }
          initial_data.left_label_width = 'w-[135%]'
          initial_data.delete_all_position = 'right-[75px]'
          initial_data.total = validate_number(
            calc?.['sell']?.values?.sale_price
          )
          initial_data.handleChange = (e, type) => {
            update_input({ e, type, decimal: 3, original: value })
          }
        }

        let result = (
          <LabelInput
            {...initial_data}
            handleChange={
              initial_data?.handleChange
                ? initial_data?.handleChange
                : (e) => {
                    update_input({ e, type, decimal: 2, original: value })
                  }
            }
          />
        )

        if (type === 'date') {
          result = (
            <Schedule
              left_label={name}
              left_label_width={label_width}
              name={key}
              show_time={false}
              schedule_date={value}
              handle_change={(e) => {
                update_input({ e, type, decimal: 2, original: value })
              }}
            />
          )
        }
        if (type === 'options') {
          const options = calc?.[calculator_type]?.option_values?.[key]
          result = (
            <Select
              left_label={name}
              left_label_width={label_width}
              no_margin={false}
              className='mb-[10px]'
              name={key}
              value={value || 'Conventional'}
              handler={(e) => {
                update_input({ e, type, decimal: 2, original: value })
              }}
              placeholder=''
            >
              {[...options].map((item) => {
                return (
                  <option key={item} value={item}>
                    {item}
                  </option>
                )
              })}
            </Select>
          )
        }

        return <div key={key}>{result}</div>
      })
  }

  const convert_annual = (base, value, type) => {
    if (type === 'm') {
      return validate_number(value) * 12
    } else {
      return validate_number(value)
    }
  }

  const calculate_estimate = () => {
    const calculator_type = 'holding_costs'
    saveCalc((prev) => {
      return {
        ...prev,
        [calculator_type]: {
          ...prev?.[calculator_type],
          data_options: {
            ...prev?.[calculator_type]?.data_options,
            instance_uuid:
              prev?.[calculator_type]?.data_options?.instance_uuid ||
              get_uuid(),
            calculated: true,
            run: (prev?.[calculator_type]?.data_options?.run || 0) + 1,
          },
        },
      }
    })
    if (calc?.sell?.values?.sale_price) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    // Define plugin to dynamically adjust y-axis maximum
    const adjustYAxisPlugin = {
      id: 'adjustYAxisPlugin',
      beforeLayout: (chart) => {
        const totals = []
        chart.data.datasets.forEach((label, index, arr) => {
          const length = arr?.length
          let total = 0
          for (let i = 0; i < length; i++) {
            const value = chart.data.datasets?.[i]?.data?.[index] || 0
            total += validate_number(value)
          }
          totals.push(total)
        }, [])

        const maxBarSize = Math.max(...totals.filter((item) => item))
        const minBarSize = Math.min(...totals.filter((item) => item))
        let maxYAxis = maxBarSize * (0.5 + Math.ceil(minBarSize / maxBarSize))
        function roundUpToLargestPlace(number) {
          let place = 1
          while (number >= 10) {
            number /= 10
            place *= 10
          }
          return Math.ceil(number) * place
        }
        maxYAxis = roundUpToLargestPlace(maxYAxis)
        chart.options.scales.y.suggestedMax = maxYAxis
        chart.options.scales.y.height = maxYAxis
        chart.options.scales.y.max = maxYAxis
        chart.options.scales.y.min = maxYAxis / 10
        let step = Math.ceil(maxYAxis / 10)
        chart.options.scales.y.ticks.stepSize = Math.ceil(step)
      },
      afterDraw: (chart) => {
        const getTotal = (datasetIndex, dataIndex) => {
          let total = 0
          chart.data.datasets
            .filter((dataset) => dataset?.data?.length)
            .forEach((dataset, index) => {
              if (index <= datasetIndex) {
                total += validate_number(dataset?.data?.[dataIndex] || 0)
              }
            })
          return validate_number(total)
        }

        const ctx = chart?.ctx
        ctx.save()
        ctx.fillStyle = '#000'
        ctx.textAlign = 'center'
        ctx.textBaseline = 'bottom'
        ctx.font = '12px Arial'

        chart._metasets
          .filter((dataset) => dataset?.data?.length)
          .forEach((dataset, datasetIndex, arr) => {
            for (let i = 0; i < dataset.data.length; i++) {
              if (datasetIndex === arr?.length - 1) {
                const model = dataset.data[i]
                const total = getTotal(datasetIndex, i)
                ctx.fillStyle = model.options.backgroundColor
                ctx.fillText(
                  `$${format_currency(total, false, 0)}`,
                  model.x,
                  model.y - 5
                )
              }
            }
          })

        ctx.restore()
      },
    }

    // Register the plugin
    ChartJS.register(adjustYAxisPlugin)

    // Unregister the plugin on component unmount
    return () => {
      ChartJS.unregister(adjustYAxisPlugin)
    }
  }, [calc?.holding_costs?.values, calc?.sell?.values])

  useEffect(() => {
    const render_chart = () => {
      let timeout = timeoutRef?.current
      if (
        !calc?.sell?.values?.sale_price &&
        calc?.holding_costs?.data_options?.instance_uuid
      ) {
        if (timeout) {
          clearTimeout(timeout)
        }
        timeout = null
        setError((prev) => {
          return {
            msg: 'Asking Price is required.',
            show_error: true,
          }
        })
        timeout = setTimeout(() => {
          setError((prev) => {
            return {
              msg: 'Asking Price is required.',
              show_error: false,
            }
          })
        }, [3000])
        return saveCalc((prev) => ({
          ...prev,
          holding_costs: {
            ...prev?.holding_costs,
            data_options: {
              ...prev?.holding_costs?.data_options,
              calculated: false,
            },
          },
        }))
      }
      let is_days = calc?.holding_costs?.defaults?.is_days === 'Y'
      const monthly_period = [3, 6, 9, 12]
      const daily_period = [30, 60, 90, 120]
      const time_period = is_days ? daily_period : monthly_period
      const time_label = is_days ? 'Days' : 'Months'
      const sell_values = calc?.sell?.values
      const defaults = calc?.sell?.defaults?.[get_loan_type('sell', 'defaults')]
      const loan_term = validate_number(defaults?.loan_term)
      const holding_values = calc?.holding_costs?.values
      const sale_price = validate_number(sell_values?.sale_price)
      const loan_balance = validate_number(sell_values?.loan_balance)
      const interest_rate = validate_number(holding_values?.interest_rate)
      const holding_types = calc?.holding_costs?.types
      const amortize = calculate_amortization_totals(
        loan_balance,
        interest_rate,
        loan_term
      )
      const get_series = (time_period) => {
        const interest_data = time_period?.map((item) => {
          return amortize?.schedule?.[Math.floor(item / (is_days ? 30 : 1)) - 1]
            ?.total_interest
        })
        const taxes = convert_percent(
          sale_price,
          holding_values?.taxes,
          holding_types?.taxes
        )
        const insurance = convert_percent(
          sale_price,
          holding_values?.insurance_rate,
          holding_types?.insurance_rate
        )
        const hoa = convert_annual(
          sale_price,
          holding_values?.hoa,
          holding_types?.hoa
        )
        const utilites = convert_annual(
          sale_price,
          holding_values?.utilities,
          holding_types?.utilites
        )
        const other = convert_annual(
          sale_price,
          holding_values?.other,
          holding_types?.other
        )
        const totals_data = []
        const format_by_time = (num) => {
          let total = 0
          const data = time_period.map((period) => {
            const new_num = validate_number(
              (num / (is_days ? 365.25 : 12)) * period,
              2
            )
            total += new_num
            return new_num
          })
          totals_data.push(total)
          return data
        }
        const tax_data = format_by_time(taxes)
        const insurance_data = format_by_time(insurance)
        const hoa_data = format_by_time(hoa)
        const utilities_data = format_by_time(utilites)
        const other_data = format_by_time(other)
        const colors = [
          ['#0063AE', '#056243', '#6749C9', '#FF183A', '#8C5E00'],
          ['#0077d1', '#067650', '#7c58f1', '#ff1d46', '#a87100'],
          ['#008ffb', '#078e60', '#956aff', '#ff2354', '#ca8800'],
          ['#00acff', '#08aa73', '#b37fff', '#ff2a65', '#f2a300'],
          ['#00ceff', '#0acc8a', '#d798ff', '#ff3279', '#ffc400'],
          ['#00f7ff', '#0cf5a6', '#ffb6ff', '#ff3c91', '#ffeb00'],
        ]
        const minBarLength = 20
        const series = [
          loan_balance
            ? {
                label: 'Interest',
                backgroundColor: colors[0],
                data: interest_data,
              }
            : {},
          taxes
            ? {
                label: 'Taxes',
                data: tax_data,
                backgroundColor: colors[1],
                minBarLength,
              }
            : {},
          insurance
            ? {
                label: 'Insurance',
                data: insurance_data,
                backgroundColor: colors[2],
                minBarLength,
              }
            : {},
          hoa
            ? {
                label: 'HOA',
                data: hoa_data,
                backgroundColor: colors[3],
                minBarLength,
              }
            : {},
          utilites
            ? {
                label: 'Utilities',
                data: utilities_data,
                backgroundColor: colors[4],
                minBarLength,
              }
            : {},
          other
            ? {
                label: 'Other',
                data: other_data,
                backgroundColor: colors[5],
                minBarLength,
              }
            : {},
        ]
        return series || []
      }

      const get_labels = (period, label) => {
        return period?.map((item) => `${item} ${label}`)
      }
      saveCalc((prev) => ({
        ...prev,
        holding_costs: {
          ...prev?.holding_costs,
          chart: {
            ...prev?.holding_costs?.chart,
            monthly_series: get_series(monthly_period),
            daily_series: get_series(daily_period),
            daily_labels: get_series(daily_period),
            daily_period,
            monthly_labels: get_labels(daily_period, 'Months'),
            monthly_labels: get_labels(monthly_period, 'Days'),
            monthly_period,
            series: get_series(time_period),
            labels: get_labels(time_period, time_label),
            options: {
              ...prev?.holding_costs?.chart?.options,
              // xaxis: {
              //   ...prev?.holding_costs?.chart?.options?.xaxis,
              //   categories: time_period.map((item) => `${item} ${time_label}`),
              // },
            },
          },
        },
      }))
    }

    render_chart()
    return () => {}
  }, [
    calc?.sell?.values,
    calc?.sell?.options,
    calc?.sell?.data_options,
    calc?.holding_costs?.values,
    calc?.holding_costs?.defaults?.is_days,
    calc?.holding_costs?.defaults?.run,
    calc?.swiper,
  ])

  useEffect(() => {
    const save_data = async () => {
      if (type !== 'alone') {
        return
      }
      if (get_storage('vbc_calc') === 'Y') {
        return
      }
      saveCalc((prev) => ({ ...prev, share_loading: true }))
      const types = {}
      const values = {}
      Object.entries(calc?.sell?.types).forEach(([key, value]) => {
        if (key === 'sale_price' || key === 'loan_balance') {
          types[`${key}_is_type`] = value
        }
      })
      Object.entries(calc?.sell?.values).forEach(([key, value]) => {
        if (key === 'sale_price' || key === 'loan_balance') {
          if (['%', '$', 'm', 'a']?.includes(calc?.sell?.types?.[key])) {
            types[key] = validate_number(value)
          } else {
            types[key] = value
          }
        }
      })
      const shortener = {
        interest_rate: 'int_rate',
        insurance_rate: 'ins_rate',
        down_payment: 'down',
        utilities: 'uti',
        loan_amount: 'amount',
        loan_start_date: 'start_date',
        loan_end_date: 'end_date',
      }
      Object.entries(calc?.holding_costs?.types).forEach(([key, value]) => {
        types[`hold_${shortener?.[key] || key}_is_type`] = value
      })
      Object.entries(calc?.holding_costs?.values).forEach(([key, value]) => {
        if (['%', '$', 'm', 'a']?.includes(calc?.holding_costs?.types?.[key])) {
          values[`hold_${key}`] = validate_number(value)
        } else {
          values[`hold_${key}`] = value
        }
      })
      Object.entries(calc?.loan_balance?.types).forEach(([key, value]) => {
        types[`lbal_${shortener?.[key] || key}_is_type`] = value
      })
      Object.entries(calc?.loan_balance?.values).forEach(([key, value]) => {
        if (['%', '$', 'm', 'a']?.includes(calc?.loan_balance?.types?.[key])) {
          values[`lbal_${shortener?.[key] || key}`] = validate_number(value)
        } else {
          values[`lbal_${shortener?.[key] || key}`] = value
        }
      })
      values.lbal_is_home_price =
        calc?.loan_balance?.defaults?.is_home_price || 'Y'

      const series = calc?.sell?.net_at_close_chart?.series
      const get_chart_value = (key) => {
        return series[key]
      }
      const chart_values = [
        'balance_total',
        'prorated_tax_owed_total',
        'all_closing_costs',
        // 'net_at_close_total',
      ]
      chart_values?.forEach((key, index) => {
        if (index > 0) {
          values[key] = validate_number(get_chart_value(index), 2)
        }
      })
      values.net_at_close_total = validate_number(
        calc?.sell?.data_options?.net_at_close
      )
      values.hold_is_days = calc?.holding_costs?.defaults?.is_days || 'Y'
      const holding_chart = calc?.holding_costs?.chart
      const monthly_series = holding_chart?.monthly_series
      const daily_series = holding_chart?.daily_series
      const monthly_period = holding_chart?.monthly_period
      const daily_period = holding_chart?.daily_period

      monthly_series?.forEach((item) => {
        const label = item?.label?.toLowerCase()
        item?.data?.forEach((point, index) => {
          values[`hold_${label}_${monthly_period[index]}_months`] = point
          const total_key = `hold_${monthly_period[index]}_months_total`
          if (!values?.[total_key]) {
            values[total_key] = 0
          }
          values[total_key] += point
        })
      })
      daily_series?.forEach((item) => {
        const label = item?.label?.toLowerCase()
        item?.data?.forEach((point, index) => {
          values[`hold_${label}_${daily_period[index]}_days`] = point
          const total_key = `hold_${daily_period[index]}_days_total`
          if (!values?.[total_key]) {
            values[total_key] = 0
          }
          values[total_key] += point
        })
      })

      const data = {
        values: {
          ...types,
          ...values,
          instance_uuid: calc?.holding_costs?.data_options?.instance_uuid,
          calc_type: get_calc_type_id('holding_costs'),
          address: calc?.formatted_address || null,
          mls_id: calc?.mls_id || null,
        },
      }

      const current_hist = calc?.axiom_calc_hist?.find(
        (item) =>
          item.instance_uuid ===
          calc?.holding_costs?.data_options?.instance_uuid
      )

      saveCalc((prev) => ({
        ...prev,
        axiom_calc_hist: [
          ...(prev?.axiom_calc_hist?.filter(
            (item) =>
              item.instance_uuid !==
              calc?.holding_costs?.data_options?.instance_uuid
          ) || []),
          {
            ...current_hist,
            ...data?.values,
            upd_dt: new Date()?.toISOString(),
          },
        ],
      }))
      await axiomFetch('/calculators/save', 'POST', data, {}, false, null, 700)
      saveCalc((prev) => ({ ...prev, share_loading: false }))
    }
    if (calc?.holding_costs?.data_options?.calculated) {
      save_data()
    }
  }, [
    calc?.sell?.values,
    calc?.holding_costs?.values,
    calc?.holding_costs?.chart?.series,
    calc?.holding_costs?.data_options?.calculated,
    calc?.holding_costs?.data_options?.run,
    calc?.loan_balance?.values,
  ])

  const get_share_calc_type = (view_type, implementation_type) => {
    if (view_type == 'holding_costs' || implementation_type === 'alone') {
      return 'holding_costs'
    } else if (view_type === 'offers' || implementation_type === 'alone') {
      return 'offers'
    } else {
      return 'sell'
    }
  }

  return (
    <div>
      {calc?.options?.view_type === 'holding_costs' || type === 'alone' ? (
        <>
          {(type === 'alone' &&
            calc?.holding_costs?.data_options?.calculated &&
            calc?.sell?.values?.sale_price) ||
          type !== 'alone' ? (
            <div className={`${type === 'alone' ? '' : 'mt-[10px]'}`}>
              {type === 'alone' ? (
                <CalcShare
                  calculator_type={get_share_calc_type(
                    calc?.options?.view_type,
                    type
                  )}
                  implementation_type={
                    type === 'alone' ? 'holding_costs' : 'sell'
                  }
                />
              ) : (
                ''
              )}
              <div className='px-3'>
                <p className='font-bold text-[18px] text-center uppercase'>
                  sell{' '}
                  <span
                    className={`${uiStyles.blue_grd_text} text-[18px] font-bold`}
                  >
                    fast
                  </span>{' '}
                  pay {''}
                  <span
                    className={`${uiStyles.green_grd_text} text-[18px] font-bold`}
                  >
                    less
                  </span>
                  !
                </p>
                <p className='text-xs text-center mt-[10px]'>
                  Strategically pricing your home for sale can lead to
                  significant long-term savings. Expenses like property taxes,
                  hazard insurance, and mortgage interest represent costs that
                  are spent without the possibility of recouping.
                </p>
                <p className='text-center my-[10px]'>
                  Asking Price -{' '}
                  <span className='font-bold'>
                    {`$${format_currency(
                      calc?.sell?.values?.sale_price,
                      false,
                      2
                    ).replace('$', '')}`}
                  </span>
                </p>
              </div>
              <div>
                {' '}
                {/* <Chart
          height={300}
          options={calc?.holding_costs?.chart?.options || {}}
          series={calc?.holding_costs?.chart?.series || []}
          type='bar'
        /> */}
                <div className='h-[300px]'>
                  <Bar
                    options={{
                      plugins: {
                        title: {
                          display: false,
                          text: 'Chart.js Bar Chart - Stacked',
                        },
                        legend: {
                          display: false,
                        },
                        datalabels: {
                          display: true,
                          color: '#f1f1f1',
                          font: {
                            weight: 'bold',
                            size: 10,
                          },
                          formatter: function (value, context) {
                            return [context.dataset.label]
                          },
                        },
                      },
                      legend: {
                        display: false,
                      },
                      maintainAspectRatio: false,
                      responsive: true,
                      // layout: {
                      //   padding: 10,
                      // },
                      scales: {
                        x: {
                          stacked: true,
                        },
                        y: {
                          display: false,
                          stacked: true,
                        },
                      },
                    }}
                    data={{
                      labels: calc?.holding_costs?.chart?.labels,
                      datasets: calc?.holding_costs?.chart?.series,
                    }}
                  ></Bar>
                </div>
              </div>
              <div className='flex justify-end mr-[100px] pr-[10px] mb-[10px] w-[310px] text-sm mt-[20px]'>
                <CircleCheckToggle
                  name={`is_days`}
                  toggle_left_label='Months'
                  toggle_right_label='Days'
                  type='toggle'
                  inputValue={calc?.['holding_costs']?.defaults}
                  handle_change={async (e) => {
                    saveCalc((prev) => {
                      return {
                        ...prev,
                        ['holding_costs']: {
                          ...prev?.['holding_costs'],

                          defaults: {
                            ...prev?.['holding_costs']?.defaults,
                            run: (prev?.holding_costs?.defaults?.run || 0) + 1,
                            ['is_days']:
                              prev?.['holding_costs']?.defaults?.is_days === 'N'
                                ? 'Y'
                                : 'N',
                          },
                        },
                      }
                    })
                  }}
                />
              </div>
            </div>
          ) : (
            ''
          )}
          <div className='px-3 mt-[10px] pb-[10px]'>
            {type === 'alone' ? (
              <>
                <LabelInput
                  left_label={'Asking Price'}
                  left_label_width={'w-[80%]'}
                  name={'sale_price'}
                  show_dollar={true}
                  show_delete_all={true}
                  value={calc?.sell?.values?.sale_price || ''}
                  percent_value='%'
                  handleChange={(e, type) => {
                    update_input({
                      e,
                      type,
                      decimal: 2,
                      original: calc?.sell?.values?.sale_price,
                    })
                  }}
                  onKeyUp={(e) => {
                    // update_input({ e, type, decimal: 2, original: value })
                    if (e.target.value && e.key === 'Enter') {
                      calculate_estimate()
                    }
                  }}
                  placeholder='Required'
                />
                <div className='relative'>
                  <LabelInput
                    left_label={'Loan Balance'}
                    left_label_width={'w-[80%]'}
                    name={'loan_balance'}
                    show_dollar={true}
                    show_delete_all={true}
                    delete_all_position='right-[40px]'
                    value={calc?.sell?.values?.loan_balance || ''}
                    percent_value='%'
                    handleChange={(e, type) => {
                      update_input({
                        e,
                        type,
                        decimal: 2,
                        original: calc?.sell?.values?.loan_balance,
                      })
                    }}
                    placeholder=''
                  />
                  <div
                    className='absolute top-[15px] right-[8px] cursor-pointer'
                    onClick={() => {
                      setModalType('loan_balance')
                      setShowModal(true)
                    }}
                  >
                    <FaCalculator />
                  </div>
                </div>
              </>
            ) : (
              ''
            )}
            {get_inputs('holding_costs')}
          </div>
          {type === 'alone' ? (
            <div className='flex justify-center w-full'>
              {error?.show_error ? (
                <div className={`${uiStyles.failure_msg} mb-[10px]`}>
                  {error?.msg}
                </div>
              ) : (
                <button
                  className={`${uiStyles.hover_btn} w-full rounded-none`}
                  onClick={() => {
                    calculate_estimate()
                  }}
                >
                  Calculate
                </button>
              )}
            </div>
          ) : (
            ''
          )}
          {showModal ? (
            <Modal
              side_padding={'px-0'}
              modalName={modalType}
              height={modals?.[modalType]?.height || 'h-[550px]'}
              width={'w-full'}
              showModal={showModal}
              setShowModal={(type) => {
                setShowModal(false)
                if (modalType === 'net_at_close' || type === 'net_at_close') {
                  saveCalc((prev) => ({
                    ...prev,
                    sell: {
                      ...prev?.sell,
                      data_options: {
                        ...prev?.sell?.data_options,
                        calculated: true,
                      },
                    },
                  }))
                }
              }}
              showClose={true}
            >
              {modals?.[modalType]?.component || <></>}
            </Modal>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
    </div>
  )
}

export default CalcSellHold
