import React, { memo } from 'react';

function PrimaryButton({
  children,
  clickHandler = () => {},
  isSelected = false,
  isDisabled = false,
}) {
  return (
    <span
      className={`inline-block cursor-pointer font-semibold text-sm text-white bg-gradient-to-b ${
        isSelected
          ? 'from-accent-150 to-accent-250'
          : isDisabled
          ? 'from-gray-500 to-gray-500'
          : 'from-accent-100 to-accent-600'
      } px-6 py-2.5 rounded-lg ${
        isDisabled
          ? 'from-gray-500 to-gray-500'
          : 'hover:from-accent-150 hover:to-accent-250'
      }`}
      onClick={clickHandler}
    >
      {children}
    </span>
  );
}

export default memo(PrimaryButton);
