import { useState } from 'react'
import { GrNext, GrPrevious } from 'react-icons/gr'
import { MdAdsClick } from 'react-icons/md'
import { Link } from 'react-router-dom'
import images_homes from '../../assets/images/home'
import { capitalize, get_background_color } from '../../helpers'
import { FilterList } from '../helpers'
import Modal from '../Modal'
import MarketingMaterialFilter from '../pages/marketingMaterials/MarketingMaterialFilter'
import StarRating from '../pages/VBCDetails/StarRating'
import PDFPreview from '../PDFPreview'
// import ProgressBar from './ProgressBar'

function ListItem({
  titleFirst = false,
  image,
  image_flag = '',
  image_flag_color = '',
  progress,
  title,
  hex_code = '',
  subtitle,
  content,
  content_link = '',
  content_link_name = '',
  sub_content,
  sub_content_gap,
  link,
  job_id,
  quantity,
  status_text,
  orderDate,
  toggle,
  setToggle,
  index,
  preview,
  fill,
  notifications,
  swiper,
  click_me = false,
  select_on_image = false,
  show_swipe_btns = true,
  select = () => {},
  star_rating,
  filter_type,
  show_filter = false,
  filter_list = [],
  filter_strategy = {},
  current_item = {},
  data_cy = '',
}) {
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('')

  const modals = {
    material_filter: {
      component: (
        <FilterList
          showModal={showModal}
          setShowModal={setShowModal}
          list={filter_list}
          filter_strategy={filter_strategy}
          current_item={current_item}
        />
      ),
      width: 'w-[340px]',
      // height: 'h-[550px]',
    },
  }

  const titleAbove = (
    <>
      {title && (
        <h3 className='swipeable text-gray-800 text-xs font-base font-semibold mb-2'>
          {link ? <Link to={link}>{title}</Link> : title}
        </h3>
      )}
      <div className='swipeable flex'>
        {progress && (
          <div className='swipeable flex justify-center items-center mr-[20px]'>
            <div className='swipeable w-[50px]'>{progress}</div>
          </div>
        )}
        {image && (
          <div className='swipeable w-32'>
            <img
              src={image}
              alt={title}
              className='swipeable rounded-md min-h-[75px] object-cover'
            />
          </div>
        )}
        <div className='swipeable flex-shrink-0 flex-1'>
          {content && (
            <div className='swipeable .scrollbar-hide text-[11px] leading-[13px] text-gray-500 font-base font-extralight'>
              {content}
            </div>
          )}

          {sub_content && (
            <div className='swipeable .scrollbar-hide text-[11px] leading-[13px] text-gray-500 font-base font-extralight'>
              {sub_content}
            </div>
          )}
        </div>
      </div>
    </>
  )
  const titleSide = (
    <>
      <div className='swipeable w-full flex justify-between items-center'>
        {swiper && show_swipe_btns && swiper?.realIndex > 0 ? (
          <div
            className='cursor-pointer'
            onClick={() => {
              swiper.slidePrev(300)
            }}
          >
            <GrPrevious size={20} color={'black'} />
          </div>
        ) : swiper && show_swipe_btns ? (
          <div className='opacity-0'>
            {' '}
            <GrPrevious size={20} color={'black'} />
          </div>
        ) : (
          ''
        )}
        <div className='flex flex-col'>
          <div className='flex'>
            <div className='swipeable flex'>
              {progress && (
                <div className='w-[50px] pl-2 pr-2 self-center mr-[20px]'>
                  {progress}
                </div>
              )}
              {image && (
                <div className='relative'>
                  <div className='absolute z-20 right-0 mt-[-5px] mr-[-5px]'>
                    {notifications && notifications}
                  </div>
                  {image_flag ? (
                    <div
                      className={` w-[100px] opacity-[0.95] text-center absolute z-20 left-1/2 top-0 -translate-x-1/2 text-white ${
                        image_flag_color || 'bg-blue-500'
                      } py-[0px] rounded-b-lg text-[11px]`}
                    >
                      <p className='font-bold p-0 m-0'>{image_flag}</p>
                    </div>
                  ) : (
                    ''
                  )}
                  {click_me ? (
                    <div className='absolute z-20 right-0 mt-[-5px] mr-[-5px]'>
                      {<MdAdsClick />}
                    </div>
                  ) : (
                    ''
                  )}
                  <div
                    className={`swipeable relative p-1 ${
                      select_on_image || click_me ? 'cursor-pointer' : ''
                    }`}
                    onClick={select}
                    data-cy={data_cy}
                  >
                    <PDFPreview
                      fill={fill}
                      index={index}
                      toggle={toggle}
                      setToggle={setToggle}
                      image={image}
                      preview={preview}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className='swipeable flex-shrink-0 flex-1 pl-2'>
              {title && (
                <h3
                  className={`swipeable text-gray-800 ${
                    subtitle ? 'text-sm' : 'text-xs'
                  } font-base font-semibold ${get_background_color(
                    hex_code,
                    'bg-gray-100',
                    'bg-slate-900'
                  )}`}
                  style={{ color: hex_code ? hex_code?.replace(';', '') : '' }}
                >
                  {link ? <Link to={link}>{title}</Link> : title}{' '}
                  {content_link && (
                    <a
                      target='_blank'
                      href={content_link}
                      className='swipeable .scrollbar-hide text-[12px] ml-[2px] text-accent-600 font-base underline'
                    >
                      {content_link_name}
                    </a>
                  )}
                </h3>
              )}
              {subtitle && (
                <h2 className='swipeable text-gray-800 text-xs font-base font-semibold'>
                  {subtitle}
                </h2>
              )}
              {star_rating && (
                <h2 className='swipeable text-gray-800 text-xs font-base font-semibold'>
                  <StarRating label={''} rating={star_rating} display={true} />
                </h2>
              )}
              <div className='swipeable flex flex-col'>
                {content && (
                  <div className='swipeable text-xs text-gray-500 font-base font-extralight max-h-[50px] overflow-y-scroll'>
                    {content}
                  </div>
                )}

                {sub_content && (
                  <div
                    className={`${
                      sub_content_gap || 'mt-[2px]'
                    } swipeable text-[13px] leading-[13px] text-gray-500 font-base font-extralight`}
                  >
                    {sub_content}
                  </div>
                )}
              </div>
              {orderDate && (
                <div className='swipeable text-xs text-gray-500 font-base font-extralight'>
                  Order Date: {orderDate?.split('T')[0]}
                </div>
              )}
              {job_id && (
                <div className='swipeable text-xs text-gray-500 font-base font-extralight'>
                  Job # {job_id}
                </div>
              )}
              {quantity && (
                <div className='swipeable text-xs text-gray-500 font-base font-extralight'>
                  Quantity: {quantity}
                </div>
              )}
              {status_text && (
                <div className='swipeable text-xs text-gray-500 font-base font-extralight'>
                  Status: {status_text}
                </div>
              )}
            </div>
            {show_filter ? (
              <div className=' cursor-pointer absolute right-[10px] bottom-[10px]'>
                <div
                  onClick={() => {
                    setShowModal(true)
                    setModalType(filter_type)
                  }}
                >
                  <img src={images_homes.filter} className={`w-[20px]`} />
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          {/* {select ? select : ''} */}
        </div>
        {swiper &&
        show_swipe_btns &&
        swiper?.realIndex < swiper?.slides?.length - 1 ? (
          <div
            className='cursor-pointer'
            onClick={() => {
              swiper.slideNext(300)
            }}
          >
            <GrNext size={20} color={'black'} />
          </div>
        ) : swiper && show_swipe_btns ? (
          <div className='opacity-0'>
            {' '}
            <GrNext size={20} color={'black'} />
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  )

  return (
    <div
      className={`swipeable relative ${!titleFirst && 'flex'} p-2 bg-gray-100`}
    >
      {titleFirst ? titleAbove : titleSide}
      {showModal ? (
        <Modal
          side_padding={'px-0'}
          modalName={modalType}
          height={modals?.[modalType]?.height || 'h-[550px]'}
          width={modals?.[modalType]?.width || 'w-[345px]'}
          showModal={showModal}
          setShowModal={setShowModal}
          showClose={true}
        >
          {modals?.[modalType || 'material_filter']?.component || <></>}
        </Modal>
      ) : (
        ''
      )}
    </div>
  )
}

export default ListItem
