import React from 'react';
import Document from '../../assets/images/documents.jpg';
import AxiomAlertOIcon from './AxiomAlertOIcon';
export default function DocumentItem({
  documentFile = null,
  buttonLabel = 'View Document',
  clickHandler = () => {},
  className,
}) {
  return (
    <div className={`relative rounded-md overflow-hidden ${className}`}>
      {
      documentFile ? <iframe src={documentFile} loading="lazy" height="150" width="100%"></iframe> : <img src={Document}
      />
       }
      <div className="overlay bg-black/10 absolute left-0 top-0 w-full h-full"></div>
      {/* <div className="absolute right-3 top-2  z-20">
        <AxiomAlertOIcon />
      </div> */}
      <div
        onClick={() => clickHandler()}
        className=" capitalize z-20 bg-white/90 cursor-pointer py-3 px-4 rounded-md absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2"
      >
        {buttonLabel}
      </div>
    </div>
  );
}
