import { useState, useEffect } from 'react'
import LabelInput from '../../../components/form/LabelInput'
import uiStyles from '../../ui/uiStyles'
import MLSSearch from '../TransactionsLobby/MLSSearch'
import MLSView from '../TransactionsLobby/MLSView'
import { useAi } from '../../../contexts/AiContext'
export const AiMls = ({ type = 'advanced' }) => {
  const { ai, saveAi } = useAi()
  const [state, setState] = useState({})
  const [mlsSearch, setMlsSearch] = useState('')
  const [mlsMatch, setMlsMatch] = useState('')
  const [mlsView, setMlsView] = useState('')
  const handle_values = (e) => {
    setState((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }
  const handle_cancel = (e) => {}

  useEffect(() => {
    let mls = { mlsMatch, setting: mlsView, search: mlsSearch }
    if (mlsView === 'mlsConfirm') {
      saveAi((prev) => ({
        ...prev,
        mls: {
          ...prev?.mls,
          setting: 'mlsYes',
          add_mls_to_prompt: (prev?.mls?.add_mls_to_prompt || 0) + 1,
        },
        slot: { ...prev?.slot, AI_GO_PROP: 'N' },
      }))
    } else if (mlsView === 'openEscrow') {
      saveAi((prev) => ({
        ...prev,
        mls: {
          ...prev?.mls,
          setting: 'mlsNo',
        },
        slot: { ...prev?.slot, AI_GO_PROP: 'Y' },
      }))
    }
  }, [mlsView])

  return (
    <div>
      {/* <p>Enter Your MLS number here</p> */}
      {/* <LabelInput
        placeholder={'Add the MLS #'}
        name={'mls_id'}
        value={state?.mls_id}
        handleChange={handle_values}
      /> */}
      {ai?.mls?.setting === 'mlsYes' || mlsView === 'mlsView' ? (
        <div className='mt-[20px]'>
          <MLSView
            show_cancel={true}
            handle_cancel={handle_cancel}
            mlsSearch={mlsSearch}
            setMlsSearch={setMlsSearch}
            mlsMatch={mlsMatch}
            setMlsMatch={setMlsMatch}
            modalType={mlsView}
            setModalType={setMlsView}
            searchTitle={''}
            type={type}
          />
        </div>
      ) : (
        <MLSSearch
          mlsSearch={mlsSearch}
          setMlsSearch={setMlsSearch}
          modalType={mlsView}
          setModalType={setMlsView}
          manualEntry={true}
          searchTitle={''}
          type={type}
        />
      )}
    </div>
  )
}
