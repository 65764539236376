export const SESSION_KEY = 'AUTH';

export const AUTH_CONFIG = {
  domain: 'dev-qidoybiua73f3sl6.us.auth0.com',
  clientId: 'Gix7KFR3jXaKdVT3x9a26DVbT1VKx98Z',
  audience: 'nqp9JOlpTEXvTY5Wjq4nnGHzvxQ2ArRQRB11Y37rDl-8tLts6JJw4EIw7gAHYbmx',
};

export const USERTYPE = {
  FREE: 'free agent',
  PROFESSIONAL: 'professional agent',
  ENTERPRISE: 'enterprise agents',
};

export const ROLES = {
  free: 'FREE',
  professional: 'PROFESSIONAL',
  enterprise: 'ENTERPRISE',
};

export const SCOPES = {
  canCreate: 'can-create',
  canEdit: 'can-edit',
  canDelete: 'can-delete',
  canView: 'can-view',
};

export const PERMISSIONS = {
  [ROLES.free]: [SCOPES.canView],
  [ROLES.professional]: [SCOPES.canView, SCOPES.canEdit],
  [ROLES.enterprise]: [
    SCOPES.canView,
    SCOPES.canEdit,
    SCOPES.canCreate,
    SCOPES.canDelete,
  ],
};
