import { useState } from 'react'
import { set_storage } from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import uiStyles from '../../ui/uiStyles'

const RemoveBuyer = ({ market_name, state, setState, setShowModal }) => {
  const { axiomFetch } = useAxiomFetch()
  const [loading, setLoading] = useState(false)

  return (
    <div>
      <div className='px-3 text-center mb-[10px]'>
        <p>Are you sure you want to remove </p>
        <p>{state?.buyer_tours?.buyer_edit?.agent_name}?</p>
      </div>
      <div className='flex justify-end gap-[5px]'>
        <button
          className={`${uiStyles.white_btn}`}
          onClick={() => {
            setShowModal(false)
          }}
        >
          Cancel
        </button>
        <button
          className={`${uiStyles.red_btn}`}
          onClick={async () => {
            setLoading(true)
            const result = await axiomFetch(
              '/tours/client/remove',
              'DELETE',
              {
                event_id:
                  state?.buyer_tours?.buyer_edit?.evt_id ||
                  state?.buyer_tours?.buyer_edit?.event_id,
                attribute_id: state?.buyer_tours?.event_edit?.event_attribute,
                remove_cont_id:
                  state?.buyer_tours?.buyer_edit?.cont_id ||
                  state?.buyer_tours?.buyer_edit?.add_cont_id,
              } || {}
            )
            setState((prev) => {
              const event_tour_info = [
                ...prev?.data?.event_tour_info?.filter(
                  (item) =>
                    !(
                      item.evt_id === prev?.buyer_tours?.buyer_edit?.evt_id &&
                      item.cont_id === prev?.buyer_tours?.buyer_edit?.cont_id
                    )
                ),
              ]

              set_storage('listing_tours', { ...prev?.data, event_tour_info })
              return {
                ...prev,
                data: { ...prev?.data, event_tour_info },
              }
            })
            setLoading(false)
            setShowModal(false)
          }}
        >
          Confirm
        </button>
      </div>
    </div>
  )
}

export default RemoveBuyer
