import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ListItem from '../../ui/ListItem'
import SwipeConfig from '../../ui/SwipeConfig'
import { DUMMYMARKETMATERIAL } from '../../../data/data'
import uiStyles from '../../ui/uiStyles'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import SmallLoading from '../../ui/SmallLoading'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import { useProfile } from '../../../contexts/UserContext'
import { get_storage, pick_keys, validate_number } from '../../../helpers'

export const MarketingMaterialLayouts = ({ state, setState, title }) => {
  const { axiomFetch, nodeProdFetch } = useAxiomFetch()
  const { profile } = useProfile()
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState(1)
  const navigate = useNavigate()
  const selection = state?.create_order?.current_selection
  const switch_layout = state?.create_order?.switch_layout
  const current_layout = state?.create_order?.current_layout_selection
  const sessions = state?.create_order?.sessions
  const global_theme = state?.materials?.mkt_req_layout_text?.[0]?.layout_text
  const topic = state?.create_order?.order_type
  const sub_topic =
    topic === 2
      ? state?.create_order?.sub_topic_property
      : state?.create_order?.sub_topic_promotion

  const layouts = state?.create_order?.current_selection?.layouts?.filter(
    (item) => item?.topic_subtype === selection?.topic_subtype
  )

  useEffect(() => {
    if (!state?.hit_create_page) {
      return navigate(
        get_storage('material_back') || '/user/marketing-material'
      )
    }
  }, [])

  const save_address = () => {
    let address = {}
    if (state?.create_order?.current_selection?.del_add1_p) {
      return
    }

    if (profile?.BR_ADD_LINE1) {
      address = {
        // eslint-disable-next-line
        ['del_add1_p']: profile?.BR_ADD_LINE1 || '',
        // eslint-disable-next-line
        ['del_add2_p']: profile?.BR_ADD_LINE2 || '',
        // eslint-disable-next-line
        ['del_city_p']: profile?.BR_ADD_CITY || '',
        // eslint-disable-next-line
        ['del_st_p']: profile?.BR_ADD_ST || '',
        // eslint-disable-next-line
        ['del_zip_p']: profile?.BR_ADD_POSTAL || '',
      }
    } else if (
      profile.account?.office_address1 ||
      profile?.account?.member_address1
    ) {
      address = {
        // eslint-disable-next-line
        ['del_add1_p']:
          profile?.account?.member_address1 ||
          profile?.account?.office_address1 ||
          '',
        // eslint-disable-next-line
        ['del_add2_p']:
          profile?.account?.member_address2 ||
          profile?.account?.office_address2 ||
          '',
        // eslint-disable-next-line
        ['del_city_p']:
          profile?.account?.member_city || profile?.account?.office_city || '',
        // eslint-disable-next-line
        ['del_st_p']:
          profile?.account?.member_state_or_province ||
          profile?.account?.office_state_or_province ||
          '',
        // eslint-disable-next-line
        ['del_zip_p']:
          profile?.account?.member_postal_code ||
          profile?.account?.office_postal_code ||
          '',
      }
    } else {
      address = {
        // eslint-disable-next-line
        ['del_add1_p']: '',
        // eslint-disable-next-line
        ['del_add2_p']: '',
        // eslint-disable-next-line
        ['del_city_p']: '',
        // eslint-disable-next-line
        ['del_st_p']: '',
        // eslint-disable-next-line
        ['del_zip_p']: '',
      }
    }

    const save_selection = (key, value) => {
      setState((prev) => ({
        ...prev,
        create_order: {
          ...prev?.create_order,
          current_selection: {
            ...prev?.create_order?.current_selection,
            [key]: value,
          },
        },
      }))
    }

    setState((prev) => ({
      ...prev,
      create_order: {
        ...prev?.create_order,
        current_selection: {
          ...prev?.create_order?.current_selection,
          ...address,
        },
      },
    }))
  }

  const save_selection = (key, value) => {
    setState((prev) => ({
      ...prev,
      create_order: {
        ...prev?.create_order,
        current_selection: {
          ...prev?.create_order?.current_selection,
          [key]: value,
        },
        session: {
          ...prev?.create_order?.session,
          [key]: validate_number(value),
        },
        sessions: [
          ...prev?.create_order?.sessions?.filter(
            (item) => item?.sess_id !== prev?.create_order?.session?.sess_id
          ),
          { ...prev?.create_order?.session, [key]: validate_number(value) },
        ],
      },
    }))
  }

  const gen_pdf = async () => {
    try {
      const replace_fields = async (html, sess_info) => {
        for (const field of sess_info) {
          if (field?.tag_name === '{{holidy_overlay_area}}') {
            html = html.replaceAll(
              field?.tag_name,
              selection?.theme_html || global_theme || ''
            )
          } else {
            let new_field = field?.saved_val || ''
            if (field?.image_field === 'Y' && new_field) {
              if (
                new_field !==
                'https://driggstitle.com/reviews/Ax_Engage/dbc/t1.png'
              ) {
                new_field = new_field
                // await compress_img(new_field)
              }
            }
            html = html?.replaceAll(field?.tag_name, new_field)
          }
        }
        return html
      }
      const get_layout = (id) => {
        const layout = state?.materials?.mkt_req_layout?.find(
          (layout_item) =>
            layout_item?.layout_id === id &&
            !layout_item?.layout_name?.toLowerCase()?.includes('test')
        )
        return layout
      }
      let htmls = []
      const add_sess = async (sess) => {
        let these_htmls = []
        const main_obj = {
          layout_id: sess?.layout_id,
          media_type: sess?.calc_type_id ? 'print' : 'screen',
          page_ranges: sess?.calc_type_id ? 'all' : '',
          height: get_layout(sess?.layout_id)?.height_pix || 1080,
          prt_height: get_layout(sess?.layout_id)?.prt_height || 11.25,
          width: get_layout(sess?.layout_id)?.width_pix || 1080,
          prt_width: get_layout(sess?.layout_id)?.prt_width || 8.75,
          dpi: get_layout(sess?.layout_id)?.prt_dpi || 300,
          layout_name:
            get_layout(sess?.layout_id)?.layout_name_alias ||
            get_layout(sess?.layout_id)?.layout_name,
          file_name: (
            get_layout(sess?.layout_id)?.layout_name_alias ||
            get_layout(sess?.layout_id)?.layout_name
          )
            ?.split(' ')
            ?.join('_'),
          html: await replace_fields(sess?.layout_text, sess?.sess_info),
          pages: [0, 0],
        }
        these_htmls.push({
          ...main_obj,
        })
        if (sess?.layout_text_back) {
          these_htmls.push({
            ...main_obj,
            html: await replace_fields(sess?.layout_text_back, sess?.sess_info),
          })
        }
        if (sess?.pdf_guide_url) {
          these_htmls.push({
            ...main_obj,
            html: '',
            pdf_guide_url: sess?.pdf_guide_url,
            pages: [1],
          })
        }
        if (sess?.prepend_pdf_url) {
          these_htmls.unshift({
            ...main_obj,
            html: '',
            pdf_guide_url: sess?.prepend_pdf_url,
            pages: [0],
          })
        }
        if (sess?.append_pdf_url) {
          these_htmls.push({
            ...main_obj,
            html: '',
            pdf_guide_url: sess?.append_pdf_url,
            pages: [0],
          })
        }
        htmls.push(these_htmls)
      }

      for (const sess of sessions) {
        await add_sess(sess)
      }

      const urls = await Promise.all([
        ...htmls?.map(async (html) => {
          let url = ''
          if (html?.length > 1) {
            const side_urls = await Promise?.all(
              html?.map(async (side_html) => {
                if (side_html?.pdf_guide_url) {
                  return side_html?.pdf_guide_url
                } else {
                  const pdf = await nodeProdFetch({
                    url: '/node_to_oracle/api/v1/pdf/create',
                    method: 'POST',
                    body: {
                      ...side_html,
                      height: side_html?.prt_height || 11.25,
                      width: side_html?.prt_width || 8.75,
                      dpi: side_html?.dpi || 300,
                    },
                  })
                  side_html.pdf_url = pdf
                  return pdf
                }
              })
            )
            url = await nodeProdFetch({
              url: '/node_to_oracle/api/v1/pdf/merge',
              method: 'POST',
              body: {
                urls: side_urls,
                file_name: html?.[0]?.layout_name,
                pages: html?.map((item) => item.pages),
              },
            })
          } else {
            url = await nodeProdFetch({
              url: '/node_to_oracle/api/v1/pdf/create',
              method: 'POST',
              body: {
                ...html[0],
                height: html?.[0]?.prt_height || 11.25,
                width: html?.[0]?.prt_width || 8.75,
                dpi: html?.[0]?.dpi || 300,
                // height: html?.[0]?.prt_height || 8.5,
                // width: html?.[0]?.prt_width || 11,
                // dpi: html?.[0]?.prt_dpi || 300,
              },
            })
          }
          html[0].pdf_url = url
          return url
        }),

        ...htmls
          ?.flat()
          ?.filter((item) => item?.html)
          ?.map(async (html) => {
            const url = await nodeProdFetch({
              url: '/node_to_oracle/api/v1/pdf/create',
              method: 'POST',
              body: {
                ...html,
                type: 'png',
              },
            })
            html.pdf_url = url
            return url
          }),
      ])

      let final_url = ''

      if (
        urls
          ?.flat()
          ?.filter((item) => typeof item === 'string')
          ?.filter((item) => item?.toString()?.includes('pdf'))?.length > 1
      ) {
        final_url = await nodeProdFetch({
          url: '/node_to_oracle/api/v1/pdf/merge',
          method: 'POST',
          body: {
            urls: urls
              ?.filter((item) => typeof item === 'string')
              ?.filter((item) => item?.includes('pdf')),
            file_name: 'all_materials',
          },
        })
      } else {
        final_url = urls
          ?.filter((item) => typeof item === 'string')
          .filter((item) => item?.includes('pdf'))?.[0]
      }

      // htmls = await Promise.all(
      //   htmls?.map(async (html) => {
      //     if (html?.length > 1) {
      //       const pdf_urls = html
      //         ?.filter((item) => typeof item?.pdf_url === 'string')
      //         ?.filter((item) => item?.pdf_url?.includes('pdf'))
      //         ?.map((item) => item?.pdf_url)
      //       const grouped_pdf_url = await nodeProdFetch({
      //         url: '/node_to_oracle/api/v1/pdf/merge',
      //         method: 'POST',
      //         body: {
      //           urls: pdf_urls,
      //           file_name: html?.[0]?.file_name,
      //         },
      //       })
      //       html[0].pdf_url = grouped_pdf_url
      //       return html?.[0] || {}
      //     }
      //     return html
      //   })
      // )
      const pdf_sessions = sessions.map((sess_item) => {
        const pdf_url = htmls
          ?.flat()
          ?.find((html) => html?.layout_id === sess_item?.layout_id)?.pdf_url
        return {
          // ...selection,
          ...pick_keys(sess_item, ['layout_text', 'layouts']),
          pdf_url: encodeURI(pdf_url?.replace('[object Object]', '')),
        }
      })

      await axiomFetch('/marketing/materials/flyer/submit', 'POST', {
        sessions: pdf_sessions,
        create_request: false,
      })

      return {
        pdf_url: final_url,
        pdf_urls: [
          ...urls?.filter(
            (item) => typeof item === 'string' && item?.includes('pdf')
          ),
        ],
        png_urls: [
          ...urls?.filter(
            (item) => typeof item === 'string' && !item?.includes('pdf')
          ),
        ],
      }
    } catch (error) {
      console.log(error)
      return ''
    }
  }

  const generate_container = async () => {
    setLoading(true)
    const body = {
      mls_id:
        state?.create_order?.order_type === 2
          ? state?.current_listing?.mls_id
          : 0,
      container_id: selection?.container_id || selection?.contain_id,
    }
    let sessions = await axiomFetch(
      '/marketing/materials/container',
      'POST',
      body
    )
    sessions = await Promise.all(
      sessions.map(async (item) => {
        let session = { sess_info: [] }
        const get_session = async () => {
          let try_count = 0
          session = await axiomFetch(
            `/marketing/materials/${switch_layout ? 'flyer/switch' : 'flyer'}`,
            'POST',
            {
              layout_id: item?.layout_id,
              mls_id:
                state?.create_order?.order_type === 2
                  ? state?.current_listing?.mls_id
                  : 0,
              sess_id: state?.create_order?.session?.sess_id,
              sess_info: state?.create_order?.session?.sess_info,
              double_sided: item?.double_sided === 'Y' ? true : false,
            }
          )

          if (
            session?.sess_info?.some((item) =>
              item?.saved_val?.includes('Unexpected token')
            ) &&
            try_count < 3
          ) {
            try_count++
            return await get_session()
          } else {
            return session
          }
        }
        session = await get_session()
        item.layout_text = session.layout_text
        item.layout_text_theme = session.layout_text_theme
        if (session.layout_text_back) {
          item.layout_text_back = session.layout_text_back
        }
        item.sess_info = session.sess_info
        if (session?.sess_info) {
          session.sess_info = session?.sess_info?.map((item) => {
            if (item?.orig_val === 'None') {
              item.orig_val = ''
              item.saved_val = ''
            }
            return item
          })
        }
        if (
          session?.sess_info
            ?.filter(
              (item) =>
                item?.field_id !== 30064 &&
                item?.field_id !== 55779 &&
                item?.allow_edit === 'Y'
            )
            ?.some((this_item) => !this_item.saved_val)
        ) {
          item.empty_fields = true
          item.edit_fields_type = 'data'
        }
        return item
      })
    )

    sessions = sessions.filter((item) => {
      const layout = state?.materials?.mkt_req_layout?.find(
        (layout_item) => layout_item?.layout_id === item.layout_id
      )
      return layout?.topic_subtype === sub_topic
    })
    setState((prev) => ({
      ...prev,
      create_order: {
        ...prev?.create_order,
        current_layout_selection: selection?.layouts?.find(
          (item) => (item.layout_id = sessions?.[0]?.layout_id)
        ),
        sessions,
        session: sessions[0],
        // empty_fields,
        // edit_fields_type,
        switch_layout: false,
        selected_fields: '',
      },
    }))
    setLoading(false)
    return navigate('customize')
  }

  const rightConfig = (item) => () => {
    return (
      <span
        className='text-md cursor-pointer'
        onClick={async () => {
          setLoading(true)
          let session = { sess_info: [] }
          const get_session = async () => {
            let try_count = 0
            session = await axiomFetch(
              `/marketing/materials/${
                switch_layout ? 'flyer/switch' : 'flyer'
              }`,
              'POST',
              {
                layout_id: item?.layout_id,
                mls_id:
                  state?.create_order?.order_type === 2
                    ? state?.current_listing?.mls_id
                    : 0,
                sess_id: state?.create_order?.session?.sess_id,
                sess_info: state?.create_order?.session?.sess_info,
                double_sided: item?.double_sided === 'Y' ? true : false,
              }
            )

            if (
              session?.sess_info?.some((item) =>
                item?.saved_val?.includes('Unexpected token')
              ) &&
              try_count < 3
            ) {
              try_count++
              return await get_session()
            } else {
              return session
            }
          }
          session = await get_session()
          if (session?.sess_info) {
            session.sess_info = session?.sess_info?.map((item) => {
              if (item?.orig_val === 'None') {
                item.orig_val = ''
                item.saved_val = ''
              }
              return item
            })
          }
          if (
            session?.sess_info
              ?.filter(
                (item) =>
                  item?.field_id !== 30064 &&
                  item?.field_id !== 55779 &&
                  item?.allow_edit === 'Y'
              )
              ?.some((item) => !item.saved_val)
          ) {
            session.empty_fields = true
            session.edit_fields_type = 'data'
          }
          setState((prev) => ({
            ...prev,
            create_order: {
              ...prev?.create_order,
              current_layout_selection: item,
              session,
              sessions: [{ ...session }],
              switch_layout: false,
              selected_fields: '',
            },
          }))
          setLoading(false)
          navigate('customize')
        }}
      >
        Select
      </span>
    )
  }
  let count = -1

  useEffect(() => {
    if (layouts?.length === 1) {
      navigate('/user/marketing-material')
    }
  }, [])

  return (
    <div>
      <div className='flex flex-col items-center gap-[10px]'>
        <div className='flex text-center'>
          <p
            className={`${uiStyles.text_black_title} capitalize text-center mb-[15px]`}
          >
            {`${title || 'Marketing Materials'}`}
          </p>
        </div>
      </div>
      {loading ? (
        <SmallLoadingRel />
      ) : (
        <div>
          {switch_layout ? (
            <div className='flex items-center flex-col mb-[10px]'>
              <button
                className={uiStyles.hover_btn}
                onClick={async () => {
                  setLoading(true)
                  save_address()
                  save_selection('status_p', 9)
                  const urls = await gen_pdf()
                  save_selection('pdf_url', urls?.pdf_url || '')
                  save_selection('pdf_urls', urls?.pdf_urls || [])
                  save_selection('png_urls', urls?.png_urls || [])
                  setLoading(false)
                  return navigate('/user/marketing-material/layouts/confirm')
                }}
              >
                Custom Order
              </button>{' '}
              {/* <p>Or</p>{' '}
              <p className={uiStyles.text_black_subtitle}>
                Select a new Layout
              </p> */}
            </div>
          ) : (
            ''
          )}
          {/* {selection?.contain_id && selection?.layouts?.length > 1 ? (
            <div className='flex items-center flex-col mb-[10px]'>
              <button
                className={uiStyles.hover_btn}
                onClick={generate_container}
              >
                Create All
              </button>{' '}
              <p>Or</p>{' '}
              <p className={uiStyles.text_black_subtitle}>Select a Layout</p>
            </div>
          ) : (
            ''
          )} */}
          {switch_layout ? (
            ''
          ) : (
            <div className='max-h-[550px] overflow-y-scroll'>
              {layouts?.length ? (
                <>
                  {layouts?.map((item, index) => {
                    count++
                    if (count > 5) {
                      count = 0
                    }
                    return (
                      <div
                        className='mb-[2px]'
                        key={`${index}-${item?.type_id}`}
                      >
                        <SwipeConfig
                          key={`${index}-${item?.type_id}`}
                          index={index}
                          selectedIndex={selected}
                          setSelectedIndex={setSelected}
                          RightComponents={rightConfig(item)}
                        >
                          <ListItem
                            key={`${index}-${item?.type_id}`}
                            fill={'object-contain'}
                            image={
                              item?.thumb_link ||
                              (selection?.axiom_photo_link &&
                              selection?.axiom_photo_link?.toLowerCase() !==
                                'photo url'
                                ? selection?.axiom_photo_link
                                : DUMMYMARKETMATERIAL?.[count]?.thumbnail)
                            }
                            title={
                              item?.layout_name ||
                              selection?.axiom_name ||
                              selection?.type_name
                            }
                            content={
                              <div className='swipeable'>
                                <p className='swipeable'>
                                  {selection?.axiom_name}
                                </p>
                                <p className='swipeable'>
                                  {selection?.axiom_blurb}
                                </p>
                              </div>
                            }
                            link={false}
                            // preview={true}
                          />
                        </SwipeConfig>
                      </div>
                    )
                  })}
                </>
              ) : (
                <div className='px-3'>
                  We couldn't find any layouts for your current selection right
                  now.
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
