import React from 'react';

export default function FormButton({ type, children, full }) {
  return (
    <button
      type={type}
      className={`bg-primary inline-block text-base font-semibold px-5 py-5 rounded-md min-w-[120px] ${
        full && 'w-full'
      }`}
    >
      {children}
    </button>
  );
}
