import { useEffect, useState } from 'react'
import axiomLogo from '../../../assets/images/axiom_driggs_logo.svg'
import facebookIcon from '../../../assets/images/facebook_icon.svg'
import facebookWhiteIcon from '../../../assets/images/facebook_white_icon.svg'
import instagramIcon from '../../../assets/images/instagram_icon.svg'
import twitterIcon from '../../../assets/images/twitter_icon.svg'
import linkedinIcon from '../../../assets/images/linkedin_icon.svg'
import googleIcon from '../../../assets/images/google_icon.svg'
import plusIcon from '../../../assets/images/plus_icon.svg'
import uiStyles from '../../ui/uiStyles'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { useNavigate } from 'react-router-dom'
import { useConnections } from '../../../contexts/ConnectionContext'
import images_logos from '../../../assets/images/axiom_logos'
import { useDistribute } from '../../../contexts/DistributeContext'
import ConnectSuccess from './ConnectSuccess'
import { open_url_tab } from '../../../helpers'
const Connection = ({ socialmedia }) => {
  const { connections, saveConnections } = useConnections()
  const { distribute, saveDistribute } = useDistribute()
  const type =
    (distribute?.type === 'social'
      ? distribute?.social || 'facebook'
      : distribute?.type) || 'social'

  const navigate = useNavigate()
  const [state, setState] = useState({})
  const { axiomFetch } = useAxiomFetch()

  useEffect(() => {
    const controller = new AbortController()
    const check_connections = async () => {
      const connections = await axiomFetch(
        '/usage/checkSocialConnections',
        'GET',
        {},
        {},
        false,
        controller.signal
      )
      saveConnections((prev) => ({ ...prev, ...connections }))
    }
    check_connections()
    return () => {
      controller?.abort()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let controller = new AbortController()
    const get_url = async () => {
      const data = await axiomFetch(
        `/social/${socialmedia}/connect`,
        'GET',
        {},
        {},
        false,
        controller.signal
      )
      setState((prev) => ({
        ...prev,
        connect_url: data.url,
      }))
    }
    get_url()
    return () => {
      controller?.abort()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let interval

    console.log('connecting', connections?.connecting === type)
    if (connections?.connecting === type) {
      interval = setInterval(async () => {
        console.log('hello interval')

        const pages = await axiomFetch('/social/facebook/pages')
        if (pages?.length) {
          saveConnections((prev) => ({
            ...prev,
            [type]: true,
          }))
          saveDistribute((prev) => ({
            ...prev,
            content: {
              ...prev?.content,
              [type]: { ...(prev?.content?.[type] || {}), pages },
            },
          }))
          localStorage.setItem('connected', 'facebook')
          clearInterval(interval)
        }
      }, [1000])
    }
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [connections?.connecting, connections?.connection_confirm])

  const connect_handler = () => {
    saveConnections((prev) => ({
      ...prev,
      connecting: socialmedia,
      connection_confirm: socialmedia,
    }))
    localStorage.setItem('connecting', socialmedia)
    open_url_tab(state?.connect_url)
  }

  const text = {
    facebook: (
      <>
        <div className='flex place-content-center gap-5'>
          <img
            alt='Axiom'
            className='w-[55px]'
            src={images_logos.axiom_small_png}
          />
          <img alt='plus' className='w-[33px]' src={plusIcon} />
          <img alt='facebook' className='w-[55px]' src={facebookIcon} />
        </div>
        <div className='mt-[20px] flex flex-col gap-5'>
          <h1 className={`${uiStyles.text_black_subtitle} text-center`}>
            Let's get your Facebook account connected to AXIOM!
          </h1>
          <p className={`${uiStyles.text_gray} text-[#0C0C0C] text-center`}>
            You will now be taken to Facebook to grant{' '}
            <span className='block'>permissions</span> from AXIOM to share your
            content
          </p>
          <p className={`${uiStyles.text_gray} text-[#0C0C0C] text-center`}>
            This feature
            <a
              href='https://www.facebook.com/business/tools/facebook-pages'
              target={'_blank'}
              rel='noreferrer'
              className={`font-semibold block uppercase text-[#0A66C2]`}
            >
              requires a business facebook page.
            </a>
          </p>
          <div className={`${uiStyles.text_gray} text-[#0C0C0C] text-center`}>
            Watch this Instructional Video!
            <div
              onClick={async () => {
                await axiomFetch('/usage/updateVideoWatchHist', 'POST', {
                  url: 'https://youtu.be/yXnel5E-K4A',
                  unique_name: 'axiom_connect_facebook',
                })

                const new_window = open_url_tab('https://youtu.be/yXnel5E-K4A')
              }}
              className={`font-semibold block uppercase text-[#0A66C2] cursor-pointer`}
            >
              How to connect Axiom to Facebook
            </div>
          </div>
          <p className={`${uiStyles.text_gray} text-[#0C0C0C] text-center`}>
            In addition, Facebok permissions give AXIOM access to your Facebook
            data, such as your friend list and profile information, which can
            enhance AXIOM's personalized recommendations and social features.
          </p>
        </div>
      </>
    ),
    instagram: (
      <>
        <div className='flex place-content-center gap-5'>
          <img
            alt='Axiom'
            className='w-[55px]'
            src={images_logos.axiom_small_png}
          />
          <img alt='plus' className='w-[33px]' src={plusIcon} />
          <img alt='instagram' className='w-[55px]' src={instagramIcon} />
        </div>
        <div className='mt-[20px] flex flex-col gap-5'>
          <h1 className={`${uiStyles.text_black_subtitle} text-center`}>
            Let's get your Instagram account connected to AXIOM!
          </h1>
          <p className={`${uiStyles.text_gray} text-[#0C0C0C] text-center`}>
            You will now be taken to Instagram to grant{' '}
            <span className='block'>permissions</span> from AXIOM to share your
            content
          </p>
          <p className={`${uiStyles.text_gray} text-[#0C0C0C] text-center`}>
            This feature
            <a
              href='https://www.facebook.com/business/tools/facebook-pages'
              target={'_blank'}
              rel='noreferrer'
              className={`font-semibold block uppercase text-[#0A66C2]`}
            >
              requires a business Instagram page.
            </a>
          </p>
          <p className={`${uiStyles.text_gray} text-[#0C0C0C] text-center`}>
            In addition, Instagram permissions give AXIOM access to your
            Instagram data, such as your friend list and profile information,
            which can enhance AXIOM's personalized recommendations and social
            features.
          </p>
        </div>
      </>
    ),
    twitter: (
      <>
        <div className='flex place-content-center gap-5'>
          <img
            alt='Axiom'
            className='w-[55px]'
            src={images_logos.axiom_small_png}
          />
          <img alt='plus' className='w-[33px]' src={plusIcon} />
          <img alt='twitter' className='w-[55px]' src={twitterIcon} />
        </div>
        <div className='mt-[20px] flex flex-col gap-5'>
          <h1 className={`${uiStyles.text_black_subtitle} text-center`}>
            Let's get your Twitter account connected to AXIOM!
          </h1>
          <p className={`${uiStyles.text_gray} text-[#0C0C0C] text-center`}>
            You will now be taken to Twitter to grant{' '}
            <span className='block'>permissions</span> from AXIOM to share your
            content
          </p>
          <p className={`${uiStyles.text_gray} text-[#0C0C0C] text-center`}>
            This feature
            <a
              href='https://www.facebook.com/business/tools/facebook-pages'
              target={'_blank'}
              rel='noreferrer'
              className={`font-semibold block uppercase text-[#0A66C2]`}
            >
              requires a business Twitter page.
            </a>
          </p>
          <p className={`${uiStyles.text_gray} text-[#0C0C0C] text-center`}>
            In addition, Twitter permissions give AXIOM access to your Twitter
            data, such as your friend list and profile information, which can
            enhance AXIOM's personalized recommendations and social features.
          </p>
        </div>
      </>
    ),
    linkedin: (
      <>
        <div className='flex place-content-center gap-5'>
          <img
            alt='Axiom'
            className='w-[55px]'
            src={images_logos.axiom_small_png}
          />
          <img alt='plus' className='w-[33px]' src={plusIcon} />
          <img alt='linkedin' className='w-[55px]' src={linkedinIcon} />
        </div>
        <div className='mt-[20px] flex flex-col gap-5'>
          <h1 className={`${uiStyles.text_black_subtitle} text-center`}>
            Let's get your LinkedIn account connected to AXIOM!
          </h1>
          <p className={`${uiStyles.text_gray} text-[#0C0C0C] text-center`}>
            You will now be taken to LinkedIn to grant{' '}
            <span className='block'>permissions</span> from AXIOM to share your
            content
          </p>
          <p className={`${uiStyles.text_gray} text-[#0C0C0C] text-center`}>
            This feature
            <a
              href='https://www.facebook.com/business/tools/facebook-pages'
              target={'_blank'}
              rel='noreferrer'
              className={`font-semibold block uppercase text-[#0A66C2]`}
            >
              requires a business LinkedIn page.
            </a>
          </p>
          <p className={`${uiStyles.text_gray} text-[#0C0C0C] text-center`}>
            In addition, LinkedIn permissions give AXIOM access to your LinkedIn
            data, such as your friend list and profile information, which can
            enhance AXIOM's personalized recommendations and social features.
          </p>
        </div>
      </>
    ),
    google: (
      <>
        <div className='flex place-content-center gap-5'>
          <img
            alt='Axiom'
            className='w-[55px]'
            src={images_logos.axiom_small_png}
          />
          <img alt='plus' className='w-[33px]' src={plusIcon} />
          <img alt='google' className='w-[55px]' src={googleIcon} />
        </div>
        <div className='mt-[20px] flex flex-col gap-5'>
          <h1 className={`${uiStyles.text_black_subtitle} text-center`}>
            Let's get your Google account connected to AXIOM!
          </h1>
          <p className={`${uiStyles.text_gray} text-[#0C0C0C] text-center`}>
            You will now be taken to Google to grant{' '}
            <span className='block'>permissions</span> from AXIOM to share your
            content
          </p>
          <p className={`${uiStyles.text_gray} text-[#0C0C0C] text-center`}>
            This feature
            <a
              href='https://www.facebook.com/business/tools/facebook-pages'
              target={'_blank'}
              rel='noreferrer'
              className={`font-semibold block uppercase text-[#0A66C2]`}
            >
              requires a business Google page.
            </a>
          </p>
          <p className={`${uiStyles.text_gray} text-[#0C0C0C] text-center`}>
            In addition, Google permissions give AXIOM access to your Google
            data, such as your friend list and profile information, which can
            enhance AXIOM's personalized recommendations and social features.
          </p>
        </div>
      </>
    ),
  }

  const button = {
    facebook: (
      <div
        className='flex bg-[#1877F2] rounded-md w-[210px] px-2 gap-2'
        onClick={connect_handler}
      >
        <img alt='facebook' src={facebookWhiteIcon} className={`w-[30px]`} />
        <button className='text-white'>Continue with Facebook</button>
      </div>
    ),
    instagram: (
      <div
        className='flex bg-[#1877F2] rounded-md w-[210px] px-2 gap-2'
        onClick={connect_handler}
      >
        <img alt='facebook' src={facebookWhiteIcon} className={`w-[30px]`} />
        <button className='text-white'>Continue with Facebook</button>
      </div>
    ),
  }

  const confirm_text = {
    facebook: {
      logo: facebookIcon,
      alt_logo: facebookWhiteIcon,
      action: (
        <div
          className='flex bg-[#1877F2] rounded-md w-[210px] p-2 gap-2 mt-[20px]'
          onClick={async () => {
            saveConnections((prev) => ({
              ...prev,
              connection_confirm: 'done',
              [socialmedia]: false,
            }))

            connect_handler()
          }}
        >
          <img alt='facebook' src={facebookWhiteIcon} className={`w-[30px]`} />
          <button className='text-white'>Continue with Facebook</button>
        </div>
      ),
    },
    instagram: {
      logo: instagramIcon,
      alt_logo: instagramIcon,
      action: (
        <div
          className='flex bg-[#1877F2] rounded-md w-[210px] p-2 gap-2 mt-[20px]'
          onClick={() => {
            saveConnections((prev) => ({
              ...prev,
              connection_confirm: 'done',
              [socialmedia]: false,
            }))
            connect_handler()
          }}
        >
          <img alt='facebook' src={facebookWhiteIcon} className={`w-[30px]`} />
          <button className='text-white'>Continue with Facebook</button>
        </div>
      ),
    },
  }

  console.log(connections)
  if (connections?.connection_confirm === socialmedia) {
    return (
      <>
        {!distribute?.content?.[socialmedia]?.pages?.length ? (
          <div className={`flex flex-col items-center`}>
            <p className=''>
              {' '}
              {`The connection to `}
              <span className='capitalize'>{socialmedia}</span>
              {` was unsuccessful.`}
            </p>
            <p>Click continue to retry!</p>
            {confirm_text[socialmedia].action}
          </div>
        ) : (
          <div className={`flex flex-col items-center`}>
            <p className=''>
              {`You have successfully connected to `}
              <span className='capitalize'>{socialmedia}</span>
              {`! 🎉`}
            </p>
            <img
              className='w-[50px] mt-[10px]'
              src={confirm_text?.[socialmedia]?.logo}
            />
            <div className='mt-[10px] flex flex-col items-center'>
              <p>You are currently connected to these pages:</p>
              <div>
                {distribute?.content?.[socialmedia]?.pages?.map(
                  (page, index) => {
                    return (
                      <p className='mt-[5px]' key={page?.page_id}>
                        {index + 1}. {page?.name}
                        {page?.page_type === 'active' ? ' Default' : ''}
                      </p>
                    )
                  }
                )}
              </div>
            </div>
            <div className='flex w-full justify-center'>
              <button
                className={`${uiStyles.hover_btn} mt-[20px]`}
                onClick={() => {
                  saveConnections((prev) => ({
                    ...prev,
                    connection_confirm: 'done',
                  }))
                }}
              >
                Continue
              </button>
            </div>
          </div>
        )}
      </>
    )
  }

  return (
    <div className='flex flex-col items-center'>
      {text?.[socialmedia]}
      <div className='flex gap-3 mt-[20px]'>
        <button
          className={`${uiStyles.white_btn}`}
          onClick={() => {
            navigate(-1)
          }}
        >
          Cancel
        </button>
        {button?.[socialmedia] || (
          <button className={`${uiStyles.green_btn}`} onClick={connect_handler}>
            Agree
          </button>
        )}
      </div>
    </div>
  )
}

export default Connection
