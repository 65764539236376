import { useState, useEffect } from 'react'
import Wrapper from '../../components/layout/Wrapper'
import SubNav from '../../components/SubNav'
import DateRangePicker from '../../components/ui/DateRangePicker'
import SwipeConfig from '../../components/ui/SwipeConfig'
import TitleIcon from '../../components/ui/TitleIcon'
import uiStyles from '../../components/ui/uiStyles'
import { HiArrowDown } from 'react-icons/hi2'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import facebook from '../../assets/images/facebook_icon.svg'
import instagram from '../../assets/images/instagram_icon.svg'
import text from '../../assets/images/messages_icon.svg'
import email from '../../assets/images/email_icon.svg'
import { getDateString } from '../../helpers'
import SmallLoading from '../../components/ui/SmallLoading'
import Modal from '../../components/Modal'
import SchedulerModal from '../../components/pages/AI/SchedulerModal'
import { useNavigate } from 'react-router-dom'
import { useProfile } from '../../contexts/UserContext'
import { useAi } from '../../contexts/AiContext'
import { useDistribute } from '../../contexts/DistributeContext'
import { content_types, social_medias } from '../../data/data'
const PageAxiomAiScheduler = () => {
  /*eslint no-extend-native: ["warn", { "exceptions": ["Date"] }]*/
  const navigate = useNavigate()
  const { saveAi } = useAi()
  const { distribute, saveDistribute } = useDistribute()
  const type =
    distribute?.type === 'social'
      ? distribute?.social || 'facebook'
      : distribute?.type || 'social'
  const content = distribute?.content?.[type]
  const { profile } = useProfile()
  Date.prototype.addDays = function (days) {
    let date_s = new Date(this.valueOf())
    date_s.setDate(date_s.getDate() + days)
    return date_s
  }
  // const formatted_msg = {
  //   intro: `Hey {{contactName}},\n\n It's ${profile?.CONT_NAME} here. `,
  //   closing: `\n\nReply STOP to unsubscribe.`,
  //   callLink: true,
  //   callLinkMsg: `\n\nClick the link below to have me call you.\n{{callLink}}`,
  //   callLinkDefault: `Reply back yes and I will connect with you directly.`,
  // }
  const { axiomFetch } = useAxiomFetch()
  const [state, setState] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [startDate, setStartDate] = useState(new Date().addDays(-30))
  const [endDate, setEndDate] = useState(new Date().addDays(1))
  const [selectedIndex, setSelectedIndex] = useState(1)
  const images = {
    facebook,
    instagram,
    text,
    email,
  }
  useEffect(() => {
    const controller = new AbortController()
    const get_content = async () => {
      setState((prev) => ({ ...prev, isLoading: true }))
      console.log(startDate.toLocaleString('sv'))
      console.log(endDate.toLocaleString('sv'))
      const data = await axiomFetch(
        encodeURI(
          `/social/schedule/content?startDate=${startDate.toLocaleString(
            'sv'
          )}&endDate=${endDate.toLocaleString('sv')}`
        ),
        'GET',
        {},
        {},
        false,
        controller?.signal
      )
      if (data?.length) {
        data?.forEach((item) => {
          let key =
            // get rid of urls.
            (item?.message?.replace(
              /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi,
              ''
            ) || '') +
            (item?.photo || '') +
            (item?.type || '') +
            '*!**' +
            item?.ins_dt?.split('T')[0]

          // gets rid of unique names at the beggining of the texts.
          if (key.startsWith('Hey') || key.startsWith('Ey')) {
            key = key?.split(',')?.[1]
          }
          item.identifier = key
        })
        let counts = {}
        const unique_data = []
        data?.forEach((item) => {
          if (item.identifier) {
            if (counts?.[item.identifier]) {
              console.log(item.identifier)
              counts[item.identifier] += 1
            } else {
              unique_data.push(item)
              counts[item.identifier] = 1
            }
          }
        })

        unique_data?.forEach((item) => {
          item.count = counts[item?.identifier]
        })
        console.log(unique_data)
        setState((prev) => ({ ...prev, content: unique_data }))
      }
      if (!controller.signal.aborted) {
        setState((prev) => ({ ...prev, isLoading: false }))
      }
    }
    get_content()
    return () => {
      controller?.abort()
    }
    // eslint-disable-next-line
  }, [startDate, endDate])

  const handleEdit = async (item) => {
    setShowModal(true)
    setState((prev) => ({ ...prev, editItem: item }))
  }

  const remove_wrapper_msg = (text) => {
    text = text
      ?.replace('Reply "STOP" to opt out.', '')
      ?.replace('Responde STOP para cancelar la suscripión.', '')
      ?.replace(/Hey.*?,/, '')
      ?.replace(/Ey.*?,/, '')
      ?.trim()
    return text
  }

  return (
    <Wrapper>
      <SubNav />
      <div className='p-3'>
        <TitleIcon></TitleIcon>
      </div>

      <>
        <div className='px-3'>
          <h1 className={uiStyles.text_black_title}>AXIOM Library</h1>
          <div className='mt-[24px]'>
            <h4 className={uiStyles.text_black_subtitle}>Your Library</h4>
            <p className={uiStyles.text_gray}>
              The all-new, ultra-organized and accessible AXIOM content library!
              Say goodbye to the hassle of searching through countless posts and
              folders to find that one piece of content you need to reschedule
              or repurpose. Our content library brings all of your content into
              one centralized location.
            </p>
          </div>
          <DateRangePicker
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
          <div className='flex justify-between mx-[10px] mb-[10px]'>
            <div className='flex items-center gap-[5px]'>
              <div>Content</div>
              <HiArrowDown />
            </div>
            <div>Schedule</div>
            <div>Type</div>
          </div>
        </div>
        <div className='overflow-scroll h-[600px]'>
          {state.isLoading ? (
            <SmallLoading styles={'mt-[400px]'} />
          ) : !state?.content?.length ? (
            <div className='p-5'>
              Looks like there is not content sheduled yet.
            </div>
          ) : (
            state?.content?.map((item, index) => {
              return (
                <SwipeConfig
                  key={`${item?.id}-${index}`}
                  index={index}
                  setSelectedIndex={setSelectedIndex}
                  selectedIndex={selectedIndex}
                  RightComponents={
                    !item.sent_dt
                      ? () => {
                          if (!item.sent_dt) {
                            return (
                              <div className='flex gap-[10px]'>
                                <button
                                  className='cursor-pointer'
                                  onClick={() => {
                                    item.status = 'active'
                                    handleEdit(item)
                                  }}
                                >
                                  Edit
                                </button>
                                {/* | <button className='cursor-pointer'
                                  onClick={() => {
                                    item.status = 'deleted'
                                    handleEdit(item)
                                  }}>Cancel</button> */}
                              </div>
                            )
                          } else {
                            return <></>
                          }
                        }
                      : () => (
                          <div className='flex gap-[10px]'>
                            <button
                              className='cursor-pointer'
                              onClick={() => {
                                saveAi((prev) => {
                                  let distribute_content =
                                    distribute?.content || {}

                                  for (const content_type of content_types) {
                                    distribute_content[content_type] = {
                                      ...distribute_content?.[content_type],
                                      headline: item?.subject,
                                      message: remove_wrapper_msg(
                                        item?.message || ''
                                      ),
                                      photo_url: item?.photo ? item?.photo : '',
                                      image_upload: {
                                        ...distribute_content?.[content_type]
                                          ?.image_upload,
                                        gallery: {
                                          ...distribute_content?.[content_type]
                                            ?.image_upload?.gallery,
                                          active: item?.photo
                                            ? { url: item?.photo }
                                            : { url: '' },
                                        },
                                      },
                                    }
                                  }
                                  saveDistribute((prev) => ({
                                    ...prev,
                                    type: social_medias.includes(
                                      item?.type || ''
                                    )
                                      ? 'social'
                                      : item?.type,
                                    social: social_medias.includes(
                                      item?.type?.toLowerCase() || ''
                                    )
                                      ? item?.type
                                      : '',
                                    content: distribute_content,
                                  }))

                                  return {
                                    ...prev,
                                    image_upload: {
                                      ...prev?.image_upload,
                                      gallery: {
                                        ...prev?.image_upload?.gallery,
                                        active: item?.photo
                                          ? { url: item?.photo }
                                          : { url: '' },
                                      },
                                    },
                                    assistant: {
                                      ...prev?.assistant,
                                      ai_assistant: 'N',
                                    },
                                    state: {
                                      ...prev?.state,
                                      subject: item?.subject,
                                      last_prompt: item?.message.includes('Ey')
                                        ? 'spanish'
                                        : 'english',
                                      message: remove_wrapper_msg(
                                        item?.message || ''
                                      ),
                                    },
                                  }
                                })
                                localStorage.setItem('ai_assistant', 'N')
                                navigate('/user/axiom-ai-distribute')
                                // navigate(
                                //   `/user/axiom-ai?media=${item?.type}&photo=${
                                //     item?.photo || ''
                                //   }&subject=${
                                //     item?.subject
                                //   }&message=${remove_wrapper_msg(
                                //     item?.message || ''
                                //   )}&lang=${
                                //     item?.message.includes('Soy')
                                //       ? 'spanish'
                                //       : 'english'
                                //   }`
                                // )
                              }}
                            >
                              Resend
                            </button>
                          </div>
                        )
                  }
                  bg={index % 2 ? 'bg-white' : 'bg-[#EAECF0]'}
                >
                  <div
                    className={`swipeable flex justify-between pl-[10px] items-center h-[73px] ${
                      index % 2 ? 'bg-white' : 'bg-[#EAECF0]'
                    } w-full`}
                  >
                    <div className='swipeable flex flex-col items-left w-[120px]'>
                      <div
                        className={`swipeable ${uiStyles.text_black} text-[14px] text-left`}
                      >
                        {item.type === 'email'
                          ? item?.subject?.slice(0, 15)
                          : ''}
                      </div>
                      <div
                        className={`swipeable ${uiStyles.text_gray} text-[12px]`}
                      >
                        {item?.message?.slice(0, 18)}...
                      </div>
                      <div
                        className={`swipeable ${uiStyles.text_gray} text-[12px]`}
                      >
                        Amount sent: {item?.count}
                      </div>
                    </div>
                    <div>
                      <div className='swipeable flex flex-col items-center w-[106px]'>
                        <div
                          className={`swipeable ${uiStyles.text_black} text-[10px] text-left`}
                        >
                          {!item?.sent_dt ? 'Scheduled' : 'Sent on'}
                        </div>
                        <div
                          className={`swipeable ${uiStyles.text_black} text-[10px]`}
                        >
                          {getDateString({
                            date: item?.sent_dt,
                            format: 'mm/dd/yy',
                          })}
                        </div>
                      </div>
                    </div>
                    <div className='swipeable flex flex-col items-center justify-center w-[106px] ml-[10px]'>
                      {' '}
                      <img
                        className='w-[18px]'
                        alt={`${item?.type} logo`}
                        src={images[item?.type]}
                      />
                    </div>
                  </div>
                </SwipeConfig>
              )
            })
          )}
        </div>
        {showModal ? (
          <Modal
            modalName={`update_scheduled_content`}
            height={
              state?.editItem?.status === 'deleted' ? 'h-[200px]' : 'h-[550px]'
            }
            setShowModal={setShowModal}
            showModal={showModal}
          >
            <SchedulerModal
              scheduleState={state}
              setScheduleState={setState}
              editItem={state?.editItem}
              setShowModal={setShowModal}
            ></SchedulerModal>
          </Modal>
        ) : (
          ''
        )}
      </>
    </Wrapper>
  )
}

export default PageAxiomAiScheduler
