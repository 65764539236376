import React, { useCallback, useEffect, useRef, useState } from 'react'
import Wrapper from '../../layout/Wrapper'

import SubNav from '../../SubNav'
import ProgressBar from '../../ui/ProgressBar'
import TransactionsMenu from '../../ui/TransactionsMenu'
import { useParams } from 'react-router'
import { useProfile } from '../../../contexts/UserContext'
import { useAuth0 } from '@auth0/auth0-react'
import { ServiceTransactions } from '../../../services/ServiceTransactions'
import { useQuery } from '@tanstack/react-query'
import { replaceWithBr } from '../../../helpers'
import uiStyles from '../../ui/uiStyles'
import SmallLoading from '../../ui/SmallLoading'
import plus_icon from '../../../assets/images/gray_plus_icon.svg'
import minus_icon from '../../../assets/images/gray_minus_icon.svg'
import DocumentItem from '../../ui/DocumentItem'
import TitleDocumentViewer from './TitleDocumentViewer'

export default function Unusual() {
  const { fileid } = useParams()
  const { profile } = useProfile()
  const [state, setState] = useState({
    requirements: false,
    documents: false,
    exceptions: false,
  })
  const [image, setImage] = useState({})
  const { getAccessTokenSilently } = useAuth0()
  const TransactionsfetchController = useRef(null)
  const getDocuments = useCallback(async () => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUDIENCE,
    }).catch((err) => null)

    const serviceTransactions = new ServiceTransactions(token)
    if (!TransactionsfetchController.current) {
      TransactionsfetchController.current =
        serviceTransactions.getController().controller
    }
    return serviceTransactions.get(fileid, '/exceptions')
  }, [fileid, getAccessTokenSilently])

  const UnusualsQuery = useQuery({
    queryKey: ['unusuals', fileid],
    queryFn: getDocuments,
    refetchOnWindowFocus: false,
    enabled: !!profile,
  })

  useEffect(() => {
    return () =>
      TransactionsfetchController.current &&
      TransactionsfetchController.current.abort()
  }, [])
  const unusualsHTML = !UnusualsQuery?.data?.error && (
    <div className='flex flex-col gap-2'>
      <div className='requirements border-b-2'>
        <div
          className='flex justify-between items-center py-5'
          onClick={() => {
            setState((prev) => ({ ...prev, requirements: !prev.requirements }))
          }}
        >
          <p className={`${uiStyles.text_black} font-semibold`}>Requirements</p>
          <div>
            Unusual:{' '}
            <span>
              {
                UnusualsQuery?.data?.requirements.filter(
                  (item) => Number(item?.non_standard) === 1
                )?.length
              }
            </span>
          </div>
          <div>
            Standard:{' '}
            <span>
              {
                UnusualsQuery?.data?.requirements.filter(
                  (item) => Number(item?.non_standard) === 0
                )?.length
              }
            </span>
          </div>
          <img
            className='w-[22px]'
            src={state?.requirements ? minus_icon : plus_icon}
          />
        </div>
        <div>
          {state?.requirements
            ? UnusualsQuery?.data?.requirements?.length &&
              UnusualsQuery?.data?.requirements?.map((item, index) => {
                return (
                  <div
                    key={`${item.id}-${index}`}
                    className={`mb-5 border-b-2 border-gray-500 ${
                      Number(item.non_standard) === 1
                        ? 'bg-red-100'
                        : 'bg-blue-100'
                    } bg-opacity-40`}
                  >
                    <h3
                      className='text-primary font-semibold'
                      dangerouslySetInnerHTML={{
                        __html: replaceWithBr(item.text ? item.text : ''),
                      }}
                    ></h3>
                    <p className='text-base'>{item?.explanation}</p>
                    <div className='text-xs italic text-right'>
                      {Number(item.non_standard) === 1 ? 'Unusual' : 'Standard'}
                    </div>
                  </div>
                )
              })
            : ''}
        </div>
      </div>
      <div className='documents border-b-2'>
        <div
          className='flex justify-between items-center py-5'
          onClick={() => {
            setState((prev) => ({ ...prev, images: !prev.images }))
          }}
        >
          <p className={`${uiStyles.text_black} font-semibold`}>
            Title Documents
          </p>

          <div>
            Total: <span>{UnusualsQuery?.data?.images?.length}</span>
          </div>
          <div className='w-[105px]'>{''}</div>
          <img
            className='w-[22px]'
            src={state?.images ? minus_icon : plus_icon}
          />
        </div>
        <div>
          {state?.images
            ? UnusualsQuery.data &&
              UnusualsQuery?.data?.images?.map((item, index) => {
                return (
                  <div
                    key={`${item.id}-${index}`}
                    className={`mb-5 border-b-2 bg-opacity-40`}
                  >
                    <h3 className='text-primary font-semibold'></h3>
                    <DocumentItem
                      className='my-2'
                      key={item?.id}
                      buttonLabel={`${item?.name}`}
                      clickHandler={() => {
                        setImage((prev) => ({
                          fileid,
                          doc_name: item.name,
                          doc_link: item.path,
                        }))
                      }}
                    />

                    <p className='text-base'>{item?.explanation}</p>
                    <div className='text-xs italic text-right'></div>
                  </div>
                )
              })
            : ''}
        </div>
      </div>
      <div className='exceptions border-b-2'>
        <div
          className='flex justify-between items-center py-5'
          onClick={() => {
            setState((prev) => ({ ...prev, exceptions: !prev.exceptions }))
          }}
        >
          <p className={`${uiStyles.text_black} font-semibold`}>Exceptions</p>
          <div>
            Unusual:{' '}
            <span>
              {
                UnusualsQuery?.data?.exceptions.filter(
                  (item) => Number(item?.non_standard) === 1
                )?.length
              }
            </span>
          </div>
          <div>
            Standard:{' '}
            <span>
              {
                UnusualsQuery?.data?.exceptions.filter(
                  (item) => Number(item?.non_standard) === 0
                )?.length
              }
            </span>
          </div>
          <img
            className='w-[22px]'
            src={state?.exceptions ? minus_icon : plus_icon}
          />
        </div>
        <div>
          {state?.exceptions
            ? UnusualsQuery.data?.exceptions?.length &&
              UnusualsQuery?.data?.exceptions?.map((item, index) => {
                return (
                  <div
                    key={`${item.id}-${index}`}
                    className={`mb-5 border-b-2 border-gray-500 ${
                      Number(item.non_standard) === 1
                        ? 'bg-red-100'
                        : 'bg-blue-100'
                    } bg-opacity-40`}
                  >
                    <h3
                      className='text-primary font-semibold'
                      dangerouslySetInnerHTML={{
                        __html: replaceWithBr(item.text ? item.text : ''),
                      }}
                    ></h3>
                    <p className='text-base'>{item?.explanation}</p>
                    <div className='text-xs italic text-right'>
                      {Number(item.non_standard) === 1 ? 'Unusual' : 'Standard'}
                    </div>
                  </div>
                )
              })
            : ''}
        </div>
      </div>
    </div>
  )

  return (
    <>
      {image?.fileid ? (
        <TitleDocumentViewer
          setImage={setImage}
          doc_name={image?.doc_name}
          doc_link={image?.doc_link}
          fileid={image?.fileid}
        />
      ) : (
        <>
          {UnusualsQuery?.isLoading ? (
            <SmallLoading />
          ) : (
            <>
              <div className='flex flex-col gap-3 border-b-2 pb-2'>
                <div>
                  <p className={`${uiStyles.text_black} font-semibold`}>
                    Current Vesting:
                  </p>{' '}
                  <p>{UnusualsQuery?.data?.current_vesting}</p>
                </div>
                <div>
                  <p className={`${uiStyles.text_black} font-semibold`}>
                    Proposed Vesting:
                  </p>{' '}
                  <p>{UnusualsQuery?.data?.proposed_vesting}</p>
                </div>
              </div>
              <div className='mt-2'>{unusualsHTML}</div>
            </>
          )}
        </>
      )}
    </>
  )
}
