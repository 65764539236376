import Chart from 'react-apexcharts'
import { useEffect, useState, useRef } from 'react'
import { useCalc } from '../../contexts/CalcContext'
import {
  calculate_amortization_totals,
  convert_percent,
  format_currency,
  format_input,
  getTextWidth,
  get_loan_balance,
  get_months,
  get_storage,
  get_uuid,
  labels_html,
  validate_number,
} from '../../helpers'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import { CircleCheckToggle } from '../form/CircleCheckToggle'
import LabelInput from '../form/LabelInput'
import { Schedule } from '../form/Schedule'
import Select from '../form/Select'
import uiStyles from '../ui/uiStyles'
import CalcCircle from './CalcCircle'
import CalcShare from './CalcShare'

const CalcLoanBalance = ({ setShowModal, type = '' }) => {
  const { calc, saveCalc, get_calc_type_id } = useCalc()
  const { axiomFetch } = useAxiomFetch()
  const chartRef = useRef()
  const totalRef = useRef()

  const calculate_estimate = () => {
    saveCalc((prev) => {
      return {
        ...prev,
        loan_balance: {
          ...prev?.loan_balance,
          data_options: {
            ...prev?.loan_balance?.data_options,
            instance_uuid:
              prev?.loan_balance?.data_options?.instance_uuid || get_uuid(),
            calculated: true,
            run: (prev?.loan_balance?.data_options?.run || 0) + 1,
          },
        },
      }
    })
    if (
      calc?.loan_balance?.values?.loan_amount ||
      calc?.loan_balance?.values?.home_price
    ) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
  const update_input = (options) => {
    const calculator_type = 'loan_balance'
    const { name, value } = format_input(options)
    let type =
      options?.type || calc?.[calculator_type]?.types?.[`${name}`] || ''
    saveCalc((prev) => {
      const result = {
        ...prev,
        [calculator_type]: {
          ...prev?.[calculator_type],
          values: { ...prev?.[calculator_type]?.values, [name]: value },
          types: { ...prev?.[calculator_type]?.types, [name]: type },
        },
      }
      return result
    })
  }
  const get_inputs = (calculator_type = 'loan_balance') => {
    const types = calc?.[calculator_type]?.types
    const names = calc?.[calculator_type]?.names
    const placeholders = calc?.[calculator_type]?.placeholders
    console.log(calc?.[calculator_type]?.values)

    return Object.entries(calc?.[calculator_type]?.values)
      ?.filter(([key, value]) => {
        if (
          (calc?.[calculator_type]?.defaults?.is_home_price === 'N' &&
            (key === 'home_price' || key === 'down_payment')) ||
          (calc?.[calculator_type]?.defaults?.is_home_price === 'Y' &&
            key === 'loan_amount')
        ) {
          return false
        } else if (key === 'extra_payment' || key === 'interest_saved') {
          return false
        } else {
          return true
        }
      })
      ?.map(([key, value]) => {
        const type = types[key]
        const name = names[key]
        const placeholder = placeholders[key] || ''
        const label_width = 'w-[80%]'
        const initial_data = {
          left_label: name,
          left_label_width: label_width,
          name: key,
          value,
          placeholder,
        }

        if (type === '%' || type === '$' || type === '%$') {
          initial_data.show_delete_all = true
          initial_data.inputMode = 'decimal'
        }
        if (type === '%') {
          initial_data.show_percent = true
          initial_data.percent_value = '%'
          if (key === 'loan_term') {
            initial_data.percent_value = ' years'
          }
          initial_data.handleChange = (e, type) => {
            update_input({ e, type, decimal: 3, original: value })
          }
        }
        if (type === '$') {
          initial_data.show_dollar = true
          initial_data.percent_value = ''
          initial_data.value = initial_data?.value
            ? `$${initial_data.value}`
            : initial_data?.value || ''
        }
        if (key === 'down_payment') {
          initial_data.show_percent = true
          initial_data.show_dollar = true
          initial_data.percent_value =
            calc?.[calculator_type]?.types?.[key] === '%' ? '%' : ''
          initial_data.dollar_percent = calc?.[calculator_type]?.types?.[key]
          initial_data.left_label_width = 'w-[135%]'
          initial_data.delete_all_position = 'right-[75px]'
          initial_data.total = validate_number(
            calculator_type === 'sell'
              ? calc?.[calculator_type]?.values?.sale_price
              : calc?.[calculator_type]?.values?.home_price
          )
          initial_data.handleChange = (e, type) => {
            update_input({ e, type, decimal: 3, original: value })
          }
        }

        if (key === 'home_price' || key === 'loan_amount') {
          initial_data.onKeyUp = (e) => {
            // update_input({ e, type, decimal: 2, original: value })
            if (e.target.value && e.key === 'Enter') {
              calculate_estimate()
            }
          }
        }

        let result = (
          <LabelInput
            {...initial_data}
            handleChange={
              initial_data?.handleChange
                ? initial_data?.handleChange
                : (e) => {
                    update_input({ e, type, decimal: 2, original: value })
                  }
            }
          />
        )

        if (type === 'date') {
          result = (
            <Schedule
              left_label={name}
              left_label_width={label_width}
              no_margin={'mb-[10px]'}
              name={key}
              show_time={false}
              schedule_date={value}
              handle_change={(e) => {
                update_input({ e, type, decimal: 2, original: value })
              }}
            />
          )
        }
        if (type === 'options') {
          const options = calc?.[calculator_type]?.option_values?.[key]
          result = (
            <Select
              left_label={name}
              left_label_width={label_width}
              no_margin={false}
              className='mb-[10px]'
              name={key}
              value={value || 'Conventional'}
              handler={(e) => {
                update_input({ e, type, decimal: 2, original: value })
              }}
              placeholder=''
            >
              {[...options].map((item) => {
                return (
                  <option key={item} value={item}>
                    {item}
                  </option>
                )
              })}
            </Select>
          )
        }

        return <div key={key}>{result}</div>
      })
  }

  const [error, setError] = useState({})

  useEffect(() => {
    const get_calcuations = () => {
      const calculator_type = 'loan_balance'
      console.log(calc?.[calculator_type]?.values?.loan_amount)
      console.log(calc?.[calculator_type]?.values?.home_price)
      console.log(calc?.[calculator_type]?.defaults?.is_home_price)
      if (calc?.[calculator_type]?.data_options?.calculated) {
        let timeout
        if (
          (!calc?.[calculator_type]?.values?.loan_amount &&
            calc?.[calculator_type]?.defaults?.is_home_price === 'N') ||
          (!calc?.[calculator_type]?.values?.home_price &&
            calc?.[calculator_type]?.defaults?.is_home_price === 'Y')
        ) {
          if (timeout) {
            clearTimeout(timeout)
          }
          timeout = null
          let msg =
            calc?.[calculator_type]?.defaults?.is_home_price === 'Y'
              ? 'Original Home Price'
              : 'Loan Amount'
          msg += ' is required.'
          setError((prev) => {
            return {
              msg: `${msg}`,
              show_error: true,
            }
          })
          timeout = setTimeout(() => {
            setError((prev) => {
              return {
                msg: `${msg}`,
                show_error: false,
              }
            })
          }, [3000])
          return saveCalc((prev) => ({
            ...prev,
            [calculator_type]: {
              ...prev?.[calculator_type],
              data_options: {
                ...prev?.[calculator_type]?.data_options,
                calculated: false,
                view_type: 'estimate',
              },
            },
          }))
        }

        const values = calc?.loan_balance?.values
        const types = calc?.loan_balance?.types
        let loan_amount = validate_number(values?.loan_amount)
        if (calc?.loan_balance?.defaults?.is_home_price === 'Y') {
          const home_price = values?.home_price
          const down_payment =
            types?.down_payment === '%'
              ? validate_number(values?.home_price) *
                (validate_number(values?.down_payment) / 100)
              : validate_number(values?.down_payment)
          console.log('down_payment', down_payment)
          loan_amount =
            validate_number(home_price) - validate_number(down_payment)
        }

        console.log('loan_end_date', values?.loan_end_date)
        const loan_est = get_loan_balance(
          loan_amount,
          validate_number(values?.interest_rate),
          validate_number(values?.loan_term),
          values?.loan_start_date,
          values?.loan_end_date
        )
        const loan_balance_est = validate_number(loan_est?.loan_balance)
        const interest_paid = validate_number(loan_est?.interest_paid)
        const principal_paid = validate_number(loan_est?.principal_paid)
        const curr_payment = validate_number(loan_est?.curr_payment)
        let text_width = getTextWidth(
          get_total_string(loan_balance_est),
          totalRef.current
        )
        // if (!calc?.sell?.data_options?.ran_data) {
        //   text_width -= 10
        // }
        let width = 200
        if (text_width > 93) {
          width = 230
        }
        if (text_width > 100) {
          width = 240
        }

        let left_px_center = (width - text_width) / 2
        // if (negative_at_close) {
        //   left_px_center += 10
        // }

        // left_px_center -= 1
        left_px_center = `${left_px_center}px`

        width = `${width}px`

        console.log('loan', loan_balance_est)
        saveCalc((prev) => {
          const result = {
            ...prev,
            sell: {
              ...prev?.sell,
              values: {
                ...prev?.sell?.values,
                loan_balance: format_currency(loan_balance_est, false, 0),
              },
            },
            loan_balance: {
              ...prev?.loan_balance,
              data_options: {
                ...prev?.loan_balance?.data_options,
                loan_balance: loan_balance_est,
                interest_paid,
                principal_paid,
                curr_payment,
                series: [interest_paid, principal_paid],
              },
              chart: {
                ...prev?.loan_balance?.chart,
                text_width,
                left_px_center,
              },
            },
          }
          return result
        })
      }
    }
    get_calcuations()
  }, [
    calc?.['loan_balance']?.values,
    calc?.['loan_balance']?.data_options?.calculated,
    calc?.['loan_balance']?.data_options?.run,
    chartRef.current,
    calc?.loan_balance?.defaults?.is_home_price,
  ])

  useEffect(() => {
    const save_data = async () => {
      if (!type) {
        return
      }
      if (get_storage('vbc_calc') === 'Y') {
        return
      }
      saveCalc((prev) => ({ ...prev, share_loading: true }))
      const types = {}
      const values = {}
      const shortener = {
        interest_rate: 'int_rate',
        insurance_rate: 'ins_rate',
        down_payment: 'down',
        utilities: 'uti',
        loan_amount: 'amount',
        loan_start_date: 'start_date',
        loan_end_date: 'end_date',
      }

      Object.entries(calc?.loan_balance?.types).forEach(([key, value]) => {
        types[`lbal_${shortener?.[key] || key}_is_type`] = value
      })
      Object.entries(calc?.loan_balance?.values).forEach(([key, value]) => {
        if (['%', '$', 'm', 'a']?.includes(calc?.loan_balance?.types?.[key])) {
          values[`lbal_${shortener?.[key] || key}`] = validate_number(value)
        } else {
          values[`lbal_${shortener?.[key] || key}`] = value
        }
      })
      values.lbal_is_home_price =
        calc?.loan_balance?.defaults?.is_home_price || 'Y'

      const data_options = calc?.loan_balance?.data_options
      values.lbal_interest_paid = validate_number(
        data_options?.interest_paid,
        3
      )
      values.lbal_principal_paid = validate_number(
        data_options?.principal_paid,
        3
      )
      values.loan_balance = validate_number(data_options?.loan_balance, 3)
      const data = {
        values: {
          ...types,
          ...values,
          instance_uuid: calc?.loan_balance?.data_options?.instance_uuid,
          calc_type: get_calc_type_id('loan_balance'),
          address: calc?.formatted_address || null,
          mls_id: calc?.mls_id || null,
        },
      }

      const current_hist = calc?.axiom_calc_hist?.find(
        (item) =>
          item.instance_uuid === calc?.loan_balance?.data_options?.instance_uuid
      )

      saveCalc((prev) => ({
        ...prev,
        axiom_calc_hist: [
          ...(prev?.axiom_calc_hist?.filter(
            (item) =>
              item.instance_uuid !==
              calc?.loan_balance?.data_options?.instance_uuid
          ) || []),
          {
            ...current_hist,
            ...data?.values,
            upd_dt: new Date()?.toISOString(),
          },
        ],
      }))
      await axiomFetch('/calculators/save', 'POST', data, {}, false, null, 700)
      saveCalc((prev) => ({ ...prev, share_loading: false }))
    }
    if (calc?.loan_balance?.data_options?.calculated) {
      save_data()
    }
  }, [
    calc?.loan_balance?.values,
    calc?.loan_balance?.data_options,
    calc?.loan_balance?.defaults?.is_home_price,
  ])

  console.log(calc?.loan_balance?.data_options)

  const get_total_string = (num, negative) => {
    let str = `$${format_currency(
      validate_number(num || calc?.loan_balance?.data_options?.loan_balance, 0),
      false,
      0
    )?.replace('$', '')}`
    if (str?.length > 17) {
      str = str?.substring(0, 15)?.padEnd(18, '.')
    }
    return str.trim()
  }

  const payment_year_month_string = (payments) => {
    const num = Math.floor(payments / 12)
    const remainder = payments % 12
    return `${`${num !== 0 ? `${num} year${num > 1 ? 's' : ''}` : ''}`} ${`${
      remainder !== 0 ? `${remainder} month${remainder > 1 ? 's' : ''}` : ''
    }`}`
  }

  return (
    <div>
      <div className='mb-[20px]'>
        {!chartRef || (calc?.loan_balance?.data_options?.calculated && type) ? (
          <div>
            <div className='flex justify-end mb-[5px]'>
              <CalcShare calculator_type={'loan_balance'} />
            </div>
            <div className='flex flex-col items-center'>
              <h1 className='text-black font-semibold text-[18px] text-center'>
                Payoff After{' '}
                {payment_year_month_string(
                  validate_number(
                    calc?.loan_balance?.data_options?.curr_payment
                  ) + 1 || 0
                )}
              </h1>
              <div className='relative' ref={chartRef}>
                <Chart
                  key={'loan_balance_chart'}
                  options={calc?.loan_balance?.chart}
                  width={calc?.loan_balance?.chart?.width || '200px'}
                  series={calc?.loan_balance?.data_options?.series || []}
                  type='donut'
                />
                <div
                  ref={totalRef}
                  className={`absolute flex flex-col items-center top-[44%] text-center `}
                  style={{
                    left: calc?.loan_balance?.chart?.left_px_center,
                  }}
                  // onClick={() => {
                  //   saveCalc((prev) => ({
                  //     ...prev,
                  //     sell: {
                  //       ...prev?.sell,
                  //       data_options: {
                  //         ...prev?.sell?.data_options,
                  //         original_net: validate_number(
                  //           prev?.sell?.data_options?.net_at_close
                  //         ),
                  //         calculated: false,
                  //       },
                  //     },
                  //   }))
                  //   setShowModal(true)
                  //   setModalType('net_at_close')
                  // }}
                >
                  {get_total_string()}
                  {/* <p className='text-[10px]'>Edit</p> */}
                </div>
              </div>
              {labels_html(
                ['Interest Paid', 'Principal Paid'],
                calc?.loan_balance?.data_options?.series,
                '',
                calc?.loan_balance?.chart?.colors || []
              )}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className='flex justify-end mr-[100px] pr-[10px] mb-[10px] w-[250px] text-sm'>
        <CircleCheckToggle
          name={`is_home_price`}
          toggle_left_label='Loan Amount'
          toggle_right_label='Home Price'
          type='toggle'
          inputValue={calc?.['loan_balance']?.defaults}
          handle_change={async (e) => {
            console.log(e.target.value)
            saveCalc((prev) => {
              let home_price = validate_number(
                prev?.loan_balance?.values?.home_price
              )
              let loan_price = validate_number(
                prev?.loan_balance?.values?.loan_amount
              )
              const down_payment = validate_number(
                prev?.loan_balance?.values?.down_payment
              )
              const down_payment_type = prev?.loan_balance?.types?.down_payment
              const down_price = convert_percent(
                home_price,
                down_payment,
                down_payment_type
              )
              if (
                prev?.loan_balance?.defaults?.is_home_price === 'Y' &&
                !loan_price &&
                home_price
              ) {
                const down_price = convert_percent(
                  home_price,
                  down_payment,
                  down_payment_type
                )
                loan_price = home_price - down_price
              } else if (
                prev?.loan_balance?.defaults?.is_home_price === 'N' &&
                !home_price &&
                loan_price
              ) {
                const down_price = convert_percent(
                  loan_price,
                  down_payment,
                  down_payment_type
                )
                home_price = loan_price + down_price
              }
              return {
                ...prev,
                ['loan_balance']: {
                  ...prev?.['loan_balance'],
                  values: {
                    ...prev?.loan_balance?.values,
                    loan_amount: loan_price
                      ? format_currency(loan_price, false, 2)
                      : null,
                    home_price: home_price
                      ? format_currency(home_price, false, 2)
                      : null,
                  },
                  defaults: {
                    ...prev?.['loan_balance']?.defaults,
                    ['is_home_price']:
                      prev?.['loan_balance']?.defaults?.is_home_price === 'Y'
                        ? 'N'
                        : 'Y',
                  },
                },
              }
            })
          }}
        />
      </div>
      <div className='px-3'>{get_inputs()}</div>
      <div className='flex justify-center w-full'>
        {error?.show_error ? (
          <div className={`${uiStyles.failure_msg} mb-[10px]`}>
            {error?.msg}
          </div>
        ) : (
          ''
        )}
      </div>

      {calc?.loan_balance?.data_options?.calculated && !type ? (
        <CalcCircle
          value={calc?.loan_balance?.data_options?.loan_balance}
          use_val={true}
          handle_val={(value) => {
            saveCalc((prev) => ({
              ...prev,
              sell: {
                ...prev?.sell,
                values: {
                  ...prev?.sell?.values,
                  loan_balance: format_currency(value, false, 0),
                },
              },
            }))
            if (setShowModal) {
              setShowModal(false)
            }
          }}
        />
      ) : (
        <button
          className={`${uiStyles.hover_btn} w-full rounded-none`}
          onClick={() => {
            calculate_estimate()
          }}
        >
          Calculate
        </button>
      )}
    </div>
  )
}

export default CalcLoanBalance
