import { useState, useEffect } from 'react'
import { HiArrowDown, HiArrowUp } from 'react-icons/hi2'
import ListItem from '../ui/ListItem'
import PropertyItem from '../ui/PropertyItem'
import SwipeConfig from '../ui/SwipeConfig'

const List = ({
  list,
  click_me = false,
  no_items_msg = 'No Results',
  swipe_right = () => {},
  swipe_left = () => {},
  show_swipe_left = false,
  show_swipe_right = true,
  config_width = 44,
  reset_slider = false,
  property_component = false,
  add_arrow_order = false,
  handle_arrow = async () => {},
  fill,
  my_listings_look = false,
}) => {
  const [selected, setSelected] = useState(1)
  useEffect(() => {
    const check_selected = () => {
      if (reset_slider) {
        setSelected(-1)
      }
    }
    check_selected()
    return () => {
      check_selected()
    }
  }, [list])

  return list?.length ? (
    list?.map((item, index) => (
      <div className='' key={item?.default_uuid || item?.event_id || index}>
        <SwipeConfig
          key={index}
          widthConfigRight={config_width}
          index={index}
          selectedIndex={selected}
          setSelectedIndex={setSelected}
          RightComponents={
            show_swipe_right && item?.swipe_right
              ? () => item?.swipe_right(item)
              : ''
          }
          LeftComponents={show_swipe_left ? () => swipe_left(item) : ''}
          className='mb-[2px]'
        >
          {property_component ? (
            <PropertyItem
              listing={item?.listing || item}
              my_listings_look={my_listings_look}
            />
          ) : (
            <div>
              <ListItem
                image_flag={item?.image_flag}
                image_flag_color={item?.image_flag_color}
                title={item?.title || ''}
                subtitle={item?.subtitle || ''}
                sub_content={item?.sub_content || ''}
                sub_content_gap={item?.sub_content_gap || 'mt-[0]'}
                content={item?.content || ''}
                content_link={item?.content_link || ''}
                content_link_name={item?.content_link_name || ''}
                image={item?.photo || item?.image || ''}
                hex_code={item?.hex_code || ''}
                fill={fill}
                click_me={item?.click_me || click_me}
                select_on_image={click_me}
                select={
                  item?.click_me || click_me ? item?.select || null : null
                }
              />
            </div>
          )}
        </SwipeConfig>
        {add_arrow_order && index + 1 !== list?.length ? (
          <div className='flex justify-center gap-[30px] py-[4px]'>
            <div
              className='cursor-pointer'
              onClick={() => {
                handle_arrow(item, list, index, 'up')
              }}
            >
              <HiArrowUp />
            </div>

            <div
              className='cursor-pointer'
              onClick={() => {
                handle_arrow(item, list, index, 'down')
              }}
            >
              <HiArrowDown />
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    ))
  ) : (
    <div className='px-3'>{no_items_msg}</div>
  )
}

export default List
