import React, { useCallback, useEffect, useRef, useState } from 'react'

import Wrapper from '../../layout/Wrapper'
import SubNav from '../../SubNav'
import Transaction from '../../ui/Transaction'

import SignPad from '../../ui/SignPad'
import { useParams } from 'react-router'
import { useTransactions } from '../../../contexts/TransactionsContext'
import { useDocuments } from '../../../contexts/DocumentsContext'
import { SpringSystem, MathUtil } from 'rebound'
import { FaCompress, FaExpand } from 'react-icons/fa'
import { useAuth0 } from '@auth0/auth0-react'
import { ServiceTransactions } from '../../../services/ServiceTransactions'
import { useQuery } from '@tanstack/react-query'
import { useProfile } from '../../../contexts/UserContext'
export default function DocumentViewer() {
  const { getAccessTokenSilently } = useAuth0()
  const { profile } = useProfile()
  const { fileid: saved_file_id, saveFileID, saveDocuments } = useDocuments()
  const [showPad, setShowPad] = useState(false)
  const [docFullView, setDocFullView] = useState(false)
  const { fileid, documentId } = useParams()
  const TransactionsfetchController = useRef(null)
  const { documents, getDocumentByID } = useDocuments()
  const { getTransactionByID } = useTransactions()
  const currentTransaction = getTransactionByID(fileid)

  const currentDocument = documents && getDocumentByID(documentId)

  const getDocuments = useCallback(async () => {
    console.log('fetching documents')
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUDIENCE,
    }).catch((err) => null)

    const serviceTransactions = new ServiceTransactions(token)
    if (!TransactionsfetchController.current) {
      TransactionsfetchController.current =
        serviceTransactions.getController().controller
    }
    return serviceTransactions.get(fileid, '/documents')
  }, [fileid, getAccessTokenSilently])

  const documentQuery = useQuery({
    queryKey: ['documents', fileid],
    queryFn: getDocuments,
    refetchOnWindowFocus: false,
    enabled: !!profile && !documents && !!fileid,
  })

  const onClickDocument = (docId) => {
    setShowPad(true)
  }

  const iframe = currentDocument && (
    <iframe
      title={currentDocument.DOC_NAME}
      src={currentDocument.DOC_LINK}
      width='1400'
      height='100%'
      className='absolute left-0 right-0 top-0  '
    />
  )

  useEffect(() => {
    if (documentQuery.data && fileid !== saved_file_id) {
      saveDocuments(documentQuery.data)
      saveFileID(fileid)
    }
    return () =>
      TransactionsfetchController.current &&
      TransactionsfetchController.current.abort()
  }, [saveDocuments, documentQuery.data, fileid, saved_file_id, saveFileID])

  console.log(currentTransaction)
  return (
    <>
      <Wrapper>
        <SubNav />
        {currentTransaction && (
          <Transaction
            title=''
            fileid={currentTransaction.ext_id}
            address={currentTransaction.address1}
            city={currentTransaction.city}
            state={currentTransaction.state}
            isClosed={currentTransaction.close_disb_dt}
            docName={currentDocument.DOC_NAME}
            escrowOfficer={currentTransaction.escrow_officer}
            lender={currentTransaction.lender}
            postal={currentTransaction.postal_code}
          />
        )}

        {/* {showPad && <SignPad onClose={setShowPad.bind(this, false)} />} */}
        <div className='relative h-[calc(100vh-170px)] pt-5 border border-t-8 border-gray-200'>
          <div className='absolute left-0 top-0 right-0 bottom-0 overflow-auto w-full'>
            {iframe}
          </div>
          <div className='absolute bottom-5 right-5 w-8 h-8 rounded-full bg-accent-250/80 flex items-center justify-center shadow-2xl shadow-accent-250'>
            <FaExpand
              className='  text-white text-xl cursor-pointer'
              onClick={() => setDocFullView(true)}
            />
          </div>
        </div>
      </Wrapper>

      {docFullView && (
        <div className='fixed left-0 top-0 w-full h-full bg-white'>
          <div className='overflow-scroll absolute left-0 right-0 top-0 bottom-0'>
            <iframe
              title={currentDocument.DOC_NAME}
              src={currentDocument.DOC_LINK}
              width='100%'
              height='100%'
              className='absolute left-0 right-0 top-0  min-w-[1400px]'
            />
          </div>
          <FaCompress
            className='rounded-full absolute bottom-3 right-3 w-10 h-10 bg-accent-250/80 p-2   text-white text-3xl cursor-pointer shadow-lg'
            onClick={() => setDocFullView(false)}
          />
        </div>
      )}
    </>
  )
}
