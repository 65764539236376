import React, { memo } from 'react';

function SubTitle({ children, className = '' }) {
  return (
    <h2
      className={` font-base text-primary text-lg font-semibold ${className}`}
    >
      {children}
    </h2>
  );
}

export default memo(SubTitle);
