import React, { useState, useEffect } from 'react'
import uiStyles from '../../ui/uiStyles'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import LabelInput from '../../form/LabelInput'
import checkmark from '../../../assets/images/checkmark.svg'
import SmallLoading from '../../ui/SmallLoading'
import Modal from '../../Modal'
import Forbidden from '../../admin/Forbidden'
import { useClaims } from '../../../contexts/ClaimsContext'
const RTP = () => {
  const { axiomFetch } = useAxiomFetch()
  const { claims } = useClaims()
  const character_limit = 200
  const [isLoading, setIsLoading] = useState(false)
  const [state, setState] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [messageSent, setMessageSent] = useState({ status: 'done' })

  const handle_msg = (status, message, type, close) => {
    setMessageSent({ ...messageSent, status, message, type })
    if (close) {
      setTimeout(() => {
        setMessageSent({ ...messageSent, status: 'done', message: '' })
      }, 3000)
    }
  }
  const handleChange = (e) => {
    let { name, value } = e.target
    if (name === 'route_num') {
      value = value?.replace(/\D/g, '').slice(0, 9)
    }
    if (name === 'acct_num') {
      value = value?.replace(/[[^0-9*]]/g, '')
    }
    if (['acct_num', 'route_num'].includes(name)) {
      setState((prev) => ({ ...prev, reset_rtp: true }))
    }
    if (name === 'rtp_amount') {
      console.log(value)
      if (value === '0') {
        value = '0.'
      }
      let num = value?.split('0.')[1]
      num = num?.replace(/\D/g, '')
      value = '0.' + num
      value = value.slice(0, 4)
    }
    console.log(name, value)
    setState((prev) => ({
      ...prev,
      instruct: {
        ...prev.instruct,
        [name]: value,
      },
    }))
  }

  const handle_save = async (e) => {
    try {
      console.log(state.reset_rtp)
      console.log(e.target.id)
      if (state?.reset_rtp && e.target.id !== 'save') {
        setShowModal(true)
        return
      }
      if (e.target.id === 'save') {
        setState((prev) => ({
          ...prev,
          instruct: { ...state?.instruct, rtp_verified_dt: null },
        }))
      }
      setShowModal(false)
      handle_msg('loading_msg', 'saving...', 'save')
      await axiomFetch('/escrow/rtp/save', 'POST', state?.instruct)
      handle_msg('success_msg', 'Saved!', 'save', true)
    } catch (error) {
      console.log(error)
    }
  }

  const handle_test_rtp = async (e) => {
    setState((prev) => ({ ...prev, rtp_test_sent: 'sending rtp...' }))
    let random_num = Math.random()
    random_num = Math.abs((0.8 - random_num.toFixed(2)).toFixed(2))
    if (random_num <= 0) {
      random_num = 0.01
    }
    await axiomFetch('/escrow/rtp/send/test', 'POST', { random_num })
    setState((prev) => ({ ...prev, rtp_test_sent: 'sent rtp' }))
  }

  const confirm_rtp = async (e) => {
    handle_msg('loading_msg', 'confirming rtp...', 'confirm')
    const data = await axiomFetch('/escrow/rtp/confirm', 'POST', {
      confirm_num: state?.instruct?.rtp_amount,
    })

    if (data?.status === 'Complete') {
      handle_msg('success_msg', 'RTP Confirmed', 'confirm', true)
      setState((prev) => ({
        ...prev,
        instruct: {
          ...state?.instruct,
          rtp_verified_dt: new Date().toISOString(),
        },
      }))
    } else if (data?.status === 'Rejected' || data?.error) {
      handle_msg('failure_msg', 'RTP was rejected', 'confirm', true)
    } else {
      handle_msg(
        'loading_msg',
        `Still confirming RTP...${data?.status ? data?.status : ''}`,
        'confirm',
        true
      )
    }
  }

  useEffect(() => {
    const controller = new AbortController()
    const get_rtp = async () => {
      setIsLoading(true)
      const data = await axiomFetch(
        '/escrow/rtp',
        'GET',
        {},
        {},
        false,
        controller.signal
      )

      setState((prev) => ({
        ...prev,
        instruct: {
          ...data?.[0],
          acct_num: data?.[0]?.acct_num
            ? data?.[0]?.acct_num
                .slice(-4)
                .padStart(data?.[0]?.acct_num.length, '*')
            : data?.[0]?.acct_num,
        },
        rtp_test_sent: false,
        memo_count:
          character_limit -
          (data?.[0]?.reference_memo?.slice(0, character_limit).length || 0),
      }))
      if (!controller.signal.aborted) {
        setIsLoading(false)
      }
    }

    get_rtp()

    return () => {
      controller?.abort()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const validate_rtp = async () => {
      const response = await fetch(
        `https://nodejsprod01.driggstitle.com/usbank/rtpCheckNetwork?routing_num=${state?.instruct?.route_num}`,
        {
          signal: controller.signal,
        }
      )
      const data = await response.json()
      setState((prev) => ({ ...prev, rtp_eligible: data?.rtpEligible }))
    }
    if (state?.instruct?.route_num?.length === 9) {
      validate_rtp()
    } else {
      if (state?.instruct?.rtp_eligible) {
        setState((prev) => ({ ...prev, rtp_eligible: false }))
      }
    }

    return () => {
      controller?.abort()
    }
    // eslint-disable-next-line
  }, [state?.instruct?.route_num])

  // if (claims?.client_proxy_login) {
  //   return <Forbidden />
  // }
  return (
    <>
      {isLoading ? (
        <SmallLoading />
      ) : (
        <div className='p-3'>
          <LabelInput
            top_label={'Payee'}
            name={`payee`}
            value={state?.instruct?.payee || ''}
            handleChange={handleChange}
          />
          <LabelInput
            top_label={'Account Name'}
            name={`acct_name`}
            value={state?.instruct?.acct_name || ''}
            handleChange={handleChange}
          />
          <LabelInput
            top_label={'Account Number'}
            name={`acct_num`}
            value={state?.instruct?.acct_num || ''}
            handleChange={handleChange}
          />
          <LabelInput
            top_label={'Bank Name'}
            name={`bank_name`}
            value={state?.instruct?.bank_name || ''}
            handleChange={handleChange}
          />
          <LabelInput
            top_label={'Routing Number'}
            name={`route_num`}
            value={state?.instruct?.route_num || ''}
            handleChange={handleChange}
          />
          <div className='mb-[10px]'>
            Eligible RTP Routing #: {state?.rtp_eligible ? 'YES' : 'NO'}
          </div>
          <textarea
            className={`${uiStyles.text_area} mt-[10px] h-[154px]`}
            placeholder='reference/memo...'
            value={
              state?.instruct?.reference_memo?.slice(0, character_limit) || ''
            }
            onChange={(e) => {
              if (e.target.value.length <= character_limit) {
                setState((prev) => ({
                  ...prev,
                  memo_count: character_limit - e.target.value.length,
                  instruct: {
                    ...prev?.instruct,
                    reference_memo: e?.target?.value,
                  },
                }))
              }
            }}
          ></textarea>
          <span className={`${uiStyles.text_gray}`}>
            {state?.memo_count} characters left
          </span>
          <div className='flex justify-end gap-[10px]'>
            <button
              className={`${uiStyles.green_btn} ${
                messageSent.status === 'done'
                  ? ''
                  : 'from-gray-300 to-gray-500 cursor-not-allowed'
              }`}
              onClick={handle_save}
              disabled={messageSent.status !== 'done' ? true : false}
            >
              Save
            </button>
          </div>
          {messageSent.type === 'save' ? (
            <div className={`${uiStyles[messageSent.status]} mb-[20px]`}>
              {messageSent.message}
            </div>
          ) : (
            ''
          )}

          <div className={`${uiStyles.line} mt-[10px]`}></div>
          <>
            {state?.instruct?.rtp_verified_dt === '2001-01-01T00:00:00.000Z' ||
            state?.instruct?.rtp_verified_dt === null ||
            !state?.instruct?.rtp_verified_dt ? (
              <div>
                <div>
                  <h3 className={`${uiStyles.text_black_subtitle} mt-[15px]`}>
                    RTP Verification
                  </h3>

                  <div className='flex justify-end gap-[10px]'>
                    <button
                      className={`${uiStyles.green_btn} ${
                        state?.rtp_eligible &&
                        state?.instruct?.acct_num &&
                        !state?.rtp_test_sent
                          ? ''
                          : 'from-gray-300 to-gray-500 cursor-not-allowed'
                      }`}
                      disabled={
                        state?.rtp_eligible && state?.instruct?.acct_num
                          ? false
                          : true
                      }
                      onClick={handle_test_rtp}
                    >
                      Send Test RTP
                    </button>
                  </div>
                  <div className='flex justify-end'>
                    {state?.rtp_test_sent ? (
                      <span className='text-center'>
                        {state?.rtp_test_sent}
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                  <LabelInput
                    top_label={'RTP Sent Amount'}
                    name={`rtp_amount`}
                    value={state?.instruct?.rtp_amount || '0.'}
                    handleChange={handleChange}
                  />
                </div>
                <div className='flex justify-end gap-[10px] mt-[10px]'>
                  <button
                    className={`${uiStyles.green_btn} ${
                      state?.rtp_eligible && state?.instruct?.rtp_amount
                        ? ''
                        : 'from-gray-300 to-gray-500 cursor-not-allowed '
                    }`}
                    onClick={confirm_rtp}
                  >
                    Confirm RTP
                  </button>
                </div>
                {messageSent.type === 'confirm' ? (
                  <div className={`${uiStyles[messageSent.status]} mb-[20px]`}>
                    {messageSent.message}
                  </div>
                ) : (
                  ''
                )}
              </div>
            ) : (
              <div className='flex items-baseline gap-[10px]'>
                <h3 className={`${uiStyles.text_black_subtitle} mt-[15px]`}>
                  RTP Verified{' '}
                </h3>
                <img width={'20px'} alt='checkmark' src={checkmark} />
              </div>
            )}
          </>
        </div>
      )}
      {showModal ? (
        <Modal
          modalName={`rtp_change_confirmation`}
          height={'h-[300px]'}
          showModal={showModal}
          setShowModal={setShowModal}
          showClose={true}
        >
          <div>
            Are you sure you want to change Account Number or Routing Number?
            Doing so will remove your RTP verification.
          </div>
          <div className='flex justify-end gap-2'>
            <button
              onClick={() => {
                setShowModal(false)
              }}
              className={`${uiStyles.white_btn}`}
            >
              Cancel
            </button>
            <button
              onClick={handle_save}
              id='save'
              className={`${uiStyles.green_btn}`}
            >
              Save
            </button>
          </div>
        </Modal>
      ) : (
        ''
      )}
    </>
  )
}

export default RTP
