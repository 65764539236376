import React from 'react'
import { AskAxiom } from '../AI/AskAxiom'
import { CopyText } from '../AI/CopyText'

const AiCard = ({ state, setState }) => {
  console.log('hello')
  return (
    <div className='px-3'>
      {/* <h1 className={`${uiStyles.text_black_title} mb-[10px]`}>AI Result</h1>
      <textarea
        className={`${uiStyles.text_area} min-h-[400px]`}
        value={state?.state?.message}
        onChange={(e) => {
          setState((prev) => ({
            ...prev,
            state: { ...prev?.state, message: e.target.value },
          }))
        }}
      ></textarea> */}

      <>
        <AskAxiom />
        <CopyText type='AI Result' text_to_copy={state?.state?.message} />
      </>
    </div>
  )
}

export default AiCard
