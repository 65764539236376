import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSales } from '../../contexts/SalesContext'
import { getDateString, set_storage, validate_number } from '../../helpers'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import LabelInput from '../form/LabelInput'
import { Schedule } from '../form/Schedule'
import Select from '../form/Select'
import uiStyles from '../ui/uiStyles'

const CliAppointmentSet = ({
  setShowModal,
  type = 'first',
  pre_func = () => {},
}) => {
  const { sales, saveSales } = useSales()
  const { axiomFetch } = useAxiomFetch()
  const navigate = useNavigate()
  const save_appointment = (key, value) => {
    saveSales((prev) => ({
      ...prev,
      curr_client: {
        ...prev?.curr_client,
        appointment: { ...prev?.curr_client?.appointment, [key]: value },
      },
    }))
  }
  return (
    <div
      className={`px-3 flex flex-col ${
        type === 'first' ? 'h-[350px] justify-between' : ''
      }`}
    >
      <div>
        {!sales?.curr_client?.appointment?.appointment_set &&
        type === 'first' ? (
          <div>
            <Select
              name='failed_reason'
              left_label={'No Appointment Reason'}
              left_label_width='w-[400px]'
              value={sales?.curr_client?.appointment?.failed_reason || '0'}
              handler={(e) => {
                save_appointment('failed_reason', e.target.value)
              }}
            >
              <option key='0' value={'0'}>
                None
              </option>
              {sales?.sm_appt_fail_types?.map((item) => {
                return (
                  <option key={item?.type_id} value={item?.type_id?.toString()}>
                    {item?.type_name}
                  </option>
                )
              })}
            </Select>
          </div>
        ) : (
          ''
        )}
        <div className='mt-[10px]'>
          <Schedule
            left_label={
              type === 'third'
                ? 'Cust. Sol. Appt.'
                : sales?.curr_client?.appointment?.appointment_set
                ? 'Appt. Date'
                : 'Follow-up Date'
            }
            left_label_width={'w-[400px]'}
            name={'date'}
            show_time={false}
            min={getDateString({ date: new Date(), format: 'yyyy-mm-dd' })}
            schedule_date={
              sales?.curr_client?.appointment?.date ||
              !sales?.curr_client?.appointment?.appointment_set
                ? new Date(
                    new Date().setDate(
                      new Date().getDate() +
                        (new Date()?.getDay() === 5 ? 3 : 1)
                    )
                  )?.toISOString()
                : new Date()?.toISOString()
            }
            handle_change={(e) => {
              save_appointment('date', e.target.value)
            }}
          />
        </div>
        <label className={uiStyles.label}>Tell The Story:</label>
        <textarea
          onChange={(e) => {
            save_appointment('notes', e.target.value)
          }}
          className={uiStyles.text_area}
          value={sales?.curr_client?.appointment?.notes}
        ></textarea>
      </div>
      <div
        className={`flex justify-end gap-[10px] ${
          type === 'first' ? 'mt-[40px]' : 'mt-[10px]'
        }`}
      >
        {type === 'first' ? (
          <button
            className={`${uiStyles.white_btn} flex-1`}
            onClick={() => {
              if (setShowModal) {
                setShowModal(false)
              }
            }}
          >
            Back
          </button>
        ) : (
          ''
        )}
        <button
          className={`${uiStyles.hover_btn} flex-1`}
          onClick={async () => {
            pre_func()
            setTimeout(async () => {
              await axiomFetch('/sales/what_next/baseline/appt', 'POST', {
                client: sales?.curr_client?.data,
                appt: {
                  ...sales?.curr_client?.appointment,
                  appointment_set:
                    type === 'third'
                      ? true
                      : sales?.curr_client?.appt?.appointment_set,
                  failed_reason: validate_number(
                    sales?.curr_client?.appointment?.failed_reason
                  ),
                  date:
                    sales?.curr_client?.appointment?.date ||
                    getDateString({ date: new Date(), format: 'yyyy-mm-dd' }),
                  position_stmt_id:
                    sales?.powerful_questions?.[
                      sales?.curr_client?.curr_quest || 0
                    ]?.qst_id,
                },
              })
            }, 0)
            if (
              sales?.what_next_clients?.length < 2 &&
              ((sales?.curr_client?.appointment?.date &&
                sales?.curr_client?.appointment?.date !==
                  getDateString({ date: new Date(), format: 'yyyy-mm-dd' })) ||
                (!sales?.curr_client?.appointment?.appointment_set &&
                  type === 'first'))
            ) {
              saveSales((prev) => {
                const sales_data = {
                  ...prev,
                  what_next_clients: prev?.what_next_clients?.filter(
                    (item) => item.cont_id !== sales?.curr_client?.data?.cont_id
                  ),
                  selected: 'what_next',
                }
                set_storage('sales', sales_data)
                return sales_data
              })
              return navigate('/user/sales/what_next')
            } else if (
              (sales?.curr_client?.appointment?.date &&
                sales?.curr_client?.appointment?.date !==
                  getDateString({ date: new Date(), format: 'yyyy-mm-dd' })) ||
              (!sales?.curr_client?.appointment?.appointment_set &&
                type === 'first')
            ) {
              saveSales((prev) => {
                const sales_data = {
                  ...prev,
                  what_next_clients: prev?.what_next_clients?.filter(
                    (item) => item.cont_id !== sales?.curr_client?.data?.cont_id
                  ),
                  curr_client: {
                    data: prev?.what_next_clients
                      ?.filter(
                        (item) =>
                          item.cont_id !== sales?.curr_client?.data?.cont_id
                      )
                      ?.sort(
                        (a, b) =>
                          validate_number(b?.total_score) -
                          validate_number(a?.total_score)
                      )?.[0],
                    selected: 'baseline',
                  },
                }
                set_storage('sales', sales_data)
                return sales_data
              })
            } else if (
              sales?.curr_client?.appointment?.appointment_set ||
              type === 'third'
            ) {
              saveSales((prev) => {
                const client_data = {
                  ...prev?.curr_client?.data,
                  total_score: 0,
                  cur_funnel_status:
                    type === 'first'
                      ? prev?.curr_client?.data?.cur_funnel_status > 1
                        ? prev?.curr_client?.data?.cur_funnel_status
                        : 1
                      : 3,
                  base_description:
                    type === 'first'
                      ? prev?.curr_client?.data?.cur_funnel_status > 1
                        ? prev?.curr_client?.data?.base_description
                        : '1st: Commitment for an appointment'
                      : '3rd: They did engage',
                }
                const sales_data = {
                  ...prev,
                  what_next_clients: [
                    ...prev?.what_next_clients?.filter(
                      (item) =>
                        item.cont_id !== sales?.curr_client?.data?.cont_id
                    ),
                    client_data,
                  ],
                  curr_client: {
                    ...prev?.curr_client,
                    nav_base: type === 'first' ? 1 : 3,
                    refresh: validate_number(prev?.curr_client?.refresh) + 1,
                    data: { ...client_data },
                    selected: 'baseline',
                  },
                }
                set_storage('sales', sales_data)
                return sales_data
              })
            } else {
            }
            if (setShowModal) {
              setShowModal(false)
            }
          }}
        >
          {type === 'third' ? 'Move to Third' : 'Next'}
        </button>
      </div>
    </div>
  )
}

export default CliAppointmentSet
