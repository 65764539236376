import React, { useRef, useState } from 'react'
import UserThumb from '../../../assets/images/profile-silhouette.svg'
// import Checkbox from './form/Checkbox';
import { useProfile } from '../../../contexts/UserContext'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import uiStyles from '../../ui/uiStyles'
import ImageUpload from '../../form/ImageUpload'
import axiom_1 from '../../../assets/images/AXIOM_1.png'
import Checkbox from '../../form/Checkbox'
const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]
export default function ApproveRequest({
  listing,
  onClose,
  currentOrder,
  setCurrentOrder,
  setModalType,
  setShowModal,
}) {
  console.log(currentOrder)
  const { axiomFetch } = useAxiomFetch()
  const refOrderRequest = useRef(null)
  //   const refOrderRequest2 = useRef(null);
  const [orderRequest, setOrderRequest] = useState({
    // quantity: currentOrder?.def_ax_qty,
    req_notes: '',
    files: [],
  })
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState('')
  const { profile } = useProfile()

  const request = async (event) => {
    setIsLoading('Sending Request...')
    event.preventDefault()
    // const address = `Address:\n${listing?.PROP_STREET ? listing?.PROP_STREET + '\n' : ''}${listing.PROP_CITY ? listing.PROP_CITY + ', ' : ''}${listing?.PROP_ST ? listing.PROP_ST + ' ' : ''}${listing?.PROP_ZIP ? listing.PROP_ZIP : ''}`

    //     const message = `
    // \n${currentOrder?.axiom_name || currentOrder?.type_name} ${currentOrder?.req_mls_link === 'Y' ? '\n\n' + address : ''}

    // Quantity: ${orderRequest?.quantity || 1}

    // Order Request Details: ${
    //       refOrderRequest.current && refOrderRequest.current.value
    //     }

    // Will Pickup at Printshop: ${
    //       refOrderRequest2.current && refOrderRequest2.current.checked
    //         ? 'Yes'
    //         : 'No'
    //     }`;

    // if (currentOrder?.req_mls_link === 'Y') {
    const body = {
      // message,
      files: orderRequest?.files,
      req_id: currentOrder?.REQ_ID,
      // mls_link: orderRequest?.mls_link || '',
      // mls_id: listing?.MLS_ID,
      req_status: '5',
      req_desc: `Update: ${
        monthNames[new Date().getMonth()]
      } ${new Date().getDate()} \n ${
        refOrderRequest?.current.value
      } \n --------\n ${currentOrder?.REQ_DESC}`,
      // shop: refOrderRequest2.current.checked ? 'Yes': 'No',
    }
    console.log(body)
    console.log(JSON.stringify(body))
    const data = await axiomFetch(
      `/marketing/materials/${currentOrder?.REQ_ID}`,
      'PATCH',
      body,
      {},
      true
    )
    console.log(data)
    // } else {
    //   await axiomFetch('/marketing/materials/order', 'POST', {material: message})
    // }
    setIsLoading('Sent')
    setTimeout(() => {
      setIsLoading('')
    }, [2000])
  }

  return (
    <div>
      <div className='flex justify-end mb-3'>
        {/* <HiOutlineTrash
          onClick={() => {
            onClose();
          }}
          className="backdrop text-xl text-gray-500"
        /> */}
      </div>
      <div className='px-2'>
        <div className='flex mb-3'>
          <div className='w-[40px] h-[40px] mt-1'>
            {(profile.PHOTO_LINK || profile?.account?.photo_url) && (
              <img
                src={
                  profile.PHOTO_LINK || profile?.account?.photo_url || UserThumb
                }
                alt='user thumbnail'
                className='w-full h-full rounded-full object-cover'
              />
            )}
          </div>
          <div className='flex-1 pl-3'>
            <h3 className='text-base text-primary'>{profile.CONT_EMAIL}</h3>
            {/* <p className="text-sm text-gray-500">to adamd@driggstitle.com</p> */}
          </div>
          <div className=' text-sm w-[50px] text-gray-500 overflow-hidden overflow-ellipsis whitespace-nowrap'>
            {`${monthNames[new Date().getMonth()]} ${new Date().getDate()}`}
          </div>
        </div>
        <div className='mb-4'>
          {(currentOrder?.PRODUCT_REQ || currentOrder?.AXIOM_NAME) && (
            <h2 className='font-semibold text-gray-700 text-xl leading-7'>
              Order:{' '}
              {currentOrder?.AXIOM_NAME || currentOrder?.PRODUCT_REQ || ''}
            </h2>
          )}
          {currentOrder?.PRODUCT_REQ && (
            <img
              className='w-full h-full object-cover'
              src={
                currentOrder?.AXIOM_PHOTO_LINK ||
                currentOrder?.IMAGE_LINK ||
                axiom_1
              }
            />
          )}
          {/* {currentOrder?.req_mls_link === 'Y' ? <> */}
          {/* <p className="text-gray-700 text-xl font-light mb-[20px] leading-6">
            {listing && listing.PROP_STREET && listing.PROP_STREET},
            {listing && listing.PROP_CITY && listing.PROP_CITY},
            {listing && listing.PROP_ST && listing.PROP_ST},
            {listing && listing.PROP_ZIP && listing.PROP_ZIP}
            
          </p> */}

          {/* </> : ''} */}
        </div>
        <div className='flex flex-col gap-[3px]'>
          <label className={uiStyles.label}>{`Quantity`}</label>
          <input
            className={`${uiStyles.input} w-[60px]`}
            value={currentOrder?.REQ_QUANTITY}
            type='number'
            disabled={true}
          />
        </div>

        {/* {currentOrder?.sign_rider === 'Y' ? (
          <>
            <label className={uiStyles.label}>Flex MLS Link</label>
            <input
              className={`${uiStyles.input} mb-[10px]`}
              value={orderRequest?.mls_link}
              placeholder='https://www.flexmls.com/share/8H2wN/619-W-DANA-AVE-Mesa-AZ-85210'
              onChange={(e) => {
                setOrderRequest((prev) => ({ ...prev, mls_link: e.target.value }))
              }}
              type='text'
            />
          </>
        ) : (
          ''
        )} */}
        {!currentOrder?.AXIOM_BLURB ? (
          <></>
        ) : (
          <div className='mt-[10px] overflow-scroll h-[60px]'>
            <p>Included in your order:</p>
            <ul className='list-disc pl-[30px]'>
              <li>{currentOrder?.AXIOM_BLURB}</li>
            </ul>
          </div>
        )}
        {/* <Checkbox label='Will Pickup at Printshop' /> */}
        {/* <span className='text-[12px]'>14287 N. 87th Street, Scottsdale, AZ 85260</span> */}
        {isLoading ? (
          <div className='flex justify-end mt-[15px]'>
            <div
              className={
                isLoading === 'Sent'
                  ? uiStyles.success_msg
                  : uiStyles.loading_msg
              }
            >
              {isLoading}
            </div>
          </div>
        ) : (
          <div className='flex justify-end mt-[15px] gap-[10px]'>
            <button
              className={`${uiStyles.white_btn}`}
              onClick={() => {
                setShowModal(false)
              }}
            >
              Cancel
            </button>
            <button
              className={`${uiStyles.green_btn}`}
              onClick={() => {
                setModalType('pay')
              }}
            >
              Next
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
