import { useState, useEffect } from 'react'
import { FaCircleArrowUp, FaCircleArrowDown } from 'react-icons/fa6'
import { LuArrowDownNarrowWide, LuArrowUpNarrowWide } from 'react-icons/lu'
import { MdSwapVerticalCircle } from 'react-icons/md'
import { useSales } from '../../contexts/SalesContext'
import { set_storage, validate_number } from '../../helpers'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import SmallLoading from '../ui/SmallLoading'
import { SmallLoadingRel } from '../ui/SmallLoadingRel'
import uiStyles from '../ui/uiStyles'
import CliAppointmentSet from './CliAppointmentSet'

const SecondBase = ({ setShowModal, setModalType }) => {
  const { sales, saveSales } = useSales()
  const { axiomFetch } = useAxiomFetch()
  const [showSvic, setShowSvic] = useState(true)
  const [showQuestions, setShowQuestions] = useState(true)

  const get_svic_cfg = () => {
    return sales?.sm_svic_cfg?.[0] || {}
  }

  const save_symptom = async (item, updates = {}) => {
    saveSales((prev) => {
      const sales_data = {
        ...prev,
        curr_client: {
          ...prev?.curr_client,
          baseline_data: {
            ...prev?.curr_client?.baseline_data,
            cont_power_quest_symptoms: [
              ...prev?.curr_client?.baseline_data?.cont_power_quest_symptoms?.filter(
                (cpq) => cpq.quest_id !== item?.quest_id
              ),
              { ...item, ...updates },
            ],
          },
        },
      }
      set_storage('sales', sales_data)
      return sales_data
    })
    await axiomFetch(
      '/sales/what_next/baseline/symptom',
      'POST',
      { symptom: { ...item, ...updates } },
      {},
      false,
      null
    )
  }

  useEffect(() => {
    sales?.curr_client?.baseline_data?.cont_power_quest_symptoms
      ?.filter((item) => item.ans_val === 'H')
      ?.sort((a, b) => validate_number(a?.qst_id) - validate_number(b?.qst_id))
      ?.sort((a, b) => validate_number(a?.ord) - validate_number(b?.ord))
      ?.map((item, index) =>
        save_symptom(item, {
          ord: index + 1,
          // install_date: new Date()?.toISOString(),
          item_ord_dt: new Date()?.toISOString(),
          cli_validated: 'V',
        })
      )
  }, [])

  return (
    <div className='px-3 mt-[10px]'>
      <div
        className='text-[18px] font-bold flex gap-[10px] cursor-pointer'
        onClick={() => {
          setShowSvic((prev) => !prev)
        }}
      >
        <p>SVIC</p>{' '}
        {showSvic ? <LuArrowUpNarrowWide /> : <LuArrowDownNarrowWide />}
      </div>
      {showSvic ? (
        <div>
          <div
            className='text-[16px] ml-[5px] flex items-center cursor-pointer mt-[5px]'
            onClick={() => {
              setShowQuestions((prev) => !prev)
            }}
          >
            <p>
              <span className='font-bold'>S</span>
              <span>ummarize</span>
            </p>
            <span className='text-sm ml-[4px]'>{' - Put them in order'}</span>
            <span className='ml-[10px]'>
              {showQuestions ? (
                <LuArrowUpNarrowWide />
              ) : (
                <LuArrowDownNarrowWide />
              )}
            </span>
          </div>
          {sales?.loading_baseline ? <SmallLoadingRel /> : ''}
          {showQuestions ? (
            <div className='ml-[10px] flex flex-col gap-[10px] mb-[15px]'>
              {sales?.curr_client?.baseline_data?.cont_power_quest_symptoms
                ?.filter((item) => item.ans_val === 'H')
                ?.sort(
                  (a, b) =>
                    validate_number(a?.qst_id) - validate_number(b?.qst_id)
                )
                ?.sort(
                  (a, b) =>
                    validate_number(a?.ord || 99) -
                    validate_number(b?.ord || 99)
                )
                ?.map((item, index) => {
                  return (
                    <div
                      key={
                        item?.qst_id + item?.ord + item?.dtl_id + item?.cpq_id
                      }
                    >
                      <div className={`${uiStyles.card_gray} mt-[5px]`}>
                        <p>
                          <span className='font-bold'>Cliff Notes:</span>{' '}
                          {item?.solution_info}
                        </p>
                        <p>
                          <span className='font-bold'>Product: </span>
                          {item?.service_title}
                        </p>
                        <p>
                          <span className='font-bold'>Root Cause: </span>
                          {item?.root_cause}
                        </p>
                        <p>
                          <span className='font-bold'>Quantify: </span>
                          {item?.quantify}
                        </p>
                        <p>
                          <span className='font-bold'>Symptom Notes: </span>
                          {item?.symptom_notes}
                        </p>
                        <p>
                          <span className='font-bold'>Original Question: </span>
                          {item?.pq_text?.replaceAll('¿', "'")}
                        </p>
                      </div>
                      {index + 1 !==
                      sales?.curr_client?.baseline_data?.cont_power_quest_symptoms?.filter(
                        (item) => item.ans_val === 'H'
                      )?.length ? (
                        <div className='flex gap-[10px] justify-evenly mt-[10px] cursor-pointer'>
                          <MdSwapVerticalCircle
                            size={30}
                            onClick={async () => {
                              const item_below =
                                sales?.curr_client?.baseline_data?.cont_power_quest_symptoms
                                  ?.filter((item) => item.ans_val === 'H')
                                  ?.sort(
                                    (a, b) =>
                                      validate_number(a?.qst_id) -
                                      validate_number(b?.qst_id)
                                  )
                                  ?.sort(
                                    (a, b) =>
                                      validate_number(a?.ord || 99) -
                                      validate_number(b?.ord || 99)
                                  )?.[index + 1]

                              save_symptom(item_below, {
                                ord: index + 1,
                              })
                              save_symptom(item, { ord: index + 2 })
                            }}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  )
                })}
            </div>
          ) : (
            ''
          )}
          <p className='text-[16px] ml-[5px]'>
            <span className='font-bold'>V</span>
            <span>alidate</span>
          </p>
          <p className='ml-[10px]'>{get_svic_cfg()?.val || ''}</p>
          <p className='text-[16px] ml-[5px]'>
            <span className='font-bold'>I</span>
            <span>mportant</span>
          </p>
          <p className='ml-[10px]'>{get_svic_cfg()?.imp || ''}</p>
          <p className='text-[16px] ml-[5px]'>
            <span className='font-bold'>C</span>
            <span>ommitment</span>
          </p>
          <p className='ml-[10px]'>{get_svic_cfg()?.cmt || ''}</p>
        </div>
      ) : (
        ''
      )}
      <div>
        <div className={`${uiStyles.line_3px} mt-[5px]`}></div>
        <p className='mt-[5px] font-bold'>{`What do you want to do next?`}</p>
        <p className='ml-[10px] italic mt-[5px]'>{`I want to get these items solved asap.`}</p>
        <p className='ml-[10px] mt-[5px]'>
          Great I am going to go back to the office and design your custom
          solution. When can I invite you to meet at the branch to go over the
          solutions?
        </p>
        <CliAppointmentSet type='third' pre_func={() => {}} />
      </div>
    </div>
  )
}

export default SecondBase
