import { useRef, useEffect, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { usePairwise } from '../../../contexts/PairwiseContext'
import {
  formatPhoneNumber,
  format_input,
  get_storage,
  set_storage,
  validate_number,
} from '../../../helpers'
import LabelInput from '../../form/LabelInput'
import { Schedule } from '../../form/Schedule'
import Select from '../../form/Select'
import uiStyles from '../../ui/uiStyles'
import {
  GoogleMap,
  Autocomplete,
  useJsApiLoader,
  CircleF,
  MarkerF,
  InfoBoxF,
  InfoWindowF,
} from '@react-google-maps/api'
import { libraries } from '../home/utils'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import { useProfile } from '../../../contexts/UserContext'
import { AiFillCloseCircle } from 'react-icons/ai'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { useMktReqs } from '../../../contexts/MktReqsContext'
import PairwiseDocumentsView from './PairwiseDocumentsView'
import RTP from '../TransactionsLobby/RTP'
import PairwiseRTP from './PairwiseRTP'
import Modal from '../../Modal'
import PairwiseIneligible from './PairwiseIneligible'
import { useClaims } from '../../../contexts/ClaimsContext'

const PairwiseDocumentsSign = () => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAdJBLTyoh5xMQujUK488i-am9syWuiG6A',
    libraries: libraries,
  })
  const { axiomFetch } = useAxiomFetch()
  const { pairwise, savePairwise } = usePairwise()
  const { generate_flyer_session, replace_fields, generate_pdf } = useMktReqs()
  const { profile } = useProfile()
  const { claims } = useClaims()
  const sigRef = useRef(null)

  const [messageSent, setMessageSent] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const handle_msg = (status, message, type, close) => {
    setMessageSent({ ...messageSent, status, message, type })
    if (close) {
      setTimeout(() => {
        setMessageSent({ ...messageSent, status: 'done', message: '' })
      }, validate_number(close?.toString()) || 3000)
    }
  }
  console.log('pairwise', pairwise)

  useEffect(() => {
    savePairwise((prev) => ({
      ...prev,
      documents: {
        ...prev?.documents,
        values: {
          ...prev?.documents.values,
          name: profile?.CONT_NAME,
          email: profile?.CONT_EMAIL,
          social_security_num:
            pairwise?.data?.pair_application?.social_security_num || '',
          bank_name:
            pairwise?.data?.pair_application?.bank_name ||
            pairwise?.data?.cli_wire_inst?.bank_name ||
            pairwise?.data?.cli_wire_inst?.bank_name_b,
          account_names:
            pairwise?.data?.pair_application?.account_names ||
            pairwise?.data?.cli_wire_inst?.acct_name ||
            pairwise?.data?.cli_wire_inst?.payee,
          account_number:
            pairwise?.data?.pair_application?.account_number ||
            pairwise?.data?.cli_wire_inst?.acct_num ||
            pairwise?.data?.cli_wire_inst?.acct_num_b,
          routing_number:
            pairwise?.data?.pair_application?.routing_number ||
            pairwise?.data?.cli_wire_inst?.route_num ||
            pairwise?.data?.cli_wire_inst?.route_num,
        },
        data_options: {
          ...prev?.data_options,
          address1: profile.BR_ADD_LINE1,
          formatted_address: `${profile.BR_ADD_LINE1 || ''} ${
            profile.BR_ADD_LINE2 || ''
          } ${profile.BR_ADD_CITY}, ${profile.BR_ADD_ST} ${
            profile.BR_ADD_POSTAL
          }`
            ?.replace(/\s+/g, ' ')
            .trim(),
          address2: profile.BR_ADD_LINE2,
          city: profile.BR_ADD_CITY,
          state: profile.BR_ADD_ST,
          zip: profile.BR_ADD_POSTAL,
        },
      },
    }))
  }, [pairwise?.data?.pair_application, profile])

  useEffect(() => {
    const controller = new AbortController()
    const validate_rtp = async () => {
      const response = await fetch(
        `https://nodejsprod01.driggstitle.com/usbank/rtpCheckNetwork?routing_num=${pairwise?.documents?.values?.routing_number}`,
        {
          signal: controller.signal,
        }
      )
      const data = await response.json()
      if (data?.name === 'AbortError') {
        return
      }
      savePairwise((prev) => ({
        ...prev,
        documents: {
          ...prev?.documents,
          data_options: {
            ...prev?.documents?.data_options,
            rtp_eligible: data?.rtpEligible,
          },
        },
      }))
    }
    if (pairwise?.documents?.values?.routing_number?.toString()?.length === 9) {
      validate_rtp()
    } else {
      savePairwise((prev) => ({
        ...prev,
        documents: {
          ...prev?.documents,
          data_options: {
            ...prev?.documents?.data_options,
            rtp_eligible: false,
          },
        },
      }))
    }

    return () => {
      controller?.abort()
    }
    // eslint-disable-next-line
  }, [pairwise?.documents?.values?.routing_number])

  const update_input = (options) => {
    const calculator_type = 'documents'
    let { name, value } = format_input(options)
    let type =
      options?.type || pairwise?.[calculator_type]?.types?.[`${name}`] || ''
    let max = validate_number(
      pairwise?.[calculator_type]?.maximums?.[`${name}`]
    )

    if (max && validate_number(value) > max) {
      value = max
    }
    if (type.includes('int_str')) {
      const length = validate_number(type)
      if (length) {
        value = validate_number(value.slice(0, length))
      } else {
        value = validate_number(value)
      }
    }
    savePairwise((prev) => {
      const result = {
        ...prev,
        [calculator_type]: {
          ...prev?.[calculator_type],
          values: { ...prev?.[calculator_type]?.values, [name]: value },
          types: { ...prev?.[calculator_type]?.types, [name]: type },
        },
      }
      return result
    })
  }

  const save_search_address = (place) => {
    const components = place?.address_components
    console.log('components', components)
    const location = place?.geometry?.location
    let address1_components = components?.filter((item) =>
      ['street_number', 'route'].includes(item?.types?.[0])
    )
    let address2_components = components?.filter((item) =>
      ['locality', 'administrative_area_level_1'].includes(item?.types?.[0])
    )
    let address3_components = components?.filter((item) =>
      ['subpremise'].includes(item?.types?.[0])
    )
    let address4_components = components?.filter((item) =>
      ['administrative_area_level_2'].includes(item?.types?.[0])
    )
    let address5_components = components?.filter((item) =>
      ['postal_code'].includes(item?.types?.[0])
    )
    let county = address4_components?.[0]?.long_name
      ?.replace('County', '')
      ?.trim()
    const search_address = {
      address1: [...(address1_components || [])]
        ?.map((item) => item?.long_name || '')
        ?.join(' '),
      address2: address3_components
        ?.map((item) => item?.long_name || '')
        ?.join(', '),
      city: address2_components?.[0]?.long_name,
      state: address2_components?.[1]?.short_name,
      zip: address5_components?.[0]?.long_name,
      lat: location?.lat(),
      lng: location?.lng(),
      county,
    }
    return search_address
  }

  const get_inputs = (calculator_type = 'documents') => {
    const types = pairwise?.[calculator_type]?.types || {}
    const names = pairwise?.[calculator_type]?.names || {}
    const placeholders = pairwise?.[calculator_type]?.placeholders || {}
    const values = pairwise?.[calculator_type]?.values || {}
    const maximums = pairwise?.[calculator_type]?.maximums || {}
    return Object.entries(values || {})
      ?.filter(([key, value]) => !['county'].includes(key))
      ?.map(([key, value]) => {
        const type = types?.[key]
        const name = names?.[key]
        const max = maximums?.[key]
        const placeholder = placeholders?.[key] || ''
        const label_width = 'w-[80%]'
        const initial_data = {
          left_label: name,
          left_label_width: label_width,
          name: key,
          value,
          placeholder,
        }

        initial_data.show_delete_all = true
        if (
          type === '%' ||
          type === '$' ||
          type === '%$' ||
          type === 'm' ||
          type === 'a' ||
          type === 'int'
        ) {
          initial_data.inputMode = 'decimal'
        }
        if (type === '%') {
          initial_data.show_percent = true
          initial_data.percent_value = '%'
          initial_data.handleChange = (e, type) => {
            update_input({ e, type, decimal: 3, original: value })
          }
        }
        if (type === '$') {
          initial_data.show_dollar = true
          initial_data.percent_value = ''
          initial_data.value = initial_data?.value
            ? `$${initial_data.value}`
            : initial_data?.value || ''
        }
        if (
          key === 'taxes' ||
          key === 'insurance_rate' ||
          key === 'hoa' ||
          key === 'other' ||
          key === 'utilities'
        ) {
          initial_data.show_percent = true
          initial_data.show_dollar = true
          initial_data.percent_value =
            pairwise?.[calculator_type]?.types?.[key] === '%' ? '%' : ''
          initial_data.dollar_percent =
            pairwise?.[calculator_type]?.types?.[key]
          if (key === 'hoa' || key === 'other' || key === 'utilities') {
            initial_data.toggle_values = ['a', 'm']
            initial_data.dollar_percent = 'm'
          }
          initial_data.left_label_width = 'w-[135%]'
          initial_data.delete_all_position = 'right-[75px]'
          initial_data.total = validate_number(
            pairwise?.[calculator_type]?.values?.home_price
          )
          initial_data.handleChange = (e, type) => {
            update_input({ e, type, decimal: 3, original: value })
          }
        }

        let result = (
          <LabelInput
            {...initial_data}
            handleChange={
              initial_data?.handleChange
                ? initial_data?.handleChange
                : (e) => {
                    update_input({ e, type, decimal: 2, original: value })
                  }
            }
          />
        )

        if (type === 'date') {
          result = (
            <Schedule
              left_label={name}
              left_label_width={label_width}
              name={key}
              show_time={false}
              schedule_date={value}
              handle_change={(e) => {
                update_input({ e, type, decimal: 2, original: value })
              }}
            />
          )
        }
        if (type === 'options') {
          const options = pairwise?.[calculator_type]?.option_values?.[key]
          const names = pairwise?.[calculator_type]?.option_names?.[key]
          result = (
            <Select
              left_label={name}
              left_label_width={label_width}
              no_margin={false}
              className='mb-[10px]'
              name={key}
              value={value || 'Conventional'}
              handler={(e) => {
                update_input({ e, type, decimal: 2, original: value })
              }}
              placeholder=''
            >
              {[...(options || [])]?.map((item, index) => {
                return (
                  <option key={item} value={item}>
                    {names?.[index]}
                  </option>
                )
              })}
            </Select>
          )
        }
        if (max) {
          result = (
            <div className='relative'>
              <div>{result}</div>
              <div className='absolute top-[13px] right-[30px]'>
                {`max (${max})`}
              </div>
            </div>
          )
        }
        if (key === 'name') {
          result = (
            <>
              {result}
              <div className='mb-[10px]'>
                {isLoaded && !pairwise?.documents?.loading_search ? (
                  <div className='mb-[4px] relative'>
                    <Autocomplete
                      onLoad={(autocomplete) => {
                        autocomplete.setFields([
                          'formatted_address',
                          'address_components',
                          'geometry',
                          'name',
                        ])
                        savePairwise((prev) => ({
                          ...prev,
                          documents: { ...prev?.documents, autocomplete },
                        }))
                      }}
                      onPlaceChanged={async () => {
                        let place = {}
                        if (pairwise.documents?.autocomplete) {
                          place = pairwise?.documents?.autocomplete?.getPlace()
                        }
                        if (place?.address_components) {
                          const search_address = save_search_address(place)
                          savePairwise((prev) => ({
                            ...prev,
                            documents: {
                              ...prev?.documents,
                              loading_search: true,
                            },
                          }))

                          savePairwise((prev) => {
                            const add_values = {
                              address1: search_address?.address1,
                              address2: search_address?.address2,
                              city: search_address?.city,
                              state: search_address?.state,
                              zip: search_address?.zip,
                            }
                            return {
                              ...prev,
                              documents: {
                                ...prev?.documents,
                                data_options: {
                                  ...prev?.documents?.data_options,
                                  search_address: save_search_address(place),
                                  formatted_address: place?.formatted_address,
                                  address_components: place?.address_components,
                                  location: place?.geometry?.location,
                                  ...add_values,
                                },
                                loading_search: false,
                              },
                            }
                          })
                        }
                      }}
                    >
                      <input
                        type='text'
                        className={`${uiStyles.input} pr-[15px] text-[14px]`}
                        placeholder='Address*'
                        value={
                          pairwise?.documents?.data_options
                            ?.formatted_address || ''
                        }
                        onChange={(e) => {
                          savePairwise((prev) => ({
                            ...prev,
                            documents: {
                              ...prev?.documents,
                              data_options: {
                                ...prev?.documents?.data_options,
                                formatted_address: e.target.value,
                              },
                            },
                          }))
                        }}
                      />
                    </Autocomplete>
                    <div
                      className='absolute top-[14px] right-[2px] cursor-pointer'
                      onClick={() => {
                        savePairwise((prev) => ({
                          ...prev,
                          documents: {
                            ...prev?.documents,
                            data_options: {
                              ...prev?.documents?.data_options,
                              formatted_address: '',
                              address1: '',
                              address2: '',
                              city: '',
                              state: '',
                              zip: '',
                            },
                          },
                        }))
                      }}
                    >
                      {<AiFillCloseCircle size={15} />}
                    </div>
                  </div>
                ) : (
                  <div className='h-[50px]'>
                    <SmallLoadingRel />
                  </div>
                )}
              </div>
            </>
          )
        }
        if (key === 'pair_entity') {
          result = (
            <div className='relative'>
              <div>{result}</div>
              <div className='absolute top-[13px] left-[55px]'>
                {`sold (${validate_number(
                  pairwise?.data?.pair_entities?.find(
                    (item) =>
                      item.pair_id ===
                      validate_number(pairwise?.estimator?.values?.pair_entity)
                  )?.sold_shares
                )}/${validate_number(
                  pairwise?.data?.pair_entities?.find(
                    (item) =>
                      item.pair_id ===
                      validate_number(pairwise?.estimator?.values?.pair_entity)
                  )?.total_shares_in_ent
                )})`}
              </div>
            </div>
          )
        }
        return <div key={key}>{result}</div>
      })
  }

  const generate_documents = async () => {
    handle_msg(
      'loading_msg',
      'Generating Documents',
      'generate_documents',
      20000
    )
    const canvas = sigRef?.current?.getTrimmedCanvas()
    const signature = canvas?.toDataURL('image/png')
    let layouts = pairwise?.data?.mkt_req_layout
    const body = {}
    const pair_values = pairwise?.documents?.values
    const pair_data_options = pairwise?.documents?.data_options
    body.name = pair_values?.name || ''
    body.number_of_shares = validate_number(pair_values?.shares_owned)
    body.pair_id = validate_number(pair_values?.pair_entity)
    body.address1 = pair_data_options?.address1
    body.address2 = pair_data_options?.address2
    body.city = pair_data_options?.city
    body.state = pair_data_options?.state
    body.zip = pair_data_options?.zip
    body.signature_url = signature
    body.social_security_num = pair_values?.social_security_num || ''
    body.email = pair_values?.email || ''
    console.log('body', body)
    if (!body.address1 || !body.city || !body.state || !body.zip) {
      return handle_msg(
        'failure_msg',
        'An address, city, state, and zip are required.',
        'generate_documents',
        true
      )
    }
    if (!body?.name) {
      return handle_msg(
        'failure_msg',
        'A Full Name is required.',
        'generate_documents',
        true
      )
    }
    if (!body?.email) {
      return handle_msg(
        'failure_msg',
        'An email is required.',
        'generate_documents',
        true
      )
    }
    if (!body?.social_security_num) {
      return handle_msg(
        'failure_msg',
        'A social Security # Name is required.',
        'generate_documents',
        true
      )
    }
    if (!body?.number_of_shares) {
      return handle_msg(
        'failure_msg',
        'Shares must be purchased.',
        'generate_documents',
        true
      )
    }
    if (!body?.pair_id) {
      return handle_msg(
        'failure_msg',
        'An Enity for pairwise must be selected.',
        'generate_documents',
        true
      )
    }
    if (!sigRef?.current || sigRef?.current?.isEmpty()) {
      return handle_msg(
        'failure_msg',
        'A Signature is required.',
        'generate_documents',
        true
      )
    }
    body.pending_dt = new Date()
    body.payment_type = pair_data_options?.rtp_eligible ? 'R' : 'A'
    body.bank_name = pair_values?.bank_name || null
    body.account_names = pair_values?.account_names || null
    body.account_number = pair_values?.account_number || null
    body.routing_number = pair_values?.routing_number || null
    if (
      !body?.bank_name ||
      !body?.account_names ||
      !body?.account_number ||
      !body?.routing_number
    ) {
      layouts = layouts.filter((item) => item?.layout_id !== 1664)
    }
    await axiomFetch('/pairwise/applications/save', 'POST', {
      application_data: body,
    })
    let flyers = await Promise.all(
      layouts?.map(async (item) => {
        return await generate_flyer_session({ layout: item })
      })
    )

    console.log('flyers', flyers)
    // get html and insert replace fields and then create pdfs
    // save pdfs to the database
    // display created documents and allow for them to create a payment
    flyers = await Promise.all(
      flyers.map(async (item) => {
        item.html = await replace_fields(
          item?.layout_text,
          item?.sess_info,
          item
        )
        item.page_ranges = 'all'
        item.upload_type = 'docs'
        item.file_name = item?.layout_name
          ? item.layout_name
              ?.toString()
              ?.replace(/\s+/g, ' ')
              ?.trim()
              ?.replace(/\s/g, '_')
              ?.replaceAll('-', '')
              ?.replace(/_+/g, '_')
          : ''
        item.pdf_url = await generate_pdf(item)
        return item
      })
    )

    const get_pdf = (id) => {
      return flyers.find((item) => item.layout_id === id)?.pdf_url || null
    }

    const url_body = {}
    url_body.purchase_agreement_url = get_pdf(1662)
    url_body.operating_agreement_url = get_pdf(1661)
    url_body.w9_url = get_pdf(1663)
    url_body.distribution_url = get_pdf(1664)

    await axiomFetch('/pairwise/applications/save', 'POST', {
      application_data: url_body,
    })

    savePairwise((prev) => {
      const data = {
        ...prev?.data,
        pair_application: {
          ...prev?.data?.pair_application,
          cont_id: profile.CONT_ID,
          acct_id: profile.ACCOUNT_ID,
          ...body,
          ...url_body,
        },
      }
      set_storage('pairwise_data', {
        // ...(get_storage('pairwise_data') || {}),
        ...data,
      })
      return {
        ...prev,
        data: data,
        documents: {
          ...prev?.documents,
          data_options: { ...prev?.documents?.data_options, re_upload: false },
        },
      }
    })
    console.log('flyers', flyers)
    handle_msg('success_msg', 'Documents Created!', 'generate_documents', true)
  }

  const modals = {
    rtp: {
      component: (
        <PairwiseRTP
          showModal={showModal}
          setShowModal={setShowModal}
          calculator_type='sell'
        />
      ),
      height: 'h-[550px]',
    },
  }

  console.log(profile)
  if (pairwise?.data?.pair_entities?.length < 1) {
    return <PairwiseIneligible />
  }
  if (
    pairwise?.data?.pair_application?.cont_id &&
    !pairwise?.documents.data_options?.re_upload
  ) {
    return <PairwiseDocumentsView />
  }
  return (
    <div className='flex flex-col justify-between min-h-[550px]'>
      <div>
        <div className='px-3'>{get_inputs()}</div>
        {/* <p className='text-xs px-3 mb-[10px]'>
          {pairwise?.documents?.data_options?.rtp_eligible
            ? 'Bank is eligible for Real Time Payments'
            : 'Bank is ineligible for Real Time Payments'}
        </p> */}
        <div>
          <p className='text-center text-[15px] mb-[5px]'>Signature*</p>
          <div className='flex justify-center bg-gray-200'>
            <SignatureCanvas
              penColor='black'
              backgroundColor={'rgba(0,0,0,0)'}
              canvasProps={{
                width: 355,
                height: 150,
                className: 'sigCanvas',
              }}
              ref={sigRef}
            />
          </div>
          <div className={'flex justify-end mt-[5px] px-3'}>
            <button
              className={`${uiStyles.hover_btn_small} mb-[10px]`}
              onClick={() => {
                let sig_api = sigRef?.current
                if (sig_api) {
                  sig_api.clear()
                }
              }}
            >
              Clear Signature
            </button>
          </div>
        </div>
      </div>
      <div>
        {
          <>
            {messageSent?.type === 'generate_documents' ? (
              <div className={`${uiStyles[messageSent.status]} mb-[20px]`}>
                {messageSent.message}
              </div>
            ) : (
              <div>
                <div className='flex'>
                  {pairwise?.documents?.data_options?.re_upload ? (
                    <button
                      className={`${uiStyles.white_btn} w-full rounded-none`}
                      onClick={() => {
                        savePairwise((prev) => ({
                          ...prev,
                          documents: {
                            ...prev?.documents,
                            data_options: {
                              ...prev?.documents?.data_options,
                              re_upload: false,
                            },
                          },
                        }))
                      }}
                    >
                      Cancel
                    </button>
                  ) : (
                    ''
                  )}
                  <button
                    disabled={claims?.client_proxy_login}
                    className={`${
                      claims?.client_proxy_login
                        ? uiStyles.hover_btn_greyed_out
                        : uiStyles.hover_btn
                    } w-full rounded-none`}
                    onClick={generate_documents}
                  >
                    Generate Documents
                  </button>
                </div>
                {claims?.client_proxy_login ? (
                  <div className='text-center'>
                    Signing documents on a clients behalf is not allowed.
                  </div>
                ) : (
                  ''
                )}
              </div>
            )}
          </>
        }
      </div>
      {showModal ? (
        <Modal
          side_padding={'px-0'}
          modalName={modalType}
          height={modals?.[modalType]?.height || 'h-[550px]'}
          width={'w-full'}
          showModal={showModal}
          setShowModal={(type) => {
            setShowModal(false)
          }}
          showClose={true}
        >
          {modals?.[modalType]?.component || <></>}
        </Modal>
      ) : (
        ''
      )}
    </div>
  )
}

export default PairwiseDocumentsSign
