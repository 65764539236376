import Wrapper from '../../components/layout/Wrapper'
import { ListingSignup } from '../../components/pages/tours'
import SubNav from '../../components/SubNav'
import { useListingTours } from '../../contexts/ListingToursContext'
const PageListingSignup = () => {
  const { listingTours, saveListingTours } = useListingTours()
  return (
    <Wrapper>
      <SubNav />
      <ListingSignup state={listingTours} setState={saveListingTours} />
    </Wrapper>
  )
}

export default PageListingSignup
