import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCalc } from '../../../contexts/CalcContext'
import { useClaims } from '../../../contexts/ClaimsContext'
import { useListingTours } from '../../../contexts/ListingToursContext'
import { useReceiver } from '../../../contexts/ReceiverContext'
import { useProfile } from '../../../contexts/UserContext'
import {
  date_today_future,
  format_currency,
  set_storage,
} from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import useHelpers from '../../../hooks/useHelpers'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import uiStyles from '../../ui/uiStyles'
import ListingPhotos from './ListingPhotos'
import Navigation from './Navigation'
import Rating from './Rating'

const Listing = ({ state, setState }) => {
  console.log(state)
  const { get_event_tour_type_id, get_sec_per_home } = useListingTours()
  const { open_url } = useHelpers()
  const { receiver, saveReceiver } = useReceiver()
  const { calc, instanciate_buy, instanciate_sell } = useCalc()
  const { claims } = useClaims()
  const [page, setPage] = useState({})
  const { nodeProdFetch } = useAxiomFetch()
  const navigate = useNavigate()
  const { profile } = useProfile()
  const { axiomFetch } = useAxiomFetch()
  const time_timeout = useRef(false)
  const seconds_to_min_str = (sec) => {
    const seconds = (sec % 60).toFixed(0)
    const minutes = Math.floor(sec / 60)
    return `${minutes?.toString()?.padStart(2, '0')}:${
      Number(seconds) ? seconds?.padStart(2, '0') : '00'
    }`
  }
  useEffect(() => {
    function get_page() {
      const data = {}
      data.tour_type_id = Number(
        get_event_tour_type_id(Number(state?.tour_state?.event_id))
      )
      data.info = {
        page_name: (state?.data?.tour_types_pages || [])?.find(
          (item) => item?.page_id === (state?.tour_state?.current_page || 1)
        )?.page_name,
      }
      data.tour_info = state?.data?.event_tour_info
      data.current_listing = (data?.tour_info || [])?.filter(
        (item) =>
          item?.cont_id > 0 &&
          item?.mls_num &&
          item?.evt_id?.toString() === state?.tour_state?.event_id?.toString()
      )?.[state?.tour_state?.listing_page - 1]
      setPage(data)
    }
    get_page()
  }, [state?.data?.event_tour_info, state?.tour_state?.listing_page])

  useEffect(() => {
    const controller = new AbortController()
    const get_data = async () => {
      setPage((prev) => ({ ...prev, loading_photos: true }))
      console.log('hello tours')
      const mls_id = (state?.data?.event_tour_info || [])?.filter(
        (item) =>
          item.cont_id > 0 &&
          item?.mls_num &&
          Number(item?.evt_id) === Number(state?.tour_state?.event_id)
      )?.[state?.tour_state?.listing_page - 1 || 0]?.mls_num
      console.log('mls_id', mls_id)
      const armls_id = state?.data?.mls_listings_v?.find(
        (item) => mls_id?.toString() === item?.mls_id?.toString()
      )?.armls_id

      console.log('armls', armls_id)
      if (armls_id) {
        const photos = await axiomFetch(
          `/tours/photos/${armls_id}`,
          'GET',
          {},
          {},
          false,
          controller.signal
        )
        setPage((prev) => ({
          ...prev,
          loading_photos: false,
          photos: photos?.armls_photos,
        }))
      }
    }
    get_data()

    return () => {
      controller?.abort()
    }
    // eslint-disable-next-line
  }, [state?.data?.event_tour_info, state?.tour_state?.listing_page])

  useEffect(() => {
    const controller = new AbortController()
    const get_data = async () => {
      setPage((prev) => ({ ...prev, loading_photos: true }))
      if (state?.data?.event_tour_info?.length) {
        const event = (state?.data?.event_tour_info || [])?.filter(
          (item) =>
            item?.cont_id > 0 &&
            item?.mls_num &&
            item?.evt_id?.toString() === state?.tour_state?.event_id?.toString()
        )?.[state?.tour_state?.listing_page - 1 || 0]

        const curr_mls = (state?.data?.event_tour_info || [])?.filter(
          (item) =>
            item?.cont_id > 0 &&
            item?.mls_num &&
            item?.evt_id?.toString() === state?.tour_state?.event_id?.toString()
        )?.[state?.tour_state?.listing_page - 1]

        const ratings = (state?.data?.tour_rating_items || [])
          ?.filter(
            (item) =>
              Number(item?.tour_type_id) ===
              Number(
                get_event_tour_type_id(Number(state?.tour_state?.event_id))
              )
          )
          ?.map((item) => {
            const obj = {
              for_cont_id: event?.cont_id,
              rate_by_cont_id: profile?.CONT_ID,
              for_mls_num: event?.mls_num,
              event_id: Number(event?.evt_id),
              rate_ord: item?.item_ord,
              rate_prompt: item?.item_name,
              rate_type: item?.rate_type,
              rate_value: item?.rate_value || null,
              item_id: item?.item_id,
              status: item?.status,
            }
            if (item?.rate_type === 'P') {
              if (!item?.rate_value) {
                obj.rate_value = curr_mls?.details?.list_price || 0
              }
            }
            return obj
          })
        const check_add_ratings = () => {
          const event_timestamp = state?.data?.event_pages?.find(
            (item) =>
              Number(item.event_id) === Number(state?.tour_state?.event_id)
          )?.event_timestamp
          if ((state?.data?.tour_ratings || [])?.length >= 0) {
            if (
              !(state?.data?.tour_ratings || [])?.filter(
                (item) =>
                  item?.for_mls_id ===
                    (state?.data?.event_tour_info || [])?.filter(
                      (item) =>
                        item?.cont_id > 0 &&
                        item?.mls_num &&
                        item?.evt_id?.toString() ===
                          state?.tour_state?.event_id?.toString()
                    )?.[state?.tour_state?.listing_page - 1]?.mls_num &&
                  item?.status === 'A' &&
                  Number(item?.event_id) === Number(state?.tour_state?.event_id)
              )?.length
            ) {
              return true
            } else {
              return date_today_future(event_timestamp)
            }
          }
        }
        const add_ratings = await axiomFetch(
          `/tours/ratings/create`,
          'POST',
          { ratings, run_merge: check_add_ratings() },
          {},
          false,
          controller.signal
        )
        setState((prev) => ({
          ...prev,
          data: {
            ...prev?.data,
            tour_ratings: add_ratings ? add_ratings : prev?.data?.tour_ratings,
          },
        }))
      }
    }
    get_data()

    return () => {
      controller?.abort()
    }
    // eslint-disable-next-line
  }, [state?.data?.event_tour_info, state?.tour_state?.listing_page])

  useEffect(() => {
    const render_timer = () => {
      console.log(time_timeout.current)
      if (time_timeout?.current) {
        clearInterval(time_timeout?.current)
        time_timeout.current = null
      }
      const mls_num = Number(
        (state?.data?.event_tour_info || [])?.filter(
          (item) =>
            item?.cont_id > 0 &&
            item?.mls_num &&
            item?.evt_id?.toString() === state?.tour_state?.event_id?.toString()
        )?.[state?.tour_state?.listing_page - 1]?.mls_num
      )
      const timer_data =
        state?.events?.[Number(state?.tour_state?.event_id)]?.[Number(mls_num)]
      if (timer_data?.run) {
        const update_time = () => {
          time_timeout.current = setInterval(() => {
            if (timer_data?.curr_time > 1) {
              const calc_remaing_time = (date) => {
                const now_sec = Date.now().valueOf() / 1000
                const start_sec = new Date(date)?.valueOf() / 1000
                return (
                  get_sec_per_home(state?.tour_state?.event_id) -
                  (now_sec - start_sec)
                )
              }
              setState((prev) => {
                const events = {
                  ...(prev?.events || {}),
                  [state.tour_state.event_id]: {
                    ...prev?.events?.[mls_num],
                    [mls_num]: {
                      ...prev?.events?.[state?.tour_state?.event_id]?.[mls_num],
                      curr_time: calc_remaing_time(
                        prev?.events?.[state?.tour_state?.event_id]?.[mls_num]
                          ?.start_time
                      ),
                      run: true,
                    },
                  },
                }
                set_storage('tour_events', events)
                return {
                  ...prev,
                  events,
                }
              })
              // update_time()
            } else {
              clearInterval(time_timeout?.current)
            }
          }, 1000)
        }
        update_time()
      }
    }
    render_timer()
    return () => {
      render_timer()
      if (time_timeout?.current) {
        clearInterval(time_timeout?.current)
      }
    }
  }, [
    state?.events?.[Number(state?.tour_state?.event_id)],
    state?.tour_state?.listing_page,
  ])

  const change_time = async (type) => {
    const values = {
      start_time: new Date(),
      curr_time: get_sec_per_home(state?.tour_state?.event_id),
      run: type === 'start',
    }
    await axiomFetch('/tours/property/timer', 'POST', {
      event_id: Number(state?.tour_state?.event_id),
      mls_id: page?.current_listing?.mls_num?.toString(),
      total_seconds: values?.curr_time,
      start_timestamp: values?.start_time,
      run: values?.run,
    })
    if (time_timeout?.current) {
      clearInterval(time_timeout?.current)
    }
    setState((prev) => {
      const events = {
        ...(prev?.events || {}),
        [state?.tour_state?.event_id]: {
          ...prev?.events?.[state?.tour_state?.event_id],
          [page?.current_listing?.mls_num]: {
            ...values,
          },
        },
      }
      const message = {
        meta_data: {
          type: 'tour_timer',
        },
        data: {
          event_id: Number(state?.tour_state?.event_id),
          mls_num: page?.current_listing?.mls_num?.toString(),
          ...values,
        },
      }
      if (claims?.ws?.send) {
        claims.ws.send(JSON.stringify(message))
      }
      set_storage('tour_events', events)
      return {
        ...prev,
        events,
      }
    })
  }

  return (
    <div>
      <div className=''>
        <div className='flex flex-col items-center'>
          <p className='text-[20px] font-bold mb-[10px]'>
            {page?.info?.page_name || 'EO Of Focus'}
          </p>
        </div>
      </div>
      {page?.current_listing?.mls_num ? (
        <div>
          {page?.tour_type_id === 1 ? (
            <div
              className={
                'flex justify-center items-center gap-[20px] mb-[10px] w-full'
              }
            >
              {/* <div className=''>
                Time Left:{' '}
                <span className='font-bold text-black text-[15px] bg-gray-100 rounded-full px-[15px] py-[5px]'>
                  {seconds_to_min_str(
                    state?.events?.[state?.tour_state?.event_id]?.[
                      page?.current_listing?.mls_num
                    ]?.curr_time ||
                      get_sec_per_home(state?.tour_state?.event_id)
                  )}
                </span>
              </div>
              {claims?.user_id ? (
                <>
                  {state?.events?.[state?.tour_state?.event_id]?.[
                    page?.current_listing?.mls_num
                  ]?.run ? (
                    <div
                      className={`${uiStyles.red_btn_small}`}
                      onClick={() => {
                        return change_time('reset')
                      }}
                    >
                      Reset
                    </div>
                  ) : (
                    <div
                      className={`${uiStyles.hover_btn_small}`}
                      onClick={() => {
                        change_time('start')
                      }}
                    >
                      Start
                    </div>
                  )}
                </>
              ) : (
                ''
              )} */}
            </div>
          ) : (
            ''
          )}
          {page?.loading_photos ? (
            <div className='min-h-[266px]'>
              <SmallLoadingRel />
            </div>
          ) : (
            <ListingPhotos state={state} setState={setState} page={page} />
          )}
          <div className='bg-gray-100 px-3 pb-3'>
            <div className='flex justify-between items-end relative'>
              <p>
                Address:{' '}
                <span className='font-bold'>
                  {page?.current_listing?.address}
                </span>
              </p>
            </div>
            <div className='flex gap-[0_10px] flex-wrap'>
              <p>Beds: {page?.current_listing?.details?.beds || '******'}</p>
              <p>Baths: {page?.current_listing?.details?.baths || '******'}</p>
              <div className='flex gap-[0_10px] flex-wrap'>
                <p>
                  Home sqft:{' '}
                  {page?.current_listing?.details?.sqft &&
                  page?.current_listing?.details?.sqft !== '0'
                    ? format_currency(
                        page?.current_listing?.details?.sqft || ''
                      )
                    : '******'}
                </p>
                <p>
                  <span>Lot sqft: </span>
                  {page?.current_listing?.details?.lot_size_square_feet &&
                  page?.current_listing?.details?.lot_size_square_feet !== '0'
                    ? format_currency(
                        page?.current_listing?.details?.lot_size_square_feet ||
                          ''
                      )
                    : '******'}
                </p>
              </div>
            </div>
            <p>
              List Price: $
              {format_currency(
                page?.current_listing?.details?.list_price || ''
              ) || '******'}
            </p>
            <p>
              Listing Agent:{' '}
              {page?.current_listing?.details?.list_agent_name ||
                page?.current_listing?.details?.co_list_agent_name ||
                '******'}
            </p>
            <div className='flex gap-[10px] mt-[10px]'>
              {['market', 'internet', 'school'].map((item) => {
                let url = `https://nodejsprod01.driggstitle.com/node_to_oracle/${item}_report?zip=${page?.current_listing?.zip}&city=${page?.current_listing?.city}&state=${page?.current_listing?.st}`
                if (item === 'school') {
                  url = `https://nodejsprod01.driggstitle.com/node_to_oracle/${item}_report?address1=${page?.current_listing?.address}&address2=${page?.current_listing?.city}, ${page?.current_listing?.st}&add_info=school`
                }
                return (
                  <>
                    {!page?.[`${item}_loading`] ? (
                      <button
                        href={url}
                        target='_blank'
                        className='underline'
                        onClick={async () => {
                          setPage((prev) => ({
                            ...prev,
                            [`${item}_loading`]: true,
                          }))
                          if (item === 'school') {
                            const school_pdf = await nodeProdFetch({
                              url: `/node_to_oracle/api/v1/attom/property/pdf?address1=${page?.current_listing?.address}}&address2=${page?.current_listing?.city}, ${page?.current_listing?.st}&add_info=school`,
                              response_type: 'text',
                            })

                            open_url(school_pdf)
                          } else if (item === 'internet') {
                            const wifi_pdf = await nodeProdFetch({
                              url: `/node_to_oracle/api/v1/internet/pdf?zip=${page?.current_listing?.zip}`,
                              response_type: 'text',
                            })
                            open_url(wifi_pdf)
                          } else if (item === 'market') {
                            const market_pdf = await axiomFetch(
                              `/marketing/materials/market_stats/pdf`,
                              'POST',
                              {
                                state: page?.current_listing?.st,
                                city: page?.current_listing?.city,
                                zip: page?.current_listing?.zip,
                                days_back: 90,
                                property_type: 'A',
                                show_client: profile?.PHOTO_LINK ? 1 : 0,
                              }
                            )
                            open_url(market_pdf)
                          }
                          setPage((prev) => ({
                            ...prev,
                            [`${item}_loading`]: false,
                          }))
                        }}
                      >
                        {item} report
                      </button>
                    ) : (
                      <div className='bg-gray-100'>
                        <SmallLoadingRel bg={'bg-gray-100'} />
                      </div>
                    )}
                  </>
                )
              })}
            </div>
            <div className='mt-[10px]'>
              {/* <p>AXIOM Estimators: </p> */}

              {calc?.home_find_loading ? (
                <SmallLoadingRel />
              ) : (
                <div className='flex gap-[5px] mt-[5px] whitespace-nowrap'>
                  <button
                    className={uiStyles.hover_btn_small}
                    onClick={() => {
                      return instanciate_buy(
                        page?.current_listing?.details,
                        'tour_view'
                      )
                    }}
                  >
                    Buyer Estimate
                  </button>
                  <button
                    className={uiStyles.hover_btn_small}
                    onClick={() => {
                      return instanciate_sell(
                        page?.current_listing?.details,
                        'tour_view'
                      )
                    }}
                  >
                    Seller Estimate
                  </button>
                  <button
                    className={`${uiStyles.hover_btn_small}`}
                    onClick={() => {
                      setTimeout(() => {
                        saveReceiver((prev) => {
                          // localStorage.setItem('vbc_edit_mode', 'true')
                          set_storage(
                            'current_home',
                            page?.current_listing?.details
                          )
                          return {
                            ...prev,
                            edit_mode: 'view',
                            homes: {
                              ...prev?.homes,
                              current_home: page?.current_listing?.details,
                            },
                          }
                        })

                        navigate(
                          '/homes/plano'
                          // {
                          //   state: { parent: '/user/' },
                          // }
                        )
                      }, [0])
                    }}
                  >
                    AXIOM PLANO
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      <div className='max-h-[550px] overflow-y-scroll'>
        {state?.data?.tour_ratings?.length
          ? (state?.data?.tour_ratings || [])
              ?.filter(
                (item) =>
                  item?.for_mls_id === page?.current_listing?.mls_num &&
                  item?.status === 'A' &&
                  Number(item?.event_id) === Number(state?.tour_state?.event_id)
              )
              ?.sort((a, b) => a?.rate_ord - b?.rate_ord)
              ?.map((item) => {
                if (item?.rate_type === 'S') {
                  item.rate_value = Number(item?.rate_value || 0)
                }
                return (
                  <div className='px-3 flex gap-[10px] py-[5px] items-center'>
                    <Rating state={state} setState={setState} rating={item} />
                  </div>
                )
              })
          : ''}
      </div>
      <Navigation state={state} setState={setState} />
    </div>
  )
}

export default Listing
