import { Link } from 'react-router-dom';
import useSortableTable from '../hooks/useSortableTable';
import Badge from './ui/Badge';

export default function CardsList({ cards = [] }) {
  console.log(cards);
  const { items, requestSort, sortConfig } = useSortableTable(cards);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };
  return (
    <table>
      <thead>
        <tr>
          <th>Cards</th>
          <th className="text-center">
            <button
              type="button"
              onClick={() => requestSort('status')}
              className={getClassNamesFor('status')}
            >
              Default
            </button>
          </th>
        </tr>
      </thead>
      <tbody>
        {items &&
          items.map((item) => (
            <tr key={item.ID}>
              <td>
                <Link to={`edit/${item.ID}`}>{item.NAME}</Link>
              </td>

              <td className="text-center">
                <Badge
                  color={
                    item.IS_DEFAULT === 'Y' ? 'text-[#05A660]' : 'text-gray-600'
                  }
                >
                  <span className="capitalize min-w-[40px] text-center inline-block">
                    <span
                      className={`inline-block align-middle mr-1 w-[4px] h-[4px] rounded-full ${
                        item.IS_DEFAULT === 'Y'
                          ? 'bg-[#05A660]'
                          : 'bg-gray-600 '
                      }`}
                    ></span>
                    {item.IS_DEFAULT === 'Y' ? 'Yes' : 'No'}
                  </span>
                </Badge>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}
