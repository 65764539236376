import React from 'react';

export default function WithRoleRestrictions(
  Component,
  allowedRoles = [],
  RenderError = () => <></>
) {
  return function RoleRestrictedComponnet(props) {
    // const { user } = useAuth0();
    const permissionGranted = allowedRoles.includes('read');

    if (!permissionGranted) return <RenderError />;

    return <Component {...props} />;
  };
}
