import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

export const SalesContext = createContext()

export const SalesProvider = ({ children, userData }) => {
  const [sales, setSales] = useState({})

  const saveSales = useCallback((data) => {
    setSales(data)
  }, [])

  const value = useMemo(
    () => ({
      sales,
      saveSales,
    }),
    [sales, saveSales]
  )

  return <SalesContext.Provider value={value}>{children}</SalesContext.Provider>
}

export const useSales = () => {
  const context = useContext(SalesContext)

  if (!context) {
    throw Error('SalesContext must be used inside an SalesContextProvider')
  }

  return context
}
