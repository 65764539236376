import { useState, useEffect } from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'
import { useClaims } from '../../../contexts/ClaimsContext'
import { get_background_color, open_url_tab } from '../../../helpers'
import Modal from '../../Modal'
import uiStyles from '../../ui/uiStyles'
import CompleteTour from './CompleteTour'
import RemoveListing from './RemoveListing'
import ResetTour from './ResetTour'
import silhouette from '../../../assets/images/profile-silhouette.svg'
import RemoveRideAlong from './RemoveRideAlong'
import { useNavigate } from 'react-router-dom'
import { MdReviews } from 'react-icons/md'
import { useListingTours } from '../../../contexts/ListingToursContext'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { useAuth0 } from '@auth0/auth0-react'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import useHelpers from '../../../hooks/useHelpers'

const Itinerary = ({ state, setState, setItineraryModal }) => {
  console.log('itinerary', state)
  const [page, setPage] = useState({})
  const { open_url } = useHelpers()
  const [selectedListing, setSelectedListing] = useState({})
  const [selectedRideAlong, setSelectedRideAlong] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('complete')
  const [loading, setLoading] = useState('')
  const { get_event_type_id, get_event_tour_type_id } = useListingTours()
  const { claims } = useClaims()
  const { axiomFetch } = useAxiomFetch()
  const { loginWithRedirect, user } = useAuth0()

  const client_login = async (client) => {
    setLoading(client?.agent_cell_phone)
    await axiomFetch('/internal/client/login', 'POST', {
      actual_phone: claims?.login_phone_number || user?.name?.replace('+1', ''),
      current_phone: client?.agent_cell_phone || client?.cont_cell_num,
    })
    return loginWithRedirect()
  }

  const navigate = useNavigate()
  const modals = {
    complete: (
      <CompleteTour
        state={state}
        setState={setState}
        setItineraryModal={setItineraryModal}
        setShowModal={setShowModal}
      />
    ),
    reset: (
      <ResetTour
        state={state}
        setState={setState}
        setItineraryModal={setItineraryModal}
        setShowModal={setShowModal}
      />
    ),
    remove: (
      <RemoveListing
        state={state}
        listing={selectedListing}
        setState={setState}
        setItineraryModal={setItineraryModal}
        setShowModal={setShowModal}
      />
    ),
    remove_ride_along: (
      <RemoveRideAlong
        state={state}
        ride_along={selectedRideAlong}
        setState={setState}
        setItineraryModal={setItineraryModal}
        setShowModal={setShowModal}
      />
    ),
  }

  useEffect(() => {
    const get_page = () => {
      const itinerary = state?.data?.event_signup
        ?.filter(
          (item) =>
            item?.list_id &&
            Number(item?.event_id) === Number(state?.tour_state?.event_id)
        )
        ?.map((item) => {
          item.info = state?.data?.event_tour_info?.find(
            (info) => info?.mls_num?.toString() === item.list_id?.toString()
          )
          return item
        })

      const ride_alongs = state?.data?.event_tour_info?.filter(
        (item) =>
          !item?.mls_num &&
          item?.cont_id > 0 &&
          Number(item?.evt_id) === Number(state?.tour_state?.event_id)
      )

      const tour_type_id = get_event_tour_type_id(
        Number(state?.tour_state?.event_id)
      )

      setPage({ itinerary, ride_alongs, tour_type_id })
    }
    get_page()
    return () => {
      //   get_page()
    }
  }, [state?.data?.event_signup, state?.data?.event_tour_info])
  console.log('itinerary page', page)
  const profile_html = (item, type) => {
    return (
      <div
        className={`flex ${
          type === 'ride_along' ? 'mt-[10px]' : 'mt-[10px] items-center'
        }`}
        key={
          item?.cont_id?.toString() +
          item?.evt_tour_id?.toString() +
          item?.signup_id?.toString() +
          item?.evt_id?.toString() +
          item?.event_id?.toString()
        }
      >
        <div
          className={`relative ${item?.agent_review ? 'cursor-pointer' : ''}`}
        >
          {
            <img
              onClick={() => {
                if (item?.agent_review) {
                  open_url(item?.agent_review)
                }
              }}
              src={
                page?.tour_type_id !== 22
                  ? item?.agent_photo || silhouette
                  : item?.info?.details?.home_photo1 || silhouette
              }
              className='w-[110px] h-[80px] object-cover rounded'
            />
          }
          {item?.agent_review ? (
            <div
              className='cursor-pointer absolute top-[-10px] right-[-5px]'
              onClick={() => {
                if (type === 'ride_along') {
                  setSelectedRideAlong(item)
                  setModalType('remove_ride_along')
                } else {
                  setSelectedListing(item)
                  setModalType('remove')
                }
                setShowModal(true)
              }}
            >
              <MdReviews size={25} />
            </div>
          ) : (
            ''
          )}
          {claims?.user_id > 0 ? (
            <div
              className='cursor-pointer absolute top-[-10px] left-[-10px]'
              onClick={() => {
                if (type === 'ride_along') {
                  setSelectedRideAlong(item)
                  setModalType('remove_ride_along')
                } else {
                  setSelectedListing(item)
                  setModalType('remove')
                }
                setShowModal(true)
              }}
            >
              <AiFillCloseCircle size={25} />
            </div>
          ) : (
            ''
          )}
        </div>
        <div className='pl-2 flex flex-col'>
          {type === 'ride_along' || page?.tour_type_id === 22 ? (
            ''
          ) : (
            <p>
              <span className='font-bold text-[13px]'>Time: </span>{' '}
              <span className='text-[13px]'>
                {item?.est_arrival_time_format || 'NA'}
              </span>
            </p>
          )}
          <p>
            <span className='font-bold text-[13px]'>
              {page?.tour_type_id === 22 && type === 'ride_along'
                ? 'Buyer Name'
                : 'Agent Name'}
              :{' '}
            </span>
            {loading === item?.agent_cell_phone ? (
              <SmallLoadingRel />
            ) : (
              <span
                onClick={() => {
                  if (claims?.user_id) {
                    client_login(item)
                  }
                }}
                className={`text-[12px] ${
                  item?.hex_code && claims?.user_id
                    ? `cursor-pointer ${get_background_color(
                        item?.hex_code,
                        'bg-gray-100',
                        'bg-slate-900'
                      )} px-[2px] rounded`
                    : ''
                }`}
                style={{
                  color:
                    item?.hex_code && claims?.user_id
                      ? item?.hex_code.replace(';', '')
                      : '',
                }}
              >
                {item?.info?.details?.list_agent_name ||
                  item?.info?.details?.co_list_agent_name ||
                  item?.agent_name ||
                  'NA'}
              </span>
            )}
          </p>
          {type === 'ride_along' ? (
            ''
          ) : (
            <p
              className={`${
                item?.info?.details?.lat && item?.info?.details?.lng
                  ? 'cursor-pointer'
                  : ''
              }`}
              onClick={() => {
                if (item?.info?.details?.lat && item?.info?.details?.lng) {
                  open_url_tab(
                    `https://www.google.com/maps/dir/?api=1&destination=${item?.info?.details?.lat},${item?.info?.details?.lng}`
                  )
                }
              }}
            >
              <span className='font-bold text-[13px]'>Property Address: </span>{' '}
              <span
                className={`text-[13px] ${
                  item?.info?.details?.lat && item?.info?.details?.lng
                    ? 'underline'
                    : ''
                }`}
              >
                {item?.info?.details?.prop_add || 'NA'}
              </span>
            </p>
          )}
        </div>
      </div>
    )
  }

  return (
    <div>
      <h1 className={uiStyles.text_black_title}>Tour Itinerary</h1>
      <div className='pl-[10px] mt-[10px] flex flex-col gap-[15px] max-h-[400px] overflow-y-scroll'>
        {page?.itinerary?.length
          ? page?.itinerary?.map((item) => profile_html(item))
          : ''}
      </div>
      <div className='flex justify-between items-center mt-[10px]'>
        <h2 className={`${uiStyles.text_black_title} `}>
          {page?.tour_type_id === 22 ? 'Buyers' : 'Ride Alongs'}{' '}
        </h2>
        {claims?.user_id ? (
          <button
            onClick={() => {
              navigate('/user/listing_tours/add')
            }}
            className={uiStyles.hover_btn_small}
          >
            Add Ride along
          </button>
        ) : (
          ''
        )}
      </div>
      <div className='pl-[10px] mt-[10px] flex flex-col gap-[15px] max-h-[380px] overflow-y-scroll'>
        {page?.ride_alongs?.length
          ? page?.ride_alongs?.map((item) => profile_html(item, 'ride_along'))
          : ''}
      </div>
      {claims?.user_id > 0 ? (
        <div className='flex justify-start gap-[10px] mt-[20px]'>
          <button
            className={uiStyles.hover_btn}
            onClick={() => {
              setModalType('complete')
              setShowModal(true)
            }}
          >
            {state?.tour_state?.completed
              ? 'Re-Activate Tour'
              : 'Complete Tour'}
          </button>
          <button
            className={uiStyles.hover_btn}
            onClick={() => {
              setModalType('reset')
              setShowModal(true)
            }}
          >
            Tour Total Reset
          </button>
        </div>
      ) : (
        ''
      )}
      {showModal ? (
        <Modal
          modalName={'tour_complete'}
          setShowModal={setShowModal}
          showModal={showModal}
          showClose={true}
          height={'h-[300px]'}
        >
          {modals[modalType]}
        </Modal>
      ) : (
        ''
      )}
    </div>
  )
}

export default Itinerary
