import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';

import ButtonWhite from './ButtonWhite';
const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export default function DateRangePicker({
  startDate,
  endDate,
  setStartDate = () => {},
  setEndDate = () => {},
}) {
  const isMonthSame = startDate.getMonth() === endDate.getMonth();
  const isYearSame = startDate.getFullYear() === endDate.getFullYear();
  const heading =
    !isMonthSame || !isYearSame
      ? `${months[startDate.getMonth()]} ${startDate.getFullYear()} - ${
          months[endDate.getMonth()]
        } ${endDate.getFullYear()}`
      : `${months[startDate.getMonth()]} ${startDate.getFullYear()}`;

  return (
    <>
      <div className="py-4">
        <div className="flex justify-center items-center">
          <div className=" px-5 text-primary text-base font-semibold">
            {heading}
          </div>
        </div>
        <div className="flex p-3 justify-center">
          <div className="rangepicker w-[128px]">
            <ReactDatePicker
              renderCustomHeader={({
                date,

                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <div className="flex justify-between items-center">
                  <ButtonWhite
                    clickHandler={decreaseMonth}
                    isDisabled={prevMonthButtonDisabled}
                    small={true}
                  >
                    {'<'}
                  </ButtonWhite>

                  <div className=" px-5 text-primary text-base font-semibold">
                    {`${months[date.getMonth()]}
                    ${date.getFullYear()}`}
                  </div>

                  <ButtonWhite
                    clickHandler={increaseMonth}
                    isDisabled={nextMonthButtonDisabled}
                    small={true}
                  >
                    {'>'}
                  </ButtonWhite>
                </div>
              )}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              maxDate={endDate}
              dateFormat="MMM dd, yyyy"
            />
          </div>
          <div className="w-4 text-center flex items-center justify-center">
            -
          </div>
          <div className="rangepicker w-[128px]">
            <ReactDatePicker
              renderCustomHeader={({
                date,

                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <div className="flex justify-between items-center">
                  <ButtonWhite
                    clickHandler={decreaseMonth}
                    isDisabled={prevMonthButtonDisabled}
                    small={true}
                  >
                    {'<'}
                  </ButtonWhite>

                  <div className=" px-5 text-primary text-base font-semibold">
                    {`${months[date.getMonth()]}
                  ${date.getFullYear()}`}
                  </div>

                  <ButtonWhite
                    clickHandler={increaseMonth}
                    isDisabled={nextMonthButtonDisabled}
                    small={true}
                  >
                    {'>'}
                  </ButtonWhite>
                </div>
              )}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              dateFormat="MMM dd, yyyy"
            />
          </div>
        </div>
      </div>
    </>
  );
}
