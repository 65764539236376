import { useEffect, useState, useRef } from 'react'
import SwipeConfig from '../../ui/SwipeConfig'
import ch_images from '../../../assets/images/conversations'
import uiStyles from '../../ui/uiStyles'
import { NotificationsIcon } from './NotificationsIcon'
export const ConversationCard = ({
  conv,
  convMessages,
  campaign = false,
  selections,
  setSelections,
}) => {
  const left_image = (count) => {
    if (selections.includes(conv.sid)) {
      return ch_images.checkmark
    }
    if (count < 3) {
      return ch_images.person
    } else if (count === 3) {
      return ch_images.people
    } else {
      return ch_images.announcement
    }
  }

  return (
    <div className='swipeable mx-[5px]'>
      <div className='swipeable flex items-center justify-between mr-[10px]'>
        <div className='cursor-pointer my-[16px] mx-[16px] bg-[#BCC1C5] rounded-full w-[50px] h-[50px] flex place-content-center'>
          <img
            onClick={() => {
              if (selections.includes(conv?.sid)) {
                setSelections((prev) =>
                  prev.filter((item) => item !== conv?.sid)
                )
              } else {
                setSelections((prev) => [...prev, conv?.sid])
              }
            }}
            className='w-[30px]'
            src={left_image(conv?._participants?.size)}
          />
        </div>
        <div className='swipeable flex grow max-w-[170px] h-[55px] px-[5px] flex-col gap-[5px]'>
          <p className='swipeable text-[16px] font-semibold text-[#0B1B0F]'>
            {conv?.attributes?.name ||
              conv?._internalState?.friendlyName ||
              conv?.sid}
          </p>
          <p className='swipeable mb-[10px] [word-break:_break-word]'>
            {convMessages?.body?.length > 35
              ? `${convMessages?.body.slice(0, 42)}...`
              : convMessages?.body}
          </p>
        </div>
        <div className='swipeable flex flex-col items-center'>
          <p className='swipeable mb-[10px] w-[65px]'>
            {convMessages?.date?.toLocaleString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
          </p>
          <NotificationsIcon count={convMessages?.unread_count} />
        </div>
      </div>
    </div>
  )
}
