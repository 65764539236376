import React from 'react'

// import Driggs from '../../assets/images/driggsSmall.png'
import { Outlet } from 'react-router'

export default function Blue() {
  return (
    <div className='overflow-hidden text-white rounded-2xl max-w-[375px]  mx-auto bg-secondary min-h-[100vh] relative '>
      <Outlet />
      {/* <img
        src={Driggs}
        alt="Driggs"
        className="max-w-[50px] max-h-[50px] absolute left-4 bottom-3"
      /> */}
    </div>
  )
}
