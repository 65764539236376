import React from 'react'
import { useListingTours } from '../../contexts/ListingToursContext'
import { useSales } from '../../contexts/SalesContext'
import { useProfile } from '../../contexts/UserContext'
import { getCurrentTime, getDateString } from '../../helpers'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import uiStyles from '../ui/uiStyles'

const ClientTourSignup = () => {
  const { listingTours, saveListingTours } = useListingTours()
  const { axiomFetch } = useAxiomFetch()
  const { sales, saveSales } = useSales()
  const { profile } = useProfile()

  const get_tour = () => {
    return listingTours?.client_signup?.tour
  }
  const get_listing = () => {
    return listingTours?.client_signup?.listing
  }
  return (
    <div>
      <div className='px-3'>
        <p>
          <span className='font-bold'>Tour Name: </span>
          {get_tour()?.tour_name}
        </p>
        <p>
          <span className='font-bold'>Start Time: </span>
          {`${getDateString({
            date: get_tour()?.event_date || '',
          })} ${getCurrentTime(get_tour()?.event_timestamp)}`}
        </p>
        <p>
          <span className='font-bold'>Starting Address: </span>
          {get_tour()?.add1}
        </p>
        <p>
          <span className='font-bold'>MLS #: </span>
          {get_listing()?.mls_id}
        </p>
        <p>
          <span className='font-bold'>Listing Address: </span>
          {get_listing()?.prop_add}
        </p>
      </div>
      <div className='flex justify-end px-3 mt-[10px]'>
        {sales?.curr_client?.add_data?.event_signup?.some(
          (item) =>
            item.event_id === get_tour()?.event_id &&
            item.list_id?.toString() === get_listing()?.mls_id?.toString()
        ) ? (
          <button
            className={uiStyles.red_btn}
            onClick={async () => {
              saveSales((prev) => ({
                ...prev,
                curr_client: {
                  ...prev?.curr_client,
                  add_data: {
                    ...prev?.curr_client?.add_data,
                    event_signup: (
                      prev.curr_client?.add_data?.event_signup || []
                    )?.filter((item) => {
                      if (item.event_id === get_tour()?.event_id) {
                        if (
                          item?.list_id?.toString() ===
                          get_listing()?.mls_id?.toString()
                        ) {
                          return false
                        } else {
                          return
                        }
                      } else {
                        return true
                      }
                    }),
                  },
                },
              }))
              await axiomFetch('/tours/remove', 'POST', {
                event_id: get_tour()?.event_id,
                mls_id: get_listing()?.mls_id,
              })
            }}
          >
            Cancel Signup For {sales?.curr_client?.data?.cont_name}
          </button>
        ) : (
          <button
            className={uiStyles.hover_btn}
            onClick={async () => {
              saveSales((prev) => ({
                ...prev,
                curr_client: {
                  ...prev?.curr_client,
                  add_data: {
                    ...prev?.curr_client?.add_data,
                    event_signup: [
                      ...(
                        prev?.curr_client?.add_data?.event_signup || []
                      )?.filter((item) => {
                        if (item.event_id === get_tour()?.event_id) {
                          if (
                            item?.list_id?.toString() ===
                            get_listing()?.mls_id?.toString()
                          ) {
                            return false
                          } else {
                            return
                          }
                        } else {
                          return true
                        }
                      }),
                      {
                        event_id: get_tour()?.event_id,
                        cont_id: sales?.curr_client?.data?.cont_id,
                        list_id: get_listing()?.mls_id,
                      },
                    ],
                  },
                },
              }))
              await axiomFetch('/tours/signup', 'POST', {
                event_id: get_tour()?.event_id,
                eo_of_focus: get_tour()?.eo_of_focus,
                mls_id: get_listing()?.mls_id,
                listing: get_listing(),
                event: get_tour(),
                cont_id: sales?.curr_client?.data?.cont_id,
                event_marketer: get_tour().event_marketer,
                agent_signed_them_up: true,
              })
            }}
          >
            Sign Up {sales?.curr_client?.data?.cont_name}
          </button>
        )}
      </div>
      <div className=''>
        {/* å<div className='px-3 text-center text-[18px] font-bold'>Tour Map</div> */}
        <iframe
          onLoad={(e) => {
            // e.scrollTo({ top: 100 })
          }}
          style={{
            // transition: 'transform 0.3s ease',
            width: '100%',
            height: '650px',
            overflowY: 'scroll',
            // overflow: 'scroll',
            // marginTop: '-200px',
          }}
          src={`https://rdbeta2.driggstitle.com/prv/list_tour_console.map_js_only?acct_p=${
            profile.ACCOUNT_ID
          }&tour_nm_p=${get_tour()?.tour_name}&zoom_p=9`}
        ></iframe>
      </div>
    </div>
  )
}

export default ClientTourSignup
