import React from 'react'
import { useListingTours } from '../../../contexts/ListingToursContext'
import AddBuyer from './AddBuyer'

const BuyerSend = () => {
  const { listingTours } = useListingTours()
  console.log(listingTours)
  return <AddBuyer send_to_buyers={true} />
}

export default BuyerSend
