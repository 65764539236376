import React from 'react'
import Billing from '../../../components/pages/settings/Billing'
import Wrapper from '../../../components/layout/Wrapper'
import SubNav from '../../../components/SubNav'

export default function PageBilling() {
  return (
    <Wrapper>
      <SubNav />
      <Billing />
    </Wrapper>
  )
}
