import send from './send.svg'
import attachment from './attachment.svg'
import people from './people.svg'
import person from './person.svg'
import person_white from './person_white.svg'
import announcement from './announcement.svg'
import search_blue from './search_blue.svg'
import search_gray from './search_gray.svg'
import add_blue from './add_blue.svg'
import add_green from './add_green.svg'
import emoji_icon from './emoji_icon.svg'
import robot_icon from './robot.svg'
import checkmark from './checkmark.svg'
import check_all from './check_all.svg'
import check_all_gray from './check_all_gray.svg'
const ch_images = { send, attachment, people, person, person_white, announcement, search_blue, search_gray, add_blue, add_green, emoji_icon, robot_icon, checkmark, check_all, check_all_gray }
export default ch_images