import React from 'react'
import ButtonsRow from '../../ui/ButtonsRow'
import MarketingMaterialApprove from './MarketingMaterialApprove'
import MarketingMaterialCompleted from './MarketingMaterialCompleted'
import MarketingMaterialPending from './MarketingMaterialPending'

export const MarketingMaterialUpdate = ({ state, setState }) => {
  const types = {
    pending: <MarketingMaterialPending />,
    for_approval: <MarketingMaterialApprove preview={true} />,
    completed: <MarketingMaterialCompleted state={state} setState={setState} />,
    catalog: (
      <MarketingMaterialCompleted
        type='catalog'
        state={state}
        setState={setState}
      />
    ),
  }
  return (
    <div>
      <div className='px-3 my-[10px]'>
        <ButtonsRow
          items={[
            'pending',
            'for_approval',
            'completed',
            // 'catalog'
          ]}
          handler={(item) => {
            console.log(item)
            setState((prev) => ({
              ...prev,
              my_orders: { ...prev?.my_orders, type: item },
            }))
          }}
          selected={state?.my_orders?.type || 'pending'}
          button_style={'text-[12px] pl-2'}
        />
      </div>
      {types[state?.my_orders?.type || 'pending']}
    </div>
  )
}
