import { useState, useEffect } from 'react'
import { AiFillDelete } from 'react-icons/ai'
import { BsPerson } from 'react-icons/bs'
import { TfiPencilAlt } from 'react-icons/tfi'
import { useNavigate } from 'react-router-dom'
import images_homes from '../../../assets/images/home'
import images_tours from '../../../assets/images/listing_tours'
import { useListingTours } from '../../../contexts/ListingToursContext'
import { useReceiver } from '../../../contexts/ReceiverContext'
import { useVBCs } from '../../../contexts/VBCContext'
import {
  date_today_future,
  getCurrentTime,
  getDateString,
  get_storage,
  set_storage,
} from '../../../helpers'
import { CircleCheckToggle } from '../../form/CircleCheckToggle'
import { List } from '../../helpers'
import Modal from '../../Modal'
import uiStyles from '../../ui/uiStyles'
import AddBuyer from './AddBuyer'
import BuyerToursCreate from './BuyerToursCreate'
import BuyerToursRemove from './BuyerToursRemove'

const BuyerToursLobby = () => {
  const {
    listingTours,
    saveListingTours,
    get_event_tour_type_id,
    show_tour_experience_lobby,
    get_experience_type_id,
  } = useListingTours()
  const { VBCs } = useVBCs()
  const { saveReceiver } = useReceiver()
  console.log('vbcs', VBCs)
  console.log('tours', listingTours)
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const modals = {
    create: (
      <BuyerToursCreate
        state={listingTours}
        setState={saveListingTours}
        setShowModal={setShowModal}
        type={162}
      />
    ),
    remove: (
      <BuyerToursRemove
        state={listingTours}
        setState={saveListingTours}
        setShowModal={setShowModal}
        type={162}
      />
    ),
    add_buyer: (
      <AddBuyer
        state={listingTours}
        setState={saveListingTours}
        setShowModal={setShowModal}
      />
    ),
  }
  const [pages, setPages] = useState([])
  const navigate = useNavigate()
  useEffect(() => {
    let data = listingTours?.data?.event_pages?.filter(
      (item) =>
        Number(item?.event_type_id) ===
        Number(listingTours?.buyer_tours?.event_type_id || 162)
    )
    if (listingTours?.buyer_tours?.tour_experience_id) {
      data = listingTours?.data?.event_pages?.filter(
        (item) =>
          Number(item?.tour_experience_id) ===
          Number(listingTours?.buyer_tours?.tour_experience_id)
      )
    }
    data = data
      ?.sort((a, b) => new Date(b.event_date) - new Date(a.event_date))
      ?.map((item) => {
        item.title = item?.tour_name || item?.event_name
        item.content = `${getDateString({
          date: new Date(item?.event_date || ''),
        })} ${getCurrentTime(item?.event_timestamp)}`
        item.image = listingTours?.data?.tour_types?.find(
          (type) =>
            type.type_id ===
            get_event_tour_type_id(Number(item?.event_id || 162))
        )?.van_photo
        item.subtitle = ''
        item.sub_content =
          listingTours?.tour_state?.event_id === item?.event_id
            ? 'Current Tour'
            : ''
        item.sub_content_gap = 'mt-[5px]'

        item.swipe_right = () => {
          return (
            <div className='flex gap-[20px]'>
              <div
                className='cursor-pointer'
                onClick={() => {
                  saveListingTours((prev) => {
                    const return_data = {
                      ...prev?.buyer_tours,
                      event_edit: item,
                    }
                    set_storage('buyer_tours', return_data)
                    return { ...prev, buyer_tours: return_data }
                  })
                  // return navigate('create')
                  setModalType('create')
                  setShowModal(true)
                }}
              >
                <TfiPencilAlt size={25} />
              </div>
              <div
                className='cursor-pointer'
                onClick={() => {
                  saveListingTours((prev) => {
                    const return_data = {
                      ...prev?.buyer_tours,
                      event_edit: item,
                      full_run: true,
                    }
                    set_storage('buyer_tours', return_data)
                    return { ...prev, buyer_tours: return_data }
                  })
                  return navigate('add_home')
                  // setModalType('create')
                  // setShowModal(true)
                }}
              >
                <div className='flex flex-col items-center'>
                  <img src={images_homes.home_icon} className='w-[25px]' />
                  {/* <small>homes</small> */}
                </div>
              </div>
              <div
                className='cursor-pointer'
                onClick={() => {
                  saveListingTours((prev) => {
                    const return_data = {
                      ...prev?.buyer_tours,
                      event_edit: item,
                    }
                    set_storage('buyer_tours', return_data)
                    return { ...prev, buyer_tours: return_data }
                  })
                  return navigate('add_buyer')
                  // setModalType('add_buyer')
                  // setShowModal(true)
                }}
              >
                <BsPerson size={25} />
              </div>
              <div
                className='cursor-pointer'
                onClick={() => {
                  saveListingTours((prev) => {
                    const return_data = {
                      ...prev?.buyer_tours,
                      event_edit: item,
                    }
                    set_storage('buyer_tours', return_data)
                    return { ...prev, buyer_tours: return_data }
                  })
                  setModalType('remove')
                  setShowModal(true)
                }}
              >
                <AiFillDelete size={25} />
              </div>
            </div>
          )
        }
        return item
      })
    setPages(data || [])
  }, [
    listingTours?.data?.event_pages,
    listingTours?.buyer_tours?.tour_experience_id,
  ])
  return (
    <div>
      <div className='w-full flex justify-center mb-[10px]'>
        {show_tour_experience_lobby() ? (
          <div>
            <button
              className={`${uiStyles.white_btn}`}
              onClick={() => {
                return navigate('/user/buyer_tours/tour_experience_lobby')
              }}
            >
              Tour Experiences
            </button>
          </div>
        ) : (
          <>
            {(listingTours?.data?.tour_types_asg || [])?.length ? (
              <CircleCheckToggle
                name={`is_agent_tour`}
                type='toggle'
                toggle_left={false}
                toggle_left_label={'Buyer Tours'}
                toggle_right_label={'Agent Tours'}
                inputValue={listingTours?.buyer_tours || {}}
                handle_change={async (e) => {
                  saveListingTours((prev) => {
                    const ret_buyer_tours = {
                      ...prev?.buyer_tours,
                      event_type_id:
                        prev?.buyer_tours?.event_type_id === 163 ? 162 : 163,
                      tour_experience_id:
                        prev?.buyer_tours?.tour_experience_id ===
                        get_experience_type_id(163)
                          ? get_experience_type_id(162)
                          : get_experience_type_id(163),
                      is_agent_tour:
                        prev?.buyer_tours?.is_agent_tour === 'Y' ? 'N' : 'Y',
                    }
                    set_storage('buyer_tours', ret_buyer_tours)
                    return { ...prev, buyer_tours: ret_buyer_tours }
                  })
                }}
              />
            ) : (
              ''
            )}
          </>
        )}
      </div>
      <div className='overflow-y-scroll max-h-[450px]'>
        <List
          config_width={44}
          no_items_msg='No Tours yet'
          reset_slider={true}
          list={pages}
          swipe_right={() => {
            return (
              <div
                onClick={() => {
                  navigate('/user/buyer_tours/create')
                }}
              >
                open
              </div>
            )
          }}
        />
      </div>
      <div className='px-3 mt-3 flex justify-end items-center gap-[10px] pb-[10px]'>
        {Number(listingTours?.buyer_tours?.event_type_id || 162) === 162 ? (
          <button
            className={`${uiStyles.hover_btn_small}`}
            onClick={() => {
              const buyer_vbc = (VBCs?.cards || [])?.find(
                (item) => item?.RECEIVER_TYPE_ID === 2
              )
              const curr_vbc = get_storage('vbc_receiver')
              saveReceiver((prev) => {
                localStorage.setItem('vbc_edit_mode', 'true')
                return {
                  ...prev,
                  edit_mode: 'view',
                }
              })
              if (
                (curr_vbc?.ITEM_ID || curr_vbc?.VBC_INSTANCE_ID) !==
                buyer_vbc?.ID
              ) {
                localStorage.removeItem('card')
                localStorage.removeItem('slots')
                buyer_vbc.VBC_INSTANCE_ID = buyer_vbc.ID
                set_storage('vbc_receiver', buyer_vbc)
              }
              if (buyer_vbc?.ID) {
                navigate(`/user/my-vbc/${buyer_vbc?.ID}`, {
                  state: { name: buyer_vbc?.NAME, parent: '/user/buyer_tours' },
                })
              } else {
                navigate('/user/my-vbc', {
                  state: { name: buyer_vbc?.NAME, parent: '/user/buyer_tours' },
                })
              }
            }}
          >
            Buyer Smart Business Card
          </button>
        ) : (
          <button
            className={`${uiStyles.hover_btn_small}`}
            onClick={() => {
              return navigate('/user/buyer_tours/ratings')
            }}
          >
            Edit Tour Ratings
          </button>
        )}
        <button
          className={`${uiStyles.hover_btn_small}`}
          onClick={() => {
            saveListingTours((prev) => {
              const return_data = {
                ...prev?.buyer_tours,
                event_edit: {
                  event_type_id: Number(
                    listingTours?.buyer_tours?.event_type_id || 162
                  ),
                  tour_experience_id: Number(
                    listingTours?.buyer_tours?.tour_experience_id
                  ),
                },
                buyer_edit: {},
                home_edit: {},
              }
              set_storage('buyer_tours', return_data)
              return { ...prev, buyer_tours: return_data }
            })
            return navigate('create')
            // setModalType('create')
            // setShowModal(true)
          }}
        >
          Create New Tour
        </button>
      </div>
      {showModal ? (
        <Modal
          modalName='create_buyer_tour'
          showModal={showModal}
          setShowModal={setShowModal}
          // width={'w-[350px]'}
          side_padding={['add_buyer'].includes(modalType) ? 'px-0' : ''}
          height={
            modalType === 'create'
              ? 'h-[300px]'
              : modalType?.includes('remove')
              ? 'h-[175px]'
              : 'h-[550px]'
          }
        >
          {modals?.[modalType]}
        </Modal>
      ) : (
        ''
      )}
    </div>
  )
}

export default BuyerToursLobby
