import React from 'react'

export const SmallLoadingRel = ({
  bg,
  color = 'from-accent-150 to-accent-400',
}) => {
  return (
    <div
      className={`${
        bg ? bg : ''
      } opacity-1 pointer-events-none flex items-center justify-center w-full h-full`}
    >
      <span className={`flex h-4 w-4  items-center`}>
        <span
          className={`animate-ping inline-flex h-full w-full rounded-full bg-gradient-to-b ${
            color === 'white' ? 'from-white to-gray-300' : color
          } opacity-75`}
        ></span>
      </span>
    </div>
  )
}
