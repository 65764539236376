import { React, useState, useEffect } from 'react'
import uiStyles from '../../ui/uiStyles'
import { AiFillDelete } from 'react-icons/ai'
import { GrFormNextLink } from 'react-icons/gr'
import { FcNext } from 'react-icons/fc'
import rightArrow from '../../../assets/images/right-arrow.png'
import { formatPhoneNumber } from '../../../helpers'
import { Link } from 'react-router-dom'
const Contact = ({ contact }) => {
  const [cont, setCont] = useState(contact)
  // useEffect(() => {
  //   setCont(contact)
  // }, [])
  return (
    <div className='flex gap-[10px] items-center bg-gray-100 w-[305px] py-3 rounded shadow-md'>
      <div className='flex flex-col gap-2 w-full'>
        <input type='hidden' value={cont?.UPLD_INPUT_ID} />
        <label className='block capitalize relative w-[40px] px-3'>
          Name
          <input
            placeholder='name'
            className={`${uiStyles.input} w-[180px] text-xs`}
            value={cont?.NM || ''}
            onChange={(e) => {
              // if (contact.updated) {
              //     contact.updated = false
              // } else {
              contact.updated = true
              //}
              contact.NM = e.target.value.trim()
              setCont({ ...cont, NM: e.target.value })
            }}
          />
        </label>
        <label className='block capitalize relative w-[40px] px-3'>
          Cell
          <input
            placeholder='cell'
            className={`${uiStyles.input} w-[180px] text-xs`}
            value={formatPhoneNumber(cont?.CELL) || ''}
            onChange={(e) => {
              // if (contact.updated) {
              //     contact.updated = false
              // } else {
              contact.updated = true
              //}
              contact.CELL = e.target.value
                ?.replace('+1', '')
                ?.replace(/\D/g, '')
                ?.slice(0, 10)
              setCont({ ...cont, CELL: e.target.value })
            }}
          />
        </label>
        <label className='block capitalize relative w-[40px] px-3'>
          Email
          <input
            placeholder='email'
            className={`${uiStyles.input} w-[180px] text-xs`}
            value={cont?.EMAIL || ''}
            onChange={(e) => {
              // if (contact.updated) {
              //     contact.updated = false
              // } else {
              contact.updated = true
              //}
              contact.EMAIL = e.target.value
              setCont({ ...cont, EMAIL: e.target.value })
            }}
          />
        </label>
      </div>
      <div className='flex flex-col gap-3 items-center w-full'>
        <label className='block capitalize relative w-[40px] px-3'>
          <input
            tabIndex={-1}
            className='absolute left-0 right-0 top-0 h-full opacity-0'
            type='checkbox'
            name={cont?.NM}
            value={cont?.STATUS}
            checked={cont?.STATUS === 'A' ? true : false}
            onChange={(e) => {
              // if (contact.updated) {
              //     contact.updated = false
              // } else {
              contact.updated = true
              //}
              if (e.target.checked) {
                contact.STATUS = 'A'
                setCont({ ...cont, STATUS: 'A' })
              } else {
                contact.STATUS = 'X'
                setCont({ ...cont, STATUS: 'X' })
              }
            }}
          />
          <div
            className={`transition-all delay-100 toggle inline-flex w-[36px] h-[20px] rounded-2xl bg-gradient-to-b ${
              cont?.STATUS === 'A'
                ? 'from-accent-150 to-accent-250'
                : 'from-gray-200 to-gray-200'
            } p-[2px] mr-2 align-middle`}
          >
            <span
              className={`bg-white handle transition-all delay-100  w-[16px] h-[16px] rounded-full inline-block  ${
                cont?.STATUS === 'A' && 'transform translate-x-4'
              }`}
            ></span>
          </div>
          {cont?.STATUS === 'A'
            ? 'Active'
            : cont?.STATUS === 'X'
            ? 'Inactive'
            : 'Deleted'}
        </label>

        <button
          tabIndex={-1}
          className={`cursor-pointer w-[50px] ml-11`}
          onClick={() => {
            // if (contact.updated) {
            //     contact.updated = false
            // } else {
            contact.updated = true
            // }
            if (contact?.STATUS === 'D') {
              contact.STATUS = 'A'
              setCont({ ...cont, STATUS: 'A' })
            } else {
              contact.STATUS = 'D'
              setCont({ ...cont, STATUS: 'D' })
            }
          }}
        >
          {cont?.STATUS === 'D' ? (
            <AiFillDelete size={25} color={'red'} />
          ) : (
            <AiFillDelete color={'gray'} size={25} />
          )}
        </button>
      </div>
    </div>
  )
}

export default Contact
