import { useEffect, useRef, useState } from 'react'
import Wrapper from '../../../components/layout/Wrapper'
import SubNav from '../../../components/SubNav'
import TitleIcon from '../../../components/ui/TitleIcon'
import uiStyles from '../../../components/ui/uiStyles'
import { useNavigate } from 'react-router-dom'
import { AiFillBug } from 'react-icons/ai'
import { useProfile } from '../../../contexts/UserContext'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { useAuth0 } from '@auth0/auth0-react'
import { FaSleigh } from 'react-icons/fa'
const Feedback = ({ modal, setShowModal }) => {
  const { profile } = useProfile()
  console.log('profile', profile)
  const { user } = useAuth0()
  const { axiomFetch } = useAxiomFetch()
  const navigate = useNavigate()
  const textRef = useRef(null)
  const [messageSent, setMessageSent] = useState({ status: 'done' })

  const handle_msg = (status, message, close) => {
    setMessageSent({ ...messageSent, status, message })
    if (close) {
      setTimeout(() => {
        setMessageSent({ ...messageSent, status: 'done', message: '' })
      }, 3000)
    }
  }

  useEffect(() => {
    textRef.current.focus()
  }, [])

  const html = (
    <div className={`${uiStyles.content_wrap}`}>
      <div className={`${uiStyles.line}`}></div>
      <div className={`flex gap-3`}>
        <p className={`${uiStyles.text_black_subtitle}`}>Found a bug? </p>
        <AiFillBug size={25} />
      </div>
      <div>
        <p className={`${uiStyles.text_black_subtitle}`}>
          Or want to share about your experience using the app?
        </p>
      </div>
      <textarea
        placeholder='Share feedback with us...'
        ref={textRef}
        className={`${uiStyles.text_area} ${modal ? 'h-[180px]' : 'h-[200px]'}`}
      ></textarea>
      <div className={`${uiStyles.line}`}></div>
      <div className='mt-[10px] flex justify-end gap-2'>
        {messageSent.status === 'done' ? (
          <>
            <button
              className={`${uiStyles.white_btn}`}
              onClick={() => {
                if (setShowModal) {
                  setShowModal(false)
                } else {
                  navigate(-1)
                }
              }}
            >
              Cancel
            </button>

            <button
              className={`${uiStyles.green_btn}`}
              onClick={async () => {
                const additional_info_html = `
          <h1>
          Bug Info 
          </h1>
          <ul>
          <li>url: ${window.location.href}</li>
          <li>Name: ${profile.CONT_NAME}</li>
          <li>Logged in Cell: ${
            user?.name?.replace('+1', '') || user?.nickname?.replace('+1', '')
          }
          <li>Cell: ${profile.CONT_CELL_NUM}</li>
          <li>contId: ${profile.CONT_ID}</li>
          </ul>
          `

                const additional_info_text = `
          Bug Info:
          url: ${window?.location?.href || ''}
          Name: ${profile?.CONT_NAME || ''}
          Logged in Cell: ${
            user?.name?.replace('+1', '') ||
            user?.nickname?.replace('+1', '') ||
            ''
          }
          Cell: ${profile?.CONT_CELL_NUM || ''}
          contId: ${profile?.CONT_ID || ''}
          `
                if (textRef?.current?.value) {
                  handle_msg('success_msg', 'Feedback Sent!', true)
                  const body = {
                    msg: {
                      to: [
                        // 'andrewj@driggstitle.com',
                        // 'ryano@driggstitle.com',
                        'zach@driggstitle.com',
                        'erikal@driggstitle.com',
                        profile?.REP_EMAIL || '',
                      ]?.filter((item) => item),
                      // to: ['andrewj@driggstitle.com'],
                      from: 'hello@theaxiomapp.com',
                      subject: `Axiom Feedback - ${profile?.CONT_NAME}`,
                      text: `${additional_info_text} ${textRef?.current?.value}`,
                      html: `${additional_info_html} <h2>User additional info:</h2> <ul>${textRef?.current?.value}</ul>`,
                    },
                    url: window?.location?.href || '',
                    cont_name: profile?.CONT_NAME || '',
                    cont_cell_phone: profile?.CONT_CELL_NUM || '',
                    login_cell_phone:
                      user?.name?.replace('+1', '') ||
                      user?.nickname?.replace('+1', '') ||
                      '',
                    bug_report_text: textRef?.current?.value || '',
                    no_send_email: false,
                    insert_bugs: true,
                  }
                  textRef.current.value = ''
                  const data = await axiomFetch(
                    '/social/email/sendEmail',
                    'POST',
                    body
                  )
                  setTimeout(() => setShowModal(false), [500])
                  console.log(data)
                }
              }}
            >
              Send
            </button>
          </>
        ) : (
          <div className={`${uiStyles[messageSent.status]} mb-[20px]`}>
            {messageSent.message}
          </div>
        )}
      </div>
    </div>
  )

  if (modal) {
    return (
      <>
        <TitleIcon>Feedback</TitleIcon>
        {html}
      </>
    )
  }

  return (
    <Wrapper>
      <SubNav />
      <TitleIcon>Feedback</TitleIcon>
      {html}
    </Wrapper>
  )
}

export default Feedback
