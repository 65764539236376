import React, { useMemo, memo, useCallback, useEffect } from 'react'
import {
  CircleF,
  GoogleMap,
  MarkerF,
  useJsApiLoader,
} from '@react-google-maps/api'
import images_homes from '../../../assets/images/home'
import { CircleCheckToggle } from '../../form/CircleCheckToggle'
import uiStyles from '../../ui/uiStyles'
import ButtonsRow from '../../ui/ButtonsRow'
import { libraries } from './utils'

export const HomePlaces = memo(({ state, setState, state_abbr, location }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAdJBLTyoh5xMQujUK488i-am9syWuiG6A',
    libraries: libraries,
  })

  const on_load = useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const request = {
      location: {
        lat:
          state?.homes?.current_home?.lat ||
          location?.lat ||
          (state_abbr === 'NV' ? 36.171563 : 33.4483771),
        lng:
          state?.homes?.current_home?.lng ||
          location?.lng ||
          (state_abbr === 'NV' ? -115.1391009 : -112.0740373),
      },
      radius: state?.homes?.radius || location?.radius || 3000, // The radius in meters to search for nearby places
      // restarau
      type: [
        'grocery_or_supermarket',
        'school',
        'park',
        'restaurant',
        'hospital',
        'pharmacy',
        'shopping_mall',
        'library',
        'gym',
        'public_transport_station',
      ],
    }
    console.log('request', request)
    const service = new window.google.maps.places.PlacesService(map)
    service.nearbySearch(request, (results, status) => {
      console.log(results)
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        console.log('Nearby places:', results)
        // Do something with the 'results' array of nearby places
        setState((prev) => ({ ...prev, places: results }))
      } else {
        console.log('Nearby search failed:', status)
      }
    })
  }, [])
  const on_unmount = useCallback(function callback(map) {
    setState((prev) => ({ ...prev, map: null }))
  }, [])

  const center_circle = useMemo(
    () => ({
      lat:
        state?.homes?.current_home?.lat ||
        location?.lat ||
        (state_abbr === 'NV' ? 36.171563 : 33.4483771),
      lng:
        state?.homes?.current_home?.lng ||
        location?.lng ||
        (state_abbr === 'NV' ? -115.1391009 : -112.0740373),
    }),
    [
      location?.lat,
      location?.lng,
      state?.homes?.current_home?.lat,
      state?.homes?.current_home?.lng,
      state_abbr,
    ]
  )

  const num_to_$ = (num) => {
    let price = ``
    for (let i = 0; i < num; i++) {
      price += '$'
    }

    return price
  }
  const get_all_places = () => {
    const places = state?.places
    if (state?.homes?.nearby_type === 'cinema') {
      state.homes.nearby_type = 'movie_theater'
    }
    if (state?.homes?.nearby_type === 'any' || !state?.homes?.nearby_type) {
      return places?.sort((a, b) => a.name.localeCompare(b.name)) || []
    } else {
      return (
        places
          ?.filter((item) => item.types.includes(state?.homes?.nearby_type))
          ?.sort((a, b) => a.name.localeCompare(b.name)) || []
      )
    }
  }

  const get_place_detail = (label, value, label_2, value_2) => {
    if (!value || value === 'undefined') {
      return ''
    } else {
      if (typeof value === 'boolean') {
        if (value) {
          value = 'Yes'
        } else {
          value = 'No'
        }
      }
      if (typeof value_2 === 'boolean') {
        if (value_2) {
          value_2 = 'Yes'
        } else {
          value_2 = 'No'
        }
      }
      if (!value_2) {
        return (
          <div className='flex'>
            <p className='mr-[5px] font-bold'>{`${label}:`}</p>
            <p>{value}</p>
          </div>
        )
      } else {
        return (
          <div className='flex'>
            <p className='mr-[5px] font-bold'>{`${label}:`}</p>
            <p>{value}</p>
            <p className='mx-[5px] font-bold'>{`${label_2}:`}</p>
            <p>{value_2}</p>
          </div>
        )
      }
    }
  }

  return (
    <>
      <div className='flex flex-col items-center gap-[10px] mr-[10px] mb-[10px]'>
        <img className='w-[55px] ml-[10px]' src={images_homes.places_icon} />
        <CircleCheckToggle
          name={`show_places_list`}
          type='toggle'
          toggle_left={false}
          toggle_color_grad={'from-[#0A66C2] to-[#0A66C2]'}
          toggle_color={'text-[#0A66C2]'}
          toggle_left_label={'Map View'}
          toggle_right_label={'List View'}
          inputValue={state}
          handle_change={async (e) => {
            setState((prev) => {
              window.localStorage.setItem(
                'show_places_list',
                prev?.show_places_list === 'Y' ? 'N' : 'Y'
              )
              return {
                ...prev,
                show_places_list: prev?.show_places_list === 'Y' ? 'N' : 'Y',
              }
            })
          }}
        />
      </div>
      {state?.show_places_list === 'Y' ? (
        <div>
          <div className='px-3'>
            <ButtonsRow
              items={['any', 'store', 'food', 'school', 'park', 'lodging']}
              handler={(item) => {
                console.log(item)
                setState((prev) => ({
                  ...prev,
                  homes: { ...prev.homes, nearby_type: item },
                }))
              }}
              selected={state?.homes?.nearby_type}
              button_style={'text-[12px] pl-2'}
            />
            <ButtonsRow
              items={['museum', 'cinema', 'finance', 'church', 'political']}
              handler={(item) => {
                setState((prev) => ({
                  ...prev,
                  homes: { ...prev.homes, nearby_type: item },
                }))
              }}
              selected={state?.homes?.nearby_type}
              button_style={'text-[12px] pl-2'}
            />
          </div>
          <div className='pt-[10px] max-h-[580px] overflow-scroll'>
            {get_all_places()?.length ? (
              get_all_places()?.map((item) => {
                return (
                  <div key={item?.place_id}>
                    <div className='flex gap-[10px] ml-[10px] mb-[10px]'>
                      <img
                        src={item?.icon}
                        className='w-[60px] object-contain'
                      />
                      <div className='flex flex-col'>
                        <div className='flex'>
                          <p className='font-bold text-[14px] text-black'>
                            {item?.name || ''}
                          </p>
                        </div>
                        {get_place_detail(
                          'Vicinity',
                          item?.vicinity?.split(',')[0]
                        )}
                        {get_place_detail(
                          'Rating',
                          `${item?.rating}`,
                          'Total',
                          item?.user_ratings_total
                        )}
                        {get_place_detail(
                          'Price level',
                          num_to_$(item?.price_level)
                        )}
                        {get_place_detail(
                          'Status',
                          item?.business_status?.toLowerCase(),
                          'Open',
                          item?.opening_hours?.open_now
                        )}
                      </div>
                    </div>
                    <div className={`${uiStyles.line_light} mb-[10px]`}></div>
                  </div>
                )
              })
            ) : (
              <div className='px-3'>No places nearby</div>
            )}
          </div>
        </div>
      ) : (
        <>
          {isLoaded ? (
            <GoogleMap
              mapContainerStyle={{
                width: '100%',
                height: '600px',
              }}
              center={center_circle}
              zoom={state?.place ? 12.5 : 12.5}
              options={{
                disableDefaultUI: true,
                zoomControl: true,
              }}
              onLoad={on_load}
              onUnmount={on_unmount}
            >
              <MarkerF
                onLoad={(e) => {
                  console.log(e)
                  setState((prev) => ({
                    ...prev,
                    place: { ...prev.place, marker: e },
                  }))
                }}
                position={center_circle}
                icon={{
                  url: images_homes.home_icon_black,
                  scaledSize: new window.google.maps.Size(25, 25),
                }}
                // label={{
                //   text: `${state?.place?.circle?.radius} mile radius`,
                //   color: 'black',
                //   fontSize: '13px',
                //   fontWeight: 'bold',
                //   className: 'marker-position',
                // }}
              ></MarkerF>
              {state?.places?.map((place, index) => (
                <MarkerF
                  key={index}
                  icon={{
                    url: place?.icon,
                    scaledSize: new window.google.maps.Size(20, 20), // Adjust the size as needed
                  }}
                  label={{
                    text: place?.name,
                    color: 'black',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    className: 'marker-position',
                  }}
                  position={{
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                  }}
                />
              ))}
              <CircleF
                radius={state?.place?.circle?.radius || 3218.69}
                editable={false}
                draggable={false}
                options={{
                  fillColor: null,
                  fillOpacity: 0,
                  strokeColor: '#05A660',
                  strokeWeight: 3,
                }}
                center={center_circle}
                // onCenterChanged={circle_change}
                // onRadiusChanged={circle_change}
              />
            </GoogleMap>
          ) : (
            'loading..'
          )}
        </>
      )}
    </>
  )
})
