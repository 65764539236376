import { useState } from 'react'
import { AiFillDelete } from 'react-icons/ai'
import { HiPhone, HiEnvelope } from 'react-icons/hi2'
import { TbWorld } from 'react-icons/tb'
import { TfiPencilAlt } from 'react-icons/tfi'
import { useNavigate } from 'react-router-dom'
import { formatPhoneNumber, set_storage } from '../../../helpers'
import Modal from '../../Modal'
import ListItem from '../../ui/ListItem'
import SwipeConfig from '../../ui/SwipeConfig'
import uiStyles from '../../ui/uiStyles'
import AddProvider from './AddProvider'
import DeleteProvider from './DeleteProvider'
import EditProvider from './EditProvider'

const EditCardServices = ({ state, setState }) => {
  const [page, setPage] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const [selected, setSelected] = useState(1)
  const navigate = useNavigate()
  console.log(
    'service',
    state?.providers?.filter((item) => {
      return (
        item.market_uuid ===
        (state?.market_default_uuid || state?.markets?.[0].default_uuid)
      )
    })
  )

  const get_markets = () => {
    return (
      state?.markets?.filter((item) => {
        return item.type?.split(',').includes('services')
      }) || []
    )
  }

  const current_market_name = () => {
    return (
      get_markets()?.find(
        (market) => market.default_uuid === state?.market_default_uuid
      ) || get_markets()?.[0]
    )?.location_name
  }
  const modals = {
    add: (
      <AddProvider
        market_name={current_market_name()}
        state={state}
        setState={setState}
        setShowModal={setShowModal}
        page={page}
        setPage={setPage}
      />
    ),
    edit: (
      <AddProvider
        market_name={current_market_name()}
        state={state}
        setState={setState}
        setShowModal={setShowModal}
        page={page}
        setPage={setPage}
      />
    ),
    remove: (
      <DeleteProvider
        market_name={current_market_name()}
        state={state}
        setState={setState}
        setShowModal={setShowModal}
        page={page}
        setPage={setPage}
      />
    ),
  }
  const settingsRight = (item) => {
    return () => (
      <div className='flex gap-[10px]'>
        <div>
          {item?.cell ? (
            <a href={`tel:${item?.cell}`} className={'underline'}>
              <HiPhone size={25} />
            </a>
          ) : (
            ''
          )}
        </div>
        <div>
          {item?.email ? (
            <a
              href={`mailto:?to=${item?.email}&subject=&body=`}
              target='_blank'
              rel='noreferrer'
              className='underline cursor-pointer'
            >
              <HiEnvelope size={25} />
            </a>
          ) : (
            ''
          )}
        </div>
        <div>
          {item?.website ? (
            <a
              href={item?.website}
              target='_blank'
              rel='noreferrer'
              className='underline cursor-pointer'
            >
              <TbWorld size={25} />
            </a>
          ) : (
            ''
          )}
        </div>
      </div>
    )
  }

  const settingsLeft = (item) => {
    return () => (
      <div className='flex gap-[20px]'>
        <div
          className='cursor-pointer'
          onClick={() => {
            setState((prev) => {
              const return_data = {
                ...prev,
                market_edit: item,
              }
              return return_data
            })
            setModalType('remove')
            setShowModal(true)
          }}
        >
          <AiFillDelete size={25} />
        </div>
        <div
          className='cursor-pointer'
          onClick={() => {
            setState((prev) => {
              const return_data = {
                ...prev,
                market_edit: item,
              }
              return return_data
            })
            setModalType('edit')
            setShowModal(true)
          }}
        >
          <TfiPencilAlt size={25} />
        </div>
      </div>
    )
  }
  return (
    <div>
      <div className='px-3'>
        <h1 className={`${uiStyles.text_black_title} mb-[10px]`}>
          Edit My Guys
        </h1>
        <p>Orange slider will not show on actual cards...</p>

        {/* <label className={`${uiStyles.label}`}>Market</label>
        <select
          className={`${uiStyles.select}`}
          value={state?.market_default_uuid || state?.markets?.[0].default_uuid}
          onChange={(e) => {
            setState((prev) => ({
              ...prev,
              market_default_uuid: e.target.value,
            }))
          }}
        >
          {get_markets()?.map((item) => {
            return (
              <option key={item.default_uuid} value={item.default_uuid}>
                {item?.location_name}
              </option>
            )
          })}
        </select> */}
      </div>
      <div className='gap-[2px] mt-[10px]'>
        {state?.providers?.length ? (
          state?.providers?.map((item, index) => {
            return (
              <div key={item.default_uuid}>
                <SwipeConfig
                  key={index}
                  index={index}
                  selectedIndex={selected}
                  setSelectedIndex={setSelected}
                  RightComponents={settingsRight(item)}
                  LeftComponents={settingsLeft(item)}
                  className='mb-[2px]'
                >
                  <ListItem
                    title={item?.name}
                    subtitle={item?.type}
                    star_rating={item?.star_rating}
                    content={item?.about}
                    image={item?.photo || ''}
                  />
                </SwipeConfig>
              </div>
            )
          })
        ) : (
          <div className='px-3'>Your Providers will go here.</div>
        )}
      </div>
      <div className='mt-[10px] flex justify-end px-3'>
        <button
          className={`${uiStyles.hover_btn}`}
          onClick={() => {
            setState((prev) => {
              const return_data = {
                ...prev,
                market_edit: {},
              }
              return return_data
            })
            setModalType('add')
            setShowModal(true)
          }}
        >
          Add My Guy
        </button>
      </div>

      {showModal ? (
        <Modal
          modalName={modalType}
          height={modalType === 'remove' ? 'h-[200px]' : ''}
          showModal={showModal}
          setShowModal={setShowModal}
          showClose={true}
        >
          {modals[modalType]}
        </Modal>
      ) : (
        ''
      )}
    </div>
  )
}

export default EditCardServices
