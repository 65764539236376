import React, { useState } from 'react'
import silhouette from '../../../assets/images/profile-silhouette.svg'
import Header2 from '../../Header2'
import { PAIRWISELOBBY } from '../../../data/data'
import ListItem from '../../ui/ListItem'
import SwipeConfig from '../../ui/SwipeConfig'
import { FaBolt } from 'react-icons/fa'
import SubTitle from '../../ui/SubTitle'
import { Link, useNavigate } from 'react-router-dom'
import Wrapper from '../../layout/Wrapper'
import { useProfile } from '../../../contexts/UserContext'
import Usage from '../../usage/Usage'
import { List } from '../../helpers'
import { validate_number } from '../../../helpers'
export default function PairwiseLobby() {
  const [selected, setSelected] = useState(1)
  const { profile } = useProfile()
  const navigate = useNavigate()
  console.log(profile)

  return (
    <Wrapper>
      <div className='flex items-center px-2 mb-[10px]'>
        <div>
          <FaBolt className='text-yellow-500 text-6xl' />
        </div>
        <div>
          <SubTitle>Pairwise</SubTitle>
          <p>All things pairwise.</p>
        </div>
      </div>
      <div className='flex flex-col gap-[2px]'>
        <List
          click_me={true}
          list={PAIRWISELOBBY?.filter((item) => {
            if (
              validate_number(profile?.PAIR_ID) > 0 &&
              (item?.type === 'in_pairwise' || item?.type === 'both')
            ) {
              return true
            } else if (
              validate_number(profile?.PAIR_ID) <= 0 &&
              (item?.type === 'out_pairwise' || item?.type === 'both')
            ) {
              return true
            }
          })?.map((item, index) => {
            item.click_me = true
            item.select = () => {
              return navigate(item.link)
            }
            return item
          })}
        />
      </div>
    </Wrapper>
  )
}
