import { useState, memo } from 'react'
import { format_currency, validate_number } from '../../helpers'
import uiStyles from '../ui/uiStyles'

const CalcAmortizationAccord = memo(
  ({ schedule, title = '', ...restProps }) => {
    const [selection, setSelection] = useState(0)
    const curr_str = (val) => {
      return `$${format_currency(val, false, 2)}`
    }
    return (
      <div {...restProps}>
        <div className='flex justify-center flex-col'>
          <h1 className='text-black text-[16px] font-bold'>{title}</h1>
          <div>
            {Object?.entries(schedule || {})?.map(([key, value], index) => {
              const selected =
                validate_number(selection) ===
                Math.floor(validate_number(key) / 12) + 1
              const transition_in = `transition-[max-height] duration-200 ease-in`
              const transition_out = `transition-[max-height] duration-200 ease-out`
              let year_html = (
                <div
                  className='cursor-pointer'
                  onClick={() => {
                    setSelection((prev) =>
                      prev === Math.floor(validate_number(key) / 12) + 1
                        ? 0
                        : Math.floor(validate_number(key) / 12) + 1
                    )
                  }}
                >
                  {' '}
                  <div className={`${uiStyles.line_3px} mt-[0px]`}></div>
                  <div className='flex justify-between items-center my-[5px] text-black font-bold'>
                    <p className=''>
                      Year {Math.floor(validate_number(key) / 12) + 1}{' '}
                    </p>
                    <p className=''>
                      {' '}
                      $
                      {format_currency(
                        value?.remaining_balance + value?.principal,
                        false,
                        2
                      )}{' '}
                    </p>
                  </div>
                  <div
                    className={`${uiStyles.line_3px} mt-[0px]  ${
                      selected
                        ? `opacity-1 ${transition_in}`
                        : `opacity-0 ${transition_out}`
                    }`}
                  ></div>
                  <div
                    className={`pl-2 grid grid-cols-4 ${
                      validate_number(selection) ===
                      Math.floor(validate_number(key) / 12) + 1
                        ? `max-h-[100px] h-full ${transition_in}`
                        : `max-h-0 overflow-hidden ${transition_out}`
                    }
                } `}
                  >
                    <div>Month</div>
                    <div>Principal</div>
                    <div>Interest</div>
                    <div>Balance</div>
                  </div>
                </div>
              )
              let monthly_html = (
                <div
                  className={`pl-2 ${
                    validate_number(selection) ===
                    Math.floor(validate_number(key) / 12) + 1
                      ? `max-h-[100px] h-full ${transition_in}`
                      : `max-h-0 overflow-hidden ${transition_out}`
                  }`}
                >
                  {<div className={`mt-[0px]`}></div>}
                  <div className='grid grid-cols-4'>
                    <p className=''>{(validate_number(key) + 1) % 12 || 12}</p>
                    <p className=''>{curr_str(value?.principal)}</p>
                    <p className=''>{curr_str(value?.interest)}</p>
                    <p className=''>{curr_str(value?.remaining_balance)}</p>
                  </div>
                  {(validate_number(key) + 1) % 12 ? (
                    <div className={`${uiStyles.line} mb-[0px]`}></div>
                  ) : (
                    ''
                  )}
                </div>
              )

              let html = (
                <div className='w-full' key={index}>
                  {validate_number(key) % 12 === 0 ? year_html : <></>}
                  {monthly_html || <></>}
                </div>
              )
              return html
            })}
          </div>
        </div>
      </div>
    )
  }
)

export default CalcAmortizationAccord
