import React from 'react'
import Wrapper from '../../components/layout/Wrapper'
import SubNav from '../../components/SubNav'
import TitleIcon from '../../components/ui/TitleIcon'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import uiStyles from '../../components/ui/uiStyles'
import { useEffect, useState, useRef } from 'react'
import { useParams, useLocation, useSearchParams } from 'react-router-dom'
import { Contact } from '../../components/pages/Lists'
import SmallLoading from '../../components/ui/SmallLoading'
import SwipeConfig from '../../components/ui/SwipeConfig'
import { formatPhoneNumber } from '../../helpers'

const PageSingleLeadList = ({
  modalListId,
  modalListName,
  showTeamConfig,
  setShowTeamConfig,
  smaller,
  saveList,
  setSaveList,
  setRefresh,
}) => {
  const { state } = useLocation()
  const { id } = useParams()
  const { axiomFetch } = useAxiomFetch()
  const [lists, setLists] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [cells, setCells] = useState(false)
  const [emails, setEmails] = useState(false)
  const [searchParams] = useSearchParams()
  const listStartDate = searchParams.get('startdate')
  const listEndDate = searchParams.get('enddate')

  const contactsRef = useRef(null)
  useEffect(() => {
    const Getlists = async () => {
      setIsLoading(true)
      const requestPath =
        listStartDate && listEndDate
          ? `/client/listings/lists/${
              id || modalListId
            }?filterByDate=true&startDate=${listStartDate}&endDate=${listEndDate}`
          : `/client/listings/lists/${id || modalListId}`
      const data = await axiomFetch(requestPath)
      setLists(data)
      setIsLoading(false)
    }
    Getlists()
  }, [modalListId])

  const sort_list = (a, b) => {
    let nameA = a?.NM?.toUpperCase().trim()
    let nameB = b?.NM?.toUpperCase().trim()
    if (!nameB) return 1
    if (!nameA) return -1
    if (nameA > nameB) {
      return 1
    }
    if (nameA < nameB) {
      return -1
    }

    return 0
  }

  const counter = (arr, key) => {
    if (!arr || arr.length < 1) return 0
    let counter = 0
    for (const obj of arr) {
      if (obj[key?.toUpperCase()] && obj.STATUS === 'A') {
        counter++
      }
    }
    return counter
  }

  return (
    <>
      {showTeamConfig ? (
        <>
          {showTeamConfig ? '' : <SubNav state={state} />}
          <TitleIcon className=''>{`List: ${
            state?.list_name || '' || modalListName || ''
          }`}</TitleIcon>
          <div
            className={`flex flex-col px-[20px] items-center overflow-x-hidden overflow-y-scroll ${
              smaller ? `max-h-[300px]` : `max-h-[550px]`
            }`}
          >
            <div className='flex items-baseline gap-3'>
              {/* <h1 ref={contactsRef} className='m-0 text-[20px]'>{`Contacts:`}</h1> */}
              {/* <div className='text-[18px] flex gap-3'>
            <button className={`cursor-pointer p-2 border-2 border-green-200 ${cells ? 'border-opacity-100' : 'border-opacity-0'}`}
            onClick={() => {
               setCells(!cells)
            }}
            >
                Cells: {counter(lists, 'cell')}
            </button>
            <button className={`cursor-pointer p-2 border-2 border-green-200 ${emails ? 'border-opacity-100' : 'border-opacity-0'}`}
            onClick={() => {
               setEmails(!emails)
            }}
            >
                Emails: {counter(lists, 'email')}
            </button>
            </div> */}
            </div>
            {isLoading && (
              <div>
                <SmallLoading />
              </div>
            )}
            {!isLoading &&
              lists
                ?.sort((a, b) => sort_list(a, b))
                ?.map((list, index) => {
                  if (list.STATUS === 'D') {
                    return
                  }
                  if (cells && !list.CELL) {
                    return
                  }
                  if (emails && !list.EMAIL) {
                    return
                  }
                  return (
                    <div className='mb-[20px]'>
                      <Contact
                        key={`${list.UPLD_INPUT_ID}-${index}`}
                        contact={list}
                      />
                    </div>
                  )
                })}
          </div>
          <div className='p-[20px]'>
            {!isLoading && (
              <div className='flex justify-end gap-3'>
                <button
                  className={uiStyles.hover_btn}
                  onClick={async () => {
                    // setIsLoading(true)
                    // const data = await axiomFetch('/client/listings/lists', 'POST', [{}])
                    // const refetch = await axiomFetch('/client/listings/lists')
                    // setLists(refetch)
                    // setIsLoading(false)
                    setLists([...lists, {}])
                    contactsRef.current.scrollIntoView()
                  }}
                >
                  ADD
                </button>
                <button
                  className={uiStyles.hover_btn}
                  onClick={async () => {
                    let update_data = []
                    for (const list of lists) {
                      if (!list.updated) {
                        continue
                      } else {
                        delete list?.updated
                        update_data.push(list)
                      }
                    }
                    setIsLoading(true)
                    const data = await axiomFetch(
                      `/client/listings/lists/${id || modalListId}`,
                      'POST',
                      lists
                    )
                    if (saveList || saveList === 0) {
                      setSaveList(saveList + 1)
                    }
                    const refetch = await axiomFetch(
                      `/client/listings/lists/${id || modalListId}`
                    )

                    setRefresh((prev) => prev + 1)
                    setLists(refetch)
                    if (setShowTeamConfig) {
                      setShowTeamConfig(false)
                    }
                    setIsLoading(false)
                  }}
                >
                  Save
                </button>
              </div>
            )}
          </div>
        </>
      ) : (
        <Wrapper>
          {showTeamConfig ? '' : <SubNav state={state} />}
          <TitleIcon className='mb-5'>{`${
            state?.list_name || '' || modalListName || ''
          }`}</TitleIcon>
          <div className='flex flex-col px-[20px] items-center overflow-x-hidden overflow-y-scroll max-h-[550px]'>
            <div className='flex items-baseline gap-3'>
              {/* <h1 ref={contactsRef} className='m-0 text-[20px]'>{`Contacts:`}</h1> */}
              {/* <div className='text-[18px] flex gap-3'>
            <button className={`cursor-pointer p-2 border-2 border-green-200 ${cells ? 'border-opacity-100' : 'border-opacity-0'}`}
            onClick={() => {
               setCells(!cells)
            }}
            >
                Cells: {counter(lists, 'cell')}
            </button>
            <button className={`cursor-pointer p-2 border-2 border-green-200 ${emails ? 'border-opacity-100' : 'border-opacity-0'}`}
            onClick={() => {
               setEmails(!emails)
            }}
            >
                Emails: {counter(lists, 'email')}
            </button>
            </div> */}
            </div>
            {isLoading && (
              <div>
                <SmallLoading />
              </div>
            )}
            {!isLoading &&
              lists
                ?.sort((a, b) => sort_list(a, b))
                ?.map((list, index) => {
                  if (list.STATUS === 'D') {
                    return
                  }
                  if (cells && !list.CELL) {
                    return
                  }
                  if (emails && !list.EMAIL) {
                    return
                  }
                  return (
                    <div className='mb-[20px]'>
                      <Contact
                        key={`${list.UPLD_INPUT_ID}-${index}`}
                        contact={list}
                      />
                    </div>
                  )
                })}
          </div>
          <div className='p-[20px]'>
            {!isLoading && (
              <div className='flex justify-end gap-3'>
                <button
                  className={uiStyles.hover_btn}
                  onClick={async () => {
                    // setIsLoading(true)
                    // const data = await axiomFetch('/client/listings/lists', 'POST', [{}])
                    // const refetch = await axiomFetch('/client/listings/lists')
                    // setLists(refetch)
                    // setIsLoading(false)
                    setLists([...lists, {}])
                    contactsRef.current.scrollIntoView()
                  }}
                >
                  ADD
                </button>
                <button
                  className={uiStyles.hover_btn}
                  onClick={async () => {
                    let update_data = []
                    for (const list of lists) {
                      if (!list.updated) {
                        continue
                      } else {
                        delete list?.updated
                        update_data.push(list)
                      }
                    }
                    setIsLoading(true)
                    const data = await axiomFetch(
                      `/client/listings/lists/${id}`,
                      'POST',
                      lists
                    )

                    const refetch = await axiomFetch(
                      `/client/listings/lists/${id}`
                    )
                    setLists(refetch)
                    if (setShowTeamConfig) {
                      setShowTeamConfig(false)
                    }
                    setIsLoading(false)
                  }}
                >
                  Save
                </button>
              </div>
            )}
          </div>
        </Wrapper>
      )}
    </>
  )
}

export default PageSingleLeadList
