import { useEffect } from 'react'
import Wrapper from '../../components/layout/Wrapper'
import { HomePlaces } from '../../components/pages/home'
import SubNav from '../../components/SubNav'
import { useListingTours } from '../../contexts/ListingToursContext'
import { useReceiver } from '../../contexts/ReceiverContext'
import { get_storage } from '../../helpers'

export const PageHomePlaces = () => {
  const { receiver, saveReceiver } = useReceiver()
  const { listingTours } = useListingTours()

  const state_abbr =
    get_storage('card')?.profile?.ST_ABBR || receiver?.card?.profile?.ST_ABBR

  useEffect(() => {
    if (
      localStorage?.getItem('vbc_edit_mode') === 'true' &&
      listingTours?.buyer_tours?.property_edit?.search_type !== 'address'
    ) {
      saveReceiver((prev) => ({ ...prev, edit_mode: 'view' }))
    }
  }, [])
  return (
    <Wrapper>
      {receiver?.edit_mode === 'view' ? (
        <SubNav
          logo_url={`/user/my-vbc/${
            receiver?.vbc_receiver?.ID ||
            (get_storage('vbc_receiver') || {})?.ID
          }`}
          showConversations={false}
          showTours={true}
        />
      ) : (
        <SubNav
          logo_url={
            listingTours?.buyer_tours?.property_edit?.search_type === 'address'
              ? `/user/buyer_tours/add_home`
              : '/card'
          }
          showConversations={false}
        />
      )}
      <HomePlaces
        state={receiver}
        setState={saveReceiver}
        state_abbr={state_abbr}
        location={{
          lat:
            receiver?.homes?.current_home?.LAT ||
            (state_abbr === 'AZ' ? 33.4483771 : 36.171563),
          lng:
            receiver?.homes?.current_home?.LNG ||
            (state_abbr === 'AZ' ? -112.0740373 : -115.1391009),
        }}
      />
    </Wrapper>
  )
}
