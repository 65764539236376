import React, { useState } from 'react'
import { useListing } from '../../../contexts/ListingContext'
import { format_number } from '../../../helpers'
import LabelInput from '../../form/LabelInput'
import Modal from '../../Modal'
import uiStyles from '../../ui/uiStyles'

export const MarketingMlsFinder = ({ mls_value, handle_input = () => {} }) => {
  const { listing } = useListing()
  const [showListings, setShowListings] = useState(false)
  return (
    <div>
      {showListings ? (
        <div>
          {listing?.map((item) => {
            return (
              <div
                className='flex flex-col mb-[10px] cursor-pointer'
                onClick={() => {
                  handle_input({ target: { value: item?.mls_id } })
                  setShowListings(false)
                }}
              >
                <p className='underline'>MLS # {item.mls_id}</p>
                <p className='text-gray-600 text-[12px]'>{`Address: ${item.prop_add}`}</p>
              </div>
            )
          })}
        </div>
      ) : (
        <LabelInput
          top_label={'MLS #'}
          name={'mls_id'}
          value={format_number(mls_value) || ''}
          handleChange={handle_input}
        />
      )}
      <div className='flex justify-end'>
        <button
          className={uiStyles.hover_btn}
          onClick={() => {
            setShowListings((prev) => !prev)
          }}
        >
          {showListings ? 'Back' : 'Add from my listings'}
        </button>
      </div>
    </div>
  )
}
