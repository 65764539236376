import React, { memo } from 'react';

function PrimaryFormButton({ children, type = 'submit', disabled = false }) {
  return (
    <button
      type={type}
      disabled={disabled}
      className={`inline-block cursor-pointer font-semibold text-sm text-white bg-gradient-to-b from-accent-100 to-accent-600 px-6 py-2.5 rounded-lg hover:from-accent-150 hover:to-accent-250
      ${disabled ? 'from-gray-100 to-gray-300 hover:from-gray-100 hover:to-gray-300 cursor-not-allowed' : ''}`}
    >
      {children}
    </button>
  );
}

export default memo(PrimaryFormButton);
