import { useState, useEffect, useRef } from 'react'
import images_tours from '../../../assets/images/listing_tours'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import uiStyles from '../../ui/uiStyles'
import { format_currency } from '../../../helpers'
import structuredClone from '@ungap/structured-clone'
import LabelInput from '../../form/LabelInput'
const Rating = ({ state, setState, rating }) => {
  const { axiomFetch } = useAxiomFetch()
  let stars = [1, 2, 3, 4, 5]
  const fetchTimeout = useRef(0)
  const lastFetchtime = useRef(0)
  const set_new_rating = async (item) => {
    if (typeof item === 'number') {
      rating.rate_value = rating?.rate_value === item ? 0 : item
    } else {
      rating.rate_value = item
    }
    setState((prev) => {
      return {
        ...prev,
        data: {
          ...prev?.data,
          tour_ratings: [
            ...prev?.data?.tour_ratings?.filter(
              (item) => item.rating_id !== rating?.rating_id
            ),
            rating,
          ].sort((a, b) => a?.rate_ord - b?.rate_ord),
        },
      }
    })
    console.log(fetchTimeout)
    console.log(lastFetchtime)
    const currentTime = performance.now()
    if (currentTime - lastFetchtime?.current < 3000) {
      console.log('hello')
      clearTimeout(fetchTimeout?.current)
    }
    lastFetchtime.current = currentTime
    const new_rating = structuredClone(rating)
    if (new_rating?.rate_type === 'P') {
      new_rating.rate_value = rating?.rate_value
        ?.toString()
        ?.replace(/[$,]/g, '')
    }
    fetchTimeout.current = setTimeout(async () => {
      await axiomFetch('/tours/ratings/update', 'POST', new_rating, {}, false)
    }, 700)
  }
  return (
    <div className='w-full'>
      {rating?.rate_type === 'P' ? (
        <div>
          <LabelInput
            top_label={rating?.item_name || rating?.rate_prompt}
            show_dollar={true}
            value={format_currency(rating?.rate_value || '')}
            onChange={(e) => {
              set_new_rating(format_currency(e.target.value))
            }}
          />
          {/* <label>{rating?.item_name || rating?.rate_prompt}</label>
          <input
            className={uiStyles.input}
            value={format_currency(rating?.rate_value || '')}
            onChange={(e) => {
              set_new_rating(format_currency(e.target.value))
            }}
          /> */}
        </div>
      ) : rating?.rate_type === 'S' ? (
        <div className='flex gap-[5px] items-center'>
          {' '}
          <p>{rating?.item_name || rating?.rate_prompt}:</p>
          {stars?.map((item) => {
            return (
              <img
                key={rating?.rating_id + item}
                className='cursor-pointer'
                onClick={() => {
                  set_new_rating(item)
                }}
                src={
                  (rating.rate_value || 0) < item
                    ? images_tours?.star_outline
                    : images_tours?.star_filled
                }
              />
            )
          })}
        </div>
      ) : (
        <div className=''>
          <p>{rating?.item_name || rating?.rate_prompt}</p>
          <textarea
            key={rating?.rating_id}
            className={uiStyles.text_area}
            value={rating?.rate_value || ''}
            onChange={(e) => {
              console.log(e.target.value)
              set_new_rating(e.target.value)
            }}
          ></textarea>
        </div>
      )}
    </div>
  )
}

export default Rating
