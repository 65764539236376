import React, { memo } from 'react';

function ButtonWhite({
  children,
  clickHandler = () => {},
  isSelected = false,
  small = false,
  isDisabled,
}) {
  return (
    <button
      disabled={isDisabled}
      className={`inline-block cursor-pointer  border border-gray-200  bg-gradient-to-b ${
        isSelected
          ? 'from-accent-150 to-accent-250 text-white'
          : 'from-white to-white'
      } ${
        !small ? 'px-6 py-2.5 font-semibold text-sm ' : 'px-4 py-2 text-xs'
      } rounded-lg hover:from-accent-150 hover:to-accent-250 hover:text-white`}
      onClick={clickHandler}
    >
      {children}
    </button>
  );
}

export default memo(ButtonWhite);
