import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import ListItem from '../../ui/ListItem'
import { useAi } from '../../../contexts/AiContext'
import { useNavigate } from 'react-router-dom'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { useProfile } from '../../../contexts/UserContext'
import images_homes from '../../../assets/images/home'
import { content_types } from '../../../data/data'
import { useDistribute } from '../../../contexts/DistributeContext'
import {
  capitalize,
  format_dollar_currency,
  set_storage,
  validate_number,
} from '../../../helpers'

export const MarketingMaterialSlider = ({
  state,
  setState,
  current_item,
  sub_items,
  setLoading,
  weekender = false,
}) => {
  const { axiomFetch } = useAxiomFetch()
  const [swiper, setSwiper] = useState({})
  const [reload, setReload] = useState(0)
  const { ai, saveAi } = useAi()
  const { distribute, saveDistribute } = useDistribute()
  const { profile } = useProfile()
  const navigate = useNavigate()
  const switch_layout = state?.create_order?.switch_layout
  const topic = state?.create_order?.order_type
  const sub_topic =
    topic === 2
      ? state?.create_order?.sub_topic_property
      : state?.create_order?.sub_topic_promotion
  const save_address = () => {
    let address = {}
    if (state?.create_order?.current_selection?.del_add1_p) {
      return
    }

    if (profile?.BR_ADD_LINE1) {
      address = {
        // eslint-disable-next-line
        ['del_add1_p']: profile?.BR_ADD_LINE1 || '',
        // eslint-disable-next-line
        ['del_add2_p']: profile?.BR_ADD_LINE2 || '',
        // eslint-disable-next-line
        ['del_city_p']: profile?.BR_ADD_CITY || '',
        // eslint-disable-next-line
        ['del_st_p']: profile?.BR_ADD_ST || '',
        // eslint-disable-next-line
        ['del_zip_p']: profile?.BR_ADD_POSTAL || '',
      }
    } else if (
      profile.account?.office_address1 ||
      profile?.account?.member_address1
    ) {
      address = {
        // eslint-disable-next-line
        ['del_add1_p']:
          profile?.account?.member_address1 ||
          profile?.account?.office_address1 ||
          '',
        // eslint-disable-next-line
        ['del_add2_p']:
          profile?.account?.member_address2 ||
          profile?.account?.office_address2 ||
          '',
        // eslint-disable-next-line
        ['del_city_p']:
          profile?.account?.member_city || profile?.account?.office_city || '',
        // eslint-disable-next-line
        ['del_st_p']:
          profile?.account?.member_state_or_province ||
          profile?.account?.office_state_or_province ||
          '',
        // eslint-disable-next-line
        ['del_zip_p']:
          profile?.account?.member_postal_code ||
          profile?.account?.office_postal_code ||
          '',
      }
    } else {
      address = {
        // eslint-disable-next-line
        ['del_add1_p']: '',
        // eslint-disable-next-line
        ['del_add2_p']: '',
        // eslint-disable-next-line
        ['del_city_p']: '',
        // eslint-disable-next-line
        ['del_st_p']: '',
        // eslint-disable-next-line
        ['del_zip_p']: '',
      }
    }

    const save_selection = (key, value) => {
      setState((prev) => ({
        ...prev,
        create_order: {
          ...prev?.create_order,
          current_selection: {
            ...prev?.create_order?.current_selection,
            [key]: value,
          },
        },
      }))
    }

    setState((prev) => ({
      ...prev,
      create_order: {
        ...prev?.create_order,
        current_selection: {
          ...prev?.create_order?.current_selection,
          ...address,
        },
      },
    }))
  }

  const make_selection = async (item) => {
    setState((prev) => ({
      ...prev,
      hit_create_page: true,
    }))
    set_storage('material_back', '/user/marketing-material')
    if (item?.mkt_stats === 'Y') {
      setState((prev) => ({
        ...prev,
        create_order: {
          ...prev?.create_order,
          current_selection: item,
        },
      }))
      return navigate('/user/marketing-material/add-data')
    }
    if (state?.create_order?.order_type === 2 && !weekender) {
      const get_photos = async () => {
        const photos = await axiomFetch(
          `/tours/photos/${state?.current_listing?.armls_id}`,
          'GET',
          {},
          {},
          false
        )
        setState((prev) => ({
          ...prev,
          current_listing: {
            ...prev?.current_listing,
            photos: photos?.armls_photos,
          },
        }))
      }
      await get_photos()
    }
    if (item?.prpt_id) {
      saveAi((prev) => ({
        ...prev,
        parent_url: '/user/marketing-material',
        slot: [item]?.map((item) => {
          item.AI_PRPT_ID = item?.ai_prpt_id
          item.AI_GO_PROP = item?.require_prop
          item.AI_PRPT_DESC = item?.prpt_desc
          item.AI_PRPT_NM = item?.prpt_name
          item.AI_PRPT_PRPT = item?.prpt_prompt
          item.AI_PRPT_TAGS = item?.prpt_tags
          item.SLOT_AI_NUM = item?.prpt_ord
          item.AI_PRPT_THUMB = item?.thumb_img
          item.already_have_listing =
            current_item?.layout_topic === 2 ? true : false
          return item
        })[0],
        mls: {
          ...prev?.mls,
          mlsMatch: state?.current_listing,
          setting: 'mlsNo',
          add_mls_to_prompt: 1,
          start_ai: false,
        },
        state: {
          run: item?.require_prop === 'Y' ? false : true,
          prompt: item?.prpt_prompt,
        },
      }))
      saveAi((prev) => {
        return {
          ...prev,
          image_upload: {
            ...prev?.image_upload,
            gallery: {
              ...prev?.image_upload?.gallery,
              active: ai?.current_listing?.home_photo1
                ? {
                    url: ai?.current_listing?.home_photo1,
                  }
                : { url: '' },
            },
          },
        }
      })

      let distribute_content = distribute?.content || {}

      for (const content_type of content_types) {
        distribute_content[content_type] = {
          ...distribute_content?.[content_type],
          photo_url: ai?.current_listing?.home_photo1
            ? ai?.current_listing?.home_photo1
            : '',
          image_upload: {
            ...distribute_content?.[content_type]?.image_upload,
            gallery: {
              ...distribute_content?.[content_type]?.image_upload?.gallery,
              active: ai?.current_listing?.home_photo1
                ? {
                    url: ai?.current_listing?.home_photo1,
                  }
                : { url: '' },
            },
          },
        }
      }
      saveDistribute((prev) => ({
        ...prev,
        content: distribute_content,
      }))
      return navigate(`/user/ai`)
    }
    if (item?.layout_id) {
      item.layouts = item
      setState((prev) => ({
        ...prev,
        create_order: {
          ...prev?.create_order,
          current_selection: item,
        },
      }))

      const layout = item
      setLoading(true)
      let session = { sess_info: [] }
      const get_session = async () => {
        let try_count = 0
        session = await axiomFetch(
          `/marketing/materials/${switch_layout ? 'flyer/switch' : 'flyer'}`,
          'POST',
          {
            layout_id: layout?.layout_id,
            mls_id:
              state?.create_order?.order_type === 2 && !weekender
                ? state?.current_listing?.mls_id
                : 0,
            sess_id: state?.create_order?.session?.sess_id,
            sess_info: state?.create_order?.session?.sess_info,
            double_sided: layout?.double_sided === 'Y' ? true : false,
            loc_id: layout?.loc_id || 0,
          }
        )

        return session
      }
      session = await get_session()
      if (session?.sess_info) {
        session.sess_info = session?.sess_info?.map((item) => {
          if (item?.orig_val === 'None') {
            item.orig_val = ''
            item.saved_val = ''
          }
          return item
        })
      }
      if (
        session?.sess_info
          ?.filter(
            (item) =>
              item?.field_id !== 30064 &&
              item?.field_id !== 55779 &&
              item?.allow_edit === 'Y'
          )
          ?.some((item) => !item.saved_val)
      ) {
        session.empty_fields = true
        session.edit_fields_type = 'data'
      }
      session.layouts = [layout]
      if (state?.create_order?.order_type === 2 && !weekender) {
        session.mls_id = state?.current_listing?.mls_id
      }
      if (!session?.quantity || !layout?.quantity) {
        session.quantity = session?.def_ax_qty || layout?.def_ax_qty || 10
      }
      session.max_ax_qty = session?.max_ax_qty || layout?.max_ax_qty || 10

      setState((prev) => ({
        ...prev,
        create_order: {
          ...prev?.create_order,
          current_layout_selection: layout,
          current_selection: session,
          session: { ...layout, ...session },
          sessions: [{ ...layout, ...session }],
          switch_layout: false,
          selected_fields: '',
        },
      }))
      setLoading(false)
      navigate('/user/marketing-material/layouts/customize', {
        state: { parent: '/user/marketing-material' },
      })
    }
    if (item?.contain_id && item?.mkt_req_type_id) {
      const generate_container = async () => {
        setLoading(true)
        const body = {
          mls_id:
            state?.create_order?.order_type === 2 && !weekender
              ? state?.current_listing?.mls_id
              : 0,
          container_id: item.contain_id,
        }
        let container_sess = await axiomFetch(
          '/marketing/materials/container',
          'POST',
          body
        )

        const layout_ids = structuredClone(
          state?.materials?.mkt_req_container_items
        )
          ?.filter(
            (container_item) =>
              container_item?.container_id === item?.contain_id
          )
          ?.map((container_item) => container_item.layout_id)
        const layouts = state?.materials?.mkt_req_layout.filter(
          (item_layout) =>
            layout_ids.includes(item_layout.layout_id) &&
            // item_layout?.topic_subtype === current_selection?.topic_subtype &&
            !item_layout?.layout_name?.toLowerCase()?.includes('test')
        )

        let sessions = layouts?.map((item) => {
          return { ...item, ...container_sess }
        })
        sessions = await Promise.all(
          sessions.map(async (sess) => {
            let session = { sess_info: [] }
            const get_session = async () => {
              const print_quantity =
                state?.materials?.mkt_req_container_items?.find(
                  (cont_item) =>
                    cont_item?.layout_id === sess?.layout_id &&
                    cont_item?.container_id === sess?.container_id
                )?.print_quantity || 0
              session = await axiomFetch(
                `/marketing/materials/${
                  switch_layout ? 'flyer/switch' : 'flyer'
                }`,
                'POST',
                {
                  layout_id: sess?.layout_id,
                  mls_id:
                    state?.create_order?.order_type === 2 && !weekender
                      ? state?.current_listing?.mls_id
                      : 0,
                  // sess_id:
                  //   sess?.sess_id || state?.create_order?.session?.sess_id,
                  // sess_info:
                  //   sess?.sess_info || state?.create_order?.session?.sess_info,
                  double_sided: sess?.double_sided === 'Y' ? true : false,
                  print_quantity,
                  container_id: sess?.container_sess_id || 0,
                }
              )

              return session
            }
            session = await get_session()
            sess.layout_text = session.layout_text
            if (session.layout_text_back) {
              sess.layout_text_back = session.layout_text_back
            }
            sess.sess_info = session.sess_info
            if (session?.sess_info) {
              session.sess_info = session?.sess_info?.map((item) => {
                if (item?.orig_val === 'None') {
                  item.orig_val = ''
                  item.saved_val = ''
                }
                return item
              })
            }
            if (session?.sess_info?.some((this_item) => !this_item.saved_val)) {
              sess.empty_fields = true
              sess.edit_fields_type = 'data'
            }
            return { ...sess, ...session }
          })
        )

        let current_selection = item
        if (state?.create_order?.order_type === 2 && !weekender) {
          current_selection.mls_id = state?.current_listing?.mls_id
        }

        // let layout_ids = sessions.map((sess_item) => sess_item.layout_id)
        current_selection.layouts = layouts
        setTimeout(() => {
          setState((prev) => ({
            ...prev,
            create_order: {
              ...prev?.create_order,
              current_layout_selection: state?.materials?.mkt_req_layout?.find(
                (item) =>
                  item.layout_id === sessions?.[0]?.layout_id &&
                  layout_ids.includes(item?.layout_id)
              ),
              current_selection: current_selection,
              sessions,
              session: sessions[0],
              // empty_fields,
              // edit_fields_type,
              switch_layout: false,
              selected_fields: '',
            },
          }))
          setLoading(false)
          return navigate('layouts/customize')
        }, 10)
      }
      return await generate_container()
    }
    if (item?.contain_id) {
      setLoading(true)
      const layout_ids = state?.materials?.mkt_req_container_items
        ?.filter(
          (container_item) => container_item?.container_id === item?.contain_id
        )
        ?.map((container_item) => container_item.layout_id)
      const layouts = state?.materials?.mkt_req_layout?.filter(
        (layout_item) =>
          layout_ids?.includes(layout_item?.layout_id) &&
          layout_item?.topic_subtype === current_item?.topic_subtype
      )

      const initial_layout = layouts?.[0] || {}
      initial_layout.layouts = layouts || []
      initial_layout.contain_id = item?.contain_id || 0
      initial_layout.mls_id =
        state?.create_order?.order_type === 2 && !weekender
          ? state?.current_listing?.mls_id
          : null
      setState((prev) => ({
        ...prev,
        create_order: {
          ...prev?.create_order,
          // current_layout_selection: initial_layout,
          current_selection: initial_layout,
          // session: { initial_layout },
          sessions: [...layouts],
          switch_layout: false,
          selected_fields: '',
        },
      }))
      if (layouts?.length === 1) {
        let session = { sess_info: [] }
        const get_session = async () => {
          let try_count = 0
          session = await axiomFetch(
            `/marketing/materials/${switch_layout ? 'flyer/switch' : 'flyer'}`,
            'POST',
            {
              layout_id: initial_layout?.layout_id,
              mls_id:
                state?.create_order?.order_type === 2
                  ? state?.current_listing?.mls_id
                  : 0,
              sess_id: state?.create_order?.session?.sess_id,
              sess_info: state?.create_order?.session?.sess_info,
              double_sided: initial_layout?.double_sided === 'Y' ? true : false,
              loc_id: initial_layout?.loc_id || 0,
            }
          )

          return session
        }
        session = await get_session()
        if (session?.sess_info) {
          session.sess_info = session?.sess_info?.map((item) => {
            if (item?.orig_val === 'None') {
              item.orig_val = ''
              item.saved_val = ''
            }
            return item
          })
        }
        if (
          session?.sess_info
            ?.filter(
              (item) =>
                item?.field_id !== 30064 &&
                item?.field_id !== 55779 &&
                item?.allow_edit === 'Y'
            )
            ?.some((item) => !item.saved_val)
        ) {
          session.empty_fields = true
          session.edit_fields_type = 'data'
        }
        setState((prev) => ({
          ...prev,
          create_order: {
            ...prev?.create_order,
            current_layout_selection: item,
            session,
            sessions: [{ ...session }],
            switch_layout: false,
            selected_fields: '',
          },
        }))
        setLoading(false)
        return navigate('/user/marketing-material/layouts/customize')
      }
      setLoading(false)
      navigate('/user/marketing-material/layouts')
    }
    if (item?.req_id) {
      let new_item = {}
      if (item?.NEW_PDF_URL) {
        item.PDF_URL = item.NEW_PDF_URL
        item.IMAGE_LINK = item.NEW_PDF_URL
      }
      for (const [key, value] of Object.entries(item)) {
        new_item[key?.toLowerCase()] = value
      }
      if (!new_item?.quantity) {
        new_item.quantity = 10
      }

      save_address()
      setState((prev) => ({
        ...prev,
        create_order: {
          ...prev?.create_order,
          current_selection: {
            status_p: 65,
            re_order: true,
            mls_id:
              state?.create_order?.order_type === 2 && !weekender
                ? state?.current_listing?.mls_id
                : null,
            ...new_item,
          },
        },
      }))
      navigate('layouts/confirm', {
        state: { parent: '/user/marketing-material' },
      })
    }
  }

  const get_first_container_layout = (container, key) => {
    const layout_ids = state?.materials?.mkt_req_container_items
      ?.filter((item) => item.container_id === container?.contain_id)
      ?.map((item) => item.layout_id)

    const layout = state?.materials?.mkt_req_layout.find((item) => {
      return (
        layout_ids?.includes(item.layout_id) &&
        item?.[key] &&
        current_item?.topic_subtype === item?.topic_subtype
      )
    })
    return layout?.[key]
      ? layout[key]
      : key === 'thumb_link'
      ? images_homes.home_icon
      : ''
    // || 'https://driggstitle.com/reviews/Ax_Engage/dbc/08Facebook1080x1080PR1696011868949.png'
  }

  const get_list_item = (item, arr) => {
    const get_title = (item) => {
      return item?.prpt_id
        ? `AI ${capitalize(item?.type_name)}`
        : current_item?.slot_prompt
    }
    const get_subtitle = (item) => {
      return (
        item?.prpt_name ||
        item?.layout_name_alias ||
        item?.layout_name ||
        item?.contain_name ||
        // item?.axiom_name ||
        item?.product_req ||
        get_first_container_layout(item, 'layout_name_alias') ||
        get_first_container_layout(item, 'layout_name')
      )
    }
    const get_content = (item) => {
      return (
        item?.prpt_desc ||
        item?.axiom_blurb ||
        item?.location_name ||
        current_item?.group_name.replace('Instant', '').replace('instant', '')
      )
    }
    const get_image = (item) => {
      return (
        item?.thumb_img ||
        item?.thumb_link ||
        item?.image_link ||
        // item?.pdf_guide_url ||
        get_first_container_layout(item, 'thumb_link')
      )
    }
    const get_primary_key = () => {
      return 'layout_id'
    }

    const get_price_tag_color = (item) => {
      return item?.per_pg_print_cost ? 'bg-blue-400' : 'bg-green-400'
    }

    const get_price_tag = (item) => {
      const design_cost = validate_number(item?.design_cost)
      const print_cost = validate_number(item?.per_pg_print_cost)
      const price = design_cost + print_cost
      if (!price) {
        return ''
      } else {
        return `${print_cost ? format_dollar_currency(print_cost) : ''}${
          print_cost ? ' ea.' : ''
        } ${design_cost && print_cost ? '+' : ''} ${
          design_cost ? format_dollar_currency(design_cost) : ''
        }`
      }
    }

    const materials = state?.materials || {}
    const languages = [
      { lang_id: 0, lang_name: 'Any' },
      ...(materials?.mkt_fly_language || []),
    ]
    const markets = [
      { loc_id: 0, location_name: 'All' },
      ...(materials?.weekender_locations || []),
    ]

    return (
      <div className='min-w-[355px]'>
        <ListItem
          image_flag={get_price_tag(item)}
          image_flag_color={get_price_tag_color(item)}
          data_cy={`layout_${item?.layout_id}`}
          title={get_title(item)}
          subtitle={get_subtitle(item)}
          show_swipe_btns={false}
          swiper={swiper}
          content={get_content(item)}
          image={get_image(item)}
          select_on_image={true}
          select={async () => {
            await make_selection(item)
          }}
          show_filter={true}
          filter_type={'material_filter'}
          filter_list={arr?.map((item) => {
            return {
              ...item,
              image_flag: get_price_tag(item),
              image_flag_color: get_price_tag_color(item),
              title: get_title(item),
              subtitle: get_subtitle(item),
              content: get_content(item),
              image: get_image(item),
              click_me: true,
              select: async () => {
                await make_selection(item)
              },
              // swipe_right: () => {
              //   return (
              //     <div
              //       className='cursor-pointer'
              //       onClick={async () => {
              //         await make_selection(item)
              //       }}
              //     >
              //       Select
              //     </div>
              //   )
              // },
            }
          })}
          filter_strategy={{
            primary_key: get_primary_key(),
            types: {
              title: 'str',
              content: 'str',
              loc_mkt_id: 'options',
              lang_id: 'options',
              search: 'search',
              subtitle: 'str',
            },
            show_filters:
              item?.topic_subtype === 61
                ? ['loc_mkt_id', 'lang_id', 'search']
                : ['search'],
            search: {
              title: 'str',
              content: 'str',
              subtitle: 'str',
              loc_mkt_id: 'str',
              lang_id: 'str',
            },
            values: {
              title: get_title(item),
              subtitle: get_subtitle(item),
              content: get_content(item),
              loc_mkt_id: markets?.[0]?.loc_id,
              lang_id: languages?.[0]?.lang_id,
              search: '',
            },
            names: {
              title: '',
              content: '',
              loc_mkt_id: 'Market',
              lang_id: 'Language',
              search: '',
              subtitle: '',
            },
            placeholders: {
              search: 'Search...',
            },
            options_names: {
              loc_mkt_id: markets?.map((item) => item?.location_name),
              lang_id: languages?.map((item) => item?.lang_name),
            },
            options_values: {
              loc_mkt_id: markets?.map((item) => item?.loc_id),
              lang_id: languages?.map((item) => item?.lang_id),
            },
            options_show_all: {
              loc_mkt_id: 0,
              lang_id: 0,
            },
          }}
          current_item={current_item}
        />
      </div>
    )
  }
  return (
    <>
      {sub_items?.length > 1 ? (
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          onSlideChange={(e) => {
            setReload((prev) => (prev || 0) + 1)
          }}
          onSwiper={(swiper) => {
            try {
              const index = 0
              if (index > 0) {
                swiper.slideTo(index === -1 ? 0 : index, 0)
                return
              }
              if (index === 0 || index === -1) {
                swiper.slideTo(1, 900)
              }
              setTimeout(() => {
                if (index === 0 || index === -1) {
                  swiper.slideTo(0, 900)
                }
                swiper.slideTo(index === -1 ? 0 : index, 0)
                setSwiper(swiper)
              }, 175)
            } catch (error) {
              console.log(error)
            }
          }}
        >
          {sub_items?.length
            ? (sub_items || [])?.map((item, index) => (
                <div key={index}>
                  <SwiperSlide key={index}>
                    <div className='flex flex-col'>
                      {get_list_item(item, sub_items)}
                    </div>
                  </SwiperSlide>
                </div>
              ))
            : ''}
        </Swiper>
      ) : (
        <>{sub_items?.map((item) => get_list_item(item, sub_items))}</>
      )}
    </>
  )
}
