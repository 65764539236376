import { compressToUTF16, decompressFromUTF16 } from 'lz-string'
import { v4 as uuidv4 } from 'uuid'
function formatFileSize(bytes, decimalPoint) {
  if (bytes === 0) return '0 Bytes';
  var k = 1000,
    dm = decimalPoint || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function replaceWithBr(string) {
  if (!string || !string.length) {
    return string;
  }
  return string.replace(/\n/g, '<br />');
}

function formatPhoneNumber(phoneNumberString) {

  let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  if (cleaned.length < 3 && cleaned.length > 0) {
    return (`(${cleaned}`)
  }
  if (cleaned.length === 3) {
    if (phoneNumberString.endsWith(')')) {
      return (`(${cleaned.slice(0, 2)}`)
    }
    return (`(${cleaned}) `)
  }
  if (cleaned.length === 6) {
    if (phoneNumberString.includes(')') && phoneNumberString.includes('-')) {
      return (`(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}`)
    }
    if (phoneNumberString.includes(')') && phoneNumberString.includes('-')) {
      return (`(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-`)
    }
  }

  if (cleaned.length > 6) {
    return (`(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`)
  }

  if (cleaned.length > 3) {
    return (`(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}`)
  }
  return '';
}

function getCurrentTime(date) {
  let now = new Date();
  if (date) {
    now = new Date(date)
  }

  let hours = now.getHours();
  let minutes = now.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';

  // Convert to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // The hour '0' should be '12'

  // Add leading zero to minutes if needed
  minutes = minutes < 10 ? '0' + minutes : minutes;

  const currentTime = `${hours}:${minutes} ${ampm}`;
  return currentTime;
}


function getDateString({ date, format }) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  if (!format) {
    format = 'mmm d, yyyy'
  }
  if (!date) { date = new Date() }
  if (typeof date === 'string') {
    if (!date?.includes(':')) {
      date += ' 00:00:00';
    }
    date = new Date(new Date(date).toLocaleString('sv', { timeZone: 'America/Phoenix' }))
  }
  if (format.toLowerCase() === 'yyyy-mm-ddt24h:mi') {
    return date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0') + 'T' + date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0');
  } else if (format.toLowerCase() === 'sv') {
    return date.toLocaleString('sv')
  } else if (format.toLowerCase() === 'mm/dd/yy') {
    return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date?.getFullYear()?.toString()?.slice(2)}`
  } else if (format.toLowerCase() === 'mmm d, yyyy') {
    return `${(months?.[date?.getMonth()]?.slice(0, 3))} ${date.getDate()}, ${date.getFullYear()}`
  } else if (format.toLowerCase() === 'yyyy-mm-dd') {
    return `${date?.getFullYear()?.toString()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
  } else if (format?.toLowerCase() === 'month year') {
    return `${(months?.[date?.getMonth()])} ${date.getFullYear()}`
  } else if (format?.toLowerCase() === 'mmm d, hh:mi am') {
    return `${(months?.[date?.getMonth()]?.slice(0, 3))} ${date.getDate()}, ${getCurrentTime(date)}`
  } else if (format?.toLowerCase() === 'mmm d') {
    return `${(months?.[date?.getMonth()]?.slice(0, 3))} ${date.getDate()}`
  } else if (format?.toLowerCase() === 'mmm dd, yyyy hh:mi am') {
    return `${(months?.[date?.getMonth()]?.slice(0, 3))} ${date.getDate()}, ${date.getFullYear()} ${getCurrentTime(date)}`
  } else {
    return date.toDateString()
  }
}

function sv_date(date, add_days) {
  if (add_days) {
    /*eslint no-extend-native: ["warn", { "exceptions": ["Date"] }]*/
    Date.prototype.addDays = function (days) {
      let date_s = new Date(this.valueOf());
      date_s.setDate(date_s.getDate() + days);
      return date_s;
    };

    let date_f = new Date(date);
    date_f = date_f.addDays(add_days);
    return date_f.toLocaleString('sv', { timeZone: 'America/Phoenix' });
  }
  return new Date(date).toLocaleString('sv', { timeZone: 'America/Phoenix' });
}

function sv_string_change(date, format) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  let month_index = Number(date.split('T')[0].split(/-/g)[1]) - 1
  let day = Number(date.split('T')[0].split(/-/g)[2])
  let month_str_3 = months[month_index].slice(0, 3)
  let year = Number(date.split('T')[0].split(/-/g)[0])

  let hours = Number(date.split('T')[1].split(/:/g)[0])
  let min = Number(date.split('T')[1].split(/:/g)[1])
  let sec = Number(date.split('T')[1].split(/:/g)[2].split('.')[0])
  if (format === 'mmm d, yyyy') {
    date = `${month_str_3} ${day}, ${year}`
  } else if (format === 'h:mi am') {
    date = `${hours > 11 ? hours - 12 : hours}:${min.toString().padStart(2, '0')} ${hours > 11 ? 'PM' : 'AM'}`
  }
  return date
}

function az_to_local(date = new Date()) {
  const inputTZ = 'America/Phoenix'
  if (typeof date === 'string') {
    date = new Date(date?.split(/[TZ]/g)?.join(' '))
  } else if (typeof date === 'number') {
    date = new Date(date)
  }
  const offset = (
    new Date(
      new Date().toLocaleString('en-US', { timeZone: inputTZ }) // Phoenix
    ) - new Date(
      new Date().toLocaleString('en-US',
        // { timeZone: 'America/Los_Angeles' }
      )   // User's local timezone
    )
  )
  const second_offset = (
    new Date(
      new Date().toLocaleString('en-US',
        // { timeZone: inputTZ }
      ) // Phoenix
    ) - new Date(
      new Date().toLocaleString('en-US',
        { timeZone: inputTZ }
      )   // User's local timezone
    )
  )

  return date?.setMilliseconds(second_offset)
}

const formatUTC = (dateInt, addOffset = false) => {
  let date = (!dateInt || dateInt.length < 1) ? new Date() : new Date(dateInt);
  if (typeof dateInt === "string") {
    return date;
  } else {
    const offset = addOffset ? date.getTimezoneOffset() : -(date.getTimezoneOffset());
    const offsetDate = new Date();
    offsetDate.setTime(date.getTime() + offset * 60000)
    return offsetDate;
  }
}

function getPercentage(startDate, endDate = new Date()) {
  // Get the current date and time
  var now = new Date(); // Calculate the total duration between the start and end dates
  var totalDuration = endDate - startDate; // Calculate the elapsed duration from the start date to now
  var elapsedDuration = now - startDate; // Calculate the percentage of elapsed time
  var percentElapsed = (elapsedDuration / totalDuration) * 100; // Round the percentage to the nearest whole number
  var percentElapsedRounded = Math.round(percentElapsed); // Return the percentage
  return percentElapsedRounded;
}

function stripHTML(string) {
  if (!string || !string.length) {
    return string;
  }
  return string.replace(/(<([^>]+)>)/gi, '');
}

function keys_uppercase(arr) {
  arr = arr.map(item => {
    for (let old_key in item) {
      if (old_key !== old_key.toUpperCase()) {
        let new_key = old_key.toUpperCase();
        item[new_key] = item[old_key];
        delete item[old_key];
      }
    }
    return item
  })
  return arr
}

function keys_lowercase(arr) {
  arr = arr.map(item => {
    for (let old_key in item) {
      if (old_key !== old_key.toLowerCase()) {
        let new_key = old_key.toLowerCase();
        item[new_key] = item[old_key];
        delete item[old_key];
      }
    }
    return item
  })
  return arr
}

function truncate(string, no_words) {
  if (!string || !string.length) {
    return string;
  }
  return string.split(' ').splice(0, no_words).join(' ');
}

function createContentBody(media, content, subject) {
  const options = {
    email: {
      email: {
        ...(subject && { subject: subject }),
        body: content,
      },
    },
    facebook: {
      facebook: { caption: content },
    },
    instagram: {
      instagram: {
        caption: content,
      },
    },
    linkedin: {
      linkedin: {
        caption: content,
      },
    },
    twitter: {
      twitter: {
        caption: content,
      },
    },
    text: {
      text: {
        message: content,
      },
    },
    letter: {
      letter: {
        message: content,
      },
    },
    blog: {
      blog: {
        message: content,
      },
    },
    voicemail: {
      voicemail: {
        message: content,
      },
    },
  };

  const isMediaExist = Object.keys(options).find((option) => option === media);
  if (!isMediaExist) {
    return null;
  }
  return options[media];
}

const check_prompt = async (prompt, model, all_checks) => {
  if (!Object?.keys(all_checks).length) {
    return
  }
  const models = Object?.keys(all_checks)
  for (const model of models) {
    for (const check of all_checks[model])
      if (prompt.toString().toLowerCase().includes(check)) {
        return model
      } else {
        continue
      }
  }

  return model
}

const fix_response = (text, model, prompt) => {
  // add spaces after punctuation
  if (text && typeof text === 'string') {
    if (text.startsWith('"')) {
      text = text.slice(1)
    }
    if (text.endsWith('"')) {
      text = text.slice(0, -1)
    }
    if (model === 'text-curie-001') {
      text = text?.replace(/\s*([,.!?:;]+)(?!\s*$)\s*/g, '$1 ')?.trim()
      // remove spaces for money and decimal numbers
      text = text?.replace(/(\d)\s(?=\d)/g, '')
      text = text?.replace(/(\d+)\s?,\s?(\d+)/g, '$1,$2')
    }
    if (
      model !== 'text-curie-001' &&
      prompt?.toLowerCase()?.includes('list') &&
      prompt?.toLowerCase()?.includes('listing')
    ) {
      // add line breaks for a list
      text = text?.replace(/\d+\.\s+/g, '\n\n$&')
    }
    return (
      text?.replace(/(?:[.!?]\s*)(\w)/g, function (match, letter) {
        return match?.toUpperCase()?.trim()
      }) || ''
    )
  } else {
    return text?.trim()
  }
}

const format_currency = (item, no_dollar_sign, fraction_digits = 2, ref, min_fraction_digits = 0) => {
  let start, end
  if (ref && ref?.selectionStart) {
    start = ref?.selectionStart || ref.current.selectionStart
    end = ref?.selectionEnd || ref.current.selectionEnd
  }
  let add_dollar = false
  let add_negative = false
  item = item?.toString()
  if (item?.startsWith('$')) {
    add_dollar = true
  }
  if (item?.startsWith('$-') || item?.startsWith('-')) {
    add_negative = true
  }

  item = item?.replace(/[-$]/g, '')
  let regex_first = /[^0-9.,$-]/
  if (item?.match(regex_first)) {
    if (item?.length === 1) {
      if (start && end) {
        return { item: '', start, end }
      } else {
        return ''
      }
    } else {
      if (start && end) {
        return { item: item.replace(regex_first, ''), start, end }
      } else {
        return item.replace(regex_first, '')
      }
    }
  }

  // if (Array.from(item)?.every(item => item === '0' || item === ',' || item === '$')) {
  //   return item
  // }

  if (item?.endsWith('.')) {
    if (item.slice(0, -1).includes('.')) {
      if (start && end) {
        return { item: item.slice(0, -1), start, end }
      } else {
        return item.slice(0, -1)
      }
    }
    if (start && end) {
      return { item, start, end }
    } else {
      return `${item}`
    }
  }
  if (item?.endsWith('.0')) {
    if (start && end) {
      return { item: `${item}`, start, end }
    } else {
      return `${item}`
    }
  }

  item = item?.replace('$', '').replace('NaN', '').replace(/,/g, '').replace('-', '')
  if (!item || item === 'NaN') {
    if (add_dollar) {
      item = '$'
    }
    if (add_negative) {
      item += '-'
    }
    if (!add_dollar && !add_negative) {
      item = ''
    }
    if (start && end) {
      return { item, start, end }
    } else {
      return item
    }
  }
  item = item?.replaceAll('[$-]', '')
  let split = item?.split('.') || []
  let nums = Array.from(split?.[0] || [])
  const nums_length = nums?.length
  let decimal = Array.from(split?.[1] || [])
  let comma_amt = Math.floor(nums_length / 3)
  // if (nums_length < 4) {
  //   comma_amt -= 1
  // }
  // let comma_filled = 0
  // for (let index = nums_length - 1; index >= 0; index--) {
  //   if (index % 3 === 0 && comma_filled < comma_amt) {
  //     comma_filled++
  //     nums.splice(index, index, ',')
  //   }
  //   const element = nums[index];

  // }
  // item = `${nums?.join('')}${decimal?.length ? '.' : ''}${decimal?.join('')}`
  item = (new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: min_fraction_digits || 0,
    maximumFractionDigits: fraction_digits,
  }).format(item))?.replaceAll('$', '')
  if (add_negative) {
    item = `-${item}`
  }
  if (add_dollar) {
    item = `$${item}`
  }
  if (start && end) {
    const commas = item.match(/,/g)
    // if (commas?.length > 0 && start > 2) {
    //   start += commas?.length
    //   end += commas?.length
    // }

    return { item, start, end }
  } else {
    return item
  }
}

function find_closest_value(arr = [], key, target) {
  if (arr?.length === 0) {
    return null; // Handle empty array case
  }

  let closest = arr?.[0]?.[key]; // Replace 'numericProperty' with the actual property name
  let closest_obj = {}
  arr?.forEach(obj => {
    const currentDiff = Math.abs(obj?.[key] - target);
    const closestDiff = Math.abs(closest - target);

    if (currentDiff < closestDiff || (currentDiff === closestDiff && obj?.[key] > closest)) {
      closest = obj?.[key];
      closest_obj = obj
    }
  });

  return closest_obj;
}

function find_closest_ge(arr, key, target) {
  if (arr?.length === 0) {
    return null; // Handle empty array case
  }
  if (!target) {
    return null
  }

  let closestGreater = null;
  let ge_obj = {};
  (arr || []).forEach(obj => {
    if (obj?.[key] >= target) {
      if (closestGreater === null || obj?.[key] < closestGreater) {
        closestGreater = obj?.[key];
        ge_obj = obj
      }
    }
  });

  return ge_obj;
}

const format_number = (item, show_percent) => {

  if (show_percent) {
    item = item?.toString()?.replace(/[^0-9.%]/g, '')
  } else {
    item = item?.toString()?.replace(/[^0-9.]/g, '')
  }
  if (!item || item === 'NaN') {
    return 0
  }
  if (item.endsWith('.')) {
    return item
  }
  return Number(item)
}

const months_abbr = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

const listing_status = {
  Active: { name: 'For Sale', color: 'bg-blue-500', id: 1, topic_id: 24, order: 4, tags: ['listed', 'just listed', 'active', 'open house', 'price reduced'] },
  'UCB (Under Contract-Backups)': {
    name: 'Under Contract',
    color: 'bg-gray-500',
    id: 2,
    tags: ['under contract',],
    topic_id: 42,
    order: 5,
  },
  'CCBS (Contract Contingent on Buyer Sale)': {
    name: 'Under Contract',
    color: 'bg-gray-500',
    tags: ['under contract',],
    topic_id: 42,
    order: 5,
    id: 2,
  },
  Pending: {
    name: 'Under Contract', color: 'bg-gray-500',
    tags: ['under contract',],
    topic_id: 42,
    order: 5,
    id: 2
  },
  'Temp Off Market': { name: 'Off Market', color: 'bg-black', id: 6, order: 1, tags: ['temp of market',] },
  Expired: { name: 'Expired', color: 'bg-red-500', id: 4, order: 9, tags: ['expired'] },
  Closed: { name: 'Sold', color: 'bg-green-500', id: 5, order: 8, topic_id: 43, tags: ['sold', 'just sold', 'closed'] },
  'Coming Soon': { name: 'Soon', color: 'bg-purple-500', id: 6, order: 7, tags: ['coming soon', 'soon'] },
  Cancelled: { name: 'Cancelled', color: 'bg-black', id: 7, order: 10, tags: ['cancelled', 'deleted'] },
  Deleted: { name: 'Cancelled', color: 'bg-black', id: 7, order: 10, tags: ['cancelled', 'deleted'] },
}

function date_within_days(date, days) {
  if (!date) {
    return false
  }
  const milliseconds_per_day = 1000 * 60 * 60 * 24
  const current_date = new Date()
  const given_date = new Date(date)
  const difference = Math.abs(current_date - given_date)
  const differenceInDays = Math.ceil(difference / milliseconds_per_day)
  return differenceInDays <= days
}

function date_today_future(targetDate) {
  const currentDateInArizona = new Date();
  currentDateInArizona.setHours(0, 0, 0, 0)
  const targetDateObj = new Date(targetDate);
  targetDateObj.setHours(0, 0, 0, 0)
  return targetDateObj >= currentDateInArizona;
}
function date_today(date) {
  const today_date = new Date();
  const today = today_date.getDate()
  const targetDate = new Date(date);
  const target = targetDate.getDate()
  return today === target;
}

function date_in_future(targetDate) {
  const currentDateInArizona = new Date();
  const targetDateObj = new Date(targetDate);
  return targetDateObj > currentDateInArizona;
}

const just_listed_days = 7
const under_contract_days = 7
const price_reduced_days = 7
const just_sold_days = 365
const check_sub_topic = (sub_topic, topic, listing, order_type) => {
  if (order_type === 1) {
    return true
  }
  const sub_type = topic?.topic_subtype
  if (
    (sub_type === 2 || sub_type === 3)
    // &&
    // (sub_topic === 1 || sub_topic === 24)
  ) {
    if (sub_type === 3 && (listing?.original_list_price > listing?.list_price) && (listing?.mls_status !== 'Closed')) {
      return true
    }
    if (sub_type === 2 && [1, 2, 3, 24].includes(sub_topic)) {
      // && listing?.has_upcoming_open_house
      return true
    }
    return false
  }
  return sub_topic === sub_type
}

const capitalize = (str) => {
  str = str?.toString()?.replace(/_/g, ' ')?.trim()
  if (!str) {
    return ''
  }
  let words = str?.split(' ')
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }
  str = words?.join(' ')
  const lower_values = [' Of ', ' With ']
  for (const val of lower_values) {
    str = str.replaceAll(val, val.toLowerCase())
  }
  return str
}

const hex_brightness = (hex_code) => {
  let r = parseInt(hex_code.slice(1, 3), 16);
  let g = parseInt(hex_code.slice(3, 5), 16);
  let b = parseInt(hex_code.slice(5, 7), 16);

  // Calculate perceived brightness
  return 0.299 * r + 0.587 * g + 0.114 * b;
}

const get_background_color = (hex_code, bright, dark) => {
  if (!hex_code) {
    return bright || 'bg-[#FFFFFF]'
  }
  const threshold = 128
  const brightness = hex_brightness(hex_code.replace(';', ''))
  return brightness > threshold ? (dark || 'bg-[#000000]') : (bright || 'bg-[#FFFFFF]')
}
const is_json = (json) => {
  try {
    JSON.parse(json)
    return true
  } catch (error) {
    return false
  }
}
const is_json_comp = (json) => {
  try {
    JSON.parse(decompressFromUTF16(json))
    return true
  } catch (error) {
    return false
  }
}

const is_utf_8 = (str) => {
  try {
    // Use TextDecoder to check if the string can be decoded as UTF-8
    new TextDecoder('utf-8').decode(new TextEncoder().encode(str));
    return true;
  } catch (e) {
    return false;
  }
}

const get_storage = (item) => {
  item = localStorage.getItem(item)
  if (!item) {
    return ''
  }
  if (item === 'true' || item === 'false' || item.toUpperCase() === 'Y' || item.toUpperCase() === 'N') {
    return item
  }
  let result = ''
  if (is_json_comp(item)) {
    result = JSON.parse(
      decompressFromUTF16(
        item
          ?.replace('undefined', compressToUTF16('{}'))
      )
    )
    if (result === '{}') {
      result = ''
    }

  } else if (is_json(item)) {
    result = JSON.parse(
      item
        ?.replace('undefined', '{}') ||
      '{}'
    )
    if (result === '{}') {
      result = ''
    }
  } else {
    result = is_utf_8(item?.replace('undefined', '')) ? item : decompressFromUTF16(item?.replace('undefined', ''))
  }
  return result
}

const set_storage = (key, value) => {
  if (is_json(JSON.stringify(value))) {
    localStorage.setItem(key, compressToUTF16(JSON.stringify(value)))
  } else if (is_json_comp(JSON.stringify(value))) {
    localStorage.setItem(JSON.stringify(value))
  } else {
    is_utf_8(value) ? localStorage.setItem(key, compressToUTF16(value)) : localStorage.setItem(key, value)
  }
  return value
}

const search_street = (street) => {
  street = street?.toLowerCase() || ''
  const nav = ['ave ', 'n ', 'e ', 's ', 'w ', 'dr ', 'dr ', ',']
  const correction = ['avenue', 'north', 'east', 'south', 'west', 'drive', 'drive', '']
  let count = 0
  for (const item of nav) {
    if (street?.includes(item)) {
      street = street?.replaceAll(item, correction?.[count])
    }
    count++
  }
  return street?.trim()?.replace(/\s/g, '')
}

function deg_to_rad(deg) {
  return (deg * Math.PI) / 180;
}

function cal_geo_dist(lat1, lon1, lat2, lon2) {
  const earthRadius = 6371; // Earth's radius in kilometers

  const dLat = deg_to_rad(lat2 - lat1);
  const dLon = deg_to_rad(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg_to_rad(lat1)) * Math.cos(deg_to_rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = earthRadius * c;

  return distance;
}

function calculate_monthly_payment(principal, annualInterestRate, years) {

  if (!years) {
    return principal
  }
  const monthlyInterestRate = annualInterestRate ? (annualInterestRate / 12 / 100) : 0;
  const numberOfPayments = years * 12;

  if (!annualInterestRate) {
    return principal / numberOfPayments
  }
  const rate_algo = Math.pow(1 + monthlyInterestRate, numberOfPayments)
  // Calculate monthly payment using the formula for an amortizing loan
  const monthlyPayment =
    principal * ((monthlyInterestRate * rate_algo) /
      (rate_algo - 1));
  return monthlyPayment
}

function calculate_amortization(principal, annualInterestRate, years) {
  const monthlyInterestRate = annualInterestRate ? annualInterestRate / 12 / 100 : 0;
  const numberOfPayments = (years * 12) || 1;
  // Calculate monthly payment using the formula for an amortizing loan
  const monthlyPayment = calculate_monthly_payment(principal, annualInterestRate, years)

  let data = {}
  let remainingBalance = principal;
  for (let i = 0; i < numberOfPayments; i++) {
    const interestPayment = remainingBalance * monthlyInterestRate;
    const principalPayment = monthlyPayment - interestPayment;
    remainingBalance -= principalPayment;

    data[i] = {
      payment: Number(monthlyPayment.toFixed(2)),
      interest: Number(interestPayment.toFixed(2)),
      principal: Number(principalPayment.toFixed(2)),
      remaining_balance: Number(remainingBalance.toFixed(2))
    }
  }
  return data
}

function calculate_principal(monthlyPayment, annualInterestRate, loanYears) {
  const monthlyInterestRate = (annualInterestRate / 100) / 12;
  const numberOfPayments = loanYears * 12;
  const rate_algo = Math.pow(1 + monthlyInterestRate, numberOfPayments)
  // Calculate principal using the loan formula
  const amortizedPrincipal = (monthlyPayment * (rate_algo - 1)) / (monthlyInterestRate * rate_algo)

  return amortizedPrincipal;
}

// function calc_apr(intrest_and_fees, principal, loan_term) {
//   const apr = intrest_and_fees / principal/ 
// }

function calculate_amortization_totals(principal, annualInterestRate, years, extra_payment = 0, check_guess = false) {
  const monthlyInterestRate = annualInterestRate ? annualInterestRate / 12 / 100 : 0;
  const numberOfPayments = (years * 12) || 1;
  // Calculate monthly payment using the formula for an amortizing loan
  //  let extra_guess = 0
  //  let tries = 1000
  //  if (check_guess) {
  //   const find_optimal = calculate_amortization_totals(principal, annualInterestRate, years, extra_guess, check_guess)
  //   if (Object.entries(find_optimal?.schedule)?.some((key, value) => )) {

  //   }
  // }
  const monthlyPayment = calculate_monthly_payment(principal, annualInterestRate, years) || 0
  let total_interest = 0
  let total_payments = 0
  let total_principal = 0
  let payment_count = 0
  let data = {}
  let remainingBalance = principal;
  for (let i = 0; i < numberOfPayments; i++) {
    const interestPayment = remainingBalance * monthlyInterestRate;
    let principalPayment = monthlyPayment - interestPayment + validate_number(extra_payment);
    remainingBalance -= principalPayment
    if (remainingBalance < principalPayment) {
      principalPayment = remainingBalance + principalPayment
      remainingBalance = 0
    } else {
      payment_count += 1
    }
    total_interest += interestPayment
    total_payments += monthlyPayment
    total_principal += principalPayment

    data[i] = {
      total_interest,
      total_payments,
      total_principal,
      payment: Number(monthlyPayment.toFixed(2)),
      interest: Number(interestPayment.toFixed(2)),
      principal: Number(principalPayment.toFixed(2)),
      remaining_balance: Number(remainingBalance.toFixed(2))
    }
  }
  return { schedule: data, total_interest, total_payments, principal, payments: numberOfPayments, interest_rate: annualInterestRate, loan_term: years, payment_count, monthly_payment: monthlyPayment }

}


function is_mobile() {
  let check = false
    ; (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
          a.substr(0, 4)
        )
      )
        check = true
    })(navigator.userAgent || navigator.vendor || window.opera)
  return check
}

function obj_flat(obj, use_parent_key = false, parentKey = '',) {
  let result = {};

  for (const key in obj) {
    const newKey = use_parent_key ? (parentKey ? `${parentKey}_${key}` : key) : key

    if (typeof obj[key] === 'object' && obj[key] !== null) {
      result = { ...result, ...obj_flat(obj[key], use_parent_key, newKey) };
    } else {
      result[newKey] = obj[key];
    }
  }

  return result;
}

function array_nums(count = 0, include_0 = true, callback) {
  let array = Array.from({ length: count }, (_, index) => callback ? callback(index, _) : index + 1)
  if (include_0) {
    array = [0, ...array]
  }
  return array
}

function remoke_key(originalObject, keyToRemove) {
  let modifiedObject = { ...originalObject };
  if (keyToRemove in modifiedObject) {
    delete modifiedObject[keyToRemove];
  }
  return modifiedObject;
}

function remove_keys_by_suffix(originalObject, suffixToRemove = 'type') {
  // Create a copy of the original object
  let modifiedObject = { ...originalObject };

  // Iterate over the keys of the object
  for (let key in modifiedObject) {
    // Check if the key ends with the specified suffix
    if (key.endsWith(suffixToRemove)) {
      // Remove the key from the modified object
      delete modifiedObject[key];
    }
  }

  // Return the modified object
  return modifiedObject;
}

function pick_keys(obj, filters, only = false) {
  return Object.assign({}, ...(Object.keys(obj || {}).filter(item => only ? filters?.includes(item) : !filters?.includes(item))?.map(key => ({ [key]: obj[key] || null }))) || {});
}

const get_uuid = () => {
  return uuidv4()
}

const add_delay = async (ms = 3000) => {
  return await new Promise(resolve => setTimeout(() => resolve('done'), ms))
}

const validate_number = (num, dec) => {
  if (num?.toString()?.replace(/[$€£¥,%]/g, '') === '-') {
    return 0
  }
  num = num?.toString()?.replace(/[$€£¥,%]/g, '')
  num = num?.split('.')
  num = `${num?.[0]?.replace(/[^0-9-]/g, '')}${num?.[1] ? `.${num?.[1]?.replace(/[^0-9-]/g, '')}` : ''}`
  num = Number(num)
  if (isNaN(num)) {
    return 0
  }
  if (dec >= 0) {
    num = Number(num.toFixed(dec))
  }
  return num
}

function calculate_apr(loanAmount, monthlyPayment, loanTermInYears, guess = 0, tolerance = 0.0001, maxIterations = 1000,) {
  const monthlyInterestRateGuess = guess ? (validate_number(guess) + .005) : 0.075; // Initial guess for monthly interest rate
  let monthlyInterestRate = validate_number(monthlyInterestRateGuess);
  let i = 0;
  while (i < maxIterations) {
    const presentValue = calculatePresentValue(loanAmount, monthlyInterestRate, monthlyPayment, loanTermInYears);
    const presentValueDerivative = calculatePresentValueDerivative(loanAmount, monthlyInterestRate, monthlyPayment, loanTermInYears);

    const newGuess = monthlyInterestRate - presentValue / presentValueDerivative;

    // Damping factor to prevent overshooting
    const dampingFactor = 0.9;
    monthlyInterestRate = monthlyInterestRate - dampingFactor * (newGuess - monthlyInterestRate);

    if (Math.abs(newGuess - monthlyInterestRate) < tolerance) {
      // Convergence reached
      return newGuess * 12; // Convert to annual interest rate
    }

    i++;
  }

  if (validate_number(monthlyInterestRate) <= 0.01) {
    monthlyInterestRate = monthlyInterestRateGuess
  }
  return validate_number(monthlyInterestRate)
}

function calculatePresentValue(loanAmount, monthlyInterestRate, monthlyPayment, loanTermInYears) {
  const totalPayments = loanTermInYears * 12;
  let presentValue = 0;

  for (let i = 1; i <= totalPayments; i++) {
    presentValue += monthlyPayment / Math.pow(1 + monthlyInterestRate, i);
  }

  return presentValue - loanAmount;
}

function calculatePresentValueDerivative(loanAmount, monthlyInterestRate, monthlyPayment, loanTermInYears) {
  const totalPayments = loanTermInYears * 12;
  let presentValueDerivative = 0;

  for (let i = 1; i <= totalPayments; i++) {
    presentValueDerivative += i * (monthlyPayment / Math.pow(1 + monthlyInterestRate, i + 1));
  }

  return presentValueDerivative;
}

function format_input({ e, type, decimal = 2, original, }) {
  let { name, value } = e.target
  let start, end
  let defaults = { item: value?.toString()?.replace(/[^$€£¥0-9,.%-]/g, ''), start: e?.target?.selectionStart, end: e?.target?.selectionEnd }
  if (type === '$' || type === '%' || type === 'm' || type === 'a' || type === 'int') {
    if (validate_number(value) < 1) {
      value = defaults
      start = value?.start
      end = value?.end
      value = value?.item
    } else {
      value = value?.toString()?.replace(/[^$€£¥0-9,.%-]/g, '')
      if (e?.target?.selectionStart) {
        value = validate_number(value) ? format_currency(value, false, decimal, e.target) : defaults
        start = value?.start
        end = value?.end
        value = value?.item
      } else {
        value = format_currency(value, false, decimal)
      }
    }
  }

  if (!type) {
    type = ''
  }

  setTimeout(() => {
    if (
      !isNaN(Number(value?.toString()?.replace(/[$€£¥,%-]/g, ''))) &&
      e?.target?.selectionStart &&
      start &&
      end
    ) {
      const orig_comma_length = (original?.toString()?.match(/,/g) || [])?.length
      const new_comma_length = (value?.toString()?.match(/,/g) || [])?.length
      if (value?.length === 1) {
        start += 1
        end += 1
      }
      if (
        (new_comma_length > orig_comma_length && start > 2) ||
        !original ||
        (value?.startsWith('0.') && original?.toString()?.length === 1)
      ) {
        start += 1
        end += 1
      } else if (new_comma_length < orig_comma_length && start > 2) {
        start -= 1
        end -= 1
      }
      // if (
      //   orig_comma_length >= new_comma_length &&
      //   orig_comma_length !== 0 &&
      //   new_comma_length !== 0 &&
      //   start > 2
      // ) {
      //   start -= 1
      //   end -= 1
      //   if (start > 6) {
      //     start -= 1
      //     end -= 1
      //   }
      // }
      e.target.selectionStart = start
      e.target.selectionEnd = end
    }
  }, 0)

  return { name, value, start, end, type, decimal, original }
}

function getTextWidth(text, el) {
  if (!el) {
    el = document.createElement('div')
  } else {
    el = el.cloneNode(true)
  }
  el.textContent = text
  el.style.position = 'absolute'
  document.body.appendChild(el)
  const width = el.getBoundingClientRect().width
  document.body.removeChild(el)
  return width
}

const get_months = (start_date = new Date().toISOString(), end_date = new Date().toISOString()) => {
  // const ms = 1000 * 60 * 60 * 24
  const start_month = new Date(
    start_date.split('T')?.[0]
  )?.getUTCMonth()
  const end_month = new Date(
    end_date?.split('T')?.[0]
  )?.getUTCMonth()
  const start_year = new Date(
    start_date?.split('T')?.[0]
  )?.getUTCFullYear()
  const end_year = new Date(
    end_date?.split('T')?.[0]
  )?.getUTCFullYear()
  const years = end_year - start_year
  const months = end_month - start_month
  return months + years * 12
}

const get_loan_balance = (
  loan_amount,
  interest_rate,
  loan_term,
  start_date,
  end_date
) => {
  const amortization = calculate_amortization_totals(
    validate_number(loan_amount),
    validate_number(interest_rate),
    validate_number(loan_term)
  )
  const curr_payment = get_months(start_date, end_date) - 1
  let principal_paid =
    amortization?.schedule?.[curr_payment]?.total_principal || 0
  const interest_paid = amortization?.schedule?.[curr_payment]?.total_interest || 0
  if (curr_payment > 359) {
    principal_paid = Math.floor(validate_number(loan_amount))
  }
  return { loan_balance: loan_amount - principal_paid, principal_paid, interest_paid, curr_payment }
}

const apr_gen_eq = (period, payment, intitial_periods, fractions, rate) => {
  let result = 0
  for (let i = 0; i < period; i++) {
    result += payment / ((1 + fractions * rate) * Math.pow(1 + rate, intitial_periods + i))
  }
  return result
}

const find_apr_gen = (amount, payment, payments, ppy, apr_guess, partial, full) => {
  let result = apr_guess
  let temp_guess = apr_guess
  do {
    result = temp_guess
    let i = temp_guess / (100 * ppy)
    let a1 = apr_gen_eq(payments, payment, full, partial, i)
    let i2 = (temp_guess + 0.1) / (100 * ppy)
    let a2 = apr_gen_eq(payments, payment, full, partial, i2)
    temp_guess = temp_guess + 0.1 * (amount - a1) / (a2 - a1)

  } while (Math.abs(result * 10000 - temp_guess * 10000) > 1)
  return result
}

function is_el_in_view(el) {

  var rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
    rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
  );
}

function max_length(text_str, max_length = 0, ending = '...') {
  if (!text_str) {
    return ''
  }
  text_str = text_str?.toString()
  if (max_length) {
    text_str = text_str?.slice(0, max_length - ending?.toString()?.length) + ending
  }
  return text_str
}

function shade_color(color, percent) {
  const f = parseInt(color.slice(1), 16);
  const t = percent < 0 ? 0 : 255;
  const p = percent < 0 ? percent * -1 : percent;
  const R = f >> 16;
  const G = (f >> 8) & 0x00ff;
  const B = f & 0x0000ff;
  return (
    '#' +
    (
      0x1000000 +
      (Math.round((t - R) * p) + R) * 0x10000 +
      (Math.round((t - G) * p) + G) * 0x100 +
      (Math.round((t - B) * p) + B)
    )
      .toString(16)
      .slice(1)
  );
}

function encode_path(url) {
  const urlObj = new URL(url);
  const path = urlObj.pathname + urlObj.search + urlObj.hash;
  return urlObj.origin + encodeURIComponent(path);
}

async function short_url(url) {
  let new_url
  const get_url = async () => {
    let origin = window.location.origin
    let fetch_url = `https://nodejsprod01.driggstitle.com/node_to_oracle/api/v1/short_url`
    if (origin.includes('beta') || origin.includes('local')) {
      fetch_url = `https://nodejsprod01.driggstitle.com/node_to_oracle_beta/api/v1/short_url`
    }
    new_url = await fetch(`${fetch_url}?long_url=${url}`).then(response => response.text())
  }
  await get_url()
  return new_url
}

async function compress_img(url, compress_to = '400000', format = 'jpg') {
  const formated_image = await fetch('https://nodejsprod01.driggstitle.com/node_to_oracle/api/v1/images/format', {
    method: 'POST',
    headers: {
      ['Content-Type']: 'application/json',
    },
    body: JSON.stringify({
      image_url: encodeURI(url),
      format,
      compress_to,
      // resize:
      //   distribute?.social === 'facebook'
      //     ? { width: 1200, height: 630 }
      //     : {},
    }),
  }).then(response => response?.json()).catch(err => console.log(err))
  return formated_image?.[0] || null
}

const convert_percent = (base, value, type, direction) => {
  if (!base) {
    return validate_number(value)
  }
  if ((direction === 'percent') && type === '%') {
    return validate_number(value)
  }
  if ((direction === 'percent') && type === '$') {
    return validate_number(value) / validate_number(base) * 100
  }
  if (type === 'a') {
    return validate_number(validate_number(value) / 12)
  }
  if (type === '%') {
    return validate_number(base) * (validate_number(value) / 100)
  } else {
    return validate_number(value)
  }
}

const check_variance = (value, desired_value = 1, control = 0.00001) => {
  const lower_bound = desired_value - control
  const upper_bound = desired_value + control
  if (lower_bound <= value && upper_bound >= value) {
    return true
  } else {
    return false
  }
}

const show_decimal = (value) => {
  if (!value) {
    return false
  }
  value = validate_number(value, 2)?.toString()
  if (value?.includes('.00') || !value.includes('.')) {
    return false
  } else {
    return true
  }
}

const show_decimal_value = (value) => {
  if (show_decimal(value)) {
    return value?.toString()
  } else {
    return value?.toString() + '.00'
  }
}
const labels_html = (labels, values, margin, colors) => {
  return (
    <div className={`${margin}`}>
      {labels?.map((item, index) => {
        return (
          <div key={item} className='flex items-center gap-[10px] text-xs'>
            <div
              className={`w-[10px] h-[10px] rounded-full`}
              style={{
                backgroundColor: colors?.[index],
              }}
            ></div>
            {item} - ${format_currency(values?.[index], false, 2, null, show_decimal(values?.[index]) ? 2 : 0)}
          </div>
        )
      })}
    </div>
  )
}

const remove_index = (index, arr = []) => {
  if (index >= 0) {
    arr = arr.slice(0, index).concat(arr.slice(index + 1))
  }
  return arr
}

const phone_is_admin = (phone) => {
  if (!phone) {
    return false
  }
  return [
    '5209296520',
    '7023367710',
    '4807475411',
    '4803389662',
    '8014254550',
    '6232390700',
    '6022842497',
    '6232021118',
    '6023636428',
    '4805226230'
  ].includes(phone?.toString()?.replace('+1', '')?.replace(/[^0-9.]/g, ''))
}

const get_year_month_string = (payments) => {
  const num = Math.floor(payments / 12)
  const remainder = payments % 12
  return `${`${num !== 0 ? `${num} year${num > 1 ? 's' : ''}` : ''}`} ${`${remainder !== 0 ? `${remainder} month${remainder > 1 ? 's' : ''}` : ''
    }`}`
}

const get_address_str = (item) => {
  let str = ``
  if (item?.prop_add) {
    str += `${item?.prop_add}, `
  }
  if (item?.prop_city) {
    str += `${item?.prop_city}, `
  }
  if (item?.prop_st) {
    str += `${item?.prop_st} `
  }
  if (item?.prop_zip) {
    str += `${item?.prop_zip}`
  }
  return str
}

const format_dollar_currency = (value) => {
  if (!validate_number(value)) {
    return ''
  }

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(value?.toString())
}

function create_calendar_event(title, dateTime, dateEndtime, location, description, gmap_url = '') {
  function formatDateTime(dateTime) {
    return dateTime.toISOString().replace(/[-:]/g, '').split('.')[0] + 'Z';
  }
  const event = `BEGIN:VCALENDAR
VERSION:2.0
CALSCALE:GREGORIAN
METHOD:PUBLISH
BEGIN:VEVENT
DTSTART:${formatDateTime(dateTime)}
DTEND:${formatDateTime(dateEndtime)}
LOCATION:${location}
SUMMARY:${title}
DESCRIPTION:${description}
URL:${gmap_url}
STATUS:CONFIRMED
SEQUENCE:0
TRANSP:OPAQUE
END:VEVENT
END:VCALENDAR`;

  const blob = new Blob([event], { type: 'text/calendar' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = 'event.ics';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const open_url_tab = (url, error_msg = 'Please enable popups for this to work.') => {
  const new_window = window.open(url, '_blank')
  if (!new_window) {
    window.alert(
      error_msg
    )
  }
  return new_window
}

export {
  formatFileSize,
  replaceWithBr,
  getPercentage,
  stripHTML,
  truncate,
  createContentBody,
  formatPhoneNumber,
  getDateString,
  keys_uppercase,
  keys_lowercase,
  sv_date,
  sv_string_change,
  formatUTC,
  az_to_local,
  check_prompt,
  fix_response,
  format_currency,
  format_number,
  months_abbr,
  listing_status,
  date_within_days,
  just_listed_days,
  just_sold_days,
  price_reduced_days,
  under_contract_days,
  check_sub_topic,
  capitalize,
  getCurrentTime,
  hex_brightness,
  get_background_color,
  get_storage,
  set_storage,
  date_today_future,
  date_in_future,
  search_street,
  date_today,
  is_json,
  cal_geo_dist,
  deg_to_rad,
  calculate_monthly_payment,
  calculate_amortization,
  calculate_principal,
  is_mobile,
  find_closest_value,
  find_closest_ge,
  obj_flat,
  array_nums,
  remoke_key,
  remove_keys_by_suffix,
  get_uuid,
  add_delay,
  validate_number,
  calculate_amortization_totals,
  calculate_apr,
  format_input,
  getTextWidth,
  get_months,
  get_loan_balance,
  find_apr_gen,
  is_el_in_view,
  max_length,
  shade_color,
  pick_keys,
  encode_path,
  short_url,
  compress_img,
  convert_percent,
  labels_html,
  show_decimal,
  check_variance,
  remove_index,
  phone_is_admin,
  get_year_month_string,
  get_address_str,
  show_decimal_value,
  format_dollar_currency,
  create_calendar_event,
  open_url_tab
};
