import { useEffect } from 'react'
import { useProfile } from '../../../contexts/UserContext'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import Wrapper from '../../layout/Wrapper'
import SubNav from '../../SubNav'
import uiStyles from '../../ui/uiStyles'
import { useAuth0 } from '@auth0/auth0-react'

const CallRep = () => {
  const { profile } = useProfile()
  const { user } = useAuth0()
  console.log(user)
  const { axiomFetch } = useAxiomFetch()

  useEffect(() => {
    const controller = new AbortController()

    const make_call = async () => {
      let body = {}
      if (process.env.REACT_APP_LOCAL === 'jklj') {
        body = {
          number: 4807698472,
          agent_id: 230199,
        }
      } else {
        body = {
          number: Number(user?.name?.toString()?.replace('+1', '')) || profile?.CONT_CELL_NUM,
          agent_id: profile?.REP_ID,
          service: [203180, 203182].includes(profile?.REP_ID) ? 'Axiom' : 'Axiom - assigned',
        }
      }
      if ([203180, 203182].includes(profile.REP_ID)) {
        delete body.agent_id
      }
      await axiomFetch('/marketing/messaging/create/call/task', 'POST', body, {}, false, controller.signal)
    }
    make_call()

    return () => {
      controller?.abort()
    }
  }, [])
  console.log(profile)
  return (
    <Wrapper>
      <SubNav />
      <div className='p-2'>
        <h3 className={`${uiStyles.text_black_subtitle} mt-[20px] mb-[20px]`}>Setting up your call!</h3>
        <h3 className={`${uiStyles.text_black_subtitle} mt-[20px] mb-[20px]`}>
          We let {profile?.REP_NAME} know you would like to have a call with them.
        </h3>
        <p>
          If they are available they will call you back shortly, but in the meantime feel free to go back and keep using
          axiom.
        </p>
      </div>
    </Wrapper>
  )
}

export default CallRep
