import React from 'react'
import { useProfile } from '../../../contexts/UserContext'
import useHelpers from '../../../hooks/useHelpers'
import ListItem from '../../ui/ListItem'
import Navigation from './Navigation'
import ThumbRating from './ThumbRating'
const EO_Focus = ({ state, setState }) => {
  const { profile } = useProfile()
  const { open_url } = useHelpers()
  const get_page = () => {
    const eo_id = state?.data?.event_pages?.find(
      (item) => item?.event_id === state?.tour_state?.event_id
    )?.eo_of_focus
    const eo = state?.data?.user_int_v?.find((user) => user?.user_id === eo_id)
    const info = {
      name: `${eo?.first_name} ${eo?.last_name}`,
      photo_link: eo?.photo_link,
      review_path: eo?.review_path,
      page_name: state?.data?.tour_types_pages?.find(
        (item) => item?.page_id === (state?.tour_state?.current_page || 3)
      )?.page_name,
      main_1: state?.data?.user_tour_data?.find(
        (item) => item?.user_id === eo_id
      )?.main1,
      main_2: state?.data?.user_tour_data?.find(
        (item) => item?.user_id === eo_id
      )?.main2,
    }
    const info_list = state?.data?.user_tour_short_dta?.filter(
      (item) => item?.user_id === eo_id
    )
    return { info, info_list }
  }

  const page = get_page()
  console.log(page)
  return (
    <div className=''>
      <div className='flex flex-col items-center'>
        <p className='text-[20px] font-bold'>
          {page?.info?.page_name || 'EO Of Focus'}
        </p>
        <img
          className={`${
            page?.info?.review_path ? 'cursor-pointer' : ''
          } w-[200px] h-[200px] object-contain mt-[20px]`}
          onClick={() => {
            if (page?.info?.review_path) {
              open_url(
                `https://www.driggstitle.com/escrow_officer_reviews/${page?.info?.review_path}/my_reviews.html`
              )
            }
          }}
          src={page?.info?.photo_link}
        />
        <p className='text-[24px] text-primary font-bold mt-[10px]'>
          {page?.info?.name}
        </p>
        <div className='px-5 mt-[10px]'>
          <p>{page?.info?.main_1?.replace('¿', "'")}</p>
        </div>
        <div className='px-5 mt-[10px]'>
          <p>{page?.info?.main_2?.replace('¿', "'")}</p>
        </div>
      </div>
      <div className='flex flex-col items-center justify-center px-5 text-[20px] font-bold text-primary'>
        <p className='my-[8px]'>Why {page?.info?.name}</p>{' '}
        <p>stands out from the rest:</p>
      </div>
      <div className='mt-[10px] max-h-[300px] overflow-y-scroll'>
        {[page?.info_list?.[(state?.tour_state?.eo_page || 1) - 1]].map(
          (item) => {
            return (
              <ListItem
                key={item?.item_txt.slice(0, 30)}
                title={item?.item_txt}
              />
            )
          }
        )}
      </div>
      <ThumbRating
        state={state}
        setState={setState}
        rating={
          state?.data?.tour_eo_items_ratings
            ?.map((item) => {
              item.rate_value = Number(item.rate_value || 0)
              return item
            })
            ?.find(
              (item) =>
                item.rated_for_item_id ===
                page?.info_list?.[(state?.tour_state?.eo_page || 1) - 1]
                  ?.item_ord
            ) || {
            rated_for_item_id:
              page?.info_list?.[(state?.tour_state?.eo_page || 1) - 1]
                ?.item_ord,
            rated_for_user_id:
              page?.info_list?.[(state?.tour_state?.eo_page || 1) - 1]?.user_id,
            rated_by_cont_id: profile?.CONT_ID,
            event_id: state?.tour_state?.event_id,
          }
        }
        type={'eo_items'}
      />
      <div className='pb-[10px]'></div>
      <Navigation state={state} setState={setState} />
    </div>
  )
}

export default EO_Focus
