import { forwardRef, useState, useRef, useEffect } from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'
import { useCalc } from '../../contexts/CalcContext'
import { is_mobile, validate_number } from '../../helpers'
import ButtonsRow from '../ui/ButtonsRow'
// import PropTypes from 'prop-types'
const LabelInput = forwardRef(
  (
    {
      no_margin = false,
      show_delete_all = false,
      show_search = false,
      delete_all_position = '',
      top_label,
      left_label,
      left_label_width,
      right_label,
      right_label_width,
      input_width,
      info_label,
      name,
      value,
      handleChange,
      placeholder,
      width,
      type,
      text_size = '',
      show_dollar = false,
      show_percent = false,
      percent_value = '%',
      total = '',
      dollar_percent = '%',
      toggle_values = ['%', '$'],
      ...restProps
    },
    ref
  ) => {
    const [showPercent, setShowPercent] = useState(
      dollar_percent === '$' ? true : false
    )
    const { get_tax_bracket, get_tax_bracket_min_income } = useCalc()
    const [dollarPercent, setDollarPercent] = useState(dollar_percent)
    useEffect(() => {
      setDollarPercent(show_dollar && !show_percent ? '$' : dollar_percent)
    }, [dollar_percent])
    const percentRef = useRef(null)
    return (
      <div className={`relative w-[full] ${no_margin ? '' : 'mb-[8px]'} `}>
        <label className='text-sm font-medium text-[#344054]'>
          {top_label}
        </label>
        <div className='flex w-full mt-0'>
          {left_label ? (
            <div
              className={`text-sm ${
                left_label_width ? left_label_width : 'w-[50%]'
              } .scrollbar-hide overflow-x-scroll rounded-l-md resize-none py-[12px] pl-[10px] pr-[2px] border border-gray-300 text-thirdary`}
            >
              {left_label}
            </div>
          ) : (
            ''
          )}
          {/* {show_percent && !showPercent ? (
            <>
              {dollarPercent === '%' ? (
                <div
                  className={`w-[100%] overflow-x-scroll ${
                    show_percent && show_dollar
                      ? 'rounded-none'
                      : left_label_width || left_label
                      ? 'rounded-r-md rounded-l-none'
                      : 'rounded-md'
                  } resize-none p-3 border border-gray-300 leading-4 text-primary ${
                    text_size ? text_size : ''
                  } ${width}`}
                  onClick={() => {
                    setShowPercent(true)
                    console.log(percentRef?.current)
                    if (ref && ref.current) {
                      setTimeout(() => {
                        ref.current.focus()
                      }, 50)
                    } else if (percentRef?.current) {
                      console.log('hello')
                      setTimeout(() => {
                        percentRef?.current?.focus()
                      }, 50)
                    }
                  }}
                >
                  {value
                    ? `${value
                        ?.toString()
                        ?.replaceAll('$', '')}${percent_value}`
                    : value?.toString()?.replaceAll('$', '')}
                </div>
              ) : (
                ''
              )}
            </>
          ) : (
            ''
          )} */}

          <input
            name={name}
            onBlur={(e) => {
              if (show_percent) {
                e.target.value = `${e?.target?.value?.replace(
                  /[%years]/g,
                  ''
                )}${
                  e?.target?.value?.replace(/[%years]/g, '')
                    ? percent_value
                    : ''
                }`
                if (dollarPercent !== '%') {
                  e.target.value = `${e?.target?.value?.replace(
                    /[%years]/g,
                    ''
                  )}`
                }
                setShowPercent(false)
              }
            }}
            onFocus={(e) => {
              if (show_percent) {
                e.target.value = e?.target?.value?.replace(/[% years]/g, '')
              }
            }}
            type={type || 'input'}
            value={
              show_search
                ? value
                : show_percent && dollarPercent === '%'
                ? `${(value || '')?.toString()?.replace(/[%$]/g, '')}${
                    (ref?.current || percentRef?.current) ===
                    document.activeElement
                      ? ''
                      : percent_value
                  }`
                : show_dollar && value
                ? `$${(value || '')?.toString()?.replace(/[%$]/g, '')}`
                : (value || '')?.toString()?.replace(/[%$]/g, '')
            }
            onChange={(e) => {
              return handleChange(e, dollarPercent)
            }}
            placeholder={placeholder || ''}
            className={`${
              dollarPercent !== '%'
                ? ''
                : show_percent && !showPercent
                ? ''
                : ''
            } w-[100%] overflow-x-scroll .scrollbar-hide ${
              show_percent && show_dollar
                ? 'rounded-none'
                : left_label_width || left_label
                ? 'rounded-r-md rounded-l-none'
                : 'rounded-md'
            } resize-none pl-3 py-3 border border-gray-300 leading-4 text-primary ${
              text_size ? text_size : ''
            } ${width}`}
            {...restProps}
            ref={ref ? ref : percentRef}
          />
          {(show_percent && show_dollar) || right_label ? (
            <div
              className={`flex gap-[5px] items-center ${
                right_label
                  ? right_label_width
                  : show_delete_all
                  ? 'w-[60%]'
                  : 'w-[50%]'
              } overflow-x-scroll rounded-r-md rounded-l-none resize-none p-1 border border-gray-300 text-thirdary`}
            >
              <div className='w-full text-sm'>
                {show_dollar && show_percent ? (
                  <ButtonsRow
                    send_usage_report={false}
                    items={toggle_values}
                    padding={''}
                    selected={dollarPercent}
                    handler={(item) => {
                      setDollarPercent(item)

                      if (name === 'gross_income') {
                        handleChange(
                          {
                            target: {
                              name,
                              value: !value
                                ? value
                                : item === '%'
                                ? get_tax_bracket(validate_number(value))
                                : get_tax_bracket_min_income(
                                    validate_number(value)
                                  ),
                              type: item,
                            },
                          },
                          item
                        )
                      } else if (total && item !== dollarPercent) {
                        handleChange(
                          {
                            target: {
                              name,
                              value:
                                dollarPercent === 'm'
                                  ? validate_number(
                                      validate_number(
                                        value?.toString()?.replace(/[$%,]/g, '')
                                      ) * 12,
                                      4
                                    )
                                  : dollarPercent === 'a'
                                  ? validate_number(
                                      validate_number(
                                        value?.toString()?.replace(/[$%,]/g, '')
                                      ) / 12,
                                      4
                                    )
                                  : dollarPercent === '%'
                                  ? validate_number(
                                      (validate_number(
                                        value?.toString()?.replace(/[$%,]/g, '')
                                      ) *
                                        total) /
                                        100,
                                      4
                                    )
                                  : validate_number(
                                      (validate_number(
                                        value?.toString()?.replace(/[$%,]/g, '')
                                      ) /
                                        total) *
                                        100,
                                      4
                                    ),
                            },
                          },
                          item
                        )
                      } else {
                        handleChange({ target: { name, value } }, item)
                      }
                      if (ref && ref.current) {
                        console.log(ref.current)
                        setShowPercent(true)
                        if (!is_mobile()) {
                          setTimeout(() => {
                            ref?.current?.focus()
                            ref.current.selectionStart =
                              ref.current.value.length
                            ref.current.selectionEnd = ref.current.value.length
                          }, 0)
                        }
                      } else if (percentRef?.current) {
                        setShowPercent(true)
                        if (!is_mobile()) {
                          setTimeout(() => {
                            percentRef?.current?.focus()
                            percentRef.current.selectionStart =
                              percentRef.current.value.length
                            percentRef.current.selectionEnd =
                              percentRef.current.value.length
                          }, 0)
                        }
                      }
                    }}
                  />
                ) : (
                  right_label
                )}
              </div>
            </div>
          ) : (
            ''
          )}
          {show_delete_all && value ? (
            <div
              className={`cursor-pointer absolute ${
                delete_all_position
                  ? delete_all_position
                  : !show_dollar || !show_percent
                  ? 'right-[8px]'
                  : 'right-[25%]'
              } ${top_label ? 'top-[40px]' : 'top-[16px]'}`}
              onClick={() => {
                // if (!is_mobile()) {
                handleChange(
                  {
                    target: {
                      name,
                      value: '',
                    },
                  },
                  dollarPercent
                )
                setTimeout(() => {
                  if (percentRef?.current) {
                    percentRef?.current?.focus()
                    percentRef.current.selectionStart =
                      percentRef.current.value.length
                    percentRef.current.selectionEnd =
                      percentRef.current.value.length
                  } else if (ref?.current) {
                    ref?.current?.focus()
                  }
                }, 0)

                // }
              }}
            >
              <AiFillCloseCircle size={15} />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    )
  }
)

// LabelInput.propTypes = {
//     top_label: PropTypes.string,
//     left_label: PropTypes.string,
// }

export default LabelInput
