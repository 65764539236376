import { useState, useRef, useEffect } from 'react'
import ImageUpload from '../../form/ImageUpload'
import uiStyles from '../../ui/uiStyles'
import { HiOutlineTrash } from 'react-icons/hi2'
import LabelInput from '../../form/LabelInput'
import plus_icon from '../../../assets/images/plus_circle.svg'
import minus_icon from '../../../assets/images/gray_minus_icon.svg'
import ButtonsRow from '../../ui/ButtonsRow'
import structuredClone from '@ungap/structured-clone'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { useNavigate } from 'react-router-dom'
import SmallLoading from '../../ui/SmallLoading'
import { useCalc } from '../../../contexts/CalcContext'
import Select from '../../form/Select'
import { validate_number } from '../../../helpers'
import { useProfile } from '../../../contexts/UserContext'

const MLSConfirm = ({ mlsSearch, mlsMatch, setModalType }) => {
  // console.log(mlsSearch, mlsMatch)
  const navigate = useNavigate()
  const { calc, saveCalc } = useCalc()
  const { profile, saveProfile } = useProfile()
  console.log('calc', calc)
  const { axiomFetch } = useAxiomFetch()
  const [state, setState] = useState({
    files: [],
    buyers: [],
    buyer_type: 'Buyer',
    sell_comm: 3.0,
    list_comm: 3.0,
    sales_price: new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(mlsMatch?.list_price || mlsMatch?.original_list_price),
  })
  const lastBuyerRef = useRef(null)
  const [scroll, setScroll] = useState(0)
  const [messageSent, setMessageSent] = useState('done')
  const [isLoading, setIsLoading] = useState(false)
  const handle_msg = (status, message, type, close) => {
    setMessageSent({ ...messageSent, status, message, type })
    if (close) {
      setTimeout(() => {
        setMessageSent({ ...messageSent, status: 'done', message: '' })
      }, 3000)
    }
  }

  useEffect(() => {
    const eo_of_record = validate_number(
      profile?.EO_OF_RECORD_ID || profile?.BRCH_ESCROW_ID
    )
    if (
      eo_of_record > 0 &&
      calc.escrow_officers.some((item) => item.user_id === eo_of_record)
    ) {
      setState((prev) => ({
        ...prev,
        eo_id: eo_of_record,
      }))
    }
  }, [profile.EO_OF_RECORD_ID, profile.BRCH_ESCROW_ID])

  const show_pct = (item) => {
    item = item?.toString().replace(/[^0-9.]/g, '')
    if (item.length === 2 && !item.includes('.')) {
      item = item.split('').join('.')
    }
    item = item.slice(0, 4)

    return `${item}`
  }

  const money = (item) => {
    item = item?.toString()?.replace(/[^0-9.,$]/g, '')
    return item
  }

  const handle_inputs = (e, buyer_id) => {
    const { name, value } = e.target
    if (name === 'first' || name === 'last') {
      const new_buyers = structuredClone(state?.buyers)
      new_buyers[buyer_id] = {
        ...new_buyers[buyer_id],
        [e.target.name]: e.target.value,
      }
      setState((prev) => ({ ...prev, buyers: new_buyers }))
      return
    }
    setState((prev) => ({ ...prev, [name]: value }))
  }

  const handle_open = async () => {
    setIsLoading(true)
    let body = {}
    console.log(state)
    if (state.buyer_type === 'Trust') {
      if (state?.trust_name?.length) {
        body.trust_name = state?.trust_name
      }
    } else {
      console.log(state?.buyers?.[0]?.first)
      if (state?.buyers?.[0]?.first) {
        body.buyers = []
      }
      for (const buyer of state?.buyers) {
        console.log(buyer)
        if (buyer?.first) {
          if (buyer.last) {
            body?.buyers?.push(buyer)
          } else {
            handle_msg(
              'failure_msg',
              'Buyer must have first and last name',
              'buyer_error',
              true
            )
            delete body.buyers
            return
          }
        }
      }
    }

    if (state?.sales_price) {
      body.sales_price = Number(
        state?.sales_price?.toString()?.replace(/\D/g, '')
      )
    }
    if (state?.files?.length) {
      body.files = state?.files
    }
    if (state?.list_comm) {
      body.list_comm = Number(state?.list_comm)
    }
    if (state?.sell_comm) {
      body.sell_comm = Number(state?.sell_comm)
    }
    if (state?.notes) {
      body.notes = state?.notes
    }
    body.eo_id = validate_number(state.eo_id)
    // console.log(JSON.stringify(body))

    const data = await axiomFetch(
      `/escrow/transaction/mls/${mlsMatch?.mls_id}`,
      'POST',
      body,
      {},
      true
    )
    console.log(data)
    setIsLoading(false)

    return navigate('/user/my-transactions-lobby/transactions')
  }

  useEffect(() => {
    setState((prev) => ({ ...prev, buyers: [{ id: 0 }] }))
  }, [state?.buyer_type])
  useEffect(() => {
    if (lastBuyerRef?.current) {
      lastBuyerRef?.current?.scrollIntoView({ behavior: 'smooth' })
      lastBuyerRef?.current.focus()
    }
  }, [scroll])

  const purchase_upload = async (e) => {
    e.preventDefault()
    console.log(e?.target?.files)
    if (e?.dataTransfer?.files.length > 1) {
      handle_msg(
        'failure_msg',
        'Must be a single file.',
        'single_file_error',
        true
      )
    }
    if (e?.target?.files?.length) {
      console.log('file transfer')

      for (const file of e?.target.files) {
        setState((prev) => ({
          ...prev,
          files: [
            ...prev?.files,
            { url: URL.createObjectURL(file), file: file },
          ],
        }))
      }
    } else {
      console.log('data transfer')
      for (const file of e?.dataTransfer?.files) {
        setState((prev) => ({
          ...prev,
          files: [
            ...prev?.files,
            { url: URL.createObjectURL(file), file: file },
          ],
        }))
      }
    }
  }
  if (isLoading) {
    return <SmallLoading />
  }
  return (
    <div>
      {state?.files?.length ? (
        <>
          <div className='flex flex-col'>
            <div className='flex items-center gap-2'>
              <p className={`${uiStyles.text_gray} text-[14px] font-medium`}>
                Purchase Contract{' '}
              </p>
              <div
                className='cursor-pointer'
                onClick={() => {
                  setState((prev) => ({ ...prev, files: [] }))
                }}
              >
                <HiOutlineTrash size={20} />
              </div>
            </div>
            <iframe
              className='self-center'
              title={state?.files[0].url}
              src={state?.files[0].url}
            ></iframe>
          </div>
        </>
      ) : (
        <ImageUpload
          accept={'.pdf'}
          title={'Upload purchase contract'}
          subtitle={'PDF'}
          handler={purchase_upload}
          multiple={false}
        />
      )}
      {messageSent.type === 'single_file_error' ? (
        <div className={`${uiStyles[messageSent.status]} mb-[20px]`}>
          {messageSent.message}
        </div>
      ) : (
        ''
      )}

      <div className='mt-[10px]'></div>
      <div className='w-[80%] [margin:0_auto]'>
        <ButtonsRow
          items={['Buyer', 'Trust/LLC']}
          selected={state?.buyer_type}
          handler={(item) => {
            setState((prev) => ({ ...prev, buyer_type: item }))
          }}
        />
      </div>
      <div className='mt-[10px]'></div>
      {state?.buyer_type === 'Trust/LLC' ? (
        <LabelInput
          name={'trust_name'}
          show_delete_all={true}
          value={state?.trust_name}
          handleChange={handle_inputs}
          top_label={'Trust/LLC'}
        />
      ) : (
        <div className='flex gap-2 flex-col'>
          <div className='max-h-[110px] overflow-y-scroll'>
            {state?.buyers?.map((buyer, index) => {
              return (
                <div
                  className={`flex gap-1 ${
                    index % 2 ? '' : 'bg-gray-100 bg-opacity-70'
                  } p-2 rounded-md`}
                >
                  {index > 0 && (
                    <img
                      className='w-[20px]'
                      alt='minus'
                      src={minus_icon}
                      onClick={() => {
                        setState((prev) => ({
                          ...prev,
                          buyers: prev?.buyers.filter(
                            (item) => item?.id !== buyer?.id
                          ),
                        }))
                      }}
                    />
                  )}
                  <LabelInput
                    placeholder='First Name'
                    value={state?.buyers?.[buyer?.id]?.first}
                    no_margin={true}
                    name={'first'}
                    show_delete_all={true}
                    ref={
                      index === state?.buyers?.length - 1 ? lastBuyerRef : null
                    }
                    handleChange={(e) => {
                      console.log(buyer.id)
                      handle_inputs(e, buyer?.id)
                    }}
                  />
                  <LabelInput
                    placeholder='Last Name'
                    name={'last'}
                    value={state?.buyers?.[buyer?.id]?.last}
                    handleChange={(e) => {
                      handle_inputs(e, buyer?.id)
                    }}
                    show_delete_all={true}
                    onKeyUp={(e) => {
                      if (e.key === 'Enter') {
                        setState((prev) => ({
                          ...prev,
                          buyers: [
                            ...prev?.buyers,
                            { id: prev?.buyers?.length },
                          ],
                        }))
                        setScroll((prev) => prev + 1)
                      }
                    }}
                    no_margin={true}
                  />
                </div>
              )
            })}
          </div>
          <img
            className='w-[25px] self-end cursor-pointer'
            alt='plus'
            onClick={() => {
              setState((prev) => ({
                ...prev,
                buyers: [...prev?.buyers, { id: prev?.buyers?.length }],
              }))
              setScroll((prev) => prev + 1)
            }}
            src={plus_icon}
          />
        </div>
      )}
      <LabelInput
        top_label={'Sales Price'}
        name={'sales_price'}
        show_delete_all={true}
        value={money(state?.sales_price)}
        handleChange={handle_inputs}
      />
      <div className='mt-[10px]'></div>
      <label className='text-sm font-medium text-[#344054]'>
        Escrow Officer
      </label>
      <Select
        value={state.eo_id}
        name={'eo_id'}
        handler={(e) => {
          setState((prev) => ({
            ...prev,
            eo_id: validate_number(e.target.value),
          }))
        }}
      >
        {calc?.escrow_officers?.map((item) => {
          return (
            <option value={item?.user_id}>
              {item.first_name} {item?.last_name}
            </option>
          )
        })}
      </Select>
      <div className='mt-[10px]'></div>
      <LabelInput
        top_label={'Listing Agent Commission %'}
        value={show_pct(state?.list_comm)}
        show_delete_all={true}
        name={'list_comm'}
        handleChange={handle_inputs}
      />
      <LabelInput
        top_label={'Selling Agent Commission %'}
        show_delete_all={true}
        value={show_pct(state?.sell_comm)}
        name={'sell_comm'}
        handleChange={handle_inputs}
      />

      <label className={`${uiStyles.label}`}>Additional Notes</label>
      <textarea
        className={`${uiStyles.text_area} mb-[10px]`}
        value={state?.notes || ''}
        onChange={(e) => {
          setState((prev) => ({ ...prev, notes: e.target.value }))
        }}
      ></textarea>
      {messageSent.type === 'buyer_error' ? (
        <div className={`${uiStyles[messageSent.status]} mb-[20px]`}>
          {messageSent.message}
        </div>
      ) : (
        ''
      )}
      <div className='flex justify-end gap-2'>
        <button
          className={`${uiStyles.white_btn}`}
          onClick={() => {
            setModalType('mlsView')
          }}
        >
          Cancel
        </button>
        <button className={`${uiStyles.green_btn}`} onClick={handle_open}>
          Open Escrow
        </button>
      </div>
    </div>
  )
}

export default MLSConfirm
