import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import images_homes from '../../../assets/images/home'
import { format_number, get_storage, set_storage } from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import Modal from '../../Modal'
import PropertyItem from '../../ui/PropertyItem'
import SwipeConfig from '../../ui/SwipeConfig'
import uiStyles from '../../ui/uiStyles'
import { HomeFilter } from './HomeFilter'
export const HomeListFavorites = ({
  title,
  state,
  setState,
  right_component,
}) => {
  const { axiomFetch } = useAxiomFetch()
  const [showModal, setShowModal] = useState(false)
  const [selected, setSelected] = useState(1)
  console.log('home', state)
  useEffect(() => {
    const controller = new AbortController()
    const get_favorited_homes = async () => {
      if (!state?.homes?.favorited_homes?.length) {
        console.log('hello')
        setState((prev) => ({
          ...prev,
          homes: {
            ...prev?.homes,
            favorited_homes: get_storage('favorited_homes'),
          },
        }))
      }
      const homes = await axiomFetch(
        '/marketing/homes/favorite',
        'GET',
        {},
        {},
        false,
        controller?.signal
      )
      let all_favorited_homes = []
      if (homes?.length) {
        all_favorited_homes = homes?.reduce((curr, val) => {
          const home_found = state?.homes?.favorited_homes?.find(
            (item) => item.mls_id === val.mls_id
          )
          if (!home_found) {
            curr.push(val)
          }
          return curr
        }, [])
      }
      if (all_favorited_homes?.length) {
        set_storage('favorited_homes', all_favorited_homes || [])
        setState((prev) => ({
          ...prev,
          homes: {
            ...prev?.homes,
            favorited_homes: all_favorited_homes || [],
          },
        }))
      }
    }
    get_favorited_homes()

    return () => {
      controller?.abort()
    }
  }, [])

  const all_homes = () => {
    let homes = state?.homes?.favorited_homes
    // let filters = state?.homes

    // // price and bedrooms
    // if (
    //   format_number(filters?.min_price) ||
    //   format_number(filters?.max_price)
    // ) {
    //   homes = homes?.filter(
    //     (item) =>
    //       format_number(item?.list_price) >=
    //         format_number(filters?.min_price) &&
    //       format_number(item?.list_price) <= format_number(filters?.max_price)
    //   )
    // }

    // // bedrooms
    // if (filters?.bedrooms !== 'any' && filters?.bedrooms) {
    //   homes = homes.filter(
    //     (item) => format_number(item?.beds) >= format_number(filters?.bedrooms)
    //   )
    // }
    // // bathrooms
    // if (filters?.bathrooms !== 'any' && filters?.bathrooms) {
    //   homes = homes.filter(
    //     (item) =>
    //       format_number(item?.baths) >= format_number(filters?.bathrooms)
    //   )
    // }

    // // open house
    // if (filters?.open_house === 'Y') {
    //   homes = homes.filter((item) => item?.upcoming_open_house > 0)
    // }

    // resort for favorites.
    return (homes || [])
      ?.sort((a, b) => a?.prop_add?.localeCompare(b?.prop_add))
      ?.sort(
        (a, b) => format_number(b?.list_price) - format_number(a?.list_price)
      )
  }
  return (
    <div className=''>
      <div className='flex justify-center'>
        <p className={`${uiStyles.text_black_title} capitalize text-center`}>
          {`${all_homes()?.length || ''} ${title || 'homes for sale'}`}
        </p>
        {/* <div
          className='mt-[10px] ml-[50px] cursor-pointer'
          onClick={() => {
            setShowModal((prev) => !prev)
          }}
        >
          <img src={images_homes.filter} />
        </div> */}
      </div>
      <div className='mt-[20px] max-h-[620px] overflow-scroll'>
        {all_homes()?.length ? (
          all_homes()?.map((item, index) => {
            return (
              <SwipeConfig
                index={index}
                selectedIndex={selected}
                setSelectedIndex={setSelected}
                widthConfigRight={44}
                RightComponents={() => right_component(item)}
                key={item?.MLS_ID || index}
              >
                <PropertyItem
                  listing={item}
                  show_favorite={true}
                  favorited={item?.favorited === 'Y' ? true : false}
                />
              </SwipeConfig>
            )
          })
        ) : (
          <div className='px-3'>No Homes Found for your current search.</div>
        )}
      </div>
      {showModal ? (
        <Modal
          modalName={'home_filter'}
          setShowModal={setShowModal}
          showModal={showModal}
          showClose={true}
        >
          <HomeFilter
            state={state}
            setState={setState}
            setShowModal={setShowModal}
          />
        </Modal>
      ) : (
        ''
      )}
    </div>
  )
}
