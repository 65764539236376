import { useState, useRef, useEffect } from 'react'
import { useDistribute } from '../../../contexts/DistributeContext'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import uiStyles from '../../ui/uiStyles'

export const AiPostsEdit = ({ setShowModal }) => {
  const { axiomFetch } = useAxiomFetch()
  const { distribute, saveDistribute } = useDistribute()
  const text_ref = useRef(null)
  const type =
    distribute?.type === 'social'
      ? distribute?.social || 'facebook'
      : 'facebook'
  const content = distribute?.content?.[type]
  const posts =
    content?.posts?.posts?.data ||
    (content?.posts?.length ? content?.posts : [])

  useEffect(() => {
    text_ref.current.focus()
    const input = text_ref.current
    input.setSelectionRange(input.value.length, input.value.length)
  }, [])
  const [messageSent, setMessageSent] = useState({})
  const handle_msg = (status, message, type, close) => {
    setMessageSent({ ...messageSent, status, message, type })
    if (close) {
      setTimeout(() => {
        setMessageSent({ ...messageSent, status: 'done', message: '' })
      }, 3000)
    }
  }

  const save_distribute = (key, value) => {
    saveDistribute((prev) => ({
      ...prev,
      content: {
        ...(prev?.content || []),
        [type]: { ...(prev?.content?.[type] || []), [key]: value },
      },
    }))
  }
  const save_posts = (key, value) => {
    saveDistribute((prev) => ({
      ...prev,
      content: {
        ...(prev?.content || []),
        [type]: {
          ...(prev?.content?.[type] || []),
          posts: {
            ...prev?.content?.[type]?.posts,
            posts: {
              ...prev?.content?.[type]?.posts.posts,
              [key]: value,
            },
          },
        },
      },
    }))
  }
  const save_post = (key, value) => {
    saveDistribute((prev) => ({
      ...prev,
      content: {
        ...(prev?.content || []),
        [type]: {
          ...(prev?.content?.[type] || []),
          selected_post: {
            ...prev?.content?.[type]?.selected_post,
            [key]: value,
          },
        },
      },
    }))
  }

  const save_edit = async () => {
    handle_msg('loading_msg', 'Updating Post', 'save_edit_post')
    await axiomFetch(`/social/${type}/post/update`, 'POST', {
      post_id: content.selected_post.id,
      message: content?.selected_post.message,
    })
    const new_posts = posts.map((item) => {
      if (item.id === content.selected_post.id) {
        item.message = content?.selected_post?.message
      }
      return item
    })
    save_posts('data', [...new_posts])
    handle_msg('sucess_msg', 'Post Updated!', 'save_edit_post', true)
    setShowModal(false)
  }
  return (
    <div>
      <label>Edit Message</label>
      <textarea
        ref={text_ref}
        value={content?.selected_post?.message}
        onChange={(e) => {
          console.log(e.shiftKey)
          save_post('message', e.target.value)
        }}
        className={uiStyles.text_area}
        onKeyUp={(e) => {
          if (e.key === 'Enter' && e.shiftKey) {
            save_edit()
          } else {
          }
        }}
      >
        {content.selected_post.message}
      </textarea>
      <div className='flex justify-end'>
        {messageSent?.type === 'save_edit_post' ? (
          <div className={`${uiStyles[messageSent.status]} mb-[20px]`}>
            {messageSent.message}
          </div>
        ) : (
          <button className={uiStyles.hover_btn} onClick={save_edit}>
            Save
          </button>
        )}
      </div>
    </div>
  )
}
