import React, { memo } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useProfile } from '../contexts/UserContext'
import profileSilhouette from '../assets/images/profile-silhouette.svg'
import logoMain from '../assets/images/logoMain.png'
import { useClaims } from '../contexts/ClaimsContext'
import int_imgs from '../assets/images/internal'
function Hero({ showName }) {
  const { isAuthenticated } = useAuth0()
  const { profile } = useProfile()
  const { claims } = useClaims()
  return (
    isAuthenticated && (
      <div
        className={`flex flex-col items-center ${
          claims?.is_internal === 'Y' ? 'max-w-[230px]' : 'max-w-[230px]'
        } mx-auto pt-5 mb-5`}
      >
        <img
          src={
            claims?.is_internal === 'Y'
              ? int_imgs?.driggs_logo
              : profile?.LOGO_LINK || logoMain
          }
          alt=''
          className={`mb-[10px] object-contain object-top ${
            claims?.is_internal === 'Y' ? 'w-[180px]' : 'w-[230px]'
          } max-h-[110px]`}
        />
        {claims?.is_internal === 'Y' ? (
          <img
            src={
              profile?.INT_PHOTO_LINK ||
              profile?.PHOTO_LINK ||
              profile?.account?.photo_url ||
              profileSilhouette
            }
            alt={profile?.CONT_NAME}
            className='mx-auto block rounded-md object-contain object-top w-[230px] max-h-[180px]'
          />
        ) : (
          <img
            src={
              profile?.PHOTO_LINK ||
              profile?.INT_PHOTO_LINK ||
              profile?.account?.photo_url ||
              profileSilhouette
            }
            alt={profile?.CONT_NAME}
            className='mx-auto block rounded-md object-cover max-w-[230px] max-h-[180px]'
          />
        )}
        {showName ? (
          <p className='text-center mt-[3px] text-[16px] font-semibold text-primary'>
            {profile?.CONT_NAME}
          </p>
        ) : (
          ''
        )}
        {profile?.JOB_TITLE_SHOW === 'Y' ? (
          <p className='text-center mt-[3px] text-[16px] font-semibold text-primary'>
            {profile?.JOB_TITLE}
          </p>
        ) : (
          ''
        )}
      </div>
    )
  )
}
export default memo(Hero)
