import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { set_storage } from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import uiStyles from '../../ui/uiStyles'

const RemoveRideAlong = ({
  state,
  setState,
  setShowModal,
  setItineraryModal,
  ride_along,
}) => {
  const [loading, setLoading] = useState(false)
  const { axiomFetch } = useAxiomFetch()
  const navigate = useNavigate()
  console.log(ride_along)
  const remove_client = async (client) => {
    setLoading(true)

    await axiomFetch('/tours/client/remove', 'DELETE', {
      event_id: state?.tour_state?.event_id,
      remove_cont_id: client?.cont_id,
      attribute_id:
        state?.data?.event_pages?.find(
          (item) => item?.event_id === state?.tour_state?.event_id
        ).event_attribute || -1,
    })

    setState((prev) => {
      const tour_info = prev?.data?.event_tour_info?.filter(
        (item) => item?.cont_id !== client?.cont_id
      )

      set_storage('listing_tours', {
        ...prev?.data,
        event_tour_info: tour_info,
      })
      return {
        ...prev,
        data: {
          ...prev?.data,
          event_tour_info: tour_info,
        },
      }
    })
    setLoading(false)
    setShowModal(false)
  }
  return (
    <div>
      <p>
        Are you sure you want to remove{' '}
        {ride_along?.agent_name || 'this ride along'} from the tour?
      </p>
      {!loading ? (
        <div className='flex justify-center gap-[10px]'>
          <button
            onClick={() => {
              setShowModal(false)
            }}
            className={uiStyles.white_btn}
          >
            Cancel
          </button>
          <button
            onClick={async () => {
              await remove_client(ride_along)
            }}
            className={uiStyles.red_btn}
          >
            Remove
          </button>
        </div>
      ) : (
        <SmallLoadingRel />
      )}
    </div>
  )
}

export default RemoveRideAlong
