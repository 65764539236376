import React from 'react'
import Wrapper from '../../../components/layout/Wrapper'
import { ConnectedAccounts } from '../../../components/pages/settings/ConnectedAccounts'
import SubNav from '../../../components/SubNav'

export const PageConnectedAccounts = () => {
  return (
    <Wrapper>
      <SubNav />
      <ConnectedAccounts />
    </Wrapper>
  )
}
