import React from 'react';
import Up from '../../assets/images/chartup.svg';
import Down from '../../assets/images/chartdown.svg';
import { HiArrowUp, HiArrowDown } from 'react-icons/hi2';
export default function DataBox({
  title = '',
  number = '',
  percentage = '',
  time = '',
  direction = '',
  className = '',
}) {
  return (
    <div
      className={`border border-gray-200 bg-white shadow-md p-4 rounded-lg w-[297px] ${className}`}
    >
      <h2 className=" text-base   text-primary">{title}</h2>
      <div className="flex items-end">
        <div className="flex-1">
          {
            <h3 className="text-3xl text-primary font-semibold my-4">
              {number || 0}
            </h3>
          }
          <p>
            {direction && direction?.toLowerCase() === 'up' && (
              <>
                <HiArrowUp className="text-accent-250 inline-block text-lg" />
                <span className="text-accent-250">{percentage}%</span>
              </>
            )}
            {direction && direction?.toLowerCase() === 'down' && (
              <>
                <HiArrowDown className="text-red-400 inline-block" />
                <span className="text-red-400">{percentage}%</span>
              </>
            )}{' '}
            {time}
          </p>
        </div>
        <div className="flex-1 flex justify-end">
          {direction && direction?.toLowerCase() === 'up' && (
            <img src={Up} alt="" />
          )}
          {direction && direction?.toLowerCase() === 'down' && (
            <img src={Down} alt="" />
          )}
        </div>
      </div>
    </div>
  );
}
