import { useEffect, useState, useRef } from 'react'
import SwipeConfig from '../../ui/SwipeConfig'
import ch_images from '../../../assets/images/conversations'
import uiStyles from '../../ui/uiStyles'
import { NotificationsIcon } from './NotificationsIcon'
import { useConversations } from '../../../contexts/ConversationsContext'
export const CampaignCard = ({ campaign, campaignMessages }) => {
  const { convMessages } = useConversations()

  const left_image = (count) => {
    if (count < 3) {
      return ch_images.person
    } else if (count === 3) {
      return ch_images.people
    } else {
      return ch_images.announcement
    }
  }

  const get_unread_count = () => {
    let total = 0
    for (const conv of campaign?.conversations) {
      total += convMessages[conv?.sid]?.unread_count || 0
    }
    return total
  }

  const get_last_conv_msg = () => {
    let max = campaign?.conversations?.reduce((prev, current) =>
      convMessages?.[prev?.sid]?.date?.valueOf() >
      convMessages?.[current?.sid]?.date?.valueOf()
        ? prev
        : current
    )
    return convMessages?.[max?.sid]
  }

  return (
    <div className='swipeable mx-[5px]'>
      <div className='swipeable flex items-center justify-between mr-[10px]'>
        <div className='swipeable my-[16px] mx-[16px] bg-[#BCC1C5] rounded-full w-[50px] h-[50px] flex place-content-center'>
          <img
            className='swipeable w-[30px]'
            src={left_image(campaign?._participants?.size)}
          />
        </div>
        <div className='swipeable flex grow max-w-[170px] h-[55px] px-[5px] flex-col gap-[5px]'>
          <p className='swipeable text-[16px] font-semibold text-[#0B1B0F]'>
            {campaign?.name}
          </p>
          <p className='swipeable mb-[10px]'>
            {get_last_conv_msg()?.body?.length > 35
              ? `${get_last_conv_msg()?.body.slice(0, 42)}...`
              : get_last_conv_msg()?.body}
          </p>
        </div>
        <div className='swipeable flex flex-col items-center'>
          <p className='swipeable mb-[10px] w-[65px]'>
            {get_last_conv_msg()?.date?.toLocaleString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
          </p>
          <NotificationsIcon count={get_unread_count()} />
        </div>
      </div>
    </div>
  )
}
