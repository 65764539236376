import React from 'react'
import { useProfile } from '../../../contexts/UserContext'
import { formatPhoneNumber } from '../../../helpers'

const PairwiseIneligible = () => {
  const { profile } = useProfile()
  return (
    <div className='px-3'>
      Looks like you aren't eligible yet for Pairwise. Be sure to talk with your
      rep for more information.
      <p className='mt-[5px]'>{profile.REP_NAME}</p>{' '}
      <p>{profile.REP_EMAIL || ''}</p>
      <p> {formatPhoneNumber(profile.REP_PHONE)}</p>
    </div>
  )
}

export default PairwiseIneligible
