import React from 'react'

import AxiomAlertOIcon from './AxiomAlertOIcon'
import AxiomClosedFileIcon from './AxiomClosedFileIcon'
import AxiomOpenedIcon from '../../assets/images/open.svg'

export default function Transaction({
  title = 'Transaction',
  fileid,
  address,
  city,
  state,
  isClosed,
  escrowOfficer,
  lender,
  postal,
  docName,
}) {
  return (
    <div className='swipeable bg-blue-50'>
      {title && (
        <div className='swipeable ml-[10px] text-center text-primary uppercase text-xs font-semibold'>{title}</div>
      )}
      <div className='swipeable flex items-center px-2'>
        <div className='swipeable w-[117px] text-xs text-gray-500 font-light flex-shrink-0'>
          {address},{city} {state} {postal}
        </div>

        <div className='swipeable flex-1 flex justify-center items-center '>
          {!isClosed && <img className='swipeable ml-[25px] w-[28px]' src={AxiomOpenedIcon} />}
          {isClosed && <AxiomClosedFileIcon className='swipeable block mx-auto' />}
        </div>
        <div className='swipeable w-[145px] text-right text-xs text-gray-500 font-light flex-shrink-0 pr-[6px]'>
          <h3 className={`swipeable ${!isClosed ? 'text-[#3E7BFA]' : 'text-[#06C270]'} text-sm font-semibold`}>
            {fileid}
          </h3>
          {escrowOfficer && `EO: ${escrowOfficer}`} <p className='swipeable'>{lender && `Lender: ${lender}`}</p>
        </div>
      </div>
      <div className='swipeable text-center    text-primary uppercase text-xs font-semibold'>
        {/* {!isClosed && 'Need Attention'}
        {isClosed && 'Commitment'} */}
        {docName}
      </div>
    </div>
  )
}
