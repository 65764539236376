import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import images_homes from '../../assets/images/home'
import images_tours from '../../assets/images/listing_tours'
import Wrapper from '../../components/layout/Wrapper'
import { HomeListFavorites } from '../../components/pages/home'
import SubNav from '../../components/SubNav'
import { SmallLoadingRel } from '../../components/ui/SmallLoadingRel'
import { useListingTours } from '../../contexts/ListingToursContext'
import { useReceiver } from '../../contexts/ReceiverContext'
import { get_storage } from '../../helpers'

export const PageHomeListFavorites = () => {
  const { receiver, saveReceiver } = useReceiver()
  const { listingTours, saveListingTours, save_home } = useListingTours()
  const [addTourLoading, setAddTourLoading] = useState(false)
  const { claims, saveClaims } = useReceiver()
  const navigate = useNavigate()
  useEffect(() => {
    if (
      localStorage?.getItem('vbc_edit_mode') === 'true' &&
      listingTours?.buyer_tours?.property_edit?.search_type !== 'address'
    ) {
      saveReceiver((prev) => ({ ...prev, edit_mode: 'view' }))
    }
  }, [])
  return (
    <Wrapper>
      {receiver?.edit_mode === 'view' ? (
        <SubNav
          url={
            ''
            //   `/user/my-vbc/${
            //   receiver?.vbc_receiver?.ID ||
            //   (get_storage('vbc_receiver') || {})?.ID
            // }`
          }
          logo_url={
            receiver?.edit_mode === 'view'
              ? '/user'
              : `/user/my-vbc/${
                  receiver?.vbc_receiver?.ID ||
                  (get_storage('vbc_receiver') || {})?.ID
                }`
          }
          showConversations={false}
          showTours={true}
        />
      ) : (
        <SubNav
          url={
            ''
            // listingTours?.buyer_tours?.property_edit?.search_type === 'address'
            //   ? ``
            //   : '/card'
          }
          logo_url={
            listingTours?.buyer_tours?.property_edit?.search_type === 'address'
              ? `/user/buyer_tours/add_home`
              : '/card'
          }
          showConversations={false}
        />
      )}
      <HomeListFavorites
        title={'Favorited Homes'}
        addTourLoading={addTourLoading}
        state={receiver}
        setState={saveReceiver}
        right_component={(item) => {
          return (
            <div className='flex gap-[20px] items-center'>
              {listingTours?.buyer_tours?.property_edit?.search_type ===
              'address' ? (
                <>
                  {addTourLoading ? (
                    <SmallLoadingRel color={'from-white to-gray-300'} />
                  ) : (
                    <div
                      className='cursor-pointer flex flex-col items-center'
                      onClick={async () => {
                        setAddTourLoading(true)
                        saveReceiver((prev) => ({
                          ...prev,
                          homes: { ...prev?.homes, current_home: item },
                        }))
                        await save_home(
                          {
                            mls_id: item?.mls_id?.toString(),
                            event_id:
                              listingTours?.buyer_tours?.event_edit?.event_id,
                            evt_id:
                              listingTours?.buyer_tours?.event_edit?.evt_id,
                          },
                          '/user/buyer_tours/add_home'
                        )
                        setAddTourLoading(false)
                      }}
                    >
                      <img
                        className='w-[25px] pb-[4px]'
                        src={images_tours.add_icon_white}
                      />
                      <p className='text-[10px]'>add to tour</p>
                    </div>
                  )}
                </>
              ) : (
                ''
              )}
              <div
                className='cursor-pointer flex flex-col items-center'
                onClick={() => {
                  saveReceiver((prev) => ({
                    ...prev,
                    homes: { ...prev?.homes, current_home: item },
                  }))
                  navigate('/homes/view')
                }}
              >
                <img className='w-[30px]' src={images_homes.home_icon} />
                <p className='text-[10px]'>View</p>
              </div>
            </div>
          )
        }}
      />
    </Wrapper>
  )
}
