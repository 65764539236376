import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import PropertyItem from '../../ui/PropertyItem'
import home_placeholder from '../../../assets/images/Home_stock.jpeg'
import ListItem from '../../ui/ListItem'
import { GrNext, GrPrevious } from 'react-icons/gr'
import uiStyles from '../../ui/uiStyles'
import { useAi } from '../../../contexts/AiContext'
import { useLocation, useNavigate } from 'react-router-dom'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { useProfile } from '../../../contexts/UserContext'
import images_homes from '../../../assets/images/home'
import { content_types } from '../../../data/data'
import { useDistribute } from '../../../contexts/DistributeContext'
import {
  capitalize,
  date_in_future,
  date_today,
  date_today_future,
  date_within_days,
  getCurrentTime,
  getDateString,
  set_storage,
} from '../../../helpers'

const TourSlider = ({
  state,
  setState,
  setLoading,
  slot_settings,
  types = [21, 162, 163],
}) => {
  const [swiper, setSwiper] = useState({})
  const [reload, setReload] = useState(0)
  const navigate = useNavigate()
  const location = useLocation()
  const organize_tours = () => {
    const upcoming_tours =
      state?.data?.event_pages
        ?.filter(
          (item) =>
            item.event_id?.toString() !==
              state?.tour_state?.event_id?.toString() &&
            (item?.demo_tour || 0) === 0 &&
            date_in_future(item?.event_timestamp)
        )
        ?.sort((a, b) => {
          return new Date(a.event_timestamp) - new Date(b.event_timestamp)
        }) || []

    const current_tour =
      state?.data?.event_pages
        ?.filter(
          (item) =>
            item.event_id?.toString() ===
            state?.tour_state?.event_id?.toString()
        )
        ?.sort((a, b) => {
          return new Date(a.event_timestamp) - new Date(b.event_timestamp)
        }) || []

    const today_tours =
      state?.data?.event_pages
        ?.filter(
          (item) =>
            item.event_id?.toString() !==
              state?.tour_state?.event_id?.toString() &&
            (item?.demo_tour || 0) === 0 &&
            date_today(item?.event_timestamp)
        )
        ?.sort((a, b) => {
          return new Date(a.event_timestamp) - new Date(b.event_timestamp)
        }) || []

    const old_tours =
      state?.data?.event_pages
        ?.filter(
          (item) =>
            item.event_id?.toString() !==
              state?.tour_state?.event_id?.toString() &&
            (item?.demo_tour || 0) === 0 &&
            date_within_days(item?.event_timestamp, 60) &&
            !date_today_future(item?.event_timestamp)
        )
        ?.sort((a, b) => {
          return new Date(b.event_timestamp) - new Date(a.event_timestamp)
        }) || []

    const demo_tours =
      state?.data?.event_pages
        ?.filter(
          (item) =>
            item.event_id !== state?.tour_state?.event_id &&
            item.demo_tour === 1
        )
        ?.sort((a, b) => {
          return new Date(a.event_timestamp) - new Date(b.event_timestamp)
        }) || []

    return [
      ...current_tour,
      ...today_tours,
      ...upcoming_tours,
      ...old_tours,
      ...demo_tours,
    ]?.filter((item) => types.includes(item.event_type_id))
  }

  const get_van_image = (item) => {
    const get_image = (type) => {
      return state?.data?.tour_types?.find((item) => item.type_id === type)
        ?.van_photo
    }
    if (item.event_type_id === 21) {
      return get_image(1)
    } else if (item?.event_type_id === 162) {
      return get_image(22)
    } else if (item?.event_type_id === 163) {
      return get_image(21)
    }
  }

  const get_list_item = (item) => {
    const get_title = (item) => {
      return `${slot_settings?.title} ${item?.demo_tour ? '(Demo)' : ''}` || ''
    }
    const get_subtitle = (item) => {
      return item?.tour_name
    }

    const get_content = (item) => {
      return `${getDateString({
        date: item?.event_timestamp || '',
      })} ${getCurrentTime(item?.event_timestamp)}`
    }
    const get_sub_content = (item) => {
      return state?.tour_state?.event_id === item?.event_id
        ? 'Current Tour'
        : ''
    }

    const get_image = (item) => {
      return get_van_image(item) || slot_settings?.image
    }

    const select_image = async (item) => {
      setState((prev) => {
        const new_tour_state =
          prev?.tour_state?.event_id === item?.event_id
            ? {
                ...prev?.tour_state,
                from_card: location?.pathname?.includes('card') ? true : false,
              }
            : {
                event_id: item?.event_id,
                completed: item?.tour_complete || 0,
                from_card: location?.pathname?.includes('card') ? true : false,
              }
        set_storage('listing_tours_state', new_tour_state)
        return {
          ...prev,
          tour_state: new_tour_state,
        }
      })
      return navigate('/user/listing_tours')
    }

    return (
      <ListItem
        click_me={true}
        title={get_title(item)}
        subtitle={get_subtitle(item)}
        show_swipe_btns={false}
        swiper={swiper}
        // status_text={
        //   item?.tour_complete ? 'Completed' : 'Incomplete'
        // }
        content={get_content(item)}
        sub_content={get_sub_content(item)}
        sub_content_gap={'mt-[0px]'}
        image={get_image(item)}
        select_on_image={true}
        select={async () => await select_image(item)}
        show_filter={true}
        filter_list={organize_tours()?.map((item) => {
          return {
            ...item,
            click_me: true,
            title: get_title(item),
            subtitle: get_subtitle(item),
            show_swipe_btns: false,
            swiper: swiper,
            content: get_content(item),
            sub_content: get_sub_content(item),
            sub_content_gap: 'mt-[0px]',
            image: get_image(item),
            select_on_image: true,
            select: async () => await select_image(item),
          }
        })}
        filter_strategy={{
          types: {
            title: 'str',
            content: 'str',
            sub_content: 'str',
            search: 'search',
            subtitle: 'str',
          },
          show_filters: ['search'],
          search: {
            title: 'str',
            content: 'str',
            subtitle: 'str',
            sub_content: 'str',
          },
          values: {
            title: get_title(item),
            subtitle: get_subtitle(item),
            content: get_content(item),
            sub_content: get_sub_content(item),
            search: '',
          },
          names: {
            title: '',
            content: '',
            search: '',
            subtitle: '',
            sub_content: '',
          },
          placeholders: {
            search: 'Search...',
          },
          // options_names: {
          //   loc_mkt_id: markets?.map((item) => item?.location_name),
          //   lang_id: languages?.map((item) => item?.lang_name),
          // },
          // options_values: {
          //   loc_mkt_id: markets?.map((item) => item?.loc_id),
          //   lang_id: languages?.map((item) => item?.lang_id),
          // },
          // options_show_all: {
          //   loc_mkt_id: 0,
          //   lang_id: 0,
          // },
        }}
      />
    )
  }

  return (
    <>
      {organize_tours()?.length > 1 ? (
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          onSlideChange={(e) => {
            setReload((prev) => (prev || 0) + 1)
          }}
          onSwiper={(swiper) => {
            try {
              const index = 0
              if (index > 0) {
                swiper.slideTo(index === -1 ? 0 : index, 0)
                return
              }
              if (index === 0 || index === -1) {
                swiper.slideTo(1, 900)
              }
              setTimeout(() => {
                if (index === 0 || index === -1) {
                  swiper.slideTo(0, 900)
                }
                swiper.slideTo(index === -1 ? 0 : index, 0)
                setSwiper(swiper)
              }, 175)
            } catch (error) {
              console.log(error)
            }
          }}
        >
          {organize_tours()?.length
            ? (organize_tours() || [])?.map((item, index) => (
                <div key={index}>
                  <SwiperSlide key={index}>
                    <div className='flex flex-col'>{get_list_item(item)}</div>
                  </SwiperSlide>
                </div>
              ))
            : ''}
        </Swiper>
      ) : (
        <>{organize_tours()?.map((item) => get_list_item(item))}</>
      )}
    </>
  )
}

export default TourSlider
