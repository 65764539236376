import facebook from './facebook_icon.svg'
import google from './google_icon.svg'
import instagram from './instagram_icon.svg'
import linkedin from './linkedin_icon.svg'
import sendgrid from './sendgrid_icon.svg'
import twitter from './twitter_icon.svg'

const icons = {
    facebook,
    linkedin,
    twitter,
    instagram,
    sendgrid,
    google,
}

export default icons