import { useState, useEffect } from 'react'
import { getDateString, validate_number } from '../../helpers'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import LabelInput from '../form/LabelInput'
import Select from '../form/Select'
import { SmallLoadingRel } from '../ui/SmallLoadingRel'
import uiStyles from '../ui/uiStyles'
import BrokerComList from './BrokerComList'

const BrokerCom = () => {
  const { axiomFetch } = useAxiomFetch()
  const [brokerComData, setBrokerComData] = useState([])
  const [loading, setLoading] = useState(false)
  const [zip, setZip] = useState('')
  useEffect(() => {
    const controller = new AbortController()
    const get_data = async () => {
      if (zip?.toString()?.length > 0 && zip?.toString()?.length < 5 && zip) {
        return
      }
      setLoading(true)
      const data = await axiomFetch(
        `/calculators/broker_com_info?zip=${zip}`,
        'GET',
        {},
        {},
        false,
        controller?.signal,
        0
      )
      setBrokerComData(data?.buyer_broker_com_info || [])
      setLoading(false)
    }
    get_data()
    return () => {
      controller?.abort()
    }
  }, [zip])
  return (
    <>
      <div className='flex flex-col gap-[10px]'>
        <h1
          className={`${uiStyles.text_black_subtitle} text-center text-[20px]`}
        >
          Co-Broke Offered to Buyer's Agents
        </h1>
        <div className='px-3'>
          <LabelInput
            show_delete_all={true}
            show_search={true}
            name={'zip'}
            placeholder='Zip (89999)'
            value={zip || ''}
            handleChange={(e) => {
              setZip(
                e?.target?.value?.replace(/[^0-9]/g, '')?.slice(0, 5) || ''
              )
            }}
          />
        </div>
        {loading ? (
          <SmallLoadingRel />
        ) : brokerComData?.length > 0 ? (
          <>
            <div className={`${uiStyles.line_3px}`}></div>
            <div>
              <BrokerComList
                data={brokerComData}
                type={'opens'}
                title={'Available'}
              />
            </div>
            <div className={`${uiStyles.line_3px}`}></div>
            <div className='mb-[10px]'>
              <BrokerComList
                data={brokerComData}
                type={'close'}
                title={'Closed'}
              />
            </div>
          </>
        ) : (
          <div className='px-3'>No Data found for your current search.</div>
        )}
      </div>
    </>
  )
}

export default BrokerCom
