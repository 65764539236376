import React from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'
import PhotoGallery from '../../ui/PhotoGallery'
import { useAi } from '../../../contexts/AiContext'
import { useDistribute } from '../../../contexts/DistributeContext'
import uiStyles from '../../ui/uiStyles'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import ImageUpload from '../../form/ImageUpload'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { content_types } from '../../../data/data'
export const AiImage = ({ title, type = 'distribute' }) => {
  const { ai, saveAi } = useAi()
  const { distribute, saveDistribute } = useDistribute()
  const dist_type =
    (distribute?.type === 'social' ? distribute?.social : distribute?.type) ||
    'facebook'
  const content = distribute?.content?.[dist_type]

  let is_url
  if (type === 'ai') {
    is_url =
      ai?.image_upload?.upload_url || ai?.image_upload?.gallery?.active?.url
  } else {
    is_url = content?.photo_url
  }
  const { axiomFetch } = useAxiomFetch()

  const content_upload_checker =
    type === 'ai' ? ai?.image_upload?.loading : content?.image_upload

  console.log(content_upload_checker)

  const handle_msg = (status, message, close) => {
    if (type === 'ai') {
      saveAi((prev) => ({
        ...prev,
        image_upload: { ...prev?.image_upload, status, message },
      }))
    } else {
      saveDistribute((prev) => ({
        ...prev,
        content: {
          ...prev?.content,
          [dist_type]: {
            ...prev?.content?.[dist_type],
            image_upload: {
              ...prev?.image_upload,
              status,
              message,
            },
          },
        },
      }))
    }
    if (close) {
      setTimeout(() => {
        saveAi((prev) => ({
          ...prev,
          image_upload: { ...prev?.image_upload, status, message },
        }))
        saveDistribute((prev) => ({
          ...prev,
          content: {
            ...prev?.content,
            [dist_type]: {
              ...prev?.content?.[dist_type],
              image_upload: {
                ...prev?.image_upload,
                status: 'done',
                message: '',
              },
            },
          },
        }))
      }, 3000)
    }
  }
  const image_upload = async (e) => {
    e.preventDefault()
    let limit = 1
    let total =
      (e?.target?.files?.length || 0) + (e?.dataTransfer?.files?.length || 0)
    let files = e?.target?.files || e?.dataTransfer?.files
    if (total > limit) {
      console.log('limit reached')
      handle_msg('failure_msg', `Max image limit is ${limit} file.`, true)
      return
    }

    if (files?.length) {
      console.log('file transfer')

      for (const file of files) {
        console.log(file)
        // if (file.size > 4e6) {
        //   handle_msg('failure_msg', `Max image is is 4MB`, true)
        //   return
        // }
        const body = { files: [{ file: file, url: URL.createObjectURL(file) }] }
        if (type === 'ai') {
          saveAi((prev) => ({
            ...prev,
            image_upload: {
              ...prev?.image_upload,
              loading: true,
              files: [{ file: file, url: URL.createObjectURL(file) }],
            },
          }))
          let distribute_content = distribute?.content || {}

          for (const content_type of type === 'ai'
            ? content_types
            : [dist_type]) {
            distribute_content[content_type] = {
              ...distribute_content?.[content_type],
              image_upload: {
                ...distribute_content?.[content_type]?.image_upload,
                loading: true,
                files: [{ file: file, url: URL.createObjectURL(file) }],
              },
            }
          }
          saveDistribute((prev) => ({
            ...prev,
            content: distribute_content,
          }))
        } else {
          saveDistribute((prev) => ({
            ...prev,
            content: {
              ...prev?.content,
              [dist_type]: {
                ...prev?.content?.[dist_type],
                image_upload: {
                  ...prev?.image_upload,
                  loading: true,
                  files: [{ file: file, url: URL.createObjectURL(file) }],
                },
              },
            },
          }))
        }
        handle_msg('loading_msg', 'uploading new image...')
        const urls = await axiomFetch(
          '/social/facebook/upload_images',
          'POST',
          body,
          {},
          true
        )
        if (type === 'ai') {
          saveAi((prev) => ({
            ...prev,
            image_upload: {
              ...prev?.image_upload,
              loading: false,
              upload_url: urls?.[0] || '',
            },
          }))
          let distribute_content = distribute?.content || {}

          for (const content_type of type === 'ai'
            ? content_types
            : [dist_type]) {
            distribute_content[content_type] = {
              ...distribute_content?.[content_type],
              photo_url: urls?.[0] || '',
              image_upload: {
                ...distribute_content?.[content_type]?.image_upload,
                loading: false,
                upload_url: urls?.[0] || '',
              },
            }
          }
          saveDistribute((prev) => ({
            ...prev,
            content: distribute_content,
          }))
        } else {
          saveDistribute((prev) => ({
            ...prev,
            content: {
              ...prev?.content,
              [dist_type]: {
                ...prev?.content?.[dist_type],
                photo_url: urls?.[0] || '',
                image_upload: {
                  ...prev?.image_upload,
                  loading: false,
                  upload_url: urls?.[0] || '',
                },
              },
            },
          }))
        }
        if (urls?.[0]) {
          handle_msg('success_msg', 'New image uploaded!', true)
        } else {
          handle_msg('failure_msg', 'Failed to upload image!', true)
        }
      }
    }
  }

  return (
    <div>
      <p className='text-center font-semibold text-primary'>{title}</p>
      {![
        type === 'ai'
          ? ai?.image_upload?.upload_url ||
            ai?.image_upload?.gallery?.active?.url
          : content?.photo_url,
      ]?.[0] ? (
        <div>
          {![
            type === 'ai'
              ? ai?.image_upload?.loading
              : content?.image_upload?.loading,
          ]?.[0] && (
            <div className='flex justify-center'>
              {[
                type === 'ai'
                  ? ai?.image_upload?.status
                  : content?.image_upload?.status,
              ]?.[0] === 'done' ? (
                <>
                  <ImageUpload
                    name={'f_upload'}
                    handler={image_upload}
                    title={'Click/drag to upload your picture'}
                    subtitle={'PNG or JPG'}
                  />
                </>
              ) : content_upload_checker?.message?.includes('image') ? (
                <div
                  className={
                    uiStyles[
                      [
                        type === 'ai'
                          ? ai?.image_upload?.status
                          : content?.image_upload?.status,
                      ]?.[0]
                    ]
                  }
                >
                  {
                    [
                      type === 'ai'
                        ? ai?.image_upload?.message
                        : content?.image_upload?.message,
                    ]?.[0]
                  }
                </div>
              ) : (
                <>
                  <ImageUpload
                    name={'upload'}
                    handler={image_upload}
                    title={'Click/drag to upload your picture'}
                    subtitle={'PNG or JPG'}
                  />
                </>
              )}
            </div>
          )}
        </div>
      ) : (
        ''
      )}
      {[
        type === 'ai'
          ? ai?.image_upload?.loading
          : content?.image_upload?.loading,
      ]?.[0] ? (
        <div
          className={
            uiStyles[
              [
                type === 'ai'
                  ? ai?.image_upload?.status
                  : content?.image_upload?.status,
              ]?.[0]
            ]
          }
        >
          {
            [
              type === 'ai'
                ? ai?.image_upload?.message
                : content?.image_upload?.message,
            ]?.[0]
          }
        </div>
      ) : (
        <>
          {is_url ? (
            <div className='relative flex justify-center m-[10px]'>
              <img src={is_url} alt='upload' className='w-[250px]' />
              <div
                className='z-10 absolute top-[-12px] left-[18px] cursor-pointer m-[auto_0]'
                onClick={() => {
                  if (type === 'ai') {
                    saveAi((prev) => ({
                      ...prev,
                      image_upload: {
                        ...prev?.image_upload,
                        loading: false,
                        upload_url: '',
                        gallery: { ...prev?.image_upload.gallery, active: '' },
                      },
                    }))
                  } else {
                    saveDistribute((prev) => ({
                      ...prev,
                      content: {
                        ...prev?.content,
                        [dist_type]: {
                          ...prev?.content?.[dist_type],
                          photo_url: '',
                          image_upload: {
                            ...prev?.content?.[dist_type]?.image_upload,
                            loading: false,
                            upload_url: '',
                            gallery: {
                              ...prev?.content?.[dist_type]?.image_upload
                                ?.gallery,
                              active: '',
                            },
                          },
                        },
                      },
                    }))
                  }
                }}
              >
                <AiFillCloseCircle size={25} color={'gray'} />
              </div>
            </div>
          ) : (
            <PhotoGallery className='my-3' type={type} />
          )}
        </>
      )}
    </div>
  )
}
