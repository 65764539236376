import React from 'react'
import SubTitle from '../../ui/SubTitle'

import AxiomFacebookIcon from '../../ui/AxiomFacebookIcon'
import AxiomTwitterIcon from '../../ui/AxiomTwitterIcon'
import AxiomLinkedinIcon from '../../ui/AxiomLinkedinIcon'
import AxiomInstagramIcon from '../../ui/AxiomInstagramIcon'
import AxiomGoogleIcon from '../../ui/AxiomGoogleIcon'
import PrimaryButton from '../../ui/PrimaryButton'
import SocialAxiom from './SocialAxiom'
import { useDistribute } from '../../../contexts/DistributeContext'
import { useClaims } from '../../../contexts/ClaimsContext'
import uiStyles from '../../ui/uiStyles'
export default function Social({
  title = 'AXIOM Social Connections',
  subtitle = 'Which network would you like to post to?',
}) {
  const { distribute, saveDistribute } = useDistribute()
  const { claims } = useClaims()
  const socialSelectionHandler = (social) => {
    saveDistribute((prev) => ({
      ...prev,
      social: social,
    }))
  }

  return (
    <div>
      <SubTitle className='text-center'>{title}</SubTitle>
      <p className='text-center'>{subtitle}</p>
      <div className='my-5 socials flex justify-center gap-[20px] text-5xl cursor-pointer px-14'>
        <AxiomFacebookIcon
          selected={distribute?.social === 'facebook'}
          clickHandler={() => {
            socialSelectionHandler('facebook')
          }}
        />

        {/* <AxiomTwitterIcon
          selected={media === 'twitter'}
          clickHandler={() => {
            socialSelectionHandler('twitter', 'caption')}}
        /> */}
        {/* {claims?.is_internal !== 'Y' && (
          <AxiomInstagramIcon
            selected={distribute?.social === 'instagram'}
            clickHandler={() => socialSelectionHandler('instagram')}
          />
        )} */}
        {/* <AxiomLinkedinIcon
          selected={media === 'linkedin'}
          clickHandler={() => socialSelectionHandler('linkedin', 'caption')}
        /> */}
        {/* <AxiomGoogleIcon
          selected={media === 'google'}
          clickHandler={() => socialSelectionHandler('google', 'caption')}
        /> */}
      </div>
      {/* <SubTitle className='text-center'>
        Post To {distribute?.social || 'facebook'}
      </SubTitle> */}
      {/* <SocialAxiom /> */}
      <div className={uiStyles.line}></div>
    </div>
  )
}
