import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import images_logos from '../../assets/images/axiom_logos'
import { useAi } from '../../contexts/AiContext'
import { useClaims } from '../../contexts/ClaimsContext'
import { useDistribute } from '../../contexts/DistributeContext'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import { List } from '../helpers'
import { SmallLoadingRel } from '../ui/SmallLoadingRel'
import uiStyles from '../ui/uiStyles'

const InternalFacebook = () => {
  const { claims } = useClaims()
  const { saveAi } = useAi()
  const { saveDistribute } = useDistribute()
  const { axiomFetch } = useAxiomFetch()
  const navigate = useNavigate()
  const btmRef = useRef(null)
  const hitRef = useRef(false)
  const finalHitRef = useRef(false)
  const [hitLoading, setHitLoading] = useState(false)
  const loadAmount = 10
  const [fPosts, setFPosts] = useState([])
  const [showPostCount, setShowPostCount] = useState(0)

  useEffect(() => {
    const controller = new AbortController()
    const get_int_fposts = async () => {
      if (finalHitRef.current) {
        return
      }
      let f_posts
      try {
        console.log('post_count', showPostCount)
        f_posts = await axiomFetch(
          `/social/facebook/posts_internal?offset=${showPostCount}&limit=${loadAmount}`,
          'GET',
          {},
          {},
          false,
          controller.signal
        )
      } catch (error) {
        console.log(error)
      }
      if (f_posts?.length) {
        f_posts = f_posts?.map((item) => {
          item.title = item?.NAME
          item.image = item?.PHOTO
          item.content = item?.MESSAGE?.includes(':')
            ? item?.MESSAGE?.split(':')?.slice(1)?.join(':')
            : item?.MESSAGE
          item.swipe_right = () => {
            return (
              <div>
                <a
                  className='cursor-pointer'
                  href='https://www.facebook.com/driggsinsider'
                  target='_blank'
                >
                  View
                </a>
              </div>
            )
          }
          return item
        })
        setFPosts((prev) => [...prev, ...f_posts])
      } else {
        if (fPosts?.length) {
          finalHitRef.current = true
        }
      }
      hitRef.current = false
      setHitLoading(false)
    }
    get_int_fposts()
    return () => {
      controller?.abort()
    }
    // eslint-disable-next-line
  }, [showPostCount])

  useEffect(() => {
    const targetElement = btmRef.current

    if (!targetElement) return

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry?.isIntersecting) {
            if (
              !hitRef?.current &&
              !finalHitRef?.current &&
              claims?.is_internal === 'Y'
            ) {
              setShowPostCount((prev) => prev + loadAmount)
              hitRef.current = true
              setHitLoading(true)
            }
            // Perform your desired action here
          }
        })
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.9,
      }
    )

    observer.observe(targetElement)

    // Clean up the observer when the component unmounts
    return () => {
      observer.unobserve(targetElement)
    }
  }, [])

  return (
    <div>
      <div className=''>
        <div className='flex justify-center'>
          <img className='' src={images_logos.driggs_insider_logo} />
        </div>
        <div className='w-full py-[20px] px-[24px]'>
          <button
            className={`${uiStyles.green_btn} w-full text-[16px]  py-[12px] px-[20px]`}
            onClick={() => {
              saveAi((prev) => ({
                ...prev,
                assistant: { ...prev?.assistant, is_assistant: 'N' },
                type: 'social',
                social: 'facebook',
                state: {
                  ...prev?.state,
                  message: '',
                  long_message: '',
                  short_message: '',
                  subject: '',
                  original_prompt: '',
                  last_prompt: '',
                  prompt: '',
                  // stopHeight: 500 * height_change,
                  isLoading: false,
                  run: false,
                },
                image_upload: {
                  ...prev?.image_upload,
                  upload_url: '',
                  gallery: {
                    ...prev?.image_upload?.gallery,
                    active: {
                      ...prev?.image_upload?.gallery?.active,
                      url: '',
                    },
                  },
                },
                // ai?.image_upload?.upload_url || ai?.image_upload?.gallery?.active?.url
              }))
              saveDistribute((prev) => ({
                ...prev,
                content: {
                  ...prev.content,
                  facebook: {
                    ...(prev?.content?.facebook || {}),
                    message: '',
                    long_message: '',
                    short_message: '',
                    posted_id: '',
                    photo_url: '',
                  },
                },
              }))
              navigate('/user/axiom-ai', {
                state: {
                  parent: '/user/internal/facebook',
                  prevParent: '/user',
                },
              })
            }}
          >
            Create A Post
          </button>
        </div>
        <p className='mt-[10px] mb-[5px] px-4 text-[#344054] text-[14px]'>
          The Latest Employee Posts
        </p>
      </div>
      {!fPosts?.length ? <SmallLoadingRel /> : <List list={fPosts} />}
      <div
        ref={btmRef}
        className={`${hitLoading || !fPosts?.length ? 'hidden' : 'h-[3px]'}`}
      ></div>
      {hitLoading && claims?.is_internal === 'Y' ? <SmallLoadingRel /> : ''}
      {finalHitRef?.current && claims?.is_internal === 'Y' && (
        <div className='center'>That's all the posts!</div>
      )}
    </div>
  )
}

export default InternalFacebook
