import React, { useCallback, useEffect, useRef, useState } from 'react'
import Wrapper from '../../layout/Wrapper'
import DocumentItem from '../../ui/DocumentItem'
import SubNav from '../../SubNav'
import ProgressBar from '../../ui/ProgressBar'

import * as numberToWords from 'number-to-words'
import { useNavigate, useParams } from 'react-router'
import TransactionsMenu from '../../ui/TransactionsMenu'
import { useAuth0 } from '@auth0/auth0-react'
import { ServiceTransactions } from '../../../services/ServiceTransactions'
import { useQuery } from '@tanstack/react-query'
import { useProfile } from '../../../contexts/UserContext'
import { useTransactions } from '../../../contexts/TransactionsContext'
import { getPercentage } from '../../../helpers'
import { useDocuments } from '../../../contexts/DocumentsContext'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import SmallLoading from '../../ui/SmallLoading'
export default function Documents() {
  const { axiomFetch } = useAxiomFetch()
  const navigate = useNavigate()
  const { fileid } = useParams()
  const { profile } = useProfile()
  const [fileData, setFileData] = useState()
  const { getAccessTokenSilently } = useAuth0()
  const TransactionsfetchController = useRef(null)
  const { getTransactionByID } = useTransactions()
  const { fileid: saved_file_id, saveFileID, saveDocuments } = useDocuments()
  const currentTransaction = getTransactionByID(fileid)
  const { open_date, close_disb_dt } = currentTransaction || {}

  const getDocuments = useCallback(async () => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUDIENCE,
    }).catch((err) => null)

    const serviceTransactions = new ServiceTransactions(token)
    if (!TransactionsfetchController.current) {
      TransactionsfetchController.current =
        serviceTransactions.getController().controller
    }
    return serviceTransactions.get(fileid, '/documents')
  }, [fileid, getAccessTokenSilently])

  const documentQuery = useQuery({
    queryKey: ['documents', fileid],
    queryFn: getDocuments,
    refetchOnWindowFocus: false,
    enabled: !!profile && !!fileid,
  })

  const onClickDocument = (docId) => {
    navigate(`${docId}`)
  }
  const documentList =
    documentQuery?.data?.length &&
    documentQuery?.data?.map((item, index) => (
      <DocumentItem
        documentFile={item?.DOC_LINK}
        className='mb-2'
        key={item?.FILE_DOCUMENT_ID}
        buttonLabel={`${item?.DOC_NAME?.split(' ')[0]} ${
          item?.DOC_NAME?.split(' ')[1]
        }`}
        clickHandler={onClickDocument.bind(this, item?.FILE_DOCUMENT_ID)}
      />
    ))

  useEffect(() => {
    if (documentQuery.data && fileid !== saved_file_id) {
      saveDocuments(documentQuery.data)
      saveFileID(fileid)
    }
    return () =>
      TransactionsfetchController.current &&
      TransactionsfetchController.current.abort()
  }, [saveDocuments, documentQuery.data, fileid, saved_file_id, saveFileID])

  // useEffect(() => {
  //   const get_data = async () => {
  //   const data = await axiomFetch(`/escrow/transaction/${fileid}`)
  //   console.log(data)
  //   setFileData(data)
  //   }
  //   get_data()
  // }, [])
  console.log(documentQuery.data)
  return (
    <>
      {documentQuery?.isLoading ? (
        <SmallLoading />
      ) : (
        <>
          <div className=''>{documentList}</div>
        </>
      )}
    </>
  )
}
