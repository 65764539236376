import React, { useState } from 'react'
import { AiOutlineCopy } from 'react-icons/ai'
import { useAi } from '../../../contexts/AiContext'
import { useLimits } from '../../../contexts/LimitsContext'
import { check_prompt, fix_response } from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import SmallLoading from '../../ui/SmallLoading'
import uiStyles from '../../ui/uiStyles'

export const AskMulti = ({
  name,
  message_val,
  new_rewrites,
  rewrites,
  copy_text,
  handle_change,
  save_field,
  type,
  mkt_item,
}) => {
  const { ai, saveAi } = useAi()
  const { axiomFetch } = useAxiomFetch()
  const { limits, saveLimits } = useLimits()
  const [customRewrite, setCustomRewrite] = useState(false)
  const [ask, setAsk] = useState({})

  const ai_response = async ({ prompt, model, signal }) => {
    setAsk((prev) => ({ ...prev, isLoading: true }))
    const mkt_rewrite_orig = rewrites?.find(
      (item) => item.option_word?.toLowerCase() === prompt?.toLowerCase()
    )
    const mkt_rewrite_new = new_rewrites?.find(
      (item) => item?.ai_option_id === mkt_rewrite_orig?.option_id
    )
    console.log('new_rewrite', mkt_rewrite_new)
    let the_prompts = []
    if (!message_val) {
      the_prompts = [
        `make a headline for this prompt: ${prompt}`,
        // `write about 350 words for this prompt: ${prompt}`,
        `write about 50 words for this prompt: ${prompt}`,
      ]
    } else {
      the_prompts = [
        `${(mkt_rewrite_new || {})?.prpt_text || prompt}: ${
          ask?.headline || message_val
        }`,
        // `${prompt}: ${ask?.long_completion || message_val}`,
        `${(mkt_rewrite_new || {})?.prpt_text || prompt}: ${
          type === 'mkt_materials'
            ? message_val || ask?.short_completion
            : ask?.short_completion || message_val
        }`,
      ]
    }

    console.log('prompts', the_prompts)
    model = 'gpt-4o'
    // if (!model) {
    //   const check_model = await check_prompt(
    //     ask?.prompt || '',
    //     'gpt-4o',
    //     ai?.ai_prompt_options || {}
    //   )
    //   if (check_model === 'text-curie-001') {
    //     model = 'text-curie-001'
    //   } else {
    //     model = check_model
    //   }
    // }
    const all_results_map = the_prompts?.map(async (item) => {
      const body = {
        messages: [
          {
            role: 'user',
            content: item,
          },
        ],
        model,
      }
      console.log(body)
      return await axiomFetch(
        '/marketing/content/ai/chat',
        'POST',
        body,
        {},
        false,
        signal
      )
    })
    const data = await Promise.all(all_results_map)
    console.log(data)
    const headline = fix_response(data?.[0]?.post, model, prompt)
    const long_completion = fix_response(
      data?.[1]?.post || data?.[2]?.post,
      model
    )
    const short_completion = fix_response(
      data?.[2]?.post || data?.[1]?.post,
      model,
      prompt
    )

    const new_limits = await axiomFetch(
      '/marketing/content/limits',
      'GET',
      {},
      {},
      false,
      signal
    )
    saveLimits(new_limits)
    setAsk((prev) => ({
      ...prev,
      isLoading: false,
      last_prompt: prompt,
      headline,
      short_completion,
      long_completion,
    }))
    handle_change({ name, headline, short_completion, long_completion })
  }

  return (
    <div className='mb-5'>
      {/* <Input label="Re-Write with AXIOM Ai" type="text" name="prompt" /> */}
      {message_val && !customRewrite ? (
        <div>
          <select
            className={`${uiStyles.select}`}
            name='ai_options'
            id='ai_options'
            onChange={async (e) => {
              if (e.target.value === 'custom') {
                setCustomRewrite(true)
              } else {
                await ai_response({ prompt: e.target.value })
              }
            }}
          >
            <option key={'select a re-write option'} value=''>
              select a re-write option...
            </option>
            {Object.entries(ai?.ai_rewrite_options || {}).map((item) => {
              return item[1].map((option) => {
                return (
                  <option
                    key={option}
                    className={`${uiStyles.option}`}
                    value={option}
                  >
                    {option}
                  </option>
                )
              })
            })}
            <option key={'custom'} value='custom'>
              custom
            </option>
          </select>
          <div className='w-full flex justify-center'>
            {ask?.isLoading ? (
              <div className='w-4 h-4 relative'>
                <span className=' animate-ping absolute inline-flex h-full w-full rounded-full bg-gradient-to-b from-accent-150 to-accent-400 opacity-75'></span>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      ) : (
        <div className='relative rounded-md border border-gray-200'>
          <textarea
            disabled={ask?.isLoading}
            className={`max-w-[270px] leading-4 rounded-md resize-none w-full p-5 ${
              message_val ? 'pr-10' : 'pr-8'
            }  h-28 outline-none italic text-sm`}
            placeholder={message_val ? 'i.e. make it funny...' : ''}
            //ref={aiBoxRef_2}
            //label="Re-Write"
            //fieldname={'contentRequest'}
            value={ask?.prompt}
            onChange={async (e) => {
              setAsk((prev) => ({
                ...prev,
                prompt: e.target.value,
              }))
            }}
            onKeyUp={async (e) => {
              if (e.key === 'Enter') {
                setAsk((prev) => ({
                  ...prev,
                  prompt: e.target.value,
                }))
                if (!message_val) {
                  setCustomRewrite(false)
                }
                await ai_response({ prompt: e.target.value })
              }
            }}
          />
          <div className='flex justify-end gap-[10px]'>
            <button
              disabled={ask?.isLoading}
              className={`absolute right-0 top-0 border-l border-b py-2 ${
                message_val ? 'px-3' : 'px-3'
              } text-sm `}
              onClick={async () => {
                if (!message_val) {
                  setCustomRewrite(false)
                }
                await ai_response({ prompt: ask?.prompt || '' })
              }}
            >
              {message_val ? 'Re-Write' : 'Ask'}
            </button>
            {ask?.isLoading && <SmallLoading />}
          </div>
        </div>
      )}
      {message_val ? (
        <div className='flex justify-end mt-[5px]'>
          <button
            className={`${uiStyles.hover_btn}`}
            onClick={async () => {
              setCustomRewrite(false)
              handle_change({ name, reset: true })
              setAsk((prev) => ({ ...prev, prompt: '' }))
              if (type === 'mkt_materials') {
                save_field(name, '')
              }
            }}
          >
            {type === 'mkt_materials' ? 'Clear Text' : 'Reset'}
          </button>
        </div>
      ) : (
        <>
          <p className='text-xs font-semibold'>
            What topic would you like to use?
          </p>
          <span>Example: </span>
          <p className='text-xs italic mb-5 flex justify-between'>
            {copy_text}
            <span
              onClick={async (e) => {
                let content = e.currentTarget?.parentElement?.textContent
                setAsk((prev) => {
                  return {
                    ...prev,
                    prompt: content,
                  }
                })
              }}
              className='flex justify-end cursor-pointer'
            >
              <AiOutlineCopy size={25} />{' '}
            </span>
          </p>
        </>
      )}
    </div>
  )
}
