import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

export const ReviewsContext = createContext();

export const ReviewsProvider = ({ children, userData }) => {
  const [reviews, setReviews] = useState(null);

  const saveReviews = useCallback((data) => {
    setReviews(data);
  }, []);

  const getReviewsByID = useCallback(
    (id) => {
      if (!reviews) {
        return null;
      }
      return reviews.find((item, index) => {
        return +item.file_id === +id;
      });
    },

    [reviews]
  );

  const value = useMemo(
    () => ({
      reviews,
      saveReviews,

      getReviewsByID,
    }),
    [reviews, saveReviews, getReviewsByID]
  );

  return (
    <ReviewsContext.Provider value={value}>{children}</ReviewsContext.Provider>
  );
};

export const useReviews = () => {
  const context = useContext(ReviewsContext);

  if (!context) {
    throw Error('ReviewsContext must be used inside an ReviewsContextProvider');
  }

  return context;
};
