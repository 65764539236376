import { useState, useEffect } from 'react'
import RealtorLogo from '../../../assets/images/rrealtor.png'
// import OpenEscrow from '../../../assets/images/openescrow.png';
// import MLSDate from '../../../assets/images/mlsdata.jpg';
import ButtonOff from './../../ui/ButtonOff'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import SmallLoading from '../../ui/SmallLoading'
import uiStyles from '../../ui/uiStyles'
import { useNavigate } from 'react-router-dom'
import { useAi } from '../../../contexts/AiContext'
import { useDistribute } from '../../../contexts/DistributeContext'
import { content_types } from '../../../data/data'
export default function MLSView({
  mlsSearch,
  mlsMatch,
  setMlsMatch,
  modalType,
  setModalType,
  searchTitle = 'Open Escrow',
}) {
  const { axiomFetch } = useAxiomFetch()
  const navigate = useNavigate()
  const { saveAi } = useAi()
  const { distribute, saveDistribute } = useDistribute()
  const dist_type =
    (distribute?.type === 'social' ? distribute?.social : distribute?.type) ||
    'facebook'
  const content = distribute?.content?.[dist_type]
  const [isLoading, setIsLoading] = useState(true)
  const [openFile, setOpenFile] = useState(false)
  const [openFileId, setOpenFileId] = useState(null)
  useEffect(() => {
    console.log('search', mlsMatch)
    const get_match = async () => {
      setIsLoading(true)
      setMlsMatch({})
      let match = await axiomFetch(`/client/listings/active/${mlsSearch || 0}`)
      console.log('match', match)
      let check = await axiomFetch('/escrow/transaction?filter=open&limit=1000')
      // eslint-disable-next-line
      check = check.find((transaction) => {
        if (
          transaction.close_disb_dt === null &&
          transaction.cancel_dt === null
        ) {
          return (
            (match[0]?.address1 || match[0]?.prop_add) === transaction?.address1
          )
        } else {
          return false
        }
      })
      console.log(check)
      if (match && match?.length > 0) {
        setMlsMatch(match[0])
      }
      if (check?.file_id) {
        setOpenFile(true)
        setOpenFileId(check.file_id)
      }

      setIsLoading(false)
    }
    get_match()
    // eslint-disable-next-line
  }, [])

  if (isLoading) {
    return <SmallLoading />
  }
  return (
    <>
      {mlsMatch?.mls_id ? (
        <div className='flex flex-col'>
          <p className='text-center font-semibold mb-[5px]'>{searchTitle}</p>
          <p className='text-center text-gray-700'>Is this your listing?</p>
          <p className='text-center text-gray-700'>{`MLS #${mlsSearch}`}</p>
          <img src={RealtorLogo} alt='' className='block mx-auto w-[140px]' />
          <img
            src={mlsMatch?.home_photo1}
            alt=''
            className='block mx-auto w-[200px] max-h-[100px] object-cover'
          />
          {/* add rest of information */}
          <div className='ml-[15px] mb-[5px] w-[270px] text-xs mt-[20px]'>
            <div className='flex w-[200px] gap-3'>
              <p>Presented By:</p>
              <div className=''>
                <p>
                  {mlsMatch?.list_agent_name ||
                    mlsMatch?.co_list_agent_name ||
                    ''}
                </p>
                <p>
                  {mlsMatch?.list_office_name ||
                    mlsMatch?.co_list_office_name ||
                    ''}
                </p>
              </div>
            </div>

            <div className='flex mt-[10px]'>
              <div className='w-[120px]'>
                <p>{mlsMatch?.property_class}</p>
                <p>MLS#: {mlsMatch?.mls_id}</p>
              </div>
              <div>
                <p>Status: {mlsMatch?.mls_status || ''}</p>
                <p>Area: {mlsMatch?.sqft}</p>
              </div>
            </div>

            <div className='flex'>
              <div className=''>
                <p>
                  List Price:{' '}
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }).format(mlsMatch?.list_price)}
                </p>
                <p>Addr: {mlsMatch?.prop_add}</p>
              </div>
            </div>

            <div className='flex'>
              <div className='w-[120px]'>
                <p>City: {mlsMatch?.prop_city}</p>
                <p>ZIP: {mlsMatch?.prop_zip}</p>
              </div>
              <div>
                <p>ZIP: {mlsMatch?.prop_zip}</p>
                <p>Area: {mlsMatch?.sqft}</p>
                <p></p>
              </div>
            </div>

            <div className='flex'>
              <div className=''>
                <p>
                  Map Coord: {mlsMatch?.lat?.toFixed(6)}/
                  {mlsMatch?.lng?.toFixed(6)}
                </p>
              </div>
            </div>

            {/* <div className='flex'>
          <div className='w-[120px]'>
            <p>County: {mlsMatch?.COUNTY_CODE}</p>
            <p>ELEM: {mlsMatch?.ELEMENTARY_SCHOOL}</p>
            <p>HIGH: {mlsMatch?.HIGH_SCHOOL}</p>
          </div>
          <div>
            <p>Tax Id: {mlsMatch?.ASSESSOR_NUMBER}</p>
            <p>MIDDLE: {mlsMatch?.JR_HIGH_SCHOOL}</p>
            <p></p>
          </div>
        </div> */}
          </div>

          {!openFile ? (
            <div className='flex flex-row-reverse mt-3'>
              <div className='ml-3'>
                <button
                  className={uiStyles.hover_btn}
                  onClick={async () => {
                    saveAi((prev) => {
                      return {
                        ...prev,
                        image_upload: {
                          ...prev?.image_upload,
                          gallery: {
                            ...prev?.image_upload?.gallery,
                            active: mlsMatch?.home_photo1
                              ? { url: mlsMatch?.home_photo1 }
                              : { url: '' },
                          },
                        },
                        mls: {
                          ...prev?.mls,
                          mlsMatch: { ...mlsMatch },
                          add_mls_to_prompt: (prev?.add_mls_to_prompt || 0) + 1,
                        },
                      }
                    })

                    let distribute_content = distribute?.content || {}

                    for (const content_type of content_types) {
                      distribute_content[content_type] = {
                        ...distribute_content?.[content_type],
                        photo_url: mlsMatch?.home_photo1
                          ? mlsMatch?.home_photo1
                          : '',
                        image_upload: {
                          ...distribute_content?.[content_type]?.image_upload,
                          gallery: {
                            ...distribute_content?.[content_type]?.image_upload
                              ?.gallery,
                            active: mlsMatch?.home_photo1
                              ? { url: mlsMatch?.home_photo1 }
                              : { url: '' },
                          },
                        },
                      }
                    }
                    saveDistribute((prev) => ({
                      ...prev,
                      content: distribute_content,
                    }))

                    setModalType('mlsConfirm')
                  }}
                >
                  Yes
                </button>
              </div>
              <div>
                <ButtonOff clickHandler={() => setModalType('openEscrow')}>
                  No
                </ButtonOff>
              </div>
            </div>
          ) : (
            <div>
              <p className='text-sm text-center'>
                Looks like a file is already open!{' '}
              </p>
              <div className='flex mt-1 gap-3 justify-end'>
                <ButtonOff clickHandler={() => setModalType('openEscrow')}>
                  Search
                </ButtonOff>
                <button
                  className={`${uiStyles.hover_btn}`}
                  onClick={() => {
                    navigate(
                      `/user/my-transactions-lobby/transactions/${openFileId}/statuses`
                    )
                  }}
                >
                  See Files
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          We couldn't find a match with your search. Please try again or submit
          a bug report.
          <div className='flex justify-end mb-[5px]'>
            {/* {searchTitle !== 'Open Escrow' && ( */}
            <button
              className={uiStyles.white_btn}
              onClick={() => setModalType('openEscrow')}
            >
              Back
            </button>
            {/* )} */}
          </div>
        </div>
      )}
    </>
  )
}
