import uiStyles from './ui/uiStyles'
const Limits = () => {
  return (
    <div className='flex flex-col items-center my-[20px]'>
      <h1 className={`${uiStyles.text_black_subtitle}`}>
        You've reached your limit!
      </h1>
      <h1 className={`${uiStyles.text_black_subtitle} mt-[10px]`}>
        Great Job!
      </h1>
      <p className='text-[14px] text-[#F8097C] text-center mt-[10px]'>
        Your account will reset on the first of each month.
      </p>
    </div>
  )
}

export default Limits
