import React, { useState } from 'react'
import { AiFillClockCircle, AiFillCloseCircle } from 'react-icons/ai'
import { HiOutlineLockClosed } from 'react-icons/hi2'
import { useNavigate } from 'react-router-dom'
import { useClaims } from '../../../contexts/ClaimsContext'
import { useProfile } from '../../../contexts/UserContext'
import { formatPhoneNumber, format_currency } from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import ImageUpload from '../../form/ImageUpload'
import LabelInput from '../../form/LabelInput'
import Modal from '../../Modal'
import ButtonsRow from '../../ui/ButtonsRow'
import uiStyles from '../../ui/uiStyles'
import { AskAxiomMulti } from '../AI/AskAxiomMulti'
import MarketingMaterialPhotoPicker from './MarketingMaterialPhotoPicker'

export const MarketingMaterialEdit = ({
  state,
  setState,
  showModal,
  setModalType,
  setShowModal,
  modalType,
}) => {
  const { axiomFetch } = useAxiomFetch()
  const { claims } = useClaims()
  const { saveProfile } = useProfile()
  const navigate = useNavigate()
  const [messageSent, setMessageSent] = useState([])
  const empty_fields = state?.create_order?.session?.empty_fields
  const selected_fields = state?.create_order?.selected_fields
  const sessions = state?.create_order?.sessions
  const session = state?.create_order?.session
  let fields = session?.sess_info?.filter(
    (field_item) => field_item.field_id > 0
  )
  if (empty_fields) {
    if (sessions?.length > 1) {
      let all_fields = sessions.reduce((curr, val) => {
        return [...curr, ...(val?.sess_info || [])]
      }, [])
      fields = (all_fields || []).reduce((curr, val) => {
        const check_val = curr.find((item) => item.field_id === val.field_id)
        if (!check_val) {
          curr.push(val)
        }
        return curr
      }, [])
      fields = (fields || [])?.filter(
        (field_item) =>
          field_item.field_id > 0 && field_item?.allow_edit === 'Y'
      )
    }
  }
  const field_type = state?.create_order?.edit_fields_type || 'data'
  const handle_msg = (status, message, type, close) => {
    let messages = messageSent
    const new_message = messages?.findIndex(
      (item) => item?.type?.toString() === type?.toString()
    )
    if (new_message === -1) {
      messages.push({ status, message, type })
    } else {
      messages[new_message].status = status
      messages[new_message].message = message
    }
    setMessageSent([...messages])

    if (close >= 0) {
      setTimeout(() => {
        const curr_msg_index = messages?.findIndex(
          (item) => item?.type?.toString() === type?.toString()
        )
        messages[curr_msg_index].status = 'done'
        messages[curr_msg_index].message = ''
        setMessageSent([...messages])
      }, close)
    }
  }

  const save_field = (name, value) => {
    const field_index = fields.findIndex(
      (item) => item?.field_id?.toString() === name?.toString()
    )
    const field = fields[field_index]
    const format = (val) => {
      if (
        field?.phone_format === 'Y' ||
        field?.prompt_val === 'Agent Cell Phone'
      ) {
        return formatPhoneNumber(val)
      } else if (
        field?.dollar_format === 'Y' ||
        field?.prompt_val === 'List Price'
      ) {
        return format_currency(val, false)
      } else {
        return val
      }
    }
    fields[field_index].saved_val = format(value)

    for (const session of sessions) {
      for (const info of session?.sess_info) {
        if (info?.field_id === field?.field_id) {
          info.saved_val = value
        }
      }
    }
    const curr_session = sessions.find(
      (sess_item) => sess_item?.sess_id === session?.sess_id
    )
    setState((prev) => ({
      ...prev,
      field_changed: true,
      create_order: {
        ...prev?.create_order,
        session: { ...curr_session },
        sessions: [...sessions],
      },
    }))
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    save_field(name, value)
  }
  const field_select = () => {
    let filtered_fields = fields

    if (empty_fields) {
      filtered_fields = filtered_fields?.filter(
        (item) => !item?.orig_val && item?.field_id
      )
      if (fields?.find((item) => item?.field_id === 30063)?.orig_val) {
        filtered_fields = filtered_fields?.filter(
          (item) => item?.field_id !== 30064
        )
      }
    }
    if (selected_fields) {
      filtered_fields = filtered_fields?.filter((item) =>
        selected_fields?.includes(item?.field_id?.toString())
      )
    }
    if (field_type === 'data')
      filtered_fields = filtered_fields?.filter(
        (item) => item?.image_field !== 'Y' && item?.ai_field !== 'Y'
      )
    if (field_type === 'images') {
      filtered_fields = filtered_fields?.filter(
        (item) => item?.image_field === 'Y'
      )
    }
    if (field_type === 'ai_text') {
      filtered_fields = filtered_fields?.filter(
        (item) => item?.ai_field === 'Y'
      )
    }
    return filtered_fields?.filter((item) => item?.allow_edit === 'Y')
  }

  const image_upload = async (e) => {
    e.preventDefault()
    let limit = 1
    let total =
      (e?.target?.files?.length || 0) + (e?.dataTransfer?.files?.length || 0)
    let files = e?.target?.files || e?.dataTransfer?.files
    const name = e?.target?.name || e?.target?.getAttribute('name')
    if (total > limit) {
      handle_msg('failure_msg', `Max image limit is ${limit} file.`, name, 3000)
      return
    }

    if (files?.length) {
      for (const file of files) {
        if (file.size > 10e6) {
          handle_msg('failure_msg', `Max image is is 10MB`, name, 3000)
          return
        }
        const body = { files: [{ file: file, url: URL.createObjectURL(file) }] }

        handle_msg('loading_msg', 'uploading new image...', name)
        const urls = await axiomFetch(
          '/social/facebook/upload_images',
          'POST',
          body,
          {},
          true
        )
        save_field(name, urls?.[0])

        if (urls?.[0]) {
          if (name?.toString() === '698') {
            const body = {
              photo: urls?.[0],
              type: 'profile',
              is_internal: claims?.is_internal || 'N',
            }
            saveProfile((prev) => ({ ...prev, PHOTO_LINK: urls?.[0] }))
            await axiomFetch('/client/uploadPhotoUrl', 'POST', body, {}, false)
          } else if (name?.toString() === '702') {
            const body = {
              photo: urls?.[0],
              type: 'logo',
              is_internal: claims?.is_internal || 'N',
            }
            saveProfile((prev) => ({ ...prev, LOGO_LINK: urls?.[0] }))
            await axiomFetch('/client/uploadPhotoUrl', 'POST', body, {}, false)
          }
          handle_msg('done', '', name, 3000)
        } else {
          handle_msg('done', '', name, 3000)
        }
      }
    }
  }

  const add_mls_photo = (item) => {
    setState((prev) => ({
      ...prev,
      create_order: {
        ...prev?.create_order,
        selected_image: item,
      },
    }))
    setModalType('picker')
  }

  return (
    <div>
      {empty_fields ? (
        <p className={`${uiStyles.text_black_title} mb-[10px] px-3`}>
          Missing Fields
        </p>
      ) : (
        ''
      )}
      <div className='px-3 mb-[10px]'>
        <ButtonsRow
          items={['data', 'ai_text', 'images']}
          handler={(item) => {
            setState((prev) => ({
              ...prev,
              create_order: { ...prev.create_order, edit_fields_type: item },
            }))
          }}
          selected={state?.create_order?.edit_fields_type || 'data'}
          button_style={'text-[12px] pl-2'}
        />
      </div>

      <div
        className={`flex flex-col px-3 pb-[20px] max-h-[600px] w-[305px] ${
          empty_fields ? 'overflow-y-scroll' : ''
        }`}
      >
        {field_select()?.length ? (
          field_select()?.map((item) => {
            if (item?.ai_field === 'Y') {
              return (
                <div key={item?.field_id} className='mt-[10px]'>
                  <AskAxiomMulti
                    type={'mkt_materials'}
                    save_field={save_field}
                    mkt_item={item}
                    new_rewrites={state?.materials?.ai_rewrit_prpt_fields?.filter(
                      (prpt) => prpt.field_id === item.field_id
                    )}
                    rewrites={state?.materials?.axiom_ai_options}
                    message_val={item?.saved_val || ''}
                    name={item?.field_id}
                    message_label={item?.prompt_val}
                    handle_change={(data) => {
                      if (data?.reset) {
                        save_field(item?.field_id, item?.orig_val)
                        return
                      }
                      save_field(item?.field_id, data?.short_completion)
                    }}
                    handle_message={(e) => {
                      save_field(e.target.name, e.target.value)
                    }}
                  />
                  {!item?.saved_val && item?.orig_val ? (
                    <div
                      className='text-blue-500 cursor-pointer underline'
                      onClick={() => {
                        save_field(item?.field_id, item?.orig_val)
                      }}
                    >
                      Reset to original
                    </div>
                  ) : (
                    ''
                  )}
                  <div className={uiStyles.line_3px}></div>
                </div>
              )
            }
            if (item?.image_field === 'Y') {
              if (item?.saved_val) {
                return (
                  <div className='mt-[20px]' key={item?.field_id}>
                    <div className='flex justify-between'>
                      <label
                        className={`${uiStyles.text_black} text-[16px] whitespace-nowrap`}
                      >
                        {item?.prompt_val}
                      </label>
                      {item?.mls_photo === 'Y' ? (
                        <button
                          className={`${uiStyles.hover_btn_small}`}
                          onClick={() => add_mls_photo(item)}
                        >
                          Switch MLS Photo
                        </button>
                      ) : (
                        ''
                      )}
                    </div>
                    <div
                      key={item?.field_id}
                      className='flex relative mt-[10px]'
                    >
                      <div
                        className='absolute left-[-10px] top-[-10px] cursor-pointer'
                        onClick={() => {
                          save_field(item?.field_id, '')
                        }}
                      >
                        <AiFillCloseCircle size={25} color={'gray'} />
                      </div>
                      <img
                        src={item?.saved_val}
                        className='object-cover w-[280px] h-[200px]'
                      />
                    </div>
                  </div>
                )
              } else {
                return (
                  <div key={item?.field_id}>
                    {messageSent?.some((item_msg) => {
                      return (
                        item_msg?.type?.toString() ===
                          item?.field_id?.toString() &&
                        item_msg?.status !== 'done'
                      )
                    }) ? (
                      <div
                        key={item?.field_id}
                        className={`${
                          uiStyles[
                            messageSent?.find(
                              (item_msg) =>
                                item_msg?.type?.toString() ===
                                item?.field_id?.toString()
                            )?.status
                          ]
                        } mb-[20px]`}
                      >
                        {
                          messageSent.find(
                            (item_msg) =>
                              item_msg?.type?.toString() ===
                              item?.field_id?.toString()
                          )?.message
                        }
                      </div>
                    ) : (
                      <div key={item?.field_id} className='mt-[20px]'>
                        {item?.orig_val ? (
                          <div
                            className='text-blue-500 cursor-pointer underline'
                            onClick={() => {
                              save_field(item?.field_id, item?.orig_val)
                            }}
                          >
                            Reset to original
                          </div>
                        ) : (
                          <></>
                        )}

                        <div>
                          <div className='flex justify-between'>
                            <label
                              className={`${uiStyles.text_black} text-[16px] whitespace-nowrap`}
                            >
                              {item?.prompt_val}
                            </label>
                            {item?.mls_photo === 'Y' ? (
                              <button
                                className={`${uiStyles.hover_btn_small}`}
                                onClick={() => add_mls_photo(item)}
                              >
                                Add MLS Photo
                              </button>
                            ) : (
                              ''
                            )}
                          </div>
                          <ImageUpload
                            name={item?.field_id}
                            handler={image_upload}
                            title={'Click/drag to upload'}
                            subtitle={'PNG SVG, or JPG'}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )
              }
            }
            return (
              <div key={item?.field_id}>
                {item?.orig_val !== item?.saved_val && item?.orig_val ? (
                  <div
                    className='text-blue-500 cursor-pointer underline'
                    onClick={() => {
                      save_field(item?.field_id, item?.orig_val)
                    }}
                  >
                    Reset to original
                  </div>
                ) : (
                  <></>
                )}
                <LabelInput
                  top_label={`${item?.prompt_val} ${
                    item?.field_id === 30064 ? '(optional)' : ''
                  }`}
                  name={item?.field_id}
                  value={item?.saved_val || ''}
                  handleChange={handleChange}
                />
              </div>
            )
          })
        ) : (
          <div className=''>No Fields for your selection</div>
        )}
      </div>
      <div>
        {messageSent?.some(
          (item) =>
            item?.type?.toString() === 'empty_fields' && item?.status !== 'done'
        ) ? (
          <div
            className={`${
              uiStyles[
                messageSent?.find(
                  (item) => item?.type?.toString() === 'empty_fields'
                ).status
              ]
            } mb-[20px]`}
          >
            {
              messageSent?.find(
                (item) => item?.type?.toString() === 'empty_fields'
              ).message
            }
          </div>
        ) : (
          <div>
            {empty_fields ? (
              <div className='flex justify-end pb-[20px] gap-[10px]'>
                <button
                  className={uiStyles.white_btn}
                  onClick={() => {
                    navigate(-1)
                  }}
                >
                  Cancel
                </button>
                <button
                  className={uiStyles.hover_btn}
                  data-cy='edit_next'
                  onClick={() => {
                    let data_fields_type = 'data'
                    if (
                      (fields || [])
                        ?.filter(
                          (item) =>
                            item?.field_id !== 30064 &&
                            item?.field_id !== 55779 &&
                            item?.allow_edit === 'Y'
                        )
                        ?.some((item) => !item?.saved_val)
                    ) {
                      let empty_fields = fields?.filter(
                        (item) =>
                          !item?.saved_val &&
                          item?.field_id !== 55779 &&
                          item?.field_id !== 30064 &&
                          item?.allow_edit === 'Y'
                      )
                      if (empty_fields.some((item) => item.ai_field === 'Y')) {
                        data_fields_type = 'ai_text'
                      } else if (
                        empty_fields.some((item) => item.image_field === 'Y')
                      ) {
                        data_fields_type = 'images'
                      } else {
                        data_fields_type = 'data'
                      }

                      setState((prev) => ({
                        ...prev,
                        create_order: {
                          ...prev?.create_order,
                          edit_fields_type: data_fields_type,
                          session: {
                            ...prev?.create_order?.session,
                            edit_fields_type: data_fields_type,
                          },
                        },
                      }))

                      let missing_vals_msg = empty_fields?.reduce(
                        (curr, val) => {
                          return curr + `${val?.prompt_val}, `
                        },
                        'Missing Values: '
                      )
                      handle_msg(
                        'failure_msg',
                        missing_vals_msg?.trim()?.slice(0, -1),
                        'empty_fields',
                        3000
                      )
                      return
                    }
                    let new_sessions = (sessions || [])
                      ?.filter((item) => item.sess_id !== session.sess_id)
                      ?.map((item) => {
                        item.empty_fields = item?.sess_info
                          ?.filter(
                            (item) =>
                              item?.field_id !== 30064 &&
                              item?.field_id !== 55779 &&
                              item?.allow_edit === 'Y'
                          )
                          ?.some((item) => !item?.saved_val)
                          ? true
                          : false
                        return item
                      })

                    setState((prev) => ({
                      ...prev,
                      create_order: {
                        ...prev?.create_order,
                        session: {
                          ...prev?.create_order?.session,
                          empty_fields: false,
                          edit_fields_type: data_fields_type,
                        },
                        sessions: [
                          ...new_sessions,
                          {
                            ...prev?.create_order?.session,
                            empty_fields: false,
                            edit_fields_type: data_fields_type,
                          },
                        ],
                      },
                    }))
                  }}
                >
                  Next
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
      {modalType === 'picker' ? (
        <Modal
          modalName={'material_mls_photo_picker'}
          setShowModal={() => {
            setModalType('material')
          }}
          showModal={true}
          showClose={true}
          height={'h-[580px]'}
          width={'w-[320px]'}
        >
          <MarketingMaterialPhotoPicker
            showModal={showModal}
            setShowModal={(modal_type) => {
              if (modal_type === false) {
                setModalType('material')
              }
            }}
            setModalType={setModalType}
            state={state}
            setState={setState}
          />
        </Modal>
      ) : (
        ''
      )}
    </div>
  )
}
