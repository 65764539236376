import { useEffect, useState } from 'react'
import Wrapper from '../../../components/layout/Wrapper'
import SubNav from '../../../components/SubNav'
import TitleIcon from '../../../components/ui/TitleIcon'
import uiStyles from '../../../components/ui/uiStyles'
import LabelInput from '../../../components/form/LabelInput'
// import { formatPhoneNumber } from '../../../helpers'
import upload_svg from '../../../assets/images/upload.svg'
import { useNavigate } from 'react-router-dom'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import SmallLoading from '../../../components/ui/SmallLoading'
import profileSilhouette from '../../../assets/images/profile-silhouette.svg'
import logoMain from '../../../assets/images/logoMain.png'
import { useProfile } from '../../../contexts/UserContext'
import ImageUpload from '../../../components/form/ImageUpload'
import { useClaims } from '../../../contexts/ClaimsContext'
import Select from '../../../components/form/Select'
import { useMktReqs } from '../../../contexts/MktReqsContext'
import { CircleCheckToggle } from '../../../components/form/CircleCheckToggle'
import { set_storage, validate_number } from '../../../helpers'
const Profile = ({
  type = 'none',
  setType,
  setIsSubscribed,
  title = 'My Profile',
  subtitle = 'Update your personal details here.',
}) => {
  const { axiomFetch } = useAxiomFetch()
  const { profile: contProfile, saveProfile } = useProfile()
  const { mktReqs, saveMktReqs } = useMktReqs()
  console.log(contProfile)
  const { claims } = useClaims()
  const navigate = useNavigate()
  const character_limit = 1000
  const [inputValue, setInputValue] = useState(null)
  console.log('inputValue', inputValue)
  const [isLoading, setIsLoading] = useState(false)
  const handleChange = (e) => {
    const { name, value } = e.target
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const [messageSent, setMessageSent] = useState('done')
  const handle_msg = (status, message, type, close) => {
    setMessageSent({ ...messageSent, status, message, type })
    if (close) {
      setTimeout(() => {
        setMessageSent({ ...messageSent, status: 'done', message: '' })
      }, 2000)
    }
  }

  useEffect(() => {
    const get_profile = async () => {
      setInputValue({
        ...contProfile,
        FIRST_NAME: contProfile?.CONT_NAME?.trim()?.split(' ')?.[0],
        LAST_NAME:
          contProfile?.CONT_NAME?.trim()?.split(' ').length > 1
            ? contProfile?.CONT_NAME?.trim()?.split(' ')?.[
                contProfile?.CONT_NAME?.trim()?.split(' ').length - 1
              ]
            : '',
        bio_count:
          character_limit -
            contProfile?.BIO?.slice(0, character_limit)?.length || 0,
      })
    }

    get_profile()

    // eslint-disable-next-line
  }, [contProfile])

  const handle_img = (e) => {
    e.preventDefault()
    console.log(e.target)
    let file = e?.target?.files?.[0] || e?.dataTransfer.files?.[0]
    let name = e?.target?.name || e?.target?.getAttribute('name')
    if (file && name === 'photo_link') {
      setInputValue({
        ...inputValue,
        PHOTO_LINK: URL.createObjectURL(file),
        PHOTO_LINK_UPLD: file,
      })
    } else if (file && name === 'logo_link') {
      setInputValue({
        ...inputValue,
        LOGO_LINK: URL.createObjectURL(file),
        LOGO_LINK_UPLD: file,
      })
    }
  }

  const save_data = async (delay = 0) => {
    if (!inputValue?.FIRST_NAME || !inputValue?.LAST_NAME) {
      handle_msg(
        'failure_msg',
        'Please add a first and last name.',
        'required_inputs',
        true
      )
      return
    }
    if (!inputValue?.CONT_EMAIL?.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
      handle_msg(
        'failure_msg',
        'Please add a valid email.',
        'required_inputs',
        true
      )
      return
    }
    if (!delay) {
      setIsLoading(true)
    }
    if (inputValue?.PHOTO_LINK_UPLD) {
      const form_body = {
        photo: inputValue?.PHOTO_LINK_UPLD,
        type: 'profile',
        is_internal: claims?.is_internal,
      }
      await axiomFetch(
        '/client/uploadPhoto',
        'POST',
        form_body,
        {},
        true,
        null,
        delay
      )
    }
    if (inputValue?.LOGO_LINK_UPLD) {
      const form_body = {
        photo: inputValue?.LOGO_LINK_UPLD,
        type: 'logo',
        is_internal: claims?.is_internal,
      }
      await axiomFetch(
        '/client/uploadPhoto',
        'POST',
        form_body,
        {},
        true,
        null,
        delay
      )
    }
    inputValue.CONT_NAME = `${inputValue.FIRST_NAME} ${inputValue.LAST_NAME}`
    inputValue.ACCOUNT_ID = Number(inputValue?.ACCOUNT_ID) || 1320
    await axiomFetch(
      '/client/profile',
      'POST',
      inputValue,
      {},
      false,
      null,
      delay
    )

    const data = await axiomFetch(
      '/client/profile',
      'GET',
      {},
      {},
      false,
      null,
      delay
    )
    if (data?.length) {
      if (claims?.is_internal === 'Y') {
        data[0].INT_PHOTO_LINK = inputValue.PHOTO_LINK
        data[0].LOGO_LINK = inputValue.LOGO_LINK
      } else {
        data[0].PHOTO_LINK = inputValue.PHOTO_LINK
        data[0].LOGO_LINK = inputValue.LOGO_LINK
      }
    }
    saveProfile((prev) => {
      const profile_data = { ...prev, ...data[0] }
      set_storage('profile', profile_data)
      return profile_data
    })
    if (type === 'initial') {
      await axiomFetch('/client/merge_clients', 'POST', {}, {}, false)
      await axiomFetch('/client/first_login', 'POST', {}, {}, false)
      let call_team_body = [
        { phone_number: data?.[0]?.CONT_CELL_NUM, name: data?.[0]?.CONT_NAME },
      ]
      const team = await axiomFetch('/client/call_team')
      let me = team.filter(
        (item) =>
          item.PHONE_NUMBER.toString() === data?.[0]?.CONT_CELL_NUM?.toString()
      )
      if (me?.[0]?.TEAM_ID) {
        call_team_body[0].team_id = me?.[0]?.TEAM_ID
      }
      await axiomFetch('/client/call_team', 'POST', call_team_body, {}, false)
      setType('full')
    } else if (type === 'full') {
      navigate('/user')
    }
    setIsLoading(false)
  }

  useEffect(() => {
    const update_data = async () => {
      if (type === 'initial' || type === 'full') {
        return
      } else {
        if (!can_automate_weekender(false)) {
          return
        }
        await save_data(700)
      }
    }
    update_data()
  }, [inputValue?.AUTO_WEEKENDER])

  useEffect(() => {
    const update_data = async () => {
      if (!inputValue?.CONT_ID) {
        return
      }
      if (type === 'initial' || type === 'full') {
        return
      } else {
        await save_data(700)
      }
    }
    update_data()
  }, [
    inputValue?.LIC_NUM,
    inputValue?.PHOTO_LINK,
    inputValue?.LOGO_LINK,
    inputValue?.FIRST_NAME,
    inputValue?.LAST_NAME,
    inputValue?.ACCOUNT_ID,
    inputValue?.WEB_SITE,
    inputValue?.FACEBOOK_LINK,
    inputValue?.INSTAGRAM_LINK,
    inputValue?.TWITTER_LINK,
    inputValue?.CONT_EMAIL,
    inputValue?.BIO,
    inputValue?.JOB_TITLE,
    inputValue?.JOB_TITLE_SHOW,
    inputValue?.BR_ADD_LINE1,
    inputValue?.BR_ADD_LINE2,
    inputValue?.BR_ADD_CITY,
    inputValue?.BR_ADD_ST,
    inputValue?.BR_ADD_POSTAL,
    inputValue?.CLIENT_COMPANY,
  ])

  console.log('cont_profile', contProfile)
  useEffect(() => {
    const is_weekender_auto =
      validate_number(contProfile?.AUTO_WEEKENDER) > 0 ? 'Y' : 'N'
    console.log(is_weekender_auto)
    saveMktReqs((prev) => ({
      ...prev,
      is_weekender_auto,
    }))
    setInputValue((prev) => ({
      ...prev,
      is_weekender_auto,
    }))
  }, [contProfile])

  const can_automate_weekender = (show_message = true) => {
    const keys = {
      profile_photo: inputValue?.PHOTO_LINK,
      logo: inputValue?.LOGO_LINK,
      first_name: inputValue?.FIRST_NAME,
      last_name: inputValue?.LAST_NAME,
      email: inputValue?.CONT_EMAIL,
    }
    let missing_values = []
    let error_msg = ''
    console.log('keys', keys)
    Object.entries(keys).forEach(([key, value]) => {
      if (!value) {
        missing_values.push(key?.replaceAll('_', ' '))
      }
    })
    error_msg = `Please add a ${missing_values?.join(', ')}`
    if (missing_values?.length) {
      if (show_message) {
        handle_msg('failure_msg', error_msg, 'can_send_weekender', true)
      }
      return false
    } else {
      return true
    }
  }

  const html_title = (
    <div
      className={`${
        type === 'automations' ? 'px-3' : ''
      } flex flex-col gap-1 mb-1`}
    >
      <h4 className={`${uiStyles.text_black_subtitle} text-[20px]`}>{title}</h4>
      <p className={`${uiStyles.text_gray}`}>{subtitle}</p>
    </div>
  )

  const html_automations = (
    <div className={`${type === 'automations' ? 'px-3' : ''}`}>
      <h2 className={`text-black text-[15px] mt-[10px]`}>
        Weekender Automation:
      </h2>
      {messageSent?.type === 'can_send_weekender' ? (
        <div className={`${uiStyles[messageSent.status]} mb-[20px]`}>
          {messageSent.message}
        </div>
      ) : (
        ''
      )}
      <div className='flex gap-[0px] mt-[5px]'>
        <CircleCheckToggle
          name={`is_weekender_auto`}
          inputValue={inputValue}
          setInputValue={setInputValue}
          handle_change={async (e) => {
            console.log(e.target.value)
            setInputValue((prev) => {
              const return_data = {
                ...prev,
                [`is_weekender_auto`]:
                  prev?.[`is_weekender_auto`] === 'Y' ? 'N' : 'Y',
                AUTO_WEEKENDER:
                  prev?.is_weekender_auto !== 'Y' && prev?.AUTO_WEEKENDER === 0
                    ? 1
                    : prev?.is_weekender_auto === 'Y'
                    ? 0
                    : prev?.AUTO_WEEKENDER,
              }
              if (!can_automate_weekender()) {
                return_data.AUTO_WEEKENDER = 0
                return_data.is_weekender_auto = 'N'
              }

              return return_data
            })
          }}
        />
        <label>Send me a weekender each week for: </label>
      </div>
      <Select
        name={'AUTO_WEEKENDER'}
        value={validate_number(inputValue?.AUTO_WEEKENDER)}
        handler={(e) => {
          const is_weekender_auto =
            validate_number(e.target.value) > 0 ? 'Y' : 'N'
          if (!can_automate_weekender()) {
            return setInputValue((prev) => ({
              ...prev,
              [e.target.name]: 0,
              is_weekender_auto: 'N',
            }))
          }

          setInputValue((prev) => ({
            ...prev,
            [e.target.name]: validate_number(e.target.value),
            is_weekender_auto,
          }))
        }}
      >
        {mktReqs?.materials?.weekender_locations?.map((item) => {
          return <option value={item?.loc_id}>{item?.location_name}</option>
        })}
      </Select>
      <div className={`${uiStyles.line} mt-[10px]`}></div>
    </div>
  )

  if (type === 'automations') {
    return (
      <>
        {html_title} {html_automations}
      </>
    )
  }

  return (
    <>
      {isLoading ? (
        <>
          <SmallLoading styles={type !== 'none' ? 'mb-[400px]' : ''} />
          {type !== 'none' ? <div className='min-h-[600px]'></div> : ''}
        </>
      ) : (
        <>
          {/* <TitleIcon showLogo={type === 'none' ? false : true}>
            {type === 'none' ? '' : 'Configure'} My Profile
          </TitleIcon> */}
          <div className='flex flex-col gap-3 text-black m-5'>
            {html_title}
            {/* <div className={`${uiStyles.line}`}></div> */}
            {/* <LabelInput 
        top_label={'Username'} 
        left_label={'axiomai.com/'} 
        left_label_width={'w-[52%]'}
        name={`CT_USER_NAME`}
        value={inputValue?.CT_USER_NAME}
        handleChange={handleChange}/> */}
            <div className={`${uiStyles.line}`}></div>
            {type !== 'initial' ? (
              <>
                {html_automations}
                <LabelInput
                  top_label={'Website'}
                  left_label={'https://'}
                  left_label_width={'w-[30%]'}
                  name={`WEB_SITE`}
                  value={inputValue?.WEB_SITE || ''}
                  handleChange={handleChange}
                />
                <LabelInput
                  top_label={'Facebook Link'}
                  left_label={'https://'}
                  left_label_width={'w-[30%]'}
                  name={`FACEBOOK_LINK`}
                  value={inputValue?.FACEBOOK_LINK || ''}
                  handleChange={handleChange}
                />
                <LabelInput
                  top_label={'Instagram Link'}
                  left_label={'https://'}
                  left_label_width={'w-[30%]'}
                  name={`INSTAGRAM_LINK`}
                  value={inputValue?.INSTAGRAM_LINK || ''}
                  handleChange={handleChange}
                />
                <LabelInput
                  top_label={'Twitter Link'}
                  left_label={'https://'}
                  left_label_width={'w-[30%]'}
                  name={`TWITTER_LINK`}
                  value={inputValue?.TWITTER_LINK || ''}
                  handleChange={handleChange}
                />
                <div>
                  <h4 className={`${uiStyles.text_gray} font-medium`}>
                    Your Photo
                  </h4>
                  <p className={`${uiStyles.text_gray} font-normal`}>
                    This will be displayed on your profile.
                  </p>
                  <div className='flex justify-between items-start'>
                    <img
                      alt={'Profile'}
                      className={`${uiStyles.profile_img} mt-[20px]`}
                      src={`${
                        claims?.is_internal === 'Y'
                          ? inputValue?.PHOTO_LINK_UPLD
                            ? inputValue?.PHOTO_LINK
                            : inputValue?.INT_PHOTO_LINK || profileSilhouette
                          : inputValue?.PHOTO_LINK || profileSilhouette
                      }`}
                    />
                    <div className='mt-[10px] flex gap-3'>
                      {/* <button className={`${uiStyles.text_gray}`}>Delete</button> */}
                      <div
                        onClick={() => {
                          setInputValue({
                            ...inputValue,
                            show_upload: inputValue?.show_upload ? false : true,
                          })
                        }}
                      >
                        <p
                          className={`${uiStyles.green_grd_text} cursor-pointer`}
                        >
                          Update
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className={`${uiStyles.line} mt-[5px]`}></div> */}
                {inputValue?.show_upload ? (
                  // <label className='flex flex-col justify-center items-center p-3 border-[1.5px] rounded-md border-gray-300 cursor-pointer mt-[5px]'>
                  //     <input
                  //     className='hidden'
                  //     type='file'
                  //     name="photo_link"
                  //     accept='image/*'
                  //     onChange={handle_img}/>
                  //     <img className={'w-[40px] mb-3'} src={upload_svg} alt='upload icon'/>
                  //     <h4 className={`${uiStyles.blue_grd_subtitle} mb-2`}>Click to upload your picture</h4>
                  //     <p className={uiStyles.text_gray}>SVG, PNG, JPG or GIF (max. 800 / 400px)</p>
                  // </label>
                  <ImageUpload
                    title='Upload Your Profile Picture'
                    secondary_title={'Drag or click to upload'}
                    subtitle={'SVG, PNG, JPG, GIF'}
                    multiple={false}
                    accept='image/*'
                    name={'photo_link'}
                    handler={handle_img}
                  />
                ) : (
                  ''
                )}
                {claims?.is_internal === 'Y' ? (
                  <></>
                ) : (
                  <>
                    <div className={`${uiStyles.line}`}></div>
                    <div>
                      <h4 className={`${uiStyles.text_gray} font-medium`}>
                        Your Logo
                      </h4>
                      <p className={`${uiStyles.text_gray} font-normal`}>
                        This will be displayed on your profile.
                      </p>
                      <div className='flex justify-between items-start'>
                        <img
                          alt={'Profile'}
                          className={`${uiStyles.rec_img} mt-[20px]`}
                          src={inputValue?.LOGO_LINK || logoMain}
                        />
                        <div className='mt-[10px] flex gap-3'>
                          {/* <button className={`${uiStyles.text_gray}`}>Delete</button> */}
                          <div
                            onClick={() => {
                              setInputValue({
                                ...inputValue,
                                show_logo_upload: inputValue?.show_logo_upload
                                  ? false
                                  : true,
                              })
                            }}
                          >
                            <p
                              className={`${uiStyles.green_grd_text} cursor-pointer`}
                            >
                              Update
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {/* <div className={`${uiStyles.line} mt-[5px]`}></div> */}
                {inputValue?.show_logo_upload ? (
                  <ImageUpload
                    title='Upload Your Logo'
                    secondary_title={'Drag or click to upload'}
                    subtitle={'SVG, PNG, JPG, GIF'}
                    accept='image/*'
                    name={'logo_link'}
                    multiple={false}
                    handler={handle_img}
                  />
                ) : (
                  ''
                )}
                <div className={`${uiStyles.line}`}></div>
              </>
            ) : (
              ''
            )}
            <LabelInput
              top_label={'First name'}
              name={'FIRST_NAME'}
              value={inputValue?.FIRST_NAME || ''}
              handleChange={handleChange}
            />
            <LabelInput
              top_label={'Last name'}
              name={'LAST_NAME'}
              value={inputValue?.LAST_NAME || ''}
              handleChange={handleChange}
            />
            <LabelInput
              top_label={'Email address'}
              name={'CONT_EMAIL'}
              value={inputValue?.CONT_EMAIL || ''}
              handleChange={handleChange}
            />
            <div className=''>
              <label className={'text-sm font-medium text-[#344054]'}>
                Market of focus
              </label>
              <select
                className={`${uiStyles.select} mt-[3px]`}
                value={inputValue?.ACCOUNT_ID?.toString() || '1320'}
                onChange={(e) => {
                  setInputValue((prev) => ({
                    ...prev,
                    ACCOUNT_ID: Number(e.target.value),
                  }))
                }}
              >
                <option value='1320'>Arizona</option>
                <option value='1560'>Nevada</option>
              </select>
            </div>

            {/* <LabelInput
        top_label={'Phone Number'}
        name={'CONT_CELL_NUM'}
        value={formatPhoneNumber(inputValue?.CONT_CELL_NUM) || ''}
        handleChange={handleChange}
        /> */}
            {type !== 'initial' ? (
              <>
                <div>
                  <div className='mb-[10px]'>
                    <LabelInput
                      top_label={'Broker Name'}
                      name={'CLIENT_COMPANY'}
                      value={inputValue?.CLIENT_COMPANY || ''}
                      handleChange={handleChange}
                    />
                  </div>
                  <div className='mb-[10px]'>
                    <LabelInput
                      top_label={'License #'}
                      name={'LIC_NUM'}
                      value={inputValue?.LIC_NUM || ''}
                      handleChange={handleChange}
                    />
                  </div>
                  <h4 className={`${uiStyles.text_gray} font-medium`}>
                    Your bio
                  </h4>
                  <p className={`${uiStyles.text_gray} font-normal`}>
                    Write a short introduction.
                  </p>
                  <textarea
                    className={`${uiStyles.text_area} mt-[10px] h-[154px]`}
                    placeholder='Add a short bio...'
                    value={inputValue?.BIO?.slice(0, character_limit) || ''}
                    onChange={(e) => {
                      if (e.target.value.length <= character_limit) {
                        const clean_up_text = (text) => {
                          const replace_strings = [
                            ['’', "'"],
                            ['‘', "'"],
                            ['“', '"'],
                            ['–', '-'],
                            ['—', '-'],
                          ]
                          for (const arr of replace_strings) {
                            text = text?.replaceAll(arr[0], arr[1])
                          }
                          //emojis
                          text = text?.replace(
                            /[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2580-\u27BF]|\uD83E[\uDD10-\uDDFF]/g,
                            ''
                          )
                          return text
                        }
                        setInputValue((prev) => ({
                          ...prev,
                          bio_count: character_limit - e.target.value.length,
                          BIO: clean_up_text(e.target.value),
                        }))
                      }
                    }}
                  ></textarea>
                  <span className={`${uiStyles.text_gray}`}>
                    {inputValue?.bio_count} characters left
                  </span>
                </div>
                <div className={`${uiStyles.line} mt-[5px]`}></div>
                <LabelInput
                  top_label={'Job title'}
                  name={'JOB_TITLE'}
                  value={inputValue?.JOB_TITLE || ''}
                  handleChange={handleChange}
                />
                <div className='flex items-center'>
                  <label className='block capitalize relative w-[16px] px-3'>
                    <input
                      tabIndex={-1}
                      className='absolute left-0 right-0 top-0 h-full opacity-0'
                      type='checkbox'
                      name={'input'}
                      value={inputValue?.JOB_TITLE_SHOW || false}
                      checked={
                        inputValue?.JOB_TITLE_SHOW === 'Y' ? true : false
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          setInputValue((prev) => ({
                            ...prev,
                            JOB_TITLE_SHOW: 'Y',
                          }))
                        } else {
                          setInputValue((prev) => ({
                            ...prev,
                            JOB_TITLE_SHOW: 'N',
                          }))
                        }
                      }}
                    />
                    <div className='flex items-center justify-center'>
                      <div className='h-[16px] w-[16px] rounded-[3px] bg-gradient-to-br from-accent-150 to-accent-250 p-[1px]'>
                        <div className='flex h-[14px] w-[14px] rounded-[3px] items-center justify-center bg-[#F9F5FF]'>
                          {inputValue?.JOB_TITLE_SHOW === 'Y' ? (
                            <svg
                              width='10'
                              height='8'
                              viewBox='0 0 10 8'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M9 1L3.5 6.5L1 4'
                                stroke='url(#paint0_linear_1338_30152)'
                                strokeWidth='1.6666'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <defs>
                                <linearGradient
                                  id='paint0_linear_1338_30152'
                                  x1='-1.58407'
                                  y1='2.77655'
                                  x2='1.30771'
                                  y2='9.28756'
                                  gradientUnits='userSpaceOnUse'
                                >
                                  <stop stopColor='#00CFDE' />
                                  <stop offset='1' stopColor='#05A660' />
                                </linearGradient>
                              </defs>
                            </svg>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </label>
                  <span className={`${uiStyles.text_gray} ml-1 font-medium`}>
                    Show my job title in my profile
                  </span>
                </div>
                <div className={`${uiStyles.line} mt-[5px]`}></div>
                <LabelInput
                  no_margin={true}
                  top_label={'address 1'}
                  // left_label={'https://'}
                  left_label_width={'w-[30%]'}
                  name={`BR_ADD_LINE1`}
                  value={inputValue?.BR_ADD_LINE1 || ''}
                  handleChange={handleChange}
                />
                <LabelInput
                  no_margin={true}
                  top_label={'address 2'}
                  // left_label={'https://'}
                  // left_label_width={'w-[30%]'}
                  name={`BR_ADD_LINE2`}
                  value={inputValue?.BR_ADD_LINE2 || ''}
                  handleChange={handleChange}
                />
                <LabelInput
                  no_margin={true}
                  top_label={'City'}
                  // left_label={'https://'}
                  // left_label_width={'w-[30%]'}
                  name={`BR_ADD_CITY`}
                  value={inputValue?.BR_ADD_CITY || ''}
                  handleChange={handleChange}
                />
                <LabelInput
                  no_margin={true}
                  top_label={'State'}
                  // left_label={'https://'}
                  // left_label_width={'w-[30%]'}
                  name={`BR_ADD_ST`}
                  value={inputValue?.BR_ADD_ST || ''}
                  handleChange={handleChange}
                />
                <LabelInput
                  no_margin={true}
                  top_label={'ZIP'}
                  // left_label={'https://'}
                  // left_label_width={'w-[30%]'}
                  name={`BR_ADD_POSTAL`}
                  value={inputValue?.BR_ADD_POSTAL || ''}
                  handleChange={handleChange}
                />
              </>
            ) : (
              ''
            )}
            <div className={`${uiStyles.line} mt-[5px]`}></div>
            {messageSent?.type === 'required_inputs' ? (
              <div className={`${uiStyles[messageSent.status]} mb-[20px]`}>
                {messageSent.message}
              </div>
            ) : (
              ''
            )}
            <div className='my-[10px] flex justify-end gap-2'>
              <button
                className={`${uiStyles.white_btn}`}
                onClick={() => {
                  if (type === 'initial') {
                    setIsSubscribed('')
                    setType('none')
                  } else if (type === 'full') {
                    setIsSubscribed('')
                    setType('none')
                  } else {
                    navigate(-1)
                  }
                }}
              >
                {type === 'full' ? 'Cancel' : 'Cancel'}
              </button>
              <button
                className={`${uiStyles.green_btn}`}
                onClick={async () => await save_data(0)}
              >
                Save
              </button>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default Profile
