import React, { useCallback, useEffect, useRef, useState } from 'react';

import Wrapper from '../../layout/Wrapper';
import SubNav from '../../SubNav';
import Transaction from '../../ui/Transaction';
import { useParams } from 'react-router';
import { useTransactions } from '../../../contexts/TransactionsContext';
import { FaCompress, FaExpand } from 'react-icons/fa';
import useAxiomFetch from '../../../hooks/useAxiomFetch';
import SmallLoading from '../../ui/SmallLoading';
export default function TitleDocumentViewer({doc_name, doc_link, fileid, setImage}) {
  const {axiomFetch} = useAxiomFetch()
  const [docFullView, setDocFullView] = useState(false);
  // const [currentTransaction, setCurrentTransaction] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const { getTransactionByID } = useTransactions();
  const currentTransaction = getTransactionByID(fileid);
 console.log(currentTransaction)
  // useEffect(() => {
  //   const controller = new AbortController()
  //   const get_doc = async () => {
  //     setIsLoading(true)
  //     const data = await axiomFetch(`/escrow/transaction/${fileid}`, 'GET', {}, {}, false, controller.signal)
  //     setCurrentTransaction(data)
  //     if (data?.file_id) {
  //     setIsLoading(false)
  //     }
  //   }
  //   get_doc()
  //   return () => {
  //     controller?.abort()
  //   }
  // }, [])

  const iframe = currentTransaction && (
    <iframe
      title={doc_name}
      src={doc_link}
      width="1400"
      height="100%"
      className="absolute left-0 right-0 top-0  "
    />
  );

  return (
    <>
      {isLoading ? <Wrapper><SmallLoading/></Wrapper> : <Wrapper>
        <div onClick={() => {
          setImage({})
        }}>
        <SubNav url={`/user/my-transactions-lobby/transactions/${fileid}/title`}/>
        </div>
        {currentTransaction && (
          <Transaction
            title=""
            fileid={currentTransaction?.ext_id}
            address={currentTransaction?.address1}
            city={currentTransaction?.city}
            state={currentTransaction?.state}
            isClosed={currentTransaction?.close_disb_dt}
            docName={doc_name}
            escrowOfficer={currentTransaction?.escrow_officer}
            lender={currentTransaction?.lender}
            postal={currentTransaction?.postal_code}
          />
        )}

        {/* {showPad && <SignPad onClose={setShowPad.bind(this, false)} />} */}
        <div className="relative h-[calc(100vh-170px)] pt-5 border border-t-8 border-gray-200">
          <div className="absolute left-0 top-0 right-0 bottom-0 overflow-auto w-full">
            {iframe}
          </div>
          <div className="absolute bottom-5 right-5 w-8 h-8 rounded-full bg-accent-250/80 flex items-center justify-center shadow-2xl shadow-accent-250">
            <FaExpand
              className="  text-white text-xl cursor-pointer"
              onClick={() => setDocFullView(true)}
            />
          </div>
        </div>
      </Wrapper>
}
      {!isLoading && docFullView && (
        <div className="fixed left-0 top-0 w-full h-full bg-white">
          <div className="overflow-scroll absolute left-0 right-0 top-0 bottom-0">
            <iframe
              title={doc_name}
              src={doc_link}
              width="100%"
              height="100%"
              className="absolute left-0 right-0 top-0  min-w-[1400px]"
            />
          </div>
          <FaCompress
            className="rounded-full absolute bottom-3 right-3 w-10 h-10 bg-accent-250/80 p-2   text-white text-3xl cursor-pointer shadow-lg"
            onClick={() => setDocFullView(false)}
          />
        </div>
      )}
    </>
  );
}
