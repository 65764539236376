import React from 'react'
import images_tours from '../../assets/images/listing_tours'
import { useCalc } from '../../contexts/CalcContext'

const CalcSellOfferStar = ({ offer = 'key', on_last_offer, gen_template }) => {
  const { calc, saveCalc, get_offer_name } = useCalc()
  return (
    <div>
      <img
        className='cursor-pointer'
        onClick={() => {
          if (!calc?.offers?.items?.offer_1) {
            gen_template()
          }
          saveCalc((prev) => {
            const reset_offers = prev?.offers?.items || {}
            Object.keys(reset_offers)
              .filter((key) => key !== get_offer_name(offer))
              .forEach((key) => {
                reset_offers[key].data_options.starred = 0
              })
            const data = {
              ...prev,
              offers: {
                ...prev?.offers,
                data_options: {
                  ...prev?.offers?.data_options,
                  on_last_offer: on_last_offer,
                },
                items: {
                  ...prev?.offers?.items,
                  ...reset_offers,
                  [get_offer_name(offer)]: {
                    ...(!calc?.offers?.items?.offer_1 ? gen_template() : {}),
                    ...prev?.offers?.items?.[get_offer_name(offer)],
                    data_options: {
                      ...prev?.offers?.items?.[get_offer_name(offer)]
                        ?.data_options,
                      starred: prev?.offers?.items?.[get_offer_name(offer)]
                        ?.data_options?.starred
                        ? 0
                        : 1,
                    },
                  },
                },
              },
            }
            return data
          })
        }}
        src={
          calc?.offers?.items?.[get_offer_name(offer)]?.data_options?.starred
            ? images_tours?.star_filled
            : images_tours?.star_outline
        }
        alt='star'
      />
    </div>
  )
}

export default CalcSellOfferStar
