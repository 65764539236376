import React from 'react';
import Wrapper from '../../components/layout/Wrapper';
import MarketingMaterialApprove from '../../components/pages/marketingMaterials/MarketingMaterialApprove';
import SubNav from '../../components/SubNav';
import TitleIcon from '../../components/ui/TitleIcon';

export default function PageMMApprove() {
  return <Wrapper> 
  <SubNav />
  <TitleIcon className="mb-5">Marketing</TitleIcon>
  <MarketingMaterialApprove /></Wrapper>;
}
