import { useState } from 'react'
import upload_svg from '../../assets/images/upload.svg'
import uiStyles from '../ui/uiStyles'

const ImageUpload = ({
  handler = (e) => {},
  accept,
  title,
  subtitle,
  secondary_title,
  multiple = false,
  name = 'csv_upload',
}) => {
  const [drag, setDrag] = useState(false)

  return (
    <label
      className={`transtion-all duration-100 relative flex flex-col justify-center items-center p-3 border-[1.5px] rounded-md ${
        drag ? 'border-[3px] border-accent-400' : 'border-gray-300'
      } cursor-pointer mt-[5px]`}
    >
      <div
        onDrop={(e) => {
          setDrag(false)
          handler(e)
        }}
        onDragOver={(e) => {
          e.preventDefault()
        }}
        onDragEnter={(e) => {
          setDrag(true)
        }}
        onDragLeave={(e) => {
          setDrag(false)
        }}
        name={name}
        className='absolute w-full h-full rounded-md top-0 right-0 bottom-0 left-0'
      ></div>
      <input
        className='hidden'
        type='file'
        multiple={multiple}
        name={name}
        accept={accept}
        onChange={handler}
      />
      <img className={'w-[40px] mb-3'} src={upload_svg} alt='upload icon' />
      <h4 className={`${uiStyles.blue_grd_subtitle} mb-2`}>
        {title ? title : 'Click to upload your picture'}{' '}
        {
          <p className={`${uiStyles.text_gray} font-normal text-center`}>
            {secondary_title ? secondary_title : ''}
          </p>
        }
      </h4>
      <p className={uiStyles.text_gray}>
        {subtitle ? subtitle : 'SVG, PNG, JPG or GIF (max. 800 / 400px)'}
      </p>
    </label>
  )
}

export default ImageUpload
