import { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTransactions } from '../../../contexts/TransactionsContext'
import Wrapper from '../../layout/Wrapper'
import SubNav from '../../SubNav'
import ButtonsRow from '../../ui/ButtonsRow'
import ProgressBar from '../../ui/ProgressBar'
import TransactionsMenu from '../../ui/TransactionsMenu'
import uiStyles from '../../ui/uiStyles'
import ClosingExperience from './ClosingExperience'
const TransactionWrapper = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const lastPathPart = pathname.substring(pathname.lastIndexOf('/') + 1)
  console.log(lastPathPart)
  const params = useParams()
  console.log(params)
  const [currFile, setCurrFile] = useState({})
  const [showClosingExperience, setShowClosingExperience] = useState(false)
  const { transactions, getTransactionByID } = useTransactions()
  useEffect(() => {
    const curr_file = getTransactionByID(params.fileid)
    setCurrFile((prev) => curr_file)
    if (curr_file?.show_close_exp) {
      setShowClosingExperience(true)
    }
  }, [transactions])
  console.log(currFile)
  return (
    <div>
      <Wrapper>
        <SubNav url={'/user/my-transactions-lobby/transactions'} />
        {showClosingExperience ? (
          <div className='flex flex-col px-3'>
            <ClosingExperience
              setShowClosingExperience={setShowClosingExperience}
              file={currFile}
            />
            <div className='flex justify-center mb-[10px]'>
              <button
                className={uiStyles.hover_btn}
                onClick={() => {
                  setShowClosingExperience(false)
                }}
              >
                Next
              </button>
            </div>
          </div>
        ) : (
          <>
            <>
              <div className='flex px-3 justify-end items-center mb-[10px]'>
                <div className='flex-1'>
                  <h1 className='text-primary font-semibold'>
                    {/* <span className="text-2xl">File</span>{' '}
            <span className="text-lg">{fileid}</span> */}
                    <span>{currFile?.address1} </span>
                    {currFile?.show_close_exp ? (
                      <button
                        className={uiStyles.hover_btn_small}
                        onClick={() => {
                          setShowClosingExperience(true)
                        }}
                      >
                        Closing Exp
                      </button>
                    ) : (
                      ''
                    )}
                  </h1>
                </div>

                <ProgressBar
                  stringId={'pr1' + currFile?.file_id}
                  percentage={currFile?.pct_done}
                  color1='#5B8DEF'
                  color2='#0063F7'
                  strokeWidth={10}
                />
              </div>
              <div className='px-3'>
                <ButtonsRow
                  items={['documents', 'title', 'parties', 'notes', 'statuses']}
                  selected={lastPathPart}
                  handler={(item) => {
                    navigate(
                      `/user/my-transactions-lobby/transactions/${currFile?.file_id}/${item}`,
                      { replace: true }
                    )
                  }}
                />
              </div>
            </>
            <div className='px-4 py-3'>
              <Outlet />
            </div>
          </>
        )}
      </Wrapper>
    </div>
  )
}

export default TransactionWrapper
