import { getYear, getMonth, getDate } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';

export default function DateTimePicker({ scheduleDate, dateChangeHandler = () => {} }) {
  const [startDate, setStartDate] = useState(scheduleDate ? scheduleDate : new Date());
  const [lastDate, setLastDate] = useState(scheduleDate || new Date())
  const dateRef = useRef();
  const changeHandler = (date, time) => {
   
    if (!time) {
    setLastDate(date);
    } else if (time) {
      let day = new Date(lastDate).toLocaleString('sv').split(' ')[0]
      let new_time = new Date(date).toLocaleString('sv').split(' ')[1]
      date = new Date(`${day} ${new_time}`)
    }
    setStartDate(date)
    dateRef.current.value = `${months[getMonth(date)].substring(
      0,
      3
    )} ${getDate(date)}, ${getYear(date)}`;
    dateChangeHandler(date);
  };
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  useEffect(() => {
    dateRef.current.value = `${months[getMonth(startDate)]?.substring(
      0,
      3
    )} ${getDate(startDate)}, ${getYear(startDate)}`;
        // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dateChangeHandler(startDate);
    // eslint-disable-next-line
  }, []);
  return (
    <div className="border border-gray-100 px-2 py-3 rounded-md">
      <ReactDatePicker
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <>
            <div className="flex justify-center items-center">
              <div
                className="border border-gray-200 px-2 py-1 rounded-md cursor-pointer"
                onClick={() => {
                  decreaseMonth();
                }}
                disabled={prevMonthButtonDisabled}
              >
                {'<'}
              </div>
              <div className=" px-5 text-primary font-semibold">
                {months[getMonth(date)]} {getYear(date)}
              </div>{' '}
              <div
                className="border border-gray-200 px-2 py-1 rounded-md cursor-pointer"
                onClick={() => {
                  increaseMonth();
                }}
                disabled={nextMonthButtonDisabled}
              >
                {'>'}
              </div>
            </div>
            <div className="flex p-2">
              <div className="px-2 flex-1 shrink-0">
                <input
                  ref={dateRef}
                  type="text"
                  className="border w-full border-gray-200 px-3 py-1 rounded-md text-sm text-primary"
                />
              </div>
              <div className="px-2 timepicker relative w-[120px]">
                <ReactDatePicker
                  selected={startDate}
                  onChange={(date) => {
                    changeHandler(date, true);
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={5}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                />
              </div>
            </div>
          </>
        )}
        selected={startDate}
        onChange={(date) => {
          changeHandler(date, false);
        }}
        monthsShown={1}
        inline
        minDate={new Date()}
      />
    </div>
  );
}
