import React from 'react'
import { useAi } from '../../../contexts/AiContext'
import { useDistribute } from '../../../contexts/DistributeContext'
import { CircleCheckToggle } from '../../form/CircleCheckToggle'
import uiStyles from '../../ui/uiStyles'
import utils from './utils'

export const AiMessage = () => {
  const { distribute, saveDistribute } = useDistribute()
  const { ai } = useAi()
  const type =
    (distribute?.type === 'social' ? distribute?.social : distribute?.type) ||
    'instagram'
  const content = distribute?.content?.[type]
  return (
    <div>
      <label>Ai formatted content for {type}:</label>
      {ai?.state?.message ? (
        <div className='flex justify-end mr-[45px] mb-[5px]'>
          {/* <CircleCheckToggle
            name={`toggle_content`}
            type='toggle'
            toggle_left={true}
            //   toggle_color_grad={'from-[#0A66C2] to-[#0A66C2]'}
            //   toggle_color={'text-[#000]'}
            toggle_left_label={'short'}
            toggle_right_label={'long'}
            inputValue={content}
            handle_change={async (e) => {
              console.log(content)
              saveDistribute((prev) => ({
                ...prev,
                content: {
                  ...prev?.content,
                  [type]: {
                    ...prev?.content?.[type],
                    message:
                      content?.toggle_content === 'Y'
                        ? content.long_message
                        : content.short_message,
                    toggle_content: content?.toggle_content === 'Y' ? 'N' : 'Y',
                  },
                },
              }))
            }}
          /> */}
        </div>
      ) : (
        ''
      )}
      {type === 'text' ? (
        <p className='text-black mt-[10px]'>
          {utils.pre_text(
            (content?.list_type || 'list') === 'single'
              ? {
                  NM: content?.list_single_name,
                  CELL: content?.list_single_number,
                }
              : '',
            ai
          )}
        </p>
      ) : (
        ''
      )}
      <textarea
        className={`${uiStyles.text_area} min-h-[200px]`}
        value={content?.message}
        onChange={(e) => {
          saveDistribute((prev) => {
            console.log(prev)
            return {
              ...prev,
              content: {
                ...prev?.content,
                [type]: {
                  ...prev?.content?.[type],
                  message: e.target.value,
                  [prev?.content?.[type]?.toggle_content === 'Y'
                    ? 'short_message'
                    : 'long_message']: e.target.value,
                },
              },
            }
          })
        }}
      ></textarea>
      {type === 'text' ? (
        <p className='text-black mb-[10px]'>{utils.post_text(ai)}</p>
      ) : (
        ''
      )}
    </div>
  )
}
