
const format_text_message = (text, contact, ai, callMe = false) => {
    let new_msg = text
    if (
        ai?.state?.last_prompt?.toLowerCase()?.includes('spanish') ||
        ai?.state?.prompt?.toLowerCase()?.includes('spanish')
    ) {
        new_msg = `Ey {{contactName}}, \n${text || ''} ${callMe
            ? `\n\nHaz clic en el enlace de abajo para que yo te llame.\n{{callLink}}`
            : ''
            } 
        \n Responde STOP para cancelar la suscripión.`
        if (contact?.NM) {
            new_msg = new_msg?.replace(`{{contactName}}`, contact?.NM)
        } else {
            new_msg = new_msg?.replace(`Ey {{contactName}},`, 'Ey,')
        }
    } else {
        new_msg = `Hey {{contactName}}, \n${text || ''} ${callMe
            ? `\n\nClick the link below to have me call you.\n{{callLink}}`
            : ''
            } 
        \n Reply "STOP" to opt out.`
        if (contact?.NM) {
            new_msg = new_msg?.replace(`{{contactName}}`, contact?.NM)
        } else {
            new_msg = new_msg?.replace(`Hey {{contactName}},`, 'Hey,')
        }
    }
    return new_msg
}

const pre_text = (contact, ai) => {
    let new_msg
    if (
        ai?.state?.last_prompt?.toLowerCase()?.includes('spanish') ||
        ai?.state?.prompt?.toLowerCase()?.includes('spanish')
    ) {
        new_msg = `Ey {{contactName}},`
        if (contact?.NM) {
            new_msg = new_msg?.replace(`{{contactName}}`, contact?.NM)
        } else {
            // new_msg = new_msg?.replace(`Ey {{contactName}},`, 'Ey,')
        }
    } else {
        new_msg = `Hey {{contactName}},`
        if (contact?.NM) {
            new_msg = new_msg?.replace(`{{contactName}}`, contact?.NM)
        } else {
            // new_msg = new_msg?.replace(`Hey {{contactName}},`, 'Hey,')
        }
    }
    return new_msg
}

const post_text = (ai) => {
    let new_msg
    if (
        ai?.state?.last_prompt?.toLowerCase()?.includes('spanish') ||
        ai?.state?.prompt?.toLowerCase()?.includes('spanish')
    ) {
        new_msg = `Responde STOP para cancelar la suscripión.`
    } else {
        new_msg = `Reply "STOP" to opt out.`
    }
    return new_msg
}
const utils = { format_text_message, pre_text, post_text }
export default utils