import React, { useCallback, useEffect, useRef, useState } from 'react'
import Wrapper from '../../layout/Wrapper'

import SubNav from '../../SubNav'
import ProgressBar from '../../ui/ProgressBar'
import TransactionsMenu from '../../ui/TransactionsMenu'
import { useParams } from 'react-router'
import { useProfile } from '../../../contexts/UserContext'
import { useAuth0 } from '@auth0/auth0-react'
import { ServiceTransactions } from '../../../services/ServiceTransactions'
import { useQuery } from '@tanstack/react-query'
import SmallLoading from '../../ui/SmallLoading'

export default function PartiesInvolved() {
  const { fileid } = useParams()
  const { profile } = useProfile()
  const { getAccessTokenSilently } = useAuth0()
  const TransactionsfetchController = useRef(null)
  const getDocuments = useCallback(async () => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUDIENCE,
    }).catch((err) => null)

    const serviceTransactions = new ServiceTransactions(token)
    if (!TransactionsfetchController.current) {
      TransactionsfetchController.current =
        serviceTransactions.getController().controller
    }
    return serviceTransactions.get(fileid)
  }, [fileid, getAccessTokenSilently])

  const NotesQuery = useQuery({
    queryKey: ['parties', fileid],
    queryFn: getDocuments,
    refetchOnWindowFocus: false,
    enabled: !!profile,
  })

  const { escrowOfficer, lender, listingAgent, sellingAgent, buyer, seller } =
    NotesQuery.data || {}

  useEffect(() => {
    return () =>
      TransactionsfetchController.current &&
      TransactionsfetchController.current.abort()
  }, [])

  const partiesHTML = (data, profile) => {
    return (
      data &&
      data.map((item, index) => {
        return (
          item.name && (
            <div key={index} className='mb-5'>
              <h3 className='text-primary font-semibold text-lg'>
                {item?.name?.trim() || item?.company_name || ''}{' '}
                {profile && ` (${profile})`}
              </h3>
              <p className='text-base'>{item?.email}</p>
              <p className='text-base'>{item?.cell}</p>
            </div>
          )
        )
      })
    )
  }
  return (
    <>
      {NotesQuery?.isLoading ? (
        <SmallLoading />
      ) : (
        <>
          {partiesHTML(escrowOfficer, 'Escrow Officer')}
          {partiesHTML(lender, 'Lender')}
          {partiesHTML(listingAgent, 'Listing Agent')}
          {partiesHTML(sellingAgent, 'Selling Agent')}
          {partiesHTML(buyer, 'Buyer')}
          {partiesHTML(seller, 'Seller')}
        </>
      )}
    </>
  )
}
