import React from 'react'
import Wrapper from '../../components/layout/Wrapper'
import { AiContent } from '../../components/pages/AiSlots'
import SubNav from '../../components/SubNav'

export default function AxiomAI() {
  return (
    <Wrapper>
      <SubNav url='/user/marketing-lobby' />
      <AiContent />
    </Wrapper>
  )
}
