import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { get_storage } from '../helpers'

export const UserContext = createContext()

export const UserProvider = ({ children, userData }) => {
  const [profile, setProfile] = useState(get_storage('profile'))

  const saveProfile = useCallback((data) => {
    setProfile(data)
  }, [])

  const value = useMemo(
    () => ({
      profile,
      saveProfile,
    }),
    [profile, saveProfile]
  )

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export const useProfile = () => {
  const context = useContext(UserContext)

  if (!context) {
    throw Error('AuthContext must be used inside an AuthContextProvider')
  }

  return context
}
