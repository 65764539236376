import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAi } from '../../../contexts/AiContext'
import { useClaims } from '../../../contexts/ClaimsContext'
import { useConnections } from '../../../contexts/ConnectionContext'
import { useDistribute } from '../../../contexts/DistributeContext'
import { useLimits } from '../../../contexts/LimitsContext'
import { useProfile } from '../../../contexts/UserContext'
import { open_url_tab } from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { CircleCheckToggle } from '../../form/CircleCheckToggle'
import uiStyles from '../../ui/uiStyles'
import utils from './utils'

export const AiDistributeAction = ({
  text_to_copy,
  copy_type,
  action_type,
}) => {
  const { axiomFetch, nodeProdFetch } = useAxiomFetch()
  const [copied, setCopied] = useState(false)
  const { distribute, saveDistribute } = useDistribute()
  const { ai, saveAi } = useAi()
  const { claims } = useClaims()
  const { connections, saveConnections } = useConnections()
  const { saveLimits } = useLimits()
  const { profile } = useProfile()

  const [messageSent, setMessageSent] = useState({})
  const navigate = useNavigate()
  const type =
    (distribute?.type === 'social'
      ? distribute?.social || 'facebook'
      : distribute?.type) || 'social'

  const content = distribute?.content?.[type]

  const handle_msg = (status, message, type, close) => {
    setMessageSent({ ...messageSent, status, message, type })
    if (close) {
      setTimeout(() => {
        setMessageSent({ ...messageSent, status: 'done', message: '' })
      }, 3000)
    }
  }

  useEffect(() => {
    const controller = new AbortController()
    const get_pages = async () => {
      const pages = await axiomFetch(
        '/social/facebook/pages',
        'GET',
        {},
        {},
        false,
        controller?.signal
      )
      if (!controller?.signal.aborted) {
        saveDistribute((prev) => ({
          ...prev,
          content: {
            ...prev?.content,
            [type]: { ...(prev?.content?.[type] || {}), pages },
          },
        }))
      }
    }

    get_pages()
    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 2000)
    }
  }, [copied])

  const media_post = async () => {
    handle_msg('loading_msg', 'Sending out Post...', action_type)
    if (
      distribute?.social === 'facebook' ||
      distribute?.social === 'instagram'
    ) {
      const body = {
        message: content?.message || null,
        photo: content?.photo_url || null,
      }

      if (content?.photo_url) {
        const formated_image = await nodeProdFetch({
          url: '/node_to_oracle/api/v1/images/format',
          method: 'POST',
          body: {
            image_url: content?.photo_url,
            format: 'jpg',
            compress_to: '4000000',
            // resize:
            //   distribute?.social === 'facebook'
            //     ? { width: 1200, height: 630 }
            //     : {},
          },
        })
        if (formated_image?.[0]) {
          body.photo = formated_image?.[0]
        }
      }
      console.log(content)

      if (content?.selected_page_id && content?.selected_page_id !== '0') {
        body.page_id = content?.selected_page_id
      } else {
        if (claims.is_internal === 'Y') {
          if (content?.selected_page_id) {
            body.page_id = content?.selected_page_id
          }
        } else {
          handle_msg('failure_msg', 'Select Page', action_type, true)
          return
        }
      }
      if (distribute?.social === 'instagram') {
        const ig_id = (content?.pages || [])?.find(
          (item) => item?.page_id == content?.selected_page_id
        )?.instagram_id
        body.ig_id = ig_id
      }
      if (body?.message && claims?.is_internal === 'Y') {
        body.message = `${profile?.CONT_NAME}: ${body?.message}`
      }
      // if (body?.message) {
      //   body.message = body?.message?.replace(/\s*#\S+/g, '')
      // }

      let scheduled_sec = Math.floor(
        new Date(content?.schedule_date).valueOf() / 1000
      )
      let time_1 = Math.floor(new Date().valueOf() / 1000 + 1 * 60)
      if (scheduled_sec > time_1) {
        body.scheduled = new Date(content?.schedule_date).toISOString()
      }
      body.is_internal = claims?.is_internal

      const data = await axiomFetch(
        `/social/${distribute?.social}/post`,
        'POST',
        body
      )
      let new_limits
      if (claims.is_internal !== 'Y') {
        new_limits = await axiomFetch('/marketing/content/limits')
      }
      if (!data.error && new_limits) {
        saveLimits(new_limits)
      }
      const clear_post = () => {
        if (claims?.is_internal === 'Y') {
          navigate('/user/internal/facebook')
        }
      }
      console.log(data)
      if (data?.id) {
        console.log(data)
        saveDistribute((prev) => ({
          ...prev,
          content: {
            ...prev?.content,
            [type]: {
              ...prev?.content?.[type],
              posted_id: data?.post_id || data?.id,
              selected_page_id: content?.selected_page_id,
            },
          },
        }))
        handle_msg(
          'success_msg',
          'Message Successfully Posted!',
          action_type,
          true
        )
        clear_post()
      } else if (data?.msg === 'success') {
        console.log('just sent back success')
        handle_msg(
          'success_msg',
          'Message Successfully Posted!',
          action_type,
          true
        )
        clear_post()
      } else if (data?.msg === 'facebook post was scheduled.') {
        handle_msg(
          'success_msg',
          'Message Successfully Scheduled!',
          action_type,
          true
        )
      } else if (
        data?.error === 'There is no page token for this user and page_id'
      ) {
        handle_msg(
          'failure_msg',
          `Unable to Post to ${distribute?.social}, action_type, `,
          true
        )
        setTimeout(() => {
          saveConnections((prev) => ({
            ...prev,
            [distribute?.social]: false,
          }))
        }, [3000])
      } else if (data?.error === `unable to post to ${distribute?.social}`) {
        handle_msg(
          'failure_msg',
          `Unable to Post to ${distribute?.social}`,
          action_type,
          true
        )
        setTimeout(() => {
          saveConnections((prev) => ({
            ...prev,
            [distribute?.social]: false,
          }))
        }, [3000])
      } else if (
        data?.facebook_error?.message ===
        '(#100) The specified scheduled publish time was invalid.'
      ) {
        handle_msg(
          'failure_msg',
          'scheduled date must be between 10 min and 75 days',
          action_type,
          true
        )
      } else if (data?.error) {
        handle_msg('failure_msg', data.error, action_type, true)
      }
    }
  }

  const email = async () => {
    console.log('in email')
    console.log(content?.list_type || 'list')
    if ((content?.list_type || 'list') === 'single') {
      console.log('single')
      const msg = {
        date: content?.schedule_date
          ? new Date(content?.schedule_date)?.toISOString()
          : new Date()?.toISOString(),
        text: `${content?.message || ''}`,
        subject: `${content?.headline || ''}`,
        selected: [
          {
            name: content?.list_single_name || '',
            email: content?.list_single_email || '',
          },
        ],
        from: profile?.CONT_EMAIL,
      }
      handle_msg('loading_msg', 'Sending Email...', action_type)
      await axiomFetch('/services/email/subuser/email/schedule', 'POST', msg)
      const new_limits = await axiomFetch('/marketing/content/limits')
      saveLimits(new_limits)
      handle_msg('success_msg', 'Email Sent', action_type, true)
    } else if ((content?.list_type || 'list') === 'list') {
      const fetch_lists = async () => {
        handle_msg('loading_msg', 'Sending Emails...', action_type)
        let data = await axiomFetch(
          `/client/listings/lists/${content?.list_id}`,
          'GET'
        )
        data = data
          ?.filter((d) => {
            if (d.STATUS === 'A' && d?.EMAIL) {
              return true
            } else {
              return false
            }
          })
          ?.map((da) => {
            return {
              name: da?.NM || '',
              email: da?.EMAIL,
            }
          })
        console.log(data)
        return data || []
      }
      const listSelected = await fetch_lists()
      if (!listSelected?.length) {
        return handle_msg(
          'failure_msg',
          'List must have an email',
          action_type,
          true
        )
      }
      const msgList = {
        date: content?.schedule_date
          ? new Date(content?.schedule_date)?.toISOString()
          : new Date()?.toISOString(),
        text: `${content?.message || ''}`,
        subject: `${content?.headline || ''}`,
        selected: listSelected,
        from: profile?.CONT_EMAIL,
      }

      await axiomFetch(
        '/services/email/subuser/email/schedule',
        'POST',
        msgList
      )
      const new_limits = await axiomFetch('/marketing/content/limits')
      saveLimits(new_limits)
      handle_msg('success_msg', 'Emails Sent', action_type, true)
    }
  }

  const text = async () => {
    handle_msg(
      'loading_msg',
      content?.schedule_date
        ? 'Scheduling Conversations...'
        : 'Sending Conversations...',
      action_type
    )
    let conversation_list = {
      conversations: [],
    }
    let list_items
    if ((content?.list_type || 'list') === 'list') {
      list_items = await axiomFetch(
        `/client/listings/lists/${content?.list_id}`
      )
      list_items = list_items?.filter(
        (item) => item?.CELL && item.STATUS === 'A'
      )
      if (!list_items?.length) {
        handle_msg(
          'failure_msg',
          'List must have atleast one phone number',
          action_type,
          true
        )
        return 'error'
      }
    } else {
      list_items = [
        {
          NM: content?.list_single_name,
          CELL: content?.list_single_number,
        },
      ]
    }

    const add_conversations = async (item) => {
      const user_msgs = []
      if (content?.photo_url) {
        const mediaSids = await axiomFetch(
          '/marketing/conversations/media/create_url',
          'POST',
          { urls: [content?.photo_url] },
          {},
          false
        )
        user_msgs?.push({ mediaSid: mediaSids?.[0] || null })
      }

      if (content?.message) {
        let new_msg = utils.format_text_message(
          content?.message,
          item,
          ai,
          false
        )
        user_msgs.push({
          msg: new_msg,
        })
      }
      if (!user_msgs?.length) {
        handle_msg(
          'failure_msg',
          'Must send at least one message/media.',
          action_type,
          true
        )
        return 'error'
      }
      conversation_list.conversations.push({
        name: item?.NM || item?.CELL,
        sched_dt: content?.schedule_date
          ? new Date(content?.schedule_date)?.toISOString()
          : '',
        attributes:
          (content?.list_type || 'list')?.toLowerCase() === 'list'
            ? {
                campaign: content?.list_name || '',
                start_index: 0,
              }
            : {
                start_index: 0,
              },
        // replace_fields: {
        //   contactName: item?.NM,
        // },
        participants: [
          {
            identity: profile?.CONT_ID?.toString(),
            type: 'user',
            order: 2,
            msgs: user_msgs,
          },
          {
            phone:
              item?.CELL?.replace(/\D/g, '')?.slice(0, 10)?.toString() || '',
            type: 'receiver',
            order: 1,
          },
        ],
        proxy_phone: '6234049037',
      })
    }
    for (const item of list_items) {
      await add_conversations(item)
    }
    if (!conversation_list?.conversations?.length) {
      return 'error'
    }
    console.log(conversation_list)
    await axiomFetch(
      '/marketing/conversations/axiom/create',
      'POST',
      conversation_list
    )
    handle_msg(
      'success_msg',
      content?.schedule_date
        ? 'Scheduled Conversations'
        : 'Sent Conversations!',
      action_type,
      true
    )
    return true
  }

  const components = {
    copy: {
      action: function () {
        navigator.clipboard.writeText(text_to_copy || content?.message)
        setCopied(true)
      },
      component: function () {
        return (
          <div className='text-right flex justify-end gap-2 items-center'>
            {
              <div
                className={`bg-gray-200 ${
                  copied ? 'opacity-50' : 'opacity-0'
                } text-black p-2 rounded transition-all duration-500 `}
              >
                <span className='capitilize'>{copy_type || ''}</span> copied!
              </div>
            }
            <button
              className={uiStyles.hover_btn}
              onClick={() => {
                this.action()
              }}
            >
              Copy
            </button>
          </div>
        )
      },
    },
    social: {
      action: async function () {
        try {
          if (!content?.message && !content?.photo_url) {
            return handle_msg(
              'failure_msg',
              'Must have a message or photo to post',
              action_type,
              true
            )
          }

          return await media_post()
        } catch (error) {
          return console.log(error)
        }
      },
      component: function () {
        return (
          <div>
            {messageSent?.type === action_type ? (
              <div className={`${uiStyles[messageSent.status]} mb-[20px]`}>
                {messageSent.message}
              </div>
            ) : (
              <div>
                {content?.posted_id ? (
                  <div className='flex flex-col'>
                    <div className='flex flex-col '>
                      <div className='mb-[10px] mx-3'>
                        <p className={uiStyles.text_black}>
                          Current page:{' '}
                          {
                            (content?.pages || [])?.find(
                              (item) =>
                                item?.page_id === content?.selected_page_id
                            )?.name
                          }
                        </p>
                      </div>
                      <div className='flex justify-end gap-[10px]'>
                        {(content?.pages || [])?.find(
                          (item) => item?.page_type === 'active'
                        )?.page_id !== content?.selected_page_id ? (
                          <div className='flex flex-col mr-[10px] items-center'>
                            <label className={`text-[11px]`}>
                              make default page
                            </label>
                            <CircleCheckToggle
                              name={`make_default`}
                              type='toggle'
                              toggle_left={false}
                              toggle_left_label={''}
                              toggle_right_label={''}
                              inputValue={content || {}}
                              handle_change={async (e) => {
                                let original_active = (
                                  content?.pages || []
                                )?.find(
                                  (item) => item?.page_type === 'active'
                                )?.page_id
                                let new_active = content?.selected_page_id
                                let make_default = content?.make_default
                                saveDistribute((prev) => {
                                  return {
                                    ...prev,
                                    content: {
                                      ...prev?.content,
                                      [type]: {
                                        ...(prev?.content?.[type] || []),
                                        old_default:
                                          prev?.content?.[type]
                                            ?.selected_page_id,
                                        make_default:
                                          prev?.content?.[type]
                                            ?.make_default === 'Y'
                                            ? 'N'
                                            : 'Y',
                                      },
                                    },
                                  }
                                })

                                await axiomFetch(
                                  '/social/facebook/make_default',
                                  'POST',
                                  {
                                    page_id:
                                      make_default === 'Y'
                                        ? original_active
                                        : new_active,
                                  }
                                )
                              }}
                            />
                          </div>
                        ) : (
                          ''
                        )}

                        <button
                          className={`${uiStyles.white_btn} text-[12px] px-[8px]`}
                          onClick={() => {
                            saveDistribute((prev) => ({
                              ...prev,
                              content: {
                                ...prev?.content,
                                [type]: {
                                  ...prev?.content?.[type],
                                  posted_id: '',
                                },
                              },
                            }))
                          }}
                        >
                          Change page
                        </button>

                        <button
                          className={`${uiStyles.white_btn} text-[12px] px-[8px]`}
                          onClick={() => {
                            saveDistribute((prev) => ({
                              ...prev,
                              content: {
                                ...prev?.content,
                                [type]: {
                                  ...prev?.content?.[type],
                                  posted_id: '',
                                  photo_url: null,
                                  image_upload: {
                                    gallery: {
                                      active: {
                                        url: null,
                                      },
                                    },
                                  },
                                  message: '',
                                  long_messag: '',
                                },
                              },
                            }))

                            saveAi((prev) => ({
                              ...prev,
                              assistant: {
                                ...prev?.assistant,
                                is_assistant: 'N',
                              },
                              came_from_mkt: false,
                              state: {
                                ...prev?.state,
                                message: '',
                                long_message: '',
                                short_message: '',
                                subject: '',
                                original_prompt: '',
                                last_prompt: '',
                                prompt: '',
                                // stopHeight: 500 * height_change,
                                isLoading: false,
                                run: false,
                              },
                              image_upload: {
                                ...prev?.image_upload,
                                upload_url: '',
                                gallery: {
                                  ...prev?.image_upload?.gallery,
                                  active: {
                                    ...prev?.image_upload?.gallery?.active,
                                    url: '',
                                  },
                                },
                              },
                            }))
                            if (ai?.came_from_mkt) {
                              navigate('/user/marketing-material')
                            } else {
                              navigate('/user/ai/assistant')
                            }
                          }}
                        >
                          New Post
                        </button>

                        <button
                          className={`${uiStyles.hover_btn} text-[12px] px-[8px]`}
                          onClick={() => {
                            if (claims?.is_internal === 'Y') {
                              return open_url_tab(
                                'https://www.facebook.com/driggsinsider'
                              )
                            }
                            saveDistribute((prev) => ({
                              ...prev,
                              content: {
                                ...prev?.content,
                                [type]: {
                                  ...prev?.content?.[type],
                                  view_page_id: content?.selected_page_id,
                                },
                              },
                            }))
                            navigate('/user/ai/media_posts')
                          }}
                        >
                          View Post
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className='flex justify-end gap-[10px]'>
                      {content?.pages?.length ? (
                        <select
                          className={uiStyles.select}
                          value={content?.selected_page_id || '0'}
                          onChange={(e) => {
                            console.log(e.target.value)
                            saveDistribute((prev) => ({
                              ...prev,
                              content: {
                                ...prev?.content,
                                [type]: {
                                  ...prev?.content?.[type],
                                  selected_page_id: e.target.value,
                                },
                              },
                            }))
                          }}
                        >
                          {claims?.is_internal !== 'Y' ? (
                            <option key='not_selected' value='0'>
                              Select Page
                            </option>
                          ) : (
                            ''
                          )}
                          {claims?.is_internal === 'Y' ? (
                            <option key='driggs_insider'>Driggs Insider</option>
                          ) : (
                            content?.pages?.map((page) => {
                              if (type === 'instagram') {
                                if (!page.instagram_id) {
                                  return null
                                }
                                return (
                                  <option
                                    key={page.page_id}
                                    value={page.page_id}
                                  >
                                    {page.instagram_name}
                                  </option>
                                )
                              } else {
                                return (
                                  <option
                                    key={page.page_id}
                                    value={page.page_id}
                                  >
                                    {page.name}
                                  </option>
                                )
                              }
                            })
                          )}
                        </select>
                      ) : (
                        ''
                      )}
                      <button
                        disabled={claims?.client_proxy_login}
                        className={`${
                          claims?.client_proxy_login
                            ? uiStyles.hover_btn_greyed_out
                            : uiStyles.hover_btn
                        }`}
                        onClick={async () => {
                          await this.action()
                        }}
                      >
                        {new Date(content?.schedule_date).valueOf() >
                        new Date().valueOf()
                          ? 'Schedule'
                          : 'Post'}
                      </button>
                    </div>
                    <div className='text-center'>
                      {claims?.client_proxy_login
                        ? 'Posting on behalf of a client is not allowed.'
                        : ''}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )
      },
    },
    email: {
      action: async function () {
        try {
          if (
            (content?.list_type || 'list') === 'single' &&
            !content?.list_single_email
          ) {
            return handle_msg(
              'failure_msg',
              'Must specify an email.',
              action_type,
              true
            )
          }
          if ((content?.list_type || 'list') === 'list' && !content?.list_id) {
            return handle_msg(
              'failure_msg',
              'Must select a list',
              action_type,
              true
            )
          }
          console.log('hello')
          return await email()
        } catch (error) {
          return console.log(error)
        }
      },
      component: function () {
        return (
          <div>
            {messageSent?.type === action_type ? (
              <div className={`${uiStyles[messageSent.status]} mb-[20px]`}>
                {messageSent.message}
              </div>
            ) : (
              <div>
                <div className='flex justify-end'>
                  <button
                    disabled={claims?.client_proxy_login}
                    className={`${
                      claims?.client_proxy_login
                        ? uiStyles.hover_btn_greyed_out
                        : uiStyles.hover_btn
                    }`}
                    onClick={async () => {
                      await this.action()
                    }}
                  >
                    {new Date(content?.schedule_date).valueOf() >
                    new Date().valueOf()
                      ? 'Schedule'
                      : 'Send'}
                  </button>
                </div>
                <div className='text-center'>
                  {claims?.client_proxy_login
                    ? 'Emailing on behalf of a client is not allowed.'
                    : ''}
                </div>
              </div>
            )}
          </div>
        )
      },
    },
    text: {
      action: async function () {
        try {
          if (
            (content?.list_type || 'list') === 'single' &&
            !content?.list_single_number &&
            !content?.list_single_name
          ) {
            return handle_msg(
              'failure_msg',
              'Must specify a name and phone number.',
              action_type,
              true
            )
          }
          if ((content?.list_type || 'list') === 'list' && !content?.list_id) {
            return handle_msg(
              'failure_msg',
              'Must select a list',
              action_type,
              true
            )
          }
          return await text()
          // return navigate('/user/conversations')
        } catch (error) {
          return console.log(error)
        }
      },
      component: function () {
        return (
          <div>
            {messageSent?.type === action_type ? (
              <div className={`${uiStyles[messageSent.status]} mb-[20px]`}>
                {messageSent.message}
              </div>
            ) : (
              <div>
                <div className='flex justify-end'>
                  <button
                    disabled={claims?.client_proxy_login}
                    className={`${
                      claims?.client_proxy_login
                        ? uiStyles.hover_btn_greyed_out
                        : uiStyles.hover_btn
                    }`}
                    onClick={async () => {
                      await this.action()
                    }}
                  >
                    {new Date(content?.schedule_date).valueOf() >
                    new Date().valueOf()
                      ? 'Schedule'
                      : 'Send'}
                  </button>
                </div>
                <div className='text-center'>
                  {claims?.client_proxy_login
                    ? 'Texting on behalf of a client is not allowed.'
                    : ''}
                </div>
              </div>
            )}
          </div>
        )
      },
    },
  }

  return <>{components[action_type || type]?.component()}</>
}
