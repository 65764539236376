import React from 'react'
import Wrapper from '../../components/layout/Wrapper'
import { MarketingMaterialSubmit } from '../../components/pages/marketingMaterials/MarketingMaterialSubmit'
import SubNav from '../../components/SubNav'
import { useMktReqs } from '../../contexts/MktReqsContext'

export default function PageMarketingMaterialSubmit() {
  const { mktReqs, saveMktReqs } = useMktReqs()
  return (
    <>
      <Wrapper>
        <SubNav url={'/user/marketing-material/layouts/customize'} />
        <MarketingMaterialSubmit
          state={mktReqs}
          setState={saveMktReqs}
          title={''}
        />
      </Wrapper>
    </>
  )
}
