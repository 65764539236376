import { useState } from 'react'
import Modal from '../../Modal'
import ListItem from '../../ui/ListItem'
import uiStyles from '../../ui/uiStyles'

const WeekenderSplash = () => {
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('')

  const modals = {
    weekender_splash: (
      <div>
        Click the Image after the Weekenders update on Thursday to generate your
        weekender!
        <div className='flex flex-col mt-[10px]'>
          <button
            className={uiStyles.white_btn}
            onClick={() => {
              setShowModal(false)
            }}
          >
            Close
          </button>
        </div>
      </div>
    ),
  }
  return (
    <div>
      <ListItem
        image={
          'https://driggstitle.com/reviews/node/node_to_oracle/a_file_1715644077370.jpeg'
        }
        click_me
        select={() => {
          setShowModal(true)
          setModalType('weekender_splash')
        }}
        title={`Check out this weekend's happenings!`}
        subtitle={'Updates on Thursdays.'}
        content={`swing by then to see all the latest events for the weekend.`}
      />
      {showModal ? (
        <Modal
          modalName={modalType}
          showModal={showModal}
          setShowModal={setShowModal}
          height='h-[300px]'
        >
          {modals?.[modalType] || ''}
        </Modal>
      ) : (
        ''
      )}
    </div>
  )
}

export default WeekenderSplash
