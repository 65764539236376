import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import useAxiomFetch from '../hooks/useAxiomFetch'

export const MktReqsContext = createContext()

export const MktReqsProvider = ({ children, userData }) => {
  const { axiomFetch, nodeProdFetch } = useAxiomFetch()
  const navigate = useNavigate()
  const [mktReqs, setMktReqs] = useState({
    create_order: {
      order_type: 2,
      // sub_topic_property: 24,
      sub_topic_promotion: 61,
      slot_group_type: 1,
      home_url: '/user',
    },
    market_data: {
      values: {
        days_back: 90,
        // subdivision: '',
        zip: '',
        city: '',
        property_type: 'All',
      },
      types: {
        days_back: 'int',
        subdivision: '',
        city: '',
        zip: 'int_str5',
        property_type: 'options',
      },
      names: {
        days_back: 'Time Period Days',
        subdivision: 'Subdivision',
        city: 'City',
        zip: 'Zip Code',
        property_type: 'Property Type',
      },
      placeholders: {
        subdivision: 'Alta Mesa',
        city: 'i.e. Mesa',
        zip: '85201',
      },
      option_names: {
        property_type: ['All', 'Single Family', 'Condo/Townhomes'],
      },
      option_values: {
        property_type: ['a', 's', 'c'],
      },
      maximums: {
        days_back: 365,
      },
    },
  })

  const saveMktReqs = useCallback((data) => {
    setMktReqs(data)
  }, [])

  const load_session_from_layout = async ({
    layout,
    mls_id,
    parent_url = '',
    prev_parent_url = '',
    switch_layout = false,
    calc_id = 0,
  }) => {
    setMktReqs((prev) => ({
      ...prev,
      create_order: {
        ...prev?.create_order,
        current_selection: layout,
      },
    }))
    let session = { sess_info: [] }
    const get_session = async () => {
      let try_count = 0
      session = await axiomFetch(
        `/marketing/materials/${switch_layout ? 'flyer/switch' : 'flyer'}`,
        'POST',
        {
          layout_id: layout?.layout_id,
          mls_id,
          sess_id: mktReqs?.create_order?.session?.sess_id,
          sess_info: mktReqs?.create_order?.session?.sess_info,
          double_sided: layout?.double_sided === 'Y' ? true : false,
          loc_id: layout?.loc_id || 0,
          calc_id: calc_id || 0,
        }
      )

      console.log('session', session)
      if (
        session?.sess_info?.some((item) =>
          item?.saved_val?.includes('Unexpected token')
        ) &&
        try_count < 3
      ) {
        try_count++
        return await get_session()
      } else {
        return session
      }
    }
    session = await get_session()
    if (session?.sess_info) {
      session.sess_info = session?.sess_info?.map((item) => {
        if (item?.orig_val === 'None') {
          item.orig_val = ''
          item.saved_val = ''
        }
        return item
      })
    }
    if (
      session?.sess_info
        ?.filter(
          (item) =>
            item?.field_id !== 30064 &&
            item?.field_id !== 55779 &&
            item?.allow_edit === 'Y'
        )
        ?.some((item) => !item.saved_val)
    ) {
      session.empty_fields = true
      session.edit_fields_type = 'data'
    }
    session.layouts = [layout]
    if (mls_id) {
      session.mls_id = mls_id
    }
    console.log('hello', session)
    setMktReqs((prev) => ({
      ...prev,
      create_order: {
        ...prev?.create_order,
        current_layout_selection: layout,
        current_selection: session,
        session: { ...layout, ...session },
        sessions: [{ ...layout, ...session }],
        switch_layout: false,
        selected_fields: '',
      },
    }))
    setTimeout(() => {
      navigate('/user/marketing-material/layouts/customize', {
        state: { parent: parent_url, prevParent: prev_parent_url },
      })
    }, 0)
  }

  const generate_flyer_session = async ({
    layout,
    mls_id = null,
    switch_layout = false,
    calc_id = 0,
    sess_id = null,
    sess_info = null,
  }) => {
    let session = { sess_info: [] }
    const get_session = async () => {
      let try_count = 0
      session = await axiomFetch(
        `/marketing/materials/${switch_layout ? 'flyer/switch' : 'flyer'}`,
        'POST',
        {
          layout_id: layout?.layout_id,
          mls_id,
          sess_id: sess_id,
          sess_info: sess_info,
          double_sided: layout?.double_sided === 'Y' ? true : false,
          loc_id: layout?.loc_id || 0,
          calc_id: calc_id || 0,
        }
      )

      if (
        session?.sess_info?.some((item) =>
          item?.saved_val?.includes('Unexpected token')
        ) &&
        try_count < 3
      ) {
        try_count++
        return await get_session()
      } else {
        return session
      }
    }
    session = await get_session()
    if (session?.sess_info) {
      session.sess_info = session?.sess_info?.map((item) => {
        if (item?.orig_val === 'None') {
          item.orig_val = ''
          item.saved_val = ''
        }
        return item
      })
    }
    console.log('session', session)
    return { ...layout, ...session, mls_id, switch_layout, calc_id }
  }

  const replace_fields = async (html, sess_info, options = {}) => {
    for (const field of sess_info) {
      if (field?.tag_name === '{{holidy_overlay_area}}') {
        html = html.replaceAll(
          field?.tag_name,
          options?.theme_html ||
            options?.global_theme ||
            options?.layout_text_theme ||
            ''
        )
      } else {
        let new_field = field?.saved_val || ''
        if (field?.image_field === 'Y' && new_field) {
          if (
            new_field !== 'https://driggstitle.com/reviews/Ax_Engage/dbc/t1.png'
          ) {
            new_field = new_field
            // await compress_img(new_field)
          }
        }
        html = html?.replaceAll(field?.tag_name, new_field)
      }
    }
    return html
  }

  const generate_pdf = async (options) => {
    console.log('options', options)
    return await nodeProdFetch({
      url: '/node_to_oracle/api/v1/pdf/create',
      method: 'POST',
      body: {
        ...options,
        html: options?.html || options?.layout_text,
        height: options?.prt_height || 11.25,
        width: options?.prt_width || 8.75,
        dpi: options?.prt_dpi || options?.dpi || 300,
        type: options?.type || 'pdf',
        upload_type: options?.upload_type || 'reviews',
        // height: html?.[0]?.prt_height || 8.5,
        // width: html?.[0]?.prt_width || 11,
        // dpi: html?.[0]?.prt_dpi || 300,
      },
    })
  }

  const merge_pdf = async (options) => {
    return await nodeProdFetch({
      url: '/node_to_oracle/api/v1/pdf/merge',
      method: 'POST',
      body: {
        urls: options?.urls,
        file_name: options?.file_name || 'all_materials',
      },
    })
  }

  const value = useMemo(
    () => ({
      mktReqs,
      saveMktReqs,
      load_session_from_layout,
      generate_flyer_session,
      replace_fields,
      generate_pdf,
      merge_pdf,
    }),
    [mktReqs, saveMktReqs, generate_flyer_session, replace_fields, generate_pdf]
  )

  return (
    <MktReqsContext.Provider value={value}>{children}</MktReqsContext.Provider>
  )
}

export const useMktReqs = () => {
  const context = useContext(MktReqsContext)

  if (!context) {
    throw Error('ListingContext must be used inside an ListingContextProvider')
  }

  return context
}
