import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

export const ReceiverContext = createContext()

export const ReceiverProvider = ({ children, userData }) => {
  const [receiver, setReceiver] = useState({
    homes: {
      min_price: '',
      max_price: '',
      bedrooms: 'any',
      bathrooms: 'any',
      for_sale: 'Y',
    },
  })

  const saveReceiver = useCallback((data) => {
    setReceiver(data)
  }, [])

  const value = useMemo(
    () => ({
      receiver,
      saveReceiver,
    }),
    [receiver, saveReceiver]
  )

  return (
    <ReceiverContext.Provider value={value}>
      {children}
    </ReceiverContext.Provider>
  )
}

export const useReceiver = () => {
  const context = useContext(ReceiverContext)

  if (!context) {
    throw Error('ListingContext must be used inside an ListingContextProvider')
  }

  return context
}
