import React, { useState } from 'react'
import icons from '../../../assets/images/connection_icons'
import { useConnections } from '../../../contexts/ConnectionContext'
import { useDistribute } from '../../../contexts/DistributeContext'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import uiStyles from '../../ui/uiStyles'
export const Disconnect = ({ type, setShowModal }) => {
  const { axiomFetch } = useAxiomFetch()
  const { saveConnections } = useConnections()
  const { saveDistribute } = useDistribute()
  const [isLoading, setIsLoading] = useState(false)
  return (
    <div>
      <p className='text-center text-[20px] mb-[10px]'>
        Are you sure you want to disconnect from:
      </p>
      <div className='flex flex-col items-center'>
        <p className='capitalize text-black text-[25px] font-bold mb-[10px]'>
          {type}
        </p>
        {isLoading ? (
          <SmallLoadingRel />
        ) : (
          <img
            src={icons[type]}
            className='w-[75px]'
            alt={`${type} media connection`}
          />
        )}
      </div>
      <div className='flex justify-center gap-[15px] mt-[20px]'>
        <button
          className={`${uiStyles.white_btn}`}
          onClick={() => {
            setShowModal(false)
          }}
        >
          Cancel
        </button>
        <button
          className={`${uiStyles.red_btn}`}
          onClick={async () => {
            setIsLoading(true)
            saveConnections((prev) => ({ ...prev, [type]: false }))
            await axiomFetch(`/usage/disconnect/${type}`, 'POST')
            saveDistribute((prev) => ({
              ...prev,
              content: {
                ...prev?.content,
                [type]: { ...prev?.content?.[type], pages: [] },
              },
            }))
            setIsLoading(false)
            setShowModal(false)
          }}
        >
          Disconnect
        </button>
      </div>
    </div>
  )
}
