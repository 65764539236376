import React, { useCallback, useEffect, useRef, useState } from 'react'
import SubNav from '../SubNav'
import TitleIcon from '../ui/TitleIcon'
import SwipeConfig from '../ui/SwipeConfig'
import ListItem from '../ui/ListItem'
import { useAuth0 } from '@auth0/auth0-react'
import { ServiceClientGuides } from '../../services/ServiceClientGuides'
import { useQuery } from '@tanstack/react-query'
import Badge from '../ui/Badge'
import Loading from '../Loading'
import { useProfile } from '../../contexts/UserContext'
import Wrapper from '../layout/Wrapper'
import GuideBuyer from '../../assets/images/DTA_Buyer_Axiom.jpg'
import GuideSeller from '../../assets/images/DTA_Seller_Axiom.jpg'
import GuideAll from '../../assets/images/DTA_ALL_Axiom.jpg'
import ButtonWhite from '../ui/ButtonWhite'
import OrderRequest from '../OrderRequest'
import Modal from '../Modal'
import GuideSend from '../GuideSend'
import SmallLoading from '../ui/SmallLoading'
import EmailVerify from './AI/EmailVerify'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import useHelpers from '../../hooks/useHelpers'
export default function Guides() {
  const [selected, setSelected] = useState(1)
  const { open_url } = useHelpers()
  const { axiomFetch } = useAxiomFetch()
  const { getAccessTokenSilently } = useAuth0()
  const { profile } = useProfile()
  const [showModal, setShowModal] = useState(null)
  const [modalType, setModalType] = useState('')
  const [currentOrder, setCurrentOrder] = useState({})
  const fetchController = useRef(null)
  const [sendgrid, setSendgrid] = useState({})

  useEffect(() => {
    const sendgrid_verify = async () => {
      const data = await axiomFetch(
        '/services/email/subuser/verify/senders',
        'GET'
      )
      if (data?.length) {
        for (const email of data) {
          if (email?.verified && email?.from_email === profile?.CONT_EMAIL) {
            setSendgrid((prev) => ({ ...prev, verified: 'verified' }))
            break
          } else {
            setSendgrid((prev) => ({ ...prev, verified: 'not_verified' }))
          }
        }
      } else {
        setSendgrid((prev) => ({ ...prev, verified: 'no_sender' }))
      }
    }
    sendgrid_verify()
  }, [])

  const getGuides = useCallback(async () => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUDIENCE,
    })

    const serviceClientGuides = new ServiceClientGuides(token)
    if (!fetchController.current) {
      fetchController.current = serviceClientGuides.getController().controller
    }
    return serviceClientGuides.getAll()
  }, [getAccessTokenSilently])

  const { isLoading, isError, data, isFetching } = useQuery({
    queryKey: ['guides'],
    queryFn: getGuides,
    refetchOnWindowFocus: false,
    enabled: !!profile,
  })

  const content = (item) => {
    return (
      <div className='swipeable'>
        <p className='swipeable'>State: {item.STATE}</p>
        <p className='swipeable my-2'>
          <ButtonWhite small={true} clickHandler={() => open_url(item.URL)}>
            View
          </ButtonWhite>
        </p>
      </div>
    )
  }
  const onClose = () => {
    setShowModal(null)
  }
  const rightConfig = (item) => () => {
    return (
      <>
        <span
          className='text-md cursor-pointer'
          onClick={() => {
            setModalType('guide_send')
            setShowModal(true)
            setCurrentOrder(item)
          }}
        >
          Send
        </span>{' '}
        {/* <span className='inline-block mx-2'>|</span>{' '}
        <span
          className='text-md'
          onClick={() => {
            setModalType('guide_order')
            setShowModal(true)
            setCurrentOrder({
              ...item,
              def_ax_qty: 1,
            })
          }}
        >
          Order
        </span> */}
      </>
    )
  }

  const modals = {
    guide_send: (
      <GuideSend
        sendgrid={sendgrid}
        setModalType={setModalType}
        setShowModal={setShowModal}
        item={currentOrder}
      />
    ),
    guide_order: (
      <OrderRequest
        currentOrder={currentOrder}
        setCurrentOrder={setCurrentOrder}
        onClose={onClose}
      />
    ),
    sendgrid_verify: (
      <EmailVerify
        setSendgrid={setSendgrid}
        setModalType={setModalType}
        cancel_to_modal={'guide_send'}
      />
    ),
  }

  const htmlListing =
    data &&
    data?.map((item, index) => (
      <SwipeConfig
        key={index}
        index={index}
        selectedIndex={selected}
        setSelectedIndex={setSelected}
        RightComponents={rightConfig(item)}
      >
        <ListItem
          key={index}
          image={
            item.TYPE && item.TYPE.toLowerCase() === 'seller'
              ? GuideSeller
              : item.TYPE.toLowerCase() === 'buyer'
              ? GuideBuyer
              : GuideAll
          }
          title={<>{item?.NAME}</>}
          content={item?.GUIDE_DESCRIPTION}
          link={false}
        />
      </SwipeConfig>
    ))

  useEffect(() => {
    return () => fetchController.current && fetchController.current.abort()
  }, [])
  if (!isError && isLoading && isFetching) {
    return <SmallLoading />
  }
  console.log(currentOrder)
  return (
    <Wrapper>
      <SubNav />
      <TitleIcon className='mb-5'>Guide</TitleIcon>
      {/* <div className="pt-2 pb-4 px-4">
        <PageSubMenu selected={INNERMENU.Guide} />
      </div> */}
      {htmlListing}
      {showModal && (
        <Modal
          modalName={modalType}
          height='h-[580px]'
          showModal={showModal}
          setShowModal={setShowModal}
        >
          {modals[modalType]}
        </Modal>
      )}
    </Wrapper>
  )
}
