import React, { useRef, useState } from 'react'
import { HiOutlineTrash } from 'react-icons/hi2'
import UserThumb from '../assets/images/profile-silhouette.svg'
// import Checkbox from './form/Checkbox';
import { useProfile } from '../contexts/UserContext'
import useAxiomFetch from '../hooks/useAxiomFetch'

import uiStyles from './ui/uiStyles'
import ImageUpload from './form/ImageUpload'

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]
export default function UpdateRequest({
  listing,
  onClose,
  currentOrder,
  setCurrentOrder,
}) {
  console.log(currentOrder)
  const { axiomFetch } = useAxiomFetch()
  const refOrderRequest = useRef(null)
  //   const refOrderRequest2 = useRef(null);
  const [orderRequest, setOrderRequest] = useState({
    // quantity: currentOrder?.def_ax_qty,
    req_notes: '',
    files: [],
  })
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState('')
  const { profile } = useProfile()

  const handle_images = async (e) => {
    e.preventDefault()
    console.log(e?.target?.files)
    console.log(e?.dataTransfer?.files)
    let limit = 21
    let total =
      (orderRequest?.files?.length || 0) +
      (e?.target?.files?.length || 0) +
      (e?.dataTransfer?.files?.length || 0)
    if (total > limit) {
      console.log('limit reached')
      setError(`Max file limit is ${limit} files.`)
      return
    }
    if (e?.target?.files?.length) {
      console.log('file transfer')

      for (const file of e?.target.files) {
        setOrderRequest((prev) => ({
          ...prev,
          files: [
            ...prev?.files,
            { url: URL.createObjectURL(file), file: file },
          ],
        }))
      }
    } else {
      console.log('data transfer')
      for (const file of e?.dataTransfer?.files) {
        setOrderRequest((prev) => ({
          ...prev,
          files: [
            ...prev?.files,
            { url: URL.createObjectURL(file), file: file },
          ],
        }))
      }
    }
  }

  const request = async (event) => {
    setIsLoading('Sending Request...')
    event.preventDefault()
    // const address = `Address:\n${listing?.PROP_STREET ? listing?.PROP_STREET + '\n' : ''}${listing.PROP_CITY ? listing.PROP_CITY + ', ' : ''}${listing?.PROP_ST ? listing.PROP_ST + ' ' : ''}${listing?.PROP_ZIP ? listing.PROP_ZIP : ''}`

    //     const message = `
    // \n${currentOrder?.axiom_name || currentOrder?.type_name} ${currentOrder?.req_mls_link === 'Y' ? '\n\n' + address : ''}

    // Quantity: ${orderRequest?.quantity || 1}

    // Order Request Details: ${
    //       refOrderRequest.current && refOrderRequest.current.value
    //     }

    // Will Pickup at Printshop: ${
    //       refOrderRequest2.current && refOrderRequest2.current.checked
    //         ? 'Yes'
    //         : 'No'
    //     }`;

    // if (currentOrder?.req_mls_link === 'Y') {
    const body = {
      // message,
      files: orderRequest?.files,
      req_id: currentOrder?.REQ_ID,
      // mls_link: orderRequest?.mls_link || '',
      // mls_id: listing?.MLS_ID,
      req_status: '5',
      req_desc: `Update: ${
        monthNames[new Date().getMonth()]
      } ${new Date().getDate()} \n ${
        refOrderRequest?.current.value
      } \n --------\n ${currentOrder?.REQ_DESC}`,
      // shop: refOrderRequest2.current.checked ? 'Yes': 'No',
    }
    console.log(body)
    console.log(JSON.stringify(body))
    const data = await axiomFetch(
      `/marketing/materials/${currentOrder?.REQ_ID}`,
      'PATCH',
      body,
      {},
      true
    )
    console.log(data)
    // } else {
    //   await axiomFetch('/marketing/materials/order', 'POST', {material: message})
    // }
    setIsLoading('Sent')
    setTimeout(() => {
      setIsLoading('')
    }, [2000])
  }

  return (
    <div>
      <div className='flex justify-end mb-3'>
        {/* <HiOutlineTrash
          onClick={() => {
            onClose();
          }}
          className="backdrop text-xl text-gray-500"
        /> */}
      </div>
      <div className='px-2'>
        <div className='flex mb-3'>
          <div className='w-[40px] h-[40px] mt-1'>
            {(profile.PHOTO_LINK || profile?.account?.photo_url) && (
              <img
                src={
                  profile.PHOTO_LINK || profile?.account?.photo_url || UserThumb
                }
                alt=''
                className='w-full h-full rounded-full object-cover'
              />
            )}
          </div>
          <div className='flex-1 pl-3'>
            <h3 className='text-base text-primary'>{profile.CONT_EMAIL}</h3>
            {/* <p className="text-sm text-gray-500">to adamd@driggstitle.com</p> */}
          </div>
          <div className=' text-sm w-[50px] text-gray-500 overflow-hidden overflow-ellipsis whitespace-nowrap'>
            {`${monthNames[new Date().getMonth()]} ${new Date().getDate()}`}
          </div>
        </div>
        <div className='mb-4'>
          {(currentOrder?.axiom_name || currentOrder?.type_name) && (
            <h2 className='font-semibold text-gray-700 text-xl leading-7'>
              Order: {currentOrder?.axiom_name || currentOrder?.type_name}
            </h2>
          )}
          {/* {currentOrder?.req_mls_link === 'Y' ? <> */}
          {/* <p className="text-gray-700 text-xl font-light mb-[20px] leading-6">
            {listing && listing.PROP_STREET && listing.PROP_STREET},
            {listing && listing.PROP_CITY && listing.PROP_CITY},
            {listing && listing.PROP_ST && listing.PROP_ST},
            {listing && listing.PROP_ZIP && listing.PROP_ZIP}
            
          </p> */}

          <ImageUpload
            title='Custom Image Request'
            secondary_title={'Drag or click to upload'}
            subtitle={'SVG, PNG, JPG, CSV, DOCS, PDF'}
            multiple={true}
            handler={handle_images}
          />
          {error}
          <label>Uploaded Images:</label>
          <div className='flex gap-2 overflow-x-scroll w-full'>
            {orderRequest?.files?.map((item) => {
              if (item?.file.type.match('pdf')) {
                return (
                  <div className='flex border-gray-100 border-2 p-1 rounded-md'>
                    <HiOutlineTrash
                      className='cursor-pointer min-w-[20px]'
                      onClick={() => {
                        let rest_file_urls = orderRequest?.files.filter(
                          (items) => items.url !== item.url
                        )
                        setOrderRequest((prev) => ({
                          ...prev,
                          files: rest_file_urls,
                        }))
                      }}
                    />
                    <iframe
                      className='min-w-[50px] w-[50px] max-h-[50px] rounded-md'
                      src={item?.url}
                      title={item?.url}
                    />
                  </div>
                )
              } else if (item?.file?.type?.match('image')) {
                return (
                  <div className='flex border-gray-100 border-2 p-1 rounded-md'>
                    <HiOutlineTrash
                      className='cursor-pointer min-w-[20px]'
                      onClick={() => {
                        let rest_file_urls = orderRequest?.files.filter(
                          (items) => items.url !== item.url
                        )
                        setOrderRequest((prev) => ({
                          ...prev,
                          files: rest_file_urls,
                        }))
                      }}
                    />
                    <img
                      alt={item?.file?.name}
                      className='min-w-[50px] w-[50px] rounded-md'
                      src={item?.url}
                    />
                  </div>
                )
              } else {
                return (
                  <div className='flex border-gray-100 border-2 p-1 rounded-md'>
                    <HiOutlineTrash
                      className='cursor-pointer min-w-[20px]'
                      onClick={() => {
                        let rest_file_urls = orderRequest?.files.filter(
                          (items) => items.url !== item.url
                        )
                        setOrderRequest((prev) => ({
                          ...prev,
                          files: rest_file_urls,
                        }))
                      }}
                    />
                    <p className='min-w-[50px] w-[50px] rounded-md overflow-x-scroll'>
                      {item?.file?.name}
                    </p>
                  </div>
                )
              }
            })}
          </div>

          {/* </> : ''} */}
        </div>
        {/* <label className={uiStyles.label}>{`Quantity (max ${currentOrder?.max_ax_qty})`}</label>
          <input className={`${uiStyles.input}`}
          value={orderRequest?.quantity}
          onChange={(e) => {
            if (Number(e.target.value) > Number(currentOrder?.max_ax_qty)) {
              setOrderRequest((prev) => ({...prev, quantity: currentOrder?.max_ax_qty}))
            } else {
              setOrderRequest((prev) => ({...prev, quantity: e?.target?.value}))
            }
          }}
          max={currentOrder?.max_ax_qty}
          type="number"/> */}
        <label className={uiStyles.label}>Update Request</label>
        <textarea
          ref={refOrderRequest}
          label='Order Request'
          className={`${uiStyles.text_area} h-[140px]`}
          name='orderrequest'
        />
        {currentOrder?.sign_rider === 'Y' ? (
          <>
            <label className={uiStyles.label}>Flex MLS Link</label>
            <input
              className={`${uiStyles.input} mb-[10px]`}
              value={orderRequest?.mls_link}
              placeholder='https://www.flexmls.com/share/8H2wN/619-W-DANA-AVE-Mesa-AZ-85210'
              onChange={(e) => {
                setOrderRequest((prev) => ({
                  ...prev,
                  mls_link: e.target.value,
                }))
              }}
              type='text'
            />
          </>
        ) : (
          ''
        )}
        {/* <Checkbox ref={refOrderRequest2} label="Will Pickup at Printshop" /> */}
        {/* <span className='text-[12px]'>14287 N. 87th Street, Scottsdale, AZ 85260</span> */}
        {isLoading ? (
          <div className='flex justify-end mt-[15px]'>
            <div
              className={
                isLoading === 'Sent'
                  ? uiStyles.success_msg
                  : uiStyles.loading_msg
              }
            >
              {isLoading}
            </div>
          </div>
        ) : (
          <div className='flex justify-end mt-[15px]'>
            <button className={`${uiStyles.green_btn}`} onClick={request}>
              Send
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
