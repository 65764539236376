import React, { useEffect, useState } from 'react'
import { HiOutlineTrash } from 'react-icons/hi2'
import { useNavigate } from 'react-router-dom'
import { useAi } from '../../../contexts/AiContext'
import { useDistribute } from '../../../contexts/DistributeContext'
import { useListing } from '../../../contexts/ListingContext'
import { useProfile } from '../../../contexts/UserContext'
import { content_types } from '../../../data/data'
import {
  encode_path,
  format_dollar_currency,
  format_number,
  get_storage,
  pick_keys,
  short_url,
  validate_number,
} from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import ImageUpload from '../../form/ImageUpload'
import LabelInput from '../../form/LabelInput'
import uiStyles from '../../ui/uiStyles'
import DBCSend from '../VBCDetails/DBCSend'
import { MarketingMaterialProfile } from './MarketingMaterialProfile'
import { MarketingMlsFinder } from './MarketingMlsFinder'
import { IoMdDownload } from 'react-icons/io'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import { useCalc } from '../../../contexts/CalcContext'
import { CalcBackLobby } from '../../calculators'
import { CircleCheckToggle } from '../../form/CircleCheckToggle'
import { PayCard } from '../Billing/PayCard'
import { useMerchant } from '../../../contexts/MerchantContext'
import Modal from '../../Modal'
import { useClaims } from '../../../contexts/ClaimsContext'
import useHelpers from '../../../hooks/useHelpers'

export const MarketingMaterialSubmit = ({
  state,
  setState,
  reorder = false,
}) => {
  const { profile } = useProfile()
  const { open_url } = useHelpers()
  const { claims } = useClaims()
  const { saveAi } = useAi()
  const { distribute, saveDistribute } = useDistribute()
  const { listing } = useListing()
  const { axiomFetch, nodeProdFetch } = useAxiomFetch()
  const { merchant, saveMerchant } = useMerchant()
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const navigate = useNavigate()
  const current_selection = state?.create_order?.current_selection
  reorder = current_selection?.re_order || reorder
  const sessions = state?.create_order?.sessions
  const session = state?.create_order?.session
  const [error, setError] = useState()
  const [loadingDownload, setLoadingDownload] = useState()
  const [messageSent, setMessageSent] = useState({})
  const { calc, saveCalc, get_calc_type_id, get_instance_uuid } = useCalc()
  const handle_msg = (status, message, type, close) => {
    setMessageSent({ ...messageSent, status, message, type })
    if (close) {
      setTimeout(() => {
        setMessageSent({ ...messageSent, status: 'done', message: '' })
      }, 6000)
    }
  }
  const save_selection = (key, value) => {
    setState((prev) => ({
      ...prev,
      create_order: {
        ...prev?.create_order,
        current_selection: {
          ...prev?.create_order?.current_selection,
          [key]: value,
        },
      },
    }))
  }

  const remove_file = (item) => {
    let rest_file_urls = state?.create_order?.current_selection?.files?.filter(
      (items) => items.url !== item.url
    )
    save_selection('files', rest_file_urls)
  }

  const handle_images = async (e) => {
    e.preventDefault()
    const current_files = state?.create_order?.current_selection?.files || []
    const files = e?.target?.files || e?.dataTransfer?.files
    let limit = 21
    let total = (current_files?.length || 0) + (files?.length || 0)
    if (total > limit) {
      setError(`Max file limit is ${limit} files.`)
      setTimeout(() => {
        setError(``)
      }, [3000])
      return
    }
    if (files?.length) {
      let file_objs = Array.from(files)?.map((file) => ({
        url: URL.createObjectURL(file),
        file: file,
      }))
      save_selection('files', [...current_files, ...file_objs])
    }
  }
  useEffect(() => {
    if (!state?.hit_create_page) {
      return navigate(
        get_storage('material_back') || '/user/marketing-material'
      )
    }
  }, [])

  console.log('session', session)
  console.log('selection', current_selection)

  const get_payment_amount = () => {
    return (
      validate_number(session?.per_pg_print_cost) *
        validate_number(current_selection?.quantity || 0) +
      validate_number(session?.design_cost)
    )
  }
  const modals = {
    pay: {
      component: (
        <div className='px-3'>
          <PayCard
            currentOrder={{
              quantity: current_selection?.quantity,
              order_name: current_selection?.layout_name,
              print_time: current_selection?.print_time
                ? `${current_selection?.print_time} min`
                : '',
              total_cost: get_payment_amount(),
              bill_type: 2,
            }}
            cancel_link=''
            setShowModal={setShowModal}
            pay_later={false}
            pay_func={async () => {
              const body = {}
              body.payment_amt = get_payment_amount()
              body.payment_token = merchant?.payment?.token
              if (!body?.payment_token) {
                return saveMerchant((prev) => ({
                  ...prev,
                  payment: {
                    ...prev?.payment,
                    payment_failed: false,
                    payment_successful: false,
                  },
                }))
              }

              // body.payment_setup_dt = new Date()
              // await axiomFetch('/pairwise/applications/save', 'POST', {
              //   application_data: body,
              // })
              // saveMerchant((prev) => ({
              //   ...prev,
              //   payment: {
              //     ...prev?.payment,
              //     payment_successful: false,
              //     payment_failed: false,
              //   },
              // }))
            }}
            pay_func_after={async () => {
              await submit_order()
              setState((prev) => ({
                ...prev,
                my_orders_toggle: 'Y',
                my_orders: { ...prev?.my_orders, type: 'pending' },
              }))
              return setTimeout(() => {
                navigate('/user/marketing-material')
              }, 1500)
            }}
          />
        </div>
      ),
      height: 'h-[450px]',
    },
    // cancel_failed: {
    //   component: <PairwiseCancelFailed setShowModal={setShowModal} />,
    //   height: 'h-[300px]',
    // },
  }

  const check_submission = () => {
    if (
      !current_selection.del_add1_p ||
      !current_selection?.del_city_p ||
      !current_selection?.del_st_p ||
      !current_selection?.del_zip_p ||
      (!current_selection?.quantity && !current_selection?.mkt_req_type_id)
    ) {
      handle_msg(
        'failure_msg',
        'A full address and quantity is required.',
        'confirm',
        true
      )
      return true
    } else {
      return false
    }
  }

  const submit_order = async () => {
    if (reorder) {
      await axiomFetch(
        '/marketing/materials/request_old',
        'POST',
        current_selection,
        {},
        true
      )
    } else {
      const body = {
        sess_id: session?.sess_id,
        ...current_selection,
        sessions: sessions?.map((item) => pick_keys(item, ['layouts'])),
        session: pick_keys(session, ['layouts']),
        container_sess_id: session?.container_sess_id,
        create_request: true,
        layouts: [],
      }
      await axiomFetch('/marketing/materials/flyer/submit', 'POST', body)
    }
    handle_msg('success_msg', 'Request Sent!', 'confirm', true)
  }

  return (
    <div className='px-3 mt-[10px]'>
      {session?.calc_type_id ? (
        <div className='mt-[-20px] mb-[10px]'>
          <CalcBackLobby />
        </div>
      ) : (
        ''
      )}
      <div>
        <MarketingMaterialProfile />
      </div>
      {reorder ? (
        ''
      ) : (
        <div>
          <div className='flex justify-center gap-[15px] items-start'>
            <div
              data-cy='view_proof'
              className={`${uiStyles.blue_grd_text} text-[20px] ${
                reorder ? 'mb-[20px]' : 'mb-[20px]'
              } cursor-pointer `}
              onClick={() => {
                const url =
                  session?.weekender === 'Y' ||
                  current_selection?.sub_medium_id === 21
                    ? current_selection?.png_urls?.[0]
                    : current_selection?.pdf_url
                if (
                  url
                    ?.toString()
                    ?.replace('undefined', '')
                    ?.replace('[Object object]', '')
                ) {
                  open_url(url)
                }
              }}
            >
              {reorder
                ? 'Previous Image'
                : session?.weekender === 'Y'
                ? 'View Proof'
                : 'View Proof'}
            </div>
            {loadingDownload ? (
              <div>
                <SmallLoadingRel />
              </div>
            ) : (
              <div
                className='cursor-pointer'
                onClick={async () => {
                  setLoadingDownload(true)
                  const url =
                    session?.weekender === 'Y' ||
                    current_selection?.sub_medium_id === 21
                      ? current_selection?.png_urls?.[0]
                      : current_selection?.pdf_url

                  const blob = await axiomFetch(
                    `/calculators/flyer_download?url=${encodeURIComponent(
                      url
                    )}`,
                    'GET',
                    {},
                    {},
                    false,
                    null,
                    0,
                    null,
                    true
                  )
                    .then((response) => response.blob())
                    .catch((err) => console.log(err))
                  const blob_url = window.URL.createObjectURL(new Blob([blob]))
                  const link = document.createElement('a')
                  link.href = blob_url
                  link.setAttribute(
                    'download',
                    `${url?.replace(
                      'https://driggstitle.com/reviews/node/node_to_oracle/',
                      ''
                    )}`
                  )
                  document.body.appendChild(link)
                  link.click()
                  link.parentNode.removeChild(link)
                  setLoadingDownload(false)
                }}
              >
                <IoMdDownload size={25} />
              </div>
            )}
            {/* {!reorder ? (
          <div
            className={`${uiStyles.blue_grd_text} text-[20px] ${
              reorder ? 'mb-[10px]' : 'mt-[10px]'
            } cursor-pointer `}
            onClick={() => {
              open_url(current_selection?.png_urls?.[0])
            }}
          >
            {reorder ? 'Previous Image' : 'PNG'}
          </div>
        ) : (
          ''
        )} */}
          </div>
          <p className={`${uiStyles.text_black_subtitle} text-center`}>
            Send Digital Content
          </p>
          {messageSent?.type === 'send_email' ? (
            <div className={`${uiStyles[messageSent.status]} mb-[20px]`}>
              {messageSent.message}
            </div>
          ) : (
            <div className='flex justify-center my-[10px] gap-[10px]'>
              <button
                className={uiStyles.hover_btn}
                onClick={async () => {
                  // handle_msg(
                  //   'loading_msg',
                  //   'Preparing Materials...',
                  //   'send_email'
                  // )
                  // const [png_64s, pdf_64s] = await Promise.all([
                  //   nodeProdFetch({
                  //     url: '/node_to_oracle/api/v1/images/url_to_base64',
                  //     method: 'POST',
                  //     body: { urls: current_selection?.png_urls?.join(',') },
                  //   }),
                  //   nodeProdFetch({
                  //     url: '/node_to_oracle/api/v1/images/url_to_base64',
                  //     method: 'POST',
                  //     body: {
                  //       urls: [
                  //         current_selection?.pdf_url,
                  //         ...current_selection?.pdf_urls,
                  //       ]?.join(','),
                  //     },
                  //   }),
                  // ])

                  // const png_attachments = (
                  //   current_selection?.png_urls || []
                  // )?.map((png, index) => {
                  //   const path_segments = png?.split('/')
                  //   const file_name = path_segments[path_segments?.length - 1]
                  //   return {
                  //     content: png_64s[index],
                  //     filename: file_name,
                  //     type: 'image/png',
                  //     disposition: 'attachment',
                  //   }
                  // })
                  // const pdf_attachments = (
                  //   [
                  //     current_selection.pdf_url,
                  //     ...current_selection?.pdf_urls,
                  //   ] || []
                  // )?.map((pdf, index) => {
                  //   const path_segments = pdf?.split('/')
                  //   const file_name = path_segments[path_segments?.length - 1]
                  //   return {
                  //     content: pdf_64s[index],
                  //     filename: file_name,
                  //     type: 'application/pdf',
                  //     disposition: 'attachment',
                  //   }
                  // })
                  handle_msg('loading_msg', 'Preparing Email...', 'send_email')
                  // setTimeout(() => {
                  //   handle_msg(
                  //     'success_msg',
                  //     'Email Will be Sent Shortly',
                  //     'send_email',
                  //     true
                  //   )
                  // }, [3000])
                  const get_file_name = (url) => {
                    const path_segments = url?.split('/')
                    const file_name = path_segments[path_segments?.length - 1]
                    return file_name
                  }
                  const pngs = current_selection?.png_urls
                  const pdfs = current_selection?.pdf_urls
                  let html = `<p>Hey ${profile?.CONT_NAME},</p><p>Here are your marketing materials!</p>`

                  if (current_selection?.sub_medium_id !== 21) {
                    html += `<p><a href="${encodeURI(
                      current_selection?.pdf_url
                    )}">All Materials PDF</a></p>
                      <p>PDFS:</p>
                      <ul>
                      ${pdfs
                        ?.map((item) => {
                          return `<li><a href="${encodeURI(
                            item
                          )}">${get_file_name(item)}</a></li>`
                        })
                        .join('')}
                      </ul>`
                  }
                  html += ` <p>PNGS:</p>
                  <ul>
                  ${pngs
                    ?.map((item) => {
                      return `<li><a href="${encodeURI(item)}">${get_file_name(
                        item
                      )}</a></li>`
                    })
                    .join('')}
                  </ul>`
                  if (session?.calc_type_id) {
                    html = `<p>Hey ${
                      profile?.CONT_NAME
                    },</p><div>Here is your <a href="${encodeURI(
                      current_selection?.pdf_url
                    )}"> ${
                      calc?.axiom_calculators?.find(
                        (item) => item?.type_id === session?.calc_type_id
                      )?.calc_name
                    }</a>
                    !</div>  
                    `
                  }
                  const body = {
                    msg: {
                      to: [profile?.CONT_EMAIL],
                      cc: [profile?.REP_EMAIL || ''],
                      // to: ['andrewj@driggstitle.com'],
                      from: 'hello@theaxiomapp.com',
                      subject: `${
                        session?.calc_type_id
                          ? calc?.axiom_calculators?.find(
                              (item) => item?.type_id === session?.calc_type_id
                            )?.calc_name
                          : current_selection?.contain_name ||
                            current_selection?.layout_name ||
                            'Marketing Materials'
                      } - ${profile?.CONT_NAME}`,
                      // text: `${additional_info_text} ${textRef?.current?.value}`,
                      html: html,
                      // attachments: [...png_attachments, ...pdf_attachments],
                    },
                    // url: window?.location?.href || '',
                    // cont_name: profile?.CONT_NAME || '',
                    // cont_cell_phone: profile?.CONT_CELL_NUM || '',
                    // login_cell_phone:
                    //   user?.name?.replace('+1', '') ||
                    //   user?.nickname?.replace('+1', '') ||
                    //   '',
                    // bug_report_text: textRef?.current?.value || '',
                    no_send_email: false,
                    // insert_bugs: true,
                  }
                  const data = await axiomFetch(
                    '/social/email/sendEmail',
                    'POST',
                    body
                  )
                  handle_msg('success_msg', 'Email Sent!', 'send_email', true)
                }}
              >
                {session?.calc_type_id ? 'Email Me' : 'Send Email'}
              </button>
              {session?.calc_type_id ? (
                ''
              ) : (
                <button
                  className={uiStyles.hover_btn}
                  onClick={() => {
                    saveAi((prev) => ({
                      ...prev,
                      assistant: {
                        ...prev?.assistant,
                        is_assistant: 'N',
                      },
                      came_from_mkt: true,
                      mkt_req_urls: [
                        ...(prev?.mkt_req_urls || [])?.filter(
                          (item) => item !== current_selection?.png_urls?.[0]
                        ),
                        current_selection?.png_urls?.[0],
                      ],
                      image_upload: {
                        ...prev?.image_upload,
                        loading: false,
                        upload_url: current_selection?.png_urls?.[0] || '',
                        gallery: {
                          ...prev?.image_upload?.gallery,
                          images: [
                            ...current_selection?.png_urls?.map((png_url) => ({
                              url: png_url || '',
                              type: 'market_material',
                            })),
                            ...(
                              prev?.image_upload?.gallery?.images || []
                            )?.filter(
                              (item) => item?.type !== 'market_material'
                            ),
                          ],
                        },
                      },
                    }))
                    let distribute_content = distribute?.content || {}

                    for (const content_type of content_types) {
                      distribute_content[content_type] = {
                        mkt_req_urls: [
                          ...(distribute_content?.mkt_req_urls || [])?.filter(
                            (item) => item !== current_selection?.png_urls?.[0]
                          ),
                          current_selection?.png_urls?.[0],
                        ],
                        ...distribute_content?.[content_type],
                        photo_url: current_selection?.png_urls?.[0] || '',
                        image_upload: {
                          ...distribute_content?.[content_type]?.image_upload,
                          loading: false,
                          upload_url: current_selection?.png_urls?.[0] || '',
                          gallery: {
                            ...distribute_content?.[content_type]?.image_upload
                              ?.gallery,
                            images: [
                              { url: current_selection?.png_urls?.[0] || '' },
                              ...(
                                distribute_content?.[content_type]?.image_upload
                                  ?.gallery?.images || []
                              )?.filter(
                                (item) => item !== current_selection?.png_urls
                              ),
                            ],
                          },
                        },
                      }
                    }
                    saveDistribute((prev) => ({
                      ...prev,
                      content: distribute_content,
                    }))
                    return navigate('/user/axiom-ai', {
                      state: {
                        parent: '/user/marketing-material/layouts/confirm',
                      },
                    })
                  }}
                >
                  Social Post
                </button>
              )}
            </div>
          )}
          {session?.weekender === 'Y' ||
          session?.calc_type_id ||
          session?.medium_id === 1 ? (
            ''
          ) : (
            <div>
              <p
                className={`${uiStyles.text_black_subtitle} text-center mb-[10px]`}
              >
                Or
              </p>
              <p
                className={`${uiStyles.text_black_subtitle} text-center mb-[10px]`}
              >
                Send To Our Printers
              </p>
            </div>
          )}
        </div>
      )}
      <div>
        {current_selection?.axiom_name || current_selection?.type_name ? (
          <h2 className='font-semibold text-gray-700 text-xl leading-7'>
            Order:{' '}
            {current_selection?.axiom_name || current_selection?.type_name}
          </h2>
        ) : (
          ''
        )}
        {current_selection?.axiom_blurb ? (
          <p>{current_selection?.axiom_blurb}</p>
        ) : (
          ''
        )}
      </div>

      {state?.create_order?.order_type === 2 &&
      session?.weekender !== 'Y' &&
      !session?.calc_type_id ? (
        <div className='mb-[10px]'>
          <MarketingMlsFinder
            mls_value={current_selection?.mls_id}
            handle_input={(e) => {
              save_selection('mls_id', e.target.value)
            }}
          />
        </div>
      ) : (
        ''
      )}
      {session?.calc_type_id ? (
        <div className='py-[10px]'>
          <DBCSend
            title={'Share with your clients'}
            title_style={`text-center mb-[10px]`}
            // add_media={[current_selection?.pdf_url]}
            //
            show_conversations_btn={true}
            show_messaging_btn={true}
            show_upd_btn={calc?.share_vbc === 'Y' ? true : false}
            no_vbc={calc?.share_vbc === 'Y' ? false : true}
            show_service_markets={calc?.share_vbc === 'Y' ? true : false}
            show_list={false}
            vbc_msg={async () => {
              return `${
                calc?.axiom_calculators?.find(
                  (item) => item?.type_id === session?.calc_type_id
                )?.sms_share_msg ||
                `Here is a pdf of your ${
                  calc?.axiom_calculators?.find(
                    (item) => item?.type_id === session?.calc_type_id
                  )?.calc_name
                }`
              } ${(
                await short_url(encodeURI(current_selection?.pdf_url))
              )?.replace('https://', '')}. ${
                (calc?.share_vbc === 'Y' ? false : true)
                  ? ''
                  : calc?.axiom_calculators?.find(
                      (item) => item?.type_id === session?.calc_type_id
                    )?.vbc_share_msg ||
                    `If you want to see the ${
                      calc?.axiom_calculators?.find(
                        (item) => item?.type_id === session?.calc_type_id
                      )?.calc_name
                    } live check out my Realty Smart Business card, custom made for you.`
              }`
            }}
            calc_instance_uuid={get_instance_uuid(
              get_calc_type_id(session?.calc_type_id)
            )}
            calc_type_id={session?.calc_type_id}
            vbc_type={
              calc?.axiom_calculators?.find(
                (item) => item?.type_id === session?.calc_type_id
              )?.b_or_s === 'S'
                ? 'seller'
                : 'buyer'
            }
          />
          <div className='flex gap-[0px] mt-[10px]'>
            <CircleCheckToggle
              name={`share_vbc`}
              inputValue={calc}
              setInputValue={saveCalc}
              handle_change={async (e) => {
                console.log(e.target.value)
                saveCalc((prev) => {
                  const return_data = {
                    ...prev,
                    [`share_vbc`]: prev?.[`share_vbc`] === 'Y' ? 'N' : 'Y',
                  }
                  return return_data
                })
              }}
            />
            <label>Include VBC Link</label>
          </div>
        </div>
      ) : (
        ''
      )}
      <div>
        {session?.weekender === 'Y' ||
        session?.calc_type_id ||
        session?.medium_id === 1 ? (
          ''
        ) : (
          <>
            {reorder ? (
              <>
                <>
                  <label className={uiStyles.label}>Upload New Images</label>
                  <ImageUpload
                    title='Custom Image Request'
                    secondary_title={'Drag or click to upload'}
                    subtitle={'SVG, PNG, JPG, CSV, DOCS, PDF'}
                    multiple={true}
                    handler={handle_images}
                  />
                  <div>{error}</div>
                  <label>Uploaded Images:</label>
                </>

                <div className='flex gap-2 overflow-x-scroll w-full'>
                  {state?.create_order?.current_selection?.files?.map(
                    (item) => {
                      if (item?.file?.type?.match('pdf')) {
                        return (
                          <div className='flex border-gray-100 border-2 p-1 rounded-md'>
                            <HiOutlineTrash
                              className='cursor-pointer min-w-[20px]'
                              onClick={() => {
                                remove_file(item)
                              }}
                            />
                            <iframe
                              className='min-w-[50px] w-[50px] max-h-[50px] rounded-md'
                              src={item?.url}
                              title={item?.url}
                            />
                          </div>
                        )
                      } else if (item?.file?.type?.match('image')) {
                        return (
                          <div className='flex border-gray-100 border-2 p-1 rounded-md'>
                            <HiOutlineTrash
                              className='cursor-pointer min-w-[20px]'
                              onClick={() => {
                                remove_file(item)
                              }}
                            />
                            <img
                              alt={item?.file?.name || 'file'}
                              className='min-w-[50px] w-[50px] rounded-md'
                              src={item?.url}
                            />
                          </div>
                        )
                      } else {
                        return (
                          <div className='flex border-gray-100 border-2 p-1 rounded-md'>
                            <HiOutlineTrash
                              className='cursor-pointer min-w-[20px]'
                              onClick={() => {
                                remove_file(item)
                              }}
                            />
                            <p className='min-w-[50px] w-[50px] rounded-md overflow-x-scroll'>
                              {item?.file?.name}
                            </p>
                          </div>
                        )
                      }
                    }
                  )}
                </div>
              </>
            ) : (
              ''
            )}
            {!current_selection?.mkt_req_type_id ? (
              <div className='mt-[10px]'>
                <label className={uiStyles.label}>{`Quantity (max ${
                  current_selection?.max_ax_qty || 10
                })`}</label>
                <input
                  className={`${uiStyles.input}`}
                  value={current_selection?.quantity}
                  onChange={(e) => {
                    if (
                      Number(e.target.value) >
                      Number(current_selection?.max_ax_qty || 10)
                    ) {
                      save_selection(
                        'quantity',
                        current_selection?.max_ax_qty || 10
                      )
                    } else {
                      save_selection('quantity', e?.target?.value)
                    }
                  }}
                  max={current_selection?.max_ax_qty}
                  type='number'
                />
                {session?.design_cost || session?.per_pg_print_cost ? (
                  <div className='mt-[10px]'>
                    <span className='font-bold text-[16px]'>Cost: </span>
                    <span className='font-bold text-[16px]'>
                      {format_dollar_currency(get_payment_amount())}
                    </span>
                  </div>
                ) : (
                  ''
                )}
                <div className='mt-[10px]'>
                  <label className={uiStyles.label}>Order Request Notes</label>
                  <textarea
                    value={current_selection?.notes}
                    onChange={(e) => {
                      save_selection('notes', e?.target?.value)
                    }}
                    label='Order Request'
                    className={`${uiStyles.text_area} h-[140px]`}
                    name='orderrequest'
                  />
                </div>
              </div>
            ) : (
              ''
            )}

            <div className='mt-[10px] pb-[10px]'>
              <p className={`${uiStyles.text_black} mt-[10px]`}>
                Delivery Address:
              </p>
              <LabelInput
                top_label={'Address 1'}
                name={'del_add1_p'}
                value={current_selection?.['del_add1_p'] || ''}
                handleChange={(e) => {
                  save_selection('del_add1_p', e.target.value)
                }}
              />
              <LabelInput
                top_label={'Address 2'}
                name={'del_add2_p'}
                value={current_selection?.['del_add2_p'] || ''}
                handleChange={(e) => {
                  save_selection('del_add2_p', e.target.value)
                }}
              />
              <LabelInput
                top_label={'City'}
                name={'del_city_p'}
                value={current_selection?.['del_city_p'] || ''}
                handleChange={(e) => {
                  save_selection('del_city_p', e.target.value)
                }}
              />
              <LabelInput
                top_label={'State'}
                name={'del_st_p'}
                value={current_selection?.['del_st_p'] || ''}
                handleChange={(e) => {
                  save_selection('del_st_p', e.target.value)
                }}
              />
              <LabelInput
                top_label={'Zip Code'}
                name={'del_zip_p'}
                value={current_selection?.['del_zip_p'] || ''}
                handleChange={(e) => {
                  save_selection('del_zip_p', e.target.value)
                }}
              />
            </div>
            <>
              {messageSent?.type === 'confirm' ? (
                <div className={`${uiStyles[messageSent.status]} mb-[20px]`}>
                  {messageSent.message}
                </div>
              ) : (
                <div className='flex justify-end gap-[10px] pb-[10px]'>
                  <button
                    className={uiStyles?.white_btn}
                    onClick={() => {
                      navigate('/user/marketing-material/layouts/customize')
                    }}
                  >
                    Cancel
                  </button>
                  {claims?.client_proxy_login ? (
                    <div>
                      <button className={`${uiStyles.hover_btn_greyed_out}`}>
                        {session?.design_cost || session?.per_pg_print_cost
                          ? 'Pay'
                          : 'Confirm'}
                      </button>
                    </div>
                  ) : session?.design_cost || session?.per_pg_print_cost ? (
                    <button
                      className={uiStyles.hover_btn}
                      onClick={() => {
                        const check = check_submission()
                        if (check) {
                          return
                        }
                        saveMerchant((prev) => ({
                          ...prev,
                          payment: {
                            ...prev?.payment,
                            payment_failed: false,
                            payment_successful: false,
                          },
                        }))
                        setModalType('pay')
                        setShowModal(true)
                      }}
                    >
                      Pay
                    </button>
                  ) : (
                    <button
                      className={uiStyles?.hover_btn}
                      onClick={async () => {
                        handle_msg(
                          'loading_msg',
                          'Processing Request...',
                          'confirm'
                        )
                        const check = check_submission()
                        if (check) {
                          return
                        }
                        await submit_order()
                        setState((prev) => ({
                          ...prev,
                          my_orders_toggle: 'Y',
                          my_orders: { ...prev?.my_orders, type: 'pending' },
                        }))
                        return setTimeout(() => {
                          navigate('/user/marketing-material')
                        }, 1500)
                      }}
                    >
                      {state?.create_order?.switch_layout
                        ? 'Confirm Not Even Close'
                        : 'Confirm'}
                    </button>
                  )}
                </div>
              )}
              {claims?.client_proxy_login ? (
                <div className='text-center pb-[10px]'>
                  Printing on behalf of a client is not allowed.
                </div>
              ) : (
                ''
              )}
            </>
          </>
        )}{' '}
      </div>
      <>
        {showModal ? (
          <Modal
            side_padding={'px-0'}
            modalName={modalType}
            height={modals?.[modalType]?.height || 'h-[550px]'}
            width={'w-full'}
            showModal={showModal}
            setShowModal={(type) => {
              setShowModal(false)
            }}
            showClose={true}
          >
            {modals?.[modalType]?.component || <></>}
          </Modal>
        ) : (
          ''
        )}
      </>
    </div>
  )
}
