import React from 'react'
import { useCalc } from '../../contexts/CalcContext'
import { NavButton } from '../helpers'
import uiStyles from '../ui/uiStyles'

const CalcInfo = ({ info_item, setShowModal, pre_func }) => {
  const { get_calc_type_id, saveCalc } = useCalc()
  console.log('info', info_item)
  return (
    <div>
      <div className='flex flex-col items-center'>
        <p className='px-3 text-center font-bold text-[20px] mt-[-15px] mb-[5px]'>
          {info_item?.calc_name}
        </p>
        <img src={info_item?.image} className='max-w-[200px] max-h-[200px]' />
        <p className='px-3 mt-[10px] max-h-[320px] overflow-y-scroll'>
          {info_item?.calc_long_desc}
        </p>
      </div>
      <div className={`flex justify-end px-3 mt-[10px] gap-[10px]`}>
        <button
          className={`${uiStyles.white_btn}`}
          onClick={() => {
            setShowModal(false)
          }}
        >
          Back
        </button>
        {info_item?.link ? (
          <NavButton
            className={uiStyles.hover_btn}
            title={'Create'}
            pre_func={() => {
              pre_func(info_item)
            }}
            link={info_item?.link}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default CalcInfo
