import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import {
  array_nums,
  calculate_amortization,
  calculate_amortization_totals,
  capitalize,
  find_closest_ge,
  format_currency,
  obj_flat,
  pick_keys,
  set_storage,
  shade_color,
  validate_number,
  get_address_str,
  get_uuid,
  get_storage,
} from '../helpers'
import structuredClone from '@ungap/structured-clone'
import { useProfile } from './UserContext'
import { useNavigate } from 'react-router-dom'
import useAxiomFetch from '../hooks/useAxiomFetch'
export const CalcContext = createContext()
export const CalcProvider = ({ children, userData }) => {
  const { profile } = useProfile()
  const navigate = useNavigate()
  const { axiomFetch } = useAxiomFetch()
  const donut_chart = {
    fill: { type: 'gradient' },
    labels: ['P&I', 'Taxes', 'Insurance', 'PMI'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        // startAngle: -90,
        // endAngle: 275,
        donut: {
          size: '70%',
          labels: {
            show: true,
            name: { offsetY: 25 },
            value: { offsetY: -8, fontSize: '14px' },
            total: {
              value: 200,
              show: true,
              showAlways: true,
              label: 'EDIT',
              fontSize: '8px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 600,
              color: '#373d3f',
              formatter: function (w) {
                const total = w.globals.seriesTotals.reduce((a, b) => {
                  return a + b
                }, 0)
                return `$${format_currency(Math.round(total))}`
              },
            },
          },
        },
      },
    },
    legend: {
      show: false,
      floating: true,
      fontSize: '10px',
      offsetX: 10,
      offsetY: 80,
      horizontalAlign: 'left',
      position: 'bottom',
      itemMargin: {
        horizontal: 20,
        vertical: 2,
      },
      formatter: (name, opts) => {
        return `${name} - $${format_currency(
          opts.w.config.series[opts.seriesIndex],
          true,
          2,
          null,
          2
        )}`
      },
    },
  }
  const stacked_chart = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    dataLabels: {
      textAnchor: 'middle',
      enabled: true,
      formatter: (val, { w, seriesIndex }) =>
        `${w.config.series[seriesIndex].name} `,
      style: {
        fontSize: 9,
      },
    },
    plotOptions: {
      bar: {
        distributed: false,
        horizontal: false,
        borderRadius: 4,
        columnWidth: '90%',
        dataLabels: {
          hideOverflowingLabels: false,
          total: {
            formatter: (val) =>
              `$${format_currency(val, false, 2).replace('$', '')}`,
            enabled: true,
            style: {
              fontSize: '13px',
              fontWeight: 900,
            },
          },
        },
      },
    },

    xaxis: {
      type: 'category',
      categories: ['30 Days', '60 Days', '90 Days', '120 Days'],
    },
    legend: {
      show: false,
      position: 'bottom',
      offsetY: 4,
    },
    tooltip: {
      fixed: {
        // enabled: true,
        // position: 'topLeft',
        // offsetY: -0,
        // offsetX: 0,
      },
      interesect: true,
      y: {
        formatter: function (val, opts) {
          return (
            '<strong>$' +
            format_currency(
              opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex],
              false,
              2
            ) +
            '</strong>'
          )
        },
      },
    },
    fill: {
      type: 'gradient',
    },

    colors: ['#008FFB', '#1297FC', '#209DFC', '#47AFFD', '#6ABFFE'],
  }
  const colors = ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0']
  const loans = [
    'Conventional',
    'FHA',
    'VA',
    'All Cash',
    // 'USDA',
    // 'Conv 2nd',
    // 'Interest Only',
    'Owner Finance',
  ]

  const sell_offer_names = {
    offer_price: 'Offer Price',
    down_payment: 'Down Payment',
    buyer_agent_name: 'Buyer Agent Name',
    earnest_money: 'Earnest Money',
    home_warranty: 'Home Warranty',
    closing_date: 'Closing Date',
    buyer_broker_fee: 'Buyer Broker Fee',
    misc: 'Misc',
    escalation_clause: 'Escalation Clause',
    contingency: 'Contingency',
    notes: 'Notes',
    buyer_credit: 'Buyer Credit',
  }
  const sell_offer_placeholders = {
    offer_price: 'Required',
    escalation_clause:
      'The purchase price shall be $1,000 dollars higher than any real offer, up to $375,000.',
    contingency:
      'Inspection period, appliances that should remain, repairs, etc.',
    notes:
      "Buyer's name, Realtor/Lender contract info, Buyer pre-approval/pre-qual letter, etc.",
  }

  const basic_defaults = {
    buyer_broker_fee: 3,
    buyer_broker_fee_type: '%',
    listing_broker_fee: 3,
    listing_broker_fee_type: '%',
    show_split_in_closing_costs: 'Y',
    interest_rate: 6.625,
    interest_rate_type: '%',
    insurance_rate: 0.39,
    insurance_rate_type: '%',
    loan_term: 30,
    loan_term_type: '%',
    down_payment: 10,
    down_payment_type: '%',
    closing_date_days: 30,
    closing_date_type: 'date',
    county: profile.ACCOUNT_ID === 1560 ? 'Clark' : 'Maricopa',
    county_type: 'options',
    is_mip: 'false',
    is_pmi: 'true',
    lender_fees: [
      'processing_fee',
      'underwriting',
      'appraisal',
      'credit_report',
      'termite_inspection',
      'home_inspection',
      'origination_fee',
    ],
    other_fees: ['home_protection'],
    default_fees: [
      'buyer_broker_fee',
      'listing_broker_fee',
      'show_split_in_closing_costs',
      'interest_rate',
      'insurance_rate',
      'loan_term',
      'down_payment',
      'is_mip',
      'is_pmi',
      'county',
      'closing_date_days',
    ],
    prepaid_fees: [
      'months_of_insurance',
      'months_of_taxes',
      'days_of_interest',
    ],
    title_fees: ['escrow_fee', 'title_policy_fee'],
    closing_costs: {
      months_of_insurance: 14,
      months_of_taxes: 4,
      days_of_interest: 15,
      title_policy_fee: 'buyer',
      escrow_fee: 'buyer',
      origination_fee: 1,
      origination_fee_type: '%',
      processing_fee: 350,
      processing_fee_type: '$',
      underwriting: 200,
      underwriting_type: '$',
      appraisal: 450,
      appraisal_type: '$',
      credit_report: 60,
      credit_report_type: '$',
      termite_inspection: 65,
      termite_inspection_type: '$',
      home_inspection: 250,
      home_inspection_type: '$',
      home_protection: 400,
      home_protection_type: '$',
    },
    closing_costs_names: {
      months_of_insurance: 'Months of Insurance',
      months_of_taxes: 'Months of Taxes',
      days_of_interest: 'Days of Interest',
      title_policy_fee: 'Title Policy',
      escrow_fee: 'Escrow Fee',
      processing_fee: 'Processing Fee',
      underwriting: 'Underwriting',
      appraisal: 'Appraisal',
      credit_report: 'Credit Report',
      termite_inspection: 'Termite Inspection',
      home_inspection: 'Home Inspection',
      home_protection: 'Home Protection',
      origination_fee: 'Origination Fee',
    },
    pmi_data: {
      '760+': {
        '20+': [
          { min: 95, max: 100, rate: 0.0055 },
          { min: 90, max: 95, rate: 0.0037 },
          { min: 85, max: 90, rate: 0.0027 },
          { min: -0.01, max: 85, rate: 0.0018 },
        ],
        '20-': [
          { min: 95, max: 100, rate: 0.0037 },
          { min: 90, max: 95, rate: 0.0028 },
          { min: 85, max: 90, rate: 0.0019 },
          { min: -0.01, max: 85, rate: 0.0017 },
        ],
      },
      '740-759': {
        '20+': [
          { min: 95, max: 100, rate: 0.0067 },
          { min: 90, max: 95, rate: 0.0052 },
          { min: 85, max: 90, rate: 0.0037 },
          { min: -0.01, max: 85, rate: 0.0019 },
        ],
        '20-': [
          { min: 95, max: 100, rate: 0.005 },
          { min: 90, max: 95, rate: 0.0038 },
          { min: 85, max: 90, rate: 0.0021 },
          { min: -0.01, max: 85, rate: 0.0018 },
        ],
      },
      '720-739': {
        '20+': [
          { min: 95, max: 100, rate: 0.0084 },
          { min: 90, max: 95, rate: 0.0065 },
          { min: 85, max: 90, rate: 0.0045 },
          { min: -0.01, max: 85, rate: 0.0022 },
        ],
        '20-': [
          { min: 95, max: 100, rate: 0.0065 },
          { min: 90, max: 95, rate: 0.0045 },
          { min: 85, max: 90, rate: 0.0025 },
          { min: -0.01, max: 85, rate: 0.0019 },
        ],
      },
      '700-719': {
        '20+': [
          { min: 95, max: 100, rate: 0.0096 },
          { min: 90, max: 95, rate: 0.0076 },
          { min: 85, max: 90, rate: 0.0054 },
          { min: -0.01, max: 85, rate: 0.0024 },
        ],
        '20-': [
          { min: 95, max: 100, rate: 0.0077 },
          { min: 90, max: 95, rate: 0.0054 },
          { min: 85, max: 90, rate: 0.0028 },
          { min: -0.01, max: 85, rate: 0.002 },
        ],
      },
      '680-699': {
        '20+': [
          { min: 95, max: 100, rate: 0.0117 },
          { min: 90, max: 95, rate: 0.0094 },
          { min: 85, max: 90, rate: 0.0064 },
          { min: -0.01, max: 85, rate: 0.0027 },
        ],
        '20-': [
          { min: 95, max: 100, rate: 0.0097 },
          { min: 90, max: 95, rate: 0.0065 },
          { min: 85, max: 90, rate: 0.0033 },
          { min: -0.01, max: 85, rate: 0.002 },
        ],
      },
      '660-679': {
        '20+': [
          { min: 95, max: 100, rate: 0.015 },
          { min: 90, max: 95, rate: 0.0125 },
          { min: 85, max: 90, rate: 0.0088 },
          { min: -0.01, max: 85, rate: 0.0037 },
        ],
        '20-': [
          { min: 95, max: 100, rate: 0.013 },
          { min: 90, max: 95, rate: 0.008 },
          { min: 85, max: 90, rate: 0.0042 },
          { min: -0.01, max: 85, rate: 0.0022 },
        ],
      },
      '640-659': {
        '20+': [
          { min: 95, max: 100, rate: 0.016 },
          { min: 90, max: 95, rate: 0.0129 },
          { min: 85, max: 90, rate: 0.0089 },
          { min: -0.01, max: 85, rate: 0.0039 },
        ],
        '20-': [
          { min: 95, max: 100, rate: 0.0145 },
          { min: 90, max: 95, rate: 0.0093 },
          { min: 85, max: 90, rate: 0.0047 },
          { min: -0.01, max: 85, rate: 0.0024 },
        ],
      },
      '620-639': {
        '20+': [
          { min: 95, max: 100, rate: 0.018 },
          { min: 90, max: 95, rate: 0.0137 },
          { min: 85, max: 90, rate: 0.0092 },
          { min: -0.01, max: 85, rate: 0.0042 },
        ],
        '20-': [
          { min: 95, max: 100, rate: 0.0165 },
          { min: 90, max: 95, rate: 0.0109 },
          { min: 85, max: 90, rate: 0.0055 },
          { min: -0.01, max: 85, rate: 0.0027 },
        ],
      },
    },
  }

  const basic_defaults_sell = {
    ...basic_defaults,
    other_fees: [
      ...basic_defaults.other_fees,
      'buyer_broker_fee',
      'listing_broker_fee',
    ],
    closing_costs: {
      ...basic_defaults.closing_costs,
      title_policy_fee: 'seller',
      escrow_fee: 'seller',
      buyer_broker_fee: 3,
      buyer_broker_fee_type: '%',
      listing_broker_fee: 3,
      listing_broker_fee_type: '%',
    },
    closing_costs_names: {
      ...basic_defaults.closing_costs_names,
      buyer_broker_fee: 'Buyer Broker Fee',
      listing_broker_fee: 'Listing Broker Fee',
    },
  }

  const buy = {
    loan_compare: {},
    max_fha: '530,150',
    defaults: {
      fha: {
        ...structuredClone(basic_defaults),
        is_mip: 'true',
        is_pmi: 'false',
        down_payment: 3.5,
        down_payment_type: '%',
        closing_costs: {
          ...basic_defaults?.closing_costs,
          days_of_interest: 30,
        },
      },
      conventional: {
        ...structuredClone(basic_defaults),
      },
      ['all cash']: {
        ...structuredClone(basic_defaults),
        down_payment: 100,
        down_payment_type: '%',
        is_mip: 'false',
        is_pmi: 'false',
        closing_costs: {
          ...basic_defaults?.closing_costs,
          origination_fee: 0,
          days_of_interest: 0,
          months_of_insurance: 0,
          months_of_taxes: 0,
          processing_fee: 0,
          processing_fee_type: '$',
          underwriting: 0,
          underwriting_type: '$',
          appraisal: 0,
          appraisal_type: '$',
          credit_report: 0,
          credit_report_type: '$',
          termite_inspection: 150,
          termite_inspection_type: '$',
        },
        closing_cost_names: {
          termite_inspection: 'Termite Inspection',
          home_inspection: 'Home Inspection',
        },
        lender_fees: ['termite_inspection', 'home_inspection'],
      },
      // ['conv 2nd']: { ...structuredClone(basic_defaults) },
      // ['usda']: { ...structuredClone(basic_defaults) },
      // ['interest only']: { ...structuredClone(basic_defaults) },
      ['owner finance']: {
        ...structuredClone(basic_defaults),
        is_mip: 'false',
        is_pmi: 'false',
        down_payment: 10,
        down_payment_type: '%',
        closing_costs: {
          ...basic_defaults?.closing_costs,
          origination_fee: 0,
          days_of_interest: 0,
          months_of_insurance: 0,
          months_of_taxes: 0,
          processing_fee: 0,
          processing_fee_type: '$',
          underwriting: 0,
          underwriting_type: '$',
          appraisal: 0,
          appraisal_type: '$',
          credit_report: 0,
          credit_report_type: '$',
          termite_inspection: 150,
          termite_inspection_type: '$',
        },
        lender_fees: ['termite_inspection', 'home_inspection'],
      },
      ['va']: {
        ...structuredClone(basic_defaults),
        is_pmi: 'false',
        is_mip: 'false',
        down_payment: 0,
        down_payment_type: '%',
      },
    },
    rent_vs_buy_add: [
      'current_rent',
      'gross_income',
      'rent_appreciation',
      'home_appreciation',
      'home_maintenance',
    ],
    rent_vs_buy_remove: ['seller_credit', 'misc', 'closing_date'],
    types: {
      home_price: '$',
      county: 'options',
      loan_type: 'options',
      down_payment: '%',
      interest_rate: '%',
      loan_term: '%',
      misc: '$',
      insurance_rate: '%',
      taxes: '%',
      closing_date: 'date',
      hoa: 'm',
      seller_credit: '$',
      funding_fee: 'options',
      current_rent: '$',
      gross_income: '$',
      rent_appreciation: '%',
      home_appreciation: '%',
      home_maintenance: '%',
    },
    names: {
      home_price: 'Home Price',
      county: 'County',
      loan_type: 'Loan Type',
      down_payment: 'Down Payment',
      interest_rate: 'Interest Rate',
      loan_term: 'Loan Term',
      misc: 'Misc',
      insurance_rate: 'Insurance Rate',
      taxes: 'Taxes',
      hoa: 'HOA',
      seller_credit: 'Seller Credit',
      closing_date: 'Closing Date',
      funding_fee: 'VA Funding Fee',
      current_rent: 'Current Rent',
      gross_income: 'Gross Income',
      rent_appreciation: 'Rent Appreciation',
      home_appreciation: 'Home Appreciation',
      home_maintenance: 'Home Maintenance',
    },
    values: {
      current_rent: '',
      home_price: '',
      county: profile?.ACCOUNT_ID === 1560 ? 'Clark' : 'Maricopa',
      loan_type: 'conventional',
      down_payment: 10,
      interest_rate: 6.625,
      loan_term: 30,
      funding_fee: 2.15,
      insurance_rate: 0.39,
      taxes: 1,
      hoa: '',
      gross_income: '',
      home_appreciation: 3.5,
      rent_appreciation: 2.5,
      home_maintenance: 0.5,
      misc: '',
      seller_credit: '',
      closing_date: new Date(
        new Date().setDate(new Date().getDate() + 30)
      )?.toISOString(),
    },
    option_values: {
      county: [],
      loan_type: loans,
      funding_fee: [1.25, 1.5, 2.15, 3.3],
    },
    placeholders: {
      home_price: 'required',
      current_rent: 'required',
      gross_income: '$77,000',
    },
    credit_score: '720-739',
    calculated: false,
    is_mip: 'false',
    is_pmi: 'true',

    prepaids: 3000,
    prepaids_type: '$',
    fixed: 5426,
    fixed_type: '$',

    bring_to_close_type: '$',

    monthly_chart: {
      chart: {
        id: 'monthly_payment',
        animations: {
          speed: 600,
        },
        events: {
          click: (e, chart, config) => {
            if (
              e.layerX > 64 &&
              e.layerX < 140 &&
              e.layerY > 56 &&
              e.layerY < 106
            ) {
              setCalc((prev) => ({
                ...prev,
                buy: {
                  ...prev?.buy,
                  show_total_edit_type: 'monthly_payment',
                  original_down: prev?.buy?.down_payment,
                  show_total_edit: true,
                  calculated: false,
                },
              }))
            }
          },
        },
      },
      ...donut_chart,
      colors,
    },
    tax_adjusted_chart: {
      chart: {
        id: 'monthly_payment',
        animations: {
          speed: 600,
        },
        events: {
          click: (e, chart, config) => {
            if (
              e.layerX > 64 &&
              e.layerX < 140 &&
              e.layerY > 56 &&
              e.layerY < 106
            ) {
              setCalc((prev) => ({
                ...prev,
                buy: {
                  ...prev?.buy,
                  show_total_edit_type: 'monthly_payment',
                  original_down: prev?.buy?.down_payment,
                  show_total_edit: true,
                  calculated: false,
                },
              }))
            }
          },
        },
      },
      ...donut_chart,
      plotOptions: {
        ...donut_chart.plotOptions,
        pie: {
          ...donut_chart.plotOptions.pie,
          donut: {
            size: '70%',
            labels: {
              show: true,
              name: { offsetY: 25 },
              value: { offsetY: -8, fontSize: '14px' },
              total: {
                value: 200,
                show: true,
                showAlways: true,
                label: '',
                fontSize: '8px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                color: '#373d3f',
                formatter: function (w) {
                  const total = w.globals.seriesTotals.reduce((a, b) => {
                    return a + b
                  }, 0)
                  return `$${format_currency(Math.round(total))}`
                },
              },
            },
          },
        },
      },
      colors,
    },
    affordability_chart: {
      chart: {
        id: 'affordability_chart',
        animations: {
          speed: 600,
        },
        events: {
          click: (e, chart, config) => {
            if (
              e.layerX > 64 &&
              e.layerX < 140 &&
              e.layerY > 56 &&
              e.layerY < 106
            ) {
              setCalc((prev) => ({
                ...prev,
                buy: {
                  ...prev?.buy,
                  show_total_edit_type: 'home_price',
                  original_down: prev?.buy?.values?.down_payment,
                  show_total_edit: true,
                  calculated: false,
                },
              }))
            }
          },
        },
      },
      ...donut_chart,
      plotOptions: {
        ...donut_chart.plotOptions,
        pie: {
          ...donut_chart.plotOptions.pie,
          donut: {
            size: '70%',
            labels: {
              show: true,
              name: { offsetY: 25 },
              value: { offsetY: -8, fontSize: '14px' },
              total: {
                value: 200,
                show: true,
                showAlways: true,
                label: '',
                fontSize: '8px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                color: '#373d3f',
                formatter: function (w) {
                  const total = w.globals.seriesTotals.reduce((a, b) => {
                    return a + b
                  }, 0)
                  return `$${format_currency(Math.round(total))}`
                },
              },
            },
          },
        },
      },
      colors,
    },
    closing_chart: {
      chart: {
        id: 'closing',
        animations: {
          speed: 600,
        },
      },
      ...donut_chart,
      labels: ['Down', 'Prepaids', 'Fixed', 'Misc'],
      colors,
    },
    monthly_payment_labels: ['P&I', 'Taxes', 'Insurance', 'PMI', 'MIP'],
    close_labels: ['Down', 'Prepaids', 'Fixed'],
    monthly_payment_series: [1354.26, 169.79, 76.38, 79.31],
    close_series: [8225.5, 3000.39, 5426.75],
    colors,
  }
  const [calc, setCalc] = useState({
    group: 'buy',
    share_vbc: 'N',
    gross_incomes: {
      19050: 10,
      77400: 12,
      165000: 22,
      315000: 24,
      400000: 32,
      600000: 35,
      600001: 37,
    },
    buy: { ...buy },
    loan_2: { ...buy },
    loan_3: { ...buy },
    sell: {
      defaults: {
        fha: {
          ...structuredClone(basic_defaults_sell),
        },
        conventional: {
          ...structuredClone(basic_defaults_sell),
        },
        ['all cash']: { ...structuredClone(basic_defaults_sell) },
        ['conv 2nd']: { ...structuredClone(basic_defaults_sell) },
        ['usda']: { ...structuredClone(basic_defaults_sell) },
        ['interest only']: { ...structuredClone(basic_defaults_sell) },
        ['owner finance']: { ...structuredClone(basic_defaults_sell) },
        ['va']: { ...structuredClone(basic_defaults_sell) },
      },
      types: {
        sale_price: '$',
        broker_fee: '%',
        repairs: '$',
        misc: '$',
        taxes: '%',
        loan_balance: '$',
        // interest_rate: '%',
        closing_date: 'date',
        loan_type: 'options',
        county: 'options',
        buyer_credit: '$',
      },
      option_values: {
        loan_type: loans,
        county: [],
      },
      placeholders: {
        sale_price: 'Required',
      },
      names: {
        sale_price: 'Sales Price',
        loan_type: 'Loan Type',
        county: 'County',
        broker_fee: 'Broker Fee',
        repairs: 'Repairs',
        misc: 'Misc',
        taxes: 'Taxes',
        loan_balance: 'Loan Balance',
        buyer_credit: 'Buyer Credit',
        // interest_rate: 'Interest Rate',
        closing_date: 'Closing Date',
      },
      values: {
        sale_price: '',
        loan_type: 'Conventional',
        county: profile?.ACCOUNT_ID === 1560 ? 'Clark' : 'Maricopa',
        loan_balance: '',
        taxes: '1',
        repairs: '',
        misc: '',
        broker_fee: '6',
        buyer_credit: '',
        // interest_rate: '3.625',
        closing_date: new Date(
          new Date().setDate(new Date().getDate() + 30)
        )?.toISOString(),
      },
      data_options: {},
      net_at_close_chart: {
        labels: [
          'Balance',
          'Prorated Tax Owed',
          'Closing Costs',
          'Net at Close',
        ],
        series: [],
        name: 'Net at Close',
        options: {
          chart: {
            id: 'net_at_close',
            animations: {
              speed: 600,
            },
            events: {
              // click: (e, chart, config) => {
              //   if (
              //     e.layerX > 64 &&
              //     e.layerX < 140 &&
              //     e.layerY > 56 &&
              //     e.layerY < 106
              //   ) {
              //     setCalc((prev) => ({
              //       ...prev,
              //       buy: {
              //         ...prev?.buy,
              //         show_total_edit_type: 'monthly_payment',
              //         original_down: prev?.buy?.down_payment,
              //         show_total_edit: true,
              //         calculated: false,
              //       },
              //     }))
              //   }
              // },
            },
          },
          ...donut_chart,
          plotOptions: {
            ...donut_chart.plotOptions,
            pie: {
              ...donut_chart.plotOptions.pie,
              donut: {
                ...donut_chart.plotOptions.pie.donut,
                size: '80%',
                labels: {
                  ...donut_chart.plotOptions.pie.donut.labels,
                  total: {
                    ...donut_chart.plotOptions.pie.donut.labels.total,
                    show: false,
                    // formatter: function (w) {
                    //   const series = w.globals.seriesTotals
                    //   const balance = series?.[0]
                    //   const taxes = series?.[1]
                    //   const closing_costs = series?.[2]
                    //   let net_at_close = series?.[3]
                    //   let negative_sign = ''
                    //   if (net_at_close < 0) {
                    //     negative_sign = '-'
                    //     net_at_close = Math.abs(net_at_close)
                    //     saveCalc((prev) => ({
                    //       ...prev,
                    //       sell: {
                    //         ...prev?.sell,
                    //         net_at_close_chart: {
                    //           ...prev?.sell?.net_at_close_chart,
                    //           negative_sign,
                    //           series: [
                    //             balance,
                    //             taxes,
                    //             closing_costs,
                    //             net_at_close,
                    //           ],
                    //         },
                    //       },
                    //     }))
                    //   }

                    //   const total =
                    //     balance + taxes + closing_costs + net_at_close
                    //   return `$${
                    //     calc?.sell?.net_at_close_chart?.negative_sign || ''
                    //   }${format_currency(Math.round(net_at_close), false, 0)}`
                    // },
                  },
                },
              },
            },
          },
          labels: [
            'Balance',
            'Prorated Tax Owed',
            'Closing Costs',
            'Net at Close',
          ],
          width: 300,
          colors: ['#00E396', '#FEB019', '#FF4560', '#008FFB', '#775DD0'],
        },
      },
    },
    loan_balance: {
      data_options: {},
      chart: {
        chart: {
          id: 'loan_balance_chart',
          animations: {
            speed: 600,
          },
          // events: {
          //   click: (e, chart, config) => {
          //     if (
          //       e.layerX > 64 &&
          //       e.layerX < 140 &&
          //       e.layerY > 56 &&
          //       e.layerY < 106
          //     ) {
          //       setCalc((prev) => ({
          //         ...prev,
          //         buy: {
          //           ...prev?.buy,
          //           show_total_edit_type: 'monthly_payment',
          //           original_down: prev?.buy?.down_payment,
          //           show_total_edit: true,
          //           calculated: false,
          //         },
          //       }))
          //     }
          //   },
          // },
        },
        ...donut_chart,
        plotOptions: {
          ...donut_chart?.plotOptions,
          pie: {
            ...donut_chart?.plotOptions?.pie,

            donut: {
              ...donut_chart?.plotOptions?.pie?.donut,
              labels: {
                show: true,
                name: { offsetY: 25 },
                value: { offsetY: -8, fontSize: '14px' },
                total: {
                  value: 200,
                  show: false,
                  showAlways: true,
                  label: '',
                  fontSize: '8px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 600,
                  color: '#373d3f',
                  formatter: function (w) {
                    const total = w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0)
                    return `$${format_currency(Math.round(total))}`
                  },
                },
              },
            },
          },
        },
        colors,
      },
      defaults: {
        is_home_price: 'Y',
      },
      values: {
        loan_amount: '',
        home_price: '',
        down_payment: 3.5,
        interest_rate: 3.625,
        loan_term: 30,
        loan_start_date: new Date(
          new Date().setFullYear(new Date().getFullYear() - 1)
        )?.toISOString(),
        loan_end_date: new Date(
          new Date().setDate(new Date().getDate() + 0)
        )?.toISOString(),
      },
      types: {
        loan_amount: '$',
        home_price: '$',
        down_payment: '%',
        interest_rate: '%',
        loan_term: '%',
        loan_start_date: 'date',
        loan_end_date: 'date',
      },
      placeholders: {
        loan_amount: 'Required',
        home_price: 'Required',
      },
      names: {
        loan_amount: 'Loan Amount',
        home_price: 'Orig. Home Price',
        down_payment: 'Down Payment',
        interest_rate: 'Interest Rate',
        loan_term: 'Loan Term',
        loan_start_date: 'Loan Start Date',
        loan_end_date: 'Current Date',
      },
    },
    extra_payment: {
      data_options: {},
      chart: {
        chart: {
          id: 'loan_balance_chart',
          animations: {
            speed: 600,
          },
          // events: {
          //   click: (e, chart, config) => {
          //     if (
          //       e.layerX > 64 &&
          //       e.layerX < 140 &&
          //       e.layerY > 56 &&
          //       e.layerY < 106
          //     ) {
          //       setCalc((prev) => ({
          //         ...prev,
          //         buy: {
          //           ...prev?.buy,
          //           show_total_edit_type: 'monthly_payment',
          //           original_down: prev?.buy?.down_payment,
          //           show_total_edit: true,
          //           calculated: false,
          //         },
          //       }))
          //     }
          //   },
          // },
        },
        ...donut_chart,
        plotOptions: {
          ...donut_chart?.plotOptions,
          pie: {
            ...donut_chart?.plotOptions?.pie,

            donut: {
              ...donut_chart?.plotOptions?.pie?.donut,
              labels: {
                show: true,
                name: { offsetY: 25 },
                value: { offsetY: -8, fontSize: '14px' },
                total: {
                  value: 200,
                  show: false,
                  showAlways: true,
                  label: 'Interest Saved',
                  fontSize: '8px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 600,
                  color: '#373d3f',
                  formatter: function (w) {
                    const total = w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0)
                    return `$${format_currency(Math.round(total))}`
                  },
                },
              },
            },
          },
        },
        colors,
      },
      defaults: {
        is_home_price: 'Y',
      },
      values: {
        loan_amount: '',
        home_price: '',
        extra_payment: 120,
        down_payment: 10,
        interest_rate: 7.125,
        loan_term: 30,
        loan_start_date: new Date(
          new Date().setDate(new Date().getDate() + 0)
        )?.toISOString(),
      },
      types: {
        loan_amount: '$',
        extra_payment: '$',
        home_price: '$',
        down_payment: '%',
        interest_rate: '%',
        loan_term: '%',
        loan_start_date: 'date',
      },
      placeholders: {
        home_price: 'Required',
        loan_amount: 'Required',
      },
      names: {
        extra_payment: 'Extra Payment',
        loan_amount: 'Loan Amount',
        home_price: 'Orig. Home Price',
        down_payment: 'Down Payment',
        interest_rate: 'Interest Rate',
        loan_term: 'Loan Term',
        loan_start_date: 'Loan Start Date',
      },
    },
    holding_costs: {
      data_options: {},
      defaults: {
        is_days: 'Y',
        run: 1,
      },
      values: {
        taxes: 1,
        insurance_rate: 0.4,
        interest_rate: 3.625,
        hoa: '',
        utilities: '',
        other: '',
      },
      types: {
        taxes: '%',
        insurance_rate: '%',
        interest_rate: '%',
        hoa: '%',
        utilities: '%',
        other: '%',
      },
      placeholders: {},
      names: {
        taxes: 'Taxes',
        insurance_rate: 'Insurance',
        interest_rate: 'Interest Rate',
        hoa: 'HOA',
        utilities: 'Utilities',
        other: 'Other',
      },
      chart: {
        // labels: [
        //   'Balance',
        //   'Prorated Tax Owed',
        //   'Closing Costs',
        //   'Net at Close',
        // ],
        series: [
          {
            name: 'Taxes',
            data: [44, 55, 41, 67],
          },
          {
            name: 'Utilities',
            data: [13, 23, 20, 8],
          },
          {
            name: 'HOA',
            data: [11, 17, 15, 15],
          },
          {
            name: 'Other',
            data: [21, 7, 25, 13],
          },
          {
            name: 'Insurance',
            data: [21, 7, 25, 13],
          },
        ],
        options_2: {
          plugins: {
            title: {
              display: false,
              text: 'Chart.js Bar Chart - Stacked',
            },
            datalabels: {
              display: true,
              color: '#f1f1f1',
              font: {
                weight: 'bold',
                size: 10,
              },
              formatter: function (value, context) {
                return [context.dataset.label, value]
              },
            },
          },
          responsive: true,
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          },
        },
        options: {
          ...stacked_chart,
        },
      },
    },
    title_fees: {
      data_options: {},
      defaults: {},
      option_values: {
        program_type: ['buyer_with_financing', 'buyer_cash', 'seller'],
      },
      values: {
        program_type: 'buyer_with_financing',
        home_price: '',
        down_payment: '',
        refi_amount: '',
      },
      types: {
        home_price: '$',
        down_payment: '%',
        refi_amount: '$',
        program_type: 'options',
      },
      placeholders: {},
      names: {
        home_price: 'Home Price',
        down_payment: 'Down',
        refi_amount: 'Refi Amount',
        program_type: 'Program',
      },
    },
  })

  const saveCalc = useCallback((data) => {
    setCalc(data)
  }, [])

  const get_sell_price = () => {
    return calc?.sell?.values?.sale_price
  }

  const calc_rent = (monthly_rent, appreciation, year_term) => {
    let total_rent = monthly_rent
    let total_payments = 0
    let current_year = 0
    const payments = array_nums(year_term * 12, false, (index) => {
      index = index

      if (index % 12 === 0 && index) {
        total_rent =
          total_rent + validate_number(total_rent * (appreciation / 100))
        current_year++
      }
      const payment = total_rent
      total_payments += payment
      const get_month = (index, current_year) => {
        if (!current_year) {
          if (index % 12 === 0 && index) {
            return 12
          } else if (index % 12 === 0) {
            return 1
          } else {
            return index % 12
          }
        }
        if (index % 12 === 0 && index) {
          return 0
        } else if (index % 12 === 0) {
          return 11
        } else {
          return index % 12
        }
      }
      return {
        index,
        year: current_year,
        month: get_month(index, current_year),
        payment,
        total: total_payments,
      }
    })
    return { payments, total: total_payments }
  }

  const get_tax_bracket = (income, bracket = calc?.gross_incomes) => {
    /* gross_incomes: {
      19050: 10,
      77400: 12,
      165000: 22,
      315000: 24,
      400000: 32,
      600000: 35,
      600001: 37,
    },*/
    income = validate_number(income)
    // return bracket percent
    if (income < 100) {
      return income
    }
    let bracket_percent
    let last_key
    Object.entries(bracket).forEach(([key, value]) => {
      if (validate_number(last_key) + 1 <= income) {
        bracket_percent = value
      }
      last_key = key
    })
    return bracket_percent
  }
  const get_tax_bracket_min_income = (
    income,
    bracket = calc?.gross_incomes
  ) => {
    /* gross_incomes: {
      19050: 10,
      77400: 12,
      165000: 22,
      315000: 24,
      400000: 32,
      600000: 35,
      600001: 37,
    },*/
    income = validate_number(income)
    // return bracket percent
    if (income > 100) {
      return income
    }
    let bracket_percent
    Object.entries(bracket).forEach(([key, value]) => {
      if (validate_number(value) <= income) {
        bracket_percent = validate_number(key)
      }
    })
    return bracket_percent
  }

  const get_ltv = (loan, home_price) => {
    const ltv = Number(((loan / home_price) * 100)?.toFixed(2))
    return ltv
  }

  const get_pmi = (
    term_payments,
    term_num,
    amortize_schedule,
    home_price_num
  ) => {
    if (!(calc?.buy?.is_pmi?.toString() === 'true')) {
      return 0
    }
    let total = 0
    const data = calc?.buy?.defaults?.conventional?.pmi_data
    for (let i = 0; i < term_payments; i++) {
      const values =
        data?.[calc?.buy?.credit_score]?.[
          Math.ceil((term_num * 12 - i) / 12) >= 20 ? '20+' : '20-'
        ]

      const loan_amount =
        i === 0
          ? amortize_schedule?.[i]?.remaining_balance +
            amortize_schedule?.[i]?.principal
          : amortize_schedule?.[i - 1]?.remaining_balance

      const ltv = get_ltv(loan_amount, home_price_num)
      if (ltv > 80) {
        for (const value of values) {
          if (ltv > value?.min && ltv <= value?.max) {
            total += loan_amount * value?.rate
          }
        }
      }
    }
    return total
  }

  const get_pmi_and_years = (
    term_payments,
    term_num,
    amortize_schedule,
    home_price_num,
    force_run = false
  ) => {
    if (!(calc?.buy?.is_pmi?.toString() === 'true') && !force_run) {
      return 0
    }
    let total = 0
    let initial_payment = 0
    let payment = 0
    let count = 0
    const data = calc?.buy?.defaults?.conventional?.pmi_data
    const payments = []
    for (let i = 0; i < term_payments; i++) {
      const values =
        data?.[calc?.buy?.credit_score]?.[
          Math.ceil((term_num * 12 - i) / 12) >= 20 ? '20+' : '20-'
        ]

      const loan_amount =
        i === 0
          ? amortize_schedule?.[i]?.remaining_balance +
            amortize_schedule?.[i]?.principal
          : amortize_schedule?.[i - 1]?.remaining_balance

      const ltv = get_ltv(loan_amount, home_price_num)

      if (ltv > 80) {
        count++
        for (const value of values) {
          if (ltv > value?.min && ltv <= value?.max) {
            if (count === 1) {
              initial_payment = (loan_amount * value?.rate) / 12
            }
            payment = (loan_amount * value?.rate) / 12
            total += (loan_amount * value?.rate) / 12
          }
        }
      } else {
        payment = 0
      }
      payments.push({ total, payment, count, index: i })
    }
    return {
      total,
      months: count,
      years: count / 12,
      initial_payment,
      payments,
    }
  }

  const get_pmi_from_loan = (
    term_payments,
    term_num,
    loan_amount,
    home_price_num
  ) => {
    if (!(calc?.buy?.is_pmi?.toString() === 'true')) {
      return 0
    }
    let total = 0
    const data = calc?.buy?.defaults?.conventional?.pmi_data
    for (let i = 0; i < term_payments; i++) {
      const values =
        data?.[calc?.buy?.credit_score]?.[
          Math.ceil((term_num * 12 - i) / 12) >= 20 ? '20+' : '20-'
        ]

      const ltv = get_ltv(loan_amount, home_price_num)
      if (ltv > 80) {
        for (const value of values) {
          if (ltv > value?.min && ltv <= value?.max) {
            total += loan_amount * value?.rate
          }
        }
      }
    }
    return total
  }

  const get_mip_base = (loan_amount) => {
    if (calc?.buy?.is_mip?.toString() === 'true') {
      return loan_amount * 0.0175
    } else {
      return 0
    }
  }

  const get_va_funding_fee = (
    calculator_type = 'buy',
    loan_amount,
    down_payment
  ) => {
    const loan_type = get_loan_type(calculator_type, 'values')
    const curr_funding_fee =
      loan_type !== 'va'
        ? 0
        : validate_number(calc?.['buy']?.values?.funding_fee)
    return loan_amount * (curr_funding_fee / 100)
  }

  const remove_mip_base = (loan_amount) => {
    return loan_amount - get_mip_base(loan_amount)
  }

  const get_yearly_mip = (home_price, loan_amount, loan_term) => {
    const data = {
      '15+': {
        low: [
          { min: 95, max: 100, rate: 0.0055 },
          { min: 90, max: 95, rate: 0.005 },
          { min: 0.01, max: 90, rate: 0.005 },
        ],
        high: [
          { min: 95, max: 100, rate: 0.0075 },
          { min: 90, max: 95, rate: 0.007 },
          { min: 0.01, max: 90, rate: 0.007 },
        ],
      },
      '15-': {
        low: [
          { min: 90, max: 100, rate: 0.004 },
          { min: 0.01, max: 90, rate: 0.0015 },
        ],
        high: [
          { min: 90, max: 100, rate: 0.0065 },
          { min: 78, max: 90, rate: 0.004 },
          { min: 0.01, max: 78, rate: 0.0015 },
        ],
      },
    }
    let mip = 0
    if (!(calc?.buy?.is_mip?.toString() === 'true')) {
      return mip
    }
    const values =
      data?.[validate_number(loan_term) > 15 ? '15+' : '15-']?.[
        loan_amount > 726200 ? 'high' : 'low'
      ]
    for (const value of values) {
      const ltv = get_ltv(loan_amount, home_price)
      if (ltv > value?.min && ltv <= value?.max) {
        mip = loan_amount * value?.rate
      }
    }
    return mip
  }

  const validate_num = (
    calculator_type = 'buy',
    key,
    type = 'closing_costs',
    { principal_num }
  ) => {
    const loan_type = get_loan_type(calculator_type, 'defaults')
    const defaults = calc?.[calculator_type]?.defaults?.[loan_type]
    const default_closing =
      calc?.[calculator_type]?.defaults?.[loan_type]?.closing_costs
    const get_num = (obj) => {
      if (
        typeof calc?.[calculator_type]?.[key] === 'boolean' ||
        typeof obj?.[key] === 'boolean'
      ) {
        return calc?.[calculator_type]?.[key] || obj?.[key]
      }
      const initial_val =
        calc?.[calculator_type]?.[key]?.toString()?.replace(/[,$%]/g, '') ||
        obj?.[key]?.toString()?.replace(/[,$%]/g, '')
      const initial = Number(initial_val)
      let initial_num
      if (initial && key === 'origination_fee') {
        initial_num =
          ((principal_num || 100) * (initial >= 100 ? 100 : initial)) / 100
      } else {
        initial_num = Number(
          type === 'closing_costs'
            ? obj?.[`${key}_type`] !== '$'
              ? ((principal_num || 100) * initial) / 100
              : initial
            : initial
        )
      }
      const num = isNaN(initial_num) ? initial_val : initial_num
      return num
    }
    if (type === 'closing_costs' || type === 'prepaids') {
      return get_num(default_closing)
    } else if (type === 'defaults') {
      return get_num(defaults)
    }
    return 0
  }

  const get_home_price = (calculator_type = 'buy') => {
    let price = 0
    if (calculator_type !== 'sell') {
      price = calc?.['buy']?.values?.home_price
    } else {
      price = calc?.[calculator_type]?.values?.sale_price
    }
    return validate_number(price)
  }
  const get_loan_term = (calculator_type = 'buy') => {
    return Number(calc?.[calculator_type]?.values?.loan_term)
  }
  const get_down_payment = (calculator_type = 'buy') => {
    if (calculator_type !== 'sell') {
      return calc?.[calculator_type]?.types?.down_payment === '%'
        ? get_home_price(calculator_type) *
            (validate_number(calc?.[calculator_type]?.values?.down_payment) /
              100)
        : validate_number(calc?.[calculator_type]?.values?.down_payment)
    } else {
      return 0
    }
  }
  const get_principal = (calculator_type = 'buy', home_price, down_payment) => {
    let principal_num =
      (home_price || get_home_price(calculator_type)) -
      (down_payment || get_down_payment(calculator_type))

    return principal_num
  }
  const get_insurance_num = (calculator_type = 'buy', home_price) => {
    let value = 0
    value =
      calc?.[calculator_type]?.types?.insurance_rate === '%'
        ? ((home_price || get_home_price(calculator_type)) *
            validate_number(calc?.[calculator_type]?.values?.insurance_rate)) /
          100
        : validate_number(calc?.[calculator_type]?.values?.insurance_rate)

    return value
  }

  const get_tax_num = (calculator_type = 'buy', home_price) => {
    return calc?.[calculator_type]?.types?.taxes === '%'
      ? ((home_price || get_home_price(calculator_type)) *
          validate_number(calc?.[calculator_type]?.values?.taxes)) /
          100
      : validate_number(calc?.[calculator_type]?.values?.taxes)
  }

  const get_interest_rate_num = (calculator_type = 'buy') => {
    return validate_number(calc?.[calculator_type]?.values?.interest_rate)
  }

  const get_title_fees = (
    calculator_type = 'buy',
    key,
    type,
    new_home_price = 0,
    new_principal = 0,
    new_loan_type
  ) => {
    const loan_type =
      new_loan_type || get_loan_type(calculator_type, 'defaults')
    const defaults = calc?.[calculator_type]?.defaults?.[loan_type]
    const default_closing =
      calc?.[calculator_type]?.defaults?.[loan_type]?.closing_costs
    const home_price = new_home_price || get_home_price(calculator_type)
    const fee_data = calc?.cur_escrow_rates?.filter(
      (item) =>
        item?.county?.toLowerCase() ===
        (calc?.[calculator_type]?.county?.toLowerCase() ||
          calc?.[calculator_type]?.defaults?.county?.toLowerCase() ||
          (profile?.ACCOUNT_ID === 1560 ? 'clark' : 'maricopa'))
    )

    const scenario = calc?.calc_scenario?.[0]
    const home_fees = find_closest_ge(fee_data, 'liability_amt', home_price)

    const loan_fees = find_closest_ge(
      fee_data,
      'liability_amt',
      calculator_type === 'sell'
        ? home_price
        : new_principal || get_principal(calculator_type)
    )
    const escrow_fee_type =
      key === 'escrow_fee' && type ? type : default_closing?.escrow_fee

    const check_null = (val) => {
      if (validate_number(val) >= 0) {
        return validate_number(val)
      } else {
        return 0
      }
    }

    // no loan_fee, no def_lender_amt, no endorsements - (remove based on fee name), cpl fee - (remove based on fee name)

    // no loan fee for seller
    const esc_buy_fee =
      check_null(home_fees?.esc_fee) *
      (validate_number(scenario?.buy_esc_disc_pct) || 1)
    const esc_buy_total =
      check_null(esc_buy_fee) +
      check_null(scenario?.buy_sign_amt) +
      check_null(scenario?.buy_bund_amt) -
      (loan_type === 'all cash' ? validate_number(home_fees?.loan_fee_amt) : 0)
    const esc_sell_fee =
      check_null(home_fees?.esc_fee) *
      (validate_number(scenario?.sell_esc_disc_pct) || 1)
    const esc_sell_total =
      check_null(esc_sell_fee) +
      check_null(scenario?.sell_sign_amt) +
      check_null(scenario?.sell_bund_amt)
    const escrow_fee =
      escrow_fee_type === 'both'
        ? esc_buy_total + esc_sell_total
        : escrow_fee_type === 'buyer'
        ? calculator_type === 'sell'
          ? 0
          : esc_buy_total
        : calculator_type === 'sell'
        ? esc_sell_total
        : 0

    const title_policy_fee_type =
      key === 'title_policy_fee' && type
        ? type
        : default_closing?.title_policy_fee

    const buy_lend_fee =
      check_null(loan_fees?.base_title_premium) *
      (loan_type === 'owner finance' ? 1 : check_null(scenario?.lend_rate_pct))
    const sell_lend_fee =
      check_null(loan_fees?.base_title_premium) *
      check_null(scenario?.own_rate_pct)
    const title_policy_fee = Math.ceil(
      validate_number(
        title_policy_fee_type === 'both'
          ? buy_lend_fee + sell_lend_fee
          : title_policy_fee_type === 'buyer'
          ? calculator_type === 'sell'
            ? 0
            : buy_lend_fee
          : calculator_type === 'sell'
          ? sell_lend_fee
          : 0
      )
    )

    const add_fees = get_fees_arr(calculator_type, 'title_fees')?.reduce(
      (curr, val) => {
        curr[val] =
          calc?.[calculator_type]?.defaults?.[
            get_loan_type(calculator_type)
          ]?.closing_costs?.[val]
        return curr
      },
      {}
    )

    const fees = {
      escrow_fee,
      title_policy_fee,
      ...add_fees,
    }
    if (key) {
      return fees?.[key] || 0
    } else {
      return fees
    }
  }

  const get_prepaids = (
    calculator_type = 'buy',
    key,
    item,
    principal = 0,
    home_price = 0
  ) => {
    if (calculator_type === 'sell') {
      return 0
    }
    const principal_num = principal || get_principal()
    const insurance_num = get_insurance_num(calculator_type, home_price)
    const tax_num = get_tax_num(calculator_type, home_price)
    const interest_rate_num = get_interest_rate_num()
    const validate_prepaids_num = (key) => {
      return validate_num(calculator_type, key, 'prepaids', { principal_num })
    }
    const months_of_insurance = validate_prepaids_num('months_of_insurance')
    const months_of_taxes = validate_prepaids_num('months_of_taxes')

    const days_of_interest = validate_prepaids_num('days_of_interest')
    const insurance =
      (insurance_num / 12) *
      (key === 'months_of_insurance' && item ? item : months_of_insurance)
    const taxes =
      (tax_num / 12) *
      (key === 'months_of_taxes' && item ? item : months_of_taxes)
    const interest =
      (principal_num *
        (key === 'days_of_interest' && item ? item : days_of_interest) *
        (interest_rate_num / 100)) /
      365
    if (calc?.buy?.loan_type === 'All Cash') {
      return 0
    }
    const data = {
      months_of_insurance: validate_number(insurance, 2),
      months_of_taxes: validate_number(taxes, 2),
      days_of_interest: validate_number(interest, 2),
    }
    if (key) {
      return data?.[key] || 0
    }
    return insurance + taxes + interest
  }

  const get_mi = (
    calculator_type = 'buy',
    home_price,
    principal,
    interest_rate,
    loan_term
  ) => {
    const mi =
      calc?.[calculator_type]?.is_mip?.toString() === 'true'
        ? get_yearly_mip(home_price, principal, loan_term)
        : get_pmi(
            1,
            loan_term,
            calculate_amortization_totals(principal, interest_rate, loan_term)
              ?.schedule,
            home_price
          )
    return mi
  }

  const get_nums = (calculator_type = 'buy') => {
    const home_price_num = get_home_price(calculator_type)
    const interest_rate_num = get_interest_rate_num(calculator_type)
    const term_num = get_loan_term(calculator_type)
    const tax_num = get_tax_num(calculator_type)
    const insurance_num = get_insurance_num(calculator_type)
    let down_payment_num = get_down_payment(calculator_type)

    const max_fha_num = validate_number(calc?.[calculator_type]?.max_fha)

    if (
      calc?.[calculator_type]?.values?.loan_type === 'FHA' &&
      home_price_num > max_fha_num &&
      max_fha_num &&
      down_payment_num < home_price_num - max_fha_num
    ) {
      down_payment_num = home_price_num - max_fha_num
    }

    let principal_num = get_principal(
      calculator_type,
      home_price_num,
      down_payment_num
    )
    const prorated_tax_num = calc_tax_proration(
      calculator_type,
      tax_num,
      calc?.[calculator_type]?.values?.closing_date,
      profile?.ACCOUNT_ID
    )
    const seller_credit_num =
      (calc?.[calculator_type]?.types?.seller_credit === '%'
        ? home_price_num *
          (validate_number(calc?.[calculator_type]?.values?.seller_credit) /
            100)
        : validate_number(calc?.[calculator_type]?.values?.seller_credit)) * -1
    const misc_num =
      calc?.[calculator_type]?.types?.misc === '%'
        ? home_price_num *
          (validate_number(calc?.[calculator_type]?.values?.misc) / 100)
        : validate_number(calc?.[calculator_type]?.values?.misc)
    const hoa_num =
      calc?.[calculator_type]?.types?.hoa === 'm'
        ? validate_number(calc?.[calculator_type]?.values?.hoa)
        : validate_number(calc?.[calculator_type]?.values?.hoa) / 12

    const amortization_data = calculate_amortization_totals(
      principal_num,
      interest_rate_num,
      term_num
    )

    const initial_nums = {
      home_price_num: !isNaN(home_price_num) ? home_price_num : 0,
      interest_rate_num: !isNaN(interest_rate_num) ? interest_rate_num : 0,
      term_num: !isNaN(term_num) ? term_num : 0,
      tax_num: !isNaN(tax_num) ? tax_num : 0,
      insurance_num: !isNaN(insurance_num) ? insurance_num : 0,
      principal_num: !isNaN(principal_num) ? principal_num : 0,
    }

    const prepaids_num =
      calc?.[calculator_type]?.prepaids_type === '%'
        ? (home_price_num * get_prepaids()) / 100
        : get_prepaids()

    const get_fixed = () => {
      const title_fees = get_title_fees(calculator_type)
      const escrow_fees = Object.values(title_fees)?.reduce(
        (curr, val) => validate_number(val) + curr,
        0
      )

      const validate_closing_num = (key) => {
        return validate_num(calculator_type, key, 'closing_costs', initial_nums)
      }
      const origination_fee = validate_closing_num('origination_fee')
      const processing_fee = validate_closing_num('processing_fee')
      const underwriting = validate_closing_num('underwriting')
      const appraisal = validate_closing_num('appraisal')
      const credit_report = validate_closing_num('credit_report')
      const termite_inspection = validate_closing_num('termite_inspection')
      const home_inspection = validate_closing_num('home_inspection')

      const lender_fees =
        get_fees_total(calculator_type, 'lender_fees') +
        get_origination_fee(calculator_type)
      // origination_fee +
      // underwriting +
      // processing_fee +
      // appraisal +
      // credit_report +
      // termite_inspection +
      // home_inspection

      const home_protection = validate_closing_num('home_protection')
      const other_fees = get_fees_total(calculator_type, 'other_fees')
      if (calc?.[calculator_type]?.loan_type === 'All Cash') {
        return escrow_fees + lender_fees + other_fees
      }
      return escrow_fees + lender_fees + other_fees
    }
    const fixed_num =
      calc?.[calculator_type]?.fixed_type === '%'
        ? (home_price_num * get_fixed()) / 100
        : get_fixed()

    const pmi_num =
      calc?.[calculator_type]?.is_mip?.toString() === 'true'
        ? get_yearly_mip(home_price_num, principal_num, term_num)
        : get_pmi(1, term_num, amortization_data?.schedule, home_price_num)
    return {
      home_price_num: !isNaN(home_price_num) ? home_price_num : 0,
      interest_rate_num: !isNaN(interest_rate_num) ? interest_rate_num : 0,
      term_num: !isNaN(term_num) ? term_num : 0,
      tax_num: !isNaN(tax_num) ? tax_num : 0,
      insurance_num: !isNaN(insurance_num) ? insurance_num : 0,
      down_payment_num: !isNaN(down_payment_num) ? down_payment_num : 0,
      misc_num: !isNaN(misc_num) ? misc_num : 0,
      hoa_num: !isNaN(hoa_num) ? hoa_num : 0,
      pmi_num: !isNaN(pmi_num) ? pmi_num : 0,
      principal_num: !isNaN(principal_num) ? principal_num : 0,
      prepaids_num: !isNaN(prepaids_num) ? prepaids_num : 0,
      fixed_num: !isNaN(fixed_num) ? fixed_num : 0,
      amortization_data,
      max_fha_num,
      seller_credit_num,
      prorated_tax_num,
      // pmi_total,
    }
  }

  const get_fees_arr = (calculator_type = 'buy', type) => {
    const loan_type = get_loan_type(calculator_type, 'values')
    const add_title_fees = calc?.calc_scenario_fees
    let filters_outs = ['origination_fee', 'title_policy_fee', 'escrow_fee']
    const endorsement_fee = add_title_fees?.find(
      (item) => item?.axiom_desc_name?.toLowerCase() === 'endorsements'
    )
    const cpl_fee = add_title_fees?.find(
      (item) => item?.axiom_desc_name?.toLowerCase() === 'cpl'
    )
    if (['owner finance', 'all cash']?.includes(loan_type)) {
      filters_outs = [
        ...filters_outs,
        `title_${endorsement_fee?.fee_id}`,
        `title_${cpl_fee?.fee_id}`,
      ]
    }
    // if (calculator_type === 'sell') {
    //   filters_outs = [
    //     ...filters_outs,
    //     `title_${endorsement_fee?.fee_id}`,
    //     `title_${cpl_fee?.fee_id}`,
    //   ]
    // }
    return calc?.[calculator_type]?.defaults?.[loan_type]?.[type]?.filter(
      (item) => !filters_outs.includes(item)
    )
  }

  const get_loan_type = (
    calculator_type = 'buy',
    is_value_point = 'defaults'
  ) => {
    if (calculator_type === 'sell') {
      return (
        calc?.[calculator_type]?.[is_value_point]?.loan_type || 'Conventional'
      )?.toLowerCase()
    } else {
      return (
        calc?.[calculator_type]?.[is_value_point]?.loan_type || 'Conventional'
      )?.toLowerCase()
    }
  }

  const get_fees_total = (calculator_type = 'buy', type, new_price = 0) => {
    if (calculator_type === 'sell' && type === 'lender_fees') {
      return 0
    }
    return get_fees_arr(calculator_type, type)?.reduce((curr, val) => {
      const closing_costs =
        calc?.[calculator_type]?.defaults?.[get_loan_type(calculator_type)]
          ?.closing_costs

      const type = closing_costs?.[`${val}_type`]
      let value = validate_number(closing_costs?.[val])
      if (
        (type === '%' || val.includes('broker_fee')) &&
        calculator_type === 'sell'
      ) {
        const sale_price = calc?.[calculator_type]?.values?.sale_price
        value = validate_number(new_price || sale_price) * (value / 100)
      } else if (type === '%' && calculator_type !== 'sell') {
        const home_price = calc?.[calculator_type]?.values?.home_price
        value = validate_number(new_price || home_price) * (value / 100)
      }
      return curr + value
    }, 0)
  }

  const get_title_fee_total = (
    calculator_type = 'buy',
    new_price = 0,
    new_principal = 0
  ) => {
    return Object.values(
      get_title_fees(calculator_type, null, null, new_price, new_principal)
    )?.reduce((curr, val) => validate_number(curr) + validate_number(val), 0)
  }

  const get_origination_fee = (calculator_type = 'buy', principal = 0) => {
    if (calculator_type === 'sell') {
      return 0
    }
    return (
      (validate_number(principal || get_principal(calculator_type)) *
        validate_number(
          calc?.[calculator_type]?.defaults?.[get_loan_type(calculator_type)]
            ?.closing_costs?.origination_fee || ''
        )) /
      100
    )
  }

  const swiper_slide = (toggle = true) => {
    const swiper = calc?.options?.swiper
    if (swiper) {
      // document.body.style.overflow = toggle ? 'auto' : 'hidden'
      swiper.allowTouchMove = toggle
    }
  }

  const get_calc_type_id = (calculator_type = 'buy') => {
    const map = {
      buy: 1,
      sell: 2,
      extra_payment: 21,
      loan_balance: 63,
      loan_compare: 41,
      loan_details: 61,
      holding_costs: 62,
      multiple_offers: 101,
      sell_to_net: 122,
      affordability: 43,
      title_fees: 44,
      rent_vs_buy: 121,
    }
    const id_map = {}
    Object.keys(map).forEach((key) => {
      id_map[map[key]] = key
    })
    return validate_number(calculator_type)
      ? id_map[calculator_type]
      : map?.[calculator_type]
  }

  const instanciate_calc = async (item, new_instance = null) => {
    if (new_instance) {
      item.instance_uuid = new_instance
    }
    const get_compare_obj = (prev, type = 'loan_2') => {
      return {
        ...prev?.[type],
        data_options: {
          ...prev?.[type]?.data_options,
          loan_edited: item?.[`${type}_edited`] === 'Y' ? true : false,
        },
        values: {
          ...prev?.[type]?.values,
          interest_rate: validate_number(item?.[`${type}_interest_rate`]),
          down_payment: validate_number(item?.[`${type}_down_payment`]),
          loan_term: validate_number(item?.[`${type}_term`]),
          loan_type: validate_number(item?.[`${type}_loan_type`]),
        },
      }
    }
    const item_loan_type = item?.loan_type?.toLowerCase() || 'conventional'
    const get_calc_obj = (filter_buy, obj = {}) => {
      const types = {}
      const values = {}
      const defaults = {}
      const longer = {
        amount: 'loan_amount',
        down: 'down_payment',
        end_date: 'loan_end_date',
        ins_rate: 'insurance_rate',
        int_rate: 'interest_rate',
        start_date: 'loan_start_date',
        uti: 'utilities',
      }
      const shortener = {
        interest_rate: 'int_rate',
        insurance_rate: 'ins_rate',
        down_payment: 'down',
        utilities: 'uti',
        loan_amount: 'amount',
        loan_start_date: 'start_date',
        loan_end_date: 'end_date',
      }
      Object.keys(obj)
        ?.filter(
          (key) =>
            key?.startsWith(filter_buy) &&
            !key?.includes('days') &&
            !key?.includes('months') &&
            !key?.startsWith(`${filter_buy}_is_`)
        )
        ?.map((key) => {
          const alter_key = (key) => {
            const new_key = key
            return new_key
              ?.replace(`${filter_buy}_`, '')
              ?.replace('_is_type', '')
          }
          if (key.endsWith('is_type')) {
            types[longer?.[alter_key(key)] || alter_key(key)] = obj[key]
          } else {
            if (
              ['$', '%', 'm', 'a'].includes(
                obj?.[
                  `${
                    shortener?.[alter_key(key)]
                      ? `hold_${shortener?.[alter_key(key)]}`
                      : key
                  }_is_type`
                ]
              )
            ) {
              values[longer?.[alter_key(key)] || alter_key(key)] =
                format_currency(obj[key], false, 3)
            } else {
              values[longer?.[alter_key(key)] || alter_key(key)] = obj[key]
            }
          }
        })
      if (filter_buy === 'hold') {
        defaults.is_days = obj?.hold_is_days || 'Y'
        defaults.run = 1
      }
      if (filter_buy === 'lbal') {
        defaults.is_home_price = obj?.lbal_is_home_price || 'Y'
      }
      return { types, values, defaults }
    }
    if (
      validate_number(item?.calc_type) === 1 ||
      validate_number(item?.calc_type) === 41 ||
      validate_number(item?.calc_type) === 61 ||
      validate_number(item?.calc_type) === 43 ||
      validate_number(item?.calc_type) === 121
    ) {
      swiper_slide(true)
      const fees = calc?.axiom_calc_hist_fees?.filter(
        (fee) => fee?.instance_uuid === item?.instance_uuid
      )
      const fee_groups = {
        prepaid_fees: [],
        title_fees: [],
        lender_fees: [],
        other_fees: [],
      }
      const closing_costs = {}
      const closing_costs_names = {}
      fees.forEach((item) => {
        if (fee_groups?.[item?.fee_group]) {
          fee_groups[item?.fee_group].push(item?.fee_key)
        }
        if (
          item?.fee_key === 'title_policy_fee' ||
          item?.fee_key === 'escrow_fee'
        ) {
          closing_costs[item?.fee_key] = item?.fee_is_type
          closing_costs_names[item?.fee_key] = item?.fee_name
        } else {
          closing_costs[item?.fee_key] = format_currency(
            item?.fee_value,
            false,
            2
          )
          closing_costs_names[item?.fee_key] = item?.fee_name
        }
      })

      setCalc((prev) => ({
        ...prev,
        formatted_address: item?.address,
        mls_id: item?.mls_id || 0,
        calculated: true,
        run: (prev?.buy?.run || 0) + 1,
        sell: prev?.sell_reset,
        buy: {
          ...prev?.buy,
          rent_vs_buy: {
            ...(prev?.buy?.rent_vs_buy || {}),
            break_curr_payment: item?.break_curr_payment
              ? validate_number(item?.break_curr_payment)
              : null,
          },
          data_options: {
            ...prev?.buy?.data_options,
            instance_uuid: item?.instance_uuid,
          },
          is_mip: item_loan_type === 'fha' ? 'true' : 'false',
          is_pmi: item_loan_type === 'conventional' ? 'true' : 'false',
          defaults: {
            ...prev?.buy?.defaults,
            loan_type: item_loan_type,
            [item_loan_type]: {
              ...prev?.buy?.defaults?.[item_loan_type],
              is_mip: item_loan_type === 'fha' ? 'true' : 'false',
              is_pmi: item_loan_type === 'conventional' ? 'true' : 'false',
              ...fee_groups,
              closing_costs: {
                // ...prev?.buy?.defaults?.[item_loan_type]?.closing_costs,
                // title_policy_fee: 'buyer',
                // escrow_fee: 'buyer',
                ...closing_costs,
              },
              closing_costs_names: {
                // ...prev?.buy?.defaults?.[item_loan_type]?.closing_costs_names,
                ...closing_costs_names,
              },
            },
          },
          calculated: true,
          desired_bring_to_close: null,
          monthly_payment: format_currency(item?.monthly_payment, false, 2),
          values: {
            ...prev?.buy?.values,
            county: item?.county,
            loan_type: item_loan_type,
            interest_rate: format_currency(item?.interest_rate, false, 3),
            current_rent: format_currency(item?.current_rent, false, 2),
            home_appreciation: format_currency(
              item?.home_appreciation,
              false,
              2
            ),
            rent_appreciation: format_currency(
              item?.rent_appreciation,
              false,
              2
            ),
            home_maintenance: format_currency(item?.home_maintenance, false, 2),
            gross_income: item?.gross_income
              ? format_currency(item?.gross_income, false, 2)
              : null,
            insurance_rate: format_currency(item?.insurance_rate),
            hoa: format_currency(item?.hoa),
            misc: format_currency(item?.misc),
            taxes: format_currency(item?.taxes, false, 3),
            closing_date: item?.closing_date?.split('T')?.[0],
            seller_credit: format_currency(item?.seller_credit),
            home_price: format_currency(item?.home_price),
            down_payment: format_currency(item?.down_payment, false, 4),
          },
          types: {
            ...prev?.buy?.types,
            county: item?.county_is_type || 'options',
            loan_type: item?.loan_type_is_type || 'options',
            interest_rate: item?.interest_rate_is_type || '%',
            insurance_rate: item?.insurance_rate_is_type || '$',
            hoa: item?.hoa_is_type || 'm',
            misc: item?.misc_is_type || '$',
            taxes: item?.taxes_is_type || '%',
            closing_date: item?.closing_date_is_type || 'date',
            seller_credit: item?.seller_credit_is_type || '$',
            home_price: item?.home_price_is_type || '$',
            down_payment: item?.down_payment_is_type || '%',
            current_rent: item?.current_rent_is_type || '$',
            home_appreciation: item?.home_appreciation_is_type || '%',
            rent_appreciation: item?.rent_appreciation_is_type || '%',
            home_maintenance: item?.home_maintenance_is_type || '%',
            gross_income: item?.gross_income_is_type || '$',
          },
        },
        loan_2: get_compare_obj(prev, 'loan_2'),
        loan_3: get_compare_obj(prev, 'loan_3'),
      }))
    } else if (
      validate_number(item?.calc_type) === 2 ||
      validate_number(item?.calc_type) === 63 ||
      validate_number(item?.calc_type) === 62 ||
      validate_number(item?.calc_type) === 101 ||
      validate_number(item?.calc_type) === 122
    ) {
      const fees = calc?.axiom_calc_hist_fees?.filter(
        (fee) => fee?.instance_uuid === item?.instance_uuid
      )
      const fee_groups = {
        prepaid_fees: [],
        title_fees: [],
        lender_fees: [],
        other_fees: [],
      }
      const closing_costs = {}
      const closing_costs_names = {}
      fees.forEach((item) => {
        if (fee_groups?.[item?.fee_group]) {
          fee_groups[item?.fee_group].push(item?.fee_key)
        }
        if (
          item?.fee_key === 'title_policy_fee' ||
          item?.fee_key === 'escrow_fee'
        ) {
          closing_costs[item?.fee_key] = item?.fee_is_type
          closing_costs_names[item?.fee_key] = item?.fee_name
        } else {
          closing_costs[item?.fee_key] = format_currency(
            item?.fee_value,
            false,
            2
          )
          closing_costs_names[item?.fee_key] = item?.fee_name
        }
      })
      const holding_costs = get_calc_obj('hold', item)
      const loan_balance = get_calc_obj('lbal', item)

      const get_multiple_offers = () => {
        const obj_offers = {}
        calc?.axiom_calc_hist_offers
          ?.filter((offer) => offer?.instance_uuid === item?.instance_uuid)
          ?.forEach((offer) => {
            offer = get_calc_obj('offer', offer)
            obj_offers[offer?.values?.key] = {
              types: offer?.types,
              values: {
                ...pick_keys(offer?.values, ['order', 'key', 'starred']),
              },
              placeholders: sell_offer_placeholders,
              names: sell_offer_names,
              data_options: {
                number: offer?.values?.order,
                key: offer?.values?.key,
                starred: offer?.values?.starred,
              },
            }
          })
        return obj_offers || {}
      }
      const multiple_offers = get_multiple_offers()

      setCalc((prev) => ({
        ...prev,
        formatted_address: item?.address,
        mls_id: item?.mls_id || 0,
        sell: {
          ...prev?.sell,
          data_options: {
            ...prev?.data_options,
            instance_uuid: item?.instance_uuid,
            calculated: true,
            ran_data: false,
            net_at_close: null,
            negative_at_close: null,
            net_changed: false,
            original_sale_price: null,
            net_at_close: item?.net_at_close_total,
          },
          defaults: {
            ...prev?.sell?.defaults,
            loan_type: item_loan_type,
            [item_loan_type]: {
              ...prev?.sell?.defaults?.[item_loan_type],
              is_mip: item_loan_type === 'fha' ? 'true' : 'false',
              is_pmi: item_loan_type === 'conventional' ? 'true' : 'false',
              ...fee_groups,
              closing_costs: {
                // ...prev?.sell?.defaults?.[item_loan_type]?.closing_costs,
                // title_policy_fee: 'seller',
                // escrow_fee: 'seller',
                ...closing_costs,
              },
              closing_costs_names: {
                // ...prev?.sell?.defaults?.[item_loan_type]?.closing_costs_names,
                ...closing_costs_names,
              },
            },
          },
          values: pick_keys(
            {
              ...prev?.sell?.values,
              sale_price: format_currency(item?.sale_price, false, 2),
              loan_balance: format_currency(item?.loan_balance, false, 2),
              repairs: format_currency(item?.repairs, false, 2),
              buyer_credit: format_currency(item?.buyer_credit, false, 2),
              county: item?.county,
              loan_type: item_loan_type,
              misc: format_currency(item?.misc),
              taxes: format_currency(item?.taxes, false, 3),
              broker_fee: format_currency(item?.broker_fee),
              closing_date: item?.closing_date?.split('T')?.[0],
            },
            ['extra_payment']
          ),
          types: {
            sale_price: item?.sale_price_is_type || '$',
            county: item?.county_is_type || 'options',
            loan_type: item?.loan_type_is_type || 'options',
            misc: item?.misc_is_type || '$',
            repairs: item?.repairs_is_type || '$',
            loan_balance: item?.loan_balance_is_type || '$',
            buyer_credit: item?.buyer_credit_is_type || '$',
            taxes: item?.taxes_is_type || '%',
            broker_fee: item?.broker_fee_is_type || '%',
            closing_date: item?.closing_date_is_type || 'date',
          },
        },
        holding_costs: {
          ...prev?.holding_costs,
          data_options: {
            ...prev?.loan_balance?.data_options,
            instance_uuid: item?.instance_uuid,
            calculated: true,
          },
          values: {
            ...prev?.holding_costs?.values,
            ...holding_costs?.values,
          },
          types: {
            ...prev?.holding_costs?.types,
            ...holding_costs?.types,
          },
          defaults: {
            ...prev?.holding_costs?.defaults,
            ...holding_costs?.defaults,
          },
        },
        loan_balance: {
          ...prev?.loan_balance,
          data_options: {
            ...prev?.loan_balance?.data_options,
            instance_uuid: item?.instance_uuid,
            calculated: true,
          },
          values: {
            ...prev?.loan_balance?.values,
            ...pick_keys(loan_balance?.values, [
              'interest_paid',
              'principal_paid',
              'extra_payment',
              'interest_saved',
              'payoff_time_label',
              'sale_price',
            ]),
          },
          types: {
            ...prev?.loan_balance?.types,
            ...loan_balance?.types,
          },
          defaults: {
            ...prev?.loan_balance?.defaults,
            ...loan_balance?.defaults,
          },
        },
        offers: {
          items: multiple_offers,
        },
      }))
    } else if (item?.calc_type === 21) {
      const extra_payment = get_calc_obj('lbal', item)
      setCalc((prev) => ({
        ...prev,
        formatted_address: item?.address,
        mls_id: item?.mls_id || 0,
        extra_payment: {
          ...prev?.extra_payment,
          data_options: {
            ...prev?.extra_payment?.data_options,
            instance_uuid: item?.instance_uuid,
            calculated: true,
          },
          values: {
            ...prev?.extra_payment?.values,
            ...pick_keys(extra_payment?.values, [
              'interest_paid',
              'principal_paid',
              'interest_saved',
              'loan_end_date',
              'payoff_time_label',
              'sale_price',
            ]),
          },
          types: {
            ...prev?.extra_payment?.types,
            ...extra_payment?.types,
          },
          defaults: {
            ...prev?.extra_payment?.defaults,
            ...extra_payment?.defaults,
          },
        },
      }))
    } else if (item?.calc_type === 44) {
      setCalc((prev) => ({
        ...prev,
        formatted_address: item?.address,
        mls_id: item?.mls_id || 0,
        title_fees: {
          ...prev?.title_fees,
          data_options: {
            ...prev?.title_fees?.data_options,
            instance_uuid: item?.instance_uuid,
            calculated: true,
          },
          values: {
            ...prev?.title_fees?.values,
            home_price: format_currency(item?.home_price, false, 2),
            down_payment: format_currency(item?.down_payment, false, 3),
            program_type: item?.program_type,
            refi_amount: format_currency(item?.refi_amount, false, 2),
          },
          types: {
            ...prev?.title_fees?.types,
            home_price: item?.home_price_is_type,
            down_payment: item?.down_payment_is_type,
            program_type: item?.program_type_is_type,
            refi_amount: item?.refi_amount_is_type,
          },
        },
      }))
    }
  }

  const instanciate_buy = (listing, from_nav = 'home_view') => {
    saveCalc((prev) => ({
      ...prev,
      home_find_loading: true,
    }))

    let hoa_amount = ''
    const fee_calcs = {
      Annually: 0.0833,
      'Semi-Annually': 0.1667,
      Monthly: 1,
      Quarterly: 0.25,
      '********': 0,
    }
    hoa_amount =
      validate_number(listing?.association_fee || 0) *
        (fee_calcs?.[listing?.association_fee_frequency] || 0) +
      validate_number(listing?.association_fee2 || 0) *
        (fee_calcs?.[listing?.association_fee2_frequency] || 0)

    saveCalc((prev) => {
      const add_values = {
        home_price: format_currency(
          validate_number(listing?.close_price || listing?.list_price)
        ),
        taxes: format_currency(validate_number(listing?.tax_amount)),
        county: listing?.prop_county?.toLowerCase(),
        hoa: hoa_amount ? format_currency(hoa_amount) : hoa_amount,
      }
      const add_types = { taxes: '$' }
      return {
        ...prev,
        mls_id: listing?.mls_id,
        formatted_address: get_address_str(listing),
        buy: {
          ...prev?.buy,
          calculated: add_values?.home_price ? true : false,
          run: (prev?.buy?.run || 0) + 1,
          data_options: {
            ...prev?.buy?.data_options,
            instance_uuid: get_uuid(),
          },
          values: {
            ...prev?.buy?.values,
            ...add_values,
          },
          types: {
            ...prev?.buy?.types,
            ...add_types,
          },
        },
        loan_2: {
          ...prev?.loan_2,
          values: {
            ...prev?.loan_2?.values,
            ...add_values,
          },
          types: { ...prev?.buy?.types, ...add_types },
          calculated: add_values?.home_price ? true : false,
        },
        loan_3: {
          ...prev?.loan_3,
          values: {
            ...prev?.loan_3?.values,
            ...add_values,
          },
          types: { ...prev?.buy?.types, ...add_types },
          calculated: add_values?.home_price ? true : false,
        },
      }
    })

    if (get_storage('uuid')) {
      set_storage(
        'home_view_calc',
        from_nav === 'home_view' ? 'vbc_view' : 'agent_' + from_nav
      )
      set_storage('vbc_calc', 'Y')
    } else {
      set_storage(
        'home_view_calc',
        from_nav === 'home_view' ? 'agent_view' : 'agent_' + from_nav
      )
    }
    saveCalc((prev) => ({
      ...prev,
      home_find_loading: false,
    }))
    navigate('/user/calculators/buy', { replace: true })
  }

  const instanciate_sell = async (listing, from_nav = 'home_view') => {
    saveCalc((prev) => ({
      ...prev,
      home_find_loading: true,
    }))

    const search_string = new URLSearchParams({
      address1: listing?.prop_add,
      address2: `${listing?.prop_city}, ${listing?.prop_st}`,
      lat: validate_number(listing?.lat),
      lng: validate_number(listing?.lng),
      unit_number: listing?.unit_number,
      county: listing?.prop_county?.toLowerCase(),
    })?.toString()
    const data = await axiomFetch(
      `/calculators/taxes_hoa?${search_string}&type=sell`
    )

    const tax_num = validate_number(
      validate_number(data?.tax_amount) || validate_number(listing?.tax_amount)
    )

    if (!data?.list_price) {
      data.list_price =
        validate_number(listing?.close_price) ||
        validate_number(listing?.list_price)
    }
    const loan_start_date = data?.mort_date || new Date().toISOString()
    const loan_end_date = new Date().toISOString()
    const loan_amount = validate_number(data?.mort_amount)
    const loan_term = Math.floor(validate_number(data?.mort_term) / 12)
    saveCalc((prev) => ({
      ...prev,
      loading_search: false,
      sell: {
        ...prev?.sell,
        modal_type: 'address_found',
        show_modal: true,
        values: {
          ...prev?.sell?.values,
          taxes: format_currency(tax_num, false, 3),

          sale_price: format_currency(
            validate_number(data?.list_price),
            false,
            0
          ),

          county: listing?.prop_county?.toLowerCase(),
          // hoa: format_currency(hoa_amount, false, 2) || '',
        },
        types: { ...prev?.sell?.types, taxes: '$' },
        data_options: {
          ...prev?.sell?.data_options,
          instance_uuid: prev?.sell?.data_options?.instance_uuid || get_uuid(),
          calculated: data?.list_price ? true : false,
          is_listing:
            validate_number(data?.list_price) !==
            validate_number(data?.sale_price)
              ? true
              : false,
          original_sale_price: calc?.sell?.values?.sale_price,
          original_taxes: calc?.sell?.values?.taxes,
        },
      },
      loan_balance: {
        ...prev?.loan_balance,
        defaults: {
          ...prev?.loan_balance?.defaults,
          is_home_price: 'N',
        },
        values: {
          ...prev?.loan_balance?.values,
          loan_start_date: loan_start_date,
          loan_end_date: loan_end_date,
          loan_amount: format_currency(loan_amount, false, 2),
          loan_term: loan_term,
          interest_rate: 0,
        },
      },
      formatted_address: get_address_str(listing),
      mls_id: listing?.mls_id,
    }))

    if (get_storage('uuid')) {
      set_storage(
        'home_view_calc',
        from_nav === 'home_view' ? 'vbc_view' : 'agent_' + from_nav
      )
      set_storage('vbc_calc', 'Y')
    } else {
      set_storage(
        'home_view_calc',
        from_nav === 'home_view' ? 'agent_view' : 'agent_' + from_nav
      )
    }
    saveCalc((prev) => ({
      ...prev,
      home_find_loading: false,
    }))
    navigate('/user/calculators/sell', { replace: true })
  }

  const edit_offer = calc?.offers?.data_options?.edit_offer

  const show_offer_page =
    Object.keys(calc?.offers?.items || {}).length &&
    !calc?.offers?.data_options?.new_offer &&
    !edit_offer &&
    !calc?.offers?.data_options?.on_last_offer

  const get_offer_name = (type = 'key', other_type = 'key') => {
    const key = `offer_${
      Object?.keys(calc?.offers?.items || {})?.length +
      (calc?.offers?.data_options?.on_last_offer || show_offer_page ? 0 : 1)
    }`
    const edit_key = calc?.offers?.data_options?.edit_offer
    if (edit_key) {
      if (type === 'key' || type === 'str' || type === 'string') {
        return type === 'key'
          ? edit_key
          : format_currency(
              calc?.offers?.items?.[edit_key]?.values?.offer_price,
              false,
              2
            ) || capitalize(edit_key)
      } else {
        return other_type === 'key'
          ? edit_key
          : format_currency(
              calc?.offers?.items?.[edit_key]?.values?.offer_price,
              false,
              2
            ) || capitalize(edit_key)
      }
    }
    if (type === 'key' || type === 'str' || type === 'string') {
      return type === 'key'
        ? key
        : calc?.offers?.items?.[key]?.values?.offer_price || capitalize(key)
    } else {
      return other_type === 'key'
        ? type
        : calc?.offers?.items?.[type]?.values?.offer_price || capitalize(type)
    }
  }
  const initial_offer =
    get_offer_name() === 'offer_1' &&
    !edit_offer &&
    !calc?.offers?.data_options?.on_last_offer

  const find_county = (name, counties = calc?.ed_counties || []) => {
    const county = counties?.find(
      (item) => item?.name?.toLowerCase() === name?.toLowerCase()
    )
    return county
  }

  const calc_tax_proration = (
    calculator_type = 'buy',
    taxes,
    offer_closing_date,
    acct = profile?.ACCOUNT_ID || 1320
  ) => {
    if (acct === 1320 && calculator_type === 'buy') {
      return 0
    }
    let closing_date =
      offer_closing_date?.split('T')?.[0]?.split('-') ||
      calc?.[calculator_type]?.values?.closing_date?.split('T')?.[0]?.split('-')
    const closing_days = closing_date?.[2]?.replace(/^0+(?=\d)/, '')
    const closing_month =
      validate_number(closing_date?.[1]?.replace(/^0+(?=\d)/, '')) - 1
    const closing_year = closing_date?.[0]
    closing_date = new Date(closing_year, closing_month, closing_days)
    const closing_time = closing_date?.getTime()
    const current_year = closing_date.getFullYear()

    let days = 0
    const mil_per_day = 1000 * 60 * 60 * 24
    if (acct === 1560) {
      const quarters = [0, 3, 6, 9]
      const months_mapper = quarters?.reduce((curr, val) => {
        return {
          ...curr,
          [val]: val,
          [val + 1]: val,
          [val + 2]: val,
        }
      }, {})
      const dates = {}
      quarters.forEach((item, index) => {
        dates[item] = new Date(current_year, item, 1)?.getTime()
      })
      const quarter = months_mapper[closing_month]
      const quarter_time = dates?.[quarter]

      days = (closing_time - quarter_time) / mil_per_day
      if (validate_number(closing_days) === 1 && dates?.[closing_month]) {
        days = Math.abs(
          (dates?.[quarter] - dates?.[Math.abs(quarter - 3)]) / mil_per_day
        )
      }
    } else {
      const first_start_due =
        acct === 1560
          ? new Date(current_year, 0, 1).getTime()
          : new Date(current_year, 2, 1).getTime()
      const second_start_due =
        acct === 1560
          ? new Date(current_year, 3, 1).getTime()
          : new Date(current_year, 9, 1).getTime()
      const first_start =
        acct === 1560
          ? new Date(current_year, 6, 1).getTime()
          : new Date(current_year, 0, 1).getTime()
      const second_start =
        acct === 1560
          ? new Date(current_year, 9, 1).getTime()
          : new Date(current_year, 6, 1).getTime()

      if (closing_time < first_start_due) {
        const second_start_prev_year =
          acct === 1560
            ? new Date(current_year - 1, 0, 1).getTime()
            : new Date(current_year - 1, 6, 1).getTime()
        days = (closing_time - second_start_prev_year) / mil_per_day
      } else if (
        closing_time >= first_start_due &&
        closing_time < second_start_due
      ) {
        days = (closing_time - first_start) / mil_per_day
      } else if (closing_time >= first_start_due) {
        days = (closing_time - second_start) / mil_per_day
      }
    }
    return (
      (taxes / 365.242374) *
      days *
      (acct === 1560 && calculator_type === 'sell' ? -1 : 1)
    )
  }

  const calc_transfer_tax = (
    calculator_type = 'buy',
    acct = profile?.ACCOUNT_ID || 1320,
    home_price,
    county
  ) => {
    if (acct !== 1560) {
      return 0
    }
    let transfer_tax_fee = 0.051
    if (
      (county || calc?.[calculator_type]?.values?.county)?.toLowerCase() ===
      'nye'
    ) {
      transfer_tax_fee = 0.039
    }
    return (
      (home_price || get_home_price(calculator_type)) * transfer_tax_fee * 0.1
    )
  }

  const get_instance_uuid = (
    calculator_type = 'buy',
    implementation_type = 'buy'
  ) => {
    return (
      calc?.[calculator_type]?.data_options?.instance_uuid ||
      calc?.[implementation_type]?.data_options?.instance_uuid ||
      calc?.buy?.data_options?.instance_uuid ||
      calc?.sell?.data_options?.instance_uuid
    )
  }

  const value = useMemo(
    () => ({
      calc,
      loans,
      colors,
      sell_offer_names,
      sell_offer_placeholders,
      saveCalc,
      get_nums,
      get_pmi,
      get_pmi_from_loan,
      get_ltv,
      remove_mip_base,
      get_mip_base,
      get_yearly_mip,
      validate_num,
      get_title_fees,
      get_prepaids,
      get_principal,
      get_loan_term,
      get_down_payment,
      get_interest_rate_num,
      swiper_slide,
      get_origination_fee,
      get_fees_total,
      get_loan_type,
      get_fees_arr,
      get_pmi_and_years,
      get_title_fee_total,
      get_offer_name,
      show_offer_page,
      edit_offer,
      initial_offer,
      get_calc_type_id,
      get_va_funding_fee,
      find_county,
      calc_tax_proration,
      get_mi,
      calc_transfer_tax,
      get_instance_uuid,
      calc_rent,
      get_tax_bracket,
      get_tax_bracket_min_income,
      instanciate_calc,
      instanciate_buy,
      instanciate_sell,
    }),
    [calc, saveCalc]
  )

  return <CalcContext.Provider value={value}>{children}</CalcContext.Provider>
}

export const useCalc = () => {
  const context = useContext(CalcContext)

  if (!context) {
    throw Error('Calc Context must be used inside a Provider')
  }

  return context
}
