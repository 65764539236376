import React, { forwardRef } from 'react'

const Textarea = forwardRef(
  (
    {
      label,
      name,
      value,
      className = 'h-32',
      onChange = () => {},
      ...rest_props
    },
    ref
  ) => {
    return (
      <div>
        <label className='text-sm text-primary'>{label}</label>
        <textarea
          onChange={onChange}
          ref={ref}
          name={name}
          defaultValue={value}
          className={`${className} rounded-md resize-none w-full p-3 border border-gray-200  leading-6 text-gray-500 font-light`}
          {...rest_props}
        ></textarea>
      </div>
    )
  }
)

export default Textarea
