import React from 'react'
import { useListingTours } from '../../../contexts/ListingToursContext'
import uiStyles from '../../ui/uiStyles'

const ListingSignupConfirm = ({ setShowModal }) => {
  const { listingTours, saveListingTours } = useListingTours()

  const get_van_image = (item) => {
    const get_image = (type) => {
      return listingTours?.data?.tour_types?.find(
        (item) => item.type_id === type
      )?.van_photo
    }
    return get_image(item)
  }
  return (
    <div>
      <p className='font-bold text-center text-[15px]'>
        {listingTours?.signup_item?.in_json?.listing?.prop_add}
      </p>
      <p className='px-3 text-center'>is now on the</p>

      <p className='font-bold text-center text-[20px]'>
        {listingTours?.signup_item?.item?.tour_name
          ?.replace('tour', '')
          ?.replace('Tour', '')}{' '}
        Tour
      </p>
      <img src={get_van_image(1)} />
      <p className='text-center my-[5px]'>See you There!</p>
      <div className='flex flex-col'>
        <button
          className={`${uiStyles.white_btn}`}
          onClick={() => {
            setShowModal(false)
          }}
        >
          Close
        </button>
      </div>
    </div>
  )
}

export default ListingSignupConfirm
