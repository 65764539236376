import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

export const TransactionsContext = createContext()

export const TransactionProvider = ({ children, userData }) => {
  const [transactions, setTransactions] = useState(null)

  const saveTransactions = useCallback((data) => {
    setTransactions(data)
  }, [])

  const getTransactionByID = useCallback(
    (id) => {
      if (!transactions || !Array.isArray(transactions)) {
        return {}
      }
      return (
        transactions?.find((item) => {
          return +item.file_id === +id
        }) || {}
      )
    },

    [transactions]
  )

  const getClosedTransactions = useCallback(() => {
    if (!transactions) {
      return null
    }
    return transactions.filter((item) => item.close_disb_dt !== null)
  }, [transactions])

  const getOpenTransactions = useCallback(() => {
    if (!transactions) {
      return null
    }
    return transactions.filter((item, index) => item.close_disb_dt === null)
  }, [transactions])

  const value = useMemo(
    () => ({
      transactions,
      saveTransactions,
      getClosedTransactions,
      getOpenTransactions,
      getTransactionByID,
    }),
    [
      transactions,
      saveTransactions,
      getClosedTransactions,
      getOpenTransactions,
      getTransactionByID,
    ]
  )

  return (
    <TransactionsContext.Provider value={value}>
      {children}
    </TransactionsContext.Provider>
  )
}

export const useTransactions = () => {
  const context = useContext(TransactionsContext)

  if (!context) {
    throw Error(
      'TransactionsContext must be used inside an TransactionsContextProvider'
    )
  }

  return context
}
