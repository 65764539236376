import React from 'react'
import { useCalc } from '../../contexts/CalcContext'
import { format_currency, format_input, validate_number } from '../../helpers'
import LabelInput from '../form/LabelInput'
import uiStyles from '../ui/uiStyles'

const CalcNetCloseChange = ({
  setShowModal,
  show_done = true,
  handle_key_up,
  implementation_type = 'sell',
}) => {
  const { calc, saveCalc } = useCalc()
  const update_input = (options) => {
    const { name, value } = format_input(options)
    let type = options?.type || calc?.sell?.types[`${name}_type`] || ''
    console.log(name, value)
    const net_amount =
      validate_number(options?.original) - validate_number(value)
    const new_sale_price = format_currency(
      validate_number(calc?.sell?.values?.sale_price) -
        validate_number(net_amount),
      false,
      2
    )
    saveCalc((prev) => {
      const result = {
        ...prev,
        sell: {
          ...prev?.sell,
          //   values: {
          //     ...prev?.sell?.values,
          //     sale_price: new_sale_price,
          //   },
          data_options: {
            ...prev?.sell?.data_options,
            net_changed: true,
            original_sale_price: validate_number(
              calc?.sell?.values?.sale_price
            ),
            desired_net: validate_number(value),
            [name]: value,
          },
        },
      }
      return result
    })
  }
  return (
    <div className='px-3'>
      <LabelInput
        left_label={
          implementation_type === 'sell' ? 'Net at Close' : 'Net Goal'
        }
        show_dollar={true}
        name={'net_at_close'}
        left_label_width='w-[77%]'
        value={calc?.sell?.data_options?.net_at_close}
        show_delete_all={true}
        handleChange={(e, type) => {
          update_input({
            e,
            type,
            decimal: 2,
            original: calc?.sell?.data_options?.net_at_close,
          })
        }}
        onKeyUp={handle_key_up}
      />{' '}
      {show_done ? (
        <div className='flex w-full justify-end'>
          <button
            className={`${uiStyles.hover_btn}`}
            onClick={() => {
              setShowModal(false)
              saveCalc((prev) => ({
                ...prev,
                sell: {
                  ...prev?.sell,
                  data_options: {
                    ...prev?.sell?.data_options,
                    calculated: true,
                  },
                },
              }))
            }}
          >
            Done
          </button>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default CalcNetCloseChange
