import React, { memo } from 'react'

function Hamburger({ menuState }) {
  return (
    <div className='menu w-6 h-5'>
      <span
        className={`duration-300
        ${
          menuState ? 'rotate-[45deg] translate-y-[6px]' : ''
        } menu rounded-md block mt-[4px] mb-[4px] h-[3px] bg-gradient-to-r from-accent-100  to-accent-200`}
      ></span>

      <span
        className={`
        duration-[150ms]
        ${
          menuState ? 'opacity-0' : ''
        } menu rounded-md block mt-[4px] mb-[4px] h-[3px] bg-gradient-to-r from-accent-300  to-accent-400`}
      ></span>

      <span
        className={`
        duration-300
        ${
          menuState ? 'rotate-[-45deg] translate-y-[-8px]' : ''
        } menu rounded-md block mt-[4px] mb-[4px] h-[3px] bg-gradient-to-r from-accent-500  to-accent-600`}
      ></span>
    </div>
  )
}

export default memo(Hamburger)
