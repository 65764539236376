import React, { useCallback, memo, useMemo, useState } from 'react'
import { CircleCheckToggle } from '../../form/CircleCheckToggle'
import home_images from '../../../assets/images/home'
import uiStyles from '../../ui/uiStyles'
import './home.css'
import {
  GoogleMap,
  Autocomplete,
  useJsApiLoader,
  CircleF,
  MarkerF,
  InfoBoxF,
  InfoWindowF,
} from '@react-google-maps/api'
import { useNavigate } from 'react-router-dom'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { HomeFilter } from './HomeFilter'
import images_homes from '../../../assets/images/home'
import Modal from '../../Modal'
import { libraries } from './utils'
import { get_storage } from '../../../helpers'
export const HomeSearch = memo(
  ({ state, setState, title, state_abbr, search_homes, messageSent }) => {
    const [showModal, setShowModal] = useState(false)
    const navigate = useNavigate()
    const search_options = [
      { key: 'postal_code', name: 'zip code', show: 7, priority: 4 },
      {
        key: 'administrative_area_level_1',
        name: 'state',
        show: 4,
        priority: 2,
      },
      {
        key: 'administrative_area_level_2',
        name: 'county',
        show: 3,
        priority: 1,
      },
      { key: 'locality', name: 'city', show: 2, priority: 3 },
      { key: 'neighborhood', name: 'Subdivision', show: 5, priority: 5 },
    ]
    const search_option_keys = [
      'postal_code',
      'administrative_area_level_1',
      'administrative_area_level_2',
      'locality',
      'neighborhood',
    ]

    const center = useMemo(() => {
      return {
        lat:
          state?.place?.geometry?.location?.lat() ||
          (state_abbr === 'NV' ? 36.171563 : 33.4483771),
        lng:
          state?.place?.geometry?.location?.lng() ||
          (state_abbr === 'NV' ? -115.1391009 : -112.0740373),
      }
    }, [
      state?.place?.geometry?.location?.lat(),
      state?.place?.geometry?.location?.lng(),
      state?.open_house_search_loc?.lat,
      state?.open_house_search_loc?.lng,
    ])
    const center_circle = useMemo(
      () => ({
        lat: state?.place?.circle?.location?.lat(),
        lng: state?.place?.circle?.location?.lng(),
      }),
      [
        state?.place?.circle?.location?.lat(),
        state?.place?.circle?.location?.lng(),
      ]
    )
    const { isLoaded } = useJsApiLoader({
      id: 'google-map-script',
      googleMapsApiKey: 'AIzaSyAdJBLTyoh5xMQujUK488i-am9syWuiG6A',
      libraries: libraries,
    })

    const on_load = useCallback(function callback(map) {
      setState((prev) => ({ ...prev, map }))
    }, [])

    const on_unmount = useCallback(function callback(map) {
      setState((prev) => ({ ...prev, map: null }))
    }, [])

    const circle_change = useCallback(function callback(e) {
      let radius
      if (!this?.mapTypeId) {
        radius = this?.getRadius()
      }
      setState((prev) => ({
        ...prev,
        place: {
          ...prev?.place,

          circle: {
            ...prev?.place?.circle,
            location: e?.latLng || this?.getCenter(),
            radius: radius ? radius : 3218.69,
          },
        },
      }))
    })

    const save_search_address = (components) => {
      let address_components = components?.filter((item) =>
        ['subpremise', 'street_number', 'route'].includes(item?.types?.[0])
      )
      address_components.push(address_components.shift())
      return address_components?.map((item) => item?.long_name || '')?.join(' ')
    }

    const show_address_components = () => {
      let components = state?.address_components
        ?.filter((item) => search_option_keys?.includes(item?.types?.[0]))
        ?.map((item) => item?.types?.[0])
      return search_options
        ?.filter((item) => components?.includes(item?.key))
        ?.sort((a, b) => a?.show - b?.show)
        ?.map((item) => {
          return (
            <div key={item?.name} className='flex flex-col mt-[10px]'>
              <p className='font-semibold'>{item?.name}:</p>
              <p className=''>
                {
                  state?.address_components.filter(
                    (add_item) => add_item?.types?.[0] === item?.key
                  )?.[0]?.long_name
                }
              </p>
            </div>
          )
        })
    }

    return (
      <div className='px-3'>
        <div className='flex flex-col items-center'>
          <div className='flex text-center'>
            <p
              className={`${uiStyles.text_black_title} capitalize text-center mt-[5px]`}
            >
              {`${title || 'quick home search'}`}
            </p>

            <div
              className='ml-[20px] cursor-pointer'
              onClick={() => {
                navigate('/homes/favorites')
              }}
            >
              <img
                src={
                  images_homes[
                    get_storage('favorited_homes')?.length ||
                    state?.homes?.favorited_homes?.length
                      ? 'heart_filled'
                      : 'heart'
                  ]
                }
                className={'w-[40px]'}
              />
            </div>
            <div
              className='mt-[5px] ml-[10px] cursor-pointer'
              onClick={() => {
                setShowModal((prev) => !prev)
              }}
            >
              <img src={images_homes.filter} className={'w-[28px]'} />
            </div>
          </div>
          <div className='mr-[85px] mt-[5px]'>
            <CircleCheckToggle
              name={`show_map`}
              type='toggle'
              toggle_left={false}
              toggle_color_grad={'from-[#0A66C2] to-[#0A66C2]'}
              toggle_color={'text-[#0A66C2]'}
              toggle_left_label={''}
              toggle_right_label={'Map Search'}
              inputValue={state}
              handle_change={async (e) => {
                setState((prev) => {
                  window.localStorage.setItem(
                    'show_map',
                    prev?.show_map === 'Y' ? 'N' : 'Y'
                  )
                  return {
                    ...prev,
                    show_map: prev?.show_map === 'Y' ? 'N' : 'Y',
                  }
                })
              }}
            />
          </div>

          {state?.show_map === 'Y' ? (
            <div className='flex items-center mt-[14px]'>
              <p className={`${uiStyles.text_black}`}>
                Use your finger or mouse to draw a circle around the area you
                would like to search
              </p>
              <img src={home_images.touch} />
            </div>
          ) : (
            ''
          )}
        </div>

        <div>
          {state?.show_map === 'N' ? <div className='mt-[10px]'></div> : ''}

          {isLoaded ? (
            <div className='pb-3'>
              <div className='mb-[4px]'>
                <Autocomplete
                  onLoad={(autocomplete) => {
                    autocomplete.setFields([
                      'formatted_address',
                      'address_components',
                      'geometry',
                      'name',
                    ])
                    setState((prev) => ({ ...prev, autocomplete }))
                  }}
                  onPlaceChanged={() => {
                    let place = {}
                    if (state?.autocomplete) {
                      place = state?.autocomplete?.getPlace()
                    }
                    if (place?.address_components) {
                      setState((prev) => ({
                        ...prev,
                        place: {
                          ...prev?.place,
                          circle: {
                            ...prev?.circle,
                            location: place?.geometry?.location,
                          },
                          geometry: place?.geometry,
                        },
                        search_address: save_search_address(
                          place?.address_components
                        ),
                        formatted_address: place?.formatted_address,
                        address_components: place?.address_components,
                        location: place?.geometry?.location,
                      }))
                    }
                  }}
                >
                  <input
                    type='text'
                    className={`${uiStyles.input}`}
                    placeholder='Enter your address'
                    value={state?.formatted_address || ''}
                    onChange={(e) => {
                      setState((prev) => ({
                        ...prev,
                        formatted_address: e.target.value,
                      }))
                    }}
                  />
                </Autocomplete>
              </div>
              {state?.show_map === 'N' ? (
                <div>
                  <div className='overflow-scroll min-h-[300px]'>
                    {/* <div>{`lat: ${state?.location?.lat()} lng: ${state?.location?.lng()}`}</div> */}
                    {state?.search_address ? (
                      <div>
                        <p className='font-semibold mt-[10px]'>
                          Street Address:
                        </p>
                        <p>{state?.search_address}</p>
                      </div>
                    ) : (
                      ''
                    )}
                    {show_address_components()}
                  </div>
                  <div className='flex gap-[10px] justify-end mt-[20px]'>
                    <>
                      {messageSent?.type === 'search' ? (
                        <div
                          className={`${
                            uiStyles[messageSent.status]
                          } mb-[20px]`}
                        >
                          {messageSent.message}
                        </div>
                      ) : (
                        <>
                          <button
                            className={`${uiStyles.hover_btn}`}
                            onClick={() => {
                              localStorage.setItem('show_map', 'Y')
                              setState((prev) => ({ ...prev, show_map: 'Y' }))
                            }}
                          >
                            Search Radius
                          </button>
                          <button
                            className={`${uiStyles.hover_btn}`}
                            onClick={search_homes}
                          >
                            Search
                          </button>
                        </>
                      )}
                    </>
                  </div>
                </div>
              ) : (
                ''
              )}

              {state?.show_map === 'Y' ? (
                <div>
                  <GoogleMap
                    mapContainerStyle={{
                      width: '100%',
                      height: '400px',
                    }}
                    center={center}
                    zoom={state?.place?.zoom || 11}
                    options={{
                      disableDefaultUI: true,
                      zoomControl: true,
                    }}
                    onLoad={on_load}
                    onClick={circle_change}
                    onUnmount={on_unmount}
                  >
                    {state?.place ? (
                      <MarkerF
                        onLoad={(e) => {
                          setState((prev) => ({
                            ...prev,
                            place: { ...prev.place, marker: e },
                          }))
                        }}
                        position={center_circle}
                        icon={home_images.radius_small}
                        // label={{
                        //   text: `${state?.place?.circle?.radius} mile radius`,
                        //   color: 'black',
                        //   fontSize: '13px',
                        //   fontWeight: 'bold',
                        //   className: 'marker-position',
                        // }}
                      ></MarkerF>
                    ) : (
                      <></>
                    )}
                    {state?.place ? (
                      <>
                        <CircleF
                          radius={state?.place?.circle?.radius || 3218.69}
                          editable={true}
                          draggable={false}
                          options={{
                            fillColor: null,
                            fillOpacity: 0,
                            strokeColor: '#05A660',
                            strokeWeight: 3,
                          }}
                          center={center_circle}
                          onCenterChanged={circle_change}
                          onRadiusChanged={circle_change}
                        />
                      </>
                    ) : (
                      ''
                    )}
                  </GoogleMap>
                  <div className='flex justify-end mt-[10px]'>
                    <>
                      {messageSent?.type === 'search' ? (
                        <div
                          className={`${
                            uiStyles[messageSent.status]
                          } mb-[20px]`}
                        >
                          {messageSent.message}
                        </div>
                      ) : (
                        <button
                          className={`${uiStyles.hover_btn}`}
                          onClick={search_homes}
                        >
                          Search
                        </button>
                      )}
                    </>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
        </div>
        {showModal ? (
          <Modal
            modalName={'home_filter'}
            setShowModal={setShowModal}
            showModal={showModal}
            showClose={true}
          >
            <HomeFilter
              state={state}
              setState={setState}
              setShowModal={setShowModal}
            />
          </Modal>
        ) : (
          ''
        )}
      </div>
    )
  }
)
