import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import {
  FaLink,
  FaFacebook,
  // FaTwitter,
  // FaLinkedin,
  // FaInstagram,
  // FaStar,
} from 'react-icons/fa'
import SubNav from '../SubNav'
import TitleIcon from '../ui/TitleIcon'
import ListItem from '../ui/ListItem'
import SwipeConfig from '../ui/SwipeConfig'
import { ServiceClientReviews } from '../../services/ServiceClientReviews'
import Loading from '../Loading'
import Pagination from '../ui/Pagination'
import { useQuery } from '@tanstack/react-query'
import { useProfile } from '../../contexts/UserContext'
import Thumbnail from '../../assets/images/item-2.jpg'

import Wrapper from '../layout/Wrapper'
const rightConfig =
  (postPath = '', caption = '') =>
  () =>
    (
      <>
        <a
          href={`${postPath}`}
          target='_blank'
          rel='noreferrer'
          className='w-8 h-8 leading-8 flex items-center justify-center  bg-white rounded-md mx-1 text-gray-300   hover:text-primary'
        >
          <FaLink className='text-xl' />
        </a>
        <a
          href={`https://www.facebook.com/dialog/feed?app_id=2447727492044346&display=popup&link=${postPath}&redirect_uri=${process.env.REACT_APP_API_ROOT}/v1/social/facebook/facebook_redirect&og:image=`}
          target='_blank'
          rel='noreferrer'
          className='w-8 h-8 leading-8 flex items-center justify-center  bg-white rounded-md mx-1 text-gray-300 hover:text-[#1877f2]'
        >
          <FaFacebook className=' text-xl' />
        </a>
        {/* <a
        href={`https://twitter.com/intent/tweet?url=${postPath}&text=${caption}`}
        target="_blank"
        rel="noreferrer"
        className="w-8 h-8 leading-8 flex items-center justify-center  bg-white rounded-md mx-1 text-gray-300 hover:text-[#1DA1F2]"
      >
        <FaInstagram className="text-xl" />
      </a> */}
        {/* <a
        href={`http://www.linkedin.com/shareArticle?mini=true&url=${postPath}`}
        target="_blank"
        rel="noreferrer"
        className="w-8 h-8 leading-8 flex items-center justify-center  bg-white rounded-md mx-1 text-gray-300 hover:text-[#0A66C2]"
      >
        <FaLinkedin className="text-xl" />
      </a> */}
      </>
    )
export default function Reviews() {
  const [selected, setSelected] = useState(1)
  const { getAccessTokenSilently } = useAuth0()
  const { profile } = useProfile()

  const fetchController = useRef(null)
  const params = useRef({
    skip: 0,
    limit: 5,
  })

  const getReviews = useCallback(async () => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUDIENCE,
    }).catch((err) => null)

    const serviceClient = new ServiceClientReviews(token)
    if (!fetchController.current) {
      fetchController.current = serviceClient.getController().controller
    }
    return serviceClient.getAll(params.current)
  }, [params, getAccessTokenSilently])

  const { isLoading, isError, data, refetch, isFetching } = useQuery({
    queryKey: ['reviews', params.current],
    queryFn: getReviews,
    refetchOnWindowFocus: false,
    enabled: !!profile,
  })

  const showNext = () => {
    if (!isLoading && !isFetching) {
      params.current = {
        ...params.current,
        skip: params.current.skip + params.current.limit,
      }
      refetch()
    }
  }
  const showPrev = () => {
    if (!isLoading && !isFetching) {
      params.current = {
        ...params.current,
        skip: params.current.skip - params.current.limit,
      }
      refetch()
    }
  }

  const ratingHTML = (propertyItem) => {
    const { ratings } = propertyItem
    if (!ratings.length) {
      return null
    }

    let names = profile && profile?.CONT_NAME.split(/\s+/)
    names[0] = names[0].substr(0, 1)
    const clientName = names.join(' ')

    const answers = ratings?.map((item, index) => (
      <div key={index}>
        {index === 0 && <p className='swipeable font-semibold'>{clientName}</p>}
        <div className='swipeable flex mb-1' key={index}>
          <div className='swipeable flex-1 leading-3'>
            {(item?.QUEST).replace('~contName~', '').replace(':', '')}
          </div>
        </div>
      </div>
    ))

    return answers
  }
  console.log(data)
  const htmlListing =
    data?.msg === 'no_reviews' ? (
      <div className='p-5'>When you get reviews they will show up here.</div>
    ) : (
      data?.map((item, index) => (
        <SwipeConfig
          key={index}
          index={index}
          selectedIndex={selected}
          setSelectedIndex={setSelected}
          RightComponents={rightConfig(
            item?.POST_PATH,
            `Agent Review of  ${profile && profile?.CONT_NAME} - by ${
              item?.property
            }`
          )}
          widthConfigRight={44}
        >
          <ListItem
            key={index}
            image={Thumbnail}
            title={`Agent Review of  ${profile && profile?.CONT_NAME} - by ${
              item?.property
            }`}
            content={ratingHTML(item)}
            link={false}
          />
        </SwipeConfig>
      ))
    )

  useEffect(() => {
    return () => fetchController.current && fetchController.current.abort()
  }, [])

  if (!isError && (isLoading || isFetching)) {
    return <Loading />
  }

  return (
    <Wrapper>
      <SubNav />
      <TitleIcon className='mb-5'>My Reviews</TitleIcon>
      {/* <div className="pt-2 pb-4 px-4">
        <PageSubMenu selected={INNERMENU.Reviews} />
      </div> */}

      {htmlListing}

      <div className='flex justify-center px-5 py-3'>
        <Pagination
          showPrev={data && params.current.skip > 0}
          showNext={data && data?.length >= params.current.limit}
          clickNextHandler={showNext}
          clickPrevHandler={showPrev}
          pageNum={
            (params.current.skip + params.current.limit) / params.current.limit
          }
        />
      </div>
    </Wrapper>
  )
}
