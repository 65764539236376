import { useState } from 'react'
import { AiFillDelete } from 'react-icons/ai'
import { HiPhone, HiEnvelope } from 'react-icons/hi2'
import { TbWorld } from 'react-icons/tb'
import { TfiPencilAlt } from 'react-icons/tfi'
import { useNavigate } from 'react-router-dom'
import { useVBCs } from '../../../contexts/VBCContext'
import { validate_number } from '../../../helpers'
import Modal from '../../Modal'
import ListItem from '../../ui/ListItem'
import SwipeConfig from '../../ui/SwipeConfig'
import uiStyles from '../../ui/uiStyles'
import AddMarket from './AddMarket'
import DeleteMarket from './DeleteMarket'
const AdminMarkets = () => {
  const { VBCs, saveVBCs } = useVBCs()
  const [page, setPage] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const [selected, setSelected] = useState(1)
  const navigate = useNavigate()

  const modals = {
    add: (
      <AddMarket
        market_name={'markets'}
        state={VBCs}
        setState={saveVBCs}
        setShowModal={setShowModal}
        page={page}
        setPage={setPage}
      />
    ),
    edit: (
      <AddMarket
        market_name={'markets'}
        state={VBCs}
        setState={saveVBCs}
        setShowModal={setShowModal}
        page={page}
        setPage={setPage}
      />
    ),
    remove: (
      <DeleteMarket
        market_name={'markets'}
        state={VBCs}
        setState={saveVBCs}
        setShowModal={setShowModal}
        page={page}
        setPage={setPage}
      />
    ),
  }
  const settingsLeft = (item) => {
    return () => (
      <div className='flex gap-[10px]'>
        <div>
          {item?.cell ? (
            <a href={`tel:${item?.cell}`} className={'underline'}>
              <HiPhone size={25} />
            </a>
          ) : (
            ''
          )}
        </div>
        <div>
          {item?.email ? (
            <a
              href={`mailto:?to=${item?.email}&subject=&body=`}
              target='_blank'
              rel='noreferrer'
              className='underline cursor-pointer'
            >
              <HiEnvelope size={25} />
            </a>
          ) : (
            ''
          )}
        </div>
        <div>
          {item?.website ? (
            <a
              href={item?.website}
              target='_blank'
              rel='noreferrer'
              className='underline cursor-pointer'
            >
              <TbWorld size={25} />
            </a>
          ) : (
            ''
          )}
        </div>
      </div>
    )
  }

  const settingsRight = (item) => {
    return () => (
      <div className='flex gap-[20px]'>
        <div
          className='cursor-pointer'
          onClick={() => {
            saveVBCs((prev) => {
              const return_data = {
                ...prev,
                market_edit: item,
              }
              return return_data
            })
            setModalType('remove')
            setShowModal(true)
          }}
        >
          <AiFillDelete size={25} />
        </div>
        <div
          className='cursor-pointer'
          onClick={() => {
            saveVBCs((prev) => {
              const return_data = {
                ...prev,
                market_edit: item,
              }
              return return_data
            })
            setModalType('edit')
            setShowModal(true)
          }}
        >
          <TfiPencilAlt size={25} />
        </div>
      </div>
    )
  }
  return (
    <div>
      <div className='px-3'>
        <h1 className={`${uiStyles.text_black_title} mb-[10px]`}>
          Edit Markets
        </h1>
      </div>
      <div className='gap-[2px] mt-[10px]'>
        {VBCs?.markets?.length ? (
          VBCs?.markets
            ?.sort(
              (a, b) => validate_number(a.loc_id) - validate_number(b?.loc_id)
            )
            ?.map((item, index) => {
              return (
                <div key={item?.default_uuid}>
                  <SwipeConfig
                    key={index}
                    index={index}
                    selectedIndex={selected}
                    setSelectedIndex={setSelected}
                    RightComponents={settingsRight(item)}
                    // LeftComponents={settingsLeft(item)}
                    className='mb-[2px]'
                  >
                    <ListItem
                      title={`${item?.location_name} ${
                        item?.city && item?.state
                          ? `(${item?.city || ''}, ${item?.state || ''})`
                          : ''
                      }`}
                      subtitle={`Types: ${
                        item?.type?.split(',')?.join(', ') || ''
                      }`}
                      content={item?.zip?.split(',').join(', ')}
                      image={item?.photo || ''}
                    />
                  </SwipeConfig>
                </div>
              )
            })
        ) : (
          <div className='px-3'>Your Markets will go here.</div>
        )}
      </div>
      <div className='mt-[10px] flex justify-end px-3'>
        <button
          className={`${uiStyles.hover_btn}`}
          onClick={() => {
            saveVBCs((prev) => {
              const return_data = {
                ...prev,
                market_edit: {},
              }
              return return_data
            })
            setModalType('add')
            setShowModal(true)
          }}
        >
          Add Market
        </button>
      </div>

      {showModal ? (
        <Modal
          modalName={modalType}
          height={modalType === 'remove' ? 'h-[200px]' : ''}
          showModal={showModal}
          setShowModal={setShowModal}
          showClose={true}
        >
          {modals[modalType]}
        </Modal>
      ) : (
        ''
      )}
    </div>
  )
}

export default AdminMarkets
