import React from 'react';
import ListThumbnail from '../assets/images/listitem.jpg';
import ListItem from './ui/ListItem';
export default function Listing({ items }) {
  const listingDummy = [
    'Your New Review',
    'New Listing',
    'Old Listing',
    'Marketing of Approval',
  ];
  const listingHTML = listingDummy?.map((item, index) => (
    <ListItem
      key={index}
      image={ListThumbnail}
      title={item}
      content="Lorem ipsum dolor sit amet consectetur. Tincidunt leo tincidunt donec arcu tellus. Tincidunt donec arcu tellus."
    />
  ));
  return listingHTML;
}
