const ROOT_URL = process.env.REACT_APP_API_ROOT + '/v1';

export class ServiceHttp {
  headers = {};

  constructor(url_prefix = '', token) {
    this.url_prefix = url_prefix;
    this.getHeaders(token);
  }

  async get(url, queryParams) {
    try {
      const apiurl =
        ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams);

      const signal = this.getController().signal;

      const response = await fetch(apiurl, {
        headers: this.headers,
        signal: signal,
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const jsonResponse = await response.json();
      return jsonResponse;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async post(url, body, queryParams = null) {
    const headers = {
      ...this.headers,
      'Content-type': 'application/json; charset=UTF-8',
    };
    try {
      let response = await fetch(
        ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams),
        {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(body),
        }
      );

      let jsonResponse = await response.json();
      return jsonResponse;
    } catch (error) {
      return null;
    }
  }

  async put(url, body, queryParams = null) {
    try {
      let response = await fetch(
        ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams),
        {
          method: 'PUT',
          headers: this.headers,
          body: JSON.stringify(body),
        }
      );
      console.log(response);
      let jsonResponse = await response.json();
      return jsonResponse;
    } catch (error) {
      return null;
    }
  }

  async patch(url, body, queryParams = null) {
    try {
      console.log(this.headers)
      const headers = {
        ...this.headers,
        'Content-type': 'application/json; charset=UTF-8',
      };
      let response = await fetch(
        ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams),
        {
          method: 'PATCH',
          headers: headers,
          body: JSON.stringify(body),
        }
      );
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const jsonResponse = response.statusText;
      return jsonResponse;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async remove(url, queryParams = null) {
    try {
      let response = await fetch(
        ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams),
        {
          method: 'DELETE',
          headers: this.headers,
        }
      );
      let jsonResponse = await response.json();
      return jsonResponse;
    } catch (error) {
      return null;
    }
  }

  getUrl(url) {
    return this.url_prefix + url;
  }

  getHeaders(token) {
    this.headers = {};
    if (token) {
      this.headers = {
        ...this.headers,
        Authorization: `Bearer ${token}`,
      };
    }
  }

  mapQueryParams(queryParams) {
    return queryParams
      ? Object.keys(queryParams)
        .map(function (key, index) {
          return index === 0
            ? '?' + key + '=' + queryParams[key]
            : key + '=' + queryParams[key];
        })
        .join('&')
      : '';
  }

  getController() {
    const controller = new AbortController();
    const signal = controller.signal;

    return { controller, signal };
  }
}
