import AccordionItem from './AccordionItem';

export const Accordion = ({ items }) => {
  return (
    <ul className="accordion">
      {items ?
        items?.map((item, index) => (
          <AccordionItem key={index} count={index} item={item} />
        )) : ''}
    </ul>
  );
};
