import { useEffect, useState } from 'react'
import axiomLogo from '../../../assets/images/axiom_driggs_logo.svg'
import sendgrid_icon from '../../../assets/images/sendgrid_icon.svg'
import plusIcon from '../../../assets/images/plus_icon.svg'
import uiStyles from '../../ui/uiStyles'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { useNavigate } from 'react-router-dom'
import { useProfile } from '../../../contexts/UserContext'
import { useAuth0 } from '@auth0/auth0-react'
import { AiOutlineCopy } from 'react-icons/ai'
import SmallLoading from '../../ui/SmallLoading'
import { useConnections } from '../../../contexts/ConnectionContext'
import { open_url_tab } from '../../../helpers'

const EmailVerify = ({ setModalType, cancel_to_modal, setShowModal }) => {
  const [sendgrid, setSendgrid] = useState({})
  const { connections, saveConnections } = useConnections()
  const {
    user: { sub },
  } = useAuth0()

  useEffect(() => {
    const controller = new AbortController()
    const find_account = async () => {
      const data = await axiomFetch(
        '/services/email/subuser/find',
        'GET',
        {},
        {},
        false,
        controller.signal
      )
      setSendgrid((prev) => ({ ...prev, account: data }))
    }
    find_account()
    return () => {
      controller.abort()
    }
  }, [])
  useEffect(() => {
    const controller = new AbortController()
    const check_verify = async () => {
      setSendgrid((prev) => ({ ...prev, isLoading: true }))
      const data = await axiomFetch(
        '/services/email/subuser/verify/senders',
        'GET',
        {},
        {},
        false,
        controller.signal
      )
      if (data?.length) {
        for (const email of data) {
          if (email?.verified && email?.from_email === profile?.CONT_EMAIL) {
            saveConnections((prev) => ({ ...prev, sendgrid: true }))
            if (cancel_to_modal !== 'close_modal') {
              setModalType(cancel_to_modal)
            } else {
              setShowModal(false)
            }
            break
          } else {
            saveConnections((prev) => ({ ...prev, sendgrid: false }))
            setSendgrid((prev) => ({ ...prev, show: 'not_verified' }))
          }
        }
      } else {
        saveConnections((prev) => ({ ...prev, sendgrid: false }))
        setSendgrid((prev) => ({ ...prev, show: 'no_sender' }))
      }
      if (!controller?.signal?.aborted) {
        setSendgrid((prev) => ({ ...prev, isLoading: false }))
      }
    }
    check_verify()
    return () => {
      controller?.abort()
    }
  }, [sendgrid?.reverify])

  useEffect(() => {
    const show_message_sent = () => {
      setTimeout(() => {
        setSendgrid((prev) => ({
          ...prev,
          username_copied: false,
          password_copied: false,
        }))
      }, 1000)
    }
    show_message_sent()
    // eslint-disable-next-line
  }, [sendgrid?.username_copied, sendgrid?.password_copied])

  const { profile } = useProfile()
  const { axiomFetch } = useAxiomFetch()

  const create_account = async (e) => {
    setSendgrid((prev) => ({ ...prev, isLoading: true }))
    const body = {
      email: profile?.CONT_EMAIL,
      limit: 1000,
    }
    const account = await axiomFetch('/services/email/subuser', 'POST', body)
    await axiomFetch('/services/email/subuser/verify/sender', 'POST', {
      email: profile?.CONT_EMAIL,
    })
    setSendgrid((prev) => ({ ...prev, account, show: 'not_verified' }))
    setSendgrid((prev) => ({ ...prev, isLoading: false }))
  }

  const verify_email = async (e) => {
    setSendgrid((prev) => ({
      ...prev,
      isLoading: true,
      send_verify_email: (prev?.send_verify_email || 0) + 1,
    }))
    const senders = await axiomFetch(
      '/services/email/subuser/verify/senders',
      'GET'
    )
    for (const sender of senders) {
      if (sender?.from_email === profile?.CONT_EMAIL) {
        await axiomFetch(
          `/services/email/subuser/verify/sender/resend/${sender?.id}`,
          'POST'
        )
      }
    }
    setSendgrid((prev) => ({ ...prev, isLoading: false }))
  }

  const confirm_account = async (e) => {
    setSendgrid((prev) => ({ ...prev, reverify: (prev?.verify || 0) + 1 }))
  }

  const verifyAccount = (
    <>
      <div className='flex flex-col gap-2'>
        <h1 className={`${uiStyles.text_black_subtitle} text-center`}>
          Account Created successfully
        </h1>
        <p
          className={`${uiStyles.text_gray} text-[#0C0C0C] text-center mb-[10px]`}
        >
          Lets login to your new Sendgrid account and setup dual authentication.
        </p>
        <div>
          <div className='flex justify-between'>
            <label>username</label>
            {sendgrid?.username_copied ? (
              <span className='text-center'>Copied!</span>
            ) : (
              ''
            )}
          </div>
          <div
            className='flex justify-between border-2 rounded-md p-2 items-center'
            onClick={() => {
              navigator.clipboard.writeText(sendgrid?.account?.username)
              setSendgrid((prev) => ({ ...prev, username_copied: true }))
            }}
          >
            <p className='overflow-x-scroll w-[232px]'>{sub}</p>
            <AiOutlineCopy size={20} className=' mr-[10px] cursor-pointer' />
          </div>
        </div>
        {sendgrid?.account?.password ? (
          <div>
            <div className='flex justify-between'>
              <label>password</label>
              {sendgrid?.password_copied ? (
                <span className='text-center'>Copied!</span>
              ) : (
                ''
              )}
            </div>
            <div
              className='flex justify-between border-2 rounded-md p-2 items-center'
              onClick={() => {
                navigator.clipboard.writeText(sendgrid?.account?.password)
                setSendgrid((prev) => ({ ...prev, password_copied: true }))
              }}
            >
              <>
                <p className='overflow-x-scroll w-[232px]'>
                  {sendgrid?.account?.password}
                </p>
                <AiOutlineCopy
                  size={20}
                  className=' mr-[10px] cursor-pointer'
                />
              </>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className='flex justify-end mt-[20px]'>
        <button
          className={`${uiStyles.green_btn}`}
          onClick={async () => {
            const new_window = open_url_tab(
              'https://app.sendgrid.com/login',
              '_target'
            )
          }}
        >
          Login
        </button>
      </div>
    </>
  )

  const createAccount = (
    <>
      <div className='flex place-content-center gap-5'>
        <img className='w-[55px]' src={axiomLogo} alt='axiom logo' />
        <img className='w-[33px]' src={plusIcon} alt='plus sign' />
        <img className='w-[55px]' src={sendgrid_icon} alt='sengrid logo' />
      </div>
      <div className='mt-[20px] flex flex-col gap-5'>
        <h1 className={`${uiStyles.text_black_subtitle} text-center`}>
          Create a sendgrid account
        </h1>
        <p className={`${uiStyles.text_gray} text-[#0C0C0C] text-center`}>
          We will generate you a username and password for you to sign into
          sendgrid. Save these somewhere safe.
        </p>
        <div className='flex gap-2 items-center mb-[10px]'>
          <p className={`${uiStyles.text_black}`}>
            Current email: {profile?.CONT_EMAIL}
          </p>
        </div>
      </div>
      <div className='flex justify-end mt-[10px]'>
        <button className={`${uiStyles.green_btn}`} onClick={create_account}>
          Create
        </button>
      </div>
    </>
  )

  const confirmAccount = (
    <>
      {' '}
      <div className='mt-[20px] flex flex-col gap-2'>
        <h1 className={`${uiStyles.text_black_subtitle} text-center`}>
          Verify sendgrid account
        </h1>
        <p className={`${uiStyles.text_gray} text-[#0C0C0C] text-center`}>
          After you have logged in and set up dual authentication. Verify your
          email.
        </p>
      </div>
      <div className='flex justify-end mt-[20px]'>
        <button className={`${uiStyles.green_btn}`} onClick={verify_email}>
          Re-send Verification Email
        </button>
      </div>
      <div className='mt-[20px]'>
        <h1 className={`${uiStyles.text_black_subtitle} text-center`}>
          Confirm integration with Axiom
        </h1>
        <div className='flex justify-end mt-[10px] gap-[10px]'>
          {cancel_to_modal ? (
            <button
              className={`${uiStyles.white_btn}`}
              onClick={() => {
                if (cancel_to_modal !== 'close_modal') {
                  setModalType(cancel_to_modal)
                } else {
                  setShowModal(false)
                }
              }}
            >
              Cancel
            </button>
          ) : (
            ''
          )}
          <button className={`${uiStyles.green_btn}`} onClick={confirm_account}>
            Confirm
          </button>
        </div>
      </div>
    </>
  )
  return (
    <>
      {sendgrid?.isLoading ? (
        <SmallLoading styles={'mt-[200px]'} />
      ) : sendgrid?.show === 'confirm' ? (
        confirmAccount
      ) : sendgrid?.show === 'no_sender' ? (
        createAccount
      ) : (
        <>
          {verifyAccount} {confirmAccount}
        </>
      )}
    </>
  )
}

export default EmailVerify
