import React from 'react'
import { useDistribute } from '../../../contexts/DistributeContext'
import { formatPhoneNumber } from '../../../helpers'
import uiStyles from '../../ui/uiStyles'

export const AiList = ({ single_type = 'phone' }) => {
  const { distribute, saveDistribute } = useDistribute()
  const type =
    (distribute?.type === 'social' ? distribute?.social : distribute?.type) ||
    'instagram'
  const content = distribute?.content?.[type]

  const change_list_type = (list_type) => {
    saveDistribute((prev) => ({
      ...prev,
      content: {
        ...prev?.content,
        [type]: {
          ...prev?.content?.[type],
          list_type,
        },
      },
    }))
  }
  return (
    <div className='mb-3 mt-3'>
      <div className='flex justify-between mr-[30px] mb-[10px]'>
        <div className='flex'>
          <div className='flex flex-col items-start'>
            <button
              onClick={() => {
                change_list_type('list')
              }}
            >
              List
            </button>
            <div
              className={`w-[50px] h-[3px] transition-all duration-400 bg-green-300  ${
                (content?.list_type || 'list') === 'list'
                  ? 'opacity-100'
                  : 'opacity-50 bg-gray-300'
              }`}
            ></div>
          </div>
          <div className='flex flex-col items-start'>
            <button
              onClick={() => {
                change_list_type('single')
              }}
            >
              Single
            </button>
            <div
              className={`w-[50px] h-[3px] transition-all duration-400 bg-green-300  ${
                content?.list_type === 'single'
                  ? 'opacity-100'
                  : 'opacity-50 bg-gray-300'
              }`}
            ></div>
          </div>
        </div>
      </div>
      {(content?.list_type || 'list') === 'list' ? (
        <>
          <label
            className={`${uiStyles?.label} transition-all duration-300 ${''}`}
          >
            Send To List
          </label>
          <select
            value={distribute?.list}
            onChange={(e) => {
              //console.log(e.target.options[e.target.selectedIndex].id)
              saveDistribute((prev) => ({
                ...prev,
                content: {
                  ...prev?.content,
                  [type]: {
                    ...prev?.content?.[type],
                    list_id: e.target.options[e.target.selectedIndex].id,
                    list_name: e.target.value,
                  },
                },
              }))
            }}
            className={`${uiStyles.select}`}
          >
            <option id={0}>none</option>
            {distribute?.lists.map((option, index) => {
              return (
                <option
                  key={`${option?.LIST_ID}-${index}`}
                  id={option?.LIST_ID}
                >
                  {option?.LIST_NAME}
                </option>
              )
            })}
          </select>
        </>
      ) : (
        <div className='text-black flex flex-col gap-[8px]'>
          {single_type === 'phone' ? (
            <>
              <label>
                Name
                <input
                  className={uiStyles.input}
                  value={content?.list_single_name || ''}
                  onChange={(e) => {
                    saveDistribute((prev) => ({
                      ...prev,
                      content: {
                        ...prev?.content,
                        [type]: {
                          ...prev?.content?.[type],
                          list_single_name: e.target.value,
                        },
                      },
                    }))
                  }}
                />
              </label>
              <label>
                Phone Number
                <input
                  className={uiStyles.input}
                  value={formatPhoneNumber(content?.list_single_number) || ''}
                  onChange={(e) => {
                    const number = formatPhoneNumber(e.target.value)
                    saveDistribute((prev) => ({
                      ...prev,
                      content: {
                        ...prev?.content,
                        [type]: {
                          ...prev?.content?.[type],
                          list_single_number: number
                            ?.replace('+1', '')
                            ?.replace(/\D/g, '')
                            ?.slice(0, 10),
                        },
                      },
                    }))
                  }}
                />
              </label>
            </>
          ) : (
            <>
              <label>
                Email
                <input
                  className={uiStyles.input}
                  value={content?.list_single_email || ''}
                  onChange={(e) => {
                    saveDistribute((prev) => ({
                      ...prev,
                      content: {
                        ...prev?.content,
                        [type]: {
                          ...prev?.content?.[type],
                          list_single_email: e.target.value,
                        },
                      },
                    }))
                  }}
                />
              </label>
            </>
          )}
        </div>
      )}
      {/* {content?.list_id > 0 && (
        <div className='mt-2'>
          <button
            className='underline cursor-pointer'
            onClick={() => {
              setModalType('List')
              setShowTeamConfig(!showTeamConfig)
            }}
          >
            Configure {list}
          </button>
        </div>
      )} */}
    </div>
  )
}
