import { useState } from 'react'
import { useCalc } from '../../contexts/CalcContext'
import { add_delay, capitalize, remove_keys_by_suffix } from '../../helpers'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import ButtonsRow from '../ui/ButtonsRow'
import { SmallLoadingRel } from '../ui/SmallLoadingRel'
import uiStyles from '../ui/uiStyles'
import CalcClosingCosts from './CalcClosingCosts'
import CalcDefaults from './CalcDefaults'

const CalcOptions = ({ setShowModal, showModal, calculator_type = 'buy' }) => {
  const { calc, saveCalc, get_nums, validate_num, get_loan_type } = useCalc()
  const [saveEstimatesLoading, setSaveEstimatesLoading] = useState(false)
  const { axiomFetch } = useAxiomFetch()
  const items_obj = {
    closing_costs: {
      component: <CalcClosingCosts calculator_type={calculator_type} />,
    },
    defaults: {
      component: <CalcDefaults calculator_type={calculator_type} />,
    },
  }

  return (
    <div className='flex flex-col'>
      <div className='px-3'>
        <ButtonsRow
          padding={'p-1'}
          items={Object.keys(items_obj)?.map((key) => key)}
          selected={calc?.options?.type || 'closing_costs'}
          handler={(item) => {
            console.log(item)
            saveCalc((prev) => ({
              ...prev,
              options: { ...prev?.options, type: item },
            }))
          }}
        />
      </div>
      {items_obj[calc?.options?.type || 'closing_costs']?.component}
      <div className='px-3 flex justify-end gap-[10px] mt-[18px]'>
        <button
          className={`${uiStyles.hover_btn_small}`}
          onClick={() => {
            setShowModal(false)
          }}
        >
          This Estimate
        </button>
        {saveEstimatesLoading ? (
          <div>
            <SmallLoadingRel />
          </div>
        ) : (
          <button
            className={`${uiStyles.hover_btn_small}`}
            onClick={async () => {
              setSaveEstimatesLoading(true)
              const arr_values = []
              const loan_type = get_loan_type(calculator_type, 'defaults')

              const fee_sections = [
                'other_fees',
                'lender_fees',
                'default_fees',
                'prepaid_fees',
                'title_fees',
              ]
              const names = remove_keys_by_suffix(
                {
                  ...calc?.[calculator_type]?.defaults?.[loan_type]
                    ?.closing_costs_names,
                },
                '_type'
              )
              const remove_fees =
                calc?.[calculator_type]?.defaults?.[loan_type]
                  ?.closing_costs_remove || []
              const default_names =
                calc?.[calculator_type]?.defaults?.[loan_type]?.default_fees
              console.log('the_names', default_names)
              // await add_delay()
              const get_type = (key) => {
                let type = 'other_fees'
                for (const section of fee_sections) {
                  if (
                    calc?.[calculator_type]?.defaults?.[loan_type]?.[
                      section
                    ]?.includes(key)
                  ) {
                    type = section
                  }
                }
                return type
              }
              const nums = get_nums(calculator_type)

              console.log('nums', nums)
              const get_value_type = (key, type) => {
                const defaults = calc?.[calculator_type]?.defaults?.[loan_type]
                const closing_costs =
                  calc?.[calculator_type]?.defaults?.[loan_type]?.closing_costs
                if (type === 'defaults') {
                  console.log(
                    loan_type,
                    defaults,
                    'key',
                    key,
                    defaults?.[`${key}_type`]
                  )
                  return defaults?.[`${key}_type`] || null
                } else if (type === 'closing_costs') {
                  return closing_costs?.[`${key}_type`] || null
                }
              }

              Object.keys(names).forEach((key) => {
                const keep_original = [
                  'months_of_insurance',
                  'months_of_taxes',
                  'days_of_interest',
                  'origination_fee',
                  'buyer_broker_fee',
                  'listing_broker_fee',
                ]
                const num = keep_original?.includes(key)
                  ? calc?.[calculator_type]?.defaults?.[loan_type]
                      ?.closing_costs?.[key]
                  : validate_num(calculator_type, key, 'closing_costs', nums)
                const type = get_type(key)
                const value_type = get_value_type(key, 'closing_costs')
                arr_values.push({
                  opt_calc_type: calculator_type,
                  opt_name: names[key],
                  opt_value: num,
                  opt_key: key,
                  opt_type: type,
                  opt_loan_type: loan_type,
                  opt_value_type: value_type,
                })
                return num
              })
              console.log('loan_type', loan_type)
              console.log('default_names', default_names)
              default_names.forEach((key) => {
                const keep_original = ['county']
                const num = keep_original?.includes(key)
                  ? calc?.[calculator_type]?.defaults?.[loan_type]?.[key]
                  : validate_num(calculator_type, key, 'defaults', nums)
                const type = get_type(key)
                const value_type = get_value_type(key, 'defaults')
                arr_values.push({
                  opt_calc_type: calculator_type,
                  opt_name: names?.[key] || capitalize(key),
                  opt_value: num,
                  opt_key: key,
                  opt_type: type,
                  opt_loan_type: loan_type,
                  opt_value_type: value_type,
                })
                return num
              })

              console.log('names', names)
              await axiomFetch('/calculators/defaults', 'POST', {
                defaults: arr_values,
                remove: remove_fees || [],
              })

              setSaveEstimatesLoading(false)
            }}
          >
            All Future Estimates
          </button>
        )}
      </div>
    </div>
  )
}

export default CalcOptions
