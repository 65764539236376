import React, { memo } from 'react';

function IconContent({ iconImage, title, content, iconWidth = '50px' }) {
  const iconw = `max-w-[${iconWidth}]`;
  return (
    <div className="flex p-3">
      {iconImage && (
        <div className={`icon ${iconw}`}>
          <img src={iconImage} alt={title} />
        </div>
      )}
      {(title || content) && (
        <div className="pl-2">
          {title && (
            <h1 className="text-lg text-primary font-semibold">{title}</h1>
          )}

          {content && <p className="text-sm">{content}</p>}
        </div>
      )}
    </div>
  );
}
export default memo(IconContent);
