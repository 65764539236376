import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import icons from '../../../assets/images/connection_icons'
import { useDistribute } from '../../../contexts/DistributeContext'
import { social_medias } from '../../../data/data'
import Modal from '../../Modal'
import uiStyles from '../../ui/uiStyles'
import { Disconnect } from './Disconnect'
export const Connection = ({ conn_key: key, value }) => {
  const { saveDistribute } = useDistribute()
  const [showModal, setShowModal] = useState(false)
  const [disconnectType, setDisconnectType] = useState('')
  const navigate = useNavigate()
  return (
    <div className='flex justify-between'>
      <div className='flex items-center'>
        <img
          src={icons[key]}
          className='w-[40px] mt-[0px] mr-[10px]'
          alt={`${key} media connection`}
        />
        <p className={`${uiStyles.text_black_subtitle}`}>{key}</p>
      </div>
      <div>
        {!value ? (
          <button
            className={uiStyles.hover_btn}
            onClick={() => {
              if (social_medias.includes(key)) {
                saveDistribute((prev) => ({
                  ...prev,
                  type: 'social',
                  social: key,
                }))
              }
              navigate('/user/axiom-ai-distribute')
            }}
          >
            Connect
          </button>
        ) : (
          <button
            className={uiStyles.white_btn}
            onClick={() => {
              setShowModal((prev) => !prev)
              setDisconnectType(key)
            }}
          >
            Disconnect
          </button>
        )}
      </div>
      {showModal ? (
        <Modal
          modalName={'account_disconnect'}
          setShowModal={setShowModal}
          showModal={showModal}
          showClose={true}
        >
          <Disconnect type={disconnectType} setShowModal={setShowModal} />
        </Modal>
      ) : (
        ''
      )}
    </div>
  )
}
