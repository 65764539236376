import React from 'react'
import { usePairwise } from '../../../contexts/PairwiseContext'
import {
  format_currency,
  getDateString,
  validate_number,
} from '../../../helpers'
import uiStyles from '../../ui/uiStyles'
import { RiMoneyDollarBoxFill } from 'react-icons/ri'

const PairwiseDistributions = () => {
  const { pairwise, savePairwise } = usePairwise()
  console.log('pairwise', pairwise)
  return (
    <div className='px-3'>
      <h1 className={`${uiStyles.text_black_title} mb-[10px]`}>
        Distributions History
      </h1>
      <div className='flex flex-col justify-between'>
        <div className='flex flex-col gap-[10px] h-[440px] overflow-scroll'>
          {pairwise?.data?.pair_payments?.map((item) => {
            return (
              <div className='flex items-center shadow-lg bg-gray-100 rounded-sm p-2 gap-[10px]'>
                <div>
                  <RiMoneyDollarBoxFill size={40} className='rounded-full' />
                </div>
                <div>
                  <p className='text-black text-[16px]'>
                    Distribution Payment: $
                    {format_currency(item?.pmt_amt, false, 2)}
                  </p>
                  {item?.sent_dt ? (
                    <p>
                      {' '}
                      {getDateString({
                        date: item?.sent_dt,
                        format: 'mmm d, yyyy',
                      })}
                    </p>
                  ) : (
                    <p>Pending</p>
                  )}
                </div>
              </div>
            )
          })}
        </div>
        <div className='flex justify-between pl-[0px] pr-[20px] text-[30px]'>
          <p>Total:</p>
          <p>
            {' '}
            $
            {format_currency(
              pairwise?.data?.pair_payments?.lenght
                ? pairwise?.data?.pair_payments?.reduce((curr, val) => {
                    return (
                      validate_number(curr?.pmt_amt) +
                      validate_number(val?.pmt_amt)
                    )
                  })
                : 0,
              false,
              2
            )}
          </p>
        </div>
      </div>
    </div>
  )
}

export default PairwiseDistributions
