import React, { useState } from 'react'
import silhouette from '../../assets/images/profile-silhouette.svg'
import Header2 from '../Header2'
import { DUMMYMARKETINGLOBBY } from '../../data/data'
import ListItem from '../ui/ListItem'
import SwipeConfig from '../ui/SwipeConfig'
import { FaBolt } from 'react-icons/fa'
import SubTitle from '../ui/SubTitle'
import { Link, useNavigate } from 'react-router-dom'
import Wrapper from '../layout/Wrapper'
import { useProfile } from '../../contexts/UserContext'
import Usage from '../usage/Usage'
import { NotificationsIcon } from './conversations/NotificationsIcon'
import { useConversations } from '../../contexts/ConversationsContext'
import { useMktReqs } from '../../contexts/MktReqsContext'
import { useDistribute } from '../../contexts/DistributeContext'
import { useAi } from '../../contexts/AiContext'
import { content_types } from '../../data/data'
import { useListingTours } from '../../contexts/ListingToursContext'
import { set_storage } from '../../helpers'
export default function MarketingLobby() {
  const { convMessages, conversations, campaigns, loadingConversations } =
    useConversations()
  const { listingTours, saveListingTours, show_tour_experience_lobby } =
    useListingTours()
  const { saveMktReqs } = useMktReqs()
  const { saveDistribute } = useDistribute()
  const { saveAi } = useAi()
  const [selected, setSelected] = useState(1)
  const navigate = useNavigate()
  const { profile } = useProfile()
  console.log(profile)
  const htmlListing = DUMMYMARKETINGLOBBY?.map((item, index) => {
    const click_link = () => {
      if (item?.id === 'buyer_tours') {
        if (show_tour_experience_lobby()) {
          return navigate('/user/buyer_tours/tour_experience_lobby')
        } else {
          saveListingTours((prev) => {
            const ret_data = {
              ...prev?.buyer_tours,
              event_type_id: 162,
              tour_experience_id: 22,
              is_agent_tour: 'N',
            }
            set_storage('buyer_tours', ret_data)
            return {
              ...prev,
              buyer_tours: ret_data,
            }
          })
        }
      }
      if (item.id === 'my_marketing') {
        saveMktReqs((prev) => ({
          ...prev,
          home_url: '/user/marketing-lobby',
          create_order: {
            ...prev?.create_order,
            order_type: 2,
            sub_topic_promotion: 61,
            slot_group_type: 1,
          },
          my_orders_toggle: 'N',
        }))
      }
      if (item.id === 'ai_assistant') {
        saveAi((prev) => ({
          ...prev,
          assistant: { ...prev?.assistant, is_assistant: 'Y' },
          type: 'social',
          social: 'facebook',
          state: {
            ...prev?.state,
            message: '',
            long_message: '',
            short_message: '',
            subject: '',
            original_prompt: '',
            last_prompt: '',
            prompt: '',
            // stopHeight: 500 * height_change,
            isLoading: false,
            run: false,
          },
          image_upload: {
            ...prev?.image_upload,
            upload_url: '',
            gallery: {
              ...prev?.image_upload?.gallery,
              active: {
                ...prev?.image_upload?.gallery?.active,
                url: '',
              },
            },
          },
          // ai?.image_upload?.upload_url || ai?.image_upload?.gallery?.active?.url
        }))

        for (const content_type of content_types) {
          saveDistribute((prev) => ({
            ...prev,
            content: {
              ...prev.content,
              [content_type]: {
                ...(prev?.content?.[content_type] || {}),
                message: '',
                long_message: '',
                short_message: '',
                posted_id: '',
                photo_url: '',
              },
            },
          }))
        }
      }
      navigate(item.link)
    }
    if (item?.id === 'market_rep') {
      item.description = item.description.replace(
        /{{name}}/g,
        profile?.REP_NAME || '{{name}}'
      )
      item.thumbnail = profile?.REP_PHOTO || silhouette
    }
    if (item?.id === 'buyer_tours') {
      if (show_tour_experience_lobby()) {
        item.link_title = 'View All'
      }
      item.thumbnail = listingTours?.data?.tour_types?.find(
        (item) => item.type_id === 22
      )?.van_photo
    }

    const get_count = (id) => {
      let total = 0
      if (id === 'conversations') {
        for (const conversation of conversations) {
          total += convMessages?.[conversation?.sid]?.unread_count || 0
        }
        for (const campaign of Object.values(campaigns)) {
          console.log(campaign)
          for (const conv of campaign?.conversations) {
            total += convMessages[conv?.sid]?.unread_count || 0
          }
        }
      }
      return total
    }
    return (
      <div key={`${item?.title}-${index}`}>
        <SwipeConfig
          key={index}
          index={index}
          selectedIndex={selected}
          setSelectedIndex={setSelected}
          widthConfigRight={44}
          RightComponents={() => (
            <div className='flex gap-[10px]'>
              <div className='cursor-pointer' onClick={click_link}>
                {item?.link_title || 'Open'}
              </div>
              {item?.id === 'buyer_tours' &&
              listingTours?.buyer_tours?.tour_experience_id &&
              show_tour_experience_lobby() ? (
                <div
                  className='cursor-pointer'
                  onClick={() => {
                    return navigate('/user/buyer_tours')
                  }}
                >
                  Current
                </div>
              ) : (
                ''
              )}
            </div>
          )}
        >
          {/* <Link to={item.link}> */}
          <div className=''>
            <ListItem
              key={`${item?.title}-${index + 1}`}
              image={item.thumbnail}
              title={item.title}
              content={item.description}
              click_me={true}
              select={click_link}
              notifications={
                item?.notifications ? (
                  <NotificationsIcon
                    count={get_count(item?.id)}
                    loading={loadingConversations}
                  />
                ) : (
                  ''
                )
              }
            />
          </div>
          {/* </Link> */}
        </SwipeConfig>
      </div>
    )
  })
  return (
    <Wrapper>
      <Header2 />
      <div className='flex items-center px-2 py-4'>
        <div>
          <FaBolt className='text-yellow-500 text-6xl' />
        </div>
        <div>
          <SubTitle>Marketing</SubTitle>
          <p>All things marketing can be accessed here.</p>
        </div>
      </div>
      <div className='flex flex-col gap-[2px]'>{htmlListing}</div>
    </Wrapper>
  )
}
