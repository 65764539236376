import { useState } from 'react'
import uiStyles from '../ui/uiStyles'
export const CircleCheckToggle = ({
  type = 'check',
  toggle_color,
  toggle_color_grad,
  toggle_left_label = '',
  toggle_right_label = '',
  toggle_left = false,
  name,
  label,
  handle_change,
  inputValue,
  setInputValue,
  ...restProps
}) => {
  const [toggle, setToggle] = useState(false)

  const circle_check = (
    <div className='flex items-center justify-center'>
      <div
        className={`h-[16px] w-[16px] rounded-full bg-gradient-to-br ${
          inputValue?.[name] === 'Y'
            ? 'from-accent-600 to-accent-150'
            : 'from-gray-400 to-gray-400'
        } p-[1px]`}
      >
        <div className='flex h-[14px] w-[14px] rounded-full items-center justify-center'>
          {inputValue?.[name] === 'Y' ? (
            <svg
              width='10'
              height='8'
              viewBox='0 0 10 8'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M9 1L3.5 6.5L1 4'
                stroke='url(#paint0_linear_1338_30152)'
                strokeWidth='1.6666'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <defs>
                <linearGradient
                  id='paint0_linear_1338_30152'
                  x1='-1.58407'
                  y1='2.77655'
                  x2='1.30771'
                  y2='9.28756'
                  gradientUnits='userSpaceOnUse'
                >
                  <stop stopColor='#fff' />
                  <stop offset='1' stopColor='#fff' />
                </linearGradient>
              </defs>
            </svg>
          ) : (
            <div className='h-full w-full rounded-full bg-gradient-to-br from-white to-white'></div>
          )}
        </div>
      </div>
    </div>
  )

  const circle_toggle = (
    <div className='flex justify-center gap-[5px] items-center'>
      {toggle_left_label ? (
        <div
          className={`flex min-w-[120px] justify-end ${
            toggle_left
              ? inputValue?.[name] === 'Y'
                ? 'font-bold text-[#344054]'
                : 'text-gray-500'
              : inputValue?.[name] === 'Y'
              ? 'text-gray-500'
              : 'font-bold text-[#344054]'
          }`}
        >
          {toggle_left_label}
        </div>
      ) : (
        ''
      )}
      <div
        className={`flex transition-all delay-100 toggle h-[20px] rounded-2xl bg-gradient-to-b ${
          inputValue?.[name] === 'Y'
            ? toggle_color
              ? toggle_color_grad
              : 'from-accent-150 to-accent-250'
            : 'from-gray-200 to-gray-200'
            ? 'from-gray-200 to-gray-200'
            : toggle_color
            ? toggle_color_grad
            : 'from-accent-150 to-accent-250'
        } px-[10px] py-[2px]`}
      >
        <span
          className={`bg-white cursor-pointer handle transition-all delay-50 w-[16px] h-[16px] rounded-full ${
            inputValue?.[name] === 'Y'
              ? toggle_left
                ? 'transform translate-x-[-8px]'
                : 'transform translate-x-[8px]'
              : toggle_left
              ? 'transform translate-x-[8px]'
              : 'transform translate-x-[-8px]'
          }`}
        ></span>
      </div>
      {toggle_right_label ? (
        <div
          className={`flex min-w-[120px] justify-start ${
            inputValue?.[name] === 'Y'
              ? toggle_left
                ? 'text-gray-500'
                : `font-bold ${toggle_color ? toggle_color : 'text-[#344054]'}`
              : toggle_left
              ? `font-bold ${toggle_color ? toggle_color : 'text-[#344054]'}`
              : 'text-gray-500'
          }`}
        >
          {toggle_right_label}
        </div>
      ) : (
        ''
      )}
    </div>
  )

  return (
    <div className='flex items-center'>
      <label className='capitalize relative w-[16px] px-3'>
        <input
          {...restProps}
          tabIndex={-1}
          className='absolute left-0 right-0 top-0 h-full opacity-0 cursor-pointer'
          type='checkbox'
          name={name}
          value={inputValue?.[name]}
          checked={inputValue?.[name] === 'Y' ? true : false}
          onChange={handle_change}
        />
        {type === 'check' ? circle_check : circle_toggle}
      </label>
      <span className={`${uiStyles.text_gray} ml-1 font-medium`}>{label}</span>
    </div>
  )
}
