import React, { useEffect, useState } from 'react'
import PrimaryButton from '../../ui/PrimaryButton'
import DateTimePicker from '../../ui/DateTimePicker'
import Engage from './Engage'
import { useDistribute } from '../../../contexts/DistributeContext'
import { useProfile } from '../../../contexts/UserContext'
import styles from '../../ui/uiStyles'
import { AiOutlineCopy } from 'react-icons/ai'
import SmallLoading from '../../ui/SmallLoading'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { check_prompt, formatPhoneNumber } from '../../../helpers'
import TitleIcon from '../../ui/TitleIcon'
import { PageCallCenter, PageSingleLeadList } from '../../../pages'
import Modal from '../../Modal'
import { useSearchParams } from 'react-router-dom'
import Limits from '../../Limits'
import { useLimits } from '../../../contexts/LimitsContext'
import { AskAxiom } from './AskAxiom'

export default function Message({
  isInModal,
  showModal,
  setShowModal,
  message,
  onMessageSend,
  modalListId,
  modalListName,
}) {
  const { axiomFetch } = useAxiomFetch()
  const [searchParams] = useSearchParams()
  const { limits, saveLimits } = useLimits()
  const rePostMedia = searchParams.get('media')
  const rePostMessage = searchParams.get('message')
  const rePostLang = searchParams.get('lang')
  const { profile } = useProfile()
  const { distribute, saveDistribute } = useDistribute()
  // const { getAccessTokenSilently } = useAuth0();
  const [state, setState] = useState({})
  const [aiCreate, setAiCreate] = useState()
  const [axiomUpdate, setAxiomUpdate] = useState('')

  const [post, setPost] = useState()
  const [suggested, setSuggested] = useState('')
  // eslint-disable-next-line
  const [date, setDate] = useState()

  const [singleNumber, setSingleNumber] = useState('')
  const [singleName, setSingleName] = useState('')
  const [list, setList] = useState(modalListName || '')
  const [listOptions, setListOptions] = useState([])
  const [listSelected, setListSelected] = useState([])
  const [messageSent, setMessageSent] = useState(false)
  const [listId, setListId] = useState(modalListId || null)
  const [currentTokens, setCurrentTokens] = useState(0)

  // if you change the Hey {{contactName}}, at the beginning fix the regex to filter sheduled message
  const [suggestedMod, setSuggestedMod] = useState({
    intro: `Hey {{contactName}},\n\n It's ${profile.CONT_NAME} here. `,
    closing: `\n\nReply STOP to unsubscribe.`,
    callLink: true,
    callLinkMsg: `\n\nClick the link below to have me call you.\n{{callLink}}`,
    // callLinkDefault: `Reply back yes and I will connect with you directly.`,
  })

  useEffect(() => {
    if (state?.last_prompt?.toLowerCase()?.includes('spanish')) {
      setSuggestedMod({
        intro: `Ey {{contactName}},\n\n Soy ${profile.CONT_NAME}. `,
        closing: `\n\nResponde STOP para cancelar la suscripión.`,
        callLink: true,
        callLinkMsg: `\n\nHaz clic en el enlace de abajo para que yo te llame.\n{{callLink}}`,
        // callLinkDefault: `Reply back yes and I will connect with you directly.`,
      })
    } else if (state?.last_prompt?.toLowerCase()?.includes('english')) {
      setSuggestedMod({
        intro: `Hey {{contactName}},\n\n It's ${profile.CONT_NAME} here. `,
        closing: `\n\nReply STOP to unsubscribe.`,
        callLink: true,
        callLinkMsg: `\n\nClick the link below to have me call you.\n{{callLink}}`,
        // callLinkDefault: `Reply back yes and I will connect with you directly.`,
      })
    }
  }, [state?.last_prompt])
  const [showTeamConfig, setShowTeamConfig] = useState(false)
  const [modalType, setModalType] = useState('')
  const [refresh, setRefresh] = useState(0)

  const dateHandler = (date) => {
    setDate(date)
  }

  useEffect(() => {
    const show_message_sent = () => {
      setTimeout(() => {
        setMessageSent(false)
      }, 3000)
    }
    show_message_sent()
  }, [messageSent])

  useEffect(() => {
    const fetch_lists = async () => {
      let data = await axiomFetch('/client/listings/lists', 'GET')
      data = data.filter((d) => {
        if (d.STATUS === 'A' && d?.LIST_NAME?.trim()?.length > 1) {
          return true
        } else {
          return false
        }
      })
      setListOptions(data)
    }
    fetch_lists()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const fetch_lists = async () => {
      if (!listId) return
      let data = await axiomFetch(`/client/listings/lists/${listId}`, 'GET')
      data = data
        ?.filter((d) => {
          if (d.STATUS === 'A' && d?.CELL) {
            return true
          } else {
            return false
          }
        })
        ?.map((da) => {
          return {
            name: da?.NM || '',
            cell: da?.CELL,
          }
        })
      console.log(data)
      setListSelected(data)
    }
    fetch_lists()
    // eslint-disable-next-line
  }, [listId, refresh])

  useEffect(() => {
    if (rePostMedia && rePostMessage && rePostMedia === 'text') {
      console.log(rePostLang)
      setState((prev) => ({
        ...prev,
        message: rePostMessage,
        last_prompt: rePostLang,
      }))
    }
  }, [rePostMessage, rePostMedia])

  const modals = {
    callTeamConfig: (
      <PageCallCenter
        showTeamConfig={showTeamConfig}
        setShowTeamConfig={setShowTeamConfig}
      />
    ),

    List: (
      <PageSingleLeadList
        modalListId={listId}
        modalListName={list}
        showTeamConfig={showTeamConfig}
        setShowTeamConfig={setShowTeamConfig}
        setRefresh={setRefresh}
        smaller={true}
      />
    ),
  }
  if (limits?.[2]?.limit_reached) {
    return <Limits />
  }

  return (
    <>
      <div>
        <AskAxiom type='text' state={state} setState={setState} />

        <div className='pl-2'>Send with Connect Me Now Link.</div>
        <div>
          <label className='block capitalize relative w-[40px] px-3'>
            <input
              tabIndex={-1}
              className='absolute left-0 right-0 top-0 h-full opacity-0'
              type='checkbox'
              name={'input'}
              value={suggestedMod.callLink}
              checked={suggestedMod.callLink ? true : false}
              onChange={(e) => {
                if (e.target.checked) {
                  setSuggestedMod({ ...suggestedMod, callLink: true })
                } else {
                  setSuggestedMod({ ...suggestedMod, callLink: false })
                }
              }}
            />
            <div
              className={`transition-all delay-100 toggle inline-flex w-[36px] h-[20px] rounded-2xl bg-gradient-to-b ${
                suggestedMod.callLink
                  ? 'from-accent-150 to-accent-250'
                  : 'from-gray-200 to-gray-200'
              } p-[2px] mr-2 align-middle`}
            >
              <span
                className={`bg-white handle transition-all delay-100  w-[16px] h-[16px] rounded-full inline-block  ${
                  suggestedMod.callLink && 'transform translate-x-4'
                }`}
              ></span>
            </div>
            {/* {suggestedMod.callLink ? 'Active' : 'Inactive'} */}
          </label>
          <div className='mt-2 pl-2'>
            Note: Text Replies will already connect your team by default.
            <button
              className='ml-1 underline cursor-pointer'
              onClick={() => {
                setModalType('callTeamConfig')
                setShowTeamConfig(!showTeamConfig)
              }}
            >
              Configure Your Team
            </button>
          </div>
        </div>

        <div className='my-3'>
          <p className='text-center font-semibold text-primary'>
            When would you like this to be sent?
          </p>
          <DateTimePicker dateChangeHandler={dateHandler} />
        </div>
        <div className='mb-3'>
          <label
            className={`${styles.label} transition-all duration-300 ${
              singleNumber && 'text-gray-300'
            }`}
          >
            Send To List
          </label>
          <select
            value={list}
            onChange={(e) => {
              setListId(e.target.options[e.target.selectedIndex].id)
              setList(e.target.value)
            }}
            disabled={singleNumber ? true : false}
            className={`${styles.select}`}
          >
            {/* 
                  <option>FSBO 01/03/2023</option>
                  <option>FSBO 01/02/2023</option> */}
            <option id={0}>none</option>
            {listOptions.map((option, index) => {
              return (
                <option key={`${option?.LIST_ID}-${index}`} id={option.LIST_ID}>
                  {option?.LIST_NAME}
                </option>
              )
            })}
          </select>
          {listId > 0 && (
            <div className='mt-2'>
              <button
                className='underline cursor-pointer'
                onClick={() => {
                  setModalType('List')
                  setShowTeamConfig(!showTeamConfig)
                }}
              >
                Configure {list}
              </button>
            </div>
          )}
          <div className='text-black my-2'>Or </div>

          <div className='text-black'>
            <label>
              Name
              <input
                className={styles.input}
                value={singleName}
                onChange={(e) => {
                  setSingleName(e.target.value)
                }}
              />
            </label>
            <label>
              Send to a Single Phone Number
              <input
                className={styles.input}
                value={singleNumber}
                onChange={(e) => {
                  const number = formatPhoneNumber(e.target.value)
                  setSingleNumber(number)
                }}
              />
            </label>
          </div>
        </div>
        <div className='whitespace-pre-line'>{`${suggestedMod.intro}${
          state?.message || ''
        }${suggestedMod.callLink ? suggestedMod.callLinkMsg : ''}${
          suggestedMod.closing
        }`}</div>
        {limits?.[2]?.ot_overage +
          (!singleNumber ? listSelected?.length || 0 : 1) >=
        0 ? (
          <Limits />
        ) : (
          ''
        )}
        {!messageSent ? (
          <div className='flex gap-3 mt-5 justify-end'>
            <button
              className={`${styles.hover_btn} ${
                !state?.message ||
                (!(
                  singleNumber
                    ?.replace('+1', '')
                    ?.replace(/\D/g, '')
                    ?.slice(0, 10).length > 9
                ) &&
                  listSelected?.length < 1)
                  ? styles.hover_btn_greyed_out
                  : limits?.[2]?.ot_overage +
                      (!singleNumber ? listSelected?.length || 0 : 1) >=
                    0
                  ? styles.hover_btn_greyed_out
                  : ''
              }}`}
              disabled={
                !state?.message ||
                (!singleNumber && (listSelected?.length < 1 || isInModal))
                  ? true
                  : limits?.[2]?.ot_overage +
                      (!singleNumber ? listSelected?.length || 0 : 1) >=
                    0
                  ? true
                  : false
              }
              onClick={async () => {
                if (singleNumber) {
                  let number = singleNumber
                    ?.replace('+1', '')
                    ?.replace(/\D/g, '')
                    ?.slice(0, 10)
                  const msg = {
                    date: date
                      ? new Date(date).toISOString()
                      : new Date().toISOString(),
                    text: `${suggestedMod.intro}${state?.message}${
                      suggestedMod.callLink ? suggestedMod.callLinkMsg : ''
                    }${suggestedMod.closing}`,
                    selected: [
                      {
                        name: singleName || '',
                        cell: number,
                      },
                    ],
                  }
                  setList('none')
                  setListId(0)
                  setListSelected([])
                  setSingleNumber('')
                  setSingleName('')
                  setMessageSent(true)
                  setState((prev) => ({
                    ...prev,
                    message: '',
                    last_prompt: 'english',
                  }))
                  if (showModal) {
                    setTimeout(() => {
                      setShowModal(false)
                    }, [2000])
                  }
                  const send_texts = await axiomFetch(
                    '/marketing/messaging/sendCallTexts',
                    'POST',
                    msg
                  )
                  const new_limits = await axiomFetch(
                    '/marketing/content/limits'
                  )
                  saveLimits(new_limits)
                } else if (listSelected?.length > 0) {
                  const msgList = {
                    date: date
                      ? new Date(date).toISOString()
                      : new Date().toISOString(),
                    text: `${suggestedMod.intro}${state?.message}${
                      suggestedMod.callLink ? suggestedMod.callLinkMsg : ''
                    }${suggestedMod.closing}`,
                    selected: listSelected,
                  }
                  setList('none')
                  setListId(0)
                  setListSelected([])
                  setSingleNumber('')
                  setSingleName('')
                  setMessageSent(true)
                  setState((prev) => ({
                    ...prev,
                    message: '',
                    last_prompt: 'english',
                  }))
                  if (showModal) {
                    setTimeout(() => {
                      setShowModal(false)
                    }, [2000])
                  }
                  const send_texts = await axiomFetch(
                    '/marketing/messaging/sendCallTexts',
                    'POST',
                    msgList
                  )
                  const new_limits = await axiomFetch(
                    '/marketing/content/limits'
                  )
                  saveLimits(new_limits)
                }
              }}
            >
              Send
            </button>
            {/* <button
                className={`${styles.hover_btn} ${(!suggested || (!(singleNumber?.replace('+1', '')?.replace(/\D/g, '')?.slice(0, 10).length > 9)) && (listSelected?.length < 1)) ? styles.hover_btn_greyed_out : ''}`}
                disabled={(!suggested || (!singleNumber && listSelected?.length < 1) ) ? true : false}
                onClick={async () => {
                  const formated_date = getDateString({
                    date: date,
                    format: 'yyyy-mm-ddT24h:mi'})
                  if (singleNumber) {
                    let number = singleNumber?.replace('+1', '')?.replace(/\D/g, '')?.slice(0, 10)
                    const msg = {
                      date: formated_date,
                      text: `${suggestedMod.intro}${suggested}${suggestedMod.callLink ? suggestedMod.callLinkMsg : ''}${suggestedMod.closing}`,
                      selected: [
                        {
                          name: singleName || 'default',
                          cell: number}
                      ],
                      tester: 'andrew'
                    }
                    setList('none')
                    setListId(0)
                    setListSelected([])
                    setSingleNumber('')
                    setSingleName('')
                    setMessageSent(true)
                    const send_texts = await axiomFetch('/marketing/messaging/sendTexts', 'POST', msg)
                    console.log(send_texts) 
                  } else if (listSelected?.length > 0) {
                   
                    const msgList = {
                      date: formated_date,
                      text: `${suggestedMod.intro}${suggested}${suggestedMod.callLink ? suggestedMod.callLinkMsg : ''}${suggestedMod.closing}`,
                      selected: listSelected,
                      tester: 'andrew'
                    }
                    setList('none')
                    setListId(0)
                    setListSelected([])
                    setSingleNumber('')
                    setSingleName('')
                    setMessageSent(true)
                    const send_texts = await axiomFetch('/marketing/messaging/sendTexts', 'POST', msgList)
                    
                    console.log(send_texts)

                  }
                }}
                >
                 Send Conversation
                </button> */}
          </div>
        ) : (
          <div className={styles.success_msg}>
            {' '}
            Message{singleNumber ? 's' : ''} Scheduled!
          </div>
        )}
      </div>
      {showTeamConfig && (
        <Modal
          modalName={modalType}
          width='w-[315px]'
          height='h-[500px]'
          showModal={setShowTeamConfig}
          setShowModal={setShowTeamConfig}
        >
          {modalType && modals[modalType]}
        </Modal>
      )}
    </>
  )
}
