import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { get_storage } from '../../helpers'
import Wrapper from '../layout/Wrapper'
import SubNav from '../SubNav'

const ReportsWrapper = () => {
  const { pathname } = useLocation()

  return (
    <Wrapper>
      <SubNav />
      <Outlet />
    </Wrapper>
  )
}

export default ReportsWrapper
