import React from 'react'
import { useCalc } from '../../contexts/CalcContext'
import {
  format_currency,
  format_input,
  getDateString,
  validate_number,
} from '../../helpers'
import LabelInput from '../form/LabelInput'
import { Schedule } from '../form/Schedule'
import Select from '../form/Select'
import uiStyles from '../ui/uiStyles'

const CalcAddressFound = ({
  calculator_type = 'buy',
  setModalType,
  setShowModal,
}) => {
  const { calc, saveCalc, get_loan_type } = useCalc()

  const update_input = (options) => {
    const calculator_type = 'loan_balance'
    const { name, value } = format_input(options)
    let type =
      options?.type || calc?.[calculator_type]?.types?.[`${name}`] || ''
    saveCalc((prev) => {
      const result = {
        ...prev,
        [calculator_type]: {
          ...prev?.[calculator_type],
          values: { ...prev?.[calculator_type]?.values, [name]: value },
          types: { ...prev?.[calculator_type]?.types, [name]: type },
        },
      }
      return result
    })
  }

  const update_input_loan = (options) => {
    const calculator_type = 'loan_balance'
    const { name, value } = format_input(options)
    let type =
      options?.type || calc?.[calculator_type]?.types?.[`${name}`] || ''
    saveCalc((prev) => {
      const result = {
        ...prev,
        [calculator_type]: {
          ...prev?.[calculator_type],
          values: { ...prev?.[calculator_type]?.values, [name]: value },
          types: { ...prev?.[calculator_type]?.types, [name]: type },
        },
      }
      return result
    })
  }
  const get_inputs = (calculator_type = 'loan_balance') => {
    const types = calc?.[calculator_type]?.types
    const names = calc?.[calculator_type]?.names
    const placeholders = calc?.[calculator_type]?.placeholders
    return Object.entries(calc?.[calculator_type]?.values)
      ?.filter(([key, value]) => {
        if (
          (calc?.[calculator_type]?.defaults?.is_home_price === 'N' &&
            (key === 'home_price' || key === 'down_payment')) ||
          (calc?.[calculator_type]?.defaults?.is_home_price === 'Y' &&
            key === 'loan_amount')
        ) {
          return false
        } else {
          return true
        }
      })
      ?.map(([key, value]) => {
        const type = types[key]
        const name = names[key]
        const placeholder = placeholders[key] || ''
        const label_width = 'w-[80%]'
        const initial_data = {
          left_label: name,
          left_label_width: label_width,
          name: key,
          value,
          placeholder,
        }

        if (type === '%' || type === '$' || type === '%$') {
          initial_data.show_delete_all = true
          initial_data.inputMode = 'decimal'
        }
        if (type === '%') {
          initial_data.show_percent = true
          initial_data.percent_value = '%'
          if (key === 'loan_term') {
            initial_data.percent_value = ' years'
          }
        }
        if (type === '$') {
          initial_data.show_dollar = true
          initial_data.percent_value = ''
          initial_data.value = initial_data?.value
            ? `$${initial_data.value}`
            : initial_data?.value || ''
        }
        if (key === 'down_payment') {
          initial_data.show_percent = true
          initial_data.show_dollar = true
          initial_data.percent_value =
            calc?.[calculator_type]?.types?.[key] === '%' ? '%' : ''
          initial_data.dollar_percent = calc?.[calculator_type]?.types?.[key]
          initial_data.left_label_width = 'w-[135%]'
          initial_data.delete_all_position = 'right-[75px]'
          initial_data.total = validate_number(
            calculator_type === 'sell'
              ? calc?.[calculator_type]?.values?.sale_price
              : calc?.[calculator_type]?.values?.home_price
          )
          initial_data.handleChange = (e, type) => {
            update_input({ e, type, decimal: 3, original: value })
          }
        }

        if (key === 'home_price' || key === 'loan_amount') {
          initial_data.onKeyUp = (e) => {
            // update_input({ e, type, decimal: 2, original: value })
            if (e.target.value && e.key === 'Enter') {
              // calculate_estimate()
            }
          }
        }

        let result = (
          <LabelInput
            {...initial_data}
            handleChange={
              initial_data?.handleChange
                ? initial_data?.handleChange
                : (e) => {
                    update_input({ e, type, decimal: 2, original: value })
                  }
            }
          />
        )

        if (type === 'date') {
          result = (
            <Schedule
              left_label={name}
              left_label_width={label_width}
              no_margin={'mb-[10px]'}
              name={key}
              show_time={false}
              schedule_date={value}
              handle_change={(e) => {
                update_input({ e, type, decimal: 2, original: value })
              }}
            />
          )
        }
        if (type === 'options') {
          const options = calc?.[calculator_type]?.option_values?.[key]
          result = (
            <Select
              left_label={name}
              left_label_width={label_width}
              no_margin={false}
              className='mb-[10px]'
              name={key}
              value={value || 'Conventional'}
              handler={(e) => {
                update_input({ e, type, decimal: 2, original: value })
              }}
              placeholder=''
            >
              {[...options].map((item) => {
                return (
                  <option key={item} value={item}>
                    {item}
                  </option>
                )
              })}
            </Select>
          )
        }

        return <div key={key}>{result}</div>
      })
  }
  return (
    <div className='px-3'>
      <div className='flex flex-col'>
        <p className='text-center font-bold mb-[20px]'>
          {calc?.formatted_address}
        </p>
        {validate_number(calc?.[calculator_type]?.values?.sale_price) ? (
          <div>
            <p className='mb-[3px]'>
              We found an estimated{' '}
              <span className='font-bold'>{`${
                calc?.[calculator_type]?.data_options?.is_listing
                  ? 'Listing Price'
                  : 'Sales Price'
              }`}</span>{' '}
              of:
            </p>
            <LabelInput
              left_label={
                calc?.[calculator_type]?.data_options?.is_listing
                  ? 'Listing Price'
                  : 'Sales Price'
              }
              left_label_width={'w-[95%]'}
              name={'sale_price'}
              show_dollar={true}
              show_delete_all={true}
              value={calc?.[calculator_type]?.values?.sale_price}
              handleChange={(e, type) =>
                update_input({
                  e,
                  type: calc?.[calculator_type]?.types?.sale_price,
                  decimal: 2,
                  original: calc?.[calculator_type]?.values?.sale_price,
                })
              }
              placeholder=''
            />
          </div>
        ) : (
          ''
        )}
        {validate_number(calc?.[calculator_type]?.values?.taxes) ? (
          <div>
            <p className='mb-[3px]'>
              We found an estimated{' '}
              <span className='font-bold'>Property Tax</span> of:
            </p>
            <LabelInput
              left_label={'Property Taxes'}
              left_label_width={'w-[95%]'}
              name={'taxes'}
              show_dollar={true}
              show_delete_all={true}
              value={calc?.[calculator_type]?.values?.taxes}
              handleChange={(e, type) =>
                update_input({
                  e,
                  type: calc?.[calculator_type]?.types?.taxes,
                  decimal: 3,
                  original: calc?.[calculator_type]?.values?.taxes,
                })
              }
              placeholder=''
            />
          </div>
        ) : (
          ''
        )}
      </div>
      <div>
        {validate_number(calc?.loan_balance?.values?.loan_amount) ? (
          <div>
            <p className='mt-[20px]'>
              We found an estimated loan recorded
              <span className='font-bold'>
                {` ${getDateString({
                  date: calc?.loan_balance?.values?.loan_start_date,
                  format: 'month year',
                })} `}
              </span>
              for an amount of{' '}
              <span className='font-bold'>
                {`$${format_currency(
                  calc?.loan_balance?.values?.loan_amount,
                  false,
                  2
                )}.`}
              </span>
            </p>
            <p className='mt-[10px] mb-[3px]'>
              We Just need an interest rate to estimate your{' '}
              <span className=''>loan balance</span>.
            </p>
            <LabelInput
              left_label={'Interest Rate'}
              left_label_width={'w-[95%]'}
              name={'interest_rate'}
              show_percent={true}
              inputMode='decimal'
              percent_value='%'
              show_delete_all={true}
              value={calc?.['loan_balance']?.values?.interest_rate}
              handleChange={(e, type) =>
                update_input_loan({
                  e,
                  type: calc?.['loan_balance']?.types?.interest_rate,
                  decimal: 3,
                  original: calc?.['loan_balance']?.values?.interest_rate,
                })
              }
              placeholder='Required'
            />
            <div className='flex justify-end gap-[10px]'>
              <button
                className={`${uiStyles.white_btn}`}
                onClick={() => {
                  saveCalc((prev) => ({
                    ...prev,
                    loan_balance: {
                      ...prev?.loan_balance,
                      values: {
                        ...prev?.loan_balance?.values,
                        interest_rate:
                          calc?.[calculator_type]?.defaults?.[
                            get_loan_type(calculator_type)
                          ]?.interest_rate,
                      },
                    },
                  }))
                  setShowModal(false)
                }}
              >
                Skip
              </button>
              {validate_number(calc?.loan_balance?.values?.interest_rate) ? (
                <button
                  className={`${uiStyles.hover_btn}`}
                  onClick={() => {
                    saveCalc((prev) => ({
                      ...prev,
                      loan_balance: {
                        ...prev?.loan_balance,
                        data_options: {
                          ...prev?.loan_balance?.data_options,
                          calculated: true,
                          from_address: true,
                        },
                      },
                    }))
                    setModalType('loan_balance')
                  }}
                >
                  Calculate
                </button>
              ) : (
                <button className={`${uiStyles.hover_btn_greyed_out}`}>
                  Calculate
                </button>
              )}
            </div>
          </div>
        ) : (
          <div>
            {!validate_number(calc?.[calculator_type]?.values?.taxes) &&
            !validate_number(calc?.[calculator_type]?.values?.sale_price) ? (
              <div>We couldn't find any data on the address you searched.</div>
            ) : (
              ''
            )}
            <div className='flex justify-end'>
              <button
                className={uiStyles.hover_btn}
                onClick={() => {
                  setShowModal(false)
                }}
              >
                Continue
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default CalcAddressFound
