import React, { useEffect, useState } from 'react'
import PrimaryButton from '../../ui/PrimaryButton'
import DateTimePicker from '../../ui/DateTimePicker'
import Engage from './Engage'

import useAxiomFetch from '../../../hooks/useAxiomFetch'
import styles from '../../ui/uiStyles'
import TitleIcon from '../../ui/TitleIcon'
import Modal from '../../Modal'
import { PageSingleLeadList } from '../../../pages'
import { useProfile } from '../../../contexts/UserContext'
import EmailVerify from './EmailVerify'
import Limits from '../../Limits'
import { useSearchParams } from 'react-router-dom'
import { useLimits } from '../../../contexts/LimitsContext'
import uiStyles from '../../ui/uiStyles'
import { AskAxiom } from './AskAxiom'
import { useConnections } from '../../../contexts/ConnectionContext'
import { useDistribute } from '../../../contexts/DistributeContext'
export default function Email({ showModal, setShowModal }) {
  const { distribute, saveDistribute } = useDistribute()
  const { limits, saveLimits } = useLimits()
  const [searchParams] = useSearchParams()
  const rePostMedia = searchParams.get('media')
  const rePostSubject = searchParams.get('subject')
  const rePostMessage = searchParams.get('message')
  const { profile } = useProfile()
  const { axiomFetch } = useAxiomFetch()
  const [state, setState] = useState({})
  const [suggested, setSuggested] = useState('')
  const [suggestedSubject, setSuggestedSubject] = useState('')
  const [date, setDate] = useState()

  const [singleEmail, setSingleEmail] = useState('')
  const [singleName, setSingleName] = useState('')
  const [list, setList] = useState(distribute?.list_name || '')
  const [listOptions, setListOptions] = useState([])
  const [listSelected, setListSelected] = useState([])
  const [listId, setListId] = useState(distribute?.list_id || null)
  const [showTeamConfig, setShowTeamConfig] = useState(false)
  const [modalType, setModalType] = useState('')
  const { connections } = useConnections()
  const [currentTokens, setCurrentTokens] = useState(0)
  const [messageSent, setMessageSent] = useState('done')
  const [refresh, setRefresh] = useState(0)
  const handle_msg = (status, message, type, close) => {
    // statuses: success_msg, loading_msg, failure_msg, done
    setMessageSent({ ...messageSent, status, message, type })
    if (close) {
      setTimeout(() => {
        setMessageSent({ ...messageSent, status: 'done', message: '' })
      }, 3000)
    }
  }

  const dateHandler = (date) => {
    setDate(date)
  }

  useEffect(() => {
    const fetch_lists = async () => {
      if (!listId) return
      let data = await axiomFetch(`/client/listings/lists/${listId}`, 'GET')
      data = data
        ?.filter((d) => {
          if (d.STATUS === 'A' && d?.EMAIL) {
            return true
          } else {
            return false
          }
        })
        ?.map((da) => {
          return {
            name: da?.NM || '',
            email: da?.EMAIL,
          }
        })
      console.log(data)
      setListSelected(data)
    }
    fetch_lists()
    // eslint-disable-next-line
  }, [listId, refresh])

  useEffect(() => {
    if (rePostMedia && rePostMedia === 'email') {
      setState((prev) => ({
        ...prev,
        message: rePostMessage,
        subject: rePostSubject,
      }))
    }
  }, [rePostMedia, rePostMessage, rePostSubject])

  const send_emails = async () => {
    if (singleEmail) {
      const msg = {
        date: date ? new Date(date)?.toISOString() : new Date()?.toISOString(),
        text: `${state?.message || ''}`,
        subject: `${state?.subject || ''}`,
        selected: [
          {
            name: singleName || '',
            email: singleEmail,
          },
        ],
        from: profile?.CONT_EMAIL,
      }
      setList('none')
      setListId(0)
      setListSelected([])
      setSingleEmail('')
      setSingleName('')
      handle_msg('loading_msg', 'Sending Email...', 'email')

      // let emailString = `mailto:${singleEmail}?subject=${suggestedSubject}&body=${suggested}`
      // console.log(emailString)
      await axiomFetch('/services/email/subuser/email/schedule', 'POST', msg)
      const new_limits = await axiomFetch('/marketing/content/limits')
      saveLimits(new_limits)
      handle_msg('success_msg', 'Email Sent', 'email', true)
      //const send_texts = await axiomFetch('/marketing/messaging/sendCallTexts', 'POST', msg)
      // console.log(send_texts)
    } else if (listSelected?.length > 0) {
      console.log(date)
      const msgList = {
        date: date ? new Date(date)?.toISOString() : new Date()?.toISOString(),
        text: `${state?.message || ''}`,
        subject: `${state?.subject || ''}`,
        selected: listSelected,
        from: profile?.CONT_EMAIL,
      }
      setList('none')
      setListId(0)
      setListSelected([])
      setSingleEmail('')
      setSingleName('')
      handle_msg('loading_msg', 'Sending Emails...', 'email')
      // console.log(msgList)
      // const emails = listSelected.map(selec => `${selec.email}, `)
      // let emailString = `mailto:?bcc=${emails}&subject=${suggestedSubject}&body=${suggested}`
      // console.log(emailString)
      await axiomFetch(
        '/services/email/subuser/email/schedule',
        'POST',
        msgList
      )
      const new_limits = await axiomFetch('/marketing/content/limits')
      saveLimits(new_limits)
      handle_msg('success_msg', 'Emails Sent', 'email', true)
      // console.log(send_texts)
    }
  }

  const modals = {
    List: (
      <PageSingleLeadList
        modalListId={listId}
        modalListName={list}
        showTeamConfig={showTeamConfig}
        setShowTeamConfig={setShowTeamConfig}
        setRefresh={setRefresh}
        smaller={true}
      />
    ),
    emailVerify: <EmailVerify />,
  }

  if (limits?.[1]?.limit_reached) {
    return <Limits />
  }
  if (!connections?.sendgrid) {
    return (
      <EmailVerify setModalType={setModalType} setShowModal={setShowModal} />
    )
  }
  console.log(
    limits?.[1]?.ot_overage + (!singleEmail ? listSelected?.length || 0 : 1)
  )
  return (
    <>
      <div>
        <AskAxiom type='email' />

        <p className='text-center font-semibold text-primary mt-3'>
          When would you like this to be sent?
        </p>
        <DateTimePicker dateChangeHandler={dateHandler} />

        <div className='mb-3 mt-3'>
          <label
            className={`${styles.label} transition-all duration-300 ${
              singleEmail && 'text-gray-300'
            }`}
          >
            Send To List
          </label>
          <select
            value={list}
            onChange={(e) => {
              //console.log(e.target.options[e.target.selectedIndex].id)
              setListId(e.target.options[e.target.selectedIndex].id)
              setList(e.target.value)
            }}
            disabled={singleEmail ? true : false}
            className={`${styles.select}`}
          >
            {/* 
                  <option>FSBO 01/03/2023</option>
                  <option>FSBO 01/02/2023</option> */}
            <option id={0}>none</option>
            {listOptions.map((option, index) => {
              return (
                <option key={`${option?.LIST_ID}-${index}`} id={option.LIST_ID}>
                  {option?.LIST_NAME}
                </option>
              )
            })}
          </select>
          {listId > 0 && (
            <div className='mt-2'>
              <button
                className='underline cursor-pointer'
                onClick={() => {
                  setModalType('List')
                  setShowTeamConfig(!showTeamConfig)
                }}
              >
                Configure {list}
              </button>
            </div>
          )}
        </div>
        <div className='text-black my-2'>Or </div>

        <div className='text-black'>
          {/* <label>
                  Name
                    <input className={styles.input}
                    value={singleName}
                    onChange={(e) => {
                      setSingleName(e.target.value)
                    }}/>
                  </label> */}
          <label>
            Send to a single email
            <input
              className={styles.input}
              value={singleEmail}
              onChange={(e) => {
                const email = e.target.value
                setSingleEmail(email)
              }}
            />
          </label>
        </div>
        {limits?.[1]?.ot_overage +
          (!singleEmail ? listSelected?.length || 0 : 1) >
        0 ? (
          <Limits />
        ) : (
          ''
        )}
        {messageSent?.type === 'email' ? (
          <div className={`${uiStyles[messageSent.status]} mb-[20px]`}>
            {messageSent.message}
          </div>
        ) : (
          <>
            <div className='flex gap-3 mt-5 justify-end'>
              <button
                className={`${styles.hover_btn} ${
                  !state?.message || (!singleEmail && listSelected?.length < 1)
                    ? styles.hover_btn_greyed_out
                    : limits?.[1]?.ot_overage +
                        (!singleEmail ? listSelected?.length || 0 : 1) >
                      0
                    ? styles.hover_btn_greyed_out
                    : ''
                }`}
                disabled={
                  !state?.message || (!singleEmail && listSelected?.length < 1)
                    ? true
                    : limits?.[1]?.ot_overage +
                        +(!singleEmail ? listSelected?.length || 0 : 1) >
                      0
                    ? true
                    : false
                }
                onClick={send_emails}
              >
                Send
              </button>
            </div>
          </>
        )}
      </div>

      {showTeamConfig && (
        <Modal
          modalName={modalType}
          width='w-[315px]'
          height='h-[500px]'
          showModal={setShowTeamConfig}
          setShowModal={setShowTeamConfig}
        >
          {modalType && modals[modalType]}
        </Modal>
      )}
    </>
  )
}
