import React, { useEffect, useState, useMemo, memo, useRef } from 'react'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import uiStyles from '../../ui/uiStyles'
import MySingleList from './MySingleList'
import SmallLoading from '../../../components/ui/SmallLoading'
import SwipeConfig from '../../../components/ui/SwipeConfig'
import { HiArrowDown } from 'react-icons/hi2'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import plus_circle from '../../../assets/images/plus_circle.svg'
import Modal from '../../Modal'
import UploadList from './UploadList'
import { keys_uppercase } from '../../../helpers'
import Email from '../AI/Email'
import Message from '../AI/Message'
import SlyDial from '../../SlyDial'
import DateRangePicker from '../../ui/DateRangePicker'
import { useLimits } from '../../../contexts/LimitsContext'
const MyLists = ({ lists, setLists }) => {
  const { limits, saveLimits } = useLimits()
  const [showFilter, setShowFilter] = useState(false)
  const [startDate, setStartDate] = useState(new Date(new Date().setDate(-7)))
  const [endDate, setEndDate] = useState(new Date())
  const navigate = useNavigate()
  const { axiomFetch } = useAxiomFetch()
  const [showModal, setShowModal] = useState(false)
  const bottomRef = useRef(null)
  const [scroll, setScroll] = useState(0)
  const scrollRef = useRef({ state: 0 })
  const [selected, setSelected] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [modalType, setModalType] = useState('')
  const [modalListId, setModalListId] = useState(null)
  const [modalListName, setModalListName] = useState('')

  const modals = {
    uploader: (
      <UploadList
        setLists={setLists}
        ref={scrollRef}
        setScroll={setScroll}
        setShowModal={setShowModal}
      />
    ),
    textMessage: (
      <Message
        //   message={aiMessage}
        modalListId={modalListId}
        modalListName={modalListName}
        showModal={showModal}
        setShowModal={setShowModal}
        onMessageSend={setShowModal}
      />
    ),
    slyDial: (
      <SlyDial
        showModal={showModal}
        setShowModal={setShowModal}
        onMessageSend={setShowModal}
      />
    ),
    email: (
      <Email
        //   message={aiMessage}
        modalListId={modalListId}
        modalListName={modalListName}
        isInModal={true}
        showModal={showModal}
        setShowModal={setShowModal}
        onMessageSend={setShowModal}
      />
    ),
  }

  useEffect(() => {
    console.log('hello')
    console.log(scrollRef?.current?.state)
    if (bottomRef.current && scrollRef?.current?.state > 0) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' })
      scrollRef.current.state = 0
    }
  }, [scroll])

  useEffect(() => {
    let controller = new AbortController()

    const Getlists = async () => {
      console.log('test')
      //   setIsLoading(true)
      let data = []

      const requestPath = showFilter
        ? `/client/listings/lists?filterByDate=${showFilter}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
        : `/client/listings/lists`
      data = await axiomFetch(
        requestPath,
        'GET',
        {},
        {},
        false,
        controller?.signal
      )
      console.log('test_2')
      console.log(data)
      if (data?.length) {
        data = data?.sort((a, b) => sort_list(a, b))
      }
      setLists(data)

      setIsLoading(false)
    }
    Getlists()

    return () => {
      controller?.abort()
    }
  }, [startDate, endDate, showFilter])

  const sort_list = (a, b) => {
    let nameA = a?.LIST_NAME?.toUpperCase()
    let nameB = b?.LIST_NAME?.toUpperCase()
    if (!nameB) return -1
    if (!nameA) return 1
    if (nameA > nameB) {
      return 1
    }
    if (nameA < nameB) {
      return -1
    }

    return 0
  }

  const counter = (arr, key) => {
    let counter = 0
    for (const obj of arr) {
      if (obj[key?.toUpperCase()] && obj.STATUS === 'A') {
        counter++
      }
    }
    return counter
  }

  const handle_save = async () => {
    let update_data = []
    for (const list of lists) {
      if (!list.updated) {
        continue
      } else {
        delete list?.updated
        update_data.push(list)
      }
    }
    console.log(update_data)
    setIsLoading(true)
    const data = await axiomFetch('/client/listings/lists', 'POST', lists)
    let refetch = await axiomFetch('/client/listings/lists')
    refetch = refetch.sort((a, b) => sort_list(a, b))
    setLists(refetch)
    setIsLoading(false)
    // if (scroll > 0) {
    // setScroll((prev) => prev + 1)
    // }
  }

  return (
    <>
      <div className='flex flex-col max-h-[550px] mb-[20px]'>
        {isLoading ? (
          <div>
            <SmallLoading />
          </div>
        ) : (
          <>
            {showFilter && (
              <>
                <p
                  className='text-center text-accent-200 pt-2 underline cursor-pointer'
                  onClick={() => setShowFilter(false)}
                >
                  Remove Date filter
                </p>
                <DateRangePicker
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                />
              </>
            )}
            {!showFilter && (
              <p
                className='text-center text-accent-200 pt-2 underline cursor-pointer'
                onClick={() => setShowFilter(true)}
              >
                Filter By date
              </p>
            )}
            <div className='flex justify-center items-center gap-[100px] mt-[10px] mb-[15px]'>
              <h1 className={`${uiStyles.text_gray}`}>
                Your Lists: <HiArrowDown className='inline' />
              </h1>
              <h1># of leads</h1>
            </div>
            <div className='flex flex-col items-center overflow-y-scroll'>
              {!lists.length ? (
                <></>
              ) : (
                lists?.map((list, index) => {
                  list.index = index
                  if (index % 2) {
                    list.bg = 'bg-white'
                  } else {
                    list.bg = 'bg-gray-100'
                  }
                  return (
                    <MySingleList
                      {...(showFilter && {
                        filterStartDate: startDate,
                        filterEndDate: endDate,
                      })}
                      key={`${list.LIST_ID}-${index}`}
                      setModalListId={setModalListId}
                      setModalListName={setModalListName}
                      setShowModal={setShowModal}
                      setModalType={setModalType}
                      selected={selected}
                      setSelected={setSelected}
                      ref={index === lists.length - 1 ? bottomRef : null}
                      list={list}
                      handle_save={handle_save}
                    />
                  )
                })
              )}
            </div>
            <div className='p-[20px]'>
              {!isLoading && (
                <div className='flex justify-center items-center'>
                  <div
                    className='flex flex-col items-center justify-center w-full cursor-pointer'
                    onClick={() => {
                      setModalType('uploader')
                      setShowModal(true)
                    }}
                  >
                    <label className='text-[10px]'>Upload New List</label>
                    <img src={plus_circle} />
                  </div>
                  <div className='flex justify-end gap-3'>
                    <button
                      className={uiStyles.hover_btn}
                      onClick={async () => {
                        scrollRef.current.state++
                        setIsLoading(true)
                        let data = await axiomFetch(
                          '/client/listings/lists',
                          'POST',
                          [{}]
                        )
                        setIsLoading(false)
                        data = keys_uppercase(data)
                        setLists([...lists, data[0]])
                        setScroll(scroll + 1)
                      }}
                    >
                      ADD
                    </button>
                    <button
                      className={uiStyles.hover_btn}
                      onClick={handle_save}
                    >
                      Save
                    </button>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {showModal && (
        <Modal
          modalName={modalType}
          height={'h-[550px]'}
          showModal={showModal}
          setShowModal={setShowModal}
        >
          {modalType && modals[modalType]}
        </Modal>
      )}
    </>
  )
}

export default MyLists
