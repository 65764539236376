import { useState } from 'react'
import { useRef } from 'react'
import { replaceWithBr } from '../../../helpers'

const AccordionItem = ({ item, count }) => {
  const [clicked, setClicked] = useState(false)
  const contentEl = useRef()

  const { SUBJECT: title, CONTENT: content, INS_DATE: date } = item

  const handleToggle = () => {
    setClicked((prev) => !prev)
  }

  return (
    <li className={`border-b border-gray-200 pb-5 ${clicked ? 'active' : ''}`}>
      <div
        className='flex justify-between cursor-pointer'
        onClick={handleToggle}
      >
        <div className='font-semibold text-primary text-lg flex-1'>{title}</div>
        <div className='flex items-center'>
          <span>{date && new Date(date).toLocaleDateString('en-US')}</span>
          <span className=' ml-3 leading-3 text-gray-400 h-5 w-5 inline-block  text-center   border-2 border-gray-400 rounded-full'>
            {clicked ? '—' : '+'}
          </span>
        </div>
      </div>

      <div
        ref={contentEl}
        className={clicked ? 'h-auto overflow-hidden' : 'h-0 overflow-hidden'}
      >
        <div>
          {/* <div className="font-semibold  text-base">{title && title}</div> */}
          <div
            className='content-accordion'
            dangerouslySetInnerHTML={{
              __html: replaceWithBr(content ? content : ''),
            }}
          />
        </div>
      </div>
    </li>
  )
}

export default AccordionItem
