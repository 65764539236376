import React from 'react'

const SmallLoading = ({ styles }) => {
  return (
    <div
      className={`absolute pointer-events-none flex items-center justify-center space-x-2 left-0 top-0 w-full h-full right-0 bottom-0 `}
    >
      <span className={`flex h-4 w-4 relative ${styles}`}>
        <span className='animate-ping absolute inline-flex h-full w-full rounded-full bg-gradient-to-b from-accent-150 to-accent-400 opacity-75'></span>
        <span className='animate-ping absolute inline-flex h-full w-full rounded-full bg-gradient-to-b from-accent-150 to-accent-400 opacity-75'></span>
      </span>
    </div>
  )
}

export default SmallLoading
