import React from 'react'
import { HiArrowLeft } from 'react-icons/hi2'
import payment_success from '../../../assets/images/payment_icons/payment_successful.svg'
import uiStyles from '../../ui/uiStyles'
import { ProfileHeader } from './ProfileHeader'
export const PaySuccessful = ({ setShowModal }) => {
  return (
    <div className='mt-[10px]'>
      <ProfileHeader />
      <div className='flex flex-col gap-[10px] items-center mt-[20px]'>
        <img src={payment_success} className={'w-[80px]'} />
        <p className={`${uiStyles.text_black_title} text-center`}>
          Payment successfully completed
        </p>

        <div
          className='mt-[10px] flex gap-[10px] text-[#007AFF] items-center cursor-pointer'
          onClick={() => {
            if (setShowModal) {
              setShowModal(false)
            }
          }}
        >
          <HiArrowLeft />
          Done
        </div>
      </div>
    </div>
  )
}
