import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import images_logos from '../../../assets/images/axiom_logos'
import { useClaims } from '../../../contexts/ClaimsContext'
import { useListingTours } from '../../../contexts/ListingToursContext'
import Modal from '../../Modal'
import ListItem from '../../ui/ListItem'
import Navigation from './Navigation'

const StartTour = ({ state, setState }) => {
  const navigate = useNavigate()
  const { get_event_type_id, get_event_tour_type_id } = useListingTours()
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const modals = {
    tour_reviews: (
      <img
        src={
          'https://driggstitle.com/reviews/Andrew_Judd_3/dbc/b75f5362ebcd0e9566a4df2b517d7563'
        }
      />
    ),
  }
  const { claims } = useClaims()
  const get_page = () => {
    const info_list = state?.data?.tour_opening_dta?.filter(
      (item) =>
        item?.tour_page_id === (state?.tour_state?.current_page || 1) &&
        get_event_tour_type_id(state?.tour_state?.event_id) ===
          item?.tour_type_id
    )
    const info = {
      ...state?.data?.tour_types?.find(
        (item) =>
          item?.type_id === get_event_tour_type_id(state?.tour_state?.event_id)
      ),
      tour_name: state?.data?.event_pages?.find(
        (item) => item?.event_id === state?.tour_state?.event_id
      )?.tour_name,
      page_name: state?.data?.tour_types_pages?.find(
        (item) => item?.page_id === (state?.tour_state?.current_page || 1)
      )?.page_name,
    }
    return { info, info_list }
  }
  const page = get_page()
  console.log('page', page)
  return (
    <div>
      <div className='flex flex-col items-center'>
        <img
          className='w-[200px] my-[10px]'
          src={
            page?.info?.company_logo ||
            state?.data?.tour_types?.find((item) => item?.type_id === 1)
              ?.company_logo
          }
          alt='Company Logo'
        />
        <img
          onClick={() => {
            if (claims?.user_id > 0) {
              navigate('/user/listing_tours/add')
            }
          }}
          className={`w-[200px] ml-[10px] ${
            claims?.user_id > 0 ? 'cursor-pointer' : ''
          }`}
          src={
            page?.info?.van_photo ||
            'https://driggstitle.com/reviews/Andrew_Judd_3/dbc/e23bc1d76934f3c77b2f2be7171a8c1a'
          }
          alt='Company Van'
        />
        <div className='my-[15px]'>
          <p>
            Welcome to the{' '}
            <span className='font-bold'>
              {page?.info?.tour_name?.replace('tour', '')?.replace('Tour', '')}
            </span>{' '}
            tour!
          </p>
        </div>
        <div className='mb-[10px]'>
          <p className='text-[20px]'>{page?.info?.page_name}</p>
        </div>
      </div>
      <div className='max-h-[400px] overflow-y-scroll'>
        {page?.info_list?.map((item) => {
          console.log(item?.item_heading)
          return (
            <ListItem
              key={item?.data_id}
              image={item?.item_img_link || images_logos.axiom_small}
              title={item?.item_heading}
              subtitle={item?.item_text}
              click_me={item?.data_id === 37}
              select_on_image={item?.data_id === 37}
              select={() => {
                if (item?.data_id === 37) {
                  setModalType('tour_reviews')
                  setShowModal(true)
                }
              }}
            />
          )
        })}
      </div>
      <Navigation state={state} setState={setState} />
      {showModal ? (
        <Modal
          modalName={'tour_reviews'}
          setShowModal={setShowModal}
          showModal={showModal}
          showClose={true}
        >
          {modals[modalType]}
        </Modal>
      ) : (
        ''
      )}
    </div>
  )
}

export default StartTour
