import React from 'react'

const PairwiseK1s = () => {
  return (
    <div className='px-3'>
      Your K1's will be here when we have them ready for you.
    </div>
  )
}

export default PairwiseK1s
