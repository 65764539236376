import React from 'react'
import Wrapper from '../../components/layout/Wrapper'
// import MarketingMaterial from '../../components/pages/marketingMaterials/MarketingMaterial'
import { MarketingMaterialHome } from '../../components/pages/marketingMaterials/MarketingMaterialHome'
import SubNav from '../../components/SubNav'
import { useMktReqs } from '../../contexts/MktReqsContext'
export default function PageMarketingMaterial() {
  const { mktReqs, saveMktReqs } = useMktReqs()
  return (
    <>
      <Wrapper>
        <SubNav url={mktReqs?.home_url || '/user/marketing-lobby'} />
        <MarketingMaterialHome
          state={mktReqs}
          setState={saveMktReqs}
          title={'Marketing Materials'}
        />
        {/* <MarketingMaterial /> */}
      </Wrapper>
    </>
  )
}
