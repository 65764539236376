import { useState } from 'react'
import { IoIosPhonePortrait } from 'react-icons/io'
import { IoBaseballOutline, IoBaseball } from 'react-icons/io5'

import { AiOutlineMail } from 'react-icons/ai'
import { formatPhoneNumber, set_storage, validate_number } from '../../helpers'
import { BsChatText } from 'react-icons/bs'
import uiStyles from '../ui/uiStyles'
import { useNavigate } from 'react-router-dom'
import { useSales } from '../../contexts/SalesContext'
import UserThumb from '../../assets/images/profile-silhouette.svg'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import Modal from '../Modal'
import { SmallLoadingRel } from '../ui/SmallLoadingRel'

const SalesItem = ({ item, type, setShowModal, setModalType }) => {
  const navigate = useNavigate()
  const { axiomFetch } = useAxiomFetch()
  const { sales, saveSales } = useSales()

  const [loading, setLoading] = useState({})
  const get_bat_num = (key) => {
    const bases = {
      at_bat: 1,
      ['1st:_commitment_for_an_appointment']: 2,
      ['2nd:_commitment_to_engage']: 3,
      ['3rd:_they_did_engage']: 4,
      ['home:_commitment_for_name_on_deal']: 5,
    }

    return bases[key?.toLowerCase()?.split(' ')?.join('_')]
  }

  return (
    <div
      key={item?.cli_id || item?.cont_id}
      className='shadow-xl p-2'
      style={{
        border: `solid ${item?.color_cont_code} 4px`,
        borderLeft: `none`,
        borderRight: `none`,
        // borderLeft: `solid ${item?.color_score_code} 4px`,
        // borderRight: `solid ${item?.color_score_code} 4px`,
      }}
    >
      <div className='flex gap-[10px]'>
        <img
          className='w-[50px] h-[50px] object-center object-cover rounded-full'
          src={item?.cont_photo || UserThumb}
        />
        <div>
          <p>
            <span className='font-bold'>{item?.cont_name}</span>{' '}
            <span>{`(${item?.lead_description})`}</span>
          </p>
          <p>{item?.client_company}</p>
        </div>
      </div>

      <div className='flex justify-between'>
        <div className='flex flex-col'>
          <div className='flex'>
            <img
              className='w-[50px] h-[50px]'
              src={`https://rightdata.driggstitle.com/sales_machine/a_Baseball_icon-${get_bat_num(
                item?.base_description
              )}.png`}
            />
            <div>
              <div className='flex items-center'>
                {/* <div
                  className='flex ml-[10px] w-[25px] h-[25px] rounded-full'
                  style={{
                    backgroundColor: item?.color_score_code,
                  }}
                ></div> */}
                <div></div>
                <IoBaseball
                  size={
                    item?.color_score?.toLowerCase()?.trim() === 'cool'
                      ? 20
                      : 23
                  }
                  className='rounded-full'
                  style={{
                    backgroundColor:
                      item?.color_score?.toLowerCase()?.trim() === 'cool'
                        ? 'gray'
                        : '',
                  }}
                  color={item?.color_score_code}
                />
                <p className='pl-[3px]'>{item?.color_score}</p>
              </div>
            </div>
            <div className='flex flex-col gap-[10px] ml-[10px]'>
              <div className='flex items-center'>
                {item?.cont_cell_num || item?.best_contact_phone ? (
                  <div className={'flex gap-[10px]'}>
                    <a
                      href={`tel:${
                        item?.cont_cell_num || item?.best_contact_phone
                      }`}
                      target='_blank'
                    >
                      <IoIosPhonePortrait size={25} />
                    </a>
                    <a
                      href={`sms://${
                        item?.cont_cell_num || item?.best_contact_phone
                      }&body=`}
                      target='_blank'
                    >
                      <BsChatText size={20} />
                    </a>
                    <p className='max-w-[150px] overflow-scroll'>
                      {formatPhoneNumber(
                        item?.cont_cell_num || item?.best_contact_phone
                      )}
                    </p>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div>
                {item?.cont_email ? (
                  <div className='flex ml-[6px] gap-[10px]'>
                    <a href={`mailto:?to=${item?.cont_email}`} target='_blank'>
                      <AiOutlineMail size={20} />
                    </a>
                    <p className='max-w-[150px] overflow-scroll .scrollbar-hide'>
                      {item?.cont_email}
                    </p>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          <div className='font-bold'>{item?.base_description}</div>
        </div>
        <div className='flex'></div>
      </div>
      <p>
        <span className='font-bold pr-[2px]'>Current Listings: </span>
        {item?.current_listings}
      </p>
      <div className='flex justify-between'>
        <p>
          <span className='font-bold pr-[2px]'>EO:</span>
          {item?.eo_name}
        </p>
        {loading?.[item?.cont_id] ? (
          <SmallLoadingRel />
        ) : (
          <div
            className={`${uiStyles.hover_btn_small}`}
            onClick={async () => {
              const clear_item = () => {
                const position = {
                  open_house: 'open_house_listings',
                  ['<_6_DOM']: 'just_listings',
                  ['>_59_DOM']: 'old_listings',
                }
                return saveSales((prev) => ({
                  ...prev,
                  on_deck: {
                    ...prev?.on_deck,
                    data: {
                      ...prev?.on_deck?.data,
                      [position?.[prev?.on_deck?.selected]]:
                        prev?.on_deck?.data?.[
                          position?.[prev?.on_deck?.selected]
                        ]?.filter(
                          (listings) => listings?.cont_id !== item?.cont_id
                        ),
                    },
                  },
                }))
              }
              let can_access_result
              if (type === 'on_deck') {
                setLoading((prev) => ({ ...prev, [item?.cont_id]: true }))
                can_access_result = await axiomFetch(
                  `/sales/what_next/on_deck/can_access?cli_cont_id=${item?.cont_id}`,
                  'GET'
                )
                setLoading((prev) => ({ ...prev, [item?.cont_id]: false }))
                if (!can_access_result?.can_access) {
                  clear_item()
                  setModalType('no_access')
                  return setShowModal(true)
                } else if (can_access_result?.assigned_no_call_cnt) {
                  setModalType('assigned_no_call')
                  return setShowModal(true)
                }
                clear_item()
                setTimeout(async () => {
                  await axiomFetch('/sales/what_next/on_deck/assign', 'POST', {
                    cli_cont_id: item?.cont_id,
                    total_score: validate_number(item?.total_score),
                  })
                }, [0])
              }

              saveSales((prev) => {
                if (type === 'on_deck') {
                  if (!validate_number(item?.total_score)) {
                    item.total_score = 10
                    if (!validate_number(item?.mkt_lead_score))
                      item.mkt_lead_score = 10
                  }
                }
                const sales = {
                  ...prev,
                  curr_client: {
                    data: item,
                    selected: 'baseline',
                  },
                }
                set_storage('sales', sales)
                return sales
              })
              navigate('/user/sales/what_next/client')
            }}
          >
            What Next
          </div>
        )}
      </div>
      {/* <p>
        <span className='font-bold pr-[2px]'>Rep:</span>
        {item?.rep_name}
      </p> */}
      <div className='flex items-center'>
        {/* <p>{item?.color_score}: </p> */}
      </div>
      {type === 'on_deck' ? (
        <div className=' mt-[10px]'>
          <div className={`${uiStyles.line}`}></div>
          <p className=''>Client Found by:</p>
          <div className='flex gap-[10px]'>
            <p>MLS #: {item?.listing?.mls_id}</p>
            <p>DOM: {item?.listing?.days_on_market}</p>
            <p>
              Open House: {item?.listing?.upcoming_open_house ? 'Yes' : 'No'}
            </p>
          </div>
        </div>
      ) : (
        <div className='mt-[10px]'>
          <p></p>
          {item?.reasons_str
            ?.split('</br>')
            ?.filter((item) => item)
            ?.map((reason, index) => {
              return (
                <div key={item.cont_id + reason} className='text-xs'>
                  <p
                    className={`${
                      reason.includes('Escrow') ? '' : 'whitespace-nowrap'
                    } overflow-scroll`}
                  >
                    <span className='font-bold'>{index + 1}.</span> {reason}
                  </p>
                </div>
              )
            })}
        </div>
      )}
    </div>
  )
}

export default SalesItem
