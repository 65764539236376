import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { set_storage } from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import uiStyles from '../../ui/uiStyles'

const RemoveListing = ({
  state,
  setState,
  setShowModal,
  setItineraryModal,
  listing,
}) => {
  const [loading, setLoading] = useState(false)
  const { axiomFetch } = useAxiomFetch()
  const navigate = useNavigate()
  console.log(listing)
  return (
    <div>
      <p>Are you sure you want to remove this listing?</p>
      {!loading ? (
        <div className='flex justify-center gap-[10px]'>
          <button
            onClick={() => {
              setShowModal(false)
            }}
            className={uiStyles.white_btn}
          >
            Cancel
          </button>
          <button
            onClick={async () => {
              setLoading(true)
              await axiomFetch('/tours/remove', 'POST', {
                event_id: state?.tour_state?.event_id,
                mls_id: listing?.list_id,
              })
              setLoading(false)
              setState((prev) => {
                const new_data = {
                  ...prev?.data,
                  //   mls_listing_v: prev?.data?.mls_listing_v?.filter(
                  //     (item) =>
                  //       item?.mls_id?.toString() !== listing?.list_id?.toString()
                  //   ),
                  event_signup: prev?.data?.event_signup?.filter(
                    (item) =>
                      item?.list_id?.toString() !==
                        listing?.list_id?.toString() &&
                      item?.event_id === listing?.event_id
                  ),
                  event_tour_info: prev?.data?.event_tour_info?.filter(
                    (item) =>
                      item?.mls_num?.toString() !==
                        listing?.list_id?.toString() &&
                      item?.evt_id === listing?.event_id
                  ),
                }
                set_storage('listing_tours', new_data)
                return {
                  ...prev,
                  data: new_data,
                }
              })
              setShowModal(false)
            }}
            className={uiStyles.red_btn}
          >
            Remove
          </button>
        </div>
      ) : (
        <SmallLoadingRel />
      )}
    </div>
  )
}

export default RemoveListing
