import React from 'react'
import Wrapper from '../../components/layout/Wrapper'
import { AiDistribute } from '../../components/pages/AiSlots'
import SubNav from '../../components/SubNav'

export default function PageAiDistribute() {
  return (
    <Wrapper>
      <SubNav />
      <AiDistribute />
    </Wrapper>
  )
}
