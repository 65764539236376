import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useReceiver } from '../../contexts/ReceiverContext'
export const VbcLayout = () => {
  const { receiver, saveReceiver } = useReceiver()

  console.log(receiver)
  useEffect(() => {
    saveReceiver((prev) => ({
      ...prev,
      show_map: localStorage?.getItem('show_map') || 'N',
    }))
  }, [])

  return (
    <div className=' font-base text-sm text-gray-500 overflow-hidden rounded-2xl max-w-[375px] mx-auto bg-user-wrapper relative '>
      <div className='p-[10px]'>
        <Outlet />
      </div>
    </div>
  )
}
