import { useRef, useState, useEffect } from 'react'
import uiStyles from '../../ui/uiStyles'
import { useAuth0 } from '@auth0/auth0-react'
import { useProfile } from '../../../contexts/UserContext'
import Modal from '../../Modal'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import SmallLoading from '../../ui/SmallLoading'

export const AddCardLightbox = ({ setRefresh }) => {
  const { getAccessTokenSilently } = useAuth0()
  const { profile: contProfile } = useProfile()
  const lightbox_checker = useRef()
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const initiate_lightbox = async () => {
    lightbox_checker.current = setInterval(() => {
      console.log('hello')
      const completed = localStorage.getItem('lightbox_complete')
      if (completed) {
        if (setRefresh) {
          setRefresh((prev) => prev + 1)
        }
        clearInterval(lightbox_checker.current)
        localStorage.removeItem('lightbox_complete')
        setShowModal(false)
      }
    }, 1000)
    const token = await getAccessTokenSilently().catch((err) => null)
    window.localStorage.setItem('token', token)
    window.localStorage.setItem(
      'first_name',
      contProfile?.CONT_NAME?.trim()?.split(' ')?.[0]
    )
    window.localStorage.setItem(
      'last_name',
      contProfile?.CONT_NAME?.trim()?.split(' ').length > 1
        ? contProfile?.CONT_NAME?.trim()?.split(' ')?.[
            contProfile?.CONT_NAME?.trim()?.split(' ').length - 1
          ]
        : ''
    )
    // window.localStorage.setItem('description', contProfile?.CONT_ID)
  }

  useEffect(() => {
    setLoading(true)
  }, [])

  const modals = {
    add_card: (
      <div className='relative'>
        {loading ? <SmallLoadingRel /> : ''}
        <iframe
          onLoad={(e) => {
            setTimeout(() => {
              setLoading(false)
            }, [4000])
          }}
          className='m-0 p-0 border-0 w-full h-[470px]'
          title='lightbox'
          // scrolling='no'
          src={`${process.env.PUBLIC_URL}/api/v1/lightbox`}
        ></iframe>
      </div>
    ),
  }
  return (
    <>
      <div className={`${uiStyles.line} mt-[10px] pb-[10px]`}></div>
      <div
        className='flex gap-[5px] items-center mb-[10px] cursor-pointer'
        onClick={async () => {
          await initiate_lightbox()
          setModalType('add_card')
          setShowModal(true)
        }}
      >
        <svg
          width='14'
          height='14'
          viewBox='0 0 14 14'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M7.00008 1.16602V12.8327M1.16675 6.99935H12.8334'
            stroke='url(#paint0_linear_1841_11824)'
            strokeWidth='1.66667'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <defs>
            <linearGradient
              id='paint0_linear_1841_11824'
              x1='-2.60169'
              y1='4.93445'
              x2='4.93518'
              y2='16.6011'
              gradientUnits='userSpaceOnUse'
            >
              <stop stopColor='#00CFDE' />
              <stop offset='1' stopColor='#0063f7' />
            </linearGradient>
          </defs>
        </svg>
        <p className={uiStyles.blue_grd_text}>Add new payment method</p>
      </div>
      {showModal ? (
        <Modal
          showModal={showModal}
          modalName={modalType}
          setShowModal={setShowModal}
          height={modalType === 'add_card' ? 'h-[550px]' : 'h-[100px]'}
          width={'w-[345px]'}
          clear_interval={lightbox_checker.current}
        >
          {modals[modalType]}
        </Modal>
      ) : (
        ''
      )}
      <div className={`${uiStyles.line} mt-[10px] pb-[10px]`}></div>
    </>
  )
}
