import { useEffect, useRef, useState } from 'react'
import ch_images from '../../../assets/images/conversations'
import { set_storage } from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { CircleCheckToggle } from '../../form/CircleCheckToggle'
import LabelInput from '../../form/LabelInput'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import uiStyles from '../../ui/uiStyles'

const AddMarket = ({
  state,
  setState,
  page,
  setPage,
  setShowModal,
  market_name,
}) => {
  const [inputValue, setInputValue] = useState(state?.market_edit || {})
  const [loading, setLoading] = useState(false)
  const [aiLoading, setAiLoading] = useState(false)
  const { axiomFetch } = useAxiomFetch()
  const startRef = useRef(null)
  useEffect(() => {
    startRef.current.focus()
  }, [])
  const handleChange = (e) => {
    let { name, value } = e.target
    // if (name === 'cell') {
    //   value = value?.replace(/\D/g, '')?.slice(0, 10)?.toString() || ''
    // }
    console.log(name, value)

    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const types = [
    { name: 'general', key: 'general' },
    { name: 'services', key: 'services' },
    { name: 'listings', key: 'listings' },
    { name: 'events', key: 'events' },
  ]
  useEffect(() => {
    const inputs = types?.reduce((curr, val) => {
      console.log(state?.market_edit?.type)
      if (state?.market_edit?.type?.includes(val.key)) {
        curr[`type_${val.key}`] = 'Y'
      } else {
        curr[`type_${val.key}`] = 'N'
      }
      return curr
    }, {})
    console.log('inputs', inputs)
    setInputValue((prev) => ({ ...prev, ...inputs }))
  }, [])

  const save_provider = async () => {
    const market_types_str = types
      ?.filter((item) => inputValue[`type_${item?.key}`] === 'Y')
      ?.map((item) => item?.key)
      ?.join(',')
      ?.trim()
    const data = {
      ...Object.assign(
        {},
        ...Object?.keys(inputValue)
          ?.filter((key) => !key.includes('type'))
          .map((key) => {
            return { [key]: inputValue[key] }
          })
      ),
      type: market_types_str,
    }
    setLoading(true)
    const result = await axiomFetch('/products/vbc/markets', 'POST', data)
    setState((prev) => {
      const the_state = {
        ...prev,
        markets: state?.market_edit?.default_uuid
          ? [
              ...prev?.markets?.filter(
                (item) => item.default_uuid !== state?.market_edit?.default_uuid
              ),
              result?.data,
            ]
          : [...prev?.markets, result?.data || {}],
      }
      set_storage('vbcs', the_state)
      return the_state
    })

    setLoading(false)
    setShowModal(false)
  }
  const get_ai_zips = async () => {
    setAiLoading(true)
    const body = {
      messages: [
        {
          role: 'user',
          content: `generate only a comma separated string with no spaces for all the zips for ${
            inputValue.city
          }, ${inputValue?.state || 'AZ'}`,
        },
      ],
      model: 'gpt-4o',
    }
    console.log('chat', body)
    const zips = await axiomFetch(
      '/marketing/content/ai/chat',
      'POST',
      body,
      {},
      false
    )
    setInputValue((prev) => ({ ...prev, zip: zips?.post }))
    setAiLoading(false)
  }

  return (
    <div>
      <h1 className='mb-[5px]'>Add {`${market_name} `} provider</h1>
      <div className='overflow-y-scroll h-[330px]'>
        <LabelInput
          ref={startRef}
          left_label={'Name*'}
          placeholder='Prescott'
          name={'location_name'}
          value={inputValue?.location_name || ''}
          handleChange={handleChange}
        />
        <div>
          <p>Market Types:</p>
          <div className='flex flex-wrap mb-[10px]'>
            {types?.map((item, index) => {
              return (
                <div className='flex'>
                  <p>{`${item.name}`}</p>
                  <CircleCheckToggle
                    name={`type_${item?.key}`}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    handle_change={async (e) => {
                      console.log(e.target.value)
                      setInputValue((prev) => {
                        const return_data = {
                          ...prev,
                          [`type_${item.key}`]:
                            prev?.[`type_${item.key}`] === 'Y' ? 'N' : 'Y',
                        }
                        return return_data
                      })
                    }}
                  />
                </div>
              )
            })}
          </div>
        </div>
        <LabelInput
          left_label={'city'}
          placeholder='Prescott'
          name={'city'}
          value={inputValue?.city || ''}
          handleChange={handleChange}
        />
        <LabelInput
          left_label={'state'}
          placeholder='AZ'
          name={'state'}
          value={inputValue?.state || ''}
          handleChange={handleChange}
        />
        {/* <LabelInput
          left_label={'logo url'}
          placeholder='https://photos.com/photos/logo.png'
          name={'logo'}
          value={inputValue?.logo || ''}
          handleChange={handleChange}
        /> */}
        <LabelInput
          left_label={
            <div className='flex gap-[10px] items-center'>
              zips{' '}
              {!aiLoading ? (
                <img
                  onClick={get_ai_zips}
                  className={'w-[20px] cursor-pointer'}
                  src={ch_images.robot_icon}
                />
              ) : (
                <SmallLoadingRel />
              )}
            </div>
          }
          placeholder={'86301,86303,86304'}
          name={'zip'}
          value={inputValue?.zip || ''}
          handleChange={handleChange}
        />
      </div>
      <div className={`flex justify-end gap-[10px] mt-[35px] items-center`}>
        <button
          className={`${uiStyles.hover_btn}`}
          onClick={() => {
            setShowModal(false)
          }}
        >
          Cancel
        </button>
        {!loading ? (
          <button className={`${uiStyles.hover_btn}`} onClick={save_provider}>
            Save
          </button>
        ) : (
          <div className={`${uiStyles.loading_msg} min-w-0 w-[150px]`}>
            Loading...
          </div>
        )}
      </div>
    </div>
  )
}

export default AddMarket
