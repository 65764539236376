import { useEffect, useRef, useState } from 'react'
import ch_images from '../../../assets/images/conversations'
import {
  formatPhoneNumber,
  getCurrentTime,
  getDateString,
  set_storage,
} from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { CircleCheckToggle } from '../../form/CircleCheckToggle'
import LabelInput from '../../form/LabelInput'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import uiStyles from '../../ui/uiStyles'

const EditBuyer = ({
  state,
  setState,
  page,
  setPage,
  setShowModal,
  market_name,
  type,
}) => {
  console.log('create_state', state)
  const [inputValue, setInputValue] = useState(
    state?.buyer_tours?.buyer_edit || {}
  )
  const [loading, setLoading] = useState(false)
  const [aiLoading, setAiLoading] = useState(false)
  const { axiomFetch } = useAxiomFetch()
  const startRef = useRef(null)
  useEffect(() => {
    startRef.current.focus()
  }, [])
  const handleChange = (e) => {
    let { name, value } = e.target
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
      evt_id: state?.buyer_tours?.event_edit?.event_id,
    }))
  }

  const save_event = async () => {
    const data = {
      ...inputValue,
      agent_cell_phone:
        inputValue.agent_cell_phone
          ?.replace(/\D/g, '')
          ?.slice(0, 10)
          ?.toString() || '',
      cli_type_id: state?.buyer_tours?.event_type_id === 163 ? 2 : 180,
    }
    console.log(data)
    setLoading(true)
    console.log(
      'test',
      (state?.data?.event_tour_info || [])?.find(
        (item) =>
          item?.evt_id === data?.evt_id &&
          item?.agent_cell_phone?.toString() ===
            data?.agent_cell_phone?.toString()
      ) && state?.buyer_tours?.buyer_edit?.new_buyer
    )
    if (
      (state?.data?.event_tour_info || [])?.find(
        (item) =>
          item?.evt_id === data?.evt_id &&
          item?.agent_cell_phone?.toString() ===
            data?.agent_cell_phone?.toString()
      ) &&
      state?.buyer_tours?.buyer_edit?.new_buyer
    ) {
      setLoading(false)
      return setShowModal(false)
    }
    const result = await axiomFetch('/tours/client/add', 'POST', data)
    console.log('result', result)
    setState((prev) => {
      const event_tour_info = prev?.buyer_tours?.buyer_edit?.evt_id
        ? [
            ...(prev?.data?.event_tour_info || [])?.filter(
              (item) =>
                !(
                  item?.evt_id === prev?.buyer_tours?.buyer_edit?.evt_id &&
                  item?.cont_id === prev?.buyer_tours?.buyer_edit?.cont_id
                )
            ),
            result,
          ]
        : [...prev?.data?.event_tour_info, result || {}]

      set_storage('listing_tours', { ...prev?.data, event_tour_info })
      return {
        ...prev,
        data: { ...prev?.data, event_tour_info },
      }
    })

    setLoading(false)
    setShowModal(false)
  }

  return (
    <div>
      <h1 className='mb-[5px]'>Create Buyer Tour</h1>
      <div className='overflow-y-scroll max-h-[330px] min-h-[175px]'>
        <LabelInput
          ref={startRef}
          left_label={'Name'}
          placeholder='John Doe'
          name={'agent_name'}
          value={inputValue?.agent_name || ''}
          handleChange={handleChange}
        />
        <LabelInput
          left_label={'Phone'}
          text_size='text-sm'
          type='text'
          placeholder='555123456'
          name={'agent_cell_phone'}
          value={formatPhoneNumber(inputValue?.agent_cell_phone)}
          handleChange={handleChange}
        />
      </div>
      <div className={`flex justify-end gap-[10px] items-center`}>
        <button
          className={`${uiStyles.white_btn}`}
          onClick={() => {
            setShowModal(false)
          }}
        >
          Cancel
        </button>
        {!loading ? (
          <button className={`${uiStyles.hover_btn}`} onClick={save_event}>
            Save
          </button>
        ) : (
          <div className={`${uiStyles.loading_msg} min-w-0 w-[150px]`}>
            Loading...
          </div>
        )}
      </div>
    </div>
  )
}

export default EditBuyer
