import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useListingTours } from '../../../contexts/ListingToursContext'
import { useProfile } from '../../../contexts/UserContext'
import { format_currency } from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import ListItem from '../../ui/ListItem'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import SwipeConfig from '../../ui/SwipeConfig'
import uiStyles from '../../ui/uiStyles'
import ListingPhotos from './ListingPhotos'
import Navigation from './Navigation'
import ThumbRating from './ThumbRating'

const Products = ({ state, setState }) => {
  const { get_event_tour_type_id } = useListingTours()
  const [page, setPage] = useState({})
  const { open_url } = useAxiomFetch()
  const { profile } = useProfile()
  const [selected, setSelected] = useState(1)
  const navigate = useNavigate()
  const { axiomFetch } = useAxiomFetch()
  useEffect(() => {
    const get_page = () => {
      const tour_type_id = get_event_tour_type_id(state?.tour_state?.event_id)
      const info = {
        page_name: state?.data?.tour_types_pages?.find(
          (item) => item?.page_id === (state?.tour_state?.current_page || 4)
        )?.page_name,
      }
      const info_list =
        tour_type_id > 1
          ? state?.data?.event_tour_ann?.filter(
              (item) => item?.tour_type_id === tour_type_id
            ) || []
          : state?.data?.tour_opening_dta?.filter(
              (item) =>
                item?.tour_page_id === 4 && item.tour_type_id === tour_type_id
            ) || []
      const current_listing = state?.data?.event_tour_info?.filter(
        (item) => item?.cont_id === profile?.CONT_ID && item?.mls_num
      )[0]
      setPage({ info, info_list, current_listing, tour_type_id })
    }

    get_page()
    return () => {
      get_page()
    }
  }, [state?.data])

  useEffect(() => {
    const controller = new AbortController()
    const get_data = async () => {
      setPage((prev) => ({ ...prev, loading_photos: true }))
      const mls_id = state?.data?.event_tour_info?.filter(
        (item) => item?.cont_id === profile?.CONT_ID && item?.mls_num
      )?.[0]?.mls_num
      const armls_id = state?.data?.mls_listings_v?.find(
        (item) => mls_id === item?.mls_id?.toString()
      )?.armls_id
      if (armls_id) {
        const photos = []
        // await axiomFetch(
        //   `/tours/photos/${armls_id}`,
        //   'GET',
        //   {},
        //   {},
        //   false,
        //   controller.signal
        // )
        setPage((prev) => ({
          ...prev,
          loading_photos: false,
          photos: photos?.armls_photos || [],
        }))
      }
    }
    get_data()

    return () => {
      controller?.abort()
    }
    // eslint-disable-next-line
  }, [state?.data, state?.tour_state])

  return (
    <div>
      <div>
        {page?.tour_type_id > 1 ? (
          <div className='flex flex-col items-center'>
            <p className='text-[20px] font-bold mb-[10px]'>
              {page?.info_list?.[(state?.tour_state?.product_page || 1) - 1]
                ?.ann_title || ''}
            </p>
            <p className='px-3 text-center mb-3'>
              {page?.info_list?.[(state?.tour_state?.product_page || 1) - 1]
                ?.ann_desc || ''}
            </p>
            {page?.info_list?.[(state?.tour_state?.product_page || 1) - 1]
              ?.ann_url ? (
              <div className='flex flex-col items-center'>
                {page?.info_list?.[
                  (state?.tour_state?.product_page || 1) - 1
                ]?.ann_url?.includes('.pdf') ? (
                  <div>
                    <iframe
                      src={
                        page?.info_list?.[
                          (state?.tour_state?.product_page || 1) - 1
                        ]?.ann_url
                      }
                      title={
                        page?.info_list?.[
                          (state?.tour_state?.product_page || 1) - 1
                        ]?.ann_url
                      }
                    ></iframe>
                  </div>
                ) : (
                  <img
                    src={
                      page?.info_list?.[
                        (state?.tour_state?.product_page || 1) - 1
                      ]?.ann_url
                    }
                  />
                )}
                <button
                  className={`${uiStyles.hover_btn_small} mt-[5px]`}
                  onClick={() => {
                    open_url(
                      page?.info_list?.[
                        (state?.tour_state?.product_page || 1) - 1
                      ]?.ann_url
                    )
                  }}
                >
                  View Full image
                </button>
              </div>
            ) : (
              ''
            )}
          </div>
        ) : (
          <div className=''>
            <div className='flex flex-col items-center'>
              <p className='text-[20px] font-bold mb-[10px]'>
                {`${'Property Pro Tool' || page?.info?.page_name} #${
                  state?.tour_state?.product_page || 1
                }` || 'Product Info'}
              </p>
            </div>
            {/* {page?.current_listing?.mls_num ? (
        <div>
          {page?.loading_photos ? (
            <div className='min-h-[266px]'>
              <SmallLoadingRel />
            </div>
          ) : (
            <ListingPhotos state={state} setState={setState} page={page} />
          )}
          <div className='bg-gray-100 px-3 pb-3 mb-[10px]'>
            <p>
              Address:{' '}
              <span className='font-bold'>
                {page?.current_listing?.address}
              </span>
            </p>
            <div className='flex gap-[10px]'>
              <p>Beds: {page?.current_listing?.details?.beds || 'N/A'}</p>
              <p>Baths: {page?.current_listing?.details?.baths || 'N/A'}</p>
              <p>Sq Ft: {page?.current_listing?.details?.sqft || 'N/A'}</p>
            </div>
            <p>
              List Price: $
              {format_currency(
                page?.current_listing?.details?.list_price || 0
              ) || 'N/A'}
            </p>
            <p>
              Listing Agent:{' '}
              {page?.current_listing?.details?.list_agent_name ||
                page?.current_listing?.details?.co_list_agent_name}
            </p>
            <div className='flex gap-[10px] mt-[10px]'>
              {['market', 'internet', 'school'].map((item) => {
                let url = `https://nodejsprod01.driggstitle.com/node_to_oracle/${item}_report?zip=${page?.current_listing?.zip}&city=${page?.current_listing?.city}&state=${page?.current_listing?.st}`
                if (item === 'school') {
                  url = `https://nodejsprod01.driggstitle.com/node_to_oracle/${item}_report?address1=${page?.current_listing?.address}&address2=${page?.current_listing?.city}, ${page?.current_listing?.st}&add_info=school`
                }
                console.log(url)
                return (
                  <a href={url} target='_blank' className='underline'>
                    {item} report
                  </a>
                )
              })}
            </div>
          </div>
        </div>
      ) : (
        ''
      )} */}
            {page?.info_list?.[(state?.tour_state?.product_page || 1) - 1]
              ? [
                  page?.info_list?.[(state?.tour_state?.product_page || 1) - 1],
                ].map((item, index) => {
                  return (
                    <SwipeConfig
                      key={index}
                      index={index}
                      selectedIndex={selected}
                      setSelectedIndex={setSelected}
                      RightComponents={() => {
                        return (
                          <div>
                            <p
                              className='cursor-pointer'
                              onClick={() => {
                                navigate(item?.axiom_path, {
                                  state: { parent: '/user/listing_tours' },
                                })
                              }}
                            >
                              View
                            </p>
                          </div>
                        )
                      }}
                      className='mb-[2px]'
                    >
                      <ListItem
                        title={item?.item_heading}
                        content={item?.item_text}
                      />
                    </SwipeConfig>
                  )
                })
              : ''}
            <ThumbRating
              state={state}
              setState={setState}
              rating={
                state?.data?.tour_product_ratings
                  ?.map((item) => {
                    item.rate_value = Number(item.rate_value)
                    return item
                  })
                  ?.find(
                    (item) =>
                      item.rated_for_item_id ===
                      page?.info_list?.[
                        (state?.tour_state?.product_page || 1) - 1
                      ]?.data_id
                  ) || {
                  rated_for_item_id:
                    page?.info_list?.[
                      (state?.tour_state?.product_page || 1) - 1
                    ]?.data_id,
                  rated_by_cont_id: profile?.CONT_ID,
                  event_id: state?.tour_state?.event_id,
                }
              }
              type={'product'}
            />
          </div>
        )}
      </div>
      <Navigation state={state} setState={setState} />
    </div>
  )
}

export default Products
