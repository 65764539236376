import { useRef } from 'react'
import { useCalc } from '../../contexts/CalcContext'
import { array_nums, format_currency, validate_number } from '../../helpers'
import { CircleCheckToggle } from '../form/CircleCheckToggle'
import LabelInput from '../form/LabelInput'
import Select from '../form/Select'
import uiStyles from '../ui/uiStyles'

const CalcDefaults = ({ calculator_type = 'buy' }) => {
  const { calc, saveCalc, get_nums, get_loan_type, loans, find_county } =
    useCalc()

  console.log('default_calc', calc)
  const updateInput = (e, type, decimal = 2) => {
    let { name, value } = e.target
    const loan_type = get_loan_type(calculator_type, 'defaults')
    let start, end
    if (!type) {
      type = calc?.[calculator_type]?.defaults?.[loan_type]?.[`${name}_type`]
    }

    setTimeout(() => {
      if (
        !isNaN(Number(value?.toString()?.replace(/[$€£¥,%]/g, ''))) &&
        e?.target?.selectionStart &&
        start &&
        end
      ) {
        const orig_comma_length = (
          calc?.[calculator_type]?.defaults?.[loan_type]?.[name]?.match(/,/g) ||
          []
        )?.length
        const new_comma_length = (value?.match(/,/g) || [])?.length
        if (
          (new_comma_length > orig_comma_length && start > 2) ||
          !calc?.[calculator_type]?.defaults?.[loan_type]?.[name] ||
          (value?.startsWith('0.') &&
            calc?.[calculator_type]?.defaults?.[loan_type]?.[name]?.length ===
              1)
        ) {
          start += 1
          end += 1
        } else if (new_comma_length < orig_comma_length && start > 2) {
          start -= 1
          end -= 1
        }
        // if (
        //   orig_comma_length >= new_comma_length &&
        //   orig_comma_length !== 0 &&
        //   new_comma_length !== 0 &&
        //   start > 2
        // ) {
        //   console.log('done')
        //   start -= 1
        //   end -= 1
        //   if (start > 6) {
        //     start -= 1
        //     end -= 1
        //   }
        // }
        e.target.selectionStart = start
        e.target.selectionEnd = end
      }
    }, 0)
    if (
      name !== 'loan_type' &&
      name !== 'county' &&
      name !== 'show_split_in_closing_costs'
    ) {
      value = value?.toString()?.replace(/[^$€£¥0-9,.%]/g, '')
    }
    if (
      !isNaN(Number(value?.toString()?.replace(/[$€£¥,%]/g, ''))) &&
      e?.target?.selectionStart
    ) {
      value = format_currency(value, false, decimal, e.target)
      start = value?.start
      end = value?.end
      value = value?.item
    }
    if (name === 'loan_type') {
      return saveCalc((prev) => {
        console.log('calculator', calculator_type)
        let loan_all_defaults = {
          ...prev?.[calculator_type]?.defaults,
          [name]: value,
        }
        const new_defaults = calc?.[calculator_type]?.defaults?.[value]
        console.log('new defaults', new_defaults)
        console.log(calculator_type, name, value)
        let all_values = {
          ...prev?.[calculator_type]?.values,
          [name]: value,
          down_payment: new_defaults?.down_payment,
        }
        if (calculator_type === 'sell') {
          delete all_values.down_payment
        }

        let all_types = { ...prev?.[calculator_type]?.types, [name]: 'options' }
        return {
          ...prev,
          loan_2: !calc?.loan_2?.data_options?.loan_edited
            ? {
                ...prev?.loan_2,
                defaults: loan_all_defaults,
                values: all_values,
                types: all_types,
                is_pmi: new_defaults?.is_pmi,
                is_mip: new_defaults?.is_mip,
              }
            : { ...prev?.loan_2 },
          loan_3: !calc?.loan_3?.data_options?.loan_edited
            ? {
                ...prev?.loan_3,
                defaults: loan_all_defaults,
                values: all_values,
                types: all_types,
                is_pmi: new_defaults?.is_pmi,
                is_mip: new_defaults?.is_mip,
              }
            : { ...prev?.loan_3 },
          [calculator_type]: {
            ...prev?.[calculator_type],
            defaults: loan_all_defaults,
            values: all_values,
            types: all_types,
            is_pmi: new_defaults?.is_pmi,
            is_mip: new_defaults?.is_mip,
          },
        }
      })
    }
    if (name === 'county') {
      return saveCalc((prev) => {
        let all_defaults = loans
          ?.map((item) => item?.toLowerCase())
          ?.reduce((curr, val) => {
            curr[val] = {
              ...prev?.[calculator_type]?.defaults?.[val],
              [name]: value,
              [`${name}_type`]: type,
            }
            return curr
          }, {})
        console.log('all', all_defaults)
        all_defaults = { ...prev?.[calculator_type]?.defaults, ...all_defaults }
        const new_county = find_county(value)
        const orig_county = find_county(prev?.[calculator_type]?.values?.county)
        console.log(
          'test',
          new_county?.name,
          new_county?.def_prop_tax_rate,
          orig_county?.name,
          orig_county?.def_prop_tax_rate,
          'tax',
          orig_county?.def_prop_tax_rate,
          prev?.[calculator_type]?.values?.taxes,
          'new_tax',
          new_county?.def_prop_tax_rate
        )
        let all_values = {
          ...prev?.[calculator_type]?.values,
          [name]: value,
        }

        let all_types = { ...prev?.[calculator_type]?.types, [name]: type }
        if (
          validate_number(orig_county?.def_prop_tax_rate) ===
          validate_number(prev?.buy?.values?.taxes)
        ) {
          all_values.taxes = new_county?.def_prop_tax_rate
          all_types.taxes = '%'
        }
        if (
          validate_number(orig_county?.def_ins_rate) ===
          validate_number(prev?.buy?.values?.insurance_rate)
        ) {
          all_values.insurance_rate = new_county?.def_ins_rate
          all_types.insurance_rate = '%'
        }
        console.log('all_values', all_values)
        return {
          ...prev,
          loan_2: !calc?.loan_3?.data_options?.loan_edited
            ? {
                ...prev?.loan_2,
                defaults: all_defaults,
                values: all_values,
                types: all_types,
              }
            : { ...prev?.loan_2 },
          loan_3: !calc?.loan_3?.data_options?.loan_edited
            ? {
                ...prev?.loan_3,
                defaults: all_defaults,
                values: all_values,
                types: all_types,
              }
            : { ...prev?.loan_3 },
          [calculator_type]: {
            ...prev?.[calculator_type],
            defaults: all_defaults,
            values: all_values,
            types: all_types,
          },
        }
      })
    }

    if (name.includes('broker_fee') && calculator_type === 'sell') {
      const listing_broker_fee = validate_number(
        calc?.[calculator_type]?.defaults?.[loan_type]?.listing_broker_fee
      )
      const buyer_broker_fee = validate_number(
        calc?.[calculator_type]?.defaults?.[loan_type]?.buyer_broker_fee
      )
      console.log('listing_broker_fee', listing_broker_fee)
      console.log('buyer_broker_fee', buyer_broker_fee)
      const broker_fee =
        validate_number(value) +
        (name === 'listing_broker_fee' ? buyer_broker_fee : listing_broker_fee)

      console.log('broker_fee', broker_fee)
      return saveCalc((prev) => ({
        ...prev,
        [calculator_type]: {
          ...prev?.[calculator_type],
          defaults: {
            ...prev?.[calculator_type]?.defaults,
            [loan_type]: {
              ...prev?.[calculator_type]?.defaults?.[loan_type],
              [name]: value,
              [`${name}_type`]: type,
              closing_costs: {
                ...prev?.[calculator_type]?.defaults?.[loan_type]
                  ?.closing_costs,
                [name]: value,
                [`${name}_type`]: type,
              },
            },
          },
          values: {
            ...prev?.[calculator_type]?.values,
            ['broker_fee']: broker_fee,
          },
          // types: { ...prev?.[calculator_type]?.types, ['broker_fee']: type },
        },
      }))
    }

    if (name === 'closing_date_days') {
      return saveCalc((prev) => {
        return {
          ...prev,
          [calculator_type]: {
            ...prev?.[calculator_type],
            defaults: {
              ...prev?.[calculator_type]?.defaults,
              [loan_type]: {
                ...prev?.[calculator_type]?.defaults?.[loan_type],
                [name]: value,
                [`${name}_type`]: type,
              },
            },
            values: {
              ...prev?.[calculator_type]?.values,
              ['closing_date']: new Date(
                new Date().setDate(
                  new Date().getDate() + validate_number(value)
                )
              )?.toISOString(),
            },
            types: {
              ...prev?.[calculator_type]?.types,
              ['closing_date']: 'date',
            },
          },
        }
      })
    }

    if (type === '%' && validate_number(value) > 100) {
      value = 100
    }
    saveCalc((prev) => {
      let all_defaults = {
        ...prev?.[calculator_type]?.defaults,
        [loan_type]: {
          ...prev?.[calculator_type]?.defaults?.[loan_type],
          [name]: value,
          [`${name}_type`]: type,
        },
      }

      return {
        ...prev,
        loan_2:
          calculator_type === 'buy'
            ? { ...prev?.loan_2, defaults: all_defaults }
            : { ...prev?.loan_2 },
        loan_3:
          calculator_type === 'buy'
            ? { ...prev?.loan_3, defaults: all_defaults }
            : { ...prev?.loan_3 },
        [calculator_type]: {
          ...prev?.[calculator_type],
          defaults: all_defaults,
          values: { ...prev?.[calculator_type]?.values, [name]: value },
          types: { ...prev?.[calculator_type]?.types, [name]: type },
        },
      }
    })
  }
  return (
    <div className='mt-[10px]'>
      <div className='px-3'>
        <Select
          left_label={'County'}
          left_label_width={'w-[95%]'}
          no_margin={false}
          className='mb-[10px]'
          name={'county'}
          value={
            calc?.[calculator_type]?.defaults?.[
              get_loan_type(calculator_type, 'defaults')
            ]?.county
          }
          handler={updateInput}
          placeholder='required'
        >
          {calc?.ed_counties?.map((item) => {
            return (
              <option key={item?.name} value={item?.name}>
                {item?.name}
              </option>
            )
          })}
        </Select>
        <Select
          left_label={'Loan Type'}
          left_label_width={'w-[95%]'}
          no_margin={false}
          className='mb-[10px]'
          name={'loan_type'}
          value={get_loan_type(calculator_type, 'defaults')}
          handler={updateInput}
          placeholder='required'
        >
          {loans?.map((item) => {
            return (
              <option key={item} value={item?.toLowerCase()}>
                {item}
              </option>
            )
          })}
        </Select>
        <div className={`${uiStyles.line_3px} mb-[10px]`}></div>
      </div>
      <div className='mt-[0px] overflow-y-scroll max-h-[332px] px-3 '>
        <LabelInput
          left_label={'Buyer Broker Fee'}
          left_label_width={'w-[95%]'}
          name={'buyer_broker_fee'}
          show_percent={true}
          show_delete_all={true}
          value={
            calc?.[calculator_type]?.defaults?.[
              get_loan_type(calculator_type, 'defaults')
            ]?.buyer_broker_fee || ''
          }
          percent_value='%'
          handleChange={(e, type) => updateInput(e, type, 3)}
          placeholder=''
        />
        <LabelInput
          left_label={'Listing Broker Fee'}
          left_label_width={'w-[95%]'}
          name={'listing_broker_fee'}
          show_percent={true}
          show_delete_all={true}
          value={
            calc?.[calculator_type]?.defaults?.[
              get_loan_type(calculator_type, 'defaults')
            ]?.listing_broker_fee || ''
          }
          percent_value='%'
          handleChange={(e, type) => updateInput(e, type, 3)}
          placeholder=''
        />
        {/* <div className='flex justify-between px-[10px] border border-gray-300 rounded mb-[10px] '>
          <p className='text-sm w-[48%] py-[12px] pr-[2px] border-r-2 border-gray-300 text-thirdary'>{`Show Split`}</p>
          <CircleCheckToggle
            name={`show_split_in_closing_costs`}
            type='toggle'
            inputValue={
              calc?.[calculator_type]?.defaults?.[
                get_loan_type(calculator_type, 'defaults')
              ]
            }
            handle_change={async (e) => {
              console.log(e.target.value)
              const loan_type = get_loan_type(calculator_type, 'defaults')
              saveCalc((prev) => {
                return {
                  ...prev,
                  [calculator_type]: {
                    ...prev?.[calculator_type],
                    defaults: {
                      ...prev?.[calculator_type]?.defaults,
                      [loan_type]: {
                        ...prev?.[calculator_type]?.defaults?.[loan_type],
                        ['show_split_in_closing_costs']:
                          prev?.[calculator_type]?.defaults?.[loan_type]
                            ?.show_split_in_closing_costs === 'Y'
                            ? 'N'
                            : 'Y',
                      },
                    },
                  },
                }
              })
            }}
          />
        </div> */}
        {/* <LabelInput
          left_label={'Interest Rate'}
          left_label_width={'w-[95%]'}
          name={'interest_rate'}
          show_percent={true}
          show_delete_all={true}
          value={
            calc?.buy?.defaults?.[
              (calc?.buy?.defaults?.loan_type || 'Conventional')?.toLowerCase()
            ]?.interest_rate || ''
          }
          percent_value='%'
          handleChange={(e, type) => updateInput(e, type, 3)}
          placeholder=''
        /> */}
        {/* <LabelInput
          left_label={'Insurance Rate'}
          left_label_width={'w-[95%]'}
          name={'insurance_rate'}
          show_percent={true}
          show_delete_all={true}
          value={
            calc?.[calculator_type]?.defaults?.[
              get_loan_type(calculator_type, 'defaults')
            ]?.insurance_rate || ''
          }
          percent_value='%'
          handleChange={(e, type) => updateInput(e, type, 3)}
          placeholder=''
        />
        <LabelInput
          left_label={'Loan Term'}
          left_label_width={'w-[95%]'}
          name={'loan_term'}
          show_percent={true}
          show_delete_all={true}
          value={
            calc?.[calculator_type]?.defaults?.[
              get_loan_type(calculator_type, 'defaults')
            ]?.loan_term || ''
          }
          percent_value=' years'
          handleChange={(e, type) => updateInput(e, type, 3)}
          placeholder=''
        /> */}
        {get_loan_type(calculator_type, 'defaults') !== 'all cash' ? (
          <LabelInput
            left_label={'Down Payment'}
            left_label_width={'w-[95%]'}
            name={'down_payment'}
            show_percent={true}
            show_delete_all={true}
            value={
              calc?.[calculator_type]?.defaults?.[
                get_loan_type(calculator_type, 'defaults')
              ]?.down_payment || ''
            }
            percent_value='%'
            handleChange={(e, type) => updateInput(e, type, 3)}
            placeholder=''
          />
        ) : (
          ''
        )}
        <Select
          left_label={'Closing Date'}
          left_label_width={'w-[95%]'}
          no_margin={false}
          className='mb-[10px]'
          name={'closing_date_days'}
          value={
            calc?.[calculator_type]?.defaults?.[
              get_loan_type(calculator_type, 'defaults')
            ]?.closing_date_days || '30'
          }
          handler={updateInput}
          placeholder=''
        >
          {array_nums(4, true, (index) => (index + 1) * 30)?.map((item) => {
            return (
              <option key={item} value={item}>
                {`${item} days`}
              </option>
            )
          })}
        </Select>
      </div>
    </div>
  )
}

export default CalcDefaults
