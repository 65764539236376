import { useState } from 'react'
import { BsArrowLeftCircle, BsArrowRightCircle } from 'react-icons/bs'
import { LuArrowDownNarrowWide, LuArrowUpNarrowWide } from 'react-icons/lu'
import { useNavigate } from 'react-router-dom'
import images_homes from '../../assets/images/home'
import { useSales } from '../../contexts/SalesContext'
import {
  array_nums,
  getDateString,
  set_storage,
  validate_number,
} from '../../helpers'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import { CircleCheckToggle } from '../form/CircleCheckToggle'
import LabelInput from '../form/LabelInput'
import Select from '../form/Select'
import ButtonsRow from '../ui/ButtonsRow'
import { SmallLoadingRel } from '../ui/SmallLoadingRel'
import uiStyles from '../ui/uiStyles'

const FirstBase = ({ setShowModal, setModalType }) => {
  const { sales, saveSales } = useSales()
  const [showAton, setShowAton] = useState(true)
  const [showInstalled, setShowInstalled] = useState(true)
  const [showSymptoms, setShowSymptoms] = useState(true)
  const [pain, setPain] = useState(false)
  const [currentQuestSymptom, setCurrentQuestSymptom] = useState(null)
  const [symptomSelect, setSymptomSelect] = useState('questions')
  const [messageSent, setMessageSent] = useState({})
  const handle_msg = (status, message, type, close) => {
    setMessageSent({ ...messageSent, status, message, type })
    if (close) {
      setTimeout(() => {
        setMessageSent({ ...messageSent, status: 'done', message: '' })
      }, 3000)
    }
  }
  const { axiomFetch } = useAxiomFetch()
  const navigate = useNavigate()
  // potentially at bat before stmt
  const get_aton_cfg = () => {
    return sales?.sm_aton_cfg?.[0] || {}
  }

  const get_symptoms = () => {
    let questions = sales?.curr_client?.baseline_data?.cont_power_quest_symptoms
      ?.filter((item) => {
        if (symptomSelect === 'questions' && !item?.ans_val) {
          return true
        } else if (
          symptomSelect === 'controlled' &&
          item?.ans_val?.toString()?.toUpperCase() === 'U'
        ) {
          return true
        } else if (
          symptomSelect === 'help' &&
          item?.ans_val?.toString()?.toUpperCase() === 'H'
        ) {
          return true
        }
      })
      ?.sort((a, b) => validate_number(a.cpq_id) - validate_number(b.cpq_id))
      ?.sort((a, b) => validate_number(a.cpq_ord) - validate_number(b.cpq_ord))

    if (symptomSelect === 'questions') {
      questions = questions?.slice(0, 1)
    }
    return questions
  }

  const save_symptom = async (item, updates = {}) => {
    saveSales((prev) => {
      const sales_data = {
        ...prev,
        curr_client: {
          ...prev?.curr_client,
          baseline_data: {
            ...prev?.curr_client?.baseline_data,
            cont_power_quest_symptoms: [
              ...prev?.curr_client?.baseline_data?.cont_power_quest_symptoms?.filter(
                (cpq) => cpq.quest_id !== item?.quest_id
              ),
              { ...item, ...updates },
            ],
          },
        },
      }
      set_storage('sales', sales_data)
      return sales_data
    })
    await axiomFetch(
      '/sales/what_next/baseline/symptom',
      'POST',
      { symptom: { ...item, ...updates } },
      {},
      false,
      null,
      700
    )
  }

  const needs_help = async (item) => {
    saveSales((prev) => {
      const sales_data = {
        ...prev,
        curr_client: { ...prev?.curr_client, aton_curr_item: item },
      }
      set_storage('sales', sales_data)
      return sales_data
    })
    setModalType('aton_needs_help')
    setShowModal(true)
  }

  const toggle_pain = () => {
    setPain((prev) => !prev)
    // if (symptomSelect === 'questions') {
    //   setTimeout(() => {
    //     window.scrollTo(0, document.body.scrollHeight)
    //   }, 50)
    // }
  }

  const get_questions = () => {
    return sales?.pain_funnel_qsts
  }

  return (
    <div>
      <div className='px-3 mt-[10px]'>
        <div className='mb-[10px]'>
          <p className='font-bold text-[18px] mb-[5px]'>Desired Outcome:</p>
          <p className='text-[18px] ml-[5px]'>
            Set custom solution appointment
          </p>
        </div>
        <div className={`${uiStyles.line_3px} my-[10px]`}></div>

        <div
          className='text-[18px] font-bold flex gap-[10px] cursor-pointer'
          onClick={() => {
            setShowAton((prev) => !prev)
          }}
        >
          <p>ATON</p>{' '}
          {showAton ? <LuArrowUpNarrowWide /> : <LuArrowDownNarrowWide />}
        </div>
        {showAton ? (
          <div>
            <p className='text-[16px] ml-[5px]'>
              <span className='font-bold'>A</span>
              <span>ppreciate</span>
            </p>
            <p className='ml-[10px]'>{get_aton_cfg()?.appr || ''}</p>
            <p className='text-[16px] ml-[5px]'>
              <span className='font-bold'>T</span>
              <span>ypically</span>
            </p>
            <p className='ml-[10px]'>{get_aton_cfg()?.typ || ''}</p>
            <p className='text-[16px] ml-[5px]'>
              <span className='font-bold'>O</span>
              <span>bviously</span>
            </p>
            <p className='ml-[10px]'>{get_aton_cfg()?.obv || ''}</p>
            <p className='text-[16px] ml-[5px]'>
              <span className='font-bold'>N</span>
              <span>aturally</span>
            </p>
            <p className='ml-[10px]'>{get_aton_cfg()?.nat || ''}</p>
            <p className='ml-[10px] italic mt-[5px]'>{`(this is the upfront contract)`}</p>
            <p className='ml-[10px] text-[15px] font-bold mt-[5px]'>
              Can we agree to this?
            </p>
          </div>
        ) : (
          ''
        )}
        {/* {validate_number(sales?.curr_client?.data?.runs_scored) > 0 ? (
          <div>
            <div className={`${uiStyles.line_3px} mt-[10px]`}></div>
            <div
              className='text-[18px] font-bold flex gap-[10px] cursor-pointer mt-[10px]'
              onClick={() => {
                setShowInstalled((prev) => !prev)
              }}
            >
              <p>Installed Products</p>{' '}
              {showInstalled ? (
                <LuArrowUpNarrowWide />
              ) : (
                <LuArrowDownNarrowWide />
              )}
            </div>
            {showInstalled ? (
              <div className='flex flex-col gap-[5px] mt-[5px]'>
                {sales?.curr_client?.baseline_data?.cont_power_quest
                  ?.sort(
                    (a, b) =>
                      new Date(b?.install_date || '01-JAN-01') -
                      new Date(a.install_date || '01-JAN-01')
                  )
                  ?.map((item) => {
                    return (
                      <div key={item?.cpq_id} className='ml-[5px]'>
                        <p>
                          <span className='font-bold'>
                            {item?.service_title}{' '}
                          </span>
                          {`(${getDateString({ date: item?.install_date })})`}
                        </p>
                        <div className='flex gap-[5px]'>
                          <p>Go Deeper:</p>
                          <CircleCheckToggle
                            name={`go_deeper`}
                            type='check'
                            toggle_left={false}
                            inputValue={item}
                            handle_change={async (e) => {
                              saveSales((prev) => {
                                const sales_data = {
                                  ...prev,
                                  curr_client: {
                                    ...prev?.curr_client,
                                    baseline_data: {
                                      ...prev?.curr_client?.baseline_data,
                                      cont_power_quest: [
                                        ...prev?.curr_client?.baseline_data?.cont_power_quest?.filter(
                                          (cpq) => cpq.cpq_id !== item?.cpq_id
                                        ),
                                        {
                                          ...item,
                                          go_deeper:
                                            item?.go_deeper === 'Y' ? 'N' : 'Y',
                                        },
                                      ],
                                    },
                                  },
                                }
                                set_storage('sales', sales_data)
                                return sales_data
                              })
                              await axiomFetch(
                                '/sales/what_next/baseline/go_deeper',
                                'POST',
                                {
                                  ...item,
                                  go_deeper:
                                    item?.go_deeper === 'Y' ? 'N' : 'Y',
                                }
                              )
                            }}
                          />
                        </div>
                        <ol className='list-decimal ml-[15px]'>
                          {item?.deep_questions
                            ?.sort(
                              (a, b) =>
                                validate_number(b.qst_ord) -
                                validate_number(a.qst_ord)
                            )
                            ?.map((item) => {
                              return (
                                <li key={item?.deep_id}>{item?.qst_text}</li>
                              )
                            })}
                        </ol>
                        <div className={`${uiStyles.line} mt-[5px]`}></div>
                      </div>
                    )
                  })}
              </div>
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )} */}
        <div>
          <div className={`${uiStyles.line_3px} mt-[10px]`}></div>
          <div
            className='text-[18px] font-bold flex gap-[10px] cursor-pointer mt-[10px]'
            onClick={() => {
              setShowSymptoms((prev) => !prev)
            }}
          >
            <p>Symptoms</p>{' '}
            {showSymptoms ? <LuArrowUpNarrowWide /> : <LuArrowDownNarrowWide />}
          </div>
          {sales?.loading_baseline ? <SmallLoadingRel /> : ''}
          {showSymptoms ? (
            <div className='text-[15px] font-normal mt-[10px]'>
              <ButtonsRow
                padding={'p-1'}
                items={['questions', 'controlled', 'help']}
                selected={symptomSelect || 'questions'}
                handler={(item) => {
                  setSymptomSelect(item)
                }}
              />
              <div className='min-h-[300px]'>
                {get_symptoms()?.map((item) => {
                  return (
                    <div key={item?.qst_id}>
                      {pain &&
                      (symptomSelect === 'questions' ||
                        (symptomSelect === 'controlled' &&
                          item?.quest_id === currentQuestSymptom)) ? (
                        <div>
                          <div>
                            <div
                              className={`${uiStyles.line_3px} mt-[10px]`}
                            ></div>
                            <div className='font-bold mt-[10px]'>
                              <div className='flex gap-[5px] justify-between items-center'>
                                <button
                                  onClick={() => {
                                    saveSales((prev) => {
                                      let pain_curr_quest = validate_number(
                                        prev?.curr_client?.pain_curr_quest
                                      )
                                      if (pain_curr_quest === 0) {
                                        pain_curr_quest =
                                          get_questions()?.length - 1
                                      } else {
                                        pain_curr_quest = pain_curr_quest - 1
                                      }
                                      const sales = {
                                        ...prev,
                                        curr_client: {
                                          ...prev?.curr_client,
                                          pain_curr_quest,
                                        },
                                      }
                                      return sales
                                    })
                                  }}
                                >
                                  <BsArrowLeftCircle size={20} />
                                </button>
                                <div className='max-w-[250px]'>
                                  <p className='text-black overflow-scroll whitespace-nowrap'>
                                    {
                                      get_questions()?.[
                                        validate_number(
                                          sales?.curr_client?.pain_curr_quest
                                        )
                                      ]?.pain_name
                                    }
                                  </p>
                                  <div className={uiStyles.line}></div>
                                  <p>
                                    {
                                      get_questions()?.[
                                        validate_number(
                                          sales?.curr_client?.pain_curr_quest
                                        )
                                      ]?.pain_text
                                    }
                                  </p>
                                </div>
                                <button
                                  onClick={() => {
                                    saveSales((prev) => {
                                      let pain_curr_quest = validate_number(
                                        prev?.curr_client?.pain_curr_quest
                                      )
                                      if (
                                        pain_curr_quest ===
                                        get_questions()?.length - 1
                                      ) {
                                        pain_curr_quest = 0
                                      } else {
                                        pain_curr_quest = pain_curr_quest + 1
                                      }
                                      const sales = {
                                        ...prev,
                                        curr_client: {
                                          ...prev?.curr_client,
                                          pain_curr_quest,
                                        },
                                      }
                                      return sales
                                    })
                                  }}
                                >
                                  <BsArrowRightCircle size={20} />
                                </button>
                              </div>

                              <div className='flex justify-end mt-[10px]'>
                                <img
                                  src={images_homes.filter}
                                  className='w-[20px] h-[20px] cursor-pointer'
                                  onClick={() => {
                                    setModalType('pain_picker')
                                    setShowModal(true)
                                  }}
                                />
                              </div>
                            </div>
                            <div
                              className={`${uiStyles.line_3px} my-[10px]`}
                            ></div>
                            <label className={uiStyles.label}>
                              What is the Root Cause?
                            </label>
                            <textarea
                              // ref={(ref) => ref && ref.focus()}
                              // onFocus={() => {
                              //   window.scrollTo(0, document.body.scrollHeight)
                              // }}
                              onChange={(e) => {
                                save_symptom(item, {
                                  root_cause: e.target.value,
                                })
                              }}
                              className={uiStyles.text_area}
                              value={item?.root_cause}
                            ></textarea>
                            <label className={uiStyles.label}>
                              Quantify: How much does it cost you?
                            </label>
                            <textarea
                              onChange={(e) => {
                                save_symptom(item, {
                                  quantify: e.target.value,
                                })
                              }}
                              className={uiStyles.text_area}
                              value={item?.quantify}
                            ></textarea>
                            <label className={uiStyles.label}>
                              Symptom Notes
                            </label>
                            <textarea
                              onChange={(e) => {
                                save_symptom(item, {
                                  symptom_notes: e.target.value,
                                })
                              }}
                              className={uiStyles.text_area}
                              value={item?.symptom_notes}
                            ></textarea>
                          </div>
                          <div className='flex justify-end gap-[10px] mt-[10px]'>
                            <button
                              className={uiStyles.white_btn}
                              onClick={() => {
                                setTimeout(async () => {
                                  await axiomFetch(
                                    '/sales/what_next/baseline/symptom',
                                    'POST',
                                    {
                                      symptom: {
                                        ...item,
                                        ans_val: null,
                                        answer_dt: null,
                                      },
                                    }
                                  )
                                }, 0)
                                toggle_pain()
                              }}
                            >
                              {symptomSelect === 'controlled' ? 'Undo' : 'Back'}
                            </button>
                            <button
                              className={uiStyles.hover_btn}
                              onClick={() => {
                                save_symptom(item, {
                                  ans_val: 'H',
                                  answer_dt: new Date().toISOString(),
                                })
                                toggle_pain()
                              }}
                            >
                              {symptomSelect === 'controlled'
                                ? 'Save'
                                : 'Next Question'}
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div
                            key={item?.pcq_id}
                            className={uiStyles.card_gray}
                          >
                            <p>{item?.pq_text?.replaceAll('¿', "'")}</p>

                            <p className='text-end italic text-sm mt-[5px]'>
                              <span className='border-t-[1px] border-gray-300'>
                                {' '}
                                Author: {item?.author_nm}
                              </span>
                            </p>
                            <p className='text-end italic text-sm'>
                              <span className='border-b-[1px] border-gray-300'>
                                {' '}
                                Focus: {item?.ser_nm}
                              </span>
                            </p>
                            <div className='flex justify-between my-[10px]'>
                              {symptomSelect === 'controlled' ? (
                                <div
                                  className={uiStyles.red_btn_small}
                                  onClick={() => {
                                    save_symptom(item, {
                                      ans_val: null,
                                      answer_dt: null,
                                      ord: 99,
                                      root_cause: null,
                                      cli_validated: 'Z',
                                      quantify: null,
                                      symptom_notes: null,
                                    })
                                  }}
                                >
                                  Reset Question
                                </div>
                              ) : (
                                <button
                                  className={uiStyles.white_btn_small}
                                  onClick={() =>
                                    save_symptom(item, {
                                      ans_val: 'U',
                                      answer_dt: new Date()?.toISOString(),
                                      cli_validated: 'Z',
                                    })
                                  }
                                >
                                  Under Control
                                </button>
                              )}
                              {symptomSelect === 'help' ? (
                                <div
                                  className={uiStyles.red_btn_small}
                                  onClick={() => {
                                    save_symptom(item, {
                                      ans_val: null,
                                      answer_dt: null,
                                      ord: 99,
                                      root_cause: null,
                                      cli_validated: 'Z',
                                      quantify: null,
                                      symptom_notes: null,
                                    })
                                  }}
                                >
                                  Reset Question
                                </div>
                              ) : (
                                <button
                                  className={uiStyles.hover_btn_small}
                                  onClick={async () => {
                                    setTimeout(async () => {
                                      await axiomFetch(
                                        '/sales/what_next/baseline/symptom',
                                        'POST',
                                        {
                                          symptom: {
                                            ...item,
                                            ans_val: 'H',
                                            answer_dt:
                                              new Date()?.toISOString(),
                                          },
                                        }
                                      )
                                    }, 0)
                                    setCurrentQuestSymptom(item?.quest_id)
                                    toggle_pain()
                                  }}
                                >
                                  Need Some Help
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className={`${uiStyles.line_3px} mt-[10px]`}></div>
        <>
          {messageSent?.type === 'svic_error' ? (
            <div className={`${uiStyles[messageSent.status]} mb-[20px]`}>
              {messageSent.message}
            </div>
          ) : (
            <div className='flex justify-between items-center mt-[10px] gap-[20px]'>
              <Select
                left_label={'Done For:'}
                left_label_width={'w-[140px]'}
                no_margin={false}
                name={'done_for_days'}
                value={validate_number(sales?.curr_client?.done_for_days || 0)}
                handler={(e) => {
                  setTimeout(async () => {
                    await axiomFetch('/sales/what_next/baseline/skip', 'POST', {
                      client: sales?.curr_client?.data,
                      days_to_skip: validate_number(e.target.value),
                    })
                  }, 0)
                  if (sales?.what_next_clients?.length < 2) {
                    saveSales((prev) => {
                      const sales_data = {
                        ...prev,
                        what_next_clients: prev?.what_next_clients?.filter(
                          (item) =>
                            item.cont_id !== sales?.curr_client?.data?.cont_id
                        ),
                        selected: 'what_next',
                      }
                      set_storage('sales', sales_data)
                      return sales_data
                    })
                    return navigate('/user/sales/what_next')
                  } else {
                    saveSales((prev) => {
                      const sales_data = {
                        ...prev,
                        what_next_clients: prev?.what_next_clients?.filter(
                          (item) =>
                            item.cont_id !== sales?.curr_client?.data?.cont_id
                        ),
                        curr_client: {
                          data: prev?.what_next_clients
                            ?.filter(
                              (item) =>
                                item.cont_id !==
                                sales?.curr_client?.data?.cont_id
                            )
                            ?.sort(
                              (a, b) =>
                                validate_number(b?.total_score) -
                                validate_number(a?.total_score)
                            )?.[0],
                          selected: 'baseline',
                        },
                      }
                      set_storage('sales', sales_data)
                      return sales_data
                    })
                  }
                }}
                placeholder=''
              >
                <option value='0'># of days</option>
                {array_nums(30, false).map((item) => {
                  return (
                    <option key={item} value={item}>
                      {item} {item === 1 ? 'day' : 'days'}
                    </option>
                  )
                })}
              </Select>
              <button
                className={`${uiStyles.hover_btn} mb-[5px]`}
                onClick={async () => {
                  const is_help_question =
                    pain ||
                    sales?.curr_client?.baseline_data?.cont_power_quest_symptoms?.some(
                      (item) => item.ans_val === 'H'
                    )
                  if (!is_help_question) {
                    return handle_msg(
                      'failure_msg',
                      'Caught stealing... No Questions in needs some help.',
                      'svic_error',
                      true,
                      5000
                    )
                  }
                  setTimeout(async () => {
                    await axiomFetch('/sales/what_next/baseline/svic', 'POST', {
                      client: sales?.curr_client?.data,
                    })
                  }, 0)
                  saveSales((prev) => {
                    const client_data = {
                      ...prev?.curr_client?.data,
                      cur_funnel_status:
                        validate_number(
                          prev?.curr_client?.data?.cur_funnel_status
                        ) < 2
                          ? 2
                          : prev?.curr_client?.data?.cur_funnel_status,
                      base_description:
                        validate_number(
                          prev?.curr_client?.data?.cur_funnel_status
                        ) < 2
                          ? '2nd: Commitment to engage'
                          : prev?.curr_client?.data?.base_description,
                    }
                    const sales_data = {
                      ...prev,
                      what_next_clients: [
                        ...prev?.what_next_clients?.filter(
                          (item) =>
                            item.cont_id !== sales?.curr_client?.data?.cont_id
                        ),
                        client_data,
                      ],
                      curr_client: {
                        ...prev?.curr_client,
                        nav_base: 2,
                        refresh:
                          validate_number(prev?.curr_client?.refresh) + 1,
                        data: client_data,
                        selected: 'baseline',
                      },
                    }
                    set_storage('sales', sales_data)
                    return sales_data
                  })
                }}
              >
                SVIC
              </button>
            </div>
          )}
        </>
      </div>
    </div>
  )
}

export default FirstBase
