import { useEffect } from 'react'
import { IoBaseballOutline } from 'react-icons/io5'
import {
  PiBaseballCapLight,
  PiDiamondFill,
  PiDiamondLight,
} from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'

import { useSales } from '../../contexts/SalesContext'
import { array_nums, set_storage, validate_number } from '../../helpers'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import Select from '../form/Select'
import uiStyles from '../ui/uiStyles'
import AtBat from './AtBat'
import FirstBase from './FirstBase'
import SecondBase from './SecondBase'
import ThirdBase from './ThirdBase'

const Baseline = ({ setShowModal, setModalType }) => {
  const { sales, saveSales } = useSales()
  const { axiomFetch } = useAxiomFetch()
  const navigate = useNavigate()
  const get_client = () => {
    return sales?.curr_client
  }
  const cur_funnel_status = validate_number(
    get_client()?.data?.cur_funnel_status
  )

  const runs_scored = validate_number(get_client()?.data?.runs_scored)

  const show_base = (base) => {
    return cur_funnel_status >= base || runs_scored
  }

  const get_base = () => {
    return sales?.curr_client?.nav_base === 0 || sales?.curr_client?.nav_base
      ? sales?.curr_client?.nav_base
      : cur_funnel_status || 0
  }
  useEffect(() => {
    saveSales((prev) => ({
      ...prev,
      curr_client: {
        ...prev?.curr_client,
        nav_base: sales?.curr_client?.data?.cur_funnel_status,
      },
    }))
  }, [sales?.curr_client?.data?.cont_id])

  const change_base = (base) => {
    return saveSales((prev) => ({
      ...prev,
      curr_client: {
        ...prev?.curr_client,
        nav_base: base,
      },
    }))
  }
  return (
    <div className='flex flex-col min-h-[350px] justify-between'>
      <div className={``}>
        <div className='cursor-pointer flex justify-evenly '>
          <div
            className={`cursor-pointer flex gap-[5px] px-3 p-2 items-center ${
              sales?.curr_client?.nav_base === 0
                ? 'border-b-2 border-blue-400 text-blue-600'
                : 'border-b-2 border-white'
            }`}
            onClick={() => change_base(0)}
          >
            <PiDiamondFill className='' />
            <p>At Bat</p>
          </div>
          {show_base(1) ? (
            <div
              className={`cursor-pointer flex gap-[5px] items-center px-3 p-2 ${
                sales?.curr_client?.nav_base === 1
                  ? 'border-b-2 border-blue-400 text-blue-600'
                  : 'border-b-2 border-white'
              }`}
              onClick={() => change_base(1)}
            >
              <PiDiamondFill className='' />
              <p>
                {' '}
                1<sup>st</sup>
              </p>
            </div>
          ) : (
            ''
          )}
          {show_base(2) ? (
            <div
              className={`cursor-pointer flex gap-[5px] items-center px-3 p-2 ${
                sales?.curr_client?.nav_base === 2
                  ? 'border-b-2 border-blue-400 text-blue-600'
                  : 'border-b-2 border-white'
              }`}
              onClick={() => change_base(2)}
            >
              <PiDiamondFill className='' />
              <p>
                {' '}
                2<sup>nd</sup>
              </p>
            </div>
          ) : (
            ''
          )}
          {show_base(3) ? (
            <div
              className={`cursor-pointer flex gap-[5px] items-center px-3 p-2 ${
                sales?.curr_client?.nav_base === 3
                  ? 'border-b-2 border-blue-400 text-blue-600'
                  : 'border-b-2 border-white'
              }`}
              onClick={() => change_base(3)}
            >
              <PiDiamondFill className='' />
              <p>
                {' '}
                3<sup>rd</sup>
              </p>
            </div>
          ) : (
            ''
          )}
        </div>
        {get_base() === 0 ? (
          <AtBat setModalType={setModalType} setShowModal={setShowModal} />
        ) : get_base() === 1 ? (
          <FirstBase setModalType={setModalType} setShowModal={setShowModal} />
        ) : get_base() === 2 ? (
          <SecondBase setModalType={setModalType} setShowModal={setShowModal} />
        ) : get_base() === 3 ? (
          <ThirdBase setModalType={setModalType} setShowModal={setShowModal} />
        ) : (
          ''
        )}
      </div>
      <div className='px-3 mt-[20px]'>
        <div className={`${uiStyles.line_3px} mb-[10px]`}></div>
        <div className='flex items-center gap-[10px]'>
          <Select
            left_label={'Skip For:'}
            left_label_width={'w-[140px]'}
            no_margin={false}
            name={'skip_for_days'}
            value={validate_number(sales?.curr_client?.skip_for_days || 0)}
            handler={(e) => {
              setTimeout(async () => {
                await axiomFetch('/sales/what_next/baseline/skip', 'POST', {
                  client: sales?.curr_client?.data,
                  days_to_skip: validate_number(e.target.value),
                })
              }, 0)
              if (sales?.what_next_clients?.length < 2) {
                saveSales((prev) => {
                  const sales_data = {
                    ...prev,
                    what_next_clients: prev?.what_next_clients?.filter(
                      (item) =>
                        item.cont_id !== sales?.curr_client?.data?.cont_id
                    ),
                    selected: 'what_next',
                  }
                  set_storage('sales', sales_data)
                  return sales_data
                })
                return navigate('/user/sales/what_next')
              } else {
                saveSales((prev) => {
                  const sales_data = {
                    ...prev,
                    what_next_clients: prev?.what_next_clients?.filter(
                      (item) =>
                        item.cont_id !== sales?.curr_client?.data?.cont_id
                    ),
                    curr_client: {
                      data: prev?.what_next_clients
                        ?.filter(
                          (item) =>
                            item.cont_id !== sales?.curr_client?.data?.cont_id
                        )
                        ?.sort(
                          (a, b) =>
                            validate_number(b?.total_score) -
                            validate_number(a?.total_score)
                        )?.[0],
                      selected: 'baseline',
                    },
                  }
                  set_storage('sales', sales_data)
                  return sales_data
                })
              }
            }}
            placeholder=''
          >
            <option value='0'># of days</option>
            {array_nums(30, false).map((item) => {
              return (
                <option key={item} value={item}>
                  {item} {item === 1 ? 'day' : 'days'}
                </option>
              )
            })}
          </Select>
          <button
            className={`${uiStyles.red_btn} mb-[5px]`}
            onClick={async () => {
              setTimeout(async () => {
                await axiomFetch('/sales/what_next/baseline/eject', 'POST', {
                  client: sales?.curr_client?.data,
                })
              }, 0)
              if (sales?.what_next_clients?.length < 2) {
                saveSales((prev) => {
                  const sales_data = {
                    ...prev,
                    what_next_clients: prev?.what_next_clients?.filter(
                      (item) =>
                        item.cont_id !== sales?.curr_client?.data?.cont_id
                    ),
                    selected: 'what_next',
                  }
                  set_storage('sales', sales_data)
                  return sales_data
                })
                return navigate('/user/sales/what_next')
              } else {
                saveSales((prev) => {
                  const sales_data = {
                    ...prev,
                    what_next_clients: prev?.what_next_clients?.filter(
                      (item) =>
                        item.cont_id !== sales?.curr_client?.data?.cont_id
                    ),
                    curr_client: {
                      data: prev?.what_next_clients
                        ?.filter(
                          (item) =>
                            item.cont_id !== sales?.curr_client?.data?.cont_id
                        )
                        ?.sort(
                          (a, b) =>
                            validate_number(b?.total_score) -
                            validate_number(a?.total_score)
                        )?.[0],
                      selected: 'baseline',
                    },
                  }
                  set_storage('sales', sales_data)
                  return sales_data
                })
              }
            }}
          >
            Eject
          </button>
        </div>
      </div>
    </div>
  )
}

export default Baseline
