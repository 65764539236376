import React, { useState } from 'react'
import { createPortal } from 'react-dom'
import { AiFillCloseCircle } from 'react-icons/ai'

function PDFPreview({
  image,
  toggle,
  setToggle,
  index,
  preview = false,
  fill,
  is_pdf = false,
}) {
  const overlay = document?.getElementById('overlays')
  // const allowed_paths = ['/user/approve-marketing-material']
  //const [toggle, setToggle] = useState(false)
  const [scroll, setScroll] = useState(0)

  // useEffect(() => {
  //   if (window.scrollY % 25) {
  //     setScroll(scroll)
  //   }
  // }, [toggle])
  return (
    <>
      <div className='swipeable w-32 min-h-[80px] z-100'>
        {image && !image?.includes('.pdf') && !is_pdf ? (
          <div
            className='flex justify-center swipeable no_parent_click absolute z-1000 top-0 left-0 w-[100%] bg-black bg-opacity-0'
            onClick={(e) => {
              if (preview) {
                setScroll(e.clientY + window.scrollY)
                setToggle(index)
              }
            }}
          >
            <img
              loading='lazy'
              src={image}
              alt={image || 'menu item'}
              draggable='false'
              className={`${
                preview && 'no_parent_click'
              } swipeable rounded-md h-[90px] w-[130px] ${
                fill ? fill : 'object-cover object-top'
              } `}
            />
          </div>
        ) : image ? (
          <>
            <div
              onClick={(e) => {
                if (preview) {
                  setScroll(e.clientY + window.scrollY)
                  setToggle(index)
                }
              }}
              className='no_parent_click absolute z-1000 top-0 left-0 w-[100%] h-32 bg-black bg-opacity-0'
            ></div>
            <iframe
              loading='lazy'
              title={image}
              className='no_parent_click z-1 w-32 rounded-md h-[90px] object-cover mx-auto overflow-y-hidden overflow-x-hidden'
              scrolling='no'
              preload='auto'
              src={image}
            />
          </>
        ) : (
          <p>Loading preview...</p>
        )}
        {toggle === index &&
          preview === true &&
          createPortal(
            <div
              style={{ top: scroll, left: `calc((100vw / 2) - 165px)` }}
              className={`absolute z-300 m-auto w-[100px] h-100px`}
            >
              <div
                className='no_parent_click absolute z-1000 top-0 left-0 cursor-pointer'
                onClick={() => setToggle(0)}
              >
                <AiFillCloseCircle
                  className='no_parent_click'
                  size={30}
                  color='#1e83f3'
                />
              </div>

              {(image && image?.includes('.pdf')) || is_pdf ? (
                <iframe
                  className='w-[330px] min-h-[calc(100vh/1.7)]'
                  src={image}
                  title={image}
                />
              ) : (
                <div className='w-[330px]'>
                  <img
                    loading='lazy'
                    className={`${fill ? fill : 'object-cover'} mx-auto`}
                    alt={image || 'menu item'}
                    src={image}
                    title={image}
                  />
                </div>
              )}
            </div>,
            overlay
          )}
      </div>
    </>
  )
}

export default PDFPreview
