import { useState } from 'react'
import { set_storage } from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import uiStyles from '../../ui/uiStyles'

const RemoveProperty = ({ market_name, state, setState, setShowModal }) => {
  const { axiomFetch } = useAxiomFetch()
  const [loading, setLoading] = useState(false)

  return (
    <div>
      <div className='px-3 text-center mb-[10px]'>
        <p>Are you sure you want to delete </p>
        <p>{state?.buyer_tours?.property_edit?.details?.prop_add}?</p>
      </div>
      <div className='flex justify-end gap-[5px]'>
        <button
          className={`${uiStyles.white_btn}`}
          onClick={() => {
            setShowModal(false)
          }}
        >
          Cancel
        </button>
        {loading ? (
          <SmallLoadingRel />
        ) : (
          <button
            className={`${uiStyles.red_btn}`}
            onClick={async () => {
              setLoading(true)
              const result = await axiomFetch(
                '/tours/property/remove',
                'DELETE',
                {
                  event_id:
                    state?.buyer_tours?.property_edit?.evt_id ||
                    state?.buyer_tours?.property_edit?.event_id,
                  remove_cont_id: state?.buyer_tours?.property_edit?.cont_id,
                  mls_id: state?.buyer_tours?.property_edit?.mls_num,
                } || {}
              )
              setState((prev) => {
                const event_tour_info = prev?.data?.event_tour_info?.filter(
                  (item) =>
                    !(
                      item.evt_id ===
                        (state?.buyer_tours?.property_edit?.evt_id ||
                          state?.buyer_tours?.property_edit?.event_id) &&
                      item.mls_num ===
                        state?.buyer_tours?.property_edit?.mls_num
                    )
                )

                const event_signup = prev?.data?.event_signup?.filter(
                  (item) =>
                    !(
                      item.event_id ===
                        (state?.buyer_tours?.property_edit?.evt_id ||
                          state?.buyer_tours?.property_edit?.event_id) &&
                      item.list_id ===
                        state?.buyer_tours?.property_edit?.mls_num
                    )
                )

                set_storage('listing_tours', {
                  ...prev?.data,
                  event_tour_info,
                  event_signup,
                })
                return {
                  ...prev,
                  data: { ...prev?.data, event_tour_info, event_signup },
                }
              })
              setLoading(false)
              setShowModal(false)
            }}
          >
            Confirm
          </button>
        )}
      </div>
    </div>
  )
}

export default RemoveProperty
