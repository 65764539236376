import Wrapper from '../../components/layout/Wrapper'
import { Tour } from '../../components/pages/tours/'
import EditCardServices from '../../components/pages/VBCDetails/EditCardServices'
import SubNav from '../../components/SubNav'
import { useVBCs } from '../../contexts/VBCContext'
const PageVBCServices = () => {
  const { VBCs, saveVBCs } = useVBCs()
  return (
    <Wrapper>
      <SubNav />
      <EditCardServices state={VBCs} setState={saveVBCs} />
    </Wrapper>
  )
}

export default PageVBCServices
