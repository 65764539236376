import React, { useCallback, useEffect, useRef, useState } from 'react'

import Wrapper from '../layout/Wrapper'
import SubNav from '../SubNav'

import { useParams } from 'react-router'
import { FaCompress, FaCopy, FaExpand } from 'react-icons/fa'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@tanstack/react-query'
import { useProfile } from '../../contexts/UserContext'
import { get_storage } from '../../helpers'

export default function URLViewer({ url_link = '', url_name = '' }) {
  const [docFullView, setDocFullView] = useState(false)
  const [pdf, setPdf] = useState({})
  const [linkCopied, setLinkCopied] = useState(false)
  useEffect(() => {
    const get_pdf = () => {
      const data = get_storage('pdf_view')
      setPdf(data)
    }
    get_pdf()
  }, [get_storage('pdf_view')?.url_link])
  const iframe = (pdf?.url_link || url_link) && (
    <iframe
      title={pdf?.url_name || url_name}
      src={pdf?.url_link || url_link}
      width='1400'
      height='100%'
      className='absolute left-0 right-0 top-0  '
    />
  )

  return (
    <>
      <Wrapper>
        <SubNav />
        <div className='flex flex-col gap-[5px] px-3'>
          {linkCopied ? (
            <p className='h-[10px]'>Link Copied!</p>
          ) : (
            <p className='h-[10px]'></p>
          )}
          <div
            className='flex gap-[10px] items-center'
            onClick={() => {
              navigator.clipboard.writeText(pdf?.url_link)
              setLinkCopied(true)
              setTimeout(() => {
                setLinkCopied(false)
              }, [2000])
            }}
          >
            <p className='max-w-[300px] overflow-x-scroll whitespace-nowrap'>
              {pdf?.url_link}
            </p>
            <FaCopy />
          </div>
        </div>
        {/* {showPad && <SignPad onClose={setShowPad.bind(this, false)} />} */}
        <div className='relative h-[calc(100vh-170px)] pt-5 border border-t-8 border-gray-200'>
          <div className='absolute left-0 top-0 right-0 bottom-0 overflow-auto w-full'>
            {iframe}
          </div>
          <div className='absolute bottom-5 right-5 w-8 h-8 rounded-full bg-accent-250/80 flex items-center justify-center shadow-2xl shadow-accent-250'>
            <FaExpand
              className='  text-white text-xl cursor-pointer'
              onClick={() => setDocFullView(true)}
            />
          </div>
        </div>
      </Wrapper>

      {docFullView && (
        <div className='fixed left-0 top-0 w-full h-full bg-white'>
          <div className='overflow-scroll absolute left-0 right-0 top-0 bottom-0'>
            <iframe
              title={pdf?.url_name || url_name}
              src={pdf?.url_link || url_link}
              width='100%'
              height='100%'
              className='absolute left-0 right-0 top-0  min-w-[1400px]'
            />
          </div>
          <FaCompress
            className='rounded-full absolute bottom-3 right-3 w-10 h-10 bg-accent-250/80 p-2   text-white text-3xl cursor-pointer shadow-lg'
            onClick={() => setDocFullView(false)}
          />
        </div>
      )}
    </>
  )
}
