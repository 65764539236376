import { useRef, useEffect, useState } from 'react'
import { validate_number } from '../../helpers'

const LabelSlider = ({
  label,
  step = 1,
  name,
  value,
  handle_change = (e) => {},
  show_count = false,
  ...restProps
}) => {
  const ref = useRef(null)
  const [positionOffset, setPositionOffset] = useState(0)
  useEffect(() => {
    const input = ref.current
    const min = input.getAttribute('min')
    const max = input.getAttribute('max')
    const range = validate_number(max) - validate_number(min)
    const position = (value - min / range) * 100
    const thumb_width = value > 250 ? 0.635 : 0.63
    const position_offset =
      Math.round((thumb_width * position) / 100) - thumb_width / 2
    setPositionOffset(position_offset)
  }, [value])
  return (
    <div className='mt-[10px] relative flex flex-col w-full'>
      <label htmlFor={name}>{label}</label>
      {show_count ? (
        <div
          className={`absolute top-[-17px]`}
          style={{
            left: `${
              value > 9 ? positionOffset : value > 1 ? positionOffset + 3 : 5
            }px`,
          }}
        >
          {value}
        </div>
      ) : (
        ''
      )}
      <input
        id={name}
        type='range'
        step={step}
        name={name}
        value={value}
        onChange={handle_change}
        ref={ref}
        {...restProps}
      />
    </div>
  )
}

export default LabelSlider
