import { useEffect, useState } from 'react'
import { set_storage } from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { CircleCheckToggle } from '../../form/CircleCheckToggle'
import { List } from '../../helpers'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import uiStyles from '../../ui/uiStyles'

const OptimizeRoute = ({ homes, setState, setShowModal }) => {
  console.log('route', homes)
  const { axiomFetch } = useAxiomFetch()
  const [homeChecks, setHomeChecks] = useState([])
  const [loadOptimization, setLoadOptimization] = useState(false)
  console.log('homechecks', homeChecks)

  useEffect(() => {
    const get_home_checks = () => {
      const home_checks = {}
      ;(homes || [])
        ?.sort((a, b) => a?.tour_order - b?.tour_order)
        ?.forEach((item) => {
          if (item.tour_order === homes?.[0]?.tour_order) {
            item.is_first_home = 'Y'
          } else {
            item.is_first_home = 'N'
          }
          home_checks[item?.evt_tour_id] = item
        })
      setHomeChecks(home_checks)
    }
    get_home_checks()
    return () => get_home_checks()
  }, [])
  return (
    <div>
      <div>
        {homes?.length > 2 ? (
          <div>
            <div className='overflow-y-scroll min-h-[100px] max-h-[430px]'>
              <p className='mb-[10px] font-bold'>Select home to start at: </p>
              {homes
                ?.sort((a, b) => a.tour_order - b.tour_order)
                ?.map((item) => {
                  return (
                    <div key={item?.evt_tour_id}>
                      <div
                        className='flex gap-[10px] mb-[10px] cursor-pointer'
                        onClick={() => {
                          const home_checks_arr = {}
                          console.log('item', item)
                          homes?.forEach((home) => {
                            console.log('home', home)
                            if (item?.evt_tour_id === home?.evt_tour_id) {
                              console.log('change', home)
                              home.is_first_home = 'Y'
                            } else {
                              home.is_first_home = 'N'
                            }
                            home_checks_arr[home?.evt_tour_id] = home
                          })
                          setHomeChecks(home_checks_arr)
                        }}
                      >
                        <div>
                          <p>{item?.address}</p>
                          <p>mls #: {item?.mls_num}</p>
                        </div>
                        <div className='mt-[5px]'>
                          <CircleCheckToggle
                            name={`is_first_home`}
                            type='check'
                            toggle_left={true}
                            toggle_left_label={'Assisted Ai'}
                            toggle_right_label={'Advanced Ai'}
                            inputValue={homeChecks[item?.evt_tour_id] || {}}
                            handle_change={async (e) => {}}
                          />
                        </div>
                      </div>
                      <div className={`${uiStyles.line} mb-[10px]`}></div>
                    </div>
                  )
                })}
            </div>
            <div className='flex justify-end gap-[10px]'>
              {loadOptimization ? (
                <SmallLoadingRel />
              ) : (
                <button
                  className={`${uiStyles.hover_btn}`}
                  onClick={async () => {
                    setLoadOptimization(true)
                    const home_check =
                      homeChecks?.[
                        (Object.keys(homeChecks || {}) || [])?.find(
                          (key) => homeChecks[key]?.is_first_home === 'Y'
                        )
                      ] || {}
                    console.log(home_check)
                    console.log('homechecks', homeChecks)
                    const result = await axiomFetch(
                      '/tours/property/order/optimize',
                      'POST',
                      home_check
                    )
                    result.event_tour_info = result?.event_tour_info?.map(
                      (item) => {
                        return {
                          ...(item || {}),
                          details: homeChecks?.[item?.evt_tour_id]?.details,
                          listing: homeChecks?.[item?.evt_tour_id]?.details,
                        }
                      }
                    )
                    result.event_signup =
                      result?.event_signup ||
                      []?.map((item) => {
                        return {
                          ...(item || {}),
                          details: homeChecks?.[item?.evt_tour_id]?.details,
                          listing: homeChecks?.[item?.evt_tour_id]?.details,
                        }
                      })
                    console.log('result', result)
                    setState((prev) => {
                      const data = {
                        ...prev?.data,
                        event_tour_info: [
                          ...prev?.data?.event_tour_info?.filter(
                            (tour_info) =>
                              !(
                                tour_info?.evt_id === home_check?.evt_id &&
                                tour_info.cont_id > 0 &&
                                tour_info?.mls_num
                              )
                          ),
                          ...(result?.event_tour_info || []),
                        ],
                        event_signup: [
                          ...prev?.data?.event_signup?.filter(
                            (signup_info) =>
                              !(
                                signup_info?.event_id === home_check?.evt_id &&
                                signup_info.cont_id > 0 &&
                                signup_info?.list_id
                              )
                          ),
                          ...(result?.event_signup || []),
                        ],
                      }
                      set_storage('listing_tours', data)
                      return { ...prev, data }
                    })
                    setLoadOptimization(false)
                    setShowModal(false)
                  }}
                >
                  Optimize Route
                </button>
              )}
            </div>
          </div>
        ) : (
          <p>You'll need at-least 3 homes to optimize a route.</p>
        )}
      </div>
    </div>
  )
}

export default OptimizeRoute
