import { useState } from 'react'
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md'
import { useListingTours } from '../../../contexts/ListingToursContext'
import { set_storage } from '../../../helpers'
import Modal from '../../Modal'
import Itinerary from './Itinerary'

const Navigation = ({ state, setState }) => {
  const { get_event_type_id, get_event_tour_type_id } = useListingTours()
  const [showModal, setShowModal] = useState(false)
  console.log('tour_state', state?.tour_state)
  const photo =
    state?.data?.tour_types?.find((item) => item?.type_id === 1)?.van_photo ||
    'https://driggstitle.com/reviews/Ax_Engage/dbc/DTATOourMercedes1699366270202.jpg'

  const go = (page_change) => {
    setState((prev) => {
      const new_tour_state = {
        ...prev?.tour_state,
      }
      const get_page = () => {
        const new_page = (prev?.tour_state?.current_page || 0) + page_change
        if (new_page <= 0) {
          return 1
        }

        const tour_type_id = get_event_tour_type_id(
          Number(state?.tour_state?.event_id)
        )

        console.log('tour_type', tour_type_id)
        const eo_id = state?.data?.event_pages?.find(
          (item) =>
            Number(item?.event_id) === Number(state?.tour_state?.event_id)
        )?.eo_of_focus

        const product_page_applied = state?.data?.tour_type_pages_applied?.find(
          (item) =>
            item.type_id === tour_type_id &&
            item.page_id === 4 &&
            item.page_ord > 0
        )

        console.log('product_page', product_page_applied)
        let product_pages_count = product_page_applied
          ? state?.data?.tour_opening_dta?.filter(
              (item) =>
                item.tour_page_id === 4 &&
                product_page_applied?.applied_id === item.applied_page_id
            )?.length
          : 0
        if (tour_type_id > 1) {
          product_pages_count =
            state?.data?.event_tour_ann?.filter(
              (item) =>
                Number(item.event_id) === Number(state?.tour_state?.event_id)
            )?.length || 0
        }

        console.log('product_pages_count', product_pages_count)

        const listing_pages = state?.data?.event_tour_info?.filter(
          (item) =>
            item?.cont_id > 0 &&
            item?.mls_num &&
            item?.evt_id?.toString() === state?.tour_state?.event_id?.toString()
        )?.length

        if (!listing_pages && new_tour_state.current_page === 6) {
          new_tour_state.listing_page = 0
          new_tour_state.product_page = 0
          new_tour_state.eo_page = 0
          if (page_change > 1) {
            return 6
          }
          if (new_tour_state?.product_page > 0) {
            return 4
          }
          return 1
        }

        if (
          listing_pages <
          (prev?.tour_state?.listing_page || 0) + page_change
        ) {
          console.log('hello page 6')
          new_tour_state.listing_page =
            (prev?.tour_state?.listing_page || 0) + page_change
          return 6
        }

        if (new_page % 3 === 0) {
          if (new_tour_state?.product_page > 0 && page_change < 0) {
            if (!eo_id) {
              new_tour_state.product_page -= 1
              if (new_tour_state?.product_page === 0) {
                return 2
              }
              return 5
            }
            new_tour_state.product_page -= 1
          }
          if (page_change > 0) {
            if (!eo_id) {
              new_tour_state.product_page =
                (prev?.tour_state?.product_page || 0) + page_change
              if (
                product_pages_count <
                (prev?.tour_state?.product_page || 0) + page_change
              ) {
                new_tour_state.listing_page =
                  (prev?.tour_state?.listing_page || 0) + page_change
                return 5
              }
              return 4
            }
            new_tour_state.eo_page =
              (prev?.tour_state?.eo_page || 0) + page_change
          }
          return 3
        } else if (new_page % 4 === 0) {
          console.log('listing_page', new_tour_state?.listing_page)
          if (new_tour_state?.listing_page > 0 && page_change < 0) {
            new_tour_state.listing_page -= 1
            if (product_pages_count < (prev?.tour_state?.product_page || 0)) {
              if ((new_tour_state?.product_page || 0) > 0) {
                new_tour_state.product_page -= 1
              }
              if (!eo_id) {
                if (new_tour_state?.listing_page === 0) {
                  return 2
                } else {
                  return 5
                }
              }
              if (new_tour_state?.listing_page === 0) {
                return 3
              } else {
                return 5
              }
            }
          }
          if (page_change > 0) {
            new_tour_state.product_page =
              (prev?.tour_state?.product_page || 0) + page_change
          }
          return 4
        } else if (new_page % 5 === 0) {
          new_tour_state.listing_page =
            (prev?.tour_state?.listing_page || 0) + page_change
          return 5
        } else if (new_page < 3 && prev?.tour_state?.listing_page > 0) {
          if (new_tour_state?.eo_page > 0 && page_change < 0) {
            new_tour_state.eo_page -= 1
          }
          return 5
        }
        if (new_tour_state?.eo_page > 0 && page_change < 0) {
          new_tour_state.eo_page -= 1
        }
        return prev?.tour_state?.current_page + page_change <= 1
          ? 1
          : (prev?.tour_state?.current_page || 1) + page_change
      }

      new_tour_state.current_page = get_page()

      set_storage('listing_tours_state', new_tour_state)
      return {
        ...prev,
        tour_state: new_tour_state,
      }
    })
  }

  return (
    <div className='flex justify-between items-center mx-[30px] py-[20px]'>
      <div
        className='flex items-center cursor-pointer'
        onClick={() => {
          go(-1)
        }}
      >
        <span className=''>
          <MdArrowBackIosNew size={20} />
        </span>
        <span className='text-[16px]'>Prev </span>
      </div>
      <img
        className='w-[100px] cursor-pointer'
        src={photo}
        alt='Driggs Title Van'
        onClick={() => {
          setShowModal(true)
        }}
      />
      <div
        className='flex items-center cursor-pointer'
        onClick={() => {
          go(1)
        }}
      >
        <span className='text-[16px]'>Next </span>
        <span className='mt-[1px] flex justify-center'>
          <MdArrowForwardIos size={20} />
        </span>
      </div>
      {showModal ? (
        <Modal
          modalName={'tour_itinerary'}
          setShowModal={setShowModal}
          showModal={showModal}
          showClose={true}
          width={'w-full'}
        >
          <Itinerary
            state={state}
            setState={setState}
            setItineraryModal={setShowModal}
          />
        </Modal>
      ) : (
        ''
      )}
    </div>
  )
}

export default Navigation
