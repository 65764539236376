import React from 'react'
import CalcCircle from './CalcCircle'
import CalcCompareCard from './CalcCompareCard'

const CalcCompareRow = ({
  card_1 = {},
  card_2 = {},
  circle_1 = {},
  circle_2 = {},
}) => {
  return (
    <div className='flex items-start gap-[12px] mx-[3px]'>
      {Object.keys(card_1).length ? <CalcCompareCard {...card_1} /> : ''}
      {Object.keys(card_2).length ? <CalcCompareCard {...card_2} /> : ''}
      {Object.keys(circle_1).length ? (
        <CalcCircle {...circle_1} shrink_text={true} />
      ) : (
        ''
      )}
      {Object.keys(circle_2).length ? (
        <CalcCircle {...circle_2} shrink_text={true} />
      ) : (
        ''
      )}
    </div>
  )
}

export default CalcCompareRow
