import { useState, useEffect } from 'react'
import { AiFillDelete } from 'react-icons/ai'
import { TfiPencilAlt } from 'react-icons/tfi'
import { useNavigate } from 'react-router-dom'
import { useListingTours } from '../../../contexts/ListingToursContext'
import { useReceiver } from '../../../contexts/ReceiverContext'
import { set_storage } from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { List } from '../../helpers'
import Modal from '../../Modal'
import uiStyles from '../../ui/uiStyles'
import EditBuyer from './EditBuyer'
import EditProperty from './EditProperty'
import OptimizeRoute from './OptimizeRoute'
import RemoveProperty from './RemoveProperty'

const AddHome = () => {
  const { listingTours, saveListingTours, save_home, get_buyers, get_homes } =
    useListingTours()
  const { axiomFetch } = useAxiomFetch()
  const { receiver, saveReceiver } = useReceiver()
  const [homes, setHomes] = useState([])
  const [inputValue, setInputValue] = useState(
    listingTours?.buyer_tours?.buyer_edit || {}
  )
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('')

  const modals = {
    edit_property: (
      <EditProperty
        state={listingTours}
        setState={saveListingTours}
        save_home={save_home}
        setShowModal={setShowModal}
      />
    ),
    remove_property: (
      <RemoveProperty
        state={listingTours}
        setState={saveListingTours}
        save_home={save_home}
        setShowModal={setShowModal}
      />
    ),
    optimize_route: (
      <OptimizeRoute
        state={listingTours}
        setState={saveListingTours}
        save_home={save_home}
        homes={homes}
        setShowModal={setShowModal}
      />
    ),
  }

  useEffect(() => {
    if (!listingTours?.buyer_tours) {
      navigate('/user/buyer_tours')
    }
  }, [])
  useEffect(() => {
    const data = listingTours?.data?.event_tour_info
      ?.filter(
        (item) =>
          item?.evt_id?.toString() ===
            listingTours?.buyer_tours?.event_edit?.event_id?.toString() &&
          item?.cont_id > 0 &&
          item?.mls_num
      )
      ?.sort((a, b) => a.tour_order - b.tour_order)
      ?.map((item) => {
        item.title = item.agent_name
        item.content = item.agent_cell_phone
        if (item.details) {
          item.listing = item.details
        }
        item.swipe_right = () => {
          return (
            <div className='flex gap-[20px]'>
              <div
                className='cursor-pointer'
                onClick={() => {
                  saveListingTours((prev) => {
                    const ret_data = {
                      ...prev?.buyer_tours,
                      property_edit: item,
                    }
                    set_storage('buyer_tours', ret_data)
                    return { ...prev, buyer_tours: ret_data }
                  })
                  setModalType('remove_property')
                  setShowModal(true)
                }}
              >
                <AiFillDelete size={25} />
              </div>
            </div>
          )
        }
        return item
      })
    setHomes(data)
  }, [listingTours?.data?.event_tour_info])

  const create_home = (type) => {
    const property_obj = {}
    if (type === 'address') {
      property_obj.search_type = 'address'
    } else if (type === 'buyer_favorite') {
      property_obj.search_type = 'buyer_favorite'
    } else if (type === 'mls_num') {
      property_obj.search = 'mls_num'
      setModalType('edit_property')
    } else {
      property_obj.search = 'mls_num'
    }

    saveListingTours((prev) => {
      const ret_data = { ...prev?.buyer_tours, property_edit: property_obj }
      set_storage('buyer_tours', ret_data)
      return { ...prev, buyer_tours: ret_data }
    })
    if (type === 'mls_num') {
      setShowModal(true)
    } else if (type === 'address') {
      if (receiver?.orig_homes?.count > 0) {
        return navigate('/homes/list', { state: { parent: '/homes' } })
      }
      return navigate('/homes', {
        state: { parent: '/user/buyer_tours/add_home' },
      })
    } else if (type === 'buyer_favorites') {
      return navigate('/user/buyer_tours/buyer_favorites')
    }
  }

  return (
    <div>
      <p className={`px-3 mb-[5px] ${uiStyles.text_black_subtitle}`}>
        Homes on Tour
      </p>
      <div className='overflow-y-scroll min-h-[100px] max-h-[400px]'>
        <List
          add_arrow_order={true}
          handle_arrow={async (item, list, index, dir) => {
            const get_old_order =
              list[index + (dir === 'up' ? 1 : 0)]?.tour_order
            const get_new_order =
              list[index + (dir === 'down' ? 1 : 0)]?.tour_order
            const re_ordered_list = list?.map((item) => {
              if (item.tour_order === get_old_order) {
                item.tour_order = get_new_order
                return item
              } else if (item.tour_order === get_new_order) {
                item.tour_order = get_old_order
                return item
              } else {
                return item
              }
            })

            const re_ordered_events = listingTours?.data?.event_signup?.map(
              (item) => {
                const tour_order = re_ordered_list?.find(
                  (tour_info) => item?.evt_tour_id === tour_info?.evt_tour_id
                )?.tour_order
                item.map_ord = tour_order
                return item
              }
            )
            saveListingTours((prev) => {
              const data = {
                ...prev?.data,
                event_tour_info: [
                  ...prev?.data?.event_tour_info?.filter(
                    (tour_info) =>
                      !(
                        tour_info?.evt_id === item?.evt_id &&
                        tour_info.cont_id > 0 &&
                        tour_info?.mls_num
                      )
                  ),
                  ...re_ordered_list,
                ],
                event_signup: [
                  ...prev?.data?.event_signup?.filter(
                    (signup_info) =>
                      !(
                        signup_info?.event_id === item?.evt_id &&
                        signup_info.cont_id > 0 &&
                        signup_info?.list_id
                      )
                  ),
                  ...re_ordered_events,
                ],
              }
              set_storage('listing_tours', data)
              return { ...prev, data }
            })
            await axiomFetch('/tours/property/order', 'POST', {
              list: re_ordered_list,
            })
          }}
          no_items_msg={'Add a property to the tour.'}
          list={homes}
          property_component={true}
          reset_slider={true}
        />
      </div>
      <p className='px-3 font-bold'>Add Property Via:</p>
      <div className='px-3 mt-3 flex justify-end gap-[10px]'>
        <button
          className={`${uiStyles.hover_btn} text-[12px]`}
          onClick={() => {
            create_home('mls_num')
          }}
        >
          MLS ID
        </button>
        <button
          className={`${uiStyles.hover_btn_small}`}
          onClick={() => {
            saveReceiver((prev) => ({ ...prev, edit_mode: '' }))
            create_home('address')
          }}
        >
          Home Search
        </button>
        <button
          className={`${uiStyles.hover_btn_small}`}
          onClick={() => {
            create_home('buyer_favorites')
          }}
        >
          {listingTours?.buyer_tours?.event_type_id === 162
            ? 'Buyer Favorites'
            : 'Agent Homes'}
        </button>
      </div>
      <div className={`${uiStyles.line_3px} mt-[10px]`}></div>
      <div className='px-3 mt-3 flex justify-end gap-[10px]'>
        <div className='flex gap-[10px] pb-[10px]'>
          <button
            className={`${uiStyles.hover_btn_small}`}
            onClick={() => {
              setModalType('optimize_route')
              setShowModal(true)
            }}
          >
            Optimize Route
          </button>
          <button
            className={`${uiStyles.hover_btn_small}`}
            onClick={() => {
              navigate('/user/buyer_tours/add_buyer')
            }}
          >
            {listingTours?.buyer_tours?.event_type_id === 162
              ? 'Add Buyers'
              : 'Add Agents'}
          </button>
          <button
            className={`${uiStyles.white_btn}`}
            onClick={() => {
              return navigate(
                listingTours?.buyer_tours?.full_run
                  ? '/user/buyer_tours'
                  : '/user/buyer_tours/send_to_buyers'
              )
            }}
          >
            {listingTours?.buyer_tours?.full_run ? 'Done' : 'Next'}
          </button>
        </div>
      </div>
      {showModal ? (
        <Modal
          modalName='create_buyer_tour'
          showModal={showModal}
          setShowModal={setShowModal}
          // width={'w-[350px]'}
          side_padding={['add_buyer'].includes(modalType) ? 'px-0' : ''}
          height={
            modalType === 'edit_buyer'
              ? 'h-[300px]'
              : modalType?.includes('remove')
              ? 'h-[175px]'
              : 'h-[550px]'
          }
        >
          {modals?.[modalType]}
        </Modal>
      ) : (
        ''
      )}
    </div>
  )
}

export default AddHome
