import React, { useEffect } from 'react'
import { useConnections } from '../../../contexts/ConnectionContext'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import uiStyles from '../../ui/uiStyles'
import { Connection } from './Connection'
export const ConnectedAccounts = () => {
  const { connections, saveConnections } = useConnections()
  const { axiomFetch } = useAxiomFetch()
  console.log(connections)
  useEffect(() => {
    const controller = new AbortController()
    const check_connections = async () => {
      const connections = await axiomFetch(
        '/usage/checkSocialConnections',
        'GET',
        {},
        {},
        false,
        controller.signal
      )
      saveConnections(connections)
    }
    check_connections()
    return () => {
      controller?.abort()
    }
    // eslint-disable-next-line
  }, [])

  const filter_out_arr = ['axiom_intro_video', 'stack', 'instagram']
  return (
    <div className='px-3'>
      <p className={uiStyles.text_black_title}>Connected Accounts</p>
      <div className={`${uiStyles.line_3px} mt-[10px]`}></div>
      {Object.keys(connections || {})
        ?.filter((key) => !filter_out_arr.includes(key))
        ?.sort()
        ?.map((key) => {
          return (
            <div className='mt-[20px]' key={key}>
              <Connection conn_key={key} value={connections[key]} />
              <div className={`${uiStyles.line_light} mt-[10px]`}></div>
            </div>
          )
        })}
    </div>
  )
}
