import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMerchant } from '../../../contexts/MerchantContext'
import { usePairwise } from '../../../contexts/PairwiseContext'
import { useProfile } from '../../../contexts/UserContext'
import {
  formatPhoneNumber,
  format_currency,
  getDateString,
  set_storage,
  validate_number,
} from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import Modal from '../../Modal'
import uiStyles from '../../ui/uiStyles'
import { PayCard } from '../Billing/PayCard'
import PairwiseCancel from './PairwiseCancel'
import PairwiseCancelFailed from './PairwiseCancelFailed'
import PairwiseIneligible from './PairwiseIneligible'
const PairwisePayments = () => {
  const { pairwise, savePairwise } = usePairwise()
  const { merchant, saveMerchant } = useMerchant()
  const { profile } = useProfile()
  const { axiomFetch } = useAxiomFetch()
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const modals = {
    cancel: {
      component: (
        <PairwiseCancel
          showModal={showModal}
          setShowModal={setShowModal}
          setModalType={setModalType}
        />
      ),
      height: 'h-[350px]',
    },
    cancel_failed: {
      component: <PairwiseCancelFailed setShowModal={setShowModal} />,
      height: 'h-[300px]',
    },
  }

  useEffect(() => {
    saveMerchant((prev) => ({
      ...prev,
      payment: {
        ...prev?.payment,
        payment_successful: false,
        payment_failed: false,
      },
    }))
  }, [pairwise?.data?.pair_application])
  // console.log(
  //   'pairwise',
  //   pairwise,
  //   pairwise?.data?.pair_entities?.find(
  //     (item) => item?.pair_id === pairwise?.data?.pair_application?.pair_id
  //   )?.cost_per_share
  // )

  const get_payment_amount = () => {
    return (
      validate_number(pairwise?.data?.pair_application?.number_of_shares) *
      validate_number(
        pairwise?.data?.pair_entities?.find(
          (item) => item?.pair_id === pairwise?.data?.pair_application?.pair_id
        )?.cost_per_share || 500
      )
    )
  }
  const get_quantity = () => {
    return pairwise?.data?.pair_application?.number_of_shares
  }

  const modal = (
    <>
      {showModal ? (
        <Modal
          side_padding={'px-0'}
          modalName={modalType}
          height={modals?.[modalType]?.height || 'h-[550px]'}
          width={'w-full'}
          showModal={showModal}
          setShowModal={(type) => {
            setShowModal(false)
          }}
          showClose={true}
        >
          {modals?.[modalType]?.component || <></>}
        </Modal>
      ) : (
        ''
      )}
    </>
  )
  if (
    pairwise?.data?.pair_entities?.length < 1 &&
    !pairwise?.data?.pair_application?.payment_setup_dt
  ) {
    return (
      <>
        <PairwiseIneligible /> {modal}
      </>
    )
  }

  if (pairwise?.data?.pair_application?.payment_received_dt) {
    return (
      <div className='px-3 flex flex-col gap-[10px]'>
        <p className={`${uiStyles.text_black_subtitle} `}>
          Your payment has been Received!
        </p>
        {/* <p>
          {' '}
          Pair Entity:{' '}
          {
            pairwise?.data?.pair_entities?.find(
              (item) =>
                item.pair_id ===
                validate_number(pairwise?.data?.pair_application?.pair_id)
            )?.pair_name
          }
        </p> */}
        <p className='text-[15px]'>
          Payment Received:{' '}
          {getDateString({
            date: pairwise?.data?.pair_application?.payment_received_dt,
            format: 'mmm dd, yyyy hh:mi am',
          })}{' '}
        </p>
        <p className='text-[15px]'>
          Shares Purchased: {pairwise?.data?.pair_application?.number_of_shares}{' '}
        </p>
        <button
          className={uiStyles.white_btn}
          onClick={() => {
            navigate('/user/pairwise')
          }}
        >
          Back
        </button>
        {modal}
      </div>
    )
  }

  if (pairwise?.data?.pair_application?.payment_setup_dt) {
    return (
      <div className='px-3 flex flex-col gap-[10px]'>
        <p className={`${uiStyles.text_black_subtitle}`}>
          Your Payment has been setup up!
        </p>
        <p>
          Payment Amount: $
          {format_currency(
            pairwise?.data?.pair_application?.payment_amt,
            false,
            2
          )}
        </p>
        <p>
          Setup Date:{' '}
          {getDateString({
            date: pairwise?.data?.pair_application?.payment_setup_dt,
            format: 'mmm dd, yyyy hh:mi am',
          })}
        </p>
        <p>
          Upon your Pairwise application approval we will bill your account. We
          will let you know when you have successfully bought{' '}
          {pairwise?.data?.pair_application?.number_of_shares} shares{'(s)'}
          {/* in{' '}
          {
            pairwise?.data?.pair_entities?.find(
              (item) =>
                item.pair_id ===
                validate_number(pairwise?.data?.pair_application?.pair_id)
            )?.pair_name
          } */}
          .
        </p>
        <div className={'flex justify-end gap-[10px]'}>
          <button
            className={uiStyles.white_btn}
            onClick={() => {
              navigate('/user/pairwise')
            }}
          >
            Back
          </button>
          <button
            className={uiStyles.red_btn}
            onClick={() => {
              setShowModal(true)
              setModalType('cancel')
              // navigate('/user/pairwise')
              // set modal then delete payment information.
              // make sure not approved.
            }}
          >
            Cancel
          </button>
        </div>
        {modal}
      </div>
    )
  }

  return (
    <div className='px-3'>
      <h1 className={`${uiStyles.text_black_subtitle} mb-[10px]`}>
        Pairwise Payment{' '}
      </h1>
      {pairwise?.data?.pair_application?.cont_id &&
      pairwise?.data?.pair_application?.pair_id &&
      pairwise?.data?.pair_application?.number_of_shares ? (
        <div>
          <PayCard
            currentOrder={{
              quantity: get_quantity(),
              total_cost: get_payment_amount(),
              bill_type: 20,
            }}
            cancel_link='/user/pairwise'
            pay_later={true}
            pay_func={async () => {
              const body = {}
              body.payment_amt = get_payment_amount()
              body.payment_token = merchant?.payment?.token
              if (!body?.payment_token) {
                return saveMerchant((prev) => ({
                  ...prev,
                  payment: {
                    ...prev?.payment,
                    payment_failed: false,
                    payment_successful: false,
                  },
                }))
              }
              body.payment_setup_dt = new Date()
              await axiomFetch('/pairwise/applications/save', 'POST', {
                application_data: body,
              })
              savePairwise((prev) => {
                const data = {
                  ...prev?.data,
                  pair_application: {
                    ...prev?.data?.pair_application,
                    ...body,
                  },
                }
                set_storage('pairwise_data', {
                  // ...(get_storage('pairwise_data') || {}),
                  ...data,
                })
                return {
                  ...prev,
                  data: data,
                }
              })
              saveMerchant((prev) => ({
                ...prev,
                payment: {
                  ...prev?.payment,
                  payment_successful: false,
                  payment_failed: false,
                },
              }))
            }}
          />
        </div>
      ) : (
        <div>
          <p>
            Be sure to figure out what{' '}
            <span className='font-bold'>pairwise entity</span> is best for you!
          </p>
          <p className='mt-[5px]'>
            Then after you have your{' '}
            <span className='font-bold'>documents</span> filled out check back
            here to make a payment.
          </p>
          <div className='flex justify-end gap-[5px] mt-[10px]'>
            <button
              className={`${uiStyles.hover_btn_small}`}
              onClick={() => {
                navigate('/user/pairwise/estimator')
              }}
            >
              Pairwise Eligibility
            </button>
            <button
              className={`${uiStyles.hover_btn_small}`}
              onClick={() => {
                navigate('/user/pairwise/documents/sign')
              }}
            >
              Pairwise Documents
            </button>
          </div>
        </div>
      )}
      {modal}
    </div>
  )
}

export default PairwisePayments
