import React, { memo } from 'react'
import { HiArrowLeft } from 'react-icons/hi2'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import Driggslogo from '../assets/images/axiom_driggs_logo.svg'
import images_logos from '../assets/images/axiom_logos'
import ch_images from '../assets/images/conversations'
import images_tours from '../assets/images/listing_tours'
import { useAi } from '../contexts/AiContext'
import { useClaims } from '../contexts/ClaimsContext'
import { useConversations } from '../contexts/ConversationsContext'
import { useListingTours } from '../contexts/ListingToursContext'
import { CircleCheckToggle } from './form/CircleCheckToggle'
import Bugs from './layout/Bugs'
import { NotificationsIcon } from './pages/conversations/NotificationsIcon'
function SubNav({
  url,
  logo_url = '/user',
  replace_url = false,
  state,
  showLogo = true,
  showBack = true,
  type = 'contId',
  showConversations = true,
  showTours = true,
  space_back_btn = false,
}) {
  const { conversations, campaigns, convMessages, loadingConversations } =
    useConversations()
  const { claims, saveClaims } = useClaims()
  const { listingTours } = useListingTours()
  const { ai, saveAi } = useAi()
  const get_count = (id) => {
    let total = 0
    if (id === 'conversations') {
      for (const conversation of conversations) {
        total += convMessages?.[conversation?.sid]?.unread_count || 0
      }
      for (const campaign of Object.values(campaigns)) {
        for (const conv of campaign?.conversations) {
          total += convMessages[conv?.sid]?.unread_count || 0
        }
      }
    }
    return total
  }
  const navigate = useNavigate()
  const { state: location_state, pathname: path } = useLocation()
  const location = useLocation()
  const all_state = { ...state, ...location_state }
  return (
    <div className='flex flex-col py-5'>
      <div className='text-primary px-2 flex justify-between items-start'>
        {showBack === true ? (
          <span
            className='flex items-center cursor-pointer'
            onClick={() => {
              console.log('hit')

              if (ai?.assistant?.toggle_back && path === '/user/ai/assistant') {
                return saveAi((prev) => ({
                  ...prev,
                  assistant: {
                    ...prev?.assistant,
                    is_assistant: 'Y',
                    toggle_back: false,
                  },
                }))
              }
              if (all_state?.parent) {
                navigate(all_state?.parent, {
                  replace: replace_url,
                  state: {
                    ...all_state,
                    parent: all_state?.prevParent,
                    prevParent: '',
                    select: all_state?.select,
                  },
                })
              } else {
                console.log('replace_url', replace_url)
                navigate(url ? url : -1, {
                  replace: replace_url,
                  state: all_state,
                })
              }
            }}
          >
            <HiArrowLeft className='mr-2' />

            <span className='text-sm font-base'>Back</span>
          </span>
        ) : (
          showBack
        )}
        <div
          className={`flex gap-[16px] items-start ${
            space_back_btn ? 'ml-[20px]' : ''
          }`}
        >
          <Bugs />

          {showConversations && type !== 'vbc' ? (
            <div
              className='flex gap-[2px] items-center cursor-pointer mt-[3px]'
              onClick={() => {
                navigate('/user/conversations')
              }}
            >
              <img src={ch_images.add_blue} className='w-[20px]' />
              <NotificationsIcon
                count={get_count('conversations')}
                loading={loadingConversations}
              />
            </div>
          ) : (
            ''
          )}
          {showTours &&
          type !== 'vbc' &&
          listingTours?.data?.event_pages?.find(
            (item) =>
              Number(item?.event_id) ===
              Number(listingTours?.tour_state?.event_id)
          ) ? (
            <div
              className='flex gap-[2px] cursor-pointer'
              onClick={() => {
                navigate('/user/listing_tours')
              }}
            >
              <img src={images_tours.home_blue} />
            </div>
          ) : (
            ''
          )}
          {showLogo && (
            <img
              src={images_logos.axiom_small_png}
              alt=''
              className='w-[40px] h-[33px] mr-[5px] cursor-pointer'
              onClick={() => navigate(logo_url)}
            />
          )}
        </div>
      </div>
      {/* {claims?.user_id && (
        <div className='w-full flex justify-center mt-[10px]'>
          <CircleCheckToggle
            name={`is_internal`}
            type='toggle'
            toggle_left={true}
            toggle_left_label={'Employee View'}
            toggle_right_label={'Agent View'}
            inputValue={claims}
            handle_change={async (e) => {
              saveClaims((prev) => ({
                ...prev,
                is_internal: prev?.is_internal === 'Y' ? 'N' : 'Y',
              }))
            }}
          />
        </div>
      )} */}
    </div>
  )
}

export default memo(SubNav)
