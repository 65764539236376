import { useState, useRef, useEffect } from 'react'
import Chart from 'react-apexcharts'
import { useCalc } from '../../contexts/CalcContext'
import {
  calculate_amortization,
  calculate_amortization_totals,
  calculate_monthly_payment,
  calculate_principal,
  convert_percent,
  find_closest_ge,
  format_currency,
  format_input,
  format_number,
  getTextWidth,
  get_loan_balance,
  get_storage,
  get_uuid,
  remove_index,
  remove_keys_by_suffix,
  show_decimal,
  validate_number,
} from '../../helpers'
import {
  GoogleMap,
  Autocomplete,
  useJsApiLoader,
  CircleF,
  MarkerF,
  InfoBoxF,
  InfoWindowF,
} from '@react-google-maps/api'
import { libraries } from '../../components/pages/home/utils'
import LabelInput from '../form/LabelInput'
import { Schedule } from '../form/Schedule'
import Select from '../form/Select'
import Modal from '../Modal'
import uiStyles from '../ui/uiStyles'
import CalcPaymentChange from './CalcPaymentChange'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import { SmallLoadingRel } from '../../components/ui/SmallLoadingRel'
import CalcOptions from './CalcOptions'
import ButtonsRow from '../ui/ButtonsRow'
import { SwiperSlide, Swiper } from 'swiper/react'
import CalcBuyDetails from './CalcBuyDetails'
import CalcAddressFound from './CalcAddressFound'
import { useProfile } from '../../contexts/UserContext'
import { AiFillCalculator } from 'react-icons/ai'
import { FaCalculator } from 'react-icons/fa'
import CalcNetCloseChange from './CalcNetCloseChange'
import CalcLoanBalance from './CalcLoanBalance'
import CalcSellHold from './CalcSellHold'
import CalcSellMultipleOffers from './CalcSellMultipleOffers'
import CalcShare from './CalcShare'
import CalcSalePriceChange from './CalcSalePriceChange'
import CalcBackLobby from './CalcBackLobby'
const CalcSell = ({ implementation_type = 'sell' }) => {
  const {
    calc,
    saveCalc,
    get_nums,
    get_pmi,
    get_pmi_from_loan,
    get_ltv,
    remove_mip_base,
    get_mip_base,
    get_yearly_mip,
    get_fees_total,
    get_title_fee_total,
    swiper_slide,
    get_calc_type_id,
    loans,
    find_county,
    get_loan_type,
    get_title_fees,
    calc_tax_proration,
    calc_transfer_tax,
  } = useCalc()
  const chartRef = useRef()
  const totalRef = useRef()
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAdJBLTyoh5xMQujUK488i-am9syWuiG6A',
    libraries: libraries,
  })
  const timeoutRef = useRef()
  const countRef = useRef(0)
  const { axiomFetch } = useAxiomFetch()
  const { profile } = useProfile()
  const [error, setError] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const labels_html = (labels, values, margin) => {
    return (
      <div className={`${margin}`}>
        {labels?.map((item, index, arr) => {
          // const largest_length = arr.reduce((curr, val, index) => {
          //   return format_currency(values[index])?.length + val?.length >= curr
          //     ? format_currency(values[index])?.length + val?.length
          //     : curr
          // }, 0)
          return (
            <div key={item} className='flex items-center gap-[10px] text-xs'>
              <div
                className={`w-[10px] h-[10px] rounded-full`}
                style={{
                  backgroundColor:
                    calc?.sell?.net_at_close_chart?.options?.colors[
                      index - 1
                    ] || 'lightgray',
                }}
              ></div>
              {item} {''.padEnd(20 - item.length, '_')} $
              {item?.toLowerCase()?.trim()?.replaceAll(' ', '_') ===
              'net_at_close'
                ? calc?.sell?.net_at_close_chart?.negative_at_close || ''
                : ''}
              {format_currency(
                values[index],
                false,
                2,
                null,
                show_decimal(values?.[index]) ? 2 : 0
              )}
            </div>
          )
        })}
      </div>
    )
  }

  const modals = {
    options: {
      component: (
        <CalcOptions
          showModal={showModal}
          setShowModal={setShowModal}
          calculator_type='sell'
        />
      ),
      height: 'h-[550px]',
    },
    address_found: {
      component: (
        <CalcAddressFound
          showModal={showModal}
          setShowModal={setShowModal}
          calculator_type='sell'
          setModalType={setModalType}
        />
      ),
      height: 'h-[550px]',
    },
    net_at_close: {
      component:
        implementation_type === 'sell_to_net' ? (
          <CalcSalePriceChange
            implementation_type={implementation_type}
            showModal={showModal}
            setShowModal={setShowModal}
          />
        ) : (
          <CalcNetCloseChange
            implementation_type={implementation_type}
            showModal={showModal}
            setShowModal={setShowModal}
          />
        ),
      height: 'h-[200px]',
    },
    loan_balance: {
      component: (
        <CalcLoanBalance showModal={showModal} setShowModal={setShowModal} />
      ),
      width: 'w-full',
      height: 'h-[550px]',
    },
  }

  useEffect(() => {
    if (calc?.sell?.show_modal) {
      setShowModal(true)
      setModalType(calc?.sell?.modal_type)
      saveCalc((prev) => ({
        ...prev,
        sell: {
          ...prev?.sell,
          modal_type: '',
          show_modal: false,
        },
      }))
    }
  }, [calc?.sell?.show_modal])

  const update_input = (options) => {
    const { name, value } = format_input(options)
    let type = options?.type || calc?.sell?.types?.[`${name}`] || ''

    if (name === 'broker_fee') {
      const loan_type = get_loan_type('sell', 'values').toLowerCase()
      const split_amount = validate_number(value / 2, 2)
      return saveCalc((prev) => {
        return {
          ...prev,
          sell: {
            ...prev?.sell,
            values: {
              ...prev?.sell?.values,
              [name]: format_currency(value, false, 2),
            },
            types: { ...prev?.sell?.types, [name]: type },
            defaults: {
              ...prev?.sell?.defaults,
              [loan_type]: {
                ...prev?.sell?.defaults?.[loan_type],
                listing_broker_fee: format_currency(split_amount, false, 2),
                buyer_broker_fee: format_currency(split_amount, false, 2),
                closing_costs: {
                  ...prev?.sell?.defaults?.[loan_type]?.closing_costs,
                  listing_broker_fee: format_currency(split_amount, false, 2),
                  buyer_broker_fee: format_currency(split_amount, false, 2),
                },
              },
            },
          },
        }
      })
    }
    if (name === 'loan_type') {
      const loan_type = value.toLowerCase()
      const split_amount = validate_number(
        validate_number(calc?.sell?.values?.broker_fee) / 2,
        2
      )
      return saveCalc((prev) => {
        return {
          ...prev,
          sell: {
            ...prev?.sell,
            values: {
              ...prev?.sell?.values,
              [name]: value,
            },
            types: { ...prev?.sell?.types, [name]: type },
            defaults: {
              ...prev?.sell?.defaults,
              [name]: value,
              [loan_type]: {
                ...prev?.sell?.defaults?.[loan_type],
                listing_broker_fee: format_currency(split_amount, false, 2),
                buyer_broker_fee: format_currency(split_amount, false, 2),
                closing_costs: {
                  ...prev?.sell?.defaults?.[loan_type]?.closing_costs,
                  listing_broker_fee: format_currency(split_amount, false, 2),
                  buyer_broker_fee: format_currency(split_amount, false, 2),
                },
              },
            },
          },
        }
      })
    }
    if (name === 'county') {
      const calculator = 'sell'
      const defaults = calc?.[calculator]?.defaults?.[value?.toLowerCase()]
      return saveCalc((prev) => {
        const new_county = find_county(value)
        const orig_county = find_county(prev?.[calculator]?.values?.county)
        let all_values = {
          ...prev?.[calculator]?.values,
          [name]: value,
        }

        let all_types = { ...prev?.[calculator]?.types, [name]: type }
        if (
          validate_number(orig_county?.def_prop_tax_rate) ===
          validate_number(prev?.[calculator]?.values?.taxes)
        ) {
          all_values.taxes = new_county?.def_prop_tax_rate
          all_types.taxes = '%'
        }
        if (
          validate_number(orig_county?.def_ins_rate) ===
          validate_number(prev?.[calculator]?.values?.insurance_rate)
        ) {
          all_values.insurance_rate = new_county?.def_ins_rate
          all_types.insurance_rate = '%'
        }
        let all_data = {
          values: all_values,
          types: all_types,
        }
        const loan_compare_values = []
        return {
          ...prev,
          [calculator]: {
            ...prev?.[calculator],
            defaults: {
              ...prev?.[calculator]?.defaults,
              [name]: value,
            },
            ...all_data,
          },
        }
      })
    }
    saveCalc((prev) => {
      const result = {
        ...prev,
        sell: {
          ...prev?.sell,
          values: { ...prev?.sell?.values, [name]: value },
          types: { ...prev?.sell?.types, [name]: type },
        },
      }
      return result
    })
  }
  const get_inputs = (calculator_type = 'buy') => {
    const types = calc?.[calculator_type]?.types
    const names = calc?.[calculator_type]?.names
    const placeholders = calc?.[calculator_type]?.placeholders
    const values = calc?.[calculator_type]?.values || {}
    return Object.entries(values)
      ?.filter(
        ([key, value]) =>
          ![
            'interest_rate',
            'insurance_rate',
            'loan_term',
            'down_payment',
          ].includes(key)
      )
      ?.filter(([key, value]) => {
        if (implementation_type === 'sell_to_net' && key === 'sale_price') {
          return false
        } else if (
          implementation_type !== 'sell_to_net' &&
          key === 'net_at_close'
        ) {
          return false
        }
        return true
      })
      ?.map(([key, value]) => {
        const type = types[key]
        const name = names[key]
        const placeholder = placeholders[key] || ''
        const label_width = 'w-[80%]'
        const initial_data = {
          left_label: name,
          left_label_width: label_width,
          name: key,
          value,
          placeholder,
        }

        if (type === '%' || type === '$' || type === '%$') {
          initial_data.show_delete_all = true
          initial_data.inputMode = 'decimal'
        }
        if (type === '%') {
          initial_data.show_percent = true
          initial_data.percent_value = '%'
        }
        if (type === '$') {
          initial_data.show_dollar = true
          initial_data.percent_value = ''
          initial_data.value = initial_data?.value
            ? `$${initial_data.value}`
            : initial_data?.value || ''
        }
        if (key === 'taxes') {
          initial_data.show_percent = true
          initial_data.show_dollar = true
          initial_data.percent_value =
            calc?.[calculator_type]?.types?.[key] === '%' ? '%' : ''
          initial_data.dollar_percent = calc?.[calculator_type]?.types?.[key]
          initial_data.left_label_width = 'w-[135%]'
          initial_data.delete_all_position = 'right-[75px]'
          initial_data.total = validate_number(
            calculator_type === 'sell'
              ? calc?.[calculator_type]?.values?.sale_price
              : calc?.calculator_type?.values?.home_price
          )
          initial_data.handleChange = (e, type) => {
            update_input({ e, type, decimal: 3, original: value })
          }
        }

        if (key === 'sale_price' || key === 'net_at_close') {
          initial_data.onKeyUp = (e) => {
            // update_input({ e, type, decimal: 2, original: value })
            if (e.target.value && e.key === 'Enter') {
              calculate_estimate()
            }
          }
        }
        if (key === 'loan_balance') {
          initial_data.delete_all_position = 'right-[40px]'
        }
        let result = (
          <LabelInput
            {...initial_data}
            handleChange={
              initial_data?.handleChange
                ? initial_data?.handleChange
                : (e) => {
                    update_input({ e, type, decimal: 2, original: value })
                  }
            }
          />
        )
        if (key === 'loan_balance') {
          result = (
            <div className='relative'>
              {result}
              <div
                className='absolute top-[15px] right-[8px] cursor-pointer'
                onClick={() => {
                  setModalType('loan_balance')
                  setShowModal(true)
                }}
              >
                <FaCalculator />
              </div>
            </div>
          )
        }
        if (type === 'date') {
          result = (
            <Schedule
              left_label={name}
              left_label_width={label_width}
              name={key}
              show_time={false}
              schedule_date={value}
              handle_change={(e) => {
                update_input({ e, type, decimal: 2, original: value })
              }}
            />
          )
        }
        if (type === 'options') {
          const options = calc?.[calculator_type]?.option_values?.[key]
          result = (
            <Select
              left_label={name}
              left_label_width={label_width}
              no_margin={false}
              className='mb-[10px]'
              name={key}
              value={value || 'Conventional'}
              handler={(e) => {
                update_input({ e, type, decimal: 2, original: value })
              }}
              placeholder=''
            >
              {[...(options || [])]?.map((item) => {
                return (
                  <option key={item} value={item}>
                    {item}
                  </option>
                )
              })}
            </Select>
          )
        }

        return <div key={key}>{result}</div>
      })
  }

  const calculate_estimate = () => {
    const instance_uuid = calc?.sell?.data_options?.instance_uuid || get_uuid()
    saveCalc((prev) => {
      return {
        ...prev,
        sell: {
          ...prev?.sell,
          data_options: {
            ...prev?.sell?.data_options,
            calculated: true,
            instance_uuid,
            run: (prev?.sell?.data_options?.run || 0) + 1,
          },
        },
      }
    })
    if (
      calc?.sell?.values?.sale_price ||
      calc?.sell?.data_options?.net_at_close
    ) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  const get_total_string = (num, negative) => {
    let str = `$${format_currency(
      validate_number(
        implementation_type === 'sell_to_net'
          ? calc?.sell?.values?.sale_price
          : calc?.sell?.data_options?.net_at_close,
        0
      ),
      false,
      0
    )}`
    if (str?.length > 17) {
      str = str?.substring(0, 15)?.padEnd(18, '.')
    }
    return str.trim()
  }

  useEffect(() => {
    const get_calculations = () => {
      if (
        calc?.sell?.data_options?.calculated ||
        (implementation_type === 'rent_vs_buy' && calc?.buy?.calculated)
      ) {
        if (implementation_type !== 'rent_vs_buy') {
          swiper_slide(implementation_type === 'sell' ? true : false)
        }
        let timeout
        if (
          (implementation_type === 'sell' &&
            !validate_number(calc?.sell?.values?.sale_price)) ||
          (implementation_type === 'sell_to_net' &&
            !validate_number(calc?.sell?.data_options?.net_at_close))
        ) {
          if (timeout) {
            clearTimeout(timeout)
          }
          timeout = null
          const msg =
            implementation_type === 'sell_to_net'
              ? 'Net Goal is Required.'
              : 'Sales Price is required.'
          setError((prev) => {
            return {
              msg: msg,
              show_error: true,
            }
          })
          timeout = setTimeout(() => {
            setError((prev) => {
              return {
                msg: msg,
                show_error: false,
              }
            })
          }, [3000])
          if (implementation_type !== 'rent_vs_buy') {
            swiper_slide(false)
          }
          return saveCalc((prev) => ({
            ...prev,
            sell: {
              ...prev?.sell,
              data_options: {
                ...prev?.sell?.data_options,
                calculated: false,
                view_type: 'net_sheet',
              },
            },
          }))
        }
        let sale_price = validate_number(calc?.sell?.values?.sale_price)
        let net_at_close
        let original_net_at_close = validate_number(
          calc?.sell?.data_options?.net_at_close
        )
        let closing_costs
        let prorated_taxes
        let transfer_tax
        const loan_balance = validate_number(calc?.sell?.values?.loan_balance)
        const broker_fee = validate_number(calc?.sell?.values?.broker_fee)
        const misc = validate_number(calc?.sell?.values?.misc)
        const repairs = validate_number(calc?.sell?.values?.repairs)
        const buyer_credit = validate_number(calc?.sell?.values?.buyer_credit)
        const sell_closing_date = calc?.sell?.values?.closing_date

        // const taxes = convert_percent(
        //   calc?.sell?.values?.sale_price,
        //   calc?.sell?.values?.taxes,
        //   calc?.sell?.types?.taxes
        // )
        // const prorated_taxes = calc_tax_proration(taxes)

        // const get_broker_fee = () => {
        //   return (sale_price * broker_fee) / 100
        // }

        // const closing_costs =
        //   get_title_fee_total('sell', sale_price) +
        //   get_fees_total('sell', 'other_fees') +
        //   get_broker_fee()
        let negative_at_close = ''

        const get_net_at_close = (sale_price) => {
          const taxes = convert_percent(
            sale_price,
            calc?.sell?.values?.taxes,
            calc?.sell?.types?.taxes
          )
          const prorated_taxes = calc_tax_proration(
            'sell',
            taxes,
            sell_closing_date,
            profile?.ACCOUNT_ID
          )
          const get_broker_fee = (broker_fee) => {
            return sale_price * (broker_fee / 100)
          }

          const defaults =
            calc?.sell?.defaults?.[get_loan_type('sell', 'values')]
          const buyer_broker_fee = validate_number(defaults?.buyer_broker_fee)
          const seller_broker_fee = validate_number(
            defaults?.listing_broker_fee
          )
          const closing_costs =
            get_title_fee_total('sell', sale_price) +
            get_fees_total('sell', 'other_fees', sale_price) -
            get_broker_fee(
              validate_number(buyer_broker_fee) +
                validate_number(seller_broker_fee)
            ) +
            get_broker_fee(broker_fee)

          const transfer_tax = calc_transfer_tax(
            'sell',
            profile.ACCOUNT_ID,
            sale_price
          )
          // +
          // get_broker_fee()

          const net_at_close = validate_number(
            sale_price -
              validate_number(loan_balance) -
              prorated_taxes -
              transfer_tax -
              closing_costs -
              validate_number(misc) -
              validate_number(repairs) +
              validate_number(buyer_credit),
            0
          )
          return {
            net_at_close,
            sale_price,
            closing_costs,
            prorated_taxes,
            transfer_tax,
          }
        }

        const new_values = get_net_at_close(sale_price)
        net_at_close = new_values?.net_at_close
        sale_price = new_values?.sale_price
        closing_costs = new_values?.closing_costs
        prorated_taxes = new_values?.prorated_taxes
        transfer_tax = new_values?.transfer_tax
        let tries = 0
        const data_options = calc?.sell?.data_options
        let desired_net = validate_number(data_options?.desired_net)
        let net_diff = 0

        const direction = net_at_close < desired_net ? 1 : -1
        const max_tries = 15
        while (tries < max_tries && data_options?.net_changed) {
          tries += 1
          let diff = Math.abs(net_at_close - desired_net)
          if (net_at_close < desired_net) {
            net_diff = diff + 1
          } else {
            net_diff = diff - 1
          }

          net_diff = net_diff * direction
          if (tries === max_tries) {
            net_diff -= 1
          }
          const new_values = get_net_at_close(sale_price + net_diff)
          net_at_close = new_values?.net_at_close
          sale_price = new_values?.sale_price
          closing_costs = new_values?.closing_costs
          prorated_taxes = new_values?.prorated_taxes
          transfer_tax = new_values?.transfer_tax
        }

        if (net_at_close < 0) {
          negative_at_close = '-'
        } else {
          negative_at_close = ''
        }

        const sell_to_net_diff = original_net_at_close - net_at_close
        const sell_to_net_amounts = sale_price + sell_to_net_diff

        let series = [
          loan_balance,
          prorated_taxes,
          transfer_tax,
          closing_costs,
          Math.abs(net_at_close),
        ]

        if (!validate_number(transfer_tax)) {
          series = remove_index(2, series)
        }

        let net_at_close_str = `$${
          implementation_type === 'sell_to_net' ? '' : negative_at_close
        }${format_currency(
          validate_number(
            implementation_type === 'sell_to_net' ? sale_price : net_at_close,
            0
          ),
          false,
          0
        )}`

        if (net_at_close_str?.length > 17) {
          net_at_close_str = net_at_close_str.substring(0, 15).padEnd(18, '.')
        }
        if (!net_at_close_str.includes('.') && negative_at_close) {
          net_at_close_str += '.00'
        }

        let text_width = getTextWidth(net_at_close_str, totalRef.current)
        if (!calc?.sell?.data_options?.ran_data) {
          text_width -= 10
        }
        let width = 200
        if (text_width > 93) {
          width = 230
        }
        if (text_width > 100) {
          width = 240
        }

        let left_px_center = (width - text_width) / 2
        if (negative_at_close) {
          left_px_center += 11
        }

        // left_px_center -= 1
        left_px_center = `${left_px_center}px`

        width = `${width}px`

        if (
          implementation_type === 'sell_to_net' &&
          net_at_close !== original_net_at_close &&
          !data_options?.sale_price_changed
        ) {
          return saveCalc((prev) => ({
            ...prev,
            sell: {
              ...prev?.sell,
              data_options: {
                ...prev?.sell?.data_options,
                net_at_close: format_currency(original_net_at_close, false, 2),
                net_changed: true,
                run: (prev?.sell?.data_options?.run || 0) + 1,
                sale_price_changed: true,
                prorated_taxes,
                closing_costs,
                transfer_tax,
              },
            },
          }))
        }
        if (data_options?.net_changed) {
          saveCalc((prev) => {
            const result = {
              ...prev,
              sell: {
                ...prev?.sell,
                values: {
                  ...prev?.sell?.values,
                  sale_price: format_currency(sale_price, false, 2),
                },
              },
            }
            return result
          })
        }

        saveCalc((prev) => {
          const result = {
            ...prev,
            sell: {
              ...prev?.sell,
              data_options: {
                ...prev?.sell?.data_options,
                ran_data: true,
                net_at_close: format_currency(
                  implementation_type === 'sell_to_net' &&
                    !data_options?.sale_price_changed
                    ? prev?.sell?.data_options?.net_at_close
                    : net_at_close,
                  false,
                  2
                ),
                negative_at_close,
                net_changed: false,
                sale_price_changed: false,
                original_sale_price: sale_price,
                prorated_taxes,
                closing_costs,
                transfer_tax,
              },
              net_at_close_chart: {
                ...prev?.sell?.net_at_close_chart,
                series,
                labels: validate_number(transfer_tax)
                  ? [
                      'Balance',
                      'Prorated Tax Owed',
                      'Transfer Tax',
                      'Closing Costs',
                      'Net at Close',
                    ]
                  : prev?.sell?.net_at_close_chart?.labels?.filter(
                      (item) => item !== 'Transfer Tax'
                    ),
                negative_at_close,
                left_px_center,
                width,
              },
            },
          }
          return result
        })
      } else {
        if (implementation_type !== 'rent_vs_buy') {
          swiper_slide(false)
        }
      }
    }
    get_calculations()
    timeoutRef.current = setTimeout(() => {
      if (!countRef?.current) {
        countRef.current = (countRef?.current || 0) + 1
        saveCalc((prev) => ({
          ...prev,
          sell: {
            ...prev?.sell,
            values: {
              ...prev?.sell?.values,
              sale_price: prev?.sell?.values?.sale_price,
            },
          },
        }))
      } else {
        clearTimeout(timeoutRef.current)
        countRef.current = 0
      }
    }, 100)
  }, [
    calc?.sell?.values,
    calc?.sell?.data_options?.calculated,
    calc?.sell?.data_options?.run,
    calc?.sell?.defaults,
  ])

  useEffect(() => {
    if (calc?.sell?.data_options?.calculated) {
      if (implementation_type !== 'rent_vs_buy') {
        swiper_slide(implementation_type === 'sell' ? true : false)
      }
      let timeout
      if (
        (implementation_type === 'sell' &&
          !validate_number(calc?.sell?.values?.sale_price)) ||
        (implementation_type === 'sell_to_net' &&
          !validate_number(calc?.sell?.data_options?.net_at_close))
      ) {
        if (timeout) {
          clearTimeout(timeout)
        }
        timeout = null
        const msg =
          implementation_type === 'sell_to_net'
            ? 'Net Goal is Required.'
            : 'Sales Price is required.'
        setError((prev) => {
          return {
            msg: msg,
            show_error: true,
          }
        })
        timeout = setTimeout(() => {
          setError((prev) => {
            return {
              msg: msg,
              show_error: false,
            }
          })
        }, [3000])
        if (implementation_type !== 'rent_vs_buy') {
          swiper_slide(false)
        }
        return saveCalc((prev) => ({
          ...prev,
          sell: {
            ...prev?.sell,
            data_options: {
              ...prev?.sell?.data_options,
              calculated: false,
              view_type: 'net_sheet',
            },
          },
        }))
      }
      saveCalc((prev) => ({
        ...prev,
        sell: {
          ...prev?.sell,
          data_options: {
            ...prev?.sell?.data_options,
            run: (prev?.sell?.data_options?.run || 0) + 1,
          },
        },
      }))
    }
  }, [calc?.sell?.data_options?.net_at_close, calc?.sell?.values?.sale_price])

  useEffect(() => {
    const save_data = async () => {
      if (get_storage('vbc_calc') === 'Y') {
        return
      }
      saveCalc((prev) => ({ ...prev, share_loading: true }))
      const types = {}
      const values = {}
      Object.entries(calc?.sell?.types).forEach(([key, value]) => {
        types[`${key}_is_type`] = value
      })
      Object.entries(calc?.sell?.values).forEach(([key, value]) => {
        if (['%', '$', 'm', 'a']?.includes(calc?.sell?.types?.[key])) {
          types[key] = validate_number(value, 3)
        } else {
          types[key] = value
        }
      })
      const shortener = {
        interest_rate: 'int_rate',
        insurance_rate: 'ins_rate',
        down_payment: 'down',
        utilities: 'uti',
        loan_amount: 'amount',
        loan_start_date: 'start_date',
        loan_end_date: 'end_date',
      }
      Object.entries(calc?.holding_costs?.types).forEach(([key, value]) => {
        types[`hold_${shortener?.[key] || key}_is_type`] = value
      })
      Object.entries(calc?.holding_costs?.values).forEach(([key, value]) => {
        if (['%', '$', 'm', 'a']?.includes(calc?.holding_costs?.types?.[key])) {
          values[`hold_${key}`] = validate_number(value)
        } else {
          values[`hold_${key}`] = value
        }
      })
      Object.entries(calc?.loan_balance?.types).forEach(([key, value]) => {
        types[`lbal_${shortener?.[key] || key}_is_type`] = value
      })
      Object.entries(calc?.loan_balance?.values).forEach(([key, value]) => {
        if (['%', '$', 'm', 'a']?.includes(calc?.loan_balance?.types?.[key])) {
          values[`lbal_${shortener?.[key] || key}`] = validate_number(value)
        } else {
          values[`lbal_${shortener?.[key] || key}`] = value
        }
      })
      values.lbal_is_home_price =
        calc?.loan_balance?.defaults?.is_home_price || 'Y'

      const series = calc?.sell?.net_at_close_chart?.series
      const get_chart_value = (key) => {
        return series[key]
      }
      const chart_values = [
        'balance_total',
        'prorated_tax_owed_total',
        'all_closing_costs',
        // 'net_at_close_total',
      ]
      chart_values?.forEach((key, index) => {
        if (index > 0) {
          values[key] = validate_number(get_chart_value(index), 2)
        }
      })
      const sell_data_options = calc?.sell?.data_options
      if (validate_number(sell_data_options?.prorated_taxes) < 0) {
        values.prorated_tax_back_total = Math.abs(
          validate_number(sell_data_options?.prorated_taxes),
          2
        )
        values.prorated_tax_owed_total = null
      } else {
        values.prorated_tax_owed_total = validate_number(
          sell_data_options?.prorated_taxes,
          2
        )
        values.prorated_tax_back_total = null
      }
      values.all_closing_costs = validate_number(
        sell_data_options?.closing_costs,
        2
      )
      values.transfer_tax = validate_number(sell_data_options?.transfer_tax)
      values.net_at_close_total = validate_number(
        sell_data_options?.net_at_close,
        2
      )
      values.hold_is_days = calc?.holding_costs?.defaults?.is_days || 'Y'
      const holding_chart = calc?.holding_costs?.chart
      const monthly_series = holding_chart?.monthly_series
      const daily_series = holding_chart?.daily_series
      const monthly_period = holding_chart?.monthly_period
      const daily_period = holding_chart?.daily_period

      monthly_series?.forEach((item) => {
        const label = item?.label?.toLowerCase()
        item?.data?.forEach((point, index) => {
          values[`hold_${label}_${monthly_period[index]}_months`] = point
          const total_key = `hold_${monthly_period[index]}_months_total`
          if (!values?.[total_key]) {
            values[total_key] = 0
          }
          values[total_key] += point
        })
      })
      daily_series?.forEach((item) => {
        const label = item?.label?.toLowerCase()
        item?.data?.forEach((point, index) => {
          values[`hold_${label}_${daily_period[index]}_days`] = point
          const total_key = `hold_${daily_period[index]}_days_total`
          if (!values?.[total_key]) {
            values[total_key] = 0
          }
          values[total_key] += point
        })
      })

      const offer_data = []
      const offers = calc?.offers?.items || []
      if (Object.keys(offers)?.length) {
        Object.values(offers)?.forEach((offer) => {
          const obj = {}
          Object.entries(offer?.types).forEach(([key, value]) => {
            obj[`offer_${key.replace('escalation', 'esc')}_is_type`] = value
          })
          Object.entries(offer?.values).forEach(([key, value]) => {
            if (['%', '$', 'm', 'a']?.includes(offer?.types?.[key])) {
              if (key === 'down_payment') {
                obj[`offer_${key}`] = convert_percent(
                  offer?.values?.offer_price,
                  value,
                  offer?.types?.[key],
                  'percent'
                )
                obj[`offer_${key.replace('escalation', 'esc')}_is_type`] = '%'
              } else if (key === 'earnest_money') {
                obj[`offer_${key}`] = convert_percent(
                  offer?.values?.offer_price,
                  value,
                  offer?.types?.[key],
                  'value'
                )
                obj[`offer_${key.replace('escalation', 'esc')}_is_type`] = '$'
              } else {
                obj[`offer_${key}`] = validate_number(value)
              }
            } else {
              obj[`offer_${key}`] = value
            }
          })

          const data_options = offer?.data_options
          obj.offer_starred = validate_number(data_options?.starred)
          obj.offer_key = data_options?.key
          obj.offer_order = validate_number(
            data_options?.number || data_options?.key.replace(/[^0-9]/g),
            ''
          )
          obj.instance_uuid = calc?.sell?.data_options?.instance_uuid
          offer_data.push(obj)
        })
      }

      const defaults = calc?.sell?.defaults?.[get_loan_type('sell', 'values')]
      const closing_costs_all = defaults?.closing_costs
      const closing_cost_names = defaults?.closing_costs_names
      const closing_costs = remove_keys_by_suffix(
        {
          ...closing_costs_all,
        },
        '_type'
      )
      const closing_values = []
      const fee_sections = [
        'other_fees',
        'lender_fees',
        'default_fees',
        'prepaid_fees',
        'title_fees',
      ]
      const get_type = (key) => {
        let type = 'other_fees'
        for (const section of fee_sections) {
          if (
            calc?.['sell']?.defaults?.[get_loan_type('sell', 'values')]?.[
              section
            ]?.includes(key)
          ) {
            type = section
          }
        }
        return type
      }
      const sale_price = validate_number(calc?.sell?.values?.sale_price)

      const get_broker_fee = (broker_fee) => {
        return sale_price * (broker_fee / 100)
      }
      Object.entries(closing_costs)?.forEach(([key, value]) => {
        const is_buy_key = [
          'months_of_insurance',
          'months_of_taxes',
          'days_of_interest',
          'origination_fee',
          'processing_fee',
          'underwriting',
          'appraisal',
          'credit_report',
          'termite_inspection',
          'home_inspection',
        ].includes(key)
        if (is_buy_key) {
          return
        }
        const data = {}
        const is_title_key = ['escrow_fee', 'title_policy_fee'].includes(key)
        data.instance_uuid = calc?.sell?.data_options?.instance_uuid
        data.fee_key = key
        data.fee_value = is_title_key
          ? validate_number(get_title_fees('sell', key), 2)
          : validate_number(value, 2)
        data.fee_total_value = is_title_key
          ? validate_number(get_title_fees('sell', key), 2)
          : key.includes('broker_fee')
          ? get_broker_fee(value)
          : validate_number(value, 2)
        data.fee_is_type = is_title_key
          ? value
          : closing_costs_all?.[`${key}_type`] || 'int'
        data.fee_name = closing_cost_names?.[key]
        data.fee_group = is_title_key
          ? 'title_fees'
          : key.includes('broker_fee')
          ? 'other_fees'
          : get_type(key)
        if (
          data?.fee_group === 'lender_fees' ||
          data?.fee_group === 'prepaid_fees'
        ) {
          return
        }
        closing_values.push(data)
      })

      const broker_fee = validate_number(calc?.sell?.values?.broker_fee)
      const buyer_broker_fee = validate_number(defaults?.buyer_broker_fee)
      const seller_broker_fee = validate_number(defaults?.listing_broker_fee)

      values.title_fees_total = get_title_fee_total('sell', sale_price)
      values.other_fees_total = validate_number(
        get_fees_total('sell', 'other_fees', sale_price) -
          get_broker_fee(buyer_broker_fee + seller_broker_fee) +
          get_broker_fee(broker_fee),
        2
      )
      values.all_closing_costs = validate_number(
        values?.title_fees_total + values?.other_fees_total,
        2
      )

      const data = {
        values: {
          ...types,
          ...values,
          instance_uuid: calc?.sell?.data_options?.instance_uuid,
          calc_type: get_calc_type_id(implementation_type || 'sell'),
          address: calc?.formatted_address || null,
          mls_id: calc?.mls_id || null,
        },
        offer_data,
        closing_values,
      }

      const current_hist = calc?.axiom_calc_hist?.find(
        (item) => item.instance_uuid === calc?.sell?.data_options?.instance_uuid
      )

      const current_hist_offers = data?.offer_data
        ?.filter(
          (item) =>
            item.instance_uuid == calc?.sell?.data_options?.instance_uuid
        )
        ?.map((item) => {
          return {
            ...calc?.axiom_calc_hist_offers?.find(
              (offer) => offer?.offer_key === item?.offer_key
            ),
            ...item,
            upd_dt: new Date()?.toISOString(),
          }
        })

      if (implementation_type !== 'rent_vs_buy') {
        saveCalc((prev) => ({
          ...prev,
          axiom_calc_hist: [
            ...(prev?.axiom_calc_hist?.filter(
              (item) =>
                item.instance_uuid !== calc?.sell?.data_options?.instance_uuid
            ) || []),
            {
              ...current_hist,
              ...data?.values,
              upd_dt: new Date()?.toISOString(),
            },
          ],
          axiom_calc_hist_fees: [
            ...(prev?.axiom_calc_hist_fees?.filter(
              (item) =>
                item.instance_uuid !== calc?.sell?.data_options?.instance_uuid
            ) || []),
            ...(closing_values || []),
          ],
          axiom_calc_hist_offers: [
            ...(prev?.axiom_calc_hist_offers?.filter(
              (item) =>
                item.instance_uuid !== calc?.sell?.data_options?.instance_uuid
            ) || []),
            ...(current_hist_offers || []),
          ],
        }))
      }
      await axiomFetch('/calculators/save', 'POST', data, {}, false, null, 700)
      saveCalc((prev) => ({ ...prev, share_loading: false }))
    }
    if (calc?.sell?.data_options?.calculated) {
      save_data()
    }
  }, [
    calc?.sell?.values,
    calc?.buy?.rent_vs_buy?.break_curr_payment,
    calc?.holding_costs?.values,
    calc?.holding_costs?.chart?.series,
    calc?.sell?.data_options,
    calc?.offers?.items,
    calc?.loan_balance?.values,
    calc?.sell?.data_options?.run,
  ])

  const save_search_address = (place) => {
    const components = place?.address_components
    const location = place?.geometry?.location
    let address1_components = components?.filter((item) =>
      ['street_number', 'route'].includes(item?.types?.[0])
    )
    let address2_components = components?.filter((item) =>
      ['locality', 'administrative_area_level_1'].includes(item?.types?.[0])
    )
    let address3_components = components?.filter((item) =>
      ['subpremise'].includes(item?.types?.[0])
    )
    let address4_components = components?.filter((item) =>
      ['administrative_area_level_2'].includes(item?.types?.[0])
    )
    let county = address4_components?.[0]?.long_name
      ?.replace('County', '')
      ?.trim()
    const search_address = {
      address1: [...(address1_components || []), ...(address3_components || [])]
        ?.map((item) => item?.long_name || '')
        ?.join(' '),
      address2: address2_components
        ?.map((item) => item?.long_name || '')
        ?.join(', '),
      lat: location?.lat(),
      lng: location?.lng(),
      unit_number: address3_components
        ?.map((item) => item?.long_name || '')
        ?.join(', '),
      county,
    }
    return search_address
  }

  const get_share_calc_type = (view_type, implementation_type) => {
    if (
      view_type == 'multiple_offers' ||
      implementation_type === 'multiple_offers'
    ) {
      return 'multiple_offers'
    } else if (
      view_type === 'holding_costs' ||
      implementation_type === 'holding_costs'
    ) {
      return 'holding_costs'
    } else {
      return 'sell'
    }
  }

  const show_calculated_ui = () => {
    if (
      implementation_type === 'sell_to_net' &&
      validate_number(calc?.sell?.data_options?.net_at_close) &&
      calc?.sell?.data_options?.calculated
    ) {
      return true
    } else if (
      implementation_type !== 'sell_to_net' &&
      validate_number(calc?.sell?.values?.sale_price) &&
      calc?.sell?.data_options?.calculated
    ) {
      return true
    } else {
      return false
    }
  }

  const chart_html = (
    <div className='flex flex-col items-center'>
      <h1 className='text-black font-semibold text-[18px] text-center'>
        {implementation_type === 'sell_to_net'
          ? 'Sales Price'
          : calc?.sell?.net_at_close_chart?.name}
      </h1>
      <div className='relative' ref={chartRef}>
        <Chart
          key={calc?.sell?.net_at_close_chart?.name}
          options={calc?.sell?.net_at_close_chart?.options}
          width={calc?.sell?.net_at_close_chart?.width || '200px'}
          series={calc?.sell?.net_at_close_chart?.series}
          type='donut'
        />
        <div
          ref={totalRef}
          className={`absolute flex flex-col items-center top-[40%] text-center cursor-pointer`}
          style={{
            left: calc?.sell?.net_at_close_chart?.left_px_center,
          }}
          onClick={() => {
            saveCalc((prev) => ({
              ...prev,
              sell: {
                ...prev?.sell,
                data_options: {
                  ...prev?.sell?.data_options,
                  original_net: validate_number(
                    prev?.sell?.data_options?.net_at_close
                  ),
                  calculated: false,
                },
              },
            }))
            setShowModal(true)
            setModalType('net_at_close')
          }}
        >
          {get_total_string(
            implementation_type === 'sell_to_net'
              ? calc?.sell?.values?.sale_price
              : calc?.sell?.data_options?.net_at_close,
            implementation_type === 'sell_to_net'
              ? ''
              : calc?.sell?.net_at_close_chart?.negative_at_close
          )}
          <p
            className={`text-[10px] ${
              calc?.sell?.net_at_close_chart?.negative_at_close
                ? 'ml-[2px]'
                : ''
            }`}
          >
            Edit
          </p>
        </div>
      </div>

      {labels_html(
        ['Sales Price', ...calc?.sell?.net_at_close_chart?.labels],
        [
          calc?.sell?.values?.sale_price?.toString()?.replace('$', ''),
          ...calc?.sell?.net_at_close_chart?.series,
        ],
        'ml-[0px]'
      )}
    </div>
  )

  if (implementation_type === 'rent_vs_buy') {
    return chart_html
  }

  return (
    <div>
      {show_calculated_ui() ? (
        <>
          {(implementation_type === 'multiple_offers' ||
            calc?.options?.view_type === 'multiple_offers') &&
          Object.keys(calc?.offers?.items || {})?.length < 2 ? (
            <>
              {get_storage('vbc_calc') === 'Y' ? (
                ''
              ) : (
                <div className='flex justify-start pl-3 mr-[10px] mb-[10px]'>
                  <CalcBackLobby />
                </div>
              )}
            </>
          ) : (
            <CalcShare
              calculator_type={get_share_calc_type(
                calc?.options?.view_type,
                implementation_type
              )}
              implementation_type={implementation_type}
            />
          )}

          {implementation_type === 'sell' ? (
            <div className='px-3 mb-[10px]'>
              <ButtonsRow
                padding={'p-1'}
                items={['multiple_offers', 'net_sheet', 'holding_costs']}
                selected={calc?.options?.view_type || 'net_sheet'}
                handler={(item) => {
                  const position = {
                    multiple_offers: 0,
                    net_sheet: 1,
                    holding_costs: 2,
                  }
                  calc?.options?.swiper?.slideTo(position[item])
                  saveCalc((prev) => ({
                    ...prev,
                    options: { ...prev?.options, view_type: item },
                  }))
                }}
              />
            </div>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        initialSlide={1}
        onSlideChange={(e) => {
          const position = {
            0: 'multiple_offers',
            1: 'net_sheet',
            2: 'holding_costs',
          }
          if (
            calc?.options?.on_a_graph &&
            !calc?.sell?.data_options?.calculated
          ) {
            return
          }
          saveCalc((prev) => ({
            ...prev,
            options: { ...prev?.options, view_type: position[e.realIndex] },
          }))
        }}
        onSwiper={(swiper) => {
          try {
            // const index = 0
            // if (index > 0) {
            //   swiper.slideTo(index === -1 ? 0 : index, 0)
            //   return
            // }
            // if (index === 0 || index === -1) {
            //   swiper.slideTo(1, 900)
            // }
            // setTimeout(() => {
            //   if (index === 0 || index === -1) {
            //     swiper.slideTo(0, 900)
            //   }
            //   swiper.slideTo(index === -1 ? 0 : index, 0)
            //   setSwiper(swiper)
            // }, 175)

            // swiper.slideTo(1)
            swiper.allowTouchMove = false
            saveCalc((prev) => ({
              ...prev,
              options: { ...prev?.options, swiper },
            }))
          } catch (error) {
            console.log(error)
          }
        }}
      >
        <SwiperSlide key={'multiple_offers'}>
          <div className='min-h-[500px]'>
            <CalcSellMultipleOffers />
          </div>
        </SwiperSlide>
        <SwiperSlide key={'net_sheet'}>
          <div className='min-h-[500px]'>
            {calc?.options?.view_type === 'net_sheet' ? (
              <div className=''>
                {implementation_type === 'multiple_offers' ? (
                  <>
                    {show_calculated_ui() ? (
                      <div>
                        <CalcSellMultipleOffers
                          implementation_type={implementation_type}
                        />
                        <div className={`${uiStyles.line_3px} my-[20px]`}></div>
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  <>
                    <div className='flex justify-center mb-[10px]'>
                      {show_calculated_ui() ? chart_html : ''}
                    </div>
                  </>
                )}
                <div className={`px-3 flex justify-end my-[10px]`}>
                  <div className={`flex justify-end mt-[10px] gap-[10px]`}>
                    {error?.show_error ? (
                      <div className={`${uiStyles.failure_msg}`}>
                        {error?.msg}
                      </div>
                    ) : (
                      <button
                        className={`${uiStyles.hover_btn_small}`}
                        onClick={() => {
                          calculate_estimate()
                        }}
                      >
                        Calculate
                      </button>
                    )}
                    <button
                      className={`${uiStyles.hover_btn_small}`}
                      onClick={() => {
                        setModalType('options')
                        setShowModal(true)
                      }}
                    >
                      Options
                    </button>
                  </div>
                </div>
                {isLoaded && !calc?.loading_search ? (
                  <div className='mb-[4px] px-3'>
                    <Autocomplete
                      onLoad={(autocomplete) => {
                        autocomplete.setFields([
                          'formatted_address',
                          'address_components',
                          'geometry',
                          'name',
                        ])
                        saveCalc((prev) => ({ ...prev, autocomplete }))
                      }}
                      onPlaceChanged={async () => {
                        let place = {}
                        if (calc?.autocomplete) {
                          place = calc?.autocomplete?.getPlace()
                        }
                        if (place?.address_components) {
                          const search_address = save_search_address(place)
                          const search_string = new URLSearchParams(
                            search_address
                          )?.toString()
                          saveCalc((prev) => ({
                            ...prev,
                            loading_search: true,
                          }))
                          const data = await axiomFetch(
                            `/calculators/taxes_hoa?${search_string}&type=sell`
                          )
                          const tax_num = validate_number(data?.tax_amount)

                          // let hoa_amount = ''
                          // const fee_calcs = {
                          //   Annually: 0.0833,
                          //   'Semi-Annually': 0.1667,
                          //   Monthly: 1,
                          //   Quarterly: 0.25,
                          //   '********': 0,
                          // }
                          // hoa_amount =
                          //   Number(data?.association_fee || 0) *
                          //     (fee_calcs?.[data?.association_fee_frequency] || 0) +
                          //   Number(data?.association_fee2 || 0) *
                          //     (fee_calcs?.[data?.association_fee2_frequency] || 0)

                          const loan_start_date =
                            data?.mort_date || new Date().toISOString()
                          const loan_end_date = new Date().toISOString()
                          const loan_amount = validate_number(data?.mort_amount)
                          const loan_term = Math.floor(
                            validate_number(data?.mort_term) / 12
                          )
                          saveCalc((prev) => ({
                            ...prev,
                            loading_search: false,
                            sell: {
                              ...prev?.sell,
                              values: {
                                ...prev?.sell?.values,
                                taxes: format_currency(tax_num, false, 3),

                                sale_price: format_currency(
                                  validate_number(data?.list_price),
                                  false,
                                  0
                                ),

                                county: search_address?.county,
                                // hoa: format_currency(hoa_amount, false, 2) || '',
                              },
                              types: { ...prev?.sell?.types, taxes: '$' },
                              data_options: {
                                ...prev?.sell?.data_options,
                                instance_uuid:
                                  prev?.sell?.data_options?.instance_uuid ||
                                  get_uuid(),
                                calculated: data?.list_price ? true : false,
                                is_listing:
                                  validate_number(data?.list_price) !==
                                  validate_number(data?.sale_price)
                                    ? true
                                    : false,
                                original_sale_price:
                                  calc?.sell?.values?.sale_price,
                                original_taxes: calc?.sell?.values?.taxes,
                              },
                            },
                            loan_balance: {
                              ...prev?.loan_balance,
                              defaults: {
                                ...prev?.loan_balance?.defaults,
                                is_home_price: 'N',
                              },
                              values: {
                                ...prev?.loan_balance?.values,
                                loan_start_date: loan_start_date,
                                loan_end_date: loan_end_date,
                                loan_amount: format_currency(
                                  loan_amount,
                                  false,
                                  2
                                ),
                                loan_term: loan_term,
                                interest_rate: 0,
                              },
                            },
                            search_address: save_search_address(place),
                            formatted_address: place?.formatted_address,
                            mls_id: data?.mls_id,
                            address_components: place?.address_components,
                            location: place?.geometry?.location,
                          }))
                          setModalType('address_found')
                          setShowModal(true)
                        }
                      }}
                    >
                      <input
                        type='text'
                        className={`${uiStyles.input}`}
                        placeholder='Address Search'
                        value={calc?.formatted_address || ''}
                        onChange={(e) => {
                          saveCalc((prev) => ({
                            ...prev,
                            formatted_address: e.target.value,
                          }))
                        }}
                      />
                    </Autocomplete>
                  </div>
                ) : (
                  <div className='h-[50px]'>
                    <SmallLoadingRel />
                  </div>
                )}
                {implementation_type === 'sell_to_net' ? (
                  <div className='mt-[8px]'>
                    <CalcNetCloseChange
                      implementation_type={implementation_type}
                      show_done={false}
                      handle_key_up={(e) => {
                        if (e.target.value && e.key === 'Enter') {
                          calculate_estimate()
                        }
                      }}
                    />
                  </div>
                ) : (
                  ''
                )}
                <div className='px-3'>{get_inputs('sell')}</div>
                <div className='flex justify-center w-full'>
                  {error?.show_error ? (
                    <div className={`${uiStyles.failure_msg} mb-[10px]`}>
                      {error?.msg}
                    </div>
                  ) : (
                    <button
                      className={`${uiStyles.hover_btn} w-full rounded-none`}
                      onClick={() => {
                        calculate_estimate()
                      }}
                    >
                      Calculate
                    </button>
                  )}
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </SwiperSlide>
        <SwiperSlide key={'holding_costs'}>
          <div className='min-h-[500px]'>
            <CalcSellHold />
          </div>
        </SwiperSlide>
      </Swiper>
      {showModal ? (
        <Modal
          side_padding={'px-0'}
          modalName={modalType}
          height={modals?.[modalType]?.height || 'h-[550px]'}
          width={'w-full'}
          showModal={showModal}
          setShowModal={(type) => {
            setShowModal(false)
            if (modalType === 'net_at_close' || type === 'net_at_close') {
              saveCalc((prev) => ({
                ...prev,
                sell: {
                  ...prev?.sell,
                  data_options: {
                    ...prev?.sell?.data_options,
                    calculated: true,
                  },
                },
              }))
            }
          }}
          showClose={true}
        >
          {modals?.[modalType]?.component || <></>}
        </Modal>
      ) : (
        ''
      )}
    </div>
  )
}

export default CalcSell
