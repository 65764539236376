import React from 'react'
import { Outlet } from 'react-router-dom'
import Wrapper from '../../layout/Wrapper'
import SubNav from '../../SubNav'

const BuyerToursWrapper = () => {
  return (
    <div>
      <Wrapper>
        <SubNav />
        <Outlet />
      </Wrapper>
    </div>
  )
}

export default BuyerToursWrapper
