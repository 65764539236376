import { useEffect, useRef, useState } from 'react'
import { formatPhoneNumber, set_storage } from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import LabelInput from '../../form/LabelInput'
import Textarea from '../../form/Textarea'
import uiStyles from '../../ui/uiStyles'
import StarRating from './StarRating'

const AddProvider = ({
  state,
  setState,
  page,
  setPage,
  setShowModal,
  market_name,
}) => {
  const [inputValue, setInputValue] = useState(state?.market_edit || {})
  const [loading, setLoading] = useState(false)
  const { axiomFetch } = useAxiomFetch()
  const startRef = useRef(null)
  useEffect(() => {
    startRef.current.focus()
  }, [])
  const handleChange = (e) => {
    let { name, value } = e.target
    // if (name === 'cell') {
    //   value = value?.replace(/\D/g, '')?.slice(0, 10)?.toString() || ''
    // }
    console.log(name, value)

    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }))
  }
  const save_provider = async () => {
    console.log(inputValue)
    if (inputValue.cell) {
      inputValue.cell =
        inputValue.cell?.replace(/\D/g, '')?.slice(0, 10)?.toString() || ''
    }
    const data = {
      ...inputValue,
      market_uuid:
        state?.market_default_uuid || state?.markets?.[0]?.default_uuid,
    }
    console.log(data)
    setLoading(true)
    // await new Promise((resolve) => {
    //   return setTimeout(() => {
    //     return resolve('done')
    //   }, 2000)
    // })
    const result = await axiomFetch('/products/vbc/services', 'POST', data)
    setState((prev) => {
      const the_state = {
        ...prev,
        providers: state?.market_edit?.default_uuid
          ? [
              ...prev?.providers?.filter(
                (item) => item.default_uuid !== state?.market_edit?.default_uuid
              ),
              result?.data,
            ]
          : [...prev?.providers, result?.data || {}],
      }
      set_storage('vbcs', the_state)
      return the_state
    })

    setLoading(false)
    setShowModal(false)
  }
  return (
    <div>
      <h1 className='mb-[5px]'>Add My Guy</h1>
      <div className='overflow-y-scroll h-[330px]'>
        <LabelInput
          ref={startRef}
          left_label={'Name*'}
          placeholder='John Doe'
          name={'name'}
          value={inputValue?.name || ''}
          handleChange={handleChange}
        />
        <LabelInput
          left_label={'Occupation'}
          placeholder='Plumber'
          name={'type'}
          value={inputValue?.type || ''}
          handleChange={handleChange}
        />
        <LabelInput
          left_label={'Phone'}
          placeholder='(555)-555-5555'
          name={'cell'}
          value={formatPhoneNumber(inputValue?.cell || '')}
          handleChange={handleChange}
        />
        <LabelInput
          left_label={'Email'}
          placeholder='example@gmail.com'
          name={'email'}
          value={inputValue?.email || ''}
          handleChange={handleChange}
        />
        <LabelInput
          left_label={'photo url'}
          placeholder='https://photos.com/photos/photo.png'
          name={'photo'}
          value={inputValue?.photo || ''}
          handleChange={handleChange}
        />
        {/* <LabelInput
          left_label={'logo url'}
          placeholder='https://photos.com/photos/logo.png'
          name={'logo'}
          value={inputValue?.logo || ''}
          handleChange={handleChange}
        /> */}
        <LabelInput
          left_label={'website'}
          placeholder={'https://website.com'}
          name={'website'}
          value={inputValue?.website || ''}
          handleChange={handleChange}
        />
        <div className='px-1'>
          <Textarea
            label={'description'}
            placeholder={'John is a good plumber'}
            name={'about'}
            value={inputValue?.about || ''}
            onChange={handleChange}
          />
          <StarRating
            rating={inputValue?.star_rating}
            handle_change={(star) => {
              handleChange({ target: { name: 'star_rating', value: star } })
            }}
          />
        </div>
      </div>
      <div className={`flex justify-end gap-[10px] mt-[35px] items-center`}>
        <button
          className={`${uiStyles.hover_btn}`}
          onClick={() => {
            setShowModal(false)
          }}
        >
          Cancel
        </button>
        {!loading ? (
          <button className={`${uiStyles.hover_btn}`} onClick={save_provider}>
            Save
          </button>
        ) : (
          <div className={`${uiStyles.loading_msg} min-w-0 w-[150px]`}>
            Loading...
          </div>
        )}
      </div>
    </div>
  )
}

export default AddProvider
