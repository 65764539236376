import { useEffect, useState } from 'react'
import uiStyles from '../../../components/ui/uiStyles'
import TitleIcon from '../../../components/ui/TitleIcon'
// import { formatPhoneNumber } from '../../../helpers'
import visa from '../../../assets/images/payment_icons/visa_payment_icon.svg'
import blank from '../../../assets/images/payment_icons/blank_payment_icon.svg'
import mc from '../../../assets/images/payment_icons/master_payment_icon.svg'
import download_cloud from '../../../assets/images/downloads/download_cloud.svg'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import SmallLoading from '../../../components/ui/SmallLoading'
import { CircleCheckToggle } from '../../form/CircleCheckToggle'
import { UpdateCard } from '../Billing/UpdateCard'
import Modal from '../../../components/Modal'
import { unparse } from 'papaparse'
import { AddCardLightbox } from '../Billing/AddCardLightbox'
import { useMerchant } from '../../../contexts/MerchantContext'
import {
  formatPhoneNumber,
  get_storage,
  validate_number,
} from '../../../helpers'
import { useProfile } from '../../../contexts/UserContext'

const Billing = () => {
  const { axiomFetch } = useAxiomFetch()
  const { merchant } = useMerchant()
  const { profile } = useProfile()
  console.log('profile', profile)
  const [inputValue, setInputValue] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [cards, setCards] = useState({})
  const [hist, setHist] = useState([])
  const [refresh, setRefresh] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const [token, setToken] = useState('')
  const handleChange = (e) => {
    const { name, value } = e.target
    if (name.includes('checked')) {
      return
    }
    console.log(name, value)
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handle_download = async (item) => {
    try {
      if (!hist.length) {
        return
      }
      const text = unparse(hist)
      let blob = new Blob([text], { type: 'text/csv;charset=utf-8;' })
      let link = document.createElement('a')
      link.setAttribute('href', `${URL.createObjectURL(blob)}`)
      link.setAttribute('download', 'axiom_billing_history.csv')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      console.log('error')
      console.log(error)
    }
  }

  const save_default = async (e, card, index) => {
    for (const key in inputValue) {
      if (key.includes('checked')) {
        if (inputValue[key] === 'Y') {
          setInputValue((prev) => ({
            ...prev,
            [key]: 'N',
          }))
        }
      }
    }
    setInputValue((prev) => ({
      ...prev,
      [`checked_card_${index + 1}`]: 'Y',
    }))
    await axiomFetch('/merchant/card_default', 'POST', {
      ssl_token: card?.token,
    })
  }

  const card_imgs = {
    visa,
    mc,
    blank,
  }

  const modals = {
    update_card: (
      <UpdateCard
        setShowModal={setShowModal}
        ssl_token={token}
        setRefresh={setRefresh}
      />
    ),
  }

  useEffect(() => {
    let all_cards = get_storage('all_cards') || { cards: [] }
    if (!all_cards?.cards?.length && !all_cards?.default_card) {
      return
    }
    all_cards.cards = [
      all_cards?.default_card,
      ...(all_cards?.cards || []),
    ]?.sort((a, b) => validate_number(a?.pref_id) - validate_number(b?.pref_id))
    for (let i = 0; i < all_cards?.cards?.length; i++) {
      setInputValue((prev) => ({
        ...prev,
        [`checked_card_${i + 1}`]:
          all_cards.cards[i]?.main_pmt_method?.toUpperCase() || 'N',
      }))
    }

    setCards(all_cards)
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const get_cards = async () => {
      console.log('merchant', merchant)
      const all_cards = await axiomFetch(
        '/merchant/cards',
        'GET',
        {},
        {},
        false,
        controller.signal
      )
      if (all_cards?.name === 'AbortError') {
        return
      }
      for (let i = 0; i < all_cards?.cards?.length; i++) {
        setInputValue((prev) => ({
          ...prev,
          [`checked_card_${i + 1}`]:
            all_cards.cards[i]?.main_pmt_method?.toUpperCase() || 'N',
        }))
      }
      setCards(all_cards)
    }
    get_cards()

    return () => {
      controller?.abort()
    }
  }, [refresh])

  useEffect(() => {
    const controller = new AbortController()
    const get_history = async () => {
      let all_hist = await axiomFetch(
        '/merchant/payment_hist',
        'GET',
        {},
        {},
        false,
        controller.signal
      )
      if (all_hist?.length) {
        all_hist = all_hist?.filter(
          (item) => item?.ssl_result_msg?.toLowerCase() === 'approval'
        )
      }
      setHist(all_hist)
    }
    get_history()

    return () => {
      controller?.abort()
    }
  }, [])

  return (
    <div>
      <TitleIcon>The AXIOM App LLC</TitleIcon>
      {isLoading ? (
        <SmallLoading />
      ) : (
        <div className='flex flex-col gap-3 text-black m-5'>
          <div className='flex flex-col gap-1 mb-1'>
            <h4 className={`${uiStyles.text_black_subtitle}`}>Billing</h4>
            <p className={`${uiStyles.text_gray}`}>
              As a subscriber of AXIOM, you'll enjoy a range of exciting
              features and benefits. To keep these services cutting edge, we may
              charge a fee in some use cases. But don't worry - this fee is an
              investment in your continued success. It helps us keep innovating
              and bring you new and improved features all the time.
            </p>
          </div>
          <div className='flex flex-col gap-1 mb-1'>
            <h4 className={`${uiStyles.text_black_subtitle}`}>
              Customer Support
            </h4>
            <div className={`${uiStyles.text_gray}`}>
              {`If you need a refund, or have any questions regarding payments please reach out to our Customer Service line.`}{' '}
              <div className='flex justify-between'>
                <div>
                  <p className='mt-[10px]'> {profile.REP_NAME} </p>{' '}
                  <p>{formatPhoneNumber(profile.REP_PHONE)} </p>
                  <p>{profile.REP_EMAIL || 'erikal@driggstitle.com'}</p>
                  <p>8787 E. Pinnacle Peak Road </p>
                  <p>Scottsdale, AZ 85255</p>
                </div>
                <div className={`${uiStyles.text_gray}`}></div>
              </div>
            </div>
          </div>
          <div className={`${uiStyles.line}`}></div>
          <div>
            <h4 className={`${uiStyles.text_gray} font-medium`}>
              Card details
            </h4>
            <p className={`${uiStyles.text_gray} font-normal mb-[10px]`}>
              select default payment method
            </p>

            {!cards?.cards?.length ? (
              <>Cards you add will appear here.</>
            ) : (
              cards?.cards?.map((card, index) => {
                return (
                  <div
                    key={card?.token + card?.cont_id}
                    className='flex items-start justify-between rounded-md border-[1px] border-gray-300 p-[10px] mt-[10px]'
                  >
                    <div className='flex justify-start items-start gap-[10px]'>
                      <img
                        src={
                          card_imgs?.[card?.pmt_name?.toLowerCase()] ||
                          card_imgs?.blank
                        }
                        alt={card?.pmt_name?.toLowerCase() || 'credit card'}
                      />
                      <div className='flex flex-col gap-[0px]'>
                        <p
                          className={`${
                            inputValue[`checked_card_${index + 1}`] === 'Y'
                              ? uiStyles.blue_grd_text_darker
                              : ''
                          }`}
                        >
                          <span className='capitalize'>
                            {card?.pmt_name
                              ?.toLowerCase()
                              ?.replace('mc', 'MasterCard')}
                          </span>{' '}
                          ending in {card?.pmt_last4?.slice(-4)}
                        </p>
                        <p
                          className={`${
                            inputValue[`checked_card_${index + 1}`] === 'Y'
                              ? uiStyles.blue_grd_text
                              : ''
                          } font-normal`}
                        >
                          Expiry{' '}
                          {`${card?.pmt_expire?.split('-')?.[1]}/${
                            card?.pmt_expire?.split('-')[0]
                          }`}
                        </p>
                        <div className='flex gap-[10px] items-baseline'>
                          <p
                            className={`${
                              inputValue[`checked_card_${index + 1}`] === 'Y'
                                ? uiStyles.blue_grd_text_darker
                                : ''
                            } mt-[5px] cursor-pointer`}
                            onClick={async (e) => {
                              await save_default(e, card, index)
                            }}
                          >
                            Set as default
                          </p>
                          <p
                            className={`${
                              inputValue[`checked_card_${index + 1}`] === 'Y'
                                ? uiStyles.blue_grd_text_darker
                                : uiStyles.blue_grd_text
                            } cursor-pointer edit`}
                            onClick={() => {
                              setShowModal(true)
                              setModalType('update_card')
                              setToken(card?.token)
                            }}
                          >
                            Edit
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='p-2'>
                      <CircleCheckToggle
                        name={`checked_card_${index + 1}`}
                        inputValue={inputValue}
                        setInputValue={setInputValue}
                        handle_change={async (e) => {
                          await save_default(e, card, index)
                        }}
                      />
                    </div>
                  </div>
                )
              })
            )}
          </div>

          {/* can't use add card because of pci compliance. so need to do a lightbox iframe */}
          <AddCardLightbox setRefresh={setRefresh} />

          <p className={`${uiStyles.text_black_subtitle}`}>Billing History</p>
          {!hist.length ? (
            <p className={`${uiStyles.text_black}`}>
              Your future purchases will show here.
            </p>
          ) : (
            <div className='flex'>
              <div
                className={`${uiStyles.white_btn} flex gap-[10px] justify-start items-start`}
                onClick={handle_download}
              >
                <img src={download_cloud} />
                <p>Download all</p>
              </div>
            </div>
          )}
          <div className='flex flex-col'>
            {!hist.length ? (
              ''
            ) : (
              <div className='flex justify-between pl-[15px] pr-[35px] bg-gray-100 h-[44px] items-center border-[1px] border-b-0 border-gray-200'>
                <p>Invoice</p>
                <p>Amount</p>
              </div>
            )}
            <div className='overflow-y-scroll h-[400px]'>
              {!hist?.length
                ? ''
                : hist?.map((item) => {
                    return (
                      <div
                        key={item?.hist_id}
                        className='flex justify-between items-center pl-[15px] pr-[30px] border-[1px] border-gray-200 h-[75px]'
                      >
                        <p className={`${uiStyles.text_black}`}>
                          {item?.bill_type_name} -{' '}
                          {item?.ins_dt_format.slice(3)}
                        </p>
                        <p className={`${uiStyles.text_gray} font-normal`}>
                          {/* USD{' '} */}
                          {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          }).format(item?.pmt_amt)}
                        </p>
                      </div>
                    )
                  })}
            </div>
          </div>
        </div>
      )}
      {showModal ? (
        <Modal
          showModal={showModal}
          modalName={modalType}
          setShowModal={setShowModal}
          height={modalType === 'add_card' ? 'h-[550px]' : 'h-[100px]'}
        >
          {modals[modalType]}
        </Modal>
      ) : (
        ''
      )}
    </div>
  )
}

export default Billing
