import { useEffect, useState } from 'react'
import { useAi } from '../../../contexts/AiContext'
import TitleIcon from '../../ui/TitleIcon'
import { AskAxiom } from '../AI/AskAxiom'
import { AiLoader } from './AiLoader'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { CopyText } from '../AI/CopyText'
import { AiAction } from './AiAction'
import { AiMls } from './AiMls'
import uiStyles from '../../ui/uiStyles'
import { useNavigate } from 'react-router-dom'
import { useProfile } from '../../../contexts/UserContext'
export const AiSlots = () => {
  const navigate = useNavigate()
  const { ai, saveAi } = useAi()
  const { profile } = useProfile()
  const pick_details = (obj, picks_arr) => {
    let new_obj = {}
    for (const pick of picks_arr) {
      if (obj?.[pick]) {
        new_obj[pick] = obj?.[pick]
      }
    }
    return new_obj
  }

  console.log(ai)
  useEffect(() => {
    if (ai?.mls?.add_mls_to_prompt) {
      console.log(ai?.state?.run)
      console.log('hello')
      console.log(ai?.mls?.mlsMatch)
      ai.slot.AI_PRPT_PRPT = ai?.slot?.AI_PRPT_PRPT
      console.log(ai.slot.AI_PRPT_PRPT)
      let mls_prompt = ai?.slot?.AI_PRPT_PRPT

      if (
        ai?.slot?.AI_PRPT_PRPT?.includes(`{{MLS_Descriptions}}`) &&
        ai?.mls?.mlsMatch?.public_remarks
      ) {
        mls_prompt = ai.slot?.AI_PRPT_PRPT?.replace(
          '{{MLS_Descriptions}}',
          ai?.mls?.mlsMatch?.public_remarks
        )
      } else {
        if (ai?.slot?.AI_PRPT_PRPT?.includes(`{{MLS_Descriptions}}`)) {
          console.log('before replace', ai?.slot?.AI_PRPT_PRPT)
          mls_prompt = ai?.slot?.AI_PRPT_PRPT?.replace(
            '{{MLS_Descriptions}}',
            ''
          )
        }
        console.log('before adding json')
        // mls_prompt = `${mls_prompt} use only these details about the property in this json: ${JSON.stringify(
        //   pick_details(ai?.mls?.mlsMatch, [
        //     'baths',
        //     'beds',
        //     'list_price',
        //     'original_list_price',
        //     'prop_add',
        //     'prop_st',
        //     'prop_city',
        //     'sqft',
        //     'pool',
        //     'list_date',
        //     'list_office_phone',
        //     'lisg_agent_name',
        //   ])
        // )}`
      }
      console.log(mls_prompt)
      saveAi((prev) => ({
        ...prev,
        state: {
          ...prev?.state,
          run: true,
          prompt: mls_prompt,
        },
        slot: {
          ...prev?.slot,
          AI_GO_PROP: ai?.slot?.already_have_listing
            ? 'N'
            : prev?.slot?.AI_GO_PROP,
        },
      }))
      saveAi((prev) => ({ ...prev, mls: { ...prev?.mls, start_ai: true } }))
    } else {
      console.log('no listig')
      if (ai?.slot?.AI_GO_PROP === 'N') {
        console.log('run no listing')
        saveAi((prev) => ({
          ...prev,
          state: {
            ...prev?.state,
            message: '',
            prompt: ai?.slot?.AI_PRPT_PRPT,
            run: true,
          },
        }))
      }
    }
  }, [ai?.mls?.add_mls_to_prompt])

  return (
    <div className='flex flex-col p-3'>
      <TitleIcon className='px-0'>{ai?.slot?.AI_PRPT_NM}</TitleIcon>
      <div>
        {ai?.slot?.AI_PRPT_DESC?.split('loading:')?.[0]?.replace('¿', "'")}
      </div>

      {ai?.slot?.AI_GO_PROP === 'Y' ? (
        <div>
          <AiMls />
        </div>
      ) : (
        <div>
          <div>
            <div className={!ai?.state?.message ? 'hidden' : ''}>
              {
                <AskAxiom
                  type='blog'
                  show_type_label={false}
                  state={ai?.state}
                  setState={saveAi}
                  response_only={true}
                />
              }
              <div className='flex items-center justify-between'>
                <button
                  className={`${uiStyles.white_btn}`}
                  onClick={() => {
                    if (ai?.mls?.add_mls_to_prompt) {
                      saveAi((prev) => ({
                        ...prev,
                        state: { ...prev?.state, message: '' },
                      }))
                      saveAi((prev) => ({
                        ...prev,
                        slot: { ...prev?.slot, AI_GO_PROP: 'Y' },
                        mls: {
                          ...prev?.mls,
                          add_mls_to_prompt: 0,
                          setting: 'mlsNo',
                        },
                      }))
                    } else {
                      navigate(-1)
                    }
                  }}
                >
                  Cancel
                </button>
                <div>
                  <CopyText type='' text_to_copy={ai?.state?.message} />
                </div>
                <button
                  onClick={() => navigate('/user/axiom-ai-distribute')}
                  className={uiStyles.hover_btn}
                >
                  Share
                </button>
              </div>
            </div>

            <div className={ai?.state?.message ? 'hidden' : ''}>
              <AiLoader
                loads={ai?.slot?.AI_PRPT_DESC?.split('loading:')?.[1]?.split(
                  ','
                )}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
