import { useRef } from 'react'
import {
  AiFillCloseCircle,
  AiFillDelete,
  AiFillMinusCircle,
  AiFillPlusCircle,
} from 'react-icons/ai'
import { useCalc } from '../../contexts/CalcContext'
import {
  array_nums,
  capitalize,
  format_currency,
  get_uuid,
  remoke_key,
  validate_number,
} from '../../helpers'
import { CircleCheckToggle } from '../form/CircleCheckToggle'
import LabelInput from '../form/LabelInput'
import Select from '../form/Select'
import uiStyles from '../ui/uiStyles'

const CalcClosingCosts = ({ calculator_type = 'buy' }) => {
  const {
    calc,
    saveCalc,
    get_nums,
    get_title_fees,
    get_prepaids,
    get_principal,
    get_loan_type,
    get_fees_total,
    get_origination_fee,
    get_fees_arr,
    get_title_fee_total,
    loans,
  } = useCalc()

  const updateInput = (e, obj_key, type, decimal = 2) => {
    let { name, value } = e.target
    console.log('value', value)
    const loan_type = get_loan_type(calculator_type)
    let start, end
    if (!type) {
      type =
        calc?.[calculator_type]?.defaults?.[loan_type]?.closing_costs?.[
          `${name}_type`
        ] || ''
    }

    if (
      obj_key !== 'closing_costs_names' &&
      obj_key &&
      !name.includes('broker_fee')
    ) {
      value = value?.toString()?.replace(/[^$€£¥0-9,.%]/g, '')
    }

    console.log('new value', value)
    if (
      !isNaN(Number(value?.toString()?.replace(/[$€£¥,%]/g, ''))) &&
      e?.target?.selectionStart
    ) {
      value = format_currency(value, false, decimal, e.target)
      start = value?.start
      end = value?.end
      value = value?.item
    }

    setTimeout(() => {
      if (
        !isNaN(Number(value?.toString()?.replace(/[$€£¥,%]/g, ''))) &&
        e?.target?.selectionStart &&
        start &&
        end
      ) {
        const orig_comma_length = (
          calc?.[calculator_type]?.defaults?.[loan_type]?.[
            obj_key || 'closing_costs'
          ]?.[name]?.match(/,/g) || []
        )?.length
        const new_comma_length = (value?.match(/,/g) || [])?.length
        if (
          (new_comma_length > orig_comma_length && start > 2) ||
          !calc?.[calculator_type]?.defaults?.[loan_type]?.[
            obj_key || 'closing_costs'
          ]?.[name] ||
          (value?.startsWith('0.') &&
            calc?.[calculator_type]?.defaults?.[loan_type]?.[
              obj_key || 'closing_costs'
            ]?.length === 1)
        ) {
          start += 1
          end += 1
        } else if (new_comma_length < orig_comma_length && start > 2) {
          start -= 1
          end -= 1
        }
        // if (
        //   orig_comma_length >= new_comma_length &&
        //   orig_comma_length !== 0 &&
        //   new_comma_length !== 0 &&
        //   start > 2
        // ) {
        //   start -= 1
        //   end -= 1
        //   if (start > 6) {
        //     start -= 1
        //     end -= 1
        //   }
        // }
        e.target.selectionStart = start
        e.target.selectionEnd = end
      }
    }, 0)

    if (name.includes('broker_fee')) {
      const other_name =
        name === 'buyer_broker_fee' ? 'listing_broker_fee' : 'buyer_broker_fee'
      const first_fee = validate_number(value)
      const second_fee = validate_number(
        calc?.[calculator_type]?.defaults?.[loan_type]?.closing_costs?.[
          other_name
        ]
      )
      const broker_fee = first_fee + second_fee
      if (first_fee + second_fee > 100) {
        value = 100 - second_fee
      }

      return saveCalc((prev) => {
        return {
          ...prev,
          [calculator_type]: {
            ...prev?.[calculator_type],
            values: {
              ...prev?.[calculator_type]?.values,
              broker_fee,
            },
            defaults: {
              ...prev?.[calculator_type]?.defaults,
              [loan_type]: {
                ...prev?.[calculator_type]?.defaults?.[loan_type],
                closing_costs: {
                  ...prev?.[calculator_type]?.defaults?.[loan_type]
                    ?.closing_costs,
                  [name]: value,
                },
                [name]: value,
              },
            },
          },
        }
      })
    }

    if (name === 'loan_type') {
      const { home_price_num } = get_nums()
      const defaults =
        calc?.[calculator_type]?.defaults?.[loan_type]?.[
          obj_key || 'closing_costs'
        ]?.[value?.toLowerCase()]
      const new_down_payment =
        calc?.[calculator_type]?.down_payment_type === '%'
          ? defaults?.down_payment
          : home_price_num * defaults?.down_payment

      return saveCalc((prev) => {
        const new_defaults =
          calc?.[calculator_type]?.defaults?.[value?.toLowerCase()]

        let loan_all_defaults = {
          ...prev?.[calculator_type]?.defaults,
          [name]: value?.toLowerCase(),
        }
        let all_values = {
          ...prev?.[calculator_type]?.values,
          [name]: value,
          down_payment: new_defaults?.down_payment,
        }

        let all_types = { ...prev?.[calculator_type]?.types, [name]: 'options' }
        return {
          ...prev,
          loan_2:
            calculator_type === 'buy'
              ? {
                  ...prev?.loan_2,
                  defaults: loan_all_defaults,
                  values: all_values,
                  types: all_types,
                  is_pmi: new_defaults?.is_pmi,
                  is_mip: new_defaults?.is_mip,
                }
              : { ...prev?.loan_2 },
          loan_3:
            calculator_type === 'buy'
              ? {
                  ...prev?.loan_3,
                  defaults: loan_all_defaults,
                  values: all_values,
                  types: all_types,
                  is_pmi: new_defaults?.is_pmi,
                  is_mip: new_defaults?.is_mip,
                }
              : { ...prev?.loan_3 },
          [calculator_type]: {
            ...prev?.[calculator_type],
            defaults: loan_all_defaults,
            values: all_values,
            types: all_types,
            is_pmi: new_defaults?.is_pmi,
            is_mip: new_defaults?.is_mip,
          },
        }
      })
    }

    if (type === '%' && validate_number(value) > 100) {
      value = 100
    }

    saveCalc((prev) => {
      let all_defaults = {
        ...prev?.[calculator_type]?.defaults,
        [loan_type]: {
          ...prev?.[calculator_type]?.defaults?.[loan_type],
          [obj_key || 'closing_costs']: {
            ...prev?.[calculator_type]?.defaults?.[loan_type]?.[
              obj_key || 'closing_costs'
            ],
            [name]: value,
            [`${name}_type`]: type || null,
          },
        },
      }
      return {
        ...prev,
        loan_2:
          calculator_type === 'buy'
            ? { ...prev?.loan_2, defaults: all_defaults }
            : { ...prev?.loan_2 },
        loan_3:
          calculator_type === 'buy'
            ? { ...prev?.loan_3, defaults: all_defaults }
            : { ...prev?.loan_3 },
        [calculator_type]: {
          ...prev?.[calculator_type],
          defaults: all_defaults,
          [name]: value,
          [`${name}_type`]: type,
        },
      }
    })
  }

  const get_fees = (type, disabled = false) => {
    return get_fees_arr(calculator_type, type)?.map((key) => {
      let label_input = (
        <LabelInput
          // left_label={capitalize(key?.replace(/_|lender_fee/g, ' '))}
          disabled={disabled}
          left_label_width={'w-[95%]'}
          name={key}
          show_dollar={true}
          show_delete_all={disabled ? false : true}
          value={
            calc?.[calculator_type]?.defaults?.[
              get_loan_type(calculator_type, 'defaults')
            ]?.closing_costs?.[key] || ''
          }
          percent_value=''
          handleChange={(e, type) => updateInput(e, 'closing_costs', type, 3)}
          placeholder=''
        />
      )

      if (
        key?.includes('broker_fee') &&
        calc?.[calculator_type]?.defaults?.[
          get_loan_type(calculator_type, 'defaults')
        ]?.show_split_in_closing_costs
      ) {
        label_input = (
          <div className='relative flex'>
            <LabelInput
              // left_label={capitalize(key?.replace(/_|lender_fee/g, ' '))}
              disabled={disabled}
              left_label_width={'w-[95%]'}
              name={key}
              show_percent={true}
              show_delete_all={disabled ? false : true}
              value={format_currency(
                calc?.[calculator_type]?.defaults?.[
                  get_loan_type(calculator_type)
                ]?.closing_costs?.[key]
              )}
              percent_value='%'
              handleChange={(e, type) =>
                updateInput(e, 'closing_costs', type, 3)
              }
              placeholder=''
            />
            <div className='absolute top-[11px] left-[60px] text-[14px] w-[75px] overflow-scroll'>
              <p>
                {`($${format_currency(
                  (validate_number(
                    calc?.[calculator_type]?.defaults?.[
                      get_loan_type(calculator_type)
                    ]?.closing_costs?.[key]
                  ) *
                    validate_number(
                      calc?.[calculator_type]?.values?.sale_price
                    )) /
                    100,
                  false,
                  2
                )})`}
              </p>
            </div>
          </div>
        )
      }

      return (
        <div className='relative' key={key}>
          {disabled || key.includes('broker_fee') ? (
            ''
          ) : (
            <div
              className='absolute top-[-6px] left-[-6px] z-10 cursor-pointer'
              onClick={() => {
                const loan_type = get_loan_type(calculator_type, 'defaults')
                saveCalc((prev) => ({
                  ...prev,
                  [calculator_type]: {
                    ...prev?.[calculator_type],
                    defaults: {
                      ...prev?.[calculator_type]?.defaults,
                      [loan_type]: {
                        ...prev?.[calculator_type]?.defaults?.[loan_type],
                        closing_costs: remoke_key(
                          prev?.[calculator_type]?.defaults?.[loan_type]
                            ?.closing_costs || {},
                          key
                        ),
                        closing_costs_remove: [
                          ...(prev?.[calculator_type]?.defaults?.[loan_type]
                            ?.closing_costs_remove || []),
                          key,
                        ],
                        [type]: [
                          ...prev?.[calculator_type]?.defaults?.[loan_type]?.[
                            type
                          ]?.filter((item) => item !== key),
                        ],
                      },
                    },
                  },
                }))
              }}
            >
              <AiFillMinusCircle color={'tomato'} />
            </div>
          )}
          <div className='flex items-start'>
            <input
              disabled={disabled}
              className={`w-[57%] overflow-x-scroll rounded-l-md resize-none py-[11px] pl-[10px] pr-[2px] border border-gray-300  text-thirdary text-sm`}
              name={key}
              value={
                calc?.[calculator_type]?.defaults?.[
                  get_loan_type(calculator_type, 'defaults')
                ]?.closing_costs_names?.[key] || ''
              }
              onChange={(e, type) =>
                updateInput(e, 'closing_costs_names', type, 3)
              }
              placeholder='add fee'
            />
            {label_input}
          </div>
        </div>
      )
    })
  }

  const add_fee = (type) => {
    const loan_type = get_loan_type(calculator_type)
    const key = get_uuid()
    saveCalc((prev) => ({
      ...prev,
      [calculator_type]: {
        ...prev?.[calculator_type],
        defaults: {
          ...prev?.[calculator_type]?.defaults,
          [loan_type]: {
            ...prev?.[calculator_type]?.defaults?.[loan_type],
            closing_costs: {
              ...(prev?.[calculator_type]?.defaults?.[loan_type]
                ?.closing_costs || {}),
              [key]: '',
            },
            closing_costs_names: {
              ...(prev?.[calculator_type]?.defaults?.[loan_type]
                ?.closing_costs_names || {}),
              [key]: '',
            },
            [type]: [
              ...prev?.[calculator_type]?.defaults?.[loan_type]?.[type]?.filter(
                (item) => item !== key
              ),
              key,
            ],
          },
        },
      },
    }))
  }

  const add_fee_btn = (type) => {
    return (
      <div
        className='flex cursor-pointer w-[16px]'
        onClick={() => add_fee(type)}
      >
        <AiFillPlusCircle color={'green'} />
      </div>
    )
  }

  const get_fee_str = (key, type) => {
    const prepaids_keys = [
      'months_of_insurance',
      'months_of_taxes',
      'days_of_interest',
    ]
    const total = prepaids_keys?.includes(key)
      ? get_prepaids(calculator_type, key, type) || ''
      : get_title_fees(calculator_type, key, type)

    return total ? ` -- ($${format_currency(total, false, 2)})` : ''
  }

  const show_prepaids = () => {
    if (calculator_type === 'sell') {
      return false
    } else if (
      ['owner finance', 'all cash']?.includes(
        get_loan_type('buy', 'defaults')?.toLowerCase()
      )
    ) {
      return false
    } else {
      return true
    }
  }

  return (
    <div className='mt-[10px]'>
      <div className='px-3'>
        <Select
          left_label={'Loan Type'}
          left_label_width={'w-[135%]'}
          no_margin={false}
          className='mb-[10px]'
          name={'loan_type'}
          value={get_loan_type(calculator_type, 'defaults')}
          handler={updateInput}
          placeholder='required'
        >
          {loans?.map((item) => {
            return (
              <option key={item} value={item?.toLowerCase()}>
                {item}
              </option>
            )
          })}
        </Select>
        <div className='flex justify-between items-center'>
          <p className='my-[5px]'>Total Closing Costs</p> $
          {format_currency(
            get_prepaids(calculator_type) +
              get_title_fee_total(calculator_type) +
              get_fees_total(calculator_type, 'lender_fees') +
              get_origination_fee(calculator_type) +
              get_fees_total(calculator_type, 'other_fees'),
            false,
            2
          )}
        </div>
        <div className={`${uiStyles.line_3px}`}></div>
      </div>

      <div className='mt-[0px] overflow-y-scroll max-h-[309px] px-3'>
        <div className={`${uiStyles.line} mt-[10px]`}></div>
        {show_prepaids() ? (
          <>
            <div className='flex justify-between items-center'>
              <p className='my-[5px]'>Prepaid Costs</p> $
              {format_currency(get_prepaids(calculator_type), false, 2)}
            </div>
            <div className={`${uiStyles.line} mb-[10px]`}></div>

            {calc?.[calculator_type]?.defaults?.[
              get_loan_type(calculator_type)
            ]?.prepaid_fees?.map((key) => {
              return (
                <Select
                  left_label={capitalize(key?.replace(/_/g, ' '))}
                  left_label_width={'w-[95%]'}
                  no_margin={false}
                  className='mb-[10px]'
                  name={key}
                  value={
                    calc?.[calculator_type]?.defaults?.[
                      get_loan_type(calculator_type)
                    ]?.closing_costs?.[key]
                  }
                  handler={updateInput}
                >
                  {array_nums(key === 'days_of_interest' ? 30 : 24).map(
                    (item) => {
                      return (
                        <option key={item} value={item}>
                          {item}
                          {get_fee_str(key, Number(item))}
                        </option>
                      )
                    }
                  )}
                </Select>
              )
            })}
            <div className={`${uiStyles.line} mt-[10px]`}></div>
          </>
        ) : (
          ''
        )}
        <div className='flex justify-between items-center'>
          <p className='my-[5px]'>Title/Escrow Fees</p> $
          {format_currency(get_title_fee_total(calculator_type), false, 2)}
        </div>
        <div className={`${uiStyles.line} mb-[10px]`}></div>
        <Select
          left_label={'Title Policy'}
          left_label_width={'w-[95%]'}
          no_margin={false}
          className='mb-[10px]'
          name={'title_policy_fee'}
          value={
            calc?.[calculator_type]?.defaults?.[
              get_loan_type(calculator_type, 'defaults')
            ]?.closing_costs?.title_policy_fee
          }
          handler={updateInput}
        >
          {['buyer', 'seller', 'both'].map((item, index) => {
            return (
              <option key={item + index} value={item}>
                {item} {get_fee_str('title_policy_fee', item)}
              </option>
            )
          })}
        </Select>
        <Select
          left_label={'Escrow Fee'}
          left_label_width={'w-[95%]'}
          no_margin={false}
          className='mb-[10px]'
          name={'escrow_fee'}
          value={
            calc?.[calculator_type]?.defaults?.[
              get_loan_type(calculator_type, 'defaults')
            ]?.closing_costs?.escrow_fee
          }
          handler={updateInput}
        >
          {['buyer', 'seller', 'both'].map((item, index) => {
            return (
              <option key={item + index} value={item}>
                {item} {get_fee_str('escrow_fee', item)}
              </option>
            )
          })}
        </Select>
        {get_fees('title_fees', true)}
        {calculator_type !== 'sell' ? (
          <>
            {' '}
            <div className={`${uiStyles.line} mt-[10px]`}></div>
            <div className='flex justify-between items-center'>
              <p className='my-[5px]'>Lender Fees</p> $
              {format_currency(
                get_fees_total(calculator_type, 'lender_fees') +
                  get_origination_fee(),
                false,
                2
              )}
            </div>
            <div className={`${uiStyles.line} mb-[10px]`}></div>
            {show_prepaids() ? (
              <div className='relative'>
                <LabelInput
                  left_label={'Origination Fee'}
                  left_label_width={'w-[95%]'}
                  name={'origination_fee'}
                  show_percent={true}
                  show_delete_all={true}
                  value={
                    calc?.[calculator_type]?.defaults?.[
                      (
                        calc?.[calculator_type]?.defaults?.loan_type ||
                        'Conventional'
                      )?.toLowerCase()
                    ]?.closing_costs?.origination_fee || ''
                  }
                  percent_value={`%`}
                  handleChange={(e, type) =>
                    updateInput(e, 'closing_costs', '%', 3)
                  }
                  placeholder=''
                />
                <p
                  className={`absolute top-[12px] text-[14px] right-[28px] ${
                    `($${format_currency(get_origination_fee(), false, 2)})`
                      ?.length > 13
                      ? 'text-xs top-[15px] right-[24px]'
                      : 'text-sm'
                  }`}
                >
                  {`($${format_currency(get_origination_fee(), false, 2)})`}
                </p>
              </div>
            ) : (
              ''
            )}
            {get_fees('lender_fees')}
            {add_fee_btn('lender_fees')}
          </>
        ) : (
          ''
        )}
        <div className={`${uiStyles.line} mt-[10px]`}></div>
        <div className='flex justify-between items-center'>
          <p className='my-[5px]'>Other Fees</p> $
          {format_currency(
            get_fees_total(calculator_type, 'other_fees'),
            false,
            2
          )}
        </div>
        <div className={`${uiStyles.line} mb-[10px]`}></div>
        {get_fees('other_fees')}
        {add_fee_btn('other_fees')}
      </div>
    </div>
  )
}

export default CalcClosingCosts
