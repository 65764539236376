import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

export const AiContext = createContext()

export const AiProvider = ({ children, userData }) => {
  const [ai, setAi] = useState(null)

  const saveAi = useCallback((data) => {
    setAi(data)
  }, [])

  const value = useMemo(
    () => ({
      ai,
      saveAi,
    }),
    [ai, saveAi]
  )

  return <AiContext.Provider value={value}>{children}</AiContext.Provider>
}

export const useAi = () => {
  const context = useContext(AiContext)

  if (!context) {
    throw Error('AuthContext must be used inside an AuthContextProvider')
  }

  return context
}
