import React from 'react'
import uiStyles from '../../ui/uiStyles'

const PairwiseCancelFailed = ({ setShowModal }) => {
  return (
    <div>
      <div className='px-3'>
        It looks like your payment was already been approved. You will need to
        sell your shares first in order to cancel.
      </div>
      <div className={'flex justify-end gap-[10px] mr-3'}>
        <button
          className={`${uiStyles.white_btn}`}
          onClick={() => {
            setShowModal(false)
          }}
        >
          Back
        </button>
      </div>
    </div>
  )
}

export default PairwiseCancelFailed
