import React from 'react'
import images_admin from '../../assets/images/admin'

const Forbidden = () => {
  return (
    <div className='bg-black h-[600px] rounded shadow-2xl'>
      <div className='px-3 mb-[20px]'>
        <p
          className={`text-white text-[24px] font-semibold text-center pt-[20px]`}
        >
          Forbidden
        </p>
      </div>
      <img src={images_admin.key} />
    </div>
  )
}

export default Forbidden
