import { useState, useRef, useEffect } from 'react'
import { formatPhoneNumber, format_currency } from '../../../helpers'
import LabelInput from '../../form/LabelInput'
import uiStyles from '../../ui/uiStyles'

const MarketingMaterialPhotoPicker = ({ state, setState, setModalType }) => {
  const [search, setSearch] = useState('')
  const searchRef = useRef(null)

  useEffect(() => {
    if (searchRef?.current) {
      searchRef.current.focus()
    }
  }, [])

  const empty_fields = state?.create_order?.session?.empty_fields
  const sessions = state?.create_order?.sessions
  const session = state?.create_order?.session
  let fields = session?.sess_info?.filter(
    (field_item) => field_item.field_id > 0
  )
  if (empty_fields) {
    if (sessions?.length > 1) {
      let all_fields = sessions.reduce((curr, val) => {
        return [...curr, ...(val?.sess_info || [])]
      }, [])
      fields = (all_fields || []).reduce((curr, val) => {
        const check_val = curr.find((item) => item.field_id === val.field_id)
        if (!check_val) {
          curr.push(val)
        }
        return curr
      }, [])
      fields = (fields || [])?.filter(
        (field_item) =>
          field_item.field_id > 0 && field_item?.allow_edit === 'Y'
      )
    }
  }
  const save_field = (name, value) => {
    const field_index = fields.findIndex(
      (item) => item?.field_id?.toString() === name?.toString()
    )
    const field = fields[field_index]
    const format = (val) => {
      if (
        field?.phone_format === 'Y' ||
        field?.prompt_val === 'Agent Cell Phone'
      ) {
        return formatPhoneNumber(val)
      } else if (
        field?.dollar_format === 'Y' ||
        field?.prompt_val === 'List Price'
      ) {
        return format_currency(val, false)
      } else {
        return val
      }
    }
    fields[field_index].saved_val = format(value)

    for (const session of sessions) {
      for (const info of session?.sess_info) {
        if (info?.field_id === field?.field_id) {
          info.saved_val = value
        }
      }
    }
    const curr_session = sessions.find(
      (sess_item) => sess_item?.sess_id === session?.sess_id
    )
    setState((prev) => ({
      ...prev,
      field_changed: true,
      create_order: {
        ...prev?.create_order,
        session: { ...curr_session },
        sessions: [...sessions],
      },
    }))
  }
  return (
    <div>
      <LabelInput
        show_delete_all={true}
        show_search={true}
        name={'search'}
        placeholder='search...'
        value={search}
        handleChange={(e) => {
          setSearch(e.target.value)
        }}
        onKeyUp={(e) => {
          const photos = state?.current_listing?.photos?.filter((item) => {
            if (!search) {
              return true
            }
            return [item?.name, item?.caption]
              ?.map((item) => item?.toString()?.toLowerCase())
              ?.join('')
              ?.includes(search?.toLowerCase())
          })

          if (e?.key === 'Enter' && photos?.length) {
            save_field(
              state?.create_order?.selected_image?.field_id,
              photos?.[0]?.uri_2048 || photos?.[0]?.media_url
            )
            setModalType('material')
          }
        }}
        ref={searchRef}
      />
      <div className='flex flex-col cursor-pointer max-h-[530px] overflow-scroll'>
        {state?.current_listing?.photos
          ?.filter((item) => {
            if (!search) {
              return true
            }
            return [item?.name, item?.caption]
              ?.map((item) => item?.toString()?.toLowerCase())
              ?.join('')
              ?.includes(search?.toLowerCase())
          })
          ?.map((item) => {
            return (
              <div
                key={item?.uri_2048 || item?.media_url}
                onClick={() => {
                  save_field(
                    state?.create_order?.selected_image?.field_id,
                    item?.uri_2048 || item?.media_url
                  )
                  setModalType('material')
                }}
              >
                <div
                  className={`${uiStyles.line_3px} mt-[10px] pb-[10px]`}
                ></div>
                {item?.name ? <p>Name: {item?.name}</p> : ''}
                {item?.caption ? <p>Caption: {item?.caption}</p> : ''}
                <img
                  className='object-cover'
                  src={item?.uri_2048 || item?.media_url}
                />
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default MarketingMaterialPhotoPicker
