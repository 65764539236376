import React from 'react'
import SmallLoading from '../../ui/SmallLoading'
export const NotificationsIcon = ({ count, loading, gray = false }) => {
  if (loading) {
    return (
      <span className={`flex h-4 w-4 relative`}>
        <span className='animate-ping absolute inline-flex h-full w-full rounded-full bg-gradient-to-b from-accent-150 to-accent-400 opacity-75'></span>
        <span className='animate-ping absolute inline-flex h-full w-full rounded-full bg-gradient-to-b from-accent-150 to-accent-400 opacity-75'></span>
      </span>
    )
  }
  return (
    <>
      {count ? (
        <div
          className={`swipeable ${
            count > 99
              ? 'bg-[#BCC1C5] text-[#fff]'
              : gray
              ? 'bg-gradient-to-b from-[#BCC1C5] to-[#BCC1C5] text-[#fff]'
              : 'bg-gradient-to-b from-[#5B8DEF] to-[#0063F7] text-[#fff]'
          } rounded-full w-[25px] h-[25px] flex items-center justify-center`}
        >
          <p className='swipeable'>{count > 99 ? `${count}+` : count}</p>
        </div>
      ) : (
        ''
      )}
    </>
  )
}
