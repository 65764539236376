import { useEffect, useState } from 'react'
import { useCalc } from '../../../contexts/CalcContext'
import { useVBCs } from '../../../contexts/VBCContext'
import { formatPhoneNumber, get_storage, short_url } from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { PageSingleLeadList } from '../../../pages'
import { CircleCheckToggle } from '../../form/CircleCheckToggle'
import Wrapper from '../../layout/Wrapper'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import TitleIcon from '../../ui/TitleIcon'
import uiStyles from '../../ui/uiStyles'
const DBCSend = ({
  setShowModal,
  sendId,
  single_name = '',
  single_number = '',
  title = 'Share My Realty Smart Business Card',
  title_style = '',
  show_service_markets = true,
  vbc_msg = async () => 'Check out My Realty Smart Business Card.',
  add_msgs = [],
  add_media = [],
  vbc_type = '',
  no_vbc = false,
  show_list = false,
  show_conversations_btn = true,
  show_messaging_btn = true,
  show_upd_btn = false,
  calc_type_id,
  calc_instance_uuid,
}) => {
  console.log(sendId)

  const { axiomFetch } = useAxiomFetch()
  const { VBCs, saveVBCs } = useVBCs()
  const { calc, saveCalc } = useCalc()
  const [loading, setLoading] = useState(false)
  const get_markets = () => {
    return (
      VBCs?.markets?.filter((item) => {
        return item.type?.split(',').includes('services')
      }) || []
    )
  }
  console.log('vbcs', VBCs)

  const [lists, setLists] = useState([])
  const [inputValue, setInputValue] = useState({})
  const [list, setList] = useState('')
  const [listId, setListId] = useState(null)
  const [listSelected, setListSelected] = useState([])
  const [singleNumber, setSingleNumber] = useState(single_number)
  const [singleEmail, setSingleEmail] = useState('')
  const [singleName, setSingleName] = useState(single_name)
  const [showTeamConfig, setShowTeamConfig] = useState(false)
  const [messageSent, setMessageSent] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [msgType, setMsgType] = useState('text')
  const [saveList, setSaveList] = useState(0)
  const curr_vbc = get_storage('vbc_receiver')
  const get_markets_string = () => {
    return Object.keys(inputValue)
      ?.filter((key) => inputValue[key] === 'Y')
      ?.map((key) => key)
      ?.toString()
  }

  const can_submit = () => {
    if (listSelected?.length < 1) {
      if (!singleEmail && !singleNumber) {
        return false
      }
    }
    // if (!get_markets_string()) {
    //   return false
    // }
    return true
  }
  useEffect(() => {
    const show_message_sent = () => {
      setTimeout(() => {
        setMessageSent(false)
      }, 3000)
    }
    show_message_sent()
  }, [messageSent])

  useEffect(() => {
    const fetch_lists = async () => {
      let data = await axiomFetch('/client/listings/lists', 'GET')
      data = data?.filter((d) => {
        if (d?.STATUS === 'A' && d?.LIST_NAME?.trim()?.length > 1) {
          console.log(d?.LIST_NAME?.trim())
          return true
        } else {
          return false
        }
      })
      console.log(data)
      setLists(data)
    }
    fetch_lists()
  }, [])

  const save_list = (state) => {
    setSaveList(state)
  }

  useEffect(() => {
    console.log(saveList)
    const fetch_lists = async () => {
      if (!listId) return
      let data = await axiomFetch(`/client/listings/lists/${listId}`, 'GET')
      data = data
        ?.filter((d) => {
          if (d.STATUS === 'A' && (d?.CELL || d?.EMAIL)) {
            return true
          } else {
            return false
          }
        })
        ?.map((da) => {
          return {
            name: da?.NM || '',
            cell: da?.CELL,
            email: da?.EMAIL,
          }
        })
      console.log(data)
      setListSelected(data)
    }
    fetch_lists()
  }, [listId, saveList])

  const modals = {
    List: (
      <PageSingleLeadList
        modalListId={listId}
        modalListName={list}
        saveList={saveList}
        setSaveList={setSaveList}
        showTeamConfig={showTeamConfig}
        setShowTeamConfig={setShowTeamConfig}
      />
    ),
  }

  const send_text = async (send_tel_link = false, only_update = false) => {
    console.log('hello send_text')
    setLoading(true)
    setSuccessMessage(
      only_update
        ? 'VBC Updated!'
        : send_tel_link
        ? `Message${singleNumber ? 's' : ''} Created!`
        : `Message${singleNumber ? 's' : ''} Sent!`
    )
    const media_ids = add_media?.filter((item) => item)?.length
      ? await axiomFetch('/marketing/conversations/media/create_url', 'POST', {
          urls: add_media,
        }).catch((err) => console.log(err))
      : []
    const media_msgs =
      media_ids?.filter((item) => item)?.map((item) => ({ mediaSid: item })) ||
      []
    add_msgs = [...media_msgs, add_msgs]
    console.log('media_ids', media_ids)
    if (!sendId && vbc_type === 'buyer') {
      console.log('cards', VBCs?.cards)
      sendId = VBCs?.cards?.find((item) => item?.RECEIVER_TYPE_ID === 2)?.ID
    } else if (!sendId && vbc_type === 'seller') {
      sendId = VBCs?.cards?.find((item) => item?.RECEIVER_TYPE_ID === 3)?.ID
    }
    console.log('send_id', sendId)
    if (msgType === 'text') {
      if (singleNumber) {
        let number = singleNumber
          ?.replace('+1', '')
          ?.replace(/\D/g, '')
          ?.slice(0, 10)
        let body = {
          cellPhone: number?.toString(),
          name: singleName,
          person_type_id: curr_vbc?.RECEIVER_TYPE_ID,
          origin_id: curr_vbc?.ORIGIN_ID,
          market_uuids: get_markets_string(),
          vbc_msg: vbc_msg ? `Hey ${singleName} ${await vbc_msg()}` : '',
          add_msgs,
          no_vbc,
          send_tel_link: send_tel_link || only_update,
        }
        setList('none')
        setListId(0)
        setListSelected([])
        setSingleNumber('')
        setSingleName('')
        setMessageSent(true)
        setLoading(false)
        console.log('msg', await vbc_msg())
        //
        console.log(calc_type_id, calc_instance_uuid)
        if (calc_type_id && calc_instance_uuid) {
          const contacts = [
            {
              calc_instance_uuid,
              calc_type_id,
              to_name: singleName,
              to_phone_number: number?.toString(),
            },
          ]
          saveCalc((prev) => ({
            ...prev,
            axiom_calc_send_list: [
              ...(prev?.axiom_calc_send_list || []).filter((item) => {
                if (
                  contacts?.some(
                    (contact) =>
                      contact?.calc_instance_uuid ===
                        item?.calc_instance_uuid &&
                      item?.to_phone_number?.toString() ===
                        contact?.to_phone_number?.toString()
                  )
                ) {
                  return false
                } else {
                  return true
                }
              }),
              ...contacts,
            ],
          }))
          await axiomFetch('/calculators/send', 'POST', {
            contacts,
          })
        }
        console.log('tel_link', send_tel_link)
        if (send_tel_link) {
          let vbc_link = ''
          if (!no_vbc) {
            vbc_link = await axiomFetch(
              `/products/vbc/${sendId}/send`,
              'POST',
              body
            )
          }
          const link = document.createElement('a')
          const msg = `${await vbc_msg()} ${
            vbc_link?.toString()?.includes('local')
              ? vbc_link
              : (await short_url(vbc_link))?.replace('https://', '')
          }`
          link.href = `sms://${body?.cellPhone}&body=${msg}`
          link.click()
          if (link.parentElement) {
            link.parentElement.removeChild(link)
          }
        } else {
          await axiomFetch(`/products/vbc/${sendId}/send`, 'POST', body)
        }
      } else if (listSelected?.length > 0) {
        let selected = listSelected.filter(
          (selec) => selec.cell && selec.cell !== null
        )
        setList('none')
        setListId(0)
        setListSelected([])
        setSingleNumber('')
        setSingleName('')
        setMessageSent(true)
        setLoading(false)
        for (const selec of selected) {
          let body = {
            cellPhone: selec?.cell?.toString(),
            name: selec?.NM?.toString(),
            person_type_id: curr_vbc?.RECEIVER_TYPE_ID,
            origin_id: curr_vbc?.ORIGIN_ID,
            market_uuids: get_markets_string(),
            vbc_msg: await vbc_msg(),
            add_msgs,
            no_vbc,
          }
          const share_dbc = await axiomFetch(
            `/products/vbc/${sendId}/send`,
            'POST',
            body
          )
        }
      }
    } else if (msgType === 'email') {
      if (singleEmail) {
        let body = {
          email: singleEmail,
          person_type_id: curr_vbc?.RECEIVER_TYPE_ID,
          origin_id: curr_vbc?.ORIGIN_ID,
          market_uuids: get_markets_string(),
          vbc_msg: vbc_msg ? `Hey ${singleName} ${await vbc_msg()}` : '',
          add_msgs,
          no_vbc,
        }
        setList('none')
        setListId(0)
        setListSelected([])
        setSingleNumber('')
        setSingleName('')
        setMessageSent(true)
        setLoading(false)
        const share_dbc = await axiomFetch(
          `/products/vbc/${sendId}/send`,
          'POST',
          body
        )
      } else if (listSelected?.length > 0) {
        let selected = listSelected.filter(
          (selec) => selec.email && selec.email !== null
        )
        console.log(selected)
        setList('none')
        setListId(0)
        setListSelected([])
        setSingleNumber('')
        setSingleName('')
        setMessageSent(true)
        setLoading(false)
        // for (const selec of selected) {
        //     let body = {
        //         email: selec.email,
        //         person_type_id: curr_vbc?.RECEIVER_TYPE_ID,
        //         origin_id: curr_vbc?.ORIGIN_ID,
        //     }
        // const share_dbc = await axiomFetch(`/products/vbc/${sendId}/send`, 'POST', body)
        // }
      }
    }
  }

  return (
    <>
      <div>
        <h1 className={`${uiStyles.text_black_title} ${title_style}`}>
          {title}
        </h1>

        <div className='flex my-[5px]'>
          <div className='flex flex-col items-start'>
            <button
              onClick={() => {
                setMsgType('text')
              }}
            >
              Text
            </button>
            <div
              className={`w-[50px] h-[3px] transition-all duration-400 bg-green-300  ${
                msgType === 'text' ? 'opacity-100' : 'opacity-50 bg-gray-300'
              }`}
            ></div>
          </div>
          <div className='flex flex-col items-start'>
            {/* <button
              onClick={() => {
                setMsgType('email')
              }}
            >
              Email
            </button>
            <div
              className={`w-[50px] h-[3px] transition-all duration-400 bg-green-300  ${
                msgType === 'email' ? 'opacity-100' : 'opacity-50 bg-gray-300'
              }`}
            ></div> */}
          </div>
        </div>
        {show_list ? (
          <>
            <div>
              <label
                className={`${uiStyles.label} transition-all duration-300 ${
                  singleNumber || singleEmail ? 'text-gray-300' : ''
                }`}
              >
                Send To List
                <select
                  value={list}
                  onChange={(e) => {
                    //console.log(e.target.options[e.target.selectedIndex].id)
                    setListId(e.target.options[e.target.selectedIndex].id)
                    setList(e.target.value)
                  }}
                  disabled={singleNumber || singleEmail ? true : false}
                  className={`${uiStyles.select}`}
                >
                  <option id={0}>none</option>
                  {lists.map((option, index) => {
                    return (
                      <option
                        key={`${option?.LIST_ID}-${index}`}
                        id={option.LIST_ID}
                      >
                        {option?.LIST_NAME}
                      </option>
                    )
                  })}
                </select>
              </label>
              {listId ? (
                <div className='flex justify-end'>
                  <button
                    onClick={() => {
                      setShowTeamConfig(!showTeamConfig)
                    }}
                    className={`${uiStyles.hover_btn} mt-3`}
                  >
                    {!showTeamConfig ? 'Edit List' : 'Close List'}
                  </button>
                </div>
              ) : (
                ''
              )}
              {listId
                ? showTeamConfig && (
                    <PageSingleLeadList
                      modalListId={listId || -20}
                      modalListName={list}
                      showTeamConfig={true}
                      smaller={true}
                      setShowTeamConfig={setShowTeamConfig}
                      saveList={saveList}
                      setSaveList={setSaveList}
                    />
                  )
                : ''}
            </div>
            <div className='text-black my-1 mt-[10px]'>
              Or Share With A Single Person
            </div>
          </>
        ) : (
          ''
        )}

        <label>
          Name
          <input
            className={uiStyles.input}
            value={singleName}
            onChange={(e) => {
              setSingleName(e.target.value)
            }}
          />
        </label>

        <div className='text-black'>
          {msgType === 'text' ? (
            <label>
              Cell Phone*
              <input
                className={uiStyles.input}
                value={singleNumber}
                onChange={(e) => {
                  const number = formatPhoneNumber(e.target.value)
                  console.log(number)
                  setSingleNumber(number)
                }}
              />
            </label>
          ) : (
            <label>
              Email
              <input
                className={uiStyles.input}
                value={singleNumber}
                onChange={(e) => {
                  setSingleEmail(e.target.value)
                }}
              />
            </label>
          )}
        </div>
      </div>
      {show_service_markets ? (
        <div>
          {/* <div className={`${uiStyles.line} mt-[5px]`}></div> */}
          {/* <h1>Add Service Markets</h1> */}
          {/* <div className='flex flex-wrap'>
            {get_markets()?.map((item, index) => {
              return (
                <div className='flex'>
                  <p>{item?.location_name}</p>
                  <CircleCheckToggle
                    name={`${item?.default_uuid}`}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    handle_change={async (e) => {
                      console.log(e.target.value)
                      setInputValue((prev) => {
                        const return_data = {
                          ...prev,
                          [`${item?.default_uuid}`]:
                            prev?.[`${item?.default_uuid}`] === 'Y' ? 'N' : 'Y',
                        }
                        return return_data
                      })
                    }}
                  />
                </div>
              )
            })}
          </div> */}
        </div>
      ) : (
        ''
      )}
      {loading ? (
        <SmallLoadingRel />
      ) : (
        <>
          {!messageSent ? (
            <div className='flex flex-wrap gap-[5px_5px] mt-5 justify-end'>
              {['update_text', 'conversation', 'tel_link']
                ?.filter((item) => {
                  if (!show_conversations_btn && item === 'conversation') {
                    return false
                  } else if (!show_messaging_btn && item === 'tel_link') {
                    return false
                  } else if (!show_upd_btn && item === 'update_text') {
                    return false
                  } else {
                    return true
                  }
                })
                .map((item) => {
                  return (
                    <button
                      key={item}
                      className={` ${
                        can_submit()
                          ? uiStyles.hover_btn_small
                          : uiStyles.hover_btn_small_greyed_out
                      } px-[15px] py-[10px]`}
                      disabled={can_submit() ? false : true}
                      onClick={async () => {
                        return await send_text(
                          item === 'tel_link',
                          item === 'update_text'
                        )
                      }}
                    >
                      {msgType === 'text'
                        ? item === 'update_text'
                          ? 'Update'
                          : item === 'conversation'
                          ? 'AXIOM Text'
                          : 'Create Text'
                        : 'Send Email'}
                    </button>
                  )
                })}
            </div>
          ) : (
            <div className={uiStyles.success_msg}>
              {' '}
              {successMessage || 'Done!'}
            </div>
          )}
        </>
      )}
    </>
  )
}

export default DBCSend
