import { useState, useRef, useEffect } from 'react'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import { SmallLoadingRel } from '../ui/SmallLoadingRel'
import uiStyles from '../ui/uiStyles'
import {
  formatPhoneNumber,
  phone_is_admin,
  pick_keys,
  set_storage,
  validate_number,
} from '../../helpers'
import profile_img from '../../assets/images/profile-silhouette.svg'
import { List } from '../helpers'
import { useClaims } from '../../contexts/ClaimsContext'
import { useAuth0 } from '@auth0/auth0-react'
import { useProfile } from '../../contexts/UserContext'
import { CircleCheckToggle } from '../form/CircleCheckToggle'
import { useSales } from '../../contexts/SalesContext'
import LabelInput from '../form/LabelInput'
import { useNavigate } from 'react-router-dom'
const ClientSearch = ({ state, setState, type = 'no_swipe' }) => {
  const { axiomFetch } = useAxiomFetch()
  const { user, loginWithRedirect } = useAuth0()
  const { sales, saveSales } = useSales()
  const { claims } = useClaims()
  const { profile } = useProfile()
  console.log('user', user)
  console.log('claims', claims)
  console.log('profile', profile)
  const [search, setSearch] = useState({})
  const fetchTimeout = useRef(0)
  const lastFetchtime = useRef(0)
  const searchRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [inputValue, setInputValue] = useState({})
  console.log('input', inputValue)
  const navigate = useNavigate()

  useEffect(() => {
    setInputValue({
      toggle_account: profile?.ACCOUNT_ID === 1320 ? 'Y' : 'N',
      search_account: profile?.ACCOUNT_ID,
    })
  }, [])

  useEffect(() => {
    if (searchRef) {
      searchRef?.current?.focus()
    }
  }, [])
  const search_func = async (e, acct) => {
    setSearch((prev) => ({ ...prev, search: e.target.value }))
    const controller = new AbortController()
    if (!e.target.value) {
      controller.abort()

      return setSearch((prev) => ({
        ...prev,
        result: [],
      }))
    }
    const currentTime = performance.now()

    if (currentTime - lastFetchtime?.current < 1000) {
      clearTimeout(fetchTimeout?.current)
      controller.abort()
    }
    lastFetchtime.current = currentTime
    setSearch((prev) => ({ ...prev, loading: true }))
    fetchTimeout.current = setTimeout(async () => {
      let result = await axiomFetch(
        `/internal/client/search?search=${e.target.value}&acct_id=${
          [1320, 1560]?.includes(validate_number(acct))
            ? acct
            : inputValue.search_account
        }`,
        'GET',
        {},
        {},
        controller.signal
      )
      console.log('search_Result', result)

      setSearch((prev) => ({
        ...prev,
        result,
        loading: false,
        search: e.target.value,
      }))
    }, 700)
  }

  const client_login = async (client) => {
    setLoading(true)
    await axiomFetch('/internal/client/login', 'POST', {
      actual_phone: claims?.login_phone_number || user?.name?.replace('+1', ''),
      current_phone: client?.cont_cell_num,
    })
    set_storage('need_refresh', true)
    return loginWithRedirect()
  }

  const what_next = async (client) => {
    console.log('client', client)
    saveSales((prev) => {
      const sales_data = {
        ...prev,
        curr_client: {
          data: pick_keys(client, [
            'swipe_right',
            'content',
            'sub_content',
            'title',
            'image',
          ]),
          selected: 'baseline',
        },
      }
      set_storage('sales', sales_data)
      return sales_data
    })
    navigate('/user/sales/what_next/client')
  }

  console.log(search)
  return (
    <div className='min-h-[550px]'>
      <div>
        <div className='px-3 mb-[10px]'>
          <div className='flex justify-end mr-[32px] mb-[10px]'>
            {phone_is_admin(claims?.login_phone_number || user?.name) ? (
              <CircleCheckToggle
                name={`toggle_account`}
                type='toggle'
                toggle_left_label='NV'
                toggle_right_label='AZ'
                inputValue={inputValue}
                handle_change={async (e) => {
                  console.log(e.target.value)
                  setInputValue((prev) => {
                    return {
                      ...prev,
                      toggle_account: prev?.toggle_account === 'Y' ? 'N' : 'Y',
                      search_account:
                        prev?.search_account === 1320 ? 1560 : 1320,
                    }
                  })
                  search_func(
                    { target: { value: search?.search } },
                    e.target.value === 'Y' ? 1560 : 1320
                  )
                }}
              />
            ) : (
              ''
            )}
          </div>
          <LabelInput
            ref={searchRef}
            show_search={true}
            className={uiStyles.input}
            placeholder='search name, phone, email...'
            value={search?.search || ''}
            show_delete_all={true}
            handleChange={search_func}
          />
        </div>
        {search?.loading ? (
          <SmallLoadingRel />
        ) : (
          <div>
            <div className='max-h-[500px] overflow-scroll'>
              <List
                fill='object-contain'
                list={
                  !search?.result?.length
                    ? []
                    : search?.result?.map((item) => {
                        item.title = item.cont_name
                        item.image = item?.agent_photo || profile_img
                        item.content = (
                          <div className='flex flex-col'>
                            {item?.cont_cell_num ? (
                              <a
                                draggable={'false'}
                                href={`tel:${item?.cont_cell_num}`}
                                className='max-w-[183px] overflow-scroll whitespace-nowrap underline'
                              >
                                {formatPhoneNumber(item?.cont_cell_num)}
                              </a>
                            ) : (
                              ''
                            )}
                            {item?.cont_email ? (
                              <a
                                draggable={'false'}
                                className='max-w-[183px] overflow-scroll whitespace-nowrap underline'
                                target='_blank'
                                rel='noreferrer'
                                href={`mailto:?to=${item?.cont_email}&subject=&body=`}
                              >
                                {item?.cont_email}
                              </a>
                            ) : (
                              ''
                            )}
                          </div>
                        )
                        if (type === 'no_swipe') {
                          item.sub_content = (
                            <div>
                              {' '}
                              <div className='flex gap-[10px] mt-[15px]'>
                                {loading ? (
                                  <SmallLoadingRel />
                                ) : (
                                  <>
                                    <button
                                      className={`${uiStyles.hover_btn_small}`}
                                      onClick={() => client_login(item)}
                                    >
                                      Axiom Login
                                    </button>
                                    <button
                                      className={`${uiStyles.hover_btn_small}`}
                                      onClick={() => what_next(item)}
                                    >
                                      What Next
                                    </button>{' '}
                                  </>
                                )}
                              </div>
                            </div>
                          )
                        }
                        if (type === 'swipe') {
                          item.swipe_right = () => {
                            return (
                              <div>
                                {item?.user_access ? (
                                  <>
                                    {loading ? (
                                      <SmallLoadingRel color='white' />
                                    ) : (
                                      <div className='flex gap-[5px] items-center'>
                                        <div
                                          className='cursor-pointer p-[20px]'
                                          onClick={() => client_login(item)}
                                        >
                                          Login
                                        </div>{' '}
                                        |{' '}
                                        <div
                                          className=' cursor-pointer p-[20px] whitespace-nowrap'
                                          onClick={() => {
                                            what_next(item)
                                          }}
                                        >
                                          What Next
                                        </div>
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <span>No Access</span>
                                )}
                              </div>
                            )
                          }
                        }
                        return item
                      }) || []
                }
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ClientSearch
