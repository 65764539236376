import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useClaims } from '../../../contexts/ClaimsContext'
import { useListingTours } from '../../../contexts/ListingToursContext'
import { formatPhoneNumber } from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { PageSingleLeadList } from '../../../pages'
import Wrapper from '../../layout/Wrapper'
import TitleIcon from '../../ui/TitleIcon'
import uiStyles from '../../ui/uiStyles'
const ReceiverDBCSend = ({ setShowModal, sendId }) => {
  console.log(sendId)
  const { axiomFetch } = useAxiomFetch()
  const { listingTours, saveListingTours } = useListingTours()
  const [lists, setLists] = useState([])
  const [list, setList] = useState('')
  const [listId, setListId] = useState(null)
  const [listSelected, setListSelected] = useState([])
  const [singleNumber, setSingleNumber] = useState('')
  const [singleEmail, setSingleEmail] = useState('')
  const [singleName, setSingleName] = useState('')
  const [showTeamConfig, setShowTeamConfig] = useState(false)
  const [messageSent, setMessageSent] = useState(false)
  const [saveList, setSaveList] = useState(0)
  const { claims, saveClaims } = useClaims()
  const { pathname } = useLocation()
  console.log(claims)
  useEffect(() => {
    const show_message_sent = () => {
      setTimeout(() => {
        setMessageSent(false)
      }, 3000)
    }
    show_message_sent()
  }, [messageSent])

  return (
    <>
      <TitleIcon>Share VBC Card</TitleIcon>

      <div className='text-black'>
        <label>
          Name
          <input
            className={uiStyles.input}
            value={singleName}
            onChange={(e) => {
              setSingleName(e.target.value)
            }}
          />
        </label>
        <label>
          Cell Phone
          <input
            className={uiStyles.input}
            value={singleNumber}
            onChange={(e) => {
              const number = formatPhoneNumber(e.target.value)
              setSingleNumber(number)
            }}
          />
        </label>
      </div>
      {!messageSent ? (
        <div className='flex gap-3 mt-5 justify-end'>
          <button
            className={`${uiStyles.hover_btn} ${
              !(
                singleNumber
                  ?.replace('+1', '')
                  ?.replace(/\D/g, '')
                  ?.slice(0, 10).length > 9
              ) &&
              !singleEmail &&
              listSelected?.length < 1
                ? uiStyles.hover_btn_greyed_out
                : ''
            }`}
            disabled={
              !singleNumber && !singleEmail && listSelected?.length < 1
                ? true
                : false
            }
            onClick={async () => {
              if (singleNumber) {
                let number = singleNumber
                  ?.replace('+1', '')
                  ?.replace(/\D/g, '')
                  ?.slice(0, 10)
                let body = {
                  cellPhone: number.toString(),
                  name: singleName,
                }

                setSingleNumber('')
                setSingleName('')
                setMessageSent(true)
                const share_dbc = await axiomFetch(
                  `/products/vbc/${sendId}/send`,
                  'POST',
                  body
                )
                if (pathname === '/card') {
                  for (const item of listingTours?.data?.event_pages) {
                    return await axiomFetch('/tours/client/add', 'POST', {
                      agent_name: singleName,
                      agent_cell_phone: number?.toString(),
                      event_id: item?.event_id,
                    })
                  }
                }

                console.log(share_dbc)
              }
            }}
          >
            Send Text
          </button>
        </div>
      ) : (
        <div className={uiStyles.success_msg}>
          {' '}
          Message{singleNumber ? 's' : ''} Scheduled!
        </div>
      )}
    </>
  )
}

export default ReceiverDBCSend
