import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import images_admin from '../../assets/images/admin'
import { useClaims } from '../../contexts/ClaimsContext'
import { useProfile } from '../../contexts/UserContext'
import Forbidden from '../admin/Forbidden'
import Wrapper from '../layout/Wrapper'
import SubNav from '../SubNav'
import uiStyles from '../ui/uiStyles'

const InternalWrapper = () => {
  const { profile } = useProfile()
  const { claims } = useClaims()
  const location = useLocation()

  const find_route = () => {
    const path = location.pathname
    const is_path = (check) => {
      return path.includes(check)
    }
    if (is_path('what_next/client')) {
      return '/user/sales/what_next'
    } else if (is_path('sales/what_next')) {
      return '/user'
    } else {
      return ''
    }
  }
  return (
    <Wrapper>
      <SubNav url={find_route()} />
      {claims?.user_id ? <Outlet /> : <Forbidden />}
    </Wrapper>
  )
}

export default InternalWrapper
