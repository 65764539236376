import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import Title from '../onBoarding/Title'
import Button from '../onBoarding/Button'

import Branding from '../../assets/images/favLarge.png'
import { useLocation, useNavigate } from 'react-router-dom'
export default function LoginIntro() {
  const location = useLocation()
  const navigate = useNavigate()
  console.log('intro', location)
  const { loginWithRedirect } = useAuth0()
  const handleClickNext = () => {
    loginWithRedirect()
  }

  useEffect(() => {
    localStorage.removeItem('uuid')

    if (location.search?.includes('uuid')) {
      const params = new URLSearchParams(location.search)
      window.history.pushState(
        null,
        document.title,
        `/card/${params.get('uuid')}`
      )
      return (window.location.href = `${window.location.origin}/${location.search}`)
    }
    return loginWithRedirect()
  }, [])
  return (
    <>
      <div className=' min-h-[calc(100%-70px)] flex flex-col p-5 items-center'>
        <div className='-ml-5 -mr-5'>
          <img src={Branding} className='w-full' alt='' />
        </div>
        <div className='mt-4'>
          <Title marginBottom='mb-4'>Boost Productivity</Title>
          <p className='text-center text-[15px] mb-4'>
            AXIOM gives you all the tools you need
            <br /> to drive additional business and increase revenue!
            <br /> Productivity in your pocket!
          </p>
        </div>
        <p className='text-center text-[12px] mb-4'>
          By clicking "Next" and entering your phone number you agree to receive
          one time passwords to your phone number and notifications from us
          about potential real estate leads. Reply STOP to opt out. Data and msg
          rates may apply.
        </p>
        <div className='text-center'>
          <Button clickHandler={handleClickNext}>Next</Button>
        </div>
      </div>
    </>
  )
}
