import { useRef } from 'react'
import images_tours from '../../../assets/images/listing_tours'
import { validate_number } from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'

const ThumbRating = ({
  state,
  setState,
  rating,
  type,
  new_rating_func,
  show_totals = false,
}) => {
  const { axiomFetch } = useAxiomFetch()
  const fetchTimeout = useRef(0)
  const lastFetchtime = useRef(0)
  const set_new_rating = new_rating_func
    ? new_rating_func
    : async (item) => {
        console.log(rating?.rate_value)
        if (typeof item === 'number') {
          rating.rate_value = rating?.rate_value === item ? 0 : item
        } else {
          rating.rate_value = item
        }
        setState((prev) => {
          return {
            ...prev,
            data: {
              ...prev?.data,
              [`tour_${type}_ratings`]: [
                ...prev?.data?.[`tour_${type}_ratings`]?.filter(
                  (item) => item.rated_for_item_id !== rating?.rated_for_item_id
                ),
                rating,
              ].sort((a, b) => a?.rated_for_item_id - b?.rated_for_item_id),
            },
          }
        })
        const currentTime = performance.now()
        if (currentTime - lastFetchtime?.current < 3000) {
          clearTimeout(fetchTimeout?.current)
        }
        lastFetchtime.current = currentTime
        fetchTimeout.current = setTimeout(async () => {
          await axiomFetch(`/tours/ratings/${type}`, 'POST', rating, {}, false)
        }, 700)
      }
  return (
    <div>
      <div className='swipeable mt-[10px] flex justify-center items-center gap-[20px]'>
        <div className='swipeable flex items-center gap-[10px]'>
          {show_totals ? (
            <div
              className={`swipeable flex items-center justify-center ${
                validate_number(
                  rating?.thumbs_down_total || rating?.thums_up_total
                ) > 9999
                  ? 'text-[14px]'
                  : 'text-[18px]'
              } font-bold mt-[5px]`}
            >
              {rating?.thumbs_up_total}
            </div>
          ) : (
            ''
          )}
          <button
            onClick={() => {
              set_new_rating(1)
            }}
          >
            <img
              className='min-w-[20px]'
              src={
                (rating?.rate_value || 0) > 0
                  ? images_tours.thumbs_up_filled
                  : images_tours.thumbs_up_outline
              }
            />
          </button>
        </div>
        <div className='swipeable flex items-center gap-[10px]'>
          <button
            onClick={() => {
              set_new_rating(-1)
            }}
          >
            <img
              className='min-w-[20px]'
              src={
                (rating?.rate_value || 0) < 0
                  ? images_tours.thumbs_down_filled
                  : images_tours.thumbs_down_outline
              }
            />
          </button>
          {show_totals ? (
            <div
              className={`swipeable flex items-center justify-center ${
                validate_number(
                  rating?.thumbs_down_total || rating?.thumbs_up_total
                ) > 9999
                  ? 'text-[14px]'
                  : 'text-[18px]'
              } font-bold mt-[-5px]`}
            >
              {rating?.thumbs_down_total || 0}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
}

export default ThumbRating
