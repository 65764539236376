import React from 'react'
import { HiArrowLeft } from 'react-icons/hi2'
import payment_failure from '../../../assets/images/payment_icons/payment_failure.svg'
import { useMerchant } from '../../../contexts/MerchantContext'
import uiStyles from '../../ui/uiStyles'
import { ProfileHeader } from './ProfileHeader'

export const PayFailure = ({ payError, setModalType }) => {
  const { merchant, saveMerchant } = useMerchant()
  return (
    <div className='mt-[10px]'>
      <ProfileHeader />
      <div className='flex flex-col gap-[10px] items-center mt-[20px]'>
        <img src={payment_failure} />
        <p className={`${uiStyles.text_black_title} text-center`}>
          Payment failed
        </p>
        <p className='text-center'>
          {payError || 'Unable to process payment.'}
        </p>
        <div
          className='mt-[10px] flex gap-[10px] text-[#007AFF] items-center cursor-pointer'
          onClick={() => {
            if (setModalType) {
              setModalType('pay')
            }
            saveMerchant((prev) => ({
              ...prev,
              payment: {
                ...prev?.payment,
                payment_failed: false,
                payment_successful: false,
              },
            }))
          }}
        >
          <HiArrowLeft />
          Pay with another card or try again
        </div>
      </div>
    </div>
  )
}
