import React, { useState } from 'react'
import SwipeConfig from '../../ui/SwipeConfig';
import pin from '../../../assets/images/map-pin.png'
import ImgEvent1 from '../../../assets/images/events1.jpg'
import ImgEvent2 from '../../../assets/images/events2.jpg'
import ImgEvent3 from '../../../assets/images/events3.jpg'
import ImgEvent4 from '../../../assets/images/events4.jpg'
import ImgEvent5 from '../../../assets/images/events5.jpg'
import ImgEvent6 from '../../../assets/images/events6.jpg'
import ImgEvent7 from '../../../assets/images/events7.jpg'

function EventItem({event,index}) {
const thumbnails = event?._embedded['wp:featuredmedia'];
const featured_img = thumbnails && thumbnails[0].link;
const events_info = event?.custom_fields; 
const [selected, setSelected] = useState(1);
const eventsThumbnails = [ImgEvent1,ImgEvent2,ImgEvent3,ImgEvent4,ImgEvent5,ImgEvent6,ImgEvent7];
const defaultThumb = eventsThumbnails[Math.floor(Math.random() * 7)];
  return (
    
    <SwipeConfig
    index={event?.id}
    selectedIndex={selected}
    setSelectedIndex={setSelected}
    widthConfigRight={44}
    RightComponents={()=><a href={events_info?.driggs_events_url} target='_blank'>View Details</a>}
   
  >
    
    <div className='flex py-2 border-b-[1px] border-white'>
        <div className="thumbnail w-[122px] swipeable">
            <img src={featured_img ? featured_img : defaultThumb} alt='' className='rounded-md'/>
        </div>
        <div className='event-info flex-1 px-2'>
            <p className='text-xs text-gray-500 font-base font-light swipeable'>{events_info?.driggs_events_date} {events_info?.driggs_events_time}</p>
            <h3 className='text-xl font-sans text-[#262627] font-bold leading-5 swipeable my-1'>{event?.title?.rendered}</h3>
            <div className='text-xs text-gray-500 font-base font-light swipeable'><img src={pin} alt='' className='inline-block align-middle' /> {events_info?.driggs_events_location}</div>
        </div>
    </div>
    </SwipeConfig>
  )
}

export default EventItem