import React from 'react';
import StartRecord from '../../assets/images/startrecord.png';
export default function AxiomRecordIcon({ clickHandler }) {
  return (
    <div
      className="flex flex-col items-center min-w-[40px] "
      onClick={() => clickHandler()}
    >
      <img src={StartRecord} alt="Record" className="max-w-[25px]" />
      <div className="text-xs">Record</div>
    </div>
  );
}
