import React from 'react'
import { useCalc } from '../../contexts/CalcContext'
import { format_currency, validate_number } from '../../helpers'
import uiStyles from '../ui/uiStyles'

const CalcGrossIncome = ({ calculator_type = 'buy', setShowModal }) => {
  const { calc, saveCalc } = useCalc()

  const get_gross_incomes = (data = calc?.gross_incomes || {}) => {
    let last_key = ''
    let last_val = ''
    let html = ''
    return Object.entries(data || {})?.map(([key, value]) => {
      const is_last = validate_number(last_key) === validate_number(key - 1)
      html = (
        <>
          <div
            className='cursor-pointer flex justify-between px-3 mt-[15px] border-b-2 border-accent-400'
            onClick={() => {
              saveCalc((prev) => ({
                ...prev,
                [calculator_type]: {
                  ...prev?.[calculator_type],
                  values: {
                    ...prev?.[calculator_type]?.values,
                    gross_income: validate_number(value),
                  },
                  types: {
                    ...prev?.[calculator_type]?.types,
                    gross_income: '%',
                  },
                },
              }))
              setShowModal(false)
            }}
          >
            <p>
              {is_last ? (
                <>Over ${format_currency(last_key)}</>
              ) : (
                <>
                  {last_key ? '$' : ''}
                  {last_key
                    ? format_currency(validate_number(last_key) + 1)
                    : 'Up to'}{' '}
                  - ${format_currency(key)}
                </>
              )}
            </p>
            <p>{value}%</p>
          </div>
        </>
      )
      last_key = key
      return html
    })
  }
  return (
    <div>
      <h1 className={`${uiStyles.text_black_subtitle} text-center mt-[-5px]`}>
        Gross Household Income
      </h1>
      <p className='text-xs text-center px-3'>
        The extent of tax savings for homeowners is contingent upon their income
        level and corresponding tax bracket. Please select a tax bracket that
        best reflects their potential tax savings.
      </p>
      {get_gross_incomes()}
    </div>
  )
}

export default CalcGrossIncome
