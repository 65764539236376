import { useState, useRef, useEffect } from 'react'
import { useDistribute } from '../../../contexts/DistributeContext'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import uiStyles from '../../ui/uiStyles'

export const AiPostsDelete = ({ setShowModal }) => {
  const { axiomFetch } = useAxiomFetch()
  const { distribute, saveDistribute } = useDistribute()
  const type =
    distribute?.type === 'social'
      ? distribute?.social || 'facebook'
      : 'facebook'
  const content = distribute?.content?.[type]
  const posts =
    content?.posts?.posts?.data ||
    (content?.posts?.length ? content?.posts : [])

  const [messageSent, setMessageSent] = useState({})
  const handle_msg = (status, message, type, close) => {
    setMessageSent({ ...messageSent, status, message, type })
    if (close) {
      setTimeout(() => {
        setMessageSent({ ...messageSent, status: 'done', message: '' })
      }, 3000)
    }
  }

  const save_distribute = (key, value) => {
    saveDistribute((prev) => ({
      ...prev,
      content: {
        ...(prev?.content || []),
        [type]: { ...(prev?.content?.[type] || []), [key]: value },
      },
    }))
  }

  const save_posts = (key, value) => {
    saveDistribute((prev) => ({
      ...prev,
      content: {
        ...(prev?.content || []),
        [type]: {
          ...(prev?.content?.[type] || []),
          posts: {
            ...prev?.content?.[type]?.posts,
            posts: {
              ...prev?.content?.[type]?.posts.posts,
              [key]: value,
            },
          },
        },
      },
    }))
  }
  //   const save_post = (key, value) => {
  //     saveDistribute((prev) => ({
  //       ...prev,
  //       content: {
  //         ...(prev?.content || []),
  //         [type]: {
  //           ...(prev?.content?.[type] || []),
  //           selected_post: {
  //             ...prev?.content?.[type]?.selected_post,
  //             [key]: value,
  //           },
  //         },
  //       },
  //     }))
  //   }

  const delete_post = async () => {
    handle_msg('loading_msg', 'Deleting Post...', 'save_edit_post')
    await axiomFetch(`/social/${type}/post/delete`, 'POST', {
      post_id: content.selected_post.id,
    })
    const new_posts = posts.filter(
      (item) => item.id !== content.selected_post.id
    )
    save_posts('data', [...new_posts])
    handle_msg('success_msg', 'Post Deleted!', 'save_edit_post', true)
    setShowModal(false)
  }
  console.log(content.selected_post)
  return (
    <div>
      <label className='text-lg'>Delete Post</label>
      {content?.selected_post?.full_picture || content?.selected_post?.photo ? (
        <div className='flex mt-[10px]'>
          Image:{' '}
          <img
            className='ml-[10px] w-[50px] mb-[10px]'
            src={
              content?.selected_post?.full_picture ||
              content?.selected_post?.photo
            }
          />
        </div>
      ) : (
        ''
      )}
      <div className='flex'>
        <div className='mr-[10px]'> Message:</div>
        <div className='overflow-y-scroll max-h-[100px] mb-[10px]'>
          {content.selected_post.message}{' '}
        </div>
      </div>
      <div className='flex justify-end'>
        {messageSent?.type === 'save_edit_post' ? (
          <div className={`${uiStyles[messageSent.status]} mb-[20px]`}>
            {messageSent.message}
          </div>
        ) : (
          <button className={uiStyles.red_btn} onClick={delete_post}>
            Delete
          </button>
        )}
      </div>
    </div>
  )
}
