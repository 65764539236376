import React, { forwardRef } from 'react';
import { HiOutlineCheck } from 'react-icons/hi2';

const Checkbox = forwardRef(({ label }, ref) => {
  return (
    <div className="custom-radio-hl">
      <label className="relative">
        <input
          type="checkbox"
          ref={ref}
          className="absolute left-0 right-0 top-0 h-full opacity-0"
        />
        <div className="transition-all delay-50 toggle inline-flex items-center w-[16px] h-[16px] rounded bg-white p-[1px] mr-2 align-middle border border-gray-200">
          <HiOutlineCheck className="transition-all delay-100 text-accent-200" />
        </div>
        {label}
      </label>
    </div>
  );
});

export default Checkbox;
