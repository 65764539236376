import { useState, useEffect } from 'react'
import { useListingTours } from '../../../contexts/ListingToursContext'
import { useProfile } from '../../../contexts/UserContext'
import {
  getCurrentTime,
  getDateString,
  set_storage,
  validate_number,
} from '../../../helpers'
import Modal from '../../Modal'
import uiStyles from '../../ui/uiStyles'
import GeoJsonGeometriesLookup from 'geojson-geometries-lookup'
import { useListing } from '../../../contexts/ListingContext'
import { FaCheckCircle } from 'react-icons/fa'
import ListingSignupConfirm from './ListingSignupConfirm'
import ListingSignupCancel from './ListingSignupCancel'
import useAxiomFetch from '../../../hooks/useAxiomFetch'

const ListingSignup = ({ state, setState }) => {
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const [mapName, setMapName] = useState('')
  const { profile, saveProfile } = useProfile()
  const { listing } = useListing()
  const { axiomFetch } = useAxiomFetch()

  useEffect(() => {
    const find_listings_in_geos = () => {
      window.scrollTo(0, 0)
      if (state?.data?.upcoming_tour_schedule?.[0]?.listings_in_json) {
        return
      }
      if (!Array.isArray(listing)) {
        return
      }
      const listings_points = listing?.map((item) => ({
        lat: item?.lat,
        lng: item?.lng,
        listing: item,
      }))

      const tours = get_tours()
      const upcoming_tours = tours?.map((item) => {
        const points_in_json = check_points_in_geo({
          geo_json: JSON.parse(item.geo_json),
          points: listings_points,
        })
        item.listings_in_json = points_in_json?.in
        return item
      })

      setState((prev) => ({
        ...prev,
        data: { ...prev?.data, upcoming_tour_schedule: upcoming_tours },
      }))
    }

    find_listings_in_geos()
    return () => {}
  }, [listing, state?.data?.tour_types])

  const check_points_in_geo = (options) => {
    let check_results = { in: [], out: [] }
    try {
      const glookup = new GeoJsonGeometriesLookup(options?.geo_json)
      for (const point of options?.points) {
        const check_point = {
          type: 'Point',
          coordinates: [point.lng, point.lat],
        }
        const value = glookup.countContainers(check_point)
        check_results[value ? 'in' : 'out'].push(point)
      }
      return check_results
    } catch (error) {
      console.log(error)
      return error
    }
  }

  const get_tours = () => {
    return state?.data?.upcoming_tour_schedule || []
  }
  // date, time, map link, tour_name,

  console.log('upcoming', state)
  const modals = {
    tour_map: {
      width: 'w-[345px]',
      component: (
        <div className='mt-[-30px]'>
          <iframe
            onLoad={(e) => {
              e.scrollTo({ top: 100 })
            }}
            style={{
              // transition: 'transform 0.3s ease',
              width: '100%',
              height: '550px',
              overflowY: 'scroll',
              // overflow: 'scroll',
              // marginTop: '-200px',
            }}
            src={`https://rdbeta2.driggstitle.com/prv/list_tour_console.map_js_only?acct_p=${profile.ACCOUNT_ID}&tour_nm_p=${mapName}&zoom_p=9`}
          ></iframe>
        </div>
      ),
    },
    listing_signup_confirm: {
      width: 'w-[345px]',
      height: 'h-[359px]',
      component: <ListingSignupConfirm setShowModal={setShowModal} />,
    },
    listing_signup_cancel: {
      width: 'w-[345px]',
      height: 'h-[200px]',
      component: <ListingSignupCancel setShowModal={setShowModal} />,
    },
  }

  return (
    <div>
      <div className='flex flex-col gap-[30px] text-gray-700 pb-[30px]'>
        {get_tours()
          ?.sort(
            (a, b) => b.listings_in_json?.length - a?.listings_in_json?.length
          )
          ?.map((item) => {
            return (
              <div
                key={item?.event_id}
                className={`mx-3 p-3 flex flex-col  ${
                  // state?.data?.event_tour_info?.some(
                  //   (eti) =>
                  //     eti.evt_id === item?.event_id &&
                  //     item?.listings_in_json
                  //       ?.map((item) => item?.listing?.mls_id)
                  //       .includes(eti?.details?.mls_id)
                  // )
                  item?.listings_in_json?.length
                    ? 'bg-green-200'
                    : 'bg-gray-200'
                } shadow-xl rounded-md`}
              >
                <p>
                  <span className='font-bold'>Tour Name: </span>
                  {item?.tour_name}
                </p>
                <p>
                  <span className='font-bold'>Start Time: </span>
                  {`${getDateString({
                    date: item?.event_date || '',
                  })} ${getCurrentTime(item?.event_timestamp)}`}
                </p>
                <p>
                  <span className='font-bold'>Starting Address: </span>
                  {item?.add1}
                </p>

                {item?.listings_in_json?.length ? (
                  <div className=''>
                    <p className='font-bold mt-[10px]'>Eligible Listings:</p>
                    {item?.listings_in_json?.map((in_json) => {
                      return (
                        <div
                          key={in_json.lat + in_json.lng}
                          className='flex items-center justify-between'
                        >
                          <p className='max-w-[200px] overflow-scroll whitespace-nowrap'>
                            {in_json?.listing?.prop_add}
                          </p>
                          {state?.data?.event_tour_info?.some(
                            (eti) =>
                              eti?.mls_num ===
                                in_json?.listing?.mls_id?.toString() &&
                              eti?.evt_id === item?.event_id
                          ) ? (
                            state?.data?.loading &&
                            state?.signup_item?.in_json?.listing?.mls_id ===
                              in_json?.listing?.mls_id ? (
                              <div>joining tour...</div>
                            ) : (
                              <div
                                className={`${uiStyles.red_btn_small} mt-[3px]`}
                                onClick={() => {
                                  setModalType('listing_signup_cancel')
                                  setShowModal(true)
                                  setState((prev) => {
                                    return {
                                      ...prev,
                                      signup_item: { item, in_json },
                                    }
                                  })
                                }}
                              >
                                Cancel Signup
                              </div>
                            )
                          ) : (
                            <button
                              onClick={async () => {
                                const data = setState((prev) => {
                                  console.log(
                                    item.event_id,
                                    in_json.listing.mls_id,
                                    [
                                      ...prev?.data?.event_signup?.filter(
                                        (es) => {
                                          if (es.event_id === item?.event_id) {
                                            if (
                                              es.list_id ===
                                              in_json?.listing?.mls_id
                                            ) {
                                              return false
                                            } else {
                                              return true
                                            }
                                          }
                                          return true
                                        }
                                      ),
                                      in_json.listing,
                                    ].length
                                  )
                                  const data = {
                                    ...prev?.data,
                                    mls_listings_v: [
                                      ...(
                                        prev?.data?.mls_listings_v || []
                                      )?.filter(
                                        (mlv) =>
                                          mlv?.mls_id !==
                                          in_json.listing?.mls_id
                                      ),
                                      in_json?.listing,
                                    ],
                                    event_pages: [
                                      ...(prev?.data?.event_pages?.filter(
                                        (ep) => ep.event_id !== item?.event_id
                                      ) || []),
                                      {
                                        ...item,
                                        cont_id: profile.CONT_ID,
                                        event_type_id: 21,
                                        tour_experience_id: 1,
                                      },
                                    ],
                                    event_signup: [
                                      ...(
                                        prev?.data?.event_signup || []
                                      )?.filter((es) => {
                                        if (es.event_id === item?.event_id) {
                                          if (
                                            es.list_id ===
                                            in_json?.listing?.mls_id
                                          ) {
                                            return false
                                          } else {
                                            return true
                                          }
                                        }
                                        return true
                                      }),
                                      {
                                        map_ord:
                                          validate_number(
                                            Math.max(
                                              prev?.data?.event_tour_info
                                                ?.filter(
                                                  (eti) =>
                                                    validate_number(
                                                      eti.cont_id
                                                    ) > 0 &&
                                                    eti.evt_id ===
                                                      item?.event_id
                                                )
                                                ?.map((eti) =>
                                                  validate_number(
                                                    eti?.tour_order
                                                  )
                                                )
                                            )
                                          ) + 1,
                                        lat: in_json.lat,
                                        lng: in_json.lng,
                                        event_id: item?.event_id,
                                        agent_name: profile.CONT_NAME,
                                        list_id: in_json?.listing?.mls_id,
                                        details: in_json?.listing,
                                        cont_id: profile.CONT_ID,
                                        signup_id:
                                          validate_number(
                                            Math.max(
                                              prev?.data?.event_signup?.map(
                                                (item) => item?.signup_id
                                              )
                                            )
                                          ) + 100000,
                                      },
                                    ],
                                    event_tour_info: [
                                      ...(
                                        prev?.data?.event_tour_info || []
                                      )?.filter((eti) => {
                                        if (eti.evt_id === item?.event_id) {
                                          if (
                                            eti.mls_num ===
                                            in_json?.listing?.mls_id?.toString()
                                          ) {
                                            return false
                                          } else {
                                            return true
                                          }
                                        }
                                        return true
                                      }),
                                      {
                                        evt_id: item?.event_id,
                                        tour_order:
                                          validate_number(
                                            Math.max(
                                              prev?.data?.event_tour_info
                                                ?.filter(
                                                  (eti) =>
                                                    validate_number(
                                                      eti.cont_id
                                                    ) > 0 &&
                                                    eti.evt_id ===
                                                      item?.event_id
                                                )
                                                ?.map((eti) =>
                                                  validate_number(
                                                    eti?.tour_order
                                                  )
                                                )
                                            )
                                          ) + 1,
                                        cont_id: profile.CONT_ID,
                                        agent_name: profile.CONT_NAME,
                                        agent_cell_phone:
                                          profile.CONT_CELL_PHONE,
                                        mls_num:
                                          in_json?.listing?.mls_id?.toString(),
                                        details: in_json?.listing,
                                        lat: in_json?.lat,
                                        lng: in_json?.lng,
                                        list_price:
                                          in_json?.listing?.list_price,
                                        address: in_json?.listing?.prop_add,
                                      },
                                    ],
                                    loading: true,
                                  }
                                  return {
                                    ...prev,
                                    data: data,
                                    // data: { ...prev?.data, loading: true },
                                    signup_item: {
                                      item,
                                      in_json,
                                    },
                                  }
                                })
                                setShowModal(true)
                                setModalType('listing_signup_confirm')
                                const result = await axiomFetch(
                                  '/tours/signup',
                                  'POST',
                                  {
                                    event_id: item?.event_id,
                                    eo_of_focus: item?.eo_of_focus,
                                    mls_id: in_json?.listing?.mls_id,
                                    listing: in_json?.listing,
                                    event: item,
                                    cont_id: profile?.CONT_ID,
                                    event_marketer: item.event_marketer,
                                  }
                                )
                                console.log('result', result)
                                setState((prev) => {
                                  const data = {
                                    ...prev?.data,
                                    mls_listings_v: [
                                      ...(
                                        prev?.data?.mls_listings_v || []
                                      )?.filter(
                                        (mlv) =>
                                          !(result?.mls_listings_v || [])
                                            ?.map((item) => item.mls_id)
                                            .includes(mlv?.mls_id)
                                      ),
                                      ...(result.mls_listings_v || []),
                                    ],
                                    event_pages: [
                                      ...(prev?.data?.event_pages?.filter(
                                        (ep) => ep.event_id !== item?.event_id
                                      ) || []),
                                      {
                                        ...item,
                                        cont_id: profile.CONT_ID,
                                        event_type_id: 21,
                                        tour_experience_id: 1,
                                      },
                                    ],
                                    event_signup: [
                                      ...(
                                        prev?.data?.event_signup || []
                                      )?.filter((es) => {
                                        return es.event_id !== item.event_id
                                      }),
                                      ...(result?.event_signup || []),
                                    ],
                                    event_tour_info: [
                                      ...(
                                        prev?.data?.event_tour_info || []
                                      )?.filter((eti) => {
                                        return eti.evt_id !== item?.event_id
                                      }),
                                      ...(result?.event_tour_info || []),
                                    ],
                                    user_int_v: [
                                      ...(prev?.data?.user_int_v || [])?.filter(
                                        (uiv) =>
                                          uiv?.user_id !== item?.eo_of_focus
                                      ),
                                      ...(result?.user_int_v || []),
                                    ],
                                    loading: false,
                                  }
                                  set_storage('listing_tours', data)
                                  return {
                                    ...prev,
                                    data: data,
                                  }
                                })
                              }}
                              className={`${uiStyles.hover_btn_small} mt-[3px]`}
                            >
                              Join Tour
                            </button>
                          )}
                        </div>
                      )
                    })}
                  </div>
                ) : (
                  <div className='mt-[10px]'>No Eligible Listings</div>
                )}
                <button
                  className={`${uiStyles.hover_btn_small} mt-[10px]`}
                  onClick={() => {
                    setShowModal(true)
                    setModalType('tour_map')
                    setMapName(item.tour_name)
                  }}
                >
                  Tour Area Map
                </button>
              </div>
            )
          })}
      </div>
      {showModal ? (
        <Modal
          side_padding={'px-0'}
          modalName={modalType}
          height={modals?.[modalType]?.height || 'h-[550px]'}
          width={modals?.[modalType]?.width || 'w-full'}
          showModal={showModal}
          setShowModal={(type) => {
            setShowModal(false)
          }}
          showClose={true}
        >
          {modals?.[modalType]?.component || <></>}
        </Modal>
      ) : (
        ''
      )}
    </div>
  )
}

export default ListingSignup
