import React from 'react'
import { useDistribute } from '../../../contexts/DistributeContext'
import uiStyles from '../../ui/uiStyles'

export const AiHeadline = () => {
  const { distribute, saveDistribute } = useDistribute()
  const type =
    (distribute?.type === 'social' ? distribute?.social : distribute?.type) ||
    'instagram'
  const content = distribute?.content?.[type]
  return (
    <div>
      <label>Subject Headline</label>
      <input
        className={`${uiStyles.input}`}
        value={content?.headline || ''}
        onChange={(e) => {
          saveDistribute((prev) => ({
            ...prev,
            content: {
              ...prev?.content,
              [type]: {
                ...prev?.content?.[type],
                headline: e.target.value,
              },
            },
          }))
        }}
      ></input>
    </div>
  )
}
