import React, { useState } from 'react'
import silhouette from '../../../assets/images/profile-silhouette.svg'
import Header2 from '../../Header2'
import { Videos } from '../../../data/data'
import ListItem from '../../ui/ListItem'
import SwipeConfig from '../../ui/SwipeConfig'
import { FaBolt } from 'react-icons/fa'
import SubTitle from '../../ui/SubTitle'
import Wrapper from '../../layout/Wrapper'
import { useProfile } from '../../../contexts/UserContext'
import Usage from '../../usage/Usage'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import useHelpers from '../../../hooks/useHelpers'
import { open_url_tab } from '../../../helpers'
export default function TutorialLobby() {
  const { axiomFetch } = useAxiomFetch()
  const [selected, setSelected] = useState(1)
  const { profile } = useProfile()
  console.log(profile)
  const htmlListing = Videos?.map((item, index) => {
    if (item?.id === 'market_rep') {
      item.description = item.description.replace(
        /{{name}}/g,
        profile?.REP_NAME || '{{name}}'
      )
      item.thumbnail = profile?.REP_PHOTO || silhouette
    }
    return (
      <div key={`${item?.title}-${index}`}>
        <SwipeConfig
          key={index}
          index={index}
          selectedIndex={selected}
          setSelectedIndex={setSelected}
          widthConfigRight={44}
          RightComponents={() => (
            <div
              className='cursor-pointer'
              onClick={async () => {
                await axiomFetch('/usage/updateVideoWatchHist', 'POST', {
                  url: item?.url,
                  unique_name: item?.unique_name,
                })
                open_url_tab(item?.url)
              }}
            >
              Open
            </div>
          )}
        >
          {/* <Link to={item.link}> */}
          <div className=''>
            <ListItem
              key={`${item?.title}-${index + 1}`}
              image={item?.thumbnail || ''}
              title={item?.title || ''}
              content={item?.description || ''}
              fill={
                item?.unique_name === 'axiom_intro_video'
                  ? 'object-fill'
                  : 'object-cover'
              }
            />
          </div>
          {/* </Link> */}
        </SwipeConfig>
      </div>
    )
  })
  return (
    <Wrapper>
      <Header2 />
      <div className='flex items-center px-2 py-4'>
        <div>
          <FaBolt className='text-yellow-500 text-6xl' />
        </div>
        <div>
          <SubTitle>Instructional Videos</SubTitle>
          <p>All instructional videos can be accessed here.</p>
        </div>
      </div>
      <div className='flex flex-col gap-[2px]'>{htmlListing}</div>
    </Wrapper>
  )
}
