import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { set_storage } from '../helpers'
import useAxiomFetch from '../hooks/useAxiomFetch'
import { useClaims } from './ClaimsContext'

export const ListingToursContext = createContext()

export const ListingToursProvider = ({ children, userData }) => {
  const [listingTours, setListingTours] = useState({})
  const { claims } = useClaims()
  const { axiomFetch } = useAxiomFetch()
  const navigate = useNavigate()
  const saveListingTours = useCallback((data) => {
    setListingTours(data)
  }, [])

  const save_home = async (data, nav) => {
    const already_exists = () => {
      if (
        (listingTours?.data?.event_tour_info || [])?.find(
          (item) =>
            item?.evt_id === (data?.evt_id || data?.event_id) &&
            item?.mls_num?.toString() === data?.mls_id?.toString()
        )
      ) {
        return true
      } else {
        return false
      }
    }
    let result = {}
    if (!already_exists()) {
      result = await axiomFetch('/tours/property/add', 'POST', data || {})
      console.log('result', result)
      const event_tour_result = result?.event_tour_info?.[0]
      const event_signup_result = result?.event_signup?.[0]
      console.log('tour_result', event_tour_result)
      saveListingTours((prev) => {
        const event_tour_info = [
          ...prev?.data?.event_tour_info?.filter(
            (item) =>
              !(
                item.evt_id?.toString() ===
                  event_tour_result?.evt_id?.toString() &&
                item.mls_num?.toString() ===
                  event_tour_result?.mls_num?.toString()
              )
          ),
          event_tour_result,
        ]
        const event_signup = [
          ...prev?.data?.event_signup?.filter(
            (item) =>
              !(
                item.event_id?.toString() ===
                  event_signup_result?.event_id?.toString() &&
                item.list_id?.toString() ===
                  event_signup_result?.list_id?.toString()
              )
          ),
          event_signup_result,
        ]

        set_storage('listing_tours', {
          ...prev?.data,
          event_tour_info,
          event_signup,
        })
        return {
          ...prev,
          data: { ...prev?.data, event_tour_info, event_signup },
        }
      })
    }
    if (nav) {
      return navigate(nav)
    } else {
      return result
    }
  }
  const get_buyers = (event_id) => {
    return listingTours?.data?.event_tour_info?.filter(
      (item) => item?.evt_id === event_id && item.cont_id > 0 && !item.mls_num
    )
  }
  const get_homes = (event_id) => {
    return listingTours?.data?.event_tour_info?.filter(
      (item) => item?.evt_id === event_id && item.cont_id > 0 && item.mls_num
    )
  }

  const get_tours_by_type = (type) => {
    return listingTours?.data?.event_pages?.filter(
      (item) => item.event_type_id === type
    )
  }
  const get_tour_type = (type) => {
    return listingTours?.data?.tour_types?.find((item) => item.type_id === type)
  }
  const get_event_type_id = (event_id) => {
    return listingTours?.data?.event_pages?.find(
      (item) => item.event_id === event_id
    )?.event_type_id
  }

  const get_event_tour_type_id = (event_id) => {
    return listingTours?.data?.event_pages?.find(
      (item) => item.event_id === event_id
    )?.tour_experience_id
  }

  const is_assigned_tour = (tour_type_id) => {
    return listingTours?.data?.tour_types_asg
      ?.map((item) => item?.tour_type_id?.toString())
      ?.includes(tour_type_id?.toString())
  }

  const get_show_tours = () => {
    return (listingTours?.data?.tour_types || [])?.filter((item) => {
      if (item?.type_id === 1) {
        return false
      } else if (
        !is_assigned_tour(item?.type_id) &&
        item?.event_type_id === 163 &&
        !claims?.user_id
      ) {
        return false
      } else {
        return true
      }
    })
  }

  const get_experience_type_id = (type) => {
    return get_show_tours()?.find((item) => item.event_type_id === type)
      ?.type_id
  }

  const show_tour_experience_lobby = () => {
    return get_show_tours()?.length > 1
  }

  const is_buyer_tour = () => {
    return (listingTours?.buyer_tours?.event_type_id || 162) === 162
  }

  const get_sec_per_home = (event_id) => {
    const event = listingTours?.data?.event_pages?.find(
      (item) => Number(item?.event_id) === Number(event_id)
    )
    console.log('time_event', event)
    const time = Number(event?.mins_between_house || 10) * 60
    return time
  }

  const value = useMemo(
    () => ({
      listingTours,
      saveListingTours,
      save_home,
      get_buyers,
      get_homes,
      get_tours_by_type,
      get_tour_type,
      get_event_type_id,
      get_event_tour_type_id,
      is_assigned_tour,
      show_tour_experience_lobby,
      get_experience_type_id,
      is_buyer_tour,
      get_show_tours,
      get_sec_per_home,
    }),
    [listingTours, saveListingTours, save_home, get_buyers]
  )

  return (
    <ListingToursContext.Provider value={value}>
      {children}
    </ListingToursContext.Provider>
  )
}

export const useListingTours = () => {
  const context = useContext(ListingToursContext)

  if (!context) {
    throw Error('TourContext must be used inside an TourListingContextProvider')
  }

  return context
}
