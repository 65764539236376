import React from 'react'
import Wrapper from '../../components/layout/Wrapper'
import { AiAssistant } from '../../components/pages/AiSlots'
import SubNav from '../../components/SubNav'
export const PageAiAssistant = () => {
  return (
    <>
      <Wrapper>
        <SubNav url={'/user/marketing-lobby'} />
        <AiAssistant />
      </Wrapper>
    </>
  )
}
