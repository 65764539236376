import Wrapper from '../../components/layout/Wrapper'
import { AiCard } from '../../components/pages/AiSlots'
import SubNav from '../../components/SubNav'
import { useAi } from '../../contexts/AiContext'
import PublicWrapper from './PublicWrapper'
const PageCardAi = () => {
  const { ai, saveAi } = useAi()
  return (
    <PublicWrapper>
      <Wrapper>
        <SubNav
          showConversations={false}
          showTours={false}
          logo_url={'/card'}
        />
        <AiCard state={ai} setState={saveAi} />
      </Wrapper>
    </PublicWrapper>
  )
}

export default PageCardAi
