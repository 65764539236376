import { useState } from 'react'
import { set_storage } from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import uiStyles from '../../ui/uiStyles'

const ResetRatings = ({ market_name, state, setState, setShowModal }) => {
  const { axiomFetch } = useAxiomFetch()
  const [loading, setLoading] = useState(false)

  return (
    <div>
      <div className='px-3 text-center mb-[10px]'>
        <p>
          Are you sure you want to reset ratings to the most current template?{' '}
        </p>
      </div>
      <div className='flex justify-end gap-[5px]'>
        <button
          className={`${uiStyles.white_btn}`}
          onClick={() => {
            setShowModal(false)
          }}
        >
          Cancel
        </button>
        <button
          className={`${uiStyles.red_btn}`}
          onClick={async () => {
            setLoading(true)
            const result = await axiomFetch(
              '/tours/rating/reset',
              'POST',
              {
                tour_type_id: state?.buyer_tours?.tour_experience_id,
                event_type_id: state?.buyer_tours?.event_type_id,
              } || {}
            )

            setState((prev) => {
              const tour_rating_items = [
                ...prev?.data?.tour_rating_items?.filter(
                  (item) =>
                    item.tour_type_id !== state?.buyer_tours?.tour_experience_id
                ),
                ...(result || []),
              ]

              set_storage('listing_tours', { ...prev?.data, tour_rating_items })
              return {
                ...prev,
                data: { ...prev?.data, tour_rating_items },
              }
            })
            setLoading(false)
            setShowModal(false)
          }}
        >
          Confirm
        </button>
      </div>
    </div>
  )
}

export default ResetRatings
