import { useState } from 'react'
import { usePairwise } from '../../../contexts/PairwiseContext'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import uiStyles from '../../ui/uiStyles'

const PairwiseCancel = ({ setShowModal, setModalType }) => {
  const { axiomFetch } = useAxiomFetch()
  const { pairwise, savePairwise } = usePairwise()
  const [loading, setLoading] = useState(false)
  return (
    <div className='px-3'>
      <h1>
        Are you sure you would like to{' '}
        <span className='font-bold'>Cancel your payment?</span>
      </h1>
      <div className={'flex justify-end gap-[10px]'}>
        <button
          className={`${uiStyles.white_btn}`}
          onClick={() => {
            setShowModal(false)
          }}
        >
          Back
        </button>
        {loading ? (
          <div>
            <SmallLoadingRel />
          </div>
        ) : (
          <button
            className={`${uiStyles.red_btn}`}
            onClick={async () => {
              setLoading(true)
              const result = await axiomFetch(
                '/pairwise/applications/cancel',
                'POST'
              )
              savePairwise((prev) => ({
                ...prev,
                data: {
                  ...prev?.data,
                  pair_application: result?.application,
                },
              }))
              setLoading(false)
              if (result?.cancelled) {
                savePairwise((prev) => ({
                  ...prev,
                  data: {
                    ...prev?.data,
                    pair_application: {
                      ...prev?.data?.pair_application,
                      payment_setup_dt: null,
                    },
                  },
                }))
                setShowModal(false)
              } else if (result?.approved || result?.payment_received) {
                setModalType('cancel_failed')
              }
            }}
          >
            Cancel
          </button>
        )}
      </div>
    </div>
  )
}

export default PairwiseCancel
