import React from 'react';
import IconPause from '../../assets/images/pause.png';
export default function AxiomPauseIcon() {
  return (
    <div className="flex flex-col  items-center justify-center min-w-[40px]">
      <img src={IconPause} alt="Pause" className="max-w-[25px]" />
      <div className="text-xs">Pause</div>
    </div>
  );
}
