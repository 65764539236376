import React from 'react'
import { useNavigate } from 'react-router-dom'
import { set_storage, short_url } from '../helpers'

const useHelpers = () => {
  const navigate = useNavigate()
  const open_url = async (url_link = '', url_name = '', show_copy = true) => {
    url_link = await short_url(url_link)
    set_storage('pdf_view', {
      url_link,
      url_name,
      show_copy,
    })
    navigate('/user/url_viewer')
  }
  return { open_url }
}

export default useHelpers
