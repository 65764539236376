import { useEffect, useRef, useState } from 'react'
import Wrapper from '../../../components/layout/Wrapper'
import SubNav from '../../../components/SubNav'
import TitleIcon from '../../../components/ui/TitleIcon'
import uiStyles from '../../../components/ui/uiStyles'
import { useNavigate } from 'react-router-dom'
import { AiFillBug } from 'react-icons/ai'
import { useProfile } from '../../../contexts/UserContext'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { useAuth0 } from '@auth0/auth0-react'
import { useDefaults } from '../../../contexts/DefaultsContext'
import { set_storage } from '../../../helpers'
import ch_images from '../../../assets/images/conversations'
import { useClaims } from '../../../contexts/ClaimsContext'
const Defaults = ({ modal, setShowModal }) => {
  const { defaults, saveDefaults } = useDefaults()
  const [defaultPage, setDefaultPage] = useState({})
  const { axiomFetch } = useAxiomFetch()
  const navigate = useNavigate()
  const [messageSent, setMessageSent] = useState({ status: 'done' })
  const { claims } = useClaims()
  const handle_msg = (status, message, close) => {
    setMessageSent({ ...messageSent, status, message })
    if (close) {
      setTimeout(() => {
        setMessageSent({ ...messageSent, status: 'done', message: '' })
      }, 3000)
    }
  }

  useEffect(() => {
    const defaults = [
      { key: 'expired' },
      { key: 'cancelled' },
      { key: 'active', name: 'new', internal_only: true },
      { key: 'contract', internal_only: true },
      { key: 'nod' },
      { key: 'fsbo' },
      { key: 'foreclosure' },
      { key: 'open', internal_only: true },
    ]
    setDefaultPage((prev) => ({ ...prev, defaults }))
  }, [])

  const update_item = async (key, value) => {
    setDefaultPage((prev) => {
      const defaults = prev?.defaults?.filter((item) => item.key !== key)
      return {
        ...prev,
        defaults: [...defaults, { key: key, loading: 1 }],
      }
    })
    saveDefaults((prev) => {
      const data = {
        ...prev,
        [key]: value,
      }
      set_storage('defaults', data)
      return data
    })
    const body = {
      [key]: value,
    }
    await axiomFetch(
      '/lobbies/defaults/update',
      'POST',
      body,
      {},
      false,
      false,
      700
    )
    setDefaultPage((prev) => {
      const defaults = prev?.defaults?.filter((item) => item.key !== key)
      return {
        ...prev,
        defaults: [...defaults, { key: key, loading: 2 }],
      }
    })
    setTimeout(() => {
      setDefaultPage((prev) => {
        const defaults = prev?.defaults?.filter((item) => item.key !== key)
        return {
          ...prev,
          defaults: [...defaults, { key: key, loading: 0 }],
        }
      })
    }, [3000])
  }

  const html = (
    <div>
      <div className={`${uiStyles.line}`}></div>
      {defaultPage?.defaults
        ?.sort((a, b) => a.key.localeCompare(b.key))
        ?.filter((item) => {
          if (!claims?.user_id && item.internal_only) {
            return false
          }
          return true
        })
        ?.map((item, index) => {
          return (
            <div
              key={item?.key}
              className={`grid grid-cols-[2fr_3fr_1fr] ${
                index % 2 === 0 ? '' : 'bg-gray-100'
              } p-[10px]`}
            >
              <label className={`text-lg w-[50px]`}>
                {item?.name || item.key}:{' '}
              </label>
              <input
                className={uiStyles.input_num}
                value={defaults[item.key]}
                onChange={async (e) => {
                  const num = Number(e.target.value?.replace(/\D/g, ''))
                  if (num > 365) {
                    return
                  }
                  await update_item(item.key, num)
                }}
              ></input>
              {defaultPage?.defaults?.find((def) => def?.key === item.key)
                ?.loading ? (
                defaultPage?.defaults?.find((def) => def?.key === item.key)
                  ?.loading === 1 ? (
                  <div className='flex items-center'>
                    <p className={'text-[15px]'}>saving...</p>
                  </div>
                ) : (
                  <div className='flex item-center'>
                    <img className='w-[30px]' src={ch_images.checkmark} />
                  </div>
                )
              ) : (
                ''
              )}
            </div>
          )
        })}
      {/* <div className={`${uiStyles.line}`}></div> */}

      {/* <div className='mt-[10px] flex justify-end gap-2'>
        {messageSent.status === 'done' ? (
          <>
            <button
              className={`${uiStyles.white_btn}`}
              onClick={() => {
                navigate(-1)
              }}
            >
              Cancel
            </button>

            <button
              className={`${uiStyles.green_btn}`}
              onClick={async () => {
                const additional_info_html = `
          <h1>
          Bug Info 
          </h1>
          <ul>
          <li>url: ${window.location.href}</li>
          <li>Name: ${profile.CONT_NAME}</li>
          <li>Logged in Cell: ${
            user?.name?.replace('+1', '') || user?.nickname?.replace('+1', '')
          }
          <li>Cell: ${profile.CONT_CELL_NUM}</li>
          <li>contId: ${profile.CONT_ID}</li>
          </ul>
          `

                const additional_info_text = `
          Bug Info:
          url: ${window?.location?.href || ''}
          Name: ${profile?.CONT_NAME || ''}
          Logged in Cell: ${
            user?.name?.replace('+1', '') ||
            user?.nickname?.replace('+1', '') ||
            ''
          }
          Cell: ${profile?.CONT_CELL_NUM || ''}
          contId: ${profile?.CONT_ID || ''}
          `
                if (textRef?.current?.value) {
                  handle_msg('success_msg', 'Feedback Sent!', true)
                  const body = {
                    msg: {
                      to: [
                        'andrewj@driggstitle.com',
                        'ryano@driggstitle.com',
                      ],
                      // to: ['andrewj@driggstitle.com'],
                      from: 'hello@theaxiomapp.com',
                      subject: `Axiom Feedback - ${profile?.CONT_NAME}`,
                      text: `${additional_info_text} ${textRef?.current?.value}`,
                      html: `${additional_info_html} <h2>User additional info:</h2> <ul>${textRef?.current?.value}</ul>`,
                    },
                    url: window?.location?.href || '',
                    cont_name: profile?.CONT_NAME || '',
                    cont_cell_phone: profile?.CONT_CELL_NUM || '',
                    login_cell_phone:
                      user?.name?.replace('+1', '') ||
                      user?.nickname?.replace('+1', '') ||
                      '',
                    bug_report_text: textRef?.current?.value || '',
                    no_send_email: false,
                    insert_bugs: true,
                  }
                  textRef.current.value = ''
                  const data = await axiomFetch(
                    '/social/email/sendEmail',
                    'POST',
                    body
                  )
                  setTimeout(() => setShowModal(false), [500])
                  console.log(data)
                }
              }}
            >
              Send
            </button>
          </>
        ) : (
          <div className={`${uiStyles[messageSent.status]} mb-[20px]`}>
            {messageSent.message}
          </div>
        )}
      </div> */}
    </div>
  )

  if (modal) {
    return (
      <>
        <TitleIcon>Feedback</TitleIcon>
        {html}
      </>
    )
  }

  return (
    <Wrapper>
      <SubNav />
      <div className='flex justify-between'>
        <p className={`${uiStyles.text_black_title} mb-[10px] pl-[10px]`}>
          Leads Defaults
        </p>
        <button
          className={`${uiStyles.hover_btn_small} m-[0px_5px_5px_0px]`}
          onClick={() => {
            navigate('/user/my-leads-lobby')
          }}
        >
          Check Leads
        </button>
      </div>
      {html}
    </Wrapper>
  )
}

export default Defaults
