import home from './home.svg'
import home_blue from './home_blue.svg'
import star_outline from './star_outline.svg'
import star_filled from './star_filled.svg'
import thumbs_up_outline from './thumbs_up_outline.svg'
import thumbs_down_outline from './thumbs_down_outline.svg'
import thumbs_up_filled from './thumbs_up_filled.svg'
import thumbs_down_filled from './thumbs_down_filled.svg'
import create_tour_icon from './create_tour_icon.png'
import buyer_tours_van from './buyer_tours_van.jpeg'
import white_label_tours_van from './white_label_tours_van.jpeg'
import home_add_icon_white from './home_add_icon_white.svg'
import home_add_icon from './add_home_icon.svg'
import add_icon_white from './add_icon_white.svg'
const images_tours = { add_icon_white, home_add_icon, home_add_icon_white, white_label_tours_van, buyer_tours_van, create_tour_icon, home, home_blue, star_outline, star_filled, thumbs_up_outline, thumbs_down_outline, thumbs_up_filled, thumbs_down_filled }

export default images_tours