import { ServiceBase } from './ServiceBase';

export class ServicePromotions extends ServiceBase {
  constructor(token) {
    super('/marketing/promotions', token);
  }
}

export class ServiceMaterialStatus extends ServiceBase {
  constructor(token) {
    super('/marketing/materials/status', token);
  }
}

export class ServiceMaterialApprove extends ServiceBase {
  constructor(token) {
    super('/marketing/materials', token);
  }
}

export class ServiceMaterialOrder extends ServiceBase {
  constructor(token) {
    super('/marketing/materials/order', token);
  }
}
export class ServiceMaterialContentTypes extends ServiceBase {
  constructor(token) {
    super('/marketing/content/types', token);
  }
}

export class ServiceMaterialContentItem extends ServiceBase {
  constructor(token) {
    super('/marketing/content/item', token);
  }
}

export class ServiceImages extends ServiceBase {
  constructor(token) {
    super('/marketing/content/images', token);
  }
}
