import React from 'react'
import { getDateString } from '../../helpers'
import uiStyles from '../ui/uiStyles'

export const Schedule = ({
  schedule_date,
  name,
  handle_change = () => {},
  no_margin = false,
  top_label = '',
  left_label,
  show_time = true,
  left_label_width,
  ...restProps
}) => {
  return (
    <div className={`${no_margin ? '' : 'pb-[8px]'}`}>
      <label className='text-sm font-medium text-[#344054]'>{top_label}</label>
      <div
        className={`flex w-[full] ${
          no_margin ? no_margin?.toString()?.replace('true', '') : 'mb-[8px]'
        } `}
      >
        <div className='flex w-full mt-0'>
          {left_label ? (
            <div
              className={`${
                left_label_width ? left_label_width : 'w-[50%]'
              } overflow-x-scroll rounded-l-md resize-none p-3 border border-gray-300 leading-4 text-thirdary text-sm`}
            >
              {left_label}
            </div>
          ) : (
            ''
          )}
          <input
            type={show_time ? 'datetime-local' : 'date'}
            name={name}
            value={
              !show_time
                ? schedule_date
                  ? getDateString({ date: schedule_date, format: 'yyyy-mm-dd' })
                  : getDateString({ date: new Date(), format: 'yyyy-mm-dd' })
                : schedule_date
                ? new Date(schedule_date)
                    .toLocaleString('sv')
                    .replace(/(\.\d{3})|Z$/g, '')
                : new Date().toLocaleString('sv').replace(/(\.\d{3})|Z$/g, '')
            }
            className={`${uiStyles.input} text-xs ${
              left_label_width || left_label
                ? 'rounded-r-md rounded-l-none'
                : 'rounded-md'
            }`}
            onChange={(e) => {
              handle_change(e)
            }}
            {...restProps}
          />
        </div>
      </div>
    </div>
  )
}
