import React from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'
import images_transactions from '../../../assets/images/transactions'
import {
  create_calendar_event,
  getDateString,
  open_url_tab,
} from '../../../helpers'
import uiStyles from '../../ui/uiStyles'

const ClosingExperience = ({ file, setShowClosingExperience }) => {
  const card_style = 'flex gap-[10px] items-start  border-b-2'
  return (
    <div>
      <div
        className='cursor-pointer'
        onClick={() => {
          setShowClosingExperience(false)
        }}
      >
        <AiFillCloseCircle size={25} />
      </div>
      <div className='flex flex-col gap-[20px] text-primary pb-[10px]'>
        <div className='flex flex-col gap-[8px] items-center mb-[10px] '>
          <h3 className={uiStyles.text_black_title}>Closing Experience</h3>
          <h4 className={`${uiStyles.text_gray} text-[17px]`}>
            Upcoming Closing Experiences
          </h4>
        </div>
        <div className={card_style}>
          <img
            src={images_transactions.calendar_icon}
            className='min-w-[50px]'
          />
          <div>
            <div>
              {file.upcoming_signings?.map((signing, index) => {
                return (
                  <div key={signing.schd_id}>
                    <div className='flex gap-[5px] flex-col'>
                      <div className='flex gap-[5px] '>
                        <label className='font-bold'>Signing Date:</label>

                        <p
                          className='underline cursor-pointer'
                          onClick={() => {
                            const desc = `The Closing Experience begins with a unique and engaging touch—a fortune cookie. This isn't just any fortune cookie; it contains a special message that reveals a meaningful gift for the new homeowner. This small but thoughtful gesture sets a positive tone and creates a memorable moment for your client. Next, we take the celebration to the screen. Both you and your client are recognized in a special on-screen presentation. This public acknowledgment not only makes your client feel valued but also highlights your role in their home-buying journey. It's a moment of pride and joy that strengthens your relationship with your client. To capture the essence of this special occasion, we take a commemorative photo of you and your client. This photo serves as a lasting memory of the successful completion of their home-buying journey. It also acts as a tangible reminder of your dedication and excellent service, which can be shared with friends and family, further increasing the chances of referrals. Your presence during The Closing Experience is crucial. It demonstrates your commitment to your clients and shows that you are there to celebrate their milestones. This personal touch not only enhances the client's experience but also significantly increases the likelihood of them referring you to others. Your dedication and involvement are key factors in capturing referrals, which are essential for your continued success.`
                            create_calendar_event(
                              'Signing Appointment',
                              new Date(signing.sign_dt_time),
                              new Date(signing.sign_dt_end_time),
                              `${signing?.address1 || ''} ${
                                signing?.address2 || ''
                              } ${signing?.city || ''} ${
                                signing?.state || ''
                              } ${signing?.postal_code || ''}`
                                ?.replaceAll(' , ', '')
                                ?.trim(),
                              desc +
                                ` https://maps.apple.com/?daddr=${signing?.lat},${signing?.lng}`,
                              `https://www.google.com/maps/dir/?api=1&destination=${signing?.lat},${signing?.lng}`
                            )
                          }}
                        >
                          {getDateString({
                            date: signing?.sign_dt_time,
                            format: 'mmm d, hh:mi am',
                          })}
                        </p>
                      </div>
                      <div className='flex gap-[5px]'>
                        <label className='font-bold'>Location:</label>
                        <div
                          className='underline cursor-pointer'
                          onClick={() => {
                            if (signing?.lat && signing?.lng) {
                              open_url_tab(
                                `https://www.google.com/maps/dir/?api=1&destination=${signing?.lat},${signing?.lng}`
                              )
                            }
                          }}
                        >
                          <p>
                            {` ${signing?.address1 || ''}, ${
                              signing?.address2 || ''
                            }`
                              ?.replaceAll(', ', '')
                              ?.trim()}
                          </p>
                          <p>
                            {` ${signing?.city || ''}, ${
                              signing?.state || ''
                            } ${signing?.postal_code || ''}`
                              ?.replaceAll(' , ', '')
                              ?.trim()}
                          </p>
                        </div>
                      </div>
                    </div>
                    {file.upcoming_signings?.length > 1 &&
                    index + 1 !== file.upcoming_signings?.length ? (
                      <hr className='my-[5px]'></hr>
                    ) : (
                      ''
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className={card_style}>
          <img src={images_transactions.gift_icon} className='min-w-[50px]' />
          <div>
            <p className='font-bold '>Fortune Cookie Reveal:</p>
            <p>
              The Closing Experience begins with a unique and engaging touch—a
              fortune cookie. This isn't just any fortune cookie; it contains a
              special message that reveals a meaningful gift for the new
              homeowner. This small but thoughtful gesture sets a positive tone
              and creates a memorable moment for your client.
            </p>
          </div>
        </div>
        <div className={card_style}>
          <img src={images_transactions.screen_icon} className='min-w-[50px]' />
          <div>
            <p className='font-bold '>On-Screen Recognition:</p>
            <p>
              Next, we take the celebration to the screen. Both you and your
              client are recognized in a special on-screen presentation. This
              public acknowledgment not only makes your client feel valued but
              also highlights your role in their home-buying journey. It's a
              moment of pride and joy that strengthens your relationship with
              your client.
            </p>
          </div>
        </div>
        <div className={card_style}>
          <img src={images_transactions.smile_icon} className='min-w-[50px]' />
          <div>
            <p className='font-bold '>Commemorative Photo:</p>
            <p>
              To capture the essence of this special occasion, we take a
              commemorative photo of you and your client. This photo serves as a
              lasting memory of the successful completion of their home-buying
              journey. It also acts as a tangible reminder of your dedication
              and excellent service, which can be shared with friends and
              family, further increasing the chances of referrals.
            </p>
          </div>
        </div>
        <div className={card_style}>
          <img src={images_transactions.team_icon} className='min-w-[50px]' />
          <div>
            <p className='font-bold '>The Power of Your Presence:</p>
            <p>
              Your presence during The Closing Experience is crucial. It
              demonstrates your commitment to your clients and shows that you
              are there to celebrate their milestones. This personal touch not
              only enhances the client’s experience but also significantly
              increases the likelihood of them referring you to others. Your
              dedication and involvement are key factors in capturing referrals,
              which are essential for your continued success.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClosingExperience
