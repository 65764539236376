import React, { useState, useEffect, useRef } from 'react'
import Hamburger from './ui/Hamburger'
import MenuItem from './ui/MenuItem'
import { useProfile } from '../contexts/UserContext'
import { NAVMENU } from '../data/data'
import { useAuth0 } from '@auth0/auth0-react'
import { useClaims } from '../contexts/ClaimsContext'
import { phone_is_admin } from '../helpers'
import { useReceiver } from '../contexts/ReceiverContext'

export default function Menu({ type = 'cont_id' }) {
  const { logout, user } = useAuth0()
  const { profile } = useProfile()
  const { claims } = useClaims()
  const clickRef = useRef(null)
  const { receiver, saveReceiver } = useReceiver()
  const [menuState, setmenuState] = useState(false)

  useEffect(() => {
    function handle_body(e) {
      if (
        (Array.isArray(e.target.className) ||
          typeof e.target.className === 'string') &&
        !e.target.className.includes('menu')
      ) {
        setmenuState(false)
      }
    }

    if (document.body.getAttribute('listener') === 'true') {
      document?.body?.removeEventListener('click', handle_body)
    }

    document.body.setAttribute('listener', 'true')
    document.body.addEventListener('click', handle_body)

    return () => {
      if (document.body.getAttribute('listener') === 'true') {
        document?.body?.removeEventListener('click', handle_body)
      }
    }
  }, [])
  let dropdown = <></>
  if (type === 'vbc') {
    dropdown = <></>
  } else if (claims?.is_internal === 'Y') {
    dropdown =
      NAVMENU.length &&
      NAVMENU.filter((item) => {
        if (item?.internal || item?.internal_only) {
          return true
        } else {
          return false
        }
      }).map((item, index) => {
        return (
          <div
            key={index + (item?.id || '')}
            onClick={() => {
              setmenuState(false)
            }}
          >
            <MenuItem
              key={index}
              icon={item.icon}
              title={item.title}
              subtitle={item.subtitle}
              link={item.link}
            />
          </div>
        )
      })
  } else {
    dropdown =
      NAVMENU.length &&
      NAVMENU?.filter((item) => !item.internal_only).map((item, index) => {
        return (
          <div
            key={index + (item?.id || '')}
            onClick={() => {
              setmenuState(false)
            }}
          >
            <MenuItem
              key={index}
              icon={item.icon}
              title={item.title}
              subtitle={item.subtitle}
              link={item.link}
            />
          </div>
        )
      })
  }

  const logoutHandler = () => {
    localStorage.setItem('vbc_receiver', null)
    if (profile?.CONT_ID === 535922) {
      return logout({
        returnTo: `${window.location.origin}/login?password=true`,
      })
    }
    return logout({ returnTo: window.location.origin })
  }
  return (
    <>
      <div className='menu absolute w-[full] h-full bg-blue-200'></div>
      <div className='menu relative'>
        <div
          className={`menu transition-all  delay-100000 
          ${
            ''
            // menuState ? 'rotate-90' : 'rotate-0'
          }`}
          onClick={() => setmenuState(!menuState)}
        >
          <Hamburger menuState={menuState} />
        </div>
        <div
          className={`menu transition-all absolute top-7 right-0 w-72  z-50 dropdown   bg-white shadow-md rounded-md overflow-hidden ${
            menuState
              ? 'scale-100 opacity-100 h-auto'
              : 'scale-50 opacity-0 h-0'
          }`}
        >
          <div className='pt-[5px]'>{dropdown}</div>
          <div
            onClick={logoutHandler}
            className='menu cursor-pointer text-base font-base text-gray-800 text-center border-t border-[#CCDDFF] px-2 py-3 font-semibold'
          >
            Logout
          </div>
        </div>
      </div>
      {menuState && (
        <div className='backdrop absolute backdrop-blur-sm left-0 right-0 top-[72px] bottom-0 z-30'></div>
      )}
    </>
  )
}
