import { useEffect, useState, useRef, useLayoutEffect } from 'react'
import uiStyles from '../../../components/ui/uiStyles'
import ch_images from '../../../assets/images/conversations'
import TitleIcon from '../../ui/TitleIcon'
import SubNav from '../../SubNav'
import { HiArrowLeft } from 'react-icons/hi2'
import { MessageBubble } from './MessageBubble'
import './conversation.css'
import { useConversations } from '../../../contexts/ConversationsContext'
import SmallLoading from '../../ui/SmallLoading'
import emojies from '../../../assets/emojies.json'
import { AiFillCloseCircle } from 'react-icons/ai'
import { AskAxiom } from '../AI/AskAxiom'
import Modal from '../../Modal'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
export const Conversation = ({
  conversation,
  setCurrentConversation,
  currentMessages,
}) => {
  const { axiomFetch } = useAxiomFetch()
  const { saveConvMessages, convMessages } = useConversations()

  //
  const [newMessage, setNewMessage] = useState({ body: '' })
  const [newFiles, setNewFiles] = useState([])
  const [loadMedias, setLoadMedias] = useState(true)
  const [loading, setLoading] = useState(true)
  const [sendMessage, setSendMessage] = useState(0)
  const [messageSent, setMessageSent] = useState({})
  const [showEmojies, setShowEmojies] = useState(false)
  const [emojiSearch, setEmojiSeach] = useState('')
  const [modalType, setModalType] = useState('none')
  const [aiState, setAiState] = useState({})
  const [showModalAi, setShowModalAi] = useState(false)

  const modals = {
    ai: (
      <div>
        <AskAxiom
          type='text'
          state={aiState}
          setState={setAiState}
          rewrite={newMessage?.state?.body}
        />
        <div className='flex justify-end'>
          <button
            className={uiStyles.hover_btn}
            onClick={() => {
              setNewMessage((prev) => ({
                ...prev,
                body: aiState?.message || prev?.body,
              }))
              setAiState((prev) => ({ ...prev, message: '' }))
              let grower = document.getElementById('grower')
              if (grower) {
                grower.dataset.replicatedValue =
                  aiState?.message || newMessage?.body
              }

              msgRef.current.focus()
              setShowModalAi(false)
            }}
          >
            Use Text
          </button>
        </div>
      </div>
    ),
  }

  const handle_msg = (status, message, type, close) => {
    setMessageSent({ ...messageSent, status, message, type })
    if (close) {
      setTimeout(() => {
        setMessageSent({ ...messageSent, status: 'done', message: '' })
      }, 500)
    }
  }

  const bottomRef = useRef()
  const firstRender = useRef(true)
  const nextRenders = useRef(false)
  const msgRef = useRef(null)
  const send_message = async () => {
    handle_msg('loading_msg', 'Sending Messages', 'send_message')
    try {
      if (newFiles?.length) {
        for (const file of newFiles) {
          let msg
          msg = await conversation?.prepareMessage()

          msg = await msg.addMedia({
            media: file,
            contentType: file.type,
            filename: file.name,
          })
          await msg?.build().send()
        }
      }
      if (newMessage?.body?.length) {
        await conversation.sendMessage(newMessage?.body || '')
      }
      if (document?.getElementById('grower')?.dataset?.replicatedValue) {
        document.getElementById('grower').dataset.replicatedValue = ''
      }
      handle_msg('success_msg', 'Message Sent!', 'send_message', true)
      setNewMessage({})
      setNewFiles([])
      setSendMessage((prev) => prev + 1)
    } catch (error) {
      handle_msg('failure_msg', 'Error occurred.', 'send_message', true)
      console.log(error)
    }
    // setMessages((prev) => [...prev, message])
  }

  const get_medias = async () => {
    setLoading(true)
    const all_msgs_urls = await Promise?.all(
      currentMessages?.messages?.items?.map(async (msg) => {
        const media = await msg?.getMediaByCategories(['media'])
        const all_urls = await Promise.all(
          media.map(async (url) => {
            return (
              (await url?.getContentTemporaryUrl()) +
              '***' +
              url?.state?.contentType
            )
          })
        )
        return { [msg?.state?.sid]: all_urls }
      }) || []
    )
    saveConvMessages((prev) => {
      return {
        ...prev,
        [conversation?.sid]: {
          ...prev?.[conversation?.sid],
          msg_urls: {
            ...prev?.[conversation?.sid]?.msg_urls,
            ...all_msgs_urls?.reduce(
              (obj, item) =>
                Object?.assign(obj, {
                  [Object?.keys(item)?.[0]]: Object?.values(item)?.[0],
                }),
              {}
            ),
          },
          last_msg_media: false,
        },
      }
    })
    setLoading(false)
    nextRenders.current = true
    firstRender.current = false
  }

  useEffect(() => {
    if (firstRender.current) {
      get_medias()
    }
    return () => {
      firstRender.current = false
    }
  }, [])

  useEffect(() => {
    if (nextRenders.current) {
      // get_medias()
      if (!loadMedias) {
        setTimeout(() => {
          bottomRef.current.scrollIntoView({ behavior: 'smooth' })
        }, [0])
      }
    }
    return () => {
      firstRender.current = false
    }
  }, [currentMessages?.messages])

  useEffect(() => {
    if (!loadMedias) {
      if (bottomRef) {
        setTimeout(() => {
          bottomRef.current.scrollIntoView()
        }, [0])
      }
    }
  }, [loadMedias])

  useEffect(() => {
    const load = async () => {
      if (convMessages?.[conversation?.sid]?.unread_count) {
        convMessages[conversation?.sid].unread_count = 0
      }
      await conversation?.setAllMessagesRead()
      await axiomFetch(
        '/marketing/conversations/messages/update_read_date',
        'POST',
        { ch_id: conversation?.sid }
      )
    }

    load()
  }, [currentMessages?.messages])

  return (
    <>
      {loadMedias || loading ? (
        <div className='bg-white opacity-50 absolute pointer-events-none flex items-center justify-center w-full h-full'>
          <span className={`flex h-4 w-4  items-center`}>
            <span className='animate-ping  inline-flex h-full w-full rounded-full bg-gradient-to-b from-accent-150 to-accent-400 opacity-75'></span>
          </span>
        </div>
      ) : (
        ''
      )}
      <div className='flex flex-col min-h-[600px]'>
        <SubNav />
        <TitleIcon>
          SMS | {conversation?.attributes?.name || conversation?.friendlyName}
        </TitleIcon>
        <div
          id='chat_box'
          className='max-h-[550px] overflow-y-scroll mt-[auto]'
        >
          {currentMessages?.messages?.items?.length &&
            currentMessages?.messages?.items?.map((msg, index, arr) => {
              let msg_urls = convMessages?.[msg?.conversation?.sid]?.msg_urls

              let last_index, last_sid
              if (msg_urls && Object?.keys(msg_urls)?.length) {
                last_index = Object?.keys(msg_urls)?.findLastIndex(
                  (item) => msg_urls?.[item]?.length
                )
                last_sid = Object?.keys(msg_urls)?.[last_index]
              }
              return (
                <div key={msg?.state?.sid} className={``}>
                  <div>
                    {/* find the last index where a message contains a url msg_urls are outside of the message*/}
                    <MessageBubble
                      conversation={conversation}
                      msg={msg}
                      setLoadMedias={setLoadMedias}
                      loading={loading}
                      last={msg?.state?.sid === last_sid}
                      ref={bottomRef}
                    />
                  </div>
                </div>
              )
            })}
          <div ref={bottomRef} className={'h-[10px]'}></div>
        </div>
        {messageSent?.type === 'send_message' ? (
          <div
            className={`${uiStyles[messageSent.status]} mb-[10px] mx-[20px]`}
          >
            {messageSent.message}
          </div>
        ) : (
          <>
            <div
              className={`overflow-x-scroll flex w-[267px] pr-[10px] h-[100px] m-[0_auto] border-[2px] border-t-rounded border-[#005FCC] border-b-0 ${
                newFiles?.length ? '' : 'hidden'
              }`}
            >
              {newFiles?.length
                ? newFiles?.map((item) => {
                    return (
                      <div
                        key={item?.name}
                        className='relative w-[85px] h-[20px] p-[15px_10px_10px_10px] cursor-pointer'
                        onClick={() => {
                          setNewFiles((prev) =>
                            prev?.filter((file) => file.name !== item?.name)
                          )
                        }}
                      >
                        <div className='z-10 absolute top-[3px] left-[0px]'>
                          <AiFillCloseCircle size={25} />
                        </div>
                        <img
                          className='border-gray-300 border p-[10px] min-w-[75px] object-contain'
                          key={item?.name}
                          src={URL.createObjectURL(item)}
                        />
                      </div>
                    )
                  })
                : ''}
            </div>
            <div
              className={`overflow-y-scroll flex flex-col w-[268px] h-[100px] m-[0_auto] border-[2px] border-t-rounded border-[#005FCC] border-b-0 ${
                showEmojies ? '' : 'hidden'
              }`}
            >
              <input
                className={`${uiStyles.input_small} m-[5px] max-h-[25px] w-[95%]`}
                placeholder='Search'
                value={emojiSearch}
                onChange={(e) => {
                  setEmojiSeach(e.target.value)
                }}
              />
              <div className='flex flex-wrap gap-[10px_15px] p-[5px_10px_5px_10px]'>
                {emojies
                  ?.filter((item) => item?.name?.includes(emojiSearch))
                  .map((item) => (
                    <div
                      key={item?.char}
                      className='w-[10px] cursor-pointer'
                      onClick={() => {
                        setNewMessage((prev) => ({
                          ...prev,
                          body: prev?.body
                            ? prev?.body + item?.char
                            : item?.char,
                        }))
                        let grower = document.getElementById('grower')
                        if (grower) {
                          grower.dataset.replicatedValue = newMessage?.body
                            ? newMessage?.body + item?.char
                            : item?.char
                        }
                        msgRef?.current?.focus()
                      }}
                    >
                      {item?.char}
                    </div>
                  ))}
              </div>
            </div>
            <div className='flex gap-[10px] justify-center p-[10px] pt-0 items-center'>
              <label className={`w-[30px] cursor-pointer`}>
                <input
                  type='file'
                  className='hidden'
                  onChange={async (e) => {
                    setNewFiles((prev) => [...prev, ...e.target.files])
                    // await send_message(e.target.files)
                    // sendMessage((prev) => prev + 1)
                  }}
                />
                <img src={ch_images.attachment} className={`w-[20px]`} />
              </label>
              <div className={`grow-wrap-2 relative`} id='grower'>
                <textarea
                  className={`${uiStyles.text_area} ${''}`}
                  ref={msgRef}
                  onFocus={(e) =>
                    e.currentTarget.setSelectionRange(
                      e.currentTarget.value.length,
                      e.currentTarget.value.length
                    )
                  }
                  value={newMessage?.body || ''}
                  onChange={(e) => {
                    e.target.parentNode.dataset.replicatedValue = e.target.value
                    setNewMessage((prev) => ({ ...prev, body: e.target.value }))
                  }}
                  onKeyDown={async (e) => {
                    console.log(e)
                    if (e.key === 'a' && e.metaKey && e.shiftKey) {
                      e.preventDefault()
                      setAiState({ message: newMessage?.body || '' })
                      setModalType('ai')
                      setShowModalAi((prev) => !prev)
                      return
                    }
                    if (e.key === 'e' && e.metaKey) {
                      console.log('hello')
                      setShowEmojies((prev) => !prev)
                      return
                    }
                    if (e.key === 'Enter') {
                      await send_message()
                    }
                  }}
                ></textarea>
                <div
                  className='absolute top-[16px] left-[8px] cursor-pointer'
                  onClick={() => {
                    setShowEmojies((prev) => !prev)
                  }}
                >
                  <img
                    src={ch_images.emoji_icon}
                    className={`${
                      showEmojies
                        ? '[filter:_invert(70%)_sepia(72%)_saturate(3966%)_hue-rotate(135deg)_brightness(94%)_contrast(101%)]'
                        : ''
                    }`}
                  />
                </div>
                <div
                  className='absolute top-[16px] right-[14px] cursor-pointer'
                  onClick={() => {
                    setModalType((prev) => (showModalAi ? '' : 'ai'))
                    setAiState({ message: newMessage?.body || '' })
                    setShowModalAi((prev) => true)
                  }}
                >
                  <img
                    src={ch_images.robot_icon}
                    className={`w-[20px] ${
                      showModalAi
                        ? '[filter:_invert(70%)_sepia(72%)_saturate(3966%)_hue-rotate(135deg)_brightness(94%)_contrast(101%)]'
                        : ''
                    }`}
                  />
                </div>
              </div>
              <img
                className='cursor-pointer w-[25px]'
                onClick={send_message}
                src={ch_images.send}
              />
            </div>
          </>
        )}
      </div>
      {showModalAi ? (
        <Modal
          showModal={showModalAi}
          setShowModal={setShowModalAi}
          modalName={modalType}
          height={'h-[500px]'}
        >
          {modals?.[modalType]}
        </Modal>
      ) : (
        ''
      )}
    </>
  )
}
