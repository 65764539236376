import React from 'react'
import Usage from '../usage/Usage'

export default function Wrapper({
  children,
  className,
  show = true,
  ...restProps
}) {
  return (
    <>
      {show ? (
        <div>
          <div
            {...restProps}
            id='wrapper'
            className={`${
              className ? className : ''
            } bg-white shadow-md rounded-md relative w-full min-h-[600px]`}
          >
            {children}
          </div>
        </div>
      ) : (
        <div>{children}</div>
      )}
    </>
  )
}
