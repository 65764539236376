import React, { useEffect, useMemo, useState } from 'react'
import Wrapper from '../../layout/Wrapper'
import Header2 from '../../Header2'
import IconContent from '../../ui/IconContent'
import Iconbag from '../../../assets/images/eventsIcon.svg'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import EventItem from './EventItem'
import { useProfile } from '../../../contexts/UserContext'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import SubNav from '../../SubNav'
import { useClaims } from '../../../contexts/ClaimsContext'
import { get_storage } from '../../../helpers'
function Events() {
  const [events, setEvents] = useState([])
  const { claims, saveClaims } = useClaims()
  const [isLoading, setIsLoading] = useState(false)
  const { externalFetch } = useAxiomFetch()
  const { profile } = useProfile()

  const state = profile?.ST_ABBR

  const category = useMemo(() => {
    if (state && state === 'AZ') {
      return '483'
    }
    if (state && state === 'NV') {
      return '484'
    }

    return null
  }, [state])
  useEffect(() => {
    const controller = new AbortController()
    setIsLoading((prev) => !prev)
    const getData = async () => {
      await externalFetch(
        `https://driggstitle.com/wp-json/wp/v2/driggs-events?_embed&events_states=${category}&orderby=date&order=asc&per_page=100`,
        'GET',
        {},
        {},
        false,
        controller.signal
      )
        .then((res) => {
          setIsLoading((prev) => !prev)
          setEvents(res)
        })
        .catch((err) => {
          setIsLoading((prev) => !prev)
        })
    }
    if (category) {
      getData()
    }
    return () => {
      controller?.abort()
    }
  }, [category])

  return (
    <Wrapper>
      <SubNav
        showConversations={false}
        showTours={false}
        logo_url={claims?.card_access || get_storage('uuid') ? '/card' : ''}
      />
      <IconContent
        iconImage={Iconbag}
        title='Events'
        content='Discover, Connect, Celebrate: Your Gateway to Local Events'
      />
      {isLoading && <SmallLoadingRel />}
      {events.length > 0 &&
        !isLoading &&
        events.map((item, index) => (
          <EventItem key={index} index={index} event={item} />
        ))}
      {!events.length > 0 && !isLoading && (
        <div className='text-center text-lg font-bold'>No Event Available</div>
      )}
    </Wrapper>
  )
}

export default Events
