import React, { useCallback, useEffect, useRef } from 'react'

import { INNERMENU } from '../../data/data'
import SubNav from '../../components/SubNav'
import TitleIcon from '../../components/ui/TitleIcon'
import PageSubMenu from '../../components/PageSubMenu'
import SubTitle from '../../components/ui/SubTitle'

import CardsList from '../../components/CardsList'

import Wrapper from '../layout/Wrapper'
import { useAuth0 } from '@auth0/auth0-react'
import { useProfile } from '../../contexts/UserContext'
import { ServiceVBC } from '../../services/ServiceVBC'
import { useQuery } from '@tanstack/react-query'
import { useVBCs } from '../../contexts/VBCContext'
import Loading from '../Loading'

export default function VBC() {
  const { getAccessTokenSilently } = useAuth0()
  const { profile } = useProfile()
  const { saveVBCs, VBCs } = useVBCs()

  const fetchController = useRef(null)

  const getVBCs = useCallback(async () => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUDIENCE,
    }).catch((err) => null)

    const serviceClientListing = new ServiceVBC(token)
    if (!fetchController.current) {
      fetchController.current = serviceClientListing.getController().controller
    }
    return serviceClientListing.getAll()
  }, [getAccessTokenSilently])

  const { data, isFetching } = useQuery({
    queryKey: ['myvbcs'],
    queryFn: getVBCs,
    refetchOnWindowFocus: false,
    enabled: !!profile && !VBCs,
  })

  useEffect(() => {
    if (!VBCs) {
      if (data && data.length) {
        saveVBCs(data)
      }
    }
    return () => fetchController.current && fetchController.current.abort()
  }, [data, saveVBCs, VBCs])

  if (isFetching) {
    return <Loading />
  }
  return (
    <Wrapper>
      <SubNav />
      <TitleIcon className='mb-5'>My Realty Smart Business Card</TitleIcon>
      <div className='pt-2 pb-4 px-4'>
        <PageSubMenu selected={INNERMENU.VBC} />
        <SubTitle className='mt-4'>Your Realty Smart Business Card</SubTitle>
        <p className='text-gray-500 text-sm mb-3'>
          Edit share your Realty Smart Business Card
        </p>
        {/* <div className="border border-gray-200 p-4 ">
          <SubTitle>
            Realty Smart Business Cards
            <Badge className="bg-gray-200 text-accent-250">8 cards</Badge>
          </SubTitle>
          <p className="mb-4">Manage your VBC’s here.</p>
          <PrimaryButton
            clickHandler={() => setCardFormVisibility(!cardFormVisibility)}
            isSelected={cardFormVisibility}
          >
            <HiPlus className="inline text-xl  align-middle mr-1" /> Add New
            card
          </PrimaryButton>
        </div> */}

        <div>{VBCs && <CardsList cards={VBCs} />}</div>
      </div>
    </Wrapper>
  )
}
