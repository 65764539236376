import { useState, forwardRef } from 'react'
import ImageUpload from '../../form/ImageUpload'
import uiStyles from '../../ui/uiStyles'
import Select from '../../form/Select'
import LabelInput from '../../form/LabelInput'
import { parse } from 'papaparse'
import garbage from '../../../assets/images/garbage.svg'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import structuredClone from '@ungap/structured-clone'

const UploadList = forwardRef(({ setShowModal, setLists, setScroll }, ref) => {
  const { axiomFetch } = useAxiomFetch()
  const inputs = [
    ['name', 'full name', 'listing member', 'listing agent', 'first name', 'last name'],
    ['email', 'primary email', 'email address'],
    ['cell phone', 'cell', 'cell number', 'phone number', 'phone'],
    [
      'work phone',
      'work_num',
      'work number',
      'business number',
      'business phone',
      'bus phone',
      'office number',
      'office phone',
    ],
    ['city', 'city/town code'],
    ['state', 'st', 'state/province'],
    ['zip', 'zip code'],
  ]
  const [state, setState] = useState({})
  const [messageSent, setMessageSent] = useState({ status: 'done' })

  const handle_msg = (status, message, close) => {
    setMessageSent({ ...messageSent, status, message })
    if (close) {
      setTimeout(() => {
        setMessageSent({ ...messageSent, status: 'done', message: '' })
      }, 3000)
    }
  }

  const handle_selects = (e) => {
    const { name } = e.target
    setState((prev) => ({ ...prev, [name]: e.target.options[e.target.selectedIndex].value }))
  }
  const handle_inputs = (e) => {
    const { name, value } = e.target
    setState((prev) => ({ ...prev, [name]: value }))
  }
  const handle_upload = (e) => {
    setState((prev) => ({ list_name: prev?.list_name || '' }))
    e.preventDefault()
    if ((e?.dataTransfer?.files[0]?.size || e?.target?.files[0]?.size) > 2_050_000) {
      handle_msg('failure_msg', 'file size too large', true)
      return
    }
    parse(e?.target?.files?.[0] || e?.dataTransfer?.files[0], {
      header: true,
      complete: (results) => {
        for (const input of inputs) {
          const match = results?.meta?.fields.filter((element) => input.includes(element.toLowerCase().trim()))
          if (match.length) {
            setState((prev) => ({ ...prev, [input[0]]: match[0].trim() }))
          }
        }
        if (Object.keys(results?.data?.[results?.data?.length - 1]).length <= 1 && Object.keys(results?.data)[0]) {
          results.data.pop(-1)
        }

        // const size = new TextEncoder().encode(JSON.stringify(results?.data)).length
        // const kiloBytes = size / 1024;
        // const megaBytes = kiloBytes / 1024;
        // console.log(megaBytes)
        results.data = results.data.map((item) => {
          for (let key in item) {
            if (key !== key.trim()) {
              let new_key = key.trim()
              item[new_key] = item[key]
              delete item[key]
            }
          }
          return item
        })

        for (let item in results?.fields) {
          item = item.trim()
        }
        setState((prev) => structuredClone({ ...prev, data: results?.data, fields: results?.meta?.fields }))
      },
    })
  }
  const handle_save = async (e) => {
    console.log(state)
    let all_selected = []
    for (const item of state.data) {
      const selected = {}
      for (const input of inputs) {
        if (state[input[0]]) {
          // console.log(item[' Phone'])
          // console.log(item[state[input[0]?.trim()]])
          selected[input[0]] = item[state[input[0]]?.trim()]?.trim()
        }
      }

      all_selected.push(selected)
    }
    all_selected = all_selected.map((item) => {
      return {
        nm: item?.name,
        email: item?.email,
        cell: item?.['cell phone'],
        work_num: item?.['work phone'],
        city: item?.city,
        st: item?.state,
        zip: item?.zip,
      }
    })
    console.log(state.list_name)
    handle_msg('loading_msg', 'Creating list...')
    let lists = await axiomFetch('/client/listings/lists', 'POST', [{ list_name: state.list_name }])
    const list_id = lists?.[0]?.list_id
    console.log(lists)
    console.log(list_id)
    lists = lists.map((item) => {
      for (let old_key in item) {
        if (old_key !== old_key.toUpperCase()) {
          let new_key = old_key.toUpperCase()
          item[new_key] = item[old_key]
          delete item[old_key]
        }
      }
      item.LIST_COUNT = all_selected?.length || 0
      return item
    })
    setLists((prev) => [...prev, lists[0]])
    console.log(all_selected)
    ref.current.state++
    setScroll((prev) => prev + 1)
    handle_msg('loading_msg', 'Adding list values...')
    const data = await axiomFetch(`/client/listings/lists/${list_id}`, 'POST', all_selected)
    console.log(data)
    handle_msg('success_msg', 'List Created!')
    setTimeout(() => {
      setShowModal(false)
    }, [500])
    console.log(JSON.stringify(all_selected))
  }

  return (
    <>
      <LabelInput top_label={'List Name'} name={'list_name'} value={state.list_name} handleChange={handle_inputs} />
      <small className={`${uiStyles.text_gray} text-[9.99px]`}>
        Best practice: keep your list name short but descriptive.
      </small>
      <div>
        <h4 className={`${uiStyles.text_black_subtitle} text-[#344054] font-medium m-0 p-0`}>Uploading your lists</h4>
        <p className={`${uiStyles.text_gray} text-[14px]`}>
          You can upload your own lists. We will try to match your structure, but it may require some adjustment, which
          you can do below.
        </p>
      </div>
      <div>
        <ImageUpload
          accept={'.xlsx, .xls, .csv'}
          handler={handle_upload}
          title={`Click to upload your file`}
          secondary_title={`or drag and drop`}
          subtitle={'Excel or csv (max. 2mb)'}
        />
        {messageSent.status === 'done' ? (
          ''
        ) : (
          <div className={`${uiStyles[messageSent.status]} mb-[20px]`}>{messageSent.message}</div>
        )}

        <p className={`text-[#979797] text-[14px]`}>First row of the file will be used for titles of each column.</p>
      </div>
      {inputs.map((input, index) => {
        return (
          <Select
            className={'capitalize'}
            label={input[0]}
            name={input[0]}
            value={state[input[0]]}
            handler={handle_selects}
          >
            <option key='none' selected={true} hidden={true}>
              Match Correct Field
            </option>
            {state?.fields?.map((item, index) => {
              return <option key={`${item}-${index}`}>{item}</option>
            })}
          </Select>
        )
      })}
      {
        <div className='flex justify-end'>
          {messageSent.status === 'done' ? (
            <button className={`${uiStyles.green_btn}`} onClick={handle_save}>
              Save
            </button>
          ) : (
            <div className={`${uiStyles[messageSent.status]} mb-[20px]`}>{messageSent.message}</div>
          )}
        </div>
      }
    </>
  )
})

export default UploadList
