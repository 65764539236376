import React, { memo } from 'react';
import { useNavigate } from 'react-router';
import Select from './form/Select';
import { INNERMENU } from '../data/data';
function PageSubMenu({ selected = '' }) {
  const { link: selectedRoute } = selected;
  const dropdownOptions =
    INNERMENU &&
    Object.values(INNERMENU)?.map((item, index) => (
      <option key={index} value={item.link}>
        {item.label}
      </option>
    ));
  const navigate = useNavigate();
  const onChangeHandler = (e) => {
    navigate(e.target.value);
  };
  return (
    <Select value={selectedRoute} handler={onChangeHandler}>
      {dropdownOptions}
    </Select>
  );
}

export default memo(PageSubMenu);
