import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import SignatureCanvas from 'react-signature-canvas';
const Backdrop = (props) => {
  return <div className="fixed left-0 right-0 bottom-0 top-0  z-10"></div>;
};

const ModalOverlay = (props) => {
  const signPadRef = useRef({});
  const [penColor, setPenColor] = useState('black');
  const [signature, setSignature] = useState();
  const generateCanvas = () => (
    <SignatureCanvas
      penColor={penColor}
      canvasProps={{
        width: 310,
        height: 150,
        className: 'sigCanvas',
      }}
      ref={signPadRef}
    />
  );
  const clear = () => {
    signPadRef.current.clear();
  };
  const trim = () => {
    setSignature(signPadRef.current.getTrimmedCanvas().toDataURL('image/png'));
  };

  return (
    <div className=" font-base  fixed top-0 left-0 h-[100vh]   z-50   w-full   overflow-x-hidden overflow-y-auto table">
      <div className="table-cell align-middle">
        <div className="relative w-full  max-w-[310px] md:h-auto mx-auto">
          <div className="relative bg-white border border-black   shadow   overflow-y-auto">
            <div className="space-y-4">
              <div className="w-[310px] h-150px bg-blue-100">
                {generateCanvas()}
              </div>
              <div className="">
                <p className="text-center text-[7px] leading-[8px]">
                  By Signing this document....
                </p>
                <div className="p-3">
                  <span
                    className={`mr-1 bg-black w-3 h-3 inline-block rounded-full border border-white  outline outline-2 ${
                      penColor === 'black' ? 'outline-black' : 'outline-white'
                    } `}
                    onClick={() => setPenColor('black')}
                  ></span>
                  <span
                    className={`mr-1 bg-blue-600 w-3 h-3 inline-block rounded-full border border-white  outline outline-2 ${
                      penColor === 'blue' ? 'outline-blue-600' : 'outline-white'
                    }`}
                    onClick={() => setPenColor('blue')}
                  ></span>
                  <span
                    className={`mr-5 bg-red-500 w-3 h-3 inline-block rounded-full border border-white  outline outline-2 ${
                      penColor === 'red' ? 'outline-red-500' : 'outline-white'
                    }`}
                    onClick={() => setPenColor('red')}
                  ></span>
                  <span
                    onClick={() => {
                      clear();
                      setSignature(null);
                    }}
                    className="text-blue-600 inline-block mr-3 text-sm font-semibold"
                  >
                    Clear
                  </span>
                  <span
                    onClick={() => {
                      trim();
                    }}
                    className=" inline-block text-sm font-semibold"
                  >
                    Capture
                  </span>
                </div>
                <div className="flex flex-row-reverse p-2">
                  {signature && (
                    <img src={signature} alt="" className="w-20 h-auto" />
                  )}
                </div>
              </div>
            </div>
            <div
              onClick={() => props.onClose()}
              className="flex justify-center items-center absolute left-2 top-2 text-primary bg-white h-4 w-4 text-xs rounded-full cursor-pointer"
            >
              X
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const portalElement = document.getElementById('overlays');

const SignPad = (props) => {
  useEffect(() => {
    document.body.classList.add('overflow-hidden');
    return () => document.body.classList.remove('overflow-hidden');
  });
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onClose={props.onClose} />,
        portalElement
      )}
      {ReactDOM.createPortal(
        <ModalOverlay onClose={props.onClose}>{props.children}</ModalOverlay>,
        portalElement
      )}
    </>
  );
};

export default SignPad;
