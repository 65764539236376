import { useEffect, useRef, useState } from 'react'
import ch_images from '../../../assets/images/conversations'
import {
  formatPhoneNumber,
  format_number,
  getCurrentTime,
  getDateString,
  set_storage,
} from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { CircleCheckToggle } from '../../form/CircleCheckToggle'
import LabelInput from '../../form/LabelInput'
import PropertyItem from '../../ui/PropertyItem'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import uiStyles from '../../ui/uiStyles'

const EditProperty = ({
  state,
  setState,
  save_home,
  page,
  setPage,
  setShowModal,
  market_name,
  type,
}) => {
  console.log('create_state', state)
  const [inputValue, setInputValue] = useState(
    state?.buyer_tours?.property_edit || {}
  )
  const [searchProperty, setSearchProperty] = useState({})
  const [loading, setLoading] = useState(false)
  const [searchLoading, setSearchLoading] = useState(false)
  const [aiLoading, setAiLoading] = useState(false)
  const { axiomFetch } = useAxiomFetch()
  const startRef = useRef(null)
  useEffect(() => {
    startRef.current.focus()
  }, [])
  const handleChange = async (e) => {
    let { name, value } = e.target
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }))
    setSearchLoading(true)
    const mls_data = await axiomFetch(
      `/tours/property/lookup?mls_id=${
        value?.replace(/\D/g, '')?.slice(0, 10)?.toString() || ''
      }`,
      'GET',
      {},
      {},
      false,
      false,
      700
    )
    setSearchProperty(mls_data || {})
    setSearchLoading(false)
  }

  const save_event = async () => {
    setLoading(true)
    const data = {
      ...inputValue,
      mls_id:
        inputValue?.mls_id?.replace(/\D/g, '')?.slice(0, 10)?.toString() || '',
      event_id: state?.buyer_tours?.event_edit?.event_id,
      evt_id: state?.buyer_tours?.event_edit?.event_id,
    }
    console.log(data)
    await save_home(data, '')
    setLoading(false)
    setShowModal(false)
  }

  return (
    <div>
      <h1 className='mb-[5px]'>Search by MLS ID</h1>
      <div className='overflow-y-scroll max-h-[330px] min-h-[175px]'>
        <LabelInput
          ref={startRef}
          left_label={'MLS ID'}
          placeholder='1612924'
          name={'mls_id'}
          value={format_number(inputValue?.mls_id) || ''}
          handleChange={handleChange}
        />
        <div>
          {searchLoading ? (
            <SmallLoadingRel />
          ) : (
            <>
              {searchProperty?.mls_id ? (
                <div className='mb-[10px]'>
                  <PropertyItem listing={searchProperty} />
                </div>
              ) : searchProperty?.not_found || searchProperty?.msg ? (
                <div>
                  <p className='px-3'>
                    We couldn't find any results for your search.
                  </p>
                </div>
              ) : (
                ''
              )}
            </>
          )}
        </div>
      </div>
      <div className={`flex justify-end gap-[10px] items-center`}>
        <button
          className={`${uiStyles.white_btn}`}
          onClick={() => {
            setShowModal(false)
          }}
        >
          Cancel
        </button>
        {!loading ? (
          <button className={`${uiStyles.hover_btn}`} onClick={save_event}>
            Add
          </button>
        ) : (
          <div className={`${uiStyles.loading_msg} min-w-0 w-[150px]`}>
            Loading...
          </div>
        )}
      </div>
    </div>
  )
}

export default EditProperty
