import { useState } from 'react'
import { useProfile } from '../../../contexts/UserContext'
import { months_abbr } from '../../../helpers'
import Wrapper from '../../layout/Wrapper'
import SubNav from '../../SubNav'
import SmallLoading from '../../ui/SmallLoading'

export default function PairwisePulse() {
  const { profile, saveProfile } = useProfile()
  const [loading, setLoading] = useState(true)
  const date = new Date()
  const start_date = `01-${months_abbr?.[date.getMonth()]}-${date
    ?.getFullYear()
    ?.toString()
    ?.slice(2, 4)}`
  return (
    <div className='flex justify-center'>
      <iframe
        title='pairwise trial report'
        onLoad={(e) => {
          setLoading(false)
        }}
        src={`https://rightdata.driggstitle.com/prv/pairwise_utils.pairwise_trial_report?pair_p=${profile?.PAIR_ID}&dt_p=${start_date}`}
        className='w-[360px] min-h-[670px]'
        action='embedview'
      ></iframe>
      {loading ? <SmallLoading /> : ''}
    </div>
  )
}
