import { useState, useEffect, forwardRef, memo } from 'react'
import { useProfile } from '../../../contexts/UserContext'
import ch_images from '../../../assets/images/conversations'
import uiStyles from '../../ui/uiStyles'
import { useConversations } from '../../../contexts/ConversationsContext'
import PDFPreview from '../../PDFPreview'
import useHelpers from '../../../hooks/useHelpers'
export const MessageBubble = memo(
  forwardRef(
    (
      {
        conversation,
        msg,
        setLoadMedias,
        loading,
        last,
        conversation_create = false,
        setSendMessageCreate,
      },
      ref
    ) => {
      const { open_url } = useHelpers()
      const { convMessages } = useConversations()
      const { profile } = useProfile()
      // for text loads.
      // if (ref && ref?.current) {
      //   setTimeout(() => {
      //     ref?.current?.scrollIntoView({ behavior: 'smooth' })
      //   }, [300])
      // }
      if (msg?.state?.author === profile?.CONT_ID?.toString()) {
        msg.state.author = profile?.CONT_NAME
      }

      const replace_links = (msg) => {
        let urls = msg?.match(/(https?|ftp):\/\/[^\s/$.?#].[^\s]*/g) || []
        for (const url of urls) {
          msg = msg?.replace(
            url,
            `<a class="text-[#3B82F6] underline" href=${url}>${url}</a>`
          )
        }
        return msg
      }
      return (
        <div className='flex flex-col px-2'>
          <div
            className={`flex gap-[5px] items-end ${
              msg?.state?.author === profile?.CONT_NAME
                ? `justify-end `
                : 'text-14px'
            }`}
            key={msg?.state?.sid}
          >
            {msg?.state?.author === profile?.CONT_NAME ? (
              ''
            ) : (
              <div className='flex justify-center items-center w-[35px] h-[35px] rounded-full bg-[#E4E6E8]'>
                <img className='w-[15px]' src={ch_images.person} />
              </div>
            )}
            <div>
              <p
                className={`flex max-w-[200px] min-w-[200px] ${
                  msg?.state?.author === profile?.CONT_NAME
                    ? `ml-auto ${uiStyles.blue_grd_text_darker}`
                    : ''
                }`}
              >
                {msg?.state?.author === profile?.CONT_NAME
                  ? msg?.state?.author
                  : conversation?.attributes?.name + ' | ' + msg?.state?.author}
              </p>

              <div
                className={`flex ${
                  msg?.state?.author === profile?.CONT_NAME ? 'ml-auto' : ''
                } items-center min-h-[30px] max-w-[200px] bg-[#E4E6E8] p-[10px] rounded-tl-xl rounded-tr-xl ${
                  msg?.state?.author === profile?.CONT_NAME
                    ? 'rounded-bl-xl'
                    : 'rounded-br-xl'
                }`}
              >
                <p
                  className={`${uiStyles.text_black} ${
                    msg?.state?.body?.includes('http') ? '' : ''
                  } [word-break:_break-word]`}
                  onLoad={() => {}}
                  dangerouslySetInnerHTML={{
                    __html: replace_links(msg?.state?.body),
                  }}
                >
                  {/* {replace_links(msg?.state?.body)} */}
                </p>
                <div className='flex'>
                  {msg?.state?.medias?.length ? (
                    conversation_create ? (
                      msg?.state?.medias?.map((item) => {
                        return (
                          <div className='flex'>
                            <img
                              className='cursor-pointer'
                              key={item.name}
                              alt={item.name}
                              src={URL.createObjectURL(item)}
                              onClick={() =>
                                open_url(URL.createObjectURL(item))
                              }
                              onLoad={() => {
                                ref.current.scrollIntoView({
                                  behavior: 'smooth',
                                })
                              }}
                            />
                          </div>
                        )
                      })
                    ) : (
                      <div>
                        {convMessages?.[msg?.conversation?.sid]?.msg_urls?.[
                          msg?.state?.sid
                        ]?.map((item, index, arr) => {
                          let file_type = item?.split('***')?.[1]
                          item = item?.split('***')?.[0]
                          if (last) {
                            return item ? (
                              file_type?.includes('pdf') ? (
                                <div className='cursor-pointer relative w-[100px] h-[90px]'>
                                  <iframe
                                    loading='lazy'
                                    title={item}
                                    className='z-1 relative w-[150px] rounded-md h-[90px] object-cover mx-auto overflow-y-hidden overflow-x-hidden'
                                    preload='auto'
                                    src={item}
                                    onClick={() => {}}
                                    onLoad={(e) => {
                                      if (!loading) {
                                        setLoadMedias(true)
                                        setLoadMedias(false)
                                      }
                                    }}
                                    onErrorCapture={(e) => {
                                      if (!loading) {
                                        setLoadMedias(true)
                                        setLoadMedias(false)
                                      }
                                    }}
                                  />
                                  <div
                                    onClick={() => {
                                      open_url(item)
                                    }}
                                    className='z-10 top-0 left- bg-transparent h-[90px] w-[180px] absolute'
                                  ></div>
                                </div>
                              ) : (
                                <img
                                  className='cursor-pointer'
                                  key={item}
                                  alt='media'
                                  src={item}
                                  onClick={() => open_url(item)}
                                  onLoad={(e) => {
                                    if (!loading) {
                                      setLoadMedias(true)
                                      setLoadMedias(false)
                                    }
                                  }}
                                  onErrorCapture={(e) => {
                                    if (!loading) {
                                      setLoadMedias(true)
                                      setLoadMedias(false)
                                    }
                                  }}
                                />
                              )
                            ) : (
                              ''
                            )
                          } else {
                            return item ? (
                              file_type?.includes('pdf') ? (
                                <div
                                  className='cursor-pointer relative w-[100px] h-[90px] z-100'
                                  onClick={(e) => {
                                    open_url(item)
                                  }}
                                >
                                  <iframe
                                    loading='lazy'
                                    title={item}
                                    className='z-1 relative w-[150px] pointer-events-none rounded-md h-[90px] object-cover mx-auto overflow-y-hidden overflow-x-hidden'
                                    preload='auto'
                                    src={item}
                                    onLoad={(e) => {
                                      if (
                                        convMessages?.[msg?.conversation?.sid]
                                          ?.last_msg_media
                                      ) {
                                        setLoadMedias(true)
                                        setLoadMedias(false)
                                      }
                                    }}
                                    onErrorCapture={(e) => {
                                      if (
                                        convMessages?.[msg?.conversation?.sid]
                                          ?.last_msg_media
                                      ) {
                                        setLoadMedias(true)
                                        setLoadMedias(false)
                                      }
                                    }}
                                  />
                                  <div
                                    onClick={() => {
                                      open_url(item)
                                    }}
                                    className='z-10 top-0 left- bg-transparent h-[90px] w-[180px] absolute'
                                  ></div>
                                </div>
                              ) : (
                                <img
                                  className='cursor-pointer'
                                  key={item}
                                  alt='media in text'
                                  src={item}
                                  onClick={() => open_url(item)}
                                  onLoad={() => {
                                    if (
                                      convMessages?.[msg?.conversation?.sid]
                                        ?.last_msg_media
                                    ) {
                                      setLoadMedias(true)
                                      setLoadMedias(false)
                                    }
                                  }}
                                  onErrorCapture={(e) => {
                                    if (
                                      convMessages?.[msg?.conversation?.sid]
                                        ?.last_msg_media
                                    ) {
                                      setLoadMedias(true)
                                      setLoadMedias(false)
                                    }
                                  }}
                                />
                              )
                            ) : (
                              ''
                            )
                          }
                        })}
                      </div>
                    )
                  ) : (
                    <img
                      src={ch_images.add_green}
                      className='hidden'
                      onLoad={() => {
                        if (!loading) {
                          // setLoadMedias(true)
                          setLoadMedias(false)
                        }
                      }}
                      onErrorCapture={(e) => {
                        if (!loading) {
                          setLoadMedias(false)
                        }
                      }}
                    />
                  )}
                </div>
              </div>
            </div>

            {msg?.state?.author === profile?.CONT_NAME ? (
              <div className='flex justify-center items-center w-[35px] h-[35px] rounded-full  bg-gradient-to-b from-[#00CFDE] to-[#05A660]'>
                <img className='w-[15px]' src={ch_images.person_white} />
              </div>
            ) : (
              ''
            )}
          </div>
          {msg?.state?.author === profile?.CONT_NAME ? (
            <div className='flex justify-end mr-[40px]'>
              <div>
                {msg?.state?.timestamp?.toLocaleString('en', {
                  hour: 'numeric',
                  minute: '2-digit',
                  hour12: true,
                })}
              </div>
            </div>
          ) : (
            <div className='flex justify-start ml-[180px]'>
              {msg?.state?.timestamp?.toLocaleString('en', {
                hour: 'numeric',
                minute: '2-digit',
                hour12: true,
              })}
            </div>
          )}
        </div>
      )
    }
  )
)
