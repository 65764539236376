import React from 'react'
import Wrapper from '../../components/layout/Wrapper'
import MarketingMaterial from '../../components/pages/marketingMaterials/MarketingMaterial'
import { MarketingMaterialHome } from '../../components/pages/marketingMaterials/MarketingMaterialHome'
import { MarketingMaterialLayouts } from '../../components/pages/marketingMaterials/MarketingMaterialLayouts'
import SubNav from '../../components/SubNav'
import { useMktReqs } from '../../contexts/MktReqsContext'
export default function PageMarketingMaterialLayouts() {
  const { mktReqs, saveMktReqs } = useMktReqs()
  return (
    <>
      <Wrapper>
        <SubNav url={'/user/marketing-material'} />
        <MarketingMaterialLayouts
          state={mktReqs}
          setState={saveMktReqs}
          title={'Layouts'}
        />
      </Wrapper>
    </>
  )
}
