import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import images_tours from '../../../assets/images/listing_tours'
import { useListingTours } from '../../../contexts/ListingToursContext'
import { formatPhoneNumber } from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { List } from '../../helpers'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import uiStyles from '../../ui/uiStyles'

const BuyerFavorites = () => {
  const { axiomFetch } = useAxiomFetch()
  const navigate = useNavigate()
  const {
    listingTours,
    saveListingTours,
    get_buyers,
    save_home,
    is_buyer_tour,
  } = useListingTours()
  const [buyerFavorites, setBuyerFavorites] = useState([])
  const [errorMsg, setErrorMsg] = useState('')
  const [loading, setLoading] = useState(true)
  const [addLoading, setAddLoading] = useState(false)
  get_buyers(listingTours?.buyer_tours?.event_edit?.event_id)
  useEffect(() => {
    if (!listingTours?.buyer_tours) {
      navigate('/user/buyer_tours')
    }
  }, [])
  useEffect(() => {
    const cont_ids = get_buyers(
      listingTours?.buyer_tours?.event_edit?.event_id
    )?.map((item) => item.cont_id)
    console.log(cont_ids)
    const controller = new AbortController()
    const get_favorites = async () => {
      setLoading(true)
      const favorites = await axiomFetch(
        `/tours/client/favorites?cont_ids=${cont_ids}&event_type_id=${listingTours?.buyer_tours?.event_edit?.event_type_id}`,
        'GET',
        {},
        {},
        false,
        controller.signal
      )
      if (favorites?.msg) {
        setLoading(false)
        if (is_buyer_tour()) {
          favorites.msg = favorites?.msg?.replace('buyers', 'agents')
        }
        return setErrorMsg(favorites.msg)
      }
      favorites?.length &&
        (favorites || [])?.map((item) => {
          item.swipe_right = () => {
            return (
              <div
                onClick={async () => {
                  setAddLoading(true)
                  await save_home(
                    {
                      mls_id: item.mls_id?.toString(),
                      event_id: listingTours?.buyer_tours?.event_edit?.event_id,
                      evt_id: listingTours?.buyer_tours?.event_edit?.event_id,
                    },
                    '/user/buyer_tours/add_home'
                  )
                  setAddLoading(false)
                }}
              >
                <img
                  className={'w-[30px] cursor-pointer'}
                  src={images_tours.add_icon_white}
                />
              </div>
            )
          }
          return item
        })
      if (!controller?.signal?.aborted) {
        const all_buyers = get_buyers(
          listingTours?.buyer_tours?.event_edit?.event_id
        )
        console.log('buyers', all_buyers)
        all_buyers?.map((item) => {
          item.favorite_homes = favorites?.filter(
            (favorite) =>
              (favorite?.favorited_cont_id || favorite?.cont_id) ===
              item?.cont_id
          )
          return item
        })

        setBuyerFavorites(all_buyers)
      }
      setLoading(false)
    }
    get_favorites()

    return () => {
      controller?.abort()
    }
  }, [])

  return (
    <div>
      {loading ? (
        <SmallLoadingRel />
      ) : (
        <div>
          <h1 className={`${uiStyles.text_black_subtitle} mb-[10px] px-3`}>
            {is_buyer_tour() ? 'Add Buyer Favorited homes' : 'Add agent homes'}
          </h1>
          {addLoading ? (
            <SmallLoadingRel />
          ) : (
            <>
              {errorMsg ? <div className='px-3'>{errorMsg}</div> : ''}
              <div className='overflow-scroll min-h-[200px] max-h-[570px]'>
                {buyerFavorites?.map((item) => {
                  return (
                    <div key={item?.evt_tour_id || item?.agent_cell_phone}>
                      <p
                        className={`${uiStyles.text_black_subtitle} px-3 py-3`}
                      >
                        {item?.agent_name}
                        {` ${formatPhoneNumber(item?.agent_cell_phone || '')}`}
                      </p>
                      <List
                        list={item?.favorite_homes || []}
                        no_items_msg={
                          is_buyer_tour()
                            ? "Buyer hasn't favorited any homes yet."
                            : "Agent doesn't have homes listed right now."
                        }
                        property_component={true}
                      ></List>
                    </div>
                  )
                })}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default BuyerFavorites
