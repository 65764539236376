import React from 'react';
import IconPlay from '../../assets/images/play.png';
export default function AxiomPlayIcon() {
  return (
    <div className="flex flex-col justify-center items-center min-w-[40px]">
      <img src={IconPlay} alt="Play" className="max-w-[25px]" />
      <div className="text-xs">Play</div>
    </div>
  );
}
