import React, { memo, useEffect, useRef, useState } from 'react'
import { useSwipeable } from 'react-swipeable'
import useCatchOutsideClick from '../../hoc/useCatchOutsideClick'

function SwipeConfig({
  children,
  index = 1,
  selectedIndex = 1,
  setSelectedIndex,
  RightComponents = null,
  LeftComponents = null,
  widthConfigRight = 32,
  widthConfigLeft = 32,
  className = '',
  bg,
}) {
  const [swipeTo, setSwipeTo] = useState('')

  const wrapperRef = useRef(null)
  const prevSwipe = useRef(null)
  const prevTap = useRef(null)
  const prevTapDirection = useRef(null)
  const swipeHandler = (newDirection) => {
    setSelectedIndex(index)
    if (prevSwipe.current === 'swiped') {
      setSwipeTo('')
      prevSwipe.current = ''
    }
    setSwipeTo((oldDirection) => {
      if (oldDirection && prevSwipe.current !== 'swiped') {
        //close the swipe options if opened
        prevTap.current = null
        return ''
      }
      //open the swipe options if not opened for any direction
      if (
        (newDirection === 'left' && isRightCompo) ||
        (newDirection === 'right' && isLeftCompo)
      ) {
        prevTap.current = 'swiped'
      } else {
        prevTap.current = null
      }
      return newDirection
    })

    prevSwipe.current = 'swiped'
  }

  const tapHandler = (newDirection) => {
    window.mobileCheck = function () {
      let check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    }
    if (window.mobileCheck()) {
      setSwipeTo('')
      return
    }
    setSelectedIndex((prev) => {
      return index
    })
    if (index !== selectedIndex) {
      prevTap.current = 'close'
    }
    setSwipeTo('')

    if (index === selectedIndex && swipeTo === 'right') {
      setSwipeTo('')
      prevTap.current = 'close'
      return
    }

    if (prevTap.current === 'swiped') {
      setSwipeTo('')
      prevTap.current = 'close'
    } else {
      setSwipeTo('left')
      prevTap.current = 'swiped'
    }
  }

  const active = index === selectedIndex
  const isRightCompo = !!RightComponents
  const isLeftCompo = !!LeftComponents
  const shouldSwipeLeft =
    isRightCompo && swipeTo?.length > 0 && active === true && swipeTo === 'left'
  const shouldSwipeRight =
    isLeftCompo && swipeTo?.length > 0 && active === true && swipeTo === 'right'

  const handlers = useSwipeable({
    onSwipedLeft: () => swipeHandler('left'),
    onSwipedRight: () => swipeHandler('right'),
    onTap: (e) => {
      // make sure that inside pdf/image class events don't occur or svg clicks
      let isPdfImg
      let classes = e.event.target.getAttribute('class')
      if (typeof classes === 'string') {
        isPdfImg = e.event?.target?.getAttribute('class')?.includes('swipeable')
      }
      // pdf close button svg
      if (
        e?.event?.target?.getAttribute('d')?.startsWith('M512 64C264.6') ||
        e?.event?.target?.getAttribute('d')?.startsWith('M864 256H736v') ||
        e?.event?.target?.getAttribute('d')?.startsWith('M259.3 17.8L194')
      ) {
        isPdfImg = true
      }
      if (isPdfImg) {
        tapHandler('left')
      }
      // tapHandler('')
    },

    trackMouse: true,

    delta: 10,
    preventScrollOnSwipe: true,
    trackTouch: true,

    rotationAngle: 0,
    swipeDuration: 500,
  })

  return (
    <div ref={wrapperRef}>
      <div
        {...handlers}
        className={`swipeable swipeconfig relative w-full flex overflow-hidden px-1 ${className} ${
          bg ? bg : 'bg-gray-100'
        }`}
      >
        {isRightCompo && (
          <span
            className={`swipeable absolute right-0 bg-gradient-to-b from-accent-100 to-accent-400 h-full w-2 block ${
              !shouldSwipeRight && 'z-[200]'
            } right-0 top-0`}
          ></span>
        )}
        {isLeftCompo && (
          <span
            className={`absolute left-0 bg-gradient-to-b from-[#FF8800] to-[#E63535] h-full w-2 block ${
              !shouldSwipeLeft && 'z-[200]'
            } right-0 top-0`}
          ></span>
        )}
        <div
          className={`transition-all duration-100 relative z-20 flex-1 ${
            shouldSwipeLeft && `-left-${widthConfigRight} right-auto`
          } ${shouldSwipeRight && `-right-${widthConfigLeft} left-auto`}`}
        >
          {children}
        </div>
        {isRightCompo && (
          <div
            className={`right-config flex items-center transition-all justify-center duration-100 bg-gradient-to-b from-accent-100 to-accent-400 text-white z-20 absolute top-0 bottom-0 w-${widthConfigRight} text-xs font-semibold  bg-green-400 text-center 
          ${shouldSwipeLeft ? 'right-0' : `-right-${widthConfigRight}`}`}
            // className={`flex w-${widthConfigRight} bg-gradient-to-b from-accent-100 to-accent-400 z-20 right-config absolute place-content-center transition-all delay-10000 ${shouldSwipeLeft ? 'right-0' : `-right-${widthConfigRight}`} top-0 bottom-0`}
          >
            <RightComponents />
          </div>
        )}

        {isLeftCompo && (
          <div
            className={`left-config flex items-center  justify-center transition-all duration-100 bg-gradient-to-b from-[#FF8800] to-[#E63535] text-white z-20 absolute top-0 bottom-0 w-${widthConfigLeft} text-xs font-semibold  bg-green-400 text-center  ${
              shouldSwipeRight ? 'left-0' : `-left-${widthConfigLeft}`
            }`}
          >
            <LeftComponents />
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(SwipeConfig)
