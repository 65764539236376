import React from 'react'
import { HiXCircle } from 'react-icons/hi2'

export default function AxiomCrossIcon({ color, className }) {
  return (
    <HiXCircle
      color={color}
      className={`text-4xl text-[#FF3B3B] ${className}`}
    />
  )
}
