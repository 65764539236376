import { useEffect } from 'react'
import ButtonsRow from '../../ui/ButtonsRow'
import { useDistribute } from '../../../contexts/DistributeContext'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { ConversationCreate } from '../conversations/ConversationCreate'
import Email from '../AI/Email'
import Social from '../AI/Social'
import Blog from '../AI/Blog'
import Letter from '../AI/Letter'
import {
  AiImage,
  AiMessage,
  AiSchedule,
  AiHeadline,
  AiList,
  AiDistributeAction,
} from './index'
import Connection from '../AI/Connection'
import { useConnections } from '../../../contexts/ConnectionContext'
import EmailVerify from '../AI/EmailVerify'
import { useLimits } from '../../../contexts/LimitsContext'
import Limits from '../../Limits'
import uiStyles from '../../ui/uiStyles'
import { useNavigate } from 'react-router-dom'
import { useAi } from '../../../contexts/AiContext'
import { useClaims } from '../../../contexts/ClaimsContext'
import ConnectSuccess from '../AI/ConnectSuccess'
import Forbidden from '../../admin/Forbidden'

export const AiDistribute = () => {
  const { distribute, saveDistribute } = useDistribute()
  const { claims, saveClaims } = useClaims()
  const { saveAi } = useAi()
  const { connections, saveconnections } = useConnections()
  const { limits, saveLimits } = useLimits()
  const navigate = useNavigate()
  const type =
    distribute?.type === 'social'
      ? distribute?.social || 'facebook'
      : distribute?.type || 'social'
  const content = distribute?.content?.[type]
  console.log(connections)
  console.log(distribute)
  const { axiomFetch } = useAxiomFetch()
  useEffect(() => {
    const fetch_lists = async () => {
      let data = await axiomFetch('/client/listings/lists', 'GET')
      data = data.filter((d) => {
        if (d.STATUS === 'A' && d?.LIST_NAME?.trim()?.length > 1) {
          return true
        } else {
          return false
        }
      })

      saveDistribute((prev) => ({ ...prev, lists: data }))
    }
    fetch_lists()

    // eslint-disable-next-line
  }, [])
  if (limits?.[4]?.limit_reached) {
    return <Limits key={1} />
  }
  const ai_components = {
    social: limits?.[4]?.limit_reached
      ? [<Limits key={2} />]
      : connections?.connection_confirm === type
      ? [
          <Social key={3} />,
          // claims?.client_proxy_login ? (
          //   <Forbidden key={4} />
          // ) : (
          <Connection
            key={5}
            socialmedia={distribute?.social}
            // setTestedConnection={setTestedConnection}
          />,
          // ),
        ]
      : connections?.[type] || claims?.is_internal === 'Y'
      ? // claims?.client_proxy_login ? (
        //   <Forbidden key={6} />
        // ) : (
        [
          <Social key={7} />,
          <AiSchedule key={8} />,
          <AiMessage key={9} />,
          <AiImage key={10} />,
          <AiDistributeAction key={11} action_type='social' />,
        ]
      : // )
        [
          <Social key={12} />,
          // claims?.client_proxy_login ? (
          //   <Forbidden key={13} />
          // ) : (
          <Connection
            key={14}
            socialmedia={distribute?.social}
            // setTestedConnection={setTestedConnection}
          />,
          // ),
        ],
    //
    email: limits?.[1]?.limit_reached
      ? [<Limits key={15} />]
      : connections?.sendgrid
      ? // claims?.client_proxy_login ? (
        //   <Forbidden key={16} />
        // ) : (
        [
          <AiSchedule key={17} />,
          <AiList key={18} single_type='email' />,
          <AiHeadline key={19} />,
          <AiMessage key={20} />,
          <AiDistributeAction key={21} action_type='email' />,
        ]
      : // ) : claims?.client_proxy_login ? (
        //   <Forbidden key={22} />
        // )
        [<EmailVerify key={23} />],
    text: limits?.[2]?.limit_reached
      ? [<Limits key={24} />]
      : // claims?.client_proxy_login ? (
        //   <Forbidden key={25} />
        // ) : (
        [
          <AiSchedule key={26} />,
          <AiList key={27} single_type='phone' />,
          <AiMessage key={28} />,
          <AiImage key={29} />,
          <AiDistributeAction key={30} action_type='text' />,
        ],
    // ),
    // blog: [
    //   <AiMessage />,
    //   <AiDistributeAction text_to_copy={content?.message} action_type='copy' />,
    // ],
    // letter: [
    //   <AiMessage />,
    //   <AiDistributeAction text_to_copy={content?.message} action_type='copy' />,
    // ],
  }

  return (
    <div className='px-3'>
      <div className='capitalize' id='top_menu'>
        <div className='flex justify-start mb-[20px]'>
          <button
            className={uiStyles.hover_btn}
            onClick={() => {
              localStorage.setItem('ai_assistant', 'N')
              saveAi((prev) => ({
                ...prev,
                assistant: {
                  ...prev?.assistant,
                  is_assistant: 'N',
                },
              }))
              navigate('/user/ai/assistant')
            }}
          >
            Ai Assistant
          </button>
        </div>
        {
          <ButtonsRow
            items={Object.keys(ai_components)}
            handler={(item) =>
              saveDistribute((prev) => ({ ...prev, type: item.toLowerCase() }))
            }
            selected={distribute?.type?.toLowerCase() || 'social'}
          />
        }
      </div>
      <div className='flex flex-col gap-[10px] mt-[20px] pb-[20px]'>
        {ai_components[distribute?.type || 'social']}
      </div>
    </div>
  )
}
