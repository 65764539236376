import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import uiStyles from '../../ui/uiStyles'
import { MarketingMaterialEdit } from './MarketingMaterialEdit'
import Modal from '../../Modal'
import { AiOutlineEdit } from 'react-icons/ai'
import { MdOutlineZoomInMap, MdOutlineZoomOutMap } from 'react-icons/md'
import { FaEdit } from 'react-icons/fa'
import { useProfile } from '../../../contexts/UserContext'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { CircleCheckToggle } from '../../form/CircleCheckToggle'
import ButtonsRow from '../../ui/ButtonsRow'
import PDFPreview from '../../PDFPreview'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import {
  compress_img,
  get_storage,
  pick_keys,
  validate_number,
} from '../../../helpers'
import LabelSlider from '../../form/LabelSlider'
import { CalcBackLobby } from '../../calculators'
import MarketingMaterialPhotoPicker from './MarketingMaterialPhotoPicker'
import { useCalc } from '../../../contexts/CalcContext'

export const MarketingMaterialSetup = ({ state, setState, title }) => {
  const { nodeProdFetch, axiomFetch } = useAxiomFetch()
  const { get_instance_uuid, get_calc_type_id } = useCalc()
  const navigate = useNavigate()
  const { profile } = useProfile()
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState(false)
  const [messageSent, setMessageSent] = useState([])
  const [calcZoom, setCalcZoom] = useState(0)
  const [calcLoading, setCalcLoading] = useState(false)
  const session = state?.create_order?.session || {}
  const sessions = state?.create_order?.sessions || []
  const fields = session?.sess_info || []
  const empty_fields = session.empty_fields
  const current_layout = state?.create_order?.current_layout_selection
  const [calcZoomPct, setCalcZoomPct] = useState(current_layout?.zoom_pct)
  const selection = state?.create_order?.current_selection
  const global_theme = state?.materials?.mkt_req_layout_text?.[0]?.layout_text
  const layouts = selection?.layouts
  const zoom_out = state?.create_order?.zoom_out || false
  let html = session?.layout_text

  useEffect(() => {
    if (!state?.hit_create_page) {
      return navigate(
        get_storage('material_back') || '/user/marketing-material'
      )
    }
  }, [])
  if (session?.show_back === 'Y') {
    html = session?.layout_text_back
  }
  const handle_msg = (status, message, type, close) => {
    let messages = messageSent
    const new_message = messages?.findIndex(
      (item) => item?.type?.toString() === type?.toString()
    )
    if (new_message === -1) {
      messages.push({ status, message, type })
    } else {
      messages[new_message].status = status
      messages[new_message].message = message
    }
    setMessageSent([...messages])

    if (close >= 0) {
      setTimeout(() => {
        const curr_msg_index = messages?.findIndex(
          (item) => item?.type?.toString() === type?.toString()
        )
        messages[curr_msg_index].status = 'done'
        messages[curr_msg_index].message = ''
        setMessageSent([...messages])
      }, close)
    }
  }
  for (const field of fields || []) {
    // if (field?.saved_val) {
    if (field?.tag_name === '{{holidy_overlay_area}}') {
      html = html.replaceAll(
        field?.tag_name,
        selection?.theme_html || global_theme || ''
      )
    } else {
      html = html
        ?.replaceAll('&lt;', '<')
        ?.replaceAll(field?.tag_name, field?.saved_val || '')
    }
    // }
  }

  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  const scriptElements = doc.querySelectorAll('script')

  // Remove each <script> element
  if (!session?.calc_type_id) {
    scriptElements.forEach((scriptElement) => {
      scriptElement.parentNode.removeChild(scriptElement)
    })
  }
  const script = doc.createElement('script')
  // script.textContent = `
  // let fields = ${JSON.stringify(fields)}
  // `
  // if (!zoom_out) {
  let zoom_num = calcZoomPct
  // if (session?.prepend_pdf_url || session?.append_pdf_url) {
  //   zoom_num = 0.136
  // }
  // if (session?.calc_type_id) {
  //   zoom_num = 0.25
  // }
  script.textContent += `
    document.body.style.zoom = "${
      zoom_num
      // (816 / 96) * 0.04270588
    }"`
  // }

  doc.body.appendChild(script)
  doc.documentElement.setAttribute('xmlns', doc.documentElement.namespaceURI)
  html = new XMLSerializer().serializeToString(doc)

  html = html
    .replaceAll('&lt;', '<')
    .replaceAll('&amp;', '&')
    .replaceAll('&gt;', '>')
  html = new Blob([html], { type: 'text/html' })
  html = URL.createObjectURL(html)

  const missing_field = () => {
    if (
      fields
        ?.filter(
          (item) =>
            item?.field_id !== 55779 &&
            item?.field_id !== 30064 &&
            item?.allow_edit === 'Y'
        )
        ?.some((item) => !item?.saved_val)
    ) {
      let empty_fields = fields?.filter(
        (item) =>
          !item?.saved_val &&
          item?.field_id !== 55779 &&
          item?.field_id !== 30064 &&
          item?.allow_edit === 'Y'
      )
      let missing_vals_msg = empty_fields?.reduce((curr, val) => {
        return curr + `${val?.prompt_val}, `
      }, 'Missing Values: ')
      handle_msg(
        'failure_msg',
        missing_vals_msg?.trim()?.slice(0, -1),
        'confirm',
        3000
      )
      return true
    } else {
      return false
    }
  }

  const save_address = () => {
    let address = {}
    if (state?.create_order?.current_selection?.del_add1_p) {
      return
    }

    if (profile?.BR_ADD_LINE1) {
      address = {
        // eslint-disable-next-line
        ['del_add1_p']: profile?.BR_ADD_LINE1 || '',
        // eslint-disable-next-line
        ['del_add2_p']: profile?.BR_ADD_LINE2 || '',
        // eslint-disable-next-line
        ['del_city_p']: profile?.BR_ADD_CITY || '',
        // eslint-disable-next-line
        ['del_st_p']: profile?.BR_ADD_ST || '',
        // eslint-disable-next-line
        ['del_zip_p']: profile?.BR_ADD_POSTAL || '',
      }
    } else if (
      profile.account?.office_address1 ||
      profile?.account?.member_address1
    ) {
      address = {
        // eslint-disable-next-line
        ['del_add1_p']:
          profile?.account?.member_address1 ||
          profile?.account?.office_address1 ||
          '',
        // eslint-disable-next-line
        ['del_add2_p']:
          profile?.account?.member_address2 ||
          profile?.account?.office_address2 ||
          '',
        // eslint-disable-next-line
        ['del_city_p']:
          profile?.account?.member_city || profile?.account?.office_city || '',
        // eslint-disable-next-line
        ['del_st_p']:
          profile?.account?.member_state_or_province ||
          profile?.account?.office_state_or_province ||
          '',
        // eslint-disable-next-line
        ['del_zip_p']:
          profile?.account?.member_postal_code ||
          profile?.account?.office_postal_code ||
          '',
      }
    } else {
      address = {
        // eslint-disable-next-line
        ['del_add1_p']: '',
        // eslint-disable-next-line
        ['del_add2_p']: '',
        // eslint-disable-next-line
        ['del_city_p']: '',
        // eslint-disable-next-line
        ['del_st_p']: '',
        // eslint-disable-next-line
        ['del_zip_p']: '',
      }
    }

    const save_selection = (key, value) => {
      setState((prev) => ({
        ...prev,
        create_order: {
          ...prev?.create_order,
          current_selection: {
            ...prev?.create_order?.current_selection,
            [key]: value,
          },
        },
      }))
    }

    setState((prev) => ({
      ...prev,
      create_order: {
        ...prev?.create_order,
        current_selection: {
          ...prev?.create_order?.current_selection,
          ...address,
        },
      },
    }))
  }

  const save_selection = (key, value) => {
    setState((prev) => ({
      ...prev,
      create_order: {
        ...prev?.create_order,
        current_selection: {
          ...prev?.create_order?.current_selection,
          [key]: value,
        },
        session: {
          ...prev?.create_order?.session,
          [key]: ['selected_theme', 'status_p'].includes(key)
            ? validate_number(value)
            : value,
        },
        sessions: [
          ...(prev?.create_order?.sessions || [])?.filter(
            (item) => item?.sess_id !== prev?.create_order?.session?.sess_id
          ),
          {
            ...prev?.create_order?.session,
            [key]: ['selected_theme', 'status_p'].includes(key)
              ? validate_number(value)
              : value,
          },
        ],
      },
    }))
  }

  const gen_pdf = async () => {
    try {
      const replace_fields = async (html, sess_info) => {
        for (const field of sess_info) {
          if (field?.tag_name === '{{holidy_overlay_area}}') {
            html = html.replaceAll(
              field?.tag_name,
              selection?.theme_html || global_theme || ''
            )
          } else {
            let new_field = field?.saved_val || ''
            if (field?.image_field === 'Y' && new_field) {
              if (
                new_field !==
                'https://driggstitle.com/reviews/Ax_Engage/dbc/t1.png'
              ) {
                new_field = new_field
                // await compress_img(new_field)
              }
            }
            html = html?.replaceAll(field?.tag_name, new_field)
          }
        }
        return html
      }
      const get_layout = (id) => {
        const layout = state?.materials?.mkt_req_layout?.find(
          (layout_item) =>
            layout_item?.layout_id === id &&
            !layout_item?.layout_name?.toLowerCase()?.includes('test')
        )
        return layout
      }
      let htmls = []
      const add_sess = async (sess) => {
        let these_htmls = []
        const main_obj = {
          layout_id: sess?.layout_id,
          media_type: sess?.calc_type_id ? 'print' : 'screen',
          page_ranges: sess?.calc_type_id ? 'all' : '',
          height: get_layout(sess?.layout_id)?.height_pix || 1080,
          prt_height: get_layout(sess?.layout_id)?.prt_height || 11.25,
          width: get_layout(sess?.layout_id)?.width_pix || 1080,
          prt_width: get_layout(sess?.layout_id)?.prt_width || 8.75,
          dpi: get_layout(sess?.layout_id)?.prt_dpi || 300,
          layout_name:
            get_layout(sess?.layout_id)?.layout_name_alias ||
            get_layout(sess?.layout_id)?.layout_name,
          file_name: (
            get_layout(sess?.layout_id)?.layout_name_alias ||
            get_layout(sess?.layout_id)?.layout_name
          )
            ?.split(' ')
            ?.join('_'),
          html: await replace_fields(sess?.layout_text, sess?.sess_info),
          pages: [0, 0],
        }
        these_htmls.push({
          ...main_obj,
        })
        if (sess?.layout_text_back) {
          these_htmls.push({
            ...main_obj,
            html: await replace_fields(sess?.layout_text_back, sess?.sess_info),
          })
        }
        if (sess?.pdf_guide_url) {
          these_htmls.push({
            ...main_obj,
            html: '',
            pdf_guide_url: sess?.pdf_guide_url,
            pages: [1],
          })
        }
        if (sess?.prepend_pdf_url) {
          these_htmls.unshift({
            ...main_obj,
            html: '',
            pdf_guide_url: sess?.prepend_pdf_url,
            pages: [0],
          })
        }
        if (sess?.append_pdf_url) {
          these_htmls.push({
            ...main_obj,
            html: '',
            pdf_guide_url: sess?.append_pdf_url,
            pages: [0],
          })
        }
        htmls.push(these_htmls)
      }

      for (const sess of sessions) {
        await add_sess(sess)
      }

      const urls = await Promise.all([
        ...htmls?.map(async (html) => {
          let url = ''
          if (html?.length > 1) {
            const side_urls = await Promise?.all(
              html?.map(async (side_html) => {
                if (side_html?.pdf_guide_url) {
                  return side_html?.pdf_guide_url
                } else {
                  const pdf = await nodeProdFetch({
                    url: '/node_to_oracle/api/v1/pdf/create',
                    method: 'POST',
                    body: {
                      ...side_html,
                      height: side_html?.prt_height || 11.25,
                      width: side_html?.prt_width || 8.75,
                      dpi: side_html?.dpi || 300,
                    },
                  })
                  side_html.pdf_url = pdf
                  return pdf
                }
              })
            )
            url = await nodeProdFetch({
              url: '/node_to_oracle/api/v1/pdf/merge',
              method: 'POST',
              body: {
                urls: side_urls,
                file_name: html?.[0]?.layout_name,
                pages: html?.map((item) => item.pages),
              },
            })
            html[0].pdf_url = url
          } else {
            url = await nodeProdFetch({
              url: '/node_to_oracle/api/v1/pdf/create',
              method: 'POST',
              body: {
                ...html[0],
                height: html?.[0]?.prt_height || 11.25,
                width: html?.[0]?.prt_width || 8.75,
                dpi: html?.[0]?.dpi || 300,
                // height: html?.[0]?.prt_height || 8.5,
                // width: html?.[0]?.prt_width || 11,
                // dpi: html?.[0]?.prt_dpi || 300,
              },
            })
          }
          html[0].pdf_url = url
          return url
        }),

        ...htmls
          ?.flat()
          ?.filter((item) => item?.html)
          ?.map(async (html) => {
            const url = await nodeProdFetch({
              url: '/node_to_oracle/api/v1/pdf/create',
              method: 'POST',
              body: {
                ...html,
                type: 'png',
              },
            })
            // html.pdf_url = url
            return url
          }),
      ])

      let final_url = ''

      if (
        urls
          ?.flat()
          ?.filter((item) => typeof item === 'string')
          ?.filter((item) => item?.toString()?.includes('pdf'))?.length > 1
      ) {
        final_url = await nodeProdFetch({
          url: '/node_to_oracle/api/v1/pdf/merge',
          method: 'POST',
          body: {
            urls: urls
              ?.filter((item) => typeof item === 'string')
              ?.filter((item) => item?.includes('pdf')),
            file_name: 'all_materials',
          },
        })
      } else {
        final_url = urls
          ?.filter((item) => typeof item === 'string')
          .filter((item) => item?.includes('pdf'))?.[0]
      }

      // htmls = await Promise.all(
      //   htmls?.map(async (html) => {
      //     if (html?.length > 1) {
      //       const pdf_urls = html
      //         ?.filter((item) => typeof item?.pdf_url === 'string')
      //         ?.filter((item) => item?.pdf_url?.includes('pdf'))
      //         ?.map((item) => item?.pdf_url)
      //       const grouped_pdf_url = await nodeProdFetch({
      //         url: '/node_to_oracle/api/v1/pdf/merge',
      //         method: 'POST',
      //         body: {
      //           urls: pdf_urls,
      //           file_name: html?.[0]?.file_name,
      //         },
      //       })
      //       html[0].pdf_url = grouped_pdf_url
      //       return html?.[0] || {}
      //     }
      //     return html
      //   })
      // )
      const pdf_sessions = sessions.map((sess_item) => {
        const pdf_url =
          htmls
            ?.flat()
            ?.filter(
              (item) =>
                item?.pdf_url
                  ?.toString()
                  ?.replace('[Object object]', '')
                  ?.replace('undefined', '') &&
                !item?.pdf_url?.toString()?.includes('png')
            )
            ?.find((html) => html?.layout_id === sess_item?.layout_id)
            ?.pdf_url || null
        return {
          // ...selection,
          ...pick_keys(sess_item, ['layout_text', 'layouts']),
          pdf_url: encodeURI(
            pdf_url?.toString()?.replace('[object Object]', '') || null
          ),
        }
      })

      await axiomFetch('/marketing/materials/flyer/submit', 'POST', {
        sessions: pdf_sessions,
        create_request: false,
      })

      await Promise.all(
        pdf_sessions
          ?.filter((sess) => sess?.calc_type_id)
          ?.map(async (sess) => {
            const instance_uuid = get_instance_uuid(
              get_calc_type_id(sess?.calc_type_id)
            )
            return await axiomFetch(
              '/calculators/save',
              'POST',
              {
                values: {
                  instance_uuid,
                  pdf_url: sess?.pdf_url || null,
                },
              },
              {},
              false,
              null,
              0
            )
          })
      )

      console.log('final_url', final_url)
      return {
        pdf_url: final_url,
        pdf_urls: [
          ...urls?.filter(
            (item) => typeof item === 'string' && item?.includes('pdf')
          ),
        ],
        png_urls: [
          ...urls?.filter(
            (item) => typeof item === 'string' && !item?.includes('pdf')
          ),
        ],
      }
    } catch (error) {
      console.log(error)
      return ''
    }
  }
  const update_pdf = async (should_change = true) => {
    if (should_change) {
      setCalcLoading(true)
      const urls = await gen_pdf()
      save_selection('pdf_url', urls?.pdf_url || '')
      save_selection('pdf_urls', urls?.pdf_urls || [])
      save_selection('png_urls', urls?.png_urls || [])
      setState((prev) => ({
        ...prev,
        calc_edited: (prev?.calc_edited || 0) + 1,
      }))
      setCalcLoading(false)
      setState((prev) => ({ ...prev, field_changed: false }))
    }
  }

  useEffect(() => {
    if (session?.calc_type_id) {
      update_pdf(true)
    }
  }, [state?.field_changed, session?.calc_type_id])

  useEffect(() => {
    setCalcZoomPct(current_layout?.zoom_pct)
  }, [current_layout])

  const ids = [
    { id: 2, name: 'property_promotion' },
    { id: 1, name: 'self_promotion' },
  ]

  if (empty_fields) {
    return (
      <div className='flex justify-center px-3'>
        <MarketingMaterialEdit
          showModal={showModal}
          setShowModal={(modal_type) => {
            if (modalType === 'picker') {
              return setModalType('material')
            }
            if (session?.calc_type_id && modal_type === false) {
              update_pdf(state?.field_changed)
            }
            setModalType('material')
            setShowModal(modal_type)
          }}
          modalType={modalType}
          setModalType={setModalType}
          state={state}
          setState={setState}
        />
      </div>
    )
  }
  return (
    <div>
      {sessions?.length > 1 ? (
        <div className='px-3 my-[10px]'>
          {layouts?.length ? (
            <ButtonsRow
              items={layouts?.map(
                (item) => item?.layout_name_alias || item?.layout_name
              )}
              handler={(item) => {
                setState((prev) => ({
                  ...prev,
                  create_order: {
                    ...prev?.create_order,
                    current_layout_selection: layouts?.filter(
                      (layout_item) =>
                        (layout_item?.layout_name_alias ||
                          layout_item.layout_name) === item
                    )?.[0],
                    session: prev?.create_order?.sessions.find(
                      (this_sess) =>
                        this_sess.layout_id ===
                        layouts?.filter(
                          (layout_item) =>
                            (layout_item?.layout_name_alias ||
                              layout_item?.layout_name) === item
                        )?.[0]?.layout_id
                    ),
                  },
                }))
              }}
              selected={
                current_layout?.layout_name_alias?.toLowerCase() ||
                current_layout?.layout_name?.toLowerCase()
              }
              button_style={'text-[12px] pl-2'}
              scroll={true}
            />
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
      {session.calc_type_id ? (
        <div className='px-3 mt-[-10px] mb-[5px]'>
          <CalcBackLobby />
        </div>
      ) : (
        ''
      )}
      <div className='flex flex-col gap-[10px] items-center'>
        <div className='w-full px-[15px] flex text-center justify-between items-center'>
          <p
            className={`${uiStyles.text_black_title} capitalize text-center mt-[5px]`}
          >
            {`${title || 'Marketing Materials'}`}
          </p>
          <div className='w-full flex text-center justify-end gap-[10px] items-center'>
            <div className=''>
              {/* <button
                className='pt-[5px]'
                onClick={() => {
                  setState((prev) => ({
                    ...prev,
                    create_order: {
                      ...prev?.create_order,
                      zoom_out: !zoom_out,
                    },
                  }))
                  if (session?.calc_type_id) {
                    setCalcZoom((prev) => {
                      return !prev ? 100 : 0
                    })
                  }
                }}
              >
                {state?.create_order?.zoom_out ? (
                  <MdOutlineZoomOutMap size={25} color={'black'} />
                ) : (
                  <MdOutlineZoomInMap size={25} color={'black'} />
                )}
              </button> */}
              <LabelSlider
                name='zoom_pct'
                label={`Zoom ${validate_number(calcZoomPct * 100, 0)}%`}
                value={calcZoomPct * 100}
                handle_change={(e) => {
                  if (e.target.value < 10) {
                    return
                  }
                  setCalcZoomPct(e.target.value / 100)
                }}
              />
            </div>

            <div>
              <button
                className='pt-[5px]'
                onClick={() => {
                  setState((prev) => ({
                    ...prev,
                    create_order: {
                      ...prev?.create_order,
                      selected_fields: '',
                    },
                  }))
                  setModalType('material')
                  setShowModal((prev) => !prev)
                }}
              >
                <FaEdit size={25} color={'black'} />
              </button>
            </div>
          </div>
        </div>
        <div
          className={`flex w-full ${
            state?.create_order?.session?.layout_text_back &&
            state?.materials?.mkt_flyer_themes?.length
              ? 'pl-[60px] pr-[10px] justify-between'
              : 'pl-[10px] justify-center'
          } `}
        >
          {state?.create_order?.session?.layout_text_back ? (
            <div className='flex justify-center'>
              <CircleCheckToggle
                name={`show_back`}
                type='toggle'
                toggle_left={true}
                //   toggle_color_grad={'from-[#0A66C2] to-[#0A66C2]'}
                //   toggle_color={'text-[#000]'}
                toggle_left_label={'Back'}
                toggle_right_label={'Front'}
                inputValue={session}
                handle_change={async (e) => {
                  setState((prev) => {
                    return {
                      ...prev,
                      create_order: {
                        ...prev?.create_order,
                        session: {
                          ...prev?.create_order?.session,
                          show_back:
                            prev?.create_order?.session?.show_back === 'Y'
                              ? 'N'
                              : 'Y',
                        },
                      },
                    }
                  })
                }}
              />
            </div>
          ) : (
            ''
          )}
          {state?.materials?.mkt_flyer_themes?.length &&
          session?.layout_text?.includes('{{holidy_overlay_area}}') ? (
            <div className='flex justify-center'>
              <select
                className={uiStyles.select}
                value={selection?.selected_theme}
                onChange={(e) => {
                  save_selection('selected_theme', e.target.value)
                  if (Number(e.target.value) > 0) {
                    let theme_html =
                      session?.layout_text_theme || global_theme || ''
                    const theme = state?.materials?.mkt_flyer_themes?.find(
                      (item) => item.theme_id === Number(e.target.value)
                    )
                    for (const replace_field of theme?.replace_fields) {
                      theme_html = theme_html?.replaceAll(
                        replace_field?.repl_field,
                        replace_field?.repl_url
                      )
                    }
                    save_selection('theme_html', theme_html)
                  } else {
                    save_selection('theme_html', '')
                  }
                }}
              >
                <option key={'theme'} value='0'>
                  Choose Theme
                </option>
                {state?.materials?.mkt_flyer_themes?.map((item) => {
                  return (
                    <option key={item?.theme_id} value={item?.theme_id}>
                      {item?.theme_name}
                    </option>
                  )
                })}
              </select>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className='w-full h-[600px]'>
          {session?.calc_type_id ? (
            calcLoading ? (
              <SmallLoadingRel />
            ) : (
              <iframe
                key={selection?.pdf_url + state?.calc_edited + calcZoomPct}
                src={`${encodeURI(selection?.pdf_url)}?${
                  (state?.calc_edited || 0) + calcZoomPct
                }#toolbar=0&navpanes=0&zoom=${calcZoomPct * 100}`}
                style={{
                  // transition: 'transform 0.3s ease',
                  width: '100%',
                  height: '100%',
                  overflowY: 'scroll',
                  // overflow: 'scroll',
                  // marginTop: '-200px',
                }}
              />
            )
          ) : (
            <iframe
              title={'layout_html'}
              src={html !== 'undefined' ? html : 'Select a New theme'}
              onLoad={(e) => {
                // if (e.target.childNodes.length < 1) {
                //   let error_html = `<div>Select a new Layout!</div>`
                //   error_html = new Blob([error_html], { type: 'text/html' })
                //   error_html = URL.createObjectURL(error_html)
                //   e.target.src = error_html
                // }
              }}
              // className=''
              style={{
                // transition: 'transform 0.3s ease',
                width: '100%',
                height: '100%',
                overflowY: 'scroll',
                // overflow: 'scroll',
                // marginTop: '-200px',
              }}
            ></iframe>
          )}
        </div>
      </div>
      <div className='mb-[10px]'>
        {messageSent?.some((item_msg) => {
          return (
            item_msg?.type?.toString() === 'confirm' &&
            item_msg?.status !== 'done'
          )
        }) ? (
          <div
            className={`${
              uiStyles[
                messageSent?.find(
                  (item_msg) => item_msg?.type?.toString() === 'confirm'
                )?.status
              ]
            } mb-[20px] mx-3`}
          >
            {
              messageSent.find(
                (item_msg) => item_msg?.type?.toString() === 'confirm'
              )?.message
            }
          </div>
        ) : (
          <div className='flex justify-end gap-[10px] mt-[10px] pb-[10px] mr-[10px]'>
            {session?.calc_type_id ||
            calcLoading ||
            session?.weekender === 'Y' ? (
              ''
            ) : (
              <button
                className={`${uiStyles.red_btn}`}
                onClick={async () => {
                  return navigate('/user/marketing-material')
                  if (!missing_field()) {
                    // handle_msg('loading_msg', 'Generating Content...', 'confirm')
                    // save_address()
                    // save_selection('status_p', 5)
                    // const url = await gen_pdf()
                    // save_selection('pdf_url', url)
                    // handle_msg('success_msg', 'Created!', 'confirm', 0)
                    setState((prev) => ({
                      ...prev,
                      create_order: {
                        ...prev?.create_order,
                        switch_layout: true,
                      },
                    }))
                    handle_msg(
                      'loading_msg',
                      'Generating Content...',
                      'confirm'
                    )
                    save_address()
                    save_selection('status_p', 9)
                    if (!session?.calc_type_id) {
                      const urls = await gen_pdf()
                      save_selection('pdf_url', urls?.pdf_url || '')
                      save_selection('pdf_urls', urls?.pdf_urls || [])
                      save_selection('png_urls', urls?.png_urls || [])
                      handle_msg('success_msg', 'Created!', 'confirm', 0)
                    }
                    return navigate('/user/marketing-material/layouts/confirm')
                  }
                }}
              >
                Not Even Close
              </button>
            )}
            {calcLoading ? (
              ''
            ) : (
              <button
                data-cy='looks_good'
                className={`${uiStyles.hover_btn}`}
                onClick={async () => {
                  if (!missing_field()) {
                    handle_msg(
                      'loading_msg',
                      'Generating Content...',
                      'confirm'
                    )
                    setState((prev) => ({
                      ...prev,
                      create_order: {
                        ...prev?.create_order,
                        switch_layout: false,
                      },
                    }))
                    save_address()
                    save_selection('status_p', 9)
                    if (!session?.calc_type_id || !session?.pdf_url) {
                      const urls = await gen_pdf()
                      save_selection('pdf_url', urls?.pdf_url || '')
                      save_selection('pdf_urls', urls?.pdf_urls || [])
                      save_selection('png_urls', urls?.png_urls || [])
                      handle_msg('success_msg', 'Created!', 'confirm', 0)
                    }
                    return navigate('/user/marketing-material/layouts/confirm')
                  }
                }}
              >
                {session?.calc_type_id ? 'Next' : 'Looks Good'}
              </button>
            )}
          </div>
        )}
      </div>
      {showModal ? (
        <Modal
          modalName={'edit_marketing_material'}
          setShowModal={(modal_type) => {
            if (modalType === 'picker') {
              return setModalType('material')
            }
            if (session?.calc_type_id && modal_type === false) {
              update_pdf(state?.field_changed)
            }
            setShowModal(modal_type)
          }}
          showModal={showModal}
          showClose={true}
          height={'h-[580px]'}
        >
          {modalType === 'picker' ? (
            <MarketingMaterialPhotoPicker
              showModal={showModal}
              setShowModal={(modal_type) => {
                if (modal_type === false) {
                  setModalType('edit')
                }
              }}
              setModalType={setModalType}
              state={state}
              setState={setState}
            />
          ) : (
            <MarketingMaterialEdit
              showModal={showModal}
              setShowModal={(modal_type) => {
                if (modalType === 'picker') {
                  return setModalType('material')
                }
                if (session?.calc_type_id && modal_type === false) {
                  update_pdf(state?.field_changed)
                }
                setModalType('material')
                setShowModal(modal_type)
              }}
              setModalType={setModalType}
              state={state}
              setState={setState}
            />
          )}
        </Modal>
      ) : (
        ''
      )}
      {showModal ? (
        <Modal
          modalName={'edit_marketing_material'}
          setShowModal={(modal_type) => {
            if (modalType === 'picker') {
              return setModalType('material')
            }
            if (session?.calc_type_id && modal_type === false) {
              update_pdf(state?.field_changed)
            }
            setShowModal(modal_type)
          }}
          showModal={showModal}
          showClose={true}
          height={'h-[580px]'}
        >
          <MarketingMaterialEdit
            showModal={showModal}
            setShowModal={(modal_type) => {
              if (modalType === 'picker') {
                return setModalType('material')
              }
              if (session?.calc_type_id && modal_type === false) {
                update_pdf(state?.field_changed)
              }
              setModalType('material')
              setShowModal(modal_type)
            }}
            modalType={modalType}
            setModalType={setModalType}
            state={state}
            setState={setState}
          />
        </Modal>
      ) : (
        ''
      )}
    </div>
  )
}
