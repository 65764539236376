import {useState} from 'react'
import uiStyles from '../../ui/uiStyles'
import DateTimePicker from '../../ui/DateTimePicker'
import PhotoGallery from '../../ui/PhotoGallery';
import useAxiomFetch from '../../../hooks/useAxiomFetch';
import { az_to_local } from '../../../helpers';
const SchedulerModal = ({editItem, scheduleState, setScheduleState, setShowModal}) => {
    const {axiomFetch} = useAxiomFetch()
    const [state, setState] = useState(editItem)
    const handle_date = (date) => {
        console.log(date)
        setState((prev) => ({...prev, schedule_dt: date}))
    }

    const handle_edit = async (e) => {
        const update_schedule = scheduleState.content.filter(item => item.id !== state?.id)
        setScheduleState((prev) => ({...prev, isLoading: true}))
        if (state?.status === 'deleted') {
            state.schedule_dt = az_to_local(state?.schedule_dt)
            setScheduleState((prev) => ({...prev, content: [state, ...update_schedule]}))
        } else {
            setScheduleState((prev) => ({...prev, content: [state, ...update_schedule]}))
        }
        state.scheduleDate = state?.schedule_dt
        console.log(state)
        console.log(JSON.stringify(state))
        await axiomFetch(`/social/schedule/update`, 'POST', state)
        setScheduleState((prev) => ({...prev, isLoading: false}))
        setShowModal(false)
    }
  return (
    <div>
    {state?.status !== 'deleted' ? <>
    {['email'].includes(state?.type) ? <><label className={uiStyles.label}>Subject</label>
    <input className={`${uiStyles.input} mb-[10px]`}
    value={state?.subject}
    onChange={(e) => {
        setState((prev) => ({...prev, subject: e?.target?.value}))
    }}></input></> : ''}
    <label className={uiStyles.label}>Message</label>
    <textarea className={`${uiStyles.text_area}`}
    value={state?.message}
    onChange={(e) => {
        setState((prev) => ({...prev, message: e?.target?.value}))
    }}></textarea>
    <div className='flex flex-col my-[15px]'>
     <label className={uiStyles.label}>Schedule Date</label>
     <DateTimePicker scheduleDate={editItem?.schedule_dt ? az_to_local(editItem?.schedule_dt) : new Date()} dateChangeHandler={handle_date}/>
     </div>
     {['instagram', 'facebook'].includes(state?.type) ? <>
     <label>Current image</label>
     <img alt='original' className='h-[90px] w-[100px] object-cover rounded-2xl' src={state?.photo}/>
     <label>Select New Image</label>
   
    <PhotoGallery onSelectImage={(image) => {
        setState((prev) => ({...prev, photo: image}))
    }}/>
    
 </>: ''}</> : <div className={`${uiStyles.text_black_subtitle} text-[17px] p-3 mb-[20px]`}>Are you sure you want to cancel?</div>}
    <div className='flex justify-end w-full gap-[10px]'>
    <button className={uiStyles.white_btn}
    onClick={() => {
        setShowModal(false)
    }}>{state?.status !== 'deleted' ? 'Cancel' : 'No'}</button>
    <button 
    onClick={handle_edit} 
    className={uiStyles.green_btn}>
        {state?.status !== 'deleted' ? 'Save' : 'Yes'}
    </button>
    </div>
    </div>
    
  )
}

export default SchedulerModal