import { useState, useEffect } from 'react'
import { MdOutlineIosShare } from 'react-icons/md'
import { useCalc } from '../../contexts/CalcContext'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import Modal from '../Modal'
import { useMktReqs } from '../../contexts/MktReqsContext'
import { useNavigate } from 'react-router-dom'
import { SmallLoadingRel } from '../ui/SmallLoadingRel'
import { compress_img, get_storage, set_storage } from '../../helpers'
import CalcBackLobby from './CalcBackLobby'

const CalcShare = ({
  calculator_type = 'buy',
  implementation_type,
  include_bottom_margin = true,
}) => {
  const { calc, saveCalc, get_calc_type_id, get_instance_uuid } = useCalc()
  const { mktReqs, saveMktReqs, load_session_from_layout } = useMktReqs()
  const { axiomFetch } = useAxiomFetch()
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState(false)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  useEffect(() => {
    const controller = new AbortController()
    const get_calc_flyer = async () => {
      setLoading(true)

      if (calc?.flyer?.calc_type_id !== get_calc_type_id(calculator_type)) {
        const flyer = await axiomFetch(
          `/calculators/flyer?instance_uuid=${get_instance_uuid(
            calculator_type,
            implementation_type
          )}&calc_type_id=${get_calc_type_id(calculator_type)}`,
          'GET',
          {},
          {},
          false,
          controller?.signal
        )

        saveCalc((prev) => ({
          ...prev,
          [calculator_type]: {
            ...prev?.[calculator_type],
            flyer,
          },
        }))
      }
      setLoading(false)
    }
    get_calc_flyer()
    return () => {
      return controller?.abort()
    }
  }, [calculator_type])

  useEffect(() => {
    saveMktReqs((prev) => ({
      ...prev,
      create_order: {},
      hit_create_page: true,
    }))
    set_storage('material_back', '/user/calculators')
  }, [])
  const modals = {}

  const get_flyer = async () => {
    const layout = calc?.[calculator_type]?.flyer
    setLoading(true)
    await load_session_from_layout({
      layout: layout,
      parent_url: `/user/calculators/${implementation_type || calculator_type}`,
      prev_parent_url: '/user/calculators',
      calc_id: layout?.calc_seq_id,
      mls_id: calc?.mls_id,
    })
  }
  if (
    get_storage('vbc_calc') === 'Y' ||
    get_storage('home_view_calc') === 'vbc_view'
  ) {
    return <></>
  }
  return (
    <div>
      <div className='flex justify-between ml-3 items-center mb-[10px]'>
        <CalcBackLobby />
        {loading || calc?.share_loading ? (
          <div className='flex justify-end mx-3 min-h-[25px] min-w-[25px]'>
            <div>
              <SmallLoadingRel />
            </div>
          </div>
        ) : (
          <div>
            <div
              className={`flex justify-end mx-3 ${
                include_bottom_margin ? ' ' : ''
              } cursor-pointer`}
              onClick={get_flyer}
            >
              <MdOutlineIosShare size={25} className={`text-accent-400`} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default CalcShare
