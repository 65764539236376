import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

export const ListingContext = createContext()

export const ListingProvider = ({ children, userData }) => {
  const [listing, setListing] = useState(null)

  const saveListing = useCallback((data) => {
    setListing(data)
  }, [])

  const getListingByID = useCallback(
    (id) => {
      if (!listing) {
        return null
      }
      return listing.find((item, index) => {
        return +item.file_id === +id
      })
    },

    [listing]
  )

  const getListingByMLS = useCallback(
    (mls_id) => {
      if (!listing) {
        return null
      }
      return listing.find((item, index) => {
        return item?.MLS_ID === mls_id
      })
    },

    [listing]
  )

  const value = useMemo(
    () => ({
      listing,
      saveListing,
      getListingByMLS,
      getListingByID,
    }),
    [listing, saveListing, getListingByID, getListingByMLS]
  )

  return (
    <ListingContext.Provider value={value}>{children}</ListingContext.Provider>
  )
}

export const useListing = () => {
  const context = useContext(ListingContext)

  if (!context) {
    throw Error('ListingContext must be used inside an ListingContextProvider')
  }

  return context
}
