import Wrapper from '../../components/layout/Wrapper'
import { AddOnTour } from '../../components/pages/tours/'
import SubNav from '../../components/SubNav'
import { useListingTours } from '../../contexts/ListingToursContext'
const PageAddOnTour = () => {
  const { listingTours, saveListingTours } = useListingTours()
  return (
    <Wrapper>
      <SubNav />
      <AddOnTour state={listingTours} setState={saveListingTours} />
    </Wrapper>
  )
}

export default PageAddOnTour
