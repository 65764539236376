import React, { useEffect, useState } from 'react'
import Wrapper from '../../layout/Wrapper'
import SubNav from '../../SubNav'
import TitleIcon from '../../ui/TitleIcon'
import AxiomClosedFileIcon from '../../../assets/images/closed.svg'
import SwipeConfig from '../../ui/SwipeConfig'
import ListItem from '../../ui/ListItem'
import Document from '../../../assets/images/documents.jpg'
import AxiomOpenedIcon from '../../../assets/images/open.svg'
import { Link, useSearchParams, useLocation } from 'react-router-dom'
// import { useTransactions } from '../../../contexts/TransactionsContext';
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import ProgressBar from '../../ui/ProgressBar'
import SmallLoading from '../../ui/SmallLoading'
export default function AllTransactions() {
  const { axiomFetch } = useAxiomFetch()
  const [transactionsList, setTransactionsList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [selected, setSelected] = useState(1)
  const [searchParams] = useSearchParams()
  const filter = searchParams.get('filter')
  const location = useLocation()
  // const { saveTransactions, transactions, getClosedTransactions, getOpenTransactions } =
  //   useTransactions();

  // useEffect(() => {

  //   if (filter === 'open') {
  //     setTransactionsList(getOpenTransactions());
  //   } else if (filter === 'closed') {
  //     setTransactionsList(getClosedTransactions());
  //   } else {
  //     setTransactionsList(transactions);
  //   }
  // }, [filter, transactions]);

  useEffect(() => {
    let controller = new AbortController()
    const refresh_transactions = async () => {
      setIsLoading(true)
      const data = await axiomFetch(
        '/escrow/transaction?limit=1000',
        'GET',
        {},
        {},
        false,
        controller.signal
      )

      if (filter === 'open') {
        if (data.length) {
          setTransactionsList(
            data?.filter((transaction) => transaction.close_disb_dt === null)
          )
        }
      } else if (filter === 'closed') {
        if (data.length) {
          setTransactionsList(
            data?.filter((transaction) => transaction.close_disb_dt !== null)
          )
        }
      } else {
        if (data.length) {
          setTransactionsList(data)
        }
      }
      console.log('signal', controller.signal.aborted)
      if (!controller.signal.aborted) {
        setIsLoading(false)
      }
    }
    refresh_transactions()
    return () => {
      controller?.abort()
    }
  }, [])

  const propertyHTML = (item) => {
    return (
      <div className='swipeable relative pr-20'>
        <h3
          className={`swipeable ${
            !item?.isActive ? 'text-[#34A853]' : 'text-[#FF8800]'
          } font-semibold`}
        >
          File {item?.ext_id}
        </h3>
        <p className='swipeable font-semibold text-primary'>Property Address</p>
        <p className='swipeable text-primary'>{item?.address1}</p>
        <p className='swipeable text-primary'>
          Status: {item?.close_disb_dt ? 'Closed' : 'Open'}
        </p>
        <div className='swipeable absolute right-0 top-0 bottom-0 w-12 flex justify-center items-center'>
          {item?.close_disb_dt ? (
            <img className='swipeable w-[18px]' src={AxiomClosedFileIcon} />
          ) : (
            <img className='swipeable w-[28px]' src={AxiomOpenedIcon} />
          )}
        </div>
      </div>
    )
  }
  const rightConfig = (item) => () => {
    return (
      <>
        <div className='ml-1 underline w-[200px] flex'>
          <div className='flex flex-col gap-3'>
            <div>
              <Link
                to={`${item?.file_id}/documents`}
                state={{
                  parent: location.pathname,
                  prevParent: location?.state?.parent || null,
                }}
                className='mx-1 ml-[5px]'
              >
                Docs
              </Link>
              <Link
                to={`${item?.file_id}/statuses`}
                state={{
                  parent: location.pathname,
                  prevParent: location?.state?.parent || null,
                }}
                className='mx-1'
              >
                Statuses
              </Link>

              <Link
                to={`${item?.file_id}/parties`}
                state={{
                  parent: location.pathname,
                  prevParent: location?.state?.parent || null,
                }}
                className='mx-1'
              >
                Parties
              </Link>
              <Link
                to={`${item?.file_id}/notes`}
                state={{
                  parent: location.pathname,
                  prevParent: location?.state?.parent || null,
                }}
                className='mx-1'
              >
                Notes
              </Link>
            </div>
            <Link
              to={`${item?.file_id}/title`}
              state={{
                parent: location.pathname,
                prevParent: location?.state?.parent || null,
              }}
              className='mx-1'
            >
              Title
            </Link>
          </div>
        </div>
      </>
    )
  }
  console.log(isLoading)
  const listHTML = !transactionsList?.length ? (
    <div className='ml-[20px]'>No {filter} transactions were found.</div>
  ) : (
    transactionsList?.map((transaction, index) => {
      return (
        <SwipeConfig
          key={index}
          index={index}
          selectedIndex={selected}
          setSelectedIndex={setSelected}
          widthConfigRight={44}
          RightComponents={rightConfig(transaction)}
        >
          <ListItem
            progress={
              <ProgressBar
                stringId={`str-${index}`}
                percentage={transaction?.pct_done}
                color1='#5B8DEF'
                color2='#0063F7'
                strokeWidth={10}
              />
            }
            content={propertyHTML(transaction)}
          />
        </SwipeConfig>
      )
    })
  )
  return (
    <Wrapper>
      <SubNav url={'/user/my-transactions-lobby'} />
      <TitleIcon className='mb-5'>
        {<div className='capitalize mr-[6px]'>{filter ? filter : 'All'} </div>}{' '}
        {filter ? 'Transactions' : 'Closing Experiences'}
      </TitleIcon>
      {isLoading ? <SmallLoading /> : <>{listHTML}</>}
    </Wrapper>
  )
}
