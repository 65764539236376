import { useEffect, useState } from 'react'
import { AiOutlineCopy } from 'react-icons/ai'
import { check_prompt } from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import SmallLoading from '../../ui/SmallLoading'
import uiStyles from '../../ui/uiStyles'
import structuredClone from '@ungap/structured-clone'
import { Ask } from './Ask'
import { useLimits } from '../../../contexts/LimitsContext'
import { useAi } from '../../../contexts/AiContext'
import Limits from '../../Limits'
import { AiMls, AiMlsSimple } from '../AiSlots'
import { useDistribute } from '../../../contexts/DistributeContext'
import { content_types, long_types } from '../../../data/data'
import { useNavigate } from 'react-router-dom'
import { CircleCheckToggle } from '../../form/CircleCheckToggle'
import { useProfile } from '../../../contexts/UserContext'
export const AskAxiom = ({
  type,
  rewrite,
  show_type_label = true,
  response_only = false,
  height_change = 0.6,
}) => {
  const { limits, saveLimits } = useLimits()
  const { ai, saveAi } = useAi()
  const { distribute, saveDistribute } = useDistribute()
  const { profile } = useProfile()
  const navigate = useNavigate()
  // useEffect(() => {
  //   const controller = new AbortController()
  //   const get_prompt_options = async () => {
  //     const ai_prompt_options = await axiomFetch(
  //       '/marketing/content/aiPromptKeywords',
  //       'GET',
  //       {},
  //       {},
  //       false,
  //       controller?.signal
  //     )
  //     saveAi((prev) => ({ ...prev, ai_prompt_options }))
  //   }
  //   get_prompt_options()
  //   return () => {
  //     controller?.abort()
  //   }
  // }, [])

  useEffect(() => {
    const controller = new AbortController()
    const get_options = async () => {
      const data = await axiomFetch('/marketing/content/aiOptionKeywords')
      saveAi((prev) => ({ ...prev, ai_rewrite_options: data }))
    }
    get_options()
    return () => {
      controller?.abort()
    }
    // eslint-disable-next-line
  }, [])

  const { axiomFetch } = useAxiomFetch()
  const socials = ['facebook', 'linkedin', 'twitter', 'instagram']
  const copy_texts = {
    blog: 'Write me a blog post about my open house',
    letter: 'Write me a letter post about my open house',
    facebook: 'Write me a facebook post about my open house',
    instagram: 'Write me an instagram post about my open house',
    text: 'Write me a text message about my open house',
    email: 'Write me an email about my open house',
  }
  const fix_response = (text, model) => {
    // add spaces after punctuation
    if (text && typeof text === 'string') {
      if (text.startsWith('"')) {
        text = text.slice(1)
      }
      if (text.endsWith('"')) {
        text = text.slice(0, -1)
      }
      if (model === 'text-curie-001') {
        text = text?.replace(/\s*([,.!?:;]+)(?!\s*$)\s*/g, '$1 ')?.trim()
        // remove spaces for money and decimal numbers
        text = text?.replace(/(\d)\s(?=\d)/g, '')
        text = text?.replace(/(\d+)\s?,\s?(\d+)/g, '$1,$2')
      }
      if (
        model !== 'text-curie-001' &&
        ai?.state?.prompt?.toLowerCase()?.includes('list') &&
        !ai?.state?.prompt?.toLowerCase()?.includes('listing')
      ) {
        // add line breaks for a list
        text = text?.replace(/\d+\.\s+/g, '\n\n$&')
      }
      return (
        text?.replace(/(?:[.!?]\s*)(\w)/g, function (match, letter) {
          return match?.toUpperCase()?.trim()
        }) || ''
      )
    } else {
      return text?.trim()
    }
  }

  const ai_response = async ({
    prompt,
    model,
    signal,
    word_count = 100,
    rewrite = false,
  }) => {
    saveAi((prev) => ({ ...prev, state: { ...prev?.state, isLoading: true } }))
    let the_prompts = []

    if (!ai?.state?.prompt && !rewrite) {
      return saveAi((prev) => ({
        ...prev,
        state: {
          ...prev?.state,
          last_prompt: prompt || prev?.state?.prompt,
          isLoading: false,
          run: false,
        },
      }))
    }
    if (!ai?.state?.last_prompt && !rewrite) {
      the_prompts = [
        `make a headline for this prompt: ${ai?.state?.last_prompt || ''} ${
          ai?.state?.prompt
        }`,
        // `write about 350 words for this prompt: ${
        //   ai?.state?.last_prompt || ''
        // } ${ai?.state?.prompt}`,
        `${
          word_count ? `write about ${word_count} words for this prompt: ` : ''
        }${ai?.state?.last_prompt || ''} ${prompt || ai?.state?.prompt}`,
      ]
    } else {
      the_prompts = [
        `${prompt || ai?.state?.prompt}: ${ai?.state?.headline || ''}`,
        // `${prompt || ai?.state?.prompt}: ${ai?.state?.message || ''}`,
        `${prompt || ai?.state?.prompt}: ${ai?.state?.message || ''}`,
      ]
    }

    model = 'gpt-4o'
    // if (!model) {
    //   const check_model =
    //     (await check_prompt(
    //       ai?.state?.prompt || '',
    //       'gpt-3.5-turbo',
    //       ai?.ai_prompt_options || {}
    //     )) || 'gpt-3.5-turbo'
    //   if (check_model === 'text-curie-001') {
    //     model = 'text-curie-001'
    //   } else {
    //     model = check_model
    //   }
    // }
    const all_results_map = the_prompts?.map(async (item) => {
      const body = {
        messages: [
          {
            role: 'user',
            content: item
              .replaceAll('{{MARKET}}', profile?.MARKET || profile?.ST_ABBR)
              .replaceAll(
                '{{MLS_Descriptions}}',
                ai?.current_listing?.public_remarks
              ),
          },
        ],
        model,
      }
      console.log(body)
      return await axiomFetch(
        '/marketing/content/ai/chat',
        'POST',
        body,
        {},
        false,
        signal
      )
    })
    const data = await Promise.all(all_results_map)
    console.log(data)
    const headline = fix_response(data?.[0]?.post, model)
    const long_completion = fix_response(
      data?.[1]?.post || data?.[2]?.post,
      model
    )
    const short_completion = fix_response(
      data?.[2]?.post || data?.[1]?.post,
      model
    )

    const new_limits = await axiomFetch(
      '/marketing/content/limits',
      'GET',
      {},
      {},
      false,
      signal
    )

    let distribute_content = distribute?.content || {}

    for (const content_type of content_types) {
      const get_message = (type) => {
        if (ai?.toggle_content === 'Y') {
          return short_completion
        } else {
          return long_completion
        }
      }
      distribute_content[content_type] = {
        ...distribute_content[content_type],
        message: get_message(content_type),
        long_message: long_completion,
        short_message: short_completion,
        headline: headline,
      }
    }

    saveDistribute((prev) => ({ ...prev, content: distribute_content }))

    if (!signal?.aborted) {
      console.log('saving ai')
      console.log('completion', short_completion)
      saveLimits(new_limits)
      saveAi((prev) => ({
        ...prev,
        state: {
          ...prev?.state,
          message:
            prev?.toggle_content === 'Y' ? short_completion : long_completion,
          long_message: long_completion,
          short_message: short_completion,
          subject: headline,
          original_prompt: prev?.state?.original_prompt
            ? prev?.state?.original_prompt
            : prompt || prev?.state?.prompt,
          last_prompt: prompt || prev?.state?.prompt,
          prompt: '',
          stopHeight: 500 * height_change,
          isLoading: false,
          run: false,
        },
      }))
      if (response_only) {
        saveAi((prev) => ({
          ...prev,
          assistant: {
            ...prev?.assistant,
            is_assistant: 'N',
          },
        }))
        return navigate(ai?.nav_url || '/user/ai/assistant', {
          state: { parent: ai?.parent_url || '' },
        })
      }
    } else {
      saveAi((prev) => ({
        ...prev,
        state: {
          ...prev?.state,
          last_prompt: prompt || prev?.state?.prompt,
          isLoading: false,
          run: false,
        },
      }))
    }

    // setTimeout(() => {
    //   saveAi((prev) => ({
    //     ...prev,
    //     state: {
    //       ...prev?.state,
    //       isLoading: false,
    //       message: !prev?.state?.message
    //         ? 'Ai got confused please try again.'
    //         : prev?.state?.message,
    //       run: false,
    //     },
    //   }))
    // }, [30000])
  }

  const message = (
    <div>
      <p className={`${uiStyles.text_black_subtitle} mb-[10px]`}>
        AXIOM Ai Suggested Content
      </p>
      {/* <div className='flex justify-end mr-[45px] mb-[5px]'>
        <CircleCheckToggle
          name={`toggle_content`}
          type='toggle'
          toggle_left={true}
          //   toggle_color_grad={'from-[#0A66C2] to-[#0A66C2]'}
          //   toggle_color={'text-[#000]'}
          toggle_left_label={'short'}
          toggle_right_label={'long'}
          inputValue={ai}
          handle_change={async (e) => {
            saveAi((prev) => {
              let distribute_content = distribute?.content || {}

              for (const content_type of content_types) {
                distribute_content[content_type] = {
                  ...distribute_content?.[content_type],
                  message:
                    prev?.toggle_content === 'Y'
                      ? prev?.state?.long_message
                      : prev?.state?.short_message,
                  toggle_content: prev?.toggle_content === 'Y' ? 'N' : 'Y',
                }
              }
              saveDistribute((prev) => ({
                ...prev,
                content: distribute_content,
              }))
              return {
                ...prev,
                state: {
                  ...prev?.state,
                  message:
                    prev?.toggle_content === 'Y'
                      ? prev?.state?.long_message
                      : prev?.state?.short_message,
                },
                toggle_content: prev?.toggle_content === 'Y' ? 'N' : 'Y',
              }
            })
          }}
        />
      </div> */}

      {type === 'email' && (
        <div className='mb-[15px]'>
          <label className='capitalize'>subject</label>
          <input
            className={`${uiStyles.input}`}
            value={ai?.state?.subject || ''}
            onChange={(e) => {
              saveAi((prev) => {
                // let distribute_content = distribute?.content || {}

                // for (const content_type of content_types) {
                //   distribute_content[content_type] = {
                //     ...distribute_content?.[content_type],
                //     headline: e.target.value,
                //   }
                // }
                // saveDistribute((prev) => ({
                //   ...prev,
                //   content: distribute_content,
                // }))

                return {
                  ...prev,
                  state: {
                    ...prev?.state,
                    subject: e.target.value,
                  },
                }
              })
            }}
          ></input>
        </div>
      )}
      {show_type_label ? <label className='capitalize'>{type}</label> : ''}
      <textarea
        className={`${uiStyles.text_area} whitespace-pre-wrap `}
        placeholder='Write your message...'
        value={ai?.state?.message || ''}
        style={{ height: `${ai?.state?.stopHeight + 50}px` || '95px' }}
        onChange={(e) => {
          saveAi((prev) => {
            let distribute_content = distribute?.content || {}

            for (const content_type of content_types) {
              distribute_content[content_type] = {
                ...distribute_content?.[content_type],
                message: e.target.value,
                [prev?.toggle_content === 'Y'
                  ? 'short_message'
                  : 'long_message']: e.target.value,
                toggle_content: prev?.toggle_content,
              }
            }
            saveDistribute((prev) => ({
              ...prev,
              content: distribute_content,
            }))
            return {
              ...prev,
              state: {
                ...prev?.state,
                message: e.target.value,
                [prev?.toggle_content === 'Y'
                  ? 'short_message'
                  : 'long_message']: e.target.value,
              },
            }
          })
        }}
      ></textarea>
    </div>
  )

  return (
    <div>
      {limits?.[3]?.limit_reached ? (
        <>
          <Limits />
          {message}
        </>
      ) : (
        <>
          <Ask
            rewrite={rewrite || ai?.state?.message}
            state={ai?.state || {}}
            setState={saveAi}
            ai_response={ai_response}
            copy_text={
              copy_texts[type] ||
              `Write me a ${type ? `${type} ` : ''}post about my open house`
            }
            response_only={response_only}
          />

          {message}
        </>
      )}
    </div>
  )
}
