import React, { useEffect, useState } from 'react'
import AxiomCancelIcon from '../../ui/AxiomCancelIcon'
import AxiomCrossIcon from '../../ui/AxiomCrossIcon'
import AxiomCheckIcon from '../../ui/AxiomCheckIcon'
import AxiomDirectoryIcon from '../../ui/AxiomDirectoryIcon'
import AxiomAlertIcon from '../../ui/AxiomAlertIcon'
import SwipeConfig from '../../ui/SwipeConfig'
import LeadItem from '../../ui/LeadItem'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { unparse } from 'papaparse'
import { useProfile } from '../../../contexts/UserContext'

import {
  HiEnvelopeOpen,
  // HiOutlineChatBubbleLeft,
  // HiOutlineEnvelope,
  // HiOutlinePhoneArrowUpRight,
  HiDocumentArrowDown,
  HiCheckCircle,
} from 'react-icons/hi2'
import { useDefaults } from '../../../contexts/DefaultsContext'
import images_homes from '../../../assets/images/home'
import { useClaims } from '../../../contexts/ClaimsContext'

const ConfigIcon = ({ icon, text, clickHandler }) => {
  return (
    <span
      onClick={clickHandler}
      className='flex min-w-[38px] font-light flex-col mx-1 text-[6px] text-center items-center justify-center leading-3 hover:text-accent-100'
    >
      {text}
      {icon}
    </span>
  )
}

export default function Leadsdata({
  cancelled,
  expired,
  active,
  contract,
  foreclosure,
  hardMoneyLenders,
  nod,
  fsbo,
  setLeads,
  open,
  setShowModal = () => {},
  setModalType = () => {},
  startDate,
  endDate,
}) {
  const { profile } = useProfile()
  const { claims } = useClaims()
  const { axiomFetch } = useAxiomFetch()
  const [selected, setSelected] = useState(1)
  const [emailSent, setEmailSent] = useState({})
  const [downloadSent, setDownloadSent] = useState({})
  const { defaults } = useDefaults()
  const [startDays, setStartDays] = useState({
    expired: defaults?.expired || 30,
    cancelled: defaults?.cancelled || 30,
    active: defaults?.active || 2,
    contract: defaults?.contract || 2,
    nod: defaults?.nod || 7,
    open: 0,
    foreclosure: 0,
    fsbo: defaults?.fsbo || 7,
  })
  const [endDays, setEndDays] = useState({
    expired: 1,
    cancelled: 1,
    nod: 1,
    active: 1,
    contract: 1,
    open: defaults?.open || 3,
    foreclosure: defaults?.foreclosure || 7,
    fsbo: 1,
  })

  const handle_download = async (item) => {
    try {
      if (downloadSent[item.key]) {
        return
      }
      setDownloadSent((prev) => ({
        ...prev,
        [item.key]: true,
      }))
      const body = {
        startDays: startDays?.[item?.key] || 0,
        endDays: endDays?.[item?.key] || 1,
        type: item?.key,
      }
      const data = await axiomFetch('/client/leads/leadsDownload', 'POST', body)
      setDownloadSent((prev) => ({
        ...prev,
        [item.key]: false,
      }))
      console.log(data)
      const text = unparse(data)
      let blob = new Blob([text], { type: 'text/csv;charset=utf-8;' })
      let link = document.createElement('a')
      link.setAttribute('href', `${URL.createObjectURL(blob)}`)
      link.setAttribute('download', `${item.title}.csv`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      console.log('error')
      console.log(error)
    }
  }

  const leadsData = [
    {
      key: 'cancelled',
      title: 'Cancelled List',
      label: 'Days Back',
      description: 'Cancelled List',
      number: cancelled,
      icon: <AxiomCancelIcon />,
    },
    {
      key: 'expired',
      title: 'Expired Listings',
      label: 'Days Back',
      description: 'Expired Listings',
      number: expired,
      icon: <AxiomCrossIcon />,
    },
    {
      key: 'active',
      title: 'New Listings',
      label: 'Days Back',
      description: 'New Listings',
      // internal_only: true,
      number: active,
      icon: <AxiomCheckIcon color='#3778F3' />,
    },
    {
      key: 'contract',
      title: 'Under Contract',
      label: 'Days Back',
      // internal_only: true,
      description: 'Under Contract',
      number: contract,
      icon: <AxiomCheckIcon color={'gray'} />,
    },

    {
      key: 'foreclosure',
      title: 'Foreclosure',
      label: 'Days Ahead',
      go_forward: true,
      description: 'Foreclosure',
      number: foreclosure,
      icon: <AxiomCrossIcon color={'black'} />,
    },
    {
      key: 'open',
      title: 'Open Houses',
      label: 'Days Ahead',
      go_forward: true,
      // internal_only: true,
      description: 'Open Houses',
      number: open,
      icon: (
        <div className='w-[31px]'>
          <img src={images_homes.home_icon_black} />
        </div>
      ),
    },

    // {
    //   title: 'Hard Money Lenders',
    //   description: 'Hard Money Lenders',
    //   number: hardMoneyLendersTotal,
    //   icon: <AxiomDirectoryIcon />,
    // },
    {
      key: 'nod',
      title: 'NOD',
      description: 'Notice of Default',
      number: nod,
      label: 'Days Back',
      icon: <AxiomAlertIcon />,
    },
    {
      key: 'fsbo',
      title: "FSBO's",
      label: 'Days Back',
      description: 'For Sale By Owner',
      number: fsbo,
      icon: <AxiomDirectoryIcon />,
    },
  ]
  const rightConfig = (item) => () =>
    (
      <>
        {/* <ConfigIcon
          text="Email List"
          icon={<HiOutlineEnvelope className="text-2xl" />}
          clickHandler={() => {
            setShowModal(true);
            setModalType('email');
          }}
        />
        <ConfigIcon
          text="Text List"
          icon={<HiOutlineChatBubbleLeft className="text-2xl" />}
          clickHandler={() => {
            setShowModal(true);
            setModalType('textMessage');
          }}
        />
        <ConfigIcon
          text="SkyDial List"
          icon={<HiOutlinePhoneArrowUpRight className="text-2xl" />}
          clickHandler={() => {
            setShowModal(true);
            setModalType('slyDial');
          }}
        /> */}
        <ConfigIcon
          text='Download'
          icon={
            downloadSent[item.key] ? (
              <HiCheckCircle className='text-2xl cursor-not-allowed' />
            ) : (
              <HiDocumentArrowDown className='text-2xl' />
            )
          }
          clickHandler={() => {
            handle_download(item)
          }}
        />
        <ConfigIcon
          text='Send to Myself'
          icon={
            emailSent[item.key] ? (
              <HiCheckCircle className='text-2xl cursor-not-allowed' />
            ) : (
              <HiEnvelopeOpen className='text-2xl cursor-pointer' />
            )
          }
          clickHandler={async () => {
            try {
              if (emailSent[item.key]) {
                return
              }
              setEmailSent((prev) => ({
                ...prev,
                [item.key]: true,
              }))
              const data = await axiomFetch(
                '/client/leads/emailLeadsList',
                'POST',
                {
                  startDays: startDays?.[item?.key] || 0,
                  endDays: endDays?.[item?.key] || 1,
                  type: item?.key,
                  email: profile?.CONT_EMAIL,
                }
              )
              setEmailSent((prev) => ({
                ...prev,
                [item.key]: false,
              }))
              console.log(data)
            } catch (error) {
              console.log('error')
              console.log(error)
            }
          }}
        />
      </>
    )
  return leadsData
    .sort((a, b) => a.key.localeCompare(b.key))
    .filter((item) => {
      if (!claims?.user_id && item?.internal_only) {
        return false
      } else {
        return true
      }
    })
    .map((item, index) => (
      <SwipeConfig
        key={index}
        bg={index % 2 ? 'bg-white' : 'bg-gray-100'}
        index={index}
        selectedIndex={selected}
        setSelectedIndex={setSelected}
        widthConfigRight={44}
        RightComponents={rightConfig(item)}
      >
        <LeadItem
          item={item}
          startDays={startDays}
          setStartDays={setStartDays}
          endDays={endDays}
          setEndDays={setEndDays}
          setLeads={setLeads}
          showInput={true}
          index={index}
        />
      </SwipeConfig>
    ))
}
