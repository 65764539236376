import React from 'react'
import Wrapper from '../../components/layout/Wrapper'
import MarketingAddData from '../../components/pages/marketingMaterials/MarketingAddData'
// import MarketingMaterial from '../../components/pages/marketingMaterials/MarketingMaterial'
import SubNav from '../../components/SubNav'
import { useMktReqs } from '../../contexts/MktReqsContext'
export default function PageMarketingMaterialAddData() {
  const { mktReqs, saveMktReqs } = useMktReqs()
  return (
    <>
      <Wrapper>
        <SubNav url={mktReqs?.home_url || '/user/marketing-lobby'} />
        <MarketingAddData
          state={mktReqs}
          setState={saveMktReqs}
          title={'Configure Market Stats'}
        />
        {/* <MarketingMaterial /> */}
      </Wrapper>
    </>
  )
}
