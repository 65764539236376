import React from 'react'
import Wrapper from '../../components/layout/Wrapper'
import { Conversations } from '../../components/pages/conversations'
import SubNav from '../../components/SubNav'
export default function PageConversations() {
  return (
    <Wrapper>
      <Conversations />
    </Wrapper>
  )
}
