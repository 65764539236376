import {React, useState, forwardRef} from 'react'
import uiStyles from '../../ui/uiStyles'
import {AiFillDelete} from 'react-icons/ai'
import { formatPhoneNumber } from '../../../helpers'
const Member = forwardRef(({member}, ref) => {
    const [mem, setMem] = useState(member)
  return (
    <>
    <div className='flex gap-[10px] items-center bg-gray-100 w-[305px] py-3 rounded shadow-md'>
    <div ref={ref} className='flex flex-col gap-2 w-full'>
        <label className="block capitalize relative w-[40px] px-3">
        Name
        <input 
        type="hidden" 
        value={mem.TEAM_ID}/>
        <input 
        placeholder='name'
        className={`${uiStyles.input} w-[180px] text-xs`}
        value={mem.NAME || ''} 
        onChange={(e) => {
            member.updated = true
            member.NAME = e.target.value
            setMem({...mem, NAME: e.target.value})
        }} />
        </label>
        <label className="block capitalize relative w-[40px] px-3">
            Cell
        <input 
        placeholder="cell number" 
        className={`${uiStyles.input} w-[180px] text-xs`}
        value={formatPhoneNumber(mem.PHONE_NUMBER) || ''} 
        onChange={(e) => {
            member.updated = true
            let new_number = e.target.value?.replace('+1', '')?.replace(/\D/g, '')?.slice(0, 10)
            member.PHONE_NUMBER = new_number
            setMem({...mem, PHONE_NUMBER: e.target.value})
        }}/>
        </label>
        </div>
        <div className='flex flex-col gap-3 items-center w-full'>
        <div>
         <label className="block capitalize relative w-[40px]">
              <input
                className="absolute left-0 right-0 top-0 h-full opacity-0"
                type="checkbox"
                name={mem.NAME}
                value={mem.STATUS}
                checked={mem.STATUS === 'active' ? false : true}
                onChange={(e) => {
                    console.log(e.target.checked)
                if (e.target.checked) {
                    member.updated = true
                    member.STATUS = 'inactive'
                setMem({...mem, STATUS: 'inactive'})
                } else {
                    member.updated = true
                    member.STATUS = 'active'
                    setMem({...mem, STATUS: 'active'})
                }
                }}
              />
              <div className={`transition-all delay-100 toggle inline-flex w-[36px] h-[20px] rounded-2xl bg-gradient-to-b ${mem.STATUS === 'active' ? 'from-accent-150 to-accent-250' : 'from-gray-200 to-gray-200'} p-[2px] mr-2 align-middle`}>
                <span className={`bg-white handle transition-all delay-100  w-[16px] h-[16px] rounded-full inline-block  ${mem.STATUS === 'active' && 'transform translate-x-4'}`}></span>
              </div>
              {mem.STATUS}
            </label>
            </div>
        <button 
        className={`cursor-pointer w-[50px]`}
        onClick={() => {
            
            if (member.STATUS === 'deleted') {
                member.STATUS = 'active'
                member.updated = true
                setMem({...mem, STATUS: 'active'})
            } else {
            member.STATUS = 'deleted'
            member.updated = true
            setMem({...mem, STATUS: 'deleted'})
            }
        }}>{member.STATUS === 'deleted' ? <AiFillDelete  size={25} color={'red'}/> : <AiFillDelete  size={25}/>}</button>
    
    </div>
    
    </div>
    </>
    
            )
})

export default Member