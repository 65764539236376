import React, { memo } from 'react';

function Badge({ children, className = '' }) {
  return (
    <span
      className={` mx-2 inline-block align-middle  text-xs font-normal ${
        !className ? 'bg-[#E4E4EB]' : className
      } py-1 px-2 rounded-xl`}
    >
      {children}
    </span>
  );
}

export default memo(Badge);
