import React from 'react'
import {
  IoMdArrowBack,
  IoMdArrowDown,
  IoMdArrowForward,
  IoMdArrowUp,
} from 'react-icons/io'
import {
  validate_number,
  show_decimal,
  show_decimal_value,
  getDateString,
} from '../../helpers'
import uiStyles from '../ui/uiStyles'

const BrokerComList = ({ title, data, type }) => {
  const grid_css = 'grid grid-cols-[3fr_2fr_1fr_2fr] text-center'
  return (
    <div>
      <h1 className={`${uiStyles.text_black_subtitle} px-3`}>{title}</h1>
      <div>
        <div className={`${grid_css} items-end text-black`}>
          <p>% Co-Broke offered</p>
          <p># of Listings</p>
          <p className='text-xs'>Change from last month</p>
          <p>% of listings</p>
        </div>
        <div
          className={`border-[1px] shadow-md  ${
            type === 'close'
              ? 'border-black bg-thirdary text-white'
              : 'border-thirdary'
          }`}
        >
          {data?.map((item, index, arr) => {
            const p_style = `border-[1px] ${
              type === 'close' ? 'border-black' : 'border-thirdary text-black'
            }`
            const change =
              validate_number(item?.[`${type}_prev_30`]) >
              validate_number(item?.[`${type}_last_30`])
            const neutral =
              validate_number(item?.[`${type}_prev_30`]) ===
              validate_number(item?.[`${type}_last_30`])
            return (
              <div className={`${grid_css}`}>
                <div className={p_style}>
                  {validate_number(item?.pct_high) < 1
                    ? 0
                    : validate_number(item?.pct_high)}
                </div>
                <div className={p_style}>
                  {validate_number(item?.[`${type}_prev_30`])}
                </div>
                <div className={p_style}>
                  {neutral ? (
                    <div className='flex justify-center items-center'>
                      <IoMdArrowForward
                        color={type === 'close' ? 'blue' : 'blue'}
                        size={18}
                      />
                    </div>
                  ) : change ? (
                    <div className='flex justify-center items-center'>
                      <IoMdArrowUp
                        color={type === 'close' ? 'lightgreen' : 'green'}
                        size={18}
                      />
                    </div>
                  ) : (
                    <div className='flex justify-center items-center'>
                      <IoMdArrowDown
                        color={type === 'close' ? 'red' : 'red'}
                        size={18}
                      />
                    </div>
                  )}
                </div>
                <div className={p_style}>
                  {show_decimal_value(
                    validate_number(
                      (validate_number(item?.[`${type}_prev_30`]) /
                        validate_number(
                          arr?.reduce((curr, val) => {
                            return curr + val?.[`${type}_prev_30`]
                          }, 0)
                        )) *
                        100,
                      2
                    )
                  )}
                  %
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <p className='text-center text-sm'>
        based on MLS data as of {getDateString({ format: 'mm/dd/yy' })}.
      </p>
      <p className='text-center text-sm'>
        % co-broke offered rounded to the next 0.5%.
      </p>
    </div>
  )
}

export default BrokerComList
