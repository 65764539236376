import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { useReceiver } from '../../contexts/ReceiverContext'
import { get_storage } from '../../helpers'
import Wrapper from '../layout/Wrapper'
import SubNav from '../SubNav'

const CalcWrapper = () => {
  const { pathname, state } = useLocation()
  const { receiver } = useReceiver()
  console.log('wrapper_receiver', receiver)
  console.log('path', pathname)
  const is_vbc = get_storage('vbc_calc') === 'Y'
  const is_home_view = get_storage('home_view_calc')
  const get_url = () => {
    console.log('home_view', is_home_view)
    if (
      receiver?.edit_vbcid &&
      receiver.edit_mode === 'view' &&
      pathname === '/user/calculators'
    ) {
      return `/user/my-vbc/${receiver?.edit_vbcid}`
    } else if (
      is_home_view === 'agent_tour_view' ||
      is_home_view === 'tour_view'
    ) {
      return '/user/listing_tours'
    } else if (is_home_view === 'vbc_view' || is_home_view === 'agent_view') {
      return '/homes/view'
    } else if (is_vbc) {
      return '/card'
    } else if (pathname !== '/user/calculators') {
      return '/user/calculators'
    } else {
      return '/user'
    }
  }
  return (
    <Wrapper>
      <SubNav
        url={get_url()}
        replace_url={is_home_view ? true : false}
        showConversations={is_vbc ? false : true}
        showTours={is_vbc ? false : true}
        logo_url={is_vbc || is_home_view === 'vbc_view' ? '/card' : '/user'}
      />
      <Outlet />
    </Wrapper>
  )
}

export default CalcWrapper
