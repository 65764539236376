import { useState } from 'react'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import uiStyles from '../../ui/uiStyles'
export const UpdateCard = ({ ssl_token, setShowModal, setRefresh }) => {
  console.log(ssl_token)
  const [loading, setLoading] = useState(false)
  const { axiomFetch } = useAxiomFetch()
  return (
    <div className='flex flex-col items-center gap-[10px]'>
      {/* <div className={uiStyles.text_black_subtitle}>Update Card</div> */}
      <div className='flex gap-[10px]'>
        <button
          className={uiStyles.white_btn}
          onClick={() => {
            setShowModal(false)
          }}
        >
          Cancel
        </button>
        {loading ? (
          <SmallLoadingRel />
        ) : (
          <button
            className={uiStyles.red_btn}
            onClick={async () => {
              setLoading(true)
              await axiomFetch('/merchant/card_token_delete', 'POST', {
                ssl_token,
              })
              setLoading(false)
              setRefresh((prev) => prev + 1)
              setShowModal(false)
            }}
          >
            Delete Card
          </button>
        )}
      </div>
    </div>
  )
}
