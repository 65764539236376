import { useEffect, memo, useRef } from 'react'
import { useProfile } from '../../contexts/UserContext'
import { useAuth0 } from '@auth0/auth0-react'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import { useLocation } from 'react-router-dom'
const Usage = memo(({ modalName, pageTab }) => {
  const { axiomFetch } = useAxiomFetch()
  const { profile, saveProfile } = useProfile()
  const { user } = useAuth0()
  const location = useLocation()
  const locationRef = useRef({})

  useEffect(() => {
    const controller = new AbortController()

    if (
      locationRef?.current?.pathname === location?.pathname &&
      locationRef?.current?.pageTab === pageTab &&
      locationRef?.current?.modalName === modalName
    ) {
      return
    }
    locationRef.current.pathname = location.pathname
    locationRef.current.pageTab = pageTab
    locationRef.current.modalName = modalName

    const page_hit = async () => {
      const body = {
        url: window.location.href,
        url_path: window.location.pathname,
        cont_name: profile?.CONT_NAME || '',
        cont_cell_phone: profile?.CONT_CELL_NUM || '',
        login_cell_phone:
          user?.name?.replace('+1', '') ||
          user?.nickname?.replace('+1', '') ||
          '',
        modal_path: modalName || null,
        tab_path: pageTab || null,
      }

      if (body.login_cell_phone && body.cont_cell_phone) {
        await axiomFetch(
          '/usage/pageHit',
          'POST',
          body,
          {},
          false,
          controller?.signal
        )
      }
    }

    page_hit()
    return () => {
      controller?.abort()
    }
  }, [pageTab, modalName, window.location.pathname])
  return <div></div>
})

export default Usage
