import React from 'react'
import { useSales } from '../../contexts/SalesContext'

const AtonNeedsHelp = ({ setShowModal }) => {
  const { sales, saveSales } = useSales()
  const get_aton_item = () => {
    return sales?.curr_client?.aton_curr_item
  }
  return <div>AtonNeedsHelp</div>
}

export default AtonNeedsHelp
