import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

export const TotalsContext = createContext()

export const TotalsProvider = ({ children, userData }) => {
  const [totals, setTotals] = useState(null)

  const saveTotals = useCallback((data) => {
    setTotals(data)
  }, [])

  const value = useMemo(
    () => ({
      totals,
      saveTotals,
    }),
    [totals, saveTotals]
  )

  return (
    <TotalsContext.Provider value={value}>{children}</TotalsContext.Provider>
  )
}

export const useTotals = () => {
  const context = useContext(TotalsContext)
  if (!context) {
    throw Error('AuthContext must be used inside an AuthContextProvider')
  }

  return context
}
