import { useState } from 'react'
import New from '../../assets/images/labelnew.png'
import googleLoc from '../../assets/images/google_location.png'
import { useReceiver } from '../../contexts/ReceiverContext'
import { useNavigate } from 'react-router-dom'
import images_homes from '../../assets/images/home'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import { FcNext } from 'react-icons/fc'
import { GrNext, GrPrevious } from 'react-icons/gr'
import home_placeholder from '../../assets/images/Home_stock.jpeg'
import {
  date_within_days,
  format_currency,
  just_listed_days,
  just_sold_days,
  listing_status,
  set_storage,
  under_contract_days,
} from '../../helpers'
import Modal from '../Modal'
import { FilterList } from '../helpers'
import { MdAdsClick } from 'react-icons/md'
import { useMktReqs } from '../../contexts/MktReqsContext'

export default function PropertyItem({
  listing = {},
  image_link,
  click_me = false,
  thumbnail,
  title,
  price,
  features = [],
  isNew = false,
  location = [],
  show_favorite = false,
  favorited = false,
  item = {},
  image_flag = '',
  swipe_button_next = false,
  swipe_button_prev = false,
  filter_type,
  show_filter = false,
  filter_list = [],
  filter_strategy = {},
  handle_swipe_next = () => {},
  handle_swipe_prev = () => {},
  my_listings_look = false,
}) {
  const { receiver, saveReceiver } = useReceiver()
  const { mktReqs, saveMktReqs } = useMktReqs()
  const get_status = (prop) => {
    let status = listing_status?.[prop?.mls_status]?.name
    if (
      date_within_days(prop?.close_date, just_sold_days)
      // ||
      // date_within_days(
      //   prop?.active_under_contr_timestamp,
      //   under_contract_days
      // ) ||
      // date_within_days(prop?.list_date, just_listed_days)
    ) {
      return (status = `Just ${status.replaceAll('Just', '')}`)
    }
    if (prop?.upcoming_open_house > 0) {
      return 'Open House'
    }
    if (
      prop?.original_list_price > prop?.list_price &&
      listing?.mls_status !== 'Closed'
    ) {
      return 'Price Reduced'
    }
    if (
      date_within_days(prop?.list_date, just_listed_days) &&
      prop?.mls_status === 'Active'
    ) {
      return 'Just Listed'
    }
    return status
  }
  const get_color = (prop) => {
    // if (date_within_days(prop?.list_date, just_listed_days)) {
    //   return 'bg-purple-500'
    // }
    return listing_status?.[prop?.mls_status]?.color || 'bg-blue-500'
  }

  const get_street_add = (prop, type = 'all') => {
    let street_add = ''
    if (type === 'all') {
      street_add = `${prop?.prop_add}, ` || ''
    }
    if (type === '1') {
      street_add = `${prop?.prop_add}` || ''
    }
    if (type === 'all' || type === '2') {
      street_add += `${prop?.prop_city}` || ''
      street_add += `, ${prop?.prop_st} ` || ''
      street_add += prop?.prop_zip || prop?.zip_code || ''
    }
    return street_add
  }

  const get_street_1 = (prop) => {
    return get_street_add(prop, '1')
  }

  const get_street_2 = (prop) => {
    return get_street_add(prop, '2')
  }

  const get_prop_features = (prop) => {
    const defaults = [
      prop?.beds ? `${prop?.beds} Bed` : '',
      prop?.baths ? `${prop?.baths} Bath` : '',
      prop?.sqft ? `${prop?.sqft} Sq Ft` : '',
    ]
    return [
      // my_listings_look && prop?.prop_add ? get_street_add(prop) : '',
      // !my_listings_look && prop?.mls_id ? `MLS #: ${prop?.mls_id}` : '',
      ...defaults,
    ]?.filter((item) => item)
  }
  const navigate = useNavigate()
  const { axiomFetch } = useAxiomFetch()

  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('')

  const modals = {
    material_filter: {
      component: (
        <FilterList
          showModal={showModal}
          setShowModal={setShowModal}
          list={filter_list}
          filter_strategy={filter_strategy}
          current_item={listing}
          property_component={true}
          my_listings_look={true}
        />
      ),
      width: 'w-[340px]',
      // height: 'h-[550px]',
    },
  }

  return (
    <>
      <div
        className={`relative swipeable flex bg-gray-100 ${
          my_listings_look ? ' pt-2 pb-1 pl-3' : 'p-2'
        }`}
      >
        {swipe_button_prev ? (
          <div
            className='absolute z-10 cursor-pointer swipeable self-center pt-[7px]'
            onClick={() => {
              handle_swipe_prev()
            }}
          >
            <GrPrevious size={25} />
          </div>
        ) : (
          ''
        )}

        <div
          className={`swipeable ${
            my_listings_look ? 'w-[130px]' : 'w-[122px]'
          } thumbnail relative ${
            image_link || listing?.click_me || click_me ? 'cursor-pointer' : ''
          }`}
        >
          <img
            src={listing?.home_photo1 || thumbnail || home_placeholder}
            onClick={() => {
              if (listing?.image_link) {
                listing?.image_link(listing)
                setShowModal(false)
                return
              }
              if (image_link) {
                image_link()
              }
            }}
            alt=''
            className={`swipeable rounded-md ${
              my_listings_look ? 'w-[130px]' : 'w-[122px]'
            } h-[90px] object-cover`}
          />
          {click_me || listing?.click_me ? (
            <div className='swipeable text-thirdary absolute z-20 top-0 right-0 mt-[-5px] mr-[-5px]'>
              {<MdAdsClick />}
            </div>
          ) : (
            ''
          )}
          {isNew && (
            <div className='swipeable absolute left-1/2 top-0 -translate-x-1/2'>
              <img src={New} alt='' />
            </div>
          )}
          {!isNew && (image_flag || listing?.mls_status) ? (
            <div
              className={`w-[100px] text-center swipeable absolute left-1/2 top-0 -translate-x-1/2 text-white ${
                get_color(listing) || 'bg-blue-500'
              } py-[2px] rounded-b-lg text-[12px]`}
            >
              {get_status(listing) || image_flag}
            </div>
          ) : (
            ''
          )}
          {/* {listing?.upcoming_open_house ? (
            <div
              className={` text-center swipeable absolute ${
                listing?.original_list_price > listing?.list_price
                  ? 'right-[30px]'
                  : 'right-[5px]'
              } bottom-[28px] -translate-x-0 rounded-full p-[2px] text-white text-[12px]
              ${get_color(listing) || 'bg-blue-500'}`}
            >
              <img src={images_homes.home_icon} className='w-[20px]' />
            </div>
          ) : (
            ''
          )}
          {listing?.original_list_price > listing?.list_price &&
          listing?.mls_status !== 'Closed' ? (
            <div
              className={` text-center swipeable absolute right-[5px] bottom-[28px] -translate-x-0 rounded-full p-[2px] text-white text-[12px]
              ${get_color(listing) || 'bg-blue-500'}`}
            >
              <img src={images_homes.sale_tag_white} className='w-[20px]' />
            </div>
          ) : (
            ''
          )} */}
        </div>

        <div className='swipeable flex-1 pl-2'>
          <div className='swipeable text-primary text-xs font-semibold mb-1'>
            {my_listings_look ? (
              'My Listings'
            ) : (
              <div className='swipeable'>
                <p className='swipeable w-[190px] scrollbar-hide overflow-x-scroll whitespace-nowrap'>
                  {get_street_1(listing)}{' '}
                </p>
                <p className='swipeable w-[190px] scrollbar-hide overflow-x-scroll whitespace-nowrap'>
                  {get_street_2(listing)}
                </p>
              </div>
            )}
          </div>

          <div
            className={`swipeable text-xs ${
              my_listings_look ? 'font-light' : 'text-primary'
            } leading-3 mb-1`}
          >
            {my_listings_look ? (
              <div className='swipeable font-light text-thirdary'>
                <p className='swipeable w-[190px] scrollbar-hide overflow-x-scroll whitespace-nowrap'>
                  {get_street_1(listing)}{' '}
                </p>
                <p className='swipeable w-[190px] scrollbar-hide overflow-x-scroll whitespace-nowrap'>
                  {get_street_2(listing)}
                </p>
              </div>
            ) : (
              ''
            )}
          </div>
          <div className='swipeable flex'>
            <div className='swipeable flex-1 text-thirdary'>
              {(get_prop_features(listing) || features || [])?.map(
                (item, index) => (
                  <p
                    key={index}
                    className='swipeable font-light text-xs leading-3'
                  >
                    {item}
                  </p>
                )
              )}
            </div>

            <div
              className={`swipeable flex flex-col flex-1 ${
                show_filter ? 'mr-[20px]' : ''
              }`}
            >
              <div className='flex justify-around items-baseline mb-[4px]'>
                {((location && location.length === 2) ||
                  (listing?.lng && listing?.lat)) && (
                  <img
                    onClick={() => {
                      saveReceiver((prev) => ({
                        ...prev,
                        homes: {
                          ...prev?.homes,
                          current_home: listing,
                        },
                      }))
                      navigate('/homes/places')
                    }}
                    draggable='false'
                    src={googleLoc}
                    alt=''
                    className={`${
                      my_listings_look ? 'w-[19px]' : 'mb-1 w-[18px]'
                    } flex cursor-pointer`}
                  />
                )}
                {show_favorite ? (
                  <div
                    className='mt-[5px] cursor-pointer w-[25px]'
                    onClick={async () => {
                      const favorited_homes =
                        receiver?.homes?.favorited_homes?.filter(
                          (item) => item?.favorited === 'Y'
                        )
                      let new_homes = []
                      if (listing?.favorited === 'Y') {
                        listing.favorited = 'N'
                        new_homes = favorited_homes?.filter(
                          (item) => item.mls_id !== listing.mls_id
                        )
                      } else {
                        listing.favorited = 'Y'
                        new_homes = [...(favorited_homes || []), listing]
                      }

                      saveReceiver((prev) => ({
                        ...prev,
                        homes: {
                          ...prev?.homes,
                          favorited_homes: new_homes,
                        },
                      }))

                      set_storage('favorited_homes', new_homes)
                      await axiomFetch('/marketing/homes/favorite', 'POST', {
                        mls_id: listing?.mls_id,
                        favorited: listing?.favorited,
                      })
                    }}
                  >
                    {favorited ? (
                      <img src={images_homes.heart_filled} />
                    ) : (
                      <img src={images_homes.heart} />
                    )}
                  </div>
                ) : (
                  ''
                )}
              </div>
              {(price || listing?.close_price || listing?.list_price) && (
                <div className='swipeable price text-xs text-primary font-semibold text-center'>
                  $
                  {format_currency(
                    listing?.close_price || listing?.list_price || price
                  )}
                </div>
              )}
            </div>
            {show_filter ? (
              <div
                className={`cursor-pointer absolute right-[10px] ${
                  my_listings_look ? 'bottom-[7px]' : 'bottom-[10px]'
                }`}
              >
                <div
                  onClick={() => {
                    saveMktReqs((prev) => ({
                      ...prev,
                      show_property_filter: true,
                    }))
                    saveReceiver((prev) => ({
                      ...prev,
                      show_property_filter: true,
                    }))
                    setModalType(filter_type)
                  }}
                >
                  <img src={images_homes.filter} className={`w-[20px]`} />
                </div>
              </div>
            ) : (
              ''
            )}
            {swipe_button_next ? (
              <div
                className='cursor-pointer swipeable'
                onClick={() => {
                  handle_swipe_next()
                }}
              >
                <GrNext size={25} color={'black'} />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      {(showModal ||
        mktReqs?.show_property_filter ||
        receiver?.show_property_filter) &&
      listing?.armls_id ===
        (mktReqs?.current_listing?.armls_id ||
          receiver?.current_listing?.armls_id) ? (
        <Modal
          side_padding={'px-0'}
          modalName={modalType || 'listing_filter'}
          height={modals?.[modalType]?.height || 'h-[550px]'}
          width={modals?.[modalType]?.width || 'w-full'}
          showModal={
            showModal ||
            mktReqs?.show_property_filter ||
            receiver?.show_property_filter
          }
          setShowModal={() => {
            saveMktReqs((prev) => ({ ...prev, show_property_filter: false }))
            saveReceiver((prev) => ({ ...prev, show_property_filter: false }))
            setShowModal(false)
          }}
          showClose={true}
        >
          {modals?.[modalType || 'material_filter']?.component || <></>}
        </Modal>
      ) : (
        ''
      )}
    </>
  )
}
