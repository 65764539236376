import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { set_storage } from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import uiStyles from '../../ui/uiStyles'

const CompleteTour = ({ state, setState, setShowModal, setItineraryModal }) => {
  const [loading, setLoading] = useState(false)
  const { axiomFetch } = useAxiomFetch()
  const navigate = useNavigate()
  return (
    <div>
      <p>
        Are you sure you want to{' '}
        {state?.tour_state?.completed ? 're-activate' : 'complete'} the tour?
      </p>
      {!loading ? (
        <div className='flex justify-center gap-[10px]'>
          <button
            onClick={() => {
              setShowModal(false)
            }}
            className={uiStyles.white_btn}
          >
            Cancel
          </button>
          <button
            onClick={async () => {
              setLoading(true)
              await axiomFetch('/tours/complete', 'POST', {
                event_id: state?.tour_state?.event_id,
                completed: state?.tour_state?.completed ? 0 : 1,
              })
              setLoading(false)
              if (state?.tour_state?.completed) {
                setState((prev) => {
                  const new_tour_state = { ...prev?.tour_state, completed: 0 }
                  set_storage('listing_tours_state', new_tour_state)
                  return {
                    ...prev,
                    tour_state: new_tour_state,
                  }
                })
                setShowModal(false)
                setItineraryModal(false)
                return navigate('/user/listing_tours')
              }
              setState((prev) => {
                const new_tour_state = { ...prev?.tour_state, completed: 1 }
                set_storage('listing_tours_state', new_tour_state)
                return {
                  ...prev,
                  tour_state: new_tour_state,
                }
              })
              setShowModal(false)
              setItineraryModal(false)
              navigate('/user/listing_tours')
            }}
            className={uiStyles.hover_btn}
          >
            {state?.tour_state?.completed ? 'Re-activate' : 'Complete'}
          </button>
        </div>
      ) : (
        <SmallLoadingRel />
      )}
    </div>
  )
}

export default CompleteTour
