import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

export const ListingsSoldContext = createContext()

export const ListingsSoldProvider = ({ children, userData }) => {
  const [listingsSold, setListingsSold] = useState(null)

  const saveListingsSold = useCallback((data) => {
    setListingsSold(data)
  }, [])

  const getListingByID = useCallback(
    (id) => {
      if (!listingsSold) {
        return null
      }
      return listingsSold.find((item, index) => {
        return +item.file_id === +id
      })
    },

    [listingsSold]
  )

  const getListingByMLS = useCallback(
    (mls_id) => {
      if (!listingsSold) {
        return null
      }
      return listingsSold.find((item, index) => {
        return item?.MLS_ID === mls_id
      })
    },

    [listingsSold]
  )

  const value = useMemo(
    () => ({
      listingsSold,
      saveListingsSold,
      getListingByMLS,
      getListingByID,
    }),
    [listingsSold, saveListingsSold, getListingByID, getListingByMLS]
  )

  return (
    <ListingsSoldContext.Provider value={value}>
      {children}
    </ListingsSoldContext.Provider>
  )
}

export const useListingsSold = () => {
  const context = useContext(ListingsSoldContext)

  if (!context) {
    throw Error('ListingContext must be used inside an ListingContextProvider')
  }

  return context
}
