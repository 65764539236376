import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  useRef,
} from 'react'

export const ConversationsContext = createContext()

export const ConversationsProvider = ({ children }) => {
  const [loadingConversations, setLoadingConversations] = useState(true)
  const [conversations, setConversations] = useState([])
  const [campaigns, setCampaigns] = useState({})
  const [convMessages, setConvMessages] = useState({})
  const [token, setToken] = useState(null)
  const saveConversations = useCallback((data) => {
    setConversations(data)
  }, [])
  const saveCampaigns = useCallback((data) => {
    setCampaigns(data)
  }, [])
  const saveToken = useCallback((data) => {
    setToken(data)
  }, [])
  const saveConvMessages = useCallback((data) => {
    setConvMessages(data)
  }, [])

  const saveLoadingConversations = useCallback((data) => {
    setLoadingConversations(data)
  }, [])

  const value = useMemo(
    () => ({
      conversations,
      saveConversations,
      campaigns,
      saveCampaigns,
      token,
      saveToken,
      convMessages,
      saveConvMessages,
      loadingConversations,
      saveLoadingConversations,
    }),
    [
      conversations,
      saveConversations,
      token,
      saveToken,
      convMessages,
      saveConvMessages,
      campaigns,
      saveCampaigns,
      loadingConversations,
      saveLoadingConversations,
    ]
  )

  return (
    <ConversationsContext.Provider value={value}>
      {children}
    </ConversationsContext.Provider>
  )
}

export const useConversations = () => {
  const context = useContext(ConversationsContext)

  if (!context) {
    throw Error('AuthContext must be used inside an AuthContextProvider')
  }

  return context
}
