import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  HiPhone,
  HiChatBubbleBottomCenterText,
  HiEnvelope,
} from 'react-icons/hi2'
import { BsBoxArrowRight } from 'react-icons/bs'
import { useAuth0 } from '@auth0/auth0-react'
import { useParams, useLocation, useNavigate } from 'react-router'
import { useQuery } from '@tanstack/react-query'
import HeaderUser1 from '../../Header1'
import Hero from '../../Hero'
import SubTitle from '../../ui/SubTitle'
import Wrapper from '../../layout/Wrapper'
import { useProfile } from '../../../contexts/UserContext'
import { ServiceVBC } from '../../../services/ServiceVBC'
import Slots from './Slots'
import SubNav from '../../SubNav'
import TitleIcon from '../../ui/TitleIcon'
import uiStyles from '../../ui/uiStyles'
import { Link } from 'react-router-dom'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import Modal from '../../Modal'
import DBCSend from './DBCSend'
import structuredClone from '@ungap/structured-clone'
import ReceiverDBCSend from './ReceiverDBCSend'
import vbc_images from '../../../assets/images/vbc'
import home_images from '../../../assets/images/home'
import { TbWorld } from 'react-icons/tb'
import { DBC } from '../../../pages'
import { get_storage } from '../../../helpers'

export default function VBCDetails({ DBCId }) {
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const { axiomFetch } = useAxiomFetch()
  const { vbcid } = useParams()
  const { state } = useLocation()
  const { getAccessTokenSilently } = useAuth0()
  const slotsfetchController = useRef(null)
  const { profile } = useProfile()
  const [data, setData] = useState([])
  const navigate = useNavigate()
  // useEffect(() => {
  //   const get_slots = async () => {
  //     let original_slots = await axiomFetch(
  //       `/products/vbc/${vbcid || DBCId}/slots`
  //     )
  //     original_slots = original_slots.sort(
  //       (a, b) => a.SLOT_ASSIGNMENT_ID - b.SLOT_ASSIGNMENT_ID
  //     )
  //     original_slots = original_slots.filter(
  //       (slot) => slot.SLOT_ASSIGNMENT_ID !== 21
  //     )
  //     let actual_slots = structuredClone(original_slots)
  //     for (const slot of actual_slots) {
  //       slot.PRIORITY = null
  //     }
  //     let count = 0
  //     for (const original of original_slots) {
  //       count++
  //       if (original.PRIORITY !== null) {
  //         let found =
  //           actual_slots.find(
  //             (actual) => actual.SLOT_ASSIGNMENT_ID === original.PRIORITY
  //           ) || {}
  //         found.PRIORITY = count
  //       }
  //     }
  //     actual_slots = actual_slots.sort((a, b) => a.PRIORITY - b.PRIORITY)
  //     console.log(actual_slots)
  //     // actual_slots.push({
  //     //   NAME: 'website',
  //     //   PRIORITY: actual_slots?.length + 1,
  //     //   SLOT_ASSIGNMENT_ID: 200,
  //     //   VBC_INSTANCE_ID: vbcid || DBCId,
  //     // })
  //     // actual_slots.push({
  //     //   NAME: 'facebook_link',
  //     //   PRIORITY: actual_slots?.length + 2,
  //     //   SLOT_ASSIGNMENT_ID: 201,
  //     //   VBC_INSTANCE_ID: vbcid || DBCId,
  //     // })
  //     // actual_slots.push({
  //     //   NAME: 'events_link',
  //     //   PRIORITY: actual_slots?.length + 3,
  //     //   SLOT_ASSIGNMENT_ID: 202,
  //     //   VBC_INSTANCE_ID: vbcid || DBCId,
  //     //   LINK: 'https://www.driggstitle.com/whats-happening/',
  //     // })
  //     setData(actual_slots)
  //   }
  //   get_slots()
  // }, [])

  const get_name = () => {
    const receiver_name = get_storage('vbc_receiver')?.NAME
    return state?.name || receiver_name
  }

  const modals = {
    send: (
      <DBCSend
        showModal={showModal}
        setShowModal={setShowModal}
        sendId={vbcid || DBCId}
      />
    ),
    user_send: (
      <ReceiverDBCSend
        showModal={showModal}
        setShowModal={setShowModal}
        sendId={vbcid || DBCId}
      />
    ),
  }

  return (
    <Wrapper>
      <div className='flex justify-between items-center'>
        <SubNav url={'/user/my-vbc'} showLogo={false} space_back_btn={true} />
        <div className='flex gap-3 mx-4 text-black'>
          <Link to={`/user/virtual-cards/edit/${vbcid || DBCId}`} state={state}>
            Edit
          </Link>
          |
          <button
            onClick={() => {
              setModalType('send')
              setShowModal(true)
            }}
          >
            Send
          </button>
        </div>
      </div>

      <TitleIcon className=''>
        {get_name() || `Card: ${vbcid || DBCId}`}
      </TitleIcon>
      <DBC show_header={false} />
      {showModal && (
        <Modal
          modalName={modalType}
          showModal={showModal}
          setShowModal={setShowModal}
          width={'w-[345px]'}
          height={modalType === 'user_send' ? 'h-[300px]' : 'h-[550px]'}
        >
          {modalType && modals[modalType]}
        </Modal>
      )}
    </Wrapper>
  )
}
