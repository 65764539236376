import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSales } from '../../contexts/SalesContext'
import { set_storage, validate_number } from '../../helpers'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import Modal from '../Modal'
import { SmallLoadingRel } from '../ui/SmallLoadingRel'
import uiStyles from '../ui/uiStyles'
import SalesItem from './SalesItem'

const SalesList = ({ list = [], type = 'what_next' }) => {
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const { axiomFetch } = useAxiomFetch()
  const navigate = useNavigate()
  const { sales, saveSales } = useSales()
  const modals = {
    no_access: {
      height: 'h-[400px]',
      component: (
        <div className=''>
          <div>
            <p className='font-bold text-center text-[18px] px-3'>
              Looks like someone has this client already!
            </p>
            <img
              src={`https://rightdata.driggstitle.com/sales_machine/a_Baseball_icon-${5}.png`}
            />
          </div>
          <div className='flex flex-col mt-[30px] px-3'>
            <button
              className={uiStyles.white_btn}
              onClick={() => {
                setShowModal(false)
              }}
            >
              Close
            </button>
          </div>
        </div>
      ),
    },
    assigned_no_call: {
      height: 'h-[430px]',

      component: (
        <div className=''>
          <div>
            <p className='font-bold text-center text-[18px] px-3'>
              Looks like you have already been assigned a client! Make sure to
              contact them first.
            </p>
            <img
              src={`https://rightdata.driggstitle.com/sales_machine/a_Baseball_icon-${1}.png`}
            />
          </div>
          <div className='flex justify-between mt-[30px] px-3 gap-[10px]'>
            <button
              className={uiStyles.white_btn}
              onClick={() => {
                setShowModal(false)
              }}
            >
              Close
            </button>
            {modalLoading ? (
              <SmallLoadingRel />
            ) : (
              <button
                className={uiStyles.hover_btn}
                onClick={async () => {
                  setModalLoading(true)
                  const assigned_client = await axiomFetch(
                    '/sales/what_next/on_deck/assigned_client',
                    'GET'
                  )
                  saveSales((prev) => {
                    const sales = {
                      ...prev,
                      curr_client: {
                        data: assigned_client,
                        selected: 'baseline',
                      },
                    }
                    set_storage('sales', sales)
                    return sales
                  })
                  setModalLoading(false)
                  setShowModal(false)
                  navigate('/user/sales/what_next/client')
                }}
              >
                Go to Assigned Client
              </button>
            )}
          </div>
        </div>
      ),
    },
  }
  return (
    <div className={`flex flex-col gap-[5px]`}>
      {(Array.isArray(list) ? list : [])?.map((item) => {
        return (
          <SalesItem
            setShowModal={setShowModal}
            setModalType={setModalType}
            item={item}
            key={item?.cli_id || item?.cont_id}
            type={type}
          />
        )
      })}
      {showModal ? (
        <Modal
          side_padding={'px-0'}
          modalName={modalType}
          height={modals?.[modalType]?.height || 'h-[550px]'}
          width={modals?.[modalType]?.width || 'w-full'}
          showModal={showModal}
          setShowModal={(type) => {
            setShowModal(false)
          }}
          showClose={true}
        >
          {modals?.[modalType]?.component || <></>}
        </Modal>
      ) : (
        ''
      )}
    </div>
  )
}

export default SalesList
