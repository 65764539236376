import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Wrapper from '../../components/layout/Wrapper'
import { HomeSearch } from '../../components/pages/home'
import SubNav from '../../components/SubNav'
import { useReceiver } from '../../contexts/ReceiverContext'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import { compressToUTF16 } from 'lz-string'
import { get_storage } from '../../helpers'
import { useListingTours } from '../../contexts/ListingToursContext'
export const PageHomeSearch = () => {
  const { axiomFetch } = useAxiomFetch()
  const { receiver, saveReceiver } = useReceiver()
  const { listingTours } = useListingTours()
  console.log(listingTours)
  const navigate = useNavigate()
  const [messageSent, setMessageSent] = useState({})

  const handle_msg = (status, message, type, close) => {
    setMessageSent({ ...messageSent, status, message, type })
    if (close) {
      setTimeout(() => {
        setMessageSent({ ...messageSent, status: 'done', message: '' })
      }, 3000)
    }
  }

  const search_options = [
    { key: 'postal_code', name: 'zip code', show: 7, priority: 4 },
    {
      key: 'administrative_area_level_1',
      name: 'state',
      show: 4,
      priority: 2,
    },
    {
      key: 'administrative_area_level_2',
      name: 'county',
      show: 3,
      priority: 1,
    },
    { key: 'locality', name: 'city', show: 2, priority: 3 },
    // { key: 'neighborhood', name: 'Subdivision', show: 5, priority: 5 },
  ]
  const search_homes = async () => {
    if (!receiver?.place) {
      handle_msg(
        'failure_msg',
        'Must specify a place to search',
        'search',
        true
      )
      return
    }

    handle_msg('loading_msg', 'finding homes...', 'search')
    function find_highest_priority(locationKeys) {
      return locationKeys.reduce(
        (highestPriorityObject, key) => {
          const option = search_options.find((item) => item.key === key)
          return option && option.priority > highestPriorityObject.priority
            ? option
            : highestPriorityObject
        },
        { priority: 0 }
      )
    }

    const priority = find_highest_priority(
      receiver?.address_components?.map((item) => item.types[0]) || []
    )
    const radius = receiver?.place?.circle?.radius
    const lat = receiver?.place?.circle?.location.lat()
    const lng = receiver?.place?.circle?.location?.lng()
    const search_by_obj = receiver?.address_components?.find(
      (item) => item?.types?.[0] === priority?.key
    )
    const circle = { radius, lat, lng }
    const search_by = {
      key: priority?.key,
      value:
        search_by_obj?.types?.[0] === 'administrative_area_level_1'
          ? search_by_obj?.short_name
          : search_by_obj?.long_name,
    }
    const body = { search_by, circle, types: ['active_listings'] }
    if (receiver?.show_map === 'Y') {
      body.search_by = {
        key: 'radius',
        value: circle,
      }
    }
    const cont_id = receiver?.card?.profile?.CONT_ID
      ? receiver?.card?.profile?.CONT_ID
      : (get_storage('card') || {})?.profile?.CONT_ID
    body.cont_id = cont_id
    const homes = await axiomFetch('/marketing/homes/find', 'POST', body)
    handle_msg('success_msg', 'Homes Found', 'search', true)
    saveReceiver((prev) => ({
      ...prev,
      homes: { ...prev.homes, homes: homes?.homes, count: homes?.count },
      orig_homes: homes,
    }))
    localStorage.setItem(
      'home_search',
      compressToUTF16(JSON.stringify(homes?.homes))
    )
    navigate('/homes/list')
  }

  useEffect(() => {
    if (
      get_storage('vbc_edit_mode') === 'true' &&
      listingTours?.buyer_tours?.property_edit?.search_type !== 'address'
    ) {
      saveReceiver((prev) => ({ ...prev, edit_mode: 'view' }))
    }
  }, [])
  console.log(
    'edit_mode',
    receiver.edit_mode,
    localStorage.getItem('vbc_edit_mode')
  )
  return (
    <Wrapper>
      {receiver?.edit_mode === 'view' ? (
        <SubNav
          url={
            !receiver?.vbc_receiver?.ID
              ? '/card'
              : `/user/my-vbc/${
                  receiver?.vbc_receiver?.ID ||
                  (get_storage('vbc_receiver') || {})?.ID
                }`
          }
          logo_url={
            !receiver?.vbc_receiver?.ID
              ? '/card'
              : `/user/my-vbc/${
                  receiver?.vbc_receiver?.ID ||
                  (get_storage('vbc_receiver') || {})?.ID
                }`
          }
          showConversations={false}
          showTours={true}
        />
      ) : (
        <SubNav
          url={
            listingTours?.buyer_tours?.property_edit?.search_type === 'address'
              ? `/user/buyer_tours`
              : '/card'
          }
          logo_url={
            listingTours?.buyer_tours?.property_edit?.search_type === 'address'
              ? `/user/buyer_tours/add_home`
              : receiver?.edit_mode ||
                localStorage?.getItem('vbc_edit_mode') === 'from_home'
              ? '/user'
              : '/card'
          }
          showConversations={false}
        />
      )}
      <HomeSearch
        state={receiver}
        setState={saveReceiver}
        title={'Quick Home Search'}
        messageSent={messageSent}
        state_abbr={
          (get_storage('card') || {})?.profile?.ST_ABBR ||
          receiver?.card?.profile?.ST_ABBR
        }
        search_homes={search_homes}
      />
    </Wrapper>
  )
}
