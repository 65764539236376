import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

export const VBCContext = createContext()

export const VBCProvider = ({ children, userData }) => {
  const [VBCs, setVBCs] = useState({})

  const saveVBCs = useCallback((data) => {
    setVBCs(data)
  }, [])

  const getVBCByID = useCallback(
    (id) => {
      if (!VBCs) {
        return null
      }
      return (VBCs?.cards || [])?.find((item, index) => {
        return +item.ID === +id
      })
    },

    [VBCs]
  )

  const value = useMemo(
    () => ({
      VBCs,
      saveVBCs,

      getVBCByID,
    }),
    [VBCs, saveVBCs, getVBCByID]
  )

  return <VBCContext.Provider value={value}>{children}</VBCContext.Provider>
}

export const useVBCs = () => {
  const context = useContext(VBCContext)

  if (!context) {
    throw Error('VBCContext must be used inside an VBCContextProvider')
  }

  return context
}
