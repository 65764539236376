import { useEffect, useRef, useState } from 'react'
import { FaArrowCircleDown, FaArrowCircleUp } from 'react-icons/fa'
import { useCalc } from '../../contexts/CalcContext'
import {
  calculate_amortization_totals,
  calculate_apr,
  calculate_monthly_payment,
  find_apr_gen,
  format_currency,
  is_el_in_view,
  validate_number,
} from '../../helpers'
import Modal from '../Modal'
import uiStyles from '../ui/uiStyles'
import CalcBuy from './CalcBuy'
import CalcCompareEdit from './CalcCompareEdit'
import CalcCompareRow from './CalcCompareRow'

const CalcLoanCompare = ({ implementation_type }) => {
  const {
    calc,
    saveCalc,
    get_nums,
    get_loan_type,
    get_pmi_and_years,
    get_yearly_mip,
    get_fees_total,
    get_origination_fee,
    get_title_fee_total,
    get_title_fees,
    get_principal,
    remove_mip_base,
    get_va_funding_fee,
    get_mip_base,
  } = useCalc()

  const topRef = useRef()
  const btmRef = useRef()
  const priceRef = useRef(0)
  const loanTypeRef = useRef('conventional')
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('')

  const modals = {
    edit_loans: {
      component: (
        <CalcCompareEdit showModal={showModal} setShowModal={setShowModal} />
      ),
      width: 'w-full',
      height: 'h-[550px]',
    },
  }
  useEffect(() => {
    const get_loan_comparisons = () => {
      const get_loan_data = (
        {
          home_price,
          down_payment,
          interest_rate,
          term,
          loan_type,
          principal,
          taxes,
          insurance,
          misc,
          seller_credit,
          prorated_taxes,
          hoa,
        },
        calculator_type = 'buy'
      ) => {
        const amortization = calculate_amortization_totals(
          principal,
          interest_rate,
          term
        )

        const total_interest = amortization?.total_interest

        const pmi_data = get_pmi_and_years(
          term * 12,
          term,
          amortization?.schedule,
          home_price,
          loan_type?.toLowerCase() === 'conventional' ? true : false
        )
        let mip = get_yearly_mip(home_price, principal, term)

        const mi_label = loan_type?.toLowerCase() === 'fha' ? 'MIP' : 'PMI'

        const get_total_term_mip = (home_price, schedule, loan_term) => {
          let total = 0
          let loan_term_loop =
            validate_number(down_payment) >= 10 ? 11 : loan_term
          for (let i = 0; i < loan_term_loop; i++) {
            const loan_amount =
              i === 0
                ? schedule?.[i]?.remaining_balance + schedule?.[i]?.principal
                : schedule?.[Math.floor(i * 12) + 1]?.remaining_balance
            total += get_yearly_mip(home_price, loan_amount, loan_term)
          }
          return total
        }

        let mip_base = get_mip_base(principal)
        let funding_fee = get_va_funding_fee(calculator_type, principal)
        let mip_term_total = get_total_term_mip(
          home_price,
          amortization?.schedule,
          term
        )
        let mip_total = mip_term_total + mip_base
        if (loan_type?.toLowerCase() !== 'fha') {
          mip = 0
          mip_base = 0
          mip_term_total = 0
          mip_total = 0
        }
        const pmi_total =
          validate_number(pmi_data?.initial_payment) *
          validate_number(pmi_data?.months)
        // this lines up with another calculator but it might be more acurate to calcualate the total instead (pmi_data?.total)
        const origination_fee = validate_number(
          get_origination_fee(calculator_type, principal)
        )
        const lender_fees = validate_number(
          get_fees_total(calculator_type, 'lender_fees')
        )
        const lender_fees_total = lender_fees + origination_fee

        const monthly_payment = calculate_monthly_payment(
          principal + mip_base + funding_fee,
          interest_rate,
          term
        )
        const apr_monthly_payment = calculate_monthly_payment(
          principal,
          interest_rate,
          term
        )

        const apr = validate_number(
          find_apr_gen(
            principal - lender_fees_total - pmi_total - mip_term_total,
            apr_monthly_payment,
            // +
            // mip_term_total / (term * 12)
            term * 12,
            12,
            validate_number(interest_rate / 100),
            0,
            1
          ),
          3
        )
        const total_monthly_payment =
          monthly_payment +
          taxes +
          insurance +
          validate_number(pmi_data?.initial_payment) +
          +validate_number(mip / 12) +
          hoa
        let days_of_interest_total,
          months_of_taxes_total,
          months_of_insurance_total
        const get_prepaids = (calc_type = 'buy') => {
          const defaults =
            calc?.[calc_type]?.defaults?.[get_loan_type(calc_type)]
              ?.closing_costs
          const months_of_taxes = validate_number(defaults?.months_of_taxes)

          const months_of_insurance = validate_number(
            defaults?.months_of_insurance
          )

          const days_of_interest = validate_number(defaults?.days_of_interest)

          const tax = months_of_taxes * taxes
          months_of_taxes_total = tax
          const insur = months_of_insurance * insurance
          months_of_insurance_total = insur
          const interest =
            (principal * days_of_interest * (interest_rate / 100)) / 365
          days_of_interest_total = interest
          return tax + insur + interest
        }
        const down_payment_total = (home_price * down_payment) / 100
        const other_fees = get_fees_total(calculator_type, 'other_fees')
        const title_fees = get_title_fees(
          calculator_type,
          null,
          null,
          home_price,
          principal
        )
        const escrow_fee = validate_number(title_fees?.escrow_fee)
        const title_policy_fee = validate_number(title_fees?.title_policy_fee)
        const title_fees_total = get_title_fee_total(
          calculator_type,
          home_price,
          principal
        )
        const prepaids = get_prepaids(calculator_type)
        const all_closing_costs =
          lender_fees_total +
          title_fees_total +
          other_fees +
          prepaids +
          misc +
          seller_credit +
          prorated_taxes
        const bring_to_close = down_payment_total + all_closing_costs

        return {
          apr,
          amortization,
          days_of_interest_total,
          months_of_insurance_total,
          months_of_taxes_total,
          origination_fee_total: origination_fee,
          lender_fees: lender_fees,
          lender_fees_total: lender_fees_total,
          other_fees_total: other_fees,
          title_fees_total,
          escrow_fee,
          title_policy_fee,
          prepaids,
          pmi: pmi_data?.initial_payment,
          mi_label,
          pmi_total: pmi_data?.total,
          pmi_months: pmi_data?.months,
          monthly_payment,
          total_monthly_payment,
          total_interest,
          bring_to_close,
          down_payment_total,
          all_closing_costs,
          hoa,
          misc,
          seller_credit,
          prorated_taxes,
          mip_term_total,
          mip_base,
          funding_fee,
        }
      }

      let price_change =
        priceRef.current !== validate_number(calc?.buy?.values?.home_price)
      let loan_type_change =
        loanTypeRef?.current?.toLowerCase() !==
        calc?.buy?.values?.loan_type?.toLowerCase()
      const get_loan = (options, type = 'buy', original_loan) => {
        const home_price = options?.home_price_num
        let down_payment = validate_number(
          (validate_number(options?.down_payment_num) / home_price) * 100,
          3
        )
        let pmi = options?.pmi_num
        let original_down = validate_number(original_loan?.down_payment)
        let term = options?.term_num
        let original_term = original_loan?.term
        let interest_rate = options?.interest_rate_num
        let misc = validate_number(options?.misc_num)
        let seller_credit = validate_number(options?.seller_credit_num)
        let prorated_taxes = validate_number(options?.prorated_tax_num)
        let hoa = validate_number(options?.hoa_num)
        const taxes = options?.tax_num / 12
        const insurance = options?.insurance_num / 12
        const loan_type = get_loan_type(type, 'values')
        if (type === 'loan_3' && !calc?.loan_3?.data_options?.loan_edited) {
          if (original_term <= 15 || original_term > 30) {
            term = 30
          } else {
            term = 15
            interest_rate = validate_number(original_loan?.interest_rate) - 0.25
          }
        } else if (
          type === 'loan_2' &&
          !calc?.loan_2?.data_options?.loan_edited
        ) {
          if (original_down < 10) {
            down_payment = 10
          } else if (original_down < 20) {
            down_payment = 20
          } else {
            down_payment = 20
          }
        }
        const principal = get_principal(
          type,
          home_price,
          (home_price * down_payment) / 100
        )
        const loan_options = {
          home_price,
          down_payment,
          term,
          interest_rate,
          loan_type,
          principal,
          taxes,
          insurance,
          misc,
          seller_credit,
          prorated_taxes,
          hoa,
          pmi,
        }
        const loan_data = get_loan_data(loan_options, type)
        const interest_saved = validate_number(
          original_loan?.total_interest - loan_data?.total_interest
        )
        const closing_saved = validate_number(
          original_loan?.all_closing_costs - loan_data?.all_closing_costs
        )
        const pmi_saved = validate_number(
          original_loan?.pmi_total - loan_data?.pmi_total
        )

        const total_saved = interest_saved + closing_saved + pmi_saved
        return { ...loan_options, ...(loan_data || {}), total_saved }
      }

      let loan_1 = get_loan(get_nums('buy')) || {}

      let loan_2 = get_loan(get_nums('loan_2'), 'loan_2', loan_1) || {}
      let loan_3 = get_loan(get_nums('loan_3'), 'loan_3', loan_1) || {}

      loan_1 = {
        ...loan_1,
        title: 'Your Loan',
        bg: 'bg-gradient-to-b from-gray-100 to-gray-300',
        text_color: 'text-thirdary',
      }
      loan_2 = {
        ...loan_2,
        title: `${loan_2?.down_payment}% Down`,
        bg: 'bg-gradient-to-b from-secondary to-primary',
        text_color: 'text-white',
      }
      loan_3 = {
        ...loan_3,
        title: `${loan_3?.term} Years`,
        bg: 'bg-gradient-to-b from-accent-150 to-accent-250',
        text_color: 'text-white',
      }

      if (
        (price_change || loan_type_change) &&
        (!calc?.loan_3?.data_options?.loan_edited ||
          !calc?.loan_2?.data_options?.loan_edited)
      ) {
        saveCalc((prev) => {
          return {
            ...prev,
            loan_1: loan_1,
            loan_2: {
              ...prev?.loan_2,
              values: {
                ...prev?.loan_2?.values,
                interest_rate: loan_2?.interest_rate,
                down_payment: loan_2?.down_payment,
                loan_term: loan_2?.term,
                loan_type: loan_2?.type,
              },
              types: { ...prev?.loan_2?.types, down_payment: '%' },
            },
            loan_3: {
              ...prev?.loan_3,
              values: {
                ...prev?.loan_3?.values,
                interest_rate: loan_3?.interest_rate,
                down_payment: loan_3?.down_payment,
                loan_term: loan_3?.term,
                loan_type: loan_3?.loan_type,
              },
              types: { ...prev?.loan_3?.types, down_payment: '%' },
            },
          }
        })
      }
      priceRef.current = validate_number(calc?.buy?.values?.home_price)
      loanTypeRef.current = calc?.buy?.values?.loan_type?.toLowerCase()
      saveCalc((prev) => ({
        ...prev,
        buy: {
          ...prev?.buy,
          loan_compare: { ...prev?.buy?.loan_compare, loan_1, loan_2, loan_3 },
        },
      }))
    }
    get_loan_comparisons()
    return () => {}
  }, [
    calc?.buy?.values?.home_price,
    calc?.loan_2?.values,
    calc?.loan_3?.values,
  ])

  return (
    <div>
      {['affordability', 'rent_vs_buy'].includes(implementation_type) ? (
        ''
      ) : (
        <>
          {' '}
          {get_loan_type('buy', 'values') === 'all cash' ? (
            <div className='px-3 font-bold'>
              Loan Comparisions are not available for this loan type!
            </div>
          ) : (
            <div>
              <div className='px-3'></div>
              <div className='flex flex-col items-center px-3'>
                <p className='text-center font-bold text-[17px]'>
                  Find the loan that is best for you
                </p>
                <p className='text-center mt-[10px]'>
                  Compare your options and find the loan that will suit you
                  best. See if a shorter loan term or a larger down payment are
                  worth it to you.
                </p>
                <div ref={topRef} className='h-[10px]'></div>
                <p className='text-center font-bold mb-[10px]'>
                  Price - $
                  {format_currency(calc?.buy?.values?.home_price).replace(
                    '$',
                    ''
                  )}
                </p>
              </div>
              <button
                className={`${uiStyles.hover_btn_small} px-[20px] mb-[10px] mx-[3px]`}
                onClick={() => {
                  setModalType('edit_loans')
                  setShowModal(true)
                }}
              >
                Edit
              </button>
              <div className='flex flex-col gap-[10px]'>
                {Object.entries(calc?.buy?.loan_compare || {})?.map(
                  ([key, value]) => {
                    return (
                      <CalcCompareRow
                        card_1={{
                          title: value?.title,
                          loan_type: value?.loan_type,
                          down_payment: value?.down_payment,
                          term: value?.term,
                          interest_rate: value?.interest_rate,
                          total_saved: value?.total_saved,
                          bg: value?.bg,
                          text_color: value?.text_color,
                        }}
                        circle_1={{
                          title: 'Payment',
                          value: value?.total_monthly_payment,
                          bg: value?.bg,
                        }}
                        circle_2={{
                          title: 'Bring to Close',
                          value: value?.bring_to_close,
                          bg: value?.bg,
                        }}
                      />
                    )
                  }
                )}
              </div>
              <div className='flex justify-center my-[20px] items-center'>
                <button
                  className={`${uiStyles.hover_btn_small} flex gap-[5px] items-center`}
                  onClick={() => {
                    const options = {
                      behavior: 'smooth',
                      block: 'start',
                    }
                    if (is_el_in_view(topRef?.current)) {
                      if (implementation_type === 'loan_compare') {
                        if (btmRef?.current) {
                          console.log('hit', btmRef)
                          btmRef?.current?.scrollIntoView({
                            behavior: 'smooth',
                            block: 'end',
                          })
                        }
                      } else {
                        window.scrollTo({
                          top: document.body.scrollHeight,
                          behavior: 'smooth',
                        })
                      }
                    } else {
                      window.scrollTo({ top: 0, behavior: 'smooth' })
                    }
                  }}
                >
                  details
                  {/* {is_el_in_view(topRef?.current) ? (
            <FaArrowCircleUp />
          ) : (
            <FaArrowCircleDown />
          )} */}
                </button>
              </div>
              {calc?.options?.view_type === 'loan_compare' ||
              implementation_type === 'loan_compare' ? (
                <>
                  <div className='flex flex-col gap-[10px] pb-[20px]'>
                    {Object.entries(calc?.buy?.loan_compare)?.map(
                      ([key, value]) => {
                        return (
                          <CalcCompareRow
                            card_1={{
                              title: value?.title,
                              loan_type: value?.loan_type,
                              down_payment: value?.down_payment,
                              term: value?.term,
                              interest_rate: value?.interest_rate,
                              apr: value?.apr,
                              bg: value?.bg,
                              text_color: value?.text_color,
                            }}
                            card_2={{
                              width: 'min-w-[207px]',
                              title: `$${format_currency(
                                value?.home_price
                              ).replace('$', '')}`,
                              monthly_payment: value?.total_monthly_payment,
                              bring_to_close: value?.bring_to_close,
                              down_payment_total: value?.down_payment_total,
                              principal: value?.principal,
                              total_investment:
                                value?.amortization?.total_payments +
                                value?.bring_to_close +
                                value?.pmi_total,
                              total_saved: value?.total_saved,
                              bg: value?.bg,
                              text_color: value?.text_color,
                            }}
                          />
                        )
                      }
                    )}
                  </div>
                </>
              ) : (
                ''
              )}
              <div ref={btmRef} className='h-[10px]'></div>
              {showModal ? (
                <Modal
                  side_padding={'px-0'}
                  modalName={modalType}
                  height={modals?.[modalType]?.height || 'h-[550px]'}
                  width={'w-full'}
                  showModal={showModal}
                  setShowModal={(type) => {
                    setShowModal(false)
                    if (
                      modalType === 'net_at_close' ||
                      type === 'net_at_close'
                    ) {
                      saveCalc((prev) => ({
                        ...prev,
                        sell: {
                          ...prev?.sell,
                          data_options: {
                            ...prev?.sell?.data_options,
                            calculated: true,
                          },
                        },
                      }))
                    }
                  }}
                  showClose={true}
                >
                  {modals?.[modalType]?.component || <></>}
                </Modal>
              ) : (
                ''
              )}
            </div>
          )}{' '}
        </>
      )}
    </div>
  )
}

export default CalcLoanCompare
