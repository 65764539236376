import {useState} from 'react';
import uiStyles from './ui/uiStyles';
import useAxiomFetch from '../hooks/useAxiomFetch';
export default function FormNewNote({setShowModal, pos1, fileid, setRefresh}) {
  const {axiomFetch} = useAxiomFetch()
  const [state, setState] = useState({
    note: '',
    note_title: '',
    position: 'BOTH_AGENTS' || null
  })

  const handle_save = async () => {
    const body = state
    const data = await axiomFetch(`/escrow/transaction/${fileid}/notes`, 'POST', body)
    console.log(data)
    setShowModal(false)
    setRefresh((prev) => prev + 1)
  }
  return (
    <div>
        <h2 className="text-primary font-semibold text-center my-5">
          New Note
        </h2>
        <div className="mb-3">
          <label className={`${uiStyles.label}`}>Note Title</label>
          <input
            className={`${uiStyles.input}`}
            value={state?.note_title || ''}
            onChange={(e) => {
              setState((prev) => ({...prev, note_title: e.target.value}))
            }}
          />
        </div>
        <div className="mb-3">
          <label className={uiStyles.label}>Note</label>
          <textarea
          className={`${uiStyles.text_area} h-28`}
          value={state?.note || ''}
          onChange={(e) => {
            console.log(e.target.value)
            setState((prev) => ({...prev, note: e.target.value}))
          }}>
          </textarea>
        </div>
        <div className="text-sm text-gray-500">
          {new Date().toLocaleDateString('en-US')}
        </div>
        <div className="flex flex-row-reverse">
          <button className={`${uiStyles.green_btn}`}
          onClick={handle_save}>Save</button>
        </div>
      </div>
  );
}
