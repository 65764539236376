import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import images_homes from '../../assets/images/home'
import images_tours from '../../assets/images/listing_tours'
import Wrapper from '../../components/layout/Wrapper'
import { HomeList } from '../../components/pages/home'
import SubNav from '../../components/SubNav'
import { SmallLoadingRel } from '../../components/ui/SmallLoadingRel'
import { useListingTours } from '../../contexts/ListingToursContext'
import { useReceiver } from '../../contexts/ReceiverContext'
import { get_storage } from '../../helpers'

export const PageHomeList = () => {
  const { receiver, saveReceiver } = useReceiver()
  const { listingTours, saveListingTours, save_home } = useListingTours()
  const [addTourLoading, setAddTourLoading] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    if (
      localStorage?.getItem('vbc_edit_mode') === 'true' &&
      listingTours?.buyer_tours?.property_edit?.search_type !== 'address'
    ) {
      saveReceiver((prev) => ({ ...prev, edit_mode: 'view' }))
    }
  }, [])
  console.log('search_type', listingTours)
  console.log('edit_mode', get_storage('vbc_edit_mode'))
  return (
    <Wrapper>
      {receiver?.edit_mode === 'view' ? (
        <SubNav
          logo_url={`/user/my-vbc/${
            receiver?.vbc_receiver?.ID ||
            (get_storage('vbc_receiver') || {})?.ID
          }`}
          showConversations={false}
          showTours={true}
        />
      ) : (
        <SubNav
          logo_url={
            listingTours?.buyer_tours?.property_edit?.search_type === 'address'
              ? `/user/buyer_tours/add_home`
              : localStorage?.getItem('vbc_edit_mode') === 'from_home'
              ? '/user'
              : '/card'
          }
          showConversations={false}
        />
      )}
      <HomeList
        title={'homes for sale'}
        state={receiver}
        setState={saveReceiver}
        right_component={(item) => {
          return (
            (
              <div className='flex gap-[20px] items-center'>
                {listingTours?.buyer_tours?.property_edit?.search_type ===
                'address' ? (
                  <>
                    {addTourLoading ? (
                      <SmallLoadingRel color={'from-white to-gray-300'} />
                    ) : (
                      <div
                        className='cursor-pointer flex flex-col items-center'
                        onClick={async () => {
                          setAddTourLoading(true)
                          saveReceiver((prev) => ({
                            ...prev,
                            homes: { ...prev?.homes, current_home: item },
                          }))

                          await save_home(
                            {
                              mls_id: item?.mls_id?.toString(),
                              event_id:
                                listingTours?.buyer_tours?.event_edit?.event_id,
                              evt_id:
                                listingTours?.buyer_tours?.event_edit?.evt_id,
                            },
                            '/user/buyer_tours/add_home'
                          )
                          setAddTourLoading(false)
                        }}
                      >
                        <img
                          className='w-[25px] pb-[4px]'
                          src={images_tours.add_icon_white}
                        />
                        <p className='text-[10px]'>add to tour</p>
                      </div>
                    )}
                  </>
                ) : (
                  ''
                )}
                <div
                  className='cursor-pointer flex flex-col items-center'
                  onClick={() => {
                    saveReceiver((prev) => ({
                      ...prev,
                      homes: { ...prev?.homes, current_home: item },
                    }))
                    navigate('/homes/view')
                  }}
                >
                  <img className='w-[30px]' src={images_homes.home_icon} />
                </div>
              </div>
            ) || ''
          )
        }}
      />
    </Wrapper>
  )
}
