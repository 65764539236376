import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { set_storage } from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import uiStyles from '../../ui/uiStyles'

const ResetTour = ({ state, setState, setShowModal, setItineraryModal }) => {
  const [loading, setLoading] = useState(false)
  const { axiomFetch } = useAxiomFetch()
  const navigate = useNavigate()
  return (
    <div>
      <p>Are you sure you want to reset the tour and clear ratings data?</p>
      {!loading ? (
        <div className='flex justify-center gap-[10px]'>
          <button
            onClick={() => {
              setShowModal(false)
            }}
            className={uiStyles.white_btn}
          >
            Cancel
          </button>
          <button
            onClick={async () => {
              setLoading(true)
              await axiomFetch('/tours/reset', 'POST', {
                event_id: state?.tour_state?.event_id,
              })
              setLoading(false)
              setState((prev) => {
                const new_data = {
                  ...prev?.data,
                  tour_ratings: [],
                  tour_eo_items_ratings: [],
                  tour_product_ratings: [],
                }
                const new_tour_state = {
                  event_id: prev?.tour_state?.event_id,
                  completed: prev?.tour_state?.completed,
                }
                set_storage('listing_tours', new_data)
                set_storage('listing_tours_state', new_tour_state)
                return {
                  ...prev,
                  data: new_data,
                  tour_state: new_tour_state,
                }
              })
              setShowModal(false)
              setItineraryModal(false)
              return navigate('/user/listing_tours')
            }}
            className={uiStyles.red_btn}
          >
            Reset
          </button>
        </div>
      ) : (
        <SmallLoadingRel />
      )}
    </div>
  )
}

export default ResetTour
