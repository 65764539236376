import React, { memo } from 'react'

function Select({
  children,
  top_label,
  left_label,
  left_label_width,
  no_margin = true,
  handler = () => {},
  value,
  label,
  name,
  className,
  padding_label = '',
  padding_select = '',
  ...restProps
}) {
  return (
    <div className={`flex w-[full] ${no_margin ? '' : 'mb-[8px]'} `}>
      <label className='text-sm font-medium text-[#344054]'>{top_label}</label>
      <div className='text-sm flex w-full mt-0'>
        {left_label ? (
          <div
            className={`${
              left_label_width ? left_label_width : 'w-[50%]'
            } overflow-x-scroll rounded-l-md resize-none ${
              padding_label ? padding_label : 'py-[12px]'
            } pl-[10px] pr-[2px] border border-gray-300 text-thirdary`}
          >
            {left_label}
          </div>
        ) : (
          ''
        )}
        <select
          name={name ? name : ''}
          className={`text-primary bg-white border border-gray-300 w-full ${
            padding_select ? padding_select : 'py-3'
          } px-3 font-base text-base ${
            left_label_width || left_label ? 'rounded-r-md' : 'rounded-md'
          }`}
          onChange={(e) => handler(e)}
          value={value}
          {...restProps}
        >
          {children}
        </select>
      </div>
    </div>
  )
}

export default memo(Select)
