import React, { useEffect, useState } from 'react'
import { Navigate, Outlet, useNavigate, useParams } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import { useAuth0 } from '@auth0/auth0-react'
import jwt_decode from 'jwt-decode'
import Loading from './Loading'
import { ProviderCombined } from '../contexts'
import { get_storage } from '../helpers'
export default function ProtectedRoutes() {
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    logout,
    loginWithRedirect,
    user,
    getIdTokenClaims,
  } = useAuth0()
  const navigate = useNavigate()
  const checkTokenExpiry = async () => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUDIENCE,
    }).catch((err) => {
      return loginWithRedirect()
    })

    const { exp, secure_signin } = jwt_decode(token)
    const expirationTime = exp * 1000 - 300000
    if (Date.now() >= expirationTime) {
      //loggin out user 5min before token  expiry
      logout({ returnTo: window.location.origin })
    }
    return true
  }

  useEffect(() => {
    if (isAuthenticated) {
      checkTokenExpiry()
    }
  })

  if (isLoading) {
    return <Loading />
  }

  if (!isAuthenticated) {
    // user is not authenticated
    return (
      <Navigate
        to={
          get_storage('uuid') ? `/login?uuid=${get_storage('uuid')}` : '/login'
        }
        // replace
      />
    )
    // return navigate('/user')
  }

  const pageMotion = {
    initial: { opacity: 0, x: -100, transition: { duration: 0.3 } },
    animate: { opacity: 1, x: 0, transition: { duration: 0.5 } },
    exit: { opacity: 0, x: -100, transition: { duration: 0 } },
  }

  return (
    <AnimatePresence mode='sync'>
      <motion.div
        // key={location.pathname}
        initial='initial'
        animate='animate'
        exit='exit'
        variants={pageMotion}
        className=''
      >
        <Outlet />
      </motion.div>
    </AnimatePresence>
  )
}
