import React from 'react'
import { FaCalculator } from 'react-icons/fa'
import { useCalc } from '../../contexts/CalcContext'
import { capitalize, format_input, validate_number } from '../../helpers'
import LabelInput from '../form/LabelInput'
import { Schedule } from '../form/Schedule'
import Select from '../form/Select'

const CalcLoanEdit = ({ calculator_type = 'buy' }) => {
  const { calc, saveCalc } = useCalc()
  const update_input = (options) => {
    const { name, value } = format_input(options)
    let type = options?.type || calc?.sell?.types?.[`${name}`] || ''
    saveCalc((prev) => {
      const result = {
        ...prev,
        [calculator_type]: {
          ...prev?.[calculator_type],
          data_options: {
            ...prev?.[calculator_type]?.data_options,
            loan_edited: true,
          },
          values: { ...prev?.[calculator_type]?.values, [name]: value },
          types: { ...prev?.[calculator_type]?.types, [name]: type },
        },
      }
      return result
    })
  }
  const get_inputs = (calculator_type = 'buy') => {
    const types = calc?.[calculator_type]?.types
    const names = calc?.[calculator_type]?.names
    const placeholders = calc?.[calculator_type]?.placeholders
    return Object.entries(calc?.[calculator_type]?.values)
      ?.filter(([key, value]) =>
        ['interest_rate', 'loan_term', 'down_payment', 'loan_type'].includes(
          key
        )
      )
      ?.map(([key, value]) => {
        const type = types?.[key]
        const name = names?.[key]
        const placeholder = placeholders[key] || ''
        const label_width = 'w-[80%]'
        const initial_data = {
          left_label: name,
          left_label_width: label_width,
          name: key,
          value,
          placeholder,
        }

        if (type === '%' || type === '$' || type === '%$') {
          initial_data.show_delete_all = true
          initial_data.inputMode = 'decimal'
        }
        if (type === '%') {
          initial_data.show_percent = true
          initial_data.percent_value = '%'
          if (key === 'loan_term') {
            initial_data.percent_value = ' years'
          }
        }
        if (type === '$') {
          initial_data.show_dollar = true
          initial_data.percent_value = ''
          initial_data.value = initial_data?.value
            ? `$${initial_data.value}`
            : initial_data?.value || ''
        }
        if (key === 'interest_rate') {
          initial_data.handleChange = (e, type) => {
            update_input({ e, type, decimal: 3, original: value })
          }
        }
        if (key === 'taxes') {
          initial_data.show_percent = true
          initial_data.show_dollar = true
          initial_data.percent_value =
            calc?.[calculator_type]?.types?.[key] === '%' ? '%' : ''
          initial_data.dollar_percent = calc?.[calculator_type]?.types?.[key]
          initial_data.left_label_width = 'w-[135%]'
          initial_data.delete_all_position = 'right-[75px]'
          initial_data.total = validate_number(
            calculator_type === 'sell'
              ? calc?.[calculator_type]?.values?.sale_price
              : calc?.calculator_type?.values?.home_price
          )
          initial_data.handleChange = (e, type) => {
            update_input({ e, type, decimal: 3, original: value })
          }
        }

        // if (key === 'sale_price') {
        //   initial_data.onKeyUp = (e) => {
        //     // update_input({ e, type, decimal: 2, original: value })
        //     if (e.target.value && e.key === 'Enter') {
        //       calculate_estimate()
        //     }
        //   }
        // }
        // if (key === 'loan_balance') {
        //   initial_data.delete_all_position = 'right-[40px]'
        // }
        let result = (
          <LabelInput
            {...initial_data}
            handleChange={
              initial_data?.handleChange
                ? initial_data?.handleChange
                : (e) => {
                    update_input({ e, type, decimal: 3, original: value })
                  }
            }
          />
        )
        // if (key === 'loan_balance') {
        //   result = (
        //     <div className='relative'>
        //       {result}
        //       <div
        //         className='absolute top-[15px] right-[8px] cursor-pointer'
        //         onClick={() => {
        //           setModalType('loan_balance')
        //           setShowModal(true)
        //         }}
        //       >
        //         <FaCalculator />
        //       </div>
        //     </div>
        //   )
        // }
        if (type === 'date') {
          result = (
            <Schedule
              left_label={name}
              left_label_width={label_width}
              name={key}
              show_time={false}
              schedule_date={value}
              handle_change={(e) => {
                update_input({ e, type, decimal: 2, original: value })
              }}
            />
          )
        }
        if (type === 'options') {
          const options = calc?.[calculator_type]?.option_values?.[key]
          result = (
            <Select
              left_label={name}
              left_label_width={label_width}
              no_margin={false}
              className='mb-[10px]'
              name={key}
              value={value || 'Conventional'}
              handler={(e) => {
                update_input({ e, type, decimal: 2, original: value })
              }}
              placeholder=''
            >
              {[...options].map((item) => {
                return (
                  <option key={item} value={item}>
                    {item}
                  </option>
                )
              })}
            </Select>
          )
        }

        return <div key={key}>{result}</div>
      })
  }
  return (
    <div className='w-[345px]'>
      <p
        className={`
        ${calc?.buy?.loan_compare?.[calculator_type]?.bg} 
        ${calc?.buy?.loan_compare?.[calculator_type]?.text_color} 
        px-3 py-[5px] mb-[5px]`}
      >
        {capitalize(calculator_type)}
      </p>
      <div className='px-2'>{get_inputs(calculator_type)}</div>
    </div>
  )
}

export default CalcLoanEdit
