import { useEffect, useState, useRef } from 'react'
import { Client } from '@twilio/conversations'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { Conversation } from './Conversation'
import TitleIcon from '../../ui/TitleIcon'
import { ConversationCard } from './ConversationCard'
import SwipeConfig from '../../ui/SwipeConfig'
import uiStyles from '../../ui/uiStyles'
import SubNav from '../../SubNav'
import { useConversations } from '../../../contexts/ConversationsContext'
import SmallLoading from '../../ui/SmallLoading'
import ch_images from '../../../assets/images/conversations'
import { ConversationCreate } from './ConversationCreate'
import Modal from '../../Modal'
import { CampaignCard } from './CampaignCard'
import { CampaignConversations } from './CampaignConversations'
import { useNavigate } from 'react-router-dom'
import Wrapper from '../../layout/Wrapper'
import { HiOutlineArrowDownLeft, HiOutlineArrowLeft } from 'react-icons/hi2'
import { BsCheckAll } from 'react-icons/bs'
import { useDistribute } from '../../../contexts/DistributeContext'
import Pagination from '../../ui/Pagination'
import './conversation.css'
export const Conversations = () => {
  const { axiomFetch } = useAxiomFetch()
  const { distribute, saveDistribute } = useDistribute()
  const navigate = useNavigate()
  const {
    conversations,
    campaigns,
    convMessages,
    saveConvMessages,
    loadingConversations,
  } = useConversations()
  const [token, setToken] = useState()
  const [selected, setSelected] = useState(0)
  const [searchActive, setSearchActive] = useState(false)
  const [search, setSearch] = useState('')
  const [currentCampaign, setCurrentCampaign] = useState()
  const [showModal, setShowModal] = useState(false)
  const [showModalAi, setShowModalAi] = useState(false)
  const [modalType, setModalType] = useState('none')
  const [selections, setSelections] = useState([])
  const [checkAll, setCheckAll] = useState({})
  const [deleting, setDeleting] = useState(false)
  const start = 20
  const [offset, setOffset] = useState(start)
  const [begin, setBegin] = useState(0)
  const [end, setEnd] = useState(start)

  console.log('begin', begin)
  console.log('end', end)

  const [pageCount, setPageCount] = useState(1)
  console.log('page', pageCount)
  const pageTopRef = useRef(null)
  const all_conversations = [
    ...conversations,
    // ...Object?.values(campaigns),
  ]?.flat()
  const searchRef = useRef(null)

  console.log('conversations', all_conversations?.length)
  const modals = {
    create_conversation: (
      <ConversationCreate
        showModalAi={showModalAi}
        setShowModalAi={setShowModalAi}
        setShowModal={setShowModal}
      />
    ),
  }

  const current_conversations = all_conversations
    ?.filter((item) => {
      if (item?.sid) {
        return convMessages[item?.sid]?.date?.valueOf()
      } else if (item?.name) {
        return item?.conversations.some((conv) =>
          convMessages[conv?.sid]?.date?.valueOf()
        )
      }
    })
    ?.filter((item) => {
      if (item?.sid) {
        return (item?.attributes?.name || item?.sid)
          ?.toLowerCase()
          ?.includes(search?.toLowerCase())
      } else if (item?.name) {
        return item?.conversations.some((conv) => {
          return (conv?.attributes?.name || conv?.sid)
            ?.toLowerCase()
            ?.includes(search?.toLowerCase())
        })
      }
    })
    ?.sort((a, b) => {
      if (a.sid && b.sid) {
        return (
          convMessages?.[b?.sid]?.date?.valueOf() -
          convMessages?.[a?.sid]?.date?.valueOf()
        )
      } else if (a?.name && b?.name) {
        const max_a = a?.conversations?.reduce((prev, current) =>
          convMessages?.[prev?.sid]?.date?.valueOf() >
          convMessages?.[current?.sid]?.date?.valueOf()
            ? prev
            : current
        )
        const max_b = b?.conversations?.reduce((prev, current) =>
          convMessages?.[prev?.sid]?.date?.valueOf() >
          convMessages?.[current?.sid]?.date?.valueOf()
            ? prev
            : current
        )
        return (
          convMessages?.[max_b?.sid]?.date?.valueOf() -
          convMessages?.[max_a?.sid]?.date?.valueOf()
        )
      } else {
        if (a.name && b.sid) {
          const max_a = a?.conversations?.reduce((prev, current) =>
            convMessages?.[prev?.sid]?.date?.valueOf() >
            convMessages?.[current?.sid]?.date?.valueOf()
              ? prev
              : current
          )
          return (
            convMessages?.[b?.sid]?.date?.valueOf() -
            convMessages?.[max_a?.sid]?.date?.valueOf()
          )
        } else if (a.sid && b.name) {
          const max_b = b?.conversations?.reduce((prev, current) =>
            convMessages?.[prev?.sid]?.date?.valueOf() >
            convMessages?.[current?.sid]?.date?.valueOf()
              ? prev
              : current
          )
          return (
            convMessages?.[max_b?.sid]?.date?.valueOf() -
            convMessages?.[a?.sid]?.date?.valueOf()
          )
        }
      }
    })

  const paged_conversations = current_conversations?.slice(begin, end)
  console.log('refresh')
  console.log('current', current_conversations?.length)
  console.log('paged', paged_conversations?.length)
  const get_page_num = (change) => {
    const max_page = Math.ceil(current_conversations?.length / start)
    if (pageCount + change > max_page) {
      setPageCount(1)
      return 1
    } else if (pageCount + change <= 0) {
      setPageCount(max_page)
      return max_page
    } else {
      setPageCount((prev) => prev + change)
      return pageCount + change
    }
  }

  if (currentCampaign) {
    return (
      <CampaignConversations
        campaign={currentCampaign}
        setCurrentCampaign={setCurrentCampaign}
      />
    )
  }
  return (
    <div>
      <SubNav />
      <div className='mb-[16px] flex justify-between'>
        <TitleIcon>
          <div
            className={`flex overflow-x-scroll ${
              searchActive ? 'hidden' : 'max-w-[220px]'
            } whitespace-nowrap`}
          >
            <p className=''>Conversations</p>
          </div>
        </TitleIcon>
        <div className='flex'>
          <img
            className='mr-[5px] cursor-pointer duration-75'
            onClick={() => {
              setSearchActive((prev) => {
                if (prev === false) {
                  setTimeout(() => {
                    searchRef.current.focus()
                  }, [0])
                }
                return !prev
              })
            }}
            src={ch_images.search_blue}
          />
          <input
            ref={searchRef}
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
              setBegin(0)
              setEnd(start)
              setPageCount(1)
            }}
            placeholder={'Search'}
            className={`${uiStyles.input} mr-[5px]  ${
              searchActive
                ? 'w-full duration-300 mr-[10px]'
                : 'duration-0 w-0 opacity-0 min-w-0'
            }`}
          ></input>
          <img
            className={`mr-[25px] cursor-pointer ${
              // searchActive ? 'hidden' : ''
              ''
            }
            `}
            onClick={() => {
              saveDistribute((prev) => ({
                ...prev,
                type: 'text',
              }))
              navigate('/user/axiom-ai')
            }}
            src={ch_images.add_blue}
          />
          <div
            className='flex items-center mr-[15px] cursor-pointer '
            onClick={() => {
              setCheckAll((prev) => {
                console.log('check_page', pageCount)
                if (!prev?.[pageCount]) {
                  setSelections((prev) => [
                    ...prev,
                    ...paged_conversations?.map((item) => item.sid),
                  ])
                } else {
                  setSelections((prev) => {
                    let current_sids = paged_conversations?.map(
                      (item) => item.sid
                    )

                    return prev?.filter((item) => !current_sids.includes(item))
                  })
                }
                return {
                  ...prev,
                  [pageCount]: prev?.[pageCount] ? false : true,
                }
              })
            }}
          >
            <img
              src={
                checkAll[pageCount]
                  ? ch_images.check_all
                  : ch_images.check_all_gray
              }
            />
          </div>
        </div>
      </div>

      <div
        className='overflow-y-scroll overflow-x-hidden h-[500px]'
        ref={pageTopRef}
      >
        {!loadingConversations ? (
          <>
            {current_conversations?.length ? (
              paged_conversations?.map((item, index) => {
                if (item?.sid) {
                  return (
                    <div key={item?.sid}>
                      <div className={`${uiStyles.line_light} ml-[7px]`}></div>
                      <div key={item?.sid} className={''}>
                        <SwipeConfig
                          index={index}
                          selectedIndex={selected}
                          setSelectedIndex={setSelected}
                          widthConfigRight={44}
                          bg={'bg-white'}
                          RightComponents={() => {
                            return (
                              <div
                                className='cursor-pointer'
                                onClick={async () => {
                                  navigate(item?.sid)
                                }}
                              >
                                Chat
                              </div>
                            )
                          }}
                          key={item?.sid}
                        >
                          <ConversationCard
                            conv={item}
                            selections={selections}
                            setSelections={setSelections}
                            convMessages={convMessages[item?.sid]}
                            setCurrentMessages={saveConvMessages}
                          />
                        </SwipeConfig>
                      </div>
                    </div>
                  )
                } else if (item?.name) {
                  return (
                    <div key={item?.name}>
                      <div className={`${uiStyles.line_light} ml-[7px]`}></div>
                      <div key={item?.name} className={''}>
                        <SwipeConfig
                          index={index}
                          selectedIndex={selected}
                          setSelectedIndex={setSelected}
                          widthConfigRight={44}
                          bg={'bg-white'}
                          RightComponents={() => {
                            return (
                              <div
                                className='cursor-pointer'
                                onClick={async () => {
                                  setCurrentCampaign(item)
                                }}
                              >
                                View
                              </div>
                            )
                          }}
                          key={item?.sid}
                        >
                          <CampaignCard campaign={item} />
                        </SwipeConfig>
                      </div>
                    </div>
                  )
                }
              })
            ) : (
              <div className='flex flex-col px-4 justify-start gap-[10px]'>
                <p className={uiStyles.text_black}>
                  Create Your First Conversation
                </p>
                <div>
                  <button
                    onClick={() => {
                      saveDistribute((prev) => ({
                        ...prev,
                        type: 'text',
                      }))
                      navigate('/user/axiom-ai')
                    }}
                    className={uiStyles.hover_btn}
                  >
                    Create
                  </button>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <SmallLoading />
          </>
        )}
      </div>
      {selections.length ? (
        <div className='flex justify-end px-2 pt-3'>
          <button
            className={`${uiStyles.red_btn}`}
            onClick={async () => {
              setDeleting(true)
              console.log(selections)
              const data = await axiomFetch(
                '/marketing/conversations/delete',
                'DELETE',
                { conversation_sids: selections }
              )
              setDeleting(false)
              setSelections([])
              setCheckAll({})
              console.log(
                (current_conversations?.length - selections?.length) % start,
                Math.ceil(current_conversations?.length / start)
              )
              if (
                (current_conversations?.length - selections?.length) % start ===
                  0 &&
                Math.ceil(current_conversations.length / start) === pageCount
              ) {
                console.log('hello world')
                setPageCount((prev) => prev - 1)
                setBegin((prev) => prev - start)
              }
              // if (Math.ceil((all_conversations.length) / start) === pageCount) {
              setEnd((prev) => prev - selections?.length)
              // }

              console.log(data)
            }}
          >
            {deleting
              ? `Cleaning up...`
              : `Remove Selected: (${selections.length})`}
          </button>
        </div>
      ) : (
        ''
      )}
      <div className='px-5 py-1'>
        <Pagination
          pageNum={pageCount}
          showlabel={true}
          clickNextHandler={() => {
            const page = get_page_num(1)
            pageTopRef.current.scrollTop = 0
            const max_page = Math.ceil(current_conversations?.length / start)
            console.log('page', page)
            console.log('max page', max_page)
            if (page === max_page) {
              setBegin((prev) => prev + start)
              setEnd(
                (prev) => prev + (current_conversations.length % start || start)
              )
              return
            }
            if (page === 1) {
              setBegin(0)
              setEnd(start)
              return
            }
            setBegin((prev) => prev + start)
            setEnd((prev) => prev + start)
          }}
          clickPrevHandler={() => {
            pageTopRef.current.scrollTop = 0
            let page = get_page_num(-1)
            console.log('sent page', page)
            const max_page = Math.ceil(current_conversations?.length / start)
            console.log('max page', max_page)
            console.log('current page', pageCount)
            if (page + 1 === max_page) {
              console.log('before last')
              setBegin((prev) => prev - start)
              setEnd(
                current_conversations?.length -
                  (current_conversations.length % start || start)
              )
              return
            }
            if (page === max_page) {
              console.log('hi max')
              console.log('remainder', current_conversations?.length % start)
              setBegin(
                current_conversations?.length -
                  (current_conversations.length % start || start)
              )
              setEnd(current_conversations?.length)
              return
            }
            setBegin((prev) => prev - start)
            setEnd((prev) => prev - start)
          }}
        />
      </div>
      {/* {showModal ? (
        <Modal
          showModal={showModalAi ? showModalAi : showModal}
          setShowModal={showModalAi ? setShowModalAi : setShowModal}
          modalName={modalType}
          height={'h-[595px]'}
        >
          {modals?.[modalType]}
        </Modal>
      ) : (
        ''
      )} */}
    </div>
  )
}
