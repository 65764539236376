import React from 'react'
import { Outlet } from 'react-router-dom'
import Header2 from '../../components/Header2'
import PublicWrapper from './PublicWrapper'

const PagePublicWrapper = ({ className, ...restProps }) => {
  return (
    <div>
      {' '}
      <PublicWrapper>
        <div
          {...restProps}
          id='wrapper'
          className={`${
            className ? className : ''
          } bg-white shadow-md rounded-md relative w-full min-h-[600px]`}
        >
          <Header2 type='vbc' showConversations={false} showTours={false} />
          <Outlet />
        </div>
      </PublicWrapper>
    </div>
  )
}

export default PagePublicWrapper
