import React from 'react'
import Header1 from '../Header1'
import Header2 from '../Header2'
import Hero from '../Hero'
import UserMenu from '../UserMenu'
import Wrapper from '../layout/Wrapper'
import { Profile } from '../../pages'
import SubNav from '../SubNav'
import White from '../layout/White'
import TitleIcon from '../ui/TitleIcon'
export default function Settings() {
  return (
    <Wrapper>
      <SubNav />
      <Profile />
    </Wrapper>
  )
}
