import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'

import { useAuth0 } from '@auth0/auth0-react'
import Loading from './Loading'
import { ProviderCombined } from '../contexts'

export default function PublicRoutes() {
  const { isAuthenticated, isLoading, user } = useAuth0()
  console.log(user)
  const location = useLocation()
  if (isLoading) {
    return <Loading />
  }

  // if (isAuthenticated) {
  //   return <Navigate to='/' replace />
  // }

  const pageMotion = {
    initial: { opacity: 0, x: 0, transition: { duration: 0.1 } },
    animate: { opacity: 1, x: 0, transition: { duration: 0.1 } },
    exit: { opacity: 0, x: 0, transition: { duration: 0 } },
  }

  return (
    <AnimatePresence mode='popLayout'>
      <motion.div
        key={location.pathname}
        initial='initial'
        animate='animate'
        exit='exit'
        variants={pageMotion}
      >
        <Outlet />
      </motion.div>
    </AnimatePresence>
  )
}
