import React from 'react';
import StopRecord from '../../assets/images/stoprecording.png';
export default function AxiomStopRecordIcon({ clickHandler }) {
  return (
    <div
      className="flex flex-col items-center min-w-[40px]"
      onClick={() => clickHandler()}
    >
      <img src={StopRecord} alt="Stop" className="max-w-[25px]" />
      <div className="text-xs">Stop</div>
    </div>
  );
}
