import React from 'react'
import Wrapper from '../../components/layout/Wrapper'
import { MarketingMaterialSetup } from '../../components/pages/marketingMaterials/MarketingMaterialSetup'
import SubNav from '../../components/SubNav'
import { useCalc } from '../../contexts/CalcContext'
import { useMktReqs } from '../../contexts/MktReqsContext'
export default function PageMarketingMaterialSetup() {
  const { mktReqs, saveMktReqs } = useMktReqs()
  const { calc, saveCalc, get_calc_type_id } = useCalc()
  const calc_type_id =
    mktReqs?.create_order?.current_layout_selection?.calc_type_id
  return (
    <>
      <Wrapper>
        <SubNav
          url={
            calc_type_id
              ? `/user/calculators/${get_calc_type_id(calc_type_id)}`
              : mktReqs?.create_order?.sessions?.length > 1 &&
                !mktReqs?.create_order?.current_selection?.mkt_req_type_id
              ? '/user/marketing-material/layouts'
              : '/user/marketing-material'
          }
        />
        <MarketingMaterialSetup
          state={mktReqs}
          setState={saveMktReqs}
          title={'Customize'}
        />
      </Wrapper>
    </>
  )
}
