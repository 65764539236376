import search from './searching_1.svg'
import touch from './touch.svg'
import radius from './radius.svg'
import radius_small from './radius_small.svg'
import filter from './filter.svg'
import home_icon from './home_icon.svg'
import school from './school.svg'
import market from './newspaper.svg'
import wifi from './wifi.svg'
import google_location from './google_location.svg'
import places_icon from './places_icon.svg'
import home_icon_black from './home_icon_black.svg'
import heart from './heart.svg'
import heart_filled from './heart_filled.svg'
import sale_tag from './sale_tag.svg'
import sale_tag_white from './sale_tag_white.svg'
const images_homes = {
    search,
    touch,
    radius,
    radius_small,
    filter,
    home_icon,
    school,
    market,
    wifi,
    google_location,
    places_icon,
    home_icon_black,
    heart,
    heart_filled,
    sale_tag,
    sale_tag_white,
}

export default images_homes