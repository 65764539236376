import { useState, useEffect } from 'react'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import uiStyles from '../../ui/uiStyles'
import { AddCard } from './AddCard'
import { AddCardLightbox } from './AddCardLightbox'
import UserThumb from '../../../assets/images/profile-silhouette.svg'
import { useProfile } from '../../../contexts/UserContext'
import axiom_1 from '../../../assets/images/AXIOM_1.png'
import SmallLoading from '../../ui/SmallLoading'
import { useNavigate } from 'react-router-dom'
import { useMerchant } from '../../../contexts/MerchantContext'
import { PaySuccessful } from './PaySuccessful'
import { PayFailure } from './PayFailure'
import { useClaims } from '../../../contexts/ClaimsContext'
const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const PayCard = ({
  setModalType,
  setShowModal,
  currentOrder,
  setPayError,
  setRefreshMaterials,
  pay_func,
  pay_func_after,
  cancel_link,
  pay_later,
}) => {
  const navigate = useNavigate()
  const { merchant, saveMerchant } = useMerchant()
  console.log('merchant', merchant)
  const { profile } = useProfile()
  const { claims } = useClaims()
  const { axiomFetch } = useAxiomFetch()
  const [refresh, setRefresh] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    const controller = new AbortController()
    const get_cards = async () => {
      const all_cards = await axiomFetch(
        '/merchant/cards',
        'GET',
        {},
        {},
        false,
        controller?.signal
      )
      all_cards.cards = all_cards?.cards?.filter(
        (item) => item?.main_pmt_method !== 'Y'
      )
      if (all_cards?.name !== 'AbortError') {
        saveMerchant((prev) => ({
          ...prev,
          data: { ...prev?.data, cards: all_cards },
          payment: { ...prev?.payment, token: all_cards?.default_card?.token },
        }))
      }
    }
    get_cards()
    return () => {
      controller?.abort()
    }
  }, [refresh])

  const pay_now = async () => {
    setIsLoading(true)
    if (Number(currentOrder?.TOTAL_COST) === 0) {
      if (pay_func) {
        await pay_func()
      } else {
        await axiomFetch(
          `/marketing/materials/${currentOrder?.REQ_ID}`,
          'PATCH',
          { req_status: 9 }
        )
      }
      if (setModalType) {
        setModalType('pay_success')
      }
      if (setRefreshMaterials) {
        setRefreshMaterials((prev) => prev + 1)
      }
      setIsLoading(false)
      return
    }
    await pay_func()
    if (pay_later) {
      return
    }
    const body = {
      ssl: {
        ssl_amount:
          currentOrder?.total_cost?.toString() ||
          currentOrder?.TOTAL_COST?.toString() ||
          '0.00',
      },
      axiom: {
        ...currentOrder,
        order_name: currentOrder?.order_name || '',
        bill_type_id: currentOrder?.bill_type || 2,
        quantity:
          currentOrder?.quantity ||
          currentOrder?.REQ_QUANTITY ||
          currentOrder?.req_quantity ||
          1,
      },
    }
    if (merchant?.payment?.token) {
      body.ssl.ssl_token = merchant?.payment?.token
    }
    const result = await axiomFetch(
      '/merchant/payment',
      'POST',
      body,
      {},
      false
    )

    if (
      result?.ssl?.ssl_result_message?.toString()?.toLowerCase() === 'approval'
    ) {
      saveMerchant((prev) => ({
        ...prev,
        payment: {
          payment_failed: false,
          payment_successful: true,
        },
      }))
      if (pay_func_after) {
        await pay_func_after()
      }
    } else {
      saveMerchant((prev) => ({
        ...prev,
        payment: {
          ...prev?.payment,
          payment_failed: true,
          payment_successful: false,
        },
      }))
      if (setPayError) {
        setPayError(result?.ssl?.ssl_result_message)
      }
    }
    setIsLoading(false)
  }

  if (merchant?.payment?.payment_failed) {
    return <PayFailure />
  }
  if (merchant?.payment?.payment_successful) {
    return <PaySuccessful setShowModal={setShowModal} />
  }

  return isLoading ? (
    <>
      <SmallLoading />
    </>
  ) : (
    <div>
      <div className='px-2'>
        <div className='flex mb-3'>
          <div className='w-[40px] h-[40px] mt-1'>
            {(profile.PHOTO_LINK || profile?.account?.photo_url) && (
              <img
                src={
                  profile.PHOTO_LINK || profile?.account?.photo_url || UserThumb
                }
                alt='user thumbnail'
                className='w-full h-full rounded-full object-cover'
              />
            )}
          </div>
          <div className='flex-1 pl-3'>
            <h3 className='text-base text-primary'>{profile.CONT_EMAIL}</h3>
            {/* <p className="text-sm text-gray-500">to adamd@driggstitle.com</p> */}
          </div>
          <div className=' text-sm w-[50px] text-gray-500 overflow-hidden overflow-ellipsis whitespace-nowrap'>
            {`${monthNames[new Date().getMonth()]} ${new Date().getDate()}`}
          </div>
        </div>
        {(currentOrder?.PRODUCT_REQ || currentOrder?.AXIOM_NAME) && (
          <h2 className=' text-gray-700 text-xl leading-7'>
            {currentOrder?.AXIOM_NAME || currentOrder?.PRODUCT_REQ || ''}
          </h2>
        )}
        {/* {currentOrder?.PRODUCT_REQ && (
            <img
              className='w-full h-full object-cover'
              src={currentOrder?.AXIOM_PHOTO_LINK || currentOrder?.IMAGE_LINK || axiom_1}
            />
          )} */}
        {/* {currentOrder?.req_mls_link === 'Y' ? <> */}
        {/* <p className="text-gray-700 text-xl font-light mb-[20px] leading-6">
            {listing && listing.PROP_STREET && listing.PROP_STREET},
            {listing && listing.PROP_CITY && listing.PROP_CITY},
            {listing && listing.PROP_ST && listing.PROP_ST},
            {listing && listing.PROP_ZIP && listing.PROP_ZIP}
            
          </p> */}

        {/* </> : ''} */}
        <div className='flex flex-col gap-[3px] mb-[15px]'>
          <p>
            Quantity:{' '}
            {currentOrder?.quantity ||
              currentOrder?.REQ_QUANTITY ||
              currentOrder?.req_quantity ||
              1}
          </p>
        </div>
        <div className='flex flex-col gap-[3px] mb-[15px]'>
          <p>Total Due: </p>
          <p
            className={`${uiStyles.text_black_subtitle} text-[32px] font-bold`}
          >
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(currentOrder?.total_cost || currentOrder?.TOTAL_COST)}
          </p>
        </div>
      </div>
      <div>
        {merchant?.data?.cards?.default_card?.token ||
        merchant?.data?.cards?.cards?.length ? (
          <>
            <label className={uiStyles.label}>Pay Using Saved Card: </label>
            <select
              className={uiStyles.select}
              onChange={(e) => {
                console.log(e.target.value)
                saveMerchant((prev) => ({
                  ...prev,
                  payment: { ...prev?.payment, token: e.target.value },
                }))
              }}
            >
              <option value={merchant?.data?.cards?.default_card?.token}>
                {merchant?.data?.cards?.default_card?.pmt_name} Card Ending in{' '}
                {merchant?.data?.cards.default_card?.pmt_last4.slice(-4)}
              </option>
              {merchant?.data?.cards?.cards?.map((card) => {
                return (
                  <option key={card?.token} value={card?.token}>
                    {card?.pmt_name} Card Ending in {card?.pmt_last4.slice(-4)}
                  </option>
                )
              })}
            </select>
          </>
        ) : (
          <></>
        )}
      </div>
      <AddCardLightbox setRefresh={setRefresh} />
      <div>
        <div className='flex justify-end mt-[15px] gap-[10px]'>
          <button
            className={`${uiStyles.white_btn}`}
            onClick={() => {
              if (setShowModal) {
                setShowModal(false)
              }
              if (cancel_link) {
                navigate(cancel_link)
              }
            }}
          >
            Cancel
          </button>
          <button
            disabled={claims?.client_proxy_login}
            className={`${
              claims?.client_proxy_login
                ? uiStyles.hover_btn_greyed_out
                : uiStyles.hover_btn
            }`}
            onClick={pay_now}
          >
            Pay Now
          </button>
        </div>
        <div className='text-center mt-[10px]'>
          {claims?.client_proxy_login
            ? 'Purchasing on behalf of a client is not allowed.'
            : ''}
        </div>
      </div>
    </div>
  )
}
