import React from 'react'
import Wrapper from '../../components/layout/Wrapper'
import SubNav from '../../components/SubNav'
import TitleIcon from '../../components/ui/TitleIcon'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import uiStyles from '../../components/ui/uiStyles'
import { useEffect, useState, useRef } from 'react'
import Member from '../../components/pages/CallCenter/Member'
import SmallLoading from '../../components/ui/SmallLoading'

const PageCallCenter = ({ showTeamConfig, setShowTeamConfig }) => {
  const { axiomFetch } = useAxiomFetch()
  const [team, setTeam] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const bottomRef = useRef(null)
  const [scroll, setScroll] = useState(0)
  useEffect(() => {
    const GetTeam = async () => {
      setIsLoading(true)
      const data = await axiomFetch('/client/call_team')
      console.log(data)
      setTeam(data)
      setIsLoading(false)
    }
    GetTeam()
  }, [])

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [scroll])

  return (
    <>
      {showTeamConfig ? (
        <>
          {showTeamConfig ? '' : <SubNav />}
          <TitleIcon className='mb-5'>Call Team Config</TitleIcon>
          <div className='relative flex flex-col px-[20px] pb-3 gap-[20px] justify-center items-center overflow-x-hidden overflow-y-scroll max-h-[550px]'>
            {/* <h1 className='mt-[20px] text-[20px]'>Call Team</h1> */}
            {isLoading && (
              <div>
                <SmallLoading />
              </div>
            )}
            {!isLoading &&
              team?.map((member, index) => {
                if (member.STATUS === 'deleted') {
                  return
                }

                return (
                  <Member
                    ref={index === team.length - 1 ? bottomRef : null}
                    key={`${member.TEAM_ID}-${index}`}
                    member={member}
                  />
                )
              })}
          </div>
          <div className='p-[20px]'>
            {!isLoading && (
              <div className='flex justify-end gap-3'>
                <button
                  className={uiStyles.hover_btn}
                  onClick={async () => {
                    // setIsLoading(true)
                    // const data = await axiomFetch('/client/call_team', 'POST', [{}])
                    // const refetch = await axiomFetch('/client/call_team')
                    // setTeam(refetch)
                    // console.log(data)
                    // setIsLoading(false)
                    setTeam([...team, {}])
                    setScroll(scroll + 1)
                  }}
                >
                  ADD
                </button>
                <button
                  className={uiStyles.hover_btn}
                  onClick={async () => {
                    console.log('hello')
                    let update_data = []
                    for (const member of team) {
                      if (!member.updated) {
                        continue
                      } else {
                        delete member?.updated
                        update_data.push(member)
                      }
                    }
                    console.log(update_data)

                    setIsLoading(true)
                    const data = await axiomFetch('/client/call_team', 'POST', update_data)
                    const refetch = await axiomFetch('/client/call_team')
                    setTeam(refetch)
                    setIsLoading(false)
                    console.log(data)
                  }}
                >
                  Save
                </button>
              </div>
            )}
          </div>
        </>
      ) : (
        <Wrapper>
          {showTeamConfig ? '' : <SubNav />}
          <TitleIcon className='mb-5'>Call Team Config</TitleIcon>
          <div className='relative flex flex-col px-[20px] pb-3 gap-[20px] justify-center items-center overflow-x-hidden overflow-y-scroll max-h-[550px]'>
            {/* <h1 className='mt-[20px] text-[20px]'>Call Team</h1> */}
            {isLoading && (
              <div>
                <SmallLoading />
              </div>
            )}
            {!isLoading &&
              team?.map((member, index) => {
                if (member.STATUS === 'deleted') {
                  return
                }

                return (
                  <Member
                    ref={index === team.length - 1 ? bottomRef : null}
                    key={`${member.TEAM_ID}-${index}`}
                    member={member}
                  />
                )
              })}
          </div>
          <div className='p-[20px]'>
            {!isLoading && (
              <div className='flex justify-end gap-3'>
                <button
                  className={uiStyles.hover_btn}
                  onClick={async () => {
                    // setIsLoading(true)
                    // const data = await axiomFetch('/client/call_team', 'POST', [{}])
                    // const refetch = await axiomFetch('/client/call_team')
                    // setTeam(refetch)
                    // console.log(data)
                    // setIsLoading(false)
                    setTeam([...team, {}])
                    setScroll(scroll + 1)
                  }}
                >
                  ADD
                </button>
                <button
                  className={uiStyles.hover_btn}
                  onClick={async () => {
                    console.log('hello')
                    let update_data = []
                    for (const member of team) {
                      if (!member.updated) {
                        continue
                      } else {
                        delete member?.updated
                        update_data.push(member)
                      }
                    }
                    console.log(update_data)

                    setIsLoading(true)
                    const data = await axiomFetch('/client/call_team', 'POST', update_data)
                    const refetch = await axiomFetch('/client/call_team')
                    setTeam(refetch)
                    setIsLoading(false)
                    console.log(data)
                  }}
                >
                  Save
                </button>
              </div>
            )}
          </div>
        </Wrapper>
      )}
    </>
  )
}

export default PageCallCenter
