import React from 'react';
import { FaFacebook } from 'react-icons/fa';

export default function AxiomFacebookIcon({
  selected = false,
  clickHandler = () => {},
}) {
  return (
    <span onClick={() => clickHandler()}>
      <FaFacebook className={`${selected ? 'text-[#4285F4]' : ''}`} />
    </span>
  );
}
