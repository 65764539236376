import { useState, useRef, useEffect } from 'react'
import uiStyles from '../../ui/uiStyles'
import MLSView from './MLSView'
import { useListing } from '../../../contexts/ListingContext'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import SmallLoading from '../../ui/SmallLoading'
import { useAi } from '../../../contexts/AiContext'
import { AiFillCloseCircle } from 'react-icons/ai'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
export default function MLSSearch({
  mlsSearch,
  setMlsSearch,
  modalType,
  setModalType,
  manualEntry = false,
  searchTitle = 'Open Escrow',
  type = 'advanced',
}) {
  const { axiomFetch } = useAxiomFetch()
  const { ai, saveAi } = useAi()
  const [mlsFound] = useState(false)
  const [streetSearch, setStreetSearch] = useState('')
  const [streetListings, setStreeListings] = useState([])
  const { listing: activeListings } = useListing()
  const searchRef = useRef(null)
  const [searchType, setSearchType] = useState('active_listings')
  const [isLoading, setIsLoading] = useState(false)
  // const [manualData, setManualData] = useState({
  //   PROP_STREET: '',
  //   DETAILS: '',
  // })

  useEffect(() => {
    if (searchRef?.current) {
      searchRef.current.focus()
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()

    const get_street_listings = async () => {
      setIsLoading(true)
      const street_listings = await axiomFetch(
        `/client/listings/active/street/${streetSearch}?limit=10`,
        'GET',
        {},
        {},
        false,
        controller.signal,
        600
      )
      if (!controller?.signal?.aborted) {
        setIsLoading(false)
      }
      setStreeListings(street_listings)
    }
    // if (streetSearch.length > 2) {
    get_street_listings()
    // }
    return () => {
      controller?.abort()
    }
  }, [streetSearch])

  return (
    <>
      {!mlsFound ? (
        <div>
          <p className='text-center mb-[10px]'>{searchTitle}</p>
          <div className='flex mb-[10px]'>
            <div className='flex flex-col items-start z-10'>
              <button
                onClick={() => {
                  setSearchType('active_listings')
                }}
              >
                Active Listings
              </button>
              <div
                className={`w-[120px] h-[3px] transition-all duration-400 bg-green-300  ${
                  searchType === 'active_listings'
                    ? 'opacity-100'
                    : 'opacity-50 bg-gray-300'
                }`}
              ></div>
            </div>
            <div className='flex flex-col items-start z-10'>
              <button
                onClick={() => {
                  setMlsSearch('')
                  setSearchType('street_search')
                }}
              >
                MLS Street/#
              </button>
              <div
                className={`w-[110px] h-[3px] transition-all duration-400 bg-green-300  ${
                  searchType === 'street_search'
                    ? 'opacity-100'
                    : 'opacity-50 bg-gray-300'
                }`}
              ></div>
            </div>
            {manualEntry ? (
              <div className='flex flex-col items-start z-10'>
                <button
                  onClick={() => {
                    setSearchType('manual_search')
                  }}
                >
                  Manual Entry
                </button>
                <div
                  className={`w-[110px] h-[3px] transition-all duration-400 bg-green-300  ${
                    searchType === 'manual_search'
                      ? 'opacity-100'
                      : 'opacity-50 bg-gray-300'
                  }`}
                ></div>
              </div>
            ) : (
              ''
            )}
          </div>
          {activeListings?.length && searchType === 'active_listings' ? (
            <>
              <div
                className={`overflow-y-scroll ${
                  activeListings.length ? 'h-[200px]' : 'h-[40px]'
                }`}
              >
                <div className='flex flex-col gap-1'>
                  {activeListings?.length &&
                    activeListings?.map((listing, index) => {
                      return (
                        <div
                          key={listing?.mls_id}
                          className={`listing flex flex-col overflow-x-scroll w-[100%] z-10 whitespace-nowrap border-b-2 p-2 pb-2 border-2 border-opacity-0 shadow-md
                      ${
                        listing?.mls_id === mlsSearch
                          ? ''
                          : 'border-2 border-opacity-1 border-green-200'
                      }`}
                          onClick={() => {
                            setMlsSearch(listing.mls_id)
                          }}
                        >
                          <p className='listing z-10'>
                            MLS #: {listing?.mls_id}
                          </p>
                          <p className='listing z-10'>
                            Street:{' '}
                            <span className='overflow-x-scroll'>
                              {listing?.prop_add}
                            </span>
                          </p>
                        </div>
                      )
                    })}
                </div>
              </div>
            </>
          ) : (
            ''
          )}
          {searchType === 'street_search' ? (
            <>
              <div className='relative flex z-10 flex-start items-start w-[90%] mb-[5px]'>
                <input
                  className={`${uiStyles.input} z-10`}
                  value={streetSearch}
                  placeholder='1234 sesame st'
                  onChange={(e) => {
                    setStreetSearch(e.target.value)
                  }}
                />
                <div
                  className='absolute z-10 right-[10px] top-[14px] cursor-pointer'
                  onClick={() => {
                    setStreetSearch('')
                  }}
                >
                  <AiFillCloseCircle size={15} />
                </div>
              </div>
              <div
                className={`overflow-y-scroll ${
                  streetListings?.length ? 'h-[200px]' : 'h-[40px]'
                }`}
              >
                <div className='flex flex-col gap-1'>
                  {!streetListings?.length ? (
                    isLoading ? (
                      <div className='mt-[5px]'>Loading...</div>
                    ) : streetSearch.length ? (
                      <div className='mt-[5px]'>
                        Cannot match your search...
                      </div>
                    ) : (
                      ''
                    )
                  ) : (
                    streetListings?.map((listing, index) => {
                      console.log('street', listing)
                      return (
                        <div
                          key={listing?.mls_id}
                          className={`listing flex flex-col overflow-x-scroll w-[100%] z-10 whitespace-nowrap border-b-2 p-2 pb-2 border-2 border-opacity-0 shadow-md
                      ${
                        listing?.mls_id === mlsSearch
                          ? ''
                          : 'border-2 border-opacity-1 border-green-200'
                      }`}
                          onClick={() => {
                            setMlsSearch(listing.mls_id)
                          }}
                        >
                          <p className='listing'>MLS #: {listing?.mls_id}</p>
                          <p className='listing'>Street: {listing?.prop_add}</p>
                        </div>
                      )
                    })
                  )}
                </div>
              </div>
            </>
          ) : (
            ''
          )}
          {searchType === 'manual_search' ? (
            <>
              <label className={uiStyles.label}>Address</label>
              <div className='flex flex-col z-10 flex-start items-start w-[90%] mb-[5px]'>
                <input
                  className={`${uiStyles.input} z-10`}
                  value={ai?.mls?.mlsMatch?.prop_add || ''}
                  placeholder='1234 sesame st'
                  onChange={(e) => {
                    saveAi((prev) => ({
                      ...prev,
                      mls: {
                        ...prev?.mls,
                        mlsMatch: {
                          ...prev?.mls?.mlsMatch,
                          PROP_STREET: e.target.value,
                        },
                      },
                    }))
                  }}
                />
                <label className={uiStyles.label}>Property Details</label>
                <textarea
                  className={`${uiStyles.text_area} z-10`}
                  value={ai?.mls?.mlsMatch?.DETAILS}
                  placeholder='Bathrooms: 3, Bedrooms: 2'
                  onChange={(e) => {
                    saveAi((prev) => ({
                      ...prev,
                      mls: {
                        ...prev?.mls,
                        mlsMatch: {
                          ...prev?.mls?.mlsMatch,
                          DETAILS: e.target.value,
                        },
                      },
                    }))
                  }}
                ></textarea>
              </div>
            </>
          ) : (
            ''
          )}
          {searchType === 'manual_search' ? (
            ''
          ) : (
            <div className='flex z-10 mt-[10px] w-full'>
              <label className={`${uiStyles.label} z-10 w-[90%]`}>
                MLS #
                <div className='relative'>
                  <input
                    ref={searchRef}
                    className={`${uiStyles.input} z-10`}
                    type='text'
                    name='mlssearch'
                    placeholder='Add the MLS #'
                    value={mlsSearch}
                    onChange={(e) => {
                      setMlsSearch(
                        e.target.value.replace(/[^0-9]/g, '').slice(0, 10)
                      )
                    }}
                    onKeyUp={(e) => {
                      console.log(e.key)
                      if (e.key === 'Enter') {
                        setModalType('mlsView')
                      }
                    }}
                  />
                  <div
                    className='absolute z-10 right-[10px] top-[14px] cursor-pointer'
                    onClick={() => {
                      setMlsSearch('')
                    }}
                  >
                    <AiFillCloseCircle size={15} />
                  </div>
                </div>
              </label>
            </div>
          )}
          <div className='flex flex-row-reverse mt-3'>
            {isLoading ? (
              <SmallLoadingRel />
            ) : (
              <button
                disabled={
                  searchType !== 'manual_search' && !mlsSearch ? true : false
                }
                className={`${
                  searchType !== 'manual_search' && !mlsSearch
                    ? uiStyles.hover_btn_greyed_out
                    : uiStyles.hover_btn
                } z-10`}
                onClick={() => {
                  if (searchType === 'manual_search') {
                    saveAi((prev) => ({
                      ...prev,
                      mls: {
                        ...prev?.mls,
                        setting: 'mlsYes',
                        add_mls_to_prompt: (prev?.add_mls_to_prompt || 0) + 1,
                      },
                    }))
                    setModalType('mlsConfirm')
                  } else {
                    setModalType('mlsView')
                  }
                }}
              >
                {searchType === 'manual_search' ? 'Create' : 'Search'}
              </button>
            )}
          </div>

          {/* <div
            className='w-full h-full z-1 absolute bg-blue-300 opacity-0 top-0 left-0'
            onClick={(e) => {
              if (!e?.target?.className?.includes('listing')) {
                setMlsSearch('')
              }
            }}
          ></div> */}
        </div>
      ) : (
        <MLSView />
      )}
    </>
  )
}
