import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

export const ConnectionContext = createContext()

export const ConnectionProvider = ({ children, userData }) => {
  const [connections, setConnections] = useState(null)

  const saveConnections = useCallback((data) => {
    setConnections(data)
  }, [])

  const value = useMemo(
    () => ({
      connections,
      saveConnections,
    }),
    [connections, saveConnections]
  )

  return (
    <ConnectionContext.Provider value={value}>
      {children}
    </ConnectionContext.Provider>
  )
}

export const useConnections = () => {
  const context = useContext(ConnectionContext)
  if (!context) {
    throw Error('Connection Context must be used inside an AuthContextProvider')
  }

  return context
}
