import React from 'react'
import { HiArrowLongLeft, HiArrowLongRight } from 'react-icons/hi2'
export default function Pagination({
  showPrev = () => {},
  showNext = () => {},
  clickNextHandler = () => {},
  clickPrevHandler = () => {},
  pageNum,
  showlabel = true,
  showEndLabel = false,
  pageEnd = 0,
}) {
  return (
    <div className='flex justify-center items-center my-4'>
      {showPrev && (
        <div className='btn-left'>
          <span
            onClick={clickPrevHandler}
            className='border border-gray-200 px-2 py-2 inline-block rounded-lg cursor-pointer hover:bg-gradient-to-b hover:from-accent-150 hover:to-accent-250 hover:text-white'
          >
            <HiArrowLongLeft />
          </span>
        </div>
      )}

      <div className='pages flex-1 text-center px-3'>
        {showlabel
          ? `Page ${pageNum}`
          : showEndLabel
          ? `Page ${pageNum} of ${pageEnd}`
          : ''}
      </div>

      {showNext && (
        <div className='btn-right'>
          <span
            onClick={clickNextHandler}
            className='border border-gray-200 px-2 py-2 inline-block rounded-lg cursor-pointer hover:bg-gradient-to-b hover:from-accent-150 hover:to-accent-250 hover:text-white'
          >
            <HiArrowLongRight />
          </span>
        </div>
      )}
    </div>
  )
}
