import { useEffect, useState } from 'react'

import useAxiomFetch from '../hooks/useAxiomFetch'

import TitleIcon from './ui/TitleIcon'
import uiStyles from './ui/uiStyles'
import { useProfile } from '../contexts/UserContext'
import { formatPhoneNumber } from '../helpers'
import UserThumb from '../assets/images/profile-silhouette.svg'
import { HiOutlineTrash } from 'react-icons/hi2'
import GuideBuyer from '../assets/images/DTA_Buyer_Axiom.jpg'
import GuideSeller from '../assets/images/DTA_Seller_Axiom.jpg'
import GuideAll from '../assets/images/DTA_ALL_Axiom.jpg'
import { ProfileHeader } from './pages/Billing/ProfileHeader'
import EmailVerify from './pages/AI/EmailVerify'

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]
const GuideSend = ({ setShowModal, sendgrid, item, setModalType }) => {
  const { axiomFetch } = useAxiomFetch()
  const { profile } = useProfile()
  const [isLoading, setIsLoading] = useState('')
  const [singleNumber, setSingleNumber] = useState('')
  const [singleEmail, setSingleEmail] = useState('')

  const request = async (event) => {
    event.preventDefault()

    const message = `\nGuide Name: ${item?.NAME}\n\nGuide URL: ${item?.URL}`
    const email = {
      date: new Date().toISOString(),
      text: `Guide URL: ${item?.URL}`,
      subject: `Guide Name: ${item?.NAME}`,
      selected: [
        {
          name: '',
          email: singleEmail,
        },
      ],
      from: profile?.CONT_EMAIL,
    }
    // const email = {
    //   to: singleEmail,
    //   from: profile?.CONT_EMAIL || 'hello@theaxiomapp.com',
    //   subject: 'Guide',
    //   body: message,
    // }
    const text = {
      selected: [
        {
          message,
          cell: singleNumber,
        },
      ],
    }
    if (singleEmail) {
      handle_msg('loading_msg', 'Sending Email...', 'message')
      console.log(JSON.stringify(email))
      const send_email = await axiomFetch(
        '/services/email/subuser/email/schedule',
        'POST',
        email
      )
      console.log(send_email)
      if (send_email.error) {
        handle_msg('success_msg', `error: ${send_email.error}`, 'message', true)
      } else {
        handle_msg('success_msg', 'Sent!', 'message', true)
      }
      setSingleEmail('')
    }
    if (singleNumber) {
      handle_msg('loading_msg', 'Sending Text...', 'message')
      const send_text = await axiomFetch(
        '/marketing/messaging/sendOneOff',
        'POST',
        text
      )
      if (send_text.error) {
        handle_msg('success_msg', `error: ${send_text.error}`, 'message', true)
      } else {
        handle_msg('success_msg', 'Sent!', 'message', true)
      }
      setSingleNumber('')
    }
  }

  const [messageSent, setMessageSent] = useState('done')
  const handle_msg = (status, message, type, close) => {
    // statuses: success_msg, loading_msg, failure_msg, done
    setMessageSent({ ...messageSent, status, message, type })
    if (close) {
      setTimeout(() => {
        setMessageSent({ ...messageSent, status: 'done', message: '' })
      }, 3000)
    }
  }

  return (
    <>
      <div>
        <ProfileHeader />
        <TitleIcon>Send: {item?.NAME}</TitleIcon>
        {item.NAME && item?.TYPE.toLowerCase() === 'buyer' ? (
          <img alt='buyer guide' src={GuideBuyer} className='mt-3' />
        ) : (
          ''
        )}
        {item.NAME && item?.TYPE.toLowerCase() === 'seller' ? (
          <img alt='seller guide' src={GuideSeller} className='mt-3' />
        ) : (
          ''
        )}
        {item.NAME && item?.TYPE.toLowerCase() === 'all' ? (
          <img alt='all guides' src={GuideAll} className='mt-3' />
        ) : (
          ''
        )}
        {item.NAME && (
          <div className='mt-3 mb-3'>
            <p className='text-sm'>Included in your Guide Title:</p>
            <ul className='text-sm list-disc pl-5'>
              <li>1 Guide Title PDF</li>
            </ul>
          </div>
        )}
        <div className='text-black'>
          {sendgrid?.verified !== 'verified' ? (
            <div className='flex justify-start mb-[10px]'>
              <div
                className={`${uiStyles.hover_btn}`}
                onClick={() => {
                  setModalType('sendgrid_verify')
                }}
              >
                Configure Email
              </div>
            </div>
          ) : (
            <label className='mb-3 block'>
              Email
              <input
                className={uiStyles.input}
                value={singleEmail}
                onChange={(e) => {
                  setSingleEmail(e.target.value)
                }}
              />
            </label>
          )}
          <label className='mb-3 block'>
            Text
            <input
              className={uiStyles.input}
              value={singleNumber}
              onChange={(e) => {
                const number = formatPhoneNumber(e.target.value)
                console.log(number)
                setSingleNumber(number)
              }}
            />
          </label>

          {messageSent?.type === 'message' ? (
            <div className={`${uiStyles[messageSent.status]} mb-[20px]`}>
              {messageSent.message}
            </div>
          ) : (
            <div className='flex justify-end mt-[15px]'>
              <button className={`${uiStyles.green_btn}`} onClick={request}>
                Send
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default GuideSend
