import { useEffect, useRef, useState } from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'
import ch_images from '../../../assets/images/conversations'
import {
  formatPhoneNumber,
  getCurrentTime,
  getDateString,
  set_storage,
} from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { CircleCheckToggle } from '../../form/CircleCheckToggle'
import ImageUpload from '../../form/ImageUpload'
import LabelInput from '../../form/LabelInput'
import Select from '../../form/Select'
import Textarea from '../../form/Textarea'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import uiStyles from '../../ui/uiStyles'

const EditRating = ({
  state,
  setState,
  page,
  setPage,
  setShowModal,
  market_name,
  type,
}) => {
  console.log('create_state', state)
  const [inputValue, setInputValue] = useState(
    state?.buyer_tours?.rating_edit || {}
  )
  const [loading, setLoading] = useState(false)
  const [aiLoading, setAiLoading] = useState(false)
  const { axiomFetch } = useAxiomFetch()
  const startRef = useRef(null)
  useEffect(() => {
    startRef.current.focus()
  }, [])
  const handleChange = (e) => {
    let { name, value } = e.target
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const save_rating = async () => {
    const data = {
      tour_type_id: state?.buyer_tours?.tour_experience_id,
      event_type_id: state?.buyer_tours?.event_type_id,
      item_ord:
        (state?.data?.tour_rating_items
          ?.filter(
            (item) =>
              item?.tour_type_id?.toString() ===
              state?.buyer_tours?.tour_experience_id?.toString()
          )
          ?.reduce(
            (curr, val) =>
              Number(curr?.item_ord || 0) > Number(val?.item_ord || 0)
                ? curr
                : val,
            []
          )?.item_ord || 0) + 1,
      ...inputValue,
      rate_type: inputValue?.rate_type || 'S',
    }
    console.log('initial_data', data)
    setLoading(true)
    const result = await axiomFetch('/tours/rating/add', 'POST', data)
    console.log('result', result)
    setState((prev) => {
      const tour_rating_items = prev?.buyer_tours?.rating_edit?.tour_type_id
        ? [
            ...prev?.data?.tour_rating_items?.filter(
              (item) =>
                item?.item_id !== prev?.buyer_tours?.rating_edit?.item_id
            ),
            result?.data,
          ]
        : [...prev?.data?.tour_rating_items, result?.data || {}]

      set_storage('listing_tours', { ...prev?.data, tour_rating_items })
      return {
        ...prev,
        data: { ...prev?.data, tour_rating_items },
      }
    })

    setLoading(false)
    setShowModal(false)
  }

  return (
    <div>
      <h1 className='mb-[5px]'>Create a rating</h1>
      <div className='overflow-y-scroll max-h-[450px] min-h-[175px] mb-[15px]'>
        <LabelInput
          ref={startRef}
          left_label={'Title'}
          placeholder='Title'
          name={'item_name'}
          value={inputValue?.item_name || ''}
          handleChange={handleChange}
        />
        <Select
          placeholder='details...'
          name={'rate_type'}
          label={'Rating Type'}
          value={inputValue?.rate_type || 'S'}
          handler={handleChange}
        >
          <option value='S'>Star Rating</option>
          <option value='C'>Comment Rating</option>
          <option value='P'>Price Rating</option>
        </Select>
      </div>
      <div className={`flex justify-end gap-[10px] items-center`}>
        <button
          className={`${uiStyles.white_btn}`}
          onClick={() => {
            setShowModal(false)
          }}
        >
          Cancel
        </button>
        {!loading ? (
          <button className={`${uiStyles.hover_btn}`} onClick={save_rating}>
            Save
          </button>
        ) : (
          <div className={`${uiStyles.loading_msg} min-w-0 w-[150px]`}>
            Loading...
          </div>
        )}
      </div>
    </div>
  )
}

export default EditRating
