import { useState, useRef, useEffect } from 'react'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import uiStyles from '../../ui/uiStyles'
import ch_images from '../../../assets/images/conversations'
import { set_storage } from '../../../helpers'
const AddOnTour = ({ state, setState }) => {
  const { axiomFetch } = useAxiomFetch()
  const [search, setSearch] = useState({
    remove_clients: state?.data?.event_tour_info
      ?.filter(
        (item) =>
          item?.cont_id > 0 && item?.evt_id === state?.tour_state?.event_id
      )
      ?.map((item) => item.cont_id),
  })
  const fetchTimeout = useRef(0)
  const lastFetchtime = useRef(0)
  const searchRef = useRef(null)

  useEffect(() => {
    if (searchRef) {
      searchRef?.current?.focus()
    }
  }, [])
  const search_func = async (e) => {
    setSearch((prev) => ({ ...prev, search: e.target.value }))
    const currentTime = performance.now()
    if (currentTime - lastFetchtime?.current < 3000) {
      clearTimeout(fetchTimeout?.current)
    }
    lastFetchtime.current = currentTime
    fetchTimeout.current = setTimeout(async () => {
      setSearch((prev) => ({ ...prev, loading: true }))
      const result = await axiomFetch(
        `/tours/client/search?search=${e.target.value}`,
        'GET',
        {},
        {},
        false
      )
      setSearch((prev) => ({ ...prev, result, loading: false }))
    }, 500)
  }

  const add_client = async (client) => {
    setSearch((prev) => ({
      ...prev,
      loading_add_clients: [
        ...(prev?.loading_add_clients || []),
        client?.cont_id,
      ],
    }))
    await axiomFetch('/tours/client/add', 'POST', {
      event_id: state?.tour_state?.event_id,
      add_cont_id: client?.cont_id,
    })
    // await new Promise((resolve) =>
    //   setTimeout(() => {
    //     return resolve('done')
    //   }, [1000])
    // )
    const tour_info = state?.data?.event_tour_info?.filter(
      (item) => item?.evt_id === state?.tour_state?.event_id
    )
    const tour_max_ord = tour_info?.reduce((curr, val) => {
      return val?.tour_order > curr?.tour_order ? val : curr
    }, tour_info[0])
    const tour_max = tour_max_ord?.tour_order
    setState((prev) => {
      const tour_info = [
        ...prev?.data?.event_tour_info,
        {
          evt_id: state?.tour_state?.event_id,
          tour_order: tour_max,
          cont_id: client?.cont_id,
          agent_name: client?.cont_name,
          agent_photo: client?.agent_photo,
          hex_code: client?.hex_code,
          agent_review: client?.agent_review,
        },
      ]
      set_storage('listing_tours', {
        ...prev?.data,
        event_tour_info: tour_info,
      })
      return {
        ...prev,
        data: {
          ...prev?.data,
          event_tour_info: tour_info,
        },
      }
    })
    setSearch((prev) => ({
      ...prev,
      loading_add_clients: (prev?.loading_add_clients || []).filter(
        (cont_id) => cont_id !== client?.cont_id
      ),
      added_clients: [...(prev?.add_clients || []), client?.cont_id],
    }))
    setTimeout(() => {
      setSearch((prev) => ({
        ...prev,
        added_clients: (prev?.loading_add_clients || []).filter(
          (cont_id) => cont_id !== client?.cont_id
        ),
        remove_clients: [...(prev?.remove_clients || []), client?.cont_id],
      }))
    }, [1000])
  }

  const remove_client = async (client) => {
    setSearch((prev) => ({
      ...prev,
      loading_add_clients: [
        ...(prev?.loading_add_clients || []),
        client?.cont_id,
      ],
    }))

    await axiomFetch('/tours/client/remove', 'DELETE', {
      event_id: state?.tour_state?.event_id,
      remove_cont_id: client?.cont_id,
      attribute_id:
        state?.data?.event_pages?.find(
          (item) => item?.event_id === state?.tour_state?.event_id
        ).event_attribute || -1,
    })
    setState((prev) => {
      const tour_info = prev?.data?.event_tour_info?.filter(
        (item) => item?.cont_id !== client?.cont_id
      )

      set_storage('listing_tours', {
        ...prev?.data,
        event_tour_info: tour_info,
      })
      return {
        ...prev,
        data: {
          ...prev?.data,
          event_tour_info: tour_info,
        },
      }
    })
    setSearch((prev) => ({
      ...prev,
      loading_add_clients: (prev?.loading_add_clients || []).filter(
        (cont_id) => cont_id !== client?.cont_id
      ),
      added_clients: (prev?.added_clients || []).filter(
        (cont_id) => cont_id !== client?.cont_id
      ),
      remove_clients: (prev?.remove_clients || []).filter(
        (cont_id) => cont_id !== client?.cont_id
      ),
    }))
  }
  console.log(search)
  return (
    <div>
      <div className='px-3'>
        <input
          ref={searchRef}
          className={uiStyles.input}
          placeholder='search name, phone, email...'
          value={search?.search || ''}
          onChange={search_func}
        />
        {search?.loading ? (
          <SmallLoadingRel />
        ) : (
          <div>
            {search?.result?.length ? (
              <div className='max-h-[500px] overflow-scroll'>
                {search?.result?.map((item) => {
                  return (
                    <div
                      key={item?.cont_id}
                      className='flex justify-between items-center gap-[10px] bg-gray-100 px-[5px] py-[10px] mt-[10px] rounded shadow-md'
                    >
                      <div>
                        <p>Name: {item?.cont_name || 'NA'}</p>
                        <p>Cell: {item?.cont_cell_num || 'NA'}</p>
                        <div className='flex max-w-[200px] overflow-x-scroll gap-[3px]'>
                          <p>Email: </p> <p> {item?.cont_email || 'NA'}</p>
                        </div>
                      </div>
                      <div>
                        {search?.loading_add_clients?.includes(
                          item?.cont_id
                        ) ? (
                          <SmallLoadingRel />
                        ) : (
                          <>
                            {search?.remove_clients?.includes(item?.cont_id) ? (
                              <button
                                className={uiStyles.red_btn}
                                onClick={async () => {
                                  await remove_client(item)
                                }}
                              >
                                Remove
                              </button>
                            ) : search?.added_clients?.includes(
                                item?.cont_id
                              ) ? (
                              <div className='mr-[10px]'>
                                <img
                                  className='w-[30px]'
                                  src={ch_images.checkmark}
                                />
                              </div>
                            ) : (
                              <button
                                className={uiStyles.hover_btn}
                                onClick={async () => {
                                  await add_client(item)
                                }}
                              >
                                Add
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  )
                })}
              </div>
            ) : (
              <p>No results match your current search.</p>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default AddOnTour
