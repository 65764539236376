import React, { useCallback, useEffect, useRef, useState } from 'react'
import Wrapper from '../../layout/Wrapper'

import SubNav from '../../SubNav'
import ProgressBar from '../../ui/ProgressBar'
import TransactionsMenu from '../../ui/TransactionsMenu'
import Accordion from '../../ui/Accordion'
import Modal from '../../Modal'

import { HiOutlinePlusCircle } from 'react-icons/hi2'
import FormNewNote from '../../FormNewNote'
import { useQuery } from '@tanstack/react-query'
import { ServiceTransactions } from '../../../services/ServiceTransactions'
import { useAuth0 } from '@auth0/auth0-react'
import { useProfile } from '../../../contexts/UserContext'
import { useParams } from 'react-router'
import SmallLoading from '../../ui/SmallLoading'
import useAxiomFetch from '../../../hooks/useAxiomFetch'

export default function Notes() {
  const [showModal, setShowModal] = useState(false)
  const { axiomFetch } = useAxiomFetch()
  const { fileid } = useParams()
  const { profile } = useProfile()
  const [notes, setNotes] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [refresh, setRefresh] = useState(0)
  useEffect(() => {
    const controller = new AbortController()
    const get_data = async () => {
      setIsLoading(true)
      const data = await axiomFetch(
        `/escrow/transaction/${fileid}/notes`,
        'GET',
        {},
        {},
        false,
        controller.signal
      )
      setNotes(data)
      if (!controller.signal.aborted) {
        setIsLoading(false)
      }
    }
    get_data()
    return () => {
      controller?.abort()
    }

    // es-lint
  }, [refresh])
  return (
    <>
      {isLoading ? (
        <SmallLoading />
      ) : (
        <>
          {notes?.length ? <Accordion items={notes} /> : <></>}
          {!notes?.error && (
            <div className='mt-6'>
              <HiOutlinePlusCircle
                className='mx-auto text-5xl text-accent-250 cursor-pointer'
                onClick={() => setShowModal(true)}
              />
            </div>
          )}
          {showModal && (
            <Modal
              modalName={`create_transaction_note_file_${fileid}`}
              height='70vh'
              showModal={showModal}
              setShowModal={setShowModal}
            >
              <FormNewNote
                setShowModal={setShowModal}
                onFormSubmit={setShowModal.bind(this, false)}
                pos1={notes[0]?.pos1}
                fileid={fileid}
                setRefresh={setRefresh}
              />
            </Modal>
          )}
        </>
      )}
    </>
  )
}
