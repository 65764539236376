import React, { memo } from 'react'
import Usage from '../usage/Usage'

function ButtonsRow({
  items = [],
  handler = () => {},
  selected,
  button_style,
  scroll = false,
  padding = 'p-2',
  send_usage_report = true,
  className,
}) {
  const last = items?.length - 1
  const html = items?.map((item, index) => {
    return (
      <button
        key={index}
        className={`capitalize transition-all delay-100 flex-grow ${padding} ${
          index !== last ? 'border-r' : ''
        } border-gray-300 ${
          item?.toLowerCase() === selected?.toLowerCase()
            ? 'bg-gradient-to-b :text-white from-accent-100 to-accent-600 text-white'
            : ''
        } hover:bg-gradient-to-b hover:text-white hover:from-accent-100 hover:to-accent-600 ${button_style}`}
        onClick={() => handler(item)}
      >
        {item.replaceAll('_', ' ')}
      </button>
    )
  })

  return (
    <>
      {send_usage_report ? <Usage pageTab={selected} /> : false}
      <div
        className={`${
          scroll ? 'overflow-scroll' : 'overflow-hidden'
        } flex border rounded-md border-gray-300 ${className} .scrollbar-hide`}
      >
        {html}
      </div>
    </>
  )
}
export default memo(ButtonsRow)
