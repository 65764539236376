import React, { useState, useEffect } from 'react'
import { useListing } from '../../../contexts/ListingContext'
import ButtonsRow from '../../ui/ButtonsRow'
import ListItem from '../../ui/ListItem'
import SwipeConfig from '../../ui/SwipeConfig'
import { MarketingMaterialListingSlider } from './MarketingMaterialListingSlider'
import { DUMMYMARKETMATERIAL } from '../../../data/data'
import { useNavigate } from 'react-router-dom'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import uiStyles from '../../ui/uiStyles'
import { MarketingMaterialSlider } from './MarketingMaterialSlider'
import {
  check_sub_topic,
  date_within_days,
  just_listed_days,
  set_storage,
} from '../../../helpers'
import { useReceiver } from '../../../contexts/ReceiverContext'
import WeekenderSplash from './WeekenderSplash'
export const MarketingMaterialCreate = ({ state, setState }) => {
  const navigate = useNavigate()
  const { saveReceiver } = useReceiver()
  const [selected, setSelected] = useState(1)
  const { axiomFetch } = useAxiomFetch()
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      hit_create_page: true,
    }))
    set_storage('material_back', '/user/marketing-material')
  }, [])
  const switch_layout = state?.create_order?.switch_layout
  const topics = state?.materials?.mkt_req_topics?.filter(
    (item) => item.topic_id !== 21
  )
  const sub_topics = state?.materials?.mkt_flyer_sub_topics
  const current_listing = state?.current_listing
  const topic = state?.create_order?.order_type
  const sub_topic =
    topic === 2
      ? state?.create_order?.sub_topic_property
      : state?.create_order?.sub_topic_promotion

  const slot_group_types = state?.materials?.mkt_slot_group_types
  const filter_materials = () => {
    let materials = state?.materials?.mkt_slot_group
    materials = materials?.sort((a, b) => {
      if (a.group_id === 61) {
        return 1
      }
      if (b.group_id === 61) {
        return -1
      }
      if (
        a?.topic_sub_type <= 3 ||
        b?.topic_subtype <= 3 ||
        a?.topic_sub_type === 24 ||
        b?.topic_sub_type === 24
      ) {
        return b?.topic_subtype - a?.topic_subtype
      } else {
        return a?.topic_subtype - b?.topic_subtype
      }
    })

    materials = materials?.filter((item) => {
      return (
        item?.layout_topic === state?.create_order?.order_type &&
        check_sub_topic(
          sub_topic,
          item,
          current_listing,
          state?.create_order?.order_type
        ) &&
        item.slot_group_type === state?.create_order.slot_group_type
      )
    })

    materials = materials?.filter((item, index) => {
      if (item.group_id === 61) {
        return false
      } else {
        return true
      }
    })

    materials = materials?.reduce((curr, val) => {
      val?.items?.map((current_item) => {
        current_item.group_name = val?.group_name
        current_item.layout_topic = val?.layout_topic
        current_item.slot_group_type = val?.slot_group_type
        current_item.topic_subtype = val?.topic_subtype

        current_item.sub_items = [
          ...(current_item?.kits || []),
          ...(current_item?.ai_prompts || []),
          ...[current_item?.single_layout],
          ...(current_item?.layouts || []),
          ...(current_item?.catalog || []),
          ...(current_item?.prior_orders || []),
        ]
          ?.filter((item) => item)
          ?.filter((sub_item) => {
            if (!sub_item?.contain_id) {
              return true
            }

            const layout_ids = structuredClone(
              state?.materials?.mkt_req_container_items
            )
              ?.filter((item) => item.container_id === sub_item?.contain_id)
              ?.map((item) => item.layout_id)

            const layout = state?.materials?.mkt_req_layout?.find((item) => {
              return (
                layout_ids?.includes(item.layout_id) &&
                item.topic_subtype === current_item?.topic_subtype &&
                check_sub_topic(sub_topic, item, current_listing)
              )
            })
            return layout
          })

        if (current_item?.sub_item?.length) {
          val.sub_items = true
        }
        return current_item
      })
      return [...curr, ...val.items]
    }, [])

    if (state?.materials?.mkt_req_weekend_img?.length) {
    }

    return materials?.filter((item) => item.sub_items?.length)
  }

  const rightConfig = (item) => () => {
    return (
      <span
        className='text-md cursor-pointer'
        onClick={async () => {
          setState((prev) => ({
            ...prev,
            create_order: { ...prev?.create_order, current_selection: item },
          }))
          if (item?.layouts?.length > 1) {
            return navigate('layouts')
          } else {
            const layout =
              item?.layouts?.length === 1 ? item?.layouts?.[0] : item
            setLoading(true)
            let session = { sess_info: [] }
            const get_session = async () => {
              let try_count = 0
              session = await axiomFetch(
                `/marketing/materials/${
                  switch_layout ? 'flyer/switch' : 'flyer'
                }`,
                'POST',
                {
                  layout_id: layout?.layout_id,
                  mls_id:
                    state?.create_order?.order_type === 2
                      ? state?.current_listing?.mls_id
                      : 0,
                  sess_id: state?.create_order?.session?.sess_id,
                  sess_info: state?.create_order?.session?.sess_info,
                  double_sided: layout?.double_sided === 'Y' ? true : false,
                }
              )

              if (
                session?.sess_info?.some((item) =>
                  item?.saved_val?.includes('Unexpected token')
                ) &&
                try_count < 3
              ) {
                try_count++
                return await get_session()
              } else {
                return session
              }
            }
            session = await get_session()
            if (session?.sess_info) {
              session.sess_info = session?.sess_info?.map((item) => {
                if (item?.orig_val === 'None') {
                  item.orig_val = ''
                  item.saved_val = ''
                }
                return item
              })
            }
            if (
              session?.sess_info
                ?.filter(
                  (item) =>
                    item?.field_id !== 30064 &&
                    item?.field_id !== 55779 &&
                    item?.allow_edit === 'Y'
                )
                ?.some((item) => !item?.saved_val)
            ) {
              session.empty_fields = true
              session.edit_fields_type = 'data'
            }
            setState((prev) => ({
              ...prev,
              create_order: {
                ...prev?.create_order,
                current_layout_selection: layout,
                session,
                sessions: [],
                switch_layout: false,
                selected_fields: '',
              },
            }))
            setLoading(false)
            navigate('/user/marketing-material/layouts/customize')
          }
        }}
      >
        Select
      </span>
    )
  }
  let count = -1

  if (loading) {
    return <SmallLoadingRel />
  }
  return (
    <div>
      <div className='px-3 my-[10px]'>
        <ButtonsRow
          items={topics?.map((topic) => topic?.topic_type)}
          handler={(item) => {
            // const check_sub_topic = () => {
            //   const current_sub_topics = sub_topics?.filter(
            //     (sub) =>
            //       sub?.topic_id ===
            //       topics?.find(
            //         (topic) =>
            //           topic?.topic_type?.toLowerCase() === item?.toLowerCase()
            //       )?.topic_id
            //   )
            //   const check_topic_exists = current_sub_topics?.find(
            //     (item) => item.sub_topic_id === sub_topic
            //   )
            //   if (check_topic_exists) {
            //     return check_topic_exists?.sub_topic_id
            //   } else {
            //     return current_sub_topics?.[0]?.sub_topic_id
            //   }
            // }
            setState((prev) => ({
              ...prev,
              create_order: {
                ...prev?.create_order,
                order_type: topics?.find(
                  (topic) =>
                    topic?.topic_type?.toLowerCase() === item?.toLowerCase()
                )?.topic_id,
                // sub_topic: check_sub_topic(),
              },
            }))
          }}
          selected={topics
            ?.find((id) => id.topic_id === state?.create_order?.order_type)
            ?.topic_type?.toLowerCase()}
          button_style={'text-[12px] pl-2'}
        />
      </div>
      {state?.create_order?.order_type === 2 && !state?.listings?.length ? (
        <div className='px-3'>
          Check back here when you have an active listing!
        </div>
      ) : (
        <>
          {state?.create_order?.order_type === 2 ? (
            <MarketingMaterialListingSlider
              my_listings_look={true}
              check_sub_topic={check_sub_topic}
              state={state}
              setState={setState}
              image_link={() => {
                saveReceiver((prev) => {
                  localStorage.setItem('vbc_edit_mode', 'true')
                  return {
                    ...prev,
                    edit_mode: 'view',
                    homes: {
                      ...prev?.homes,
                      current_home: state?.current_listing,
                    },
                  }
                })
                navigate('/homes/view', {
                  state: { parent: '/user/marketing-material' },
                })
              }}
            />
          ) : (
            <div className='px-3'>
              {/* <select
                className={uiStyles.select}
                value={
                  sub_topics?.find((item) => {
                    return check_sub_topic(item.sub_topic_id)
                  })?.sub_topic_id || [44]
                }
                onChange={(e) => {
                  setState((prev) => ({
                    ...prev,
                    create_order: {
                      ...prev?.create_order,
                      sub_topic_promotion: Number(e.target.value),
                    },
                  }))
                }}
              >
                {sub_topics
                  ?.filter(
                    (item) => item.topic_id === state?.create_order?.order_type
                  )
                  ?.filter((item) => item.sub_topic_id === 61)
                  ?.map((item) => (
                    <option key={item?.sub_topic_id} value={item?.sub_topic_id}>
                      {item?.sub_topic_name}
                    </option>
                  ))}
              </select> */}
            </div>
          )}
          <div className='px-3 my-[10px]'>
            {/* <ButtonsRow
              items={slot_group_types?.map((group) => group?.type_val)}
              handler={(item) => {
                setState((prev) => ({
                  ...prev,
                  create_order: {
                    ...prev?.create_order,
                    slot_group_type: slot_group_types?.find(
                      (group) =>
                        group?.type_val?.toLowerCase() === item?.toLowerCase()
                    )?.type_id,
                  },
                }))
              }}
              selected={slot_group_types
                ?.find(
                  (group) =>
                    group.type_id === state?.create_order?.slot_group_type
                )
                ?.type_val?.toLowerCase()}
              button_style={'text-[12px] pl-2'}
            /> */}
          </div>
          <div className='max-h-[500px] overflow-scroll'>
            {filter_materials()?.length ? (
              <>
                {filter_materials()?.map((item, index) => {
                  count++
                  if (count > 5) {
                    count = 0
                  }

                  return (
                    <div
                      className='bg-gray-100 mb-[2px]'
                      key={`${index}-${item?.item_id}`}
                    >
                      {/* <div className=''>
                      <div className='border-b-2 border-gray-500 mx-[30px]'>
                        <p className='text-gray-800 font-bold text-center '>
                          {item?.slot_prompt}
                        </p>
                      </div>
                    </div> */}
                      <div className='flex items-center'>
                        <MarketingMaterialSlider
                          state={state}
                          setState={setState}
                          current_item={item}
                          sub_items={item.sub_items}
                          setLoading={setLoading}
                          weekender={sub_topic === 44}
                          check_sub_topic={check_sub_topic}
                        />
                      </div>
                    </div>
                  )
                })}
                {state?.materials?.mkt_req_weekend_img?.length &&
                state?.create_order?.order_type === 1 ? (
                  <MarketingMaterialSlider
                    state={state}
                    setState={setState}
                    current_item={state?.materials?.weekenders || []}
                    weekender={true}
                    setLoading={setLoading}
                    sub_items={state?.materials?.weekenders?.items?.map(
                      (weekenders) => {
                        return weekenders.single_layout
                      }
                    )}
                  />
                ) : state?.create_order?.order_type === 1 ? (
                  <WeekenderSplash />
                ) : (
                  ''
                )}
              </>
            ) : (
              <p className='px-3'>No Materials Match your current search</p>
            )}
          </div>
        </>
      )}
    </div>
  )
}
