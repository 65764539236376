import React, { useEffect, useState } from 'react'
import Wrapper from '../layout/Wrapper'
import SubNav from '../SubNav'
import TitleIcon from '../ui/TitleIcon'
import SubTitle from '../ui/SubTitle'
import { useVBCs } from '../../contexts/VBCContext'
import { useNavigate, useParams } from 'react-router'
import { useProfile } from '../../contexts/UserContext'
import { Link } from 'react-router-dom'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import uiStyles from '../ui/uiStyles'
import SmallLoading from '../ui/SmallLoading'
import structuredClone from '@ungap/structured-clone'

export default function EditVBC() {
  const { axiomFetch } = useAxiomFetch()
  const { getVBCByID } = useVBCs()
  const { vbcid } = useParams()
  const currentVBC = getVBCByID(vbcid)
  const [slots, setSlots] = useState([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const organize_data = (data) => {
    data = data.sort((a, b) => a.SLOT_ASSIGNMENT_ID - b.SLOT_ASSIGNMENT_ID)
    data = data.filter((slot) => slot.SLOT_ASSIGNMENT_ID !== 21)
    const assigned_slots = []
    let count = 0
    for (const slot of data) {
      if (slot.PRIORITY) {
        assigned_slots.push(slot.PRIORITY)
      }
    }

    data = data.map((da, index) => {
      let second_count = 0
      let slot_options = data.map((slot, index) => {
        count++
        return {
          allready_filled:
            assigned_slots.includes(slot.SLOT_ASSIGNMENT_ID) &&
            slot.SLOT_ASSIGNMENT_ID !== da.PRIORITY,
          id: count,
          ...slot,
        }
      })
      second_count = `0${count}`
      return {
        index: index + 1,
        id: second_count,
        slot_assignment: da.PRIORITY,
        orig_slot: da.SLOT_ASSIGNMENT_ID,
        slot_options,
      }
    })
    return structuredClone(data)
  }

  const reorganize_data = (data) => {
    const assigned_slots = []
    let count = 0
    for (const slot of data) {
      if (slot.slot_assignment) {
        assigned_slots.push(slot.slot_assignment)
      }
    }

    for (const slot of data) {
      for (const option of slot.slot_options) {
        if (
          assigned_slots.includes(option.SLOT_ASSIGNMENT_ID) &&
          option.SLOT_ASSIGNMENT_ID !== slot.slot_assignment
        ) {
          option.allready_filled = true
        } else {
          option.allready_filled = false
        }
      }
    }
    return structuredClone(data)
  }

  const save_all = async () => {
    const controller = new AbortController()
    for (const slot of slots) {
      let body
      if (slot.updated) {
        body = {
          priority: slot.slot_assignment,
          slotAssignmentId: slot.orig_slot,
        }
        let data
        try {
          data = await axiomFetch(
            `/products/vbc/${vbcid}/slots`,
            'PATCH',
            body,
            {},
            false,
            controller.signal
          )
          localStorage.removeItem('slots')
          localStorage.removeItem('card')
        } catch (error) {
          console.log(error)
        }
      }
    }
  }
  useEffect(() => {
    setLoading(true)
    const get_slots = async () => {
      let data = await axiomFetch(`/products/vbc/${vbcid}/slots`)
      data = organize_data(data)
      setSlots(data)
      setLoading(false)
    }
    get_slots()
  }, [])

  let slotsHTML = (
    <div className={`flex flex-col gap-4`}>
      {slots.map((slot) => {
        return (
          <div className={`bg-gray-100 rounded-md p-3 shadow-md`} key={slot.id}>
            <h4 className='text-black mt-1 mb-2 text-md '>Slot {slot.index}</h4>
            {slot.slot_options.map((option, index) => {
              return (
                <div key={option.id} className='flex gap-[10px]'>
                  <label
                    className={`block capitalize relative ${
                      option.allready_filled && 'line-through'
                    }`}
                  >
                    <input
                      className='absolute left-0 right-0 top-0 h-full opacity-0'
                      type='checkbox'
                      name={option.NAME}
                      value={option.SLOT_ASSIGNMENT_ID}
                      checked={
                        option.SLOT_ASSIGNMENT_ID === slot.slot_assignment
                      }
                      onChange={(e) => {
                        slot.updated = true

                        if (!option.allready_filled) {
                          console.log('hello', e.target.value)
                          if (Number(e.target.value) === slot.slot_assignment) {
                            slot.slot_assignment = null
                            let new_data = reorganize_data(slots)
                            console.log(new_data)
                            setSlots(new_data)
                          } else {
                            slot.slot_assignment = Number(e.target.value)

                            let new_data = reorganize_data(slots)
                            console.log(new_data)
                            setSlots(new_data)
                          }
                        }
                      }}
                    />
                    <div
                      className={`transition-all delay-100 toggle inline-flex w-[36px] h-[20px] rounded-2xl bg-gradient-to-b ${
                        option.SLOT_ASSIGNMENT_ID === slot.slot_assignment
                          ? 'from-accent-150 to-accent-250'
                          : 'from-gray-200 to-gray-200'
                      } p-[2px] mr-2 align-middle`}
                    >
                      <span
                        className={`bg-white handle transition-all delay-100  w-[16px] h-[16px] rounded-full inline-block  ${
                          option.SLOT_ASSIGNMENT_ID === slot.slot_assignment &&
                          'transform translate-x-4'
                        }`}
                      ></span>
                    </div>
                    {/* {optionCounter + 1 === slot.PRIORITY ? slot.NAME : option.NAME} */}
                    {option.NAME}
                  </label>
                  {option?.NAME?.toLowerCase() === 'my guys' ? (
                    <button
                      className={uiStyles.blue_grd_text}
                      onClick={() => {
                        navigate('/user/my-vbc/services')
                      }}
                    >
                      Add My Guys
                    </button>
                  ) : (
                    ''
                  )}
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )

  return (
    <Wrapper>
      <div className='flex justify-between items-center'>
        <SubNav url={'/user/my-vbc'} showLogo={false} />
        <div className='flex gap-3 mx-4 text-black'>
          <Link to={`/user/my-vbc/${vbcid}`} state={{ name: currentVBC?.NAME }}>
            Preview
          </Link>
          {/* |
        <Link>Send</Link> */}
        </div>
      </div>
      {/* <TitleIcon className='mb-5'>My Realty Smart Business Card</TitleIcon> */}
      <div className=' px-4'>
        {/* <PageSubMenu selected={INNERMENU.VBC} /> */}
        <SubTitle className='mt-4'>
          Lets update your {currentVBC && currentVBC.NAME}
        </SubTitle>
        <p className='text-gray-500 text-sm mb-3'>
          Select content you want to show on the Smart Business Card.{' '}
        </p>
        <div className='py-4 max-h-[550px] overflow-y-scroll overflow-x-hidden flex flex-col gap-2'>
          {!loading ? slotsHTML : <SmallLoading />}
        </div>
        {!loading && (
          <div className='flex justify-end mt-2'>
            <button
              className={uiStyles.hover_btn}
              onClick={async () => {
                setLoading(true)
                await save_all()
                setLoading(false)
              }}
            >
              Save
            </button>
          </div>
        )}
      </div>
    </Wrapper>
  )
}
