import React from 'react'
import { Outlet } from 'react-router-dom'
import ErrorBoundary from '../../components/ErrorBoundy'

const PublicWrapper = ({ children }) => {
  return (
    <div className=' font-base text-sm text-gray-500 overflow-hidden rounded-2xl max-w-[375px] mx-auto bg-user-wrapper relative '>
      <div className='p-[10px]'>{children}</div>
    </div>
  )
}

export default PublicWrapper
