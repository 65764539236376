import React, { useState } from 'react'
import UserMenuItem from './ui/UserMenuItem'
import { USERMENU } from '../data/data'
import SwipeConfig from './ui/SwipeConfig'
import { Link } from 'react-router-dom'

export default function UserMenu() {
  const [selected, setSelected] = useState(1)
  const settingsRight = (item) => () => <Link to={item.link}>Open</Link>

  const htmlMenu = USERMENU.map((item, index) => (
    <SwipeConfig
      key={index}
      index={index}
      selectedIndex={selected}
      setSelectedIndex={setSelected}
      RightComponents={settingsRight(item)}
      className='mb-[2px]'
    >
      <UserMenuItem
        link={item.link}
        icon={item.icon}
        title={item.title}
        description={item.description}
      />
    </SwipeConfig>
  ))
  return htmlMenu
}
