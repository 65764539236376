import { useState, useEffect } from 'react'
import { AiFillDelete } from 'react-icons/ai'
import { TfiPencilAlt } from 'react-icons/tfi'
import { useNavigate } from 'react-router-dom'
import { useListingTours } from '../../../contexts/ListingToursContext'
import { useReceiver } from '../../../contexts/ReceiverContext'
import { set_storage } from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { List } from '../../helpers'
import Modal from '../../Modal'
import uiStyles from '../../ui/uiStyles'
import EditAnnouncement from './EditAnnouncement'
import RemoveAnnouncement from './RemoveAnnouncement'

const AddAnnouncements = () => {
  const { listingTours, saveListingTours, get_homes, is_buyer_tour } =
    useListingTours()
  console.log('tours', listingTours)
  const { axiomFetch } = useAxiomFetch()
  const [homes, setHomes] = useState([])
  const [inputValue, setInputValue] = useState(
    listingTours?.buyer_tours?.buyer_edit || {}
  )
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('')

  const modals = {
    edit_ann: (
      <EditAnnouncement
        state={listingTours}
        setState={saveListingTours}
        setShowModal={setShowModal}
      />
    ),
    remove_ann: (
      <RemoveAnnouncement
        state={listingTours}
        setState={saveListingTours}
        setShowModal={setShowModal}
      />
    ),
  }

  const create_ann = (item, type) => {
    saveListingTours((prev) => {
      console.log('item', item)
      const ret_data = { ...prev?.buyer_tours, ann_edit: item }
      set_storage('buyer_tours', ret_data)
      return { ...prev, buyer_tours: ret_data }
    })
    setShowModal(true)
    setModalType(type)
  }
  useEffect(() => {
    if (!listingTours?.buyer_tours) {
      navigate('/user/buyer_tours')
    }
  }, [])
  useEffect(() => {
    const data = listingTours?.data?.event_tour_ann
      ?.filter(
        (item) =>
          item?.event_id?.toString() ===
          listingTours?.buyer_tours?.event_edit?.event_id?.toString()
      )
      ?.sort((a, b) => a.ann_ord - b.ann_ord)
      ?.map((item) => {
        item.title = item?.ann_title
        item.content = item?.ann_desc
        item.image = item?.ann_url
        item.swipe_right = () => {
          return (
            <div className='flex gap-[30px]'>
              <div
                className='cursor-pointer'
                onClick={() => {
                  create_ann(item, 'edit_ann')
                }}
              >
                <TfiPencilAlt size={25} />
              </div>
              <div
                className='cursor-pointer'
                onClick={() => {
                  create_ann(item, 'remove_ann')
                }}
              >
                <AiFillDelete size={25} />
              </div>
            </div>
          )
        }
        return item
      })
    setHomes(data)
  }, [listingTours?.data?.event_tour_ann])

  return (
    <div>
      <p className={`px-3 mb-[5px] ${uiStyles.text_black_subtitle}`}>
        Announcements on Tour
      </p>
      <div className='overflow-y-scroll min-h-[100px] max-h-[400px]'>
        <List
          add_arrow_order={true}
          handle_arrow={async (item, list, index, dir) => {
            const get_old_order = list[index + (dir === 'up' ? 1 : 0)]?.ann_ord
            const get_new_order =
              list[index + (dir === 'down' ? 1 : 0)]?.ann_ord
            const re_ordered_list = list?.map((item) => {
              if (item.ann_ord === get_old_order) {
                item.ann_ord = get_new_order
                return item
              } else if (item.ann_ord === get_new_order) {
                item.ann_ord = get_old_order
                return item
              } else {
                return item
              }
            })

            saveListingTours((prev) => {
              const data = {
                ...prev?.data,
                event_tour_ann: [
                  ...prev?.data?.event_tour_ann?.filter(
                    (ann) =>
                      ann?.event_id?.toString() !== item?.event_id?.toString()
                  ),
                  ...re_ordered_list,
                ],
              }
              set_storage('listing_tours', data)
              return { ...prev, data }
            })
            await axiomFetch('/tours/announcement/order', 'POST', {
              list: re_ordered_list,
            })
          }}
          no_items_msg={'Add an annoucement to the tour.'}
          list={homes}
          reset_slider={true}
        />
      </div>
      <div className='px-3 mt-3 flex justify-end gap-[10px]'>
        <button
          className={`${uiStyles.hover_btn}`}
          onClick={() => {
            create_ann({}, 'edit_ann')
          }}
        >
          {'Create Announcement'}
        </button>
      </div>
      <div className={`${uiStyles.line_3px} mt-[10px]`}></div>
      <div className='px-3 mt-3 flex justify-end gap-[10px]'>
        {get_homes(listingTours?.buyer_tours?.event_edit?.event_id)?.length ? (
          <button
            className={`${uiStyles.white_btn}`}
            onClick={() => {
              return navigate('/user/buyer_tours')
            }}
          >
            Done
          </button>
        ) : (
          <>
            <button
              className={`${uiStyles.white_btn}`}
              onClick={() => {
                navigate(-1)
              }}
            >
              Back
            </button>
            {is_buyer_tour() ? (
              <button
                className={`${uiStyles.hover_btn}`}
                onClick={() => {
                  navigate('/user/buyer_tours/add_buyer')
                }}
              >
                Add Buyers
              </button>
            ) : (
              <button
                className={`${uiStyles.hover_btn}`}
                onClick={() => {
                  navigate('/user/buyer_tours/add_home')
                }}
              >
                Add Homes
              </button>
            )}
          </>
        )}
      </div>
      {showModal ? (
        <Modal
          modalName='create_buyer_tour'
          showModal={showModal}
          setShowModal={setShowModal}
          // width={'w-[350px]'}
          side_padding={['add_buyer'].includes(modalType) ? 'px-0' : ''}
          height={
            modalType === 'edit_buyer'
              ? 'h-[300px]'
              : modalType?.includes('remove')
              ? 'h-[175px]'
              : 'h-[550px]'
          }
        >
          {modals?.[modalType]}
        </Modal>
      ) : (
        ''
      )}
    </div>
  )
}

export default AddAnnouncements
