import React, { useCallback, useEffect, useRef, useState } from 'react'

import SubNav from '../SubNav'
import TitleIcon from '../ui/TitleIcon'
// import PageSubMenu from '../PageSubMenu';
// import { INNERMENU } from '../../data/data';
import PropertyItem from '../ui/PropertyItem'
import SwipeConfig from '../ui/SwipeConfig'
import {
  FaLink,
  FaFacebook,
  // FaTwitter,
  // FaLinkedin
} from 'react-icons/fa'
import { useAuth0 } from '@auth0/auth0-react'
import { ServiceClientListing } from '../../services/ServiceClientListing'
import { useQuery } from '@tanstack/react-query'
import Pagination from '../ui/Pagination'
import Loading from '../Loading'
import { useProfile } from '../../contexts/UserContext'
import Wrapper from '../layout/Wrapper'
import { Link, useNavigate } from 'react-router-dom'
import home_placeholder from '../../assets/images/Home_stock.jpeg'
import { useMktReqs } from '../../contexts/MktReqsContext'
import { useReceiver } from '../../contexts/ReceiverContext'
import { SmallLoadingRel } from '../ui/SmallLoadingRel'
import { listing_status } from '../../helpers'

export default function MyListing() {
  const [selected, setSelected] = useState(1)
  const { getAccessTokenSilently } = useAuth0()
  const { profile } = useProfile()
  const navigate = useNavigate()
  const { mktReqs, saveMktReqs } = useMktReqs()
  const { receiver, saveReceiver } = useReceiver()
  const fetchController = useRef(null)
  const params = useRef({
    skip: 0,
    limit: 5,
  })

  // useEffect(() => {
  //   const get_facebook_pages = async () => {

  //   }
  // })
  const getListing = useCallback(async () => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUDIENCE,
    }).catch((err) => null)

    const serviceClientListing = new ServiceClientListing(token)
    if (!fetchController.current) {
      fetchController.current = serviceClientListing.getController().controller
    }
    return serviceClientListing.getAll(params.current)
  }, [params, getAccessTokenSilently])

  const { isLoading, isError, data, refetch, isFetching } = useQuery({
    queryKey: ['listing', params.current],
    queryFn: getListing,
    refetchOnWindowFocus: false,
    enabled: !!profile,
  })

  const showNext = () => {
    if (!isLoading && !isFetching) {
      params.current = {
        ...params.current,
        skip: params.current.skip + params.current.limit,
      }
      refetch()
    }
  }
  const showPrev = () => {
    if (!isLoading && !isFetching) {
      params.current = {
        ...params.current,
        skip: params.current.skip - params.current.limit,
      }
      refetch()
    }
  }
  const rightConfig = (item) => () =>
    (
      <div className='flex gap-[5px]'>
        <div
          className='cursor-pointer'
          onClick={() => {
            saveMktReqs((prev) => ({ ...prev, current_listing: item }))
            navigate('/user/marketing-material', {
              state: { parent: '/user/my-listing' },
            })
          }}
        >
          Materials
        </div>
        <span> | </span>
        <div
          className='cursor-pointer'
          to={`${item?.mls_id}`}
          onClick={() => {
            saveReceiver((prev) => {
              localStorage.setItem('vbc_edit_mode', 'true')
              return {
                ...prev,
                edit_mode: 'view',
                homes: { ...prev?.homes, current_home: item },
              }
            })
            navigate('/homes/view', {
              state: { parent: '/user/my-listing' },
            })
          }}
        >
          View
        </div>
      </div>
    )
  const leftConfig = (item) => () => {
    return (
      <>
        <a
          href={`${item?.home_photo1 || home_placeholder}`}
          target='_blank'
          rel='noreferrer'
          className='w-8 h-8 leading-8 flex items-center justify-center  bg-white rounded-md mx-1 text-gray-300   hover:text-primary'
        >
          <FaLink className='text-xl' />
        </a>
        {/* &from=101648389479183&to=101648389479183 */}
        <a
          href={`https://www.facebook.com/dialog/feed?app_id=2447727492044346&display=popup&link=${
            item?.home_photo1 ||
            'https://www.driggstitle.com/wp-content/uploads/2023/04/Home_stock-jpeg.webp'
          }&redirect_uri=${
            process.env.REACT_APP_API_ROOT
          }/v1/social/facebook/facebook_redirect`}
          target='_blank'
          rel='noreferrer'
          className='w-8 h-8 leading-8 flex items-center justify-center  bg-white rounded-md mx-1 text-gray-300 hover:text-[#1877f2]'
        >
          <FaFacebook className=' text-xl' />
        </a>
        {/* <a
          href="https://www.twitter.com"
          target="_blank"
          rel="noreferrer"
          className="w-8 h-8 leading-8 flex items-center justify-center  bg-white rounded-md mx-1 text-gray-300 hover:text-[#1DA1F2]"
        >
          <FaTwitter className="text-xl" />
        </a>
        <a
          href="https://www.linkedin.com"
          target="_blank"
          rel="noreferrer"
          className="w-8 h-8 leading-8 flex items-center justify-center  bg-white rounded-md mx-1 text-gray-300 hover:text-[#0A66C2]"
        >
          <FaLinkedin className="text-xl" />
        </a> */}
      </>
    )
  }
  useEffect(() => {
    return () => fetchController.current && fetchController.current.abort()
  }, [])
  const htmlListing = (
    data
      ?.sort((a, b) => new Date(b?.list_date) - new Date(a?.list_date))
      ?.sort(
        (a, b) =>
          listing_status[a?.mls_status]?.order -
          listing_status[b?.mls_status]?.order
      ) || []
  )?.map((item, index) => (
    <SwipeConfig
      key={index}
      index={index}
      selectedIndex={selected}
      setSelectedIndex={setSelected}
      RightComponents={rightConfig(item)}
      LeftComponents={leftConfig(item)}
      widthConfigRight={44}
      widthConfigLeft={44}
    >
      <PropertyItem listing={item} />
      <hr className='border-white' />
    </SwipeConfig>
  ))
  if (!isError && isLoading && isFetching) {
    return (
      <Wrapper>
        <SubNav />
        <TitleIcon className='mb-5'>My Listings</TitleIcon>
        <SmallLoadingRel />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <SubNav />
      <TitleIcon className='mb-5'>My Listings</TitleIcon>
      {/* <div className="pt-2 pb-4 px-4">
        <PageSubMenu selected={INNERMENU.MyListings} />
      </div> */}
      {data?.length ? (
        htmlListing
      ) : (
        <p className='px-4'>Check back here when you have listings with us.</p>
      )}
      <div className='flex justify-center px-5 py-3'>
        <Pagination
          showPrev={data && params.current.skip > 0}
          showNext={data && data.length >= params.current.limit}
          clickNextHandler={showNext}
          clickPrevHandler={showPrev}
          pageNum={
            (params.current.skip + params.current.limit) / params.current.limit
          }
        />
      </div>
    </Wrapper>
  )
}
