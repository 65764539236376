import React, { useState } from 'react'
import Wrapper from '../../components/layout/Wrapper'
import { Conversation } from '../../components/pages/conversations'
import SubNav from '../../components/SubNav'
import { useConversations } from '../../contexts/ConversationsContext'
import { useParams } from 'react-router-dom'
export default function PageConversation() {
  const { id } = useParams()
  const { conversations, convMessages } = useConversations()
  const [, setCurrentConversation] = useState()
  return (
    <Wrapper>
      <Conversation
        conversation={conversations.filter((item) => item.sid === id)[0]}
        currentMessages={convMessages[id]}
        setCurrentConversation={setCurrentConversation}
      />
    </Wrapper>
  )
}
