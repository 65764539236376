import { useEffect, useRef, useState } from 'react'
import images_tours from '../../assets/images/listing_tours'
import { useCalc } from '../../contexts/CalcContext'
import {
  capitalize,
  convert_percent,
  format_currency,
  format_input,
  validate_number,
} from '../../helpers'
import LabelInput from '../form/LabelInput'
import LabelText from '../form/LabelText'
import { Schedule } from '../form/Schedule'
import Select from '../form/Select'
import Textarea from '../form/Textarea'
import uiStyles from '../ui/uiStyles'
import CalcSellOfferStar from './CalcSellOfferStar'

const CalcSellOffer = () => {
  const {
    calc,
    saveCalc,
    get_offer_name,
    show_offer_page,
    edit_offer,
    initial_offer,
    get_loan_type,
    calc_tax_proration,
    get_title_fee_total,
    get_fees_total,
    sell_offer_names,
    sell_offer_placeholders,
  } = useCalc()

  const [error, setError] = useState()
  const timeoutRef = useRef(null)
  const template = {
    values: {
      offer_price: initial_offer ? calc?.sell?.values?.sale_price : '',
      down_payment: '',
      buyer_agent_name: '',
      earnest_money: '',
      home_warranty: '',
      buyer_credit: initial_offer ? calc?.sell?.values?.buyer_credit : '',
      closing_date: calc?.sell?.values?.closing_date || '',
      buyer_broker_fee:
        calc?.sell?.defaults?.[get_loan_type('sell', 'defaults')]
          ?.buyer_broker_fee || '',
      misc: initial_offer ? calc?.sell?.values?.misc : '',
      escalation_clause: '',
      contingency: '',
      notes: '',
    },
    names: sell_offer_names,
    types: {
      offer_price: '$',
      down_payment: '%',
      buyer_agent_name: 'text',
      earnest_money: '%',
      home_warranty: '$',
      closing_date: 'date',
      buyer_broker_fee: '%',
      misc: '$',
      escalation_clause: 'multiline',
      contingency: 'multiline',
      notes: 'multiline',
      buyer_credit: '$',
      net_at_close: '$',
    },
    placeholders: sell_offer_placeholders,
  }
  const get_offer_template = (options) => {
    const items = calc?.offers?.items || {}
    const gen_template = {
      data_options: {
        ...calc?.offers?.items?.[get_offer_name()]?.data_options,
        number: Object.keys(items)?.length,
        key: get_offer_name(),
        name: get_offer_name('str'),
      },
      values: {
        ...template?.values,
        ...calc?.offers?.items?.[get_offer_name()]?.values,
      },
      types: {
        ...template?.types,
        ...calc?.offers?.items?.[get_offer_name()]?.types,
      },
      placeholders: {
        ...template?.placeholders,
        ...calc?.offers?.items?.[get_offer_name()]?.placeholders,
      },
      names: {
        ...template?.names,
        ...calc?.offers?.items?.[get_offer_name()]?.names,
      },
    }

    return gen_template
  }
  const add_offer = () => {
    const template = get_offer_template()
    const sell_values = calc?.sell?.values
    const offer_price = validate_number(template?.values?.offer_price)

    let timeout = timeoutRef?.current
    if (!offer_price) {
      if (timeout) {
        clearTimeout(timeout)
      }
      timeout = null
      setError((prev) => {
        return {
          msg: 'Offer Price is required.',
          show_error: true,
        }
      })
      timeout = setTimeout(() => {
        setError((prev) => {
          return {
            msg: 'Offer Price is required.',
            show_error: false,
          }
        })
      }, [3000])
      return
    }
    console.log('offer_price', offer_price)
    const loan_balance = validate_number(calc?.sell?.values?.loan_balance)
    const misc = validate_number(template?.values?.misc || sell_values?.misc)
    const repairs = validate_number(
      template?.values?.repairs || sell_values?.repairs
    )
    const buyer_credit = validate_number(template?.values?.buyer_credit)
    const taxes = convert_percent(
      offer_price,
      template?.values?.taxes || sell_values?.taxes,
      calc?.sell?.types?.taxes
    )
    const broker_fee = validate_number(calc?.sell?.values?.broker_fee)
    const get_broker_fee = () => {
      return offer_price * (broker_fee / 100)
    }

    const get_split_broker_fee = () => {
      const defaults = calc?.sell?.defaults?.[get_loan_type('sell', 'values')]
      const buyer_broker_fee = validate_number(
        template?.values?.buyer_broker_fee || defaults?.buyer_broker_fee
      )
      const seller_broker_fee = validate_number(defaults?.listing_broker_fee)
      console.log(seller_broker_fee, buyer_broker_fee)
      return offer_price * ((buyer_broker_fee + seller_broker_fee) / 100)
    }

    const closing_costs =
      get_title_fee_total('sell', offer_price) +
      get_fees_total('sell', 'other_fees', offer_price) -
      get_broker_fee() +
      get_split_broker_fee()

    const closing_date =
      template?.values?.closing_date || sell_values?.closing_date
    const prorated_taxes = calc_tax_proration('sell', taxes, closing_date)
    const home_warranty = template?.values?.home_warranty

    const net_at_close = validate_number(
      offer_price -
        validate_number(loan_balance) -
        prorated_taxes -
        closing_costs -
        validate_number(misc) -
        validate_number(repairs) -
        validate_number(home_warranty) +
        validate_number(buyer_credit),
      0
    )
    saveCalc((prev) => ({
      ...prev,
      offers: {
        ...prev?.offers,
        data_options: {
          ...prev?.offers?.data_options,
          new_offer: false,
          on_last_offer: false,
          edit_offer: '',
        },
        items: {
          ...prev?.offers?.items,
          [get_offer_name()]: {
            ...get_offer_template(),
            values: {
              ...get_offer_template()?.values,
              net_at_close,
              closing_costs: validate_number(closing_costs, 2),
              prorated_taxes: validate_number(prorated_taxes, 2),
            },
          },
        },
      },
    }))
  }

  const update_input = (options) => {
    const calculator_type = 'offers'
    const offer = get_offer_name()
    const { name, value } = format_input(options)
    let type =
      options?.type ||
      calc?.[calculator_type]?.items?.[offer]?.types?.[`${name}`] ||
      ''
    saveCalc((prev) => {
      const result = {
        ...prev,
        [calculator_type]: {
          ...prev?.[calculator_type],
          data_options: {
            ...prev?.[calculator_type]?.data_options,
            on_last_offer: true,
          },
          items: {
            ...prev?.[calculator_type]?.items,
            [offer]: {
              ...prev?.[calculator_type]?.items?.[offer],
              values: {
                ...template?.values,
                ...prev?.[calculator_type]?.items?.[offer]?.values,

                [name]: value,
              },
              types: {
                ...template?.types,
                ...prev?.[calculator_type]?.items?.[offer]?.types,
                [name]: type,
              },
            },
          },
        },
      }
      return result
    })
  }

  const get_inputs = (calculator_type = 'buy') => {
    const offer = get_offer_name()
    const types =
      calc?.[calculator_type]?.items?.[offer]?.types || template?.types
    const names =
      calc?.[calculator_type]?.items?.[offer]?.names || template?.names
    const placeholders =
      calc?.[calculator_type]?.items?.[offer]?.placeholders ||
      template?.placeholders
    return Object.entries(
      calc?.[calculator_type]?.items?.[offer]?.values || template?.values
    )
      ?.filter(
        ([key, value]) =>
          !['net_at_close', 'closing_costs', 'prorated_taxes'].includes(key)
      )
      ?.map(([key, value]) => {
        const type = types[key]
        const name = names[key]
        const placeholder = placeholders[key] || ''
        const label_width = 'w-[80%]'
        const initial_data = {
          left_label: name,
          left_label_width: label_width,
          name: key,
          value,
          placeholder,
          show_delete_all: true,
        }

        if (
          type === '%' ||
          type === '$' ||
          type === '%$' ||
          type === 'm' ||
          type === 'a'
        ) {
          initial_data.inputMode = 'decimal'
        }
        if (type === '%') {
          initial_data.show_percent = true
          initial_data.percent_value = '%'
          initial_data.handleChange = (e, type) => {
            update_input({ e, type, decimal: 3, original: value })
          }
        }
        if (type === '$') {
          initial_data.show_dollar = true
          initial_data.percent_value = ''
          initial_data.value = initial_data?.value
            ? `$${initial_data.value}`
            : initial_data?.value || ''
        }
        const both_type_keys = ['earnest_money', 'down_payment']
        if (both_type_keys.includes(key)) {
          initial_data.show_percent = true
          initial_data.show_dollar = true
          initial_data.percent_value = types?.[key] === '%' ? '%' : ''
          initial_data.dollar_percent = types?.[key]
          if (key === 'hoa' || key === 'other' || key === 'utilities') {
            initial_data.toggle_values = ['a', 'm']
            initial_data.dollar_percent = 'm'
          }
          initial_data.left_label_width = 'w-[135%]'
          initial_data.delete_all_position = 'right-[75px]'
          initial_data.total = validate_number(
            calc?.['sell']?.values?.sale_price
          )
          initial_data.handleChange = (e, type) => {
            update_input({ e, type, decimal: 3, original: value })
          }
        }

        let result = (
          <LabelInput
            {...initial_data}
            handleChange={
              initial_data?.handleChange
                ? initial_data?.handleChange
                : (e) => {
                    update_input({ e, type, decimal: 2, original: value })
                  }
            }
          />
        )

        if (type === 'date') {
          result = (
            <Schedule
              left_label={name}
              left_label_width={label_width}
              name={key}
              show_time={false}
              schedule_date={value}
              handle_change={(e) => {
                update_input({ e, type, decimal: 2, original: value })
              }}
            />
          )
        }
        if (type === 'options') {
          const options = calc?.[calculator_type]?.option_values?.[key]
          result = (
            <Select
              left_label={name}
              left_label_width={label_width}
              no_margin={false}
              className=''
              name={key}
              value={value || 'Conventional'}
              handler={(e) => {
                update_input({ e, type, decimal: 2, original: value })
              }}
              placeholder=''
            >
              {[...options].map((item) => {
                return (
                  <option key={item} value={item}>
                    {item}
                  </option>
                )
              })}
            </Select>
          )
        }
        if (type === 'multiline') {
          result = (
            <LabelText
              left_label={name}
              left_label_width={label_width}
              no_margin={false}
              container_class='mb-[10px]'
              name={key}
              value={value}
              handleChange={(e) => {
                update_input({ e, type, decimal: 2, original: value })
              }}
              placeholder={placeholder || ''}
            />
          )
        }

        return <div key={key}>{result}</div>
      })
  }

  return (
    <div>
      <div className='flex flex-col justify-between min-h-[550px]'>
        <div className='px-3'>
          <div className='flex justify-between'>
            <div className='flex items-center gap-[5px] mt-[10px] mb-[10px]'>
              <CalcSellOfferStar
                on_last_offer={true}
                initial_offer={initial_offer}
                gen_template={get_offer_template}
              />
              <p>
                {template?.values?.offer_price
                  ? `$${format_currency(
                      template?.values?.offer_price,
                      false,
                      2
                    )?.replace('$', '')}`
                  : get_offer_name('string')}
              </p>
            </div>
            <div></div>
          </div>
          <div className='max-h-[450px] overflow-y-scroll'>
            {get_inputs('offers')}
          </div>
        </div>
        <div className='flex'>
          {(get_offer_name() !== 'offer_1' || edit_offer) &&
          !calc?.offers?.items?.[get_offer_name()]?.values?.offer_price ? (
            <button
              className={`${uiStyles.white_btn} w-full rounded-none`}
              onClick={() => {
                const items = calc?.offers?.items
                if (!edit_offer) {
                  delete items?.[get_offer_name()]
                }
                saveCalc((prev) => ({
                  ...prev,
                  offers: {
                    ...prev?.offers,
                    data_options: {
                      ...prev?.offers?.data_options,
                      new_offer: false,
                      on_last_offer: false,
                      edit_offer: '',
                    },
                    items,
                  },
                }))
              }}
            >
              Back{' '}
            </button>
          ) : (
            ''
          )}
          <>
            {error?.show_error ? (
              <div className={`${uiStyles.failure_msg} mb-[10px]`}>
                {error?.msg}
              </div>
            ) : (
              <button
                className={`${uiStyles.hover_btn} w-full rounded-none`}
                onClick={add_offer}
              >
                {edit_offer ? 'Update Offer' : 'Add Offer'}
              </button>
            )}
          </>
        </div>
      </div>
    </div>
  )
}

export default CalcSellOffer
