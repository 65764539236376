import { useEffect, useState, useRef } from 'react'
import { useCalc } from '../../contexts/CalcContext'
import {
  get_uuid,
  format_input,
  validate_number,
  capitalize,
  format_currency,
  convert_percent,
  pick_keys,
  get_storage,
} from '../../helpers'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import LabelInput from '../form/LabelInput'
import { Schedule } from '../form/Schedule'
import Select from '../form/Select'
import uiStyles from '../ui/uiStyles'
import CalcClosingCosts from './CalcClosingCosts'
import CalcShare from './CalcShare'

const CalcTitleFees = () => {
  const { calc, saveCalc, get_title_fees, get_principal, get_calc_type_id } =
    useCalc()
  const { axiomFetch } = useAxiomFetch()
  const [error, setError] = useState({})
  const calcTimeoutRef = useRef()
  const update_input = (options) => {
    const calculator_type = 'title_fees'
    const { name, value } = format_input(options)
    let type =
      options?.type || calc?.[calculator_type]?.types?.[`${name}`] || ''
    saveCalc((prev) => {
      const result = {
        ...prev,
        [calculator_type]: {
          ...prev?.[calculator_type],
          values: { ...prev?.[calculator_type]?.values, [name]: value },
          types: { ...prev?.[calculator_type]?.types, [name]: type },
        },
      }
      return result
    })
  }
  const get_inputs = (calculator_type = 'title_fees') => {
    const types = calc?.[calculator_type]?.types
    const names = calc?.[calculator_type]?.names
    const placeholders = calc?.[calculator_type]?.placeholders
    console.log(calc?.[calculator_type]?.values)

    return Object.entries(calc?.[calculator_type]?.values)
      ?.filter(([key, value]) => {
        const program_type = calc?.[calculator_type]?.values?.program_type
        if (
          (program_type === 'refi' &&
            (key === 'home_price' || key === 'down_payment')) ||
          (program_type !== 'refi' && key === 'refi_amount') ||
          (program_type === 'buyer_cash' && key === 'down_payment') ||
          (program_type === 'seller' && key === 'down_payment')
        ) {
          return false
        } else if (key === 'extra_payment' || key === 'interest_saved') {
          return false
        } else {
          return true
        }
      })
      ?.map(([key, value]) => {
        const type = types[key]
        const name = names[key]
        const placeholder = placeholders[key] || ''
        const label_width = 'w-[80%]'
        const initial_data = {
          left_label: name,
          left_label_width: label_width,
          name: key,
          value,
          placeholder,
        }

        if (type === '%' || type === '$' || type === '%$') {
          initial_data.show_delete_all = true
          initial_data.inputMode = 'decimal'
        }
        if (type === '%') {
          initial_data.show_percent = true
          initial_data.percent_value = '%'
          if (key === 'loan_term') {
            initial_data.percent_value = ' years'
          }
        }
        if (type === '$') {
          initial_data.show_dollar = true
          initial_data.percent_value = ''
          initial_data.value = initial_data?.value
            ? `$${initial_data.value}`
            : initial_data?.value || ''
        }
        if (key === 'down_payment') {
          initial_data.show_percent = true
          initial_data.show_dollar = true
          initial_data.percent_value =
            calc?.[calculator_type]?.types?.[key] === '%' ? '%' : ''
          initial_data.dollar_percent = calc?.[calculator_type]?.types?.[key]
          initial_data.left_label_width = 'w-[135%]'
          initial_data.delete_all_position = 'right-[75px]'
          initial_data.total = validate_number(
            calculator_type === 'sell'
              ? calc?.[calculator_type]?.values?.sale_price
              : calc?.[calculator_type]?.values?.home_price
          )
          initial_data.handleChange = (e, type) => {
            update_input({ e, type, decimal: 3, original: value })
          }
        }

        if (
          key === 'home_price' ||
          key === 'refi_amount' ||
          key === 'down_payment'
        ) {
          initial_data.onKeyUp = (e) => {
            // update_input({ e, type, decimal: 2, original: value })
            if (e.target.value && e.key === 'Enter') {
              calculate_estimate()
            }
          }
        }

        let result = (
          <LabelInput
            {...initial_data}
            handleChange={
              initial_data?.handleChange
                ? initial_data?.handleChange
                : (e) => {
                    update_input({ e, type, decimal: 2, original: value })
                  }
            }
          />
        )

        if (type === 'date') {
          result = (
            <Schedule
              left_label={name}
              left_label_width={label_width}
              no_margin={'mb-[10px]'}
              name={key}
              show_time={false}
              schedule_date={value}
              handle_change={(e) => {
                update_input({ e, type, decimal: 2, original: value })
              }}
            />
          )
        }
        if (type === 'options') {
          console.log('options', calc?.[calculator_type]?.option_values?.[key])
          const options = calc?.[calculator_type]?.option_values?.[key].map(
            (item) => capitalize(item)
          )
          console.log('options', options)

          result = (
            <Select
              left_label={name}
              left_label_width={label_width}
              no_margin={false}
              className='mb-[10px]'
              name={key}
              value={value}
              handler={(e) => {
                update_input({ e, type, decimal: 2, original: value })
              }}
              placeholder=''
            >
              {[...options].map((item) => {
                console.log('item', item?.split(' ')?.join('_')?.toLowerCase())
                return (
                  <option
                    key={item}
                    value={item?.split(' ')?.join('_')?.toLowerCase()}
                  >
                    {item}
                  </option>
                )
              })}
            </Select>
          )
        }

        return <div key={key}>{result}</div>
      })
  }

  const get_title_type = (program_type) => {
    if (program_type.includes('buy')) {
      return 'buy'
    } else if (program_type?.includes('sell')) {
      return 'sell'
    } else if (program_type?.includes('refi')) {
      return 'refi'
    } else {
      return 'buy'
    }
  }

  useEffect(() => {
    const calculator_type = 'title_fees'
    const calculator = calc?.[calculator_type]
    const calc_values = calculator?.values
    const data_options = calculator?.data_options
    const calc_types = calculator?.types
    const program_type = calc_values?.program_type

    if (data_options?.calculated) {
      if (!validate_number(calc_values?.home_price)) {
        let timeout = calcTimeoutRef?.current
        if (timeout) {
          clearTimeout(timeout)
        }
        timeout = null
        const msg = 'Home Price is required'
        setError((prev) => {
          return {
            msg: msg,
            show_error: true,
          }
        })
        calcTimeoutRef.current = setTimeout(() => {
          setError((prev) => {
            return {
              msg: msg,
              show_error: false,
            }
          })
        }, [3000])
        return saveCalc((prev) => ({
          ...prev,
          [calculator_type]: {
            ...prev?.[calculator_type],
            data_options: {
              ...prev?.[calculator_type]?.data_options,
              calculated: false,
            },
          },
        }))
      }

      const home_price = validate_number(calc_values?.home_price)
      const down_payment = validate_number(calc_values?.down_payment)
      const principal = get_principal(
        get_title_type(program_type),
        home_price,
        convert_percent(
          home_price,
          down_payment,
          calc_types?.down_payment,
          'value'
        )
      )

      const get_fees = (type = 'buy', loan_type = 'conventional') => {
        let fees = get_title_fees(
          type,
          null,
          null,
          home_price,
          principal,
          loan_type
        )
        if (loan_type === 'all cash') {
          const add_title_fees = calc?.calc_scenario_fees
          const endorsement_fee = add_title_fees?.find(
            (item) => item?.axiom_desc_name?.toLowerCase() === 'endorsements'
          )
          const cpl_fee = add_title_fees?.find(
            (item) => item?.axiom_desc_name?.toLowerCase() === 'cpl'
          )
          fees = pick_keys(fees, [
            'title_policy_fee',
            `title_${endorsement_fee?.fee_id}`,
            `title_${cpl_fee?.fee_id}`,
          ])
        }
        return fees
      }

      const title_fees = get_fees(
        get_title_type(program_type),
        program_type?.includes('cash') ? 'all cash' : 'conventional'
      )
      const buy_title_fees = get_fees(
        'buy',
        program_type?.includes('cash') ? 'all cash' : 'conventional'
      )
      const sell_title_fees = get_fees(
        'sell',
        program_type?.includes('cash') ? 'all cash' : 'conventional'
      )
      saveCalc((prev) => ({
        ...prev,
        title_fees: {
          ...prev?.title_fees,
          data_options: {
            ...prev?.title_fees?.data_options,
            title_fees,
            buy_title_fees,
            sell_title_fees,
          },
        },
      }))
    }
  }, [calc?.title_fees?.values, calc?.title_fees?.data_options?.calculated])

  useEffect(() => {
    const save_data = async () => {
      if (get_storage('vbc_calc') === 'Y') {
        return
      }
      const calculator_type = 'title_fees'
      const calculator = calc?.[calculator_type]
      const calc_values = calculator?.values
      const data_options = calculator?.data_options
      const calc_types = calculator?.types
      const types = {}
      const values = {}
      Object.entries(calc?.[calculator_type]?.types).forEach(([key, value]) => {
        types[`${key}_is_type`] = value
      })
      Object.entries(calc?.[calculator_type]?.values).forEach(
        ([key, value]) => {
          if (
            ['%', '$', 'm', 'a']?.includes(
              calc?.[calculator_type]?.types?.[key]
            )
          ) {
            types[key] = validate_number(value)
          } else {
            types[key] = value
          }
        }
      )
      values.down_payment_total = validate_number(
        convert_percent(
          validate_number(calc_values?.home_price),
          validate_number(calc_values?.down_payment),
          calc_types?.down_payment,
          'value'
        ),
        2
      )
      values.down_payment_percent = validate_number(
        convert_percent(
          validate_number(calc_values?.home_price),
          validate_number(calc_values?.down_payment),
          calc_types?.down_payment,
          'percent'
        ),
        2
      )

      const closing_values = []
      const program_names = {
        buy: 'buyer',
        sell: 'seller',
      }
      const buy_title_fees = data_options?.buy_title_fees
      const sell_title_fees = data_options?.sell_title_fees

      const add_fees = (
        fees = {},
        implementation = 'buy',
        group = 'title_fees'
      ) => {
        Object.entries(fees)?.forEach(([key, value]) => {
          const data = {}
          const is_title_key = ['escrow_fee', 'title_policy_fee'].includes(key)
          data.instance_uuid =
            calc?.[calculator_type]?.data_options?.instance_uuid
          data.fee_key = key
          data.fee_implementation = program_names?.[implementation]
          data.fee_value = validate_number(value, 2)
          data.fee_total_value = validate_number(value, 2)
          data.fee_is_type = is_title_key
            ? program_names?.[implementation]
            : '$'
          data.fee_name = get_fee_name(key)
          data.fee_group = group
          closing_values.push(data)
        })
      }

      add_fees(buy_title_fees, 'buy', 'title_fees')
      add_fees(sell_title_fees, 'sell', 'title_fees')
      add_fees({ home_protection: 400 }, 'buy', 'other_fees')

      console.log('closing_values', closing_values)
      const current_hist = calc?.axiom_calc_hist?.find(
        (item) =>
          item.instance_uuid ===
          calc?.[calculator_type]?.data_options?.instance_uuid
      )

      console.log('values', values)
      const data = {
        values: {
          ...types,
          ...values,
          instance_uuid: calc?.[calculator_type]?.data_options?.instance_uuid,
          calc_type: get_calc_type_id(calculator_type || 'title_fees'),
          address: calc?.formatted_address || null,
          mls_id: calc?.mls_id || null,
        },
        is_title_fees: true,
        closing_values,
      }
      saveCalc((prev) => ({
        ...prev,
        axiom_calc_hist: [
          ...(prev?.axiom_calc_hist?.filter(
            (item) =>
              item.instance_uuid !==
              calc?.[calculator_type]?.data_options?.instance_uuid
          ) || []),
          {
            ...current_hist,
            ...data?.values,
            upd_dt: new Date()?.toISOString(),
          },
        ],
      }))
      await axiomFetch('/calculators/save', 'POST', data, {}, false, null, 700)
      saveCalc((prev) => ({ ...prev, share_loading: false }))
    }
    if (
      calc?.title_fees?.data_options?.calculated
      // &&
      // (validate_number(calc?.title_fees?.home_price) ||
      //   validate_number(calc?.title_fees?.refi_amount))
    ) {
      saveCalc((prev) => ({ ...prev, share_loading: true }))
      save_data()
    }
    return () => {}
  }, [calc?.title_fees?.values, calc?.title_fees?.data_options])
  console.log('calc', calc?.title_fees)
  const calculate_estimate = () => {
    saveCalc((prev) => {
      return {
        ...prev,
        title_fees: {
          ...prev?.title_fees,
          data_options: {
            ...prev?.title_fees?.data_options,
            instance_uuid:
              prev?.title_fees?.data_options?.instance_uuid || get_uuid(),
            calculated: true,
            run: (prev?.title_fees?.data_options?.run || 0) + 1,
          },
        },
      }
    })
    if (validate_number(calc?.title_fees?.values?.home_price)) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  const get_fee_name = (key) => {
    return validate_number(key)
      ? calc?.calc_scenario_fees?.find(
          (item) => item.fee_id === validate_number(key)
        )?.axiom_desc_name
      : capitalize(key)
  }

  const get_fees = (type = 'title_fees', disabled = false, fees_obj = {}) => {
    return Object.entries(fees_obj)
      ?.filter(([key, value]) => validate_number(value))
      ?.map(([key, value]) => {
        let label_input = (
          <div className='grid grid-cols-3 ' key={key}>
            <div className='p-1 col-span-2 border-[1px] border-thirdary'>
              {get_fee_name(key)}
            </div>
            <div className='p-1 col-span-1 border-[1px] border-thirdary text-right'>
              ${format_currency(value, false, 2)?.replace('$', '')}
            </div>
          </div>
        )

        return label_input
      })
  }

  const get_total = (type = 'all') => {
    const title_fees = Object.values(
      calc?.title_fees?.data_options?.title_fees || {}
    ).reduce((curr, val) => curr + val, 0)
    const other_fees = 400
    if (type === 'title_fees') {
      return title_fees
    } else if (type === 'other_fees') {
      return get_title_type(calc?.title_fees?.values?.program_type) === 'sell'
        ? 0
        : other_fees
    } else {
      return title_fees + other_fees
    }
  }

  return (
    <div className='flex flex-col justify-between min-h-[550px]'>
      <div>
        {calc?.title_fees?.data_options?.calculated ? (
          <div className='mb-[10px]'>
            <CalcShare
              calculator_type={'title_fees'}
              implementation_type={'title_fees'}
              include_bottom_margin={false}
            />
          </div>
        ) : (
          ''
        )}
        <div className='px-3'>
          <div className=''>{get_inputs()}</div>
          {calc?.title_fees?.data_options?.calculated ? (
            <div>
              <div className='flex justify-between items-center'>
                <p className='my-[5px] font-bold'>Title/Escrow Fees</p>
                <div className='mr-[5px] font-bold'>
                  ${format_currency(get_total('title_fees'), false, 2)}
                </div>
              </div>
              <div className=''>
                {get_fees(
                  'title_fees',
                  true,
                  calc?.title_fees?.data_options?.title_fees || {}
                )}
              </div>
              {get_title_type(calc?.title_fees?.values?.program_type) ===
              'buy' ? (
                <div>
                  <div className='flex justify-between items-center'>
                    <p className='my-[5px] font-bold'>Other Fees</p>
                    <div className='mr-[5px] font-bold'>
                      $
                      {format_currency(
                        get_total('other_fees'),
                        false,
                        2
                      )?.replace('$', '')}
                    </div>
                  </div>

                  <div className=''>
                    <div className='grid grid-cols-3 '>
                      <div className='p-1 col-span-2 border-[1px] border-thirdary'>
                        Home Protection
                      </div>
                      <div className='p-1 col-span-1 border-[1px] border-thirdary text-right'>
                        ${format_currency(400, false, 2)?.replace('$', '')}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className='flex justify-between items-center'>
                <p className='my-[5px] font-bold '>Total Fees</p>
                <div className='mr-[5px] font-bold'>
                  $
                  {format_currency(get_total('all'), false, 2)?.replace(
                    '$',
                    ''
                  )}
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className='flex justify-center w-full'>
        {error?.show_error ? (
          <div className={`${uiStyles.failure_msg} mb-[10px]`}>
            {error?.msg}
          </div>
        ) : (
          <button
            className={`${uiStyles.hover_btn} w-full rounded-none`}
            onClick={() => {
              calculate_estimate()
            }}
          >
            Calculate
          </button>
        )}
      </div>
    </div>
  )
}

export default CalcTitleFees
