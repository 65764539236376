import React from 'react';

export default function Button({ clickHandler, children }) {
  return (
    <button
      onClick={clickHandler}
      className="bg-primary inline-block text-base font-semibold px-5 py-5 rounded-md min-w-[120px]"
    >
      {children}
    </button>
  );
}
