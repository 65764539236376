import React from 'react';
import { FaStar } from 'react-icons/fa';
import Verified from '../../assets/images/verified.png';
export default function ReviewItem({ rating = 0, comment }) {
  const stars =
    rating &&
    new Array(+rating)
      .fill('')
      ?.map((_, index) => (
        <FaStar key={index} className="swipeable text-orange-400 text-2xl m-1" />
      ));
  return (
    <div className="swipeable py-3 px-2 bg-gray-100">
      <div className="swipeable flex">
        <div className="swipeable w-[80px]">
          <img src={Verified} alt="" className="swipeable max-w-full" />
        </div>
        <div className="swipeable flex-1">
          <h2 className="swipeable text-center font-semibold text-primary uppercase">
            {rating} Star Review
          </h2>
          <div className="swipeable flex justify-center ">{stars}</div>
        </div>
        <div className="swipeable w-[80px]">
          <img src={Verified} alt="verified" className="swipeable max-w-full" />
        </div>
      </div>
      {comment && (
        <div className="swipeable text-center text-xs font-light leading-3">
          {comment}
        </div>
      )}
    </div>
  );
}
