import { ServiceHttp } from './ServiceHttp';

export class ServiceBase {
  http;

  constructor(url_prefix = '', token) {
    this.http = new ServiceHttp(url_prefix, token);
  }

  async getAll(params) {
    return await this.http.get(``, params);
  }

  async get(id, childPath) {
    if (childPath) {
      return await this.http.get(`/${id}${childPath}`);
    }
    return await this.http.get(`/${id}`);
  }

  async create(body) {
    return await this.http.post(``, body);
  }
  async createWithID(id, body, childPath) {
    if (childPath) {
      return await this.http.post(`/${id}${childPath}`, body);
    }
    return await this.http.post(`/${id}`, body);
  }

  async update(id, body) {
    return await this.http.put(`/${id}`, body);
  }
  async patch(id, body, childPath) {
    console.log(body);
    if (childPath) {
      return await this.http.patch(`/${id}${childPath}`, body);
    }
    return await this.http.patch(`/${id}`, body);
  }

  async delete(id) {
    return await this.http.remove(`/${id}`);
  }

  getController() {
    return this.http.getController();
  }
}
