import { useEffect, useState } from 'react'
import { AiOutlineMail } from 'react-icons/ai'
import { BsChatText, BsPlusCircle } from 'react-icons/bs'
import { IoIosPhonePortrait } from 'react-icons/io'
import { useSales } from '../../contexts/SalesContext'
import GeoJsonGeometriesLookup from 'geojson-geometries-lookup'
import { SwiperSlide, Swiper } from 'swiper/react'

import {
  formatPhoneNumber,
  format_dollar_currency,
  getDateString,
  get_storage,
  set_storage,
  validate_number,
} from '../../helpers'
import ButtonsRow from '../ui/ButtonsRow'
import UserThumb from '../../assets/images/profile-silhouette.svg'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import { SmallLoadingRel } from '../ui/SmallLoadingRel'
import { useReceiver } from '../../contexts/ReceiverContext'
import { useNavigate } from 'react-router-dom'
import { useListingTours } from '../../contexts/ListingToursContext'
import { useProfile } from '../../contexts/UserContext'
import { ListingSignupCancel, ListingSignupConfirm } from '../pages/tours'
import Modal from '../Modal'
import ClientTourSignup from './ClientTourSignup'
import { MdAdsClick } from 'react-icons/md'
import uiStyles from '../ui/uiStyles'
import CliNote from './CliNote'
import Select from '../form/Select'
import CliInterest from './CliInterest'
import { LuInfo } from 'react-icons/lu'
import AtBat from './AtBat'
import FirstBase from './FirstBase'
import SecondBase from './SecondBase'
import ThirdBase from './ThirdBase'
import Baseline from './Baseline'
import CliQuestPicker from './CliQuestPicker'
import CliAppointmentSet from './CliAppointmentSet'
import AtonNeedsHelp from './AtonNeedsHelp'
import PainPicker from './PainPicker'

const WhatNextClient = () => {
  const [showModal, setShowModal] = useState(false)
  const [currSwiper, setCurrSwiper] = useState({})
  const [modalType, setModalType] = useState('')
  const [mapName, setMapName] = useState('')

  const { profile } = useProfile()
  const { sales, saveSales } = useSales()
  const { receiver, saveReceiver } = useReceiver()
  const { listingTours, saveListingTours } = useListingTours()
  const navigate = useNavigate()
  const { axiomFetch } = useAxiomFetch()
  const [loading, setLoading] = useState(false)
  const [loadingScore, setLoadingScore] = useState(false)
  const [loadingBaseline, setLoadingBaseline] = useState(false)
  const [loadingOther, setLoadingOther] = useState(false)

  // console.log('sales', sales)

  const modals = {
    tour_map: {
      width: 'w-[345px]',
      component: (
        <div className='mt-[-30px]'>
          <iframe
            onLoad={(e) => {
              e.scrollTo({ top: 100 })
            }}
            style={{
              // transition: 'transform 0.3s ease',
              width: '100%',
              height: '550px',
              overflowY: 'scroll',
              // overflow: 'scroll',
              // marginTop: '-200px',
            }}
            src={`https://rdbeta2.driggstitle.com/prv/list_tour_console.map_js_only?acct_p=${profile.ACCOUNT_ID}&tour_nm_p=${mapName}&zoom_p=9`}
          ></iframe>
        </div>
      ),
    },
    listing_signup: {
      component: <ClientTourSignup />,
    },
    listing_signup_confirm: {
      width: 'w-[345px]',
      height: 'h-[359px]',
      component: <ListingSignupConfirm setShowModal={setShowModal} />,
    },
    listing_signup_cancel: {
      width: 'w-[345px]',
      height: 'h-[200px]',
      component: <ListingSignupCancel setShowModal={setShowModal} />,
    },
    cli_note: {
      component: <CliNote setShowModal={setShowModal} />,
      width: 'w-[345px]',
      height: 'h-[400px]',
    },
    assign_interest: {
      component: <CliInterest setShowModal={setShowModal} />,
      width: 'w-[345px]',
      height: 'h-[550px]',
    },
    quest_picker: {
      component: <CliQuestPicker setShowModal={setShowModal} />,
      width: 'w-[345px]',
    },
    pain_picker: {
      component: <PainPicker setShowModal={setShowModal} />,
      width: 'w-[345px]',
    },
    appointment_set: {
      component: <CliAppointmentSet setShowModal={setShowModal} />,
      width: 'w-[345px]',
      height: 'h-[400px]',
    },
    aton_needs_help: {
      component: <AtonNeedsHelp setShowModal={setShowModal} />,
      width: 'w-[345px]',
      height: 'h-[400px]',
    },
  }

  const get_client = () => {
    return sales?.curr_client
  }

  const card_style = 'bg-gray-100 rounded-lg shadow-xl p-1 mt-[10px]'
  const data_container_style =
    'px-3 min-h-[300px] max-h-[350px] overflow-y-scroll overflow-x-hidden'
  useEffect(() => {
    const controller = new AbortController()

    const get_add_data = async () => {
      setLoading(true)
      const add_data = await axiomFetch(
        `/sales/what_next?cli_cont_id=${
          get_client()?.data?.cont_id
        }&cli_master_client_id=${get_client()?.data?.master_client_id}`,
        'GET',
        {},
        {},
        false,
        controller?.signal
      )

      if (add_data?.name === 'AbortError') {
        return
      }

      saveSales((prev) => ({
        ...prev,
        curr_client: { ...prev?.curr_client, add_data },
      }))
      setLoading(false)
    }

    get_add_data()

    return () => {
      controller?.abort()
    }
  }, [sales?.curr_client?.data?.cont_id, sales?.curr_client?.refresh])

  useEffect(() => {
    const controller = new AbortController()

    const get_score_data = async () => {
      setLoadingScore(true)
      const add_data = await axiomFetch(
        `/sales/what_next/scores?cli_cont_id=${
          get_client()?.data?.cont_id
        }&cli_master_client_id=${get_client()?.data?.master_client_id}`,
        'GET',
        {},
        {},
        false,
        controller?.signal
      )

      if (add_data?.name === 'AbortError') {
        return
      }

      saveSales((prev) => ({
        ...prev,
        curr_client: { ...prev?.curr_client, score_data: add_data },
      }))
      setLoadingScore(false)
    }
    get_score_data()

    return () => controller.abort()
  }, [sales?.curr_client?.data?.cont_id, sales?.curr_client?.refresh])

  useEffect(() => {
    const controller = new AbortController()

    const get_other_data = async () => {
      setLoadingOther(true)
      const add_data = await axiomFetch(
        `/sales/what_next/other?cli_cont_id=${
          get_client()?.data?.cont_id
        }&cli_master_client_id=${get_client()?.data?.master_client_id}`,
        'GET',
        {},
        {},
        false,
        controller?.signal
      )

      if (add_data?.name === 'AbortError') {
        return
      }

      saveSales((prev) => ({
        ...prev,
        curr_client: { ...prev?.curr_client, other_data: add_data },
      }))
      setLoadingOther(false)
    }
    get_other_data()

    return () => controller.abort()
  }, [sales?.curr_client?.data?.cont_id, sales?.curr_client?.refresh])

  useEffect(() => {
    const controller = new AbortController()

    const get_other_data = async () => {
      saveSales((prev) => ({ ...prev, loading_baseline: true }))
      const add_data = await axiomFetch(
        `/sales/what_next/baseline?cli_cont_id=${
          get_client()?.data?.cont_id
        }&cli_master_client_id=${get_client()?.data?.master_client_id}`,
        'GET',
        {},
        {},
        false,
        controller?.signal
      )

      if (add_data?.name === 'AbortError') {
        return
      }

      saveSales((prev) => ({
        ...prev,
        loading_baseline: false,
        curr_client: { ...prev?.curr_client, baseline_data: add_data },
      }))
    }
    get_other_data()

    return () => controller.abort()
  }, [sales?.curr_client?.data?.cont_id, sales?.curr_client?.refresh])

  const get_bat_num = (key) => {
    const bases = {
      at_bat: 1,
      ['1st:_commitment_for_an_appointment']: 2,
      ['2nd:_commitment_to_engage']: 3,
      ['3rd:_they_did_engage']: 4,
      ['home:_commitment_for_name_on_deal']: 5,
    }

    return bases[key?.toLowerCase()?.split(' ')?.join('_')]
  }

  const get_eo_obj = (files) => {
    const data = {}
    if (!files?.length) {
      return data
    }
    files.forEach((item) => {
      data[item?.eo_name] = validate_number(data?.[item?.eo_name]) + 1
    })
    return data
  }

  const check_listing_in_tour = (listing) => {
    const tours = listingTours?.data?.upcoming_tour_schedule
    for (const tour of tours) {
      const is_point_in_geo = check_point_in_geo({
        lat: listing?.lat,
        lng: listing?.lng,
        geo_json: JSON.parse(tour?.geo_json),
      })
      if (is_point_in_geo) {
        return tour
      }
    }
  }

  const check_point_in_geo = (options) => {
    try {
      const glookup = new GeoJsonGeometriesLookup(options?.geo_json)
      const check_point = {
        type: 'Point',
        coordinates: [options.lng, options.lat],
      }
      const value = glookup.countContainers(check_point)
      return value
    } catch (error) {
      console.log(error)
      return false
    }
  }

  const order_hist_html = (
    <ul className='pl-[25px] flex flex-col gap-[2px] mt-[2px]'>
      <li className='list-disc'>
        <span className='font-bold'>Total Opened Orders: </span>
        <span>
          {validate_number(get_client()?.add_data?.all_files_data?.open_count)}
        </span>
      </li>
      <li className='list-disc'>
        {' '}
        <span className='font-bold'>Last Order: </span>
        <span>
          {get_client()?.add_data?.all_files_data?.last_order_date
            ? getDateString({
                date: get_client()?.add_data?.all_files_data?.last_order_date,
              })
            : 'N/A'}
        </span>
      </li>

      <li className='list-disc'>
        {' '}
        <span className='font-bold'>Total Closed Files: </span>
        <span>
          {validate_number(
            get_client()?.add_data?.all_files_data?.closed_count
          )}
        </span>
      </li>
      <li className='list-disc'>
        {' '}
        <span className='font-bold'>Last Closed: </span>
        <span>
          {get_client()?.add_data?.all_files_data?.last_close_date
            ? getDateString({
                date: get_client()?.add_data?.all_files_data?.last_close_date,
              })
            : 'N/A'}
        </span>
      </li>
    </ul>
  )

  return (
    <div className='pb-3'>
      <div className='flex px-3'>
        <img
          src={get_client()?.data?.cont_photo || UserThumb}
          className='w-[50px] h-[50px] object-center object-cover rounded-full'
        />
        <div className='w-full flex justify-between'>
          <div className='ml-[5px]'>
            <p className='max-w-[140px] overflow-scroll whitespace-nowrap'>
              {get_client()?.data?.cont_name}
            </p>
            <p className='max-w-[140px] overflow-scroll whitespace-nowrap'>
              {get_client()?.data?.client_company}
            </p>
            <p className='max-w-[140px] overflow-scroll whitespace-nowrap'>
              {get_client()?.data?.address1}
            </p>
          </div>
          <div>
            <p
              className='whitespace-nowrap border-b-2'
              style={{
                borderColor: get_client()?.data?.color_score_code,
              }}
            >
              Score: {validate_number(get_client()?.data?.total_score)} | Runs:{' '}
              {validate_number(get_client()?.data.runs_scored)}
            </p>
            <p>{get_client()?.data?.lead_description}</p>
            <p className='max-w-[120px] overflow-scroll whitespace-nowrap'>
              {get_client()?.data?.base_description}
            </p>
          </div>
        </div>
      </div>
      <div className='flex gap-[10px] ml-[10px]'>
        <div className='flex items-center'>
          <div className={'flex gap-[10px] items-center'}>
            {get_client()?.data?.cont_cell_num ||
            get_client()?.data?.best_contact_phone ? (
              <div className='flex items-center gap-[10px]'>
                <a
                  href={`tel:${
                    get_client()?.data?.cont_cell_num ||
                    get_client()?.data?.best_contact_phone
                  }`}
                  target='_blank'
                >
                  <IoIosPhonePortrait size={25} />
                </a>
                <a
                  className='mr-[5px]'
                  href={`sms://${
                    get_client()?.data?.cont_cell_num ||
                    get_client()?.data?.best_contact_phone
                  }&body=`}
                  target='_blank'
                >
                  <BsChatText size={20} />
                </a>
              </div>
            ) : (
              ''
            )}
            <a
              href={`mailto:?to=${get_client()?.data?.cont_email}`}
              target='_blank'
            >
              <AiOutlineMail size={20} />
            </a>
          </div>
        </div>
        <div className='flex w-full justify-between items-center pr-[15px]'>
          <div
            className={`flex flex-col ${
              get_client()?.data?.cont_cell_num ||
              get_client()?.data?.best_contact_phone
                ? 'mt-[10px]'
                : ''
            }`}
          >
            {get_client()?.data?.cont_cell_num ||
            get_client()?.data?.best_contact_phone ? (
              <p className='max-w-[150px] overflow-scroll'>
                {formatPhoneNumber(
                  get_client()?.data?.cont_cell_num ||
                    get_client()?.data?.best_contact_phone
                )}
              </p>
            ) : (
              ''
            )}
            <p className='max-w-[150px] overflow-scroll .scrollbar-hide'>
              {get_client()?.data?.cont_email}
            </p>
          </div>
          <div>
            <img
              className='w-[50px] h-[50px] mt-[-10px]'
              src={`https://rightdata.driggstitle.com/sales_machine/a_Baseball_icon-${get_bat_num(
                get_client()?.data?.base_description
              )}.png`}
            />
          </div>
        </div>
      </div>
      <div className='px-3 mt-[10px] relative'>
        <Select
          padding_label='py-[5px]'
          padding_select='py-[5px]'
          name='lead_cycle'
          value={get_client()?.data?.cur_lead_cycle?.toString()}
          left_label='Lead Cycle'
          handler={async (e) => {
            if (
              validate_number(get_client()?.data?.cur_lead_cycle) >
              validate_number(e.target.value)
            ) {
              return
            }
            saveSales((prev) => {
              const sales = {
                ...prev,
                what_next_clients: [
                  ...(Array.isArray(prev?.what_next_clients)
                    ? prev?.what_next_clients
                    : []
                  ).filter(
                    (item) => item?.cont_id !== get_client()?.data?.cont_id
                  ),
                  {
                    ...prev?.what_next_clients?.find(
                      (item) => item?.cont_id === get_client()?.data?.cont_id
                    ),
                    cur_lead_cycle: validate_number(e.target.value),
                  },
                ]?.sort(
                  (a, b) =>
                    validate_number(b?.total_score) -
                    validate_number(a?.total_score)
                ),
                curr_client: {
                  ...prev?.curr_client,
                  data: {
                    ...prev?.curr_client?.data,
                    cur_lead_cycle: validate_number(e.target.value),
                  },
                },
              }
              set_storage('sales', sales)
              return sales
            })
            setModalType('assign_interest')
            setShowModal(true)
            await axiomFetch('/sales/what_next/assign_cycle', 'POST', {
              cli_cont_id: get_client()?.data?.cont_id,
              cycle_item_id: validate_number(e.target.value),
              orig_cycle_item_id: validate_number(
                get_client()?.data?.cur_lead_cycle
              ),
            })
          }}
        >
          <option key={'0'} value='0'>
            None
          </option>
          {sales?.lead_cycle_items
            ?.filter((item) => {
              if (
                item.item_id?.toString() === '6' &&
                get_client()?.data?.cur_lead_cycle?.toString() !== '6'
              ) {
                return false
              } else {
                return true
              }
            })
            ?.map((item) => {
              return (
                <option key={item?.item_id} value={item?.item_id}>
                  {item?.item_name}
                </option>
              )
            })}
        </Select>
        <div
          className='absolute top-[6px] left-[98px] cursor-pointer'
          onClick={() => {
            setModalType('assign_interest')
            setShowModal(true)
          }}
        >
          <LuInfo size={20} />
        </div>
      </div>
      <div className='px-3 mt-[10px]'>
        <ButtonsRow
          items={['baseline', 'info', 'score', 'notes']}
          selected={get_client()?.selected || 'baseline'}
          handler={(item) => {
            const position = {
              baseline: 0,
              info: 1,
              score: 2,
              notes: 3,
            }
            if (currSwiper) {
              currSwiper?.slideTo(position[item])
            }
            saveSales((prev) => {
              const sales = {
                ...prev,
                curr_client: { ...prev?.curr_client, selected: item },
              }
              set_storage('sales', sales)
              return sales
            })
          }}
        />
      </div>

      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        initialSlide={0}
        onSlideChange={(e) => {
          const position = {
            0: 'baseline',
            1: 'info',
            2: 'score',
            3: 'notes',
          }

          saveSales((prev) => ({
            ...prev,
            curr_client: {
              ...prev?.curr_client,
              selected: position[e.realIndex],
            },
          }))
        }}
        onSwiper={(swiper) => {
          try {
            // const index = 0
            // if (index > 0) {
            //   swiper.slideTo(index === -1 ? 0 : index, 0)
            //   return
            // }
            // if (index === 0 || index === -1) {
            //   swiper.slideTo(1, 900)
            // }
            // setTimeout(() => {
            //   if (index === 0 || index === -1) {
            //     swiper.slideTo(0, 900)
            //   }
            //   swiper.slideTo(index === -1 ? 0 : index, 0)
            //   setSwiper(swiper)
            // }, 175)

            // swiper.slideTo(1)
            const position = {
              baseline: 0,
              info: 1,
              score: 2,
              notes: 3,
            }
            if (get_storage('sales')?.curr_client?.selected) {
              swiper.slideTo(
                position[get_storage('sales')?.curr_client?.selected],
                0
              )
            }
            if (swiper) {
              setCurrSwiper(swiper)
            }
          } catch (error) {
            console.log(error)
          }
        }}
      >
        <SwiperSlide>
          {sales?.curr_client?.selected === 'baseline' ? (
            <Baseline setShowModal={setShowModal} setModalType={setModalType} />
          ) : (
            ''
          )}
        </SwiperSlide>
        <SwiperSlide>
          {sales?.curr_client?.selected === 'info' ? (
            <div className={`${data_container_style}`}>
              {loading ? (
                <div className='w-full relative flex justify-center'>
                  <div className='absolute'>
                    <SmallLoadingRel />
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className={`${card_style}`}>
                <p className='mt-[5px] font-bold'>
                  Eo's{' '}
                  {get_client()?.data?.cont_name?.toString()?.split(' ')?.[0]}{' '}
                  has worked with:
                </p>
                <ul className='pl-[25px] flex flex-col gap-[5px] mt-[5px]'>
                  {Object.entries(
                    get_eo_obj(get_client()?.add_data?.ed_files || [])
                  )
                    ?.sort(
                      ([key_a, value_a], [key_b, value_b]) => value_b - value_a
                    )
                    ?.map(([key, value]) => {
                      return (
                        <li className='list-disc' key={key}>
                          {key} {`(${value})`}{' '}
                        </li>
                      )
                    })}
                </ul>
              </div>
              <div className={`${card_style}`}>
                <p className='mt-[5px] font-bold'>
                  Order History With Driggs Title:
                </p>
                {order_hist_html}
                <p className='mt-[5px] font-bold'>
                  Open Files Last 90 days {'('}link is em receipt{')'}:
                </p>
                <ul className='pl-[25px] flex flex-col gap-[5px] mt-[5px]'>
                  {(get_client()?.add_data?.current_files || [])?.map(
                    (item) => {
                      return (
                        <li
                          className=' list-decimal whitespace-nowrap'
                          key={item.id}
                        >
                          <a
                            href={item?.em_receipt_url || '#'}
                            target='_blank'
                            className={`${
                              item?.em_receipt_url
                                ? 'underline'
                                : 'cursor-default pointer-events-none'
                            }`}
                          >
                            {item?.id} -{' '}
                            {getDateString({ date: item?.open_date })}
                          </a>
                        </li>
                      )
                    }
                  )}
                </ul>
              </div>
              <div className={`${card_style}`}>
                <p className='mt-[5px] font-bold'>Services & Products Used:</p>
                <ul className='pl-[25px] flex flex-col gap-[5px] mt-[5px]'>
                  <li className='list-disc'>
                    <span className='font-bold'>Reviews: </span>
                    <span>
                      {validate_number(get_client()?.data?.count_reviews)}
                    </span>
                  </li>
                  <li className='list-disc'>
                    <span className='font-bold'>AXIOM: </span>
                    <span>{get_client()?.data?.used_axiom ? 'Yes' : 'No'}</span>
                  </li>
                  <li className='list-disc'>
                    <span className='font-bold'>AXIOM Estimator: </span>
                    <span>
                      {validate_number(get_client()?.data?.count_estimator)}
                    </span>
                  </li>
                  <li className='list-disc'>
                    <span className='font-bold'>AXIOM Flyers: </span>
                    <span>
                      {validate_number(get_client()?.data?.count_flyers)}
                    </span>
                  </li>
                  <li className='list-disc'>
                    <span className='font-bold'>Pairwise: </span>
                    <span>
                      {validate_number(get_client()?.data?.count_pairwise)}
                    </span>
                  </li>
                  <li className='list-disc'>
                    <span className='font-bold'>D-Pay: </span>
                    <span>
                      {validate_number(get_client()?.data?.count_dpay)}
                    </span>
                  </li>
                  <li className='list-disc'>
                    <span className='font-bold'>EO Thumbs Up: </span>
                    <span>
                      {validate_number(get_client()?.data?.is_eo_thumb_up)
                        ? 'Yes'
                        : 'No'}
                    </span>
                  </li>
                </ul>
              </div>
              <div className={`${card_style}`}>
                <p className='mt-[5px] font-bold'>
                  Broker Metrics Last 12 Months:
                </p>
                <p>
                  updated:{' '}
                  {get_client()?.add_data?.master_client_list?.last_update_dt
                    ? `${getDateString({
                        date: get_client()?.add_data?.master_client_list
                          ?.last_update_dt,
                      })}`
                    : ''}
                </p>
                <ul className='pl-[25px] flex flex-col gap-[5px] mt-[5px]'>
                  <li className='list-disc'>
                    <span className='font-bold'>transactions: </span>
                    <span>
                      {validate_number(
                        get_client()?.add_data?.master_client_list?.totalnum
                      )}
                    </span>
                  </li>
                  <li className='list-disc'>
                    <span className='font-bold'>Listings: </span>
                    <span>
                      {validate_number(
                        get_client()?.add_data?.master_client_list?.listno
                      )}
                    </span>
                  </li>
                  <li className='list-disc'>
                    <span className='font-bold'>Buyers: </span>
                    <span>
                      {validate_number(
                        get_client()?.add_data?.master_client_list?.sellno
                      )}
                    </span>
                  </li>
                </ul>
              </div>
              <div className={`${card_style}`}>
                <p className='mt-[5px] font-bold'>
                  Current Listings {`(last 180 days)`}:
                </p>
                <ul className='pl-[25px] flex flex-col gap-[10px] mt-[5px]'>
                  {get_client()
                    ?.add_data?.all_listings?.sort(
                      (a, b) => new Date(b?.list_date) - new Date(a?.list_date)
                    )
                    ?.filter((item) => item.mls_status === 'Active')
                    ?.map((item) => {
                      return (
                        <li className='list-disc' key={item?.mls_id}>
                          <div className='flex gap-[4px]'>
                            <span
                              className='font-bold cursor-pointer underline'
                              onClick={() => {
                                saveReceiver((prev) => {
                                  localStorage.setItem('vbc_edit_mode', 'true')
                                  return {
                                    ...prev,
                                    edit_mode: 'view',
                                    homes: {
                                      ...prev?.homes,
                                      current_home: item,
                                    },
                                  }
                                })
                                navigate('/homes/view', {
                                  replace: true,
                                  state: {
                                    parent: '/user/sales/what_next/client',
                                  },
                                })
                              }}
                            >
                              {item?.mls_id}
                            </span>
                            <span>
                              {' '}
                              {getDateString({ date: item?.list_date })}
                            </span>{' '}
                            <span>
                              {format_dollar_currency(item?.list_price)}
                            </span>{' '}
                            <span>
                              {check_listing_in_tour(item)?.tour_name ? (
                                <div className='relative flex'>
                                  <button
                                    className=''
                                    onClick={() => {
                                      setShowModal(true)
                                      saveListingTours((prev) => ({
                                        ...prev,
                                        client_signup: {
                                          ...prev?.client_signup,
                                          tour: check_listing_in_tour(item),
                                          listing: item,
                                        },
                                      }))
                                      setModalType('listing_signup')
                                    }}
                                  >
                                    🚌{' '}
                                  </button>
                                  <div className='absolute z-20 top-[-5px] left-[10px]'>
                                    {<MdAdsClick />}
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}
                            </span>
                          </div>
                        </li>
                      )
                    })}
                </ul>
              </div>
            </div>
          ) : (
            ''
          )}
        </SwiperSlide>
        <SwiperSlide>
          {sales?.curr_client?.selected === 'score' ? (
            <div className={`${data_container_style}`}>
              {loadingScore ? (
                <div className='w-full relative flex justify-center'>
                  <div className='absolute'>
                    <SmallLoadingRel />
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className={`${card_style}`}>
                <p className='mt-[5px] font-bold'>
                  Lead Score: {get_client()?.data?.mkt_lead_score}
                </p>
                <ul className='pl-[25px] flex flex-col gap-[10px] mt-[5px]'>
                  {get_client()
                    ?.score_data?.machine_cont_dts?.filter(
                      (item) =>
                        validate_number(item.serv_num) >= 2 &&
                        validate_number(item.serv_num) <= 6
                    )
                    ?.map((item, index) => {
                      return (
                        <li
                          className='list-disc'
                          key={item?.serv_name + item?.date_found + index}
                        >
                          <div className='flex gap-[4px]'>
                            <span>
                              {' '}
                              {getDateString({ date: item?.date_found })}
                              {' - '}
                              {item?.serv_name} - {item?.serv_exp_name} -{' '}
                              {item?.pts}
                            </span>{' '}
                          </div>
                        </li>
                      )
                    })}
                </ul>
              </div>
              <div className={`${card_style}`}>
                <p className='mt-[5px] font-bold'>
                  Tools and Events Score: {get_client()?.data?.grid_score}
                </p>
                <ul className='pl-[25px] flex flex-col gap-[10px] mt-[5px]'>
                  {get_client()?.score_data?.cont_attributes?.map(
                    (item, index) => {
                      return (
                        <li
                          className='list-disc'
                          key={
                            item?.date_assigned + item?.attribute_name + index
                          }
                        >
                          <div className='flex gap-[4px]'>
                            <span>
                              {' '}
                              {getDateString({ date: item?.date_assigned })}
                              {' - '}
                              {item?.attribute_name
                                .replace('- AZ', '')
                                .replace('- NV', '')}
                            </span>{' '}
                          </div>
                        </li>
                      )
                    }
                  )}
                </ul>
                {/* <div className={uiStyles.line_3px}></div> */}
                <ul className='pl-[25px] flex flex-col gap-[10px] mt-[5px]'>
                  {get_client()
                    ?.score_data?.machine_cont_dts?.filter(
                      (item) =>
                        validate_number(item.serv_num) === 1 ||
                        validate_number(item.serv_num) === 7 ||
                        validate_number(item.serv_num) === 8
                    )
                    ?.map((item, index) => {
                      return (
                        <li
                          className='list-disc'
                          key={
                            item?.date_found +
                            item?.serv_exp_name +
                            item?.pts +
                            index
                          }
                        >
                          <div className='flex gap-[4px]'>
                            <span>
                              {' '}
                              {getDateString({ date: item?.date_found })}
                              {' - '}
                              {item?.serv_name} - {item?.serv_exp_name} -{' '}
                              {item?.pts}
                            </span>{' '}
                          </div>
                        </li>
                      )
                    })}
                </ul>
              </div>
              <div className={`${card_style}`}>
                <p className='mt-[5px] font-bold'>
                  Transaction Score: {get_client()?.data?.driggs_score}
                </p>
                {order_hist_html}
                <p className='mt-[5px] font-bold'>Files:</p>
                <ul className='pl-[25px] flex flex-col gap-[10px] mt-[5px]'>
                  {get_client()?.score_data?.cont_file_info?.map((item) => {
                    return (
                      <li className='list-disc' key={item?.id}>
                        <div className='flex flex-col gap-[4px]'>
                          <p className='font-bold'>{item?.id}</p>
                          <p>
                            <span className='font-bold'> Open Date: </span>
                            {getDateString({ date: item?.open_dt })}
                          </p>{' '}
                          <p>
                            <span className='font-bold'>Estimated Close:</span>{' '}
                            {getDateString({ date: item?.open_dt })}
                          </p>{' '}
                          <div className='flex flex-wrap gap-[4px_10px]'>
                            <p>
                              {' '}
                              <span className='font-bold'>
                                Open File #:
                              </span>{' '}
                              {item?.open_num}
                            </p>{' '}
                            <p>
                              {' '}
                              <span className='font-bold'>Total:</span>{' '}
                              {item?.tot_pts}
                            </p>{' '}
                            <p>
                              {' '}
                              <span className='font-bold'>
                                After Open:
                              </span>{' '}
                              {item?.ao_pts}
                            </p>{' '}
                            <p>
                              {' '}
                              <span className='font-bold'>
                                Before Close:
                              </span>{' '}
                              {item?.bc_pts}
                            </p>{' '}
                            <p>
                              {' '}
                              <span className='font-bold'>
                                After Close:
                              </span>{' '}
                              {item?.ac_pts}
                            </p>{' '}
                          </div>
                        </div>
                      </li>
                    )
                  })}
                </ul>
                {get_client()?.score_data?.cancelled_files?.length ? (
                  <p className='mt-[5px] font-bold'>
                    Cancelled Files {`(Last 30 days)`}:
                  </p>
                ) : (
                  ''
                )}
                <ul className='pl-[25px] flex flex-col gap-[10px] mt-[5px]'>
                  {get_client()?.score_data?.cancelled_files?.map((item) => {
                    return (
                      <li className='list-disc' key={item?.id}>
                        <div className='flex flex-col gap-[4px]'>
                          <p className='font-bold'>{item?.id}</p>
                          <p>
                            <span className='font-bold'> Open Date: </span>
                            {getDateString({ date: item?.open_date })}
                          </p>{' '}
                          <p>
                            <span className='font-bold'>Canceled Date:</span>{' '}
                            {getDateString({ date: item?.cancel_dt })}
                          </p>{' '}
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </div>
              <div className={`${card_style}`}>
                <p className='mt-[5px] font-bold'>
                  Follow-up Score: {get_client()?.data?.funnel_score}
                </p>
                <ul className='pl-[25px] flex flex-col gap-[10px] mt-[5px]'>
                  {get_client()?.score_data?.cont_base_score_info?.map(
                    (item) => {
                      return (
                        <li className='list-disc' key={item?.id}>
                          <div className='flex flex-col gap-[4px]'>
                            <p className='font-bold'>
                              {item?.notes || 'no note'}
                            </p>
                            <p>
                              <span className='font-bold'> Changed: </span>
                              {getDateString({ date: item?.base_dt })}
                            </p>{' '}
                            <p>
                              <span className='font-bold'>Points:</span>{' '}
                              {item?.pts}
                            </p>{' '}
                          </div>
                        </li>
                      )
                    }
                  )}
                  {get_client()?.score_data?.cont_neg_pts?.map((item) => {
                    return (
                      <li className='list-disc' key={item?.id}>
                        <div className='flex flex-col gap-[4px]'>
                          <p className='font-bold'>
                            {item?.reason || 'no reason'}
                          </p>{' '}
                          <p>
                            <span className='font-bold'>Applicable:</span>{' '}
                            {getDateString({ date: item?.beg_dt })} -{' '}
                            {getDateString({ date: item?.end_dt })}
                          </p>{' '}
                          <p>
                            <span className='font-bold'>Negative Points:</span>{' '}
                            {item?.pts}
                          </p>{' '}
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </div>
              <div className={`${card_style}`}>
                <p className='mt-[5px] font-bold'>Pitch History:</p>
                <ul className='pl-[25px] flex flex-col gap-[10px] mt-[5px]'>
                  {get_client()?.score_data?.pitch_history?.map((item) => {
                    return (
                      <li
                        className='list-disc'
                        key={
                          item?.id + item?.attribute_name + item?.date_assigned
                        }
                      >
                        <div className='flex flex-col gap-[4px]'>
                          <p className='font-bold'>{item?.attribute_name}</p>
                          <p>
                            <span className='font-bold'> By: </span>
                            {item?.added_user_name} -{' '}
                            <span>
                              {getDateString({ date: item?.date_assigned })}
                            </span>{' '}
                          </p>{' '}
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          ) : (
            ''
          )}
        </SwiperSlide>
        <SwiperSlide>
          {sales?.curr_client?.selected === 'notes' ? (
            <div className={`${data_container_style}`}>
              {loadingScore ? (
                <div className='w-full relative flex justify-center'>
                  <div className='absolute'>
                    <SmallLoadingRel />
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className='flex flex-col mt-[10px] justify-center'>
                <button
                  className={`${uiStyles.hover_btn} justify-center flex items-center gap-[10px]`}
                  onClick={() => {
                    setModalType('cli_note')
                    setShowModal(true)
                  }}
                >
                  <BsPlusCircle size={18} /> Add Note
                </button>
              </div>
              {get_client()
                ?.other_data?.cli_notes?.sort(
                  (a, b) => new Date(b?.create_dt) - new Date(a?.create_dt)
                )
                ?.map((item) => {
                  return (
                    <div className={`${card_style}`} key={item?.cli_note_id}>
                      <div className='flex flex-col gap-[4px]'>
                        <p>
                          <span className='font-bold'> Created By: </span>
                          {item?.create_by_name} -{' '}
                          <span>
                            {getDateString({ date: item?.create_dt })}
                          </span>{' '}
                        </p>{' '}
                        <p className=''>{item?.note}</p>
                      </div>
                    </div>
                  )
                })}
            </div>
          ) : (
            ''
          )}
        </SwiperSlide>
      </Swiper>
      {showModal ? (
        <Modal
          side_padding={'px-0'}
          modalName={modalType}
          height={modals?.[modalType]?.height || 'h-[550px]'}
          width={modals?.[modalType]?.width || 'w-full'}
          showModal={showModal}
          setShowModal={(type) => {
            setShowModal(false)
          }}
          showClose={true}
        >
          {modals?.[modalType]?.component || <></>}
        </Modal>
      ) : (
        ''
      )}
    </div>
  )
}

export default WhatNextClient
