import React from 'react'
import { useNavigate } from 'react-router-dom'

const NavButton = ({
  children,
  pre_func = async () => {},
  link,
  title,
  ...restProps
}) => {
  const navigate = useNavigate()
  return (
    <button
      {...restProps}
      onClick={async () => {
        if (pre_func) {
          await pre_func()
        }
        if (link) {
          return navigate(link)
        }
      }}
    >
      {title || 'view'}
    </button>
  )
}

export default NavButton
