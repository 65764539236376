import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

export const MerchantContext = createContext()

export const MerchantProvider = ({ children, userData }) => {
  const [merchant, setMerchant] = useState({})

  const saveMerchant = useCallback((data) => {
    setMerchant(data)
  }, [])

  const value = useMemo(
    () => ({
      merchant,
      saveMerchant,
    }),
    [merchant, saveMerchant]
  )

  return (
    <MerchantContext.Provider value={value}>
      {children}
    </MerchantContext.Provider>
  )
}

export const useMerchant = () => {
  const context = useContext(MerchantContext)

  if (!context) {
    throw Error(
      'MerchantContext must be used inside an MerchantContextProvider'
    )
  }

  return context
}
