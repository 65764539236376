import { useAuth0 } from '@auth0/auth0-react'
import { useState, useEffect, memo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import images_homes from '../../../assets/images/home'
import {
  format_currency,
  getCurrentTime,
  getDateString,
  get_storage,
  get_uuid,
  set_storage,
  validate_number,
} from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { CircleCheckToggle } from '../../form/CircleCheckToggle'
import uiStyles from '../../ui/uiStyles'
import { currency, get_address_str } from './utils'
import { compressToUTF16, decompressFromUTF16 } from 'lz-string'
import { useProfile } from '../../../contexts/UserContext'
import { ListingPhotos } from '../tours'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import { BsBoxArrowRight } from 'react-icons/bs'
import { useCalc } from '../../../contexts/CalcContext'
import HomePlanoSub from './HomePlanoSub'
import { useClaims } from '../../../contexts/ClaimsContext'
import useHelpers from '../../../hooks/useHelpers'
export const HomeView = memo(
  ({
    state,
    setState,
    showHomeModal,
    setShowHomeModal,
    page_type = 'home',
  }) => {
    const { user } = useAuth0()
    const { open_url } = useHelpers()
    const { claims } = useClaims()
    const { calc, saveCalc, instanciate_buy, instanciate_sell } = useCalc()
    const location = useLocation()
    const [page, setPage] = useState({})
    const [searchedHomes, setSearchedHomes] = useState([])
    const [calcLoading, setCalcLoading] = useState(false)
    const [home, setHome] = useState({})
    const [favoritedHomes, setFavoritedHomes] = useState([])
    const { profile: cont_profile } = useProfile()
    const { axiomFetch, nodeProdFetch } = useAxiomFetch()
    const [messageSent, setMessageSent] = useState({})

    const handle_msg = (status, message, type, close) => {
      setMessageSent({ ...messageSent, status, message, type })
      if (close) {
        setTimeout(() => {
          setMessageSent({ ...messageSent, status: 'done', message: '' })
        }, 15000)
      }
    }

    useEffect(() => {
      const controller = new AbortController()
      const controller_2 = new AbortController()
      const get_data = async () => {
        setPage((prev) => ({ ...prev, loading_photos: true }))
        const searched_homes = state?.homes?.homes?.length
          ? state?.homes?.homes
          : get_storage('home_search') || []

        setSearchedHomes(searched_homes)

        const curr_home =
          state?.homes?.current_home || get_storage('current_home') || {}
        if (curr_home?.mls_id) {
          set_storage('current_home', curr_home)
        }
        setHome(curr_home)
        const get_favorited_homes = state?.homes?.favorited_homes?.length
          ? state?.homes?.favorited_homes
          : get_storage('favorited_homes') || []
        if (
          (get_favorited_homes || [])?.find(
            (item) => item?.mls_id === curr_home?.mls_id
          )
        ) {
          curr_home.favorited = 'Y'
        } else {
          curr_home.favorited = 'N'
        }

        setFavoritedHomes(get_favorited_homes)

        const photos = await axiomFetch(
          `/tours/photos/${curr_home?.armls_id}?data_type=${curr_home?.data_type}`,
          'GET',
          {},
          {},
          false,
          controller.signal
        )
        const add_data = await axiomFetch(
          `/marketing/homes/get_additional_data?id=${curr_home?.armls_id}&data_type=${curr_home?.data_type}`,
          'GET',
          {},
          {},
          false,
          controller.signal
        )
        console.log('add_data', add_data)
        setPage((prev) => ({
          ...prev,
          loading_photos: false,
          photos: photos?.armls_photos,
          add_data: add_data?.mls_listings_v?.[0],
          open_house_data: add_data?.mls_open_houses_v,
        }))
      }
      get_data()

      return () => {
        controller?.abort()
      }
      // eslint-disable-next-line
    }, [])

    const navigate = useNavigate()

    const profile =
      state?.card?.profile || get_storage('card')?.profile || cont_profile
    const get_buyer_name = () => {
      return user?.name?.replace('+1', '')
    }
    const get_request_info = () => {
      let info = ''
      if (home?.additional_notes) {
        info += ` Additional Notes: ${home?.additional_notes}`
      }

      return info
    }
    const create_info_request = async () => {
      handle_msg('loading_msg', 'Requesting more info', 'search')
      const msg = `Hey ${
        profile?.CONT_NAME
      },\nyour potential buyer ${get_buyer_name()} may be interested in the property: ${get_address_str(
        home
      )} with an mls_id of: ${
        home?.mls_id
      }.\nThey requested more info about the property: ${get_request_info()}\n Reply back in your Axiom Conversation to text them.`
      const body = {
        conversations: [
          {
            name: get_buyer_name(),
            attributes: { campaign: 'buyer_vbc' },
            participants: [
              {
                identity: profile?.CONT_ID?.toString(),
                type: 'user',
                order: 2,
              },
              {
                phone: get_buyer_name(),
                type: 'receiver',
                order: 1,
                msgs: [
                  {
                    msg: msg,
                  },
                ],
              },
            ],
          },
        ],
        conversation_from_buyer: true,
      }
      await axiomFetch('/marketing/conversations/axiom/create', 'POST', body)

      handle_msg(
        'success_msg',
        'Thanks! Your agent should text you back soon.',
        'search',
        true
      )

      setState((prev) => ({
        ...prev,
        homes: {
          ...prev?.homes,
          current_home: { ...prev?.homes?.current_home, additional_notes: '' },
        },
      }))

      await axiomFetch('/marketing/messaging/sendOneOff', 'POST', {
        selected: [{ message: `${msg}`, cell: profile?.CONT_CELL_NUM }],
      })

      const email_body = {
        msg: {
          to: [profile?.CONT_EMAIL],
          cc: [profile?.REP_EMAIL || ''],
          // to: ['andrewj@driggstitle.com'],
          from: 'hello@theaxiomapp.com',
          subject: `potential buyer ${get_buyer_name()}`,
          // text: `${additional_info_text} ${textRef?.current?.value}`,
          text: msg,
          // attachments: [...png_attachments, ...pdf_attachments],
        },
        // url: window?.location?.href || '',
        // cont_name: profile?.CONT_NAME || '',
        // cont_cell_phone: profile?.CONT_CELL_NUM || '',
        // login_cell_phone:
        //   user?.name?.replace('+1', '') ||
        //   user?.nickname?.replace('+1', '') ||
        //   '',
        // bug_report_text: textRef?.current?.value || '',
        no_send_email: false,
        // insert_bugs: true,
      }
      await axiomFetch('/social/email/sendEmail', 'POST', email_body)
    }

    const get_report = async (type) => {
      let pdfs = {}
      setState((prev) => ({
        ...prev,
        homes: {
          ...prev?.homes,
          [type]: true,
        },
      }))
      if (type === 'school') {
        const school_pdf = await nodeProdFetch({
          url: `/node_to_oracle/api/v1/attom/property/pdf?address1=${home.prop_add}}&address2=${home.prop_city}, ${home.prop_st}&add_info=school`,
          response_type: 'text',
        })
        pdfs.school = school_pdf
      }
      if (type === 'wifi') {
        const wifi_pdf = await nodeProdFetch({
          url: `/node_to_oracle/api/v1/internet/pdf?zip=${home.prop_zip}`,
          response_type: 'text',
        })
        pdfs.wifi = wifi_pdf
      }
      if (type === 'market') {
        const market_pdf = await axiomFetch(
          `/marketing/materials/market_stats/pdf`,
          'POST',
          {
            state: home?.prop_st,
            city: home?.prop_city,
            zip: home?.prop_zip,
            days_back: 90,
            property_type: 'A',
            show_client: cont_profile?.PHOTO_LINK ? 1 : 0,
          }
        )
        pdfs.market = market_pdf
      }
      setState((prev) => ({
        ...prev,
        homes: {
          ...prev?.homes,
          [type]: false,
        },
      }))
      return pdfs[type]
    }

    console.log('pageT_type', page_type)
    return (
      <>
        {Object?.keys(home || {})?.length ? (
          page_type === 'plano' ? (
            <div>
              <HomePlanoSub
                state={state}
                setState={setState}
                page={page}
                setPage={setPage}
                home={home}
                showHomeModal={showHomeModal}
                setShowHomeModal={setShowHomeModal}
              />
            </div>
          ) : (
            <div>
              {/* <div className={'flex flex-col items-center'}> */}
              <div className='px-3 flex justify-between items-center mb-[10px]'>
                <p className={`${uiStyles.text_black_title} `}>Property View</p>
                <button
                  className={`${uiStyles.hover_btn_small} mt-[2px]`}
                  onClick={() => {
                    navigate('/homes/favorites', {
                      state: {
                        parent: '/homes/view',
                        prevParent: location?.state?.parent || '',
                      },
                    })
                  }}
                >
                  My Favorites
                </button>
              </div>

              {/* <img
              className='rounded-xl object-cover min-w-[326px]'
              src={home?.home_photo1}
            /> */}
              {page?.loading_photos ? (
                <div className='min-h-[266px]'>
                  <SmallLoadingRel />
                </div>
              ) : (
                <ListingPhotos
                  state={state}
                  setState={setState}
                  page={page}
                  showHomeModal={showHomeModal}
                  setShowHomeModal={setShowHomeModal}
                />
              )}
              {/* </div> */}
              <div className='flex ml-[15px] flex-col mt-[10px]'>
                {home?.prop_add ? (
                  <div className='flex'>
                    <p className=''>
                      <span className='font-bold text-[#344054]'>{`Address: `}</span>
                      <span className='max-w-[250px]'>
                        {get_address_str(home)}
                      </span>
                    </p>
                  </div>
                ) : (
                  ''
                )}

                <div className='flex justify-between items-end'>
                  {home?.list_price ? (
                    <div className='flex'>
                      <p className='font-bold text-[#344054]'>{`Price: `}</p>
                      <p className='ml-[5px] max-w-[250px]'>{currency(home)}</p>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
                {home?.beds ? (
                  <div className='flex'>
                    <p className='font-bold text-[#344054]'>{`Bedrooms: `}</p>
                    <p className='ml-[5px] max-w-[250px]'>{home?.beds}</p>
                  </div>
                ) : (
                  ''
                )}
                {home?.baths ? (
                  <div className='flex'>
                    <p className='font-bold text-[#344054]'>{`Bathrooms: `}</p>
                    <p className='ml-[5px] max-w-[250px]'>{home?.baths}</p>
                  </div>
                ) : (
                  ''
                )}
                {home?.sqft ? (
                  <div className='flex'>
                    <p className='font-bold text-[#344054]'>{`Sq Ft: `}</p>
                    <p className='ml-[5px] max-w-[250px]'>
                      {format_currency(home?.sqft || home?.sqft)}
                    </p>
                  </div>
                ) : (
                  ''
                )}
                {home?.lot_size_square_feet ||
                page?.add_data?.lot_size_square_feet ? (
                  <div className='flex'>
                    <p className='font-bold text-[#344054]'>{`Lot Size: `}</p>
                    <p className='ml-[5px] max-w-[250px]'>
                      {`${format_currency(
                        validate_number(
                          validate_number(
                            home?.lot_size_square_feet ||
                              page?.add_data?.lot_size_square_feet
                          ) / 43560,
                          2
                        )
                      )} acres` || ''}
                    </p>
                  </div>
                ) : (
                  ''
                )}
                {home?.year_built || page?.add_data?.year_built ? (
                  <div className='flex'>
                    <p className='font-bold text-[#344054]'>{`Year Built: `}</p>
                    <p className='ml-[5px] max-w-[250px]'>
                      {`${home?.year_built || page?.add_data?.year_built}` ||
                        ''}
                    </p>
                  </div>
                ) : (
                  ''
                )}

                <div
                  className={`flex justify-end mr-[20px] ${
                    page?.add_data?.baths && page?.add_data?.beds
                      ? 'mt-[-80px]'
                      : 'mt-[-40px]'
                  } cursor-pointer`}
                >
                  <div className='flex flex-col'>
                    <div className='flex'>
                      <button
                        className={`${uiStyles.hover_btn_small}`}
                        onClick={() => {
                          navigate('/homes/plano')
                        }}
                      >
                        AXIOM PLANO
                      </button>
                    </div>
                    <div className='flex items-center mt-[10px]'>
                      <div
                        className='mt-[10px] cursor-pointer'
                        onClick={async () => {
                          const favorited_homes = favoritedHomes?.filter(
                            (item) => item?.favorited === 'Y'
                          )
                          let new_homes = []
                          if (home?.favorited === 'Y') {
                            home.favorited = 'N'
                            new_homes = favorited_homes?.filter(
                              (item) => item.mls_id !== home.mls_id
                            )
                          } else {
                            home.favorited = 'Y'
                            new_homes = [...(favorited_homes || []), home]
                          }

                          setState((prev) => ({
                            ...prev,
                            homes: {
                              ...prev?.homes,
                              favorited_homes: new_homes,
                            },
                          }))
                          set_storage('favorited_homes', new_homes)
                          await axiomFetch(
                            '/marketing/homes/favorite',
                            'POST',
                            {
                              mls_id: home?.mls_id,
                              favorited: home?.favorited,
                            }
                          )
                        }}
                      >
                        <img
                          className='w-[38px] mr-[30px] mt-[-19px]'
                          src={
                            images_homes?.[
                              home.favorited === 'Y' ? 'heart_filled' : 'heart'
                            ]
                          }
                        />
                      </div>

                      <img
                        className='w-[27px]'
                        src={images_homes.google_location}
                        onClick={() => {
                          navigate('/homes/places')
                        }}
                      />
                    </div>
                  </div>
                </div>
                {page?.add_data?.mls_id ? (
                  <div className='mt-[10px] w-full'>
                    <p className='font-bold text-[#344054] mb-[3px]'>{`AXIOM Estimators: `}</p>
                    {calc?.home_find_loading ? (
                      <SmallLoadingRel />
                    ) : (
                      <div className='flex gap-[10px]'>
                        <button
                          className={uiStyles.hover_btn_small}
                          onClick={() => instanciate_buy(page?.add_data)}
                        >
                          Buyer Estimate
                        </button>
                        <button
                          className={uiStyles.hover_btn_small}
                          onClick={() => instanciate_sell(page?.add_data)}
                        >
                          Seller Estimate
                        </button>
                      </div>
                    )}
                  </div>
                ) : (
                  ''
                )}

                {page?.add_data?.mls_id ? (
                  <div className='mt-[10px] w-full'>
                    <p className='font-bold text-[#344054] mb-[3px]'>{`Open House Times: `}</p>
                    {calc?.home_find_loading ? (
                      <SmallLoadingRel />
                    ) : (
                      <div className='flex flex-col gap-[5px]'>
                        {page?.open_house_data?.map((item) => {
                          return (
                            <div className='w-full' key={item.open_house_key}>
                              {getDateString({
                                date: item.open_house_start_time,
                                format: 'mmm dd, yyyy hh:mi am',
                              })}{' '}
                              - {getCurrentTime(item.open_house_end_time)}
                            </div>
                          )
                        })}
                      </div>
                    )}
                  </div>
                ) : (
                  ''
                )}

                {page?.add_data?.public_remarks ? (
                  <div className='mt-[20px] w-full'>
                    <p className='font-bold text-[#344054]'>{`Description: `}</p>
                    <p className='pr-3 leading-6'>
                      {`${page?.add_data?.public_remarks}` || ''}
                    </p>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className='flex flex-col mt-[20px] gap-[20px] pb-[20px]'>
                {['school', 'market', 'wifi']?.map((item) => {
                  return (
                    <div className='flex justify-between items-center t-[10px] gap-[10px]'>
                      <div className='flex items-center gap-[20px] ml-[30px]'>
                        <img src={images_homes[item]} />
                        <p
                          className={`${uiStyles.text_black_subtitle} mt-[10px] mr-[40px] capitalize`}
                        >
                          {item} Report
                        </p>
                      </div>

                      {state?.homes?.[item] ? (
                        <div className='flex items-center w-[25px] h-[25px] mt-[12px] mr-[50px]'>
                          <SmallLoadingRel />
                        </div>
                      ) : (
                        <div
                          className='cursor-pointer flex items-center mt-[12px] mr-[50px]'
                          onClick={async () => {
                            const url = await get_report(item)
                            open_url(url)
                          }}
                        >
                          <BsBoxArrowRight size={25} />
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
              <div className='px-3 mb-[10px]'>
                <label className={uiStyles.label}>Additional Notes</label>
                <textarea
                  className={uiStyles.text_area}
                  value={home?.additional_notes}
                  onChange={(e) => {
                    setState((prev) => ({
                      ...prev,
                      homes: {
                        ...prev?.homes,
                        current_home: {
                          ...prev?.homes?.current_home,
                          additional_notes: e.target.value,
                        },
                      },
                    }))
                  }}
                />
              </div>
              <div className='px-3 pb-[10px]'>
                <div className='flex justify-end mt-[10px]'>
                  <>
                    {messageSent?.type === 'search' ? (
                      <div
                        className={`${uiStyles[messageSent.status]} mb-[20px]`}
                      >
                        {messageSent.message}
                      </div>
                    ) : (
                      <button
                        className={`${uiStyles.hover_btn} w-full`}
                        onClick={create_info_request}
                      >
                        Request More Info
                      </button>
                    )}
                  </>
                </div>
                <p className='text-center text-[12px] mb-4'>
                  By clicking Request more info you agree to receive messages
                  from the real estate agent who sent you this card. Reply STOP
                  to opt out. Data and msg rates may apply.
                </p>
              </div>
            </div>
          )
        ) : (
          <div className='px-3'>
            <p>
              We're sorry. We were unable to find your home! check back later.
            </p>
            <div className='flex justify-end'>
              <button
                className={uiStyles.hover_btn}
                onClick={() => {
                  navigate('/homes')
                }}
              >
                Home Search
              </button>
            </div>
          </div>
        )}
      </>
    )
  }
)
