import React from 'react'
import { format_currency, validate_number } from '../../helpers'

const CalcCompareCard = ({
  width = 'min-w-[130px]',
  title,
  loan_type,
  term,
  down_payment,
  interest_rate,
  apr,
  monthly_payment,
  bring_to_close,
  down_payment_total,
  principal,
  total_investment,
  total_saved,
  bg,
  text_color,
}) => {
  return (
    <div>
      <div
        className={`${width} mt-[4px] border-2 border-gray-200 rounded-[5px]`}
      >
        {title ? (
          <p
            className={`${bg} ${text_color} text-center font-bold border-b-2 rounded-t-[3px]`}
          >
            {title}
          </p>
        ) : (
          ''
        )}
        <div className='px-[3px]'>
          {loan_type ? (
            <div className='text-center font-bold mt-[3px] uppercase text-xs'>
              {loan_type}
            </div>
          ) : (
            ''
          )}
          <div className='text-xs mt-[3px] flex flex-col gap-[5px]'>
            {term ? (
              <div className='flex justify-between'>
                <p className='text-center font-bold capitalize'>{'years'}</p>
                <p className='text-center font-bold capitalize'>{term}</p>
              </div>
            ) : (
              ''
            )}
            {down_payment ? (
              <div className='flex justify-between'>
                <p className='text-center font-bold capitalize'>{'down'}</p>
                <p className='text-center font-bold capitalize'>
                  {down_payment}%
                </p>
              </div>
            ) : (
              ''
            )}
            {interest_rate ? (
              <div className='flex justify-between'>
                <p className='text-center font-bold capitalize'>{'Rate'}</p>
                <p className='text-center font-bold capitalize'>
                  {interest_rate}%
                </p>
              </div>
            ) : (
              ''
            )}
            {apr ? (
              <div className='flex justify-between'>
                <p className='text-center font-bold capitalize'>{'APR'}</p>
                <p className='text-center font-bold capitalize'>{apr}%</p>
              </div>
            ) : (
              ''
            )}
            {monthly_payment ? (
              <div className='flex justify-between'>
                <p className='text-center font-bold capitalize'>
                  {'Monthly Payment'}
                </p>
                <p className='text-center font-bold capitalize'>
                  {`$${format_currency(validate_number(monthly_payment, 0))}`}
                </p>
              </div>
            ) : (
              ''
            )}
            {bring_to_close ? (
              <div className='flex justify-between'>
                <p className='text-center font-bold'>{'Bring to Close'}</p>
                <p className='text-center font-bold capitalize'>
                  {`$${format_currency(validate_number(bring_to_close, 0))}`}
                </p>
              </div>
            ) : (
              ''
            )}
            {down_payment_total ? (
              <div className='flex justify-between'>
                <p className='text-center font-bold capitalize'>
                  {'Down Payment'}
                </p>
                <p className='text-center font-bold capitalize'>
                  {`$${format_currency(
                    validate_number(down_payment_total, 0)
                  )}`}
                </p>
              </div>
            ) : (
              ''
            )}
            {principal ? (
              <div className='flex justify-between'>
                <p className='text-center font-bold capitalize'>
                  {'Loan Amount'}
                </p>
                <p className='text-center font-bold capitalize'>
                  {`$${format_currency(validate_number(principal, 0))}`}
                </p>
              </div>
            ) : (
              ''
            )}
            {total_investment ? (
              <div className='flex justify-between'>
                <p className='text-center font-bold capitalize'>
                  {'Total Investment'}
                </p>
                <p className='text-center font-bold capitalize'>
                  {`$${format_currency(validate_number(total_investment, 0))}`}
                </p>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        {total_saved ? (
          <div
            className={`${bg} ${text_color} flex justify-between font-bold pt-[3px] text-xs px-[3px] rounded-b-[5px]`}
          >
            <p className='text-center font-bold'>{'Savings'}</p>
            <p
              className={`text-center font-bold capitalize ${
                validate_number(width) > 200 ? 'max-w-[100px]' : 'max-w-[70px]'
              }  overflow-scroll`}
            >
              {`$${format_currency(validate_number(total_saved, 0))}`}
            </p>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default CalcCompareCard
