import { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import { SmallLoadingRel } from './SmallLoadingRel'
import uiStyles from './uiStyles'
export default function LeadItem({
  item,
  startDays,
  setStartDays,
  endDays,
  setEndDays,
  setLeads,
  index,
  showInput = false,
  link,
}) {
  const { axiomFetch } = useAxiomFetch()
  const fetchTimeout = useRef(0)
  const lastFetchtime = useRef(0)
  const isEven = ((item?.index || index) + 1) % 2 === 0
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const get_new_count = async (num, forward) => {
    setLoading(true)
    const stats = await axiomFetch(
      `/client/leads/stats`,
      'POST',
      {
        startDays: forward
          ? startDays
          : {
              ...startDays,
              [item.key]: num,
            },
        endDays: forward ? { ...endDays, [item.key]: num } : endDays,
        search: item.key,
      },
      {},
      false,
      false,
      700
    )
    console.log(stats)
    setLeads((prev) => ({ ...prev, [item.key]: stats?.[item.key] || 0 }))
    setLoading(false)
  }
  console.log(item.icon)
  return (
    <div
      className={`swipeable flex items-center px-4 py-3 ${
        isEven ? 'bg-white' : 'bg-gray-100'
      }`}
    >
      <div
        className='cursor-pointer swipeable icon max-w-[40px]'
        onClick={async () => {
          if (item?.link && typeof item?.link === 'string') {
            navigate(item?.link)
          } else if (item?.link && typeof item?.link === 'function') {
            return await item?.link()
          }
        }}
      >
        {item?.icon}
      </div>
      <div className='swipeable info flex-1 pl-3'>
        <h3 className='swipeable text-sm text-primary'>{item?.title}</h3>
        <p className='swipeable text-sm'>{item?.description}</p>
      </div>
      {showInput ? (
        <div className='number text-sm text-primary text-start'>
          <div className='flex flex-col items-center'>
            <label className={'text-xs'}>{item?.label}</label>
            <input
              className={uiStyles.input_num}
              value={
                item?.go_forward ? endDays?.[item.key] : startDays?.[item?.key]
              }
              onChange={async (e) => {
                const num = Number(e.target.value?.replace(/\D/g, ''))
                if (num > 365) {
                  return
                }
                if (item?.go_forward) {
                  setEndDays((prev) => ({
                    ...prev,
                    [item.key]: num,
                  }))
                } else {
                  setStartDays((prev) => ({
                    ...prev,
                    [item.key]: num,
                  }))
                }
                if (num >= 0) {
                  await get_new_count(num, item?.go_forward)
                }
              }}
            />
          </div>
        </div>
      ) : (
        ''
      )}
      <div className='swipeable number text-sm text-primary w-[80px] text-center'>
        {loading ? <SmallLoadingRel /> : item?.number}
      </div>
    </div>
  )
}
