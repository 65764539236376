import React from 'react'
import { useDistribute } from '../../../contexts/DistributeContext'
import uiStyles from '../../ui/uiStyles'

export const AiSchedule = () => {
  const { distribute, saveDistribute } = useDistribute()
  const type =
    (distribute?.type === 'social' ? distribute?.social : distribute?.type) ||
    'instagram'
  const content = distribute?.content?.[type]
  return (
    <div>
      <label className={`${uiStyles.label} mt-[5px]`}>Schedule Date</label>
      <input
        type='datetime-local'
        value={
          content?.schedule_date
            ? new Date(content?.schedule_date)
                .toLocaleString('sv')
                .replace(/(\.\d{3})|Z$/g, '')
            : new Date().toLocaleString('sv').replace(/(\.\d{3})|Z$/g, '')
        }
        className={uiStyles.input}
        onChange={(e) => {
          saveDistribute((prev) => ({
            ...prev,
            content: {
              ...prev?.content,
              [type]: {
                ...prev?.content?.[type],
                schedule_date: new Date(e.target.value).toISOString(),
              },
            },
          }))
        }}
      />
    </div>
  )
}
