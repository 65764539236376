import React from 'react'
import Wrapper from '../../components/layout/Wrapper'
import SubNav from '../../components/SubNav'
import TitleIcon from '../../components/ui/TitleIcon'
import RTP from '../../components/pages/TransactionsLobby/RTP'
const PageRTP = () => {
  return (
   <>
   <Wrapper>
    <SubNav/>
    <TitleIcon>RTP/Wires</TitleIcon>
    <RTP/>
   </Wrapper>
   </>
  )
}

export default PageRTP