import { useEffect, useRef, useState } from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'
import ch_images from '../../../assets/images/conversations'
import {
  formatPhoneNumber,
  getCurrentTime,
  getDateString,
  set_storage,
} from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { CircleCheckToggle } from '../../form/CircleCheckToggle'
import ImageUpload from '../../form/ImageUpload'
import LabelInput from '../../form/LabelInput'
import Textarea from '../../form/Textarea'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import uiStyles from '../../ui/uiStyles'

const EditAnnouncement = ({
  state,
  setState,
  page,
  setPage,
  setShowModal,
  market_name,
  type,
}) => {
  console.log('create_state', state)
  const [inputValue, setInputValue] = useState(
    state?.buyer_tours?.ann_edit || {}
  )
  const [loading, setLoading] = useState(false)
  const [aiLoading, setAiLoading] = useState(false)
  const { axiomFetch } = useAxiomFetch()
  const startRef = useRef(null)
  useEffect(() => {
    startRef.current.focus()
  }, [])
  const handleChange = (e) => {
    let { name, value } = e.target
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
      evt_id: state?.buyer_tours?.event_edit?.event_id,
    }))
  }

  const handle_images = async (e) => {
    e.preventDefault()
    console.log(e?.target?.files)
    console.log(e?.dataTransfer?.files)
    let limit = 1
    let total =
      (state?.buyer_tours?.ann_edit?.files?.length || 0) +
      (e?.target?.files?.length || 0) +
      (e?.dataTransfer?.files?.length || 0)
    if (total > limit) {
      console.log('limit reached')
      //   setError(`Max file limit is ${limit} file.`)
      return
    }
    if (e?.target?.files?.length) {
      console.log('file transfer')

      for (const file of e?.target.files) {
        setState((prev) => ({
          ...prev,
          buyer_tours: {
            ...prev?.buyer_tours,
            ann_edit: {
              ...prev?.buyer_tours?.ann_edit,
              ann_url: URL.createObjectURL(file),
              files: [
                ...(prev?.buyer_tours?.ann_edit?.files || []),
                { url: URL.createObjectURL(file), file: file },
              ],
            },
          },
        }))
      }
    } else {
      console.log('data transfer')
      for (const file of e?.dataTransfer?.files) {
        setState((prev) => ({
          ...prev,
          buyer_tours: {
            ...prev?.buyer_tours,
            ann_edit: {
              ...prev?.buyer_tours?.ann_edit,
              ann_url: URL.createObjectURL(file),
              files: [
                ...(prev?.buyer_tours?.ann_edit?.files || []),
                { url: URL.createObjectURL(file), file: file },
              ],
            },
          },
        }))
      }
    }
  }

  const save_ann = async () => {
    const data = {
      tour_type_id: state?.buyer_tours?.event_edit?.tour_experience_id,
      event_id: state?.buyer_tours?.event_edit?.event_id,
      ann_ord:
        (state?.data?.event_tour_ann
          ?.filter(
            (item) =>
              item.event_id?.toString() ===
              state?.buyer_tours?.event_edit?.event_id?.toString()
          )
          ?.reduce(
            (curr, val) =>
              Number(curr?.ann_ord || 0) > Number(val?.ann_ord || 0)
                ? curr
                : val,
            []
          )?.ann_ord || 0) + 1,
      ...inputValue,
      files: state?.buyer_tours?.ann_edit?.files || [],
    }
    console.log('initial_data', data)
    setLoading(true)
    const result = await axiomFetch(
      '/tours/announcement/add',
      'POST',
      data,
      {},
      true
    )
    console.log('result', result)
    setState((prev) => {
      const event_tour_ann = prev?.buyer_tours?.ann_edit?.event_id
        ? [
            ...prev?.data?.event_tour_ann?.filter(
              (item) =>
                item.default_uuid !== prev?.buyer_tours?.ann_edit?.default_uuid
            ),
            result?.data,
          ]
        : [...prev?.data?.event_tour_ann, result?.data || {}]

      set_storage('listing_tours', { ...prev?.data, event_tour_ann })
      return {
        ...prev,
        data: { ...prev?.data, event_tour_ann },
      }
    })

    setLoading(false)
    setShowModal(false)
  }

  return (
    <div>
      <h1 className='mb-[5px]'>Create an Announcement</h1>
      <div className='overflow-y-scroll max-h-[450px] min-h-[175px] mb-[15px]'>
        <LabelInput
          ref={startRef}
          left_label={'Title'}
          placeholder='announcement'
          name={'ann_title'}
          value={inputValue?.ann_title || ''}
          handleChange={handleChange}
        />
        <Textarea
          placeholder='details...'
          name={'ann_desc'}
          value={inputValue?.ann_desc}
          onChange={handleChange}
        />
        <>
          {state?.buyer_tours?.ann_edit?.ann_url ? (
            <div className='relative'>
              {state?.buyer_tours?.ann_edit?.ann_url?.includes('.pdf') ||
              state?.buyer_tours?.ann_edit?.files?.[0]?.file?.type?.includes(
                'pdf'
              ) ? (
                <iframe
                  className='max-h-[250px] rounded-md'
                  src={state?.buyer_tours?.ann_edit?.ann_url}
                  title={state?.buyer_tours?.ann_edit?.ann_url}
                />
              ) : (
                <img
                  className='max-h-[250px]'
                  src={state?.buyer_tours?.ann_edit?.ann_url}
                />
              )}
              <div
                className='absolute top-[10px] left-[2px] cursor-pointer'
                onClick={() => {
                  setState((prev) => {
                    const ret_data = {
                      ...prev?.buyer_tours,
                      ann_edit: {
                        ...prev?.buyer_tours?.ann_edit,
                        ann_url: '',
                        files: [],
                      },
                    }
                    set_storage('buyer_tours', ret_data)
                    return { ...prev, buyer_tours: ret_data }
                  })
                }}
              >
                <AiFillCloseCircle size={25} />
              </div>
            </div>
          ) : (
            <ImageUpload
              title='Announcement Image'
              secondary_title={'Drag or click to upload'}
              subtitle={'SVG, PNG, JPG, PDF'}
              multiple={true}
              handler={handle_images}
            />
          )}
        </>
      </div>
      <div className={`flex justify-end gap-[10px] items-center`}>
        {!loading ? (
          <>
            <button
              className={`${uiStyles.white_btn}`}
              onClick={() => {
                setShowModal(false)
              }}
            >
              Cancel
            </button>

            <button className={`${uiStyles.hover_btn}`} onClick={save_ann}>
              Save
            </button>
          </>
        ) : (
          <div className={`${uiStyles.loading_msg} min-w-0 w-[400px]`}>
            Loading... Images may take a minute.
          </div>
        )}
      </div>
    </div>
  )
}

export default EditAnnouncement
