import React, { useRef } from 'react'
import { format_currency } from '../../../helpers'
import { CircleCheckToggle } from '../../form/CircleCheckToggle'
import ButtonsRow from '../../ui/ButtonsRow'
import uiStyles from '../../ui/uiStyles'
export const HomeFilter = ({ state, setState, setShowModal }) => {
  const toggles = [
    'for_sale',
    'open_house',
    'coming_soon',
    'cancelled',
    'expired',
    'under_contract',
    'sold',
    'off_market',
  ]
  return (
    <div className='overscroll-none'>
      <div className='flex items-center gap-[10px]'>
        <div>
          <label className={`${uiStyles.label}`}>Min Price</label>
          <input
            className={uiStyles.input_med}
            placeholder={'min price'}
            value={format_currency(state?.homes?.min_price || '')}
            onChange={(e) => {
              setState((prev) => ({
                ...prev,
                homes: {
                  ...prev?.homes,
                  skip: 0,
                  min_price: e.target.value,
                },
              }))
            }}
          />{' '}
        </div>
        <div className='mt-[22px]'>-</div>
        <div>
          <label className={`${uiStyles.label}`}>Max Price</label>
          <input
            className={uiStyles.input_med}
            placeholder={'max price'}
            value={format_currency(state?.homes?.max_price || '')}
            onChange={(e) => {
              setState((prev) => ({
                ...prev,
                homes: {
                  ...prev?.homes,
                  skip: 0,
                  page: 1,
                  max_price: e.target.value,
                },
              }))
            }}
          ></input>
        </div>
      </div>

      <div className='mt-[10px]'>
        <label>Bedrooms</label>
        <ButtonsRow
          items={['any', '1+', '2+', '3+', '4+', '5+']}
          handler={(item) => {
            console.log(item)
            setState((prev) => ({
              ...prev,
              homes: { ...prev.homes, skip: 0, page: 1, bedrooms: item },
            }))
          }}
          selected={state?.homes?.bedrooms}
          button_style={'text-[12px] pl-2'}
        />
      </div>
      <div className='mt-[10px]'>
        <label>Bathrooms</label>
        <ButtonsRow
          items={['any', '1+', '2+', '3+', '4+', '5+']}
          handler={(item) => {
            console.log(item)
            setState((prev) => ({
              ...prev,
              homes: { ...prev.homes, skip: 0, page: 1, bathrooms: item },
            }))
          }}
          selected={state?.homes?.bathrooms}
          button_style={'text-[12px] pl-2'}
        />
      </div>
      <div className='flex flex-wrap'>
        {toggles?.map((item) => {
          return (
            <div className='min-w-[73px] ml-[70px] mt-[15px] cursor-pointer'>
              <CircleCheckToggle
                name={item}
                type='toggle'
                toggle_left={false}
                toggle_color_grad={'from-[#0A66C2] to-[#0A66C2]'}
                toggle_color={'text-[#000]'}
                toggle_left_label={''}
                toggle_right_label={item?.split('_')?.join(' ')}
                inputValue={state?.homes}
                handle_change={async (e) => {
                  setState((prev) => {
                    return {
                      ...prev,
                      homes: {
                        ...prev?.homes,
                        skip: 0,
                        page: 1,
                        [item]: prev?.homes?.[item] === 'Y' ? 'N' : 'Y',
                      },
                    }
                  })
                }}
              />
            </div>
          )
        })}
      </div>

      <div className='mt-[20px] flex justify-end gap-[10px]'>
        <button
          className={uiStyles.white_btn}
          onClick={() => {
            setState((prev) => ({
              ...prev,
              homes: {
                ...prev?.homes,
                skip: 0,
                page: 1,
                min_price: '',
                max_price: '',
                bedrooms: 'any',
                bathrooms: 'any',
                open_house: 'N',
                cancelled: 'N',
                expired: 'N',
                under_contract: 'N',
                sold: 'N',
                off_market: 'N',
                coming_soon: 'N',
              },
            }))
          }}
        >
          Reset
        </button>
        <button
          className={uiStyles.hover_btn}
          onClick={() => {
            setShowModal(false)
          }}
        >
          Done
        </button>
      </div>
    </div>
  )
}
