import React, { useEffect, useState } from 'react'
import images_logos from '../../../assets/images/axiom_logos'
import { useDistribute } from '../../../contexts/DistributeContext'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import Modal from '../../Modal'
import ListItem from '../../ui/ListItem'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import SwipeConfig from '../../ui/SwipeConfig'
import uiStyles from '../../ui/uiStyles'
import Social from '../AI/Social'
import AiMediaPosts from './AiMediaPosts'
import { AiPostsEdit } from './AiPostsEdit'
const AiMedia = () => {
  const { axiomFetch } = useAxiomFetch()
  const { distribute, saveDistribute } = useDistribute()
  const [selected, setSelected] = useState(1)
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const type =
    distribute?.type === 'social'
      ? distribute?.social || 'facebook'
      : 'facebook'
  const content = distribute?.content?.[type] || {}

  const save_distribute = (key, value) => {
    saveDistribute((prev) => ({
      ...prev,
      content: {
        ...(prev?.content || []),
        [type]: { ...(prev?.content?.[type] || []), [key]: value },
      },
    }))
  }

  useEffect(() => {
    const controller = new AbortController()
    const get_posts = async () => {
      console.log('hello')
      console.log(content?.view_page_id, content?.selected_page_id)
      console.log(content?.pages)
      console.log(
        content?.pages?.find(
          (item) =>
            item.page_id ===
            (content?.view_page_id || content?.selected_page_id)
        )
      )
      setLoading(true)
      if (!content?.selected_page_id) {
        const pages = await axiomFetch('/social/facebook/pages', 'GET')
        if (pages?.error && !controller?.signal?.aborted) {
          return setLoading(false)
        }
        content.selected_page_id = pages?.find(
          (item) => item.page_type === 'active'
        )?.page_id
      }
      const query =
        content?.view_page_id || content?.selected_page_id
          ? `?page_id=${
              content?.view_page_id || content?.selected_page_id
            }&ig_id=${
              content?.pages?.find(
                (item) =>
                  item.page_id ===
                  (content?.view_page_id || content?.selected_page_id)
              )?.instagram_id || ''
            }`
          : ''

      const posts = await axiomFetch(
        `/social/${type}/posts${query}`,
        'GET',
        {},
        {},
        false,
        controller?.signal
      )
      if (!controller?.signal?.aborted) {
        console.log(posts)
        save_distribute('posts', posts)
        setLoading(false)
      }
    }

    get_posts()
    return () => {
      controller.abort()
    }
  }, [content?.view_page_id, type])

  console.log(content)
  const Pages = () => {
    return (
      <div className='px-3 mt-[10px]'>
        {' '}
        {content?.pages?.length ? (
          <select
            className={uiStyles.select}
            value={content?.view_page_id || content?.selected_page_id}
            onChange={(e) => {
              console.log(e.target.value)
              save_distribute('view_page_id', e.target.value)
            }}
          >
            {content?.pages?.map((page) => {
              if (type === 'instagram') {
                if (!page.instagram_id) {
                  return null
                }
                let selected_page = false
                if (
                  page.instagram_type === 'active' &&
                  !content?.view_page_id
                ) {
                  selected_page = true
                }
                if (content?.view_page_id === page.page_id) {
                  selected_page = true
                }
                return (
                  <option key={page.page_id} value={page.page_id}>
                    {page.instagram_name}
                  </option>
                )
              } else {
                let selected_page = false
                if (page.page_type === 'active' && !content?.view_page_id) {
                  selected_page = true
                }
                if (content?.view_page_id === page.page_id) {
                  selected_page = true
                }
                return (
                  <option key={page.page_id} value={page.page_id}>
                    {page.name}
                  </option>
                )
              }
            })}
          </select>
        ) : (
          ''
        )}
      </div>
    )
  }

  //   const PostEdit = () => {
  //     return (
  //       <div>
  //         <label>Edit Message</label>
  //         <textarea
  //           value={content?.selected_post?.message}
  //           onChange={(e) => {
  //             save_post('message', e.target.value)
  //           }}
  //           className={uiStyles.text_area}
  //         >
  //           {content.selected_post.message}
  //         </textarea>
  //         <div className='flex justify-end'>
  //           {messageSent?.type === 'save_edit_post' ? (
  //             <div className={`${uiStyles[messageSent.status]} mb-[20px]`}>
  //               {messageSent.message}
  //             </div>
  //           ) : (
  //             <button
  //               className={uiStyles.hover_btn}
  //               onClick={async () => {
  //                 handle_msg(
  //                   'loading_msg',
  //                   'Updating Post',
  //                   'save_edit_post',
  //                   true
  //                 )
  //                 await axiomFetch(`/social/facebook/post/update`, 'POST', {
  //                   post_id: content.selected_post.id,
  //                   message: content?.selected_post.message,
  //                 })
  //                 const posts = content.posts.filter(
  //                   (item) => item.id !== content.selected_post.id
  //                 )
  //                 save_distribute('posts', [content.selected_post, ...posts])
  //                 handle_msg(
  //                   'sucess_msg',
  //                   'Post Updated!',
  //                   'save_edit_post',
  //                   true
  //                 )

  //                 setShowModal(false)
  //               }}
  //             >
  //               Save
  //             </button>
  //           )}
  //         </div>
  //       </div>
  //     )
  //   }

  return (
    <div>
      <Social subtitle={'Select media to view posts'} />
      <Pages />
      <AiMediaPosts loading={loading} content={content} type={type} />

      {showModal ? (
        <Modal
          modalName={'media_post_edit'}
          setShowModal={setShowModal}
          showModal={showModal}
          showClose={false}
        >
          <AiPostsEdit setShowModal={setShowModal} />
        </Modal>
      ) : (
        ''
      )}
    </div>
  )
}

export default AiMedia
