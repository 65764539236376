import React, { forwardRef } from 'react'

const LabelText = forwardRef(
  (
    {
      label,
      top_label,
      left_label,
      left_label_width,
      name,
      value,
      className = 'h-[50px]',
      container_class = '',
      handleChange = () => {},
      ...rest_props
    },
    ref
  ) => {
    return (
      <div className={`${container_class}`}>
        <label className='text-sm font-medium text-[#344054]'>
          {top_label}
        </label>
        <div className={`flex w-full mt-0`}>
          {left_label ? (
            <div
              className={`text-sm ${
                left_label_width ? left_label_width : 'w-[50%]'
              } overflow-x-scroll rounded-l-md  py-[12px] pl-[10px] pr-[2px] border border-gray-300 leading-4 text-thirdary`}
            >
              {left_label}
            </div>
          ) : (
            ''
          )}
          <textarea
            onChange={handleChange}
            ref={ref}
            name={name}
            value={value}
            className={`text-xs resize-vertical w-full border border-gray-300 leading-4 text-thirdary pl-[2px] pt-[2px] ${className} `}
            {...rest_props}
          ></textarea>
        </div>
      </div>
    )
  }
)

export default LabelText
