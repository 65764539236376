import { useEffect } from 'react'
import { usePairwise } from '../../../contexts/PairwiseContext'

const PairwiseRTP = () => {
  const { pairwise, savePairwise } = usePairwise()

  return <div>PairwiseRTP</div>
}

export default PairwiseRTP
