import React from 'react'
import Wrapper from '../../components/layout/Wrapper'
import { AiMedia } from '../../components/pages/AiSlots'
import SubNav from '../../components/SubNav'

export default function PageAiMediaPosts() {
  return (
    <Wrapper>
      <SubNav />
      <AiMedia />
    </Wrapper>
  )
}
