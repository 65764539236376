import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useListingTours } from '../../../contexts/ListingToursContext'
import { set_storage } from '../../../helpers'
import { List } from '../../helpers'
import uiStyles from '../../ui/uiStyles'

const TourExperienceLobby = () => {
  const { listingTours, saveListingTours, is_assigned_tour, get_show_tours } =
    useListingTours()
  const navigate = useNavigate()
  console.log('tours', listingTours?.data?.tour_types)
  console.log(
    'tours_assigned',
    listingTours?.data?.tour_types?.filter((item) => {
      console.log(item?.type_id)
      if (item?.type_id === 1) {
        return false
      } else if (
        !is_assigned_tour(item?.type_id) &&
        item?.event_type_id === 163
      ) {
        return false
      } else {
        return true
      }
    })
  )
  return (
    <div>
      <h3 className={`${uiStyles.text_black_subtitle} px-3 mb-[10px]`}>
        Select Your Tour Experience:
      </h3>
      <List
        list={get_show_tours()
          ?.sort((a, b) => a?.type_id - b?.type_id)
          ?.map((item) => ({
            title: item?.type_name,
            image: item?.van_photo,
            swipe_right: () => {
              return (
                <div
                  className='cursor-pointer'
                  onClick={() => {
                    saveListingTours((prev) => {
                      const buyer_tours = {
                        ...prev?.buyer_tours,
                        tour_experience_id: item?.type_id,
                        event_type_id: item?.event_type_id,
                        is_agent_tour:
                          Number(item?.event_type_id) === 163 ? 'Y' : 'N',
                      }
                      set_storage('buyer_tours', buyer_tours)
                      return { ...prev, buyer_tours }
                    })
                    return navigate('/user/buyer_tours/')
                  }}
                >
                  Open
                </div>
              )
            },
          }))}
      />
    </div>
  )
}

export default TourExperienceLobby
