import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

export const DefaultsContext = createContext()

export const DefaultsProvider = ({ children, userData }) => {
  const [defaults, setDefaults] = useState({})

  const saveDefaults = useCallback((data) => {
    setDefaults(data)
  }, [])

  const value = useMemo(
    () => ({
      defaults,
      saveDefaults,
    }),
    [defaults, saveDefaults]
  )

  return (
    <DefaultsContext.Provider value={value}>
      {children}
    </DefaultsContext.Provider>
  )
}

export const useDefaults = () => {
  const context = useContext(DefaultsContext)

  if (!context) {
    throw Error('TourContext must be used inside an TourListingContextProvider')
  }

  return context
}
