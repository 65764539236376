import React from 'react'
import { useProfile } from '../../../contexts/UserContext'
import UserThumb from '../../../assets/images/profile-silhouette.svg'
const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const ProfileHeader = () => {
  const { profile } = useProfile()
  return (
    <div className='px-2'>
      <div className='flex mb-3'>
        <div className='w-[40px] h-[40px] mt-1'>
          {(profile.PHOTO_LINK || profile?.account?.photo_url) && (
            <img
              src={
                profile.PHOTO_LINK || profile?.account?.photo_url || UserThumb
              }
              alt='user thumbnail'
              className='w-full h-full rounded-full object-cover'
            />
          )}
        </div>
        <div className='flex-1 pl-3 overflow-x-scroll'>
          <h3 className='text-base text-primary'>{profile.CONT_EMAIL}</h3>
          {/* <p className="text-sm text-gray-500">to adamd@driggstitle.com</p> */}
        </div>
        <div className=' text-sm w-[50px] ml-[9px] mt-[3px] text-gray-500 overflow-hidden overflow-ellipsis whitespace-nowrap'>
          {`${monthNames[new Date().getMonth()]} ${new Date().getDate()}`}
        </div>
      </div>
    </div>
  )
}
