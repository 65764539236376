import React from 'react'
import { useAi } from '../../../contexts/AiContext'
import TitleIcon from '../../ui/TitleIcon'
import uiStyles from '../../ui/uiStyles'
import { AskAxiom } from '../AI/AskAxiom'
import { AiDistributeAction, AiImage } from './index.js'
import { useNavigate } from 'react-router-dom'
export const AiContent = ({ title = true }) => {
  const { ai } = useAi()
  const navigate = useNavigate()
  console.log("AI",ai)
  return (
    <div className='px-3'>
      {title ? (
        <div className='mb-[20px]'>
          <TitleIcon>Axiom Ai Content Creator</TitleIcon>
        </div>
      ) : (
        ''
      )}
      {!ai?.state?.run || ai.state?.internal_run ? (
        <AskAxiom type='' height_change={0.15} show_type_label={false} />
      ) : (
        ''
      )}
      <AiDistributeAction
        action_type='copy'
        text_to_copy={ai?.state?.message || ''}
      />
      <div className='mb-[20px]'>
        <AiImage title={'Add an image'} type='ai' />
      </div>
      <div className='w-full flex justify-end pb-[20px] gap-[5px]'>
        <button
          onClick={() => navigate('/user/axiom-ai-distribute')}
          className={uiStyles.hover_btn}
        >
          Next
        </button>
      </div>
    </div>
  )
}
