import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import PropertyItem from '../../ui/PropertyItem'
import home_placeholder from '../../../assets/images/Home_stock.jpeg'
import {
  date_within_days,
  just_listed_days,
  listing_status,
} from '../../../helpers'
import { useMktReqs } from '../../../contexts/MktReqsContext'
import { useReceiver } from '../../../contexts/ReceiverContext'

export const MarketingMaterialListingSlider = ({
  state,
  setState,
  image_link = () => {},
  my_listings_look = false,
}) => {
  const { saveMktReqs } = useMktReqs()
  const { saveReceiver } = useReceiver()
  const find_sub_topic = (listing, topic) => {
    if (
      date_within_days(listing?.list_date, just_listed_days) &&
      listing?.mls_status === 'Active'
    ) {
      return 1
    }
    return topic
  }
  const listings =
    (state?.listings || state?.card?.listings)
      ?.sort((a, b) => new Date(b.list_date) - new Date(a.list_date))
      ?.sort(
        (a, b) =>
          listing_status[a?.mls_status]?.order -
          listing_status[b?.mls_status]?.order
      ) || []
  return (
    <div className='bg-gray-100'>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        onSlideChange={(e) => {
          setState((prev) => ({
            ...prev,
            current_listing: listings?.[e.realIndex],
            create_order: {
              ...prev?.create_order,
              sub_topic_property: find_sub_topic(
                listings?.[e.realIndex],
                listing_status[listings?.[e.realIndex]?.mls_status]?.topic_id
              ),
            },
          }))
        }}
        onSwiper={(swiper) => {
          try {
            setState((prev) => ({
              ...prev,
              swiper,
              current_listing: listings?.length === 1 ? listings?.[0] : {},
              create_order:
                listings?.length === 1
                  ? {
                      ...prev?.create_order,
                      sub_topic_property: find_sub_topic(
                        listings?.[0],
                        listing_status[listings?.[0]?.mls_status]?.topic_id
                      ),
                    }
                  : { ...prev?.create_order },
            }))
            const index = listings?.findIndex(
              (item) => item?.mls_id === state?.current_listing?.mls_id
            )
            if (index > 0) {
              swiper.slideTo(index === -1 ? 0 : index, 0)
              return
            }
            if (index === 0 || index === -1) {
              swiper.slideTo(1, 900)
            }
            setTimeout(() => {
              if (index === 0 || index === -1) {
                swiper.slideTo(0, 900)
              }
              swiper.slideTo(index === -1 ? 0 : index, 0)
            }, 175)
          } catch (error) {
            console.log(error)
          }
        }}
      >
        {listings?.map((item, index) => (
          <SwiperSlide key={index}>
            <PropertyItem
              my_listings_look={my_listings_look}
              click_me
              listing={item}
              image_link={image_link}
              show_filter={true}
              filter_list={listings?.map((item) => {
                return {
                  click_me: true,
                  image_link: (item) => {
                    const change_listing_index = listings?.findIndex(
                      (listing) => listing.armls_id === item?.armls_id
                    )
                    state?.swiper.slideTo(change_listing_index, 0)

                    saveMktReqs((prev) => ({
                      ...prev,
                      show_property_filter: false,
                    }))
                    saveReceiver((prev) => ({
                      ...prev,
                      show_property_filter: false,
                    }))
                    setState((prev) => ({
                      ...prev,
                      show_property_filter: false,
                      current_listing: [
                        ...listings,
                        ...(prev?.listings_sold || []),
                      ][change_listing_index],
                    }))
                  },
                  ...item,
                }
              })}
              filter_type='material_filter'
              // swipe_button_prev={index !== 0}
              // swipe_button_next={
              //   index !==
              //   [...(state?.listings || []), ...(state?.listings_sold || [])]
              //     ?.length -
              //     1
              // }
              handle_swipe_next={() => {
                state?.swiper.slideNext(300)

                setState((prev) => ({
                  ...prev,
                  current_listing: [
                    ...(prev?.listings || []),
                    ...(prev?.listings_sold || []),
                  ][state?.swiper?.realIndex],
                }))
              }}
              handle_swipe_prev={() => {
                state?.swiper.slidePrev(300)
                setState((prev) => ({
                  ...prev,
                  current_listing: [
                    ...(prev?.listings || []),
                    ...(prev?.listings_sold || []),
                  ][state?.swiper?.realIndex],
                }))
              }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
