import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { ServiceImages } from '../../services/ServiceMarketing'
import { useProfile } from '../../contexts/UserContext'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import Pagination from './Pagination'
import { useAi } from '../../contexts/AiContext'
import { SmallLoadingRel } from './SmallLoadingRel'
import { content_types } from '../../data/data'
import { useDistribute } from '../../contexts/DistributeContext'
export default function PhotoGallery({ className, type }) {
  const { axiomFetch } = useAxiomFetch()
  const { ai, saveAi } = useAi()
  const { distribute, saveDistribute } = useDistribute()
  const dist_type =
    (distribute?.type === 'social' ? distribute?.social : distribute?.type) ||
    'facebook'
  const content = distribute?.content?.[dist_type]
  const images_length =
    [
      type === 'ai'
        ? ai?.image_upload?.gallery?.images?.length
        : content?.image_upload?.gallery?.images?.length,
    ]?.[0] || 0
  const curr_page =
    [
      type === 'ai'
        ? ai?.image_upload?.gallery?.page
        : content?.image_upload?.gallery?.page,
    ]?.[0] || 1
  const start =
    [
      type === 'ai'
        ? ai?.image_upload?.gallery?.start
        : content?.image_upload?.gallery?.start,
    ]?.[0] || 9
  const max_page = Math.ceil(images_length / start)
  const { profile } = useProfile()
  const [imageActive, setImageActive] = useState()

  useEffect(() => {
    const controller = new AbortController()
    const get_images = async () => {
      saveAi((prev) => ({
        ...prev,
        image_upload: {
          ...prev?.image_upload,
          gallery: { ...prev?.image_upload?.gallery, loading: true },
        },
      }))
      let images
      try {
        images = await axiomFetch(
          `/marketing/content/images?skip=${
            ai?.image_upload?.gallery?.skip || 0
          }&limit=${1000}&type=${'facebook'}`,
          'GET',
          {},
          {},
          false,
          controller?.signal
        )
      } catch (error) {
        console.log(error)
      }

      if (images?.length) {
        saveAi((prev) => ({
          ...prev,
          image_upload: {
            ...prev?.image_upload,
            gallery: {
              ...prev?.image_upload?.gallery,
              images: [
                ...(prev?.image_upload?.gallery?.images || []),
                ...images,
              ],
            },
          },
        }))
        let distribute_content = distribute?.content || {}

        for (const content_type of type === 'ai'
          ? content_types
          : [dist_type]) {
          distribute_content[content_type] = {
            ...distribute_content?.[content_type],
            image_upload: {
              ...distribute_content?.[content_type]?.image_upload,
              gallery: {
                ...distribute_content?.[content_type]?.image_upload?.gallery,
                images: [
                  ...(distribute_content?.[content_type]?.image_upload?.gallery
                    ?.images || []),
                  ...images,
                ],
              },
            },
          }
        }
        saveDistribute((prev) => ({
          ...prev,
          content: distribute_content,
        }))
      }
      saveAi((prev) => ({
        ...prev,
        image_upload: {
          ...prev?.image_upload,
          gallery: { ...prev?.image_upload?.gallery, loading: false },
        },
      }))
    }
    if (images_length < 10) {
      get_images()
    }
    return () => {
      controller?.abort()
    }
  }, [])

  const get_page_num = (change) => {
    const images_length = ai?.image_upload?.gallery?.images?.length || 0
    const max_page = Math.ceil(images_length / start)
    const save = (page) => {
      if (type === 'ai') {
        saveAi((prev) => {
          let distribute_content = distribute?.content || {}
          for (const content_type of content_types) {
            distribute_content[content_type] = {
              ...distribute_content?.[content_type],
              image_upload: {
                ...distribute_content?.[content_type]?.image_upload,
                gallery: { ...prev?.image_upload?.gallery, page },
              },
            }
          }
          saveDistribute((prev) => ({
            ...prev,
            content: distribute_content,
          }))
          return {
            ...prev,
            image_upload: {
              ...prev?.image_upload,
              gallery: { ...prev?.image_upload?.gallery, page },
            },
          }
        })
      } else {
        saveDistribute((prev) => ({
          ...prev,
          content: {
            ...prev?.content,
            [dist_type]: {
              ...prev?.content?.[dist_type],
              image_upload: {
                ...prev?.content?.[dist_type]?.image_upload,
                gallery: {
                  ...prev?.content?.[dist_type]?.image_upload?.gallery,
                  page,
                },
              },
            },
          },
        }))
      }
      return page
    }
    if (curr_page + change > max_page) {
      return save(1)
    } else if (curr_page + change <= 0) {
      return save(max_page)
    } else {
      return save(curr_page + change)
    }
  }

  const showNext = () => {
    const page = get_page_num(1)
    const save = (skip, limit) => {
      if (type === 'ai') {
        saveAi((prev) => {
          let distribute_content = distribute?.content || {}
          for (const content_type of content_types) {
            distribute_content[content_type] = {
              ...distribute_content?.[content_type],
              image_upload: {
                ...distribute_content?.[content_type]?.image_upload,
                gallery: {
                  ...prev?.image_upload?.gallery,
                  skip: (prev?.image_upload?.gallery?.skip || 0) + skip,
                  limit: (prev?.image_upload?.gallery?.limit || start) + limit,
                },
              },
            }
          }
          saveDistribute((prev) => ({
            ...prev,
            content: distribute_content,
          }))
          return {
            ...prev,
            image_upload: {
              ...prev?.image_upload,
              gallery: {
                ...prev?.image_upload?.gallery,
                skip: (prev?.image_upload?.gallery?.skip || 0) + skip,
                limit: (prev?.image_upload?.gallery?.limit || start) + limit,
              },
            },
          }
        })
      } else {
        saveDistribute((prev) => ({
          ...prev,
          content: {
            ...prev?.content,
            [dist_type]: {
              ...prev?.content?.[dist_type],
              image_upload: {
                ...prev?.content?.[dist_type]?.image_upload,
                gallery: {
                  ...prev?.content?.[dist_type]?.image_upload?.gallery,
                  skip:
                    (prev?.content?.[dist_type]?.image_upload?.gallery?.skip ||
                      0) + skip,
                  limit:
                    (prev?.content?.[dist_type]?.image_upload?.gallery?.limit ||
                      start) + limit,
                },
              },
            },
          },
        }))
      }
      return
    }
    if (page === max_page) {
      return save(start, images_length % start || start)
    }
    if (page === 1) {
      if (type === 'ai') {
        saveAi((prev) => {
          let distribute_content = distribute?.content || {}
          for (const content_type of content_types) {
            distribute_content[content_type] = {
              ...distribute_content?.[content_type],
              image_upload: {
                ...distribute_content?.[content_type]?.image_upload,
                gallery: {
                  ...prev?.image_upload?.gallery,
                  skip: 0,
                  limit: start,
                },
              },
            }
          }
          saveDistribute((prev) => ({
            ...prev,
            content: distribute_content,
          }))
          return {
            ...prev,
            image_upload: {
              ...prev?.image_upload,
              gallery: {
                ...prev?.image_upload?.gallery,
                skip: 0,
                limit: start,
              },
            },
          }
        })
      } else {
        saveDistribute((prev) => ({
          ...prev,
          content: {
            ...prev?.content,
            [dist_type]: {
              ...prev?.content?.[dist_type],
              image_upload: {
                ...prev?.content?.[dist_type]?.image_upload,
                gallery: {
                  ...prev?.content?.[dist_type]?.image_upload?.gallery,
                  skip: 0,
                  limit: start,
                },
              },
            },
          },
        }))
      }
      return
    }
    return save(start, start)
  }
  const showPrev = () => {
    let page = get_page_num(-1)
    if (page + 1 === max_page) {
      if (type === 'ai') {
        saveAi((prev) => {
          let distribute_content = distribute?.content || {}
          for (const content_type of content_types) {
            distribute_content[content_type] = {
              ...distribute_content?.[content_type],
              image_upload: {
                ...distribute_content?.[content_type]?.image_upload,
                gallery: {
                  ...prev?.image_upload?.gallery,
                  skip: prev?.image_upload?.gallery?.skip - start,
                  limit: images_length - (images_length % start || start),
                },
              },
            }
          }
          saveDistribute((prev) => ({
            ...prev,
            content: distribute_content,
          }))
          return {
            ...prev,
            image_upload: {
              ...prev?.image_upload,
              gallery: {
                ...prev?.image_upload?.gallery,
                skip: prev?.image_upload?.gallery?.skip - start,
                limit: images_length - (images_length % start || start),
              },
            },
          }
        })
      } else {
        saveDistribute((prev) => ({
          ...prev,
          content: {
            ...prev?.content,
            [dist_type]: {
              ...prev?.content?.[dist_type],
              image_upload: {
                ...prev?.content?.[dist_type]?.image_upload,
                gallery: {
                  ...prev?.content?.[dist_type]?.image_upload?.gallery,
                  skip:
                    prev?.content?.[dist_type]?.image_upload?.gallery?.skip -
                    start,
                  limit: images_length - (images_length % start || start),
                },
              },
            },
          },
        }))
      }
      return
    }
    if (page === max_page) {
      if (type === 'ai') {
        saveAi((prev) => {
          let distribute_content = distribute?.content || {}
          for (const content_type of content_types) {
            distribute_content[content_type] = {
              ...distribute_content?.[content_type],
              image_upload: {
                ...distribute_content?.[content_type]?.image_upload,
                gallery: {
                  ...prev?.image_upload?.gallery,
                  skip: images_length - (images_length % start || start),
                  limit: images_length,
                },
              },
            }
          }
          saveDistribute((prev) => ({
            ...prev,
            content: distribute_content,
          }))
          return {
            ...prev,
            image_upload: {
              ...prev?.image_upload,
              gallery: {
                ...prev?.image_upload?.gallery,
                skip: images_length - (images_length % start || start),
                limit: images_length,
              },
            },
          }
        })
      } else {
        saveDistribute((prev) => ({
          ...prev,
          content: {
            ...prev?.content,
            [dist_type]: {
              ...prev?.content?.[dist_type],
              image_upload: {
                ...prev?.content?.[dist_type]?.image_upload,
                gallery: {
                  ...prev?.content?.[dist_type]?.image_upload?.gallery,
                  skip: images_length - (images_length % start || start),
                  limit: images_length,
                },
              },
            },
          },
        }))
      }
      return
    }
    if (type === 'ai') {
      saveAi((prev) => {
        let distribute_content = distribute?.content || {}
        for (const content_type of content_types) {
          distribute_content[content_type] = {
            ...distribute_content?.[content_type],
            image_upload: {
              ...distribute_content?.[content_type]?.image_upload,
              gallery: {
                ...prev?.image_upload?.gallery,
                skip: prev?.image_upload?.gallery?.skip - start,
                limit: prev?.image_upload?.gallery?.limit - start,
              },
            },
          }
        }
        saveDistribute((prev) => ({
          ...prev,
          content: distribute_content,
        }))
        return {
          ...prev,
          image_upload: {
            ...prev?.image_upload,
            gallery: {
              ...prev?.image_upload?.gallery,
              skip: prev?.image_upload?.gallery?.skip - start,
              limit: prev?.image_upload?.gallery?.limit - start,
            },
          },
        }
      })
    } else {
      saveDistribute((prev) => ({
        ...prev,
        content: {
          ...prev?.content,
          [dist_type]: {
            ...prev?.content?.[dist_type],
            image_upload: {
              ...prev?.content?.[dist_type]?.image_upload,
              gallery: {
                ...prev?.content?.[dist_type]?.image_upload?.gallery,
                skip:
                  prev?.content?.[dist_type]?.image_upload?.gallery?.skip -
                  start,
                limit:
                  prev?.content?.[dist_type]?.image_upload?.gallery?.limit -
                  start,
              },
            },
          },
        },
      }))
    }
  }

  const galleryHTML =
    [
      type === 'ai'
        ? ai?.image_upload?.gallery?.images?.length
        : content?.image_upload?.gallery?.images?.length,
    ]?.[0] &&
    [
      type === 'ai'
        ? ai?.image_upload?.gallery?.images
        : content?.image_upload?.gallery?.images,
    ]?.[0]
      ?.slice(
        [
          type === 'ai'
            ? ai?.image_upload?.gallery?.skip
            : content?.image_upload?.gallery?.skip,
        ]?.[0] || 0,
        [
          type === 'ai'
            ? ai?.image_upload?.gallery?.limit
            : content?.image_upload?.gallery?.limit,
        ]?.[0] || start
      )
      .map((item, index) => {
        return (
          <div
            key={index}
            className='w-[33.3%] overflow-hidden mb-[10px]'
            onClick={() => {
              if (type === 'ai') {
                saveAi((prev) => {
                  return {
                    ...prev,
                    image_upload: {
                      ...prev?.image_upload,
                      gallery: {
                        ...prev?.image_upload?.gallery,
                        active:
                          prev?.image_upload?.gallery?.active?.url === item?.url
                            ? ''
                            : item,
                      },
                    },
                  }
                })
              }
              let distribute_content = distribute?.content || {}

              for (const content_type of type === 'ai'
                ? content_types
                : [dist_type]) {
                distribute_content[content_type] = {
                  ...distribute_content?.[content_type],
                  photo_url:
                    distribute_content?.[content_type]?.image_upload?.gallery
                      ?.active?.url === item?.url
                      ? ''
                      : item[content_type] || item?.url,
                  image_upload: {
                    ...distribute_content?.[content_type]?.image_upload,
                    gallery: {
                      ...distribute_content?.[content_type]?.image_upload
                        ?.gallery,
                      active:
                        distribute_content?.[content_type]?.image_upload
                          ?.gallery?.active?.url === item?.url
                          ? ''
                          : item,
                    },
                  },
                }
              }
              saveDistribute((prev) => ({
                ...prev,
                content: distribute_content,
              }))
            }}
          >
            <img
              key={index}
              src={item?.url}
              alt=''
              className={` h-[100px] object-cover rounded-2xl ${
                [
                  type === 'ai'
                    ? ai?.image_upload?.gallery?.active?.url === item?.url
                    : content?.image_upload?.gallery?.active?.url === item?.url,
                ]?.[0]
                  ? `border-[5px] border-[#06C270]`
                  : ''
              }`}
            />
          </div>
        )
      })

  if (ai?.image_upload?.gallery?.loading) {
    return <SmallLoadingRel />
  }

  return (
    <>
      <div className={`${className} flex flex-wrap`}>{galleryHTML}</div>
      <Pagination
        showlabel={false}
        showPrev={
          [
            type === 'ai'
              ? ai?.image_upload?.gallery?.length
              : content?.image_upload?.gallery?.length,
          ]?.[0] &&
          [
            type === 'ai'
              ? ai?.image_upload?.gallery?.length
              : content?.image_upload?.gallery?.length,
          ]?.[0] >= start
        }
        showNext={
          [
            type === 'ai'
              ? ai?.image_upload?.gallery?.length
              : content?.image_upload?.gallery?.length,
          ]?.[0] &&
          [
            type === 'ai'
              ? ai?.image_upload?.gallery?.length
              : content?.image_upload?.gallery?.length,
          ]?.[0] >= start
        }
        clickNextHandler={showNext}
        clickPrevHandler={showPrev}
        pageNum={
          (([
            type === 'ai'
              ? ai?.image_upload?.gallery?.skip
              : content?.image_upload?.gallery?.skip,
          ]?.[0] || 0) +
            [
              type === 'ai'
                ? ai?.image_upload?.gallery?.limit
                : content?.image_upload?.gallery?.limit,
            ]?.[0] || start) /
          [
            type === 'ai'
              ? ai?.image_upload?.gallery?.limit
              : content?.image_upload?.gallery?.limit,
          ]?.[0]
        }
      />
    </>
  )
}
