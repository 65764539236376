import { useEffect, useState } from 'react'

import { HiArrowDown, HiArrowUp } from 'react-icons/hi2'
import { FaBolt } from 'react-icons/fa'

import SubNav from '../SubNav'
import TitleIcon from '../ui/TitleIcon'
import SubTitle from '../ui/SubTitle'
import Badge from '../ui/Badge'
import ButtonWhite from '../ui/ButtonWhite'
import DataBox from '../ui/DataBox'
import ProgressBar from '../ui/ProgressBarAnalytics'
import Wrapper from '../layout/Wrapper'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import Modal from '../Modal'
import SmallLoading from '../ui/SmallLoading'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import { useTotals } from '../../contexts/TotalsContext'
export default function Analytics() {
  const { axiomFetch } = useAxiomFetch()
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const { totals, saveTotals } = useTotals()
  // const [totals, settotals] = useState()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const controller = new AbortController()
    setIsLoading(true)
    const update_totals = async () => {
      const totals_data = await axiomFetch(
        '/marketing/content/totals',
        'GET',
        {},
        {},
        false,
        controller.signal
      )
      saveTotals(totals_data)
      if (!controller.signal.aborted) {
        setIsLoading(false)
      }
    }
    update_totals()

    return () => {
      controller?.abort()
    }
  }, [])

  const get_limit_by_id = (id) => {
    let limit = totals?.limits?.filter((item) => item?.type_id === id)
    return limit?.[0]?.limit
  }
  const get_percentage_total = () => {
    console.log(get_limit_by_id(2))
    const sms_total = totals?.totals?.sms_cnt / get_limit_by_id(2)
    const social_total = totals?.totals?.post_cnt / get_limit_by_id(4)
    const email_total = totals?.totals?.email_cnt / get_limit_by_id(1)
    let total = ((sms_total + social_total + email_total) / 3) * 100
    // total = 10
    return total
  }

  const get_prev_month_percentage_total = () => {
    const sms_total = totals?.totals?.prev_m_sms_cnt / get_limit_by_id(2)
    const social_total = totals?.totals?.pre_m_post_cnt / get_limit_by_id(4)
    const email_total = totals?.totals?.prev_m_email_cnt / get_limit_by_id(1)
    let total = ((sms_total + social_total + email_total) / 3) * 100
    // total = 3
    return total
  }

  const modals = {
    token_pack: (
      <div>
        For the beta release there is currently no way to increase tokens!
      </div>
    ),
    plan: (
      <div>
        For the beta release there is currently no way to upgrade your plan!
      </div>
    ),
  }

  return (
    <Wrapper>
      <SubNav />
      <TitleIcon className='mb-5'>My Analytics</TitleIcon>
      {isLoading ? (
        <SmallLoading />
      ) : (
        <>
          <div className='border border-gray-200 rounded-lg py-4 px-2'>
            <SubTitle className='mb-2 text-center'>
              AXIOM Ai Content Created
            </SubTitle>
            <p className='text-center'>
              You’re using{' '}
              {get_percentage_total().toFixed(
                get_percentage_total() >= 1 ? 0 : 2
              )}
              % of your plans content.
            </p>
            <hr className='border-t-none border-gray-200 mt-5 mb-2' />
            <div className='flex labels justify-center items-center mb-5'>
              <div className='mx-2'>
                <span className='relative -top-[1px] inline-block mr-1 h-[8px] w-[8px] rounded-full  bg-gradient-to-b from-accent-150 to-accent-250'></span>
                Social
              </div>
              <div className='mx-2'>
                <span className='relative -top-[1px] inline-block mr-1 h-[8px] w-[8px] rounded-full  bg-gradient-to-b from-accent-200 to-accent-400'></span>
                SMS
              </div>
              <div className='mx-2'>
                <span className='relative -top-[1px] inline-block mr-1 h-[8px] w-[8px] rounded-full  bg-accent-600'></span>
                Email
              </div>
            </div>
            <div className='relative mb-5'>
              <div className='relative h-[260px] w-[260px] mx-auto'>
                <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
                  <div style={{ height: '260px', width: '260px' }}>
                    <ProgressBar
                      stringId={'pr1'}
                      percentage={(
                        (totals?.totals?.post_cnt / get_limit_by_id(4)) *
                        100
                      ).toFixed(0)}
                      color2='#05A660'
                      color1='#00CFDE'
                      strokeWidth={8}
                    />
                    {/* <CircularProgressbar strokeWidth={8} value={((totals?.POST_CNT / totals?.LEVEL_POST_QTY) * 100).toFixed(0)}  styles={buildStyles({pathColor: `#00CFDE`,})}/>; */}
                  </div>
                </div>

                <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
                  <div style={{ height: '208px', width: '208px' }}>
                    <ProgressBar
                      stringId={'pr2'}
                      percentage={(
                        (totals?.totals?.sms_cnt / get_limit_by_id(2)) *
                        100
                      ).toFixed(0)}
                      color2='#0063F7'
                      color1='#73DFE7'
                      strokeWidth={10}
                    />
                    {/* <CircularProgressbar strokeWidth={9} value={((totals?.SMS_CNT / totals?.LEVEL_TEXT_LIMIT) * 100).toFixed(0)}  styles={buildStyles({pathColor: `#0063F7`,})}/>; */}
                  </div>
                </div>
                <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
                  <div style={{ height: '157px', width: '157px' }}>
                    <ProgressBar
                      stringId={'pr3'}
                      percentage={(
                        (totals?.totals?.email_cnt / get_limit_by_id(1)) *
                        100
                      ).toFixed(0)}
                      color2='#3E7BFA'
                      color1='#3E7BFA'
                      strokeWidth={12}
                    />
                    {/* <CircularProgressbar strokeWidth={11} value={((totals?.EMAIL_CNT / totals?.LEVEL_EMAIL_LIMIT) * 100).toFixed(0)}  styles={buildStyles({pathColor: `#3E7BFA`,})}/>; */}
                  </div>
                </div>
              </div>

              <div className='absolute right-0 top-0'>
                <Badge
                  className={`${
                    get_percentage_total() >= get_prev_month_percentage_total()
                      ? 'text-accent-250'
                      : 'text-red-400'
                  } bg-[#ecfdf3]`}
                >
                  {get_percentage_total() >=
                  get_prev_month_percentage_total() ? (
                    <HiArrowUp className='inline-block' />
                  ) : (
                    <HiArrowDown className='inline-block' />
                  )}
                  {get_percentage_total() < get_prev_month_percentage_total()
                    ? `${(
                        (get_prev_month_percentage_total() /
                          get_percentage_total()) *
                          100 || 0
                      )
                        .toFixed(0)
                        .replace('Infinity', '0')}%`
                    : `${(
                        (get_prev_month_percentage_total() /
                          get_percentage_total()) *
                          100 || 0
                      )
                        .toFixed(0)
                        .replace('Infinity', '0')}%`}
                </Badge>
              </div>
              <div className='w-[90px] h-[90px] absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center flex-col'>
                <div className='font-semibold text-4xl text-primary mb-3'>
                  {totals?.totals?.sms_cnt +
                    totals?.totals?.email_cnt +
                    totals?.totals?.post_cnt}
                </div>
                <div className='text-primary text-base'>All Content</div>
              </div>
            </div>

            <div className='px-5'>
              <p className=' text-primary text-base  mb-2 text-center'>
                {get_percentage_total() >= 75
                  ? "You've almost reached your limit"
                  : ''}
              </p>
              <p className='text-center'>
                You have used{' '}
                {get_percentage_total().toFixed(
                  get_percentage_total() >= 1 ? 0 : 2
                )}
                % of your available content this month. Upgrade plan to get even
                more content.
              </p>
            </div>
            <hr className='my-4' />
            <div className='text-right flex flex-col gap-2 items-end'>
              <div
                onClick={() => {
                  setModalType('plan')
                  setShowModal(true)
                }}
              >
                <ButtonWhite>
                  <FaBolt className='inline-block text-accent-250 text-base' />
                  Upgrade Plan
                </ButtonWhite>
              </div>
              <div
                onClick={() => {
                  setModalType('token_pack')
                  setShowModal(true)
                }}
              >
                <ButtonWhite>
                  <FaBolt className='inline-block text-accent-250 text-base' />
                  Purchase a Token Pack
                </ButtonWhite>
              </div>
            </div>
          </div>
          <div className='flex flex-col  py-5 items-center'>
            <DataBox
              direction={
                totals?.totals?.post_cnt >= totals?.totals?.prev_m_post_cnt
                  ? 'up'
                  : 'down'
              }
              title='Social Shares'
              number={totals?.totals?.post_cnt}
              percentage={
                totals?.totals?.post_cnt >= totals?.totals?.prev_m_post_cnt
                  ? (
                      (totals?.totals?.prev_m_post_cnt /
                        totals?.totals?.post_cnt) *
                        100 || 0
                    ).toFixed(0)
                  : (
                      (totals?.totals?.post_cnt /
                        totals?.totals?.prev_m_post_cnt) *
                        100 || 0
                    ).toFixed(0)
              }
              time='last month'
              className='mb-5'
            />
            <DataBox
              direction={
                totals?.totals?.sms_cnt >= totals?.totals?.prev_m_sms_cnt
                  ? 'up'
                  : 'down'
              }
              title='SMS Sent'
              number={totals?.totals?.sms_cnt}
              percentage={
                totals?.totals?.sms_cnt >= totals?.totals?.prev_m_sms_cnt
                  ? (
                      (totals?.totals?.prev_m_sms_cnt /
                        totals?.totals?.sms_cnt) *
                        100 || 0
                    ).toFixed(0)
                  : (
                      (totals?.totals?.sms_cnt /
                        totals?.totals?.prev_m_sms_cnt) *
                        100 || 0
                    ).toFixed(0)
              }
              time='last month'
              className='mb-5'
            />
            <DataBox
              direction={
                totals?.totals?.email_cnt >= totals?.totals?.prev_m_email_cnt
                  ? 'up'
                  : 'down'
              }
              title='Emails'
              number={totals?.totals?.email_cnt}
              percentage={
                totals?.totals?.email_cnt >= totals?.totals?.prev_m_email_cnt
                  ? (
                      (totals?.totals?.prev_m_email_cnt /
                        totals?.totals?.email_cnt) *
                        100 || 0
                    ).toFixed(0)
                  : (
                      (totals?.totals?.email_cnt /
                        totals?.totals?.prev_m_email_cnt) *
                        100 || 0
                    ).toFixed(0)
              }
              time='last month'
              className='mb-5'
            />
            <DataBox
              direction={
                totals?.totals?.vbc_cnt >= totals?.totals?.prev_m_vbc_cnt
                  ? 'up'
                  : 'down'
              }
              title='VBC Shares'
              number={totals?.totals?.vbc_cnt}
              percentage={
                totals?.totals?.vbc_cnt >= totals?.totals?.prev_m_vbc_cnt
                  ? (
                      (totals?.totals?.prev_m_vbc_cnt /
                        totals?.totals?.vbc_cnt) *
                        100 || 0
                    ).toFixed(0)
                  : (
                      (totals?.totals?.vbc_cnt /
                        totals?.totals?.prev_m_vbc_cnt) *
                        100 || 0
                    ).toFixed(0)
              }
              time='last month'
              className='mb-5'
            />
            <DataBox
              direction={
                totals?.totals?.leads_downloaded_cnt >=
                totals?.totals?.prev_m_leads_downloaded_cnt
                  ? 'up'
                  : 'down'
              }
              title='Leads Lists Downloaded'
              number={totals?.totals?.leads_downloaded_cnt}
              percentage={
                totals?.totals?.leads_downloaded_cnt >=
                totals?.totals?.prev_m_leads_downloaded_cnt
                  ? (
                      (totals?.totals?.prev_m_leads_downloaded_cnt /
                        totals?.totals?.leads_downloaded_cnt) *
                        100 || 0
                    ).toFixed(0)
                  : (
                      (totals?.totals?.leads_downloaded_cnt /
                        totals?.totals?.prev_m_leads_downloaded_cnt) *
                        100 || 0
                    ).toFixed(0)
              }
              time='last month'
              className='mb-5'
            />

            {/* <DataBox
          direction="Down"
          title="SlyDials Sent"
          number="15"
          percentage="25"
          time="last month"
        /> */}
          </div>
        </>
      )}
      {showModal ? (
        <Modal
          modalName={modalType}
          height={'h-[200px]'}
          showModal={showModal}
          setShowModal={setShowModal}
          showClose={true}
        >
          {modals[modalType]}
        </Modal>
      ) : (
        ''
      )}
    </Wrapper>
  )
}
