import { useState } from 'react'
import Wrapper from '../../components/layout/Wrapper'
import { AiSlots } from '../../components/pages/AiSlots'
import SubNav from '../../components/SubNav'
import { useClaims } from '../../contexts/ClaimsContext'
import { get_storage } from '../../helpers'
const PageAi = () => {
  const { claims } = useClaims()
  return (
    <Wrapper>
      <SubNav
        showConversations={
          claims?.card_access || get_storage('uuid') ? false : true
        }
        logo_url={claims?.card_access || get_storage('uuid') ? '/card' : ''}
      />
      <AiSlots />
    </Wrapper>
  )
}

export default PageAi
