import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Wrapper from '../../components/layout/Wrapper'
import SubNav from '../../components/SubNav'
import TitleIcon from '../../components/ui/TitleIcon'
import uiStyles from '../../components/ui/uiStyles'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import GoogleIcon from '../../assets/images/google_location.png'
import Google from '../../assets/images/google.svg'
import SmallLoading from '../../components/ui/SmallLoading'
const Listing = ({ listing = {} }) => {
  const { axiomFetch } = useAxiomFetch()
  const [state, setState] = useState({})
  const { id } = useParams()
  useEffect(() => {
    const get_data = async () => {
      setState((prev) => ({
        ...prev,
        isLoading: true,
      }))
      let data
      if (listing?.mls_id !== id) {
        data = await axiomFetch('/armls/view/listings?new_result=true')
        console.log(data)
        data = data?.filter((listing) => {
          return listing.mls_id?.toString() === id
        })
      } else {
        data = [listing]
      }
      setState((prev) => ({
        ...prev,
        listing: data?.[0] || {},
      }))
      setState((prev) => ({
        ...prev,
        isLoading: false,
      }))
    }
    get_data()
  }, [])
  return (
    <>
      <Wrapper>
        <SubNav />
        <div className='pr-5 pt-3'>
          <TitleIcon></TitleIcon>
        </div>
        {state.isLoading ? (
          <SmallLoading />
        ) : (
          <div className={`${uiStyles.content_wrap}`}>
            <h1 className={`${uiStyles.text_black_subtitle}`}>
              {state?.listing?.prop_add},
            </h1>
            <h1 className={`${uiStyles.text_black_subtitle}`}>
              {state?.listing?.prop_city},{' '}
              {state?.listing?.prop_st === 'AZ'
                ? 'Arizona'
                : state?.listing?.prop_st}
              , {state?.listing?.prop_zip}
            </h1>
            <img
              className={`rounded-xl h-[150px] w-[304px]`}
              src={state?.listing?.home_photo1}
            />
            <div>
              <h2 className={`${uiStyles.text_black_subtitle} font-normal`}>
                Details:
              </h2>
              <p>
                MLS#: <span>{state?.listing?.mls_id || id}</span>
              </p>
              <p>
                General:{' '}
                <span className={`${uiStyles.text_gray} m-0 p-0`}>
                  {state?.listing?.beds} Bedrooms, {state?.listing?.baths} Bath,{' '}
                  {state?.listing?.sqft} Sq Ft
                </span>
              </p>
              <p>
                Year Built:{' '}
                <span className={`${uiStyles.text_gray}`}>
                  {state?.listing?.year_built}
                </span>
              </p>
              <p>
                GEO Area:{' '}
                <span className={`${uiStyles.text_gray}`}>
                  {state?.listing?.lat?.toFixed(5)} Lat{' '}
                  {state?.listing?.lng?.toFixed(5)} Lng
                </span>
              </p>
              <p>
                Status Type:{' '}
                <span className={`${uiStyles.text_gray}`}>
                  {state?.listing?.property_class === '%'
                    ? 'Residential'
                    : state?.listing?.property_class}
                </span>
              </p>
              {/* <p>Virtual Tour:<span className={`${uiStyles.text_gray}`}>{state?.listing?.MLS_ID}</span></p> */}
              <div className='w-full flex flex-col items-center'>
                <a
                  className='flex flex-col items-center'
                  draggable='false'
                  target='_blank'
                  rel='noreferrer'
                  href={`http://maps.google.com/maps?q=&layer=c&cbll=${state?.listing?.lat},${state?.listing?.lng}&cbp=11,0,0,0,0`}
                >
                  <img
                    draggable='false'
                    className='w-[308px] h-[175px] my-[10px]'
                    src={Google}
                  />
                  <img
                    draggable='false'
                    className='w-[35px] my-[10px]'
                    src={GoogleIcon}
                  />
                </a>
                <p className={`${uiStyles.text_black_subtitle}`}>
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }).format(state?.listing?.list_price)}
                </p>
              </div>
            </div>
          </div>
        )}
      </Wrapper>
    </>
  )
}

export default Listing
