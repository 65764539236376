import React, { useState } from 'react'
import images_logos from '../../../assets/images/axiom_logos'
import { useDistribute } from '../../../contexts/DistributeContext'
import ListItem from '../../ui/ListItem'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import SwipeConfig from '../../ui/SwipeConfig'
import Modal from '../../Modal'
import { AiPostsEdit } from './AiPostsEdit'
import { AiPostsDelete } from './AiPostsDelete'
import { useClaims } from '../../../contexts/ClaimsContext'
import useHelpers from '../../../hooks/useHelpers'
import { open_url_tab } from '../../../helpers'
const AiMediaPosts = ({ content, type, loading }) => {
  const posts = content?.posts?.posts?.data || content?.posts?.media?.data
  const { claims } = useClaims()
  const { open_url } = useHelpers()
  const { saveDistribute } = useDistribute()
  const [selected, setSelected] = useState(1)
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const modals = {
    edit: <AiPostsEdit setShowModal={setShowModal} />,
    delete: <AiPostsDelete setShowModal={setShowModal} />,
  }
  const save_distribute = (key, value) => {
    saveDistribute((prev) => ({
      ...prev,
      content: {
        ...(prev?.content || []),
        [type]: { ...(prev?.content?.[type] || []), [key]: value },
      },
    }))
  }
  return (
    <div className='flex flex-col gap-[2px] mt-[10px]'>
      {' '}
      {loading ? (
        <SmallLoadingRel />
      ) : (
        <>
          <div className='px-3 flex flex-wrap gap-[10px] mb-[10px]'>
            <div>Published Posts: {posts?.length || 0}</div>
            <div>Followers: {content?.posts?.followers_count || 0}</div>
            {content?.posts?.fan_count ? (
              <div>Fans: {content?.posts?.fan_count || 0}</div>
            ) : (
              ''
            )}
            <div>
              Total Likes:{' '}
              {content?.like_count ||
                posts?.reduce((curr, val) => {
                  return (
                    curr +
                    (val?.like_count ||
                      val?.reactions?.data?.filter(
                        (item) => item?.type === 'LIKE'
                      )?.length ||
                      0)
                  )
                }, 0) ||
                0}
            </div>
            {type === 'facebook' ? (
              <div>
                Total Loves:{' '}
                {posts?.reduce((curr, val) => {
                  return (
                    curr +
                    (val?.reactions?.data?.filter(
                      (item) => item?.type === 'LOVE'
                    )?.length || 0)
                  )
                }, 0)}
              </div>
            ) : (
              ''
            )}
            {type === 'instagram' ? (
              <div>
                Total Comments:{' '}
                {posts?.reduce((curr, val) => {
                  return curr + (val?.comments_count || 0)
                }, 0)}
              </div>
            ) : (
              ''
            )}
          </div>
          {posts?.length ? (
            (posts || [])?.map((post, index) => {
              return (
                <SwipeConfig
                  index={index}
                  selectedIndex={selected}
                  setSelectedIndex={setSelected}
                  widthConfigRight={44}
                  RightComponents={() => (
                    <div className='flex'>
                      <div
                        className=' cursor-pointer'
                        onClick={() => {
                          open_url_tab(
                            post?.permalink_url ||
                              post?.permalink ||
                              post?.link,
                            '',
                            'width=500,height=850'
                          )
                        }}
                      >
                        View
                      </div>

                      {claims?.client_proxy_login ? (
                        ''
                      ) : (
                        <>
                          {['facebook']?.includes(type) ? (
                            <>
                              <div className='px-3'> | </div>
                              <div
                                className=' cursor-pointer'
                                onClick={() => {
                                  save_distribute('selected_post', post)
                                  setModalType('edit')
                                  setShowModal(true)
                                }}
                              >
                                Edit{' '}
                              </div>
                              <div className='px-3'> | </div>
                              <div
                                className=' cursor-pointer'
                                onClick={() => {
                                  save_distribute('selected_post', post)
                                  setModalType('delete')
                                  setShowModal(true)
                                }}
                              >
                                Delete{' '}
                              </div>
                            </>
                          ) : (
                            ''
                          )}
                        </>
                      )}
                    </div>
                  )}
                  key={post.id}
                >
                  <ListItem
                    image={
                      post?.full_picture ||
                      post?.media_url ||
                      images_logos.facebook_icon
                    }
                    //   title={post?.NAME}
                    content={post?.message || post?.caption}
                    sub_content={
                      <div className='swipeable flex gap-[10px]'>
                        <p className='swipeable'>
                          Likes:{' '}
                          {post?.reactions?.data?.filter(
                            (item) => item?.type === 'LIKE'
                          )?.length ||
                            post?.like_count ||
                            0}
                        </p>
                        {type === 'instagram' ? (
                          <p className='swipeable'>
                            Comments: {post?.comments_count}
                          </p>
                        ) : (
                          <p className='swipeable'>
                            Loves:{' '}
                            {post?.reactions?.data?.filter(
                              (item) => item?.type === 'LOVE'
                            )?.length || 0}
                          </p>
                        )}
                      </div>
                    }
                    fill={post?.photo ? 'object-cover' : 'object-contain'}
                  />
                </SwipeConfig>
              )
            })
          ) : (
            <div className='py-3 px-3'>
              <p>You Don't have any posts yet.</p>
            </div>
          )}
        </>
      )}
      {showModal ? (
        <Modal
          modalName={modalType}
          height={'h-[320px]'}
          showModal={showModal}
          setShowModal={setShowModal}
          showClose={true}
        >
          {modals[modalType]}
        </Modal>
      ) : (
        ''
      )}
    </div>
  )
}

export default AiMediaPosts
