import React from 'react'
import uiStyles from '../ui/uiStyles'
import CalcLoanEdit from './CalcLoanEdit'

const CalcCompareEdit = ({ setShowModal, showModal }) => {
  return (
    <div>
      <CalcLoanEdit calculator_type='loan_2' />
      <CalcLoanEdit calculator_type='loan_3' />
      <div className='flex justify-end px-3'>
        <button
          className={`${uiStyles.hover_btn} mt-[10px]`}
          onClick={() => {
            if (setShowModal) {
              setShowModal(false)
            }
          }}
        >
          Done
        </button>
      </div>
    </div>
  )
}

export default CalcCompareEdit
