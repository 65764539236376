import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { format_currency } from '../helpers'
import { useProfile } from './UserContext'

export const PairwiseContext = createContext()

export const PairwiseProvider = ({ children, userData }) => {
  const donut_chart = {
    colors: ['#00E396'],
    fill: { type: 'gradient' },
    labels: ['P&I', 'Taxes', 'Insurance', 'PMI'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        // startAngle: -90,
        // endAngle: 275,
        donut: {
          size: '70%',
          labels: {
            show: true,
            name: { offsetY: 18 },
            value: { offsetY: -13, fontSize: '18px' },
            total: {
              value: 200,
              show: true,
              showAlways: true,
              label: 'per month',
              style: {
                fontSize: '20px',
              },
              fontSize: '8px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 600,
              color: '#373d3f',
              formatter: function (w) {
                const total = w.globals.seriesTotals.reduce((a, b) => {
                  return a + b
                }, 0)
                return `$${format_currency(Math.round(total))}`
              },
            },
          },
        },
      },
    },
    legend: {
      show: false,
      floating: true,
      fontSize: '10px',
      offsetX: 10,
      offsetY: 80,
      horizontalAlign: 'left',
      position: 'bottom',
      itemMargin: {
        horizontal: 20,
        vertical: 2,
      },
      formatter: (name, opts) => {
        return `${name} - $${format_currency(
          opts.w.config.series[opts.seriesIndex],
          true,
          2,
          null,
          2
        )}`
      },
    },
  }
  const { profile } = useProfile()
  const [pairwise, setPairwise] = useState({
    estimator: {
      chart: { ...donut_chart, series: [1000], labels: 'Monthly Earnings' },
      data_options: {
        underwriter_split: 0.15,
      },
      values: {
        pair_entity: '',
        home_price: format_currency(450000, false, 2),
        number_of_deals: 49,
        shares_owned: 5,
        county: profile?.ACCOUNT_ID === 1320 ? 'maricopa' : 'clark',
      },
      maximums: {
        number_of_deals: 49,
      },
      types: {
        pair_entity: 'options',
        home_price: '$',
        number_of_deals: 'int',
        shares_owned: 'int',
        county: 'options',
      },
      names: {
        pair_entity: 'Entity',
        home_price: 'Avg. Home Price',
        county: 'County',
        number_of_deals: '# of deals',
        shares_owned: 'Shares Owned',
      },
      option_names: {
        pair_entity: ['pair_1', 'pair_2'],
        county: ['Maricopa', 'Clark'],
      },
      option_values: {
        pair_entity: [1, 2],
        county: ['Maricopa', 'Clark'],
      },
    },
    documents: {
      chart: { ...donut_chart, series: [1000], labels: 'Monthly Earnings' },
      data_options: {
        underwriter_split: 0.15,
      },
      values: {
        pair_entity: '',
        shares_owned: 5,
        name: '',
        email: '',
        social_security_num: '',
        bank_name: '',
        account_names: '',
        account_number: '',
        routing_number: '',
        // home_price: format_currency(450000, false, 2),
        // number_of_deals: 49,
        // county: profile?.ACCOUNT_ID === 1320 ? 'maricopa' : 'clark',
      },
      maximums: {
        number_of_deals: 49,
      },
      types: {
        name: '',
        pair_entity: 'options',
        home_price: '$',
        number_of_deals: 'int',
        shares_owned: 'int',
        county: 'options',
        social_security_num: 'int_str_9',
        bank_name: '',
        account_names: '',
        account_number: 'int_str',
        routing_number: 'int_str',
        email: '',
      },
      names: {
        pair_entity: 'Entity*',
        name: 'Shares Owned By*',
        email: 'Email*',
        social_security_num: 'Social Security #*',
        home_price: 'Avg. Home Price',
        county: 'County',
        number_of_deals: '# of deals',
        shares_owned: 'Shares to Purchase*',
        bank_name: 'Bank Name',
        account_names: 'Account Name(s)',
        account_number: 'Account #',
        routing_number: 'ABA/Routing #',
      },
      option_names: {
        pair_entity: ['pair_1', 'pair_2'],
        county: ['Maricopa', 'Clark'],
      },
      option_values: {
        pair_entity: [1, 2],
        county: ['Maricopa', 'Clark'],
      },
    },
  })

  const savePairwise = useCallback((data) => {
    setPairwise(data)
  }, [])

  const value = useMemo(
    () => ({
      pairwise,
      savePairwise,
    }),
    [pairwise, savePairwise]
  )

  return (
    <PairwiseContext.Provider value={value}>
      {children}
    </PairwiseContext.Provider>
  )
}

export const usePairwise = () => {
  const context = useContext(PairwiseContext)

  if (!context) {
    throw Error(
      'PairwiseContext must be used inside an PairwiseContextProvider'
    )
  }

  return context
}
