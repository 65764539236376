import { useEffect } from 'react'
import { AiFillEdit, AiFillMinusCircle } from 'react-icons/ai'
import { useCalc } from '../../contexts/CalcContext'
import { capitalize, format_currency } from '../../helpers'
import uiStyles from '../ui/uiStyles'
import CalcSellOffer from './CalcSellOffer'
import CalcSellOfferStar from './CalcSellOfferStar'

const CalcSellMultipleOffers = ({ implementation_type = 'sell' }) => {
  const { calc, saveCalc, show_offer_page, get_offer_name } = useCalc()
  useEffect(() => {
    const get_offers = () => {}

    get_offers()
    return () => {}
  }, [calc?.offers])

  return (
    <div>
      {calc?.options?.view_type === 'multiple_offers' ||
      implementation_type === 'multiple_offers' ? (
        <>
          <div className='flex flex-col justify-between min-h-[550px]'>
            <div>
              {show_offer_page ? (
                <div className='flex flex-col gap-[10px] mt-[10px] px-3 max-h-[500px] overflow-y-scroll'>
                  {Object.entries(calc?.offers?.items)?.map(([key, value]) => {
                    return (
                      <div key={key} className='border-2 p-1 border-thirdary'>
                        <div className='flex justify-between items-center border-b-2 border-gray-200 pb-1'>
                          <div className='flex gap-[10px] items-center'>
                            <CalcSellOfferStar
                              on_last_offer={false}
                              offer={key}
                            />
                            <p className='font-bold'>
                              {value?.values?.offer_price
                                ? `$${format_currency(
                                    value?.values?.offer_price,
                                    false,
                                    2
                                  )?.replace('$', '')}`
                                : get_offer_name(key, 'str')}
                            </p>
                          </div>
                          <div className='flex items-center gap-[15px]'>
                            <AiFillMinusCircle
                              color={'tomato'}
                              className='cursor-pointer'
                              onClick={() => {
                                const items = calc?.offers?.items || {}
                                delete items[key]
                                saveCalc((prev) => {
                                  const data = {
                                    ...prev,
                                    offers: { ...prev?.offers, items },
                                  }
                                  return data
                                })
                              }}
                            />
                            <AiFillEdit
                              size={20}
                              className='cursor-pointer'
                              onClick={() => {
                                saveCalc((prev) => {
                                  const data = {
                                    ...prev,
                                    offers: {
                                      ...prev?.offers,
                                      data_options: {
                                        ...prev?.offers?.data_options,
                                        edit_offer: key,
                                      },
                                    },
                                  }
                                  return data
                                })
                              }}
                            />
                          </div>
                        </div>
                        <div className='mt-[5px] flex flex-col gap-[5px]'>
                          <div className='flex justify-between'>
                            <p>Offer Price</p>
                            <p className='font-bold'>
                              {' '}
                              {value?.values?.offer_price
                                ? `$${format_currency(
                                    value?.values?.offer_price,
                                    false,
                                    2
                                  )?.replace('$', '')}`
                                : ''}
                            </p>
                          </div>
                          <div className='flex justify-between'>
                            <p>Net at Close</p>
                            <p className='font-bold'>
                              {' '}
                              {value?.values?.net_at_close
                                ? `$${format_currency(
                                    value?.values?.net_at_close,
                                    false,
                                    2
                                  )?.replace('$', '')}`
                                : ''}
                            </p>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              ) : (
                <CalcSellOffer />
              )}
            </div>
            {show_offer_page ? (
              <div>
                <button
                  className={`${uiStyles.hover_btn} w-full rounded-none`}
                  onClick={() => {
                    saveCalc((prev) => ({
                      ...prev,
                      offers: {
                        ...prev?.offers,
                        data_options: {
                          ...prev?.offers?.data_options,
                          new_offer: true,
                        },
                      },
                    }))
                  }}
                >
                  Add New Offer
                </button>
              </div>
            ) : (
              ''
            )}
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  )
}

export default CalcSellMultipleOffers
