import React from 'react';
import Wrapper from '../../components/layout/Wrapper';
import SubNav from '../../components/SubNav';
import TitleIcon from '../../components/ui/TitleIcon';
import useAxiomFetch from '../../hooks/useAxiomFetch';
import uiStyles from '../../components/ui/uiStyles';
import { useEffect, useState } from 'react';
import {List} from '../../components/pages/Lists'
import SmallLoading from '../../components/ui/SmallLoading';
import SwipeConfig from '../../components/ui/SwipeConfig';

const PageLeadList = () => {
    const {axiomFetch} = useAxiomFetch()
    const [lists, setLists] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        const Getlists = async () => {
            setIsLoading(true)
            const data = await axiomFetch('/client/listings/lists')
            console.log(data)
            setLists(data)
            setIsLoading(false)
        }
        Getlists()
    }, [])

    const sort_list = (a, b) => {
        let nameA = a?.LIST_NAME?.toUpperCase()
        let nameB = b?.LIST_NAME?.toUpperCase()
        if (!nameB) return -1
        if (!nameA) return 1
        if (nameA > nameB ) {
            return 1;
        }
        if (nameA < nameB) {
            return -1;
        }
        
        return 0;
    }

    const counter = (arr, key) => {
        console.log(arr, key)
        let counter = 0
        for (const obj of arr) {
            if (obj[key?.toUpperCase()] && obj.STATUS === 'A') {
                counter++
            } 
        }
        return counter
    }

  return (
    <Wrapper>
        <SubNav/>
        <TitleIcon className="mb-5">List Config</TitleIcon>
        <div className='flex flex-col px-[20px] gap-[20px] overflow-y-scroll max-h-[550px]'>
            <h1 className='mt-[20px] text-[20px]'>Active Lists: {counter(lists, 'LIST_ID')}</h1>
            {isLoading && <div><SmallLoading/></div>}
            {!isLoading && lists.sort((a, b) => sort_list(a, b)).map((list, index) => {
                if (list.STATUS === 'D') {
                    return
                } 
                return (
                    
                    <List  key={`${list.LIST_ID}-${index}`} list={list}/>
                    
                )}
            )}
            </div>
            <div className='p-[20px]'>
            {!isLoading && <div className='flex justify-end gap-3'>
            <button 
            className={uiStyles.hover_btn}
            onClick={async () => {
                // setIsLoading(true)
                // const data = await axiomFetch('/client/listings/lists', 'POST', [{}])
                // const refetch = await axiomFetch('/client/listings/lists')
                // setLists(refetch)
                // console.log(data)
                // setIsLoading(false)
                setLists([...lists, {}])
            }}>ADD</button>
            <button 
            className={uiStyles.hover_btn}
            onClick={async () => {
                console.log('hello')
                let update_data = []
                for (const list of lists) {
                    if (!list.updated) {
                        continue
                    } else {
                        delete list?.updated
                        update_data.push(list)
                        
                    }
                }
                console.log(update_data)
                setIsLoading(true)
                const data = await axiomFetch('/client/listings/lists', 'POST', lists)
                const refetch = await axiomFetch('/client/listings/lists')
                setLists(refetch)
                setIsLoading(false)
                console.log(data)
            }}>Save</button>
            </div>}
        </div>
    </Wrapper>
  )
}

export default PageLeadList