import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

export const LimitsContext = createContext()

export const LimitsProvider = ({ children, userData }) => {
  const [limits, setLimits] = useState(null)

  const saveLimits = useCallback((data) => {
    setLimits(data)
  }, [])

  const value = useMemo(
    () => ({
      limits,
      saveLimits,
    }),
    [limits, saveLimits]
  )

  return (
    <LimitsContext.Provider value={value}>{children}</LimitsContext.Provider>
  )
}

export const useLimits = () => {
  const context = useContext(LimitsContext)

  if (!context) {
    throw Error('AuthContext must be used inside an AuthContextProvider')
  }

  return context
}
