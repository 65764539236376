import Wrapper from '../../components/layout/Wrapper'
import { Tour } from '../../components/pages/tours/'
import SubNav from '../../components/SubNav'
import { useListingTours } from '../../contexts/ListingToursContext'
import { get_storage } from '../../helpers'
const PageTour = () => {
  const { listingTours, saveListingTours } = useListingTours()
  console.log('state', listingTours)
  console.log('uuid', get_storage('uuid'))
  return (
    <Wrapper>
      <SubNav
        showConversations={!listingTours?.tour_state?.from_card}
        logo_url={
          listingTours?.tour_state?.from_card || get_storage('uuid')
            ? '/card'
            : '/user'
        }
      />
      <Tour state={listingTours} setState={saveListingTours} />
    </Wrapper>
  )
}

export default PageTour
