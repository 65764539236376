import { useState, useEffect } from 'react'
import { format_input, validate_number } from '../../helpers'
import LabelInput from '../form/LabelInput'
import { Schedule } from '../form/Schedule'
import Select from '../form/Select'
import List from './List'

const FilterList = ({
  list,
  filters = {},
  filter_strategy = {},
  property_component = false,
  my_listings_look = false,
}) => {
  const [strategy, setStrategy] = useState(filter_strategy)
  const [listItems, setListItems] = useState(list)
  useEffect(() => {
    setStrategy(filter_strategy)
    setListItems(list)
  }, [list])

  const update_input = (options) => {
    const calculator_type = 'title_fees'
    const { name, value } = format_input(options)
    let type = options?.type || strategy?.types?.[`${name}`] || ''
    setStrategy((prev) => {
      const result = {
        ...prev,
        values: { ...prev?.values, [name]: value },
        types: { ...prev?.types, [name]: type },
      }

      return result
    })
  }

  const get_inputs = () => {
    const types = strategy?.types || {}
    const names = strategy?.names || {}
    const values = strategy?.values || {}
    const placeholders = strategy?.placeholders || {}
    const options_values = strategy?.options_values || {}
    const options_names = strategy?.options_names || {}
    return Object.entries(values || [])
      ?.filter(([key, value]) => {
        if (strategy?.show_filters?.length < 1) {
          return true
        }
        if (strategy?.show_filters?.includes(key)) {
          return true
        } else {
          return false
        }
      })
      ?.map(([key, value]) => {
        const type = types[key]
        const name = names[key]
        const placeholder = placeholders?.[key] || ''
        const label_width = 'w-[80%]'
        const initial_data = {
          name: key,
          value,
          placeholder,
        }
        if (name) {
          initial_data.left_label = name
          initial_data.left_label_width = label_width
        }

        if (type === 'search') {
          initial_data.show_search = true
          initial_data.show_delete_all = true
        }

        if (type === '%' || type === '$' || type === '%$') {
          initial_data.show_delete_all = true
          initial_data.inputMode = 'decimal'
        }
        if (type === '%') {
          initial_data.show_percent = true
          initial_data.percent_value = '%'
          if (key === 'loan_term') {
            initial_data.percent_value = ' years'
          }
        }
        if (type === '$') {
          initial_data.show_dollar = true
          initial_data.percent_value = ''
          initial_data.value = initial_data?.value
            ? `$${initial_data.value}`
            : initial_data?.value || ''
        }
        if (key === 'down_payment') {
          initial_data.show_percent = true
          initial_data.show_dollar = true
          initial_data.percent_value = type === '%' ? '%' : ''
          initial_data.dollar_percent = type
          initial_data.left_label_width = 'w-[135%]'
          initial_data.delete_all_position = 'right-[75px]'
          initial_data.total = 0
          initial_data.handleChange = (e, type) => {
            update_input({ e, type, decimal: 3, original: value })
          }
        }

        // if (
        //   key === 'home_price' ||
        //   key === 'refi_amount' ||
        //   key === 'down_payment'
        // ) {
        //   initial_data.onKeyUp = (e) => {
        //     // update_input({ e, type, decimal: 2, original: value })
        //     if (e.target.value && e.key === 'Enter') {
        //       calculate_estimate()
        //     }
        //   }
        // }

        let result = (
          <LabelInput
            {...initial_data}
            handleChange={
              initial_data?.handleChange
                ? initial_data?.handleChange
                : (e) => {
                    update_input({ e, type, decimal: 2, original: value })
                  }
            }
          />
        )

        if (type === 'date') {
          result = (
            <Schedule
              left_label={name}
              left_label_width={label_width}
              no_margin={'mb-[10px]'}
              name={key}
              show_time={false}
              schedule_date={value}
              handle_change={(e) => {
                update_input({ e, type, decimal: 2, original: value })
              }}
            />
          )
        }
        if (type === 'options') {
          result = (
            <Select
              left_label={name}
              left_label_width={label_width}
              no_margin={false}
              className='mb-[10px]'
              name={key}
              value={validate_number(value)}
              handler={(e) => {
                update_input({ e, type, decimal: 2, original: value })
              }}
              placeholder=''
            >
              {(options_names?.[key] || [])?.map((item, index) => {
                return (
                  <option key={item} value={options_values?.[key]?.[index]}>
                    {item}
                  </option>
                )
              })}
            </Select>
          )
        }

        return <div key={key}>{result}</div>
      })
  }

  const filter_method = (items) => {
    const types = strategy?.types || {}
    const names = strategy?.names || {}
    const values = strategy?.values || {}
    const search_values = strategy?.search || {}
    const placeholders = strategy?.placeholders || {}
    const options_values = strategy?.options_values || {}
    const options_names = strategy?.options_names || {}
    const options_show_all = strategy?.options_show_all || []

    const filtered_items = items?.filter((item) => {
      let values_check = []
      Object.keys(values)
        ?.filter(
          (key) =>
            key !== 'search' &&
            strategy?.show_filters?.includes(key) &&
            types[key] === 'options'
        )
        ?.forEach((key) => {
          if (
            item?.[key] == values?.[key] ||
            values?.[key] == options_show_all?.[key]
          ) {
            values_check.push(true)
          } else {
            values_check.push(false)
          }
        })
      return values_check.some((val) => !val) ? false : true
    })

    const search_items = filtered_items.filter((item) => {
      let values_check = []
      if (!values?.search) {
        return true
      }
      Object.keys(search_values)?.forEach((key) => {
        if (types[key] === 'options') {
          if (
            options_names?.[key]?.[item?.[key]]
              ?.toLowerCase()
              ?.includes(values?.search?.toLowerCase())
          ) {
            values_check.push(true)
          } else {
            values_check.push(false)
          }
        } else {
          const search_component = {
            $: validate_number(item?.[key])
              ?.toString()
              ?.includes(validate_number(values?.search)?.toString()),
            str: item?.[key]
              ?.toLowerCase()
              ?.includes(values?.search?.toLowerCase()),
          }
          if (search_component?.[types?.[key] || 'str']) {
            values_check.push(true)
          } else {
            values_check.push(false)
          }
        }
      })
      return values_check.some((val) => val)
    })

    return search_items
  }
  return (
    <div>
      <div className='px-3'>{get_inputs()}</div>
      <List
        list={filter_method(listItems)}
        property_component={property_component}
        my_listings_look={my_listings_look}
      />
    </div>
  )
}

export default FilterList
