import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { HiOutlineQuestionMarkCircle } from 'react-icons/hi2';
export default function Textarea({
  name,
  value,
  label,

  rule,
  hint,
  placeholder,
  suggestion,
  errorMessage,
  className,
}) {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <div>
      <label htmlFor={name} className="text-primary text-sm">
        {label}
      </label>
      <div className="relative">
        <textarea
          className={`text-primary bg-white border border-gray-300 rounded-md py-2 pl-3 pr-6 w-full font-base text-base field ${className}`}
          {...register(name, rule)}
          placeholder={placeholder}
          defaultValue={value}
        ></textarea>
        {hint && (
          <div
            className={`hint absolute right-2 top-1/2 -translate-y-1/2  field-suggestion`}
          >
            <HiOutlineQuestionMarkCircle />
            <strong>{hint} </strong>
          </div>
        )}
      </div>
      <p className="text-[11px] ">{suggestion}</p>
      {errors[name] && <p className="text-xs text-red-500">{errorMessage}</p>}
    </div>
  );
}

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  hint: PropTypes.string,
  placeholder: PropTypes.string,
  suggestion: PropTypes.string,
  errorMessage: PropTypes.string,
};
