import useSortableTable from '../hooks/useSortableTable';
import Badge from './ui/Badge';

export default function PromotionList({ sources }) {
  const { items, requestSort, sortConfig } = useSortableTable(sources);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };
  return (
    <table>
      <thead>
        <tr>
          <th>Promotion</th>
          <th>
            <button
              type="button"
              onClick={() => requestSort('status')}
              className={getClassNamesFor('status')}
            >
              Status
            </button>
          </th>
        </tr>
      </thead>
      <tbody>
        {items.map((item) => (
          <tr key={item.ID}>
            <td>
              <a
                href={item?.PROMO_LINK}
                title={item?.HEADLINE}
                target="_blank"
                rel="noreferrer"
              >
                {item?.HEADLINE}
              </a>
            </td>

            <td width={'110px'}>
              <Badge
                color={
                  item?.IS_ACTIVE === 'Y' ? 'text-[#05A660]' : 'text-gray-600'
                }
              >
                <span className="capitalize">
                  <span
                    className={`inline-block align-middle mr-1 w-[4px] h-[4px] rounded-full ${
                      item?.IS_ACTIVE === 'Y' ? 'bg-[#05A660]' : 'bg-gray-600 '
                    }`}
                  ></span>
                  {item?.IS_ACTIVE === 'Y' ? 'active' : 'inactive'}
                </span>
              </Badge>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
