import { useState, useEffect } from 'react'
import { AiFillDelete } from 'react-icons/ai'
import { TfiPencilAlt } from 'react-icons/tfi'
import { useNavigate } from 'react-router-dom'
import { useListingTours } from '../../../contexts/ListingToursContext'
import { useReceiver } from '../../../contexts/ReceiverContext'
import { set_storage } from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { List } from '../../helpers'
import Modal from '../../Modal'
import uiStyles from '../../ui/uiStyles'
import EditAnnouncement from './EditAnnouncement'
import EditRating from './EditRating'
import RemoveAnnouncement from './RemoveAnnouncement'
import RemoveRating from './RemoveRating'
import ResetRatings from './ResetRatings'

const AddRatings = () => {
  const { listingTours, saveListingTours, get_homes, is_buyer_tour } =
    useListingTours()
  console.log('tours', listingTours)
  const { axiomFetch } = useAxiomFetch()
  const [items, setItems] = useState([])
  const [inputValue, setInputValue] = useState(
    listingTours?.buyer_tours?.buyer_edit || {}
  )
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('')

  const modals = {
    edit_rating: (
      <EditRating
        state={listingTours}
        setState={saveListingTours}
        setShowModal={setShowModal}
      />
    ),
    remove_rating: (
      <RemoveRating
        state={listingTours}
        setState={saveListingTours}
        setShowModal={setShowModal}
      />
    ),
    reset_ratings: (
      <ResetRatings
        state={listingTours}
        setState={saveListingTours}
        setShowModal={setShowModal}
      />
    ),
  }

  const create_item = (item, type) => {
    saveListingTours((prev) => {
      console.log('item', item)
      const ret_data = { ...prev?.buyer_tours, rating_edit: item }
      set_storage('buyer_tours', ret_data)
      return { ...prev, buyer_tours: ret_data }
    })
    setShowModal(true)
    setModalType(type)
  }
  useEffect(() => {
    if (!listingTours?.buyer_tours) {
      navigate('/user/buyer_tours')
    }
  }, [])
  useEffect(() => {
    const rate_type_names = {
      S: 'Star Rating',
      C: 'Comment Rating',
      P: 'Price Rating',
    }
    const data = listingTours?.data?.tour_rating_items
      ?.filter(
        (item) =>
          item?.tour_type_id?.toString() ===
            listingTours?.buyer_tours?.tour_experience_id?.toString() &&
          item?.status === 'A'
      )
      ?.sort((a, b) => a.item_ord - b.item_ord)
      ?.map((item) => {
        item.title = item?.item_name
        item.content = rate_type_names[item?.rate_type]
        item.swipe_right = () => {
          return (
            <div className='flex gap-[20px]'>
              <div
                className='cursor-pointer'
                onClick={() => {
                  create_item(item, 'edit_rating')
                }}
              >
                <TfiPencilAlt size={25} />
              </div>
              <div
                className='cursor-pointer'
                onClick={() => {
                  create_item(item, 'remove_rating')
                }}
              >
                <AiFillDelete size={25} />
              </div>
            </div>
          )
        }
        return item
      })
    setItems(data)
  }, [listingTours?.data?.tour_rating_items])

  return (
    <div>
      <p className={`px-3 mb-[5px] ${uiStyles.text_black_subtitle}`}>
        Ratings on Tours
      </p>
      <div className='overflow-y-scroll min-h-[100px] max-h-[400px]'>
        <List
          add_arrow_order={true}
          handle_arrow={async (item, list, index, dir) => {
            const key = 'item_ord'
            const get_old_order = list[index + (dir === 'up' ? 1 : 0)]?.[key]
            const get_new_order = list[index + (dir === 'down' ? 1 : 0)]?.[key]
            console.log(get_old_order, get_new_order)
            const re_ordered_list = list?.map((item) => {
              if (item[key] === get_old_order) {
                item[key] = get_new_order
                return item
              } else if (item[key] === get_new_order) {
                item[key] = get_old_order
                return item
              } else {
                return item
              }
            })

            saveListingTours((prev) => {
              const data = {
                ...prev?.data,
                tour_rating_items: [
                  ...prev?.data?.tour_rating_items?.filter(
                    (rate) =>
                      rate?.tour_type_id?.toString() !==
                      item?.tour_type_id?.toString()
                  ),
                  ...re_ordered_list,
                ],
              }
              set_storage('listing_tours', data)
              return { ...prev, data }
            })
            await axiomFetch('/tours/rating/order', 'POST', {
              list: re_ordered_list,
            })
          }}
          no_items_msg={'Add a rating to the tour.'}
          list={items}
          reset_slider={true}
        />
      </div>
      <div className='px-3 mt-3 flex justify-end gap-[10px]'>
        <button
          className={`${uiStyles.hover_btn}`}
          onClick={() => {
            create_item({}, 'reset_ratings')
          }}
        >
          {'Reset Ratings'}
        </button>
        <button
          className={`${uiStyles.hover_btn}`}
          onClick={() => {
            create_item({}, 'edit_rating')
          }}
        >
          {'Create Rating'}
        </button>
      </div>
      <div className={`${uiStyles.line_3px} mt-[10px]`}></div>
      <div className='px-3 mt-3 flex justify-end gap-[10px] pb-[10px]'>
        <button
          className={`${uiStyles.white_btn}`}
          onClick={() => {
            return navigate('/user/buyer_tours')
          }}
        >
          Done
        </button>
      </div>
      {showModal ? (
        <Modal
          modalName='create_buyer_tour'
          showModal={showModal}
          setShowModal={setShowModal}
          // width={'w-[350px]'}
          side_padding={['add_buyer'].includes(modalType) ? 'px-0' : ''}
          height={
            modalType === 'edit_buyer'
              ? 'h-[300px]'
              : modalType?.includes('remove') || modalType?.includes('reset')
              ? 'h-[175px]'
              : 'h-[550px]'
          }
        >
          {modals?.[modalType]}
        </Modal>
      ) : (
        ''
      )}
    </div>
  )
}

export default AddRatings
