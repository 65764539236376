import { useRef } from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import { useClaims } from '../contexts/ClaimsContext';
import { get_storage, set_storage } from '../helpers';
import { useParams } from 'react-router-dom';
import sign from 'jwt-encode'

const ROOT_URL = process.env.REACT_APP_API_ROOT + '/v1';
const useAxiomFetch = () => {
    const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
    const { claims, saveClaims } = useClaims()
    const params = useParams()
    const fetchTimeout = useRef(0)
    const lastFetchtime = useRef(0)
    const timeoutRef = useRef()

    const axiomFetch = async (url, method, body = {}, headers, use_form_data = false, signal, delay = 0, pub_token = null, generic_response = false) => {
        try {
            url = ROOT_URL + url

            let token
            token = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUDIENCE,
            }).catch(err => {
                // if (!params?.id && !get_storage('public_token')) {
                //     return loginWithRedirect()
                // }
            })

            if (!token && (!pub_token || pub_token === 'initial') && !get_storage('public_token') && !params?.id) {
                if (timeoutRef?.current) {
                    clearTimeout(timeoutRef?.current)
                }
                saveClaims(prev => ({ ...prev, token_loading: true }))
                timeoutRef.current = setTimeout(async () => {
                    return await loginWithRedirect()
                }, [500])
            }

            if (!token && !pub_token) {
                token = get_storage('public_token') || sign(await pubAxiomFetch({ token: 'initial', url: `/client/token?uuid=${params?.id}`, method: 'GET' }), 'secret')
                pub_token = token
            }
            if (pub_token) {
                const new_url = new URL(url)
                new_url.searchParams.set('public_access_token', 'access')
                url = new_url.toString()
            }
            const config = {}
            config.method = method || 'GET'
            if (Object.keys(body).length > 0) {
                if (use_form_data) {
                    let form_data = new FormData()

                    for (const name in body) {
                        if (name === 'files') {
                            for (const file of body[name]) {
                                form_data.append('files', file?.file)
                            }
                        } else if (typeof (body[name]) === 'object' && name !== 'photo') {
                            form_data.append(name, JSON.stringify(body[name]))
                        } else {
                            form_data.append(name, body[name])
                        }
                    }
                    config.body = form_data
                } else {
                    config.body = JSON.stringify(body)

                }
            }
            if (!headers) {
                config.headers = {}
            }
            if (!use_form_data) {
                config.headers = {
                    ...headers,
                    'Content-Type': 'application/json'
                }
            }
            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${token}`,
                // accept: 'application/json',
                // 'Access-Control-Allow-Credentials': true,
                // 'Access-Control-Allow-Origin': '*',
            }
            if (generic_response) {
                config.headers = {
                    Authorization: `Bearer ${token}`,
                    //accept: 'application/json',
                    'Access-Control-Allow-Credentials': true,
                    'Access-Control-Allow-Origin': '*',
                }
            }

            if (signal) {
                config.signal = signal
            }
            const currentTime = performance.now()
            if (delay > 0) {
                if (currentTime - lastFetchtime?.current < delay) {
                    clearTimeout(fetchTimeout?.current)
                }
                lastFetchtime.current = currentTime
                const final_data = new Promise(resolve => {
                    fetchTimeout.current = setTimeout(async () => {
                        console.log('hello')
                        const response = await fetch(url, config)
                        if (generic_response) {
                            return response
                        }
                        const data = await response.json()
                        return resolve(data)
                    }, delay)
                })
                return await final_data
            } else {
                const response = await fetch(url, config)
                if (generic_response) {
                    console.log('respone', response)
                    return response
                }
                const data = await response.json()
                return data
            }

        } catch (error) {
            // console.log(error)
            return error
        }
    }
    const pubAxiomFetch = async ({ token, url, method, body = {}, headers, use_form_data = false, signal = null, delay = 0 }) => {
        return await axiomFetch(url, method, body, headers, use_form_data, signal, delay, token)
    }
    const get_token = async (uuid) => {
        if (uuid !== get_storage(uuid)) {
            localStorage.removeItem('public_token')
        }
        set_storage('uuid', uuid)
        const public_token = get_storage('public_token') ? get_storage('public_token') : sign(await pubAxiomFetch({ token: 'initial', url: `/client/token?uuid=${uuid}`, method: 'GET' }), 'secret')
        set_storage('public_token', public_token)
        return public_token
    }
    const nodeProdFetch = async ({ url, method, body = {}, headers, response_type = 'json', signal, pub_token }) => {
        try {
            url = 'https://nodejsprod01.driggstitle.com' + url
            let token
            token = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUDIENCE,
            }).catch(err => null)
            if (!token && !pub_token) {
                token = get_storage('public_token') || sign(await pubAxiomFetch({ token: 'initial', url: `/client/token?uuid=${params?.id}`, method: 'GET' }), 'secret')
                pub_token = token
            }
            if (pub_token) {
                const new_url = new URL(url)
                new_url.searchParams.set('public_access_token', 'access')
                url = new_url.toString()
            }
            const config = {}
            config.method = method || 'GET'
            if (Object.keys(body).length > 0) {
                if (response_type === 'form') {
                    let form_data = new FormData()

                    for (const name in body) {
                        if (name === 'files') {
                            for (const file of body[name]) {
                                form_data.append('files', file?.file)
                            }
                        } else if (typeof (body[name]) === 'object' && name !== 'photo') {
                            form_data.append(name, JSON.stringify(body[name]))
                        } else {
                            form_data.append(name, body[name])
                        }
                    }
                    config.body = form_data
                } else {
                    config.body = JSON.stringify(body)

                }
            }
            if (!headers) {
                config.headers = {}
            }
            if (response_type !== 'form') {
                config.headers = {
                    ...headers,
                    'Content-Type': 'application/json'
                }
            }
            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${token}`,
                // accept: 'application/json',
                // 'Access-Control-Allow-Credentials': true,
                // 'Access-Control-Allow-Origin': '*',
            }
            if (signal) {
                config.signal = signal
            }
            const response = await fetch(url, config)
            let final_data
            if (response_type === 'text') {
                final_data = await response.text()
            } else {
                final_data = await response.json()
            }
            return final_data
        } catch (error) {
            return error
        }
    }

    const externalFetch = async (url, method, body = {}, headers, use_form_data = false, signal, pub_token) => {
        try {
            // url = url
            let token
            token = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUDIENCE,
            }).catch(err => null)

            if (!token && !pub_token) {
                token = get_storage('public_token') || sign(await pubAxiomFetch({ token: 'initial', url: `/client/token?uuid=${params?.id}`, method: 'GET' }), 'secret')
                pub_token = token
            }
            if (pub_token) {
                const new_url = new URL(url)
                new_url.searchParams.set('public_access_token', 'access')
                url = new_url.toString()
            }
            const config = {}
            config.method = method || 'GET'
            if (Object.keys(body).length > 0) {
                if (use_form_data) {
                    let form_data = new FormData()

                    for (const name in body) {
                        if (name === 'files') {
                            for (const file of body[name]) {
                                form_data.append('files', file?.file)
                            }
                        } else if (typeof (body[name]) === 'object' && name !== 'photo') {
                            form_data.append(name, JSON.stringify(body[name]))
                        } else {
                            form_data.append(name, body[name])
                        }
                    }
                    config.body = form_data
                } else {
                    config.body = JSON.stringify(body)

                }
            }
            if (!headers) {
                config.headers = {}
            }
            if (!use_form_data) {
                config.headers = {
                    ...headers,
                    'Content-Type': 'application/json'
                }
            }
            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${token}`,
                // accept: 'application/json',
                // 'Access-Control-Allow-Credentials': true,
                // 'Access-Control-Allow-Origin': '*',
            }
            if (signal) {
                config.signal = signal
            }
            const response = await fetch(url, config)
            const data = await response.json()
            return data
        } catch (error) {
            console.log(error)
            return error
        }
    }

    return { axiomFetch, nodeProdFetch, externalFetch, pubAxiomFetch, get_token }
}

export default useAxiomFetch