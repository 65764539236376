import { useState } from 'react'
import images_tours from '../../../assets/images/listing_tours'
import uiStyles from '../../ui/uiStyles'

const StarRating = ({
  display = false,
  rating,
  label = 'rating:',
  handle_change = async () => {},
}) => {
  const [starState, setStarState] = useState(rating || 0)
  const stars = [1, 2, 3, 4, 5]
  return (
    <div className='swipeable flex items-center'>
      <label className={`${uiStyles.label}`}>{label}</label>
      {stars?.map((item) => {
        return (
          <img
            key={item}
            className={`swipeable mr-[5px] ${
              display ? 'w-[15px]' : 'cursor_pointer'
            }`}
            onClick={async () => {
              if (display) {
                return
              }
              setStarState((prev) => (prev === item ? 0 : item))
              await handle_change(item)
            }}
            src={
              (starState || 0) < item
                ? images_tours?.star_outline
                : images_tours?.star_filled
            }
          />
        )
      })}
    </div>
  )
}

export default StarRating
