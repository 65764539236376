import { useEffect } from 'react'
export const ErrorAuth = () => {
  useEffect(() => {
    if (!window.location.href.includes('https')) {
      if (window.location.href.includes('localhost')) {
        return
      }
      window.location.href = window.location.href.replace('http', 'https')
    }
  }, [])
  return <div>Redirecting to secure login...</div>
}
