import React, { useCallback, useEffect, useRef, useState } from 'react'
import { HiArrowDown } from 'react-icons/hi2'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@tanstack/react-query'
import Header2 from '../../Header2'
import Iconbag from '../../../assets/images/bag.png'

import DateRangePicker from '../../ui/DateRangePicker'

import Modal from '../../Modal'
import SlyDial from '../../SlyDial'
import Message from '../AI/Message'
import Email from '../AI/Email'
import Wrapper from '../../layout/Wrapper'
import IconContent from '../../ui/IconContent'
import { useProfile } from '../../../contexts/UserContext'
import { useLocation, useNavigate } from 'react-router-dom'
import { ServiceLeads } from '../../../services/ServiceLeads'
import Leadsdata from './Leadsdata'
import Loading from '../../Loading'
import ButtonsRow from '../../ui/ButtonsRow'
import uiStyles from '../../ui/uiStyles'
import MyLists from './MyLists'
import SmallLoading from '../../ui/SmallLoading'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { useDefaults } from '../../../contexts/DefaultsContext'

export default function LeadsLobby() {
  const { state: location_state } = useLocation()
  const navigate = useNavigate()
  const [startDate, setStartDate] = useState(new Date(new Date().setDate(-7)))
  const [endDate, setEndDate] = useState(new Date())
  const { defaults } = useDefaults()
  const [startDays, setStartDays] = useState({
    expired: defaults?.expired || 30,
    cancelled: defaults?.cancelled || 30,
    active: defaults?.active || 2,
    contract: defaults?.contract || 2,
    nod: defaults?.nod || 7,
    open: 0,
    foreclosure: 0,
    fsbo: defaults?.fsbo || 7,
  })
  const [endDays, setEndDays] = useState({
    expired: 1,
    cancelled: 1,
    nod: 1,
    active: 1,
    contract: 1,
    open: defaults?.open || 3,
    foreclosure: defaults?.foreclosure || 7,
    fsbo: 1,
  })
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState(null)
  const [aiMessage, setAIMessage] = useState(null)
  const [state, setState] = useState({
    selected_list: location_state?.select || 'AXIOM LISTS',
  })

  const { axiomFetch } = useAxiomFetch()
  const [lists, setLists] = useState([])
  const [leads, setLeads] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  const sort_list = (a, b) => {
    let nameA = a?.LIST_NAME?.toUpperCase()
    let nameB = b?.LIST_NAME?.toUpperCase()
    if (!nameB) return -1
    if (!nameA) return 1
    if (nameA > nameB) {
      return 1
    }
    if (nameA < nameB) {
      return -1
    }

    return 0
  }

  useEffect(() => {
    let controller = new AbortController()
    let controller_2 = new AbortController()
    const Getlists = async () => {
      setIsLoading(true)
      let data = []
      if (!lists?.length) {
        data = await axiomFetch(
          '/client/listings/lists',
          'GET',
          {},
          {},
          false,
          controller?.signal
        )
        if (data?.length) {
          data = data?.sort((a, b) => sort_list(a, b))
        }
        setLists(data)
      }
      let get_leads = await axiomFetch(
        `/client/leads/stats`,
        'POST',
        { startDays, endDays, default_days: true },
        {},
        false,
        controller_2?.signal
      )
      console.log('leads', get_leads)
      setLeads(get_leads)
      if (!controller_2?.signal?.aborted) {
        setIsLoading(false)
      }
    }
    Getlists()

    return () => {
      controller?.abort()
      controller_2?.abort()
    }
  }, [])

  const { profile } = useProfile()

  const handle_lists = (item) => {
    setState((prev) => ({ ...prev, selected_list: item }))
  }

  const {
    cancelled,
    expired,
    foreclosure,
    hardMoneyLenders,
    nod,
    fsbo,
    contract,
    active,
    open,
  } = leads || {}

  const modals = {
    textMessage: (
      <Message
        message={aiMessage}
        showModal={showModal}
        setShowModal={setShowModal}
        onMessageSend={setShowModal}
      />
    ),
    slyDial: (
      <SlyDial
        showModal={showModal}
        setShowModal={setShowModal}
        onMessageSend={setShowModal}
      />
    ),
    email: (
      <Email
        message={aiMessage}
        isInModal={true}
        showModal={showModal}
        setShowModal={setShowModal}
        onMessageSend={setShowModal}
      />
    ),
    //
  }
  // useEffect(() => {
  //   setAIMessage(`Hello!\nI recently came across your For Sale By Owner home and I am interested in representing you in the sale. As a real estate agent in your area, I have the expertise and resources to help you effectively market and sell your home. Would you be open to discussing the possibility of me representing you in the sale of your home? Reply "Yes" and I will give you a call directly.\n\nThanks!\nAdam Driggs,\n adam@kellhome.com
  //   `);
  // }, []);

  // if (leadsQuery.isFetching) {
  //   return <Loading />;
  // }
  console.log(isLoading)
  return (
    <Wrapper>
      <Header2 show_agent_toggle={false} />
      <IconContent
        iconImage={Iconbag}
        title='Leads'
        content='All of your lead lists in one place.'
      />
      <div className={'flex justify-end pr-3'}>
        <button
          className={uiStyles.hover_btn_small}
          onClick={() => {
            navigate('/user/defaults')
          }}
        >
          Change default days
        </button>
      </div>

      <div className='flex flex-col place-items-center'>
        <p className={`${uiStyles.text_gray} my-[10px]`}>Select your lists</p>
        <div className='w-[60%] justify-self-center'>
          <ButtonsRow
            items={[
              // 'VBC',
              'AXIOM LISTS',
              'MY LISTS',
            ]}
            handler={handle_lists}
            selected={state.selected_list}
            button_style={'text-[12px] pl-2'}
          />
        </div>
      </div>
      {isLoading ? (
        <SmallLoading />
      ) : (
        <>
          {state?.selected_list === 'AXIOM LISTS' ? (
            <>
              {/* <DateRangePicker
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
              /> */}
              {/* <div className='px-5 flex gap-[10px] items-center mt-[10px] justify-center'>
                <label>Days Back: </label>
                <input
                  className={uiStyles.input_num}
                  value={startDays}
                  onChange={(e) => {
                    if (Number(e.target.value?.replace(/\D/g, '')) > 100) {
                      return
                    }
                    setStartDays(e.target.value.replace(/\D/g, ''))
                  }}
                />
                <label>{'Max (100)'}</label>
              </div> */}

              <div className='leads'>
                <div className='header-lead'>
                  <div className={`flex items-center px-4 py-3`}>
                    <div className='icon w-[20px]'></div>
                    <div className='info flex-1 '>
                      Leads List <HiArrowDown className='inline' />
                    </div>
                    {/* <div className='w-[100px] text-center'># days back</div> */}
                    <div className='w-[80px] text-center'># Leads</div>
                  </div>
                </div>
                <Leadsdata
                  cancelled={cancelled}
                  contract={contract}
                  active={active}
                  expired={expired}
                  open={open}
                  foreclosure={foreclosure}
                  hardMoneyLenders={hardMoneyLenders}
                  nod={nod}
                  fsbo={fsbo}
                  setShowModal={setShowModal}
                  setModalType={setModalType}
                  startDate={startDate}
                  endDate={endDate}
                  startDays={startDays}
                  endDays={endDays}
                  setLeads={setLeads}
                />
              </div>
              {showModal && (
                <Modal
                  modalName={modalType}
                  width={'w-[300px]'}
                  height={'h-[550px]'}
                  showModal={showModal}
                  setShowModal={setShowModal}
                >
                  {modalType && modals[modalType]}
                </Modal>
              )}
            </>
          ) : (
            <MyLists
              lists={lists}
              setLists={setLists}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          )}
        </>
      )}
    </Wrapper>
  )
}
