import { useState, useEffect } from 'react'
import { FaInfoCircle } from 'react-icons/fa'
import { useLocation, useNavigate } from 'react-router-dom'
import { useCalc } from '../../contexts/CalcContext'
import { CALCMENU } from '../../data/data'
import {
  format_currency,
  getDateString,
  validate_number,
  search_street,
  pick_keys,
  capitalize,
  set_storage,
} from '../../helpers'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import LabelInput from '../form/LabelInput'
import { List, NavButton } from '../helpers'
import Modal from '../Modal'
import { Rating, ThumbRating } from '../pages/tours'
import ButtonsRow from '../ui/ButtonsRow'
import CalcInfo from './CalcInfo'

const CalcLobby = () => {
  const {
    calc,
    saveCalc,
    swiper_slide,
    get_calc_type_id,
    sell_offer_names,
    sell_offer_placeholders,
    instanciate_calc,
  } = useCalc()
  const { axiomFetch } = useAxiomFetch()
  const [search, setSearch] = useState('')
  const [calcMenu, setCalcuMenu] = useState([])
  const [histMenu, setHistMenu] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const [infoItem, setInfoItem] = useState({})
  const navigate = useNavigate()
  useEffect(() => {
    set_storage('vbc_calc', '')
    set_storage('home_view_calc', '')
  }, [])
  const pre_func_new = (item) => {
    saveCalc((prev) => ({
      ...prev,
      buy: prev?.buy_reset,
      sell: prev?.sell_reset,
      loan_2: prev?.loan_2_reset,
      loan_3: prev?.loan_3_reset,
      holding_costs: prev?.holding_costs_reset,
      loan_balance: prev?.loan_balance_reset,
      title_fees: prev?.title_fees_reset,
      offers: null,
      formatted_address: '',
      mls_id: '',
      flyer: null,
    }))
  }
  const modals = {
    calc_info: {
      component: (
        <CalcInfo
          pre_func={pre_func_new}
          info_item={infoItem}
          setShowModal={setShowModal}
        />
      ),
      width: 'w-[335px]',
    },
  }

  const search_history = (str) => {
    if (!str) {
      return histMenu
    }
    const items = [
      { key: 'title', type: '$' },
      { key: 'loan_amount', type: '$' },
      { key: 'apr', type: '$' },
      { key: 'loan_balance', type: '$' },
      { key: 'monthly_payment', type: '$' },
      { key: 'down_payment_total', type: '$' },
      { key: 'down_payment_percent', type: '$' },
      { key: 'program_type', type: 'str' },
      { key: 'net_at_close_total', type: '$' },
      { key: 'bring_to_close', type: '$' },
      { key: 'lbal_interest_paid', type: '$' },
      { key: 'lbal_interest_saved', type: '$' },
      { key: 'lbal_principal_paid', type: '$' },
      { key: 'orig_calc_content', type: 'str' },
      { key: 'address', type: 'street' },
      { key: 'date_str', type: 'str' },
      { key: 'send_list_str', type: 'str' },
      { key: 'break_gain_total', type: '$' },
      { key: 'current_rent', type: '$' },
      { key: 'break_time_label', type: 'str' },
      { key: 'break_gain_label', type: 'str' },
    ]
    let hist_filtered_items = histMenu?.filter((item) => {})
    hist_filtered_items = histMenu?.filter((item) => {
      let regex_numbers = /[$.,%]/g
      let result = false
      for (const filter_item of items) {
        if (result) {
          return result
        }

        let str_filter = item?.[filter_item?.key]
          ?.toString()
          ?.toLowerCase()
          ?.replaceAll('_', ' ')
          ?.includes(str?.toString()?.toLowerCase())
        if (filter_item?.type === 'str') {
          result = str_filter
        } else if (filter_item?.type === '$') {
          if (validate_number(item?.[filter_item?.key])) {
            result = item?.[filter_item?.key]
              ?.toString()
              ?.replace(regex_numbers, '')
              .includes(str?.toString()?.replace(regex_numbers, ''))
          } else {
            result = str_filter
          }
        } else if (filter_item?.type === 'street') {
          result = search_street(item?.[filter_item?.key])?.includes(
            search_street(str)
          )
        }
      }

      return result
    })

    return hist_filtered_items
  }

  useEffect(() => {
    let calculators = calc?.axiom_calculators || calcMenu

    calculators = calculators
      ?.sort((a, b) => a?.calc_ord - b?.calc_ord)
      ?.sort((a, b) => {
        if (a.calc_url && !b.calc_url) {
          return -1
        }
        if (!a.calc_url && b?.calc_url) {
          return 1
        }
        return a?.calc_ord - b?.calc_ord
      })
      ?.map((item, index) => {
        const nav_default = CALCMENU?.find((calc) => calc?.id === item?.type_id)
        const thumb_rating = (
          <ThumbRating
            rating={{
              rate_value: item?.rate_value || 0,
              thumbs_up_total: item?.thumbs_up_total || 0,
              thumbs_down_total: item?.thumbs_down_total || 0,
            }}
            show_totals={true}
            new_rating_func={async (rating) => {
              const set_count = rating === item?.rate_value ? 0 : rating
              let thumbs_up_count = 0,
                thumbs_down_count = 0
              if (rating > 0) {
                if (item?.rate_value === rating) {
                  thumbs_up_count = -1
                } else {
                  thumbs_up_count = 1
                }
              } else if (rating < 0) {
                if (item?.rate_value === rating) {
                  thumbs_down_count = -1
                } else {
                  thumbs_down_count = 1
                }
              }
              if (item?.rate_value === 1) {
                thumbs_up_count = -1
              } else if (item?.rate_value === -1) {
                thumbs_down_count = -1
              }
              saveCalc((prev) => ({
                ...prev,
                axiom_calculators: [
                  ...prev?.axiom_calculators?.filter(
                    (calc) => calc?.type_id !== item?.type_id
                  ),
                  {
                    ...item,
                    rate_value: set_count,
                    thumbs_up_total:
                      validate_number(item?.thumbs_up_total) + thumbs_up_count,
                    thumbs_down_total:
                      validate_number(item?.thumbs_down_total) +
                      thumbs_down_count,
                  },
                ],
              }))
              await axiomFetch('/calculators/rate_calc', 'POST', {
                rating: {
                  rate_value: set_count,
                  calc_id: item?.type_id,
                },
              })
            }}
          />
        )
        return {
          ...item,
          title: item?.calc_name || nav_default?.title || '',
          content: item?.calc_desc || nav_default?.content || '',
          sub_content: (
            <div className='swipeable flex flex-col items-center min-w-[180px]'>
              <p className='swipeable mt-[5px] font-bold text-[13px]'>
                {!item?.calc_url || !nav_default?.completed
                  ? 'With Popular Demand:'
                  : ''}
              </p>
              {!item?.calc_url || !nav_default?.completed ? thumb_rating : ''}
            </div>
          ),
          image:
            item?.calc_thumb ||
            nav_default?.image ||
            'https://driggstitle.com/reviews/node/node_to_oracle/a_file_1708711962822.jpeg',
          link: item?.calc_url || nav_default?.link || '',
          click_me: true,
          select: function () {
            pre_func_new(item)
            navigate(item?.calc_url || nav_default?.link || '')
          },
          swipe_right: function () {
            return (
              <div className='flex gap-[20px] items-center'>
                {item?.calc_url && nav_default?.completed ? (
                  <>
                    <div>
                      <NavButton
                        className='text-[15px]'
                        title='New'
                        pre_func={() => {
                          pre_func_new(item)
                          navigate(item?.calc_url || nav_default?.link || '')
                        }}
                        // link={this.link}
                      />
                    </div>
                    <div>|</div>{' '}
                  </>
                ) : (
                  ''
                )}
                <div
                  className='cursor-pointer'
                  onClick={() => {
                    setInfoItem(this)
                    setShowModal(true)
                    setModalType('calc_info')
                  }}
                >
                  {<FaInfoCircle size={15} />}
                </div>
              </div>
            )
          },
          id: item?.type_id || nav_default?.id,
        }
      })

    let calc_hist = calc?.axiom_calc_hist || []
    calc_hist = calc_hist
      ?.map((item) => {
        const orig_calc = calculators?.find(
          (calc) => calc?.id === validate_number(item?.calc_type || 1)
        )
        const contents = {
          1: (
            <div className='swipeable'>
              <p className='swipeable font-bold'>{orig_calc?.content}</p>
              <p className='swipeable mt-[2px]'>
                Monthly Payment: $
                {format_currency(
                  item?.monthly_payment?.toString()?.replace('$', ''),
                  false,
                  0
                )}
              </p>
              <p className='swipeable'>
                Bring to close: $
                {format_currency(
                  item?.bring_to_close?.toString()?.replace('$', ''),
                  false,
                  0
                )}
              </p>
            </div>
          ),
          121: (
            <div className='swipeable'>
              <p className='swipeable font-bold'>{orig_calc?.content}</p>
              <p className='swipeable mt-[2px]'>
                Current Rent: $
                {format_currency(
                  item?.current_rent?.toString()?.replace('$', ''),
                  false,
                  0
                )}
              </p>
              <p className='swipeable'>
                {item?.break_gain_label?.toString()?.toLowerCase() ===
                'break even' ? (
                  <>
                    {item?.break_gain_label}: {item?.break_time_label}
                  </>
                ) : (
                  <>
                    {item?.break_gain_total ? (
                      <>
                        {item?.break_gain_label}: $
                        {format_currency(
                          item?.break_gain_total?.toString()?.replace('$', ''),
                          false,
                          2
                        )}
                      </>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </p>
            </div>
          ),
          41: (
            <div className='swipeable'>
              <p className='swipeable font-bold'>{orig_calc?.content}</p>
              <p className='swipeable mt-[2px]'>
                Monthly Payment: $
                {format_currency(
                  item?.monthly_payment?.toString()?.replace('$', ''),
                  false,
                  0
                )}
              </p>
              <p className='swipeable'>
                Bring to close: $
                {format_currency(
                  item?.bring_to_close?.toString()?.replace('$', ''),
                  false,
                  0
                )}
              </p>
            </div>
          ),
          44: (
            <div className='swipeable'>
              <p className='swipeable font-bold'>{orig_calc?.content}</p>
              <p className='swipeable mt-[2px] capitalize'>
                {item?.program_type?.replaceAll('_', ' ')}
              </p>
              {item?.program_type?.includes('buy') &&
              !item?.program_type?.includes('cash') ? (
                <p className='swipeable'>
                  Down {`${validate_number(item?.down_payment_percent)}%`}: $
                  {format_currency(
                    item?.down_payment_total?.toString()?.replace('$', ''),
                    false,
                    0
                  )}
                </p>
              ) : (
                ''
              )}
            </div>
          ),
          43: (
            <div className='swipeable'>
              <p className='swipeable font-bold'>{orig_calc?.content}</p>
              <p className='swipeable mt-[2px]'>
                Monthly Payment: $
                {format_currency(
                  item?.monthly_payment?.toString()?.replace('$', ''),
                  false,
                  0
                )}
              </p>
              <p className='swipeable'>
                Bring to close: $
                {format_currency(
                  item?.bring_to_close?.toString()?.replace('$', ''),
                  false,
                  0
                )}
              </p>
            </div>
          ),
          2: (
            <div className='swipeable'>
              <p className='swipeable font-bold'>{orig_calc?.content}</p>
              <p className='swipeable mt-[2px]'>
                Sales Price: $
                {format_currency(
                  item?.sale_price?.toString()?.replace('$', ''),
                  false,
                  0
                )}
              </p>
              <p className='swipeable'>
                Net at close: $
                {format_currency(
                  item?.net_at_close_total?.toString()?.replace('$', ''),
                  false,
                  0
                )}
              </p>
            </div>
          ),
          122: (
            <div className='swipeable'>
              <p className='swipeable font-bold'>{orig_calc?.content}</p>
              <p className='swipeable mt-[2px]'>
                Sales Price: $
                {format_currency(
                  item?.sale_price?.toString()?.replace('$', ''),
                  false,
                  0
                )}
              </p>
              <p className='swipeable'>
                Net at close: $
                {format_currency(
                  item?.net_at_close_total?.toString()?.replace('$', ''),
                  false,
                  0
                )}
              </p>
            </div>
          ),
          63: (
            <div className='swipeable'>
              <p className='swipeable font-bold'>{orig_calc?.content}</p>
              <p className='swipeable mt-[2px]'>
                Interest Paid: $
                {format_currency(
                  item?.lbal_interest_paid?.toString()?.replace('$', ''),
                  false,
                  0
                )}
              </p>
              <p className='swipeable'>
                Principal Paid: $
                {format_currency(
                  item?.lbal_principal_paid?.toString()?.replace('$', ''),
                  false,
                  0
                )}
              </p>
            </div>
          ),
          61: (
            <div className='swipeable'>
              <p className='swipeable font-bold'>{orig_calc?.content}</p>
              <p className='swipeable mt-[2px]'>
                Loan Amount: $
                {format_currency(
                  item?.loan_amount?.toString()?.replace('$', ''),
                  false,
                  0
                )}
              </p>
              <p className='swipeable'>
                APR:{' '}
                {format_currency(
                  item?.apr?.toString()?.replace('$', ''),
                  false,
                  3
                )}
                %
              </p>
            </div>
          ),
          62: (
            <div className='swipeable mb-[12px]'>
              <p className='swipeable font-bold'>{orig_calc?.content}</p>
              <p className='swipeable mt-[2px]'>
                Loan Balance: $
                {format_currency(
                  item?.loan_balance?.toString()?.replace('$', ''),
                  false,
                  0
                )}
              </p>
              {/* <p className='swipeable'>
                Interest Saved: $
                {format_currency(
                  item?.lbal_interest_saved?.toString()?.replace('$', ''),
                  false,
                  0
                )}
              </p> */}
            </div>
          ),
          21: (
            <div className='swipeable'>
              <p className='swipeable font-bold'>{orig_calc?.content}</p>
              <p className='swipeable mt-[2px]'>
                Extra Payment: $
                {format_currency(
                  item?.lbal_extra_payment?.toString()?.replace('$', ''),
                  false,
                  0
                )}
              </p>
              <p className='swipeable'>
                Interest Saved: $
                {format_currency(
                  item?.lbal_interest_saved?.toString()?.replace('$', ''),
                  false,
                  0
                )}
              </p>
            </div>
          ),
          101: (
            <div className='swipeable mb-[30px]'>
              <p className='swipeable font-bold'>{orig_calc?.content}</p>
              {/* <p className='swipeable mt-[2px]'>
                Extra Payment: $
                {format_currency(
                  item?.lbal_extra_payment?.toString()?.replace('$', ''),
                  false,
                  0
                )}
              </p>
              <p className='swipeable'>
                Interest Saved: $
                {format_currency(
                  item?.lbal_interest_saved?.toString()?.replace('$', ''),
                  false,
                  0
                )}
              </p> */}
            </div>
          ),
        }

        const calc_send_list = calc?.axiom_calc_send_list?.filter(
          (send) => send.calc_instance_uuid === item.instance_uuid
        )

        let send_list_str = [],
          send_list_phones = [],
          send_list_names = []

        calc_send_list?.forEach((item) => {
          if (item?.to_name || item?.to_phone_number) {
            send_list_str.push(`${item?.to_name} ${item?.to_phone_number}`)
          }
          if (item?.to_phone_number) {
            send_list_phones.push(`${item?.to_phone_number}`)
          }
          if (item?.to_name) {
            send_list_names.push(`${item?.to_name}`)
          }
        })

        const send_name_phone_str = send_list_names?.length
          ? `(${
              send_list_names?.length === 1
                ? send_list_names?.[0]
                : `${send_list_names?.[0]}, ...`
            })`
          : send_list_phones?.length
          ? `(${
              send_list_phones?.length === 1
                ? send_list_phones?.[0]
                : `${send_list_phones?.[0]}, ...`
            })`
          : ''
        return {
          ...item,
          orig_calc_content: orig_calc?.content,
          send_list: calc_send_list,
          send_list_str,
          title: `${
            item?.home_price ||
            item?.sale_price ||
            item?.loan_balance ||
            item?.lbal_home_price ||
            item?.lbal_loan_amount
              ? `$${format_currency(
                  item?.home_price ||
                    item?.sale_price ||
                    item?.loan_balance ||
                    item?.lbal_home_price ||
                    item?.lbal_loan_amount
                )}`
              : orig_calc?.title
          } ${send_name_phone_str}`,
          content: contents[item?.calc_type],
          sub_content: (
            <div>
              <p className='swipeable text-xs'>
                {item?.address?.split(',')?.[0] || item?.address || ''}
              </p>
              <p
                className={`swipeable ${
                  item?.address ? '' : 'mt-[10px]'
                } text-xs`}
              >
                Last accessed:{' '}
                {getDateString({
                  date: item?.upd_dt || item?.ins_dt,
                  format: 'mmm d, hh:mi am',
                })}
              </p>
            </div>
          ),
          date_str: getDateString({
            date: item?.upd_dt || item?.ins_dt,
            format: 'mmm d, hh:mi am',
          }),
          image: orig_calc?.image,
          link: orig_calc?.link,
          swipe_right: function () {
            return (
              <div>
                <NavButton
                  title='Update'
                  pre_func={() => instanciate_calc(item)}
                  link={this.link}
                />
              </div>
            )
          },
          id: item?.instance_uuid,
          internal: true,
        }
      })
      ?.sort((a, b) => {
        a.upd_dt = new Date(a?.upd_dt || '01-JAN-01')
        a.ins_dt = new Date(a?.ins_dt || '01-JAN-01')
        b.upd_dt = new Date(b?.upd_dt || '01-JAN-01')
        b.ins_dt = new Date(b?.ins_dt || '01-JAN-01')
        if (a.upd_dt > b.upd_dt) return -1
        if (a.upd_dt < b.upd_dt) return 1

        if (a.ins_dt > b.upd_dt) return -1
        if (a.ins_dt < b.upd_dt) return 1

        // If update dates are equal, compare by insert date
        if (a.ins_dt > b.ins_dt) return -1
        if (a.ins_dt < b.ins_dt) return 1

        // If both dates are equal, maintain the order
        return 0
      })

    calculators = calculators?.filter((item) => {
      if (calc?.group?.toLowerCase() === 'buy') {
        return item?.b_or_s?.toLowerCase() === 'b'
      } else if (calc?.group?.toLowerCase() === 'sell') {
        return item?.b_or_s?.toLowerCase() === 's'
      } else if (calc?.group?.toLowerCase() === 'history') {
        return false
      } else {
        return true
      }
    })
    setCalcuMenu(calculators)
    setHistMenu(calc_hist)
  }, [calc?.group, calc?.axiom_calculators, calc?.axiom_calc_hist])
  return (
    <div>
      <div className='px-3 mb-[10px]'>
        <ButtonsRow
          items={['Buy', 'Sell', 'History']}
          selected={calc?.group}
          handler={(item) => {
            saveCalc((prev) => ({ ...prev, group: item }))
          }}
        />
      </div>
      <div>
        {calc?.group?.toLowerCase() !== 'history' ? (
          <List click_me={false} list={calcMenu} reset_slider={true} />
        ) : (
          <div>
            <div className='px-3'>
              <LabelInput
                show_delete_all={true}
                show_search={true}
                name={'search'}
                placeholder='search...'
                value={search}
                handleChange={(e) => {
                  setSearch(e.target.value)
                }}
              />
            </div>
            <List
              click_me={false}
              list={search_history(search)}
              reset_slider={true}
            />
          </div>
        )}
      </div>
      {showModal ? (
        <Modal
          side_padding={'px-0'}
          modalName={modalType}
          height={modals?.[modalType]?.width || 'h-[550px]'}
          width={modals?.[modalType]?.width || 'w-full'}
          showModal={showModal}
          setShowModal={setShowModal}
          showClose={true}
        >
          {modals?.[modalType]?.component || <></>}
        </Modal>
      ) : (
        ''
      )}
    </div>
  )
}

export default CalcLobby
