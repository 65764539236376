import React from 'react'
import { useListingTours } from '../../../contexts/ListingToursContext'
import { set_storage } from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import uiStyles from '../../ui/uiStyles'

const ListingSignupCancel = ({ setShowModal }) => {
  const { listingTours, saveListingTours } = useListingTours()
  const { axiomFetch } = useAxiomFetch()
  return (
    <div>
      <p className='text-center'>Are you sure that you want to Cancel?</p>
      <div className='flex justify-end gap-[10px] px-3 mt-[10px]'>
        <button
          className={`${uiStyles.white_btn}`}
          onClick={() => {
            setShowModal(false)
          }}
        >
          Back
        </button>
        <button
          className={`${uiStyles.red_btn}`}
          onClick={async () => {
            saveListingTours((prev) => {
              const data = {
                ...prev?.data,
                event_tour_info: [
                  ...(prev?.data?.event_tour_info || []),
                ]?.filter((eti) => {
                  if (eti.evt_id === prev?.signup_item?.item?.event_id) {
                    if (
                      eti.mls_num ===
                      prev?.signup_item?.in_json?.listing?.mls_id?.toString()
                    ) {
                      return false
                    } else {
                      return true
                    }
                  }
                  return true
                }),
                event_signup: [...(prev?.data?.event_signup || [])]?.filter(
                  (es) => {
                    if (es.event_id === prev?.signup_item?.item?.event_id) {
                      if (
                        es.list_id ===
                        prev?.signup_item?.in_json?.listing?.mls_id
                      ) {
                        return false
                      } else {
                        return true
                      }
                    }
                    return true
                  }
                ),
                // mls_listings_v: [...(prev?.data?.mls_listings_v || [])]?.filter(
                //   (mlv) =>
                //     mlv.mls_id !== prev?.signup_item?.in_json?.listing?.mls_id
                // ),
              }
              set_storage('listing_tours', data)
              return {
                ...prev,
                data: data,
              }
            })
            setShowModal(false)
            await axiomFetch('/tours/remove', 'POST', {
              event_id: listingTours?.signup_item?.item?.event_id,
              mls_id: listingTours?.signup_item?.in_json?.listing?.mls_id,
            })
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  )
}

export default ListingSignupCancel
