import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Client } from '@twilio/conversations'
import jwtDecode from 'jwt-decode'
import { Outlet } from 'react-router-dom'
import { useClaims } from '../contexts/ClaimsContext'
import { useConversations } from '../contexts/ConversationsContext'
import { useListingTours } from '../contexts/ListingToursContext'
import { get_storage, is_json, set_storage, validate_number } from '../helpers'
import useAxiomFetch from '../hooks/useAxiomFetch'
import { SmallLoadingRel } from './ui/SmallLoadingRel'
import { useCalc } from '../contexts/CalcContext'
import { usePairwise } from '../contexts/PairwiseContext'
import { useProfile } from '../contexts/UserContext'
import { useMerchant } from '../contexts/MerchantContext'
import Usage from './usage/Usage'
import { useSales } from '../contexts/SalesContext'
import { useListing } from '../contexts/ListingContext'
import { useAi } from '../contexts/AiContext'
import { useMktReqs } from '../contexts/MktReqsContext'

const GlobalWrapper = () => {
  const { listingTours, saveListingTours } = useListingTours()
  const { listing, saveListing } = useListing()
  const { ai, saveAi } = useAi()
  const { profile, saveProfile } = useProfile()
  const { mktReqs, saveMktReqs } = useMktReqs()
  const { calc, saveCalc, loans, find_county } = useCalc()
  const { pairwise, savePairwise } = usePairwise()
  const { merchant, saveMerchant } = useMerchant()
  const { sales, saveSales } = useSales()
  const [reloadConvs, setReloadConvs] = useState(0)
  const {
    conversations,
    saveConversations,
    saveCampaigns,
    saveConvMessages,
    convMessages,
    token,
    saveToken,
    saveLoadingConversations,
    loadingConversations,
  } = useConversations()
  const { claims, saveClaims } = useClaims()
  const {
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    user,
  } = useAuth0()
  const { axiomFetch, get_token } = useAxiomFetch()

  useEffect(() => {
    const host = process.env.REACT_APP_API_ROOT.replace(/^http/, 'ws')
    let ws
    if (ws === undefined || ws.readyState === 3) {
      ws = new WebSocket(host)
    }
    const connect_socket = () => {
      ws.onmessage = function (event, isBinary) {
        let check_json = is_json(event?.data)
        let msg = check_json ? JSON.parse(event.data) : event.data
        if (check_json) {
          if (msg?.meta_data?.type === 'tour_timer') {
            saveListingTours((prev) => {
              const events = {
                ...(prev?.events || {}),
                [msg?.data?.event_id]: {
                  ...prev?.events?.[msg?.data?.event_id],
                  [msg?.data?.mls_num]: {
                    start_time: msg?.data?.start_time,
                    curr_time: msg?.data?.curr_time,
                    run: msg?.data?.run,
                  },
                },
              }
              set_storage('tour_events', events)
              return {
                ...prev,
                events,
              }
            })
          }
        }
      }
      ws.onopen = (event) => {
        ws.send('hey everyone')
        saveClaims((prev) => ({ ...prev, ws }))
      }

      ws.onerror = () => {
        console.log('socket error')
        if (ws) {
          ws.close()
        }
      }
      ws.onclose = (event) => {
        console.log('disconnected client')
        if (ws) {
          ws.close()
          setTimeout(() => {
            ws = new WebSocket(host)
            connect_socket()
          }, 2000)
        }
      }
    }
    connect_socket()
    return () => {
      ws.close()
      ws = undefined
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const init_conversations = async () => {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUDIENCE,
      }).catch((err) => {
        return null
      })
      if (!token) {
        return saveLoadingConversations(false)
      }
      const claims = jwtDecode(token || {})

      const data = await axiomFetch(
        '/marketing/conversations/token',
        'POST',
        {
          identity: claims?.cont_id?.toString(),
        },
        {},
        false,
        controller.signal
      )
      saveToken(data?.token)

      if (data?.token) {
        window.client = Client
        const client = new Client(data?.token)
        client.on('connectionStateChanged', (state) => {
          if (state === 'connecting') {
          }
          if (state === 'connected') {
            setTimeout(() => {
              saveLoadingConversations(false)
            }, [3000])
          }
          if (state === 'disconnecting') {
            setReloadConvs((prev) => prev + 1)
          }
          if (state === 'disconnected') {
          }
          if (state === 'denied') {
          }
        })
        let all_conversations = []
        client.on('conversationJoined', async (conversation) => {
          if (loadingConversations) {
            setTimeout(() => {
              saveLoadingConversations(false)
            }, [3000])
          }
          if (
            conversations.filter((item) => item.sid === conversation?.sid)
              .length === 0 &&
            all_conversations.filter((item) => item.sid === conversation?.sid)
              .length === 0
          ) {
            all_conversations.push(conversation)
            let campaign = conversation?.attributes?.campaign || ''
            // if (campaign) {
            //   saveCampaigns((prev) => ({
            //     ...prev,
            //     [campaign]: {
            //       name: campaign,
            //       conversations: [
            //         ...(prev?.[campaign]?.conversations || []),
            //         conversation,
            //       ],
            //     },
            //   }))
            // } else {
            saveConversations((prev) => [...prev, conversation])
            // }

            const messages = await conversation.getMessages()
            let unread_count = 0
            let date = conversation?.lastMessage?.dateCreated
            let last_msg = messages?.items?.filter(
              (item) => item.state.index === conversation?.lastMessage?.index
            )[0]
            await conversation.advanceLastReadMessageIndex(
              conversation?.attributes?.start_index > 0
                ? conversation?.attributes?.start_index
                : 0
            )
            unread_count = await conversation.getUnreadMessagesCount()
            saveConvMessages((prev) => ({
              ...prev,
              [conversation?.sid]: {
                ...prev?.[conversations?.sid],
                messages,
                body: last_msg?.state.body,
                last_msg_author: last_msg?.state?.author,
                unread_count,
                date,
                last_msg,
              },
            }))

            conversation.on('messageAdded', async (message) => {
              if (
                message?.state?.author?.toString() ===
                claims?.cont_id?.toString()
              ) {
                await conversations?.[
                  message?.conversation?.sid
                ]?.setAllMessagesRead()
              }
              const all_urls =
                (await Promise?.all(
                  message?.state?.medias?.map(async (url) => {
                    return await url?.getContentTemporaryUrl()
                  }) || []
                )) || []

              saveConvMessages((prev) => {
                return {
                  ...prev,
                  [message?.conversation?.sid]: {
                    ...prev?.[message?.conversation?.sid],
                    messages: {
                      ...prev?.[message?.conversation?.sid]?.messages,
                      items: [
                        ...prev?.[message?.conversation?.sid]?.messages?.items,
                        message,
                      ],
                    },
                    unread_count:
                      message.state.author !== claims?.cont_id?.toString()
                        ? prev?.[message.conversation?.sid]?.unread_count + 1
                        : prev?.[message.conversation?.sid]?.unread_count,
                    body: message?.state?.body,
                    last_msg_author: message?.state?.author,
                    date: message?.state?.timestamp,
                    last_msg_media: all_urls?.length ? true : false,
                    msg_urls: {
                      ...prev?.[conversation?.sid]?.msg_urls,
                      [message?.state?.sid]: all_urls,
                    },
                  },
                }
              })
            })
          }
        })

        client.on('conversationLeft', (thisConversation) => {
          all_conversations = all_conversations.filter(
            (item) => item?.sid !== thisConversation?.sid
          )
          saveConversations((prev) => [
            ...prev?.filter((item) => item?.sid !== thisConversation?.sid),
          ])
          // if (thisConversation?.attributes?.campaign) {
          //   saveCampaigns((prev) => ({
          //     ...prev,
          //     [thisConversation?.attributes?.campaign]: {
          //       ...prev?.[thisConversation?.attributes?.campaign],
          //       conversations: [
          //         ...prev?.[
          //           thisConversation?.attributes?.campaign
          //         ].conversations?.filter(
          //           (item) => item.sid !== thisConversation?.sid
          //         ),
          //       ],
          //     },
          //   }))
          // }
        })
      }
    }

    if (isAuthenticated) {
      init_conversations()
    }
    if (loadingConversations) {
      setTimeout(() => {
        saveLoadingConversations(false)
      }, [3000])
    }
    return () => {
      controller?.abort()
    }
    // eslint-disable-next-line
  }, [reloadConvs, isAuthenticated, profile.CONT_ID])

  useEffect(() => {
    const controller = new AbortController()
    const controller_2 = new AbortController()
    const get_data = async () => {
      let tour_state = {}
      let buyer_tours = {}
      let events = {}

      if (get_storage('listing_tours_state')) {
        tour_state = get_storage('listing_tours_state')
        saveListingTours((prev) => ({ ...prev, tour_state }))
      }
      if (get_storage('buyer_tours')) {
        buyer_tours = get_storage('buyer_tours')
        saveListingTours((prev) => ({ ...prev, buyer_tours }))
      }
      if (get_storage('tour_events')) {
        events = get_storage('tour_events')
        saveListingTours((prev) => ({ ...prev, events }))
      }
      if (get_storage('listing_tours')) {
        saveListingTours((prev) => ({
          ...prev,
          data: get_storage('listing_tours') || {},
        }))
      }

      const data = await axiomFetch(
        '/tours/data',
        'GET',
        {},
        {},
        false,
        controller.signal
      )
      if (data?.name === 'AbortError') {
        return
      }
      console.log({ tour_data: data })
      if (data?.event_tour_info?.length && data?.mls_listings_v?.length)
        data?.event_tour_info?.map((item) => {
          item.details =
            data?.mls_listings_v?.find(
              (mls) => item.mls_num?.toString() === mls?.mls_id?.toString()
            ) || {}
          return item
        })

      if (data?.tour_types?.[0]?.type_id) {
        if (!tour_state?.event_id) {
          tour_state.event_id = data?.event_pages?.[0]?.event_id
          tour_state.completed = data?.event_pages?.[0]?.tour_complete
        }
        tour_state.completed =
          data?.event_pages?.find(
            (item) => item?.event_id === tour_state?.event_id
          )?.tour_complete || 0

        data?.axiom_tours_timers?.forEach((timer) => {
          events[timer?.event_id] = {
            ...(events?.[timer?.event_id] || {}),
            [timer?.mls_id]: {
              run: timer?.run,
              start_time: timer?.start_timestamp,
              curr_time: timer?.total_seconds,
            },
          }
        })
        set_storage('listing_tours', data)
        set_storage('buyer_tours', buyer_tours)
        set_storage('tour_events', events)
        saveListingTours((prev) => {
          const final_tour_state = {
            ...prev?.tour_state,
            event_id: tour_state?.event_id,
            completed: tour_state?.completed,
          }
          set_storage('listing_tours_state', final_tour_state)
          return {
            ...prev,
            data,
            tour_state: final_tour_state,
            buyer_tours,
            events,
          }
        })
      }
    }
    // if (isAuthenticated) {
    get_data()
    // }

    return () => {
      controller?.abort()
      controller_2?.abort()
    }
    // eslint-disable-next-line
  }, [isAuthenticated, profile.CONT_ID])

  useEffect(() => {
    const controller = new AbortController()

    const get_calculator_data = async () => {
      saveCalc((prev) => ({
        ...prev,
        ...get_storage('calculators'),
      }))
      const data = await axiomFetch(
        '/calculators/data',
        'GET',
        {},
        {},
        false,
        controller?.signal
      )
      if (data?.cur_escrow_rates) {
        saveCalc((prev) => {
          const result = { ...prev, ...data }
          set_storage('calculators', {
            ...(get_storage('calculators') || {}),
            ...(data || {}),
          })
          return result
        })
      }
    }
    get_calculator_data()
    return () => controller?.abort()
  }, [isAuthenticated, profile.ACCOUNT_ID])

  useEffect(() => {
    const controller = new AbortController()
    const controller_2 = new AbortController()
    const get_listings = async () => {
      const save_listings = (data) => {
        saveListing(data)
        saveMktReqs((prev) => ({
          ...prev,
          listings: data,
        }))
        saveAi((prev) => ({
          ...prev,
          listings: data,
        }))
      }
      let stored_profile
      if (get_storage('account')) {
        if (get_storage('profile')) {
          stored_profile = get_storage('profile') || {}
        }
        saveProfile((prev) => ({
          ...prev,
          ...stored_profile,
          account: get_storage('account'),
        }))
      }
      if (get_storage('all_listings')) {
        console.log('stored_listings', get_storage('all_listings'))
        save_listings(get_storage('all_listings'))
      }
      const initial_data = await axiomFetch(
        '/marketing/homes/get_listings?type=agent',
        'GET',
        {},
        {},
        false,
        controller_2.signal
      )

      if (initial_data?.name === 'AbortError') {
        return
      }
      console.log('intitial_data', initial_data)
      save_listings(initial_data)

      let data = await axiomFetch(
        '/armls/view/listings?new_result=true',
        'GET',
        {},
        {},
        false,
        controller.signal
      )
      if (data?.name === 'AbortError') {
        return
      }
      console.log('listings', data)
      const profile_data = await axiomFetch('/client/profile')
      if (!controller.signal.aborted) {
        save_listings(data?.listings)
        if (profile_data?.CONT_ID) {
          saveProfile((prev) => {
            set_storage('profile', {
              ...prev,
              ...profile_data[0],
              account: data?.account,
              is_admin: prev?.is_admin,
            })
            return {
              ...prev,
              ...profile_data[0],
              account: data?.account,
              is_admin: prev?.is_admin,
            }
          })
        }
        set_storage('account', data?.account)
        set_storage('all_listings', data?.listings)
      }
    }

    get_listings()

    return () => {
      controller?.abort()
      controller_2?.abort()
    }
  }, [isAuthenticated])

  useEffect(() => {
    const controller = new AbortController()

    const get_calculator_data = async () => {
      saveCalc((prev) => ({ ...prev, ...get_storage('calculators') }))

      const run_updates = (data) => {
        const data_options = {}
        if (data?.ed_counties) {
          data_options.county = data?.ed_counties?.map((item) => item?.name)
        }

        if (data?.axiom_calc_options) {
          const update_calculator_defaults = (
            calculator_type = 'buy',
            data_options = {}
          ) => {
            const loan_data = calc?.[calculator_type]?.defaults
            const default_data = calc?.[calculator_type]
            let broker_count = 0
            data?.axiom_calc_options
              ?.filter((item) => item.opt_calc_type === calculator_type)
              ?.forEach((item) => {
                if (!loan_data?.[item?.opt_loan_type]) {
                  loan_data[item?.opt_loan_type] =
                    calc?.buy?.defaults?.[item?.opt_loan_type] || {}
                  loan_data[item?.opt_loan_type].closing_costs =
                    calc?.buy?.defaults?.[item?.opt_loan_type]?.closing_costs ||
                    {}
                  loan_data[item?.opt_loan_type].closing_costs_names =
                    calc?.buy?.defaults?.[item?.opt_loan_type]
                      ?.closing_costs_names || {}
                }
                if (!loan_data?.[item?.opt_loan_type]?.[item?.opt_type]) {
                  loan_data[item?.opt_loan_type][item?.opt_type] =
                    calc?.buy?.defaults?.[item?.opt_loan_type]?.[
                      item?.opt_type
                    ] || []
                }
                if (
                  !loan_data[item?.opt_loan_type][item?.opt_type]?.includes(
                    item?.opt_key
                  )
                ) {
                  loan_data[item?.opt_loan_type][item?.opt_type].push(
                    item?.opt_key
                  )
                }
                if (
                  item?.opt_type === 'default_fees' &&
                  !item?.opt_key.startsWith('title_')
                ) {
                  loan_data[item?.opt_loan_type][item?.opt_key] =
                    item?.opt_value
                  loan_data[item?.opt_loan_type][`${item?.opt_key}_type`] =
                    item?.opt_value_type || ''

                  if (
                    item?.opt_key?.includes('broker_fee') &&
                    calculator_type === 'sell'
                  ) {
                    if (broker_count === 0) {
                      default_data.values['broker_fee'] = 0
                    }
                    broker_count++
                    default_data.values['broker_fee'] += validate_number(
                      item?.opt_value
                    )
                    loan_data[item?.opt_loan_type][item?.opt_type].push(
                      item?.opt_key
                    )
                    loan_data[item?.opt_loan_type].closing_costs[
                      item?.opt_key
                    ] = item?.opt_value
                    loan_data[item?.opt_loan_type].closing_costs_names[
                      item?.opt_key
                    ] = item?.opt_name
                    loan_data[item?.opt_loan_type].closing_costs[
                      `${item?.opt_key}_type`
                    ] = item?.opt_value_type || ''
                  }

                  if (item?.opt_key === 'closing_date_days') {
                    default_data.values['closing_date'] = new Date(
                      new Date().setDate(
                        new Date().getDate() + validate_number(item?.opt_value)
                      )
                    )?.toISOString()
                  }
                  let filter_out = [
                    'buyer_broker_fee',
                    'listing_broker_fee',
                    'show_split_in_closing_costs',
                    'is_mip',
                    'is_pmi',
                    'closing_date_days',
                  ]

                  if (!filter_out?.includes(item?.opt_key)) {
                    default_data.names[item?.opt_key] = item?.opt_name
                    default_data.values[item?.opt_key] = item?.opt_value
                    default_data.types[item?.opt_key] =
                      item?.opt_value_type || ''
                  } else {
                    default_data[item?.opt_key] = item?.opt_value
                    default_data[`${item?.opt_key}_type`] =
                      item?.opt_value_type || ''
                  }
                } else {
                  loan_data[item?.opt_loan_type].closing_costs[item?.opt_key] =
                    item?.opt_value
                  loan_data[item?.opt_loan_type].closing_costs_names[
                    item?.opt_key
                  ] = item?.opt_name
                  loan_data[item?.opt_loan_type].closing_costs[
                    `${item?.opt_key}_type`
                  ] = item?.opt_value_type || ''
                }
              })
            if (data?.calc_scenario?.length) {
              loans.forEach((loan) => {
                loan = loan?.toLowerCase()
                const title_fees = []
                if (!loan_data?.[loan]) {
                  return
                }
                if (!loan_data?.[loan]?.closing_costs) {
                  loan_data[loan].closing_costs = {}
                  loan_data[loan].closing_costs_names = {}
                }
                data?.calc_scenario_fees
                  ?.filter((item) => Number(item?.buy_amt || 0) > 0)
                  ?.forEach((fee) => {
                    if (
                      (calculator_type === 'sell' &&
                        validate_number(fee?.sell_amt)) ||
                      (calculator_type === 'buy' &&
                        validate_number(fee?.buy_amt))
                    ) {
                      loan_data[loan].closing_costs[`title_${fee?.fee_id}`] =
                        calculator_type === 'sell'
                          ? fee?.sell_amt
                          : fee?.buy_amt
                      loan_data[loan].closing_costs_names[
                        `title_${fee?.fee_id}`
                      ] = fee?.axiom_desc_name || fee?.descript.slice(0, 20)
                      loan_data[loan].closing_costs[
                        `title_${fee?.fee_id}_type`
                      ] = '$'
                      title_fees.push(`title_${fee?.fee_id}`)
                    }
                  })

                loan_data[loan] = {
                  ...loan_data?.[loan],
                  title_fees,
                }
              })
            }
            let rate = validate_number(default_data?.values?.interest_rate)
            if (default_data?.values?.county && data?.ed_counties?.length) {
              const county_data = find_county(
                default_data?.values?.county,
                data?.ed_counties
              )
              default_data.values.insurance_rate = county_data?.def_ins_rate
              default_data.types.insurance_rate = '%'
              default_data.values.taxes = county_data?.def_prop_tax_rate
              default_data.types.taxes = '%'
            }
            if (data?.int_rates?.length) {
              let rates = data?.int_rates?.[0]
              rate =
                rates?.[
                  `rate_${validate_number(default_data?.values?.loan_term)}`
                ] || rates?.rate_30
              if (calculator_type !== 'sell') {
                default_data.values.interest_rate = rate
              }
              loans.forEach((loan) => {
                if (!loan_data?.[loan?.toLowerCase()]) {
                  return
                }
                loan_data[loan?.toLowerCase()].interest_rate = rate
              })
            }
            const default_loan =
              default_data?.values?.loan_type?.toLowerCase() || 'conventional'
            saveCalc((prev) => {
              let all_calc_data = {
                ...prev?.[calculator_type],
                ...default_data,
                defaults: {
                  ...prev?.[calculator_type]?.defaults,
                  ...loan_data,
                },
                option_values: {
                  ...prev?.[calculator_type]?.option_values,
                  ...(data_options || {}),
                },
              }
              const result = {
                ...prev,
                ...data,
                [calculator_type]: { ...all_calc_data },
                [`${calculator_type}_reset`]: { ...all_calc_data },
                loan_2:
                  calculator_type === 'buy' ? all_calc_data : prev?.loan_2,
                loan_2_reset:
                  calculator_type === 'buy' ? all_calc_data : prev?.loan_2,
                loan_3:
                  calculator_type === 'buy' ? all_calc_data : prev?.loan_3,
                loan_3_reset:
                  calculator_type === 'buy' ? all_calc_data : prev?.loan_3,
                [calculator_type]: all_calc_data,
                loan_balance:
                  calculator_type === 'sell'
                    ? {
                        ...prev?.loan_balance,
                        values: {
                          ...prev?.loan_balance?.values,
                          interest_rate:
                            rate ||
                            default_data?.defaults?.[default_loan]
                              ?.interest_rate,
                          down_payment:
                            default_data?.defaults?.[default_loan]
                              ?.down_payment,
                          loan_term:
                            default_data?.defaults?.[default_loan]?.loan_term,
                        },
                      }
                    : { ...prev?.loan_balance },
                extra_payment:
                  calculator_type === 'buy'
                    ? {
                        ...prev?.extra_payment,
                        values: {
                          ...prev?.extra_payment?.values,
                          interest_rate:
                            rate ||
                            default_data?.defaults?.[default_loan]
                              ?.interest_rate,
                          down_payment:
                            default_data?.defaults?.[default_loan]
                              ?.down_payment,
                          loan_term:
                            default_data?.defaults?.[default_loan]?.loan_term,
                        },
                      }
                    : { ...prev?.extra_payment },
                extra_payment_reset:
                  calculator_type === 'buy'
                    ? {
                        ...prev?.extra_payment,
                        values: {
                          ...prev?.extra_payment?.values,
                          interest_rate:
                            rate ||
                            default_data?.defaults?.[default_loan]
                              ?.interest_rate,
                          down_payment:
                            default_data?.defaults?.[default_loan]
                              ?.down_payment,
                          loan_term:
                            default_data?.defaults?.[default_loan]?.loan_term,
                        },
                      }
                    : { ...prev?.extra_payment },
                title_fees_reset: { ...prev?.title_fees },
                loan_balance_reset:
                  calculator_type === 'sell'
                    ? {
                        ...prev?.loan_balance,
                        values: {
                          ...prev?.loan_balance?.values,
                          interest_rate:
                            rate ||
                            default_data?.defaults?.[default_loan]
                              ?.interest_rate,
                          down_payment:
                            default_data?.defaults?.[default_loan]
                              ?.down_payment,
                          loan_term:
                            default_data?.defaults?.[default_loan]?.loan_term,
                        },
                      }
                    : { ...prev?.loan_balance },
                holding_costs:
                  calculator_type === 'sell'
                    ? {
                        ...prev?.holding_costs,
                        values: {
                          ...prev?.holding_costs?.values,
                          interest_rate:
                            rate ||
                            default_data?.defaults?.[default_loan]
                              ?.interest_rate,
                          insurance_rate:
                            default_data?.defaults?.[default_loan]
                              ?.insurance_rate,
                        },
                      }
                    : { ...prev?.holding_costs },
                holding_costs_reset:
                  calculator_type === 'sell'
                    ? {
                        ...prev?.holding_costs,
                        values: {
                          ...prev?.holding_costs?.values,
                          interest_rate:
                            rate ||
                            default_data?.defaults?.[default_loan]
                              ?.interest_rate,
                          insurance_rate:
                            default_data?.defaults?.[default_loan]
                              ?.insurance_rate,
                        },
                      }
                    : { ...prev?.holding_costs },
              }
              return result
            })
          }
          update_calculator_defaults('buy', data_options)
          update_calculator_defaults('sell', data_options)
        }
      }
      run_updates(get_storage('calculators'))
      const data = await axiomFetch(
        '/calculators/defaults',
        'GET',
        {},
        {},
        false,
        controller?.signal
      )
      if (data?.name !== 'AbortError') {
        set_storage('calculators', {
          ...(get_storage('calculators') || {}),
          ...(data || {}),
        })
        run_updates(data)
      }
    }
    get_calculator_data()
    return () => controller?.abort()
  }, [isAuthenticated, profile.CONT_ID])

  useEffect(() => {
    const controller = new AbortController()

    const get_pairwise_data = async () => {
      const save_pairwise = (data) => {
        const county_values = data?.ed_counties?.map((item) =>
          item?.name?.toLowerCase()
        )
        const county_names = data?.ed_counties?.map((item) => item?.name)
        const pair_entity_names = data?.pair_entities?.map(
          (item) =>
            `${item?.pair_name} - sold (${validate_number(
              item?.sold_shares
            )}/${validate_number(item?.total_shares_in_ent)})`
        )
        const pair_entitiy_values = data?.pair_entities?.map(
          (item) => item?.pair_id
        )
        const initial_entity = data?.pair_entities?.[0]

        return savePairwise((prev) => {
          set_storage('pairwise_data', data)
          return {
            ...prev,
            estimator: {
              ...prev?.estimator,
              values: {
                ...prev?.estimator?.values,
                shares_owned: validate_number(
                  initial_entity?.shares_avail_to_buy
                ),
                county: profile.ACCOUNT_ID === 1320 ? 'maricopa' : 'clark',
                pair_entity: initial_entity?.pair_id,
              },
              maximums: {
                ...prev?.estimator?.maximums,
                number_of_deals:
                  validate_number(initial_entity?.shares_avail) || 49,
                shares_owned: validate_number(
                  initial_entity?.shares_avail_to_buy
                ),
              },
              option_names: {
                ...prev?.estimator?.option_names,
                county: county_names,
                pair_entity: pair_entity_names,
              },
              option_values: {
                ...prev?.estimator?.option_values,
                county: county_values,
                pair_entity: pair_entitiy_values,
              },
            },
            documents: {
              ...prev?.documents,
              values: {
                ...prev?.documents?.values,
                shares_owned: validate_number(
                  initial_entity?.shares_avail_to_buy
                ),
                pair_entity: initial_entity?.pair_id,
              },
              maximums: {
                ...prev?.documents?.maximums,
                number_of_deals:
                  validate_number(initial_entity?.shares_avail) || 49,
                shares_owned: validate_number(
                  initial_entity?.shares_avail_to_buy
                ),
              },
              option_names: {
                ...prev?.documents?.option_names,
                county: county_names,
                pair_entity: pair_entity_names,
              },
              option_values: {
                ...prev?.documents?.option_values,
                county: county_values,
                pair_entity: pair_entitiy_values,
              },
            },
            data: data,
          }
        })
      }
      const cache_data = get_storage('pairwise_data')
      if (cache_data) {
        save_pairwise(cache_data)
      }
      const data = await axiomFetch(
        '/pairwise/data',
        'GET',
        {},
        {},
        false,
        controller.signal
      )

      if (data?.name !== 'AbortError') {
        save_pairwise(data)
      }
    }

    get_pairwise_data()

    return () => {
      controller.abort()
    }
  }, [isAuthenticated, profile.CONT_ID, profile.ACCOUNT_ID])

  useEffect(() => {
    const controller = new AbortController()
    const get_cards = async () => {
      saveMerchant((prev) => ({
        ...prev,
        data: { ...prev?.data, cards: get_storage('all_cards') },
      }))
      const all_cards = await axiomFetch(
        '/merchant/cards',
        'GET',
        {},
        {},
        false,
        controller?.signal
      )
      all_cards.cards = all_cards?.cards?.filter(
        (item) => item?.main_pmt_method !== 'Y'
      )
      if (all_cards?.name !== 'AbortError') {
        set_storage('all_cards', all_cards)
        saveMerchant((prev) => ({
          ...prev,
          data: { ...prev?.data, cards: all_cards },
          payment: { ...prev?.payment, token: all_cards?.default_card?.token },
        }))
      }
    }
    get_cards()
    return () => {
      controller?.abort()
    }
  }, [isAuthenticated, profile.CONT_ID])

  useEffect(() => {
    const controller = new AbortController()

    const get_sales_data = async () => {
      saveSales((prev) => ({
        ...prev,
        ...get_storage('sales'),
        selected: 'what_next',
      }))
      const data = await axiomFetch(
        '/sales/data',
        'GET',
        {},
        {},
        false,
        controller?.signal
      )
      if (data?.name === 'AbortError') {
        return
      }
      console.log('data', data)

      saveSales((prev) => {
        const result = { ...prev, ...data }
        set_storage('sales', {
          ...prev,
          ...(get_storage('sales') || {}),
          ...(data || {}),
        })
        return result
      })
    }
    get_sales_data()
    return () => controller?.abort()
  }, [isAuthenticated, profile.CONT_ID])

  useEffect(() => {
    const controller = new AbortController()

    saveSales((prev) => ({
      ...prev,
      on_deck: { ...prev?.on_deck, loading: true },
    }))
    const get_add_data = async () => {
      const add_data = await axiomFetch(
        `/sales/what_next/on_deck`,
        'GET',
        {},
        {},
        false,
        controller?.signal
      )

      if (add_data?.name === 'AbortError') {
        return
      }

      saveSales((prev) => ({
        ...prev,
        on_deck: {
          ...prev?.on_deck,
          data: add_data,
          selected: '<_6_DOM',
          loading: false,
        },
      }))
    }

    get_add_data()

    return () => {
      controller?.abort()
    }
  }, [isAuthenticated, profile.CONT_ID])

  useEffect(() => {
    const controller = new AbortController()
    const get_materials = async () => {
      if (get_storage('market_materials')) {
        saveMktReqs((prev) => ({
          ...prev,
          materials: get_storage('market_materials') || {},
        }))
      }
      const materials = await axiomFetch(
        '/marketing/materials/slot_groups',
        'GET',
        {},
        {},
        false,
        controller.signal
      )
      if (materials.name === 'AbortError') {
        return
      }
      // console.log('materials', materials)
      const get_weekenders = () => {
        const weekenders = materials?.mkt_req_weekend_img
        const group =
          (materials?.mkt_slot_group || [])?.find(
            (group) => group.group_id === 61
          ) || {}
        const weekender_flyer = materials?.mkt_slot_group
          ?.find((group) => group.group_id === 61)
          ?.items?.find(
            (single_layout) => single_layout?.single_layout_id === 241
          )
        const sub_items = weekenders?.map((item) => {
          weekender_flyer.single_layout = {
            ...(weekender_flyer?.single_layout || {}),
            ...item,
          }
          return { ...weekender_flyer, ...item }
        })
        group.items = sub_items?.sort((a, b) => a?.loc_id - b?.loc_id)
        return group
      }
      materials.weekenders = get_weekenders() || []
      materials.mkt_slot_group = [
        ...(materials?.mkt_slot_group || [])?.filter(
          (item) => item.group_id !== 61
        ),
        get_weekenders(),
      ]
      console.log('materials', materials)
      if (materials?.mkt_req_layout?.length) {
        set_storage('market_materials', materials)
        saveMktReqs((prev) => ({ ...prev, materials }))
      }
    }

    get_materials()

    return () => {
      controller?.abort()
    }
    // eslint-disable-next-line
  }, [isAuthenticated, profile.CONT_ID])

  return claims?.token_loading ? (
    <div>
      <SmallLoadingRel />
    </div>
  ) : (
    <>
      <Usage />
      <Outlet />
    </>
  )
}

export default GlobalWrapper
