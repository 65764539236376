import React from 'react'
import { MdAdsClick } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

const UserMenuItem = ({ icon, title, description, link }) => {
  const navigate = useNavigate()
  return (
    <div
      className={`swipeable transition-all delay-10000 z-20 bg-gray-100 flex px-3 py-3 `}
    >
      {icon && (
        <div
          className='relative swipeable icon min-w-11 flex-shrink-0 cursor-pointer'
          onClick={() => {
            return navigate(link)
          }}
        >
          <img src={icon} alt='' className='w-11 h-auto' />
        </div>
      )}
      <div className='swipeable content pl-3'>
        <h3 className='swipeable text-primary text-xs font-semibold'>
          {title}
        </h3>
        <p className='swipeable text-gray-500 text-xs max-w-[200px]'>
          {description}
        </p>
      </div>
    </div>
  )
}

export default React.memo(UserMenuItem)
