import { useState } from 'react'
import { set_storage } from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import uiStyles from '../../ui/uiStyles'

const BuyerToursRemove = ({ market_name, state, setState, setShowModal }) => {
  const { axiomFetch } = useAxiomFetch()
  const [loading, setLoading] = useState(false)

  return (
    <div>
      <div className='px-3 text-center mb-[10px]'>
        <p>Are you sure you want to delete </p>
        <p>{state?.buyer_tours?.event_edit?.tour_name}?</p>
      </div>
      <div className='flex justify-end gap-[5px]'>
        <button
          className={`${uiStyles.white_btn}`}
          onClick={() => {
            setShowModal(false)
          }}
        >
          Cancel
        </button>
        <button
          className={`${uiStyles.red_btn}`}
          onClick={async () => {
            setLoading(true)
            const result = await axiomFetch(
              '/tours/event/remove',
              'DELETE',
              state?.buyer_tours?.event_edit || {}
            )
            setState((prev) => {
              const event_pages = [
                ...prev?.data?.event_pages?.filter(
                  (item) =>
                    item.event_id !== prev?.buyer_tours?.event_edit?.event_id
                ),
              ]

              set_storage('listing_tours', { ...prev.data, event_pages })
              return {
                ...prev,
                data: { ...prev.data, event_pages },
              }
            })
            setLoading(false)
            setShowModal(false)
          }}
        >
          Confirm
        </button>
      </div>
    </div>
  )
}

export default BuyerToursRemove
