import React from 'react'
import { useSales } from '../../contexts/SalesContext'
import uiStyles from '../ui/uiStyles'

const ThirdBase = ({ setShowModal, setModalType }) => {
  const { sales, saveSales } = useSales()
  return (
    <div>
      <div className='flex flex-col mt-[10px] px-3'>
        <a
          className={`${uiStyles.hover_btn} text-center`}
          target='_blank'
          href={`https://rdbeta2.driggstitle.com/prv/sales_mach_cust_sol.cust_solution?rep_p=${sales?.curr_client?.data?.brch_mrket_id}&cont_p=${sales?.curr_client?.data?.cont_id}`}
        >
          Open Custom Solution
        </a>
      </div>
    </div>
  )
}

export default ThirdBase
