import React, { useEffect } from 'react'
import { useAi } from '../../../contexts/AiContext'
import { useLimits } from '../../../contexts/LimitsContext'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import Limits from '../../Limits'
import uiStyles from '../../ui/uiStyles'
import { AskMulti } from './AskMulti'

export const AskAxiomMulti = ({
  message_val = '',
  save_field = () => {},
  mkt_item = {},
  new_rewrites = [],
  rewrites = [],
  handle_change = () => {},
  handle_message = () => {},
  name = 'ask_axiom',
  type = 'mkt_materials',
  message_label = 'content',
  copy_text = 'write me a post about my open house',
}) => {
  const { axiomFetch } = useAxiomFetch()
  const { limits, saveLimits } = useLimits()
  const { ai, saveAi } = useAi()

  useEffect(() => {
    const controller = new AbortController()
    const get_prompt_options = async () => {
      const ai_prompt_options = await axiomFetch(
        '/marketing/content/aiPromptKeywords',
        'GET',
        {},
        {},
        false,
        controller?.signal
      )
      saveAi((prev) => ({ ...prev, ai_prompt_options }))
    }
    get_prompt_options()
    return () => {
      controller?.abort()
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const get_options = async () => {
      const data = await axiomFetch('/marketing/content/aiOptionKeywords')
      saveAi((prev) => ({ ...prev, ai_rewrite_options: data }))
    }
    get_options()
    return () => {
      controller?.abort()
    }
    // eslint-disable-next-line
  }, [])

  const message = (
    <div>
      <textarea
        name={name}
        className={`${uiStyles.text_area} whitespace-pre-wrap `}
        value={message_val}
        style={{ height: `${200}px` || '200px' }}
        onChange={handle_message}
      ></textarea>
    </div>
  )
  return (
    <div>
      {limits?.[3]?.limit_reached ? (
        <>
          <Limits />
          {message}
        </>
      ) : (
        <>
          <label className={`${uiStyles.text_black}`}>{message_label}</label>
          <div className='mt-[5px]'>
            <AskMulti
              name={name}
              type={type}
              mkt_item={mkt_item}
              new_rewrites={new_rewrites}
              rewrites={rewrites}
              save_field={save_field}
              message_val={message_val}
              copy_text={copy_text}
              handle_change={handle_change}
            />
          </div>

          {message}
        </>
      )}
    </div>
  )
}
