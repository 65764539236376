import React from 'react';
import HeaderUser2 from '../Header2';
import Hero from '../Hero';
import Transaction from '../ui/Transaction';
import Listing from '../Listing';
import SubTitle from '../ui/SubTitle';
import PropertyThumb from '../../assets/images/property.jpg';
import {
  HiPhone,
  HiChatBubbleBottomCenterText,
  HiEnvelope,
} from 'react-icons/hi2';
import { BsBoxArrowRight } from 'react-icons/bs';
import PropertyItem from '../ui/PropertyItem';
import ReviewItem from '../ui/ReviewItem';
import Wrapper from '../layout/Wrapper';

function VBCWTrans() {
  return (
    <Wrapper>
      <HeaderUser2 />
      <Hero />
      <SubTitle className="text-center">Adam Driggs</SubTitle>
      <div className="flex justify-center text-primary text-2xl pt-1 pb-3">
        <a href="#;" className="mx-4">
          <HiPhone />
        </a>
        <a href="#;" className="mx-4">
          <HiChatBubbleBottomCenterText />
        </a>
        <a href="#;" className="mx-4">
          <HiEnvelope />
        </a>
        <a href="#;" className="mx-4">
          <BsBoxArrowRight />
        </a>
      </div>
      <Transaction title="TRANSACTION" />
      <PropertyItem
        thumbnail={PropertyThumb}
        title="2727 E. Mockingbird Ln, Scottsdale, Arizona, 85254"
        features={[
          '4 Bedroom',
          '3 Bath',
          '4200 Sq Ft',
          '1 Acre Lot',
          'Diving pool',
        ]}
        isNew={true}
        price="$1,325,000"
      />
      <hr className="border-t-0 border-white border-b-2 " />
      <ReviewItem
        rating={5}
        comment={
          '2505 North 140th Drive, was facilitated by Driggs Title Agency; a disinterested 3rd party. We received the following review in Goodyear about real estate agent Adam Driggs from the Buyer.'
        }
      />
      <hr className="border-t-0 border-white border-b-2 " />
      <Listing />
    </Wrapper>
  );
}

export default VBCWTrans;
