import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import Wrapper from '../../layout/Wrapper'
import Header2 from '../../Header2'
import IconContent from '../../ui/IconContent'
import IconCalc from '../../../assets/images/calc.png'
import dpay_icon from '../../../assets/images/transactions/dpay_icon.svg'
import SwipeConfig from '../../ui/SwipeConfig'
import LeadItem from '../../ui/LeadItem'
import AxiomAllTransactionIcon from '../../ui/AxiomAllTransactionIcon'
import AxiomFileOpenIcon from '../../ui/AxiomFileOpenIcon'
import AxiomClosedFileIcon from '../../ui/AxiomClosedFileIcon'
import AxiomEscrowIcon from '../../ui/AxiomEscrowIcon'
// import DocumentItem from '../../ui/DocumentItem';

// import Modal from '../../Modal';
// import MLSView from './MLSView';
// import UploadContracts from './UploadContracts';
// import MLSSearch from './MLSSearch';
// import { useAuth0 } from '@auth0/auth0-react';
// import { useProfile } from '../../../contexts/UserContext';
// import { ServiceTransactions } from '../../../services/ServiceTransactions';
// import { useQuery } from '@tanstack/react-query';
// import { useTransactions } from '../../../contexts/TransactionsContext';
import MLSSearch from './MLSSearch'
import Modal from '../../Modal'
import MLSView from './MLSView'
import SmallLoading from '../../ui/SmallLoading'
import MLSConfirm from './MLSConfirm'
import uiStyles from '../../ui/uiStyles'
import images_logos from '../../../assets/images/axiom_logos'
import { open_url_tab } from '../../../helpers'

export default function TransactionsLobby() {
  const { axiomFetch } = useAxiomFetch()
  const [selected, setSelected] = useState(1)
  const [openEscrow, setOpenEscrow] = useState(false)
  const [modalType, setModalType] = useState('')
  const [mlsSearch, setMlsSearch] = useState('')
  const [mlsMatch, setMlsMatch] = useState({})
  const [count, setCount] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  // const { transactions, getClosedTransactions, getOpenTransactions } =
  //   useTransactions();

  useEffect(() => {
    let controller = new AbortController()
    const refresh_transactions = async () => {
      setIsLoading(true)
      const data = await axiomFetch(
        '/escrow/transaction?limit=1000',
        'GET',
        {},
        {},
        false,
        controller.signal
      )
      if (!controller.signal.aborted) {
        if (data?.length) {
          setCount({
            ...count,
            all: data?.length,
            open:
              data?.filter((transaction) => transaction?.close_disb_dt === null)
                ?.length || 0,
            closed:
              data?.filter((transaction) => transaction?.close_disb_dt !== null)
                ?.length || 0,
          })
        }
        setIsLoading(false)
      }
    }
    refresh_transactions()
    return () => {
      controller?.abort()
    }
    // eslint-disable-next-line
  }, [])

  const modals = {
    openEscrow: (
      <MLSSearch
        mlsSearch={mlsSearch}
        setMlsSearch={setMlsSearch}
        modalType={modalType}
        setModalType={setModalType}
      />
    ),
    mlsView: (
      <MLSView
        setMlsMatch={setMlsMatch}
        mlsMatch={mlsMatch}
        mlsSearch={mlsSearch}
        modalType={modalType}
        setModalType={setModalType}
      />
    ),
    mlsConfirm: (
      <MLSConfirm
        mlsSearch={mlsSearch}
        mlsMatch={mlsMatch}
        setModalType={setModalType}
      />
    ),
  }
  return (
    <>
      <Wrapper>
        <Header2 />
        <IconContent
          iconImage={IconCalc}
          title='Closing Experiences'
          content='Your Closing Experiences.'
        />
        {isLoading ? (
          <SmallLoading />
        ) : (
          <>
            {
              <div className='leads flex flex-col'>
                <SwipeConfig
                  index={0}
                  selectedIndex={selected}
                  setSelectedIndex={setSelected}
                  widthConfigRight={44}
                  bg={'bg-gray-100'}
                  RightComponents={() => <Link to='transactions'>View</Link>}
                >
                  <LeadItem
                    item={{
                      link: 'transactions',
                      icon: <AxiomAllTransactionIcon />,
                      title: 'All Closing Experiences',
                      description: 'All Your Closing Experiences',
                      number: count?.all,
                      index: 0,
                    }}
                  />
                </SwipeConfig>
                <SwipeConfig
                  index={1}
                  selectedIndex={selected}
                  setSelectedIndex={setSelected}
                  widthConfigRight={44}
                  bg={'bg-white'}
                  RightComponents={() => (
                    <Link to='transactions?filter=open'>View</Link>
                  )}
                >
                  <LeadItem
                    item={{
                      link: 'transactions?filter=open',
                      icon: <AxiomFileOpenIcon />,
                      title: 'Open Files',
                      description: 'Your Open Files',
                      number: count?.open,
                      index: 1,
                    }}
                  />
                </SwipeConfig>

                <SwipeConfig
                  index={2}
                  selectedIndex={selected}
                  setSelectedIndex={setSelected}
                  widthConfigRight={44}
                  bg={'bg-gray-100'}
                  RightComponents={() => (
                    <Link to='transactions?filter=closed'>View</Link>
                  )}
                >
                  <LeadItem
                    item={{
                      link: 'transactions?filter=closed',
                      icon: <AxiomClosedFileIcon />,
                      title: 'Closed Files',
                      description: 'Your Closed Files',
                      number: count?.closed,
                      index: 2,
                    }}
                  />
                </SwipeConfig>

                <SwipeConfig
                  index={3}
                  selectedIndex={selected}
                  setSelectedIndex={setSelected}
                  widthConfigRight={44}
                  bg={'bg-white'}
                  RightComponents={() => <Link to='rtp'>Open</Link>}
                >
                  <LeadItem
                    item={{
                      link: 'rtp',
                      icon: <AxiomEscrowIcon />,
                      title: 'RTP',
                      description: 'Wires/RTP configuration',
                      index: 3,
                    }}
                  />
                </SwipeConfig>
                <SwipeConfig
                  index={4}
                  selectedIndex={selected}
                  setSelectedIndex={setSelected}
                  widthConfigRight={44}
                  bg={'bg-gray-100'}
                  RightComponents={() => (
                    <button
                      onClick={() => {
                        setMlsSearch('')
                        setMlsMatch({})
                        setOpenEscrow(true)
                        setModalType('openEscrow')
                      }}
                    >
                      Open
                    </button>
                  )}
                >
                  <LeadItem
                    item={{
                      link: () => {
                        setMlsSearch('')
                        setMlsMatch({})
                        setOpenEscrow(true)
                        setModalType('openEscrow')
                      },
                      icon: <AxiomEscrowIcon />,
                      title: 'Open Escrow',
                      description: 'Open Escrow Now',
                      index: 4,
                    }}
                  />
                </SwipeConfig>
                <div
                  className='flex flex-col items-center gap-[10px] my-[40px] cursor-pointer'
                  onClick={() => {
                    open_url_tab('https://www.driggstitle.com/driggs-pay/')
                  }}
                >
                  <p className={`${uiStyles.text_black} font-bold`}>
                    Welcome to
                  </p>
                  <img src={dpay_icon} className={'w-[230px]'} />
                  {/* <div className='bg-[#272525] text-white px-[20px] py-[40px] rounded-lg text-[50px]'>
                    AXIOM Pay
                  </div>
                  <p className={`${uiStyles.text_gray}`}>Get Paid Faster </p> */}
                </div>
              </div>
            }
            {openEscrow ? (
              <Modal
                modalName={modalType}
                showClose={false}
                height={modalType === 'openEscrow' ? 'h-[500px]' : 'h-[550px]'}
                width={'w-[330px]'}
                showModal={openEscrow}
                setShowModal={setOpenEscrow}
              >
                {modalType && modals[modalType]}
              </Modal>
            ) : (
              ''
            )}
          </>
        )}
      </Wrapper>
    </>
  )
}
