import { useState, useEffect } from 'react'
import uiStyles from '../../ui/uiStyles'

export const CopyText = ({ text_to_copy, type }) => {
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 2000)
    }
  }, [copied])
  return (
    <div className='text-right flex justify-end gap-2 items-center'>
      {
        <div
          className={`bg-gray-200 ${
            copied ? 'opacity-50' : 'opacity-0'
          } text-black p-3 rounded transition-all duration-500 `}
        >
          <span className='capitilize'>{type}</span> copied!
        </div>
      }
      <button
        className={uiStyles.hover_btn}
        onClick={() => {
          navigator.clipboard.writeText(text_to_copy)
          setCopied(true)
        }}
      >
        Copy
      </button>
    </div>
  )
}
