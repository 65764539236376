import { forwardRef, useEffect } from 'react'
import { useCalc } from '../../contexts/CalcContext'
import { format_currency } from '../../helpers'
import LabelInput from '../form/LabelInput'
import Modal from '../Modal'
import uiStyles from '../ui/uiStyles'

const CalcPaymentChange = forwardRef(({ update_input }, ref) => {
  const { calc, saveCalc } = useCalc()

  const finish = () => {
    return saveCalc((prev) => ({
      ...prev,
      buy: {
        ...prev?.buy,
        show_total_edit: false,
        calculated: true,
        closing_calc_count: 0,
      },
    }))
  }
  return calc?.buy?.show_total_edit ? (
    <Modal
      modalName='change_monthly_close'
      showModal={calc?.buy?.show_total_edit}
      setShowModal={finish}
      height='h-[200px]'
    >
      <div className='flex'>
        <div className='w-[300px] flex flex-col items-center p-2 rounded m-1 opacity-100'>
          {calc?.buy?.show_total_edit_type === 'monthly_payment' ? (
            <LabelInput
              left_label={'Monthly Payment'}
              left_label_width={'w-[100%]'}
              name={'monthly_payment'}
              show_dollar={true}
              show_delete_all={true}
              percent_value=''
              value={calc?.buy?.monthly_payment || ''}
              handleChange={(e, type) => {
                update_input({
                  e,
                  type,
                  decimal: 3,
                  original: calc?.buy?.monthly_payment || '',
                })
              }}
              placeholder=''
            />
          ) : calc?.buy?.show_total_edit_type === 'home_price' ? (
            <LabelInput
              left_label={'Home Price'}
              left_label_width={'w-[70%]'}
              name={'home_price'}
              show_dollar={true}
              show_delete_all={true}
              value={calc?.buy?.values?.home_price}
              handleChange={(e, type) => {
                update_input({
                  e,
                  type,
                  decimal: 3,
                  original: calc?.buy?.values?.home_price,
                })
                saveCalc((prev) => ({
                  ...prev,
                  buy: { ...prev?.buy, run_affordability: true },
                }))
              }}
              placeholder=''
            />
          ) : (
            <LabelInput
              left_label={'Bring To Close'}
              left_label_width={'w-[70%]'}
              name={'bring_to_close'}
              show_dollar={true}
              show_delete_all={true}
              value={calc?.buy?.bring_to_close}
              handleChange={(e, type) =>
                update_input({
                  e,
                  type,
                  decimal: 3,
                  original: calc?.buy?.bring_to_close,
                })
              }
              placeholder=''
            />
          )}
          <div className='flex w-full justify-end'>
            <button className={`${uiStyles.hover_btn}`} onClick={finish}>
              Done
            </button>
          </div>
        </div>
      </div>
    </Modal>
  ) : (
    ''
  )
})

export default CalcPaymentChange
