import React from 'react'
import { Outlet } from 'react-router-dom'
import images_admin from '../../assets/images/admin'
import { useProfile } from '../../contexts/UserContext'
import Wrapper from '../layout/Wrapper'
import SubNav from '../SubNav'
import uiStyles from '../ui/uiStyles'

const AdminWrapper = () => {
  const { profile } = useProfile()
  console.log('admin', profile)
  return (
    <Wrapper>
      <SubNav />
      {profile?.is_admin === 'Y' ? (
        <Outlet />
      ) : (
        <div className='bg-black h-[600px] rounded shadow-2xl'>
          <div className='px-3 mb-[20px]'>
            <p
              className={`text-white text-[24px] font-semibold text-center pt-[20px]`}
            >
              Forbidden
            </p>
          </div>
          <img src={images_admin.key} />
        </div>
      )}
    </Wrapper>
  )
}

export default AdminWrapper
