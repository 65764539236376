import React from 'react'
import { GrDocument, GrDocumentMissing } from 'react-icons/gr'
import { IoMdDocument } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { usePairwise } from '../../../contexts/PairwiseContext'
import { getDateString, validate_number } from '../../../helpers'
import DownloadBtn from '../../form/DownloadBtn'
import uiStyles from '../../ui/uiStyles'

const PairwiseDocumentsView = () => {
  const { pairwise, savePairwise } = usePairwise()
  const navigate = useNavigate()

  const get_urls = () => {
    const validate_name = (name) => {
      return name?.toString()?.toLowerCase() || ''
    }
    const check_valid_name = (name) => {
      if (
        name.includes('purchase') ||
        name.includes('operating') ||
        name.includes('w9') ||
        name?.includes('distribution')
      ) {
        return true
      } else {
        return false
      }
    }
    const pair_docs_obj = pairwise?.data?.pair_application || {}
    pairwise?.data?.pair_client_docs
      ?.filter((item) => check_valid_name(validate_name(item?.doc_name)))
      ?.forEach((item) => {
        if (validate_name(item?.doc_name)?.includes('purchase')) {
          pair_docs_obj['purchase_agreement_url'] = item?.doc_path
        } else if (validate_name(item?.doc_name)?.includes('operating')) {
          pair_docs_obj['operating_agreement_url'] = item?.doc_path
        } else if (validate_name(item?.doc_name)?.includes('w9')) {
          pair_docs_obj['w9_url'] = item?.doc_path
        } else if (validate_name(item?.doc_name)?.includes('distribution')) {
          pair_docs_obj['distribution_url'] = item?.doc_path
        }
      })
    return pair_docs_obj
  }
  return (
    <div className='px-3'>
      <h1 className={`${uiStyles.text_black_subtitle}`}>
        Your Pairwise Documents
      </h1>

      <div className='flex flex-col gap-[10px] mt-[15px]'>
        {Object.entries(get_urls())
          ?.filter(
            ([key, value]) => key.includes('url') && !key.includes('signature')
          )
          ?.map(([key, value], index) => {
            const get_layout_name = (url) => {
              let id = 1664
              const url_matcher = (key_match) => {
                return key?.toLowerCase().includes(key_match)
              }
              if (url_matcher('operating')) {
                id = 1661
              } else if (url_matcher('purchase')) {
                id = 1662
              } else if (url_matcher('w9')) {
                id = 1663
              } else if (url_matcher('distribution')) {
                id = 1664
              }
              const layout = pairwise?.data?.mkt_req_layout?.find(
                (item) => item?.layout_id === validate_number(id)
              )
              return layout?.layout_name_alias || layout?.layout_name
            }
            const name = get_layout_name(value)

            return (
              <div key={value + index} className='flex flex-col text-thirdary'>
                <div className='flex justify-between'>
                  <div className='flex items-center gap-[5px]'>
                    {value ? (
                      <GrDocument size={25} />
                    ) : (
                      <GrDocumentMissing size={25} />
                    )}
                    {value ? (
                      <a
                        className='text-[15px] underline'
                        target='_blank'
                        href={encodeURI(value)}
                      >
                        {name}
                      </a>
                    ) : (
                      <div>{name}</div>
                    )}
                  </div>
                  {/* <DownloadBtn url={value} /> */}
                </div>
                <div className={`${uiStyles.line} mt-[10px]`}></div>
              </div>
            )
          })}
      </div>
      {pairwise?.data?.pair_application?.approved_dt ? (
        <div>
          <p>
            {' '}
            Application Approved:{' '}
            {getDateString({
              date: pairwise?.data?.pair_application?.approved_dt,
              format: 'mmm dd, yyyy hh:mi am',
            })}
          </p>
          <div>
            <button
              className={`${uiStyles.white_btn} w-full mt-[10px]`}
              onClick={() => {
                navigate('/user/pairwise')
              }}
            >
              Back
            </button>
          </div>
        </div>
      ) : (
        <div>
          <div>
            Application Pending:{' '}
            {getDateString({
              date: pairwise?.data?.pair_application?.pending_dt,
              format: 'mmm dd, yyyy hh:mi am',
            })}
          </div>
          {pairwise?.data?.pair_application?.payment_setup_dt &&
          !pairwise?.data?.pair_application?.payment_received_dt ? (
            <div>
              Payment Pending:{' '}
              {getDateString({
                date: pairwise?.data?.pair_application?.payment_setup_dt,
                format: 'mmm dd, yyyy hh:mi am',
              })}
            </div>
          ) : pairwise?.data?.pair_application?.payment_received_dt ? (
            <div>
              Payment Received:{' '}
              {getDateString({
                date: pairwise?.data?.pair_application?.payment_received_dt,
                format: 'mmm dd, yyyy hh:mi am',
              })}
            </div>
          ) : (
            <div className='font-bold text-red-500'>Payment Setup Required</div>
          )}
          <div className='flex justify-end gap-[10px]'>
            <button
              className={`${uiStyles.white_btn_small} mt-[10px]`}
              onClick={() => {
                savePairwise((prev) => ({
                  ...prev,
                  documents: {
                    ...prev?.documents,
                    data_options: {
                      ...prev?.documents?.data_options,
                      re_upload: true,
                    },
                  },
                }))
              }}
            >
              Re-Upload Documents
            </button>

            <button
              className={`${uiStyles.hover_btn_small} mt-[10px]`}
              onClick={() => {
                navigate('/user/pairwise/payments')
              }}
            >
              Setup Payment
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default PairwiseDocumentsView
