import { useRef, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSales } from '../../contexts/SalesContext'
import { useProfile } from '../../contexts/UserContext'
import { set_storage, validate_number } from '../../helpers'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import { SmallLoadingRel } from '../ui/SmallLoadingRel'
import uiStyles from '../ui/uiStyles'

const CliNote = ({ setShowModal }) => {
  const { sales, saveSales } = useSales()
  const [loading, setLoading] = useState(false)
  const { profile } = useProfile()
  const { axiomFetch } = useAxiomFetch()
  const textRef = useRef(null)
  const navigate = useNavigate()
  useEffect(() => {
    if (textRef?.current) {
      textRef.current.focus()
    }

    return () => {}
  }, [textRef])

  const save_note = async () => {
    setLoading(true)
    await axiomFetch('/sales/what_next/notes/create', 'POST', {
      cli_cont_id: sales?.curr_client?.data?.cont_id,
      user_id: profile.USER_ID,
      note: textRef.current.value,
    })
    saveSales((prev) => {
      const sales = {
        ...prev,
        what_next_clients: prev?.what_next_clients.filter(
          (item) => item.cont_id !== sales?.curr_client?.data?.cont_id
        ),
        curr_client: {
          ...prev?.curr_client,
          other_data: {
            ...prev?.curr_client?.other_data,
            cli_notes: [
              {
                cli_note_id: validate_number(
                  Math.max(
                    (prev?.curr_client?.score_date?.cli_notes || [])?.map(
                      (item) => item.cli_note_id
                    )
                  ) + 1
                ),
                note: textRef?.current?.value,
                create_dt: new Date().toLocaleString(),
                create_by_name: profile.CONT_NAME,
              },
              ...(prev?.curr_client?.other_data?.cli_notes || []),
            ],
          },
        },
      }
      set_storage('sales', sales)
      return sales
    })
    setLoading(false)
    navigate('/user/sales/what_next')
    setShowModal(false)
  }

  return (
    <div className='px-3 flex flex-col justify-between min-h-[350px]'>
      <textarea
        ref={textRef}
        className={`${uiStyles.text_area} h-[250px]`}
        onKeyDown={async (e) => {
          if (e.key === 'Enter' && e.metaKey) {
            await save_note()
          }
        }}
      ></textarea>
      <small className='text-thirdary mt-[-20px] text-end'>
        cmd + Enter = submit
      </small>
      {loading ? (
        <SmallLoadingRel />
      ) : (
        <div className='flex flex-col'>
          {
            <button className={`${uiStyles.hover_btn}`} onClick={save_note}>
              Create Note
            </button>
          }
          <small className='text-thirdary text-end'>
            ...{sales?.curr_client?.data?.cont_name} Will Clear What's next
          </small>
        </div>
      )}
    </div>
  )
}

export default CliNote
