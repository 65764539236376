import { useEffect, useState, useRef } from 'react'
import ch_images from '../../../assets/images/conversations'
import uiStyles from '../../ui/uiStyles'
export const AiLoader = ({ loads, setRefresh }) => {
  const [currLoad, setCurrLoad] = useState(loads?.[0] || 'Generating Content')
  const default_loads = [
    'Generating Content',
    'Thinking Creatively',
    'Reading up on Real Estate',
    'Studying up on marketing',
  ]
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (loads?.length) {
        setCurrLoad(
          loads.concat(default_loads)?.[
            Math.floor(Math.random() * loads?.length)
          ]
        )
      } else {
        setCurrLoad(
          default_loads?.[Math.floor(Math.random() * default_loads?.length)]
        )
      }
    }, [3000])
    return () => {
      clearInterval(intervalId)
    }
  }, [])
  return (
    <div className='flex flex-col items-center my-[20px]'>
      <div className='flex flex-col items-center max-w-[200px] gap-[10px]'>
        <img src={ch_images.robot_icon} className={'w-[50px]'} />
        <p className={uiStyles.text_black_subtitle}>{currLoad}...</p>
      </div>
    </div>
  )
}
