import React, { useCallback, useEffect, useRef, useState } from 'react'
import SubNav from '../SubNav'
import TitleIcon from '../ui/TitleIcon'
import PageSubMenu from '../PageSubMenu'
import { DUMMYTHUMBS, INNERMENU } from '../../data/data'
import SwipeConfig from '../ui/SwipeConfig'
import ListItem from '../ui/ListItem'
import Wrapper from '../layout/Wrapper'
import { useAuth0 } from '@auth0/auth0-react'
import { useProfile } from '../../contexts/UserContext'
import { ServiceVBC } from '../../services/ServiceVBC'
import { useQuery } from '@tanstack/react-query'
import Loading from '../Loading'
import { Link, useNavigate } from 'react-router-dom'
import { useVBCs } from '../../contexts/VBCContext'
import Modal from '../Modal'
import DBCSend from './VBCDetails/DBCSend'
import SmallLoading from '../ui/SmallLoading'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import uiStyles from '../ui/uiStyles'
import { useReceiver } from '../../contexts/ReceiverContext'
import { get_storage, set_storage } from '../../helpers'
export default function MyVBC() {
  const { axiomFetch } = useAxiomFetch()
  const [selected, setSelected] = useState(1)
  const { saveVBCs, VBCs } = useVBCs()
  const { profile } = useProfile()
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [resetCards, setResetCards] = useState(0)
  const { saveReceiver } = useReceiver()
  const sendId = useRef(null)
  const navigate = useNavigate()
  useEffect(() => {
    const controller = new AbortController()
    const get_vbcs = async () => {
      if (!VBCs?.cards?.length) {
        setIsLoading(true)
        console.log('hello')
        const data = await axiomFetch(
          '/products/vbc',
          'GET',
          {},
          {},
          false,
          controller.signal
        )
        saveVBCs(data)
        if (!controller.signal.aborted) {
          setIsLoading(false)
        }
      }
    }
    get_vbcs()
    return () => {
      controller?.abort()
    }
    // eslint-disable-next-line
  }, [resetCards])

  // const fetchController = useRef(null)

  // const getVBCs = useCallback(async () => {
  //   const token = await getAccessTokenSilently({
  //     audience: process.env.REACT_APP_AUDIENCE,
  //   })

  //   const serviceClientListing = new ServiceVBC(token)
  //   if (!fetchController.current) {
  //     fetchController.current = serviceClientListing.getController().controller
  //   }
  //   return serviceClientListing.getAll()
  // }, [getAccessTokenSilently])

  // const { isLoading, data, isFetching } = useQuery({
  //   queryKey: ['myvbcs'],
  //   queryFn: getVBCs,
  //   refetchOnWindowFocus: false,
  //   enabled: !!profile && !VBCs,
  // })

  const rightConfig = (item) => () => {
    return (
      <>
        <div
          // to={`${item.ID}`}
          // state={{ name: item.NAME }}
          onClick={() => {
            const curr_vbc = get_storage('vbc_receiver')
            saveReceiver((prev) => {
              localStorage.setItem('vbc_edit_mode', 'true')
              return {
                ...prev,
                edit_mode: 'view',
              }
            })
            if ((curr_vbc?.ITEM_ID || curr_vbc?.VBC_INSTANCE_ID) !== item?.ID) {
              localStorage.removeItem('card')
              localStorage.removeItem('slots')
              item.VBC_INSTANCE_ID = item.ID
              set_storage('vbc_receiver', item)
            }
            navigate(`${item?.ID}`, { state: { name: item?.NAME } })
          }}
          className='inline-block mx-1 cursor-pointer'
        >
          View
        </div>
        |
        <Link
          to={`/user/virtual-cards/edit/${item.ID}`}
          state={{ name: item.NAME }}
          className='inline-block mx-1'
        >
          Edit
        </Link>
        |
        <button
          className='inline-block mx-1'
          onClick={() => {
            setShowModal(true)
            setModalType('send')
            sendId.current = item.ID
          }}
        >
          Send
        </button>
      </>
    )
  }
  // useEffect(() => {
  //   if (!VBCs) {
  //     if (data && data?.length) {
  //       saveVBCs(data)
  //     }
  //   }
  //   return () => fetchController.current && fetchController.current.abort()
  // }, [data, saveVBCs, VBCs])

  const modals = {
    send: (
      <DBCSend
        showModal={showModal}
        setShowModal={setShowModal}
        sendId={sendId?.current}
      />
    ),
  }

  console.log(VBCs)
  const htmlListing =
    VBCs?.cards &&
    VBCs?.cards?.length &&
    (VBCs?.cards || []).map((item, index) => {
      return (
        <SwipeConfig
          key={index}
          index={index}
          selectedIndex={selected}
          setSelectedIndex={setSelected}
          RightComponents={rightConfig(item)}
        >
          <ListItem
            key={index}
            image={item?.IMG_LINK || DUMMYTHUMBS[index > 2 ? index % 2 : index]}
            title={item.NAME}
            content={item.VBC_DESC}
          />
        </SwipeConfig>
      )
    })

  // if (isLoading || isFetching) {
  //   return <Loading />;
  // }

  return (
    <Wrapper>
      <SubNav url={'/user/marketing-lobby'} />
      <div className='flex items-baseline'>
        <TitleIcon className='mb-5'>My Realty Smart Business Cards</TitleIcon>
        <div className={'mt-[10px] px-3 flex justify-end'}>
          <button
            onClick={async () => {
              setIsLoading(true)
              await axiomFetch('/products/vbc', 'DELETE')
              saveVBCs(null)
              setResetCards((prev) => prev + 1)
            }}
            className={uiStyles.hover_btn}
          >
            Reset
          </button>
        </div>
      </div>
      {/* <div className="pt-2 pb-4 px-4">
        <PageSubMenu selected={INNERMENU.MyVBC} />
      </div> */}
      {isLoading ? (
        <SmallLoading />
      ) : (
        <div>
          <div className='flex flex-col gap-[2px]'>{htmlListing}</div>
          <div className={'mt-[10px] px-3 flex gap-[10px] justify-end'}>
            <button
              onClick={async () => {
                navigate('/user/my-vbc/services')
              }}
              className={uiStyles.hover_btn}
            >
              Add My Guys
            </button>
          </div>
        </div>
      )}
      {showModal && (
        <Modal
          modalName={modalType}
          showModal={showModal}
          setShowModal={setShowModal}
          width='w-[330px]'
        >
          {modalType && modals[modalType]}
        </Modal>
      )}
    </Wrapper>
  )
}
