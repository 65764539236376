import { useState, useRef } from 'react'
import { HiArrowLeft } from 'react-icons/hi2'
import { useConversations } from '../../../contexts/ConversationsContext'
import SubNav from '../../SubNav'
import SwipeConfig from '../../ui/SwipeConfig'
import TitleIcon from '../../ui/TitleIcon'
import uiStyles from '../../ui/uiStyles'
import { ConversationCard } from './ConversationCard'
import ch_images from '../../../assets/images/conversations'
import LabelInput from '../../form/LabelInput'
import { NotificationsIcon } from './NotificationsIcon'
export const CampaignConversations = ({
  campaign,
  setCurrentCampaign,
  setCurrentConversation,
}) => {
  const [selected, setSelected] = useState(1)
  const [searchActive, setSearchActive] = useState(false)
  const [search, setSearch] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('none')
  const searchRef = useRef(null)
  const { convMessages, saveConvMessages } = useConversations()

  const get_unread_count = () => {
    let total = 0
    for (const conv of campaign?.conversations) {
      total += convMessages[conv?.sid]?.unread_count || 0
    }
    return total || 0
  }

  const get_last_conv_msg = () => {
    let max = campaign?.conversations?.reduce((prev, current) =>
      convMessages?.[prev?.sid]?.date?.valueOf() >
      convMessages?.[current?.sid]?.date?.valueOf()
        ? prev
        : current
    )
    return convMessages?.[max?.sid]
  }

  return (
    <div>
      <SubNav
        showBack={
          <div
            className='flex items-center cursor-pointer'
            onClick={() => {
              setCurrentCampaign()
            }}
          >
            <span>
              <HiArrowLeft className='mr-2' />
            </span>{' '}
            Back
          </div>
        }
      />
      <div className='flex-col'>
        <div className='mb-[16px] flex'>
          <div className='flex flex-col'>
            <TitleIcon>
              <div
                className={`flex overflow-x-scroll ${'max-w-[220px]'} whitespace-nowrap`}
              >
                <p className=''>{campaign?.name}</p>
              </div>
            </TitleIcon>
            <p className={`${uiStyles.text_gray} pl-4`}>
              Campaign Sent On{' '}
              {campaign?.conversations?.[0]?.dateCreated?.toLocaleString(
                'en-US',
                { day: '2-digit', month: '2-digit', year: '2-digit' }
              )}
            </p>
          </div>
          {get_unread_count() ? (
            <div className='mt-[5px]'>
              <NotificationsIcon count={get_unread_count()} gray={true} />
            </div>
          ) : (
            ''
          )}
        </div>
        <div className='flex justify-center'>
          <div
            className={`w-full px-4 mb-[8px] cursor-pointer `}
            onClick={() => {
              setTimeout(() => {
                // searchActive decommissioned
                searchRef.current.focus()
              }, [0])
            }}
          >
            <div
              className={`flex w-full mt-1 border-2 ${
                searchActive ? 'border-gray-400' : 'border-gray-400'
              } rounded`}
            >
              <div
                className={`overflow-x-scroll rounded-l-md resize-none p-3 leading-4 text-thirdary`}
              >
                <img
                  className='mr-[10px] cursor-pointer duration-75'
                  onClick={() => {
                    // setSearchActive((prev) => {
                    //   if (prev === false) {
                    //     setTimeout(() => {
                    //       searchRef.current.focus()
                    //     }, [0])
                    //   }
                    //   return !prev
                    // })
                  }}
                  src={ch_images.search_gray}
                />
              </div>
              <input
                name={'search'}
                type={'input'}
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
                placeholder={'Search'}
                className={`w-[100%] overflow-x-scroll resize-none p-3 leading-4 text-primary focus:outline-none`}
                ref={searchRef}
              />
            </div>
          </div>
          {/* <label className={`flex ${uiStyles.input} w-[80%]`}>
            <img
              className='mr-[10px] cursor-pointer duration-75'
              onClick={() => {
                setSearchActive((prev) => {
                  if (prev === false) {
                    setTimeout(() => {
                      searchRef.current.focus()
                    }, [0])
                  }
                  return !prev
                })
              }}
              src={ch_images.search_blue}
            />
            <input
              ref={searchRef}
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              placeholder={'Search'}
              className={`${uiStyles.input} mr-[5px]  ${'w-[80%]'} hidden`}
            ></input>
          </label> */}
        </div>
      </div>
      <div>
        {campaign?.conversations?.length
          ? campaign?.conversations
              ?.filter((item) => {
                return convMessages[item?.sid]?.date?.valueOf()
              })
              ?.filter((item) => {
                if (item?.sid) {
                  return (item?._internalState?.friendlyName || item?.sid)
                    ?.toLowerCase()
                    ?.includes(search?.toLowerCase())
                }
              })
              ?.sort((a, b) => {
                if (a.sid && b.sid) {
                  return (
                    convMessages?.[b?.sid]?.date?.valueOf() -
                    convMessages?.[a?.sid]?.date?.valueOf()
                  )
                }
              })
              ?.map((item, index) => {
                if (item?.sid) {
                  return (
                    <div key={item?.sid}>
                      <div className={`${uiStyles.line_light} ml-[7px]`}></div>
                      <div key={item?.sid} className={''}>
                        <SwipeConfig
                          index={index}
                          selectedIndex={selected}
                          setSelectedIndex={setSelected}
                          widthConfigRight={44}
                          bg={'bg-white'}
                          RightComponents={() => {
                            return (
                              <div
                                className='cursor-pointer'
                                onClick={async () => {
                                  setCurrentConversation(item)
                                }}
                              >
                                Chat
                              </div>
                            )
                          }}
                          key={item?.sid}
                        >
                          <ConversationCard
                            conv={item}
                            convMessages={convMessages[item?.sid]}
                            setCurrentMessages={saveConvMessages}
                          />
                        </SwipeConfig>
                      </div>
                    </div>
                  )
                }
              })
          : ''}
      </div>
    </div>
  )
}
