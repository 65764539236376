import { useState } from 'react'
import { HiEnvelope, HiPhone } from 'react-icons/hi2'
import { TbWorld } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import { useClaims } from '../../../contexts/ClaimsContext'
import ListItem from '../../ui/ListItem'
import SwipeConfig from '../../ui/SwipeConfig'
import uiStyles from '../../ui/uiStyles'

const CardServices = ({ state, setState }) => {
  const [page, setPage] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const [selected, setSelected] = useState(1)
  const navigate = useNavigate()
  const { claims, saveClaims } = useClaims()
  console.log('claims', claims)
  console.log('service', state)

  const get_markets = () => {
    return (
      state?.card?.markets?.filter((item) => {
        return (
          claims?.vbc_receiver?.MARKET_UUIDS ||
          state?.vbc_receiver?.market_uuids
        )
          ?.split(',')
          .includes(item?.default_uuid)
      }) || []
    )
  }
  const current_market_name = () => {
    return (
      get_markets()?.find(
        (market) => market.default_uuid === state?.card?.market_default_uuid
      ) || get_markets()?.[0]
    )?.location_name
  }

  const initial_market = () => {
    return state?.card?.market_default_uuid || get_markets()?.[0]?.default_uuid
  }

  const settingsRight = (item) => {
    return () => (
      <div className='flex gap-[10px]'>
        <div>
          {item?.cell ? (
            <a href={`tel:${item?.cell}`} className={'underline'}>
              <HiPhone size={25} />
            </a>
          ) : (
            ''
          )}
        </div>
        <div>
          {item?.email ? (
            <a
              href={`mailto:?to=${item?.email}&subject=&body=`}
              target='_blank'
              rel='noreferrer'
              className='underline cursor-pointer'
            >
              <HiEnvelope size={25} />
            </a>
          ) : (
            ''
          )}
        </div>
        <div>
          {item?.website ? (
            <a
              href={item?.website}
              target='_blank'
              rel='noreferrer'
              className='underline cursor-pointer'
            >
              <TbWorld size={25} />
            </a>
          ) : (
            ''
          )}
        </div>
      </div>
    )
  }
  return (
    <div>
      <div className='px-3'>
        <h2 className={`${uiStyles.text_black_title}`}>My Guys</h2>
        {/* {get_markets()?.length > 1 ? (
          <select
            className={`${uiStyles.select}`}
            value={initial_market()}
            onChange={(e) => {
              setState((prev) => {
                const return_data = {
                  ...prev,
                  card: { ...prev?.card, market_default_uuid: e.target.value },
                }
                return return_data
              })
            }}
          >
            {get_markets()?.map((item) => {
              return (
                <option key={item.default_uuid} value={item.default_uuid}>
                  {item.location_name}
                </option>
              )
            })}
          </select>
        ) : (
          <p className={`${uiStyles.text_black_title}`}>
            {current_market_name()}
          </p>
        )} */}
      </div>
      <div className='gap-[2px] mt-[10px]'>
        {state?.card?.providers?.length ? (
          state?.card?.providers?.map((item, index) => {
            return (
              <div key={item.default_uuid}>
                <SwipeConfig
                  key={index}
                  index={index}
                  selectedIndex={selected}
                  setSelectedIndex={setSelected}
                  RightComponents={settingsRight(item)}
                  // LeftComponents={settingsLeft(item)}
                  className='mb-[2px]'
                >
                  <ListItem
                    title={item?.name || item?.location_name}
                    subtitle={item?.type}
                    star_rating={item?.star_rating}
                    content={item?.about}
                    image={item?.photo || ''}
                  />
                </SwipeConfig>
              </div>
            )
          })
        ) : (
          <div className='px-3'>
            Your {current_market_name()} Guys will go here.
          </div>
        )}
      </div>
    </div>
  )
}

export default CardServices
