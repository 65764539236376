import Wrapper from '../../components/layout/Wrapper'
import { AiCard } from '../../components/pages/AiSlots'
import CardServices from '../../components/pages/VBCDetails/CardServices'
import SubNav from '../../components/SubNav'
import { useAi } from '../../contexts/AiContext'
import { useReceiver } from '../../contexts/ReceiverContext'
import PublicWrapper from './PublicWrapper'
const PageCardServices = () => {
  const { receiver, saveReceiver } = useReceiver()
  return (
    <PublicWrapper>
      <Wrapper>
        <SubNav
          showConversations={false}
          showTours={false}
          logo_url={'/card'}
        />
        <CardServices state={receiver} setState={saveReceiver} />
      </Wrapper>
    </PublicWrapper>
  )
}

export default PageCardServices
