import { useEffect, useState, useRef } from 'react'
import { MdAdsClick } from 'react-icons/md'
import { Swiper, SwiperSlide } from 'swiper/react'
import Modal from '../../Modal'
import ListItem from '../../ui/ListItem'

const ListingPhotos = ({
  state,
  setState,
  page,
  primary_photo,
  showHomeModal,
  setShowHomeModal,
}) => {
  const [swiper, setSwiper] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [listPhotos, setListPhotos] = useState({})
  const [photos, setPhotos] = useState([])
  const photo_focus_ref = useRef(null)

  useEffect(() => {
    const get_photos = () => {
      setPhotos(page?.photos)
    }
    const focus_photo = () => {
      if (photo_focus_ref?.current) {
        photo_focus_ref.current.scrollIntoView({
          block: 'center',
          inline: 'start',
        })
      }
    }
    get_photos()
    focus_photo()
    return () => {
      focus_photo()
      return get_photos()
    }
  }, [listPhotos?.view_photo, page?.photos])
  return (
    <div className='bg-gray-100'>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        onSlideChange={(e) => {
          // setReload((prev) => (prev || 0) + 1)
        }}
        onSwiper={(swiper) => {
          try {
            const index = 0
            if (index > 0) {
              swiper.slideTo(index === -1 ? 0 : index, 0)
              return
            }
            if (index === 0 || index === -1) {
              swiper.slideTo(1, 900)
            }
            setTimeout(() => {
              if (index === 0 || index === -1) {
                swiper.slideTo(0, 900)
              }
              swiper.slideTo(index === -1 ? 0 : index, 0)
              setSwiper(swiper)
            }, 175)
          } catch (error) {
            console.log(error)
          }
        }}
      >
        {photos?.length
          ? (photos || [])?.map((item, index) => (
              <div key={item?.id}>
                <SwiperSlide key={index}>
                  <div className='flex flex-col cursor-pointer'>
                    <div
                      className='flex relative'
                      onClick={() => {
                        if (setShowHomeModal) {
                          setShowHomeModal(true)
                        } else {
                          setShowModal(true)
                        }
                        setListPhotos((prev) => ({
                          ...prev,
                          view_photo: item?.uri_2048 || item?.media_url,
                        }))
                      }}
                    >
                      <div className='absolute z-20 right-0 mr-[0px] '>
                        {<MdAdsClick size={16} />}
                      </div>
                      <img
                        className='z-1 object-cover object-top h-[250px]'
                        src={item?.uri_640 || item?.media_url}
                      />
                    </div>
                  </div>
                </SwiperSlide>
              </div>
            ))
          : ''}
      </Swiper>
      <div>
        {showHomeModal || showModal ? (
          <Modal
            width={'w-[500px]'}
            height={'h-[590px]'}
            side_padding={'px-0'}
            modalName={'home_photo_full_view'}
            showModal={setShowHomeModal ? showHomeModal : showModal}
            setShowModal={setShowHomeModal ? setShowHomeModal : setShowModal}
          >
            <div className='flex flex-col cursor-pointer'>
              {photos?.map((item) => {
                return (
                  <img
                    key={item?.uri_2048 || item?.media_url}
                    ref={
                      (item?.uri_2048 || item?.media_url) ===
                      listPhotos?.view_photo
                        ? photo_focus_ref
                        : null
                    }
                    className='object-cover'
                    src={item?.uri_2048 || item?.media_url}
                  />
                )
              })}
            </div>
          </Modal>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default ListingPhotos
