import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import PropertyItem from '../../ui/PropertyItem'
import home_placeholder from '../../../assets/images/Home_stock.jpeg'
import ListItem from '../../ui/ListItem'
import { GrNext, GrPrevious } from 'react-icons/gr'
import uiStyles from '../../ui/uiStyles'
import { useAi } from '../../../contexts/AiContext'
import { useNavigate } from 'react-router-dom'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { useProfile } from '../../../contexts/UserContext'
import images_homes from '../../../assets/images/home'
import { content_types } from '../../../data/data'
import { useDistribute } from '../../../contexts/DistributeContext'
import { capitalize } from '../../../helpers'
import ch_images from '../../../assets/images/conversations'

export const ListSlider = ({
  state,
  setState,
  items,
  setLoading,
  weekender = false,
  show_filter = false,
  filter_strategy = {},
  handler = async () => {},
}) => {
  const { axiomFetch } = useAxiomFetch()
  const [swiper, setSwiper] = useState({})
  const [reload, setReload] = useState(0)
  const { ai, saveAi } = useAi()
  const { distribute, saveDistribute } = useDistribute()
  const { profile } = useProfile()
  const navigate = useNavigate()

  const list_item = (item) => {
    return (
      <ListItem
        click_me={true}
        title={item?.title}
        subtitle={item?.subtitle}
        show_swipe_btns={false}
        swiper={swiper}
        content={item?.content}
        sub_content={item?.sub_content || ''}
        content_link={item?.content_link || ''}
        content_link_name={item?.content_link_name || ''}
        image={item?.image || ch_images.person}
        select_on_image={true}
        select={async () => {
          await handler(item)
        }}
        show_filter={show_filter}
        filter_list={items}
        filter_strategy={filter_strategy}
        current_item={items?.[0]}
      />
    )
  }
  return (
    <>
      {items?.length > 1 ? (
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          onSlideChange={(e) => {
            setReload((prev) => (prev || 0) + 1)
          }}
          onSwiper={(swiper) => {
            try {
              const index = 0
              if (index > 0) {
                swiper.slideTo(index === -1 ? 0 : index, 0)
                return
              }
              if (index === 0 || index === -1) {
                swiper.slideTo(1, 900)
              }
              setTimeout(() => {
                if (index === 0 || index === -1) {
                  swiper.slideTo(0, 900)
                }
                swiper.slideTo(index === -1 ? 0 : index, 0)
                setSwiper(swiper)
              }, 175)
            } catch (error) {
              console.log(error)
            }
          }}
        >
          {items?.length
            ? (items || [])?.map((item, index) => (
                <div key={index}>
                  <SwiperSlide key={index}>
                    <div className='flex flex-col'>{list_item(item)}</div>
                  </SwiperSlide>
                </div>
              ))
            : ''}
        </Swiper>
      ) : (
        <>{items?.map((item) => list_item(item))}</>
      )}
    </>
  )
}
