import React, { useCallback, useEffect, useRef, useState } from 'react'

// import SubNav from '../SubNav';
// import TitleIcon from '../ui/TitleIcon';
import ListItem from '../../ui/ListItem'
import SwipeConfig from '../../ui/SwipeConfig'
// import Wrapper from '../layout/Wrapper';
import { useAuth0 } from '@auth0/auth0-react'
import { useProfile } from '../../../contexts/UserContext'
import {
  ServiceMaterialApprove,
  ServiceMaterialStatus,
} from '../../../services/ServiceMarketing'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import UpdateRequest from '../../UpdateRequest'
import ApproveRequest from './ApproveRequest'
import Modal from '../../Modal'
import SmallLoading from '../../ui/SmallLoading'
import axiom_1 from '../../../assets/images/AXIOM_1.png'
import { PayCard } from '../Billing/PayCard'
import { PaySuccessful } from '../Billing/PaySuccessful'
import { PayFailure } from '../Billing/PayFailure'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { getDateString } from '../../../helpers'

export default function MarketingMaterialApprove({
  selection = false,
  preview = false,
}) {
  const { axiomFetch } = useAxiomFetch()
  const [selected, setSelected] = useState(1)
  const [toggle, setToggle] = useState(0)
  let prevToggle = 0
  let lastToggle = 0
  const { getAccessTokenSilently } = useAuth0()
  const { profile } = useProfile()
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  // const [, setNeedsUpdate] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const [payError, setPayError] = useState('')
  const [currentOrder, setCurrentOrder] = useState({})
  const [refresh, setRefresh] = useState(0)
  const onClose = () => {
    setShowModal(null)
  }

  useEffect(() => {
    const controller = new AbortController()
    const get_data = async () => {
      setIsLoading(true)
      const all_data = await axiomFetch(
        `/marketing/materials/status/7`,
        'GET',
        {},
        {},
        false,
        controller.signal
      )
      setData(all_data)
      if (!controller?.signal.aborted) {
        setIsLoading(false)
      }
    }
    get_data()
    return () => {
      controller?.abort()
    }
  }, [refresh])

  const modals = {
    update: (
      <UpdateRequest
        // listing={selectListing}
        currentOrder={currentOrder}
        setCurrentOrder={setCurrentOrder}
        onClose={onClose}
      />
    ),
    approve: (
      <ApproveRequest
        currentOrder={currentOrder}
        setCurrentOrder={setCurrentOrder}
        setModalType={setModalType}
        setShowModal={setShowModal}
      />
    ),
    pay: (
      <PayCard
        setModalType={setModalType}
        currentOrder={currentOrder}
        setCurrentOrder={setCurrentOrder}
        setShowModal={setShowModal}
        setPayError={setPayError}
        setRefreshMaterials={setRefresh}
      />
    ),
    pay_success: (
      <PaySuccessful
        setModalType={setModalType}
        currentOrder={currentOrder}
        setCurrentOrder={setCurrentOrder}
        setShowModal={setShowModal}
      />
    ),
    pay_failure: (
      <PayFailure
        setModalType={setModalType}
        currentOrder={currentOrder}
        setCurrentOrder={setCurrentOrder}
        setShowModal={setShowModal}
        payError={payError}
      />
    ),
  }

  const rightConfig = (item) => () =>
    (
      <span
        className='text-md cursor-pointer'
        onClick={async () => {
          setCurrentOrder(item)
          setModalType('approve')
          setShowModal(true)
          // mutation.mutate(item)
        }}
      >
        Approve
      </span>
    )
  const leftConfig = (item) => () =>
    (
      <button
        className='text-md cursor-pointer'
        onClick={() => {
          //change it back to 5
          setCurrentOrder(item)
          setModalType('update')
          setShowModal(true)
        }}
      >
        Update
      </button>
    )

  const htmlListing =
    data && !data?.length ? (
      <div className='p-5'>
        Currently no requests for approval yet. Check back soon!
      </div>
    ) : (
      data?.map((item, index) => {
        // if (index < 10) {
        return (
          <SwipeConfig
            key={index}
            index={index}
            selectedIndex={selected}
            setSelectedIndex={setSelected}
            RightComponents={rightConfig(item)}
            LeftComponents={leftConfig(item)}
          >
            <ListItem
              key={index}
              toggle={toggle}
              index={index + 1}
              setToggle={setToggle}
              image={item.IMAGE_LINK || item?.NEW_PDF_URL || axiom_1}
              // {DUMMYTHUMBS[Math.floor(Math.random() * 3)]}
              title={item?.PRODUCT_REQ || 'Instant Design'}
              //content={item.REQ_DESC}
              orderDate={getDateString(item.REQ_DT)}
              quantity={item.REQ_QUANTITY}
              job_id={item.REQ_ID}
              status_text={item?.REQ_STATUS_TEXT || 'Waiting For Approval'}
              link={false}
              preview={preview}
            />
          </SwipeConfig>
        )
        //}
      })
    )
  if (isLoading) {
    return (
      <div>
        <SmallLoading />{' '}
        {showModal && (
          <Modal
            modalName={`marketing_material_update`}
            height={
              ['pay_success', 'pay_failure'].includes(modalType)
                ? 'h-[406px]'
                : 'h-[580px]'
            }
            showModal={showModal}
            setShowModal={setShowModal}
          >
            {modals[modalType]}
          </Modal>
        )}
      </div>
    )
  }
  return (
    // <Wrapper>
    <div
      onClick={() => {
        // when clicking on wrapper need to have it actually close on first try. problem is when the picture is clicked wrapper gets clicked
        lastToggle = toggle
        // eslint-disable-next-line
        if ((lastToggle !== toggle || prevToggle == toggle) && toggle > 0) {
          setToggle(0)
          prevToggle = toggle
        }

        prevToggle = toggle
      }}
    >
      {/* <div className="pt-2 pb-4 px-4">
        <PageSubMenu selected={INNERMENU.ApproveMM} />
      </div> */}
      <div>
        <div className='flex flex-col gap-[2px]'>{htmlListing}</div>
      </div>
      {/* </Wrapper> */}
      {showModal && (
        <Modal
          modalName={modalType}
          height={
            ['pay_success', 'pay_failure'].includes(modalType)
              ? 'h-[406px]'
              : 'h-[580px]'
          }
          showModal={showModal}
          setShowModal={setShowModal}
        >
          {modals[modalType]}
        </Modal>
      )}
    </div>
  )
}
