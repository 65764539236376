import React from 'react'
import { useProfile } from '../../../contexts/UserContext'
import { months_abbr } from '../../../helpers'
import UserThumb from '../../../assets/images/profile-silhouette.svg'

export const MarketingMaterialProfile = () => {
  const { profile } = useProfile()
  return (
    <div className='flex mb-3 items-start'>
      <div className='w-[40px] h-[40px] mt-1'>
        <img
          src={profile?.PHOTO_LINK || profile?.account?.photo_url || UserThumb}
          alt='profile'
          className='w-full h-full rounded-full object-cover object-top'
        />
      </div>
      <div className='flex-1 pl-3 overflow-x-scroll'>
        <h3 className='text-base text-primary '>{profile?.CONT_EMAIL}</h3>
        {/* <p className='text-[13px] text-gray-500'>to adamd@driggstitle.com</p> */}
      </div>
      <div className=' text-sm w-[50px] ml-[8px] mt-[3px] text-gray-500 overflow-hidden overflow-ellipsis whitespace-nowrap'>
        {`${months_abbr[new Date().getMonth()]} ${new Date().getDate()}`}
      </div>
    </div>
  )
}
