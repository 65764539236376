import React from 'react'
import { set_storage } from '../../../helpers'
import AddOnTour from './AddOnTour'
import EO_Focus from './Eo_Focus'
import FinishTour from './FinishTour'
import Listing from './Listing'
import Products from './Products'
import StartTour from './StartTour'

const Tour = ({ state, setState }) => {
  const pages = {
    page_1: <StartTour state={state} setState={setState} />,
    page_2: <StartTour state={state} setState={setState} />,
    page_3: <EO_Focus state={state} setState={setState} />,
    page_4: <Products state={state} setState={setState} />,
    page_5: <Listing state={state} setState={setState} />,
    page_6: <FinishTour state={state} setState={setState} />,
  }
  let html_state = () => {
    if (!state?.tour_state?.current_page) {
      return pages.page_1
    } else {
      if (pages?.[`page_${state?.tour_state?.current_page}`]) {
        return pages?.[`page_${state?.tour_state?.current_page}`]
      } else {
        setState((prev) => {
          const new_tour_state = {
            ...prev?.tour_state,
            current_page: 1,
          }
          set_storage('listing_tours_state', new_tour_state)
          return {
            ...prev,
            tour_state: new_tour_state,
          }
        })
      }
    }
  }
  return html_state()
}

export default Tour
