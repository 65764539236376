import { useEffect, useState, lazy } from 'react'
import { useCalc } from '../../contexts/CalcContext'
import {
  calculate_apr,
  calculate_monthly_payment,
  capitalize,
  find_apr_gen,
  format_currency,
  validate_number,
} from '../../helpers'
import uiStyles from '../ui/uiStyles'
import CalcAmortizationGraph from './CalcAmortizationGraph'

const CalcBuyDetails = ({ implementation_type = 'buy' }) => {
  const {
    calc,
    saveCalc,
    get_nums,
    get_fees_total,
    get_origination_fee,
    get_pmi,
    get_prepaids,
    get_pmi_and_years,
  } = useCalc()
  const [data, setData] = useState({})

  useEffect(() => {
    const get_data = () => {
      const nums = get_nums()

      const pmi_data = get_pmi_and_years(
        nums?.term_num * 12,
        nums?.term_num,
        nums?.amortization_data?.schedule,
        nums?.home_price_num
      )
      const orig_data = [
        {
          key: calc?.buy?.values?.loan_type?.toUpperCase() || 'conventional',
          values: [
            {
              key: 'Home Price',
              value: `$${format_currency(nums?.home_price_num, true, 2)}`,
            },
            {
              key: `Down - ${calc?.buy?.values?.down_payment}%`,
              value: `$${format_currency(nums?.down_payment_num, true, 2)}`,
            },
            {
              key: `Total Loan at - ${calc?.buy?.values?.interest_rate}%`,
              value: `$${format_currency(nums?.principal_num, true, 2)}`,
            },
            // {
            //   key: `Upfront MIP`,
            //   value: `$${format_currency(
            //     calc?.buy?.loan_compare?.loan_1?.mip_base,
            //     false,
            //     2
            //   )}`,
            // },
            // {
            //   key: `MIP total amount`,
            //   value: `$${format_currency(
            //     calc?.buy?.loan_compare?.loan_1?.mip_term_total,
            //     false,
            //     2
            //   )}`,
            // },
            {
              key: `APR`,
              value: `${calc?.buy?.loan_compare?.loan_1?.apr}%`,
            },
          ],
        },
        {
          key: `${capitalize(calc?.buy?.loan_term || '30')} Year Totals`,
          values: [
            {
              key: 'Principal',
              value: `$${format_currency(
                nums?.amortization_data?.principal,
                true,
                2
              )}`,
            },
            {
              key: `Interest`,
              value: `$${format_currency(
                nums?.amortization_data?.total_interest,
                true,
                2
              )}`,
            },
            {
              key: `Total Payments`,
              value: `$${format_currency(
                nums?.amortization_data?.total_payments,
                true,
                2
              )}`,
            },
          ],
        },
      ]
      setData((prev) => ({ ...prev, values: orig_data, nums }))
    }
    get_data()
    return () => get_data()
  }, [
    calc?.options?.view_type,
    calc?.buy?.values,
    calc?.buy?.loan_compare?.loan_1?.apr,
  ])

  return (
    <div>
      <div className='px-3'>
        {(data?.values || [])?.map((item) => {
          return (
            <div key={item?.key}>
              <div className={`${uiStyles.line_3px} mt-[0px]`}></div>
              <div className='flex justify-between items-center my-[5px] text-black font-bold'>
                <p className=''>{item?.key}</p>
              </div>
              <div className={`${uiStyles.line_3px} mb-[0px]`}></div>
              {item?.values?.map((sub_item, index) => {
                return (
                  <div key={sub_item?.key}>
                    {<div className={`mt-[0px]`}></div>}
                    <div className='flex justify-between items-center px-3 my-[5px] text-black'>
                      <p className=''>{sub_item?.key}</p>
                      <p className=''>{sub_item?.value}</p>
                    </div>
                    <div className={`${uiStyles.line} mb-[0px]`}></div>
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
      {calc?.options?.view_type === 'loan_details' ||
      implementation_type === 'loan_details' ? (
        <CalcAmortizationGraph
          principal={data?.nums?.principal_num}
          interest_rate={data?.nums?.interest_rate_num}
          loan_term={data?.nums?.term_num}
          show_extra_payment={true}
          show_accordian={true}
          original_nums={data?.nums}
        />
      ) : (
        ''
      )}
    </div>
  )
}

export default CalcBuyDetails
