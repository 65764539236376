import { useState, useRef, useEffect, lazy } from 'react'
import ApexChart from 'react-apexcharts'
import { useCalc } from '../../contexts/CalcContext'
import {
  array_nums,
  calculate_amortization,
  calculate_amortization_totals,
  calculate_monthly_payment,
  calculate_principal,
  check_variance,
  convert_percent,
  find_closest_ge,
  format_currency,
  format_input,
  format_number,
  get_storage,
  get_uuid,
  get_year_month_string,
  pick_keys,
  remove_keys_by_suffix,
  show_decimal,
  validate_number,
} from '../../helpers'
import {
  GoogleMap,
  Autocomplete,
  useJsApiLoader,
  CircleF,
  MarkerF,
  InfoBoxF,
  InfoWindowF,
} from '@react-google-maps/api'
import { libraries } from '../../components/pages/home/utils'
import LabelInput from '../form/LabelInput'
import { Schedule } from '../form/Schedule'
import Select from '../form/Select'
import Modal from '../Modal'
import uiStyles from '../ui/uiStyles'
import CalcPaymentChange from './CalcPaymentChange'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import { SmallLoadingRel } from '../../components/ui/SmallLoadingRel'
import CalcOptions from './CalcOptions'
import ButtonsRow from '../ui/ButtonsRow'
import { SwiperSlide, Swiper } from 'swiper/react'
import CalcLoanCompare from './CalcLoanCompare'
import { MdOutlineIosShare } from 'react-icons/md'
import { FaCalculator } from 'react-icons/fa'
import { useMktReqs } from '../../contexts/MktReqsContext'
import CalcShare from './CalcShare'
import structuredClone from '@ungap/structured-clone'
import CalcBuyDetails from './CalcBuyDetails'
import CalcGrossIncome from './CalcGrossIncome'
import CalcBuyLineChart from './CalcBuyLineChart'
import CalcSell from './CalcSell'
import LabelSlider from '../form/LabelSlider'
const CalcBuy = ({ implementation_type = '' }) => {
  const {
    calc,
    saveCalc,
    get_nums,
    get_pmi,
    get_pmi_from_loan,
    get_ltv,
    remove_mip_base,
    get_mip_base,
    get_yearly_mip,
    get_loan_type,
    swiper_slide,
    get_calc_type_id,
    get_title_fee_total,
    get_origination_fee,
    get_principal,
    get_prepaids,
    get_fees_total,
    colors,
    get_va_funding_fee,
    find_county,
    loans,
    get_mi,
    calc_rent,
    get_pmi_and_years,
    get_tax_bracket,
  } = useCalc()
  const { mktReqs, saveMktReqs } = useMktReqs()
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAdJBLTyoh5xMQujUK488i-am9syWuiG6A',
    libraries: libraries,
  })
  const { axiomFetch } = useAxiomFetch()
  const [error, setError] = useState({})
  const [hitClose, setHitclose] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const affordTimeoutRef = useRef()
  const affordTimeoutRefSave = useRef()
  const labels_html = (labels, values, margin, colors) => {
    return (
      <div className={`${margin}`}>
        {labels?.map((item, index) => {
          return (
            <div key={item} className='flex items-center gap-[10px] text-xs'>
              <div
                className={`w-[10px] h-[10px] rounded-full`}
                style={{
                  backgroundColor: colors[index],
                }}
              ></div>
              {item} - $
              {format_currency(
                values[index],
                false,
                2,
                null,
                show_decimal(values?.[index]) ? 2 : 0
              )}
            </div>
          )
        })}
      </div>
    )
  }
  const closingRef = useRef(0)

  const modals = {
    options: (
      <CalcOptions
        showModal={showModal}
        setShowModal={setShowModal}
        calculator_type='buy'
      />
    ),
    gross_income: (
      <CalcGrossIncome
        showModal={showModal}
        setShowModal={setShowModal}
        calculator_type='buy'
      />
    ),
  }

  const update_input = (options) => {
    let { name, value } = format_input(options)
    let type = options?.type || calc?.buy?.types[`${name}`] || ''
    saveCalc((prev) => ({
      ...prev,
      buy: { ...prev?.buy, hide_details: true, run_new_down: false },
    }))
    if (name === 'loan_type') {
      const { home_price_num } = get_nums()
      const defaults = calc?.buy?.defaults?.[value?.toLowerCase()]
      const new_down_payment =
        calc?.buy?.types?.down_payment === '%'
          ? defaults?.down_payment
          : home_price_num * defaults?.down_payment

      return saveCalc((prev) => {
        let all_values = {
          ...prev?.buy?.values,
          [name]: value,
          down_payment: format_currency(
            new_down_payment || prev?.buy?.down_payment,
            false,
            3
          ),
          interest_rate:
            defaults?.interest_rate || prev?.buy?.values?.interest_rate,
          insurance_rate:
            defaults?.insurance_rate || prev?.buy?.values?.insurance_rate,
          loan_term: defaults?.loan_term || prev?.buy?.values?.loan_term,
          county: defaults?.county || prev?.buy?.values?.county,
        }
        let all_types = {
          ...prev?.buy?.types,
          [name]: type,
          interest_rate: '%',
          insurance_rate: '%',
        }
        let all_data = {
          values: all_values,
          types: all_types,
          alter_pmi: 0,
          is_mip: defaults?.is_mip,
          is_pmi: defaults?.is_pmi,
        }
        const loan_compare_values = []
        return {
          ...prev,
          buy: {
            ...prev?.buy,
            defaults: {
              ...prev?.buy?.defaults,
              [name]: value,
            },
            ...all_data,
          },
          loan_2: !calc?.loan_2?.data_options?.loan_edited
            ? { ...prev?.loan_2, ...all_data }
            : { ...prev?.loan_2 },
          loan_3: !calc?.loan_3?.data_options?.loan_edited
            ? { ...prev?.loan_3, ...all_data }
            : { ...prev?.loan_3 },
        }
      })
    }
    if (name === 'county') {
      const defaults = calc?.buy?.defaults?.[value?.toLowerCase()]
      return saveCalc((prev) => {
        const new_county = find_county(value)
        const orig_county = find_county(prev?.buy?.values?.county)
        let all_values = {
          ...prev?.buy?.values,
          [name]: value,
        }

        let all_types = { ...prev?.buy?.types, [name]: type }
        if (
          validate_number(orig_county?.def_prop_tax_rate) ===
          validate_number(prev?.buy?.values?.taxes)
        ) {
          all_values.taxes = new_county?.def_prop_tax_rate
          all_types.taxes = '%'
        }
        if (
          validate_number(orig_county?.def_ins_rate) ===
          validate_number(prev?.buy?.values?.insurance_rate)
        ) {
          all_values.insurance_rate = new_county?.def_ins_rate
          all_types.insurance_rate = '%'
        }
        let all_data = {
          values: all_values,
          types: all_types,
          // alter_pmi: 0,
          // is_mip: defaults?.is_mip,
          // is_pmi: defaults?.is_pmi,
        }
        const loan_compare_values = []
        return {
          ...prev,
          buy: {
            ...prev?.buy,
            defaults: {
              ...prev?.buy?.defaults,
              [name]: value,
            },
            ...all_data,
          },
          loan_2: !calc?.loan_2?.data_options?.loan_edited
            ? { ...prev?.loan_2, ...all_data }
            : { ...prev?.loan_2 },
          loan_3: !calc?.loan_3?.data_options?.loan_edited
            ? { ...prev?.loan_3, ...all_data }
            : { ...prev?.loan_3 },
        }
      })
    }

    if (type === '%' && validate_number(value) > 100) {
      value = 100
    }
    if (
      name === 'max_fha' ||
      name === 'monthly_payment' ||
      name === 'bring_to_close'
    ) {
      return saveCalc((prev) => {
        return {
          ...prev,
          buy: {
            ...prev?.buy,
            [name]: value,
            [`${name}_type`]: type,
            [`desired_${name}`]: value,
          },
        }
      })
    }
    saveCalc((prev) => {
      let all_values = { ...prev?.buy?.values, [name]: value }
      let all_types = { ...prev?.buy?.types, [name]: type }
      let all_data = { values: all_values, types: all_types }
      const loan_compare_values = []
      const key_picks_2 = ['down_payment']
      const key_picks_3 = [
        'interest_rate',
        // 'loan_type',
        // 'down_payment',
        'loan_term',
      ]
      const result = {
        ...prev,
        buy: {
          ...prev?.buy,
          ...all_data,
        },
        loan_2: !calc?.loan_2?.data_options?.loan_edited
          ? {
              ...prev?.loan_2,
              values: {
                ...prev?.loan_2?.values,
                ...pick_keys(all_values, key_picks_2),
              },
              types: {
                ...prev?.loan_2?.types,
                ...pick_keys(all_types, key_picks_2),
              },
            }
          : { ...prev?.loan_2 },
        loan_3: !calc?.loan_3?.data_options?.loan_edited
          ? {
              ...prev?.loan_3,
              values: {
                ...prev?.loan_3?.values,
                ...pick_keys(all_values, key_picks_3),
              },
              types: {
                ...prev?.loan_3?.types,
                ...pick_keys(all_types, key_picks_3),
              },
            }
          : { ...prev?.loan_3 },
      }
      return result
    })
  }
  const updateInput = (e, type, decimal = 2) => {
    let { name, value } = e.target
    let start, end
    if (!type) {
      type = calc?.buy[`${name}_type`]
    }

    setTimeout(() => {
      if (
        !isNaN(Number(value?.toString()?.replace(/[$€£¥,%]/g, ''))) &&
        e?.target?.selectionStart &&
        start &&
        end
      ) {
        const orig_comma_length = (calc?.buy?.[name]?.match(/,/g) || [])?.length
        const new_comma_length = (value?.match(/,/g) || [])?.length
        if (
          (new_comma_length > orig_comma_length && start > 2) ||
          !calc?.buy?.[name] ||
          (value?.startsWith('0.') && calc?.buy?.[name]?.length === 1)
        ) {
          start += 1
          end += 1
        } else if (new_comma_length < orig_comma_length && start > 2) {
          start -= 1
          end -= 1
        }
        // if (
        //   orig_comma_length >= new_comma_length &&
        //   orig_comma_length !== 0 &&
        //   new_comma_length !== 0 &&
        //   start > 2
        // ) {
        //   start -= 1
        //   end -= 1
        //   if (start > 6) {
        //     start -= 1
        //     end -= 1
        //   }
        // }
        e.target.selectionStart = start
        e.target.selectionEnd = end
      }
    }, 0)

    if (
      name === 'home_price' ||
      name === 'down_payment' ||
      (name === 'monthly_payment' && value) ||
      name === 'interest_rate' ||
      name === 'misc' ||
      name === 'insurance_rate' ||
      name === 'hoa' ||
      name === 'taxes' ||
      name === 'loan_term' ||
      name === 'bring_to_close'
    ) {
      value = value?.toString()?.replace(/[^$€£¥0-9,.%]/g, '')
    }

    if (
      !isNaN(Number(value?.toString()?.replace(/[$€£¥,%]/g, ''))) &&
      e?.target?.selectionStart
    ) {
      value = format_currency(value, false, decimal, e.target)
      start = value?.start
      end = value?.end
      value = value?.item
    }
    if (name === 'loan_type') {
      const { home_price_num } = get_nums()
      const defaults = calc?.buy?.defaults?.[value?.toLowerCase()]
      const new_down_payment =
        calc?.buy?.types?.down_payment === '%'
          ? defaults?.down_payment
          : home_price_num * defaults?.down_payment

      return saveCalc((prev) => {
        return {
          ...prev,
          buy: {
            ...prev?.buy,
            [name]: value,
            [`${name}_type`]: type,
            down_payment: format_currency(
              new_down_payment || prev?.buy?.down_payment,
              false,
              3
            ),
            alter_pmi: 0,
            interest_rate: defaults?.interest_rate || prev?.buy?.interest_rate,
            is_mip: defaults?.is_mip,
            is_pmi: defaults?.is_pmi,
            insurance_rate:
              defaults?.insurance_rate || prev?.buy?.insurance_rate,
            loan_term: defaults?.loan_term || prev?.buy?.loan_term,
            county: defaults?.county || prev?.buy?.county,
          },
        }
      })
    }

    if (type === '%' && validate_number(value) > 100) {
      value = 100
    }
    saveCalc((prev) => {
      return {
        ...prev,
        buy: { ...prev?.buy, [name]: value, [`${name}_type`]: type },
      }
    })
  }

  useEffect(() => {
    if (
      validate_number(calc?.buy?.values?.home_price) &&
      calc?.buy?.show_total_edit_type === 'monthly_payment'
    ) {
      const original_payment = Number(
        calc?.buy?.monthly_payment_series?.reduce((curr, val) => curr + val, 0)
      )
      const new_payment = Number(
        calc?.buy?.monthly_payment?.toString()?.replaceAll(/[,$]/g, '')
      )
      let {
        tax_num,
        insurance_num,
        term_num,
        home_price_num,
        hoa_num,
        interest_rate_num,
        down_payment_num,
        principal_num,
      } = get_nums('buy')
      const origi_down_payment_num =
        calc?.buy?.types?.down_payment === '%'
          ? home_price_num *
            ((Number(
              calc?.buy?.original_down?.toString()?.replace(/[,$]/g, '') || 0
            ) || 0) /
              100)
          : Number(calc?.buy?.original_down?.toString()?.replace(/[,$]/g, ''))
      let new_principal_num = home_price_num - origi_down_payment_num
      const amortization_data = calculate_amortization_totals(
        (calc?.buy?.is_mip?.toString() === 'true'
          ? new_principal_num + get_mip_base(new_principal_num)
          : new_principal_num) + get_va_funding_fee('buy', new_principal_num),
        interest_rate_num,
        term_num
      )
      const original_pmi_num =
        calc?.buy?.is_mip?.toString() === 'true'
          ? get_yearly_mip(home_price_num, new_principal_num, term_num)
          : get_pmi(1, term_num, amortization_data?.schedule, home_price_num)

      const find_rate = (pmi_num) => {
        const total_other =
          Number(tax_num / 12) +
          Number(insurance_num / 12) +
          hoa_num +
          Number(pmi_num / 12) +
          Number(calc?.buy?.alter_pmi || 0)

        const pi = original_payment - total_other
        return pi
      }

      const get_pmi_from_pi = (pi) => {
        if (calc?.buy?.is_mip?.toString() === 'true') {
          return 0
        }
        const principal = calculate_principal(pi, interest_rate_num, term_num)
        const pmi = get_pmi_from_loan(1, term_num, principal, home_price_num)
        return pmi
      }

      const get_mip_from_pi = (pi) => {
        if (calc?.buy?.is_pmi?.toString() === 'true') {
          return 0
        }
        const principal = calculate_principal(pi, interest_rate_num, term_num)
        const mip = get_yearly_mip(
          home_price_num,
          remove_mip_base(principal),
          term_num
        )
        return mip
      }
      const initial_pi = find_rate(original_pmi_num)
      const pay_diff = new_payment - original_payment
      const new_pi = initial_pi + pay_diff
      let final_pi
      let all_pi = {}
      let result = {}
      const find_actual_rate = (initial_pi, new_pi) => {
        const diff = new_pi - initial_pi
        const max_combined =
          original_payment -
          Number(tax_num / 12) -
          Number(insurance_num / 12) -
          Number(calc?.buy?.alter_pmi || 0) -
          hoa_num +
          pay_diff

        if (isNaN(diff) || isNaN(initial_pi) || isNaN(new_pi)) {
          return
        }

        for (
          let i = diff > 0 ? initial_pi : new_pi;
          i < diff > 0 ? new_pi : initial_pi;
          i++
        ) {
          if (i > 4000) {
            return
          }
          const get_combined = (pi) => {
            const pmi =
              calc?.buy?.is_mip?.toString() === 'true'
                ? get_mip_from_pi(pi, interest_rate_num, term_num)
                : get_pmi_from_pi(pi, interest_rate_num, term_num)
            const result = {
              pi,
              max_combined,
              actual_combined: pi + pmi / 12,
              pmi: pmi / 12,
            }
            all_pi[pi] = {
              ...result,
            }
            return result
          }
          const prev = get_combined(i - 1)
          const next = get_combined(i + 1)

          if (prev?.actual_combined > prev?.max_combined) {
            const value = get_combined(initial_pi)
            if (calc?.buy?.is_mip?.toString() === 'true' && value?.pmi < 1) {
              value.pi += value?.max_combined - value?.actual_combined
            } else if (
              calc?.buy?.is_mip?.toString() === 'true' &&
              value?.pmi > 1
            ) {
              value.pi += value?.max_combined - value?.actual_combined
            } else {
              value.alter_pmi = value?.max_combined - value?.actual_combined
            }
            if (value?.pmi < 1) {
              value.alter_pmi = 0
            }
            return (result = value)
          }

          if (
            prev?.actual_combined <= max_combined &&
            next?.actual_combined >= max_combined
          ) {
            const value = get_combined(i)
            if (calc?.buy?.is_mip?.toString() === 'true' && value?.pmi < 1) {
              value.pi += value?.max_combined - value?.actual_combined
            } else if (
              calc?.buy?.is_mip?.toString() === 'true' &&
              value?.pmi > 1
            ) {
              value.pi += value?.max_combined - value?.actual_combined
            } else {
              value.alter_pmi = value?.max_combined - value?.actual_combined
            }
            if (value?.pmi < 1) {
              value.alter_pmi = 0
            }
            // if (
            //   Math.floor(prev?.pmi)?.toFixed(0) ===
            //   Math.floor(next?.pmi)?.toFixed(0)
            // ) {
            //   value.pi =
            //     value?.pi + (value?.max_combined - value?.actual_combined)
            // } else {
            //   value.alter_pmi = diff
            // }
            return (result = value)
          }
        }
      }

      if (calc?.buy?.is_pmi?.toString() === 'true') {
        find_actual_rate(initial_pi, new_pi)
      } else if (calc?.buy?.is_mip?.toString() === 'true') {
        find_actual_rate(initial_pi, new_pi)
      } else {
        result.pi = new_pi
      }

      final_pi = result?.pi || new_pi

      const new_principal_start = calculate_principal(
        final_pi,
        interest_rate_num,
        term_num
      )
      const test_monthly_payment = calculate_monthly_payment(
        new_principal_start,
        interest_rate_num,
        term_num
      )

      let new_principal =
        calc?.buy?.is_mip?.toString() === 'true'
          ? remove_mip_base(new_principal_start)
          : new_principal_start

      const loan_amount =
        home_price_num +
        get_mip_base(home_price_num) +
        get_va_funding_fee('buy', home_price_num)

      const max =
        calc?.buy?.is_mip?.toString() === 'true'
          ? calculate_monthly_payment(loan_amount, interest_rate_num, term_num)
          : calculate_monthly_payment(
              home_price_num,
              interest_rate_num,
              term_num
            ) - 1

      const min = calculate_monthly_payment(
        home_price_num * 0.01,
        interest_rate_num,
        term_num
      )

      const down_payment_diff =
        home_price_num -
        origi_down_payment_num -
        new_principal +
        get_va_funding_fee('buy', new_principal)

      let new_down_payment_amt = origi_down_payment_num + down_payment_diff
      const check_monthly = calculate_amortization_totals(
        home_price_num -
          new_down_payment_amt +
          get_va_funding_fee('buy', home_price_num - new_down_payment_amt) +
          (calc?.buy?.is_mip?.toString() === 'true'
            ? get_mip_base(home_price_num - new_down_payment_amt)
            : 0),
        interest_rate_num,
        term_num
      )?.monthly_payment

      const check_principal = calculate_principal(
        check_monthly,
        interest_rate_num,
        term_num
      )
      if (get_loan_type('buy', 'values') !== 'conventional') {
        new_down_payment_amt -= new_principal_start - check_principal
      }
      let new_down_payment =
        calc?.buy?.types?.down_payment === '%'
          ? (new_down_payment_amt / home_price_num) * 100
          : new_down_payment_amt

      if (min > new_pi) {
        new_down_payment =
          calc?.buy?.types?.down_payment === '%' ? 99 : home_price_num * 0.99
      }

      saveCalc((prev) => {
        return {
          ...prev,
          buy: {
            ...prev?.buy,
            values: {
              ...prev?.buy?.values,
              down_payment:
                new_pi >= max ? 0 : format_currency(new_down_payment, false, 4),
            },
            alter_pmi: result?.alter_pmi || 0,
          },
          // loan_1: {
          //   ...prev?.loan_1,
          //   values: {
          //     ...prev?.loan_1?.values,
          //     down_payment:
          //       new_pi >= max ? 0 : format_currency(new_down_payment, false, 3),
          //   },
          //   alter_pmi: result?.alter_pmi || 0,
          // },
        }
      })
    }
  }, [calc?.buy?.show_total_edit])

  const get_rest_close = (
    calculator_type = 'buy',
    home_price,
    down_payment
  ) => {
    const { misc_num, seller_credit_num, prorated_tax_num } = get_nums()
    const principal = get_principal(calculator_type, home_price, down_payment)
    const get_fixed = (calculator_type, home_price, principal) => {
      const title_fees_total = get_title_fee_total(
        calculator_type,
        home_price,
        principal
      )
      const origination_fee = validate_number(
        get_origination_fee(calculator_type, principal)
      )
      const other_fees = get_fees_total(calculator_type, 'other_fees')

      const lender_fees = validate_number(
        get_fees_total(calculator_type, 'lender_fees')
      )
      const lender_fees_total = lender_fees + origination_fee
      return title_fees_total + lender_fees_total + other_fees
    }
    const prepaids = get_prepaids(
      calculator_type,
      null,
      null,
      principal,
      home_price
    )
    const fixed = get_fixed(calculator_type, home_price, principal)
    const misc = misc_num
    const seller_credit = seller_credit_num
    const prorated_taxes = prorated_tax_num
    return prepaids + fixed + misc + seller_credit + prorated_taxes
  }

  useEffect(() => {
    const get_new_closing = () => {
      if (
        validate_number(calc?.buy?.values?.home_price) &&
        calc?.buy?.show_total_edit_type === 'closing' &&
        hitClose
      ) {
        const {
          home_price_num,
          interest_rate_num,
          term_num,
          tax_num,
          insurance_num,
          down_payment_num,
          misc_num,
          hoa_num,
          pmi_num,
          principal_num,
          seller_credit_num,
          prorated_tax_num,
        } = get_nums()

        const total_other_close = get_rest_close(
          'buy',
          home_price_num,
          principal_num
        )

        let original_close = validate_number(
          calc?.buy?.close_series?.reduce(
            (curr, val) => curr + (!isNaN(val) ? val : 0),
            0
          )
        )
        const desired_close = validate_number(calc?.buy?.desired_bring_to_close)

        let desired_down = desired_close - total_other_close
        let original_down = original_close - total_other_close
        const max =
          calc?.buy?.types?.down_payment === '%' ? 100 : home_price_num
        const min = 0

        let tries = 0
        let net_diff = 0
        let direction = original_close < desired_close ? 1 : -1

        const max_tries = 15
        while (tries < max_tries) {
          tries += 1
          let diff = Math.abs(original_close - desired_close)
          if (original_close < desired_close) {
            net_diff = diff + 1
          } else {
            net_diff = diff - 1
          }

          net_diff = net_diff * direction
          if (tries === max_tries) {
            net_diff -= 1
          }

          original_down = original_down + net_diff

          original_close =
            original_down + get_rest_close('buy', home_price_num, original_down)

          direction = original_close < desired_close ? 1 : -1
          // original_close =
          //   original_down + get_rest_close('buy', home_price_num, original_down)
        }

        // new_down += diff
        let new_down_payment =
          calc?.buy?.types?.down_payment === '%'
            ? (original_down / home_price_num) * 100
            : original_down

        if (min > new_down_payment) {
          new_down_payment = min
        }

        if (!calc?.buy?.show_total_edit) {
          saveCalc((prev) => {
            return {
              ...prev,
              buy: {
                ...prev?.buy,
                closing_calc_count:
                  validate_number(prev?.buy?.closing_calc_count) + 1,
                values: {
                  ...prev?.buy?.values,
                  down_payment:
                    new_down_payment >= max
                      ? max
                      : format_currency(new_down_payment, false, 4),
                },
                new_down_payment:
                  new_down_payment >= max
                    ? max
                    : format_currency(new_down_payment, false, 4),
                alter_pmi: 0,
              },
            }
          })
        }
      }
    }
    if (calc?.buy?.closing_calc_count < 15) {
      get_new_closing()
    }
  }, [calc?.buy?.show_total_edit, calc?.buy?.desired_bring_to_close])

  const calc_tax_savings = (total_interest, tax_bracket) => {
    return total_interest * (tax_bracket / 100)
  }

  useEffect(() => {
    const perform_calculations = () => {
      if (calc?.buy?.calculated) {
        let instance_uuid = calc?.buy?.data_options?.instance_uuid
        let timeout
        let is_rent = validate_number(calc?.buy?.values?.current_rent)
        if (implementation_type !== 'rent_vs_buy') {
          is_rent = true
        }
        const is_home_price = validate_number(calc?.buy?.values?.home_price)
        if (!is_home_price || !is_rent) {
          if (timeout) {
            clearTimeout(timeout)
          }
          const msg =
            !is_rent && !is_home_price
              ? 'Home Price & Rent required.'
              : !is_rent
              ? 'Current Rent is required.'
              : 'Home price is required.'
          timeout = null
          setError((prev) => {
            return {
              msg,
              show_error: true,
            }
          })
          timeout = setTimeout(() => {
            setError((prev) => {
              return {
                msg,
                show_error: false,
              }
            })
          }, [3000])
          swiper_slide(false)
          return saveCalc((prev) => ({
            ...prev,
            buy: { ...prev?.buy, calculated: false },
            options: {
              ...prev?.options,
              view_type:
                implementation_type === 'rent_vs_buy' ? 'costs' : 'estimate',
            },
          }))
        }
        swiper_slide(
          ['buy', 'affordability', 'rent_vs_buy']?.includes(implementation_type)
            ? true
            : false
        )
        let {
          home_price_num,
          interest_rate_num,
          term_num,
          tax_num,
          insurance_num,
          down_payment_num,
          principal_num,
          misc_num,
          hoa_num,
          pmi_num,
          prepaids_num,
          fixed_num,
          max_fha_num,
          seller_credit_num,
          prorated_tax_num,
        } = get_nums()
        const amortize = calculate_amortization_totals(
          principal_num +
            get_va_funding_fee('buy', principal_num) +
            (calc?.buy?.is_mip?.toString() === 'true'
              ? get_mip_base(principal_num)
              : 0),
          interest_rate_num,
          term_num
        )
        let { payment, interest, principal } = amortize?.schedule?.[0]
        if (isNaN(payment)) {
          payment = 0
        }
        const buy_values = calc?.buy?.values

        const monthly_payment_disp = [
          {
            key: 'payment',
            label: 'P&I',
            value: validate_number(payment),
            color: colors[0],
          },
          {
            key: 'taxes',
            label: 'Taxes',
            value: validate_number(tax_num / 12),
            color: colors[1],
          },
          {
            key: 'insurance',
            label: 'Insurance',
            value: validate_number(insurance_num / 12),
            color: colors[2],
          },
          {
            key: 'mi',
            label: calc?.buy?.is_pmi === 'true' ? 'PMI' : 'MIP',
            value:
              validate_number(pmi_num / 12) +
              validate_number(calc?.buy?.alter_pmi),
            color: colors[3],
          },
          {
            key: 'hoa',
            label: 'HOA',
            value: validate_number(hoa_num),
            color: colors[4],
          },
        ]

        const closing_payment_disp = [
          {
            key: 'down',
            label: 'Down',
            value: validate_number(down_payment_num || 0, 2),
            color: colors[0],
          },
          {
            key: 'prepaids',
            label: 'Prepaids',
            value: validate_number(prepaids_num, 2),
            color: colors[1],
          },
          {
            key: 'fixed',
            label: 'Fixed',
            value: validate_number(fixed_num, 2),
            color: colors[2],
          },
          {
            key: 'prorated_taxes',
            label: 'Tax Owed',
            value: validate_number(prorated_tax_num),
            color: colors[3],
          },
          {
            key: 'misc',
            label: 'Misc',
            value: validate_number(misc_num),
            color: colors[4],
          },
          {
            key: 'seller_credit',
            label: 'Credit',
            value: validate_number(seller_credit_num),
            color: colors[5],
          },
        ]

        const affordability_series = [
          validate_number(principal_num),
          validate_number(down_payment_num),
        ]
        const affordability_labels = ['Loan Amount', `Down Payment`]

        let monthly_payment_series = [
          validate_number(payment),
          validate_number(tax_num / 12),
          validate_number(insurance_num / 12),
          validate_number(pmi_num / 12) + validate_number(calc?.buy?.alter_pmi),
        ]

        let monthly_payment_labels = calc?.buy?.monthly_payment_labels
          ?.filter((item) => item !== 'HOA')
          ?.filter((item) => {
            if (!(calc?.buy?.is_mip?.toString() === 'true') && item === 'MIP') {
              return false
            } else if (
              !(calc?.buy?.is_pmi?.toString() === 'true') &&
              item === 'PMI'
            ) {
              return false
            } else {
              return true
            }
          })
        let close_labels = calc?.buy?.close_labels?.filter(
          (item) => item !== 'Misc' && item !== 'Credit'
        )

        if (calc?.buy?.is_pmi?.toString() === 'true') {
          monthly_payment_labels = [
            ...monthly_payment_labels?.filter((item) => item !== 'PMI'),
            'PMI',
          ]
        } else if (calc?.buy?.is_mip?.toString() === 'true') {
          monthly_payment_labels = [
            ...monthly_payment_labels?.filter((item) => item !== 'MIP'),
            'MIP',
          ]
        }
        if (hoa_num) {
          monthly_payment_labels = [...monthly_payment_labels, 'HOA']
          monthly_payment_series = [...monthly_payment_series, hoa_num]
        }

        // monthly_payment_labels = monthly_payment_labels?.filter(
        //   (item, index) => {
        //     return validate_number(monthly_payment_series[index])
        //   }
        // )
        const monthly_colors = monthly_payment_disp
          ?.filter((item) => item?.value)
          .map((item) => item.color)
        const monthly_labels = monthly_payment_disp
          ?.filter((item) => item?.value)
          ?.map((item) => item.label)
        const monthly_series = monthly_payment_disp
          ?.filter((item) => item?.value)
          ?.map((item) => item.value)

        const closing_colors = closing_payment_disp
          ?.filter((item) => item?.value)
          .map((item) => item.color)
        const closing_labels = closing_payment_disp
          ?.filter((item) => item?.value)
          ?.map((item) => item.label)
        const closing_series = closing_payment_disp
          ?.filter((item) => item?.value)
          ?.map((item) => item.value)

        let close_series = [
          validate_number(down_payment_num || 0, 2),
          validate_number(prepaids_num, 2),
          validate_number(fixed_num, 2),
        ]
        if (misc_num) {
          close_labels = [...close_labels, 'Misc']
          close_series = [...close_series, misc_num]
        }
        if (seller_credit_num) {
          close_labels = [...close_labels, 'Credit']
          close_series = [...close_series, seller_credit_num]
        }
        if (prorated_tax_num) {
          close_labels = [...close_labels, 'Tax Owed']
          close_series = [...close_series, prorated_tax_num]
        }

        // close_labels = close_labels?.filter((item, index) => {
        //   return validate_number(close_series[index])
        // })
        if (
          implementation_type === 'affordability' &&
          !calc?.buy?.run_affordability
        ) {
          return saveCalc((prev) => ({
            ...prev,
            buy: {
              ...prev?.buy,
              values: {
                ...prev?.buy?.values,
                home_price: format_currency(home_price_num, false, 0),
                down_payment: format_currency(
                  prev?.buy?.values?.down_payment,
                  false,
                  3
                ),
              },
              monthly_payment: calc?.buy?.monthly_payment,
              bring_to_close: calc?.buy?.bring_to_close,
              run: (calc?.buy?.run || 0) + 1,
              run_affordability: true,

              monthly_chart: {
                ...prev?.buy?.monthly_chart,
                colors: monthly_colors,
              },
              closing_chart: {
                ...prev?.buy?.closing_chart,
                colors: closing_colors,
              },
              // data_options: { ...prev?.buy?.data_options, instance_uuid },
              monthly_payment_labels: monthly_labels,
              monthly_payment_disp,
              closing_payment_disp,
              monthly_payment_series: monthly_series,
              close_labels: closing_labels,
              close_series: closing_series,
              affordability_labels,
              affordability_series,
              amortize,
            },
          }))
        }

        // **rent_vs_buy
        let break_even_time = ''
        let break_mortgage_payments = 0,
          break_maintenance = 0,
          break_total_out_of_pocket = 0,
          break_principal_paid = 0,
          break_appreciation = 0,
          break_tax_savings = 0,
          break_tax_savings_month_one = 0,
          break_total_savings = 0,
          break_buy_cost_total = 0,
          break_home_payments = [],
          break_rent_series = [],
          break_buy_series = [],
          break_even_payment = 0,
          equity_labels = ['Down Payment', 'Appreciation', 'Principal']

        let break_curr_payment = calc?.buy?.rent_vs_buy?.break_curr_payment || 0
        const use_curr_payment =
          calc?.buy?.rent_vs_buy?.use_curr_payment || false
        if (implementation_type === 'rent_vs_buy') {
          const amortize_schedule = amortize?.schedule
          const current_rent = validate_number(buy_values?.current_rent)
          const bring_to_close = validate_number(calc?.buy?.bring_to_close)
          const home_appreciation = validate_number(
            buy_values?.home_appreciation
          )
          const home_maintenance = validate_number(buy_values?.home_maintenance)
          const get_home_totals = (
            home_price,
            home_maintenance,
            tax_percent = 0,
            insurance_percent = 0,
            home_appreciation,
            payments = 360
          ) => {
            let home_value = home_price
            let home_appreciation_val = 0
            let total_home_appreciation = 0
            let total_maintenance = 0
            let current_year = 0
            let total_taxes = 0
            let total_insurance = 0
            const all_payments = array_nums(payments, false, (index) => {
              if (index % 12 === 0 && index) {
                current_year++
              }
              home_appreciation_val =
                (home_value * home_appreciation) / 100 / 12
              total_home_appreciation += home_appreciation_val
              home_value = home_value + home_appreciation_val
              const maintenance = ((home_maintenance / 100) * home_price) / 12
              total_maintenance += maintenance
              const taxes = ((tax_percent / 100) * home_price) / 12
              const insurance = ((insurance_percent / 100) * home_price) / 12
              total_taxes += taxes
              total_insurance += insurance
              return {
                index,
                home_appreciation: home_appreciation_val,
                home_appreciation_total: total_home_appreciation,
                year: current_year,
                month: current_year
                  ? (index + 1) % 12 || 0
                  : (index + 1) % 12 || 12,
                maintenance: maintenance,
                total_maintenance,
                taxes,
                total_taxes,
                insurance,
                total_insurance,
              }
            })
            return {
              payments: all_payments,
              total_maintenance,
              total_insurance,
              total_taxes,
              total_home_appreciation,
            }
          }

          break_home_payments = get_home_totals(
            home_price_num,
            home_maintenance,
            convert_percent(home_price_num, tax_num, '$', 'percent'),
            convert_percent(home_price_num, insurance_num, '$', 'percent'),
            home_appreciation,
            term_num * 12
          )

          const rent_appreciation = validate_number(
            buy_values?.rent_appreciation
          )
          const rent_payments = calc_rent(
            current_rent,
            rent_appreciation || 2.5,
            term_num || 30
          )

          const pmi_payments = get_pmi_and_years(
            term_num * 12,
            term_num,
            amortize_schedule,
            home_price_num
          )

          const get_total_buy_costs = (index) => {
            const mi =
              calc?.buy?.is_pmi === 'true'
                ? validate_number(pmi_payments?.payments?.[index]?.total)
                : (get_yearly_mip(home_price_num, principal_num, term_num) /
                    12) *
                  (index + 1)

            const hoa = validate_number(hoa_num * (index + 1))

            break_mortgage_payments =
              hoa +
              mi +
              validate_number(amortize_schedule?.[index]?.total_payments) +
              validate_number(
                break_home_payments?.payments?.[index]?.total_insurance
              ) +
              validate_number(
                break_home_payments?.payments?.[index]?.total_taxes
              )

            break_maintenance = validate_number(
              break_home_payments?.payments?.[index]?.total_maintenance
            )
            break_total_out_of_pocket =
              bring_to_close + break_mortgage_payments + break_maintenance
            break_principal_paid = validate_number(
              amortize_schedule?.[index]?.total_principal
            )
            break_appreciation = validate_number(
              break_home_payments?.payments?.[index]?.home_appreciation_total
            )
            break_tax_savings = calc_tax_savings(
              validate_number(amortize_schedule?.[index]?.total_interest),
              validate_number(
                get_tax_bracket(validate_number(buy_values?.gross_income || 22))
              )
            )
            break_total_savings =
              break_principal_paid + break_appreciation + break_tax_savings
            break_buy_cost_total =
              break_total_out_of_pocket - break_total_savings

            return break_buy_cost_total
          }
          const get_break_even_time = () => {
            let done = false
            rent_payments?.payments?.forEach((item) => {
              if (done) {
                return
              }

              const total_buy = get_total_buy_costs(item?.index)
              break_rent_series.push(item?.total)
              break_buy_series.push(total_buy)
              if (item?.total <= total_buy) {
                break_curr_payment = item?.index
                break_even_payment = item?.index
                // if (amortize_schedule?.[item?.index]?.)
                break_even_time =
                  item?.index === 359
                    ? `${item?.year + 1} years`
                    : `${item?.year} ${item?.year > 1 ? 'Years' : 'Year'} - ${
                        item?.month
                      } ${item?.month > 1 ? 'Months' : 'month'}`
              }
            })
            break_tax_savings_month_one =
              calc_tax_savings(
                validate_number(amortize_schedule?.[11]?.total_interest),
                validate_number(
                  get_tax_bracket(
                    validate_number(buy_values?.gross_income || 22)
                  )
                )
              ) / 12
          }
          get_break_even_time()
        }

        let equity_series = [
          down_payment_num,
          break_appreciation,
          break_principal_paid,
        ]
        let break_total_equity = equity_series.reduce(
          (curr, val) => curr + val,
          0
        )

        // if (implementation_type === 'rent_vs_buy' && !use_curr_payment) {
        //   return saveCalc((prev) => ({
        //     ...prev,
        //     buy: {
        //       ...prev?.buy,
        //       rent_vs_buy: {
        //         ...prev?.buy?.rent_vs_buy,
        //         use_curr_payment: true,
        //         break_curr_payment,
        //       },
        //     },
        //   }))
        // }
        if (
          (implementation_type === 'affordability' &&
            calc?.buy?.run_affordability) ||
          implementation_type !== 'affordability'
        ) {
          saveCalc((prev) => ({
            ...prev,
            buy: {
              ...prev?.buy,
              // run_affordability: false,
              monthly_chart: {
                ...prev?.buy?.monthly_chart,
                colors: monthly_colors,
              },
              closing_chart: {
                ...prev?.buy?.closing_chart,
                colors: closing_colors,
              },
              rent_vs_buy: {
                ...prev?.buy?.rent_vs_buy,
                break_even_time,
                break_mortgage_payments,
                break_maintenance,
                break_total_out_of_pocket,
                break_principal_paid,
                break_appreciation,
                break_tax_savings,
                break_total_savings,
                break_total_equity,
                break_buy_cost_total,
                break_rent_series,
                break_buy_series,
                break_even_payment,
                break_curr_payment,
                use_curr_payment: false,
                equity_series,
                equity_labels,
                break_home_payments,
                break_tax_savings_month_one,
              },
              data_options: {
                ...prev?.buy?.data_options,
                instance_uuid,
              },
              monthly_payment:
                implementation_type === 'affordability'
                  ? prev?.buy?.monthly_payment
                  : format_currency(
                      Math.round(
                        monthly_payment_series?.reduce(
                          (curr, val) => curr + val,
                          0
                        )
                      )
                    ),
              bring_to_close:
                implementation_type === 'affordability'
                  ? prev?.buy?.bring_to_close
                  : format_currency(
                      Math.round(
                        close_series?.reduce(
                          (curr, val) => curr + (!isNaN(val) ? val : 0),
                          0
                        )
                      )
                    ),
              monthly_payment_labels: monthly_labels,
              monthly_payment_disp,
              closing_payment_disp,
              monthly_payment_series: monthly_series,
              close_labels: closing_labels,
              close_series: closing_series,
              affordability_labels,
              affordability_series,
              amortize,
            },
          }))
        }
      } else {
        if (implementation_type !== 'rent_vs_buy') {
          swiper_slide(false)
        }
      }
    }
    perform_calculations()
  }, [
    calc?.buy?.calculated,
    calc?.buy?.run,
    calc?.buy?.values,
    // calc?.buy?.types,
    showModal,
    calc?.buy?.defaults,
    calc?.buy?.bring_to_close,
    // calc?.buy?.alter_pmi,
    // calc?.buy?.monthly_payment,
  ])

  useEffect(() => {
    // **sell_net_sheet
    if (implementation_type === 'rent_vs_buy') {
      const curr_index = calc?.buy?.rent_vs_buy?.break_curr_payment
      const home_appreciation =
        calc?.buy?.rent_vs_buy?.break_home_payments?.payments?.[curr_index]
          ?.home_appreciation_total
      const sale_price =
        home_appreciation + validate_number(calc?.buy?.values?.home_price)

      const balance =
        calc?.buy?.amortize?.schedule?.[curr_index]?.remaining_balance
      saveCalc((prev) => ({
        ...prev,
        sell: {
          ...prev?.sell,
          data_options: {
            ...prev?.sell?.data_options,
            calculated: true,
            instance_uuid: calc?.buy?.data_options?.instance_uuid,
          },
          values: {
            ...prev?.sell?.values,
            sale_price: sale_price,
            loan_balance: balance,
          },
        },
      }))
    }
  }, [calc?.buy?.rent_vs_buy?.break_curr_payment])

  const find_home_price = (new_down_payment, new_home_price) => {
    const monthly_payment = validate_number(calc?.buy?.monthly_payment)
    const buy_values = structuredClone(calc?.buy?.values)

    if (new_down_payment || new_down_payment == 0) {
      buy_values.down_payment = new_down_payment
    }

    const buy_types = structuredClone(calc?.buy?.types)
    const original_hoa_type = buy_types?.hoa
    const interest_rate = validate_number(buy_values?.interest_rate)
    const loan_term = validate_number(buy_values?.loan_term)
    let first_guess_home_price =
      new_home_price ||
      calculate_principal(monthly_payment, interest_rate, loan_term)
    const get_val = (home_price, val, direction, type) => {
      return convert_percent(
        home_price,
        validate_number(buy_values?.[val]),
        type || buy_types?.[val],
        direction
      )
    }
    let check_price_count = 0
    const check_price = (monthly_payment, home_price) => {
      const max_fha = validate_number(calc?.buy?.max_fha)
      const extra_fha_down = home_price > max_fha ? home_price - max_fha : 0
      const data = [
        'taxes',
        'hoa',
        'insurance_rate',
        'down_payment',
        'interest_rate',
        'loan_term',
      ]?.reduce((curr, val) => {
        const divide_by_12 = ['taxes', 'insurance_rate']?.includes(val) ? 12 : 1
        if (!curr?.values) {
          curr.values = {}
        }
        if (!curr?.percents) {
          curr.percents = {}
        }
        curr.values[val] = get_val(home_price, val, 'value') / divide_by_12
        curr.percents[val] = get_val(home_price, val, 'percent')
        return curr
      }, {})

      const principal = get_principal(
        'buy',
        home_price,
        data?.values?.down_payment
      )
      const mi =
        get_mi(
          'buy',
          home_price,
          principal,
          data?.percents?.interest_rate,
          data?.percents?.loan_term
        ) / 12

      const total_other =
        data?.values?.taxes +
        data?.values?.insurance_rate +
        data?.values?.hoa +
        mi

      const new_pi = calculate_monthly_payment(
        principal,
        data?.percents?.interest_rate,
        data?.percents?.loan_term
      )

      const new_monthly_payment = new_pi + total_other

      check_price_count++
      const payment_deviation = monthly_payment / new_monthly_payment

      let new_home_price = validate_number(home_price)
      // * payment_deviation

      const hoa_price = validate_number(data?.values?.hoa)
      let use_old_method =
        !hoa_price &&
        (validate_number(data?.percents?.down_payment) > 60 ||
          monthly_payment > 3800)
      use_old_method = false
      if (use_old_method) {
        new_home_price *= payment_deviation
      }
      const insurance_rate = validate_number(data?.percents?.insurance_rate)
      const taxes_rate = validate_number(data?.percents?.taxes)
      const hoa_diff = new_home_price / hoa_price - home_price / hoa_price
      // new_home_price = new_home_price + hoa_diff

      const check_principal =
        new_home_price - (data?.percents?.down_payment * new_home_price) / 100
      const check_pi = calculate_monthly_payment(
        principal +
          get_mip_base(principal) +
          get_va_funding_fee('buy', principal),
        data?.percents?.interest_rate,
        data?.percents?.loan_term
      )
      const check_insurance = ((insurance_rate / 100) * new_home_price) / 12
      const check_taxes = ((taxes_rate / 100) * new_home_price) / 12

      const check_mi =
        get_mi(
          'buy',
          new_home_price,
          check_principal,
          data?.percents?.interest_rate,
          data?.percents?.loan_term
        ) / 12
      const check_monthly_payment =
        check_pi + check_insurance + check_taxes + check_mi + hoa_price
      let check_monthly_variance = monthly_payment / check_monthly_payment
      let pay_length = validate_number(check_monthly_payment, 0)?.toString()
        ?.length

      let pay_num = array_nums(pay_length, false)?.reduce(
        (curr, val) => 10 * (curr || 10),
        0
      )
      let variance_num = array_nums(pay_length - 1, false)?.reduce(
        (curr, val) => 0.1 * (curr || 0.1),
        0
      )

      const add_amount = use_old_method
        ? 0
        : (1 - check_monthly_variance) * pay_num * -1
      // check_monthly_variance > 1
      //   ? (1 - check_monthly_variance) * pay_num
      //   : (1 - check_monthly_variance) * pay_num * -1
      if (
        !check_variance(check_monthly_variance, 1, variance_num) &&
        check_price_count < 100
      ) {
        return check_price(monthly_payment, new_home_price + add_amount)
      }
      return { new_home_price, ...data }
    }
    const final_data = check_price(monthly_payment, first_guess_home_price)
    return final_data
  }

  useEffect(() => {
    const get_affordability = () => {
      if (!validate_number(calc?.buy?.monthly_payment)) {
        return
      }
      const monthly_payment = validate_number(calc?.buy?.monthly_payment)
      const buy_values = calc?.buy?.values
      const buy_types = calc?.buy?.types
      let timeout = affordTimeoutRef.current

      if (timeout) {
        clearTimeout(timeout)
      }
      affordTimeoutRef.current = setTimeout(() => {
        const final_data = find_home_price()
        const home_price = final_data?.new_home_price
        const down_payment = convert_percent(
          home_price,
          calc?.buy?.values?.down_payment,
          calc?.buy?.types?.down_payment
        )
        const rest_of_close = get_rest_close('buy', home_price, down_payment)
        saveCalc((prev) => ({
          ...prev,
          buy: {
            ...prev?.buy,
            bring_to_close: calc?.buy?.run_new_down
              ? prev?.buy?.bring_to_close
              : format_currency(down_payment + rest_of_close, false, 2),
            rest_of_close,
            values: {
              ...prev?.buy?.values,
              home_price: format_currency(home_price, false, 2),
            },
            run_affordability: false,
          },
        }))
      }, 200)
    }
    if (implementation_type === 'affordability') {
      get_affordability()
    }
  }, [
    calc?.buy?.monthly_payment,
    ...Object.values(pick_keys(calc?.buy?.values, ['home_price'])),
  ])

  // useEffect(() => {
  //   if (implementation_type === 'affordability') {
  //     const home_price = validate_number(calc?.buy?.values?.home_price)
  //     const down_payment = convert_percent(
  //       home_price,
  //       calc?.buy?.values?.down_payment,
  //       calc?.buy?.types?.down_payment
  //     )
  //     const original_principal = home_price - down_payment
  //     const rest_of_close = get_rest_close('buy', home_price, down_payment)
  //     const tries = 10
  //     let count = 0
  //     const find_lowest_down = (check_down, check_home_price) => {
  //       const new_down =
  //         check_down || check_down === 0
  //           ? check_down
  //           : validate_number(calc?.buy?.bring_to_close) - rest_of_close

  //       let new_down_percent =
  //         (new_down / (check_home_price || home_price)) * 100

  //       if (new_down_percent > 100) {
  //         new_down_percent = 100
  //       } else if (new_down_percent < 0) {
  //         new_down_percent = 0
  //       }

  //       const final_data = find_home_price(new_down_percent)
  //       const final_home_price = final_data?.new_home_price
  //       const final_down_payment = convert_percent(
  //         final_home_price,
  //         new_down_percent,
  //         calc?.buy?.types?.down_payment
  //       )
  //       const final_rest_close = get_rest_close(
  //         'buy',
  //         final_home_price,
  //         down_payment
  //       )

  //       const diff = rest_of_close - final_rest_close

  //       const new_check_down = ((final_down_payment - diff) / home_price) * 100
  //       if (
  //         !check_variance(rest_of_close / final_rest_close, 1, 0.0001) &&
  //         count < tries
  //       ) {
  //         count++
  //         find_lowest_down(new_check_down, check_home_price)
  //       }
  //       return { new_down_percent, final_home_price }
  //     }

  //     const result = find_lowest_down()
  //     saveCalc((prev) => ({
  //       ...prev,
  //       buy: {
  //         ...prev?.buy,
  //         run_affordability: false,
  //         run_new_down: true,
  //         values: {
  //           ...prev?.buy?.values,
  //           down_payment: format_currency(result?.new_down_percent, false, 3),
  //           home_price: format_currency(result?.final_home_price, false, 2),
  //         },
  //       },
  //     }))
  //   }
  // }, [calc?.buy?.run_bring_to_close, calc?.buy?.down_payment])

  useEffect(() => {
    const save_data = async () => {
      if (get_storage('vbc_calc') === 'Y') {
        return
      }
      const types = {}
      const values = {}

      const get_calculator_values = (calculator_type = 'buy') => {
        Object.entries(calc?.buy?.types).forEach(([key, value]) => {
          types[`${key}_is_type`] = value
        })
        Object.entries(calc?.buy?.values).forEach(([key, value]) => {
          if (['%', '$', 'm', 'a']?.includes(calc?.buy?.types?.[key])) {
            types[key] = validate_number(value)
          } else {
            types[key] = value
          }
        })
      }
      get_calculator_values('buy')
      const amortize = calc?.buy?.amortize
      const amortize_values = Object?.values(amortize?.schedule || {})
        ?.filter((value, index) => {
          if (index > 0) {
            return (index + 1) % 12 === 0
          } else {
            return true
          }
        })
        ?.slice(0, -1)
        ?.map((value, index) => {
          if (index === 0) {
            return {
              instance_uuid: calc?.buy?.data_options?.instance_uuid,
              year: index + 1,
              principal: null,
              interest: null,
              balance: validate_number(amortize?.principal, 2),
            }
          }
          return {
            instance_uuid: calc?.buy?.data_options?.instance_uuid,
            year: index + 1,
            principal: validate_number(value?.principal, 2),
            interest: validate_number(value?.interest, 2),
            balance: validate_number(value?.remaining_balance, 2),
          }
        })

      const down_values = [0.000000001, 5, 10, 15, 20]
      down_values?.forEach((val) => {
        values[`down_${Math.floor(val)}_home_price`] = validate_number(
          find_home_price(val)?.new_home_price,
          2
        )
      })
      const loan_compare = calc?.buy?.loan_compare
      const loan_1 = loan_compare?.loan_1 || {}
      const loan_2 = loan_compare?.loan_2 || {}
      const loan_3 = loan_compare?.loan_3 || {}
      const get_loan_compare_values = (type = 'loan_2') => {
        const curr_loan = type === 'loan_2' ? loan_2 : loan_3
        values[`${type}_term`] = validate_number(curr_loan?.term, 0)
        values[`${type}_down_payment`] = validate_number(
          curr_loan?.down_payment,
          2
        )
        values[`${type}_down_payment_total`] = validate_number(
          curr_loan?.down_payment_total,
          2
        )
        values[`${type}_mi_label`] = curr_loan?.mi_label
        values[`${type}_mi_total`] = validate_number(curr_loan?.pmi, 2)
        values[`${type}_all_closing_costs`] = validate_number(
          curr_loan?.all_closing_costs,
          2
        )
        values[`${type}_seller_credit`] = Math.abs(
          validate_number(curr_loan?.seller_credit, 2)
        )
        values[`${type}_prorated_tax_owed`] = Math.abs(
          validate_number(curr_loan?.prorated_taxes, 2)
        )
        values[`${type}_misc`] = validate_number(curr_loan?.misc, 2)
        values[`${type}_hoa`] = validate_number(curr_loan?.hoa, 2)
        values[`${type}_interest_rate`] = validate_number(
          curr_loan?.interest_rate,
          3
        )
        values[`${type}_loan_type`] = curr_loan?.loan_type
        values[`${type}_apr`] = validate_number(curr_loan?.apr, 3)
        values[`${type}_loan_amount`] = validate_number(curr_loan?.principal, 2)
        values[`${type}_monthly_payment`] = validate_number(
          curr_loan?.monthly_payment,
          2
        )
        values[`${type}_bring_to_close`] = validate_number(
          curr_loan?.bring_to_close,
          2
        )
        values[`${type}_total_investment`] = validate_number(
          validate_number(curr_loan?.amortization?.total_payments) +
            validate_number(curr_loan?.bring_to_close) +
            validate_number(curr_loan?.pmi_total),
          2
        )
        values[`${type}_total_saved`] = validate_number(
          curr_loan?.total_saved,
          2
        )
      }
      get_loan_compare_values('loan_2')

      values.loan_2_edited = calc?.loan_2?.data_options?.loan_edited ? 'Y' : 'N'
      get_loan_compare_values('loan_3')
      values.loan_3_edited = calc?.loan_3?.data_options?.loan_edited ? 'Y' : 'N'
      const defaults = calc?.buy?.defaults?.[get_loan_type('buy', 'values')]
      const closing_costs_all = defaults?.closing_costs
      const closing_cost_names = defaults?.closing_costs_names
      const closing_costs = remove_keys_by_suffix(
        {
          ...closing_costs_all,
        },
        '_type'
      )
      const closing_values = []
      const fee_sections = [
        'other_fees',
        'lender_fees',
        'default_fees',
        'prepaid_fees',
        'title_fees',
      ]
      const get_type = (key) => {
        let type = 'other_fees'
        for (const section of fee_sections) {
          if (
            calc?.['buy']?.defaults?.[get_loan_type('buy', 'values')]?.[
              section
            ]?.includes(key)
          ) {
            type = section
          }
        }
        return type
      }
      Object.entries(closing_costs)?.forEach(([key, value]) => {
        const data = {}
        const is_title_key = ['escrow_fee', 'title_policy_fee'].includes(key)
        data.instance_uuid = calc?.buy?.data_options?.instance_uuid
        data.fee_key = key
        data.fee_value = is_title_key
          ? loan_1?.[key]
          : validate_number(value, 2)
        data.fee_total_value = validate_number(
          loan_1?.[`${key}_total`] || loan_1?.[key] || value,
          2
        )
        data.fee_is_type = is_title_key
          ? value
          : closing_costs_all?.[`${key}_type`] || 'int'
        data.fee_name = closing_cost_names?.[key]
        data.fee_group = is_title_key ? 'title_fees' : get_type(key)
        closing_values.push(data)
      })
      const get_chart_data = (key, type = 'monthly') => {
        const data =
          type === 'monthly'
            ? calc?.buy?.monthly_payment_disp
            : calc?.buy?.closing_payment_disp
        const value = validate_number(
          data?.find((item) => item?.key === key)?.value
        )
        return value
      }
      values.down_payment_total = validate_number(loan_1?.down_payment_total)
      values.down_payment_percent = validate_number(loan_1?.down_payment)
      values.prorated_tax_owed_total = validate_number(
        loan_1?.prorated_taxes,
        2
      )
      values.title_fees_total = validate_number(loan_1?.title_fees_total, 2)
      values.lender_fees_total = validate_number(loan_1?.lender_fees_total, 2)
      values.other_fees_total = validate_number(loan_1?.other_fees_total, 2)
      if (implementation_type === 'rent_vs_buy') {
        values.buy_all_closing_costs = validate_number(
          loan_1?.all_closing_costs,
          2
        )
      } else {
        values.all_closing_costs = validate_number(loan_1?.all_closing_costs, 2)
      }

      values.loan_amount = validate_number(amortize?.principal)
      values.loan_amount_is_type = '$'
      values.apr = validate_number(loan_1?.apr)
      values.apr_is_type = '$'
      values.total_interest = validate_number(amortize?.total_interest, 2)
      values.total_interest_is_type = '$'
      values.total_payments = validate_number(amortize?.total_payments, 2)
      values.total_payments_is_type = '$'
      values.monthly_payment = validate_number(calc?.buy?.monthly_payment)
      values.monthly_payment_is_type = calc?.buy?.monthly_payment_type || '$'
      values.bring_to_close = validate_number(calc?.buy?.bring_to_close)
      values.bring_to_close_is_type = calc?.buy?.bring_to_close_type || '$'
      values.prepaids = validate_number(get_chart_data('prepaids', 'closing'))
      values.prepaids_is_type = '$'
      values.fixed = validate_number(get_chart_data('fixed', 'closing'))
      values.fixed_is_type = '$'
      const monthly_series = calc?.buy?.monthly_payment_series
      values.principal_interest = validate_number(
        get_chart_data('payment', 'monthly')
      )
      values.principal_interest_is_type = '$'
      values.monthly_taxes = validate_number(get_chart_data('taxes', 'monthly'))
      values.monthly_taxes_is_type = '$'
      values.monthly_insurance = validate_number(
        get_chart_data('insurance', 'monthly')
      )
      values.monthly_insurance_is_type = '$'
      values.monthly_hoa = validate_number(get_chart_data('how', 'monthly'))
      if (calc?.buy?.is_pmi === 'true') {
        values.monthly_pmi = validate_number(get_chart_data('mi', 'monthly'))
        values.monthly_mi_total_label = 'PMI'
      } else if (calc?.buy?.is_mip === 'true') {
        values.monthly_mip = validate_number(get_chart_data('mi', 'monthly'))
        values.monthly_mi_total_label = 'MIP'
      } else if (calc?.buy?.is_gf === 'true') {
        values.monthly_gf = validate_number(get_chart_data('mi', 'monthly'))
        values.monthly_mi_total_label = 'GF'
      }
      values.monthly_pmi_is_type = '$'
      values.monthly_mip_is_type = '$'

      values.monthly_mi_total = validate_number(get_chart_data('mi', 'monthly'))

      const rent_vs_buy_values = calc?.buy?.rent_vs_buy
      const break_curr_payment = rent_vs_buy_values?.break_curr_payment
      const break_even_payment = rent_vs_buy_values?.break_even_payment
      values.break_curr_payment = validate_number(break_curr_payment)
      values.monthly_tax_savings = validate_number(
        rent_vs_buy_values?.break_tax_savings_month_one,
        0
      )

      values.buy_costs_total = validate_number(
        rent_vs_buy_values?.break_buy_series?.[break_curr_payment],
        0
      )
      values.rent_costs_total = validate_number(
        rent_vs_buy_values?.break_rent_series?.[break_curr_payment],
        0
      )

      values.break_time_label = get_year_month_string(
        break_curr_payment + 1 || 1
      )
      if (break_curr_payment === rent_vs_buy_values?.break_even_payment) {
        values.break_gain_label = 'Break Even'
        values.break_gain_total = null
      } else if (break_curr_payment > break_even_payment) {
        values.break_gain_label = 'Buy Gain'
        values.break_gain_total = validate_number(
          values?.rent_costs_total - values?.buy_costs_total,
          0
        )
      } else if (break_curr_payment < break_even_payment) {
        values.break_gain_label = 'Rent Gain'
        values.break_gain_total = validate_number(
          values?.buy_costs_total - values?.rent_costs_total,
          0
        )
      }

      values.tax_savings_total = validate_number(
        calc_tax_savings(
          get_rent_vs_buy_val('amortize_schedule')?.total_interest,
          get_tax_bracket(
            validate_number(calc?.buy?.values?.gross_income || 22)
          )
        ),
        0
      )
      values.tax_adjusted_payment = validate_number(
        validate_number(
          calc?.buy?.monthly_payment_series?.reduce((cur, val) => cur + val, 0)
        ) - values?.monthly_tax_savings,
        0
      )
      const equity_series = [
        get_nums()?.down_payment_num,
        get_rent_vs_buy_val('break_home_payments')?.home_appreciation_total,
        get_rent_vs_buy_val('amortize_schedule')?.total_principal,
      ]
      values.home_maintenance_total = validate_number(
        get_rent_vs_buy_val('break_home_payments')?.total_maintenance,
        0
      )

      const { term_num, principal_num, home_price_num } = get_nums()
      const pmi_payments = get_pmi_and_years(
        term_num * 12,
        term_num,
        calc?.buy?.amortize?.schedule,
        home_price_num
      )

      const mi =
        calc?.buy?.is_pmi === 'true'
          ? validate_number(pmi_payments?.payments?.[break_curr_payment]?.total)
          : (get_yearly_mip(home_price_num, principal_num, term_num) / 12) *
            (break_curr_payment + 1)

      values.total_payments_made = validate_number(
        values?.monthly_hoa +
          mi +
          get_rent_vs_buy_val('amortize_schedule')?.total_payments +
          get_rent_vs_buy_val('break_home_payments')?.total_insurance +
          get_rent_vs_buy_val('break_home_payments')?.total_taxes,
        0
      )

      values.break_total_principal = validate_number(equity_series?.[2], 0)
      values.home_appreciation_total = validate_number(equity_series?.[1], 0)
      values.total_equity = validate_number(
        equity_series?.reduce((cur, val) => cur + val, 0),
        0
      )
      const data = {
        values: {
          ...types,
          ...values,
          instance_uuid: calc?.buy?.data_options?.instance_uuid || get_uuid(),
          calc_type: get_calc_type_id(implementation_type || 'buy'),
          address: calc?.formatted_address || null,
          mls_id: calc?.mls_id || null,
        },
        closing_values,
        amortize_values: amortize_values || [],
      }

      //       monthly_tax_savings, tax_adjusted_payment
      // total_payments_made, home_appreciation_total, principal, tax savings, maintenance,
      // owning, renting, total equity,
      // time_label, break_label,
      // sale_price, loan_balance, prorated_tax, closing_costs, net_at_close,

      const current_hist = calc?.axiom_calc_hist?.find(
        (item) => item.instance_uuid == calc?.buy?.data_options?.instance_uuid
      )
      // const current_hist_fees = data?.closing_values
      //   ?.filter(
      //     (item) => item.instance_uuid == calc?.buy?.data_options?.instance_uuid
      //   )
      //   ?.map((item) => {
      //     return {
      //       ...calc?.axiom_calc_hist_fees?.find(
      //         (fee) => fee?.fee_key === item?.fee_key
      //       ),
      //       ...item,
      //       upd_dt: new Date()?.toISOString(),
      //     }
      //   })

      saveCalc((prev) => ({
        ...prev,
        axiom_calc_hist: [
          ...(prev?.axiom_calc_hist?.filter(
            (item) =>
              item.instance_uuid !== calc?.buy?.data_options?.instance_uuid
          ) || []),
          {
            ...current_hist,
            ...data?.values,
            upd_dt: new Date()?.toISOString(),
          },
        ],
        axiom_calc_hist_fees: [
          ...(prev?.axiom_calc_hist_fees?.filter(
            (item) =>
              item.instance_uuid !== calc?.buy?.data_options?.instance_uuid
          ) || []),
          ...(closing_values || []),
        ],
      }))
      await axiomFetch('/calculators/save', 'POST', data, {}, false, null, 700)
      saveCalc((prev) => ({ ...prev, share_loading: false }))
    }
    if (calc?.buy?.calculated) {
      if (implementation_type === 'affordability') {
        let timeout = affordTimeoutRefSave.current
        if (timeout) {
          clearTimeout(timeout)
        }
        saveCalc((prev) => ({ ...prev, share_loading: true }))
        affordTimeoutRefSave.current = setTimeout(() => {
          save_data()
        }, 800)
      } else {
        save_data()
      }
    }
  }, [
    calc?.buy?.values,
    calc?.buy?.defaults,
    calc?.buy?.rent_vs_buy?.break_curr_payment,
    calc?.formatted_address,
    calc?.buy?.loan_compare?.loan_1,
    calc?.buy?.loan_compare?.loan_2,
    calc?.buy?.loan_compare?.loan_3,
    calc?.buy?.monthly_payment,
    calc?.buy?.bring_to_close,
    calc?.buy?.data_options?.calculated,
    calc?.buy?.data_options?.run,
  ])

  const save_search_address = (place) => {
    const components = place?.address_components
    const location = place?.geometry?.location
    let address1_components = components?.filter((item) =>
      ['street_number', 'route'].includes(item?.types?.[0])
    )
    let address2_components = components?.filter((item) =>
      ['locality', 'administrative_area_level_1'].includes(item?.types?.[0])
    )
    let address3_components = components?.filter((item) =>
      ['subpremise'].includes(item?.types?.[0])
    )
    let address4_components = components?.filter((item) =>
      ['administrative_area_level_2'].includes(item?.types?.[0])
    )
    let county = address4_components?.[0]?.long_name
      ?.replace('County', '')
      ?.trim()
    const search_address = {
      address1: [...(address1_components || []), ...(address3_components || [])]
        ?.map((item) => item?.long_name || '')
        ?.join(' '),
      address2: address2_components
        ?.map((item) => item?.long_name || '')
        ?.join(', '),
      lat: location?.lat(),
      lng: location?.lng(),
      unit_number: address3_components
        ?.map((item) => item?.long_name || '')
        ?.join(', '),
      county,
    }
    return search_address
  }

  const calculate_estimate = () => {
    const instance_uuid = calc?.buy?.data_options?.instance_uuid || get_uuid()
    saveCalc((prev) => {
      return {
        ...prev,
        buy: {
          ...prev?.buy,
          hide_details: false,
          data_options: { ...prev?.buy?.data_options, instance_uuid },
          rent_vs_buy: {
            ...(prev?.buy?.rent_vs_buy || {}),
            use_curr_payment: false,
          },
          calculated: true,
          run: (prev?.buy?.run || 0) + 1,
        },
      }
    })
    swiper_slide(
      ['buy', 'affordability']?.includes(implementation_type) ? true : false
    )
    if (validate_number(calc?.buy?.values?.home_price)) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  const get_inputs = (calculator_type = 'buy') => {
    const types = calc?.[calculator_type]?.types
    const names = calc?.[calculator_type]?.names
    const placeholders = calc?.[calculator_type]?.placeholders
    const loan_type = get_loan_type(calculator_type, 'values')
    return Object.entries(calc?.[calculator_type]?.values || {})
      ?.sort(([a_key, a_value], [b_key, b_value]) => {
        if (implementation_type === 'affordability') {
          if (a_key === 'down_payment') {
            return -1
          }
        }
      })
      ?.filter(([key, value]) => {
        if (
          (calculator_type === 'buy',
          loan_type === 'all cash' &&
            ['down_payment', 'interest_rate', 'loan_term']?.includes(key))
        ) {
          return false
        } else {
          return true
        }
      })
      ?.filter(([key, value]) => {
        if (
          calculator_type === 'buy' &&
          loan_type !== 'va' &&
          ['funding_fee']?.includes(key)
        ) {
          return false
        } else {
          return true
        }
      })
      ?.filter(([key, value]) => {
        if (implementation_type === 'affordability' && key === 'home_price') {
          return false
        } else {
          return true
        }
      })
      ?.filter(([key, value]) => {
        if (
          implementation_type === 'rent_vs_buy' &&
          calc?.buy?.rent_vs_buy_remove?.includes(key)
        ) {
          return false
        } else if (
          implementation_type !== 'rent_vs_buy' &&
          calc?.buy?.rent_vs_buy_add?.includes(key)
        ) {
          return false
        } else {
          return true
        }
      })
      ?.map(([key, value]) => {
        if (!value?.toString()?.replace('[$,-^0-9]', '')) {
          value = null
        }
        const type = types[key]
        const name = names[key]
        const placeholder = placeholders[key] || ''
        const label_width = 'w-[80%]'
        const initial_data = {
          left_label: name,
          left_label_width: label_width,
          name: key,
          value,
          placeholder,
        }

        if (
          type === '%' ||
          type === '$' ||
          type === '%$' ||
          type === 'a' ||
          type === 'm'
        ) {
          initial_data.show_delete_all = true
          initial_data.inputMode = 'decimal'
        }
        if (type === '%' || type === 'm' || type === 'a') {
          initial_data.show_percent = true
          initial_data.percent_value = '%'
          if (key === 'loan_term') {
            initial_data.percent_value = ' years'
          }
          initial_data.handleChange = (e) => {
            update_input({ e, type, decimal: 3, original: value })
          }
        }
        if (type === '$') {
          initial_data.show_dollar = true
          initial_data.percent_value = ''
          initial_data.value = initial_data?.value
            ? `$${initial_data.value}`
            : initial_data?.value || ''
        }
        const both_values = [
          'down_payment',
          'insurance_rate',
          'taxes',
          'hoa',
          'gross_income',
        ]?.filter((item) => {
          if (
            implementation_type === 'affordability' &&
            ['down_payment', 'insurance_rate', 'taxes'].includes(item)
          ) {
            return false
          } else {
            return true
          }
        })
        if (both_values.includes(key)) {
          initial_data.show_percent = true
          initial_data.show_dollar = true
          initial_data.percent_value =
            calc?.[calculator_type]?.types?.[key] === '%' ? '%' : ''
          initial_data.dollar_percent = calc?.[calculator_type]?.types?.[key]
          if (key === 'hoa') {
            initial_data.toggle_values = ['a', 'm']
            initial_data.dollar_percent = 'm'
          }
          initial_data.left_label_width = 'w-[132%]'
          initial_data.delete_all_position = 'right-[75px]'
          initial_data.total = validate_number(
            calculator_type === 'sell'
              ? calc?.[calculator_type]?.values?.sale_price
              : calc?.[calculator_type]?.values?.home_price
          )
          initial_data.handleChange = (e, type) => {
            update_input({ e, type, decimal: 3, original: value })
          }
        }

        if (key === 'home_price' || key === 'current_rent') {
          initial_data.onKeyUp = (e) => {
            // update_input({ e, type, decimal: 2, original: value })
            if (e.target.value && e.key === 'Enter') {
              calculate_estimate()
            }
          }
        }

        if (key === 'gross_income') {
          initial_data.delete_all_position = 'right-[75px]'
        }

        let result = (
          <LabelInput
            {...initial_data}
            handleChange={
              initial_data?.handleChange
                ? initial_data?.handleChange
                : (e) => {
                    update_input({ e, type, decimal: 2, original: value })
                  }
            }
          />
        )
        if (key === 'gross_income') {
          result = (
            <div className='relative'>
              {result}
              <div
                className='absolute top-[16px] left-[120px] cursor-pointer'
                onClick={() => {
                  setModalType('gross_income')
                  setShowModal(true)
                }}
              >
                <FaCalculator />
              </div>
            </div>
          )
        }
        if (type === 'date') {
          result = (
            <Schedule
              left_label={name}
              left_label_width={label_width}
              name={key}
              show_time={false}
              schedule_date={value}
              handle_change={(e) => {
                update_input({ e, type, decimal: 2, original: value })
              }}
            />
          )
        }
        if (type === 'options') {
          let options = calc?.[calculator_type]?.option_values?.[key]?.filter(
            (opt) => {
              if (
                key === 'loan_type' &&
                implementation_type === 'affordability' &&
                opt?.toLowerCase() === 'all cash'
              ) {
                return false
              } else {
                return true
              }
            }
          )

          result = (
            <Select
              left_label={name}
              left_label_width={'w-[82%]'}
              no_margin={false}
              className='mb-[10px]'
              name={key}
              value={
                ['county', 'loan_type']?.includes(key)
                  ? value?.toLowerCase()
                  : value
              }
              handler={(e) => {
                update_input({ e, type, decimal: 2, original: value })
              }}
              placeholder=''
            >
              {[...options].map((item) => {
                return (
                  <option
                    key={item}
                    value={
                      ['county', 'loan_type']?.includes(key)
                        ? item?.toLowerCase()
                        : item
                    }
                  >
                    {item}
                  </option>
                )
              })}
            </Select>
          )
        }

        return <div key={key}>{result}</div>
      })
  }

  const get_share_calc_type = (view_type, implementation_type) => {
    if (view_type == 'loan_compare' || implementation_type === 'loan_compare') {
      return 'loan_compare'
    } else if (implementation_type === 'affordability') {
      return 'affordability'
    } else if (
      view_type === 'loan_details' ||
      implementation_type === 'loan_details'
    ) {
      return 'loan_details'
    } else if (implementation_type === 'rent_vs_buy') {
      return 'rent_vs_buy'
    } else {
      return 'buy'
    }
  }

  const get_rent_vs_buy_val = (
    key,
    index = calc?.buy?.rent_vs_buy?.break_curr_payment
  ) => {
    const val_obj = {
      amortize_schedule: calc?.buy?.amortize?.schedule,
      break_home_payments:
        calc?.buy?.rent_vs_buy?.break_home_payments?.payments,
      rent_payments: calc?.buy?.rent_vs_buy?.rent_payments,
      buy_payments: calc?.buy?.rent_vs_buy?.buy_payments,
    }
    return val_obj?.[key]?.[index]
  }

  return (
    <div>
      {calc?.buy?.calculated &&
      validate_number(calc?.buy?.values?.home_price) ? (
        <div>
          {get_loan_type('buy', 'values') != 'all cash' ? (
            <CalcShare
              calculator_type={get_share_calc_type(
                calc?.options?.view_type,
                implementation_type
              )}
              implementation_type={implementation_type}
            />
          ) : (
            ''
          )}

          <div className='px-3 mb-[10px]'>
            {['affordability', 'buy', 'rent_vs_buy'].includes(
              implementation_type
            ) ? (
              <ButtonsRow
                padding={'p-1'}
                items={
                  implementation_type === 'rent_vs_buy'
                    ? ['payment', 'costs', 'equity', 'net_sheet']
                    : ['loan_compare', 'estimate', 'loan_details']?.filter(
                        (item) => {
                          if (
                            item === 'loan_compare' &&
                            implementation_type === 'affordability'
                          ) {
                            return false
                          } else {
                            return true
                          }
                        }
                      )
                }
                selected={
                  calc?.options?.view_type ||
                  (implementation_type === 'rent_vs_buy' ? 'costs' : 'estimate')
                }
                handler={(item) => {
                  const position =
                    implementation_type === 'rent_vs_buy'
                      ? {
                          loan_compare: 0,
                          payment: 1,
                          costs: 2,
                          equity: 3,
                          net_sheet: 4,
                          loan_details: 5,
                        }
                      : {
                          loan_compare: 0,
                          estimate: 1,
                          loan_details: 2,
                        }
                  calc?.options?.swiper?.slideTo(position[item])
                  saveCalc((prev) => ({
                    ...prev,
                    options: { ...prev?.options, view_type: item },
                  }))
                }}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      ) : (
        ''
      )}
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        initialSlide={implementation_type === 'rent_vs_buy' ? 2 : 1}
        onSlideChange={(e) => {
          const position =
            implementation_type === 'rent_vs_buy'
              ? {
                  0: 'loan_compare',
                  1: 'payment',
                  2: 'costs',
                  3: 'equity',
                  4: 'net_sheet',
                  5: 'loan_details',
                }
              : {
                  0: 'loan_compare',
                  1: 'estimate',
                  2: 'loan_details',
                }
          if (calc?.options?.on_a_graph) {
            return
          }
          if (
            ['affordability', 'rent_vs_buy']?.includes(implementation_type) &&
            e.realIndex === 0
          ) {
            return e.slideTo(1)
          }
          if (implementation_type === 'rent_vs_buy' && e.realIndex === 5) {
            return e.slideTo(4)
          }
          saveCalc((prev) => ({
            ...prev,
            options: { ...prev?.options, view_type: position[e.realIndex] },
          }))
        }}
        onSwiper={(swiper) => {
          try {
            // const index = 0
            // if (index > 0) {
            //   swiper.slideTo(index === -1 ? 0 : index, 0)
            //   return
            // }
            // if (index === 0 || index === -1) {
            //   swiper.slideTo(1, 900)
            // }
            // setTimeout(() => {
            //   if (index === 0 || index === -1) {
            //     swiper.slideTo(0, 900)
            //   }
            //   swiper.slideTo(index === -1 ? 0 : index, 0)
            //   setSwiper(swiper)
            // }, 175)

            // swiper.slideTo(1)
            if (
              (!calc?.buy?.calculated && !calc?.buy?.home_price) ||
              implementation_type !== 'buy'
            ) {
              swiper.allowTouchMove = false
            }
            if (swiper) {
              saveCalc((prev) => ({
                ...prev,
                options: { ...prev?.options, swiper },
              }))
            }
          } catch (error) {
            console.log(error)
          }
        }}
      >
        <SwiperSlide key={'loan_compare'}>
          <div className='min-h-[550px]'>
            <CalcLoanCompare implementation_type={implementation_type} />
          </div>
        </SwiperSlide>
        {implementation_type === 'rent_vs_buy' ? (
          <SwiperSlide key={'payment'}>
            {calc?.options?.view_type === 'payment' ? (
              <div>
                {calc?.buy?.calculated &&
                validate_number(calc?.buy?.values?.home_price) &&
                validate_number(calc?.buy?.values?.current_rent) ? (
                  <div>
                    <div className='flex flex-col items-center'>
                      <h1 className='text-black font-semibold text-[15px] '>
                        Tax Adjusted Payment
                      </h1>
                      <div
                        onClick={(e) => {
                          if (e.target.tagName === 'circle') {
                            // saveCalc((prev) => ({
                            //   ...prev,
                            //   buy: {
                            //     ...prev?.buy,
                            //     show_total_edit_type: 'home_price',
                            //     original_down: prev?.buy?.values?.down_payment,
                            //     show_total_edit: true,
                            //     calculated: false,
                            //   },
                            // }))
                          }
                        }}
                      >
                        <ApexChart
                          key={'payment_chart'}
                          options={{
                            ...calc?.buy?.tax_adjusted_chart,
                          }}
                          width={'200px'}
                          series={[
                            validate_number(
                              calc?.buy?.monthly_payment_series?.reduce(
                                (cur, val) => cur + val,
                                0
                              )
                            ) -
                              validate_number(
                                calc?.buy?.rent_vs_buy
                                  ?.break_tax_savings_month_one
                              ) *
                                2,
                            validate_number(
                              calc?.buy?.rent_vs_buy
                                ?.break_tax_savings_month_one
                            ),
                          ]}
                          type='donut'
                        />
                      </div>
                      {labels_html(
                        ['Payment', 'Tax Savings'],
                        [
                          validate_number(
                            calc?.buy?.monthly_payment_series?.reduce(
                              (cur, val) => cur + val,
                              0
                            )
                          ),
                          validate_number(
                            calc?.buy?.rent_vs_buy?.break_tax_savings_month_one
                          ),
                        ],
                        '',
                        colors
                      )}
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <div className='min-h-[550px]'>
                  <div className='px-3 '>
                    <div className={`flex justify-end my-[10px] gap-[10px]`}>
                      {error?.show_error ? (
                        <div className={`${uiStyles.failure_msg} mb-[10px]`}>
                          {error?.msg}
                        </div>
                      ) : (
                        <button
                          className={`${uiStyles.hover_btn_small}`}
                          onClick={() => {
                            calculate_estimate()
                          }}
                        >
                          Calculate
                        </button>
                      )}
                    </div>

                    {calc?.buy?.values?.loan_type === 'fha' ? (
                      <LabelInput
                        left_label={'Max FHA'}
                        left_label_width={'w-[80%]'}
                        name={'max_fha'}
                        show_dollar={true}
                        value={calc?.buy?.max_fha}
                        show_delete_all={true}
                        percent_value=''
                        handleChange={(e, type) =>
                          update_input({
                            e,
                            type,
                            decimal: 2,
                            original: calc?.buy?.max_fha,
                          })
                        }
                        placeholder=''
                      />
                    ) : (
                      ''
                    )}
                    {get_inputs('buy')}
                  </div>
                  <div className='flex justify-center w-full'>
                    {error?.show_error ? (
                      <div className={`${uiStyles.failure_msg} mb-[10px]`}>
                        {error?.msg}
                      </div>
                    ) : (
                      <button
                        className={`${uiStyles.hover_btn} w-full rounded-none`}
                        onClick={() => {
                          calculate_estimate()
                        }}
                      >
                        Calculate
                      </button>
                    )}
                  </div>
                  <CalcPaymentChange
                    key='same_key'
                    ref={null}
                    update_input={update_input}
                  />
                  {showModal ? (
                    <Modal
                      side_padding={'px-0'}
                      modalName={modalType}
                      height={
                        modalType === 'remove' ? 'h-[200px]' : 'h-[550px]'
                      }
                      width={'w-full'}
                      showModal={showModal}
                      setShowModal={setShowModal}
                      showClose={true}
                    >
                      {modals[modalType]}
                    </Modal>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : (
              ''
            )}
          </SwiperSlide>
        ) : (
          ''
        )}
        <SwiperSlide key={'estimate'}>
          <div>
            {calc?.buy?.calculated &&
            validate_number(calc?.buy?.values?.home_price) ? (
              <>
                {!validate_number(calc?.buy?.values?.current_rent) &&
                implementation_type === 'rent_vs_buy' ? (
                  ''
                ) : (
                  <>
                    {implementation_type === 'rent_vs_buy' &&
                    (calc?.options?.view_type || 'costs') !== 'costs' ? (
                      ''
                    ) : (
                      <>
                        {implementation_type === 'rent_vs_buy' ? (
                          <div className='px-3'>
                            {calc?.buy?.rent_vs_buy?.break_even_payment ===
                            calc?.buy?.rent_vs_buy?.break_curr_payment ? (
                              <div className='flex justify-between px-[40px]'>
                                <p className={`font-bold`}>Break Even</p>
                                <p className={`font_bold`}>
                                  {get_year_month_string(
                                    calc?.buy?.rent_vs_buy?.break_curr_payment +
                                      1 || 1
                                  )}
                                </p>
                              </div>
                            ) : calc?.buy?.rent_vs_buy?.break_buy_series?.[
                                calc?.buy?.rent_vs_buy?.break_curr_payment
                              ] >
                              calc?.buy?.rent_vs_buy?.break_rent_series?.[
                                calc?.buy?.rent_vs_buy?.break_curr_payment
                              ] ? (
                              <div className='flex justify-between px-[40px]'>
                                <p className={`font-bold`}>
                                  Rent Gain{' '}
                                  <span className='text-xs font-normal'>
                                    {get_year_month_string(
                                      calc?.buy?.rent_vs_buy
                                        ?.break_curr_payment + 1 || 1
                                    )
                                      .replace('year', 'yr')
                                      .replace('month', 'mth')}
                                  </span>
                                </p>
                                <p className={`font_bold`}>
                                  $
                                  {format_currency(
                                    calc?.buy?.rent_vs_buy?.break_buy_series?.[
                                      calc?.buy?.rent_vs_buy?.break_curr_payment
                                    ] -
                                      calc?.buy?.rent_vs_buy
                                        ?.break_rent_series?.[
                                        calc?.buy?.rent_vs_buy
                                          ?.break_curr_payment
                                      ],
                                    false,
                                    2
                                  )}
                                </p>
                              </div>
                            ) : (
                              <div className='flex justify-between px-[40px]'>
                                <p className={`font-bold`}>
                                  Buy Gain{' '}
                                  <span className='text-xs font-normal'>
                                    {get_year_month_string(
                                      calc?.buy?.rent_vs_buy
                                        ?.break_curr_payment + 1 || 1
                                    )
                                      .replace('year', 'yr')
                                      .replace('month', 'mth')}
                                  </span>
                                </p>
                                <p className={`font_bold`}>
                                  $
                                  {format_currency(
                                    calc?.buy?.rent_vs_buy?.break_rent_series?.[
                                      calc?.buy?.rent_vs_buy?.break_curr_payment
                                    ] -
                                      calc?.buy?.rent_vs_buy
                                        ?.break_buy_series?.[
                                        calc?.buy?.rent_vs_buy
                                          ?.break_curr_payment
                                      ],
                                    false,
                                    2
                                  )}
                                </p>
                              </div>
                            )}
                            <div className='flex justify-between px-[40px]'>
                              <div className='flex gap-[2px]  items-center'>
                                <div className='w-[8px] h-[8px] bg-[rgba(53,_162,_235,_1)] rounded-sm'></div>

                                <p className={`font-bold`}>Buy Costs</p>
                              </div>
                              <p className={`font_bold`}>
                                $
                                {format_currency(
                                  calc?.buy?.rent_vs_buy?.break_buy_series?.[
                                    calc?.buy?.rent_vs_buy?.break_curr_payment
                                  ] || 1
                                )}
                              </p>
                            </div>
                            <div className='flex justify-between px-[40px]'>
                              <div className='flex gap-[2px]  items-center'>
                                <div className='w-[8px] h-[8px] bg-[rgba(255,_99,_132,_1)]  rounded-sm'></div>
                                <p className={`font-bold`}>Rent Costs</p>
                              </div>
                              <p className={`font_bold`}>
                                $
                                {format_currency(
                                  calc?.buy?.rent_vs_buy?.break_rent_series?.[
                                    calc?.buy?.rent_vs_buy?.break_curr_payment
                                  ]
                                )}
                              </p>
                            </div>
                            <CalcBuyLineChart
                              line_point={
                                calc?.buy?.rent_vs_buy?.break_curr_payment - 1 <
                                0
                                  ? 0
                                  : calc?.buy?.rent_vs_buy
                                      ?.break_curr_payment || 1
                              }
                              show_line_slider={true}
                              slide_change={(e) => {
                                saveCalc((prev) => ({
                                  ...prev,
                                  buy: {
                                    ...prev?.buy,
                                    rent_vs_buy: {
                                      ...prev?.buy?.rent_vs_buy,
                                      break_curr_payment: validate_number(
                                        e.target.value
                                      ),
                                    },
                                  },
                                }))
                              }}
                              labels={array_nums(
                                validate_number(
                                  calc?.buy?.values?.loan_term * 12
                                )
                              )}
                              datasets={[
                                {
                                  label: 'Rent Costs',
                                  fill: false,
                                  data:
                                    calc?.buy?.rent_vs_buy?.break_rent_series ||
                                    [],

                                  borderColor: 'rgb(255, 99, 132)',
                                  backgroundColor: 'rgba(255, 99, 132, 0.5)',
                                },
                                {
                                  label: 'Buy Costs',
                                  fill: false,
                                  data:
                                    calc?.buy?.rent_vs_buy?.break_buy_series ||
                                    [],

                                  borderColor: 'rgb(53, 162, 235)',
                                  backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                },
                              ]}
                            />
                          </div>
                        ) : implementation_type === 'loan_compare' ? (
                          <CalcLoanCompare
                            implementation_type={implementation_type}
                          />
                        ) : implementation_type === 'loan_details' ? (
                          !calc?.buy?.hide_details ? (
                            <CalcBuyDetails
                              implementation_type={implementation_type}
                            />
                          ) : (
                            ''
                          )
                        ) : implementation_type === 'affordability' ? (
                          <div>
                            <div className='flex flex-col items-center'>
                              <h1 className='text-black font-semibold text-[18px] '>
                                Buying Power
                              </h1>
                              <div
                                onClick={(e) => {
                                  if (e.target.tagName === 'circle') {
                                    // saveCalc((prev) => ({
                                    //   ...prev,
                                    //   buy: {
                                    //     ...prev?.buy,
                                    //     show_total_edit_type: 'home_price',
                                    //     original_down: prev?.buy?.values?.down_payment,
                                    //     show_total_edit: true,
                                    //     calculated: false,
                                    //   },
                                    // }))
                                  }
                                }}
                              >
                                <ApexChart
                                  key={'affordability_chart'}
                                  options={calc?.buy?.affordability_chart}
                                  width={'200px'}
                                  series={calc?.buy?.affordability_series}
                                  type='donut'
                                />
                              </div>
                              {labels_html(
                                [
                                  ...(calc?.buy?.affordability_labels || []),
                                  'Closing Costs',
                                ],
                                [
                                  ...(calc?.buy?.affordability_series || []),
                                  validate_number(get_nums()?.fixed_num) +
                                    validate_number(get_nums()?.prepaids_num),
                                ],
                                '',
                                calc?.buy?.affordability_chart?.colors
                              )}
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className='flex justify-center mb-[10px] w-full'>
                              <div className='flex flex-col items-center'>
                                <h1 className='text-black font-semibold text-[18px] ml-[20px]'>
                                  Monthly Payment
                                </h1>
                                <div
                                  onClick={(e) => {
                                    if (e.target.tagName === 'circle') {
                                      saveCalc((prev) => ({
                                        ...prev,
                                        buy: {
                                          ...prev?.buy,
                                          show_total_edit_type:
                                            'monthly_payment',
                                          original_down:
                                            prev?.buy?.values?.down_payment,
                                          show_total_edit: true,
                                          calculated: false,
                                        },
                                      }))
                                    }
                                  }}
                                >
                                  <ApexChart
                                    key={'monthly_chart'}
                                    options={calc?.buy?.monthly_chart}
                                    width={'200px'}
                                    series={calc?.buy?.monthly_payment_series}
                                    type='donut'
                                  />
                                </div>
                                {labels_html(
                                  calc?.buy?.monthly_payment_labels,
                                  calc?.buy?.monthly_payment_series,
                                  'ml-[16px]',
                                  calc?.buy?.monthly_chart?.colors
                                )}
                              </div>
                              <div className='flex flex-col'>
                                <h1 className='text-black font-semibold text-[18px] ml-[44px]'>
                                  Bring to Close
                                </h1>
                                <div
                                  onClick={(e) => {
                                    if (e.target.tagName === 'circle') {
                                      setHitclose(true)
                                      saveCalc((prev) => ({
                                        ...prev,
                                        buy: {
                                          ...prev?.buy,
                                          show_total_edit_type: 'closing',
                                          show_total_edit: true,
                                          original_down:
                                            prev?.buy?.down_payment,
                                          desired_bring_to_close:
                                            prev?.buy?.bring_to_close,
                                          calculated: false,
                                        },
                                      }))
                                    }
                                  }}
                                >
                                  <ApexChart
                                    key={'closing'}
                                    options={calc?.buy?.closing_chart}
                                    width={'200px'}
                                    series={calc?.buy?.close_series}
                                    type='donut'
                                  />
                                </div>
                                {labels_html(
                                  calc?.buy?.close_labels,
                                  calc?.buy?.close_series,
                                  'ml-[20px]',
                                  calc?.buy?.closing_chart?.colors
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              ''
            )}
            <div className='px-3 '>
              <div className={`flex justify-end my-[10px] gap-[10px]`}>
                {error?.show_error ? (
                  <div className={`${uiStyles.failure_msg} mb-[10px]`}>
                    {error?.msg}
                  </div>
                ) : (
                  <button
                    className={`${uiStyles.hover_btn_small}`}
                    onClick={() => {
                      calculate_estimate()
                    }}
                  >
                    Calculate
                  </button>
                )}
                <button
                  className={`${uiStyles.hover_btn_small}`}
                  onClick={() => {
                    setModalType('options')
                    setShowModal(true)
                  }}
                >
                  Options
                </button>
              </div>
              {implementation_type !== 'affordability' ? (
                <>
                  {isLoaded && !calc?.loading_search ? (
                    <div className='mb-[4px]'>
                      <Autocomplete
                        onLoad={(autocomplete) => {
                          autocomplete.setFields([
                            'formatted_address',
                            'address_components',
                            'geometry',
                            'name',
                          ])
                          saveCalc((prev) => ({ ...prev, autocomplete }))
                        }}
                        onPlaceChanged={async () => {
                          let place = {}
                          if (calc?.autocomplete) {
                            place = calc?.autocomplete?.getPlace()
                          }
                          if (place?.address_components) {
                            const search_address = save_search_address(place)
                            const search_string = new URLSearchParams(
                              search_address
                            )?.toString()
                            saveCalc((prev) => ({
                              ...prev,
                              loading_search: true,
                            }))
                            const data = await axiomFetch(
                              `/calculators/taxes_hoa?${search_string}`
                            )
                            const tax_num = validate_number(
                              data?.tax_amount || 0
                            )

                            let hoa_amount = ''
                            const fee_calcs = {
                              Annually: 0.0833,
                              'Semi-Annually': 0.1667,
                              Monthly: 1,
                              Quarterly: 0.25,
                              '********': 0,
                            }
                            hoa_amount =
                              validate_number(data?.hoa1 || 0) *
                                (fee_calcs?.[data?.hoa1_frequency] || 0) +
                              validate_number(data?.hoa2 || 0) *
                                (fee_calcs?.[data?.hoa2_frequency] || 0)

                            if (
                              !validate_number(data?.list_price) &&
                              !tax_num &&
                              !hoa_amount
                            ) {
                              setError({
                                show_error: true,
                                msg: 'No data found',
                              })
                              setTimeout(() => {
                                setError({ show_error: false, msg: '' })
                              }, [3000])
                            }
                            saveCalc((prev) => {
                              const add_values = {
                                taxes: format_currency(tax_num, false, 3),
                                home_price: validate_number(data?.list_price)
                                  ? format_currency(
                                      validate_number(data?.list_price),
                                      false,
                                      0
                                    )
                                  : null,

                                county: search_address?.county,
                                hoa:
                                  format_currency(hoa_amount, false, 2) || '',
                              }
                              const add_types = { taxes: '$' }
                              return {
                                ...prev,
                                loading_search: false,
                                buy: {
                                  ...prev?.buy,
                                  values: {
                                    ...prev?.buy?.values,
                                    ...add_values,
                                  },
                                  types: { ...prev?.buy?.types, ...add_types },
                                  data_options: {
                                    ...prev?.buy?.data_options,
                                    instance_uuid:
                                      prev?.buy?.data_options?.instance_uuid ||
                                      get_uuid(),
                                  },
                                  calculated: data?.list_price ? true : false,
                                },
                                loan_2: {
                                  ...prev?.loan_2,
                                  values: {
                                    ...prev?.loan_2?.values,
                                    ...add_values,
                                  },
                                  types: { ...prev?.buy?.types, ...add_types },
                                  calculated: data?.list_price ? true : false,
                                },
                                loan_3: {
                                  ...prev?.loan_3,
                                  values: {
                                    ...prev?.loan_3?.values,
                                    ...add_values,
                                  },
                                  types: { ...prev?.buy?.types, ...add_types },
                                  calculated: data?.list_price ? true : false,
                                },
                                search_address: save_search_address(place),
                                mls_id: data?.mls_id,
                                formatted_address: place?.formatted_address,
                                address_components: place?.address_components,
                                location: place?.geometry?.location,
                              }
                            })
                          }
                        }}
                      >
                        <input
                          type='text'
                          className={`${uiStyles.input}`}
                          placeholder='Address Search'
                          value={calc?.formatted_address || ''}
                          onChange={(e) => {
                            saveCalc((prev) => ({
                              ...prev,
                              formatted_address: e.target.value,
                            }))
                          }}
                        />
                      </Autocomplete>
                    </div>
                  ) : (
                    <div className='h-[50px]'>
                      <SmallLoadingRel />
                    </div>
                  )}
                </>
              ) : (
                ''
              )}
              {calc?.buy?.values?.loan_type === 'fha' &&
              implementation_type !== 'affordability' ? (
                <LabelInput
                  left_label={'Max FHA'}
                  left_label_width={'w-[80%]'}
                  name={'max_fha'}
                  show_dollar={true}
                  value={calc?.buy?.max_fha}
                  show_delete_all={true}
                  percent_value=''
                  handleChange={(e, type) =>
                    update_input({
                      e,
                      type,
                      decimal: 2,
                      original: calc?.buy?.max_fha,
                    })
                  }
                  placeholder=''
                />
              ) : (
                ''
              )}
              {implementation_type === 'affordability' ? (
                <>
                  <LabelInput
                    left_label={'Monthly Payment'}
                    left_label_width={'w-[80%]'}
                    name={'monthly_payment'}
                    show_dollar={true}
                    show_delete_all={true}
                    percent_value=''
                    value={calc?.buy?.monthly_payment || ''}
                    handleChange={(e, type) => {
                      update_input({
                        e,
                        type,
                        decimal: 3,
                        original: calc?.buy?.monthly_payment || '',
                      })
                      saveCalc((prev) => ({
                        ...prev,
                        buy: {
                          ...prev?.buy,
                          run_monthly_payment:
                            (prev?.buy?.run_monthly_payment || 0) + 1,
                        },
                      }))
                    }}
                    onKeyUp={(e) => {
                      if (e.target.value && e.key === 'Enter') {
                        calculate_estimate()
                      }
                    }}
                    placeholder='Required'
                  />
                  {/* <LabelInput
                    left_label={'Bring To Close'}
                    left_label_width={'w-[80%]'}
                    name={'bring_to_close'}
                    show_dollar={true}
                    show_delete_all={true}
                    value={calc?.buy?.bring_to_close}
                    handleChange={(e, type) => {
                      update_input({
                        e,
                        type,
                        decimal: 3,
                        original: calc?.buy?.bring_to_close,
                      })
                      saveCalc((prev) => ({
                        ...prev,
                        buy: {
                          ...prev?.buy,
                          desired_bring_to_close: validate_number(
                            calc?.buy?.bring_to_close
                          ),
                          run_new_down: true,
                          run_bring_to_close:
                            (prev?.buy?.run_bring_to_close || 0) + 1,
                        },
                      }))
                    }}
                    placeholder=''
                  /> */}
                </>
              ) : (
                ''
              )}
              {implementation_type === 'affordability' &&
              calc?.options?.view_type === 'loan_details' ? (
                ''
              ) : (
                <>{get_inputs('buy')}</>
              )}
            </div>
            <div className='flex justify-center w-full'>
              {error?.show_error ? (
                <div className={`${uiStyles.failure_msg} mb-[10px]`}>
                  {error?.msg}
                </div>
              ) : (
                <button
                  className={`${uiStyles.hover_btn} w-full rounded-none`}
                  onClick={() => {
                    calculate_estimate()
                  }}
                >
                  Calculate
                </button>
              )}
            </div>
            <CalcPaymentChange
              key='same_key'
              ref={null}
              update_input={update_input}
            />
            {showModal ? (
              <Modal
                side_padding={'px-0'}
                modalName={modalType}
                height={modalType === 'remove' ? 'h-[200px]' : 'h-[550px]'}
                width={'w-full'}
                showModal={showModal}
                setShowModal={setShowModal}
                showClose={true}
              >
                {modals[modalType]}
              </Modal>
            ) : (
              ''
            )}
          </div>
        </SwiperSlide>
        {implementation_type === 'rent_vs_buy' ? (
          <SwiperSlide key={'equity'}>
            {calc?.options?.view_type === 'equity' ? (
              <div>
                {calc?.buy?.calculated &&
                validate_number(calc?.buy?.values?.home_price) &&
                validate_number(calc?.buy?.values?.current_rent) ? (
                  <div>
                    <div className='flex flex-col items-center'>
                      <h1 className='text-black text-[15px] '>
                        Total Equity after{' '}
                        {get_year_month_string(
                          calc?.buy?.rent_vs_buy?.break_curr_payment + 1 || 1
                        )}
                      </h1>
                      <div className='flex ml-[-40px]'>
                        <div>
                          <ApexChart
                            key={'equity_chart'}
                            options={{
                              ...calc?.buy?.monthly_chart,
                              plotOptions: {},
                            }}
                            width={'200px'}
                            series={[
                              get_nums()?.down_payment_num,
                              get_rent_vs_buy_val('break_home_payments')
                                ?.home_appreciation_total,
                              get_rent_vs_buy_val('amortize_schedule')
                                ?.total_principal,
                            ]}
                            type='pie'
                          />
                        </div>
                        <div className='mt-[10px]'>
                          <div className='mb-[5px]'>
                            Total Equity - $
                            {format_currency(
                              [
                                get_nums()?.down_payment_num,
                                get_rent_vs_buy_val('break_home_payments')
                                  ?.home_appreciation_total,
                                get_rent_vs_buy_val('amortize_schedule')
                                  ?.total_principal,
                              ]?.reduce((curr, val) => curr + val, 0)
                            )}
                          </div>
                          {labels_html(
                            [...(calc?.buy?.rent_vs_buy?.equity_labels || [])],
                            [
                              get_nums()?.down_payment_num,
                              get_rent_vs_buy_val('break_home_payments')
                                ?.home_appreciation_total,
                              get_rent_vs_buy_val('amortize_schedule')
                                ?.total_principal,
                            ],
                            '',
                            colors
                          )}
                        </div>
                      </div>
                      <div className='ml-[24px] w-[251px]'>
                        <CalcBuyLineChart
                          line_point={
                            calc?.buy?.rent_vs_buy?.break_curr_payment - 1 < 0
                              ? 0
                              : calc?.buy?.rent_vs_buy?.break_curr_payment || 1
                          }
                          show_line_slider={true}
                          show_graph={false}
                          slide_change={(e) => {
                            saveCalc((prev) => ({
                              ...prev,
                              buy: {
                                ...prev?.buy,
                                rent_vs_buy: {
                                  ...prev?.buy?.rent_vs_buy,
                                  break_curr_payment: validate_number(
                                    e.target.value
                                  ),
                                },
                              },
                            }))
                          }}
                          labels={array_nums(
                            validate_number(calc?.buy?.values?.loan_term * 12)
                          )}
                          datasets={[
                            {
                              label: 'Rent Costs',
                              fill: false,
                              data:
                                calc?.buy?.rent_vs_buy?.break_rent_series || [],

                              borderColor: 'rgb(255, 99, 132)',
                              backgroundColor: 'rgba(255, 99, 132, 0.5)',
                            },
                            {
                              label: 'Buy Costs',
                              fill: false,
                              data:
                                calc?.buy?.rent_vs_buy?.break_buy_series || [],

                              borderColor: 'rgb(53, 162, 235)',
                              backgroundColor: 'rgba(53, 162, 235, 0.5)',
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <div className='min-h-[550px]'>
                  <div className='px-3 '>
                    <div className={`flex justify-end my-[10px] gap-[10px]`}>
                      {error?.show_error ? (
                        <div className={`${uiStyles.failure_msg} mb-[10px]`}>
                          {error?.msg}
                        </div>
                      ) : (
                        <button
                          className={`${uiStyles.hover_btn_small}`}
                          onClick={() => {
                            calculate_estimate()
                          }}
                        >
                          Calculate
                        </button>
                      )}
                    </div>

                    {calc?.buy?.values?.loan_type === 'fha' ? (
                      <LabelInput
                        left_label={'Max FHA'}
                        left_label_width={'w-[80%]'}
                        name={'max_fha'}
                        show_dollar={true}
                        value={calc?.buy?.max_fha}
                        show_delete_all={true}
                        percent_value=''
                        handleChange={(e, type) =>
                          update_input({
                            e,
                            type,
                            decimal: 2,
                            original: calc?.buy?.max_fha,
                          })
                        }
                        placeholder=''
                      />
                    ) : (
                      ''
                    )}
                    {get_inputs('buy')}
                  </div>
                  <div className='flex justify-center w-full'>
                    {error?.show_error ? (
                      <div className={`${uiStyles.failure_msg} mb-[10px]`}>
                        {error?.msg}
                      </div>
                    ) : (
                      <button
                        className={`${uiStyles.hover_btn} w-full rounded-none`}
                        onClick={() => {
                          calculate_estimate()
                        }}
                      >
                        Calculate
                      </button>
                    )}
                  </div>
                  <CalcPaymentChange
                    key='same_key'
                    ref={null}
                    update_input={update_input}
                  />
                  {showModal ? (
                    <Modal
                      side_padding={'px-0'}
                      modalName={modalType}
                      height={
                        modalType === 'remove' ? 'h-[200px]' : 'h-[550px]'
                      }
                      width={'w-full'}
                      showModal={showModal}
                      setShowModal={setShowModal}
                      showClose={true}
                    >
                      {modals[modalType]}
                    </Modal>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : (
              ''
            )}
          </SwiperSlide>
        ) : (
          ''
        )}
        {implementation_type === 'rent_vs_buy' ? (
          <SwiperSlide key={'net_sheet'}>
            <div className='min-h-[250px] flex flex-col items-center'>
              <h1 className='text-black text-[15px] mb-[10px]'>
                If you sell in{' '}
                {get_year_month_string(
                  calc?.buy?.rent_vs_buy?.break_curr_payment + 1 || 1
                )}
              </h1>
              <CalcSell implementation_type='rent_vs_buy' />
              <div className='mt-[20px]'>
                <CalcBuyLineChart
                  line_point={
                    calc?.buy?.rent_vs_buy?.break_curr_payment - 1 < 0
                      ? 0
                      : calc?.buy?.rent_vs_buy?.break_curr_payment || 1
                  }
                  show_line_slider={true}
                  show_graph={false}
                  slide_change={(e) => {
                    saveCalc((prev) => ({
                      ...prev,
                      buy: {
                        ...prev?.buy,
                        rent_vs_buy: {
                          ...prev?.buy?.rent_vs_buy,
                          break_curr_payment: validate_number(e.target.value),
                        },
                      },
                    }))
                  }}
                  labels={array_nums(
                    validate_number(calc?.buy?.values?.loan_term * 12)
                  )}
                  datasets={[
                    {
                      label: 'Rent Costs',
                      fill: false,
                      data: calc?.buy?.rent_vs_buy?.break_rent_series || [],

                      borderColor: 'rgb(255, 99, 132)',
                      backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    },
                    {
                      label: 'Buy Costs',
                      fill: false,
                      data: calc?.buy?.rent_vs_buy?.break_buy_series || [],

                      borderColor: 'rgb(53, 162, 235)',
                      backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    },
                  ]}
                />
              </div>
            </div>
          </SwiperSlide>
        ) : (
          ''
        )}
        {implementation_type === 'rent_vs_buy' ? (
          ''
        ) : (
          <SwiperSlide key={'loan_details'}>
            <div className='min-h-[550px]'>
              {implementation_type === 'affordability' ? (
                <div>
                  <div className='flex flex-col justify-center mb-[10px] w-full'>
                    <div className='flex flex-col items-center'>
                      <h1
                        className={`text-black font-semibold text-[13px] mt-[0px]`}
                      >
                        Home Price - $
                        {format_currency(
                          calc?.buy?.values?.home_price,
                          false,
                          2
                        )?.replace('$', '')}
                      </h1>
                      <div className={`${uiStyles.line_3px} my-[10px]`}></div>
                      <div className='flex mt-[10px]'>
                        <div
                          onClick={(e) => {
                            if (e.target.tagName === 'circle') {
                              saveCalc((prev) => ({
                                ...prev,
                                buy: {
                                  ...prev?.buy,
                                  show_total_edit_type: 'monthly_payment',
                                  original_down:
                                    prev?.buy?.values?.down_payment,
                                  show_total_edit: true,
                                  calculated: false,
                                },
                              }))
                            }
                          }}
                        >
                          <ApexChart
                            key={'monthly_chart'}
                            options={{
                              ...calc?.buy?.monthly_chart,
                              plotOptions: {},
                            }}
                            width={'200px'}
                            series={calc?.buy?.monthly_payment_series}
                            type='pie'
                          />
                        </div>
                        <div className='mt-[10px] ml-[-20px] mr-[40px]'>
                          <h1 className='text-black font-semibold text-[13px] ml-[15px] whitespace-nowrap'>
                            Monthly Payment - $
                            {format_currency(
                              calc?.buy?.monthly_payment_series?.reduce(
                                (curr, val) =>
                                  validate_number(curr) + validate_number(val)
                              ),
                              false,
                              0
                            )?.replace('$', '')}
                          </h1>
                          {labels_html(
                            calc?.buy?.monthly_payment_labels,
                            calc?.buy?.monthly_payment_series,
                            'ml-[16px]',
                            calc?.buy?.monthly_chart?.colors
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-col mt-[50px]'>
                      <div className='flex'>
                        <div className='whitespace-nowrap ml-[5px] mr-[-15px]'>
                          <h1 className='text-black font-semibold text-[13px] ml-[20px] mt-[20px]'>
                            Bring to Close - $
                            {format_currency(
                              calc?.buy?.bring_to_close,
                              false,
                              0
                            )?.replace('$', '')}
                          </h1>
                          {labels_html(
                            calc?.buy?.close_labels,
                            calc?.buy?.close_series,
                            'ml-[20px]',
                            calc?.buy?.closing_chart?.colors
                          )}
                        </div>
                        <div
                          onClick={(e) => {
                            if (e.target.tagName === 'circle') {
                              // setHitclose(true)
                              // saveCalc((prev) => ({
                              //   ...prev,
                              //   buy: {
                              //     ...prev?.buy,
                              //     show_total_edit_type: 'closing',
                              //     show_total_edit: true,
                              //     original_down: prev?.buy?.down_payment,
                              //     desired_bring_to_close: prev?.buy?.bring_to_close,
                              //     calculated: false,
                              //   },
                              // }))
                            }
                          }}
                        >
                          <ApexChart
                            key={'closing'}
                            options={{
                              ...calc?.buy?.closing_chart,
                              plotOptions: {},
                            }}
                            width={'200px'}
                            series={calc?.buy?.close_series}
                            type='pie'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {get_loan_type('buy', 'values') === 'all cash' ? (
                    <div className='px-3 font-bold'>
                      Loan details are not available for this loan type!
                    </div>
                  ) : (
                    <CalcBuyDetails implementation_type={implementation_type} />
                  )}
                </>
              )}
            </div>
          </SwiperSlide>
        )}
      </Swiper>
    </div>
  )
}

export default CalcBuy
