import { useState } from 'react'
import bug_icon from '../../assets/images/axiom_bug_icon.png'
import uiStyles from '../ui/uiStyles'
import { Feedback } from '../../pages'
import Modal from '../Modal'

const Bugs = () => {
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      <div
        className='flex flex-col justify-center items-center cursor-pointer'
        onClick={() => {
          setShowModal(true)
        }}
      >
        <img src={bug_icon} className='w-[20px]' alt='report a bug' />
        <p className={`${uiStyles.text_black} text-[10px]`}>report bug</p>
      </div>
      {showModal ? (
        <Modal
          modalName={'bug_report'}
          setShowModal={setShowModal}
          showModal={showModal}
          showClose={true}
        >
          <Feedback modal={true} setShowModal={setShowModal} />
        </Modal>
      ) : (
        ''
      )}
    </>
  )
}

export default Bugs
