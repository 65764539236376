import React, { memo } from 'react'
import Driggslogo from '../../assets/images/axiom_driggs_logo.svg'

function TitleIcon({ children, className = '', showLogo }) {
  return (
    <div className='flex items-baseline'>
      <div
        className={`font-semibold font-base text-primary text-2xl flex px-4 ${className}`}
      >
        {children}
      </div>
      {showLogo && (
        <img src={Driggslogo} alt='' className='w-[39px] h-[41px] mr-[5px]' />
      )}
    </div>
  )
}
export default memo(TitleIcon)
