import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

export const DistributeContext = createContext()

export const DistributeProvider = ({ children, userData }) => {
  const [distribute, setDistribute] = useState({
    social: 'facebook',
    type: 'social',
    list_id: null,
    lists: [],
    list_name: null,
    engage_type: null,
  })

  const saveDistribute = useCallback((data) => {
    setDistribute(data)
  }, [])

  const value = useMemo(
    () => ({
      distribute,
      saveDistribute,
    }),
    [distribute, saveDistribute]
  )

  return (
    <DistributeContext.Provider value={value}>
      {children}
    </DistributeContext.Provider>
  )
}

export const useDistribute = () => {
  const context = useContext(DistributeContext)

  if (!context) {
    throw Error(
      'DistributeContext must be used inside an DistributeContextProvider'
    )
  }

  return context
}
