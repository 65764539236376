import { useState, useEffect } from 'react'
import { AiOutlineCopy } from 'react-icons/ai'
import SmallLoading from '../../ui/SmallLoading'
import uiStyles from '../../ui/uiStyles'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { useAi } from '../../../contexts/AiContext'
export const Ask = ({
  state,
  setState,
  ai_response,
  rewrite,
  copy_text,
  response_only,
}) => {
  const { ai, saveAi } = useAi()
  const [customRewrite, setCustomRewrite] = useState(false)
  const [runRewrite, setRunRewrite] = useState(0)
  useEffect(() => {
    const controller = new AbortController()
    const run = async () => {
      let model = ''
      if (ai?.state?.run) {
        // for (const [key, value] of Object.entries(
        //   ai?.ai_rewrite_options || {}
        // ) || []) {
        //   if (value.includes(ai?.state?.prompt)) {
        //     model = key
        //   }
        // }
        await ai_response({
          model,
          signal: controller?.signal,
          //
        })
      }
    }
    run()

    return () => {
      controller?.abort()
    }
    // eslint-disable-next-line
  }, [runRewrite])
  return (
    <div className='mb-5'>
      {/* <Input label="Re-Write with AXIOM Ai" type="text" name="prompt" /> */}
      {rewrite && !customRewrite ? (
        <div>
          <select
            className={`${uiStyles.select}`}
            name='ai_options'
            id='ai_options'
            onChange={async (e) => {
              if (e.target.value === 'custom') {
                setCustomRewrite(true)
              } else {
                saveAi((prev) => ({
                  ...prev,
                  state: { ...prev?.state, prompt: e.target.value },
                }))
                await ai_response({ prompt: e.target.value, rewrite: true })
              }
            }}
          >
            <option key={'select a re-write option'} value=''>
              select a re-write option...
            </option>
            {Object.entries(ai?.ai_rewrite_options || {}).map((item) => {
              return item[1].map((option) => {
                return (
                  <option
                    key={option}
                    className={`${uiStyles.option}`}
                    value={option}
                  >
                    {option}
                  </option>
                )
              })
            })}
            <option key={'custom'} value='custom'>
              custom
            </option>
          </select>
          <div className='w-full flex justify-center'>
            {ai?.state?.isLoading ? (
              <div className='w-4 h-4 relative'>
                <span className=' animate-ping absolute inline-flex h-full w-full rounded-full bg-gradient-to-b from-accent-150 to-accent-400 opacity-75'></span>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      ) : (
        <div className='relative rounded-md border border-gray-200'>
          <textarea
            disabled={ai?.state?.isLoading}
            className={`max-w-[270px] leading-4 rounded-md resize-none w-full p-5 ${
              rewrite ? 'pr-10' : 'pr-8'
            }  h-28 outline-none italic text-sm`}
            placeholder={
              rewrite ? 'i.e. make it funny...' : 'Write your ai prompt...'
            }
            //ref={aiBoxRef_2}
            //label="Re-Write"
            //fieldname={'contentRequest'}
            value={ai?.state?.prompt}
            onChange={async (e) => {
              saveAi((prev) => ({
                ...prev,
                state: { ...prev?.state, prompt: e.target.value },
              }))
            }}
            onKeyUp={async (e) => {
              if (e.key === 'Enter') {
                saveAi((prev) => ({
                  ...prev,
                  state: { ...prev?.state, prompt: e.target.value },
                }))
                if (!rewrite) {
                  setCustomRewrite(false)
                }
                await ai_response({ prompt: e.target.value })
              }
            }}
          />
          <div className='flex justify-end gap-[10px]'>
            <button
              disabled={ai?.state?.isLoading}
              className={`absolute right-0 top-0 border-l border-b py-2 ${
                rewrite ? 'px-3' : 'px-3'
              } text-sm `}
              onClick={async () => {
                if (!rewrite) {
                  setCustomRewrite(false)
                }
                await ai_response({})
              }}
            >
              {rewrite ? 'Re-Write' : 'Ask'}
            </button>
            {ai?.state?.isLoading && <SmallLoading />}
          </div>
        </div>
      )}
      {rewrite ? (
        <div className='flex justify-end mt-[5px]'>
          <button
            className={`${uiStyles.hover_btn}`}
            onClick={async () => {
              if (response_only) {
                saveAi((prev) => ({
                  ...prev,
                  state: {
                    ...prev?.state,
                    prompt: ai?.slot?.AI_PRPT_PRPT,
                    rewrite: false,
                    message: '',
                    stopHeight: 0,
                  },
                }))
                await ai_response({})
              } else {
                saveAi((prev) => ({
                  ...prev,
                  state: {
                    ...prev?.state,
                    message: '',
                    message_hist: [],
                    subject: '',
                    prompt: response_only ? prev?.prompt : '',
                    original_prompt: '',
                    last_prompt: '',
                    stopHeight: 0,
                  },
                }))
              }
            }}
          >
            Reset
          </button>
        </div>
      ) : (
        <>
          <p className='text-xs font-semibold'>
            What topic would you like to use?
          </p>
          <span>Example: </span>
          <p className='text-xs italic mb-5 flex justify-between'>
            {copy_text}
            <span
              onClick={async (e) => {
                let content = e.currentTarget?.parentElement?.textContent
                saveAi((prev) => {
                  console.log(content)
                  console.log('prev', prev)
                  return {
                    ...prev,
                    state: {
                      ...prev?.state,
                      prompt: content,
                    },
                  }
                })
              }}
              className='flex justify-end cursor-pointer'
            >
              <AiOutlineCopy size={25} />{' '}
            </span>
          </p>
        </>
      )}
    </div>
  )
}
