import { useEffect } from 'react'
import ApexChart from 'react-apexcharts'
import { useNavigate } from 'react-router-dom'
import { SwiperSlide, Swiper } from 'swiper/react'
import { useCalc } from '../../../contexts/CalcContext'
import { usePairwise } from '../../../contexts/PairwiseContext'
import { useProfile } from '../../../contexts/UserContext'
import {
  find_closest_ge,
  format_currency,
  format_input,
  labels_html,
  validate_number,
} from '../../../helpers'
import LabelInput from '../../form/LabelInput'
import { Schedule } from '../../form/Schedule'
import Select from '../../form/Select'
import ButtonsRow from '../../ui/ButtonsRow'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import uiStyles from '../../ui/uiStyles'
const PairwiseEstimator = () => {
  const { profile } = useProfile()
  const navigate = useNavigate()
  const { calc, saveCalc, colors } = useCalc()
  const { pairwise, savePairwise } = usePairwise()
  console.log('calc', calc, pairwise, profile)
  const update_input = (options) => {
    const calculator_type = 'estimator'
    let { name, value } = format_input(options)
    let type =
      options?.type || pairwise?.[calculator_type]?.types?.[`${name}`] || ''
    let max = validate_number(
      pairwise?.[calculator_type]?.maximums?.[`${name}`]
    )

    if (max && validate_number(value) > max) {
      value = max
    }
    savePairwise((prev) => {
      const result = {
        ...prev,
        [calculator_type]: {
          ...prev?.[calculator_type],
          values: { ...prev?.[calculator_type]?.values, [name]: value },
          types: { ...prev?.[calculator_type]?.types, [name]: type },
        },
      }
      return result
    })
  }
  const get_inputs = (calculator_type = 'estimator') => {
    const types = pairwise?.[calculator_type]?.types || {}
    const names = pairwise?.[calculator_type]?.names || {}
    const placeholders = pairwise?.[calculator_type]?.placeholders || {}
    const values = pairwise?.[calculator_type]?.values || {}
    const maximums = pairwise?.[calculator_type]?.maximums || {}
    return Object.entries(values || {})
      ?.filter(([key, value]) => !['county'].includes(key))
      ?.map(([key, value]) => {
        const type = types?.[key]
        const name = names?.[key]
        const max = maximums?.[key]
        const placeholder = placeholders?.[key] || ''
        const label_width = 'w-[80%]'
        const initial_data = {
          left_label: name,
          left_label_width: label_width,
          name: key,
          value,
          placeholder,
        }

        if (
          type === '%' ||
          type === '$' ||
          type === '%$' ||
          type === 'm' ||
          type === 'a' ||
          type === 'int'
        ) {
          initial_data.show_delete_all = true
          initial_data.inputMode = 'decimal'
        }
        if (type === '%') {
          initial_data.show_percent = true
          initial_data.percent_value = '%'
          initial_data.handleChange = (e, type) => {
            update_input({ e, type, decimal: 3, original: value })
          }
        }
        if (type === '$') {
          initial_data.show_dollar = true
          initial_data.percent_value = ''
          initial_data.value = initial_data?.value
            ? `$${initial_data.value}`
            : initial_data?.value || ''
        }
        if (
          key === 'taxes' ||
          key === 'insurance_rate' ||
          key === 'hoa' ||
          key === 'other' ||
          key === 'utilities'
        ) {
          initial_data.show_percent = true
          initial_data.show_dollar = true
          initial_data.percent_value =
            pairwise?.[calculator_type]?.types?.[key] === '%' ? '%' : ''
          initial_data.dollar_percent =
            pairwise?.[calculator_type]?.types?.[key]
          if (key === 'hoa' || key === 'other' || key === 'utilities') {
            initial_data.toggle_values = ['a', 'm']
            initial_data.dollar_percent = 'm'
          }
          initial_data.left_label_width = 'w-[135%]'
          initial_data.delete_all_position = 'right-[75px]'
          initial_data.total = validate_number(
            pairwise?.[calculator_type]?.values?.home_price
          )
          initial_data.handleChange = (e, type) => {
            update_input({ e, type, decimal: 3, original: value })
          }
        }

        let result = (
          <LabelInput
            {...initial_data}
            handleChange={
              initial_data?.handleChange
                ? initial_data?.handleChange
                : (e) => {
                    update_input({ e, type, decimal: 2, original: value })
                  }
            }
          />
        )

        if (type === 'date') {
          result = (
            <Schedule
              left_label={name}
              left_label_width={label_width}
              name={key}
              show_time={false}
              schedule_date={value}
              handle_change={(e) => {
                update_input({ e, type, decimal: 2, original: value })
              }}
            />
          )
        }
        if (type === 'options') {
          const options = pairwise?.[calculator_type]?.option_values?.[key]
          const names = pairwise?.[calculator_type]?.option_names?.[key]
          result = (
            <Select
              left_label={name}
              left_label_width={label_width}
              no_margin={false}
              className='mb-[10px]'
              name={key}
              value={value || 'Conventional'}
              handler={(e) => {
                update_input({ e, type, decimal: 2, original: value })
              }}
              placeholder=''
            >
              {[...(options || [])]?.map((item, index) => {
                return (
                  <option key={item} value={item}>
                    {names?.[index]}
                  </option>
                )
              })}
            </Select>
          )
        }
        console.log('max', max, key)
        if (max) {
          result = (
            <div className='relative'>
              <div>{result}</div>
              <div className='absolute top-[13px] right-[30px]'>
                {`max (${max})`}
              </div>
            </div>
          )
        }
        if (key === 'pair_entity') {
          result = (
            <div className='relative'>
              <div>{result}</div>
              <div className='absolute top-[13px] left-[55px]'>
                {`sold (${validate_number(
                  pairwise?.data?.pair_entities?.find(
                    (item) =>
                      item.pair_id ===
                      validate_number(pairwise?.estimator?.values?.pair_entity)
                  )?.sold_shares
                )}/${validate_number(
                  pairwise?.data?.pair_entities?.find(
                    (item) =>
                      item.pair_id ===
                      validate_number(pairwise?.estimator?.values?.pair_entity)
                  )?.total_shares_in_ent
                )})`}
              </div>
            </div>
          )
        }
        return <div key={key}>{result}</div>
      })
  }

  useEffect(() => {
    const get_calculations = () => {
      savePairwise((prev) => ({
        ...prev,
        estimator: {
          ...prev?.estimator,
          loading_estimate: true,
        },
      }))
      const calculator_type = 'estimator'
      const values = pairwise?.[calculator_type]?.values
      const data_options = pairwise?.[calculator_type]?.data_options
      const pair_entity_id = validate_number(values?.pair_entity)
      const pair_entity_obj = pairwise?.data?.pair_entities?.find(
        (item) => item.pair_id === pair_entity_id
      )
      const home_price = validate_number(values?.home_price)
      const county = values?.county
      const shares_owned = validate_number(values?.shares_owned)
      const number_of_deals = validate_number(values?.number_of_deals)
      const fee_data = calc?.cur_escrow_rates?.filter(
        (item) => item?.county?.toLowerCase() === county
      )
      const home_fees = find_closest_ge(fee_data, 'liability_amt', home_price)
      const loan_fees = find_closest_ge(
        fee_data,
        'liability_amt',
        home_price * 0.8
      )

      const shares_per_deal =
        validate_number(pair_entity_obj?.shares_allowed_per_file) || 0.5

      const lender_def_pct = profile.ACCOUNT_ID === 1320 ? 0.65 : 0.55
      const owner_def_pct = 1.1
      const per_deal_title_fees =
        loan_fees?.base_title_premium * lender_def_pct +
        (home_fees?.def_owner_amt + owner_def_pct) +
        125
      const total_shares_in_entity = validate_number(
        pair_entity_obj?.total_shares_in_ent
      )
      console.log(lender_def_pct, owner_def_pct, per_deal_title_fees)
      const cost_per_share =
        validate_number(pair_entity_obj?.cost_per_share) || 100
      const underwriter_split = data_options?.underwriter_split || 0.15
      const monthly_cost = 10000
      // total_shares_in_entity * cost_per_share
      const total_monthly_title_fees = number_of_deals * per_deal_title_fees
      const total_expenses =
        total_monthly_title_fees * underwriter_split + monthly_cost
      const net_income = total_monthly_title_fees - total_expenses
      console.log(total_monthly_title_fees, total_expenses, net_income)

      const total_earned_monthly = validate_number(
        net_income * (shares_owned / total_shares_in_entity)
      )

      const driggs_shares = pair_entity_obj?.shares_reserved_for_driggs || 51
      const driggs_portion_monthly_costs = driggs_shares * 100
      // * (pair_entity_obj?.cost_per_share || 100)
      const agent_portion_monthly_costs = shares_owned * 100

      //* (pair_entity_obj?.cost_per_share || 100)

      const per_percent_split = net_income * (1 / total_shares_in_entity)
      const driggs_monthly_split =
        net_income * (driggs_shares / total_shares_in_entity)
      const driggs_quarterly_split = driggs_monthly_split * 3
      const driggs_yearly_split = driggs_monthly_split * 12
      const agent_quarterly_split = total_earned_monthly * 3
      const agent_yearly_split = total_earned_monthly * 12
      savePairwise((prev) => ({
        ...prev,
        estimator: {
          ...prev?.estimator,
          loading_estimate: false,
          data_options: {
            ...prev?.estimator?.data_options,
            total_earned_monthly,
            per_deal_title_fees,
            total_monthly_title_fees,
            monthly_cost,
            total_expenses,
            net_income,
            driggs_portion_monthly_costs,
            agent_portion_monthly_costs,
            underwriter_split,
            per_percent_split,
            driggs_monthly_split,
            driggs_quarterly_split,
            driggs_yearly_split,
            agent_quarterly_split,
            agent_yearly_split,
            shares_owned,
            driggs_shares,
          },
        },
      }))
    }

    get_calculations()
  }, [
    pairwise?.estimator?.values,
    pairwise?.data?.pair_entities,
    calc?.cur_escrow_rates,
    calc?.loading_rates,
  ])

  const get_data_option = (key) => {
    return pairwise?.estimator?.data_options?.[key]
  }

  const can_buy_shares = () => {
    return (
      validate_number(
        pairwise?.data?.pair_entities?.find(
          (item) =>
            item.pair_id ===
            validate_number(pairwise?.estimator?.values?.pair_entity)
        )?.shares_avail_to_buy
      ) > 0 && validate_number(pairwise?.estimator?.values?.shares_owned) > 0
    )
  }

  return (
    <div>
      <div className='flex flex-col justify-between'>
        <div>
          <div className='px-3 mb-[10px]'>
            <ButtonsRow
              padding={'p-1'}
              items={['estimate', 'details']}
              selected={pairwise?.options?.view_type || 'estimate'}
              handler={(item) => {
                const position = {
                  estimate: 0,
                  details: 1,
                }
                pairwise?.options?.swiper?.slideTo(position[item])
                savePairwise((prev) => ({
                  ...prev,
                  options: { ...prev?.options, view_type: item },
                }))
              }}
            />
          </div>
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            initialSlide={0}
            onSlideChange={(e) => {
              const position = {
                0: 'estimate',
                1: 'details',
              }
              savePairwise((prev) => ({
                ...prev,
                options: { ...prev?.options, view_type: position[e.realIndex] },
              }))
            }}
            onSwiper={(swiper) => {
              try {
                // const index = 0
                // if (index > 0) {
                //   swiper.slideTo(index === -1 ? 0 : index, 0)
                //   return
                // }
                // if (index === 0 || index === -1) {
                //   swiper.slideTo(1, 900)
                // }
                // setTimeout(() => {
                //   if (index === 0 || index === -1) {
                //     swiper.slideTo(0, 900)
                //   }
                //   swiper.slideTo(index === -1 ? 0 : index, 0)
                //   setSwiper(swiper)
                // }, 175)

                // swiper.slideTo(1)

                if (swiper) {
                  savePairwise((prev) => ({
                    ...prev,
                    options: { ...prev?.options, swiper },
                  }))
                }
              } catch (error) {
                console.log(error)
              }
            }}
          >
            <SwiperSlide>
              <div className='flex flex-col items-center my-[10px]'>
                <h1 className={`${uiStyles.text_black_subtitle}`}>
                  Estimated Earnings
                </h1>
                <div>
                  <ApexChart
                    key={'estimator_chart'}
                    options={{
                      ...pairwise?.estimator?.chart,
                    }}
                    width={'200px'}
                    series={[
                      validate_number(
                        pairwise?.estimator?.data_options?.total_earned_monthly
                      ),
                    ]}
                    type='donut'
                  />

                  {/* {labels_html(
            ['Monthly Earnings'],
            [
              validate_number(
                pairwise?.estimator?.data_options?.total_earned_monthly
              ),
            ],
            '',
            colors
          )} */}
                </div>
              </div>
              <div className='px-3'>{get_inputs()}</div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='min-h-[500px]'>
                <div className='flex flex-col gap-[10px] mt-[20px]'>
                  {[
                    {
                      label: 'Per Deal Title Fees',
                      value: get_data_option('per_deal_title_fees'),
                      dollar: '$',
                      percent: '',
                    },
                    {
                      label: 'Total Title Fees (monthly)',
                      value: get_data_option('total_monthly_title_fees'),
                      dollar: '$',
                      percent: '',
                    },
                    {
                      label: 'Underwriter Split',
                      value:
                        validate_number(get_data_option('underwriter_split')) *
                        100,
                      dollar: '',
                      percent: '%',
                    },
                    {
                      label: 'Total Business Cost (monthly)',
                      value: get_data_option('monthly_cost'),
                      dollar: '$',
                      percent: '',
                    },
                    {
                      label: 'Driggs Business Cost (monthly)',
                      value: get_data_option('driggs_portion_monthly_costs'),
                      dollar: '$',
                      percent: '',
                      no_bold: true,
                    },
                    {
                      label: 'Your Business Cost (monthly)',
                      value: get_data_option('agent_portion_monthly_costs'),
                      dollar: '$',
                      percent: '',
                      no_bold: true,
                    },

                    {
                      label: 'Net Income',
                      value: get_data_option('net_income'),
                      dollar: '$',
                      percent: '',
                    },
                  ]?.map((item) => {
                    return (
                      <>
                        <div className='flex justify-between pl-[5px] pr-[15px]'>
                          <p className='text-[16px]'>
                            {item?.no_bold ? '---  ' : ''}
                            {item?.label}
                          </p>
                          <p
                            className={`text-[16px] ${
                              item?.no_bold ? '' : 'font-bold'
                            }`}
                          >
                            {item?.dollar || ''}
                            {format_currency(item?.value, false, 2)}
                            {item?.percent || ''}
                          </p>
                        </div>
                        <div className={`${uiStyles.line}`}></div>
                      </>
                    )
                  })}
                </div>
                <div className={`${uiStyles.line_3px}`}></div>
                <div className=' w-full grid grid-cols-[1.5fr_1.2fr_1.2fr_1.2fr_1.2fr] text-center mt-[13px] gap-[10px_0px]'>
                  <p className='text-left text-[15px] font-bold pl-[5px]'>
                    Splits
                  </p>
                  <p>Share</p>
                  <p>Month</p>
                  <p>Quarter</p>
                  <p>Year </p>
                  <div className={`${uiStyles.line} col-span-full`}></div>
                  <p className='text-left pl-[5px]'>
                    Agent {get_data_option('shares_owned')}%
                  </p>
                  <p className=' whitespace-nowrap px-[2px]'>
                    $
                    {format_currency(
                      get_data_option('per_percent_split'),
                      false,
                      0
                    )}
                  </p>
                  <p className=' whitespace-nowrap px-[2px]'>
                    $
                    {format_currency(
                      get_data_option('total_earned_monthly'),
                      false,
                      0
                    )}
                  </p>
                  <p className=' whitespace-nowrap px-[2px]'>
                    $
                    {format_currency(
                      get_data_option('agent_quarterly_split'),
                      false,
                      0
                    )}
                  </p>
                  <p className=' whitespace-nowrap px-[2px]'>
                    $
                    {format_currency(
                      get_data_option('agent_yearly_split'),
                      false,
                      0
                    )}
                  </p>
                  <div className={`${uiStyles.line} col-span-full`}></div>

                  <p className='text-left pl-[5px]'>
                    Driggs {get_data_option('driggs_shares')}%
                  </p>
                  <p className=' whitespace-nowrap px-[2px]'>
                    $
                    {format_currency(
                      get_data_option('per_percent_split'),
                      false,
                      0
                    )}
                  </p>
                  <p className=' whitespace-nowrap px-[2px]'>
                    $
                    {format_currency(
                      get_data_option('driggs_monthly_split'),
                      false,
                      0
                    )}
                  </p>
                  <p className=' whitespace-nowrap px-[2px]'>
                    $
                    {format_currency(
                      get_data_option('driggs_quarterly_split'),
                      false,
                      0
                    )}
                  </p>
                  <p className=' whitespace-nowrap px-[2px]'>
                    $
                    {format_currency(
                      get_data_option('driggs_yearly_split'),
                      false,
                      0
                    )}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className='flex flex-col items-center'>
          {can_buy_shares() ? (
            ''
          ) : (
            <h2 className={`text-[14px] mb-[10px] px-3 text-center`}>
              Looks like you don't have enough eligible shares yet to be able to
              join.
            </h2>
          )}
          <button
            disabled={can_buy_shares() ? false : true}
            className={`${
              can_buy_shares()
                ? uiStyles.hover_btn
                : uiStyles.hover_btn_greyed_out
            } w-full rounded-none`}
            onClick={() => {
              savePairwise((prev) => ({
                ...prev,
                documents: {
                  ...prev?.documents,
                  values: {
                    ...prev?.documents?.values,
                    shares_owned: prev?.estimator?.values?.shares_owned,
                    pair_entity: prev?.estimator?.values?.pair_entity,
                  },
                },
              }))
              navigate('/user/pairwise/documents/sign')
            }}
          >
            Apply to Join Pairwise
            {/* {`${
              pairwise?.data?.pair_entities?.find(
                (item) =>
                  item.pair_id ===
                  validate_number(pairwise?.estimator?.values?.pair_entity)
              )?.pair_name
            }`} */}
          </button>
        </div>
      </div>
    </div>
  )
}

export default PairwiseEstimator
