import { useEffect, useState } from 'react'
import LabelInput from '../../form/LabelInput'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import uiStyles from '../../ui/uiStyles'
import { useProfile } from '../../../contexts/UserContext'
export const AddCard = ({ make_toggle, make_default, title, description, setRefresh }) => {
  const { profile } = useProfile()
  const { axiomFetch } = useAxiomFetch()
  const [inputValue, setInputValue] = useState({
    ssl_name: profile?.CONT_NAME,
  })
  const [toggle, setToggle] = useState(false)
  const handleChange = (e) => {
    const { name, value } = e.target
    console.log(name, value)
    setInputValue((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  useEffect(() => {
    setInputValue((prev) => ({
      ...prev,
      ssl_name: profile?.CONT_NAME,
    }))
  }, [profile, toggle])

  const [messageSent, setMessageSent] = useState('done')
  const handle_msg = (status, message, type, close) => {
    // statuses: success_msg, loading_msg, failure_msg, done
    setMessageSent({ ...messageSent, status, message, type })
    if (close) {
      setTimeout(() => {
        setMessageSent({ ...messageSent, status: 'done', message: '' })
      }, 3000)
    }
  }
  const msg_html = (type) => {
    return (
      <>
        {messageSent?.type === type ? (
          <div className={`${uiStyles[messageSent.status]} mb-[20px]`}>{messageSent.message}</div>
        ) : (
          ''
        )}
      </>
    )
  }

  const card_number_format = (number) => {
    number = number?.replace(/\D/g, '')?.slice(0, 16) || ''
    return number?.match(/.{1,4}/g, ' ')?.join(' ') || ''
  }

  const expiry_format = (date) => {
    date = date?.replace(/\D/g, '')?.slice(0, 4)
    return date?.match(/.{1,2}/g, '')?.join('/') || ''
  }

  const ccv_format = (cvv) => {
    cvv = cvv?.replace(/\D/g, '')?.slice(0, 3)
    return cvv || ''
  }

  const add_card = async () => {
    const ssl_first_name = inputValue?.ssl_name?.split(' ')?.[0]
    const ssl_last_name = inputValue?.ssl_name?.split(' ')?.[inputValue?.ssl_name?.split(' ').length - 1]
    const ssl_card_number = inputValue?.ssl_card_number?.replace(/\D/g, '')?.slice(0, 16) || ''
    const ssl_exp_date = inputValue?.ssl_exp_date?.replace(/\D/g, '')?.slice(0, 4) || ''
    const ssl_cvv2cvc2 = inputValue?.ssl_cvv2cvc2?.replace(/\D/g, '')?.slice(0, 3) || ''

    if (!ssl_first_name || !ssl_last_name) {
      handle_msg('failure_msg', 'card must have first and last name', 'name', true)
      return
    }
    if (ssl_card_number.length < 15) {
      handle_msg('failure_msg', 'Card number is not valid.', 'card', true)
      return
    }
    if (ssl_exp_date.length < 4) {
      handle_msg('failure_msg', 'Expiration date format incorrect.', 'expiry', true)
      return
    }

    let body = {
      ssl_first_name,
      ssl_last_name,
      ssl_card_number,
      ssl_exp_date,
      ssl_cvv2cvc2,
    }
    if (make_default) {
      body = { axiom: { make_default_card: 'Y' }, ssl: body }
    }
    const result = await axiomFetch('/merchant/card_token_create', 'POST', body, {}, false)
    setInputValue((prev) => ({
      ...prev,
      ssl_first_name: '',
      ssl_last_name: '',
      ssl_card_number: '',
      ssl_exp_date: '',
      ssl_cvv2cvc2: '',
    }))
    setRefresh((prev) => prev + 1)
    setToggle((prev) => !prev)
    console.log(result)
  }
  return (
    <div>
      {!make_toggle ? (
        ''
      ) : (
        <>
          <div
            className='flex gap-[5px] items-center mb-[10px] cursor-pointer'
            onClick={() => {
              setToggle(!toggle)
            }}
          >
            <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M7.00008 1.16602V12.8327M1.16675 6.99935H12.8334'
                stroke='url(#paint0_linear_1841_11824)'
                strokeWidth='1.66667'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <defs>
                <linearGradient
                  id='paint0_linear_1841_11824'
                  x1='-2.60169'
                  y1='4.93445'
                  x2='4.93518'
                  y2='16.6011'
                  gradientUnits='userSpaceOnUse'
                >
                  <stop stopColor='#00CFDE' />
                  <stop offset='1' stopColor='#05A660' />
                </linearGradient>
              </defs>
            </svg>
            <p className={uiStyles.green_grd_text}>Add new payment method</p>
          </div>
          {!toggle && make_toggle ? <div className={`${uiStyles.line} my-[20px]`}></div> : ''}
        </>
      )}
      {!toggle && make_toggle ? (
        ''
      ) : (
        <>
          <div className='mb-[10px]'>
            <h4 className={`${uiStyles.text_gray} text-[15px] font-medium`}>{title}</h4>
            <p className={`${uiStyles.text_gray} font-normal`}>{description}</p>
          </div>
          <LabelInput
            top_label={'Name on card'}
            name={'ssl_name'}
            value={inputValue?.ssl_name || ''}
            handleChange={handleChange}
          />
          {msg_html('name')}
          <LabelInput
            top_label={'Card number'}
            name={'ssl_card_number'}
            value={card_number_format(inputValue?.ssl_card_number) || ''}
            handleChange={handleChange}
          />
          {msg_html('card')}
          <div className='flex gap-[10px]'>
            <LabelInput
              top_label={'Expiry'}
              width={'w-[100px]'}
              name={'ssl_exp_date'}
              placeholder={'MM/YY'}
              value={expiry_format(inputValue?.ssl_exp_date) || ''}
              handleChange={handleChange}
            />
            {msg_html('expiry')}
            <LabelInput
              width={'w-[100px]'}
              top_label={'CVV'}
              type='password'
              name={'ssl_cvv2cvc2'}
              value={ccv_format(inputValue?.ssl_cvv2cvc2) || ''}
              handleChange={handleChange}
            />
          </div>
          <div className='mt-[10px] flex justify-end gap-2'>
            <button
              className={`${uiStyles.white_btn}`}
              onClick={() => {
                setToggle((prev) => false)
              }}
            >
              Cancel
            </button>
            <button className={`${uiStyles.green_btn}`} onClick={add_card}>
              Save
            </button>
          </div>
          <div className={`${uiStyles.line} my-[20px]`}></div>
        </>
      )}
    </div>
  )
}
