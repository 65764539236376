import React from 'react'
import { CircleCheckToggle } from '../../form/CircleCheckToggle'
import uiStyles from '../../ui/uiStyles'
import { MarketingMaterialCreate } from './MarketingMaterialCreate'
import { MarketingMaterialUpdate } from './MarketingMaterialUpdate'

export const MarketingMaterialHome = ({ state, setState, title }) => {
  console.log(state)
  return (
    <div>
      <div className='flex flex-col items-center gap-[10px]'>
        <div className='flex text-center'>
          <p
            className={`${uiStyles.text_black_title} capitalize text-center mt-[5px]`}
          >
            {`${title || 'Marketing Materials'}`}
          </p>
        </div>
        <CircleCheckToggle
          name={`my_orders_toggle`}
          type='toggle'
          toggle_left={true}
          //   toggle_color_grad={'from-[#0A66C2] to-[#0A66C2]'}
          //   toggle_color={'text-[#000]'}
          toggle_left_label={'My Orders'}
          toggle_right_label={'Create Order'}
          inputValue={state}
          handle_change={async (e) => {
            setState((prev) => {
              return {
                ...prev,
                my_orders_toggle: prev?.my_orders_toggle === 'Y' ? 'N' : 'Y',
              }
            })
          }}
        />
      </div>
      {state?.my_orders_toggle === 'Y' ? (
        <MarketingMaterialUpdate state={state} setState={setState} />
      ) : (
        <MarketingMaterialCreate state={state} setState={setState} />
      )}
    </div>
  )
}
