import {React, useState, useRef} from 'react'
import uiStyles from '../../ui/uiStyles'
import {AiFillDelete} from 'react-icons/ai'
import {GrFormNextLink} from 'react-icons/gr'
import {FcNext} from 'react-icons/fc'
import rightArrow from '../../../assets/images/right-arrow.png'
import { formatPhoneNumber } from '../../../helpers'
import { Link } from 'react-router-dom'
const Member = ({list}) => {
    const [lis, setLis] = useState(list)
  return (
    <div className='flex gap-[10px] items-top pt-5 bg-gray-100 w-[305px] py-3 rounded shadow-md'>
    <div className='flex flex-col gap-2 w-full'>
    <label className="block capitalize relative w-[100px] px-3">
        List Name
        <input 
        type="hidden" 
        value={lis?.LIST_ID}/>
        <input 
        placeholder='name'
        className={`${uiStyles.input} w-[180px] text-xs`}
        value={lis?.LIST_NAME || ''} 
        onChange={(e) => {
            // if (list.updated) {
            //     list.updated = false
            // } else {
                list.updated = true
            //}
            list.LIST_NAME = e.target.value
            setLis({...lis, LIST_NAME: e.target.value})
            
        }} />
        </label>
        </div>
        <div className='flex flex-col gap-3 items-center w-full'>
        <div className=''>
         <label className="block capitalize relative w-[40px] px-3">
              <input
                className="absolute left-0 right-0 top-0 h-full opacity-0"
                type="checkbox"
                name={lis?.LIST_NAME}
                value={lis.STATUS}
                checked={lis?.STATUS === 'A' ? false : true}
                onChange={(e) => {
                // if (list.updated) {
                //     list.updated = false
                // } else {
                    list.updated = true
                //}
                if (e.target.checked) {
                    list.STATUS = 'X'
                setLis({...lis, STATUS: 'X'})
                } else {
                    list.STATUS = 'A'
                    setLis({...lis, STATUS: 'A'})
                }
                }}
              />
              <div className={`transition-all delay-1000 duration-1000 toggle inline-flex w-[36px] h-[20px] rounded-2xl bg-gradient-to-b ${lis.STATUS === 'A' ? 'from-accent-150 to-accent-250' : 'from-gray-200 to-gray-200'} p-[2px] mr-2 align-middle`}>
                <span className={`bg-white handle transition-all delay-1000 duration-1000  w-[16px] h-[16px] rounded-full inline-block  ${lis.STATUS === 'A' && 'transform translate-x-4'}`}></span>
              </div>
              {lis?.STATUS === 'A' ? 'Active' : lis?.STATUS === 'X' ? 'Inactive' : 'Deleted'}
            </label>
        </div>
        <button 
        className={`cursor-pointer`}
        onClick={() => {
            console.log('hello')
            // if (list.updated) {
            //     list.updated = false
            // } else {
                list.updated = true
            // }
            if (list?.STATUS === 'D') {
                list.STATUS = 'A'
                setLis({...lis, STATUS: 'A'})
            } else {
                list.STATUS = 'D'
                setLis({...lis, STATUS: 'D'})
            }
        }}>{lis.STATUS === 'D' ? <AiFillDelete  size={25} color={'red'}/> : <AiFillDelete color={'gray'} size={25}/>}
        </button>
        <Link 
        className={`cursor-pointer`}
        to={`${lis?.LIST_ID}`}
        state={{list_name: lis.LIST_NAME, select: 'MY LISTS', parent: '/user/my-leads-lobby'}}
        >
            {<img className={'h-[30px]'} src={rightArrow}/>}
        </Link>
    
    </div>
    </div>
            )
}

export default Member