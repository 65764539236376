import React, { useRef } from 'react'
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'

import Textarea from './form/Textarea'
import Select from './form/Select'

import PrimaryButton from './ui/PrimaryButton'
import useRecorder from '../hooks/useRecorder'
import AxiomPlayIcon from './ui/AxiomPlayIcon'
import AxiomPauseIcon from './ui/AxiomPauseIcon'

import AxiomRecordIcon from './ui/AxiomRecordIcon'
import AxiomStopRecordIcon from './ui/AxiomStopRecordIcon'

import TitleIcon from './ui/TitleIcon'

export default function SlyDial({ showModal, setShowModal, onMessageSend }) {
  const refTextMessage = useRef()
  let [audioURL, isRecording, startRecording, stopRecording] = useRecorder()

  return (
    <div>
      <div className='text-sm text-center'>
        <span>AXIOM Ai Suggested Content</span>
        <span className='inline-block m-2'>|</span>
        <span className='text-[#34A853]'>Sly Dial </span>
      </div>
      <Textarea ref={refTextMessage} className='h-80' />
      <p className='text-xs mb-5 text-gray-500'>You can customize the content now to suit your needs.</p>

      <Select label='Sending to List' handler={(e) => console.log(e.target.value)} className='mb-3'>
        <option value='list1'>FSBO 01/02/2023</option>
        <option value='list2'>FSBO 01/02/2023</option>
      </Select>

      <Select label='Select SlyDial' handler={(e) => console.log(e.target.value)}>
        <option value='slydial1'>My FSBO SlyDial</option>
        <option value='slydial2'>My FSBO SlyDial</option>
      </Select>

      <AudioPlayer
        autoPlay
        onError={() => {}}
        onPlayError={() => {}}
        src={audioURL}
        onPlay={(e) => {}}
        showFilledVolume={false}
        customAdditionalControls={[]}
        customVolumeControls={[]}
        showJumpControls={false}
        customControlsSection={[
          RHAP_UI.MAIN_CONTROLS,
          isRecording ? (
            <AxiomStopRecordIcon clickHandler={stopRecording} />
          ) : (
            <AxiomRecordIcon clickHandler={startRecording} />
          ),
        ]}
        customIcons={{
          play: <AxiomPlayIcon />,
          pause: <AxiomPauseIcon />,
        }}
      />

      <div className='text-right'>
        <PrimaryButton clickHandler={() => onMessageSend()}>Send</PrimaryButton>
      </div>
    </div>
  )
}
