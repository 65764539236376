import { useEffect, useState, useRef, memo } from 'react'
import Chart from 'react-apexcharts'
import { useCalc } from '../../contexts/CalcContext'
import LabelInput from '../form/LabelInput'
import { SmallLoadingRel } from '../../components/ui/SmallLoadingRel'
import {
  array_nums,
  calculate_amortization_totals,
  format_currency,
  format_input,
  get_year_month_string,
  is_mobile,
  validate_number,
} from '../../helpers'
import CalcAmortizationAccord from './CalcAmortizationAccord'
import CalcBuyLineChart from './CalcBuyLineChart'
const CalcAmortizationGraph = ({
  principal,
  interest_rate,
  loan_term,
  show_extra_payment = false,
  original_nums,
  show_accordian = false,
  extra_payment_send = '',
}) => {
  const [chartData, setChartData] = useState({})
  const [extra_payment, setExtraPayment] = useState('')
  const { calc, saveCalc, swiper_slide } = useCalc()
  const [reload, setReload] = useState(0)
  const [loading, setLoading] = useState(false)
  const timeRef = useRef()
  const payment_year_month_string = (payments) => {
    const num = Math.floor(payments / 12)
    const remainder = payments % 12
    return `${`${num !== 0 ? `${num} year${num > 1 ? 's' : ''}` : ''}`} ${`${
      remainder !== 0 ? `${remainder} month${remainder > 1 ? 's' : ''}` : ''
    }`}`
  }

  useEffect(() => {
    const get_data = () => {
      const amortization_data = calculate_amortization_totals(
        principal,
        interest_rate,
        loan_term,
        validate_number(extra_payment_send || extra_payment) || 0
      )
      const amortization_schedule = amortization_data?.schedule
      const get_series = (type) => {
        return Object.keys(amortization_schedule || {})?.map((key) =>
          validate_number(amortization_schedule?.[key]?.[type], 2)
        )
      }

      const get_series_name = (name, key) => {
        return `${name} - <strong>$${format_currency(
          amortization_data?.[key]
        )}</strong>`
      }
      const balance_series_data = get_series('remaining_balance')
      const interest_series_data = get_series('total_interest')
      const principal_series_data = get_series('total_principal')
      const options = {
        series: [
          {
            name: get_series_name('balance', 'principal'),
            data: balance_series_data,
          },
          {
            name: get_series_name('interest', 'total_interest'),
            data: interest_series_data,
          },
          {
            name: get_series_name('principal', 'principal'),
            data: principal_series_data,
          },
        ],

        chart: {
          animations: {
            enabled: false,
          },
          height: 550,
          type: 'line',
          zoom: {
            enabled: false,
          },
          toolbar: { show: false },
        },
        dataLabels: {
          enabled: false,
        },

        stroke: {
          width: [3, 3, 3],
          curve: ['straight', 'straight', 'straight'],
          dashArray: [0, 0, 0],
        },
        title: {
          text: '',
          align: 'left',
        },

        legend: {
          itemMargin: {
            horizontal: 5,
          },
          offsetY: -15,
          tooltipHoverFormatter: function (val, opts) {
            return (
              val?.replace(/[\s,.\d$-]/g, '') +
              ' - <strong>$' +
              format_currency(
                opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex],
                false,
                2
              ) +
              '</strong>'
            )
          },
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6,
          },
        },
        yaxis: {
          max:
            amortization_data?.total_interest > amortization_data?.principal
              ? amortization_data?.total_interest
              : amortization_data?.principal,
          min: 0,
          //   stepSize: 250000,
          labels: {
            formatter: (val) => {
              return `$${format_currency(val)}`
            },
          },
        },
        xaxis: {
          type: 'numeric',
          tooltip: {
            formatter: (val) => {
              return `${val} months`
            },
          },
          tickAmount: 5,
          min: 0,
          max: amortization_data?.payments + 1,
          labels: {
            formatter: (val) => {
              return `${format_currency(Math.floor(val / 12))} years`
            },
          },
        },
        tooltip: {
          fixed: {
            enabled: true,
            position: 'topLeft',
            offsetY: -120,
            offsetX: 0,
          },
          interesect: true,
          x: {
            formatter: payment_year_month_string,
          },
          y: [
            {
              title: {
                formatter: function (val) {
                  return val?.replace(/[\s,.\d$-]/g, '')
                },
              },
            },
            {
              title: {
                formatter: function (val) {
                  return val?.replace(/[\s,.\d$-]/g, '')
                },
              },
            },
            {
              title: {
                formatter: function (val) {
                  return val?.replace(/[\s,.\d$-]/g, '')
                },
              },
            },
          ],
        },
        grid: {
          borderColor: '#f1f1f1',
        },
      }
      setChartData((prev) => ({ ...prev, options, amortization_data }))
    }

    get_data()
    setLoading(false)
    return () => {
      get_data()
    }
  }, [principal, interest_rate, loan_term, reload, extra_payment_send])

  useEffect(() => {
    saveCalc((prev) => ({
      ...prev,
      buy: { ...prev?.buy, extra_payment_line_point: 0 },
    }))
  }, [])
  const update_input = (options) => {
    console.log('old', options?.e?.target?.value)
    const { name, value } = format_input(options)
    console.log('new', value)
    setExtraPayment(value)
  }

  console.log('chart_data', chartData)
  return (
    <div className='mt-[10px]'>
      <div
        className='relative'
        onTouchStart={() => {
          swiper_slide(false)
        }}
        onTouchMove={() => {
          swiper_slide(false)
        }}
        onTouchEnd={() => {
          swiper_slide(true)
        }}
        // onMouseOver={() => {
        //   swiper_slide(false)
        // }}
        // onMouseEnter={() => {
        //   swiper_slide(false)
        // }}
        // onMouseLeave={() => {
        //   swiper_slide(true)
        // }}
      >
        {/* <Chart
          height={300}
          options={chartData?.options || {}}
          series={chartData?.options?.series || []}
          type={'line'}
        /> */}
        {chartData?.options?.series?.length ? (
          <div>
            <div className='px-3 text-black'>
              Balance after:{' '}
              {get_year_month_string(calc?.buy?.extra_payment_line_point + 1)}
            </div>
            <CalcBuyLineChart
              labels={array_nums(
                validate_number(calc?.buy?.values?.loan_term) * 12
              )}
              line_point={calc?.buy?.extra_payment_line_point || 0}
              slide_change={(e) => {
                saveCalc((prev) => ({
                  ...prev,
                  buy: {
                    ...prev?.buy,
                    extra_payment_line_point: validate_number(e.target.value),
                  },
                }))
              }}
              show_line_slider={true}
              datasets={chartData?.options?.series?.map((item, index) => {
                const colors = {
                  0: 'rgba(255, 99, 132, 1)',
                  1: 'rgba(53, 162, 235, 1)',
                  2: 'rgba(254, 175, 25, 1)',
                }
                return {
                  label: index,
                  data: item?.data,
                  borderColor: colors[index],
                  backgroundColor: colors[index].replace('1', '0.5'),
                }
              })}
            />
            <div className='px-3 mt-[10px] gap-[0px_10px] flex flex-wrap'>
              <div className='flex items-center gap-[2px]'>
                <div className='w-[10px] h-[10px] rounded-full bg-[rgba(255,_99,_132,_1)]'></div>
                <p className='text-[12px]'>
                  Principal: $
                  {format_currency(
                    chartData?.options?.series?.[0]?.data?.[
                      calc?.buy?.extra_payment_line_point || 0
                    ],
                    false,
                    2
                  )}
                </p>
              </div>
              <div className='flex items-center gap-[2px]'>
                <div className='w-[10px] h-[10px] rounded-full bg-[rgba(53,_162,_235,_1)]'></div>
                <p className='text-[12px]'>
                  Interest: $
                  {format_currency(
                    chartData?.options?.series?.[1]?.data?.[
                      calc?.buy?.extra_payment_line_point || 0
                    ],

                    false,
                    2
                  )}
                </p>
              </div>
              <div className='flex items-center gap-[2px]'>
                <div className='w-[10px] h-[10px] rounded-full bg-[rgba(254,_175,_25,_1)]'></div>
                <p className='text-[12px]'>
                  Balance: $
                  {format_currency(
                    chartData?.options?.series?.[2]?.data?.[
                      calc?.buy?.extra_payment_line_point || 0
                    ] || chartData?.amortization_data?.principal,
                    false,
                    2
                  )}
                </p>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        {loading ? (
          <div className='absolute w-full top-[100px]'>
            {/* <SmallLoadingRel /> */}
          </div>
        ) : (
          ''
        )}
        {show_extra_payment ? (
          <div className=''>
            <div
              className={`px-3 mt-[10px] w-full ${
                is_mobile() ? 'bottom-[-20px]' : 'bottom-[0px]'
              }`}
            >
              <div className={`relative`}>
                <LabelInput
                  left_label={'Extra Payment'}
                  name={'extra_payment'}
                  value={extra_payment}
                  show_dollar={true}
                  show_delete_all={true}
                  handleChange={async (e) => {
                    update_input({
                      e,
                      type: '$',
                      name: 'extra_payment',
                      original: extra_payment,
                      decimal: 2,
                    })
                    if (timeRef.current) {
                      console.log('current', timeRef.current)
                      clearTimeout(timeRef.current)
                    }
                    setLoading(true)
                    // timeRef.current = setTimeout(() => {
                    console.log('hello')
                    setReload((prev) => prev + 1)
                    // }, [800])
                  }}
                  onBlur={(e) => {
                    if (e.target.value) {
                      setReload((prev) => prev + 1)
                    }
                  }}
                />
                {/* <button className={`absolute top-[12px] right-[30px]`}>Save</button> */}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      {show_extra_payment && extra_payment ? (
        <>
          {loading && !loading ? (
            <div className='mt-[20px]'>
              <SmallLoadingRel />
            </div>
          ) : (
            <div className='flex justify-center mt-[20px] px-3'>
              <div className='flex items-center flex-col'>
                <p className='font-bold'>Interest Saved:</p>
                <div className='flex items-center justify-center bg-gradient-to-b from-accent-100 to-accent-600 border-2 border-darkgray border-opacity-10 drop-shadow-lg rounded-full w-[85px] h-[85px]'>
                  <div className='bg-white w-[75px] h-[75px] rounded-full flex items-center justify-center'>
                    <p className='text-thirdary font-bold'>
                      {' '}
                      $
                      {format_currency(
                        validate_number(
                          original_nums?.amortization_data?.total_interest
                        ) -
                          validate_number(
                            chartData?.amortization_data?.total_interest
                          ),
                        false,
                        0
                      )}
                    </p>
                  </div>
                </div>
                <div className='flex items-center justify-center font-bold'>
                  {payment_year_month_string(
                    validate_number(chartData?.amortization_data?.payments) -
                      validate_number(
                        chartData?.amortization_data?.payment_count
                      )
                  )}{' '}
                  early
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        ''
      )}
      {show_accordian && !loading ? (
        <CalcAmortizationAccord
          schedule={chartData?.amortization_data?.schedule}
          title={`${extra_payment ? 'New' : ''} Amortization Schedule`}
          className='mt-[20px] px-3'
        />
      ) : (
        ''
      )}
    </div>
  )
}

export default CalcAmortizationGraph
