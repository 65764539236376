import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

export const ClaimsContext = createContext()

export const ClaimsProvider = ({ children, userData }) => {
  const [claims, setClaims] = useState(null)

  const saveClaims = useCallback((data) => {
    setClaims(data)
  }, [])

  const value = useMemo(
    () => ({
      claims,
      saveClaims,
    }),
    [claims, saveClaims]
  )

  return (
    <ClaimsContext.Provider value={value}>{children}</ClaimsContext.Provider>
  )
}

export const useClaims = () => {
  const context = useContext(ClaimsContext)

  if (!context) {
    throw Error('AuthContext must be used inside an AuthContextProvider')
  }

  return context
}
