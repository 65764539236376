import { useEffect, memo } from 'react'
import Wrapper from '../../components/layout/Wrapper'
import { HomeView } from '../../components/pages/home'
import SubNav from '../../components/SubNav'
import { useListingTours } from '../../contexts/ListingToursContext'
import { useReceiver } from '../../contexts/ReceiverContext'
import { get_storage } from '../../helpers'

export const PageHomeView = memo(() => {
  const { receiver, saveReceiver } = useReceiver()
  const { listingTours } = useListingTours()

  useEffect(() => {
    if (
      localStorage?.getItem('vbc_edit_mode') === 'true' &&
      listingTours?.buyer_tours?.property_edit?.search_type !== 'address'
    ) {
      saveReceiver((prev) => ({ ...prev, edit_mode: 'view' }))
    }
  }, [])
  return (
    <Wrapper>
      {receiver?.edit_mode === 'view' ? (
        <SubNav
          logo_url={
            receiver?.edit_mode === 'view'
              ? get_storage('uuid')
                ? '/card'
                : '/user'
              : `/user/my-vbc/${
                  receiver?.vbc_receiver?.ID ||
                  (get_storage('vbc_receiver') || {})?.ID
                }`
          }
          showConversations={false}
          showTours={true}
        />
      ) : (
        <SubNav
          logo_url={
            listingTours?.buyer_tours?.property_edit?.search_type === 'address'
              ? `/user/buyer_tours/add_home`
              : receiver?.edit_mode ||
                localStorage?.getItem('vbc_edit_mode') === 'from_home'
              ? '/user'
              : '/card'
          }
          showConversations={false}
          showTours={false}
        />
      )}
      <HomeView state={receiver} setState={saveReceiver} />
    </Wrapper>
  )
})
