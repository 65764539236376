import { useState } from 'react'
import { useSales } from '../../contexts/SalesContext'
import {
  getDateString,
  validate_number,
  capitalize,
  set_storage,
} from '../../helpers'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import LabelInput from '../form/LabelInput'
import Select from '../form/Select'
import uiStyles from '../ui/uiStyles'

const CliInterest = () => {
  const { sales, saveSales } = useSales()
  const [interestSearch, setInterestSearch] = useState('')
  const { axiomFetch } = useAxiomFetch()
  return (
    <div>
      <div className='px-3'>
        <LabelInput
          show_search={true}
          name='interest_search'
          placeholder='Search Interests...'
          show_delete_all={true}
          value={interestSearch || ''}
          handleChange={(e) => {
            setInterestSearch(e.target.value)
          }}
        />
      </div>
      <div className='px-3'>
        <p className='font-bold'>
          Lead Cycle Interests: {sales?.curr_client?.data?.cont_name}
        </p>
        <div className='flex flex-col gap-[10px] my-[10px]'>
          {sales?.curr_client?.other_data?.driggs_services
            ?.filter((item) =>
              item?.serve_name
                ?.toLowerCase()
                ?.includes(interestSearch?.toLowerCase())
            )
            ?.sort((a, b) => a.serve_name?.localeCompare(b.serve_name))
            ?.map((item) => {
              return (
                <div key={item?.serve_id} className=''>
                  {/* <label>{item?.serve_name}</label> */}
                  <Select
                    padding_label='p-1'
                    padding_select='p-1'
                    left_label_width='w-[350px]'
                    name={item?.serve_name}
                    left_label={item?.serve_name}
                    value={item?.serve_int?.toString()}
                    handler={async (e) => {
                      saveSales((prev) => {
                        const sales = {
                          ...prev,
                          curr_client: {
                            ...prev?.curr_client,
                            other_data: {
                              ...prev?.curr_client?.other_data,
                              driggs_services: [
                                ...(
                                  prev?.curr_client?.other_data
                                    ?.driggs_services || []
                                )?.filter(
                                  (service) =>
                                    service?.serve_id !== item?.serve_id
                                ),
                                {
                                  ...(
                                    prev?.curr_client?.other_data
                                      ?.driggs_services || []
                                  )?.find(
                                    (service) =>
                                      service?.serve_id === item?.serve_id
                                  ),
                                  serve_int: validate_number(e.target.value),
                                },
                              ],
                            },
                          },
                        }
                        set_storage('sales', sales)
                        return sales
                      })
                      await axiomFetch(
                        '/sales/what_next/assign_interest',
                        'POST',
                        {
                          cli_cont_id: sales?.curr_client?.data?.cont_id,
                          serve_id: item?.serve_id,
                          serve_int: validate_number(e.target.value),
                        }
                      )
                    }}
                  >
                    {['none', 'pitched', 'interest', 'engaged']?.map(
                      (option, index) => {
                        return (
                          <option
                            key={option + item?.serve_name}
                            value={(index === 0 ? 0 : index + 1)?.toString()}
                          >
                            {capitalize(option)}
                          </option>
                        )
                      }
                    )}
                  </Select>
                </div>
              )
            })}
        </div>
      </div>
      <div className='px-3'>
        <p className='font-bold mt-[30px] mb-[5px]'>
          Lead Cycle History: {sales?.curr_client?.data?.cont_name}
        </p>
        <div className='grid grid-cols-[1fr_1.1fr_1fr]'>
          <p className>Name</p>
          <p>Date</p>
          <p>By</p>
        </div>
        <div className={`${uiStyles.line_3px}`}></div>
        {sales?.curr_client?.other_data?.cli_lead_cycle_status?.map((item) => {
          return (
            <>
              <div
                key={item?.status_id}
                className='grid grid-cols-[1fr_1.1fr_1fr]'
              >
                <p className='max-w-[100px] overflow-scroll whitespace-nowrap'>
                  {item?.item_name}
                </p>
                <p>{getDateString({ date: item?.cycle_item_dt })}</p>
                <p className='max-w-[100px] overflow-scroll whitespace-nowrap'>
                  {item?.created_by}
                </p>
              </div>
              <div className={`${uiStyles.line}`}></div>
            </>
          )
        })}
      </div>
    </div>
  )
}

export default CliInterest
