import React from 'react'
import { IoMdArrowBack } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import uiStyles from '../ui/uiStyles'

const CalcBackLobby = () => {
  const navigate = useNavigate()
  return (
    <div>
      <button
        className={`${uiStyles.hover_btn_small}`}
        onClick={() => {
          navigate('/user/calculators')
        }}
      >
        <div className='flex items-center gap-[5px]'>
          {<IoMdArrowBack />}
          <p>Calc Lobby</p>
        </div>
      </button>
    </div>
  )
}

export default CalcBackLobby
