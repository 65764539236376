import React, { useCallback, useEffect, useRef, useState } from 'react'
import { HiPlus } from 'react-icons/hi2'
import SubNav from '../../components/SubNav'
import TitleIcon from '../../components/ui/TitleIcon'
import PageSubMenu from '../../components/PageSubMenu'
import SubTitle from '../../components/ui/SubTitle'
import PrimaryButton from '../../components/ui/PrimaryButton'
import RSSList from '../RSSList'
import { INNERMENU } from '../../data/data'
import { useAuth0 } from '@auth0/auth0-react'
import { ServiceRSS } from '../../services/ServiceRSS'
import { useQuery } from '@tanstack/react-query'
import Loading from '../Loading'
import { useProfile } from '../../contexts/UserContext'
import Wrapper from '../layout/Wrapper'
export default function RSS() {
  const [rssFormVisibility, setRSSFormVisibility] = useState(false)
  const { getAccessTokenSilently } = useAuth0()
  const { profile } = useProfile()

  const fetchController = useRef(null)

  const getPromotions = useCallback(async () => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUDIENCE,
    }).catch((err) => null)

    const serviceRSS = new ServiceRSS(token)
    if (!fetchController.current) {
      fetchController.current = serviceRSS.getController().controller
    }
    return serviceRSS.getAll()
  }, [getAccessTokenSilently])

  const { isLoading, isError, data, isFetching } = useQuery({
    queryKey: ['rss'],
    queryFn: getPromotions,
    refetchOnWindowFocus: false,
    enabled: !!profile,
  })

  useEffect(() => {
    return () => fetchController.current && fetchController.current.abort()
  }, [])
  if (!isError && isLoading && isFetching) {
    return <Loading />
  }

  return (
    <Wrapper>
      <SubNav />
      <TitleIcon className='mb-5'>My RSS</TitleIcon>
      <div className='pt-2 pb-4 px-4'>
        <PageSubMenu selected={INNERMENU.RSS} />
        <SubTitle className='mt-4'>RSS Feeds</SubTitle>
        <p className='text-gray-500 text-sm mb-3'>
          RSS feeds are a useful tool for keeping your VBC up-to-date with the
          latest content from your favorite websites. Instead of having to
          search to check for new content, you can add RSS feeds to aggregate
          all of your selections in one place, making it easier to stay
          informed.
        </p>
        <div className='border border-gray-200 p-4 '>
          <SubTitle>Manage your feeds here.</SubTitle>
          <p className='mb-4'>Manage your VBC’s here.</p>
          <PrimaryButton
            clickHandler={() => setRSSFormVisibility(!rssFormVisibility)}
            isSelected={rssFormVisibility}
          >
            <HiPlus className='inline text-xl  align-middle mr-1' /> Custom RSS
          </PrimaryButton>
        </div>

        <div>{data && <RSSList sources={data} />}</div>
      </div>
    </Wrapper>
  )
}
