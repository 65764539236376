import React from 'react'
import { useSales } from '../../contexts/SalesContext'
import { validate_number } from '../../helpers'

const PainPicker = ({ setShowModal }) => {
  const { sales, saveSales } = useSales()
  return (
    <div>
      <div className='flex flex-col gap-[10px] px-1'>
        {sales?.pain_funnel_qsts?.map((item, index) => {
          return (
            <div
              key={item?.qst_id}
              className='bg-gray-100 rounded-md cursor-pointer p-1'
              onClick={() => {
                saveSales((prev) => {
                  let pain_curr_quest = validate_number(index)
                  const sales = {
                    ...prev,
                    curr_client: { ...prev?.curr_client, pain_curr_quest },
                  }
                  return sales
                })
                setShowModal(false)
              }}
            >
              <p className='font-bold '>{item?.pain_name}</p>
              <p className='text-[14px] text-thirdary'>
                {item?.pain_text?.replaceAll('¿', "'")}
              </p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default PainPicker
