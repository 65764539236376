import { memo, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useClaims } from '../../../contexts/ClaimsContext'
import { useProfile } from '../../../contexts/UserContext'
import {
  format_currency,
  getDateString,
  get_storage,
  validate_number,
  getCurrentTime,
  capitalize,
  short_url,
  format_dollar_currency,
  pick_keys,
} from '../../../helpers'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import useHelpers from '../../../hooks/useHelpers'
import DownloadBtn from '../../form/DownloadBtn'
import ButtonsRow from '../../ui/ButtonsRow'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import uiStyles from '../../ui/uiStyles'
import { ListingPhotos } from '../tours'
import { currency, get_address_str } from './utils'

const HomePlanoSub = memo(
  ({ state, setState, page, home, showHomeModal, setShowHomeModal }) => {
    const { claims } = useClaims()
    const { profile } = useProfile()
    const { open_url } = useHelpers()
    const [view, setView] = useState('listing_details')
    const [allData, setAllData] = useState({})
    const [loading, setLoading] = useState(false)
    const { axiomFetch } = useAxiomFetch()

    const location = useLocation()
    const button_handler = (item) => {
      setView(item)
    }
    const is_vbc = () => {
      return claims?.card_access || get_storage('uuid')
    }

    const container_style = 'px-3 flex flex-col gap-[5px] mt-[20px] pb-[20px]'

    useEffect(() => {
      const controller = new AbortController()
      const get_data = async () => {
        const add_data = await axiomFetch(
          `/marketing/homes/get_all_data?id=${
            location?.params?.id || home?.armls_id || page?.add_data?.armls_id
          }&mls_id=${home?.mls_id}`,
          'GET',
          {},
          {},
          false,
          controller.signal
        )

        if (add_data?.name === 'AbortError') {
          return
        }
        add_data.virt_tour_url = (await get_tour_url()) || ''
        console.log('home', home?.mls_id, add_data)
        setAllData(add_data)
      }
      get_data()

      return () => {
        controller.abort()
      }
    }, [home?.armls_id, page?.add_data?.armls_id, home?.mls_id])

    const get_single_layout = (key, value, show_missing = false, type) => {
      if (
        (value?.toString()?.trim() === '$' ||
          value === '0' ||
          !value ||
          value === '********') &&
        !show_missing
      ) {
        return <></>
      }
      return (
        <>
          <div className={`flex ${type}`}>
            <span
              className={`font-bold text-[#344054] ${
                type ? '' : 'min-w-[130px] max-w-[130px]'
              }`}
            >{`${key}: `}</span>
            <span className='ml-[5px]'>{value || ''}</span>
          </div>
          <div className={uiStyles.line}></div>
        </>
      )
    }

    const get_feature_layout = (
      obj_key,
      obj_value,
      show_missing = false,
      type
    ) => {
      if (
        (obj_value?.toString()?.trim() === '$' ||
          obj_value === '0' ||
          !obj_value ||
          obj_value === '********') &&
        !show_missing
      ) {
        return <></>
      }
      if (typeof obj_value === 'string') {
        return get_single_layout(
          obj_key,
          obj_value?.split(',')?.join(', '),
          show_missing,
          type
        )
      }
      if (Array.isArray(obj_value)) {
        return (
          <>
            <div className={`flex ${type}`} key={obj_key}>
              <p
                className={`font-bold text-[#344054]  ${
                  type ? '' : 'min-w-[130px] max-w-[130px]'
                }`}
              >{`${obj_key}: `}</p>{' '}
              <p className=''>
                {obj_value
                  ?.filter((value) => value)
                  ?.map((item) => item)
                  ?.join(', ')}
              </p>
            </div>
            <div className={uiStyles.line}></div>
          </>
        )
      }
      return (
        <>
          <div className={`flex ${type}`}>
            <p
              className={`font-bold text-[#344054] ${
                type ? '' : 'min-w-[130px] max-w-[130px]'
              }`}
            >{`${obj_key}: `}</p>
            <p className=''>
              {Object.entries(obj_value || {})
                ?.filter(([key, value]) => value)
                ?.map(([key, value]) => {
                  return (
                    <p className='ml-[5px]' key={key}>
                      {capitalize(
                        key?.replace(/\s+/g, '')?.replaceAll('r_v', 'rv')
                      )
                        ?.trim()
                        ?.replaceAll('h2_o', 'h20')}
                    </p>
                  )
                })}
            </p>
          </div>
          <div className={uiStyles.line}></div>
        </>
      )
    }

    const get_feature_layout_single = (
      obj_key,
      obj_value,
      show_missing = false
    ) => {
      if (
        (obj_value?.toString()?.trim() === '$' ||
          obj_value === '0' ||
          !obj_value ||
          obj_value === '********') &&
        !show_missing
      ) {
        return <></>
      }
      if (typeof obj_value === 'string') {
        return get_single_layout(obj_key, obj_value)
      }
      if (Array.isArray(obj_value)) {
        return (
          <>
            <div className='flex'>
              <p className='font-bold text-[#344054]'>{`${obj_key}: `}</p>
              <p className=''>
                {obj_value
                  ?.filter((value) => value)
                  ?.map((item) => item?.toString()?.replaceAll('h2_o', 'h20'))}
              </p>
            </div>
            <div className={uiStyles.line}></div>
          </>
        )
      }
      return (
        <>
          <div className='flex flex-col gap-[10px]'>
            {/* <p className='font-bold text-[#344054] min-w-[150px]'>{`${obj_key}: `}</p> */}
            {/* <p className=''> */}
            {Object.entries(obj_value)?.map(([key, value]) => {
              return (
                <div>
                  <div className='flex'>
                    <p className='font-bold text-[#344054]'>
                      {capitalize(
                        key.replace(/\s+/g, '')?.replaceAll('h2_o', 'h20')
                      )?.trim()}
                      :{' '}
                    </p>
                    <p className='ml-[5px]'>{value ? 'Y' : 'N'}</p>
                  </div>
                  <div className={uiStyles.line}></div>
                </div>
              )
            })}
            {/* </p> */}
          </div>
        </>
      )
    }

    const get_tour_url = async () => {
      const url =
        allData?.virtual_tour_u_r_l_unbranded ||
        allData?.virtual_tour_u_r_l_branded ||
        allData?.virtual_tour_u_r_l_branded2 ||
        allData?.virtual_tour_u_r_l_branded3 ||
        allData?.virtual_tour_url ||
        allData?.virtual_tour_u_r_l

      return (await short_url(url || '')) || ''
    }

    console.log('all_data', allData)

    const generate_pdf = async () => {
      setLoading(true)
      const result = await axiomFetch(
        `/marketing/homes/plano?id=${
          home?.armls_id || page?.add_data?.armls_id
        }&mls_id=${home?.mls_id}&prop_add=${
          page?.add_data?.prop_add
        }&home_photo1=${page?.add_data?.home_photo1}`,
        'GET',
        {},
        {},
        false
      )
      setLoading(false)
      return result?.pdf
    }

    const [messageSent, setMessageSent] = useState({})
    const handle_msg = (status, message, type, close) => {
      setMessageSent({ ...messageSent, status, message, type })
      if (close) {
        setTimeout(() => {
          setMessageSent({ ...messageSent, status: 'done', message: '' })
        }, 3000)
      }
    }

    return (
      <div>
        <div>
          {page?.loading_photos ? (
            <div className='min-h-[266px]'>
              <SmallLoadingRel />
            </div>
          ) : (
            <ListingPhotos
              state={state}
              setState={setState}
              page={page}
              showHomeModal={showHomeModal}
              setShowHomeModal={setShowHomeModal}
            />
          )}
        </div>
        <div className='px-3'>
          <p className='mt-[5px]'>
            {home?.prop_add ? get_address_str(home) : ''}
          </p>
          <div className='flex justify-between items-center'>
            <p className='mt-[5px]'>
              <span className='font-bold'>List Price: </span>
              {currency(home)}
            </p>
            <p className='mt-[5px]'>
              <span className='font-bold'>MLS #: </span>
              {page?.add_data?.mls_id || allData?.listing_id}
            </p>
          </div>
        </div>
        <div
          className={`flex my-[10px] justify-center px-3 gap-[10px] items-center`}
        >
          {loading ? (
            <SmallLoadingRel />
          ) : (
            <>
              <button
                className={`${uiStyles.hover_btn_small}`}
                onClick={async () => {
                  const pdf = await generate_pdf()
                  setLoading(true)
                  const message = `PLANO for ${
                    home?.prop_add || page?.add_data?.prop_add
                  } - ${await short_url(pdf)}`
                  const text = {
                    selected: [
                      {
                        message,
                        cell: profile.CONT_CELL_NUM,
                      },
                    ],
                  }
                  await axiomFetch(
                    '/marketing/messaging/sendOneOff',
                    'POST',
                    text
                  )
                  setLoading(false)
                  return handle_msg(
                    'success_msg',
                    'Text Sent!',
                    'send_email',
                    true
                  )
                }}
              >
                Text Me
              </button>
              <button
                className={uiStyles.hover_btn_small}
                onClick={async () => {
                  const pdf = await generate_pdf()
                  setLoading(true)
                  let html = `
                  <a href="${pdf}">PLANO - ${
                    home?.prop_add || page?.add_data?.prop_add
                  }</a>
                  `
                  const body = {
                    msg: {
                      to: [profile?.CONT_EMAIL],
                      cc: [profile?.REP_EMAIL || ''],
                      // to: ['andrewj@driggstitle.com'],
                      from: 'hello@theaxiomapp.com',
                      subject: `PLANO for ${
                        home?.prop_add || page?.add_data?.prop_add
                      }`,
                      // text: `${additional_info_text} ${textRef?.current?.value}`,
                      html: html,
                      // attachments: [...png_attachments, ...pdf_attachments],
                    },
                    // url: window?.location?.href || '',
                    // cont_name: profile?.CONT_NAME || '',
                    // cont_cell_phone: profile?.CONT_CELL_NUM || '',
                    // login_cell_phone:
                    //   user?.name?.replace('+1', '') ||
                    //   user?.nickname?.replace('+1', '') ||
                    //   '',
                    // bug_report_text: textRef?.current?.value || '',
                    no_send_email: false,
                    // insert_bugs: true,
                  }
                  await axiomFetch('/social/email/sendEmail', 'POST', body)
                  setLoading(false)
                  return handle_msg(
                    'success_msg',
                    'Email Sent!',
                    'send_email',
                    true
                  )
                }}
              >
                {' '}
                Email Me
              </button>
              <button
                className={uiStyles.hover_btn_small}
                onClick={async () => {
                  const pdf = await generate_pdf()
                  open_url(pdf, 'Plano')
                }}
              >
                {' '}
                Open PDF
              </button>
              <DownloadBtn
                func_url={async () => {
                  return await generate_pdf()
                }}
              />
            </>
          )}
        </div>
        <div className={uiStyles.line_3px}></div>
        <div className='px-1 mt-[5px]'>
          <ButtonsRow
            handler={button_handler}
            selected={view}
            padding='p-1'
            className='border-b-0'
            scroll={false}
            items={['listing_details', 'schools', 'showing_info']}
          />
          <ButtonsRow
            send_usage_report={false}
            handler={button_handler}
            selected={view}
            padding='p-1'
            className='border-b-0'
            scroll={false}
            items={['features', 'room_details', 'const_&_utilities']}
          />
          <ButtonsRow
            send_usage_report={false}
            handler={button_handler}
            selected={view}
            padding='p-1'
            scroll={false}
            items={['county,_tax,_finance', 'agent_information']}
          />
        </div>
        {/* {view === 'listing_basics' ? (
          <div className={`${container_style}`}>
            {get_single_layout(
              'Original Price',
              `$${format_currency(
                page?.add_data?.original_list_price ||
                  allData?.original_list_price
              )}`
            )}
            {get_single_layout(
              'Status',
              home?.mls_status || page?.add_data?.mls_status
            )}
            {get_single_layout(
              'Taxes',
              page?.add_data?.tax_amount
                ? `$${format_currency(page?.add_data?.tax_amount)} / ${
                    page?.add_data?.tax_year || 'year'
                  }`
                : ''
            )}
            {get_single_layout('Parcel #', page?.add_data?.parcel_number)}
            {get_single_layout(
              'Days on Market',
              page?.add_data?.days_on_market || allData?.days_on_market
            )}
          </div>
        ) : (
          ''
        )} */}

        {view === 'listing_details' ? (
          <div className={`${container_style}`}>
            {get_single_layout('Beds', home?.beds)}
            {get_single_layout('Baths', home?.baths)}
            {get_single_layout('Bedrooms Possible', allData?.bedrooms_possible)}
            {get_single_layout(
              'Approx Sqft',
              `${
                allData?.living_area
                  ? `${format_currency(allData?.living_area)} ${
                      allData?.living_area_source ? '/' : ''
                    } ${allData?.living_area_source || ''}`
                  : ''
              }`
            )}
            {get_single_layout(
              'Price/SqFt',
              format_dollar_currency(allData?.list_price_per_sqft) ||
                format_dollar_currency(
                  validate_number(allData?.list_price) /
                    (validate_number(allData?.living_area) || 1)
                )
            )}
            {get_single_layout(
              'Year Built',
              home?.year_built || page?.add_data?.year_built
            )}
            {get_single_layout(
              'Pool',
              allData?.pool_y_n && allData?.pool_features?.private
                ? allData?.community_features?.community_pool
                  ? 'Both Private & Community'
                  : 'Private'
                : allData?.community_features?.community_pool
                ? 'Community'
                : 'None'
            )}
            {get_single_layout('Stories', allData?.storiesTotal)}
            {get_single_layout(
              'Property Type',
              allData?.property_class || allData?.property_type
            )}
            {get_single_layout('Property Sub Type', allData?.property_sub_type)}
            {validate_number(allData?.lot_size_area) !==
            validate_number(allData?.lot_size_acres)
              ? get_single_layout(
                  'Approx Lot Sqft',
                  `${
                    allData?.lot_size_area
                      ? `${format_currency(allData?.lot_size_area)} ${
                          allData?.lot_size_source ? '/' : ''
                        } ${allData?.lot_size_source || ''}`
                      : ''
                  }`
                )
              : ''}
            {get_single_layout(
              'Approx Lot Acres',
              `${
                allData?.lot_size_acres
                  ? `${format_currency(allData?.lot_size_acres)}`
                  : ''
              }`
            )}
            {get_single_layout('Subdivision', allData?.subdivision_name)}
            {get_single_layout('Tax Municipality', allData?.city)}
            {get_single_layout('Builder Name', allData?.builder_name)}
            {get_single_layout('Unit Number', allData?.unit_number)}
            {get_single_layout('Cross Street', allData?.cross_street)}
            {get_single_layout(
              'Directions',
              allData?.directions,
              true,
              'flex-col'
            )}
            {get_single_layout(
              'Public Remarks',
              allData?.public_remarks,
              true,
              'flex-col'
            )}
            {/* {get_single_layout('Entry Level', allData?.entry_level)} */}
            {/* {get_single_layout('Unit #', allData?.unit_number)} */}
            {/* {get_single_layout(
              'Zoning',
              allData?.zoning || allData?.zoning_description
            )} */}
            {/* {get_single_layout('Rooms', allData?.rooms_total)} */}

            {/* {get_single_layout(
              'Sq Ft',
              format_currency(home?.sqft || home?.sqft)
            )}
            {get_single_layout(
              'Lot Size',
              home?.lot_size_square_feet || page?.add_data?.lot_size_square_feet
                ? `${format_currency(
                    validate_number(
                      validate_number(
                        home?.lot_size_square_feet ||
                          page?.add_data?.lot_size_square_feet
                      ) / 43560,
                      2
                    )
                  )} acres`
                : ''
            )} */}

            {/* {get_single_layout(
              'Lock Box Location',
              is_vbc() ? '' : allData?.lock_box_location
            )}
            {get_single_layout(
              'Lock Box #',
              is_vbc() ? '' : allData?.lock_box_number
            )}
            {get_single_layout(
              'Lock Box Serial #',
              is_vbc() ? '' : allData?.lock_box_serial_number
            )}
            {get_feature_layout(
              'Lock Box Type',
              is_vbc() ? '' : allData?.lock_box_type
            )} */}
          </div>
        ) : (
          ''
        )}
        {view === 'schools' ? (
          <div className={`${container_style}`}>
            {get_single_layout(
              'Ele Sch Dist',
              allData?.district_elementary_school ||
                allData?.elementary_school_district
            )}
            {get_single_layout('Elementary School', allData?.elementary_school)}
            {get_single_layout(
              'Jr. School District',
              allData?.district_middle_or_junior_school ||
                allData?.middle_or_junior_school_district
            )}
            {get_single_layout(
              'Jr. High school',
              allData?.middle_or_junior_school ||
                allData?.custom_fields?.general_property_description
                  ?.high_school
            )}

            {get_single_layout(
              'High School District',
              allData?.district_high_school ||
                allData?.high_school_district ||
                allData?.custom_fields?.general_property_description
                  ?.high_school_district
            )}
            {get_single_layout('High school', allData?.high_school)}
          </div>
        ) : (
          ''
        )}
        {view === 'showing_info' ? (
          <div className={`${container_style}`}>
            {get_single_layout(
              'MLS #',
              page?.add_data?.mls_id || allData?.listing_id
            )}
            {get_single_layout(
              'Open House',
              page?.add_data?.upcoming_open_house ||
                allData?.open_houses?.length
                ? `${getDateString({
                    date:
                      allData?.open_houses?.sort(
                        (a, b) =>
                          new Date(a?.open_house_start_timestamp) -
                          new Date(b?.open_house_start_timestamp)
                      )?.[0]?.open_house_start_timestamp ||
                      allData?.open_house?.sort(
                        (a, b) =>
                          new Date(a?.open_house_start_time) -
                          new Date(b?.open_house_start_time)
                      )?.[0]?.open_house_start_time,
                    format: 'mmm dd, yyyy hh:mi am',
                  })} - ${
                    allData?.open_houses?.sort(
                      (a, b) =>
                        new Date(a?.open_house_start_timestamp) -
                        new Date(b?.open_house_start_timestamp)
                    )?.[0]?.end_time ||
                    getCurrentTime(
                      allData?.open_house?.sort(
                        (a, b) =>
                          new Date(a?.open_house_start_time) -
                          new Date(b?.open_house_start_time)
                      )?.[0]?.open_house_end_time
                    )
                  }`
                : ''
            )}
            {allData?.virt_tour_url ? (
              <>
                <div className='flex'>
                  <p className='font-bold text-[#344054] whitespace-nowrap w-[130px]'>{`Virtual Tour: `}</p>
                  <a
                    className='ml-[5px] underline'
                    target='_blank'
                    href={allData?.virt_tour_url || '#'}
                  >
                    {allData?.virt_tour_url || ''}
                  </a>
                </div>
                <div className={uiStyles.line}></div>{' '}
              </>
            ) : (
              ''
            )}
            {/* {get_feature_layout('Possession', allData?.possession)} */}
            {get_single_layout('Building Name', allData?.building_name)}
            {get_single_layout(
              'Occupant Type',
              is_vbc() ? '' : allData?.occupant_type
            )}
            {get_feature_layout(
              'Showing Service',
              allData?.custom_fields?.showing_notification_methods
                ?.showing_service
            )}
            {allData?.custom_fields?.showing_notification_methods
              ? get_feature_layout(
                  'Showing Notification Method',
                  pick_keys(
                    allData?.custom_fields?.showing_notification_methods,
                    ['showing_service']
                  )
                )
              : ''}
            {get_feature_layout(
              'Permission Required To Show',
              allData?.custom_fields?.showing_instructions
                ?.permission_requiredto_show
            )}
            {get_feature_layout(
              'Primary Showing Contact',
              allData?.custom_fields?.showing_instructions
                ?.primary_showing_contact
            )}
          </div>
        ) : (
          ''
        )}
        {view === 'features' ? (
          <div className={`${container_style}`}>
            {get_single_layout('Garage Spaces', allData?.garage_spaces)}
            {get_single_layout('Carport Spaces', allData?.carport_spaces)}
            {get_single_layout('Covered Spaces', allData?.covered_spaces)}
            {get_single_layout('Open Spaces', allData?.open_parking_spaces)}
            {get_feature_layout('Parking Features', allData?.parking_features)}
            {get_feature_layout('Pool Features', allData?.pool_features)}
            {get_feature_layout('Spa Features', allData?.spa_features)}

            {get_single_layout('Horses', allData?.horse_y_n ? 'Y' : 'N')}
            {get_feature_layout(
              'Fireplace Features',
              allData?.fireplace_features
            )}
            {get_feature_layout(
              'Property Description',
              allData?.custom_fields?.property_description
            )}
            {get_feature_layout(
              'Lot/Landscaping Features',
              allData?.lot_features || allData?.custom_fields?.landscaping
            )}
            {get_feature_layout(
              'Interior Features',
              allData?.interior_features
            )}
            {get_feature_layout(
              'Exterior Features',
              allData?.exterior_features
            )}
            {get_feature_layout('Features', allData?.custom_fields?.features)}
            {get_feature_layout(
              'Community Features',
              allData?.community_features
            )}
            {get_feature_layout('Flooring', allData?.flooring)}

            {get_feature_layout_single(
              'Building Features',
              allData?.building_features
            )}

            {get_feature_layout('View', allData?.view)}
            {get_feature_layout('Window Features', allData?.window_features)}
          </div>
        ) : (
          ''
        )}
        {view === 'room_details' ? (
          <div className={`${container_style}`}>
            {allData?.rooms
              ? Object.entries(allData?.rooms || {})?.map(([key, value]) => {
                  return (
                    <div key={key} className='flex flex-col'>
                      {get_feature_layout(
                        capitalize(key),
                        Object.entries(value || {})
                          ?.filter(([, value]) => value)
                          .map(([key, value]) => {
                            return (
                              <div>
                                <span className='font-bold'>
                                  {capitalize(key)}:
                                </span>{' '}
                                {value}
                              </div>
                            )
                          }),
                        false,
                        'flex-col'
                      )}
                    </div>
                  )
                })
              : ''}
            {get_feature_layout(
              'Kitchen Features',
              allData?.custom_fields?.kitchen_features
            )}
            {get_feature_layout(
              'Master Bedroom',
              allData?.custom_fields?.master_bedroom
            )}
            {get_feature_layout(
              'Master Bathroom',
              allData?.custom_fields?.master_bathroom
            )}
            {get_feature_layout(
              'Additional Bedroom',
              allData?.custom_fields?.additional_bedroom
            )}
            {get_feature_layout('Laundry', allData?.custom_fields?.laundry)}
            {get_feature_layout(
              'Dining Area',
              allData?.custom_fields?.dining_area
            )}
            {allData?.basement
              ? get_feature_layout('Basement', allData?.basement)
              : get_single_layout(
                  'Basement Y/N',
                  allData?.basement || allData?.basement_y_n ? 'Y' : 'N'
                )}
            {get_feature_layout(
              'Other Rooms',
              allData?.custom_fields?.other_rooms
            )}
            {get_feature_layout(
              'Items Updated',
              allData?.custom_fields?.items_updated
            )}
          </div>
        ) : (
          ''
        )}
        {view === 'const_&_utilities' ? (
          <div className={`${container_style}`}>
            {get_feature_layout(
              'Construction Materials',
              allData?.construction_materials
            )}
            {get_feature_layout('Roof', allData?.roof)}
            {get_feature_layout('Fencing', allData?.fencing)}
            {get_feature_layout('Cooling', allData?.cooling)}
            {get_feature_layout('Heating', allData?.heating)}
            {get_feature_layout(
              'Utilities',
              allData?.utilities || allData?.custom_fields?.utilities
            )}
            {get_feature_layout('Water', allData?.water_source)}
            {get_feature_layout('Sewer', allData?.sewer)}
            {get_feature_layout(
              'Technology',
              allData?.custom_fields?.technology
            )}
            {get_feature_layout(
              'Green Energy Features',
              allData?.custom_fields?.['energy/_green_feature'] ||
                allData?.green_energy_efficient
            )}
            {get_feature_layout(
              'Solar Panels',
              allData?.custom_fields?.solar_panels?.ownership
            )}
            {get_feature_layout('Appliances', allData?.appliances)}
          </div>
        ) : (
          ''
        )}
        {view === 'county,_tax,_finance' ? (
          <div className={`${container_style}`}>
            {get_feature_layout(
              'County Code',
              allData?.custom_fields?.['location,_tax&_legal']?.county_code ||
                allData?.county_or_parish
            )}
            {get_feature_layout(
              'Legal Description',
              allData?.tax_legal_description
            )}
            {get_feature_layout('AN', allData?.parcel_number)}
            {get_feature_layout(
              'Town-Range-Sect',
              `${allData?.township || ''}-${
                allData?.public_survey_range || ''
              }-${allData?.public_survey_section || ''}`?.replace('--', '')
            )}
            {get_feature_layout('Lot Number', allData?.tax_lot)}
            {get_single_layout(
              'Taxes',
              allData?.tax_amount
                ? `$${format_currency(allData?.tax_amount)} / ${
                    allData?.tax_year || 'year'
                  }`
                : ''
            )}
            {get_feature_layout('Ownership', allData?.ownership)}
            {get_feature_layout(
              'New Financing',
              allData?.custom_fields?.new_financing
            )}
            {/* {get_feature_layout(
              'Existing 1st Loan',
              allData?.custom_fields?.['existing1st_loan']
            )} */}
            {get_feature_layout('Disclosures', allData?.disclosures)}
            {get_feature_layout('Possession', allData?.possession)}
            {get_feature_layout('Association Name', allData?.association_name)}
            {get_feature_layout(
              'Association Phone',
              allData?.association_phone
            )}
            {get_single_layout(
              'HOA',
              `${allData?.association_y_n ? 'Y' : 'N'} / $${format_currency(
                allData?.association_fee?.toString()?.replace('********', ''),
                false,
                2
              )} ${
                allData?.association_fee_frequency
                  ?.toString()
                  ?.replace('********', '') || ''
              }`
            )}
            {get_single_layout(
              'HOA 2',
              `$${format_currency(
                allData?.association_fee2?.toString()?.replace('********', ''),
                false,
                2
              )} ${
                allData?.association_fee2_frequency
                  ?.toString()
                  ?.replace('********', '') || ''
              }`
            )}
            {get_feature_layout(
              'Transfer Fee',
              format_dollar_currency(
                allData?.custom_fields?.['association&_fees']
                  ?.h_o_a_transfer_fee
              )
            )}

            {get_feature_layout(
              'Association Amenities',
              allData?.association_amenities
            )}
            {get_feature_layout(
              'Association fee includes',
              allData?.association_fee_includes
            )}
          </div>
        ) : (
          ''
        )}

        {/* {view === 'remarks' ? (
          <div className={`${container_style}`}>
            {get_single_layout(
              'Public Remarks',
              allData?.public_remarks || page?.add_data?.public_remarks
            )}
            {get_single_layout(
              'Semi Private Remarks',
              is_vbc()
                ? ''
                : allData?.semi_private_remarks ||
                    page?.add_data?.semi_private_remarks
            )}
            {get_single_layout(
              'Private Remarks',
              is_vbc()
                ? ''
                : allData?.private_remarks || page?.add_data?.private_remarks
            )}
            {get_single_layout(
              'Private Office Remarks',
              is_vbc()
                ? ''
                : allData?.private_office_remarks ||
                    page?.add_data?.private_office_remarks
            )}
          </div>
        ) : (
          ''
        )} */}
        {view === 'agent_information' ? (
          <div className={`${container_style}`}>
            {get_single_layout(
              'Listing Agent Name',
              allData?.list_agent_name || allData?.list_agent_full_name
            )}
            {get_single_layout(
              'Preferred Phone',
              allData?.list_agent_preferred_phone
            )}
            {get_single_layout('Cell Phone', allData?.list_agent_cell_phone)}
            {get_single_layout('Home Phone', allData?.list_agent_home_phone)}
            {get_single_layout(
              'Direct Phone',
              allData?.list_agent_direct_phone
            )}
            {get_single_layout('Fax', allData?.list_agent_fax)}
            {get_single_layout(
              'Website',
              allData?.list_agent_u_r_l ? (
                <a
                  href={`//${allData?.list_agent_u_r_l
                    ?.replace('https://', '')
                    ?.replace('http://', '')
                    ?.replace('www.', '')}`}
                  target='_blank'
                  className='underline'
                >
                  {allData?.list_agent_u_r_l}
                </a>
              ) : (
                ''
              )
            )}
            {get_single_layout('Office', allData?.list_office_name)}
            {get_single_layout('Office Phone', allData?.list_office_phone)}
            {get_single_layout('Office Email', allData?.list_office_email)}
            {get_single_layout('Office Fax', allData?.list_office_fax)}
            {get_single_layout(
              'Buyer/Broker',
              `${
                allData?.custom_fields?.contract_information?.[
                  'buyer/_broker'
                ] || ''
              } / ${(
                allData?.custom_fields?.contract_information?.[
                  'buyer_broker$/%'
                ] ||
                allData?.buyer_agency_compensation_type ||
                ''
              )?.replace('%', '')}${format_currency(
                allData?.custom_fields?.contract_information?.[
                  'compto_buyer_broker'
                ] ||
                  allData?.buyer_agency_compensation ||
                  ''
              )}${(
                allData?.custom_fields?.contract_information?.[
                  'buyer_broker$/%'
                ] ||
                allData?.buyer_agency_compensation_type ||
                ''
              )?.replace('$', '')}`
                ?.replace('/', '')
                ?.trim()
            )}
            {get_single_layout(
              'Var',
              `${
                allData?.custom_fields?.contract_information?.[
                  'variable_commission'
                ] || ''
              }`
            )}
            {get_feature_layout(
              'Special Listing Conditions',
              allData?.special_listing_conditions
            )}
            {get_single_layout(
              allData?.cumulative_days_on_market && allData?.days_on_market
                ? 'CDOM / ADOM'
                : 'DOM',
              `${allData?.cumulative_days_on_market || ''} ${
                allData?.cumulative_days_on_market ? '/' : ''
              } ${allData?.days_on_market || ''}`
            )}
            {get_single_layout(
              'Status Change Date',
              allData?.status_change_date
            )}
            {get_single_layout('Off Market Date', allData?.off_market_date)}
            {get_feature_layout('Lock Box Type', allData?.lock_box_type)}
          </div>
        ) : (
          ''
        )}

        <>
          {messageSent?.type === 'send_email' ? (
            <div className={`${uiStyles[messageSent.status]} mb-[20px]`}>
              {messageSent.message}
            </div>
          ) : (
            ''
          )}
        </>
      </div>
    )
  }
)

export default HomePlanoSub
