import React, { useCallback, useEffect, useRef, useState } from 'react'

import ListItem from '../../ui/ListItem'
import SwipeConfig from '../../ui/SwipeConfig'
// import Wrapper from '../layout/Wrapper';
import { useProfile } from '../../../contexts/UserContext'
import UpdateRequest from '../../UpdateRequest'
import Modal from '../../Modal'
import SmallLoading from '../../ui/SmallLoading'
import axiom_1 from '../../../assets/images/AXIOM_1.png'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import { getDateString } from '../../../helpers'

export default function MarketingMaterialPending({
  selection = false,
  preview = false,
}) {
  const [selected, setSelected] = useState(1)
  const [toggle, setToggle] = useState(0)
  let prevToggle = 0
  let lastToggle = 0
  const { axiomFetch } = useAxiomFetch()
  const { profile } = useProfile()
  // const [, setNeedsUpdate] = useState(0)
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [currentOrder, setCurrentOrder] = useState({})

  const onClose = () => {
    setShowModal(null)
  }

  useEffect(() => {
    const controller = new AbortController()

    const get_pending_materials = async () => {
      setIsLoading(true)
      const items = await axiomFetch(
        '/marketing/materials/status/pending',
        'GET',
        {},
        {},
        false,
        controller.signal
      )
      if (!controller.signal.aborted) {
        let final_items = items
        if (final_items?.length) {
          final_items = items
        }
        final_items = items?.filter((item) => item?.REQ_STATUS !== 11)
        setData(final_items)
        setIsLoading(false)
      }
    }
    get_pending_materials()
    return () => {
      controller?.abort()
    }
  }, [])

  // const rightConfig = (item) => () =>
  //   (
  //     <span className="text-md" onClick={() => mutation.mutate(item)}>
  //       Approve
  //     </span>
  //   );
  // const leftConfig = (item) => () => <button className="text-md"
  // onClick={() => {
  //   //change it back to 5
  //   setCurrentOrder(item)
  //   setShowModal(true)
  // }}>Update</button>;
  const htmlListing =
    data && !data?.length ? (
      <div className='p-5'>No requests are pending yet.</div>
    ) : (
      data?.map((item, index) => {
        // if (index < 10) {
        return (
          <SwipeConfig
            key={index}
            index={index}
            selectedIndex={selected}
            setSelectedIndex={setSelected}
            // RightComponents={rightConfig(item)}
            // LeftComponents={leftConfig(item)}
          >
            <ListItem
              key={index}
              toggle={toggle}
              index={index + 1}
              setToggle={setToggle}
              image={item.IMAGE_LINK || item?.NEW_PDF_URL || axiom_1}
              // {DUMMYTHUMBS[Math.floor(Math.random() * 3)]}
              title={item?.PRODUCT_REQ || 'Instant Design'}
              //content={item.REQ_DESC}
              orderDate={getDateString(item.REQ_DT)}
              quantity={item.REQ_QUANTITY}
              job_id={item.REQ_ID}
              status_text={item?.REQ_STATUS_TEXT || 'Pending'}
              link={false}
              preview={preview}
            />
          </SwipeConfig>
        )
        //}
      })
    )
  if (isLoading) {
    return <SmallLoading />
  }
  return (
    // <Wrapper>
    <div
      onClick={() => {
        // when clicking on wrapper need to have it actually close on first try. problem is when the picture is clicked wrapper gets clicked
        lastToggle = toggle
        // eslint-disable-next-line
        if ((lastToggle !== toggle || prevToggle == toggle) && toggle > 0) {
          setToggle(0)
          prevToggle = toggle
        }

        prevToggle = toggle
      }}
    >
      {/* <div className="pt-2 pb-4 px-4">
        <PageSubMenu selected={INNERMENU.ApproveMM} />
      </div> */}
      <div>
        <div className='flex flex-col gap-[2px]'>{htmlListing}</div>
      </div>
      {/* </Wrapper> */}
      {showModal && (
        <Modal
          modalName={`marketing_material_pending`}
          height='h-[550px]'
          showModal={showModal}
          setShowModal={setShowModal}
        >
          <UpdateRequest
            // listing={selectListing}
            currentOrder={currentOrder}
            setCurrentOrder={setCurrentOrder}
            onClose={onClose}
          />
        </Modal>
      )}
    </div>
  )
}
