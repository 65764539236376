import React, { useEffect, useState } from 'react'
import ai_images from '../../../assets/images/ai'
import uiStyles from '../../ui/uiStyles'
import { useAi } from '../../../contexts/AiContext'
import { CircleCheckToggle } from '../../form/CircleCheckToggle'
import { useNavigate } from 'react-router-dom'
import ListItem from '../../ui/ListItem'
import useAxiomFetch from '../../../hooks/useAxiomFetch'
import SwipeConfig from '../../ui/SwipeConfig'
import { SmallLoadingRel } from '../../ui/SmallLoadingRel'
import { AskAxiom } from '../AI/AskAxiom'
import { AiContent, AiMls, AiMlsSimple } from './index'
import ButtonsRow from '../../ui/ButtonsRow'
import { MarketingMaterialListingSlider } from '../marketingMaterials/MarketingMaterialListingSlider'
import {
  date_within_days,
  just_listed_days,
  just_sold_days,
  listing_status,
  under_contract_days,
} from '../../../helpers'
import { content_types } from '../../../data/data'
import { useDistribute } from '../../../contexts/DistributeContext'
import { useProfile } from '../../../contexts/UserContext'
import { useReceiver } from '../../../contexts/ReceiverContext'
export const AiAssistant = () => {
  const { axiomFetch } = useAxiomFetch()
  const [selected, setSelected] = useState(1)
  const [state, setState] = useState({})
  const [loading, setLoading] = useState(false)
  const { ai, saveAi } = useAi()
  const { receiver, saveReceiver } = useReceiver()
  const { distribute, saveDistribute } = useDistribute()
  const navigate = useNavigate()
  const { profile } = useProfile()
  console.log(profile)
  useEffect(() => {
    const controller = new AbortController()
    const get_assistant_slots = async () => {
      setLoading(true)
      const data = await axiomFetch(
        '/lobbies/ai_assistant',
        'GET',
        {},
        {},
        false,
        controller?.signal
      )
      if (!controller?.signal?.aborted) {
        saveAi((prev) => ({
          ...prev,
          assistant: {
            ...prev?.assistant,
            slots: data?.slots || [],
            types: data?.types || [],
            property_types: data?.property_types || [],
            property_types_ids: data?.property_types_ids || [],
            personal_types: data?.personal_types || [],
          },
        }))
        setLoading(false)
      }
    }
    get_assistant_slots()
    return () => {
      controller?.abort()
    }
  }, [])
  const agent_ai_slots = {}
  let assistant_slots = ai?.assistant?.slots
  const check_listing_status = (item) => {
    const status = listing_status?.[ai?.current_listing?.mls_status]
    const status_check = status?.tags.includes(item?.type_name?.toLowerCase())

    if (
      item?.type_name?.toLowerCase() === 'price reduced' ||
      item?.type_name?.toLowerCase() === 'price reduction'
    ) {
      return (
        ai?.current_listing?.original_list_price >
          ai?.current_listing?.list_price &&
        ai?.current_listing?.mls_status != 'Closed'
      )
    }

    if (item?.type_name?.toLowerCase() === 'open house') {
      return ai?.current_listing?.upcoming_open_house
    }
    console.log(
      date_within_days(ai?.current_listing?.list_date, just_listed_days)
    )
    if (
      date_within_days(ai?.current_listing?.close_date, just_sold_days) ||
      date_within_days(
        ai?.current_listing?.active_under_contr_timestamp,
        under_contract_days
      ) ||
      date_within_days(ai?.current_listing?.list_date, just_listed_days)
    ) {
      return status_check && item?.type_name?.toLowerCase().includes('just')
    }
    return status_check && !item?.type_name?.toLowerCase().includes('just')
  }
  assistant_slots = assistant_slots?.filter((item) => {
    if ((ai?.assistant?.main_filter || 'property_ai') === 'property_ai') {
      return (
        ai?.assistant?.property_types_ids?.includes(item?.AI_PROMPT_TYPE_ID) &&
        check_listing_status(item)
      )
    } else {
      return (
        !ai?.assistant?.property_types_ids?.includes(item?.AI_PROMPT_TYPE_ID) &&
        item?.AI_PROMPT_TYPE_ID?.toString() ===
          (ai?.assistant?.personal_filter?.toString() ||
            ai?.assistant?.personal_types
              ?.filter((item) => {
                return ai?.assistant?.slots?.some(
                  (slot) => item.type_id === slot?.AI_PROMPT_TYPE_ID
                )
              })?.[0]
              ?.type_id?.toString())
      )
    }
  })
  for (const slot of assistant_slots || []) {
    agent_ai_slots[slot?.SLOT_AI_NUM] = {
      swipe_right: () => (
        <div
          className='cursor-pointer'
          onClick={() => {
            saveAi((prev) => ({
              ...prev,
              assistant: { ...prev?.assistant, toggle_back: true },
              slot: {
                ...slot,
                AI_GO_PROP:
                  (ai?.assistant?.main_filter || 'property_ai') ===
                  'property_ai'
                    ? 'Y'
                    : 'N',
                already_have_listing:
                  (ai?.assistant?.main_filter || 'property_ai') ===
                  'property_ai',
              },
              mls: {
                ...prev?.mls,
                mlsMatch: ai?.current_listing,
                setting: 'mlsNo',
                add_mls_to_prompt: 1,
                start_ai: false,
              },
              state: {
                run:
                  (ai?.assistant?.main_filter || 'property_ai') ===
                  'property_ai'
                    ? false
                    : true,
                prompt: slot?.AI_PRPT_PRPT,
              },
            }))
            saveAi((prev) => {
              return {
                ...prev,
                image_upload: {
                  ...prev?.image_upload,
                  gallery: {
                    ...prev?.image_upload?.gallery,
                    active: ai?.current_listing?.home_photo1
                      ? { url: ai?.current_listing?.home_photo1 }
                      : { url: '' },
                  },
                },
              }
            })

            let distribute_content = distribute?.content || {}

            for (const content_type of content_types) {
              distribute_content[content_type] = {
                ...distribute_content?.[content_type],
                photo_url: ai?.current_listing?.home_photo1
                  ? ai?.current_listing?.home_photo1
                  : '',
                image_upload: {
                  ...distribute_content?.[content_type]?.image_upload,
                  gallery: {
                    ...distribute_content?.[content_type]?.image_upload
                      ?.gallery,
                    active: ai?.current_listing?.home_photo1
                      ? { url: ai?.current_listing?.home_photo1 }
                      : { url: '' },
                  },
                },
              }
            }
            saveDistribute((prev) => ({
              ...prev,
              content: distribute_content,
            }))
            navigate(`/user/ai`)
          }}
        >
          Ask{' '}
        </div>
      ),
      component: (
        <ListItem
          image={slot?.SLOT_THUMB_IMG || slot?.AI_PRPT_THUMB}
          title={slot?.SLOT_NAME || slot?.AI_PRPT_NM}
          subtitle={slot?.type === 'property' ? slot?.type_name : ''}
          content={(
            slot?.SLOT_DESC || slot?.AI_PRPT_DESC?.split('loading:')?.[0]
          )?.replace('¿', "'")}
        />
      ),
      order: slot?.SLOT_AI_NUM,
      show: slot ? true : false,
    }
  }

  const slots = { ...agent_ai_slots }

  console.log(
    ai?.assistant?.personal_types
      ?.filter((item) => {
        return ai?.assistant?.slots?.some(
          (slot) => item.type_id === slot?.AI_PROMPT_TYPE_ID
        )
      })?.[0]
      ?.type_id?.toString()
  )
  return (
    <div>
      <div className='flex items-center mb-[20px] ml-[10px]'>
        <img src={ai_images.ai_icon} />
        <div>
          <p className={`${uiStyles.text_black_subtitle} ml-[18px]`}>
            AXIOM Ai ASSISTANT
          </p>
          <div className='w-full flex justify-center mt-[8px] ml-[4px]'>
            <CircleCheckToggle
              name={`is_assistant`}
              type='toggle'
              toggle_left={true}
              toggle_left_label={'Assisted Ai'}
              toggle_right_label={'Advanced Ai'}
              inputValue={ai?.assistant || {}}
              handle_change={async (e) => {
                saveAi((prev) => {
                  window.localStorage.setItem(
                    'ai_assistant',
                    prev?.assistant?.is_assistant === 'Y' ? 'N' : 'Y'
                  )
                  return {
                    ...prev,
                    assistant: {
                      ...prev?.assistant,
                      is_assistant:
                        prev?.assistant?.is_assistant === 'Y' ? 'N' : 'Y',
                    },
                  }
                })
              }}
            />
          </div>
        </div>
      </div>
      {ai?.assistant?.is_assistant === 'Y' ? (
        <div>
          <div>
            <div className='px-3 pb-3'>
              <ButtonsRow
                items={['personal_ai', 'property_ai']}
                selected={ai?.assistant?.main_filter || 'property_ai'}
                handler={(item) => {
                  console.log(item)
                  saveAi((prev) => ({
                    ...prev,
                    assistant: { ...prev.assistant, main_filter: item },
                  }))
                }}
              />
            </div>
            {(ai?.assistant?.main_filter || 'property_ai') === 'property_ai' ? (
              <div className='pb-3'>
                <MarketingMaterialListingSlider
                  my_listings_look={true}
                  state={ai}
                  setState={saveAi}
                  image_link={() => {
                    saveReceiver((prev) => {
                      localStorage.setItem('vbc_edit_mode', 'true')
                      return {
                        ...prev,
                        edit_mode: 'view',
                        homes: {
                          ...prev?.homes,
                          current_home: ai?.current_listing,
                        },
                      }
                    })

                    navigate('/homes/view', {
                      state: { parent: '/user/ai/assistant' },
                    })
                  }}
                />
              </div>
            ) : (
              <div className='px-3 pb-3'>
                <select
                  className={uiStyles.select}
                  value={
                    ai?.assistant?.personal_filter ||
                    ai?.assistant?.personal_types
                      ?.filter((item) => {
                        return ai?.assistant?.slots?.some(
                          (slot) => item.type_id === slot?.AI_PROMPT_TYPE_ID
                        )
                      })?.[0]
                      ?.type_id?.toString()
                  }
                  onChange={(e) => {
                    console.log(e.target.value)
                    saveAi((prev) => ({
                      ...prev,
                      assistant: {
                        ...prev?.assistant,
                        personal_filter: e.target.value,
                      },
                    }))
                  }}
                >
                  {ai?.assistant?.personal_types
                    ?.filter((item) => {
                      return ai?.assistant?.slots?.some(
                        (slot) => item.type_id === slot?.AI_PROMPT_TYPE_ID
                      )
                    })
                    ?.map((item) => {
                      return (
                        <option key={item?.type_id} value={item?.type_id}>
                          {item?.type_name}
                        </option>
                      )
                    })}
                </select>
              </div>
            )}
          </div>

          {Object.keys(slots)?.length ? (
            <div className='flex flex-col gap-[2px] max-h-[500px] overflow-y-scroll'>
              {Object.entries(slots)
                .filter((item) => item[1].show)
                .sort((a, b) => {
                  if (a[1]?.order_desc || b[1]?.order_desc) {
                    return b[1].order - a[1].order
                  } else {
                    return a[1].order - b[1].order
                  }
                })
                .map(([key, slot], index) =>
                  slot.component ? (
                    slot?.swipe_right ? (
                      <SwipeConfig
                        index={index}
                        selectedIndex={selected}
                        setSelectedIndex={setSelected}
                        widthConfigRight={44}
                        RightComponents={slot?.swipe_right || ''}
                        key={key}
                      >
                        {slot?.component || <></>}
                      </SwipeConfig>
                    ) : (
                      <div key={key}>{slot?.component || <></>}</div>
                    )
                  ) : (
                    <div key={key}></div>
                  )
                )}
            </div>
          ) : loading ? (
            <SmallLoadingRel />
          ) : (
            <div className='px-3'>No results match your current search.</div>
          )}
        </div>
      ) : (
        <div className='px-3'>
          <p className={`${uiStyles.text_black_title} mb-[10px]`}>
            Advanced Ai
          </p>
          <AiContent title={false} />
        </div>
      )}
    </div>
  )
}
