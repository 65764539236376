import React from 'react';

export default function TitleOnBoard({
  children,
  marginBottom,
  marginTop,
  color,
}) {
  const bottomSpace = marginBottom ? marginBottom : '';
  const topSpace = marginTop ? marginTop : '';
  return (
    <h1
      className={`text-center font-semibold text-3xl ${bottomSpace} ${topSpace} ${
        color ? color : ''
      }`}
    >
      {children}
    </h1>
  );
}
