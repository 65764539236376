import { useEffect, useRef, useState } from 'react'
import { BsShare } from 'react-icons/bs'
import { FaShare } from 'react-icons/fa'
import { SlShare } from 'react-icons/sl'
import { format_currency, getTextWidth, validate_number } from '../../helpers'

const CalcCircle = ({
  value,
  title,
  use_val = false,
  shrink_text = false,
  bg = 'bg-gradient-to-b from-accent-100 to-accent-600',
  handle_val = () => {},
}) => {
  const textRef = useRef(null)
  const [circleSize, setCircleSize] = useState(0)
  const [textSize, setTextSize] = useState(0)
  useEffect(() => {
    const resize = () => {
      const el = textRef?.current
      if (el) {
        const width = getTextWidth(el?.textContent?.trim(), el)
        if (shrink_text) {
          if (width > 50) {
            setTextSize(12)
            setCircleSize(85)
          } else {
            setCircleSize(85)
          }
        } else {
          if (width > 135) {
            setCircleSize(width + 10)
          } else if (width < 30) {
            setCircleSize(80)
          } else {
            setCircleSize(width + 25)
          }
        }
      }
    }
    resize()
    return () => {
      resize()
    }
  }, [value])

  const get_str = (value) => {
    let new_value = `$${format_currency(
      validate_number(value),
      false,
      0
    )}`?.trim()
    if (new_value?.length > 17) {
      new_value = `${new_value?.slice(0, 15)}...`
    }
    return new_value
  }

  return (
    <div className='flex justify-center'>
      <div className='flex items-center flex-col'>
        <p className='font-bold text-xs whitespace-nowrap'>
          {title || 'Current Loan Balance'}
        </p>
        <div
          className={`flex items-center justify-center ${bg} border-2 border-darkgray border-opacity-10 drop-shadow-lg rounded-full`}
          style={{
            width: `${circleSize + 10}px`,
            height: `${circleSize + 10}px`,
          }}
        >
          <div
            className={`flex flex-col bg-white rounded-full items-center justify-center ${
              use_val ? 'cursor-pointer' : ''
            }`}
            style={{
              width: `${circleSize}px`,
              height: `${circleSize}px`,
            }}
            onClick={() => {
              handle_val(value)
            }}
          >
            <p
              className={`text-thirdary font-bold ${
                circleSize > 80 ? 'text-sm' : ''
              }`}
              style={{ fontSize: textSize ? `${textSize}px` : '14px' }}
              ref={textRef}
            >
              {get_str(value)}
            </p>
            {use_val ? (
              <div className='mt-[5px]'>
                <FaShare />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CalcCircle
